import { DateTime } from 'luxon';
import { PreRegisterRequest, SpeakingDetails } from 'ors-api/ukvi';

import slice from './reservationSlice';
import { globalCleanRegistration } from '../_common/actions';
import { AppThunk } from '..';
import { appRoutes, navigateTo } from 'routing';
import { getPreregisterRequest } from './selectors';
import { registration } from '../registration';
import { preRegister } from './api/api.ors';
import { preRegister as preRegisterUkvi } from './api/api.ukvi';
import { getRegFlow } from 'core';

export const preregister = (): AppThunk => async (dispatch, getState) => {
  const reqOrs = getPreregisterRequest(getState());

  const reqUkvi: PreRegisterRequest = {
    ...reqOrs,
    districtExamId: reqOrs.plannedExamId,
    speakingDetails: { ...reqOrs.speakingDetails, isAutoBooking: false } as SpeakingDetails,
  };

  dispatch(registration.actions.clearRegistrationId());

  switch (getRegFlow()) {
    case 'ors':
      await dispatch(preRegister(reqOrs));
      break;
    case 'ukvi':
      await dispatch(preRegisterUkvi(reqUkvi));
      break;

    default:
      break;
  }
};

export const letReservationGo = (): AppThunk => async (dispatch, getState) => {
  // TODO: Extract data from existing registration and after state is cleared set up few basics
  dispatch(globalCleanRegistration());

  navigateTo(appRoutes.root);
};

/**
 * Sets up a worker that will every 15 seconds refresh reservation's state.
 * It has to be done this way, because working with current time and Redux store
 * requires different approach. And with this we will have a nice performance as well.
 * It must be invoked only once!
 */
export const setupRefreshStateWorker = (): AppThunk => async (dispatch, getState) => {
  const workerFunction = () => {
    const { timerActive } = getState().reservation;
    const curTime = DateTime.local().toISO();

    if (timerActive) {
      dispatch(slice.actions.refreshState(curTime));
    }
  };

  setInterval(workerFunction, 15000);
  workerFunction();
};

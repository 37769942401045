import { DateTime } from 'luxon';
import { userFriendlyTime } from '@britishcouncil/react-solas-ors3';
import { ExamFormat } from 'ors-api/ors2';
import { AvailableDistrictExamDto, AvailableSpeakingSlotDto } from 'ors-api/mod';

import { GTM } from 'core';
import { logger } from 'ors-utils';
import { converters } from 'common';
import { appRoutes, navigateTo } from 'routing';
import { reservation } from '../../reservation';
import { payment } from '../../payment';
import { registration } from '../../registration';
import { FullCandidatePersonalDetails } from '../../candidate';
import { rebookGoToReview } from '../../personalDetails/thunks/thunks.common';
import { verifyCandidateLastMarketingAnswers } from '../../api';
import { AppThunk, ThunkApi } from '../..';
import slice from '../bookTestSlice';
import { getRebookStatus, RebookStatus } from './thunks.ors';

/**
 * Thunks related to booking process both on '/book-test' and '/test-details' pages.
 * Covers rebooking process as well.
 */

export const saveBookingDetails =
  (exam: AvailableDistrictExamDto, selectedSpeakingSlot?: AvailableSpeakingSlotDto): AppThunk =>
  async (dispatch, getState) => {
    const speakingSlot = selectedSpeakingSlot || exam.defaultSpeakingSlot;
    const fee = exam.fee;
    const feeCurrency = exam.currencyCode;

    dispatch(slice.actions.setSelectedExam(exam));

    speakingSlot &&
      dispatch(
        registration.actions.setSelectedInCentreExam({
          plannedExam: exam,
          speaking: speakingSlot,
        })
      );

    GTM.trackRegistrationEvent('book-event', {
      centreName: exam.centreName,
      day: DateTime.fromISO(exam.examDate).toFormat('dd-MM-yyyy'),
      time: userFriendlyTime(exam.startTime),
      prodFormat: exam.examFormat === ExamFormat.CD ? 'CD' : 'PB',
      venueName: exam.venueName,
      currency: feeCurrency,
      quantity: 1,
      testPrice: fee,
      speakingFormat: converters.fromSpeakingFormat(speakingSlot?.speakingFormat).toGtmCode(),
      speakingDay: !speakingSlot ? '' : DateTime.fromISO(speakingSlot?.date).toFormat('yyyy-MM-dd'),
      speakingTime: userFriendlyTime(speakingSlot?.startTime),
    });
  };

export const bookExam =
  (
    exam: AvailableDistrictExamDto,
    handleIdentityFlow: () => void,
    selectedSpeakingSlot?: AvailableSpeakingSlotDto
  ): AppThunk =>
  async (dispatch, getState) => {
    const inChangeMode = getState().registration.changeMode;

    try {
      await dispatch(saveBookingDetails(exam, selectedSpeakingSlot));
      dispatch(payment.actions.clearDiscount());

      if (inChangeMode) {
        await Promise.all([
          dispatch(
            verifyCandidateLastMarketingAnswers({
              candidateId: getState().candidate.profile?.id ?? -1,
              awardingBodySystem: exam.awardingBodySystem ?? -1,
              answers: getState().candidate.answers.data?.answers,
            })
          ),
        ]);
        await dispatch(reservation.thunksCommon.preregister());
        if (!getState().reservation.processingErr && !getState().reservation.showExamTakenModal) {
          navigateTo(appRoutes.journey.review);
        }
        return;
      }

      return proceedToRightStep({ dispatch, getState }, handleIdentityFlow);
    } catch (ex: any) {
      // ex.name = 'Error occured in bookTest/thunks/bookExam';
      logger.logError(ex);
    }
  };

/** Decides where user should be navigated. Additionally sets up everything accordingly */
async function proceedToRightStep(
  { dispatch, getState }: ThunkApi,
  handleIdentityFlow: () => void
) {
  const rebooking = getState().registration.rebooking;
  const forWhom = getState().candidate.forWhom;
  const isLoggedIn = getState().auth.isLoggedIn;
  const isSomethingMissing = !isCandidateProfileOk(getState().candidate.profile);

  if (!rebooking || isSomethingMissing) {
    return isLoggedIn ? navigateTo(appRoutes.journey.personalDetails) : handleIdentityFlow();
  }

  await dispatch(reservation.thunksCommon.preregister());
  if (getState().reservation.processingErr) {
    // If there are pre-register errors, do not move anywhere from this page
    return;
  }

  if (rebooking && forWhom === 'someone-else') {
    return navigateTo(appRoutes.journey.childDetails);
  }

  const profile = getState().candidate.profile;
  const rebookStatus = await dispatch(getRebookStatus(profile));

  if (profile) {
    if (rebookStatus === RebookStatus.ExistingTestTaker) {
      return dispatch(rebookGoToReview(profile));
    }
    if (rebookStatus === RebookStatus.IdInvalid || rebookStatus === RebookStatus.IdUploadRequired) {
      return navigateTo(appRoutes.journey.idDetails);
    }
  }
  return isLoggedIn ? navigateTo(appRoutes.journey.personalDetails) : handleIdentityFlow();
}

// Checks mandatory fields that could be missing for some reason
function isCandidateProfileOk(profile?: FullCandidatePersonalDetails): boolean {
  return (
    !!profile?.countryId &&
    !!profile?.addressLine1 &&
    !!profile?.town &&
    !!profile?.gender &&
    !!profile?.mobile
  );
}

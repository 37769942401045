import {
  ApiResponseOfTermsAndConditionsDto,
  AwardingBodySystem,
  awardingBodySystemsClient,
  countriesClient,
  CountryDto,
  ExamCountryDto,
  MarketingQuestionDto,
  termsAndConditionsClient,
} from 'ors-api/mod';
import { productsClient } from 'ors-api/iol';
import { extractApiErrors } from 'ors-ui';

import { logger } from 'ors-utils';
import { api } from '../shared/api';
import { Status, storageData } from '.';
import { mapTranslatedCountryNames } from 'store/utils';
import { BaseTranslatedQueryParams } from 'store/shared';
import { mapIolMq } from './marketing/utils';

const searchSelectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExamCountries: builder.query<
      ExamCountryDto[],
      {
        awardingBodySystem: AwardingBodySystem;
        organisationId?: number;
      } & BaseTranslatedQueryParams
    >({
      queryFn: async ({ awardingBodySystem, productFamilyId }) => {
        if (!productFamilyId) return Promise.resolve([] as any);

        const countries = await awardingBodySystemsClient.getAllCountries(
          awardingBodySystem,
          productFamilyId
        );

        const examCountriesList = await countriesClient.getExamCountries(productFamilyId);

        return { data: mapTranslatedCountryNames(examCountriesList.data, countries.data) };
      },
    }),
    getCountries: builder.query<
      CountryDto[],
      {
        awardingBodySystem?: AwardingBodySystem;
        productId?: string;
      } & BaseTranslatedQueryParams
    >({
      query: ({ awardingBodySystem, productId, productFamilyId }) => {
        if (awardingBodySystem && productFamilyId) {
          return awardingBodySystemsClient.getAllCountries(awardingBodySystem, productFamilyId);
        } else if (productId) {
          return productsClient.getCountries(productId);
        }

        return Promise.resolve([] as any);
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(storageData.actions.countriesStatus({ status: Status.Pending, error: undefined }));

        try {
          const { data } = await queryFulfilled;
          dispatch(storageData.actions.countriesStatus({ status: Status.Idle, data }));
        } catch (error) {
          logger.logError(error as Error);
          dispatch(
            storageData.actions.countriesStatus({
              status: Status.Idle,
              error: extractApiErrors(error),
            })
          );
        }
      },
    }),
    getMarketingQuestions: builder.query<
      MarketingQuestionDto[],
      {
        awardingBodySystem?: AwardingBodySystem;
        productId?: string;
        fetch?: boolean;
      } & BaseTranslatedQueryParams
    >({
      queryFn: async ({ awardingBodySystem, productId, productFamilyId, fetch = true }) => {
        if (!fetch) {
          return Promise.resolve([] as any);
        }

        try {
          if (awardingBodySystem && productFamilyId) {
            const response = await awardingBodySystemsClient.getAllMarketingQuestions(
              awardingBodySystem,
              productFamilyId
            );

            return { data: response.data };
          } else if (productId) {
            const response = await productsClient.getMarketingQuestions(productId);
            return { data: mapIolMq(response?.data) };
          }
        } catch (err) {
          const error = extractApiErrors(err);
          return { error: error };
        }
      },
    }),
    getTerms: builder.query<
      ApiResponseOfTermsAndConditionsDto,
      {
        shortCode?: string;
        organisationCountryId?: number;
        isLifeSkills?: boolean;
        version?: string | null;
      } & BaseTranslatedQueryParams
    >({
      query: async ({
        shortCode,
        productFamilyId,
        organisationCountryId,
        isLifeSkills,
        version,
      }) => {
        if (!shortCode || !productFamilyId) {
          return Promise.resolve({} as any);
        }

        return termsAndConditionsClient.getTermsAndConditions(shortCode, productFamilyId, {
          organisationCountryId: Number(organisationCountryId) || undefined,
          version: Number(version) || undefined,
          isLifeSkills,
        });
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          logger.logError(error as Error);
        }
      },
    }),
  }),
});

export const {
  useGetExamCountriesQuery,
  useGetCountriesQuery,
  useGetMarketingQuestionsQuery,
  useGetTermsQuery,
} = searchSelectApi;

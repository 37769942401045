import { ApiError } from '@britishcouncil/react-common';
import { registrationClient } from 'ors-api/iol';
import { AwardingBodySystem, Candidate, candidateClient } from 'ors-api/ors2';
import {
  candidateClient as candidateClientUkvi,
  termsAndConditionsClient as termsAndConditionsClientUkvi,
} from 'ors-api/ukvi';
import { candidatesEnglishOnlineClient, MarketingQuestionDto } from 'ors-api/mod';
import {
  ChildAfterTest,
  ChildDetailsForm,
  ApiErrorsList,
  getValidatedMobileNumber,
  preparePickups,
} from 'ors-ui';
import { logger, TermsShortCodeEnum } from 'ors-utils';

import { getRegFlow, GTM } from 'core';
import { appRoutes, navigateTo } from 'routing';
import { persistTempAuth } from '../../authSlice';
import { reservation } from '../../reservation';
import { CandidatePersonalDetails } from '../../registration';
import { candidate, FullCandidatePersonalDetails } from '../../candidate';
import { searchSelect } from '../../searchSelect';
import { getRebookStatus, RebookStatus } from '../../bookTest/thunks/thunks.ors';
import { getRebookStatusIol } from '../../bookTest/thunks/thunks.iol';
import {
  saveCandidateDetails,
  addCandidateDetails,
  saveSomeoneElseDetailsForBooking,
  verifyCandidateLastMarketingAnswers,
  saveCandidateMissingDetails,
} from '../../api';
import { AppThunk } from '../..';
import slice from '../personalDetailsSlice';
import { createCandidateAccount, getTermsAndConditionsNewestVersionNumber } from '../api/api.ors';
import { getTermsAndConditionsNewestVersionNumber as getTermsAndConditionsNewestVersionNumberUkvi } from '../api/api.ukvi';
import { childAfterExamTypes, PersonalDetailsForm, Titles } from '../models';
import {
  staticProfilePart,
  getTitleFromGender,
  updateGTM,
  candidateDetailsReq,
  getGenderId,
} from './thunks.helpers';
import { rebookGoToReviewIol, saveNationalityOnRebook } from './thunks.iol';
import { loadOrsData } from './thunks.ors';
import { IeltsReadyRegFormValues } from 'pages/IeltsReadyRegForm/useIeltsReadyRegForm';
import { noTargetScoreSelectedValue } from 'pages/IeltsReadyRegForm/utils';
import { saveCandidateIdDetails } from 'store/idDetails/api/api.ors';
import { saveCandidateIdDetailsUkvi } from 'store/idDetails/api/api.ukvi';

const globalTermsAndConditionsVersion = 1;
/** A familyRelationshipId for the option 'Other' */
const defaultFamilyRelationshipId = 1000;

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const isLoggedIn = getState().auth.isLoggedIn;
  const centreId = getState().registration.inCentreExam?.centreId;
  const arg = {
    centreId,
    isLoggedIn,
  };

  dispatch(loadRegistrations());

  switch (getRegFlow()) {
    case 'ors':
      dispatch(loadOrsData(arg));
      break;
    case 'ukvi':
      /*  Decided to use ORS endpoints to feed Personal Details page.
          Use "loadUkviData" from "./thunks.ukvi" when this decision change. */
      dispatch(loadOrsData(arg));
      break;

    default:
      break;
  }
};

export const loadRegistrations = (): AppThunk => async (dispatch, getState) => {
  const { allowEdit } = getState().personalDetails;
  const candidateId = getState().candidate.profile?.id;

  if (!candidateId) return;

  if (!allowEdit) {
    dispatch(slice.actions.setProcessing(true));
    await Promise.all([
      candidateClient.getCandidateRegistrations(candidateId),
      candidateClientUkvi.getCandidateRegistrations(candidateId),
      registrationClient.getTestTakerRegistrations(),
    ])
      .then(([ors, ukvi, iol]) => {
        const regsOrs = [...(ors.data.past || []), ...(ors.data.upcoming || [])];
        const regsUkvi = [...(ukvi.data.past || []), ...(ukvi.data.upcoming || [])];
        const regsIol = [...(iol.data.past || []), ...(iol.data.upcoming || [])];
        [...regsOrs, ...regsUkvi, ...regsIol].length === 0 &&
          dispatch(slice.actions.setAllowEdit(true));
      })
      .finally(() => dispatch(slice.actions.setProcessing(false)));
  }
};

export const proceed =
  (
    form: PersonalDetailsForm,
    mobileInputId: string,
    marketingQuestions: MarketingQuestionDto[] = []
  ): AppThunk =>
  async (dispatch, getState) => {
    const validatedMobileNumber = getValidatedMobileNumber(mobileInputId, form.mobile);
    form = { ...form, mobile: validatedMobileNumber };

    const isLoggedIn = getState().auth.isLoggedIn;
    const candidateProfile = getState().candidate.profile;
    const { dependants, genders, allowEdit } = getState().personalDetails;
    const orgData = getState().organisationCountry.organisationDetails.data;
    const needToSupplyMissingDetails = getState().candidate.profile?.needToSupplyMissingDetails;

    const isIol = searchSelect.selectors.isIol();
    const isUkvi = searchSelect.selectors.isUkvi();
    const accountTermsShortCode =
      orgData?.accountTermsAndConditions?.shortCode ??
      TermsShortCodeEnum.Global_IELTS_Account_Registration;

    const allCountries = getState().storageData.countriesMod.data;

    const countryOfResidenceId = Number(form.countryId);
    const countryOfResidenceName =
      allCountries?.find((c) => c.id === countryOfResidenceId)?.name || '';

    let mainCandidateId = candidateProfile?.id || 0;
    let mainCandidateEmail = candidateProfile?.email || '';
    let newCandidateProfile: Partial<CandidatePersonalDetails> = {};

    const getTermsAndConditionsVersion = async () => {
      const { payload: version } = await dispatch(
        isUkvi
          ? getTermsAndConditionsNewestVersionNumberUkvi(accountTermsShortCode)
          : getTermsAndConditionsNewestVersionNumber(accountTermsShortCode)
      );
      const termsAndConditionsVersion =
        typeof version === 'number'
          ? version
          : orgData?.accountTermsAndConditions?.version ?? globalTermsAndConditionsVersion;

      return termsAndConditionsVersion;
    };

    dispatch(slice.actions.setProcessError(undefined));
    dispatch(slice.actions.setProcessing(true));

    if (!isLoggedIn) {
      /** Get terms and conditions short code and version */
      const termsAndConditionsVersion = await getTermsAndConditionsVersion();

      /** Register new candidate */
      const { payload } = await dispatch(
        createCandidateAccount({
          dob: form.dateOfBirth,
          marketingPreferences: form.marketingPrefs,
          confirmEmail: form.confirmEmail,
          email: form.email,
          firstName: form.firstName,
          surname: form.lastName,
          password: form.password,
          acceptedTermsAndConditionsVersion: termsAndConditionsVersion,
          acceptedTermsAndConditionsShortCode: accountTermsShortCode,
          isIeltsReadyMember: false,
        })
      );

      mainCandidateEmail = form.email;

      if (payload && 'id' in payload) {
        mainCandidateId = payload.id;

        newCandidateProfile = {
          marketingPreferences: form.marketingPrefs,
          email: form.email,
          firstName: form.firstName,
          surname: form.lastName,
        };

        payload.tokenResponse && dispatch(persistTempAuth(payload.tokenResponse));
        payload.id && dispatch(slice.actions.setAllowEdit(true));

        /** Accept UKVI account terms */
        await termsAndConditionsClientUkvi.addConsentToAccountTermsAndConditions({
          shortCode: accountTermsShortCode,
          productGroupShortCode: 'IELTSUKVI',
          subSystemName: 'B2C',
        });
      } else {
        /* Stop processing when account creation fails */
        return dispatch(slice.actions.setProcessError(payload));
      }
    }

    if (needToSupplyMissingDetails) {
      const termsAndConditionsVersion = await getTermsAndConditionsVersion();

      await dispatch(
        saveCandidateMissingDetails({
          firstName: form.firstName,
          dob: form.dateOfBirth,
          surname: form.lastName,
          marketingPreferences: form.marketingPrefs,
          acceptedTermsAndConditionsShortCode: accountTermsShortCode,
          acceptedTermsAndConditionsVersion: termsAndConditionsVersion,
          isIeltsReadyMember: false,
        })
      );

      const updatedDetailsExtended = (await candidateClient.getCandidateDetailsExtended()).data;

      mainCandidateId = updatedDetailsExtended.id;
      mainCandidateEmail = updatedDetailsExtended.email || '';

      /** Accept UKVI account terms */
      await termsAndConditionsClientUkvi.addConsentToAccountTermsAndConditions({
        shortCode: accountTermsShortCode,
        subSystemName: 'B2C',
        productGroupShortCode: 'IELTSUKVI',
      });
    }

    dispatch(
      candidate.thunks.saveProfile({
        ...candidateProfile,
        ...newCandidateProfile,
        ...staticProfilePart,
        id: mainCandidateId,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        addressLine3: form.addressLine3,
        mobile: form.mobile,
        countryId: countryOfResidenceId,
        country: countryOfResidenceName,
        postCode: form.postcode,
        state: form.state,
        town: form.town,
        firstName: form.firstName,
        surname: form.lastName,
        dob: form.dateOfBirth,
        gender: form.gender,
        genderId: getGenderId(genders, form?.gender),
        titleId: getTitleFromGender(form.gender),
        smsNotificationsAllowed: form.smsNotificationsAllowed,
        marketingPreferences: form.marketingPrefs,
        isMinor: false,
        email: mainCandidateEmail,
      })
    );

    const detailsRequest = candidateDetailsReq(
      form,
      mainCandidateId,
      getState().candidate.profile?.addressId
    );

    const action = allowEdit
      ? saveCandidateDetails(detailsRequest)
      : addCandidateDetails(detailsRequest);

    const { meta, payload } = await dispatch(action);
    if (meta.requestStatus === 'rejected') {
      return dispatch(slice.actions.setProcessError(payload as ApiErrorsList));
    }

    updateGTM(form, countryOfResidenceName);

    isIol
      ? await dispatch(reservation.thunksIol.preregisterIol())
      : await dispatch(reservation.thunksCommon.preregister());

    /* Stop processing when pre-registraion fails */
    const { processingErr, showExamTakenModal } = getState().reservation;
    if (processingErr || showExamTakenModal) return dispatch(slice.actions.setProcessing(false));

    await dispatch(navigate(form, isIol, dependants.data, marketingQuestions));

    dispatch(slice.actions.setProcessing(false));
  };

export const proceedChild =
  (form: ChildDetailsForm): AppThunk =>
  async (dispatch, getState) => {
    const parentDetails = getState().personalDetails?.parentDetails;
    const childProfile = getState().candidate.ttProfile;
    const reservationGuid = getState().reservation.reservationGuid;

    try {
      await dispatch(candidate.thunks.loadProfile());
      const parentProfile = getState().candidate.profile;
      let mainCandidateId = parentProfile?.id || 0;

      dispatch(slice.actions.setProcessError(undefined));
      dispatch(slice.actions.setProcessing(true));

      const childTitle = getTitleFromGender(form.gender);
      const { payload, meta } = await dispatch(
        saveSomeoneElseDetailsForBooking({
          id: childProfile?.id || 0,
          familyRelationshipId: defaultFamilyRelationshipId,
          firstName: form.firstName,
          surname: form.lastName,
          dob: form.dateOfBirth,
          parentCandidateId: mainCandidateId,
          addressLine1: parentProfile?.addressLine1,
          addressLine2: parentProfile?.addressLine2,
          addressLine3: parentProfile?.addressLine3,
          addressLine4: parentProfile?.addressLine4,
          mobile: parentProfile?.mobile,
          countryId: parentProfile?.countryId,
          postCode: parentProfile?.postCode,
          town: parentProfile?.town,
          state: parentProfile?.state,
          titleId: childTitle,
          gender: form.gender,
          registrationReservationGuid: reservationGuid,
        })
      );

      const candidateDetails = payload && 'id' in payload ? payload : undefined;
      if (!candidateDetails) {
        const err =
          meta.requestStatus === 'rejected'
            ? (payload as ApiErrorsList)
            : { status: ApiError.Unknown };

        return dispatch(slice.actions.setProcessError(err));
      }

      const pickups =
        form.childAfterTest === ChildAfterTest.SomeoneElseWillPick ? form.pickups : [];

      dispatch(
        candidate.actions.childProfileSet({
          profile: {
            ...candidateDetails,
            ...staticProfilePart,
            titleId: childTitle,
            notExpires: candidateDetails.idExpiry === null,
          },
          childAfterTest: form.childAfterTest,
          pickups: preparePickups(
            form.childAfterTest,
            form.pickups,
            parentDetails.data ?? parentProfile
          ),
        })
      );

      GTM.update({
        childAfterExam: childAfterExamTypes[form.childAfterTest],
        gender: form.gender,
        title: childTitle === Titles.MALE ? 'Mr' : 'Ms',
        noOfGuardians: pickups.length,
      });

      dispatch(navigateChild());
    } catch (ex: any) {
      const err = ex?.response?.data || { message: 'Error occured' };
      dispatch(slice.actions.setProcessError(err));
      logger.logError(ex);
    }

    dispatch(slice.actions.setProcessing(false));
  };

/** Decide where to go when exam is pre-registered */
const navigate =
  (
    form: PersonalDetailsForm,
    isIol: boolean,
    dependants?: Candidate[],
    marketingQuestions: MarketingQuestionDto[] = []
  ): AppThunk =>
  async (dispatch, getState) => {
    const { profile } = getState().candidate;
    const inChangeMode = getState().registration.changeMode;

    const { forWhom, marketingPrefs, smsNotificationsAllowed } = form;

    if (inChangeMode) {
      dispatch(slice.actions.setProcessing(false));
      return navigateTo(appRoutes.journey.review);
    }

    if (forWhom === 'myself') {
      const rebookStatus = isIol
        ? await dispatch(getRebookStatusIol(profile))
        : await dispatch(getRebookStatus(profile));
      if (rebookStatus === RebookStatus.ExistingTestTaker && profile) {
        return dispatch(
          isIol
            ? rebookGoToReviewIol(profile, marketingQuestions)
            : rebookGoToReview(profile, marketingQuestions)
        );
      }
      dispatch(slice.actions.setProcessing(false));
      navigateTo(appRoutes.journey.idDetails);
    } else {
      if (forWhom !== 'someone-else' && dependants && dependants.find((d) => d.id === forWhom)) {
        const someoneElse = dependants.find((d) => d.id === forWhom);
        const child = {
          ...someoneElse!,
          marketingPreferences: marketingPrefs,
          smsNotificationsAllowed: smsNotificationsAllowed,
          isMinor: true,
          ...staticProfilePart,
        };
        await dispatch(candidate.actions.childProfileSet({ profile: child }));
        await dispatch(getRebookStatus(child));
      } else {
        dispatch(candidate.actions.childProfileSet({}));
      }

      dispatch(slice.actions.setProcessing(false));
      navigateTo(appRoutes.journey.childDetails);
    }
  };

/** Decide where to go when exam is pre-registered for child */
const navigateChild = (): AppThunk => async (dispatch, getState) => {
  const { ttProfile } = getState().candidate;
  const inChangeMode = getState().registration.changeMode;

  const rebookStatus = await dispatch(getRebookStatus(ttProfile));

  if (inChangeMode) {
    dispatch(slice.actions.setProcessing(false));
    return navigateTo(appRoutes.journey.review);
  }

  if (rebookStatus === RebookStatus.ExistingTestTaker && ttProfile) {
    return dispatch(rebookGoToReview(ttProfile));
  }

  navigateTo(appRoutes.journey.idDetails);
};

/** Go directly to review and do necessary CJ API calls in the background. */
export const rebookGoToReview =
  (
    profile: FullCandidatePersonalDetails,
    marketingQuestions: MarketingQuestionDto[] = []
  ): AppThunk =>
  async (dispatch, getState) => {
    const { inCentreExam } = getState().registration;
    const reservationGuid = getState().reservation.reservationGuid;
    const awardingBodySystem = getState().bookTest.selectedExam?.awardingBodySystem;
    const answers = getState().candidate.answers;
    const isUkvi = searchSelect.selectors.isUkvi();

    if (inCentreExam && reservationGuid) {
      /* Save candidate ID as on the "/id-details" page. */
      const saveIdArgs = {
        organisationCountryId: inCentreExam?.organisationCountryId ?? -1,
        id: profile.id,
        idExpiry: profile.idExpiry,
        districtExamId: inCentreExam?.plannedExamId ?? -1,
        idNumber: profile.idNumber,
        notExpires: !!profile.notExpires,
        issuingAuthority: profile.issuingAuthority,
        idTypeId: profile.idTypeId,
        registrationReservationGuid: reservationGuid,
      };

      const saveId = dispatch(
        isUkvi ? saveCandidateIdDetailsUkvi(saveIdArgs) : saveCandidateIdDetails(saveIdArgs)
      );

      await Promise.all([
        saveId,
        dispatch(
          verifyCandidateLastMarketingAnswers({
            candidateId: profile.id,
            awardingBodySystem: awardingBodySystem ?? AwardingBodySystem.CMDS,
            answers: answers.data?.answers,
          })
        ),
      ]);

      /** Save candidate's nationality. */
      dispatch(saveNationalityOnRebook(marketingQuestions));

      dispatch(slice.actions.setProcessing(false));
      return navigateTo(appRoutes.journey.review);
    }

    return dispatch(slice.actions.setProcessError({ status: ApiError.Unknown }));
  };

export const createIeltsReadyAccount =
  (formValues: IeltsReadyRegFormValues): AppThunk =>
  async (dispatch, getState) => {
    const allCountries = getState().storageData.countriesMod.data;
    const needToSupplyMissingDetails = !!getState().candidate.profile?.needToSupplyMissingDetails;
    const countryOfResidenceId = Number(formValues.countryId);
    const isUkvi = searchSelect.selectors.isUkvi();
    const orgData = getState().organisationCountry.organisationDetails.data;
    const accountTermsShortCode =
      orgData?.accountTermsAndConditions?.shortCode ??
      TermsShortCodeEnum.Global_IELTS_Account_Registration;
    const countryOfResidenceName =
      allCountries?.find((c) => c.id === countryOfResidenceId)?.name || '';

    let mainCandidateId = 0;
    let mainCandidateEmail;

    dispatch(slice.actions.setProcessing(true));

    const getTermsAndConditionsVersion = async () => {
      const { payload: version } = await dispatch(
        isUkvi
          ? getTermsAndConditionsNewestVersionNumberUkvi(accountTermsShortCode)
          : getTermsAndConditionsNewestVersionNumber(accountTermsShortCode)
      );
      const termsAndConditionsVersion =
        typeof version === 'number'
          ? version
          : orgData?.accountTermsAndConditions?.version ?? globalTermsAndConditionsVersion;

      return termsAndConditionsVersion;
    };

    if (needToSupplyMissingDetails) {
      const termsAndConditionsVersion = await getTermsAndConditionsVersion();

      await dispatch(
        saveCandidateMissingDetails({
          firstName: formValues.firstName,
          dob: formValues.dateOfBirth,
          surname: formValues.lastName,
          marketingPreferences: formValues.marketingPrefs,
          acceptedTermsAndConditionsShortCode: accountTermsShortCode,
          acceptedTermsAndConditionsVersion: termsAndConditionsVersion,
          isIeltsReadyMember: true,
        })
      );

      const updatedDetailsExtended = (await candidateClient.getCandidateDetailsExtended()).data;

      mainCandidateId = updatedDetailsExtended.id;
      mainCandidateEmail = updatedDetailsExtended.email || '';

      /** Accept UKVI account terms */
      await termsAndConditionsClientUkvi.addConsentToAccountTermsAndConditions({
        shortCode: accountTermsShortCode,
        subSystemName: 'B2C',
        productGroupShortCode: 'IELTSUKVI',
      });
    } else {
      const { payload } = await dispatch(
        createCandidateAccount({
          dob: formValues.dateOfBirth,
          marketingPreferences: formValues.marketingPrefs,
          confirmEmail: formValues.confirmEmail,
          email: formValues.email,
          firstName: formValues.firstName,
          surname: formValues.lastName,
          password: formValues.password,
          acceptedTermsAndConditionsVersion: globalTermsAndConditionsVersion,
          acceptedTermsAndConditionsShortCode: TermsShortCodeEnum.Global_IELTS_Account_Registration,
          isIeltsReadyMember: true,
        })
      );

      if (payload && 'id' in payload) {
        payload.tokenResponse && dispatch(persistTempAuth(payload.tokenResponse));
        mainCandidateId = payload.id;
      } else {
        return dispatch(slice.actions.setProcessError(payload));
      }
    }

    try {
      await candidatesEnglishOnlineClient.createEnglishOnlineAccount({
        countryId: countryOfResidenceId,
        expectedTargetScore:
          formValues.expectedTargetScore !== noTargetScoreSelectedValue
            ? Number(formValues.expectedTargetScore)
            : undefined,
      });
    } catch (error) {
      return dispatch(slice.actions.setProcessError());
    }

    dispatch(
      candidate.thunks.saveProfile({
        marketingPreferences: formValues.marketingPrefs,
        email: needToSupplyMissingDetails ? mainCandidateEmail : formValues.email,
        firstName: formValues.firstName,
        surname: formValues.lastName,
        id: mainCandidateId,
        isAwaitingParent: false,
        needToUpdateMarketingPreferences: false,
        needToVerifyDraftProcess: false,
        needToSupplyMissingDetails: false,
        countryId: countryOfResidenceId,
        country: countryOfResidenceName,
        isMinor: false,
        dob: formValues.dateOfBirth,
        smsNotificationsAllowed: false,
        hasEnglishOnlineAccount: false,
      })
    );

    dispatch(slice.actions.setProcessing(false));
  };

import { FC, useState, useEffect, useCallback } from 'react';
import { omitBy, keys } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { BcCheckbox, BcRadio } from '@britishcouncil/react-solas-ors3';
import { ExamFormat, TimeTableSession } from 'ors-api/ors2';
import { BatchExamFormat } from 'ors-api/mod';
import { Modal } from 'ors-ui';

import { GTM, useSelector } from 'core';
import { ExamFilterBase, searchSelect } from 'store/searchSelect';
import { trackFilters } from './filterHelper';

type TimeTableSessionsDictionary = Record<TimeTableSession, boolean>;

const initialFilter: ExamFilterBase = {};

const initialTimeTableSessionsDictionary: TimeTableSessionsDictionary = {
  [TimeTableSession.A]: true,
  [TimeTableSession.P]: true,
  [TimeTableSession.E]: true,
  [TimeTableSession.D]: false,
  [TimeTableSession.X]: false,
};

interface Props {
  show: boolean;
  onExit: () => void;
  onConfirm: (filterSelection: ExamFilterBase) => void;
}

export const FilterModal: FC<Props> = ({ show, onExit, onConfirm }) => {
  const { t } = useTranslation();
  const { needSpecialReqs, filter: stateFilter } = useSelector((state) => state.searchSelect);
  const invitationExamFormat = useSelector(
    (state) => state.invitation.invitationContext?.data?.examFormat
  );
  const invitationLrwSessions = useSelector(
    (state) => state.invitation.invitationContext?.data?.lrwSessions
  );
  const isUkvi = useSelector(searchSelect.selectors.isUkvi);

  const [filterFromModal, setFilterFromModal] = useState<ExamFilterBase>(initialFilter);
  const [timeTableSessionsDictionary, setTimeTableSessions] = useState<TimeTableSessionsDictionary>(
    initialTimeTableSessionsDictionary
  );
  const handleUpdateTimeTableSessionCheck = useCallback(
    (timeTableSession: TimeTableSession) => (value: boolean) =>
      setTimeTableSessions({
        ...timeTableSessionsDictionary,
        [timeTableSession]: value,
      }),
    [timeTableSessionsDictionary]
  );

  const searchResults = useCallback(() => {
    const timeTableSessions = keys(omitBy(timeTableSessionsDictionary, (value) => !value)).map(
      (timeTableSession) => Number(timeTableSession) as unknown as TimeTableSession
    );

    trackFilters(filterFromModal, timeTableSessions);

    onConfirm({
      ...filterFromModal,
      timeTableSessions:
        timeTableSessions.length &&
        timeTableSessions.length !== Object.keys(TimeTableSession).length
          ? timeTableSessions
          : undefined,
    });
  }, [timeTableSessionsDictionary, filterFromModal, onConfirm]);

  useEffect(() => {
    if (!stateFilter) {
      return;
    }

    const examFormat = needSpecialReqs
      ? ExamFormat.PB
      : stateFilter.examFormat ?? initialFilter.examFormat;

    const timeTableSessions = { ...initialTimeTableSessionsDictionary };
    if (stateFilter.timeTableSessions && stateFilter.timeTableSessions.length) {
      for (const timeTableSession in initialTimeTableSessionsDictionary) {
        timeTableSessions[timeTableSession as unknown as TimeTableSession] =
          !!stateFilter.timeTableSessions.find((session) => session === Number(timeTableSession));
      }
    }

    setFilterFromModal({ ...initialFilter, examFormat });
    setTimeTableSessions(timeTableSessions);
  }, [stateFilter, needSpecialReqs]);

  const disableExamFormatSelection =
    !!invitationExamFormat && invitationExamFormat !== BatchExamFormat.Both;
  const disableSessionsSelection = invitationLrwSessions && invitationLrwSessions.length > 0;

  return (
    <Modal
      show={show}
      onExit={onExit}
      title={t('APPB2C.common.bookTest.filters.modal.title')}
      subtitle={t('APPB2C.common.bookTest.filters.modal.subtitle')}
      confirmLabel={t('APPB2C.common.bookTest.filters.modal.cta')}
      onConfirm={searchResults}
      onHide={() => GTM.trackModal('Hide', 'filters-modal')}
      onShow={() => GTM.trackModal('Show', 'filters-modal')}
    >
      {!needSpecialReqs && (
        <>
          <OptionTitle>{t('APPB2C.common.bookTest.filters.modal.whichFormatTest')}?</OptionTitle>
          <StyledFormGroup className="form-group">
            <BcRadio
              background="gray"
              checkedValue={ExamFormat.PB}
              value={filterFromModal.examFormat}
              name="paperBased"
              data-testid="paper-based"
              disabled={disableExamFormatSelection}
              onChange={() => setFilterFromModal({ ...filterFromModal, examFormat: ExamFormat.PB })}
            >
              <ComplexOptionText>
                <OptionText>{t('APPB2C.common.bookTest.results.pb')}</OptionText>
                <OptionSuplementText>
                  ({t('APPB2C.common.bookTest.filters.modal.resultsIn', { days: '13' })})
                </OptionSuplementText>
              </ComplexOptionText>
            </BcRadio>
            <BcRadio
              background="gray"
              checkedValue={ExamFormat.CD}
              value={filterFromModal.examFormat}
              name="computerDelivered"
              data-testid="computer-delivered"
              disabled={disableExamFormatSelection}
              onChange={() => setFilterFromModal({ ...filterFromModal, examFormat: ExamFormat.CD })}
            >
              <ComplexOptionText>
                <OptionText>{t('APPB2C.common.bookTest.results.cd')}</OptionText>
                <OptionSuplementText>
                  ({t('APPB2C.common.bookTest.filters.modal.resultsIn', { days: isUkvi ? '3-5' : '1-5' })})
                </OptionSuplementText>
              </ComplexOptionText>
            </BcRadio>
            <BcRadio
              background="gray"
              value={filterFromModal.examFormat}
              name="bothExamFormat"
              data-testid="both-exam-format"
              disabled={disableExamFormatSelection}
              onChange={() => setFilterFromModal({ ...filterFromModal, examFormat: undefined })}
            >
              <OptionText>{t('APPB2C.common.bookTest.filters.modal.iDontMind')}</OptionText>
            </BcRadio>
          </StyledFormGroup>
        </>
      )}
      {(!filterFromModal.examFormat || filterFromModal.examFormat === ExamFormat.CD) && (
        <>
          <OptionTitle>{t('APPB2C.common.bookTest.filters.modal.whichTimeOfDay')}?</OptionTitle>
          <StyledFormGroup className="form-group">
            <BcCheckbox
              background="gray"
              checked={timeTableSessionsDictionary[TimeTableSession.A]}
              handleChange={handleUpdateTimeTableSessionCheck(TimeTableSession.A)}
              id="morning"
              disabled={disableSessionsSelection}
            >
              {t('APPB2C.common.bookTest.filters.morning')}
            </BcCheckbox>
            <BcCheckbox
              background="gray"
              checked={timeTableSessionsDictionary[TimeTableSession.P]}
              handleChange={handleUpdateTimeTableSessionCheck(TimeTableSession.P)}
              id="afternoon"
              disabled={disableSessionsSelection}
            >
              {t('APPB2C.common.bookTest.filters.afternoon')}
            </BcCheckbox>
            <BcCheckbox
              background="gray"
              checked={timeTableSessionsDictionary[TimeTableSession.E]}
              handleChange={handleUpdateTimeTableSessionCheck(TimeTableSession.E)}
              id="evening"
              disabled={disableSessionsSelection}
            >
              {t('APPB2C.common.bookTest.filters.evening')}
            </BcCheckbox>
          </StyledFormGroup>
        </>
      )}
    </Modal>
  );
};

const OptionTitle = styled.p`
  color: #000;
`;

const ComplexOptionText = styled.div`
  margin-top: -10px;
  margin-bottom: -10px;
`;

const OptionText = styled.div`
  color: #000;
  font-size: 0.9em;
`;

const OptionSuplementText = styled.div`
  font-size: 0.8em;
  line-height: 1.2em;
`;

const StyledFormGroup = styled.div`
  .radio,
  .checkbox {
    margin: 8px 0;
  }
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import {
  clearErrors,
  examTypeChanged,
  globalCleanRegistration,
  globalLogOut,
} from '../_common/actions';
import { ReservationState } from '.';
import { extraReducersOrs } from './extraReducers/extraReducers.ors';
import { extraReducersUkvi } from './extraReducers/extraReducers.ukvi';
import { extraReducersIol } from './extraReducers/extraReducers.iol';

const sliceName = 'reservation';

export const initialState: ReservationState = {
  timerActive: false,
  showNearEndNotification: false,
  showTimeUpModal: false,
  processing: false,
  showExamTakenModal: false,
};

/**
 * Persisted slice responsible for reserving selected slots and registration
 * for a given time
 */
const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    refreshState(state, action: PayloadAction<string>) {
      const curTime = DateTime.fromISO(action.payload);

      if (state.deadline) {
        state.showNearEndNotification =
          !!state.nearEndTime &&
          DateTime.fromISO(state.deadline) >= curTime &&
          DateTime.fromISO(state.nearEndTime) <= curTime;

        const minutesLeft = state.nearEndTime
          ? Math.ceil(DateTime.fromISO(state.deadline).diff(curTime, 'minutes').minutes)
          : undefined;
        state.minutesLeft = !minutesLeft || minutesLeft < 0 ? undefined : minutesLeft;

        state.showTimeUpModal = curTime > DateTime.fromISO(state.deadline);
      }
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(globalLogOut, () => initialState);
    builder.addCase(globalCleanRegistration, () => initialState);
    builder.addCase(examTypeChanged, () => initialState);
    builder.addCase(clearErrors, (state) => {
      state.processingErr = undefined;
    });

    extraReducersOrs(builder);
    extraReducersUkvi(builder);
    extraReducersIol(builder);
  },
});

export default slice;

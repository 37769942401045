import { DateTime } from 'luxon';
import { max, flatten } from 'lodash-es';
import { ExamFormat } from 'ors-api/ukvi';
import { ExamDistrictDto } from 'ors-api/mod';

import { Location, DatesRange, ExtSpecialArrangement } from './';

export function getDaysOffset(specialReqs?: ExtSpecialArrangement[]) {
  const selectedSpecialReqs = specialReqs?.filter((e) => e.checked) || [];
  const selectedDaysToNearestPbExam = flatten(
    selectedSpecialReqs.map((e) =>
      e.specialArrangementExamFormats?.filter((f) => f.examFormat === (ExamFormat.PB as any))
    )
  ).map((e) => e?.daysToNearestExam);

  const maxPbExam = max(selectedDaysToNearestPbExam);
  const maxCdExam = max(
    flatten(
      selectedSpecialReqs.map((e) =>
        e.specialArrangementExamFormats?.filter((f) => f.examFormat === (ExamFormat.CD as any))
      )
    ).map((e) => e?.daysToNearestExam)
  );

  return {
    daysToNearestPbExam: maxPbExam || 0,
    daysToNearestCdExam: maxCdExam || 0,
  };
}

export function formatDatesRange(
  dates: [Date, Date] | undefined,
  availableDates: string[] | undefined
): DatesRange | undefined {
  const isoDates = dates
    ? (dates.map((e) => DateTime.fromJSDate(e).toISODate()) as DatesRange)
    : undefined;

  const availableDatesCount = availableDates?.length;
  if ((isoDates && isoDates[1]) || !availableDates || !availableDatesCount) {
    return isoDates || ['', ''];
  }

  const lastAvailableDate = availableDates[availableDatesCount - 1];
  const firstDate = (isoDates && isoDates[0]) || '';
  const lastDate = firstDate ? DateTime.fromISO(lastAvailableDate).toISODate() : '';

  return !firstDate && !lastDate ? undefined : [firstDate, lastDate || ''];
}

export function isAnotherSpecialReqSelected(specialReqs?: ExtSpecialArrangement[]) {
  return (specialReqs && specialReqs.find((sr) => sr.shortCode === 'ANTHR')?.checked) || false;
}

const addAvailability = (current: ExamDistrictDto) => ({
  hasCdExamsAvailable: current?.hasCdExamsAvailable || false,
  hasPbExamsAvailable: current?.hasPbExamsAvailable || false,
});

export function buildLocationList(locations: ExamDistrictDto[]) {
  let nestedLocations: { parentName: string; locations: Location[] }[] = [];

  const flatLocations = locations.reduce((previous, current) => {
    if (!!current.name && (current.districtIds?.length || 0) > 0) {
      // Separates locations grouped by districtParentName into a temporary structure
      const currentParentName = current.districtParentName;
      if (currentParentName) {
        const parentExists = nestedLocations.some((n) => n.parentName === currentParentName);
        const currentLocation: Location = {
          ids: current.districtIds || [],
          centreIds: current.centreIds || [],
          name: current.name,
          districtParentName: currentParentName,
          isMobile: current.isMobile,
          ...addAvailability(current),
        };

        parentExists
          ? (nestedLocations = nestedLocations.map((n) =>
              n.parentName === currentParentName
                ? {
                    ...n,
                    locations: [...n.locations, currentLocation].sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    ),
                  }
                : n
            ))
          : nestedLocations.push({
              parentName: currentParentName,
              locations: [currentLocation],
            });

        return previous;
      }

      return previous.concat({
        ids: current.districtIds || [],
        centreIds: current.centreIds || [],
        name: current.name,
        isMobile: current.isMobile,
        ...addAvailability(current),
      });
    }

    return previous;
  }, [] as Location[]);

  // Adds and sorts parent locations into proper places on the list (they include ids from all child locations)
  let locationsWithParents: Location[] = [
    ...flatLocations,
    ...nestedLocations.map((n) => ({
      ids: n.locations.reduce((prev, cur) => [...prev, ...cur.ids], [] as number[]),
      centreIds: n.locations.reduce((prev, cur) => [...prev, ...cur.centreIds], [] as number[]),
      name: n.parentName,
      isParent: true,
      hasCdExamsAvailable: n.locations.some((l) => l.hasCdExamsAvailable),
      hasPbExamsAvailable: n.locations.some((l) => l.hasPbExamsAvailable),
    })),
  ].sort((a, b) => (a.name > b.name ? 1 : -1));

  // Insterts child locations directly below their parents
  nestedLocations.forEach((n) => {
    const parentIndex = locationsWithParents.findIndex((l) => l.name === n.parentName);
    n.locations.forEach((l, index) => locationsWithParents.splice(parentIndex + index + 1, 0, l));
  });

  return locationsWithParents;
}

import { extractApiError, ApiErrorDetailed, ApiCache } from '@britishcouncil/react-common';
import { createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunk } from '@reduxjs/toolkit';
import { getOrganisationAlias, logger } from 'ors-utils';

import { throttleAction } from 'core';

import {
  featureFlagsClient,
  FeatureFlagsDto,
  districtsClient,
  ExamDistrictDto,
  IeltsModule,
  ExamOptionType,
  countriesClient,
  GetDistrictExamDatesRequest,
  ProductFamilies,
  GetCountryExamDatesRequest,
  accessibilityClient,
  AccessibilityDto,
} from 'ors-api/mod';

import {
  B2CCreateRegistrationRequest,
  CandidateB2CMissingDetails,
  CandidateBookingPersonalDetails,
  candidateClient,
  candidateDocumentClient,
  CandidateDocumentResult,
  CandidatePersonalDetails,
  ExamFormat,
  MarketingQuestionAnswer,
  pricingClient,
  ProductPricingRequest,
  Registration,
  registrationClient,
  registrationTaxFeeCalculatorClient,
  RegistrationDocumentType,
  SomeoneElsePersonalDetails,
  TaxedFee,
  AwardingBodySystem,
  CandidateBookingPersonalDetailsWithName,
} from 'ors-api/ors2';

import { RootState } from './';
import { ApiErrorsListWithInCentreRegistrationRequest } from './registration/types';
import { extractApiErrors, ApiErrorsList } from 'ors-ui';

const sliceName = 'api';

export const loadDatesBy = throttledAsyncThunk(
  'loadDatesBy',
  async (params: LoadDatesByRequest, thunkAPI) => {
    let resp = null;

    if (params.districtIds && params.districtIds.length > 0) {
      const payload = {
        ...params.request,
        districtIds: params.districtIds,
      } as GetDistrictExamDatesRequest;
      resp = await districtsClient.getDistrictExamDates(params.productFamily, payload);
    } else if (!!params.countryId) {
      resp = await countriesClient.getCountryExamDates(
        params.countryId,
        params.productFamily,
        params.request
      );
    } else {
      throw new Error('Either districtId or has to be provided');
    }

    return resp.data;
  }
);

export const loadLocationsList = createAsyncThunk(
  sliceName + '/loadLocations',
  async (request: LoadLocationsRequest, thunkAPI) => {
    const {
      invitation: { deeplinkToken },
    } = thunkAPI.getState() as RootState;

    return ApiCache.getOrCreate<ExamDistrictDto[]>(
      'LOCATIONS_',
      async () => {
        const response = await districtsClient.getExamDistricts(request.productFamilyId, {
          countryId: request.countryId,
          ieltsModule: request.ieltsModule,
          examOptionType: request.examOptionType,
          organisationId: request.organisationId,
        });
        return response.data;
      },
      [
        getOrganisationAlias() || '',
        request.organisationId || -1,
        request.ieltsModule || -1,
        request.examOptionType || -1,
        request.countryId,
        request.productFamilyId,
        deeplinkToken || '',
      ]
    );
  }
);

export const loadParentDetails = createAsyncThunk(
  sliceName + '/loadParentDetails',
  async (minorId: number, thunkAPI) => {
    const response = await candidateClient.getParentCandidateBasicsByMinor(minorId);
    return response.data;
    /** TODO: Provide error handling - choosing IWillPickHim option in child consent becomes unavailable */
  }
);

export const createRegistration = createAsyncThunk<
  Registration,
  B2CCreateRegistrationRequest,
  { state: RootState; rejectValue: ApiErrorsListWithInCentreRegistrationRequest }
>(sliceName + '/createRegistration', async (req, { rejectWithValue }) => {
  try {
    const response = await registrationClient.createRegistration(req);
    return response.data;
  } catch (error: any) {
    const err = extractApiErrors(error);
    return rejectWithValue({ error: err, answers: req.answers });
  }
});

export const calculateProductPrice = createAsyncThunk(
  sliceName + '/calculateProductPrice',
  async (context: ProductPricingRequest, { rejectWithValue }) => {
    try {
      const response = await pricingClient.calculateProductPrice(context);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.status);
    }
  }
);

export const getIdFiles = createAsyncThunk<
  CandidateDocumentResult[],
  number,
  { rejectValue: ApiErrorsList }
>(sliceName + '/getIdFiles', async (candidateId, { rejectWithValue }) => {
  try {
    const response = await candidateDocumentClient.get(
      candidateId,
      RegistrationDocumentType.IdDocument
    );
    return response.data;
  } catch (error) {
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const prepareCandidateDocuments = createAsyncThunk<
  CandidateDocumentResult[],
  number,
  { rejectValue: ApiErrorsList }
>(sliceName + '/prepareCandidateDocuments', async (candidateId, { rejectWithValue }) => {
  try {
    const response = await candidateDocumentClient.copyDocumentsIfEmptyFromLastRegistration(
      candidateId,
      RegistrationDocumentType.IdDocument
    );
    return response.data;
  } catch (error) {
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const uploadIdFile = createAsyncThunk<
  CandidateDocumentResult[],
  UploadIdReq,
  { rejectValue: ApiErrorsList }
>(sliceName + '/uploadIdFile', async (req, { rejectWithValue }) => {
  try {
    const { candidateId, files } = req;
    const response = await candidateDocumentClient.upload(
      candidateId,
      files,
      RegistrationDocumentType.IdDocument
    );
    return response.data;
  } catch (error) {
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const deleteIdFile = createAsyncThunk<
  CandidateDocumentResult[],
  DeleteIdReq,
  { rejectValue: ApiErrorsList }
>(sliceName + '/deleteIdFile', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateDocumentClient.deleteDocument(req.candidateId, req.documentId);
    return response.data;
  } catch (error) {
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

type GetTaxedFeeReq = {
  organisationCountryId: number;
  fee: number;
};
export const getTaxedFee = createAsyncThunk<
  TaxedFee,
  GetTaxedFeeReq,
  { state: RootState; rejectValue: ApiErrorsList }
>(sliceName + '/getTaxedFee', async (req, { rejectWithValue }) => {
  try {
    const response = await registrationTaxFeeCalculatorClient.getComputedTaxesFeeForRegistration({
      orgCountryId: req.organisationCountryId,
      fee: req.fee,
    });
    return response.data;
  } catch (error) {
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const saveCandidateDetails = createAsyncThunk<
  CandidatePersonalDetails,
  CandidateBookingPersonalDetailsWithName,
  { state: RootState; rejectValue: ApiErrorsList }
>(sliceName + '/saveCandidateDetails', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveCandidateDetailsForBookingWithName(req);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const addCandidateDetails = createAsyncThunk<
  CandidatePersonalDetails,
  CandidateBookingPersonalDetails,
  { state: RootState; rejectValue: ApiErrorsList }
>(sliceName + '/addCandidateDetails', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveCandidateDetailsForBooking(req);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const saveSomeoneElseDetailsForBooking = createAsyncThunk<
  CandidatePersonalDetails,
  SomeoneElsePersonalDetails,
  { state: RootState; rejectValue: ApiErrorsList }
>(sliceName + '/saveSomeoneElseDetailsForBooking', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveSomeoneElseDetailsForBooking(req);
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiErrors(error);
    return rejectWithValue(err);
  }
});

export const verifyCandidateLastMarketingAnswers = createAsyncThunk<
  MarketingQuestionAnswer[],
  VerifyLastMarketingAnswersArgs,
  { state: RootState; rejectValue: ApiErrorDetailed }
>(sliceName + '/verifyCandidateLastMarketingAnswers', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.verifyCandidateLastMarketingAnswers(req.candidateId, {
      awardingBodySystem: req.awardingBodySystem,
      answers: req.answers,
    });
    return response.data;
  } catch (error: any) {
    logger.logError(error);
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

export const saveCandidateMissingDetails = createAsyncThunk<
  any,
  CandidateB2CMissingDetails,
  { rejectValue: ApiErrorDetailed }
>(sliceName + '/saveCandidateMissingDetails', async (req, { rejectWithValue }) => {
  try {
    const response = await candidateClient.saveCandidateMissingDetails(req);
    return response.data;
  } catch (error) {
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

export const getAllAccessibilities = createAsyncThunk<
  AccessibilityDto[],
  void,
  { state: RootState; rejectValue: ApiErrorDetailed }
>('accessibility/getAllAccessibilities', async (_, { rejectWithValue }) => {
  try {
    const response = await accessibilityClient.getAccessibilities();
    return response.data;
  } catch (error) {
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

export interface PayMethodsArgs {
  centreId: number;
  registrationId?: number;
  curTime: string;
  examDate?: string;
  examFormat?: ExamFormat;
}

interface VerifyLastMarketingAnswersArgs {
  candidateId: number;
  awardingBodySystem: AwardingBodySystem;
  answers?: MarketingQuestionAnswer[];
}

interface LoadLocationsRequest {
  countryId: number;
  productFamilyId: string;
  ieltsModule?: IeltsModule;
  examOptionType?: ExamOptionType;
  organisationId?: number;
}

interface LoadDatesByRequest {
  request: GetDistrictExamDatesRequest | GetCountryExamDatesRequest;
  districtIds?: number[];
  countryId?: number;
  productFamily: ProductFamilies.IELTS | ProductFamilies.UKVI;
}

interface UploadIdReq {
  candidateId: number;
  files: File[] | null | undefined;
}
interface DeleteIdReq {
  documentId: string;
  candidateId: number;
}

function throttledAsyncThunk<ThunkArg = void, ThunkApiConfig = {}>(
  name: string,
  // @ts-ignore
  payloadCreator: AsyncThunkPayloadCreator<any, ThunkArg, ThunkApiConfig>,
  options?: any
  // @ts-ignore
): AsyncThunk<any, ThunkArg, ThunkApiConfig> {
  const key = sliceName + '/' + name;
  // @ts-ignore
  return createAsyncThunk(
    key,
    (args, thunksApi) => {
      // @ts-ignore
      return throttleAction(key, async () => payloadCreator(args, thunksApi), args);
    },
    options
  );
}

export const refreshFeatureFlags = createAsyncThunk<
  FeatureFlagsDto,
  void,
  { rejectValue: ApiErrorDetailed }
>(sliceName + '/refreshFeatureFlags', async (_, { rejectWithValue }) => {
  try {
    const response = await featureFlagsClient.getAll();
    return response.data;
  } catch (error) {
    const err = extractApiError(error);
    return rejectWithValue(err);
  }
});

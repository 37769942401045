var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target, mod));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  defaultLanguage: () => defaultLanguage,
  getDateFnLocale: () => getDateFnLocale,
  hasIeltsUsaUrl: () => hasIeltsUsaUrl,
  ieltsUsaLanguage: () => ieltsUsaLanguage,
  ieltsUsaSpecific: () => ieltsUsaSpecific,
  initTranslations: () => initTranslations,
  isIeltsUsaContextUrl: () => isIeltsUsaContextUrl,
  pseudoLanguage: () => pseudoLanguage,
  readSupportedLanguages: () => readSupportedLanguages,
  selectLanguage: () => selectLanguage,
  setOneTrustLanguage: () => setOneTrustLanguage,
  setSupportedLanguages: () => setSupportedLanguages,
  useLanguageNames: () => useLanguageNames,
  useSelectedLang: () => useSelectedLang,
  writeSupportedLanguages: () => writeSupportedLanguages
});
module.exports = __toCommonJS(src_exports);

// src/config.ts
var import_i18next = __toESM(require("i18next"));
var import_react_i18next = require("react-i18next");

// src/locale/en/b2c.json
var APPB2C_auth_forgetPassword_title = "Recover password";
var APPB2C_auth_forgetPassword_email = "E-mail address";
var APPB2C_auth_forgetPassword_text = "Forgotten your password? No problem, we can send you a link to recover it.";
var APPB2C_auth_forgetPassword_cta = "Email me a recovery link";
var APPB2C_auth_forgetPassword_success_title = "Success!";
var APPB2C_auth_forgetPassword_success_msg1 = "Thanks! If an account exists, an email will be sent with further instructions.";
var APPB2C_auth_forgetPassword_success_msg2 = " Please click the link when you get it.";
var APPB2C_auth_resetPassword_title = "Reset your password";
var APPB2C_auth_resetPassword_cta = "Reset password";
var APPB2C_auth_resetPassword_form_newPassword = "New password";
var APPB2C_auth_resetPassword_success_title = "Success!";
var APPB2C_auth_resetPassword_success_msg = "Your password has been successfully changed. You will be redirected shortly...";
var APPB2C_auth_resetPassword_noToken_msg = "Provided token was not recognized";
var APPB2C_auth_resetPassword_noToken_cta = "Recover your password";
var APPB2C_auth_resetPassword_error_wrongPassword = "The password you entered does not meet the requirements.";
var APPB2C_auth_resetPassword_error_expiredToken = "Link to reset your password has expired. Please use \u2018Forgot Password\u2019 on login screen to receive new link.";
var APPB2C_auth_resetPassword_error_wrongToken = "Your temporary link for password reset is invalid.";
var APPB2C_auth_changePassword_title = "Set your new password";
var APPB2C_auth_changePassword_cta = "Set Password";
var APPB2C_auth_changePassword_id_note = "To continue logging-in, add your Passport number / National ID number below.";
var APPB2C_auth_changePassword_id_label = "Passport number / National ID number";
var APPB2C_auth_changePassword_id_warn = "If you keep seeing the \u2018ID number is incorrect\u2019 error while providing a correct one, please contact the British Council centre.";
var APPB2C_auth_changePassword_pass_note = "Please set up a password of your choice to continue.";
var APPB2C_auth_changePassword_pass_label = "Password";
var APPB2C_auth_changePassword_error = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_auth_changePassword_error_wrongIdNumber = "ID number is incorrect.";
var APPB2C_auth_changePassword_error_wrongPassword = "Password does not meet requirements.";
var APPB2C_auth_changePassword_error_differentPasswords = "Entered passwords are different.";
var APPB2C_auth_changePassword_error_wrongOldPassword = "Old password is incorrect.";
var APPB2C_cj_basic_cta = "Save and continue";
var APPB2C_cj_basic_goToReview = "Save and Review";
var APPB2C_cj_basic_validationError = "Please check all the highlighted answers to continue.";
var APPB2C_cj_basic_scrollLink = "View answers to check.";
var APPB2C_cj_basic_selectDate = "Select date";
var APPB2C_cj_basic_selectTime = "Select time";
var APPB2C_cj_incorrectOrganisation_title = "Sorry!";
var APPB2C_cj_incorrectOrganisation_line1 = "It looks like you\u2019re trying to book a test with an organisation we don\u2019t recognise.";
var APPB2C_cj_incorrectOrganisation_line2 = "Don\u2019t worry; there\u2019s a couple of things you can try:";
var APPB2C_cj_incorrectOrganisation_locationsLink = "Look at our global locations on the IELTS website";
var APPB2C_cj_incorrectOrganisation_locationsLinkUsa = "Look at all the test centres in the USA on the IELTS website";
var APPB2C_cj_incorrectOrganisation_bookNewLink = "Book a test on this website (we\u2019ll help you find a test centre)";
var APPB2C_cj_timer_nearEnd_title = "Attention!";
var APPB2C_cj_timer_nearEnd_text = 'Your chosen test is only being held for another $t(APPB2C.cj.timer.nearEnd.minuteCount, {"count": {{count}} }).';
var APPB2C_cj_timer_nearEnd_minuteCount = "{{count}} minute";
var APPB2C_cj_timer_nearEnd_minuteCount_other = "{{count}} minutes";
var APPB2C_cj_timer_modal_title = "Restart your booking";
var APPB2C_cj_timer_modal_message = "Sorry; you\u2019ve run out of time to complete this booking.";
var APPB2C_cj_timer_modal_cta = "Restart booking";
var APPB2C_cj_rebook_errorModal_title = "Booking reservation failed";
var APPB2C_cj_rebook_errorModal_message = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_cj_personalDetails_title = "Personal details";
var APPB2C_cj_personalDetails_gender = "Sex:";
var APPB2C_cj_personalDetails_forWho_myself = "Myself";
var APPB2C_cj_personalDetails_forWho_child = "My child";
var APPB2C_cj_personalDetails_forWho_childUnder18 = "(under 18 years old)";
var APPB2C_cj_personalDetails_forWho_title = "Who are you booking the test for?";
var APPB2C_cj_personalDetails_forWho_note = "You cannot book a test for a child under the age of 11. If your child is 18 or over, please ask them to register for themselves.";
var APPB2C_cj_personalDetails_forWho_dob = "Date of birth: ";
var APPB2C_cj_personalDetails_visaType_title = "Your visa type";
var APPB2C_cj_personalDetails_visaType_title_child = "Their visa type";
var APPB2C_cj_personalDetails_visaType_label = "What visa type do you need?";
var APPB2C_cj_personalDetails_visaType_label_child = "What visa type do they need?";
var APPB2C_cj_personalDetails_visaType_link = "Learn more about the visa types";
var APPB2C_cj_personalDetails_tncs_title = "Our terms and conditions";
var APPB2C_cj_personalDetails_tncs_agreement = "I agree to the account registration <1>terms and conditions</1>";
var APPB2C_cj_personalDetails_login_title = "Create an account";
var APPB2C_cj_personalDetails_login_loggedIn = "Hi! You are now logged in.";
var APPB2C_cj_personalDetails_login_note = "You\u2019ll need an account to manage your booking. Once you\u2019ve booked a test, you\u2019ll receive an email explaining how to access your booking online.";
var APPB2C_cj_personalDetails_login_link = "Already have an account? <1>Log in</1>";
var APPB2C_cj_personalDetails_login_emailNotEditable = "You can\u2019t edit your email address now that you\u2019ve created an account. To change this, please <1>contact us</1>";
var APPB2C_cj_personalDetails_login_logout = "Not your details? <1>Logout</1>";
var APPB2C_cj_personalDetails_login_btn = "Log in";
var APPB2C_cj_personalDetails_idNote_whenForChild = "we\u2019ll ask you about your child on the next page";
var APPB2C_cj_personalDetails_idNote_idMatch = "All personal details provided on this page must match the Test Taker's identification document used for registration and presented at the test location, as these will appear on the Test Taker's Test Report Form (TRF). Once registration is complete, you may be charged a fee if you ask us to modify any of this information before we issue the TRF. Please note that no modifications can be made after the TRF has been issued.";
var APPB2C_cj_personalDetails_idNote_idMatch_change = "The full name and date of birth <bold>must match</bold> the Identification Document you will present on test day.";
var APPB2C_cj_personalDetails_forms_personal_firstName = "First / given names";
var APPB2C_cj_personalDetails_forms_personal_firstMiddleName = "First / given names (including middle names)";
var APPB2C_cj_personalDetails_forms_personal_lastName = "Surname / family name";
var APPB2C_cj_personalDetails_forms_personal_noLastName = "I don\u2019t have a surname / family name";
var APPB2C_cj_personalDetails_forms_personal_idMatch = "This must match the name(s) on your identification document";
var APPB2C_cj_personalDetails_forms_personal_dob = "Date of birth";
var APPB2C_cj_personalDetails_forms_personal_sectionTitle = "About you";
var APPB2C_cj_personalDetails_forms_personal_change = "Change";
var APPB2C_cj_personalDetails_forms_personal_name = "Name";
var APPB2C_cj_personalDetails_forms_personal_dobCta = "Book IELTS in a test centre";
var APPB2C_cj_personalDetails_forms_contact_note = "Please provide your phone number and postal address in case we need to contact you or send you any documents (e.g. your test report form).";
var APPB2C_cj_personalDetails_forms_contact_email = "Your email";
var APPB2C_cj_personalDetails_forms_contact_emailInUse = "This email address is already in use. If that was you, would you like to <0>log in</0> instead?";
var APPB2C_cj_personalDetails_forms_contact_confirmEmail = "Confirm email address";
var APPB2C_cj_personalDetails_forms_contact_mobile = "Mobile number";
var APPB2C_cj_personalDetails_forms_contact_title = "Your contact details";
var APPB2C_cj_personalDetails_forms_contact_password = "Password";
var APPB2C_cj_personalDetails_forms_contact_smsConsent = "I agree to receive notifications or to be contacted about my test registration to this telephone number via SMS, WhatsApp, etc.";
var APPB2C_cj_personalDetails_forms_contact_smsNote = "<bold>Please note:</bold> this service might not be available in your location.";
var APPB2C_cj_personalDetails_forms_contact_emailExist = "This email address is already in use. If that was you, please try to log in instead.";
var APPB2C_cj_personalDetails_forms_address_country = "Country / territory of residence";
var APPB2C_cj_personalDetails_forms_address_postal = "Postal address";
var APPB2C_cj_personalDetails_forms_address_town = "Town / City";
var APPB2C_cj_personalDetails_forms_address_zip = "Postcode / ZIP";
var APPB2C_cj_personalDetails_forms_address_state = "State";
var APPB2C_cj_personalDetails_forms_address_enterManually = "Enter address manually";
var APPB2C_cj_personalDetails_error_missingFirstName = "Please enter your first / given names.";
var APPB2C_cj_personalDetails_error_missingSurname = "Please enter your surname / family name. If you do not have a surname, please put a hyphen ('-') in this field.";
var APPB2C_cj_personalDetails_error_missingEmail = "Please enter a valid email address.";
var APPB2C_cj_personalDetails_error_missingEmailConfirm = "Please confirm your email address by re-entering it.";
var APPB2C_cj_personalDetails_error_wrongEmailConfirm = "The re-entered email address does not match the one you have provided. Please check your spelling carefully.";
var APPB2C_cj_personalDetails_error_wrongDob = "Please provide your actual date of birth.";
var APPB2C_cj_personalDetails_error_missingMobile = "Please provide your mobile telephone number.";
var APPB2C_cj_personalDetails_error_missingAddress = "Please provide your postal address.";
var APPB2C_cj_personalDetails_error_missingSex = "Please provide your sex.";
var APPB2C_cj_personalDetails_error_missingTown = "Please provide your town / city.";
var APPB2C_cj_personalDetails_error_missingZip = "Please provide your postcode / ZIP.";
var APPB2C_cj_personalDetails_error_missingState = "Please provide your state.";
var APPB2C_cj_personalDetails_error_doubleBooking = "The Test taker has already registered for an IELTS test on the same date.";
var APPB2C_cj_personalDetails_examTakenModal_title = "We\u2019re sorry \u2013 your chosen test isn\u2019t available anymore";
var APPB2C_cj_personalDetails_examTakenModal_message = "Don\u2019t worry though, we\u2019ve got lots of other tests for you to choose from, and we won\u2019t forget any of the details you just told us.";
var APPB2C_cj_personalDetails_examTakenModal_cta = "Find a new test";
var APPB2C_cj_personalDetails_existingRegistrationsModal_title = "You already have a test booked on this date!";
var APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle = "Don\u2019t forget you already have a test booked on";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest = "Your Written test";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest = "Your Speaking test";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest = "Your test";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking = "You can view your current booking in the Test Taker Portal.";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking = "View my current booking";
var APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate = "Choose a different test date";
var APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse = "Book a test for someone else";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title = "Please choose a different test date";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate = "Find a new test";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line1 = "You cannot book an IELTS Online test so close to your other test on {{nearestRegistratonExamDate}}.";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line2 = "Please find a test date 5 or more days earlier or later than your previous test.";
var APPB2C_cj_childDetails_title = "Your child\u2019s details";
var APPB2C_cj_childDetails_about = "About your child";
var APPB2C_cj_childDetails_aboutNote = "Please add the details for the child taking the test:";
var APPB2C_cj_childDetails_noLastName = "They don\u2019t have a surname / family name";
var APPB2C_cj_childDetails_note = "Their full name and date of birth must match the identification document presented at the test location.";
var APPB2C_cj_childDetails_onTestDay = "On the test day";
var APPB2C_cj_childDetails_forms_personal_idMatch = "This must match the name(s) on their identification document";
var APPB2C_cj_childDetails_error_missingFirstName = "Please enter your child\u2019s first / given names.";
var APPB2C_cj_childDetails_error_missingSurname = "Please enter your child\u2019s surname / family name. If they do not have a surname, please put a hyphen ('-') in this field.";
var APPB2C_cj_childDetails_error_wrongDob = "Please provide your child\u2019s actual date of birth.";
var APPB2C_cj_marketingPrefs = "Your marketing preferences";
var APPB2C_cj_idDetails_title = "Identification details";
var APPB2C_cj_idDetails_title_child = "Your child\u2019s identification";
var APPB2C_cj_idDetails_forms_idType = "Identification type";
var APPB2C_cj_idDetails_forms_changeNote = "You have changed the selected identification type. Please ensure the images uploaded below match the identification document you will bring on the test day.";
var APPB2C_cj_idDetails_forms_changeNote_child = "You have changed the selected identification type. Please ensure the images uploaded below match the identification document they will bring on the test day.";
var APPB2C_cj_idDetails_forms_idNumber = "$t({{idName}}, capitalise) number";
var APPB2C_cj_idDetails_forms_issuingAuthority = "Issuing authority";
var APPB2C_cj_idDetails_forms_expiryDate = "$t({{idName}}, capitalise) expiry date";
var APPB2C_cj_idDetails_forms_documentWithNoExpiryDate = "The document doesn\u2019t have an expiry date";
var APPB2C_cj_idDetails_typeNames_passport = "passport";
var APPB2C_cj_idDetails_typeNames_card = "identity card";
var APPB2C_cj_idDetails_typeNames_other = "ID";
var APPB2C_cj_idDetails_iolMessage = "On the test day, you will be required to show the same identification document you are using for registration.";
var APPB2C_cj_idDetails_idExpired = "The identification document you registered with before has now expired. Please add new identification.";
var APPB2C_cj_idDetails_noteAboutIdentification = "On the test day, you will be required to bring the same identification document you are using for registration.";
var APPB2C_cj_idDetails_noteAboutIdentificationIol = "A passport is currently the only acceptable ID used for IELTS Online. If you wish to use alternative acceptable IDs, you should take an IELTS on Paper test or IELTS on Computer test instead.";
var APPB2C_cj_idDetails_noteAboutIdentificationUol = "A passport is currently the only acceptable ID used for IELTS for UKVI Online. If you wish to use alternative acceptable IDs, you should take an IELTS UKVI on Paper test or IELTS UKVI on Computer test instead.";
var APPB2C_cj_idDetails_noteAboutIdentificationIolLink = "If you don\u2019t have a valid passport, use this link to book a different test instead.";
var APPB2C_cj_idDetails_noteAboutIdentification_child = "On the test day, your child will be required to bring the same identification document you are using for registration.";
var APPB2C_cj_idDetails_upload_title = "Upload proof of $t({{idName}})";
var APPB2C_cj_idDetails_upload_mobile = "As it looks like you\u2019re on a mobile device, we suggest you upload a photo from your device.";
var APPB2C_cj_idDetails_upload_instructions_title = "How to take a suitable photo / scan:";
var APPB2C_cj_idDetails_upload_instructions_capture = "Capture your entire document";
var APPB2C_cj_idDetails_upload_instructions_noBlur = "Make sure the photo / scan is in focus with no blur present.";
var APPB2C_cj_idDetails_upload_instructions_directLight = "Move away from direct light to avoid glare.";
var APPB2C_cj_idDetails_upload_instructions_covers = "Make sure nothing covers your document (fingers, stickers, paperclips etc.)";
var APPB2C_cj_idDetails_upload_file1 = "Image 1";
var APPB2C_cj_idDetails_upload_file2 = "Image 2 (optional)";
var APPB2C_cj_idDetails_upload_fetchError = "We cannot process your file. Please try again later.";
var APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions = "Follow the instructions below to make sure your photo meets our requirements.";
var APPB2C_cj_idDetails_upload_passport_guidelines_crop = "Place your passport on a flat surface and make sure that the data is visible.";
var APPB2C_cj_idDetails_upload_passport_guidelines_cover = "Make sure you do not cover the passport data (finger, paperclip etc.).";
var APPB2C_cj_idDetails_upload_passport_guidelines_glare = "The photo needs to be clear with no reflections, make sure all the data is readable.";
var APPB2C_cj_idDetails_upload_passport_guidelines_watermark = "Make sure that image watermark is disabled in the camera settings.";
var APPB2C_cj_idDetails_confirm_title = "Please check your $t({{idName}}) before you continue";
var APPB2C_cj_idDetails_confirm_note1 = "It\u2019s really important that you make sure the $t({{idName}}) details you\u2019ve told us are correct. Please check them below before proceeding.";
var APPB2C_cj_idDetails_confirm_note2 = "On the test day, you will be required to bring this $t({{idName}}) with you.";
var APPB2C_cj_idDetails_confirm_confirm = "I confirm these details are correct";
var APPB2C_cj_idDetails_confirm_change = "I need to change these details";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_title = "Please note:";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_content = "If your ID details are wrong it may delay the release of your results.";
var APPB2C_cj_idDetails_error_missingIdNumber = "Please provide the identification document number. It needs to match specific format (allowed are letters, digits, and special chars: _\\-/,).";
var APPB2C_cj_idDetails_error_missingIdExpiry = "Please provide the expiry date of the identification document.";
var APPB2C_cj_idDetails_error_missingIdType = "Please provide the identification document type.";
var APPB2C_cj_idDetails_error_wrongIdExpiry = "Please provide the identification document that does not expire before chosen test date.";
var APPB2C_cj_idDetails_error_missingIssuingAuthority = "Please provide the issuing authority of the identification document.";
var APPB2C_cj_idDetails_error_wrongFilename = "File name contains invalid characters.";
var APPB2C_cj_ttProfile_title = "Your profile";
var APPB2C_cj_ttProfile_title_child = "Your child\u2019s profile";
var APPB2C_cj_ttProfile_note = "Answering these questions has no impact on your IELTS test results. These questions will help us improve our services to test takers like you.";
var APPB2C_cj_ttProfile_note_child = "Answering these questions has no impact on your child\u2019s IELTS test results. These questions will help us improve our services to test takers like them.";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle = "About you";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle_child = "About your child";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality = "What is your country / territory of nationality?";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child = "What is their country / territory of nationality?";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken = "What is your first language?";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child = "What is their first language?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish = "How many years have you been studying English?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child = "How many years have they been studying English?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel = "Your answer to this question has no impact on your test score";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child = "Your answer to this question has no impact on their test score";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_384 = "Secondary (up to 16 years)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_385 = "Secondary (16-19 years)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_386 = "Degree (or equivalent)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_387 = "Post-graduate";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_title = "What level of education have you completed?";
var APPB2C_cj_ttProfile_forms_occupation_sectionTitle = "Your occupation";
var APPB2C_cj_ttProfile_forms_occupation_occupationLevel = "What is your occupation level?";
var APPB2C_cj_ttProfile_forms_occupation_occupationSector = "What is your occupation sector?";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle = "Your interest in IELTS";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle_child = "Their interest in IELTS";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest = "Why are you taking the test?";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child = "Why are they taking the test?";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied = "Which country / territory do you want to study / work / live in?";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child = "Which country / territory do they want to study / work / live in?";
var APPB2C_cj_review_title = "Review";
var APPB2C_cj_review_note_title = "You have not booked yet!";
var APPB2C_cj_review_note_content = "Please check all the details carefully before you book.";
var APPB2C_cj_review_registrationError = "We cannot create registration right now. Please try again later.";
var APPB2C_cj_review_missingMarketingError = "It looks like you've tried to book a test with us before, and now we just need to check a few answers with you again.";
var APPB2C_cj_review_missingMarketingCta = "Check your answers";
var APPB2C_cj_review_taxNumberError = "Please provide valid VAT/NIP number, it has to match specific format (e.g. 13243546-3-35, 1324354635).";
var APPB2C_cj_review_paymentError = "We cannot process your payment. Please try again later.";
var APPB2C_cj_review_expiredIdError = "The Test taker's ID must not expire before the chosen test date.";
var APPB2C_cj_review_tooHighPromoCodeError = "We couldn't complete your registration. Please attempt registration without using the promo code. If you believe the code should work, please contact your centre for help";
var APPB2C_cj_review_error_wrongTaxNumber = "Please provide a valid VAT/NIP number. It has to match specific format (e.g. 13243546-3-35, 1324354635)";
var APPB2C_cj_review_error_missingTown = "Please change your details and include town / city in your address.";
var APPB2C_cj_review_error_missingPickups = "Please specify who will pick your child up from the test premises.";
var APPB2C_cj_review_error_missingOrderAcknowledgement = "Please specify who should receive the order confirmation for the test booking.";
var APPB2C_cj_review_error_wrongPaymentConfig = "Payment configuration is missing or incorrect, please contact the test centre if this issue persists.";
var APPB2C_cj_review_error_wrongCountrySetting = "Booking this test is not available in your country. Please select a different test.";
var APPB2C_cj_review_change = "Change";
var APPB2C_cj_review_changeUkviLsTest = "Change test date";
var APPB2C_cj_review_changeLrwTest = "Change written test date";
var APPB2C_cj_review_changeSpeakingTest = "Change speaking schedule";
var APPB2C_cj_review_payOnline_buttonText = "Pay online";
var APPB2C_cj_review_bookNowNoFee_buttonText = "Book now";
var APPB2C_cj_review_payOnline_sideNote = "Recommended payment method";
var APPB2C_cj_review_or = "or";
var APPB2C_cj_review_payLater_buttonText = "Offline payment";
var APPB2C_cj_review_confirmPaymentModal_title = "Paying online may cost you more";
var APPB2C_cj_review_confirmPaymentModal_note = "Please note: Additional charges may apply from your bank for your online payment for the IELTS exam.";
var APPB2C_cj_review_confirmPaymentModal_optionsTitle = "You have two options.";
var APPB2C_cj_review_confirmPaymentModal_btnNote = "You may pay more than the <0></0> listed";
var APPB2C_cj_review_payLater_sideNote = "Payment options will be shown on next page";
var APPB2C_cj_review_bookTest = "Book test";
var APPB2C_cj_review_paymentNote = "Book & pay:";
var APPB2C_cj_review_paymentNoteZeroFee = "Get your free test";
var APPB2C_cj_review_localization_location = "We localised you in";
var APPB2C_cj_review_localization_localTime = "Your local time";
var APPB2C_cj_review_localization_timezone = "Your timezone";
var APPB2C_cj_review_bookingDetails_title = "You are booking";
var APPB2C_cj_review_bookingDetails_lrw_title = "Your Written test";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills = "Your test";
var APPB2C_cj_review_bookingDetails_lrw_title_child = "Your child\u2019s Written test";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child = "Your child\u2019s test";
var APPB2C_cj_review_bookingDetails_speaking_title = "Your Speaking test";
var APPB2C_cj_review_bookingDetails_speaking_title_child = "Your child\u2019s Speaking test";
var APPB2C_cj_review_personalDetails_title = "Your details";
var APPB2C_cj_review_personalDetails_name = "Name";
var APPB2C_cj_review_personalDetails_dob = "Date of Birth";
var APPB2C_cj_review_personalDetails_email = "Email address";
var APPB2C_cj_review_personalDetails_phone = "Mobile telephone number";
var APPB2C_cj_review_personalDetails_address = "Address";
var APPB2C_cj_review_personalDetails_gender = "Sex";
var APPB2C_cj_review_personalDetails_smsNotificationsConsent = "I want to receive updates about my tests and results to this mobile telephone number";
var APPB2C_cj_review_childDetails_title = "Your child\u2019s details";
var APPB2C_cj_review_childDetails_onTestDay = "On the test day";
var APPB2C_cj_review_childDetails_guardiansTitle = "Person(s) who will collect your child";
var APPB2C_cj_review_childDetails_onTestDayOptions_1 = "Child can leave the test premises on its own";
var APPB2C_cj_review_childDetails_onTestDayOptions_2 = "I will pick my child up from the test premises";
var APPB2C_cj_review_childDetails_onTestDayOptions_3 = "Someone else will pick my child up from the test premises";
var APPB2C_cj_review_idDetails_title = "Your identification";
var APPB2C_cj_review_idDetails_title_child = "Your child\u2019s identification";
var APPB2C_cj_review_idDetails_issuing = "Issuing authority";
var APPB2C_cj_review_idDetails_nationality = "Country / territory of nationality";
var APPB2C_cj_review_payment_title = "Payment";
var APPB2C_cj_review_payment_fee = "Fee";
var APPB2C_cj_review_payment_total = "Total";
var APPB2C_cj_review_payment_tax = "Tax";
var APPB2C_cj_review_payment_promoCodes_promoCode = "Promo code";
var APPB2C_cj_review_payment_promoCodes_haveCode = "I have a promo code";
var APPB2C_cj_review_payment_promoCodes_notHaveCode = "I don\u2019t have a promo code";
var APPB2C_cj_review_payment_promoCodes_removeCode = "remove promo code";
var APPB2C_cj_review_payment_promoCodes_remove = "Remove";
var APPB2C_cj_review_payment_promoCodes_applyCode = "Apply code";
var APPB2C_cj_review_payment_promoCodes_discount = "Promotion";
var APPB2C_cj_review_payment_promoCodes_promo = "Promo";
var APPB2C_cj_review_payment_promoCodes_total = "Total";
var APPB2C_cj_review_payment_promoCodes_newFee = "New fee";
var APPB2C_cj_review_payment_promoCodes_invalidCode = "The promo code you entered is not valid";
var APPB2C_cj_review_payment_promoCodes_generalError = "An error occurred \u2013 please try again.";
var APPB2C_cj_review_payment_promoCodes_applied = "Promo code applied";
var APPB2C_cj_review_invoice_title = "Invoice information";
var APPB2C_cj_review_invoice_send = "What type of invoice would you like us to issue?";
var APPB2C_cj_review_invoice_type_duplicatePaper = "Duplicate Uniform Paper Invoice";
var APPB2C_cj_review_invoice_type_triplicate = "Triplicate Uniform Paper Invoice";
var APPB2C_cj_review_invoice_type_duplicateElectronic = "Duplicate Uniform Electronic Invoice";
var APPB2C_cj_review_invoice_type_donate = "Donate Electronic Invoice";
var APPB2C_cj_review_invoice_subtype_label = "Which carrier would you like to use?";
var APPB2C_cj_review_invoice_subtype_mobileBarcode = "Mobile Barcode";
var APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate = "Citizen Digital Certificate";
var APPB2C_cj_review_invoice_consent = "Do you consent to British Council (Taiwan) Limited handling invoice and proof of product return on your behalf for refund of purchased items in order to speed up the refund process?";
var APPB2C_cj_review_invoice_companyName = "Invoice header";
var APPB2C_cj_review_invoice_taxCaption_duplicatePaper = "Invoice number";
var APPB2C_cj_review_invoice_taxCaption_donate = "Charitable organisation number";
var APPB2C_cj_review_invoice_taxValidation_triplicate = "Invoice number has to match 8 digits specific format (eg 11223344)";
var APPB2C_cj_review_invoice_taxValidation_mobileBarcode = "Mobile Barcode has to match the correct format (eg /123-ABC)";
var APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate = "Citizen Digital Certificate has to match the correct format of 16 alphanumeric characters (eg 1122AABB3344CCDD)";
var APPB2C_cj_review_invoice_taxValidation_donate = "Charitable organisation number has to match 3-7 digits specific format (eg 123)";
var APPB2C_cj_review_acknowledgement_title = "Order acknowledgement";
var APPB2C_cj_review_acknowledgement_send = "Who should receive the order confirmation for the test booking?";
var APPB2C_cj_review_acknowledgement_myself = "Myself";
var APPB2C_cj_review_acknowledgement_anotherPerson = "Another Person";
var APPB2C_cj_review_acknowledgement_company = "A Company";
var APPB2C_cj_review_acknowledgement_fields_companyName = "Company name";
var APPB2C_cj_review_acknowledgement_fields_companyName_subnote = "use the original spelling of company name";
var APPB2C_cj_review_acknowledgement_fields_firstName = "Other / given names";
var APPB2C_cj_review_acknowledgement_fields_familyName = "Family name";
var APPB2C_cj_review_acknowledgement_fields_email = "Email";
var APPB2C_cj_review_acknowledgement_fields_mobile = "Telephone number";
var APPB2C_cj_review_acknowledgement_fields_country = "Country / territory";
var APPB2C_cj_review_ai_title = "Training consent";
var APPB2C_cj_review_ai_note = "Would you be happy for anonymised test answers and test recordings to be used to train the remote proctoring and test marking software?";
var APPB2C_cj_review_agent_title = "Agent name";
var APPB2C_cj_review_agent_note = "Do you have an agent who is acting on your behalf with your UKVI application?";
var APPB2C_cj_review_agent_label = "Please provide the name of your agent";
var APPB2C_cj_review_tncs_title = "Our terms and conditions";
var APPB2C_cj_review_tncs_agreement = "I agree to the IELTS <0>terms and conditions</0> and <2>cancellation policy</2>";
var APPB2C_cj_review_countryMsg_pakistan = "The IELTS fees paid with a credit card may be different from the mentioned price due to fluctuating exchange rates. Credit card transactions conducted in Pakistani Rupee at online or international merchants are settled by the merchant in a foreign currency.";
var APPB2C_cj_review_registrationFailedModal_title = "Restart your booking";
var APPB2C_cj_review_registrationFailedModal_message = "Oooops, something went wrong. Please try again.";
var APPB2C_cj_review_registrationFailedModal_changedFee_title = "Test prices have changed";
var APPB2C_cj_review_registrationFailedModal_changedFee_line1 = "We inform you that the prices of some test have changed.";
var APPB2C_cj_review_registrationFailedModal_changedFee_line2 = "Please review the new prices and re-select the test.";
var APPB2C_cj_review_registrationFailedModal_changedFee_line3 = "We apologize for any inconvenience.";
var APPB2C_cj_review_registrationFailedModal_changedFee_btn = "Show new prices";
var APPB2C_cj_review_registrationFailedModal_cta = "Restart booking";
var APPB2C_cj_finishPayment_processing = "We are processing your payment...";
var APPB2C_cj_finishPayment_pleaseWait = "Please wait";
var APPB2C_cj_finishPayment_error = "We cannot process your payment right now. Please try again later.";
var APPB2C_cj_bookingComplete_title = "Booking complete";
var APPB2C_cj_bookingComplete_yourBooking = "Your booking";
var APPB2C_cj_bookingComplete_note = "Congratulations, your IELTS test is booked. We have sent you a confirmation of this booking to your email address (don\u2019t forget to check your spam folder!).";
var APPB2C_cj_bookingComplete_noteUolPassportInfo = "Your booking is subject to your passport being verified. We will contact you soon to do this.";
var APPB2C_cj_bookingComplete_paymentFailed = "Sorry \u2013 your online payment failed! We\u2019ve still booked your test for you, but you need to try and pay again for your test.";
var APPB2C_cj_bookingComplete_tryAgain = "Try payment again";
var APPB2C_cj_bookingComplete_otherPaymentOptions = "See other payment options";
var APPB2C_cj_bookingComplete_paymentOptions = "See payment options";
var APPB2C_cj_bookingComplete_loadingRegistrationError = "We cannot load your registration details right now. Please try again later.";
var APPB2C_cj_bookingComplete_loadingPaymentMethodsError = "We cannot load available payment methods right now. Please try again later.";
var APPB2C_cj_bookingComplete_feeDetails_title = "Fee details";
var APPB2C_cj_bookingComplete_feeDetails_fee = "Fee";
var APPB2C_cj_bookingComplete_feeDetails_newFee = "New fee";
var APPB2C_cj_bookingComplete_feeDetails_tax = "Tax";
var APPB2C_cj_bookingComplete_feeDetails_discount = "Promotion";
var APPB2C_cj_bookingComplete_feeDetails_promo = "Promo";
var APPB2C_cj_bookingComplete_feeDetails_total = "Total";
var APPB2C_cj_bookingComplete_feeDetails_reference = "Reference";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine1 = "The test fee has been discounted to";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine2 = "by using a promotional code.";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine3 = "You are not required to make any payment.";
var APPB2C_cj_bookingComplete_prepareNote_title = "Prepare for your test";
var APPB2C_cj_bookingComplete_prepareNote_note = "The first step making sure you are ready to take IELTS is to prepare for it so you know what to expect on the test day.";
var APPB2C_cj_bookingComplete_prepareNote_accesNote = "Free access to your IELTS study materials and resources from the British Council.";
var APPB2C_cj_bookingComplete_prepareNote_view = "View preparation materials";
var APPB2C_cj_bookingComplete_prepareNote_counselling_note = "With our IELTS Counselling packages, you will get the chance to get the optimum support from our IELTS experts who will guide and advise you on how to best prepare for your test to achieve your desired score.";
var APPB2C_cj_bookingComplete_prepareNote_counselling_view = "View our counselling service";
var APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote = "Please note";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1 = "You now have {{days}} working day(s) to pay. Your test place will be cancelled if you do not pay within this time.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours = "You now have {{hours}} hours to pay. Your test place will be cancelled if you do not pay within this time.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft = "You have until {{date}} to pay. Your test place will be cancelled if you do not pay within this time.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line2 = "You will then need to log back in to this site to upload your proof of payment.";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line1 = "The test fee has been discounted to";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line2 = "To confirm your booking, please make a payment for the remaining fee";
var APPB2C_cj_bookingComplete_offlinePayment_fee = "Fee";
var APPB2C_cj_bookingComplete_offlinePayment_incTax = "inc. tax";
var APPB2C_cj_bookingComplete_offlinePayment_name = "Account name";
var APPB2C_cj_bookingComplete_offlinePayment_number = "Account number";
var APPB2C_cj_bookingComplete_offlinePayment_code = "Sort code";
var APPB2C_cj_bookingComplete_offlinePayment_reference = "Payment reference";
var APPB2C_cj_bookingComplete_offlinePayment_description = "Payment description:";
var APPB2C_cj_bookingComplete_offlinePayment_showDescription = "Show payment description";
var APPB2C_cj_bookingComplete_offlinePayment_hideDescription = "Hide payment description";
var APPB2C_cj_bookingComplete_tnc = "You agreed to these";
var APPB2C_cj_bookingComplete_tncLink = "terms and conditions";
var APPB2C_cj_ukviStartPage_title = "IELTS for UK Visas";
var APPB2C_cj_ukviStartPage_note1_title = "Please note:";
var APPB2C_cj_ukviStartPage_note1_content = "If you are NOT booking an IELTS test to support a UK Visas and Immigration application, <0>please see the other IELTS tests we offer</0>.";
var APPB2C_cj_ukviStartPage_testsBelow = "The IELTS tests below have been approved for UK Visas and Immigration purposes:";
var APPB2C_cj_ukviStartPage_ac_title = "IELTS for UKVI Academic";
var APPB2C_cj_ukviStartPage_ac_content = "This test is for test takers wishing to study at undergraduate or postgraduate levels, and for those seeking professional registration in the UK.";
var APPB2C_cj_ukviStartPage_gt_title = "IELTS for UKVI General Training";
var APPB2C_cj_ukviStartPage_gt_content = "This test is for test takers wishing to migrate to the UK and for those wishing to train or study below degree level.";
var APPB2C_cj_ukviStartPage_lifeSkils_title = "IELTS Life Skills";
var APPB2C_cj_ukviStartPage_lifeSkils_visaTypes = "This is the test to take if you are applying for the following types of UK visa:";
var APPB2C_cj_ukviStartPage_lifeSkils_family = "family visa";
var APPB2C_cj_ukviStartPage_lifeSkils_extension = "extension to family, spouse or partner visa";
var APPB2C_cj_ukviStartPage_lifeSkils_citizenship = "indefinite leave to remain or citizenship.";
var APPB2C_cj_ukviStartPage_lifeSkils_note_title = "Please note:";
var APPB2C_cj_ukviStartPage_lifeSkils_note_content = "If you have taken an IELTS Life Skills test within the last 2 years and passed it, you should not take the exact same test.";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_title = "IELTS Life Skills A1";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_content = "This test is for those who need to prove their English speaking and listening skills as part of their application to UK Visas and Immigration for 'family of a settled person' visas.";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_title = "IELTS Life Skills A2 (UK only)";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_content = "This test is for those who need to prove their English speaking and listening skills as part of their application to UK Visas and Immigration for extension to Family, Spouse or Partner visa.";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_title = "IELTS Life Skills B1";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_content = "This test is for those who need to prove their English speaking and listening skills as part of their application to UK Visas and Immigration for indefinite leave to remain or citizenship.";
var APPB2C_cj_ukviStartPage_whichUkvi = "Which IELTS for UK Visas test is right for me?";
var APPB2C_cj_ukviStartPage_terms = "Before you proceed, please read the <0>IELTS for UK Visas Terms and Conditions</0>.";
var APPB2C_cj_onRequest_note = "There are currently no IELTS for UKVI test dates available for this location.";
var APPB2C_cj_onRequest_info1 = "Complete the form to register your interest in sitting for an IELTS for UKVI test. The information you provide will be reviewed and we will contact you to follow up on this request.";
var APPB2C_cj_onRequest_prefNote = "The British Council will make every attempt to accommodate your preferred choice. If your preferred choice is not available, you will be offered an alternative option.";
var APPB2C_cj_onRequest_please = "Please note";
var APPB2C_cj_onRequest_form_labels_testRangeQuestion = "How soon would you like to take the test?";
var APPB2C_cj_onRequest_form_labels_firstname = "First / given names (including middle names)";
var APPB2C_cj_onRequest_form_labels_surname = "Surname / family name";
var APPB2C_cj_onRequest_form_labels_telephone = "Mobile number";
var APPB2C_cj_onRequest_form_labels_email = "Your email";
var APPB2C_cj_onRequest_form_labels_confirmEmail = "Confirm email address";
var APPB2C_cj_onRequest_form_submit = "Register my interest";
var APPB2C_cj_onRequest_form_testRangeOpt_oneMonth = "in the next 28 days";
var APPB2C_cj_onRequest_form_testRangeOpt_twoMonths = "within the next 2 months";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonths = "within the next 3 months";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus = "in more than 3 months";
var APPB2C_cj_onRequest_otherLocations_info1 = "You have two options to help you book a test date:";
var APPB2C_cj_onRequest_otherLocations_choose = "Choose a different location";
var APPB2C_cj_onRequest_otherLocations_info2 = "We have IELTS for UKVI tests in the following locations.";
var APPB2C_cj_onRequest_otherLocations_show = "+ Show more";
var APPB2C_cj_onRequest_otherLocations_hide = "- Hide";
var APPB2C_cj_onRequest_subtitles_register = "Register your interest for taking your test in";
var APPB2C_cj_onRequest_subtitles_aboutYou = "About you";
var APPB2C_cj_onRequest_subtitles_aboutPreferences = "About your test preferences";
var APPB2C_cj_onRequest_subtitles_markpref = "Your marketing preferences";
var APPB2C_cj_onRequest_created_title = "Your request has been received";
var APPB2C_cj_onRequest_created_note = "We have registered your interest in sitting for IELTS for UKVI test in ";
var APPB2C_cj_onRequest_created_in = "in";
var APPB2C_cj_onRequest_created_info = "The information you provide will be reviewed and we will contact you to follow up on this request within 48-72 hours.";
var APPB2C_cj_onRequest_created_back = "< Back to all UKVI test options";
var APPB2C_cj_ieltsReady_title = "Create an account";
var APPB2C_cj_ieltsReady_note = "You\u2019ll need an account to access the <0>IELTS Ready Member area.</0>";
var APPB2C_cj_ieltsReady_submitBtnText = "Create account";
var APPB2C_cj_ieltsReady_dobMin = "You need to be over 18 years to create an account. You will need your parent or guardian to get access to IELTS Ready Member area.";
var APPB2C_cj_ieltsReady_targetScore_title = "What is your overall target score for your IELTS test?";
var APPB2C_cj_ieltsReady_targetScore_option = "I don't know yet";
var APPB2C_cj_ieltsReady_nameWarning = "When you register for an IELTS test your name <bold>must match</bold> your official Identification Document, ensure it is correct now to save time later.";
var APPB2C_cj_ieltsReady_description_line1 = "Access a wealth of IELTS Ready study materials covering grammar, vocabulary, reading, listening, and more. Improve your skills for the IELTS test, whether studying solo or with expert guidance. Get the score you want with IELTS Ready.";
var APPB2C_cj_ieltsReady_description_line2 = "Best of luck!";
var APPB2C_common_basic_cancel = "Cancel";
var APPB2C_common_basic_bookPrePaid = "Book pre-paid test";
var APPB2C_common_basic_notFound_title = "Page not found";
var APPB2C_common_basic_retake = "Retake";
var APPB2C_common_basic_notFound_msg = "The page you are looking for isn\u2019t there";
var APPB2C_common_basic_notFound_goHome = "Go to home page";
var APPB2C_common_basic_expando_show = "+ Read full notice";
var APPB2C_common_basic_expando_collapse = "- Hide full notice";
var APPB2C_common_basic_notAllowed_title = "Oooops!";
var APPB2C_common_basic_notAllowed_msg = "You are not allowed to visit this page.";
var APPB2C_common_basic_errors_title = "Oooops!";
var APPB2C_common_basic_errors_network = "Possibly you\u2019ve lost connection to the Internet. Please try to reconnect and refresh the page.";
var APPB2C_common_basic_errors_server = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_common_basic_errors_unknown = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_common_basic_errors_contactTestCentre = "Sorry, there has been a problem. Please contact your local centre to request a transfer.";
var APPB2C_common_basic_errors_noTest = "This test isn\u2019t available anymore. Please select another test instead.";
var APPB2C_common_basic_errors_unableToDownloadFile = "We\u2019ve got your files, but we\u2019re having problems displaying them.";
var APPB2C_common_basic_notLoading_title = "Unable to load page";
var APPB2C_common_basic_notLoading_note = "We could not load the requested page. Most probably you\u2019ve lost your network connection. Try to refresh the page in a few moments.";
var APPB2C_common_basic_notLoading_refresh = "Refresh";
var APPB2C_common_basic_dateField_day = "Day";
var APPB2C_common_basic_dateField_months = "Choose month";
var APPB2C_common_basic_dateField_month = "Month";
var APPB2C_common_basic_dateField_year = "Year";
var APPB2C_common_basic_bookingDetails_lrw_title = "Your Written test";
var APPB2C_common_basic_bookingDetails_osrTitle_listening = "Your Listening test";
var APPB2C_common_basic_bookingDetails_osrTitle_reading = "Your Reading test";
var APPB2C_common_basic_bookingDetails_lrw_results = "Listening, Reading & Writing";
var APPB2C_common_basic_bookingDetails_lrw_cd = "IELTS on computer at test centre";
var APPB2C_common_basic_bookingDetails_lrw_pb = "IELTS on paper";
var APPB2C_common_basic_bookingDetails_lrw_online = "Online";
var APPB2C_common_basic_bookingDetails_speaking_title = "Your Speaking test";
var APPB2C_common_basic_bookingDetails_speaking_results = "Speaking";
var APPB2C_common_basic_bookingDetails_speaking_f2f = "Face to face";
var APPB2C_common_basic_bookingDetails_speaking_live = "Face to face";
var APPB2C_common_basic_bookingDetails_speaking_remote = "Video call at the test centre";
var APPB2C_common_basic_bookingDetails_speaking_online = "Online";
var APPB2C_common_basic_bookingDetails_lifeSkills_title = "Your test";
var APPB2C_common_basic_bookingDetails_lifeSkills_results = "Listening & Speaking";
var APPB2C_common_basic_bookingDetails_arrival = "(please arrive at {{time}})";
var APPB2C_common_basic_yes = "Yes";
var APPB2C_common_basic_no = "No";
var APPB2C_common_basic_goBack = "Go back";
var APPB2C_common_basic_menu = "Menu";
var APPB2C_common_basic_choose = "Choose";
var APPB2C_common_basic_pleaseNote = "Please note:";
var APPB2C_common_basic_loading = "Loading...";
var APPB2C_common_basic_oneSecond = "One second, please";
var APPB2C_common_basic_waitReassurance = "We are almost there...";
var APPB2C_common_basic_backToTop = "Back to top";
var APPB2C_common_basic_wereSorry = "We\u2019re sorry";
var APPB2C_common_basic_failure = "Failure";
var APPB2C_common_basic_close = "Close";
var APPB2C_common_basic_copy = "Copy";
var APPB2C_common_basic_copied = "Copied";
var APPB2C_common_basic_externalLinkTitle = "(opens new window)";
var APPB2C_common_basic_forms_requiredMessage = "This field is required.";
var APPB2C_common_basic_forms_maxLengthMessage = "Max length is {{count}}.";
var APPB2C_common_basic_forms_onlyLatin = "Field should contain only latin characters.";
var APPB2C_common_basic_forms_unsupportedChars = "Field contains not supported characters set.";
var APPB2C_common_basic_forms_idNumber = "Field has to match specific format (allowed are latin letters, digits, and hyphen '-')";
var APPB2C_common_basic_forms_idMaxLength = "Document number can be at most 20 characters long.";
var APPB2C_common_basic_forms_idExpiration = "Your document expires before test term.";
var APPB2C_common_basic_forms_idProofRequired = "Proof of identification is required.";
var APPB2C_common_basic_forms_mobileLength = "Mobile number can be at most 20 characters long.";
var APPB2C_common_basic_forms_mobileBypass = "This is my correct mobile number";
var APPB2C_common_basic_forms_validMobile = "Please specify a valid mobile number.";
var APPB2C_common_basic_forms_requiredNoSurmane = "This field is required. If you do not have a surname, please put a hyphen ('-') in this field.";
var APPB2C_common_basic_forms_dobOutOfRange = "Are you sure you were born that year?";
var APPB2C_common_basic_forms_dobMin = "You need to be over 18 years old to create an account. You will need your parent or guardian to book the test for you.";
var APPB2C_common_basic_forms_dobMinIol = "Sorry \u2013 you have to be at least 18 years old to book IELTS Online. Please book IELTS in a test centre instead.";
var APPB2C_common_basic_forms_minorDobMax = "If your child is over 18, please ask them to register for an account themselves with the British Council";
var APPB2C_common_basic_forms_minorDobMin = "Children of age 11 and higher can take a test.";
var APPB2C_common_basic_forms_postCodeMessage = "Postcode / ZIP has to match 5 digits specific format (eg 07102)";
var APPB2C_common_basic_forms_postCodeNotFound = "Sorry we can\u2019t find that postcode; please check you\u2019ve entered it correctly.";
var APPB2C_common_basic_forms_firstNameMaxLength = "First / given names can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_surnameMaxLength = "Surname / family name can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_addressMaxLength = "Address can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_townMaxLength = "Town / city can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_postCodeMaxLength = "Post code can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_stateMaxLength = "State can be at most {{count}} characters long.";
var APPB2C_common_basic_ok = "OK";
var APPB2C_common_basic_change = "Change";
var APPB2C_common_basic_edit = "Edit";
var APPB2C_common_basic_add = "Add";
var APPB2C_common_basic_upload = "Upload";
var APPB2C_common_basic_validationError = "Please check all the marked questions to continue.";
var APPB2C_common_basic_on = "on";
var APPB2C_common_specialReqs_AMANU = "Amanuensis";
var APPB2C_common_specialReqs_ANTHR = "Another";
var APPB2C_common_specialReqs_BTP = "Braille test papers";
var APPB2C_common_specialReqs_BWP = "Braille word processor";
var APPB2C_common_specialReqs_EPTP = "Enlarged print test papers";
var APPB2C_common_specialReqs_ET = "Extra time";
var APPB2C_common_specialReqs_LRTV = "Lip-reading test version";
var APPB2C_common_specialReqs_SAE = "Special amplification equipment";
var APPB2C_common_specialReqs_VAS = "Voice-activated software";
var APPB2C_common_specialReqs_WP = "Word processor";
var APPB2C_common_general_next = "Next";
var APPB2C_common_general_previous = "Previous";
var APPB2C_common_general_remove = "Remove";
var APPB2C_common_general_regNotFound = "Registration not found";
var APPB2C_common_general_steps = "Step {{step}} of {{steps}}";
var APPB2C_common_general_stepsContinued = "Step {{step}} of {{steps}} continued...";
var APPB2C_common_nav_login = "Login";
var APPB2C_common_nav_ttp = "Test Taker Portal";
var APPB2C_common_nav_logout = "Log out";
var APPB2C_common_nav_logoutModal_title = "Logging out";
var APPB2C_common_nav_logoutModal_message = "Logging out will exit the Registration process.";
var APPB2C_common_nav_logoutModal_question = "Are you sure you want to continue?";
var APPB2C_common_nav_logoutModal_confirm = "OK";
var APPB2C_common_nav_hello = "Hello";
var APPB2C_common_nav_chooseLanguage = "Choose language";
var APPB2C_common_nav_bookNewTest = "Book new test";
var APPB2C_common_nav_resultsTests = "Tests & results";
var APPB2C_common_nav_myAccount = "My account";
var APPB2C_common_nav_helpAndContact = "Help & contact";
var APPB2C_common_nav_preparation = "Preparation";
var APPB2C_common_examType_ors_ac = "IELTS Academic";
var APPB2C_common_examType_ors_gt = "IELTS General Training";
var APPB2C_common_examType_ors_osr_ac = "IELTS Academic";
var APPB2C_common_examType_ors_osr_gt = "IELTS General Training";
var APPB2C_common_examType_iol_ac = "IELTS Academic (Online)";
var APPB2C_common_examType_iol_gt = "IELTS General Training (Online)";
var APPB2C_common_examType_uol_ac = "IELTS for UKVI (Academic) Online";
var APPB2C_common_examType_uol_gt = "IELTS for UKVI (General Training) Online";
var APPB2C_common_examType_ukvi_ac = "IELTS for UKVI Academic";
var APPB2C_common_examType_ukvi_gt = "IELTS for UKVI General Training";
var APPB2C_common_examType_ukvi_osr_ac = "IELTS for UKVI Academic";
var APPB2C_common_examType_ukvi_osr_gt = "IELTS for UKVI General Training";
var APPB2C_common_examType_ukvi_lfska1 = "IELTS Life Skills A1";
var APPB2C_common_examType_ukvi_lfska2 = "IELTS Life Skills A2";
var APPB2C_common_examType_ukvi_lfskb1 = "IELTS Life Skills B1";
var APPB2C_common_startPage_title = "Choose your IELTS test";
var APPB2C_common_startPage_subtitle = "Which IELTS test would you like to take?";
var APPB2C_common_startPage_ielts = "IELTS";
var APPB2C_common_startPage_ieltsUkvi = "IELTS for UK Visas";
var APPB2C_common_startPage_book = "Book";
var APPB2C_common_startPage_options_ac = "IELTS Academic";
var APPB2C_common_startPage_options_gt = "IELTS General Training";
var APPB2C_common_startPage_options_ukvi = "IELTS for UKVI or IELTS Life Skills";
var APPB2C_common_startPage_hero_title = "Tests & Results";
var APPB2C_common_startPage_hero_subtitle = "Your Test Taker portal will help you prepare for your test, manage your bookings, get your results, and use your IELTS score to continue your journey with the English language.";
var APPB2C_common_startPage_hero_cta = "Book a new test";
var APPB2C_common_startPage_whichIELTS = "Which IELTS test is right for me?";
var APPB2C_common_startPage_readTnCs = "Before you proceed, please read the IELTS";
var APPB2C_common_startPage_endNote = "The British Council is a proud co-owner of IELTS.";
var APPB2C_common_startPage_tncs = "Terms & Conditions";
var APPB2C_common_startPage_description_ac = "Take this test if you want to study at undergraduate or postgraduate level, or join a professional organisation anywhere in the world.";
var APPB2C_common_startPage_description_gt = "Take this test if you want to study at below degree level or work anywhere in the world.";
var APPB2C_common_startPage_description_ukvi = "UK government approved Secure English Language Tests for those who need a Work / Spouse Visa, or who are planning to study a course below degree level, or at a UK institution not recognised by UKVI.";
var APPB2C_common_startPage_upcoming_title = "Your upcoming tests";
var APPB2C_common_startPage_upcoming_manage = "View this booking";
var APPB2C_common_startPage_upcoming_prepare = "Prepare for this test";
var APPB2C_common_startPage_upcoming_cd = "Computer test";
var APPB2C_common_startPage_upcoming_pb = "Written test";
var APPB2C_common_startPage_upcoming_live = "Speaking test";
var APPB2C_common_startPage_upcoming_lifeSkills = "Listening & Speaking test";
var APPB2C_common_startPage_upcoming_remote = "Remote speaking test";
var APPB2C_common_startPage_upcoming_empty = "You don\u2019t have any upcoming tests";
var APPB2C_common_startPage_upcoming_noSpeaking = "No info about speaking yet";
var APPB2C_common_startPage_upcoming_noTime = "We will send you the exact time of your test no more than 48 hours before your test date.";
var APPB2C_common_startPage_upcoming_pay = "Pay for your test";
var APPB2C_common_startPage_upcoming_pastPaymentDeadline = "Payment date expired";
var APPB2C_common_startPage_upcoming_missing_id = "Upload ID document";
var APPB2C_common_startPage_upcoming_missing_medical = "Upload medical evidence";
var APPB2C_common_startPage_upcoming_missing_payment = "Upload proof of payment";
var APPB2C_common_startPage_upcoming_missing_childConsent = "Update child consent information";
var APPB2C_common_startPage_upcoming_osr_note = "Your {{skill}} Retake result will count towards your new overall band score.";
var APPB2C_common_startPage_past_title = "Your previous tests & scores";
var APPB2C_common_startPage_past_cd = "IELTS on computer";
var APPB2C_common_startPage_past_pb = "IELTS on paper";
var APPB2C_common_startPage_past_score = "You scored";
var APPB2C_common_startPage_past_osr_newScore = "Your new score";
var APPB2C_common_startPage_past_osr_originalScore = "Your original score";
var APPB2C_common_startPage_past_osr_viewRetakeLink = "View booked retake";
var APPB2C_common_startPage_past_osr_note_confirmed = "You\u2019ve booked a {{skill}} Retake to try and improve your overall band score.";
var APPB2C_common_startPage_past_osr_note_awaiting = "You\u2019re waiting for your results from your {{skill}} Retake to try and improve your overall band score.";
var APPB2C_common_startPage_past_osr_note_hasResults = "You booked a {{skill}} Retake to try and improve your overall band score. Your new score is now available.";
var APPB2C_common_startPage_past_scoreLifeSkills = "Your score";
var APPB2C_common_startPage_past_rebook = "Rebook this test with different dates";
var APPB2C_common_startPage_past_empty = "You don\u2019t have any tests";
var APPB2C_common_startPage_past_upcomingResults = "Your result will be available by {{date}}";
var APPB2C_common_startPage_recent_title = "Your recent result";
var APPB2C_common_startPage_recent_cta = "See full result";
var APPB2C_common_startPage_candidates_showingFor = "Showing test & results for: ";
var APPB2C_common_startPage_candidates_nowAdult = "We have noticed that {{name}} is now legally an adult. According to our policy they have to manage their IELTS account themselves.";
var APPB2C_common_startPage_common_cancelled = "Cancelled";
var APPB2C_common_startPage_missing_cancel = "I don\u2019t want to log in";
var APPB2C_common_startPage_missing_ttData_title = "We need to check something!";
var APPB2C_common_startPage_missing_ttData_line1 = "We can see you\u2019ve registered with us before for a different product or service.";
var APPB2C_common_startPage_missing_ttData_line2 = "So that we can log you in, please confirm your name and date of birth again.";
var APPB2C_common_startPage_missing_ttData_line3 = "Thanks!";
var APPB2C_common_startPage_missing_ttData_cta = "Confirm your details";
var APPB2C_common_startPage_missing_ttData_firstName = "First / given names (including middle names)";
var APPB2C_common_startPage_missing_ttData_lastName = "Surname / family name";
var APPB2C_common_startPage_missing_ttData_noLastName = "I don\u2019t have a surname / family name";
var APPB2C_common_startPage_missing_ttData_dob = "Date of birth";
var APPB2C_common_startPage_missing_marketing_title = "Your marketing preferences";
var APPB2C_common_startPage_missing_marketing_opts_BcOnly = "I am happy to receive updates about products, services and events provided or organised by the British Council (including members of the wider British Council group).";
var APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties = "I am happy to receive information about products, services and events organised by British Council and by third parties selected by the British Council.";
var APPB2C_common_startPage_missing_marketing_opts_DoNotSend = "Please do not send me any marketing updates.";
var APPB2C_common_startPage_missing_marketing_notes_main = "The British Council would like to use the information you provide to send details of activities, services and events (including social events) which we think are of interest.";
var APPB2C_common_startPage_missing_marketing_notes_full1 = "You may unsubscribe at any time from our emails within your \u2018My Account\u2019 area in the Test Taker Portal.";
var APPB2C_common_startPage_missing_marketing_notes_full2 = "Or you can";
var APPB2C_common_startPage_missing_marketing_notes_link = "contact us";
var APPB2C_common_startPage_missing_marketing_notes_full3 = "We will process your personal information based on your consent.";
var APPB2C_common_startPage_missing_marketing_cta = "Save";
var APPB2C_common_startPage_error_title = "Unable to load all tests";
var APPB2C_common_startPage_error_body = "There might be some more tests but we\u2019ve encountered an error while loading them. Please try to refresh the page in a few moments.";
var APPB2C_common_startPage_error_cta = "Refresh page";
var APPB2C_common_account_title = "My account";
var APPB2C_common_account_error = "Oooops, something went wrong. Please try again in a few moments.";
var APPB2C_common_account_details_name = "Your name";
var APPB2C_common_account_details_email_note = "The email address you will provide must be valid and you need to have access to it. All correspondence regarding your account and registration will be sent to this email address. From now on, you will be able to log into the portal using username or email address.";
var APPB2C_common_account_details_email_username = "Username";
var APPB2C_common_account_details_email_label = "Email";
var APPB2C_common_account_details_email_set = "Provide email";
var APPB2C_common_account_details_email_confirm = "Confirm email";
var APPB2C_common_account_details_email_save = "Save email";
var APPB2C_common_account_details_email_success = "Your email has been saved.";
var APPB2C_common_account_details_email_maxLength = "Your email is too long, max length is 60.";
var APPB2C_common_account_details_email_mismatch = "This field needs to match the Email field above exactly.";
var APPB2C_common_account_details_email_validEmail = "Please specify a valid email address.";
var APPB2C_common_account_details_psw_label = "Password";
var APPB2C_common_account_details_psw_change = "Change password";
var APPB2C_common_account_details_psw_show = "Show password";
var APPB2C_common_account_details_psw_old = "Old password";
var APPB2C_common_account_details_psw_new = "New password";
var APPB2C_common_account_details_psw_success = "Your password has been changed.";
var APPB2C_common_account_details_psw_requirements_levels_0 = "Very Weak";
var APPB2C_common_account_details_psw_requirements_levels_1 = "Weak";
var APPB2C_common_account_details_psw_requirements_levels_2 = "Medium";
var APPB2C_common_account_details_psw_requirements_levels_3 = "Strong";
var APPB2C_common_account_details_psw_requirements_levels_4 = "Very Strong";
var APPB2C_common_account_details_psw_requirements_ok = "Your password meets requirements";
var APPB2C_common_account_details_psw_requirements_mustHave = "Your password must have:";
var APPB2C_common_account_details_psw_requirements_shouldHave = "And it needs to meet 3 of the following requirements:";
var APPB2C_common_account_details_psw_requirements_strength = "Strength";
var APPB2C_common_account_details_psw_requirements_reqs_length = "8 or more characters";
var APPB2C_common_account_details_psw_requirements_reqs_lower = "Lowercase letters";
var APPB2C_common_account_details_psw_requirements_reqs_upper = "Uppercase letters";
var APPB2C_common_account_details_psw_requirements_reqs_number = "At least one number";
var APPB2C_common_account_details_psw_requirements_reqs_special = "At least one special character";
var APPB2C_common_account_details_psw_requirements_maxLength = "Your password is too long, max length is 100.";
var APPB2C_common_account_details_psw_requirements_tooWeak = "Your password is too weak.";
var APPB2C_common_account_details_subnote_address = "use the original spelling of address";
var APPB2C_common_account_details_subnote_city = "use the original spelling of town / city";
var APPB2C_common_account_marketing_title = "Your marketing preferences";
var APPB2C_common_account_marketing_success = "Your marketing preferences have been updated successfully.";
var APPB2C_common_account_smsNotificationSettings_title = "SMS notifications setting";
var APPB2C_common_account_smsNotificationSettings_mobileLabel = "SMS messages will be sent to:";
var APPB2C_common_account_smsNotificationSettings_success = "Your SMS notifications setting has been updated successfully.";
var APPB2C_common_account_terms_title = "You agreed to the account registration <0>terms and conditions</0>";
var APPB2C_common_account_terms_disclaimer = "The British Council will use the information that you are providing in connection with processing your registration. The legal basis for processing your information is agreement with our terms and conditions of registration (contract).";
var APPB2C_common_account_terms_extra_title = "Data Protection";
var APPB2C_common_account_terms_extra_line1 = "The British Council complies with data protection law in the UK and laws in other countries that meet internationally accepted standards.";
var APPB2C_common_account_terms_extra_line2 = "You have the right to ask for a copy of the information we hold on you, and the right to ask us to correct any inaccuracies in that information. If you have concerns about how we have used your personal information, you also have the right to complain to a privacy regulator.";
var APPB2C_common_account_terms_extra_line3_1 = "For detailed information, please refer to the privacy section of our website,";
var APPB2C_common_account_terms_extra_line3_2 = " or contact your local British Council office. We will keep your information for a period of 3 years from the time of collection for your registration; for marketing purposes, we will keep your information for a period of 7 years.";
var APPB2C_common_account_terms_download = "You can download your agreed Terms & Conditions version:";
var APPB2C_common_account_terms_previous = "You can find the link to the previous Terms & Conditions version here: <0>terms and conditions</0>";
var APPB2C_common_account_terms_cta = "Download T&Cs";
var APPB2C_common_helpAndContact_title = "Help & contact";
var APPB2C_common_helpAndContact_selectExamLabel = "Showing help for this test:";
var APPB2C_common_helpAndContact_ieltsReference = "IELTS reference number:";
var APPB2C_common_helpAndContact_noTests_header = "You don\u2019t have any tests to request help for.";
var APPB2C_common_helpAndContact_noTests_bookNewBtn = "Book a new test";
var APPB2C_common_helpAndContact_noTests_contactLink = "Contact details can be found on our <0>Take IELTS website</0>.";
var APPB2C_common_helpAndContact_icContent_header = "If you have any questions about your IELTS test, please contact us.";
var APPB2C_common_helpAndContact_icContent_emailLabel = "Email";
var APPB2C_common_helpAndContact_icContent_phoneLabel = "Phone";
var APPB2C_common_helpAndContact_icContent_referenceReminder = "So we can help you quicker, please quote your IELTS reference number:";
var APPB2C_common_helpAndContact_icContent_commonQuestionsHeader = "Common questions";
var APPB2C_common_helpAndContact_actionSection_icHeader = "Other ways we can help you\u2026";
var APPB2C_common_helpAndContact_actionSection_iolHeader = "What sort of support do you need?";
var APPB2C_common_helpAndContact_actionSection_faqLabel = "I have a quick question I need an answer for:";
var APPB2C_common_helpAndContact_actionSection_faqLink = "View our FAQs";
var APPB2C_common_helpAndContact_actionSection_launchChatLabel = "I need to chat to somebody online about my test:";
var APPB2C_common_helpAndContact_actionSection_launchChatBtn = "Launch live-chat";
var APPB2C_common_helpAndContact_actionSection_contactFormLabel = "I can\u2019t chat right now, but I need somebody to contact me later:";
var APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre = "Do you need more help? Click the button below to complete the contact form.";
var APPB2C_common_helpAndContact_actionSection_contactFormLink = "Complete our contact form";
var APPB2C_common_helpAndContact_form_back = "Back to help & contact";
var APPB2C_common_helpAndContact_form_title = "How can we help you with your test?";
var APPB2C_common_helpAndContact_form_label = "Please briefly outline your query";
var APPB2C_common_helpAndContact_form_complaint_title = "Please select the type of request:";
var APPB2C_common_helpAndContact_form_complaint_radio_other = "Regular request eg. a question";
var APPB2C_common_helpAndContact_form_complaint_radio_complaint = "Complaint";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle = "Find out more";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLink = "For more information on making a complaint to the British Council, see <0>Make a complaint</0>";
var APPB2C_common_helpAndContact_form_validation = "This field is required.";
var APPB2C_common_helpAndContact_form_response = "We will aim to respond to all requests by email within 48-72 hours.";
var APPB2C_common_helpAndContact_form_legal = "The British Council will use the information that you are providing in connection with processing your request. For detailed information, please refer to the <0>privacy section of our website</0>";
var APPB2C_common_helpAndContact_form_submit = "Submit contact form";
var APPB2C_common_helpAndContact_form_thanks_title = "Thanks for contacting us";
var APPB2C_common_helpAndContact_form_thanks_banner = "We have received your contact request.";
var APPB2C_common_helpAndContact_form_thanks_info = "We will aim to respond to all requests within 48-72 hours.";
var APPB2C_common_helpAndContact_form_thanks_back = "Back to my test booking";
var APPB2C_common_preparation_title = "Preparation";
var APPB2C_common_preparation_selectExamLabel = "Showing materials for this test:";
var APPB2C_common_preparation_viewTestBtn = "View this test";
var APPB2C_common_preparation_noTests_header = "You don\u2019t have any booked tests to prepare for.";
var APPB2C_common_preparation_noTests_contactLink = "Free preparation materials are available on our <0>Take IELTS website</0>.";
var APPB2C_common_draft_title = "Confirm your details";
var APPB2C_common_draft_note_line1 = "Since you last visited this site, your IELTS agent ({{agent}}) has added different details for you when they were registering you for a test.";
var APPB2C_common_draft_note_line2 = "Please check their changes below and update your details if you notice they\u2019ve made a mistake.";
var APPB2C_common_draft_note_line3 = "If you don\u2019t confirm your details, {{agent}} will not be able to register you for a test.";
var APPB2C_common_draft_difference = "Your agent added this different answer.";
var APPB2C_common_draft_edit = "Change";
var APPB2C_common_draft_personal_header = "Your details";
var APPB2C_common_draft_personal_title = "Check your personal details";
var APPB2C_common_draft_personal_note = "All personal details provided on this page must match the Test Taker's identification document used for registration and presented at the test location, as these will appear on the Test Taker's Test Report Form (TRF). Once registration is complete, you may be charged a fee if you ask us to modify any of this information before we issue the TRF. Please note that no modifications can be made after the TRF has been issued.";
var APPB2C_common_draft_personal_cta = "Update personal details";
var APPB2C_common_draft_id_header = "Your identification";
var APPB2C_common_draft_id_title = "Check your ID details";
var APPB2C_common_draft_id_note = "On the test day, you will be required to bring the same identification you are providing below.";
var APPB2C_common_draft_id_documentWithNoExpiryDate = "The document doesn\u2019t have an expiry date";
var APPB2C_common_draft_id_issuingAuthority = "Issuing authority";
var APPB2C_common_draft_id_cta = "Update ID";
var APPB2C_common_draft_cta_confirm = "Confirm my details";
var APPB2C_common_draft_cta_note = "If you don\u2019t confirm your details, {{agent}} will not be able to register you for a test. You will still be able to book tests for yourself";
var APPB2C_common_draft_cta_decline = "Don\u2019t confirm my details with my agent";
var APPB2C_common_draft_error_pastDeadline = "The deadline for your details confirmation has passed.";
var APPB2C_common_regHeader_reference = "Reference";
var APPB2C_common_regHeader_trfLoading = "Just a second...";
var APPB2C_common_regHeader_trfNumber = "TRF number";
var APPB2C_common_changeBooking_title = "Change booking";
var APPB2C_common_changeBooking_subtitle = "What do you want to change about this booking?";
var APPB2C_common_changeBooking_goBack = "I don\u2019t want to change my booking";
var APPB2C_common_changeBooking_transferNotes_1 = "Transfer and cancellation requests are subject to approval by the test centre.";
var APPB2C_common_changeBooking_transferNotes_2 = "We will aim to respond to all requests within 48-72 hours";
var APPB2C_common_changeBooking_transferNotesIol_1 = "Transfers and cancellations are subject to approval by the British Council.";
var APPB2C_common_changeBooking_transferNotesIol_2 = "Before submitting your request, please carefully read the <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_changeBooking_transfer_text = "I need to change the date, time or location of my test:";
var APPB2C_common_changeBooking_transfer_textIol = "I need to transfer the test to a different date:";
var APPB2C_common_changeBooking_transfer_cta = "Request transfer";
var APPB2C_common_changeBooking_cancel_text = "I need to cancel my test:";
var APPB2C_common_changeBooking_cancel_cta = "Request cancellation";
var APPB2C_common_transfer_title = "Transfer your booking";
var APPB2C_common_transfer_selfService_chooseDates_title = "Transfer your test";
var APPB2C_common_transfer_selfService_availableDates_title = "Available dates";
var APPB2C_common_transfer_selfService_review_test_changeLrw = "Change written exam date";
var APPB2C_common_transfer_selfService_review_tncs_agreement = "You agreed to the IELTS <0>terms and conditions</0> and <2>cancellation policy</2>";
var APPB2C_common_transfer_selfService_review_confirm_title = "Confirm transfer";
var APPB2C_common_transfer_selfService_review_confirm_proceedBtn = "Transfer test";
var APPB2C_common_transfer_selfService_review_errors_transferDisabled = "Transferring your test is no longer available. Contact your test centre for more information.";
var APPB2C_common_transfer_selfService_review_errors_noLrwSlots = "Test capacity has been exhausted for the selected date. Try selecting a different day.";
var APPB2C_common_transfer_selfService_review_errors_noDistricts = "There are no test dates available in this location anymore. Please contact the test centre to see if we can help you.";
var APPB2C_common_transfer_goBack = "Back";
var APPB2C_common_transfer_notes_1 = "Transfer requests are subject to approval by the test centre.";
var APPB2C_common_transfer_notes_2 = "IELTS tests can only be transferred once.";
var APPB2C_common_transfer_notes_3 = "You will be required to choose an alternative test day within 3 months.";
var APPB2C_common_transfer_notes_4 = "Administrative charges may apply. If the request is approved, we will send the payment instructions to you.";
var APPB2C_common_transfer_notesIol_1 = "Transfer requests are subject to approval by the British Council.";
var APPB2C_common_transfer_notesIol_2 = "Before submitting your request, please carefully read the <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_transfer_form_freeform = "Please tell us about your preferences for a new test";
var APPB2C_common_transfer_form_reasons_1 = "The type of test I booked";
var APPB2C_common_transfer_form_reasons_2 = "The location of my booking";
var APPB2C_common_transfer_form_reasons_3 = "The test date";
var APPB2C_common_transfer_form_reasons_4 = "Just the speaking part";
var APPB2C_common_transfer_form_reasons_title = "What do you need to change?";
var APPB2C_common_transfer_form_reasons_choose = "Please choose an option";
var APPB2C_common_transfer_form_reasons_other = "Other";
var APPB2C_common_transfer_contactNote = "We will contact you directly to process the transfer of your test. We will aim to respond to all requests within 48-72 hours";
var APPB2C_common_transfer_contactNoteIol = "We will contact you directly by email to process the transfer of your test. We will aim to respond to all requests within 48-72 hours";
var APPB2C_common_transfer_requestValidation = "This field is required.";
var APPB2C_common_transfer_agreement = "For full details about our transferral policy, please see our <1>terms and conditions</1>";
var APPB2C_common_transfer_cta = "Submit transfer request";
var APPB2C_common_cancelBooking_title = "Cancel your booking";
var APPB2C_common_cancelBooking_subtitle = "Need to cancel your test?";
var APPB2C_common_cancelBooking_transferNote_title = "Did you know you can transfer your booking instead to a different date or location?";
var APPB2C_common_cancelBooking_transferNote_titleIol = "Did you know you can transfer your booking instead to a different date?";
var APPB2C_common_cancelBooking_transferNote_cta = "Request transfer";
var APPB2C_common_cancelBooking_cancelNotes_1 = "Cancellation requests are subject to approval by the British Council.";
var APPB2C_common_cancelBooking_cancelNotes_2 = "Your refund may have an administration fee deducted from it.";
var APPB2C_common_cancelBooking_cancelNotes_3 = "Before submitting your request, please carefully read the <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_cancelBooking_back = "I don\u2019t want to cancel my booking";
var APPB2C_common_cancelBooking_alreadyRequested_title = "You have already requested this service.";
var APPB2C_common_cancelBooking_alreadyRequested_cta = "Back to my test booking";
var APPB2C_common_cancelBooking_checkTest = "Please check that this is the test you wish to cancel:";
var APPB2C_common_cancelBooking_reason_note_title = "Please note:";
var APPB2C_common_cancelBooking_reason_note_content = "Because your test date is less than 5 weeks away, please provide a reason for cancelling and attach relevant documentation / evidence.";
var APPB2C_common_cancelBooking_reason_form_title = "Why do you need to cancel this test?";
var APPB2C_common_cancelBooking_reason_form_choose = "Please choose an option";
var APPB2C_common_cancelBooking_reason_form_requiredReason = "Please choose a reason why you are cancelling booking.";
var APPB2C_common_cancelBooking_reason_form_freeform = "Please add more details below:";
var APPB2C_common_cancelBooking_reason_form_optional = "(optional)";
var APPB2C_common_cancelBooking_reason_form_requiredEvidence = "Evidence is required.";
var APPB2C_common_cancelBooking_upload_title = "Upload evidence";
var APPB2C_common_cancelBooking_upload_notes_1 = "You must upload a copy of the medical certificate issued by a Professional Medical Practitioner. The medical certificate must include nature of illness and other relevant information which will assist in any assessment of this application for special consideration.";
var APPB2C_common_cancelBooking_upload_notes_2 = "You must upload a copy of documentation that proves that you were unable to attend the test due to military service.";
var APPB2C_common_cancelBooking_upload_notes_3 = "You must upload a copy of documentation that proves that you were unable to attend the test due to a bereavement in your family.";
var APPB2C_common_cancelBooking_upload_notes_4 = "You must upload a copy of documentation that proves that you were unable to attend the test due to a duplicate booking.";
var APPB2C_common_cancelBooking_upload_notes_5 = "You must upload a copy of documentation that proves that you were unable to attend the test due to official commitment or travel for official purposes.";
var APPB2C_common_cancelBooking_upload_notes_6 = "You must upload a copy of documentation that proves that you were unable to attend the test due events that were outside of your control.";
var APPB2C_common_cancelBooking_upload_notes_7 = "You must upload a copy of documentation that proves that you were unable to take your test due to a technical issue on the test day.";
var APPB2C_common_cancelBooking_upload_notes_8 = "You must upload a copy of documentation that proves that you were unable to take your test due to British Council cancelling your test.";
var APPB2C_common_cancelBooking_upload_notes_10 = "You must upload a copy of documentation that proves that you were unable to take your test due to a specific reason you feel is valid.";
var APPB2C_common_cancelBooking_upload_mobile = "As it looks like you\u2019re on a mobile device, we suggest you upload a photo from your device.";
var APPB2C_common_cancelBooking_upload_requirements_title = "Please note:";
var APPB2C_common_cancelBooking_upload_requirements_note = "Your uploaded evidence should be easy to read. You can add a maximum of 4 files.";
var APPB2C_common_cancelBooking_bottomNotes_note1 = "Once we receive your request to cancel, we will contact you directly if any additional information is needed.";
var APPB2C_common_cancelBooking_bottomNotes_note2 = "Refunds are subject to approval and you will receive a confirmation of acceptance / rejection of your request within 1 week. Your refund may have an administration fee deducted from it; please read the <0>IELTS cancellation and refund policy</0>";
var APPB2C_common_cancelBooking_bottomNotes_note3 = "If approved, your refund request will be processed within 3 weeks of submission.";
var APPB2C_common_cancelBooking_bottomNotes_terms = "Please read the <0>IELTS Postponement, Cancellation & Refund policy</0> carefully before submitting your request.";
var APPB2C_common_cancelBooking_bankDetails_title = "Bank account details";
var APPB2C_common_cancelBooking_bankDetails_note = "Please provide the bank account details you wish the refund to be paid into if your cancellation request is successful.";
var APPB2C_common_cancelBooking_bankDetails_form_holderName = "Account holder\u2019s name";
var APPB2C_common_cancelBooking_bankDetails_form_holderEmail = "Email address of the account holder";
var APPB2C_common_cancelBooking_bankDetails_form_bankName = "Bank name";
var APPB2C_common_cancelBooking_bankDetails_form_bankNumber = "Bank account number";
var APPB2C_common_cancelBooking_bankDetails_form_sortCode = "Sort code";
var APPB2C_common_cancelBooking_bankDetails_form_bankAddress = "Bank branch address";
var APPB2C_common_cancelBooking_bankDetails_form_accountHolder = "Bank account holder address";
var APPB2C_common_cancelBooking_bankDetails_form_city = "Town / City";
var APPB2C_common_cancelBooking_bankDetails_form_postcode = "Postcode / ZIP";
var APPB2C_common_cancelBooking_bankDetails_form_country = "Country / territory";
var APPB2C_common_cancelBooking_bankDetails_form_changeCountry = "change country / territory";
var APPB2C_common_cancelBooking_bankDetails_form_swift = "Swift code";
var APPB2C_common_cancelBooking_bankDetails_form_invalidSwift = "Incorrect SWIFT code.";
var APPB2C_common_cancelBooking_bankDetails_form_iban = "IBAN code";
var APPB2C_common_cancelBooking_bankDetails_form_invalidIban = "Incorrect IBAN code.";
var APPB2C_common_cancelBooking_bankDetails_terms_title = "Our terms and conditions";
var APPB2C_common_cancelBooking_bankDetails_terms_consent = "I hereby consent to providing a refund by an alternative method to the original payment method and processing my refund to the bank account details provided above. I understand that the British Council is not responsible for transfers to the wrong bank account in case of inaccurate account details provided by me.";
var APPB2C_common_cancelBooking_bankDetails_privacy = "The British Council will use the information that you are providing in connection with processing your request. For detailed information, please refer to the <0>privacy section of our website.</0>";
var APPB2C_common_cancelBooking_cta_submit = "Submit cancellation request";
var APPB2C_common_cancelBooking_cta_addBankDetails = "Add your bank details";
var APPB2C_common_cancelBooking_cta_goBack = "Go back to edit cancellation reason";
var APPB2C_common_cancelBooking_error_missingFile = "Please upload an evidence supporting your reason for cancellation.";
var APPB2C_common_cancelBooking_error_requestedAlready = "A request to cancel has been made for this booking already.";
var APPB2C_common_cancelBooking_error_cancelledAlready = "This booking has been cancelled already.";
var APPB2C_common_changeBookingAck_cancel_title = "Cancellation request acknowledgement";
var APPB2C_common_changeBookingAck_cancel_respondTime = "Please allow up to 3 weeks for your refund amount to be returned to your card or credited to your bank account.";
var APPB2C_common_changeBookingAck_cancel_banner = "We have received your request to cancel your test.";
var APPB2C_common_changeBookingAck_cancel_refunds = "Refunds are subject to approval and you will receive a confirmation of acceptance / rejection of your request within 1 week.";
var APPB2C_common_changeBookingAck_cancel_contact_title = "If you have any further queries, please contact:";
var APPB2C_common_changeBookingAck_cancel_contact_titleIol = "If you have any further queries, please <0>view our help page</0>.";
var APPB2C_common_changeBookingAck_cancel_contact_phone = "Phone";
var APPB2C_common_changeBookingAck_cancel_contact_email = "Email";
var APPB2C_common_changeBookingAck_cancel_refNr = "So we can help you quicker, please quote your IELTS reference number:";
var APPB2C_common_changeBookingAck_cancel_terms = "For full details about our transferral policy, please see our <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_changeBookingAck_transfer_title = "Transfer request acknowledgement";
var APPB2C_common_changeBookingAck_transfer_banner = "Your request to speak to somebody at the test centre about transferring your test has been submitted.";
var APPB2C_common_changeBookingAck_transfer_respondTime = "We will aim to respond to all requests within 48-72 hours.";
var APPB2C_common_changeBookingAck_transfer_note = "We will contact you directly to arrange a new test date. Once you have confirmed you are happy with the new date, we will send you confirmation of your updated test booking.";
var APPB2C_common_changeBookingAck_transferIol_banner = "We have received your request to transfer your test.";
var APPB2C_common_changeBookingAck_transferIol_note = "We will contact you directly to arrange a new test date. Once you have confirmed you are happy with the new date, we will send you confirmation of your updated test booking.";
var APPB2C_common_changeBookingAck_transferIol_contact = "If you have any further queries, please <0>view our help page</0>.";
var APPB2C_common_changeBookingAck_transferIol_refNr = "So we can help you quicker, please quote your IELTS reference number:";
var APPB2C_common_changeBookingAck_transferIol_terms = "For full details about our transferral policy, please see our <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_changeBookingAck_speaking_title = "Speaking test transferred";
var APPB2C_common_changeBookingAck_speaking_banner = "Your speaking test has been transferred successfully.";
var APPB2C_common_changeBookingAck_speaking_note_title = "Please note:";
var APPB2C_common_changeBookingAck_speaking_note_line1 = "Changing your speaking test hasn\u2019t affected your written test.";
var APPB2C_common_changeBookingAck_speaking_note_line2 = "There was no charge for changing your speaking test.";
var APPB2C_common_changeBookingAck_fees = "Fees may apply. We will confirm when we contact you directly.";
var APPB2C_common_changeBookingAck_feesIol = "Administrative charges may apply. If the request is approved, we will send the payment instructions to you.";
var APPB2C_common_changeBookingAck_terms = "For full details about our transferral policy, please see our <0>terms and conditions</0>";
var APPB2C_common_changeBookingAck_back = "Back to my test booking";
var APPB2C_common_changeSpeaking_title = "Change your speaking test";
var APPB2C_common_changeSpeaking_note_title = "Please note:";
var APPB2C_common_changeSpeaking_note_line1 = "Changing your speaking test won\u2019t affect your written test.";
var APPB2C_common_changeSpeaking_note_line2 = "There is no charge for changing your speaking test.";
var APPB2C_common_changeSpeaking_note_back = "I don\u2019t want to change my booking";
var APPB2C_common_changeSpeaking_cards_title = "Your new Speaking test";
var APPB2C_common_changeSpeaking_cards_change = "Change Test";
var APPB2C_common_changeSpeaking_cards_noSlots = "There are no free slots available";
var APPB2C_common_changeSpeaking_cards_noSlots_error = "Speaking capacity has been exhausted for the selected time. Try selecting a different slot.";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_title = "This speaking test isn\u2019t available!";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line1 = "We just checked and the speaking test you just selected isn\u2019t available, sorry!";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line2 = "Please choose another speaking test instead.";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_cta = "Choose new speaking test";
var APPB2C_common_changeSpeaking_modals_noSlots_title = "Sorry \u2013 no speaking tests are available!";
var APPB2C_common_changeSpeaking_modals_noSlots_line1 = "We just checked and no other speaking tests are available, sorry!";
var APPB2C_common_changeSpeaking_modals_noSlots_line2 = "If you really need to change your speaking test, request a transfer of your test date, and then you\u2019ll be able to choose a new speaking test.";
var APPB2C_common_changeSpeaking_modals_noSlots_keepTest = "Keep speaking test";
var APPB2C_common_changeSpeaking_modals_noSlots_transferTest = "Request transfer instead";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_title = "Sorry \u2013 no speaking tests are available!";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_line1 = "We just checked and no other speaking tests are available, sorry!";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta = "Keep speaking test";
var APPB2C_common_changeSpeaking_cta = "Transfer speaking test";
var APPB2C_common_footer_terms_termsOfUse = "Terms of use";
var APPB2C_common_footer_terms_accessibility = "Accessibility";
var APPB2C_common_footer_terms_privacyAndCookies = "Privacy and cookies";
var APPB2C_common_footer_terms_dataProtection = "Data Protection";
var APPB2C_common_footer_terms_copyrightLine1 = "The United Kingdom's international organisation for cultural relations and educational opportunities.";
var APPB2C_common_footer_terms_copyrightLine2 = "A registered charity: 209131 (England and Wales) SC037733 (Scotland).";
var APPB2C_common_recreateReg_progress = "Recreating your booking";
var APPB2C_common_recreateReg_errTitle = "Recreate failed";
var APPB2C_common_recreateReg_errMsg = "We couldn\u2019t recreate this registration, but please don\u2019t worry. We will redirect you to the standard registration instead.";
var APPB2C_common_chooseTest_title = "Choose your test";
var APPB2C_common_chooseTest_difference = "What\u2019s the difference between IELTS in a test centre and IELTS Online";
var APPB2C_common_chooseTest_iol_title = "IELTS Online";
var APPB2C_common_chooseTest_iol_desc = "Take this test anytime, anywhere in a new secure way.  It combines the benefits of online testing with video monitoring to ensure a secure experience and reliable results.";
var APPB2C_common_chooseTest_iol_consent = "I confirm I am happy for IELTS Online to use facial recognition and other proctoring technologies to securely monitor me during the test.";
var APPB2C_common_chooseTest_iol_link = "Find out more about IELTS Online software";
var APPB2C_common_chooseTest_iol_cta = "Book IELTS Online";
var APPB2C_common_chooseTest_iol_note_title = "Please note:";
var APPB2C_common_chooseTest_iol_note_content = "You currently have to be over 18 to take an IELTS Online test. If you are booking a test for somebody under 18, please book IELTS in a test centre instead.";
var APPB2C_common_chooseTest_ukvi_difference = "What\u2019s the difference between IELTS for UKVI in a test centre and IELTS for UKVI Online";
var APPB2C_common_chooseTest_ukvi_online_title = "IELTS for UKVI Online";
var APPB2C_common_chooseTest_ukvi_online_cta = "Book IELTS for UKVI Online";
var APPB2C_common_chooseTest_ukvi_online_link = "Find out more about IELTS for UKVI Online software";
var APPB2C_common_chooseTest_ukvi_online_consent = "I confirm I am happy for IELTS for UKVI Online to use facial recognition and other proctoring technologies to securely monitor me during the test.";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_title = "I confirm I have a valid biometric passport with a RFID chip.";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol = "(Look for this symbol on your passport!)";
var APPB2C_common_chooseTest_ukvi_online_note_title = "Please note:";
var APPB2C_common_chooseTest_ukvi_online_note_content = "You currently have to be over 18 to take an IELTS for UKVI Online test. If you are booking a test for somebody under 18, please book IELTS for UKVI in a test centre instead.";
var APPB2C_common_chooseTest_ukvi_inCentre_title = "IELTS for UKVI in a test centre";
var APPB2C_common_chooseTest_ukvi_inCentre_descAc = "Take your IELTS for UKVI Academic test at one of our official test centres. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ukvi_inCentre_descGt = "Take your IELTS for UKVI General Training test at one of our official test centres. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ukvi_inCentre_cta = "Book IELTS for UKVI in a test centre";
var APPB2C_common_chooseTest_ors_title = "IELTS in a test centre";
var APPB2C_common_chooseTest_ors_descAc = "Take your IELTS Academic test at one of our official test centres. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ors_descGt = "Take your IELTS General Training test at one of our official test centres. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ors_cta = "Book IELTS in a test centre";
var APPB2C_common_findTest_title = "Find a test";
var APPB2C_common_findTest_subtitle = "Where would you like to take your test?";
var APPB2C_common_findTest_cta = "Search for tests";
var APPB2C_common_findTest_searchIn = "Search in:";
var APPB2C_common_findTest_changeCountry = "Change country / territory";
var APPB2C_common_findTest_changeCity = "Change city";
var APPB2C_common_findTest_langModal_title = "You\u2019ve changed the location of your test";
var APPB2C_common_findTest_langModal_continue = "You can now continue with your booking in one of the following languages:";
var APPB2C_common_findTest_langModal_choose = "You were previously browsing this site in {{languageName}}. This language isn\u2019t supported for booking tests in {{countryName}}. Please choose one of the following options instead:";
var APPB2C_common_findTest_langModal_chooseSingle = "You were previously browsing this site in {{languageName}}. This language isn\u2019t supported for booking tests in {{countryName}}. Please choose the language below instead:";
var APPB2C_common_findTest_multipleLocations = "Multiple Locations";
var APPB2C_common_findTest_selectCountry_label = "Choose a country / territory";
var APPB2C_common_findTest_selectCountry_placeholder = "Search by country / territory name";
var APPB2C_common_findTest_selectCountry_continue = "Continue booking";
var APPB2C_common_findTest_selectCountry_countryNotSupported = "To book a test in this country / territory you need to use a different site.";
var APPB2C_common_findTest_selectLocation_label = "Search city";
var APPB2C_common_findTest_selectLocation_noExamForThisCountry = "We can\u2019t find any tests in this country / territory. Please <1>contact us</1> to see if we can help you.";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation = "We can\u2019t find any tests for this organisation. Choose one of the options:";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option1 = "select a location where you want to book a test";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta = "Search for available tests";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option2 = "<1>contact us</1> and we will try to help you";
var APPB2C_common_findTest_selectLocation_closestToMe = "Closest to me";
var APPB2C_common_findTest_selectLocation_geolocationBlocked = "We can\u2019t get your location, please make sure location permission isn\u2019t blocked for this site.";
var APPB2C_common_findTest_dates_title = "When would you like to take your test?";
var APPB2C_common_findTest_dates_showAll = "Show me all dates";
var APPB2C_common_findTest_dates_showDateRange = "Let me choose the dates";
var APPB2C_common_findTest_dates_pleaseSelect = "Please use the calendar below";
var APPB2C_common_findTest_dates_selected = "Selected";
var APPB2C_common_findTest_dates_from = "From";
var APPB2C_common_findTest_dates_to = "To";
var APPB2C_common_findTest_dates_clear = "Clear";
var APPB2C_common_findTest_dates_noDatesSelected = "Sorry \u2013 there are no tests available on the dates you have selected. Please widen your date range";
var APPB2C_common_findTest_dates_noresults_title = "No test dates found";
var APPB2C_common_findTest_dates_noresults_details = "We\u2019re sorry; There are no available test dates. Please choose another location or change filters";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_title = "We\u2019re sorry; there are no available test dates";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_details = "Because you have told us you need special arrangements on the test date, we aren\u2019t able to find any test dates in the location you\u2019ve selected. Please contact us directly using the details below to find out how we can help you.";
var APPB2C_common_findTest_dates_noresultsMobileLocation_title = "No test dates found";
var APPB2C_common_findTest_dates_noresultsMobileLocation_details = "There are currently no test dates available for this location but you can register your interest in sitting for an IELTS for UK Visas test.";
var APPB2C_common_findTest_dates_noresultsMobileLocation_cta = "Register your interest";
var APPB2C_common_findTest_specialReqs_findOutMore = "Read about how we can help you";
var APPB2C_common_findTest_specialReqs_needCheckbox = "I have <1>accessibility</1> needs for the test date";
var APPB2C_common_findTest_specialReqs_list_title = "Which special arrangements do you need on the test day?";
var APPB2C_common_findTest_specialReqs_list_subtitle = "This will affect the test dates that are available. Please choose one or more options below.";
var APPB2C_common_findTest_specialReqs_other = "What special arrangements do you need";
var APPB2C_common_findTest_specialReqs_pleaseNoteMessage = "In case you need to register for a test date prior to the earliest test date available, please contact the test centre for options available. It may not be possible for the test centre to provide an earlier test date in case you need special test materials, for eg. Enlarged print test papers, Braille, lip-reading version etc.";
var APPB2C_common_findTest_examFormat_title = "Which format IELTS test would you like to take?";
var APPB2C_common_findTest_examFormat_results = "(results in {{days}} days)";
var APPB2C_common_findTest_examFormat_pb = "IELTS on paper";
var APPB2C_common_findTest_examFormat_cd = "IELTS on computer";
var APPB2C_common_findTest_examFormat_all = "I don\u2019t mind";
var APPB2C_common_findTest_residencyConfirmation_title = "Important!";
var APPB2C_common_findTest_residencyConfirmation_msg_line1 = "If you are interested in taking an <0>IELTS on Paper</0> test we must confirm that you are a resident of the country in which you are taking the test.";
var APPB2C_common_findTest_residencyConfirmation_msg_line2 = "If you are not a resident of your chosen country, <0>choose a computer test or choose a paper test located in your country of residence</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine2 = "If you are not a resident of your chosen country, <0>choose a test located in your country of residence</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine2 = "If you are not a resident of your chosen country, <0>choose a computer test</0> (available in other cities) <0>or choose a paper test located in your country of residence</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_line3 = "If you choose incorrectly, your test will be cancelled.";
var APPB2C_common_findTest_residencyConfirmation_selectDates = "Please select a date first";
var APPB2C_common_findTest_residencyConfirmation_btn = "Got it, continue searching";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_title = "You can not book this test";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line1 = "If you are not a resident of the country you selected, you cannot book a paper test. Please <0>choose a computer test or choose a paper test located in your country of residence.</0>";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line2 = "On the \u2018Book a test\u2019 page, you can use filters to change the test type. You can also return to the country selection page.";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_btn = "Change test type";
var APPB2C_common_findTest_osrInfo = "<bold>One Skill Retake</bold> is not available with IELTS on Paper, consider booking IELTS on Computer to have this option.";
var APPB2C_common_findTest_osrInfoLink = "Read more about One Skill Retake";
var APPB2C_common_findTest_iol_noLrwResults = "We\u2019re sorry; There are no available test dates for your country.";
var APPB2C_common_findTest_iol_softwareCheck_title = "Can your computer run the software needed to take the test?";
var APPB2C_common_findTest_iol_softwareCheck_desc1 = "Your IELTS Online test uses Inspera software which you will need to download on either a Windows PC or MacOS (you can\u2019t take the test on a mobile or tablet!).";
var APPB2C_common_findTest_iol_softwareCheck_desc2 = "Before you book a test with us, you must check your computer meets the recommended system requirements.";
var APPB2C_common_findTest_iol_softwareCheck_link = "Check the device requirements for the test";
var APPB2C_common_findTest_iol_softwareCheck_check = "I confirm I have checked the device and environment requirements to take the test";
var APPB2C_common_findTest_iol_organisationCheck_title = "Does your intended organisation or institute accept IELTS online?";
var APPB2C_common_findTest_iol_organisationCheck_desc = "Before you book a test with us, you must check with your organisation that they accept IELTS Online.";
var APPB2C_common_findTest_iol_organisationCheck_check = "I confirm I have checked my intended organisation(s) accept IELTS Online.";
var APPB2C_common_findTest_uol_softwareCheck_title = "Can your computer run the software needed to take the test?";
var APPB2C_common_findTest_uol_softwareCheck_desc1 = "Your IELTS for UKVI Online test uses Inspera software which you will need to download on either a Windows PC or MacOS (you can\u2019t take the test on a mobile or tablet!).";
var APPB2C_common_findTest_uol_softwareCheck_desc2 = "Before you book a test with us, you must check your computer meets the recommended system requirements.";
var APPB2C_common_findTest_uol_softwareCheck_link = "Check the device requirements for the test";
var APPB2C_common_findTest_uol_softwareCheck_check = "I confirm I have checked the device and environment requirements to take the test";
var APPB2C_common_findTest_uol_organisationCheck_title = "IELTS for UKVI Online is only accepted by UK Visa and Immigration";
var APPB2C_common_findTest_uol_organisationCheck_desc = "To take an IELTS test recognised by other organisations please <0>look at our other IELTS tests.</0>";
var APPB2C_common_findTest_uol_organisationCheck_check = "I confirm I understand IELTS for UKVI Online is only accepted by UK Visa and Immigration.";
var APPB2C_common_bookTest_title = "Book a test";
var APPB2C_common_bookTest_noDiscount_title = "Please note";
var APPB2C_common_bookTest_noDiscount_msg_used = "This voucher has already been used. You can proceed with your booking but you will have to pay the full price for the test.";
var APPB2C_common_bookTest_noDiscount_msg_reserved = "This voucher is already being used. Please finish the registration where you used it, or try again later.";
var APPB2C_common_bookTest_noDiscount_msg_countryNotMatched = "This voucher cannot be used in your selected country. Please go back to the original country selection.";
var APPB2C_common_bookTest_noDiscount_msg_notSupported = "This voucher is dedicated to another product. Please go back to the original product selection.";
var APPB2C_common_bookTest_noDiscount_msg_notFound = "The voucher is not valid.";
var APPB2C_common_bookTest_changeExamType = "Change test type";
var APPB2C_common_bookTest_loadMoreTests = "Load more tests";
var APPB2C_common_bookTest_sortBy_distance = "Distance";
var APPB2C_common_bookTest_sortBy_date = "Date";
var APPB2C_common_bookTest_sortBy_price = "Price";
var APPB2C_common_bookTest_sortBy_title = "Sort by";
var APPB2C_common_bookTest_filters_title = "Filter by";
var APPB2C_common_bookTest_filters_computer = "Computer";
var APPB2C_common_bookTest_filters_paper = "Paper";
var APPB2C_common_bookTest_filters_allDates = "All Dates";
var APPB2C_common_bookTest_filters_seeAllFilters = "See all filters";
var APPB2C_common_bookTest_filters_anyTime = "Any time of day";
var APPB2C_common_bookTest_filters_morning = "Morning";
var APPB2C_common_bookTest_filters_afternoon = "Afternoon";
var APPB2C_common_bookTest_filters_evening = "Evening";
var APPB2C_common_bookTest_filters_modal_title = "Filters";
var APPB2C_common_bookTest_filters_modal_subtitle = "Filter tests";
var APPB2C_common_bookTest_filters_modal_whichFormatTest = "Which format IELTS test would you like to take";
var APPB2C_common_bookTest_filters_modal_resultsIn = "Results in {{days}} days";
var APPB2C_common_bookTest_filters_modal_iDontMind = "I don\u2019t mind";
var APPB2C_common_bookTest_filters_modal_whichTimeOfDay = "Time of day for Listening, Reading and Writing test";
var APPB2C_common_bookTest_filters_modal_hide = "Hide";
var APPB2C_common_bookTest_filters_modal_cta = "Search results";
var APPB2C_common_bookTest_results_searchText = "We are searching for your exams...";
var APPB2C_common_bookTest_results_noResultsTitle = "Sorry";
var APPB2C_common_bookTest_results_noResultsText = "We could not find any tests that meet your needs";
var APPB2C_common_bookTest_results_noResultsGoBack = "We could not find any tests, please <1>go back</1> and try changing your location or dates";
var APPB2C_common_bookTest_results_clearFilters = "Clear filters and show all tests";
var APPB2C_common_bookTest_results_lrw = "Listening, Reading & Writing";
var APPB2C_common_bookTest_results_book = "Book Test";
var APPB2C_common_bookTest_results_bookFor = "Book for";
var APPB2C_common_bookTest_results_review = "and review";
var APPB2C_common_bookTest_results_cd = "IELTS on computer";
var APPB2C_common_bookTest_results_pb = "IELTS on paper";
var APPB2C_common_bookTest_results_changeSpeaking = "Change speaking schedule";
var APPB2C_common_bookTest_results_speaking = "Speaking test";
var APPB2C_common_bookTest_results_fromCurrentLocation = "km from current location";
var APPB2C_common_bookTest_results_viewOnMap = "View on map";
var APPB2C_common_regInProgressModal_title = "Attention!";
var APPB2C_common_regInProgressModal_message1 = "You have the registration for <bold>{{date}}</bold> in progress already. Do you really want to start over with the selected test?";
var APPB2C_common_regInProgressModal_message2 = "Your current slots will be released in this case.";
var APPB2C_common_regInProgressModal_restart = "Start over";
var APPB2C_common_regInProgressModal_continue = "Never mind";
var APPB2C_common_testDetails_mapModal_title = "Location Details";
var APPB2C_common_testDetails_mapModal_close = "Close Window";
var APPB2C_common_testDetails_title = "Test details";
var APPB2C_common_testDetails_changeWrittenTest = "Change written test";
var APPB2C_common_testDetails_yourWrittenTest = "Your Written test";
var APPB2C_common_testDetails_changeTest = "Change test";
var APPB2C_common_testDetails_yourSpeakingTest = "Your Speaking test";
var APPB2C_common_testDetails_completeYourBooking = "Complete your booking";
var APPB2C_common_testDetails_modifyNote_pleaseNote = "Please note";
var APPB2C_common_testDetails_modifyNote_note = "Although every attempt is made to ensure that we honour your test venue, date and time slot selection, we reserve the right to modify, discontinue and replace your selected preference based on availability and in accordance with the terms and conditions.";
var APPB2C_common_testDetails_modifyNote_confirm = "I understand and agree to these Terms and Conditions.";
var APPB2C_common_invitation_modal_header = "Invalid link";
var APPB2C_common_invitation_modal_invalid = "The URL you tried to use is either incorrect or no longer valid. You can continue registration without Agent context.";
var APPB2C_common_fileUpload_cta = "Choose a file";
var APPB2C_common_fileUpload_requirements = "Please only upload:";
var APPB2C_common_fileUpload_fileSize = "Files no larger than {{size}} MB";
var APPB2C_common_fileUpload_remove = "remove this file";
var APPB2C_common_fileUpload_fileApproved = "This file has been approved and cannot be deleted";
var APPB2C_common_fileUpload_fileRequired = "Image 1";
var APPB2C_common_fileUpload_fileOptional = "Image {{number}} (optional)";
var APPB2C_common_fileUpload_filesCount = "Page {{current}} of {{total}}";
var APPB2C_common_fileUpload_pdfIeMsg = "Your PDF file is successfully uploaded, however preview is not available in the Internet Explorer browser. Did you know that IE is not actively supported since 2015?";
var APPB2C_common_fileUpload_success = "File <bold><italic>{{fileName}}</italic></bold> added successfully!";
var APPB2C_common_fileUpload_pdfPasswordMsg = "Uploading secured file is disallowed.";
var APPB2C_common_fileUpload_exceededSize = "This file exceeds maximum allowed size of";
var APPB2C_common_fileUpload_imgAlt = "File Preview";
var APPB2C_common_selfServices_requested_transfer = "Transfer requested";
var APPB2C_common_selfServices_requested_cancellation = "Cancellation requested";
var APPB2C_common_filePreview_label = "Image";
var APPB2C_common_filePreview_status = "Review status";
var APPB2C_common_filePreview_pdfPreviewLink = "Preview this file";
var APPB2C_common_filePreview_statusName_pending = "Pending";
var APPB2C_common_filePreview_statusName_approved = "Approved";
var APPB2C_common_filePreview_statusName_rejected = "Rejected";
var APPB2C_common_filePreview_statusName_reviewed = "Reviewed";
var APPB2C_common_filePreview_statusName_deleted = "Deleted";
var APPB2C_common_unpaidErrorBar_paymentExpired = "Registration payment date has expired";
var APPB2C_common_alertBar_beforeTest = "Before the test day, you need to:";
var APPB2C_common_alertBar_withCreds = "You can now log in to your test:";
var APPB2C_common_alertBar_afterTest = "You still need to:";
var APPB2C_common_alertBar_regPayment = "Pay for your test";
var APPB2C_common_alertBar_regPaymentProof = "Upload proof of payment for your test";
var APPB2C_common_alertBar_eorPayment = "Pay for your EOR";
var APPB2C_common_alertBar_eorPaymentProof = "Upload proof of payment for your EOR";
var APPB2C_common_alertBar_idUpload = "Upload identification documents";
var APPB2C_common_alertBar_idReupload = "Upload identification documents again";
var APPB2C_common_alertBar_medicalUpload = "Upload medical evidence";
var APPB2C_common_alertBar_medicalReupload = "Upload medical evidence again";
var APPB2C_common_alertBar_cancelReupload = "Upload cancellation documents again";
var APPB2C_common_alertBar_childConsentMissing = "Provide child consent information";
var APPB2C_common_alertBar_check = "Take your pre-test check";
var APPB2C_common_alertBar_creds = "View my log-in details";
var APPB2C_common_updateBar_close = "Close";
var APPB2C_common_updateBar_cancelPending = "Your request to cancel this test is pending. <1>See details</1>.";
var APPB2C_common_updateBar_cancelRejected = "Your cancellation request has been rejected.";
var APPB2C_common_updateBar_cancelled = "This test has been cancelled.";
var APPB2C_common_updateBar_transferCancelled = "Your transfer request could not be processed. Try again or contact your test centre for more information.";
var APPB2C_common_updateBar_transferPending = "Your request to transfer this test is pending. <1>See details</1>.";
var APPB2C_common_updateBar_transferPendingCmds = "Your transfer is awaiting confirmation. You will be notified when your transfer request has been processed.";
var APPB2C_common_updateBar_transferred = "This test has been transferred. Your new dates are below.";
var APPB2C_common_updateBar_transferredCmds = "This test has been transferred. See booking details for new dates.";
var APPB2C_common_updateBar_termsUpdated = "You need to <1>accept new terms & conditions</1> that will be in effect for your test day. <br> All test takers attending an IELTS test will be governed by these Terms and Conditions.";
var APPB2C_common_updateBar_hasResults = "Your results are now available.";
var APPB2C_common_updateBar_eorPending = "Your Enquiry on Results (EOR) is pending. <1>See details</1>.";
var APPB2C_common_updateBar_eorPendingAgentPays = "Your Enquiry on Results (EOR) is pending.";
var APPB2C_common_updateBar_eorCompleted = "Your Enquiry on Results (EOR) has been completed.";
var APPB2C_common_updateBar_eorCompleted_scoreDecreased = "Your overall band score has decreased from {{prevOverallScore}} to {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreUnchanged = "Your overall band score remains unchanged at {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreIncreased = "Your overall band score has increased from {{prevOverallScore}} to {{currentOverallScore}}";
var APPB2C_common_updateBar_eorRejected = "Your Enquiry on Result (EOR) was rejected.";
var APPB2C_common_updateBar_eorRejected_overallBand = "Your overall band score has not changed from {{overallBand}}";
var APPB2C_common_updateBar_details = "Please see full details below:";
var APPB2C_common_updateBar_eorRefundPending = "Your Enquiry on Results (EOR) fee refund is being processed.";
var APPB2C_common_updateBar_eorRefunded = "Your Enquiry on Results (EOR) fee has been refunded.";
var APPB2C_common_updateBar_transactionRefundRequestPending = "Your refund request is pending.";
var APPB2C_common_updateBar_transactionRefundRequestAccepted = "Your refund request has been accepted.";
var APPB2C_common_updateBar_transactionRefundRequestRejected = "Your refund request has been rejected.";
var APPB2C_common_unknownCountry_title = "We can\u2019t find you!";
var APPB2C_common_unknownCountry_header = "We can\u2019t detect the country that you\u2019re browsing from :(";
var APPB2C_common_unknownCountry_msg1 = "Sometimes this happens because your device is using software that hides your location.";
var APPB2C_common_unknownCountry_msg2 = "If you are using a VPN or similar tools that hide your location, please turn them off and try again.";
var APPB2C_common_unknownCountry_cta = "I\u2019ve turned off my VPN";
var APPB2C_common_underConstruction_title = "Under construction...";
var APPB2C_common_underConstruction_message = "Hi. You\u2019re visiting this page because you\u2019re part of our pilot to test this booking website (thanks for helping!) Don\u2019t worry, before we go live we\u2019ll make sure we show our real customers some proper content here :)";
var APPB2C_common_terms_title = "Terms and conditions";
var APPB2C_common_terms_downloadTitle = "Terms and conditions PDF download";
var APPB2C_common_terms_downloadInfo = "Your Terms and Conditions PDF download will begin shortly.";
var APPB2C_common_terms_error = "Oooops, something went wrong. Please try again in a few moments.";
var APPB2C_common_modals_newTermsAndConditions_trigger = "Accept new terms and conditions";
var APPB2C_common_modals_newTermsAndConditions_title = "New terms & conditions";
var APPB2C_common_modals_newTermsAndConditions_note = "Please accept new terms & conditions before proceeding";
var APPB2C_common_modals_newTermsAndConditions_checkboxNote = "I agree to the {{context}}";
var APPB2C_common_modals_newTermsAndConditions_checkboxTC = "terms and conditions";
var APPB2C_common_modals_newTermsAndConditions_cta = "Proceed";
var APPB2C_common_modals_newTermsAndConditions_error = "Oooops, something went wrong. Please try again in a few moments.";
var APPB2C_common_gelRedirect_header = "Prepare for your test";
var APPB2C_common_gelRedirect_info = "We can help you prepare for IELTS with our range of free resources.";
var APPB2C_common_gelRedirect_errorMessage = "This resource is currently unavailable.";
var APPB2C_registration_tabs_bookingDetails = "Booking details";
var APPB2C_registration_tabs_date = "Date";
var APPB2C_registration_tabs_dateLocation = "Date & location";
var APPB2C_registration_tabs_candidate = "Test Taker";
var APPB2C_registration_tabs_results = "Results";
var APPB2C_registration_tabs_payments = "Payments";
var APPB2C_registration_tabs_recognisingOrgs = "Recognising Organisations";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_1 = "Please contact the test centre directly about sending your results to Recognising Organisations:";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_2 = "Please quote your registration reference number for us to help you better:";
var APPB2C_registration_date_zone_header = "When you booked your test";
var APPB2C_registration_date_zone_country = "We localised you in";
var APPB2C_registration_date_zone_time = "Your local time";
var APPB2C_registration_date_zone_name = "Your timezone";
var APPB2C_registration_date_check_back = "Back to the test details";
var APPB2C_registration_date_check_beforeTestDate = "Before the test day";
var APPB2C_registration_date_check_intro = "Before your test day, please make sure you do the following things:";
var APPB2C_registration_date_check_step1_title = "Step 1 \u2013 Device check";
var APPB2C_registration_date_check_step1_body = "Check the laptop or desktop computer you intend to take the test on meets the minimum requirement (PC or Mac only \u2013 you cannot take IELTS Online on mobile or tablet).";
var APPB2C_registration_date_check_step1_link = "Check your device meets the requirements";
var APPB2C_registration_date_check_step2_title = "Step 2 \u2013 Install software";
var APPB2C_registration_date_check_step2_body = "Before the test date, you will need to install Inspera software on the PC or Mac you intend to take the test on.";
var APPB2C_registration_date_check_step2_link = "Download Inspera software";
var APPB2C_registration_date_check_onTestDate = "On the test day";
var APPB2C_registration_date_check_step3_body1 = "Once you have Inspera installed on your device, open the software and login. To login you just need your username and password below \u2013 you can simply copy and paste them in.";
var APPB2C_registration_date_check_step3_noDetailsTitle = "Your login details are not available yet";
var APPB2C_registration_date_check_step3_noDetailsBody = "They will be available nearer the test date.";
var APPB2C_registration_date_check_step3_username = "Your username:";
var APPB2C_registration_date_check_step3_password = "Your password:";
var APPB2C_registration_date_check_step3_body2 = "Once you\u2019re logged-in, you\u2019ll be taken through the following steps:";
var APPB2C_registration_date_check_step3_step1_title = "Step 1 \u2013 Device check";
var APPB2C_registration_date_check_step3_step1_body = "You\u2019ll be asked to check if your microphone and camera are working.";
var APPB2C_registration_date_check_step3_step2_title = "Step 2 \u2013 Identification";
var APPB2C_registration_date_check_step3_step2_body = "You\u2019ll be asked to take a photo of your face and your identification document (make sure you have the same identification document you originally registered with).";
var APPB2C_registration_date_check_step3_step3_title = "Step 3 \u2013 Test login";
var APPB2C_registration_date_check_step3_step3_body = "To access your IELTS Online test, you will be asked for a PIN.";
var APPB2C_registration_date_check_step3_speakingPin = "Your Speaking test PIN:";
var APPB2C_registration_date_check_step3_writtenPin = "Your Written test PIN:";
var APPB2C_registration_date_check_step3_noSpeakingPinTitle = "Your speaking test PIN is not available yet";
var APPB2C_registration_date_check_step3_noSpeakingPinBody = "This will be available nearer the test date.";
var APPB2C_registration_bookingDetails_seeTestTaker = "See Test Taker details";
var APPB2C_registration_bookingDetails_payment = "Payment";
var APPB2C_registration_bookingDetails_seePayment = "See Payment details";
var APPB2C_registration_bookingDetails_whatNext = "What can I do next?";
var APPB2C_registration_bookingDetails_results = "Find out about Results";
var APPB2C_registration_dateLocation_changeLinkIol = "I want to change my test";
var APPB2C_registration_dateLocation_changeLrwLink = "I want to change my written test";
var APPB2C_registration_dateLocation_changeSpeakingLink = "I want to change my speaking test";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader = "Sorry \u2013 no speaking tests are available!";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody1 = "We just checked and no other speaking tests are available, sorry!";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody2 = "If you really need to change your speaking test, request a transfer of your test date, and then you\u2019ll be able to choose a new speaking test.";
var APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest = "Keep speaking test";
var APPB2C_registration_dateLocation_speakingTransfer_requestTransfer = "Request transfer instead";
var APPB2C_registration_dateLocation_location = "Location";
var APPB2C_registration_dateLocation_manage_title = "Manage your booking";
var APPB2C_registration_dateLocation_manage_transferSpeaking = "Change speaking";
var APPB2C_registration_dateLocation_manage_transferWhole = "Transfer test date";
var APPB2C_registration_dateLocation_manage_cancel = "Cancel my booking";
var APPB2C_registration_testTaker_bookedFor_title = "This test is booked for:";
var APPB2C_registration_testTaker_bookedFor_completed = "This test was taken by:";
var APPB2C_registration_testTaker_bookedFor_parent = "Parent / Guardian:";
var APPB2C_registration_testTaker_bookedFor_name = "Name";
var APPB2C_registration_testTaker_bookedFor_dob = "Date of Birth";
var APPB2C_registration_testTaker_bookedFor_gender = "Sex";
var APPB2C_registration_testTaker_bookedFor_email = "Email address";
var APPB2C_registration_testTaker_contact_title = "Contact details:";
var APPB2C_registration_testTaker_contact_parent = "Parent / Guardian\u2019s contact details:";
var APPB2C_registration_testTaker_contact_edit = "Edit contact details";
var APPB2C_registration_testTaker_contact_address = "Address";
var APPB2C_registration_testTaker_contact_mobile = "Mobile telephone number";
var APPB2C_registration_testTaker_consent_title = "Consent:";
var APPB2C_registration_testTaker_consent_edit = "Edit consent";
var APPB2C_registration_testTaker_consent_missing = "Please provide child consent information.";
var APPB2C_registration_testTaker_childConsent_title = "Child consent";
var APPB2C_registration_testTaker_childConsent_back = "I don\u2019t want to edit child consent";
var APPB2C_registration_testTaker_childConsent_warning = "Children aged 11 years or older are only permitted to leave test premises alone once parental / carer consent has been given";
var APPB2C_registration_testTaker_childConsent_context = "Do you give permission for your child to leave the test premises unaccompanied during a test break between modules and at the end of the test?";
var APPB2C_registration_testTaker_childConsent_options_1 = "Yes; I give my permission";
var APPB2C_registration_testTaker_childConsent_options_2 = "No; I will pick them up";
var APPB2C_registration_testTaker_childConsent_options_3 = "No; I want to specify somebody else who will pick them up";
var APPB2C_registration_testTaker_childConsent_cta = "Save consent details";
var APPB2C_registration_testTaker_childConsent_somebodyElse_note = "Please provide the details for the person(s) picking up the child. If different people are picking up the child on the day of the Written test and the day of the Speaking test, please add their names below.";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_1 = "About the person(s) picking up the child";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_2 = "About the second person";
var APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName = "They don\u2019t have a surname / family name";
var APPB2C_registration_testTaker_childConsent_somebodyElse_relationship = "Relationship to the child";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phone = "Telephone number";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote = "In case of an emergency";
var APPB2C_registration_testTaker_childConsent_somebodyElse_add = "+ Add another person";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_1 = "Do you confirm that the person(s) listed are authorised to collect";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_2 = "at the end of the test, and they are over 14 years old?";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox = "Yes, I confirm";
var APPB2C_registration_testTaker_childConsent_somebodyElse_policy = "Read our full child safe collection protection policy";
var APPB2C_registration_testTaker_editContact_note = "Please note";
var APPB2C_registration_testTaker_editContact_warn = "Updates to the contact details will be changed in the test booking(s):";
var APPB2C_registration_testTaker_editContact_back = "I don\u2019t want to change my contact details";
var APPB2C_registration_testTaker_editContact_country_residence = "Country / territory of residence";
var APPB2C_registration_testTaker_editContact_country_change = "change country / territory";
var APPB2C_registration_testTaker_editContact_address = "Address";
var APPB2C_registration_testTaker_editContact_city = "Town / City";
var APPB2C_registration_testTaker_editContact_postCode = "Postcode / ZIP";
var APPB2C_registration_testTaker_editContact_state = "State";
var APPB2C_registration_testTaker_editContact_mobile = "Mobile telephone number";
var APPB2C_registration_testTaker_editContact_cta = "Update details";
var APPB2C_registration_testTaker_editContact_error_missingCountry = "Please provide your country.";
var APPB2C_registration_testTaker_editContact_error_missingAddress = "Please provide your address.";
var APPB2C_registration_testTaker_editContact_error_pastCutoff = "Editing your contact details is no longer available.";
var APPB2C_registration_testTaker_id_title = "Identification details:";
var APPB2C_registration_testTaker_id_child = "Child\u2019s identification details:";
var APPB2C_registration_testTaker_id_typeNames_passport = "passport";
var APPB2C_registration_testTaker_id_typeNames_card = "identity card";
var APPB2C_registration_testTaker_id_typeNames_other = "ID";
var APPB2C_registration_testTaker_id_idNumber = "$t({{idName}}, capitalise) number";
var APPB2C_registration_testTaker_id_expiryDate = "$t({{idName}}, capitalise) expiry date";
var APPB2C_registration_testTaker_id_issuingAuthority = "Issuing authority";
var APPB2C_registration_testTaker_id_fileAlt = "Uploaded proof of ID";
var APPB2C_registration_testTaker_id_noFilesTitle = "No proof";
var APPB2C_registration_testTaker_id_noFilesBody = "You have not uploaded any proof of ID.";
var APPB2C_registration_testTaker_id_edit_title = "Upload proof of $t({{idName}})";
var APPB2C_registration_testTaker_id_edit_subtitle = "Please upload proof of $t({{idName}}) shown below:";
var APPB2C_registration_testTaker_id_edit_rules_example = "See example of proof of $t({{idName}})";
var APPB2C_registration_testTaker_id_edit_upload_exampleProof = "Example proof";
var APPB2C_registration_testTaker_id_edit_upload_imgAlt = "Proof of ID";
var APPB2C_registration_testTaker_files_editButton_add = "Upload your files";
var APPB2C_registration_testTaker_files_editButton_edit = "Edit uploaded files";
var APPB2C_registration_testTaker_files_upload_note = "You can upload {{count}} files (you must upload at least 1)";
var APPB2C_registration_testTaker_files_upload_mobile_title = "Please note:";
var APPB2C_registration_testTaker_files_upload_mobile_line1 = "As it looks like you\u2019re on a mobile device, we suggest you upload a photo from your device.";
var APPB2C_registration_testTaker_files_upload_mobile_line2 = "If you\u2019re unable to do this on your mobile device, please log back in on a computer.";
var APPB2C_registration_testTaker_files_upload_cta = "Save your files";
var APPB2C_registration_testTaker_files_upload_back_title = "back to Test Taker";
var APPB2C_registration_testTaker_files_upload_back_subtitle = "(this won\u2019t upload or remove any files)";
var APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded = "You have exceeded the number of files you can upload. Please remove files over the limit and try again.";
var APPB2C_registration_testTaker_files_upload_error_wrongFileType = "You are trying to upload a file in an unsupported format. Please remove it and choose a different file.";
var APPB2C_registration_testTaker_files_upload_error_wrongFileName = "The file you are trying to upload has a name containing unsupported characters. Please remove it, rename it locally and try uploading it again.";
var APPB2C_registration_testTaker_files_upload_error_fileTooLarge = "The file you are trying to upload is too large. Please remove it and choose a different file.";
var APPB2C_registration_testTaker_files_upload_error_uploadUnavailable = "Uploading files is not available for this booking.";
var APPB2C_registration_testTaker_specialArrangements_title = "Special arrangements:";
var APPB2C_registration_testTaker_specialArrangements_description = "You have requested the following special arrangements:";
var APPB2C_registration_testTaker_specialArrangements_noFilesTitle = "No evidence";
var APPB2C_registration_testTaker_specialArrangements_noFilesBody = "You have not uploaded any medical evidence.";
var APPB2C_registration_testTaker_specialArrangements_fileAlt = "Uploaded medical evidence";
var APPB2C_registration_testTaker_specialArrangements_edit_title = "Upload medical evidence";
var APPB2C_registration_testTaker_specialArrangements_edit_subtitle = "When you booked your test you indicated that you needed special arrangements.";
var APPB2C_registration_testTaker_specialArrangements_edit_line1 = "Special arrangements needed:";
var APPB2C_registration_testTaker_specialArrangements_edit_line2 = "All applications for special requirements must be supported by medical evidence.";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_title = "The medical certificate should:";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule1 = "be accessible to an IELTS administrator in terms of language and legibility";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule2 = "should be an original document on headed paper, and bearing the name, relevant qualification(s) and signature of a recognised practitioner";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule3 = "should give a clear statement of your special needs";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule4 = "should justify the special arrangements you need";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule5 = "should be issued no more than two years before the test date";
var APPB2C_registration_testTaker_error = "Editing your details is no longer available for this booking.";
var APPB2C_registration_results_hasResults_viewExplanation = "View full scores and explanation";
var APPB2C_registration_results_hasResults_share_title = "Got the score you needed?";
var APPB2C_registration_results_hasResults_share_subtitle = "Here\u2019s what you can do now:";
var APPB2C_registration_results_hasResults_share_trfCta = "Download your Test Report Form";
var APPB2C_registration_results_hasResults_share_RecognisingOrgsLink = "Share results with organisations";
var APPB2C_registration_results_hasResults_share_applyBoard = "Apply directly to programs";
var APPB2C_registration_results_hasResults_scores_Listening = "Listening";
var APPB2C_registration_results_hasResults_scores_Reading = "Reading";
var APPB2C_registration_results_hasResults_scores_Writing = "Writing";
var APPB2C_registration_results_hasResults_scores_Speaking = "Speaking";
var APPB2C_registration_results_hasResults_scores_Overall = "Your overall band score";
var APPB2C_registration_results_hasResults_scores_label = "Your full score and explanation";
var APPB2C_registration_results_hasResults_lifeSkills_result = "Result";
var APPB2C_registration_results_hasResults_lifeSkills_level = "CEFR level";
var APPB2C_registration_results_hasResults_lifeSkills_passed = "Passed";
var APPB2C_registration_results_hasResults_lifeSkills_failed = "Failed";
var APPB2C_registration_results_hasResults_lifeSkills_note = "Your results available online are only provisional and should not be used as an official confirmation of your performance. The IELTS Test Report Form (TRF) is the official document confirming your results.";
var APPB2C_registration_results_hasResults_rankNameConsonant = "This means you\u2019re a";
var APPB2C_registration_results_hasResults_rankNameVowel = "This means you\u2019re an";
var APPB2C_registration_results_hasResults_explanations_heading = "Your scores explained:";
var APPB2C_registration_results_hasResults_explanations_improve = "How to improve:";
var APPB2C_registration_results_hasResults_posting_postedOn = "Your Test Report Form has been posted on:";
var APPB2C_registration_results_hasResults_posting_willPostOn = "Your Test Report Form will be posted on:";
var APPB2C_registration_results_hasResults_posting_postedTo = "It has been posted to:";
var APPB2C_registration_results_hasResults_posting_willPostTo = "It will be posted to:";
var APPB2C_registration_results_hasResults_posting_oneCopyWarning = "Only one copy is sent out, so keep it safe!";
var APPB2C_registration_results_hasResults_eorLink = "I would like my test to be re-marked";
var APPB2C_registration_results_hasResults_eorLine1 = "Your re-mark has been completed.";
var APPB2C_registration_results_hasResults_eorLine2 = "Your score is available below. Re-marked sections are highlighted for easy reference.";
var APPB2C_registration_results_hasResults_eorLetter = "Please check your <0>EOR Results Outcome Letter<1></1></0> for further details.";
var APPB2C_registration_results_hasResults_scoreImprove_title = "Didn\u2019t get the score you needed?";
var APPB2C_registration_results_hasResults_scoreImprove_subtitle = "If you didn\u2019t get the score you needed, you might be able to improve it.";
var APPB2C_registration_results_hasResults_scoreImprove_btn = "See my options";
var APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle = "If you\u2019d like to improve your overall band score, you have the following options:";
var APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn = "Back to test result details";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title = "Book a new test";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description = "If you\u2019re not happy with your score on more than one section of the test, you can quickly book a new test with us.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title = "Retake part of your test";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description = "If you\u2019re not happy with your score on any one section of the test and believe this was due to your performance on test day, you can register for a One Skill Retake.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title = "Re-marking your test";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description = "If you\u2019re not happy with the way your test was marked, and you would like to have any section(s) of the test re-marked, you can apply for an Enquiry on Results, up to {{count}} days after your test date.";
var APPB2C_registration_results_hasResults_bandScore_increase = "increase";
var APPB2C_registration_results_hasResults_bandScore_decrease = "decrease";
var APPB2C_registration_results_hasResults_bandScore_unchanged = "unchanged";
var APPB2C_registration_results_updateBar_osr_marked = "Your retake of your {{osrSkillName}} test has been marked. <1>View result</1>";
var APPB2C_registration_results_updateBar_osr_booked = "You have booked to retake your {{osrSkillName}} test. <1>View this booking</1>";
var APPB2C_registration_results_hasResults_osr_updateBar_title = "Your {{osrSkillName}} Retake result ({{score}}) has been counted towards your new overall band score.";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased = "Your overall band score has increased from {{originalScore}} to {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased = "Your overall band score has decreased from {{originalScore}} to {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged = "Your overall band score remains unchanged at {{originalScore}}";
var APPB2C_registration_results_hasResults_osr_previousResults = "See previous test result in full";
var APPB2C_registration_results_bandScore_osrBooked = "Retake booked <1>View booking</1>";
var APPB2C_registration_results_bandScore_osrMarked = "Retake marked <1>View result</1>";
var APPB2C_registration_results_eor_title = "Re-marking your test (Enquiry on Results)";
var APPB2C_registration_results_eor_titlePayment = "Paying for your re-mark (EOR)";
var APPB2C_registration_results_eor_preDeadline_line1 = "If you\u2019re not happy with the way your test was marked, and you would like to have any section(s) of the test re-marked, you can apply for an Enquiry on Results, up to {{count}} days after your test date.";
var APPB2C_registration_results_eor_preDeadline_paymentTitle = "Paying for your re-mark";
var APPB2C_registration_results_eor_preDeadline_line2 = "There is a charge for an enquiry on IELTS test results, however this charge will be refunded if your score increases for any selected section of the test.";
var APPB2C_registration_results_eor_preDeadline_note = "Once you\u2019ve paid, you cannot cancel or change your EOR request.";
var APPB2C_registration_results_eor_preDeadline_fee = "Fee";
var APPB2C_registration_results_eor_preDeadline_tax = "inc. tax";
var APPB2C_registration_results_eor_preDeadline_back = "Back to result";
var APPB2C_registration_results_eor_preDeadline_scoresTitle = "Please choose one or more parts of your test to be re-marked:";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr = "You have chosen to enquire about your";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening = "Listening Retake result";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading = "Reading Retake result";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing = "Writing Retake result";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking = "Speaking Retake result";
var APPB2C_registration_results_eor_preDeadline_trfNumberLabel = "Test Report Form (TRF) Number";
var APPB2C_registration_results_eor_preDeadline_trfNumberSubnote = "Please provide the 15\u201318 digit number found in the bottom right-hand corner of every Test Report Form.";
var APPB2C_registration_results_eor_preDeadline_trfNumberValidation = "Field has to match specific format (e.g. 11AA111110AAAA001A).";
var APPB2C_registration_results_eor_preDeadline_maxScore = "You received a full mark for this part. You cannot request a re-mark.";
var APPB2C_registration_results_eor_preDeadline_scoresRequired = "Please select at least one part.";
var APPB2C_registration_results_eor_preDeadline_explanation = "Please provide a brief explanation below why you believe you need your selected test parts re-marked";
var APPB2C_registration_results_eor_preDeadline_explanationRequired = "You must provide this explanation to support your request.";
var APPB2C_registration_results_eor_preDeadline_evidence = "Please upload evidence that supports your request for a re-mark.";
var APPB2C_registration_results_eor_preDeadline_evidenceRequired = "You must provide this evidence to support your request.";
var APPB2C_registration_results_eor_preDeadline_iolNote_line2 = "The outcome can become available on the same day as your application and up to 21 days after your application, depending on several factors including the number of sections requested for the re-mark.";
var APPB2C_registration_results_eor_preDeadline_iolNote_line3 = "If you have not received a response after 21 days, please <0>contact us</0>.";
var APPB2C_registration_results_eor_preDeadline_noteTitle = "Please note:";
var APPB2C_registration_results_eor_preDeadline_legal = "I agree to the EOR <0>terms and conditions</0>";
var APPB2C_registration_results_eor_preDeadline_legalNote = "The British Council will use the information that you are providing in connection with processing your EOR. The legal basis for processing your information is agreement with our terms and conditions of EOR.";
var APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel = "Who should receive the order confirmation for the EOR?";
var APPB2C_registration_results_eor_preDeadline_paymentCta = "View payment options";
var APPB2C_registration_results_eor_preDeadline_payOnlineCTA = "Pay online";
var APPB2C_registration_results_eor_preDeadline_payOfflineCTA = "Pay another way";
var APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber = "Please provide a valid Test Report Form (TRF) Number.";
var APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber = "The Test Report Form (TRF) Number has to match specific format (e.g. 11AA111110AAAA001A).";
var APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength = "Your explanation can be at most {{count}} characters long.";
var APPB2C_registration_results_eor_postDeadline_line1 = "A request for a re-mark (called an Enquiry on Results, or \u2018EOR\u2019) must be requested no later than {{count}} days from the date of your test.";
var APPB2C_registration_results_eor_postDeadline_line2 = "As the deadline for an EOR application has passed, your request cannot be processed online.";
var APPB2C_registration_results_eor_postDeadline_line3 = "If you wish the centre to consider your EOR, please contact the test centre to provide details to support this request.";
var APPB2C_registration_results_eor_postDeadline_iol_line3 = "If you wish for us to consider your EOR, please contact us to provide details to support this request.";
var APPB2C_registration_results_eor_postDeadline_iol_launchChatLine = "I need to chat to somebody online about this:";
var APPB2C_registration_results_eor_postDeadline_back = "I don\u2019t need my test to be re-marked";
var APPB2C_registration_results_eor_postDeadline_phone = "Phone";
var APPB2C_registration_results_eor_postDeadline_email = "Email";
var APPB2C_registration_results_eor_postDeadline_help = "Please quote your registration reference number for us to help you better:";
var APPB2C_registration_results_eor_acknowledgement_title = "EOR Acknowledgement";
var APPB2C_registration_results_eor_acknowledgement_success = "Your Enquiry On Results (EOR) has been booked and a decision is pending.";
var APPB2C_registration_results_eor_acknowledgement_info = "Your Enquiry On Results (EOR) has been cancelled.";
var APPB2C_registration_results_eor_acknowledgement_confirmation = "We have sent you a confirmation of this EOR booking to your email address.";
var APPB2C_registration_results_eor_acknowledgement_components = "You have selected the following parts of your test to be re-marked:";
var APPB2C_registration_results_eor_acknowledgement_noteTitle = "Please note:";
var APPB2C_registration_results_eor_acknowledgement_payment = "Completed payment";
var APPB2C_registration_results_eor_acknowledgement_fee = "Fee";
var APPB2C_registration_results_eor_acknowledgement_tax = "Tax";
var APPB2C_registration_results_eor_acknowledgement_total = "Total";
var APPB2C_registration_results_eor_acknowledgement_reference = "EOR reference";
var APPB2C_registration_results_eor_acknowledgement_terms = "You agreed to these <0>terms and conditions</0>";
var APPB2C_registration_results_eor_acknowledgement_backButton = "View this test";
var APPB2C_registration_results_eor_acknowledgement_paymentNote = "You still have to settle the payment or your request will be cancelled";
var APPB2C_registration_results_eor_acknowledgement_paymentButton = "See your payment options";
var APPB2C_registration_results_osr_title = "Retake one part of your test";
var APPB2C_registration_results_osr_line1 = "If you need a second chance to improve your IELTS score, you can retake the one part of your test you were unhappy with.";
var APPB2C_registration_results_osr_line2 = "This means all the great work you\u2019ve already done isn\u2019t wasted.";
var APPB2C_registration_results_osr_line3 = "Which part of your test score (shown above) would you like to retake, and when would you like to retake it?";
var APPB2C_registration_results_osr_line4 = "We will aim to respond to all requests by email within 48-72 hours.";
var APPB2C_registration_results_osr_line5 = "The British Council will use the information that you are providing in connection with processing your request. For detailed information, please refer to the <0>privacy section of our website</0>";
var APPB2C_registration_results_osr_submitBtn = "Submit retake request";
var APPB2C_registration_results_noResults_title = "To see information about your results, including when they will be available, click <0>here</0>. You will be sent a link to access your results online once they are ready.";
var APPB2C_registration_results_osr_successBanner = "We have received your IELTS One Skill Retake request.";
var APPB2C_registration_results_osr_chooseSkill_formTitle = "Please choose which part of your test to retake";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title = "Does the institution you intend to apply to accept IELTS One Skill Retake?";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description = "Before you book a test with us, you should <0>check that your chosen institution accepts the relevant IELTS test version.</0>";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation = "I confirm that my chosen institution accepts IELTS One Skill Retake.";
var APPB2C_registration_results_osr_chooseSkill_chooseDateBtn = "Choose date of One Skill Retake";
var APPB2C_registration_results_osr_chooseSkill_backBtn = "Back to results";
var APPB2C_registration_results_osr_chooseSkill_skillRequired = "Please select a skill you wish to retake";
var APPB2C_registration_results_osr_chooseSkill_confirmationRequired = "Please confirm that you have checked that intended organisation(s) accept IELTS retakes.";
var APPB2C_registration_results_osr_chooseSkill_modal_title = "Sorry, we don\u2019t have any available dates";
var APPB2C_registration_results_osr_chooseSkill_modal_description = "We don\u2019t have any dates that you can book for your selected One Skill Retake.";
var APPB2C_registration_results_osr_chooseSkill_modal_contact = "Please contact us directly using the details below to find out how we can help you.";
var APPB2C_registration_results_osr_chooseSkill_modal_phone = "Phone";
var APPB2C_registration_results_osr_chooseSkill_modal_email = "Email";
var APPB2C_registration_results_osr_chooseDate_retakeListening = "Listening Retake";
var APPB2C_registration_results_osr_chooseDate_retakeReading = "Reading Retake";
var APPB2C_registration_results_osr_chooseDate_retakeWriting = "Writing Retake";
var APPB2C_registration_results_osr_chooseDate_retakeSpeaking = "Speaking Retake";
var APPB2C_registration_results_osr_chooseDate_chooseDateTitle = "Choose your {{skillName}} Retake date";
var APPB2C_registration_results_osr_chooseDate_note_title = "Please note:";
var APPB2C_registration_results_osr_chooseDate_note_content = "We can only offer you a retake date within 60 days of your original test date ({{date}}).";
var APPB2C_registration_results_osr_chooseDate_accessibility = "I have <bold>accessibility</bold> needs for the test date";
var APPB2C_registration_results_osr_chooseDate_accessibilityLink = "Read about how we can help you";
var APPB2C_registration_results_osr_chooseTest_availableDates = "Available dates";
var APPB2C_registration_results_osr_chooseTest_speaking_time = "Change time";
var APPB2C_registration_results_osr_review_note = "Please note, your personal details below have been retrieved from your original IELTS booking. These cannot be changed for your One Skill Retake.";
var APPB2C_registration_results_osr_review_note_testDay = "On the test day, you will be required to bring the same identification document you used to register with. If you do not have the original test ID with you, please contact your test centre.";
var APPB2C_registration_results_osr_error_noTestDates = "There are no test dates available in this location anymore. Please choose a different one or contact the test centre.";
var APPB2C_registration_results_osr_error_noTests = "There are no test dates available in the time period and location you have selected anymore. Please <0>go back</0> and change your search parameters.";
var APPB2C_registration_results_osr_error_missingFile = "There is a problem with files uploaded to your original registration. Please contact the test centre for further assistance.";
var APPB2C_registration_results_noResults_note_title = "Please note:";
var APPB2C_registration_results_noResults_osr_booked = "Retake booked";
var APPB2C_registration_results_noResults_osr_previousScores = "Your previous scores:";
var APPB2C_registration_results_noResults_cmds_title = "To see information about your results, including when they will be available, click <0>here</0>. We will send you a link to access your results and download your electronic Test Report Form (TRF).";
var APPB2C_registration_results_organisations_chosenOrganisationsHeader = "Chosen organisations to send your results to";
var APPB2C_registration_results_organisations_organisationsCount = "{{count}} organisation";
var APPB2C_registration_results_organisations_organisationsCount_other = "{{count}} organisations";
var APPB2C_registration_results_organisations_title = "Sharing your results with organisations";
var APPB2C_registration_results_organisations_note0 = "We can send your results to organisations on your behalf.";
var APPB2C_registration_results_organisations_note1 = 'We can send your results to up to $t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} }).';
var APPB2C_registration_results_organisations_note2 = "These can include:";
var APPB2C_registration_results_organisations_list_item1 = "academic institutions";
var APPB2C_registration_results_organisations_list_item2 = "government agencies";
var APPB2C_registration_results_organisations_list_item3 = "professional bodies";
var APPB2C_registration_results_organisations_list_item4 = "employers";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote1 = 'You can still choose another $t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} }) to have your results sent to.';
var APPB2C_registration_results_organisations_availableReceivingOrgsNote2 = "Applications are open until {{date}}.";
var APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote = "You have reached the limit for Additional TRFs requests and/or the deadline to apply has passed.";
var APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote = "No organisations have been chosen.";
var APPB2C_registration_results_organisations_mod_howTo_title = "How to choose your organisations";
var APPB2C_registration_results_organisations_mod_howTo_line1 = "You can choose from two types of organisations";
var APPB2C_registration_results_organisations_mod_howTo_item1 = "<bold>eTRF</bold>: Organisations that accept your electronic Test Report Form (eTRF) directly from us. You can choose as many of these as you like.";
var APPB2C_registration_results_organisations_mod_howTo_item2 = "<bold>TRF</bold>: Organisations that we send your Test Report Form (TRF) to on your behalf. You can only choose <bold>up to {{number}}</bold> of these.";
var APPB2C_registration_results_organisations_mod_delivery_auto_header = "Here are the organisations you chose which will receive your eTRF automatically:";
var APPB2C_registration_results_organisations_mod_delivery_auto_note = "We will send these organisations your eTRF for you. You don\u2019t need to do anything. You can choose as many of these as you like.";
var APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote = "We haven\u2019t sent these organisations your eTRF as you didn\u2019t achieve the minimum overall band you set for each of them.";
var APPB2C_registration_results_organisations_mod_delivery_auto_sentNote = "We have sent these organisations your eTRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_mod_delivery_postal_header = "Here are the organisations you chose that we will post your TRF to:";
var APPB2C_registration_results_organisations_mod_delivery_postal_note1 = "We will post your Test Report Form (TRF) to these organisations. You can only choose up to {{count}} of these.";
var APPB2C_registration_results_organisations_mod_delivery_postal_note2 = "If you can\u2019t find an organisation in our list, you can add your own. They will be counted as one of your TRF choices.";
var APPB2C_registration_results_organisations_mod_delivery_postal_note3 = 'You can still choose another $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} }) to have your results sent to.';
var APPB2C_registration_results_organisations_mod_delivery_postal_note4 = 'Now that you\u2019ve chosen $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} }) you can\u2019t choose anymore. You can still choose organisations that accept eTRF.';
var APPB2C_registration_results_organisations_mod_delivery_postal_note5 = "You have reached the limit for organisations we can send your results to.";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount = "{{count}} TRF organisation";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other = "{{count}} TRF organisations";
var APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote = "We haven\u2019t sent these organisations your TRF as you didn\u2019t achieve the minimum overall band you set for each of them.";
var APPB2C_registration_results_organisations_mod_delivery_postal_sentNote = "We have sent these organisations your TRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_iol_roAccept_title = "Does your intended organisation or institute accept IELTS online?";
var APPB2C_registration_results_organisations_iol_roAccept_message = "If you take IELTS Online then you need to check whether your organisation accepts this.";
var APPB2C_registration_results_organisations_iol_roAccept_link = "Please check which organisations accept IELTS Online test scores";
var APPB2C_registration_results_organisations_iol_delivery_postal_header = "Here are the organisations you chose which need manual delivery:";
var APPB2C_registration_results_organisations_iol_delivery_postal_note1 = "These organisations accept IELTS Online, but are not registered to receive eTRF directly from us.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2 = "When you receive your results, you will need to email a copy of your TRF to each organisation.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2_results = "You will need to email a copy of your TRF to each organisation.";
var APPB2C_registration_results_organisations_iol_delivery_auto_header = "Here are the organisations you chose which will receive your eTRF automatically:";
var APPB2C_registration_results_organisations_iol_delivery_auto_note = "We will send these organisations your eTRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote = "We haven\u2019t sent these organisations your eTRF as you didn\u2019t achieve the minimum overall band you set for each of them.";
var APPB2C_registration_results_organisations_iol_delivery_auto_sentNote = "We have sent these organisations your eTRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_iol_chooseCta = "Choose organisations";
var APPB2C_registration_results_organisations_iol_noConditions = "You did not set any conditional scores for this organisation.";
var APPB2C_registration_results_organisations_iol_generalCondition = "We will only send this organisation your results if your achieve this minimum overall band:";
var APPB2C_registration_results_organisations_iol_preciseCondition = "We will only send your results to this organisation if you achieve every score you have set.";
var APPB2C_registration_results_organisations_iol_notChosen = "Not chosen";
var APPB2C_registration_results_organisations_iol_edit = "Change";
var APPB2C_registration_results_organisations_iol_error = "Something went wrong while loading changes.<0></0> Please try <1>refreshing</1> the page.";
var APPB2C_registration_results_organisations_iol_sendAnyway = "Send my results to this organisation anyway";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_country = "Choose a country / territory";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional = "Choose a country / territory: (optional)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll = "All";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry = "Search by country / territory name";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_org = "Choose an organisation";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg = "Search by organisation name";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew = "+ Add a new organisation";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation = "Organisation name";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_person = "Name of Person / Department: (optional)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_file = "File / case number: (optional)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_address = "Address:";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode = "Post code";
var APPB2C_registration_results_organisations_editRo_iol_newOrgTitle = "Add an organisation";
var APPB2C_registration_results_organisations_editRo_iol_cancelEdit = "Discard changes";
var APPB2C_registration_results_organisations_editRo_iol_noResults = "No results";
var APPB2C_registration_results_organisations_editRo_iol_address = "Address:";
var APPB2C_registration_results_organisations_editRo_iol_copy = "Copy email address";
var APPB2C_registration_results_organisations_editRo_iol_copied = "Email address copied";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note1 = "This organisation accepts IELTS Online, but is not registered to receive eTRF directly from us.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2 = "When you receive your results, you will need to email a copy of your TRF to the organisation.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results = "You will need to email a copy of your TRF to the organisation.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf = "TRF";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults = "eTRF";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note = "You can have your eTRF sent to as many organisations as you like, but you can only choose up to {{number}} TRF organisations.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal = "We will send this organisation your Test Report Form (TRF). <br>It counts towards your limit of {{number}} TRF organisations.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery = "This organisation accepts your eTRF directly from us. It does not affect how many more organisations you can choose.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_header = "Would you like to choose conditional scores for this organisation?";
var APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader = "We would only send your results to them if you get the score(s) you want.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_no = "No";
var APPB2C_registration_results_organisations_editRo_iol_conditions_yes = "Yes";
var APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder = "Please choose";
var APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder = "Optional";
var APPB2C_registration_results_organisations_editRo_iol_conditions_clear = "clear";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note1 = "Please choose the minimum overall band you want to score before this organisation receives your result.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note2 = "You can also choose your minimum scores for each test component:";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note3 = "We will only send your results to this organisation if you achieve every score you have set.";
var APPB2C_registration_results_organisations_editRo_iol_addCta = "Add organisation";
var APPB2C_registration_results_organisations_editRo_iol_saveCta = "Save changes";
var APPB2C_registration_results_organisations_editRo_iol_removeLink = "Remove organisation";
var APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation = "Please choose an organisation.";
var APPB2C_registration_results_organisations_editRo_iol_validation_overallScore = "The minimal score for your overall band is required.";
var APPB2C_registration_results_organisations_editRo_addAnother = "+ Add another organisation";
var APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength = "Name of Person / Department can be at most {{count}} characters long.";
var APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength = "File / case number can be at most {{count}} characters long.";
var APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength = "Organisation name can be at most {{count}} characters long.";
var APPB2C_registration_results_organisations_editRo_error_minScoreMissing = "Please choose the minimum score for your overall band.";
var APPB2C_registration_results_organisations_editRo_error_missingAddressLine1 = "Please provide the organisation address.";
var APPB2C_registration_results_organisations_editRo_error_missingOrgName = "Please provide the organisation name.";
var APPB2C_registration_results_ttAbsent_msg = "No results for this test can be made available as the Test Taker was absent on the test date.";
var APPB2C_registration_results_ttAbsent_btn = "Rebook this test with different dates";
var APPB2C_registration_payments_paidByAgent = "Registration paid for by the agent";
var APPB2C_registration_payments_unpaid = "Unpaid";
var APPB2C_registration_payments_awaitingPayment = "Awaiting payment";
var APPB2C_registration_payments_expired = "Expired";
var APPB2C_registration_payments_completed = "Completed";
var APPB2C_registration_payments_back = "Back to test";
var APPB2C_registration_payments_transaction_header = "Payment for";
var APPB2C_registration_payments_transaction_description_registration = "Test Registration";
var APPB2C_registration_payments_transaction_description_transfer = "Transfer";
var APPB2C_registration_payments_transaction_description_eor = "EOR";
var APPB2C_registration_payments_transaction_description_appeal = "Appeal";
var APPB2C_registration_payments_transaction_underReviewSuffix = "(under review)";
var APPB2C_registration_payments_transaction_unpaidSuffix = "(unpaid)";
var APPB2C_registration_payments_transaction_cancelledSuffix = "(cancelled)";
var APPB2C_registration_payments_transaction_hideDetails = "Hide details";
var APPB2C_registration_payments_transaction_showDetails = "Show details";
var APPB2C_registration_payments_transaction_feeLabel = "Fee";
var APPB2C_registration_payments_transaction_taxLabel = "Tax";
var APPB2C_registration_payments_transaction_totalLabel = "Total";
var APPB2C_registration_payments_transaction_promoCode = "Promo code";
var APPB2C_registration_payments_transaction_referenceLabel = "Payment reference";
var APPB2C_registration_payments_transaction_receipt = "Receipt";
var APPB2C_registration_payments_transaction_receiptCta = "You can download here";
var APPB2C_registration_payments_transaction_deadlineWarning_default = "You have until {{date}} to pay this fee.";
var APPB2C_registration_payments_transaction_deadlineWarning_registration = "You have until {{date}} to pay this fee. After this date, your registration will be cancelled.";
var APPB2C_registration_payments_transaction_deadlineWarning_eor = "You have until {{date}} to pay this fee. After this date, your Enquiry On Results (EOR) will be cancelled.";
var APPB2C_registration_payments_transaction_pastDeadlineContactUs = "If you believe this is an error and can provide proof that the payment has been made on time, please contact us:";
var APPB2C_registration_payments_transaction_pastDeadlinePhone = "Phone";
var APPB2C_registration_payments_transaction_pastDeadlineEmail = "Email";
var APPB2C_registration_payments_transaction_pastDeadline = "Payment for this item has not been registered in our system.";
var APPB2C_registration_payments_transaction_termsLabel = "View <0>terms and conditions</0>";
var APPB2C_registration_payments_transaction_outdatedTermsLabel = "You originally agreed to these <0>terms and conditions</0>";
var APPB2C_registration_payments_finishPayment_processing = "We are processing your payment...";
var APPB2C_registration_payments_finishPayment_pleaseWait = "Please wait";
var APPB2C_registration_payments_finishPayment_success_title = "Payment confirmation";
var APPB2C_registration_payments_finishPayment_success_alert = "Your payment has been successfully processed!";
var APPB2C_registration_payments_finishPayment_success_message = "We have sent you a confirmation of this payment to your email address.";
var APPB2C_registration_payments_finishPayment_success_cta = "View this test";
var APPB2C_registration_payments_finishPayment_failure_title = "Payment failed";
var APPB2C_registration_payments_finishPayment_failure_alert = "Sorry \u2013 your online payment failed!";
var APPB2C_registration_payments_finishPayment_failure_message = "Please go back to your payment options and try again.";
var APPB2C_registration_payments_finishPayment_failure_cta = "View payment options";
var APPB2C_registration_payments_finishPayment_error_title = "Unknown payment status";
var APPB2C_registration_payments_finishPayment_error_messageLine1 = "We\u2019ve encountered an error while checking your payment status. Please try to refresh the page in a few moments.";
var APPB2C_registration_payments_finishPayment_error_messageLine2 = "If this issue persists, please go back to your payment options.";
var APPB2C_registration_payments_finishPayment_error_cta = "Refresh page";
var APPB2C_registration_payments_actions_header = "How would you like to pay?";
var APPB2C_registration_payments_actions_payOnlineCTA = "Pay online";
var APPB2C_registration_payments_actions_paymentInProgressInfo = "Previous attempt to pay online is still being processed. Please wait for the result.";
var APPB2C_registration_payments_actions_payOfflineCTA = "Pay another way";
var APPB2C_registration_payments_actions_subHeader = "I\u2019ve already paid and need to upload my payment proof";
var APPB2C_registration_payments_actions_uploadProofCTA = "Upload payment proof";
var APPB2C_registration_payments_actions_editProofCTA = "Edit proof of payment";
var APPB2C_registration_payments_offlineDetails_payBy = "Pay by";
var APPB2C_registration_payments_offlineDetails_accountName = "Account name";
var APPB2C_registration_payments_offlineDetails_accountNumber = "Account number";
var APPB2C_registration_payments_offlineDetails_sortCode = "Sort code";
var APPB2C_registration_payments_proofUpload_intro = "If you have already paid this fee you can upload proof of payment below.";
var APPB2C_registration_payments_proofUpload_info = "You can upload 2 files (you must upload at least 1)";
var APPB2C_registration_payments_proofUpload_cta = "Save your files";
var APPB2C_registration_payments_proofUpload_rules_intro = "Your proof of payment must clearly show:";
var APPB2C_registration_payments_proofUpload_rules_rule1 = "the payee name";
var APPB2C_registration_payments_proofUpload_rules_rule2 = "the payee account number";
var APPB2C_registration_payments_proofUpload_rules_rule3 = "the amount paid";
var APPB2C_registration_payments_proofUpload_rules_rule4 = "the date it was paid";
var APPB2C_registration_payments_proofUpload_rules_rule5 = "the British Council account number";
var APPB2C_registration_payments_proofPreview_proofLabel = "Proof of payment:";
var APPB2C_registration_payments_proofPreview_fileAlt = "Uploaded proof of payment";
var APPB2C_registration_prepare_header = "Prepare for your test";
var APPB2C_registration_prepare_intro = "We can help you prepare for IELTS with our range of free resources.";
var APPB2C_registration_prepare_introLifeSkills = "We can help you prepare for Life Skills with our range of free resources.";
var APPB2C_registration_prepare_rteLastMinute_header = "Road to IELTS \u2013 Last Minute";
var APPB2C_registration_prepare_rteLastMinute_body_line1 = "Get ready for your IELTS test with:";
var APPB2C_registration_prepare_rteLastMinute_body_item1 = "9 videos giving advice and tutorials,";
var APPB2C_registration_prepare_rteLastMinute_body_item2 = "100 interactive activities,";
var APPB2C_registration_prepare_rteLastMinute_body_item3 = "2 practice tests for each of the 4 skills.";
var APPB2C_registration_prepare_rteLastMinute_body_cta = "Start now";
var APPB2C_registration_prepare_rteLastMinute_ukvi_line1 = "Get unlimited access to Road to IELTS \u2013 Last Minute Course, British Council\u2019s popular online preparation course. Watch videos giving advice and tutorials, use 100 interactive activities and take two practice tests for each of the four core skills.";
var APPB2C_registration_prepare_rteLastMinute_ukvi_cta = "Access the course";
var APPB2C_registration_prepare_rteTestDrive_header = "Road to IELTS Test Drive \u2013 Free Version";
var APPB2C_registration_prepare_rteTestDrive_body_line1 = "Jump start your IELTS journey with Road to IELTS Test Drive and get:";
var APPB2C_registration_prepare_rteTestDrive_body_item1 = "30 interactive exercises,";
var APPB2C_registration_prepare_rteTestDrive_body_item2 = "4 videos giving tips and advice,";
var APPB2C_registration_prepare_rteTestDrive_body_item3 = "practice test for each of the 4 skills.";
var APPB2C_registration_prepare_rteTestDrive_body_line2 = "You will also get more materials once registration is complete.";
var APPB2C_registration_prepare_rteTestDrive_body_cta = "Try Road to IELTS test drive version";
var APPB2C_registration_prepare_onlineCourse_header = "Online Course: Understanding IELTS";
var APPB2C_registration_prepare_onlineCourse_body_line1 = "Our four Understanding IELTS ExpertTrack courses will give you a complete guide to everything you need to know as you prepare for the IELTS test. You\u2019ll get a deep dive into each section of the IELTS test \u2013 Reading, Writing, Listening and Speaking. You\u2019ll also receive advice from our video tutor and a team of experienced IELTS educators. Join a free 7-day trial which provides you with access to the full suite of courses and assessments for a week.";
var APPB2C_registration_prepare_onlineCourse_body_cta = "Start your free trial now";
var APPB2C_registration_prepare_tutor_header = "1-to-1 tutor \u2013 IELTS Preparation Course";
var APPB2C_registration_prepare_tutor_body_line1 = "Prepare for your IELTS test with a professional IELTS tutor. With a number of courses to choose from, you can get ready for your IELTS if it\u2019s a week, or a few months away. Try your first session today for $1.";
var APPB2C_registration_prepare_tutor_body_line2 = "Exclusive Offer: As an IELTS test taker with the British Council you get 10% off when you purchase one of our 1-to-1 preparation courses.";
var APPB2C_registration_prepare_tutor_body_cta = "Click here to try your first session for $1 and avail this offer.";
var APPB2C_registration_prepare_prepApp_header = "IELTS Prep App";
var APPB2C_registration_prepare_prepApp_body_line1 = "The IELTS App has sample questions, practice tests, grammar tips, exercises, quizzes and more. You can also use it to track your progress.";
var APPB2C_registration_prepare_prepApp_body_cta = "Access IELTS Prep App";
var APPB2C_registration_prepare_prepApp_ukvi_line1 = "While focusing on Listening and Speaking parts of the test, this app will also help you expand your vocabulary, practice grammar and understand what to do on your test day. You will be able to test your knowledge by doing grammar exercises, Listening and Speaking practice tests and watching videos of IELTS Speaking interviews.";
var APPB2C_registration_prepare_prepApp_ukvi_line2 = "Download for:";
var APPB2C_registration_prepare_prepApp_ukvi_cta1 = "iOS";
var APPB2C_registration_prepare_prepApp_ukvi_cta2 = "Android";
var APPB2C_registration_prepare_blog_header = "IELTS Opportunities Abroad blog";
var APPB2C_registration_prepare_blog_body_line1 = "This blog gives Experts\u2019 advice and tips on the IELTS test, plus an overview of US campuses.";
var APPB2C_registration_prepare_blog_body_cta = "Access IELTS Opportunities Abroad blog";
var APPB2C_registration_prepare_facebook_header = "Ask the Expert (Facebook)";
var APPB2C_registration_prepare_facebook_body_line1 = "Three live chats every month by an IELTS expert on Facebook, with on-the-spot answers to audience questions.";
var APPB2C_registration_prepare_facebook_body_cta = "Ask the Expert (Facebook)";
var APPB2C_registration_prepare_prepVideos_header = "IELTS preparation videos";
var APPB2C_registration_prepare_prepVideos_body_line1 = "Get to know the test format through a series of videos.";
var APPB2C_registration_prepare_prepVideos_body_cta = "Access IELTS preparation videos";
var APPB2C_registration_prepare_speaking_header = "Understanding IELTS: Speaking";
var APPB2C_registration_prepare_speaking_body_line1 = "Find out what you need to know about the IELTS Speaking test to improve your test performance.";
var APPB2C_registration_prepare_speaking_body_cta = "Access Understanding IELTS: Speaking";
var APPB2C_registration_prepare_reading_header = "Understanding IELTS: Reading";
var APPB2C_registration_prepare_reading_body_line1 = "Find out what you need to know about the IELTS Reading test to improve your test performance.";
var APPB2C_registration_prepare_reading_body_cta = "Access Understanding IELTS: Reading";
var APPB2C_registration_prepare_cdTests_header = "Computer Delivered IELTS practice tests";
var APPB2C_registration_prepare_cdTests_body_line1 = "These sample test questions will prepare you for the test and help you decide which option you prefer.";
var APPB2C_registration_prepare_cdTests_body_cta = "Access Computer Delivered IELTS practice tests";
var APPB2C_registration_prepare_cdTipsVideos_header = "Computer Delivered IELTS tips videos";
var APPB2C_registration_prepare_cdTipsVideos_body_line1 = "Tips on computer-delivered IELTS.";
var APPB2C_registration_prepare_cdTipsVideos_body_cta = "Access Computer Delivered IELTS tips videos";
var APPB2C_registration_prepare_cdFamiliarVideos_header = "Computer Delivered IELTS familiarisation video";
var APPB2C_registration_prepare_cdFamiliarVideos_body_line1 = "These videos will help you understand how the Listening, Reading and Writing sections appear on the computer.";
var APPB2C_registration_prepare_cdFamiliarVideos_body_cta = "Access Computer Delivered IELTS familiarisation videos";
var APPB2C_registration_prepare_cdFamiliarTest_header = "CD IELTS Familiarisation Test";
var APPB2C_registration_prepare_cdFamiliarTest_body_line1 = "Free full sample version of the computer-delivered IELTS test \u2013 the Listening, Reading and Writing sections provide 2 hour 30 minutes of questions, exactly like the real IELTS test.";
var APPB2C_registration_prepare_cdFamiliarTest_body_cta = "Access CD IELTS Familiarisation Test";
var APPB2C_registration_prepare_pbPractice_header = "Practice papers";
var APPB2C_registration_prepare_pbPractice_body_line1 = "Use these tests for timed practice sessions. You can also review and compare answers.";
var APPB2C_registration_prepare_pbPractice_body_cta = "Access Practice tests";
var APPB2C_registration_prepare_webinars_header = "IELTS Webinars";
var APPB2C_registration_prepare_webinars_body_line1 = "Get test ready with free weekly IELTS webinars conducted by our IELTS experts. You\u2019ll also get access to our IELTS Study Pack with:";
var APPB2C_registration_prepare_webinars_body_item1 = "Guidance videos";
var APPB2C_registration_prepare_webinars_body_item2 = "Detailed study plans for all four skills";
var APPB2C_registration_prepare_webinars_body_item3 = "Practice tests";
var APPB2C_registration_prepare_webinars_body_cta = "Sign up for access";
var APPB2C_registration_prepare_gel_ukvi_header = "<0>IELTS Ready Premium</0> for UKVI";
var APPB2C_registration_prepare_gel_ukvi_body_line1 = "Personalised, smart support that works with you and your learning needs to help you reach your required IELTS for UKVI score. Including mock tests and practice resources for all our skills. Powered by GEL IELTS Prep, <0>IELTS Ready Premium</0> will help you succeed.";
var APPB2C_registration_prepare_gel_ielts_header = "<0>IELTS Ready Premium</0>";
var APPB2C_registration_prepare_gel_ielts_body_line1 = "Personalised, smart support that works with you and your learning needs to help you reach your required IELTS score. Including mock tests and practice resources for all our skills. Powered by GEL IELTS Prep, <0>IELTS Ready Premium</0> will help you succeed.";
var APPB2C_registration_prepare_gel_ielts_body_cta = "Get access";
var APPB2C_registration_prepare_gel_disabledMsg = "Access to <0>IELTS Ready Premium</0> is blocked by your test centre. Please contact them to be given access to the British Council's free preparation services.";
var APPB2C_registration_prepare_practice_header = "Practice tests";
var APPB2C_registration_prepare_practice_body_line1 = "Take advantage of our free practice tests to get the IELTS score you need. If you prefer to practice offline, download the tests, blank answer sheets, transcripts and answers from the introductory pages.";
var APPB2C_registration_prepare_practice_body_cta = "Access practice tests";
var APPB2C_registration_prepare_a1_header = "Level A1";
var APPB2C_registration_prepare_a1_cta = "Prepare for IELTS Life Skills \u2013 level A1";
var APPB2C_registration_prepare_a2_header = "Level A2";
var APPB2C_registration_prepare_a2_cta = "Prepare for IELTS Life Skills \u2013 level A2";
var APPB2C_registration_prepare_b1_header = "Level B1";
var APPB2C_registration_prepare_b1_cta = "Prepare for IELTS Life Skills - level B1";
var APPB2C_registration_prepare_ieltsReady_title = "Choose your preparation service";
var APPB2C_registration_prepare_ieltsReady_subTitle = "You have access to the following preparation services:";
var APPB2C_registration_prepare_ieltsReadyMember_header = "<0>IELTS Ready Member</0>";
var APPB2C_registration_prepare_ieltsReadyMember_body = "Wide range of support materials including practice tests, preparation webinars, tips, videos, Road to IELTS content and much more. Our <0>IELTS Ready Member</0> area will help you prepare for your IELTS test with confidence.";
var APPB2C_registration_prepare_ieltsReadyMember_link = "Access for free";
var APPB2C_registration_prepare_ieltsReadyMember_freePrepLink = "Free preparation materials are available on our <0>Take IELTS website</0>";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo = "To access <0>IELTS Ready Premium</0> for free you need to have an upcoming test booked.";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn = "Book a new test";
var APPB2C_registration_prepare_ieltsReadyMember_payBtn = "Pay for your test";
var APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo = "To access <0>IELTS Ready Premium</0> for free you need to have paid for your upcoming test.";
var APPB2C_registration_prepare_ieltsReadyMember_label = "Free for everyone";
var APPB2C_registration_incorrectOrganisation_line1 = "It looks like you\u2019re trying to use the Test Taker Portal in a context of an organisation we don\u2019t recognise.";
var APPB2C_registration_incorrectOrganisation_line2 = "Please try following the original link that led you here again or simply use the TTP without any context:";
var APPB2C_registration_incorrectOrganisation_homeLink = "Show me Test Taker Portal without organisation context";
var APPB2C_testCard_lrw = "Listening, Reading & Writing";
var APPB2C_testCard_ls = "Listening & Speaking";
var APPB2C_testCard_speaking = "Speaking test";
var APPB2C_testCard_cd = "IELTS on computer";
var APPB2C_testCard_pb = "IELTS on paper";
var APPB2C_testCard_f2f = "Face to face";
var APPB2C_testCard_vcs = "Video call at the test centre";
var APPB2C_testCard_changeSpeaking = "Change speaking schedule";
var APPB2C_testCard_notLinkedVenue = "Speaking and written test locations are not the same. Plan your travel.";
var APPB2C_testCard_speakingAddress = "Speaking address";
var APPB2C_testCard_arrival = "(please arrive at {{time}})";
var APPB2C_testCard_mixedSlots = "Test will be recorded. Consent implied by booking. Please, choose other if you don't agree.";
var APPB2C_testCard_timeUnknown = "You will receive your test time 48 hours before your test date.";
var APPB2C_testCard_book = "Book";
var APPB2C_testCard_bookFor = "Book for";
var APPB2C_testCard_review = "and review";
var APPB2C_testCard_hideSpeakingTests = "hide speaking tests at this venue";
var APPB2C_testCard_showSpeakingTests = "show speaking tests at this venue";
var APPB2C_testCard_bookingPrice = "Price";
var APPB2C_testCard_bookExam = "Book Test";
var APPB2C_testCard_bookForFree = "Book Test for free";
var b2c_default = {
  "APPB2C.auth.forgetPassword.title": APPB2C_auth_forgetPassword_title,
  "APPB2C.auth.forgetPassword.email": APPB2C_auth_forgetPassword_email,
  "APPB2C.auth.forgetPassword.text": APPB2C_auth_forgetPassword_text,
  "APPB2C.auth.forgetPassword.cta": APPB2C_auth_forgetPassword_cta,
  "APPB2C.auth.forgetPassword.success.title": APPB2C_auth_forgetPassword_success_title,
  "APPB2C.auth.forgetPassword.success.msg1": APPB2C_auth_forgetPassword_success_msg1,
  "APPB2C.auth.forgetPassword.success.msg2": APPB2C_auth_forgetPassword_success_msg2,
  "APPB2C.auth.resetPassword.title": APPB2C_auth_resetPassword_title,
  "APPB2C.auth.resetPassword.cta": APPB2C_auth_resetPassword_cta,
  "APPB2C.auth.resetPassword.form.newPassword": APPB2C_auth_resetPassword_form_newPassword,
  "APPB2C.auth.resetPassword.success.title": APPB2C_auth_resetPassword_success_title,
  "APPB2C.auth.resetPassword.success.msg": APPB2C_auth_resetPassword_success_msg,
  "APPB2C.auth.resetPassword.noToken.msg": APPB2C_auth_resetPassword_noToken_msg,
  "APPB2C.auth.resetPassword.noToken.cta": APPB2C_auth_resetPassword_noToken_cta,
  "APPB2C.auth.resetPassword.error.wrongPassword": APPB2C_auth_resetPassword_error_wrongPassword,
  "APPB2C.auth.resetPassword.error.expiredToken": APPB2C_auth_resetPassword_error_expiredToken,
  "APPB2C.auth.resetPassword.error.wrongToken": APPB2C_auth_resetPassword_error_wrongToken,
  "APPB2C.auth.changePassword.title": APPB2C_auth_changePassword_title,
  "APPB2C.auth.changePassword.cta": APPB2C_auth_changePassword_cta,
  "APPB2C.auth.changePassword.id.note": APPB2C_auth_changePassword_id_note,
  "APPB2C.auth.changePassword.id.label": APPB2C_auth_changePassword_id_label,
  "APPB2C.auth.changePassword.id.warn": APPB2C_auth_changePassword_id_warn,
  "APPB2C.auth.changePassword.pass.note": APPB2C_auth_changePassword_pass_note,
  "APPB2C.auth.changePassword.pass.label": APPB2C_auth_changePassword_pass_label,
  "APPB2C.auth.changePassword.error": APPB2C_auth_changePassword_error,
  "APPB2C.auth.changePassword.error.wrongIdNumber": APPB2C_auth_changePassword_error_wrongIdNumber,
  "APPB2C.auth.changePassword.error.wrongPassword": APPB2C_auth_changePassword_error_wrongPassword,
  "APPB2C.auth.changePassword.error.differentPasswords": APPB2C_auth_changePassword_error_differentPasswords,
  "APPB2C.auth.changePassword.error.wrongOldPassword": APPB2C_auth_changePassword_error_wrongOldPassword,
  "APPB2C.cj.basic.cta": APPB2C_cj_basic_cta,
  "APPB2C.cj.basic.goToReview": APPB2C_cj_basic_goToReview,
  "APPB2C.cj.basic.validationError": APPB2C_cj_basic_validationError,
  "APPB2C.cj.basic.scrollLink": APPB2C_cj_basic_scrollLink,
  "APPB2C.cj.basic.selectDate": APPB2C_cj_basic_selectDate,
  "APPB2C.cj.basic.selectTime": APPB2C_cj_basic_selectTime,
  "APPB2C.cj.incorrectOrganisation.title": APPB2C_cj_incorrectOrganisation_title,
  "APPB2C.cj.incorrectOrganisation.line1": APPB2C_cj_incorrectOrganisation_line1,
  "APPB2C.cj.incorrectOrganisation.line2": APPB2C_cj_incorrectOrganisation_line2,
  "APPB2C.cj.incorrectOrganisation.locationsLink": APPB2C_cj_incorrectOrganisation_locationsLink,
  "APPB2C.cj.incorrectOrganisation.locationsLinkUsa": APPB2C_cj_incorrectOrganisation_locationsLinkUsa,
  "APPB2C.cj.incorrectOrganisation.bookNewLink": APPB2C_cj_incorrectOrganisation_bookNewLink,
  "APPB2C.cj.timer.nearEnd.title": APPB2C_cj_timer_nearEnd_title,
  "APPB2C.cj.timer.nearEnd.text": APPB2C_cj_timer_nearEnd_text,
  "APPB2C.cj.timer.nearEnd.minuteCount": APPB2C_cj_timer_nearEnd_minuteCount,
  "APPB2C.cj.timer.nearEnd.minuteCount_other": APPB2C_cj_timer_nearEnd_minuteCount_other,
  "APPB2C.cj.timer.modal.title": APPB2C_cj_timer_modal_title,
  "APPB2C.cj.timer.modal.message": APPB2C_cj_timer_modal_message,
  "APPB2C.cj.timer.modal.cta": APPB2C_cj_timer_modal_cta,
  "APPB2C.cj.rebook.errorModal.title": APPB2C_cj_rebook_errorModal_title,
  "APPB2C.cj.rebook.errorModal.message": APPB2C_cj_rebook_errorModal_message,
  "APPB2C.cj.personalDetails.title": APPB2C_cj_personalDetails_title,
  "APPB2C.cj.personalDetails.gender": APPB2C_cj_personalDetails_gender,
  "APPB2C.cj.personalDetails.forWho.myself": APPB2C_cj_personalDetails_forWho_myself,
  "APPB2C.cj.personalDetails.forWho.child": APPB2C_cj_personalDetails_forWho_child,
  "APPB2C.cj.personalDetails.forWho.childUnder18": APPB2C_cj_personalDetails_forWho_childUnder18,
  "APPB2C.cj.personalDetails.forWho.title": APPB2C_cj_personalDetails_forWho_title,
  "APPB2C.cj.personalDetails.forWho.note": APPB2C_cj_personalDetails_forWho_note,
  "APPB2C.cj.personalDetails.forWho.dob": APPB2C_cj_personalDetails_forWho_dob,
  "APPB2C.cj.personalDetails.visaType.title": APPB2C_cj_personalDetails_visaType_title,
  "APPB2C.cj.personalDetails.visaType.title.child": APPB2C_cj_personalDetails_visaType_title_child,
  "APPB2C.cj.personalDetails.visaType.label": APPB2C_cj_personalDetails_visaType_label,
  "APPB2C.cj.personalDetails.visaType.label.child": APPB2C_cj_personalDetails_visaType_label_child,
  "APPB2C.cj.personalDetails.visaType.link": APPB2C_cj_personalDetails_visaType_link,
  "APPB2C.cj.personalDetails.tncs.title": APPB2C_cj_personalDetails_tncs_title,
  "APPB2C.cj.personalDetails.tncs.agreement": APPB2C_cj_personalDetails_tncs_agreement,
  "APPB2C.cj.personalDetails.login.title": APPB2C_cj_personalDetails_login_title,
  "APPB2C.cj.personalDetails.login.loggedIn": APPB2C_cj_personalDetails_login_loggedIn,
  "APPB2C.cj.personalDetails.login.note": APPB2C_cj_personalDetails_login_note,
  "APPB2C.cj.personalDetails.login.link": APPB2C_cj_personalDetails_login_link,
  "APPB2C.cj.personalDetails.login.emailNotEditable": APPB2C_cj_personalDetails_login_emailNotEditable,
  "APPB2C.cj.personalDetails.login.logout": APPB2C_cj_personalDetails_login_logout,
  "APPB2C.cj.personalDetails.login.btn": APPB2C_cj_personalDetails_login_btn,
  "APPB2C.cj.personalDetails.idNote.whenForChild": APPB2C_cj_personalDetails_idNote_whenForChild,
  "APPB2C.cj.personalDetails.idNote.idMatch": APPB2C_cj_personalDetails_idNote_idMatch,
  "APPB2C.cj.personalDetails.idNote.idMatch.change": APPB2C_cj_personalDetails_idNote_idMatch_change,
  "APPB2C.cj.personalDetails.forms.personal.firstName": APPB2C_cj_personalDetails_forms_personal_firstName,
  "APPB2C.cj.personalDetails.forms.personal.firstMiddleName": APPB2C_cj_personalDetails_forms_personal_firstMiddleName,
  "APPB2C.cj.personalDetails.forms.personal.lastName": APPB2C_cj_personalDetails_forms_personal_lastName,
  "APPB2C.cj.personalDetails.forms.personal.noLastName": APPB2C_cj_personalDetails_forms_personal_noLastName,
  "APPB2C.cj.personalDetails.forms.personal.idMatch": APPB2C_cj_personalDetails_forms_personal_idMatch,
  "APPB2C.cj.personalDetails.forms.personal.dob": APPB2C_cj_personalDetails_forms_personal_dob,
  "APPB2C.cj.personalDetails.forms.personal.sectionTitle": APPB2C_cj_personalDetails_forms_personal_sectionTitle,
  "APPB2C.cj.personalDetails.forms.personal.change": APPB2C_cj_personalDetails_forms_personal_change,
  "APPB2C.cj.personalDetails.forms.personal.name": APPB2C_cj_personalDetails_forms_personal_name,
  "APPB2C.cj.personalDetails.forms.personal.dobCta": APPB2C_cj_personalDetails_forms_personal_dobCta,
  "APPB2C.cj.personalDetails.forms.contact.note": APPB2C_cj_personalDetails_forms_contact_note,
  "APPB2C.cj.personalDetails.forms.contact.email": APPB2C_cj_personalDetails_forms_contact_email,
  "APPB2C.cj.personalDetails.forms.contact.emailInUse": APPB2C_cj_personalDetails_forms_contact_emailInUse,
  "APPB2C.cj.personalDetails.forms.contact.confirmEmail": APPB2C_cj_personalDetails_forms_contact_confirmEmail,
  "APPB2C.cj.personalDetails.forms.contact.mobile": APPB2C_cj_personalDetails_forms_contact_mobile,
  "APPB2C.cj.personalDetails.forms.contact.title": APPB2C_cj_personalDetails_forms_contact_title,
  "APPB2C.cj.personalDetails.forms.contact.password": APPB2C_cj_personalDetails_forms_contact_password,
  "APPB2C.cj.personalDetails.forms.contact.smsConsent": APPB2C_cj_personalDetails_forms_contact_smsConsent,
  "APPB2C.cj.personalDetails.forms.contact.smsNote": APPB2C_cj_personalDetails_forms_contact_smsNote,
  "APPB2C.cj.personalDetails.forms.contact.emailExist": APPB2C_cj_personalDetails_forms_contact_emailExist,
  "APPB2C.cj.personalDetails.forms.address.country": APPB2C_cj_personalDetails_forms_address_country,
  "APPB2C.cj.personalDetails.forms.address.postal": APPB2C_cj_personalDetails_forms_address_postal,
  "APPB2C.cj.personalDetails.forms.address.town": APPB2C_cj_personalDetails_forms_address_town,
  "APPB2C.cj.personalDetails.forms.address.zip": APPB2C_cj_personalDetails_forms_address_zip,
  "APPB2C.cj.personalDetails.forms.address.state": APPB2C_cj_personalDetails_forms_address_state,
  "APPB2C.cj.personalDetails.forms.address.enterManually": APPB2C_cj_personalDetails_forms_address_enterManually,
  "APPB2C.cj.personalDetails.error.missingFirstName": APPB2C_cj_personalDetails_error_missingFirstName,
  "APPB2C.cj.personalDetails.error.missingSurname": APPB2C_cj_personalDetails_error_missingSurname,
  "APPB2C.cj.personalDetails.error.missingEmail": APPB2C_cj_personalDetails_error_missingEmail,
  "APPB2C.cj.personalDetails.error.missingEmailConfirm": APPB2C_cj_personalDetails_error_missingEmailConfirm,
  "APPB2C.cj.personalDetails.error.wrongEmailConfirm": APPB2C_cj_personalDetails_error_wrongEmailConfirm,
  "APPB2C.cj.personalDetails.error.wrongDob": APPB2C_cj_personalDetails_error_wrongDob,
  "APPB2C.cj.personalDetails.error.missingMobile": APPB2C_cj_personalDetails_error_missingMobile,
  "APPB2C.cj.personalDetails.error.missingAddress": APPB2C_cj_personalDetails_error_missingAddress,
  "APPB2C.cj.personalDetails.error.missingSex": APPB2C_cj_personalDetails_error_missingSex,
  "APPB2C.cj.personalDetails.error.missingTown": APPB2C_cj_personalDetails_error_missingTown,
  "APPB2C.cj.personalDetails.error.missingZip": APPB2C_cj_personalDetails_error_missingZip,
  "APPB2C.cj.personalDetails.error.missingState": APPB2C_cj_personalDetails_error_missingState,
  "APPB2C.cj.personalDetails.error.doubleBooking": APPB2C_cj_personalDetails_error_doubleBooking,
  "APPB2C.cj.personalDetails.examTakenModal.title": APPB2C_cj_personalDetails_examTakenModal_title,
  "APPB2C.cj.personalDetails.examTakenModal.message": APPB2C_cj_personalDetails_examTakenModal_message,
  "APPB2C.cj.personalDetails.examTakenModal.cta": APPB2C_cj_personalDetails_examTakenModal_cta,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.title": APPB2C_cj_personalDetails_existingRegistrationsModal_title,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle": APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourWrittenTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourSpeakingTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourLifeSkillsTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate": APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse": APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.title": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.changeDate": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line1": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line1,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line2": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line2,
  "APPB2C.cj.childDetails.title": APPB2C_cj_childDetails_title,
  "APPB2C.cj.childDetails.about": APPB2C_cj_childDetails_about,
  "APPB2C.cj.childDetails.aboutNote": APPB2C_cj_childDetails_aboutNote,
  "APPB2C.cj.childDetails.noLastName": APPB2C_cj_childDetails_noLastName,
  "APPB2C.cj.childDetails.note": APPB2C_cj_childDetails_note,
  "APPB2C.cj.childDetails.onTestDay": APPB2C_cj_childDetails_onTestDay,
  "APPB2C.cj.childDetails.forms.personal.idMatch": APPB2C_cj_childDetails_forms_personal_idMatch,
  "APPB2C.cj.childDetails.error.missingFirstName": APPB2C_cj_childDetails_error_missingFirstName,
  "APPB2C.cj.childDetails.error.missingSurname": APPB2C_cj_childDetails_error_missingSurname,
  "APPB2C.cj.childDetails.error.wrongDob": APPB2C_cj_childDetails_error_wrongDob,
  "APPB2C.cj.marketingPrefs": APPB2C_cj_marketingPrefs,
  "APPB2C.cj.idDetails.title": APPB2C_cj_idDetails_title,
  "APPB2C.cj.idDetails.title.child": APPB2C_cj_idDetails_title_child,
  "APPB2C.cj.idDetails.forms.idType": APPB2C_cj_idDetails_forms_idType,
  "APPB2C.cj.idDetails.forms.changeNote": APPB2C_cj_idDetails_forms_changeNote,
  "APPB2C.cj.idDetails.forms.changeNote.child": APPB2C_cj_idDetails_forms_changeNote_child,
  "APPB2C.cj.idDetails.forms.idNumber": APPB2C_cj_idDetails_forms_idNumber,
  "APPB2C.cj.idDetails.forms.issuingAuthority": APPB2C_cj_idDetails_forms_issuingAuthority,
  "APPB2C.cj.idDetails.forms.expiryDate": APPB2C_cj_idDetails_forms_expiryDate,
  "APPB2C.cj.idDetails.forms.documentWithNoExpiryDate": APPB2C_cj_idDetails_forms_documentWithNoExpiryDate,
  "APPB2C.cj.idDetails.typeNames.passport": APPB2C_cj_idDetails_typeNames_passport,
  "APPB2C.cj.idDetails.typeNames.card": APPB2C_cj_idDetails_typeNames_card,
  "APPB2C.cj.idDetails.typeNames.other": APPB2C_cj_idDetails_typeNames_other,
  "APPB2C.cj.idDetails.iolMessage": APPB2C_cj_idDetails_iolMessage,
  "APPB2C.cj.idDetails.idExpired": APPB2C_cj_idDetails_idExpired,
  "APPB2C.cj.idDetails.noteAboutIdentification": APPB2C_cj_idDetails_noteAboutIdentification,
  "APPB2C.cj.idDetails.noteAboutIdentificationIol": APPB2C_cj_idDetails_noteAboutIdentificationIol,
  "APPB2C.cj.idDetails.noteAboutIdentificationUol": APPB2C_cj_idDetails_noteAboutIdentificationUol,
  "APPB2C.cj.idDetails.noteAboutIdentificationIolLink": APPB2C_cj_idDetails_noteAboutIdentificationIolLink,
  "APPB2C.cj.idDetails.noteAboutIdentification.child": APPB2C_cj_idDetails_noteAboutIdentification_child,
  "APPB2C.cj.idDetails.upload.title": APPB2C_cj_idDetails_upload_title,
  "APPB2C.cj.idDetails.upload.mobile": APPB2C_cj_idDetails_upload_mobile,
  "APPB2C.cj.idDetails.upload.instructions.title": APPB2C_cj_idDetails_upload_instructions_title,
  "APPB2C.cj.idDetails.upload.instructions.capture": APPB2C_cj_idDetails_upload_instructions_capture,
  "APPB2C.cj.idDetails.upload.instructions.noBlur": APPB2C_cj_idDetails_upload_instructions_noBlur,
  "APPB2C.cj.idDetails.upload.instructions.directLight": APPB2C_cj_idDetails_upload_instructions_directLight,
  "APPB2C.cj.idDetails.upload.instructions.covers": APPB2C_cj_idDetails_upload_instructions_covers,
  "APPB2C.cj.idDetails.upload.file1": APPB2C_cj_idDetails_upload_file1,
  "APPB2C.cj.idDetails.upload.file2": APPB2C_cj_idDetails_upload_file2,
  "APPB2C.cj.idDetails.upload.fetchError": APPB2C_cj_idDetails_upload_fetchError,
  "APPB2C.cj.idDetails.upload.passport.guidelines.followInstructions": APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions,
  "APPB2C.cj.idDetails.upload.passport.guidelines.crop": APPB2C_cj_idDetails_upload_passport_guidelines_crop,
  "APPB2C.cj.idDetails.upload.passport.guidelines.cover": APPB2C_cj_idDetails_upload_passport_guidelines_cover,
  "APPB2C.cj.idDetails.upload.passport.guidelines.glare": APPB2C_cj_idDetails_upload_passport_guidelines_glare,
  "APPB2C.cj.idDetails.upload.passport.guidelines.watermark": APPB2C_cj_idDetails_upload_passport_guidelines_watermark,
  "APPB2C.cj.idDetails.confirm.title": APPB2C_cj_idDetails_confirm_title,
  "APPB2C.cj.idDetails.confirm.note1": APPB2C_cj_idDetails_confirm_note1,
  "APPB2C.cj.idDetails.confirm.note2": APPB2C_cj_idDetails_confirm_note2,
  "APPB2C.cj.idDetails.confirm.confirm": APPB2C_cj_idDetails_confirm_confirm,
  "APPB2C.cj.idDetails.confirm.change": APPB2C_cj_idDetails_confirm_change,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.title": APPB2C_cj_idDetails_confirm_wrongDetailsNote_title,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.content": APPB2C_cj_idDetails_confirm_wrongDetailsNote_content,
  "APPB2C.cj.idDetails.error.missingIdNumber": APPB2C_cj_idDetails_error_missingIdNumber,
  "APPB2C.cj.idDetails.error.missingIdExpiry": APPB2C_cj_idDetails_error_missingIdExpiry,
  "APPB2C.cj.idDetails.error.missingIdType": APPB2C_cj_idDetails_error_missingIdType,
  "APPB2C.cj.idDetails.error.wrongIdExpiry": APPB2C_cj_idDetails_error_wrongIdExpiry,
  "APPB2C.cj.idDetails.error.missingIssuingAuthority": APPB2C_cj_idDetails_error_missingIssuingAuthority,
  "APPB2C.cj.idDetails.error.wrongFilename": APPB2C_cj_idDetails_error_wrongFilename,
  "APPB2C.cj.ttProfile.title": APPB2C_cj_ttProfile_title,
  "APPB2C.cj.ttProfile.title.child": APPB2C_cj_ttProfile_title_child,
  "APPB2C.cj.ttProfile.note": APPB2C_cj_ttProfile_note,
  "APPB2C.cj.ttProfile.note.child": APPB2C_cj_ttProfile_note_child,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle": APPB2C_cj_ttProfile_forms_personal_sectionTitle,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle.child": APPB2C_cj_ttProfile_forms_personal_sectionTitle_child,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality": APPB2C_cj_ttProfile_forms_personal_countryOfNationality,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality.child": APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken.child": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.384": APPB2C_cj_ttProfile_forms_personal_educationLevel_384,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.385": APPB2C_cj_ttProfile_forms_personal_educationLevel_385,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.386": APPB2C_cj_ttProfile_forms_personal_educationLevel_386,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.387": APPB2C_cj_ttProfile_forms_personal_educationLevel_387,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.title": APPB2C_cj_ttProfile_forms_personal_educationLevel_title,
  "APPB2C.cj.ttProfile.forms.occupation.sectionTitle": APPB2C_cj_ttProfile_forms_occupation_sectionTitle,
  "APPB2C.cj.ttProfile.forms.occupation.occupationLevel": APPB2C_cj_ttProfile_forms_occupation_occupationLevel,
  "APPB2C.cj.ttProfile.forms.occupation.occupationSector": APPB2C_cj_ttProfile_forms_occupation_occupationSector,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle": APPB2C_cj_ttProfile_forms_interest_sectionTitle,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle.child": APPB2C_cj_ttProfile_forms_interest_sectionTitle_child,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest.child": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied.child": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child,
  "APPB2C.cj.review.title": APPB2C_cj_review_title,
  "APPB2C.cj.review.note.title": APPB2C_cj_review_note_title,
  "APPB2C.cj.review.note.content": APPB2C_cj_review_note_content,
  "APPB2C.cj.review.registrationError": APPB2C_cj_review_registrationError,
  "APPB2C.cj.review.missingMarketingError": APPB2C_cj_review_missingMarketingError,
  "APPB2C.cj.review.missingMarketingCta": APPB2C_cj_review_missingMarketingCta,
  "APPB2C.cj.review.taxNumberError": APPB2C_cj_review_taxNumberError,
  "APPB2C.cj.review.paymentError": APPB2C_cj_review_paymentError,
  "APPB2C.cj.review.expiredIdError": APPB2C_cj_review_expiredIdError,
  "APPB2C.cj.review.tooHighPromoCodeError": APPB2C_cj_review_tooHighPromoCodeError,
  "APPB2C.cj.review.error.wrongTaxNumber": APPB2C_cj_review_error_wrongTaxNumber,
  "APPB2C.cj.review.error.missingTown": APPB2C_cj_review_error_missingTown,
  "APPB2C.cj.review.error.missingPickups": APPB2C_cj_review_error_missingPickups,
  "APPB2C.cj.review.error.missingOrderAcknowledgement": APPB2C_cj_review_error_missingOrderAcknowledgement,
  "APPB2C.cj.review.error.wrongPaymentConfig": APPB2C_cj_review_error_wrongPaymentConfig,
  "APPB2C.cj.review.error.wrongCountrySetting": APPB2C_cj_review_error_wrongCountrySetting,
  "APPB2C.cj.review.change": APPB2C_cj_review_change,
  "APPB2C.cj.review.changeUkviLsTest": APPB2C_cj_review_changeUkviLsTest,
  "APPB2C.cj.review.changeLrwTest": APPB2C_cj_review_changeLrwTest,
  "APPB2C.cj.review.changeSpeakingTest": APPB2C_cj_review_changeSpeakingTest,
  "APPB2C.cj.review.payOnline.buttonText": APPB2C_cj_review_payOnline_buttonText,
  "APPB2C.cj.review.bookNowNoFee.buttonText": APPB2C_cj_review_bookNowNoFee_buttonText,
  "APPB2C.cj.review.payOnline.sideNote": APPB2C_cj_review_payOnline_sideNote,
  "APPB2C.cj.review.or": APPB2C_cj_review_or,
  "APPB2C.cj.review.payLater.buttonText": APPB2C_cj_review_payLater_buttonText,
  "APPB2C.cj.review.confirmPaymentModal.title": APPB2C_cj_review_confirmPaymentModal_title,
  "APPB2C.cj.review.confirmPaymentModal.note": APPB2C_cj_review_confirmPaymentModal_note,
  "APPB2C.cj.review.confirmPaymentModal.optionsTitle": APPB2C_cj_review_confirmPaymentModal_optionsTitle,
  "APPB2C.cj.review.confirmPaymentModal.btnNote": APPB2C_cj_review_confirmPaymentModal_btnNote,
  "APPB2C.cj.review.payLater.sideNote": APPB2C_cj_review_payLater_sideNote,
  "APPB2C.cj.review.bookTest": APPB2C_cj_review_bookTest,
  "APPB2C.cj.review.paymentNote": APPB2C_cj_review_paymentNote,
  "APPB2C.cj.review.paymentNoteZeroFee": APPB2C_cj_review_paymentNoteZeroFee,
  "APPB2C.cj.review.localization.location": APPB2C_cj_review_localization_location,
  "APPB2C.cj.review.localization.localTime": APPB2C_cj_review_localization_localTime,
  "APPB2C.cj.review.localization.timezone": APPB2C_cj_review_localization_timezone,
  "APPB2C.cj.review.bookingDetails.title": APPB2C_cj_review_bookingDetails_title,
  "APPB2C.cj.review.bookingDetails.lrw.title": APPB2C_cj_review_bookingDetails_lrw_title,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills,
  "APPB2C.cj.review.bookingDetails.lrw.title.child": APPB2C_cj_review_bookingDetails_lrw_title_child,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills.child": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child,
  "APPB2C.cj.review.bookingDetails.speaking.title": APPB2C_cj_review_bookingDetails_speaking_title,
  "APPB2C.cj.review.bookingDetails.speaking.title.child": APPB2C_cj_review_bookingDetails_speaking_title_child,
  "APPB2C.cj.review.personalDetails.title": APPB2C_cj_review_personalDetails_title,
  "APPB2C.cj.review.personalDetails.name": APPB2C_cj_review_personalDetails_name,
  "APPB2C.cj.review.personalDetails.dob": APPB2C_cj_review_personalDetails_dob,
  "APPB2C.cj.review.personalDetails.email": APPB2C_cj_review_personalDetails_email,
  "APPB2C.cj.review.personalDetails.phone": APPB2C_cj_review_personalDetails_phone,
  "APPB2C.cj.review.personalDetails.address": APPB2C_cj_review_personalDetails_address,
  "APPB2C.cj.review.personalDetails.gender": APPB2C_cj_review_personalDetails_gender,
  "APPB2C.cj.review.personalDetails.smsNotificationsConsent": APPB2C_cj_review_personalDetails_smsNotificationsConsent,
  "APPB2C.cj.review.childDetails.title": APPB2C_cj_review_childDetails_title,
  "APPB2C.cj.review.childDetails.onTestDay": APPB2C_cj_review_childDetails_onTestDay,
  "APPB2C.cj.review.childDetails.guardiansTitle": APPB2C_cj_review_childDetails_guardiansTitle,
  "APPB2C.cj.review.childDetails.onTestDayOptions.1": APPB2C_cj_review_childDetails_onTestDayOptions_1,
  "APPB2C.cj.review.childDetails.onTestDayOptions.2": APPB2C_cj_review_childDetails_onTestDayOptions_2,
  "APPB2C.cj.review.childDetails.onTestDayOptions.3": APPB2C_cj_review_childDetails_onTestDayOptions_3,
  "APPB2C.cj.review.idDetails.title": APPB2C_cj_review_idDetails_title,
  "APPB2C.cj.review.idDetails.title.child": APPB2C_cj_review_idDetails_title_child,
  "APPB2C.cj.review.idDetails.issuing": APPB2C_cj_review_idDetails_issuing,
  "APPB2C.cj.review.idDetails.nationality": APPB2C_cj_review_idDetails_nationality,
  "APPB2C.cj.review.payment.title": APPB2C_cj_review_payment_title,
  "APPB2C.cj.review.payment.fee": APPB2C_cj_review_payment_fee,
  "APPB2C.cj.review.payment.total": APPB2C_cj_review_payment_total,
  "APPB2C.cj.review.payment.tax": APPB2C_cj_review_payment_tax,
  "APPB2C.cj.review.payment.promoCodes.promoCode": APPB2C_cj_review_payment_promoCodes_promoCode,
  "APPB2C.cj.review.payment.promoCodes.haveCode": APPB2C_cj_review_payment_promoCodes_haveCode,
  "APPB2C.cj.review.payment.promoCodes.notHaveCode": APPB2C_cj_review_payment_promoCodes_notHaveCode,
  "APPB2C.cj.review.payment.promoCodes.removeCode": APPB2C_cj_review_payment_promoCodes_removeCode,
  "APPB2C.cj.review.payment.promoCodes.remove": APPB2C_cj_review_payment_promoCodes_remove,
  "APPB2C.cj.review.payment.promoCodes.applyCode": APPB2C_cj_review_payment_promoCodes_applyCode,
  "APPB2C.cj.review.payment.promoCodes.discount": APPB2C_cj_review_payment_promoCodes_discount,
  "APPB2C.cj.review.payment.promoCodes.promo": APPB2C_cj_review_payment_promoCodes_promo,
  "APPB2C.cj.review.payment.promoCodes.total": APPB2C_cj_review_payment_promoCodes_total,
  "APPB2C.cj.review.payment.promoCodes.newFee": APPB2C_cj_review_payment_promoCodes_newFee,
  "APPB2C.cj.review.payment.promoCodes.invalidCode": APPB2C_cj_review_payment_promoCodes_invalidCode,
  "APPB2C.cj.review.payment.promoCodes.generalError": APPB2C_cj_review_payment_promoCodes_generalError,
  "APPB2C.cj.review.payment.promoCodes.applied": APPB2C_cj_review_payment_promoCodes_applied,
  "APPB2C.cj.review.invoice.title": APPB2C_cj_review_invoice_title,
  "APPB2C.cj.review.invoice.send": APPB2C_cj_review_invoice_send,
  "APPB2C.cj.review.invoice.type.duplicatePaper": APPB2C_cj_review_invoice_type_duplicatePaper,
  "APPB2C.cj.review.invoice.type.triplicate": APPB2C_cj_review_invoice_type_triplicate,
  "APPB2C.cj.review.invoice.type.duplicateElectronic": APPB2C_cj_review_invoice_type_duplicateElectronic,
  "APPB2C.cj.review.invoice.type.donate": APPB2C_cj_review_invoice_type_donate,
  "APPB2C.cj.review.invoice.subtype.label": APPB2C_cj_review_invoice_subtype_label,
  "APPB2C.cj.review.invoice.subtype.mobileBarcode": APPB2C_cj_review_invoice_subtype_mobileBarcode,
  "APPB2C.cj.review.invoice.subtype.citizenDigitalCertificate": APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate,
  "APPB2C.cj.review.invoice.consent": APPB2C_cj_review_invoice_consent,
  "APPB2C.cj.review.invoice.companyName": APPB2C_cj_review_invoice_companyName,
  "APPB2C.cj.review.invoice.taxCaption.duplicatePaper": APPB2C_cj_review_invoice_taxCaption_duplicatePaper,
  "APPB2C.cj.review.invoice.taxCaption.donate": APPB2C_cj_review_invoice_taxCaption_donate,
  "APPB2C.cj.review.invoice.taxValidation.triplicate": APPB2C_cj_review_invoice_taxValidation_triplicate,
  "APPB2C.cj.review.invoice.taxValidation.mobileBarcode": APPB2C_cj_review_invoice_taxValidation_mobileBarcode,
  "APPB2C.cj.review.invoice.taxValidation.citizenDigitalCertificate": APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate,
  "APPB2C.cj.review.invoice.taxValidation.donate": APPB2C_cj_review_invoice_taxValidation_donate,
  "APPB2C.cj.review.acknowledgement.title": APPB2C_cj_review_acknowledgement_title,
  "APPB2C.cj.review.acknowledgement.send": APPB2C_cj_review_acknowledgement_send,
  "APPB2C.cj.review.acknowledgement.myself": APPB2C_cj_review_acknowledgement_myself,
  "APPB2C.cj.review.acknowledgement.anotherPerson": APPB2C_cj_review_acknowledgement_anotherPerson,
  "APPB2C.cj.review.acknowledgement.company": APPB2C_cj_review_acknowledgement_company,
  "APPB2C.cj.review.acknowledgement.fields.companyName": APPB2C_cj_review_acknowledgement_fields_companyName,
  "APPB2C.cj.review.acknowledgement.fields.companyName.subnote": APPB2C_cj_review_acknowledgement_fields_companyName_subnote,
  "APPB2C.cj.review.acknowledgement.fields.firstName": APPB2C_cj_review_acknowledgement_fields_firstName,
  "APPB2C.cj.review.acknowledgement.fields.familyName": APPB2C_cj_review_acknowledgement_fields_familyName,
  "APPB2C.cj.review.acknowledgement.fields.email": APPB2C_cj_review_acknowledgement_fields_email,
  "APPB2C.cj.review.acknowledgement.fields.mobile": APPB2C_cj_review_acknowledgement_fields_mobile,
  "APPB2C.cj.review.acknowledgement.fields.country": APPB2C_cj_review_acknowledgement_fields_country,
  "APPB2C.cj.review.ai.title": APPB2C_cj_review_ai_title,
  "APPB2C.cj.review.ai.note": APPB2C_cj_review_ai_note,
  "APPB2C.cj.review.agent.title": APPB2C_cj_review_agent_title,
  "APPB2C.cj.review.agent.note": APPB2C_cj_review_agent_note,
  "APPB2C.cj.review.agent.label": APPB2C_cj_review_agent_label,
  "APPB2C.cj.review.tncs.title": APPB2C_cj_review_tncs_title,
  "APPB2C.cj.review.tncs.agreement": APPB2C_cj_review_tncs_agreement,
  "APPB2C.cj.review.countryMsg.pakistan": APPB2C_cj_review_countryMsg_pakistan,
  "APPB2C.cj.review.registrationFailedModal.title": APPB2C_cj_review_registrationFailedModal_title,
  "APPB2C.cj.review.registrationFailedModal.message": APPB2C_cj_review_registrationFailedModal_message,
  "APPB2C.cj.review.registrationFailedModal.changedFee.title": APPB2C_cj_review_registrationFailedModal_changedFee_title,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line1": APPB2C_cj_review_registrationFailedModal_changedFee_line1,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line2": APPB2C_cj_review_registrationFailedModal_changedFee_line2,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line3": APPB2C_cj_review_registrationFailedModal_changedFee_line3,
  "APPB2C.cj.review.registrationFailedModal.changedFee.btn": APPB2C_cj_review_registrationFailedModal_changedFee_btn,
  "APPB2C.cj.review.registrationFailedModal.cta": APPB2C_cj_review_registrationFailedModal_cta,
  "APPB2C.cj.finishPayment.processing": APPB2C_cj_finishPayment_processing,
  "APPB2C.cj.finishPayment.pleaseWait": APPB2C_cj_finishPayment_pleaseWait,
  "APPB2C.cj.finishPayment.error": APPB2C_cj_finishPayment_error,
  "APPB2C.cj.bookingComplete.title": APPB2C_cj_bookingComplete_title,
  "APPB2C.cj.bookingComplete.yourBooking": APPB2C_cj_bookingComplete_yourBooking,
  "APPB2C.cj.bookingComplete.note": APPB2C_cj_bookingComplete_note,
  "APPB2C.cj.bookingComplete.noteUolPassportInfo": APPB2C_cj_bookingComplete_noteUolPassportInfo,
  "APPB2C.cj.bookingComplete.paymentFailed": APPB2C_cj_bookingComplete_paymentFailed,
  "APPB2C.cj.bookingComplete.tryAgain": APPB2C_cj_bookingComplete_tryAgain,
  "APPB2C.cj.bookingComplete.otherPaymentOptions": APPB2C_cj_bookingComplete_otherPaymentOptions,
  "APPB2C.cj.bookingComplete.paymentOptions": APPB2C_cj_bookingComplete_paymentOptions,
  "APPB2C.cj.bookingComplete.loadingRegistrationError": APPB2C_cj_bookingComplete_loadingRegistrationError,
  "APPB2C.cj.bookingComplete.loadingPaymentMethodsError": APPB2C_cj_bookingComplete_loadingPaymentMethodsError,
  "APPB2C.cj.bookingComplete.feeDetails.title": APPB2C_cj_bookingComplete_feeDetails_title,
  "APPB2C.cj.bookingComplete.feeDetails.fee": APPB2C_cj_bookingComplete_feeDetails_fee,
  "APPB2C.cj.bookingComplete.feeDetails.newFee": APPB2C_cj_bookingComplete_feeDetails_newFee,
  "APPB2C.cj.bookingComplete.feeDetails.tax": APPB2C_cj_bookingComplete_feeDetails_tax,
  "APPB2C.cj.bookingComplete.feeDetails.discount": APPB2C_cj_bookingComplete_feeDetails_discount,
  "APPB2C.cj.bookingComplete.feeDetails.promo": APPB2C_cj_bookingComplete_feeDetails_promo,
  "APPB2C.cj.bookingComplete.feeDetails.total": APPB2C_cj_bookingComplete_feeDetails_total,
  "APPB2C.cj.bookingComplete.feeDetails.reference": APPB2C_cj_bookingComplete_feeDetails_reference,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine1": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine1,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine2": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine2,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine3": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine3,
  "APPB2C.cj.bookingComplete.prepareNote.title": APPB2C_cj_bookingComplete_prepareNote_title,
  "APPB2C.cj.bookingComplete.prepareNote.note": APPB2C_cj_bookingComplete_prepareNote_note,
  "APPB2C.cj.bookingComplete.prepareNote.accesNote": APPB2C_cj_bookingComplete_prepareNote_accesNote,
  "APPB2C.cj.bookingComplete.prepareNote.view": APPB2C_cj_bookingComplete_prepareNote_view,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.note": APPB2C_cj_bookingComplete_prepareNote_counselling_note,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.view": APPB2C_cj_bookingComplete_prepareNote_counselling_view,
  "APPB2C.cj.bookingComplete.offlinePayment.note.pleaseNote": APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1": APPB2C_cj_bookingComplete_offlinePayment_note_line1,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.hours": APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.timeLeft": APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line2": APPB2C_cj_bookingComplete_offlinePayment_note_line2,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line1": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line1,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line2": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line2,
  "APPB2C.cj.bookingComplete.offlinePayment.fee": APPB2C_cj_bookingComplete_offlinePayment_fee,
  "APPB2C.cj.bookingComplete.offlinePayment.incTax": APPB2C_cj_bookingComplete_offlinePayment_incTax,
  "APPB2C.cj.bookingComplete.offlinePayment.name": APPB2C_cj_bookingComplete_offlinePayment_name,
  "APPB2C.cj.bookingComplete.offlinePayment.number": APPB2C_cj_bookingComplete_offlinePayment_number,
  "APPB2C.cj.bookingComplete.offlinePayment.code": APPB2C_cj_bookingComplete_offlinePayment_code,
  "APPB2C.cj.bookingComplete.offlinePayment.reference": APPB2C_cj_bookingComplete_offlinePayment_reference,
  "APPB2C.cj.bookingComplete.offlinePayment.description": APPB2C_cj_bookingComplete_offlinePayment_description,
  "APPB2C.cj.bookingComplete.offlinePayment.showDescription": APPB2C_cj_bookingComplete_offlinePayment_showDescription,
  "APPB2C.cj.bookingComplete.offlinePayment.hideDescription": APPB2C_cj_bookingComplete_offlinePayment_hideDescription,
  "APPB2C.cj.bookingComplete.tnc": APPB2C_cj_bookingComplete_tnc,
  "APPB2C.cj.bookingComplete.tncLink": APPB2C_cj_bookingComplete_tncLink,
  "APPB2C.cj.ukviStartPage.title": APPB2C_cj_ukviStartPage_title,
  "APPB2C.cj.ukviStartPage.note1.title": APPB2C_cj_ukviStartPage_note1_title,
  "APPB2C.cj.ukviStartPage.note1.content": APPB2C_cj_ukviStartPage_note1_content,
  "APPB2C.cj.ukviStartPage.testsBelow": APPB2C_cj_ukviStartPage_testsBelow,
  "APPB2C.cj.ukviStartPage.ac.title": APPB2C_cj_ukviStartPage_ac_title,
  "APPB2C.cj.ukviStartPage.ac.content": APPB2C_cj_ukviStartPage_ac_content,
  "APPB2C.cj.ukviStartPage.gt.title": APPB2C_cj_ukviStartPage_gt_title,
  "APPB2C.cj.ukviStartPage.gt.content": APPB2C_cj_ukviStartPage_gt_content,
  "APPB2C.cj.ukviStartPage.lifeSkils.title": APPB2C_cj_ukviStartPage_lifeSkils_title,
  "APPB2C.cj.ukviStartPage.lifeSkils.visaTypes": APPB2C_cj_ukviStartPage_lifeSkils_visaTypes,
  "APPB2C.cj.ukviStartPage.lifeSkils.family": APPB2C_cj_ukviStartPage_lifeSkils_family,
  "APPB2C.cj.ukviStartPage.lifeSkils.extension": APPB2C_cj_ukviStartPage_lifeSkils_extension,
  "APPB2C.cj.ukviStartPage.lifeSkils.citizenship": APPB2C_cj_ukviStartPage_lifeSkils_citizenship,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.title": APPB2C_cj_ukviStartPage_lifeSkils_note_title,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.content": APPB2C_cj_ukviStartPage_lifeSkils_note_content,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.title": APPB2C_cj_ukviStartPage_lifeSkils_a1_title,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.content": APPB2C_cj_ukviStartPage_lifeSkils_a1_content,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.title": APPB2C_cj_ukviStartPage_lifeSkils_a2_title,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.content": APPB2C_cj_ukviStartPage_lifeSkils_a2_content,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.title": APPB2C_cj_ukviStartPage_lifeSkils_b1_title,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.content": APPB2C_cj_ukviStartPage_lifeSkils_b1_content,
  "APPB2C.cj.ukviStartPage.whichUkvi": APPB2C_cj_ukviStartPage_whichUkvi,
  "APPB2C.cj.ukviStartPage.terms": APPB2C_cj_ukviStartPage_terms,
  "APPB2C.cj.onRequest.note": APPB2C_cj_onRequest_note,
  "APPB2C.cj.onRequest.info1": APPB2C_cj_onRequest_info1,
  "APPB2C.cj.onRequest.prefNote": APPB2C_cj_onRequest_prefNote,
  "APPB2C.cj.onRequest.please": APPB2C_cj_onRequest_please,
  "APPB2C.cj.onRequest.form.labels.testRangeQuestion": APPB2C_cj_onRequest_form_labels_testRangeQuestion,
  "APPB2C.cj.onRequest.form.labels.firstname": APPB2C_cj_onRequest_form_labels_firstname,
  "APPB2C.cj.onRequest.form.labels.surname": APPB2C_cj_onRequest_form_labels_surname,
  "APPB2C.cj.onRequest.form.labels.telephone": APPB2C_cj_onRequest_form_labels_telephone,
  "APPB2C.cj.onRequest.form.labels.email": APPB2C_cj_onRequest_form_labels_email,
  "APPB2C.cj.onRequest.form.labels.confirmEmail": APPB2C_cj_onRequest_form_labels_confirmEmail,
  "APPB2C.cj.onRequest.form.submit": APPB2C_cj_onRequest_form_submit,
  "APPB2C.cj.onRequest.form.testRangeOpt.oneMonth": APPB2C_cj_onRequest_form_testRangeOpt_oneMonth,
  "APPB2C.cj.onRequest.form.testRangeOpt.twoMonths": APPB2C_cj_onRequest_form_testRangeOpt_twoMonths,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonths": APPB2C_cj_onRequest_form_testRangeOpt_threeMonths,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonthsPlus": APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus,
  "APPB2C.cj.onRequest.otherLocations.info1": APPB2C_cj_onRequest_otherLocations_info1,
  "APPB2C.cj.onRequest.otherLocations.choose": APPB2C_cj_onRequest_otherLocations_choose,
  "APPB2C.cj.onRequest.otherLocations.info2": APPB2C_cj_onRequest_otherLocations_info2,
  "APPB2C.cj.onRequest.otherLocations.show": APPB2C_cj_onRequest_otherLocations_show,
  "APPB2C.cj.onRequest.otherLocations.hide": APPB2C_cj_onRequest_otherLocations_hide,
  "APPB2C.cj.onRequest.subtitles.register": APPB2C_cj_onRequest_subtitles_register,
  "APPB2C.cj.onRequest.subtitles.aboutYou": APPB2C_cj_onRequest_subtitles_aboutYou,
  "APPB2C.cj.onRequest.subtitles.aboutPreferences": APPB2C_cj_onRequest_subtitles_aboutPreferences,
  "APPB2C.cj.onRequest.subtitles.markpref": APPB2C_cj_onRequest_subtitles_markpref,
  "APPB2C.cj.onRequest.created.title": APPB2C_cj_onRequest_created_title,
  "APPB2C.cj.onRequest.created.note": APPB2C_cj_onRequest_created_note,
  "APPB2C.cj.onRequest.created.in": APPB2C_cj_onRequest_created_in,
  "APPB2C.cj.onRequest.created.info": APPB2C_cj_onRequest_created_info,
  "APPB2C.cj.onRequest.created.back": APPB2C_cj_onRequest_created_back,
  "APPB2C.cj.ieltsReady.title": APPB2C_cj_ieltsReady_title,
  "APPB2C.cj.ieltsReady.note": APPB2C_cj_ieltsReady_note,
  "APPB2C.cj.ieltsReady.submitBtnText": APPB2C_cj_ieltsReady_submitBtnText,
  "APPB2C.cj.ieltsReady.dobMin": APPB2C_cj_ieltsReady_dobMin,
  "APPB2C.cj.ieltsReady.targetScore.title": APPB2C_cj_ieltsReady_targetScore_title,
  "APPB2C.cj.ieltsReady.targetScore.option": APPB2C_cj_ieltsReady_targetScore_option,
  "APPB2C.cj.ieltsReady.nameWarning": APPB2C_cj_ieltsReady_nameWarning,
  "APPB2C.cj.ieltsReady.description.line1": APPB2C_cj_ieltsReady_description_line1,
  "APPB2C.cj.ieltsReady.description.line2": APPB2C_cj_ieltsReady_description_line2,
  "APPB2C.common.basic.cancel": APPB2C_common_basic_cancel,
  "APPB2C.common.basic.bookPrePaid": APPB2C_common_basic_bookPrePaid,
  "APPB2C.common.basic.notFound.title": APPB2C_common_basic_notFound_title,
  "APPB2C.common.basic.retake": APPB2C_common_basic_retake,
  "APPB2C.common.basic.notFound.msg": APPB2C_common_basic_notFound_msg,
  "APPB2C.common.basic.notFound.goHome": APPB2C_common_basic_notFound_goHome,
  "APPB2C.common.basic.expando.show": APPB2C_common_basic_expando_show,
  "APPB2C.common.basic.expando.collapse": APPB2C_common_basic_expando_collapse,
  "APPB2C.common.basic.notAllowed.title": APPB2C_common_basic_notAllowed_title,
  "APPB2C.common.basic.notAllowed.msg": APPB2C_common_basic_notAllowed_msg,
  "APPB2C.common.basic.errors.title": APPB2C_common_basic_errors_title,
  "APPB2C.common.basic.errors.network": APPB2C_common_basic_errors_network,
  "APPB2C.common.basic.errors.server": APPB2C_common_basic_errors_server,
  "APPB2C.common.basic.errors.unknown": APPB2C_common_basic_errors_unknown,
  "APPB2C.common.basic.errors.contactTestCentre": APPB2C_common_basic_errors_contactTestCentre,
  "APPB2C.common.basic.errors.noTest": APPB2C_common_basic_errors_noTest,
  "APPB2C.common.basic.errors.unableToDownloadFile": APPB2C_common_basic_errors_unableToDownloadFile,
  "APPB2C.common.basic.notLoading.title": APPB2C_common_basic_notLoading_title,
  "APPB2C.common.basic.notLoading.note": APPB2C_common_basic_notLoading_note,
  "APPB2C.common.basic.notLoading.refresh": APPB2C_common_basic_notLoading_refresh,
  "APPB2C.common.basic.dateField.day": APPB2C_common_basic_dateField_day,
  "APPB2C.common.basic.dateField.months": APPB2C_common_basic_dateField_months,
  "APPB2C.common.basic.dateField.month": APPB2C_common_basic_dateField_month,
  "APPB2C.common.basic.dateField.year": APPB2C_common_basic_dateField_year,
  "APPB2C.common.basic.bookingDetails.lrw.title": APPB2C_common_basic_bookingDetails_lrw_title,
  "APPB2C.common.basic.bookingDetails.osrTitle.listening": APPB2C_common_basic_bookingDetails_osrTitle_listening,
  "APPB2C.common.basic.bookingDetails.osrTitle.reading": APPB2C_common_basic_bookingDetails_osrTitle_reading,
  "APPB2C.common.basic.bookingDetails.lrw.results": APPB2C_common_basic_bookingDetails_lrw_results,
  "APPB2C.common.basic.bookingDetails.lrw.cd": APPB2C_common_basic_bookingDetails_lrw_cd,
  "APPB2C.common.basic.bookingDetails.lrw.pb": APPB2C_common_basic_bookingDetails_lrw_pb,
  "APPB2C.common.basic.bookingDetails.lrw.online": APPB2C_common_basic_bookingDetails_lrw_online,
  "APPB2C.common.basic.bookingDetails.speaking.title": APPB2C_common_basic_bookingDetails_speaking_title,
  "APPB2C.common.basic.bookingDetails.speaking.results": APPB2C_common_basic_bookingDetails_speaking_results,
  "APPB2C.common.basic.bookingDetails.speaking.f2f": APPB2C_common_basic_bookingDetails_speaking_f2f,
  "APPB2C.common.basic.bookingDetails.speaking.live": APPB2C_common_basic_bookingDetails_speaking_live,
  "APPB2C.common.basic.bookingDetails.speaking.remote": APPB2C_common_basic_bookingDetails_speaking_remote,
  "APPB2C.common.basic.bookingDetails.speaking.online": APPB2C_common_basic_bookingDetails_speaking_online,
  "APPB2C.common.basic.bookingDetails.lifeSkills.title": APPB2C_common_basic_bookingDetails_lifeSkills_title,
  "APPB2C.common.basic.bookingDetails.lifeSkills.results": APPB2C_common_basic_bookingDetails_lifeSkills_results,
  "APPB2C.common.basic.bookingDetails.arrival": APPB2C_common_basic_bookingDetails_arrival,
  "APPB2C.common.basic.yes": APPB2C_common_basic_yes,
  "APPB2C.common.basic.no": APPB2C_common_basic_no,
  "APPB2C.common.basic.goBack": APPB2C_common_basic_goBack,
  "APPB2C.common.basic.menu": APPB2C_common_basic_menu,
  "APPB2C.common.basic.choose": APPB2C_common_basic_choose,
  "APPB2C.common.basic.pleaseNote": APPB2C_common_basic_pleaseNote,
  "APPB2C.common.basic.loading": APPB2C_common_basic_loading,
  "APPB2C.common.basic.oneSecond": APPB2C_common_basic_oneSecond,
  "APPB2C.common.basic.waitReassurance": APPB2C_common_basic_waitReassurance,
  "APPB2C.common.basic.backToTop": APPB2C_common_basic_backToTop,
  "APPB2C.common.basic.wereSorry": APPB2C_common_basic_wereSorry,
  "APPB2C.common.basic.failure": APPB2C_common_basic_failure,
  "APPB2C.common.basic.close": APPB2C_common_basic_close,
  "APPB2C.common.basic.copy": APPB2C_common_basic_copy,
  "APPB2C.common.basic.copied": APPB2C_common_basic_copied,
  "APPB2C.common.basic.externalLinkTitle": APPB2C_common_basic_externalLinkTitle,
  "APPB2C.common.basic.forms.requiredMessage": APPB2C_common_basic_forms_requiredMessage,
  "APPB2C.common.basic.forms.maxLengthMessage": APPB2C_common_basic_forms_maxLengthMessage,
  "APPB2C.common.basic.forms.onlyLatin": APPB2C_common_basic_forms_onlyLatin,
  "APPB2C.common.basic.forms.unsupportedChars": APPB2C_common_basic_forms_unsupportedChars,
  "APPB2C.common.basic.forms.idNumber": APPB2C_common_basic_forms_idNumber,
  "APPB2C.common.basic.forms.idMaxLength": APPB2C_common_basic_forms_idMaxLength,
  "APPB2C.common.basic.forms.idExpiration": APPB2C_common_basic_forms_idExpiration,
  "APPB2C.common.basic.forms.idProofRequired": APPB2C_common_basic_forms_idProofRequired,
  "APPB2C.common.basic.forms.mobileLength": APPB2C_common_basic_forms_mobileLength,
  "APPB2C.common.basic.forms.mobileBypass": APPB2C_common_basic_forms_mobileBypass,
  "APPB2C.common.basic.forms.validMobile": APPB2C_common_basic_forms_validMobile,
  "APPB2C.common.basic.forms.requiredNoSurmane": APPB2C_common_basic_forms_requiredNoSurmane,
  "APPB2C.common.basic.forms.dobOutOfRange": APPB2C_common_basic_forms_dobOutOfRange,
  "APPB2C.common.basic.forms.dobMin": APPB2C_common_basic_forms_dobMin,
  "APPB2C.common.basic.forms.dobMinIol": APPB2C_common_basic_forms_dobMinIol,
  "APPB2C.common.basic.forms.minorDobMax": APPB2C_common_basic_forms_minorDobMax,
  "APPB2C.common.basic.forms.minorDobMin": APPB2C_common_basic_forms_minorDobMin,
  "APPB2C.common.basic.forms.postCodeMessage": APPB2C_common_basic_forms_postCodeMessage,
  "APPB2C.common.basic.forms.postCodeNotFound": APPB2C_common_basic_forms_postCodeNotFound,
  "APPB2C.common.basic.forms.firstNameMaxLength": APPB2C_common_basic_forms_firstNameMaxLength,
  "APPB2C.common.basic.forms.surnameMaxLength": APPB2C_common_basic_forms_surnameMaxLength,
  "APPB2C.common.basic.forms.addressMaxLength": APPB2C_common_basic_forms_addressMaxLength,
  "APPB2C.common.basic.forms.townMaxLength": APPB2C_common_basic_forms_townMaxLength,
  "APPB2C.common.basic.forms.postCodeMaxLength": APPB2C_common_basic_forms_postCodeMaxLength,
  "APPB2C.common.basic.forms.stateMaxLength": APPB2C_common_basic_forms_stateMaxLength,
  "APPB2C.common.basic.ok": APPB2C_common_basic_ok,
  "APPB2C.common.basic.change": APPB2C_common_basic_change,
  "APPB2C.common.basic.edit": APPB2C_common_basic_edit,
  "APPB2C.common.basic.add": APPB2C_common_basic_add,
  "APPB2C.common.basic.upload": APPB2C_common_basic_upload,
  "APPB2C.common.basic.validationError": APPB2C_common_basic_validationError,
  "APPB2C.common.basic.on": APPB2C_common_basic_on,
  "APPB2C.common.specialReqs.AMANU": APPB2C_common_specialReqs_AMANU,
  "APPB2C.common.specialReqs.ANTHR": APPB2C_common_specialReqs_ANTHR,
  "APPB2C.common.specialReqs.BTP": APPB2C_common_specialReqs_BTP,
  "APPB2C.common.specialReqs.BWP": APPB2C_common_specialReqs_BWP,
  "APPB2C.common.specialReqs.EPTP": APPB2C_common_specialReqs_EPTP,
  "APPB2C.common.specialReqs.ET": APPB2C_common_specialReqs_ET,
  "APPB2C.common.specialReqs.LRTV": APPB2C_common_specialReqs_LRTV,
  "APPB2C.common.specialReqs.SAE": APPB2C_common_specialReqs_SAE,
  "APPB2C.common.specialReqs.VAS": APPB2C_common_specialReqs_VAS,
  "APPB2C.common.specialReqs.WP": APPB2C_common_specialReqs_WP,
  "APPB2C.common.general.next": APPB2C_common_general_next,
  "APPB2C.common.general.previous": APPB2C_common_general_previous,
  "APPB2C.common.general.remove": APPB2C_common_general_remove,
  "APPB2C.common.general.regNotFound": APPB2C_common_general_regNotFound,
  "APPB2C.common.general.steps": APPB2C_common_general_steps,
  "APPB2C.common.general.stepsContinued": APPB2C_common_general_stepsContinued,
  "APPB2C.common.nav.login": APPB2C_common_nav_login,
  "APPB2C.common.nav.ttp": APPB2C_common_nav_ttp,
  "APPB2C.common.nav.logout": APPB2C_common_nav_logout,
  "APPB2C.common.nav.logoutModal.title": APPB2C_common_nav_logoutModal_title,
  "APPB2C.common.nav.logoutModal.message": APPB2C_common_nav_logoutModal_message,
  "APPB2C.common.nav.logoutModal.question": APPB2C_common_nav_logoutModal_question,
  "APPB2C.common.nav.logoutModal.confirm": APPB2C_common_nav_logoutModal_confirm,
  "APPB2C.common.nav.hello": APPB2C_common_nav_hello,
  "APPB2C.common.nav.chooseLanguage": APPB2C_common_nav_chooseLanguage,
  "APPB2C.common.nav.bookNewTest": APPB2C_common_nav_bookNewTest,
  "APPB2C.common.nav.resultsTests": APPB2C_common_nav_resultsTests,
  "APPB2C.common.nav.myAccount": APPB2C_common_nav_myAccount,
  "APPB2C.common.nav.helpAndContact": APPB2C_common_nav_helpAndContact,
  "APPB2C.common.nav.preparation": APPB2C_common_nav_preparation,
  "APPB2C.common.examType.ors.ac": APPB2C_common_examType_ors_ac,
  "APPB2C.common.examType.ors.gt": APPB2C_common_examType_ors_gt,
  "APPB2C.common.examType.ors.osr.ac": APPB2C_common_examType_ors_osr_ac,
  "APPB2C.common.examType.ors.osr.gt": APPB2C_common_examType_ors_osr_gt,
  "APPB2C.common.examType.iol.ac": APPB2C_common_examType_iol_ac,
  "APPB2C.common.examType.iol.gt": APPB2C_common_examType_iol_gt,
  "APPB2C.common.examType.uol.ac": APPB2C_common_examType_uol_ac,
  "APPB2C.common.examType.uol.gt": APPB2C_common_examType_uol_gt,
  "APPB2C.common.examType.ukvi.ac": APPB2C_common_examType_ukvi_ac,
  "APPB2C.common.examType.ukvi.gt": APPB2C_common_examType_ukvi_gt,
  "APPB2C.common.examType.ukvi.osr.ac": APPB2C_common_examType_ukvi_osr_ac,
  "APPB2C.common.examType.ukvi.osr.gt": APPB2C_common_examType_ukvi_osr_gt,
  "APPB2C.common.examType.ukvi.lfska1": APPB2C_common_examType_ukvi_lfska1,
  "APPB2C.common.examType.ukvi.lfska2": APPB2C_common_examType_ukvi_lfska2,
  "APPB2C.common.examType.ukvi.lfskb1": APPB2C_common_examType_ukvi_lfskb1,
  "APPB2C.common.startPage.title": APPB2C_common_startPage_title,
  "APPB2C.common.startPage.subtitle": APPB2C_common_startPage_subtitle,
  "APPB2C.common.startPage.ielts": APPB2C_common_startPage_ielts,
  "APPB2C.common.startPage.ieltsUkvi": APPB2C_common_startPage_ieltsUkvi,
  "APPB2C.common.startPage.book": APPB2C_common_startPage_book,
  "APPB2C.common.startPage.options.ac": APPB2C_common_startPage_options_ac,
  "APPB2C.common.startPage.options.gt": APPB2C_common_startPage_options_gt,
  "APPB2C.common.startPage.options.ukvi": APPB2C_common_startPage_options_ukvi,
  "APPB2C.common.startPage.hero.title": APPB2C_common_startPage_hero_title,
  "APPB2C.common.startPage.hero.subtitle": APPB2C_common_startPage_hero_subtitle,
  "APPB2C.common.startPage.hero.cta": APPB2C_common_startPage_hero_cta,
  "APPB2C.common.startPage.whichIELTS": APPB2C_common_startPage_whichIELTS,
  "APPB2C.common.startPage.readTnCs": APPB2C_common_startPage_readTnCs,
  "APPB2C.common.startPage.endNote": APPB2C_common_startPage_endNote,
  "APPB2C.common.startPage.tncs": APPB2C_common_startPage_tncs,
  "APPB2C.common.startPage.description.ac": APPB2C_common_startPage_description_ac,
  "APPB2C.common.startPage.description.gt": APPB2C_common_startPage_description_gt,
  "APPB2C.common.startPage.description.ukvi": APPB2C_common_startPage_description_ukvi,
  "APPB2C.common.startPage.upcoming.title": APPB2C_common_startPage_upcoming_title,
  "APPB2C.common.startPage.upcoming.manage": APPB2C_common_startPage_upcoming_manage,
  "APPB2C.common.startPage.upcoming.prepare": APPB2C_common_startPage_upcoming_prepare,
  "APPB2C.common.startPage.upcoming.cd": APPB2C_common_startPage_upcoming_cd,
  "APPB2C.common.startPage.upcoming.pb": APPB2C_common_startPage_upcoming_pb,
  "APPB2C.common.startPage.upcoming.live": APPB2C_common_startPage_upcoming_live,
  "APPB2C.common.startPage.upcoming.lifeSkills": APPB2C_common_startPage_upcoming_lifeSkills,
  "APPB2C.common.startPage.upcoming.remote": APPB2C_common_startPage_upcoming_remote,
  "APPB2C.common.startPage.upcoming.empty": APPB2C_common_startPage_upcoming_empty,
  "APPB2C.common.startPage.upcoming.noSpeaking": APPB2C_common_startPage_upcoming_noSpeaking,
  "APPB2C.common.startPage.upcoming.noTime": APPB2C_common_startPage_upcoming_noTime,
  "APPB2C.common.startPage.upcoming.pay": APPB2C_common_startPage_upcoming_pay,
  "APPB2C.common.startPage.upcoming.pastPaymentDeadline": APPB2C_common_startPage_upcoming_pastPaymentDeadline,
  "APPB2C.common.startPage.upcoming.missing.id": APPB2C_common_startPage_upcoming_missing_id,
  "APPB2C.common.startPage.upcoming.missing.medical": APPB2C_common_startPage_upcoming_missing_medical,
  "APPB2C.common.startPage.upcoming.missing.payment": APPB2C_common_startPage_upcoming_missing_payment,
  "APPB2C.common.startPage.upcoming.missing.childConsent": APPB2C_common_startPage_upcoming_missing_childConsent,
  "APPB2C.common.startPage.upcoming.osr.note": APPB2C_common_startPage_upcoming_osr_note,
  "APPB2C.common.startPage.past.title": APPB2C_common_startPage_past_title,
  "APPB2C.common.startPage.past.cd": APPB2C_common_startPage_past_cd,
  "APPB2C.common.startPage.past.pb": APPB2C_common_startPage_past_pb,
  "APPB2C.common.startPage.past.score": APPB2C_common_startPage_past_score,
  "APPB2C.common.startPage.past.osr.newScore": APPB2C_common_startPage_past_osr_newScore,
  "APPB2C.common.startPage.past.osr.originalScore": APPB2C_common_startPage_past_osr_originalScore,
  "APPB2C.common.startPage.past.osr.viewRetakeLink": APPB2C_common_startPage_past_osr_viewRetakeLink,
  "APPB2C.common.startPage.past.osr.note.confirmed": APPB2C_common_startPage_past_osr_note_confirmed,
  "APPB2C.common.startPage.past.osr.note.awaiting": APPB2C_common_startPage_past_osr_note_awaiting,
  "APPB2C.common.startPage.past.osr.note.hasResults": APPB2C_common_startPage_past_osr_note_hasResults,
  "APPB2C.common.startPage.past.scoreLifeSkills": APPB2C_common_startPage_past_scoreLifeSkills,
  "APPB2C.common.startPage.past.rebook": APPB2C_common_startPage_past_rebook,
  "APPB2C.common.startPage.past.empty": APPB2C_common_startPage_past_empty,
  "APPB2C.common.startPage.past.upcomingResults": APPB2C_common_startPage_past_upcomingResults,
  "APPB2C.common.startPage.recent.title": APPB2C_common_startPage_recent_title,
  "APPB2C.common.startPage.recent.cta": APPB2C_common_startPage_recent_cta,
  "APPB2C.common.startPage.candidates.showingFor": APPB2C_common_startPage_candidates_showingFor,
  "APPB2C.common.startPage.candidates.nowAdult": APPB2C_common_startPage_candidates_nowAdult,
  "APPB2C.common.startPage.common.cancelled": APPB2C_common_startPage_common_cancelled,
  "APPB2C.common.startPage.missing.cancel": APPB2C_common_startPage_missing_cancel,
  "APPB2C.common.startPage.missing.ttData.title": APPB2C_common_startPage_missing_ttData_title,
  "APPB2C.common.startPage.missing.ttData.line1": APPB2C_common_startPage_missing_ttData_line1,
  "APPB2C.common.startPage.missing.ttData.line2": APPB2C_common_startPage_missing_ttData_line2,
  "APPB2C.common.startPage.missing.ttData.line3": APPB2C_common_startPage_missing_ttData_line3,
  "APPB2C.common.startPage.missing.ttData.cta": APPB2C_common_startPage_missing_ttData_cta,
  "APPB2C.common.startPage.missing.ttData.firstName": APPB2C_common_startPage_missing_ttData_firstName,
  "APPB2C.common.startPage.missing.ttData.lastName": APPB2C_common_startPage_missing_ttData_lastName,
  "APPB2C.common.startPage.missing.ttData.noLastName": APPB2C_common_startPage_missing_ttData_noLastName,
  "APPB2C.common.startPage.missing.ttData.dob": APPB2C_common_startPage_missing_ttData_dob,
  "APPB2C.common.startPage.missing.marketing.title": APPB2C_common_startPage_missing_marketing_title,
  "APPB2C.common.startPage.missing.marketing.opts.BcOnly": APPB2C_common_startPage_missing_marketing_opts_BcOnly,
  "APPB2C.common.startPage.missing.marketing.opts.BcAndThirdParties": APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties,
  "APPB2C.common.startPage.missing.marketing.opts.DoNotSend": APPB2C_common_startPage_missing_marketing_opts_DoNotSend,
  "APPB2C.common.startPage.missing.marketing.notes.main": APPB2C_common_startPage_missing_marketing_notes_main,
  "APPB2C.common.startPage.missing.marketing.notes.full1": APPB2C_common_startPage_missing_marketing_notes_full1,
  "APPB2C.common.startPage.missing.marketing.notes.full2": APPB2C_common_startPage_missing_marketing_notes_full2,
  "APPB2C.common.startPage.missing.marketing.notes.link": APPB2C_common_startPage_missing_marketing_notes_link,
  "APPB2C.common.startPage.missing.marketing.notes.full3": APPB2C_common_startPage_missing_marketing_notes_full3,
  "APPB2C.common.startPage.missing.marketing.cta": APPB2C_common_startPage_missing_marketing_cta,
  "APPB2C.common.startPage.error.title": APPB2C_common_startPage_error_title,
  "APPB2C.common.startPage.error.body": APPB2C_common_startPage_error_body,
  "APPB2C.common.startPage.error.cta": APPB2C_common_startPage_error_cta,
  "APPB2C.common.account.title": APPB2C_common_account_title,
  "APPB2C.common.account.error": APPB2C_common_account_error,
  "APPB2C.common.account.details.name": APPB2C_common_account_details_name,
  "APPB2C.common.account.details.email.note": APPB2C_common_account_details_email_note,
  "APPB2C.common.account.details.email.username": APPB2C_common_account_details_email_username,
  "APPB2C.common.account.details.email.label": APPB2C_common_account_details_email_label,
  "APPB2C.common.account.details.email.set": APPB2C_common_account_details_email_set,
  "APPB2C.common.account.details.email.confirm": APPB2C_common_account_details_email_confirm,
  "APPB2C.common.account.details.email.save": APPB2C_common_account_details_email_save,
  "APPB2C.common.account.details.email.success": APPB2C_common_account_details_email_success,
  "APPB2C.common.account.details.email.maxLength": APPB2C_common_account_details_email_maxLength,
  "APPB2C.common.account.details.email.mismatch": APPB2C_common_account_details_email_mismatch,
  "APPB2C.common.account.details.email.validEmail": APPB2C_common_account_details_email_validEmail,
  "APPB2C.common.account.details.psw.label": APPB2C_common_account_details_psw_label,
  "APPB2C.common.account.details.psw.change": APPB2C_common_account_details_psw_change,
  "APPB2C.common.account.details.psw.show": APPB2C_common_account_details_psw_show,
  "APPB2C.common.account.details.psw.old": APPB2C_common_account_details_psw_old,
  "APPB2C.common.account.details.psw.new": APPB2C_common_account_details_psw_new,
  "APPB2C.common.account.details.psw.success": APPB2C_common_account_details_psw_success,
  "APPB2C.common.account.details.psw.requirements.levels.0": APPB2C_common_account_details_psw_requirements_levels_0,
  "APPB2C.common.account.details.psw.requirements.levels.1": APPB2C_common_account_details_psw_requirements_levels_1,
  "APPB2C.common.account.details.psw.requirements.levels.2": APPB2C_common_account_details_psw_requirements_levels_2,
  "APPB2C.common.account.details.psw.requirements.levels.3": APPB2C_common_account_details_psw_requirements_levels_3,
  "APPB2C.common.account.details.psw.requirements.levels.4": APPB2C_common_account_details_psw_requirements_levels_4,
  "APPB2C.common.account.details.psw.requirements.ok": APPB2C_common_account_details_psw_requirements_ok,
  "APPB2C.common.account.details.psw.requirements.mustHave": APPB2C_common_account_details_psw_requirements_mustHave,
  "APPB2C.common.account.details.psw.requirements.shouldHave": APPB2C_common_account_details_psw_requirements_shouldHave,
  "APPB2C.common.account.details.psw.requirements.strength": APPB2C_common_account_details_psw_requirements_strength,
  "APPB2C.common.account.details.psw.requirements.reqs.length": APPB2C_common_account_details_psw_requirements_reqs_length,
  "APPB2C.common.account.details.psw.requirements.reqs.lower": APPB2C_common_account_details_psw_requirements_reqs_lower,
  "APPB2C.common.account.details.psw.requirements.reqs.upper": APPB2C_common_account_details_psw_requirements_reqs_upper,
  "APPB2C.common.account.details.psw.requirements.reqs.number": APPB2C_common_account_details_psw_requirements_reqs_number,
  "APPB2C.common.account.details.psw.requirements.reqs.special": APPB2C_common_account_details_psw_requirements_reqs_special,
  "APPB2C.common.account.details.psw.requirements.maxLength": APPB2C_common_account_details_psw_requirements_maxLength,
  "APPB2C.common.account.details.psw.requirements.tooWeak": APPB2C_common_account_details_psw_requirements_tooWeak,
  "APPB2C.common.account.details.subnote.address": APPB2C_common_account_details_subnote_address,
  "APPB2C.common.account.details.subnote.city": APPB2C_common_account_details_subnote_city,
  "APPB2C.common.account.marketing.title": APPB2C_common_account_marketing_title,
  "APPB2C.common.account.marketing.success": APPB2C_common_account_marketing_success,
  "APPB2C.common.account.smsNotificationSettings.title": APPB2C_common_account_smsNotificationSettings_title,
  "APPB2C.common.account.smsNotificationSettings.mobileLabel": APPB2C_common_account_smsNotificationSettings_mobileLabel,
  "APPB2C.common.account.smsNotificationSettings.success": APPB2C_common_account_smsNotificationSettings_success,
  "APPB2C.common.account.terms.title": APPB2C_common_account_terms_title,
  "APPB2C.common.account.terms.disclaimer": APPB2C_common_account_terms_disclaimer,
  "APPB2C.common.account.terms.extra.title": APPB2C_common_account_terms_extra_title,
  "APPB2C.common.account.terms.extra.line1": APPB2C_common_account_terms_extra_line1,
  "APPB2C.common.account.terms.extra.line2": APPB2C_common_account_terms_extra_line2,
  "APPB2C.common.account.terms.extra.line3.1": APPB2C_common_account_terms_extra_line3_1,
  "APPB2C.common.account.terms.extra.line3.2": APPB2C_common_account_terms_extra_line3_2,
  "APPB2C.common.account.terms.download": APPB2C_common_account_terms_download,
  "APPB2C.common.account.terms.previous": APPB2C_common_account_terms_previous,
  "APPB2C.common.account.terms.cta": APPB2C_common_account_terms_cta,
  "APPB2C.common.helpAndContact.title": APPB2C_common_helpAndContact_title,
  "APPB2C.common.helpAndContact.selectExamLabel": APPB2C_common_helpAndContact_selectExamLabel,
  "APPB2C.common.helpAndContact.ieltsReference": APPB2C_common_helpAndContact_ieltsReference,
  "APPB2C.common.helpAndContact.noTests.header": APPB2C_common_helpAndContact_noTests_header,
  "APPB2C.common.helpAndContact.noTests.bookNewBtn": APPB2C_common_helpAndContact_noTests_bookNewBtn,
  "APPB2C.common.helpAndContact.noTests.contactLink": APPB2C_common_helpAndContact_noTests_contactLink,
  "APPB2C.common.helpAndContact.icContent.header": APPB2C_common_helpAndContact_icContent_header,
  "APPB2C.common.helpAndContact.icContent.emailLabel": APPB2C_common_helpAndContact_icContent_emailLabel,
  "APPB2C.common.helpAndContact.icContent.phoneLabel": APPB2C_common_helpAndContact_icContent_phoneLabel,
  "APPB2C.common.helpAndContact.icContent.referenceReminder": APPB2C_common_helpAndContact_icContent_referenceReminder,
  "APPB2C.common.helpAndContact.icContent.commonQuestionsHeader": APPB2C_common_helpAndContact_icContent_commonQuestionsHeader,
  "APPB2C.common.helpAndContact.actionSection.icHeader": APPB2C_common_helpAndContact_actionSection_icHeader,
  "APPB2C.common.helpAndContact.actionSection.iolHeader": APPB2C_common_helpAndContact_actionSection_iolHeader,
  "APPB2C.common.helpAndContact.actionSection.faqLabel": APPB2C_common_helpAndContact_actionSection_faqLabel,
  "APPB2C.common.helpAndContact.actionSection.faqLink": APPB2C_common_helpAndContact_actionSection_faqLink,
  "APPB2C.common.helpAndContact.actionSection.launchChatLabel": APPB2C_common_helpAndContact_actionSection_launchChatLabel,
  "APPB2C.common.helpAndContact.actionSection.launchChatBtn": APPB2C_common_helpAndContact_actionSection_launchChatBtn,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabel": APPB2C_common_helpAndContact_actionSection_contactFormLabel,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabelInCentre": APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre,
  "APPB2C.common.helpAndContact.actionSection.contactFormLink": APPB2C_common_helpAndContact_actionSection_contactFormLink,
  "APPB2C.common.helpAndContact.form.back": APPB2C_common_helpAndContact_form_back,
  "APPB2C.common.helpAndContact.form.title": APPB2C_common_helpAndContact_form_title,
  "APPB2C.common.helpAndContact.form.label": APPB2C_common_helpAndContact_form_label,
  "APPB2C.common.helpAndContact.form.complaint.title": APPB2C_common_helpAndContact_form_complaint_title,
  "APPB2C.common.helpAndContact.form.complaint.radio.other": APPB2C_common_helpAndContact_form_complaint_radio_other,
  "APPB2C.common.helpAndContact.form.complaint.radio.complaint": APPB2C_common_helpAndContact_form_complaint_radio_complaint,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLinkTitle": APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLink": APPB2C_common_helpAndContact_form_complaint_moreInfoLink,
  "APPB2C.common.helpAndContact.form.validation": APPB2C_common_helpAndContact_form_validation,
  "APPB2C.common.helpAndContact.form.response": APPB2C_common_helpAndContact_form_response,
  "APPB2C.common.helpAndContact.form.legal": APPB2C_common_helpAndContact_form_legal,
  "APPB2C.common.helpAndContact.form.submit": APPB2C_common_helpAndContact_form_submit,
  "APPB2C.common.helpAndContact.form.thanks.title": APPB2C_common_helpAndContact_form_thanks_title,
  "APPB2C.common.helpAndContact.form.thanks.banner": APPB2C_common_helpAndContact_form_thanks_banner,
  "APPB2C.common.helpAndContact.form.thanks.info": APPB2C_common_helpAndContact_form_thanks_info,
  "APPB2C.common.helpAndContact.form.thanks.back": APPB2C_common_helpAndContact_form_thanks_back,
  "APPB2C.common.preparation.title": APPB2C_common_preparation_title,
  "APPB2C.common.preparation.selectExamLabel": APPB2C_common_preparation_selectExamLabel,
  "APPB2C.common.preparation.viewTestBtn": APPB2C_common_preparation_viewTestBtn,
  "APPB2C.common.preparation.noTests.header": APPB2C_common_preparation_noTests_header,
  "APPB2C.common.preparation.noTests.contactLink": APPB2C_common_preparation_noTests_contactLink,
  "APPB2C.common.draft.title": APPB2C_common_draft_title,
  "APPB2C.common.draft.note.line1": APPB2C_common_draft_note_line1,
  "APPB2C.common.draft.note.line2": APPB2C_common_draft_note_line2,
  "APPB2C.common.draft.note.line3": APPB2C_common_draft_note_line3,
  "APPB2C.common.draft.difference": APPB2C_common_draft_difference,
  "APPB2C.common.draft.edit": APPB2C_common_draft_edit,
  "APPB2C.common.draft.personal.header": APPB2C_common_draft_personal_header,
  "APPB2C.common.draft.personal.title": APPB2C_common_draft_personal_title,
  "APPB2C.common.draft.personal.note": APPB2C_common_draft_personal_note,
  "APPB2C.common.draft.personal.cta": APPB2C_common_draft_personal_cta,
  "APPB2C.common.draft.id.header": APPB2C_common_draft_id_header,
  "APPB2C.common.draft.id.title": APPB2C_common_draft_id_title,
  "APPB2C.common.draft.id.note": APPB2C_common_draft_id_note,
  "APPB2C.common.draft.id.documentWithNoExpiryDate": APPB2C_common_draft_id_documentWithNoExpiryDate,
  "APPB2C.common.draft.id.issuingAuthority": APPB2C_common_draft_id_issuingAuthority,
  "APPB2C.common.draft.id.cta": APPB2C_common_draft_id_cta,
  "APPB2C.common.draft.cta.confirm": APPB2C_common_draft_cta_confirm,
  "APPB2C.common.draft.cta.note": APPB2C_common_draft_cta_note,
  "APPB2C.common.draft.cta.decline": APPB2C_common_draft_cta_decline,
  "APPB2C.common.draft.error.pastDeadline": APPB2C_common_draft_error_pastDeadline,
  "APPB2C.common.regHeader.reference": APPB2C_common_regHeader_reference,
  "APPB2C.common.regHeader.trfLoading": APPB2C_common_regHeader_trfLoading,
  "APPB2C.common.regHeader.trfNumber": APPB2C_common_regHeader_trfNumber,
  "APPB2C.common.changeBooking.title": APPB2C_common_changeBooking_title,
  "APPB2C.common.changeBooking.subtitle": APPB2C_common_changeBooking_subtitle,
  "APPB2C.common.changeBooking.goBack": APPB2C_common_changeBooking_goBack,
  "APPB2C.common.changeBooking.transferNotes.1": APPB2C_common_changeBooking_transferNotes_1,
  "APPB2C.common.changeBooking.transferNotes.2": APPB2C_common_changeBooking_transferNotes_2,
  "APPB2C.common.changeBooking.transferNotesIol.1": APPB2C_common_changeBooking_transferNotesIol_1,
  "APPB2C.common.changeBooking.transferNotesIol.2": APPB2C_common_changeBooking_transferNotesIol_2,
  "APPB2C.common.changeBooking.transfer.text": APPB2C_common_changeBooking_transfer_text,
  "APPB2C.common.changeBooking.transfer.textIol": APPB2C_common_changeBooking_transfer_textIol,
  "APPB2C.common.changeBooking.transfer.cta": APPB2C_common_changeBooking_transfer_cta,
  "APPB2C.common.changeBooking.cancel.text": APPB2C_common_changeBooking_cancel_text,
  "APPB2C.common.changeBooking.cancel.cta": APPB2C_common_changeBooking_cancel_cta,
  "APPB2C.common.transfer.title": APPB2C_common_transfer_title,
  "APPB2C.common.transfer.selfService.chooseDates.title": APPB2C_common_transfer_selfService_chooseDates_title,
  "APPB2C.common.transfer.selfService.availableDates.title": APPB2C_common_transfer_selfService_availableDates_title,
  "APPB2C.common.transfer.selfService.review.test.changeLrw": APPB2C_common_transfer_selfService_review_test_changeLrw,
  "APPB2C.common.transfer.selfService.review.tncs.agreement": APPB2C_common_transfer_selfService_review_tncs_agreement,
  "APPB2C.common.transfer.selfService.review.confirm.title": APPB2C_common_transfer_selfService_review_confirm_title,
  "APPB2C.common.transfer.selfService.review.confirm.proceedBtn": APPB2C_common_transfer_selfService_review_confirm_proceedBtn,
  "APPB2C.common.transfer.selfService.review.errors.transferDisabled": APPB2C_common_transfer_selfService_review_errors_transferDisabled,
  "APPB2C.common.transfer.selfService.review.errors.noLrwSlots": APPB2C_common_transfer_selfService_review_errors_noLrwSlots,
  "APPB2C.common.transfer.selfService.review.errors.noDistricts": APPB2C_common_transfer_selfService_review_errors_noDistricts,
  "APPB2C.common.transfer.goBack": APPB2C_common_transfer_goBack,
  "APPB2C.common.transfer.notes.1": APPB2C_common_transfer_notes_1,
  "APPB2C.common.transfer.notes.2": APPB2C_common_transfer_notes_2,
  "APPB2C.common.transfer.notes.3": APPB2C_common_transfer_notes_3,
  "APPB2C.common.transfer.notes.4": APPB2C_common_transfer_notes_4,
  "APPB2C.common.transfer.notesIol.1": APPB2C_common_transfer_notesIol_1,
  "APPB2C.common.transfer.notesIol.2": APPB2C_common_transfer_notesIol_2,
  "APPB2C.common.transfer.form.freeform": APPB2C_common_transfer_form_freeform,
  "APPB2C.common.transfer.form.reasons.1": APPB2C_common_transfer_form_reasons_1,
  "APPB2C.common.transfer.form.reasons.2": APPB2C_common_transfer_form_reasons_2,
  "APPB2C.common.transfer.form.reasons.3": APPB2C_common_transfer_form_reasons_3,
  "APPB2C.common.transfer.form.reasons.4": APPB2C_common_transfer_form_reasons_4,
  "APPB2C.common.transfer.form.reasons.title": APPB2C_common_transfer_form_reasons_title,
  "APPB2C.common.transfer.form.reasons.choose": APPB2C_common_transfer_form_reasons_choose,
  "APPB2C.common.transfer.form.reasons.other": APPB2C_common_transfer_form_reasons_other,
  "APPB2C.common.transfer.contactNote": APPB2C_common_transfer_contactNote,
  "APPB2C.common.transfer.contactNoteIol": APPB2C_common_transfer_contactNoteIol,
  "APPB2C.common.transfer.requestValidation": APPB2C_common_transfer_requestValidation,
  "APPB2C.common.transfer.agreement": APPB2C_common_transfer_agreement,
  "APPB2C.common.transfer.cta": APPB2C_common_transfer_cta,
  "APPB2C.common.cancelBooking.title": APPB2C_common_cancelBooking_title,
  "APPB2C.common.cancelBooking.subtitle": APPB2C_common_cancelBooking_subtitle,
  "APPB2C.common.cancelBooking.transferNote.title": APPB2C_common_cancelBooking_transferNote_title,
  "APPB2C.common.cancelBooking.transferNote.titleIol": APPB2C_common_cancelBooking_transferNote_titleIol,
  "APPB2C.common.cancelBooking.transferNote.cta": APPB2C_common_cancelBooking_transferNote_cta,
  "APPB2C.common.cancelBooking.cancelNotes.1": APPB2C_common_cancelBooking_cancelNotes_1,
  "APPB2C.common.cancelBooking.cancelNotes.2": APPB2C_common_cancelBooking_cancelNotes_2,
  "APPB2C.common.cancelBooking.cancelNotes.3": APPB2C_common_cancelBooking_cancelNotes_3,
  "APPB2C.common.cancelBooking.back": APPB2C_common_cancelBooking_back,
  "APPB2C.common.cancelBooking.alreadyRequested.title": APPB2C_common_cancelBooking_alreadyRequested_title,
  "APPB2C.common.cancelBooking.alreadyRequested.cta": APPB2C_common_cancelBooking_alreadyRequested_cta,
  "APPB2C.common.cancelBooking.checkTest": APPB2C_common_cancelBooking_checkTest,
  "APPB2C.common.cancelBooking.reason.note.title": APPB2C_common_cancelBooking_reason_note_title,
  "APPB2C.common.cancelBooking.reason.note.content": APPB2C_common_cancelBooking_reason_note_content,
  "APPB2C.common.cancelBooking.reason.form.title": APPB2C_common_cancelBooking_reason_form_title,
  "APPB2C.common.cancelBooking.reason.form.choose": APPB2C_common_cancelBooking_reason_form_choose,
  "APPB2C.common.cancelBooking.reason.form.requiredReason": APPB2C_common_cancelBooking_reason_form_requiredReason,
  "APPB2C.common.cancelBooking.reason.form.freeform": APPB2C_common_cancelBooking_reason_form_freeform,
  "APPB2C.common.cancelBooking.reason.form.optional": APPB2C_common_cancelBooking_reason_form_optional,
  "APPB2C.common.cancelBooking.reason.form.requiredEvidence": APPB2C_common_cancelBooking_reason_form_requiredEvidence,
  "APPB2C.common.cancelBooking.upload.title": APPB2C_common_cancelBooking_upload_title,
  "APPB2C.common.cancelBooking.upload.notes.1": APPB2C_common_cancelBooking_upload_notes_1,
  "APPB2C.common.cancelBooking.upload.notes.2": APPB2C_common_cancelBooking_upload_notes_2,
  "APPB2C.common.cancelBooking.upload.notes.3": APPB2C_common_cancelBooking_upload_notes_3,
  "APPB2C.common.cancelBooking.upload.notes.4": APPB2C_common_cancelBooking_upload_notes_4,
  "APPB2C.common.cancelBooking.upload.notes.5": APPB2C_common_cancelBooking_upload_notes_5,
  "APPB2C.common.cancelBooking.upload.notes.6": APPB2C_common_cancelBooking_upload_notes_6,
  "APPB2C.common.cancelBooking.upload.notes.7": APPB2C_common_cancelBooking_upload_notes_7,
  "APPB2C.common.cancelBooking.upload.notes.8": APPB2C_common_cancelBooking_upload_notes_8,
  "APPB2C.common.cancelBooking.upload.notes.10": APPB2C_common_cancelBooking_upload_notes_10,
  "APPB2C.common.cancelBooking.upload.mobile": APPB2C_common_cancelBooking_upload_mobile,
  "APPB2C.common.cancelBooking.upload.requirements.title": APPB2C_common_cancelBooking_upload_requirements_title,
  "APPB2C.common.cancelBooking.upload.requirements.note": APPB2C_common_cancelBooking_upload_requirements_note,
  "APPB2C.common.cancelBooking.bottomNotes.note1": APPB2C_common_cancelBooking_bottomNotes_note1,
  "APPB2C.common.cancelBooking.bottomNotes.note2": APPB2C_common_cancelBooking_bottomNotes_note2,
  "APPB2C.common.cancelBooking.bottomNotes.note3": APPB2C_common_cancelBooking_bottomNotes_note3,
  "APPB2C.common.cancelBooking.bottomNotes.terms": APPB2C_common_cancelBooking_bottomNotes_terms,
  "APPB2C.common.cancelBooking.bankDetails.title": APPB2C_common_cancelBooking_bankDetails_title,
  "APPB2C.common.cancelBooking.bankDetails.note": APPB2C_common_cancelBooking_bankDetails_note,
  "APPB2C.common.cancelBooking.bankDetails.form.holderName": APPB2C_common_cancelBooking_bankDetails_form_holderName,
  "APPB2C.common.cancelBooking.bankDetails.form.holderEmail": APPB2C_common_cancelBooking_bankDetails_form_holderEmail,
  "APPB2C.common.cancelBooking.bankDetails.form.bankName": APPB2C_common_cancelBooking_bankDetails_form_bankName,
  "APPB2C.common.cancelBooking.bankDetails.form.bankNumber": APPB2C_common_cancelBooking_bankDetails_form_bankNumber,
  "APPB2C.common.cancelBooking.bankDetails.form.sortCode": APPB2C_common_cancelBooking_bankDetails_form_sortCode,
  "APPB2C.common.cancelBooking.bankDetails.form.bankAddress": APPB2C_common_cancelBooking_bankDetails_form_bankAddress,
  "APPB2C.common.cancelBooking.bankDetails.form.accountHolder": APPB2C_common_cancelBooking_bankDetails_form_accountHolder,
  "APPB2C.common.cancelBooking.bankDetails.form.city": APPB2C_common_cancelBooking_bankDetails_form_city,
  "APPB2C.common.cancelBooking.bankDetails.form.postcode": APPB2C_common_cancelBooking_bankDetails_form_postcode,
  "APPB2C.common.cancelBooking.bankDetails.form.country": APPB2C_common_cancelBooking_bankDetails_form_country,
  "APPB2C.common.cancelBooking.bankDetails.form.changeCountry": APPB2C_common_cancelBooking_bankDetails_form_changeCountry,
  "APPB2C.common.cancelBooking.bankDetails.form.swift": APPB2C_common_cancelBooking_bankDetails_form_swift,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidSwift": APPB2C_common_cancelBooking_bankDetails_form_invalidSwift,
  "APPB2C.common.cancelBooking.bankDetails.form.iban": APPB2C_common_cancelBooking_bankDetails_form_iban,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidIban": APPB2C_common_cancelBooking_bankDetails_form_invalidIban,
  "APPB2C.common.cancelBooking.bankDetails.terms.title": APPB2C_common_cancelBooking_bankDetails_terms_title,
  "APPB2C.common.cancelBooking.bankDetails.terms.consent": APPB2C_common_cancelBooking_bankDetails_terms_consent,
  "APPB2C.common.cancelBooking.bankDetails.privacy": APPB2C_common_cancelBooking_bankDetails_privacy,
  "APPB2C.common.cancelBooking.cta.submit": APPB2C_common_cancelBooking_cta_submit,
  "APPB2C.common.cancelBooking.cta.addBankDetails": APPB2C_common_cancelBooking_cta_addBankDetails,
  "APPB2C.common.cancelBooking.cta.goBack": APPB2C_common_cancelBooking_cta_goBack,
  "APPB2C.common.cancelBooking.error.missingFile": APPB2C_common_cancelBooking_error_missingFile,
  "APPB2C.common.cancelBooking.error.requestedAlready": APPB2C_common_cancelBooking_error_requestedAlready,
  "APPB2C.common.cancelBooking.error.cancelledAlready": APPB2C_common_cancelBooking_error_cancelledAlready,
  "APPB2C.common.changeBookingAck.cancel.title": APPB2C_common_changeBookingAck_cancel_title,
  "APPB2C.common.changeBookingAck.cancel.respondTime": APPB2C_common_changeBookingAck_cancel_respondTime,
  "APPB2C.common.changeBookingAck.cancel.banner": APPB2C_common_changeBookingAck_cancel_banner,
  "APPB2C.common.changeBookingAck.cancel.refunds": APPB2C_common_changeBookingAck_cancel_refunds,
  "APPB2C.common.changeBookingAck.cancel.contact.title": APPB2C_common_changeBookingAck_cancel_contact_title,
  "APPB2C.common.changeBookingAck.cancel.contact.titleIol": APPB2C_common_changeBookingAck_cancel_contact_titleIol,
  "APPB2C.common.changeBookingAck.cancel.contact.phone": APPB2C_common_changeBookingAck_cancel_contact_phone,
  "APPB2C.common.changeBookingAck.cancel.contact.email": APPB2C_common_changeBookingAck_cancel_contact_email,
  "APPB2C.common.changeBookingAck.cancel.refNr": APPB2C_common_changeBookingAck_cancel_refNr,
  "APPB2C.common.changeBookingAck.cancel.terms": APPB2C_common_changeBookingAck_cancel_terms,
  "APPB2C.common.changeBookingAck.transfer.title": APPB2C_common_changeBookingAck_transfer_title,
  "APPB2C.common.changeBookingAck.transfer.banner": APPB2C_common_changeBookingAck_transfer_banner,
  "APPB2C.common.changeBookingAck.transfer.respondTime": APPB2C_common_changeBookingAck_transfer_respondTime,
  "APPB2C.common.changeBookingAck.transfer.note": APPB2C_common_changeBookingAck_transfer_note,
  "APPB2C.common.changeBookingAck.transferIol.banner": APPB2C_common_changeBookingAck_transferIol_banner,
  "APPB2C.common.changeBookingAck.transferIol.note": APPB2C_common_changeBookingAck_transferIol_note,
  "APPB2C.common.changeBookingAck.transferIol.contact": APPB2C_common_changeBookingAck_transferIol_contact,
  "APPB2C.common.changeBookingAck.transferIol.refNr": APPB2C_common_changeBookingAck_transferIol_refNr,
  "APPB2C.common.changeBookingAck.transferIol.terms": APPB2C_common_changeBookingAck_transferIol_terms,
  "APPB2C.common.changeBookingAck.speaking.title": APPB2C_common_changeBookingAck_speaking_title,
  "APPB2C.common.changeBookingAck.speaking.banner": APPB2C_common_changeBookingAck_speaking_banner,
  "APPB2C.common.changeBookingAck.speaking.note.title": APPB2C_common_changeBookingAck_speaking_note_title,
  "APPB2C.common.changeBookingAck.speaking.note.line1": APPB2C_common_changeBookingAck_speaking_note_line1,
  "APPB2C.common.changeBookingAck.speaking.note.line2": APPB2C_common_changeBookingAck_speaking_note_line2,
  "APPB2C.common.changeBookingAck.fees": APPB2C_common_changeBookingAck_fees,
  "APPB2C.common.changeBookingAck.feesIol": APPB2C_common_changeBookingAck_feesIol,
  "APPB2C.common.changeBookingAck.terms": APPB2C_common_changeBookingAck_terms,
  "APPB2C.common.changeBookingAck.back": APPB2C_common_changeBookingAck_back,
  "APPB2C.common.changeSpeaking.title": APPB2C_common_changeSpeaking_title,
  "APPB2C.common.changeSpeaking.note.title": APPB2C_common_changeSpeaking_note_title,
  "APPB2C.common.changeSpeaking.note.line1": APPB2C_common_changeSpeaking_note_line1,
  "APPB2C.common.changeSpeaking.note.line2": APPB2C_common_changeSpeaking_note_line2,
  "APPB2C.common.changeSpeaking.note.back": APPB2C_common_changeSpeaking_note_back,
  "APPB2C.common.changeSpeaking.cards.title": APPB2C_common_changeSpeaking_cards_title,
  "APPB2C.common.changeSpeaking.cards.change": APPB2C_common_changeSpeaking_cards_change,
  "APPB2C.common.changeSpeaking.cards.noSlots": APPB2C_common_changeSpeaking_cards_noSlots,
  "APPB2C.common.changeSpeaking.cards.noSlots.error": APPB2C_common_changeSpeaking_cards_noSlots_error,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.title": APPB2C_common_changeSpeaking_modals_testNotAvailable_title,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line1": APPB2C_common_changeSpeaking_modals_testNotAvailable_line1,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line2": APPB2C_common_changeSpeaking_modals_testNotAvailable_line2,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.cta": APPB2C_common_changeSpeaking_modals_testNotAvailable_cta,
  "APPB2C.common.changeSpeaking.modals.noSlots.title": APPB2C_common_changeSpeaking_modals_noSlots_title,
  "APPB2C.common.changeSpeaking.modals.noSlots.line1": APPB2C_common_changeSpeaking_modals_noSlots_line1,
  "APPB2C.common.changeSpeaking.modals.noSlots.line2": APPB2C_common_changeSpeaking_modals_noSlots_line2,
  "APPB2C.common.changeSpeaking.modals.noSlots.keepTest": APPB2C_common_changeSpeaking_modals_noSlots_keepTest,
  "APPB2C.common.changeSpeaking.modals.noSlots.transferTest": APPB2C_common_changeSpeaking_modals_noSlots_transferTest,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.title": APPB2C_common_changeSpeaking_modals_transferNotAvailable_title,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.line1": APPB2C_common_changeSpeaking_modals_transferNotAvailable_line1,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.cta": APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta,
  "APPB2C.common.changeSpeaking.cta": APPB2C_common_changeSpeaking_cta,
  "APPB2C.common.footer.terms.termsOfUse": APPB2C_common_footer_terms_termsOfUse,
  "APPB2C.common.footer.terms.accessibility": APPB2C_common_footer_terms_accessibility,
  "APPB2C.common.footer.terms.privacyAndCookies": APPB2C_common_footer_terms_privacyAndCookies,
  "APPB2C.common.footer.terms.dataProtection": APPB2C_common_footer_terms_dataProtection,
  "APPB2C.common.footer.terms.copyrightLine1": APPB2C_common_footer_terms_copyrightLine1,
  "APPB2C.common.footer.terms.copyrightLine2": APPB2C_common_footer_terms_copyrightLine2,
  "APPB2C.common.recreateReg.progress": APPB2C_common_recreateReg_progress,
  "APPB2C.common.recreateReg.errTitle": APPB2C_common_recreateReg_errTitle,
  "APPB2C.common.recreateReg.errMsg": APPB2C_common_recreateReg_errMsg,
  "APPB2C.common.chooseTest.title": APPB2C_common_chooseTest_title,
  "APPB2C.common.chooseTest.difference": APPB2C_common_chooseTest_difference,
  "APPB2C.common.chooseTest.iol.title": APPB2C_common_chooseTest_iol_title,
  "APPB2C.common.chooseTest.iol.desc": APPB2C_common_chooseTest_iol_desc,
  "APPB2C.common.chooseTest.iol.consent": APPB2C_common_chooseTest_iol_consent,
  "APPB2C.common.chooseTest.iol.link": APPB2C_common_chooseTest_iol_link,
  "APPB2C.common.chooseTest.iol.cta": APPB2C_common_chooseTest_iol_cta,
  "APPB2C.common.chooseTest.iol.note.title": APPB2C_common_chooseTest_iol_note_title,
  "APPB2C.common.chooseTest.iol.note.content": APPB2C_common_chooseTest_iol_note_content,
  "APPB2C.common.chooseTest.ukvi.difference": APPB2C_common_chooseTest_ukvi_difference,
  "APPB2C.common.chooseTest.ukvi.online.title": APPB2C_common_chooseTest_ukvi_online_title,
  "APPB2C.common.chooseTest.ukvi.online.cta": APPB2C_common_chooseTest_ukvi_online_cta,
  "APPB2C.common.chooseTest.ukvi.online.link": APPB2C_common_chooseTest_ukvi_online_link,
  "APPB2C.common.chooseTest.ukvi.online.consent": APPB2C_common_chooseTest_ukvi_online_consent,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.title": APPB2C_common_chooseTest_ukvi_online_consentBiometric_title,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.symbol": APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol,
  "APPB2C.common.chooseTest.ukvi.online.note.title": APPB2C_common_chooseTest_ukvi_online_note_title,
  "APPB2C.common.chooseTest.ukvi.online.note.content": APPB2C_common_chooseTest_ukvi_online_note_content,
  "APPB2C.common.chooseTest.ukvi.inCentre.title": APPB2C_common_chooseTest_ukvi_inCentre_title,
  "APPB2C.common.chooseTest.ukvi.inCentre.descAc": APPB2C_common_chooseTest_ukvi_inCentre_descAc,
  "APPB2C.common.chooseTest.ukvi.inCentre.descGt": APPB2C_common_chooseTest_ukvi_inCentre_descGt,
  "APPB2C.common.chooseTest.ukvi.inCentre.cta": APPB2C_common_chooseTest_ukvi_inCentre_cta,
  "APPB2C.common.chooseTest.ors.title": APPB2C_common_chooseTest_ors_title,
  "APPB2C.common.chooseTest.ors.descAc": APPB2C_common_chooseTest_ors_descAc,
  "APPB2C.common.chooseTest.ors.descGt": APPB2C_common_chooseTest_ors_descGt,
  "APPB2C.common.chooseTest.ors.cta": APPB2C_common_chooseTest_ors_cta,
  "APPB2C.common.findTest.title": APPB2C_common_findTest_title,
  "APPB2C.common.findTest.subtitle": APPB2C_common_findTest_subtitle,
  "APPB2C.common.findTest.cta": APPB2C_common_findTest_cta,
  "APPB2C.common.findTest.searchIn": APPB2C_common_findTest_searchIn,
  "APPB2C.common.findTest.changeCountry": APPB2C_common_findTest_changeCountry,
  "APPB2C.common.findTest.changeCity": APPB2C_common_findTest_changeCity,
  "APPB2C.common.findTest.langModal.title": APPB2C_common_findTest_langModal_title,
  "APPB2C.common.findTest.langModal.continue": APPB2C_common_findTest_langModal_continue,
  "APPB2C.common.findTest.langModal.choose": APPB2C_common_findTest_langModal_choose,
  "APPB2C.common.findTest.langModal.chooseSingle": APPB2C_common_findTest_langModal_chooseSingle,
  "APPB2C.common.findTest.multipleLocations": APPB2C_common_findTest_multipleLocations,
  "APPB2C.common.findTest.selectCountry.label": APPB2C_common_findTest_selectCountry_label,
  "APPB2C.common.findTest.selectCountry.placeholder": APPB2C_common_findTest_selectCountry_placeholder,
  "APPB2C.common.findTest.selectCountry.continue": APPB2C_common_findTest_selectCountry_continue,
  "APPB2C.common.findTest.selectCountry.countryNotSupported": APPB2C_common_findTest_selectCountry_countryNotSupported,
  "APPB2C.common.findTest.selectLocation.label": APPB2C_common_findTest_selectLocation_label,
  "APPB2C.common.findTest.selectLocation.noExamForThisCountry": APPB2C_common_findTest_selectLocation_noExamForThisCountry,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option1": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option1,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.cta": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option2": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option2,
  "APPB2C.common.findTest.selectLocation.closestToMe": APPB2C_common_findTest_selectLocation_closestToMe,
  "APPB2C.common.findTest.selectLocation.geolocationBlocked": APPB2C_common_findTest_selectLocation_geolocationBlocked,
  "APPB2C.common.findTest.dates.title": APPB2C_common_findTest_dates_title,
  "APPB2C.common.findTest.dates.showAll": APPB2C_common_findTest_dates_showAll,
  "APPB2C.common.findTest.dates.showDateRange": APPB2C_common_findTest_dates_showDateRange,
  "APPB2C.common.findTest.dates.pleaseSelect": APPB2C_common_findTest_dates_pleaseSelect,
  "APPB2C.common.findTest.dates.selected": APPB2C_common_findTest_dates_selected,
  "APPB2C.common.findTest.dates.from": APPB2C_common_findTest_dates_from,
  "APPB2C.common.findTest.dates.to": APPB2C_common_findTest_dates_to,
  "APPB2C.common.findTest.dates.clear": APPB2C_common_findTest_dates_clear,
  "APPB2C.common.findTest.dates.noDatesSelected": APPB2C_common_findTest_dates_noDatesSelected,
  "APPB2C.common.findTest.dates.noresults.title": APPB2C_common_findTest_dates_noresults_title,
  "APPB2C.common.findTest.dates.noresults.details": APPB2C_common_findTest_dates_noresults_details,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.title": APPB2C_common_findTest_dates_noresultsSpecialReqs_title,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.details": APPB2C_common_findTest_dates_noresultsSpecialReqs_details,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.title": APPB2C_common_findTest_dates_noresultsMobileLocation_title,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.details": APPB2C_common_findTest_dates_noresultsMobileLocation_details,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.cta": APPB2C_common_findTest_dates_noresultsMobileLocation_cta,
  "APPB2C.common.findTest.specialReqs.findOutMore": APPB2C_common_findTest_specialReqs_findOutMore,
  "APPB2C.common.findTest.specialReqs.needCheckbox": APPB2C_common_findTest_specialReqs_needCheckbox,
  "APPB2C.common.findTest.specialReqs.list.title": APPB2C_common_findTest_specialReqs_list_title,
  "APPB2C.common.findTest.specialReqs.list.subtitle": APPB2C_common_findTest_specialReqs_list_subtitle,
  "APPB2C.common.findTest.specialReqs.other": APPB2C_common_findTest_specialReqs_other,
  "APPB2C.common.findTest.specialReqs.pleaseNoteMessage": APPB2C_common_findTest_specialReqs_pleaseNoteMessage,
  "APPB2C.common.findTest.examFormat.title": APPB2C_common_findTest_examFormat_title,
  "APPB2C.common.findTest.examFormat.results": APPB2C_common_findTest_examFormat_results,
  "APPB2C.common.findTest.examFormat.pb": APPB2C_common_findTest_examFormat_pb,
  "APPB2C.common.findTest.examFormat.cd": APPB2C_common_findTest_examFormat_cd,
  "APPB2C.common.findTest.examFormat.all": APPB2C_common_findTest_examFormat_all,
  "APPB2C.common.findTest.residencyConfirmation.title": APPB2C_common_findTest_residencyConfirmation_title,
  "APPB2C.common.findTest.residencyConfirmation.msg.line1": APPB2C_common_findTest_residencyConfirmation_msg_line1,
  "APPB2C.common.findTest.residencyConfirmation.msg.line2": APPB2C_common_findTest_residencyConfirmation_msg_line2,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInCountryLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine2,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInLocationLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine2,
  "APPB2C.common.findTest.residencyConfirmation.msg.line3": APPB2C_common_findTest_residencyConfirmation_msg_line3,
  "APPB2C.common.findTest.residencyConfirmation.selectDates": APPB2C_common_findTest_residencyConfirmation_selectDates,
  "APPB2C.common.findTest.residencyConfirmation.btn": APPB2C_common_findTest_residencyConfirmation_btn,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.title": APPB2C_common_findTest_residencyConfirmation_errorMsg_title,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line1": APPB2C_common_findTest_residencyConfirmation_errorMsg_line1,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line2": APPB2C_common_findTest_residencyConfirmation_errorMsg_line2,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.btn": APPB2C_common_findTest_residencyConfirmation_errorMsg_btn,
  "APPB2C.common.findTest.osrInfo": APPB2C_common_findTest_osrInfo,
  "APPB2C.common.findTest.osrInfoLink": APPB2C_common_findTest_osrInfoLink,
  "APPB2C.common.findTest.iol.noLrwResults": APPB2C_common_findTest_iol_noLrwResults,
  "APPB2C.common.findTest.iol.softwareCheck.title": APPB2C_common_findTest_iol_softwareCheck_title,
  "APPB2C.common.findTest.iol.softwareCheck.desc1": APPB2C_common_findTest_iol_softwareCheck_desc1,
  "APPB2C.common.findTest.iol.softwareCheck.desc2": APPB2C_common_findTest_iol_softwareCheck_desc2,
  "APPB2C.common.findTest.iol.softwareCheck.link": APPB2C_common_findTest_iol_softwareCheck_link,
  "APPB2C.common.findTest.iol.softwareCheck.check": APPB2C_common_findTest_iol_softwareCheck_check,
  "APPB2C.common.findTest.iol.organisationCheck.title": APPB2C_common_findTest_iol_organisationCheck_title,
  "APPB2C.common.findTest.iol.organisationCheck.desc": APPB2C_common_findTest_iol_organisationCheck_desc,
  "APPB2C.common.findTest.iol.organisationCheck.check": APPB2C_common_findTest_iol_organisationCheck_check,
  "APPB2C.common.findTest.uol.softwareCheck.title": APPB2C_common_findTest_uol_softwareCheck_title,
  "APPB2C.common.findTest.uol.softwareCheck.desc1": APPB2C_common_findTest_uol_softwareCheck_desc1,
  "APPB2C.common.findTest.uol.softwareCheck.desc2": APPB2C_common_findTest_uol_softwareCheck_desc2,
  "APPB2C.common.findTest.uol.softwareCheck.link": APPB2C_common_findTest_uol_softwareCheck_link,
  "APPB2C.common.findTest.uol.softwareCheck.check": APPB2C_common_findTest_uol_softwareCheck_check,
  "APPB2C.common.findTest.uol.organisationCheck.title": APPB2C_common_findTest_uol_organisationCheck_title,
  "APPB2C.common.findTest.uol.organisationCheck.desc": APPB2C_common_findTest_uol_organisationCheck_desc,
  "APPB2C.common.findTest.uol.organisationCheck.check": APPB2C_common_findTest_uol_organisationCheck_check,
  "APPB2C.common.bookTest.title": APPB2C_common_bookTest_title,
  "APPB2C.common.bookTest.noDiscount.title": APPB2C_common_bookTest_noDiscount_title,
  "APPB2C.common.bookTest.noDiscount.msg.used": APPB2C_common_bookTest_noDiscount_msg_used,
  "APPB2C.common.bookTest.noDiscount.msg.reserved": APPB2C_common_bookTest_noDiscount_msg_reserved,
  "APPB2C.common.bookTest.noDiscount.msg.countryNotMatched": APPB2C_common_bookTest_noDiscount_msg_countryNotMatched,
  "APPB2C.common.bookTest.noDiscount.msg.notSupported": APPB2C_common_bookTest_noDiscount_msg_notSupported,
  "APPB2C.common.bookTest.noDiscount.msg.notFound": APPB2C_common_bookTest_noDiscount_msg_notFound,
  "APPB2C.common.bookTest.changeExamType": APPB2C_common_bookTest_changeExamType,
  "APPB2C.common.bookTest.loadMoreTests": APPB2C_common_bookTest_loadMoreTests,
  "APPB2C.common.bookTest.sortBy.distance": APPB2C_common_bookTest_sortBy_distance,
  "APPB2C.common.bookTest.sortBy.date": APPB2C_common_bookTest_sortBy_date,
  "APPB2C.common.bookTest.sortBy.price": APPB2C_common_bookTest_sortBy_price,
  "APPB2C.common.bookTest.sortBy.title": APPB2C_common_bookTest_sortBy_title,
  "APPB2C.common.bookTest.filters.title": APPB2C_common_bookTest_filters_title,
  "APPB2C.common.bookTest.filters.computer": APPB2C_common_bookTest_filters_computer,
  "APPB2C.common.bookTest.filters.paper": APPB2C_common_bookTest_filters_paper,
  "APPB2C.common.bookTest.filters.allDates": APPB2C_common_bookTest_filters_allDates,
  "APPB2C.common.bookTest.filters.seeAllFilters": APPB2C_common_bookTest_filters_seeAllFilters,
  "APPB2C.common.bookTest.filters.anyTime": APPB2C_common_bookTest_filters_anyTime,
  "APPB2C.common.bookTest.filters.morning": APPB2C_common_bookTest_filters_morning,
  "APPB2C.common.bookTest.filters.afternoon": APPB2C_common_bookTest_filters_afternoon,
  "APPB2C.common.bookTest.filters.evening": APPB2C_common_bookTest_filters_evening,
  "APPB2C.common.bookTest.filters.modal.title": APPB2C_common_bookTest_filters_modal_title,
  "APPB2C.common.bookTest.filters.modal.subtitle": APPB2C_common_bookTest_filters_modal_subtitle,
  "APPB2C.common.bookTest.filters.modal.whichFormatTest": APPB2C_common_bookTest_filters_modal_whichFormatTest,
  "APPB2C.common.bookTest.filters.modal.resultsIn": APPB2C_common_bookTest_filters_modal_resultsIn,
  "APPB2C.common.bookTest.filters.modal.iDontMind": APPB2C_common_bookTest_filters_modal_iDontMind,
  "APPB2C.common.bookTest.filters.modal.whichTimeOfDay": APPB2C_common_bookTest_filters_modal_whichTimeOfDay,
  "APPB2C.common.bookTest.filters.modal.hide": APPB2C_common_bookTest_filters_modal_hide,
  "APPB2C.common.bookTest.filters.modal.cta": APPB2C_common_bookTest_filters_modal_cta,
  "APPB2C.common.bookTest.results.searchText": APPB2C_common_bookTest_results_searchText,
  "APPB2C.common.bookTest.results.noResultsTitle": APPB2C_common_bookTest_results_noResultsTitle,
  "APPB2C.common.bookTest.results.noResultsText": APPB2C_common_bookTest_results_noResultsText,
  "APPB2C.common.bookTest.results.noResultsGoBack": APPB2C_common_bookTest_results_noResultsGoBack,
  "APPB2C.common.bookTest.results.clearFilters": APPB2C_common_bookTest_results_clearFilters,
  "APPB2C.common.bookTest.results.lrw": APPB2C_common_bookTest_results_lrw,
  "APPB2C.common.bookTest.results.book": APPB2C_common_bookTest_results_book,
  "APPB2C.common.bookTest.results.bookFor": APPB2C_common_bookTest_results_bookFor,
  "APPB2C.common.bookTest.results.review": APPB2C_common_bookTest_results_review,
  "APPB2C.common.bookTest.results.cd": APPB2C_common_bookTest_results_cd,
  "APPB2C.common.bookTest.results.pb": APPB2C_common_bookTest_results_pb,
  "APPB2C.common.bookTest.results.changeSpeaking": APPB2C_common_bookTest_results_changeSpeaking,
  "APPB2C.common.bookTest.results.speaking": APPB2C_common_bookTest_results_speaking,
  "APPB2C.common.bookTest.results.fromCurrentLocation": APPB2C_common_bookTest_results_fromCurrentLocation,
  "APPB2C.common.bookTest.results.viewOnMap": APPB2C_common_bookTest_results_viewOnMap,
  "APPB2C.common.regInProgressModal.title": APPB2C_common_regInProgressModal_title,
  "APPB2C.common.regInProgressModal.message1": APPB2C_common_regInProgressModal_message1,
  "APPB2C.common.regInProgressModal.message2": APPB2C_common_regInProgressModal_message2,
  "APPB2C.common.regInProgressModal.restart": APPB2C_common_regInProgressModal_restart,
  "APPB2C.common.regInProgressModal.continue": APPB2C_common_regInProgressModal_continue,
  "APPB2C.common.testDetails.mapModal.title": APPB2C_common_testDetails_mapModal_title,
  "APPB2C.common.testDetails.mapModal.close": APPB2C_common_testDetails_mapModal_close,
  "APPB2C.common.testDetails.title": APPB2C_common_testDetails_title,
  "APPB2C.common.testDetails.changeWrittenTest": APPB2C_common_testDetails_changeWrittenTest,
  "APPB2C.common.testDetails.yourWrittenTest": APPB2C_common_testDetails_yourWrittenTest,
  "APPB2C.common.testDetails.changeTest": APPB2C_common_testDetails_changeTest,
  "APPB2C.common.testDetails.yourSpeakingTest": APPB2C_common_testDetails_yourSpeakingTest,
  "APPB2C.common.testDetails.completeYourBooking": APPB2C_common_testDetails_completeYourBooking,
  "APPB2C.common.testDetails.modifyNote.pleaseNote": APPB2C_common_testDetails_modifyNote_pleaseNote,
  "APPB2C.common.testDetails.modifyNote.note": APPB2C_common_testDetails_modifyNote_note,
  "APPB2C.common.testDetails.modifyNote.confirm": APPB2C_common_testDetails_modifyNote_confirm,
  "APPB2C.common.invitation.modal.header": APPB2C_common_invitation_modal_header,
  "APPB2C.common.invitation.modal.invalid": APPB2C_common_invitation_modal_invalid,
  "APPB2C.common.fileUpload.cta": APPB2C_common_fileUpload_cta,
  "APPB2C.common.fileUpload.requirements": APPB2C_common_fileUpload_requirements,
  "APPB2C.common.fileUpload.fileSize": APPB2C_common_fileUpload_fileSize,
  "APPB2C.common.fileUpload.remove": APPB2C_common_fileUpload_remove,
  "APPB2C.common.fileUpload.fileApproved": APPB2C_common_fileUpload_fileApproved,
  "APPB2C.common.fileUpload.fileRequired": APPB2C_common_fileUpload_fileRequired,
  "APPB2C.common.fileUpload.fileOptional": APPB2C_common_fileUpload_fileOptional,
  "APPB2C.common.fileUpload.filesCount": APPB2C_common_fileUpload_filesCount,
  "APPB2C.common.fileUpload.pdfIeMsg": APPB2C_common_fileUpload_pdfIeMsg,
  "APPB2C.common.fileUpload.success": APPB2C_common_fileUpload_success,
  "APPB2C.common.fileUpload.pdfPasswordMsg": APPB2C_common_fileUpload_pdfPasswordMsg,
  "APPB2C.common.fileUpload.exceededSize": APPB2C_common_fileUpload_exceededSize,
  "APPB2C.common.fileUpload.imgAlt": APPB2C_common_fileUpload_imgAlt,
  "APPB2C.common.selfServices.requested.transfer": APPB2C_common_selfServices_requested_transfer,
  "APPB2C.common.selfServices.requested.cancellation": APPB2C_common_selfServices_requested_cancellation,
  "APPB2C.common.filePreview.label": APPB2C_common_filePreview_label,
  "APPB2C.common.filePreview.status": APPB2C_common_filePreview_status,
  "APPB2C.common.filePreview.pdfPreviewLink": APPB2C_common_filePreview_pdfPreviewLink,
  "APPB2C.common.filePreview.statusName.pending": APPB2C_common_filePreview_statusName_pending,
  "APPB2C.common.filePreview.statusName.approved": APPB2C_common_filePreview_statusName_approved,
  "APPB2C.common.filePreview.statusName.rejected": APPB2C_common_filePreview_statusName_rejected,
  "APPB2C.common.filePreview.statusName.reviewed": APPB2C_common_filePreview_statusName_reviewed,
  "APPB2C.common.filePreview.statusName.deleted": APPB2C_common_filePreview_statusName_deleted,
  "APPB2C.common.unpaidErrorBar.paymentExpired": APPB2C_common_unpaidErrorBar_paymentExpired,
  "APPB2C.common.alertBar.beforeTest": APPB2C_common_alertBar_beforeTest,
  "APPB2C.common.alertBar.withCreds": APPB2C_common_alertBar_withCreds,
  "APPB2C.common.alertBar.afterTest": APPB2C_common_alertBar_afterTest,
  "APPB2C.common.alertBar.regPayment": APPB2C_common_alertBar_regPayment,
  "APPB2C.common.alertBar.regPaymentProof": APPB2C_common_alertBar_regPaymentProof,
  "APPB2C.common.alertBar.eorPayment": APPB2C_common_alertBar_eorPayment,
  "APPB2C.common.alertBar.eorPaymentProof": APPB2C_common_alertBar_eorPaymentProof,
  "APPB2C.common.alertBar.idUpload": APPB2C_common_alertBar_idUpload,
  "APPB2C.common.alertBar.idReupload": APPB2C_common_alertBar_idReupload,
  "APPB2C.common.alertBar.medicalUpload": APPB2C_common_alertBar_medicalUpload,
  "APPB2C.common.alertBar.medicalReupload": APPB2C_common_alertBar_medicalReupload,
  "APPB2C.common.alertBar.cancelReupload": APPB2C_common_alertBar_cancelReupload,
  "APPB2C.common.alertBar.childConsentMissing": APPB2C_common_alertBar_childConsentMissing,
  "APPB2C.common.alertBar.check": APPB2C_common_alertBar_check,
  "APPB2C.common.alertBar.creds": APPB2C_common_alertBar_creds,
  "APPB2C.common.updateBar.close": APPB2C_common_updateBar_close,
  "APPB2C.common.updateBar.cancelPending": APPB2C_common_updateBar_cancelPending,
  "APPB2C.common.updateBar.cancelRejected": APPB2C_common_updateBar_cancelRejected,
  "APPB2C.common.updateBar.cancelled": APPB2C_common_updateBar_cancelled,
  "APPB2C.common.updateBar.transferCancelled": APPB2C_common_updateBar_transferCancelled,
  "APPB2C.common.updateBar.transferPending": APPB2C_common_updateBar_transferPending,
  "APPB2C.common.updateBar.transferPendingCmds": APPB2C_common_updateBar_transferPendingCmds,
  "APPB2C.common.updateBar.transferred": APPB2C_common_updateBar_transferred,
  "APPB2C.common.updateBar.transferredCmds": APPB2C_common_updateBar_transferredCmds,
  "APPB2C.common.updateBar.termsUpdated": APPB2C_common_updateBar_termsUpdated,
  "APPB2C.common.updateBar.hasResults": APPB2C_common_updateBar_hasResults,
  "APPB2C.common.updateBar.eorPending": APPB2C_common_updateBar_eorPending,
  "APPB2C.common.updateBar.eorPendingAgentPays": APPB2C_common_updateBar_eorPendingAgentPays,
  "APPB2C.common.updateBar.eorCompleted": APPB2C_common_updateBar_eorCompleted,
  "APPB2C.common.updateBar.eorCompleted.scoreDecreased": APPB2C_common_updateBar_eorCompleted_scoreDecreased,
  "APPB2C.common.updateBar.eorCompleted.scoreUnchanged": APPB2C_common_updateBar_eorCompleted_scoreUnchanged,
  "APPB2C.common.updateBar.eorCompleted.scoreIncreased": APPB2C_common_updateBar_eorCompleted_scoreIncreased,
  "APPB2C.common.updateBar.eorRejected": APPB2C_common_updateBar_eorRejected,
  "APPB2C.common.updateBar.eorRejected.overallBand": APPB2C_common_updateBar_eorRejected_overallBand,
  "APPB2C.common.updateBar.details": APPB2C_common_updateBar_details,
  "APPB2C.common.updateBar.eorRefundPending": APPB2C_common_updateBar_eorRefundPending,
  "APPB2C.common.updateBar.eorRefunded": APPB2C_common_updateBar_eorRefunded,
  "APPB2C.common.updateBar.transactionRefundRequestPending": APPB2C_common_updateBar_transactionRefundRequestPending,
  "APPB2C.common.updateBar.transactionRefundRequestAccepted": APPB2C_common_updateBar_transactionRefundRequestAccepted,
  "APPB2C.common.updateBar.transactionRefundRequestRejected": APPB2C_common_updateBar_transactionRefundRequestRejected,
  "APPB2C.common.unknownCountry.title": APPB2C_common_unknownCountry_title,
  "APPB2C.common.unknownCountry.header": APPB2C_common_unknownCountry_header,
  "APPB2C.common.unknownCountry.msg1": APPB2C_common_unknownCountry_msg1,
  "APPB2C.common.unknownCountry.msg2": APPB2C_common_unknownCountry_msg2,
  "APPB2C.common.unknownCountry.cta": APPB2C_common_unknownCountry_cta,
  "APPB2C.common.underConstruction.title": APPB2C_common_underConstruction_title,
  "APPB2C.common.underConstruction.message": APPB2C_common_underConstruction_message,
  "APPB2C.common.terms.title": APPB2C_common_terms_title,
  "APPB2C.common.terms.downloadTitle": APPB2C_common_terms_downloadTitle,
  "APPB2C.common.terms.downloadInfo": APPB2C_common_terms_downloadInfo,
  "APPB2C.common.terms.error": APPB2C_common_terms_error,
  "APPB2C.common.modals.newTermsAndConditions.trigger": APPB2C_common_modals_newTermsAndConditions_trigger,
  "APPB2C.common.modals.newTermsAndConditions.title": APPB2C_common_modals_newTermsAndConditions_title,
  "APPB2C.common.modals.newTermsAndConditions.note": APPB2C_common_modals_newTermsAndConditions_note,
  "APPB2C.common.modals.newTermsAndConditions.checkboxNote": APPB2C_common_modals_newTermsAndConditions_checkboxNote,
  "APPB2C.common.modals.newTermsAndConditions.checkboxTC": APPB2C_common_modals_newTermsAndConditions_checkboxTC,
  "APPB2C.common.modals.newTermsAndConditions.cta": APPB2C_common_modals_newTermsAndConditions_cta,
  "APPB2C.common.modals.newTermsAndConditions.error": APPB2C_common_modals_newTermsAndConditions_error,
  "APPB2C.common.gelRedirect.header": APPB2C_common_gelRedirect_header,
  "APPB2C.common.gelRedirect.info": APPB2C_common_gelRedirect_info,
  "APPB2C.common.gelRedirect.errorMessage": APPB2C_common_gelRedirect_errorMessage,
  "APPB2C.registration.tabs.bookingDetails": APPB2C_registration_tabs_bookingDetails,
  "APPB2C.registration.tabs.date": APPB2C_registration_tabs_date,
  "APPB2C.registration.tabs.dateLocation": APPB2C_registration_tabs_dateLocation,
  "APPB2C.registration.tabs.candidate": APPB2C_registration_tabs_candidate,
  "APPB2C.registration.tabs.results": APPB2C_registration_tabs_results,
  "APPB2C.registration.tabs.payments": APPB2C_registration_tabs_payments,
  "APPB2C.registration.tabs.recognisingOrgs": APPB2C_registration_tabs_recognisingOrgs,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.1": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_1,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.2": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_2,
  "APPB2C.registration.date.zone.header": APPB2C_registration_date_zone_header,
  "APPB2C.registration.date.zone.country": APPB2C_registration_date_zone_country,
  "APPB2C.registration.date.zone.time": APPB2C_registration_date_zone_time,
  "APPB2C.registration.date.zone.name": APPB2C_registration_date_zone_name,
  "APPB2C.registration.date.check.back": APPB2C_registration_date_check_back,
  "APPB2C.registration.date.check.beforeTestDate": APPB2C_registration_date_check_beforeTestDate,
  "APPB2C.registration.date.check.intro": APPB2C_registration_date_check_intro,
  "APPB2C.registration.date.check.step1.title": APPB2C_registration_date_check_step1_title,
  "APPB2C.registration.date.check.step1.body": APPB2C_registration_date_check_step1_body,
  "APPB2C.registration.date.check.step1.link": APPB2C_registration_date_check_step1_link,
  "APPB2C.registration.date.check.step2.title": APPB2C_registration_date_check_step2_title,
  "APPB2C.registration.date.check.step2.body": APPB2C_registration_date_check_step2_body,
  "APPB2C.registration.date.check.step2.link": APPB2C_registration_date_check_step2_link,
  "APPB2C.registration.date.check.onTestDate": APPB2C_registration_date_check_onTestDate,
  "APPB2C.registration.date.check.step3.body1": APPB2C_registration_date_check_step3_body1,
  "APPB2C.registration.date.check.step3.noDetailsTitle": APPB2C_registration_date_check_step3_noDetailsTitle,
  "APPB2C.registration.date.check.step3.noDetailsBody": APPB2C_registration_date_check_step3_noDetailsBody,
  "APPB2C.registration.date.check.step3.username": APPB2C_registration_date_check_step3_username,
  "APPB2C.registration.date.check.step3.password": APPB2C_registration_date_check_step3_password,
  "APPB2C.registration.date.check.step3.body2": APPB2C_registration_date_check_step3_body2,
  "APPB2C.registration.date.check.step3.step1.title": APPB2C_registration_date_check_step3_step1_title,
  "APPB2C.registration.date.check.step3.step1.body": APPB2C_registration_date_check_step3_step1_body,
  "APPB2C.registration.date.check.step3.step2.title": APPB2C_registration_date_check_step3_step2_title,
  "APPB2C.registration.date.check.step3.step2.body": APPB2C_registration_date_check_step3_step2_body,
  "APPB2C.registration.date.check.step3.step3.title": APPB2C_registration_date_check_step3_step3_title,
  "APPB2C.registration.date.check.step3.step3.body": APPB2C_registration_date_check_step3_step3_body,
  "APPB2C.registration.date.check.step3.speakingPin": APPB2C_registration_date_check_step3_speakingPin,
  "APPB2C.registration.date.check.step3.writtenPin": APPB2C_registration_date_check_step3_writtenPin,
  "APPB2C.registration.date.check.step3.noSpeakingPinTitle": APPB2C_registration_date_check_step3_noSpeakingPinTitle,
  "APPB2C.registration.date.check.step3.noSpeakingPinBody": APPB2C_registration_date_check_step3_noSpeakingPinBody,
  "APPB2C.registration.bookingDetails.seeTestTaker": APPB2C_registration_bookingDetails_seeTestTaker,
  "APPB2C.registration.bookingDetails.payment": APPB2C_registration_bookingDetails_payment,
  "APPB2C.registration.bookingDetails.seePayment": APPB2C_registration_bookingDetails_seePayment,
  "APPB2C.registration.bookingDetails.whatNext": APPB2C_registration_bookingDetails_whatNext,
  "APPB2C.registration.bookingDetails.results": APPB2C_registration_bookingDetails_results,
  "APPB2C.registration.dateLocation.changeLinkIol": APPB2C_registration_dateLocation_changeLinkIol,
  "APPB2C.registration.dateLocation.changeLrwLink": APPB2C_registration_dateLocation_changeLrwLink,
  "APPB2C.registration.dateLocation.changeSpeakingLink": APPB2C_registration_dateLocation_changeSpeakingLink,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsHeader": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody1": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody1,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody2": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody2,
  "APPB2C.registration.dateLocation.speakingTransfer.keepSpeakingTest": APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest,
  "APPB2C.registration.dateLocation.speakingTransfer.requestTransfer": APPB2C_registration_dateLocation_speakingTransfer_requestTransfer,
  "APPB2C.registration.dateLocation.location": APPB2C_registration_dateLocation_location,
  "APPB2C.registration.dateLocation.manage.title": APPB2C_registration_dateLocation_manage_title,
  "APPB2C.registration.dateLocation.manage.transferSpeaking": APPB2C_registration_dateLocation_manage_transferSpeaking,
  "APPB2C.registration.dateLocation.manage.transferWhole": APPB2C_registration_dateLocation_manage_transferWhole,
  "APPB2C.registration.dateLocation.manage.cancel": APPB2C_registration_dateLocation_manage_cancel,
  "APPB2C.registration.testTaker.bookedFor.title": APPB2C_registration_testTaker_bookedFor_title,
  "APPB2C.registration.testTaker.bookedFor.completed": APPB2C_registration_testTaker_bookedFor_completed,
  "APPB2C.registration.testTaker.bookedFor.parent": APPB2C_registration_testTaker_bookedFor_parent,
  "APPB2C.registration.testTaker.bookedFor.name": APPB2C_registration_testTaker_bookedFor_name,
  "APPB2C.registration.testTaker.bookedFor.dob": APPB2C_registration_testTaker_bookedFor_dob,
  "APPB2C.registration.testTaker.bookedFor.gender": APPB2C_registration_testTaker_bookedFor_gender,
  "APPB2C.registration.testTaker.bookedFor.email": APPB2C_registration_testTaker_bookedFor_email,
  "APPB2C.registration.testTaker.contact.title": APPB2C_registration_testTaker_contact_title,
  "APPB2C.registration.testTaker.contact.parent": APPB2C_registration_testTaker_contact_parent,
  "APPB2C.registration.testTaker.contact.edit": APPB2C_registration_testTaker_contact_edit,
  "APPB2C.registration.testTaker.contact.address": APPB2C_registration_testTaker_contact_address,
  "APPB2C.registration.testTaker.contact.mobile": APPB2C_registration_testTaker_contact_mobile,
  "APPB2C.registration.testTaker.consent.title": APPB2C_registration_testTaker_consent_title,
  "APPB2C.registration.testTaker.consent.edit": APPB2C_registration_testTaker_consent_edit,
  "APPB2C.registration.testTaker.consent.missing": APPB2C_registration_testTaker_consent_missing,
  "APPB2C.registration.testTaker.childConsent.title": APPB2C_registration_testTaker_childConsent_title,
  "APPB2C.registration.testTaker.childConsent.back": APPB2C_registration_testTaker_childConsent_back,
  "APPB2C.registration.testTaker.childConsent.warning": APPB2C_registration_testTaker_childConsent_warning,
  "APPB2C.registration.testTaker.childConsent.context": APPB2C_registration_testTaker_childConsent_context,
  "APPB2C.registration.testTaker.childConsent.options.1": APPB2C_registration_testTaker_childConsent_options_1,
  "APPB2C.registration.testTaker.childConsent.options.2": APPB2C_registration_testTaker_childConsent_options_2,
  "APPB2C.registration.testTaker.childConsent.options.3": APPB2C_registration_testTaker_childConsent_options_3,
  "APPB2C.registration.testTaker.childConsent.cta": APPB2C_registration_testTaker_childConsent_cta,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.note": APPB2C_registration_testTaker_childConsent_somebodyElse_note,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.1": APPB2C_registration_testTaker_childConsent_somebodyElse_title_1,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.2": APPB2C_registration_testTaker_childConsent_somebodyElse_title_2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.noLastName": APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.relationship": APPB2C_registration_testTaker_childConsent_somebodyElse_relationship,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phone": APPB2C_registration_testTaker_childConsent_somebodyElse_phone,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phoneNote": APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.add": APPB2C_registration_testTaker_childConsent_somebodyElse_add,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.1": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_1,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.2": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmCheckbox": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.policy": APPB2C_registration_testTaker_childConsent_somebodyElse_policy,
  "APPB2C.registration.testTaker.editContact.note": APPB2C_registration_testTaker_editContact_note,
  "APPB2C.registration.testTaker.editContact.warn": APPB2C_registration_testTaker_editContact_warn,
  "APPB2C.registration.testTaker.editContact.back": APPB2C_registration_testTaker_editContact_back,
  "APPB2C.registration.testTaker.editContact.country.residence": APPB2C_registration_testTaker_editContact_country_residence,
  "APPB2C.registration.testTaker.editContact.country.change": APPB2C_registration_testTaker_editContact_country_change,
  "APPB2C.registration.testTaker.editContact.address": APPB2C_registration_testTaker_editContact_address,
  "APPB2C.registration.testTaker.editContact.city": APPB2C_registration_testTaker_editContact_city,
  "APPB2C.registration.testTaker.editContact.postCode": APPB2C_registration_testTaker_editContact_postCode,
  "APPB2C.registration.testTaker.editContact.state": APPB2C_registration_testTaker_editContact_state,
  "APPB2C.registration.testTaker.editContact.mobile": APPB2C_registration_testTaker_editContact_mobile,
  "APPB2C.registration.testTaker.editContact.cta": APPB2C_registration_testTaker_editContact_cta,
  "APPB2C.registration.testTaker.editContact.error.missingCountry": APPB2C_registration_testTaker_editContact_error_missingCountry,
  "APPB2C.registration.testTaker.editContact.error.missingAddress": APPB2C_registration_testTaker_editContact_error_missingAddress,
  "APPB2C.registration.testTaker.editContact.error.pastCutoff": APPB2C_registration_testTaker_editContact_error_pastCutoff,
  "APPB2C.registration.testTaker.id.title": APPB2C_registration_testTaker_id_title,
  "APPB2C.registration.testTaker.id.child": APPB2C_registration_testTaker_id_child,
  "APPB2C.registration.testTaker.id.typeNames.passport": APPB2C_registration_testTaker_id_typeNames_passport,
  "APPB2C.registration.testTaker.id.typeNames.card": APPB2C_registration_testTaker_id_typeNames_card,
  "APPB2C.registration.testTaker.id.typeNames.other": APPB2C_registration_testTaker_id_typeNames_other,
  "APPB2C.registration.testTaker.id.idNumber": APPB2C_registration_testTaker_id_idNumber,
  "APPB2C.registration.testTaker.id.expiryDate": APPB2C_registration_testTaker_id_expiryDate,
  "APPB2C.registration.testTaker.id.issuingAuthority": APPB2C_registration_testTaker_id_issuingAuthority,
  "APPB2C.registration.testTaker.id.fileAlt": APPB2C_registration_testTaker_id_fileAlt,
  "APPB2C.registration.testTaker.id.noFilesTitle": APPB2C_registration_testTaker_id_noFilesTitle,
  "APPB2C.registration.testTaker.id.noFilesBody": APPB2C_registration_testTaker_id_noFilesBody,
  "APPB2C.registration.testTaker.id.edit.title": APPB2C_registration_testTaker_id_edit_title,
  "APPB2C.registration.testTaker.id.edit.subtitle": APPB2C_registration_testTaker_id_edit_subtitle,
  "APPB2C.registration.testTaker.id.edit.rules.example": APPB2C_registration_testTaker_id_edit_rules_example,
  "APPB2C.registration.testTaker.id.edit.upload.exampleProof": APPB2C_registration_testTaker_id_edit_upload_exampleProof,
  "APPB2C.registration.testTaker.id.edit.upload.imgAlt": APPB2C_registration_testTaker_id_edit_upload_imgAlt,
  "APPB2C.registration.testTaker.files.editButton.add": APPB2C_registration_testTaker_files_editButton_add,
  "APPB2C.registration.testTaker.files.editButton.edit": APPB2C_registration_testTaker_files_editButton_edit,
  "APPB2C.registration.testTaker.files.upload.note": APPB2C_registration_testTaker_files_upload_note,
  "APPB2C.registration.testTaker.files.upload.mobile.title": APPB2C_registration_testTaker_files_upload_mobile_title,
  "APPB2C.registration.testTaker.files.upload.mobile.line1": APPB2C_registration_testTaker_files_upload_mobile_line1,
  "APPB2C.registration.testTaker.files.upload.mobile.line2": APPB2C_registration_testTaker_files_upload_mobile_line2,
  "APPB2C.registration.testTaker.files.upload.cta": APPB2C_registration_testTaker_files_upload_cta,
  "APPB2C.registration.testTaker.files.upload.back.title": APPB2C_registration_testTaker_files_upload_back_title,
  "APPB2C.registration.testTaker.files.upload.back.subtitle": APPB2C_registration_testTaker_files_upload_back_subtitle,
  "APPB2C.registration.testTaker.files.upload.error.maxNumberOfFilesExceeded": APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileType": APPB2C_registration_testTaker_files_upload_error_wrongFileType,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileName": APPB2C_registration_testTaker_files_upload_error_wrongFileName,
  "APPB2C.registration.testTaker.files.upload.error.fileTooLarge": APPB2C_registration_testTaker_files_upload_error_fileTooLarge,
  "APPB2C.registration.testTaker.files.upload.error.uploadUnavailable": APPB2C_registration_testTaker_files_upload_error_uploadUnavailable,
  "APPB2C.registration.testTaker.specialArrangements.title": APPB2C_registration_testTaker_specialArrangements_title,
  "APPB2C.registration.testTaker.specialArrangements.description": APPB2C_registration_testTaker_specialArrangements_description,
  "APPB2C.registration.testTaker.specialArrangements.noFilesTitle": APPB2C_registration_testTaker_specialArrangements_noFilesTitle,
  "APPB2C.registration.testTaker.specialArrangements.noFilesBody": APPB2C_registration_testTaker_specialArrangements_noFilesBody,
  "APPB2C.registration.testTaker.specialArrangements.fileAlt": APPB2C_registration_testTaker_specialArrangements_fileAlt,
  "APPB2C.registration.testTaker.specialArrangements.edit.title": APPB2C_registration_testTaker_specialArrangements_edit_title,
  "APPB2C.registration.testTaker.specialArrangements.edit.subtitle": APPB2C_registration_testTaker_specialArrangements_edit_subtitle,
  "APPB2C.registration.testTaker.specialArrangements.edit.line1": APPB2C_registration_testTaker_specialArrangements_edit_line1,
  "APPB2C.registration.testTaker.specialArrangements.edit.line2": APPB2C_registration_testTaker_specialArrangements_edit_line2,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.title": APPB2C_registration_testTaker_specialArrangements_edit_rules_title,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule1": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule1,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule2": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule2,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule3": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule3,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule4": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule4,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule5": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule5,
  "APPB2C.registration.testTaker.error": APPB2C_registration_testTaker_error,
  "APPB2C.registration.results.hasResults.viewExplanation": APPB2C_registration_results_hasResults_viewExplanation,
  "APPB2C.registration.results.hasResults.share.title": APPB2C_registration_results_hasResults_share_title,
  "APPB2C.registration.results.hasResults.share.subtitle": APPB2C_registration_results_hasResults_share_subtitle,
  "APPB2C.registration.results.hasResults.share.trfCta": APPB2C_registration_results_hasResults_share_trfCta,
  "APPB2C.registration.results.hasResults.share.RecognisingOrgsLink": APPB2C_registration_results_hasResults_share_RecognisingOrgsLink,
  "APPB2C.registration.results.hasResults.share.applyBoard": APPB2C_registration_results_hasResults_share_applyBoard,
  "APPB2C.registration.results.hasResults.scores.Listening": APPB2C_registration_results_hasResults_scores_Listening,
  "APPB2C.registration.results.hasResults.scores.Reading": APPB2C_registration_results_hasResults_scores_Reading,
  "APPB2C.registration.results.hasResults.scores.Writing": APPB2C_registration_results_hasResults_scores_Writing,
  "APPB2C.registration.results.hasResults.scores.Speaking": APPB2C_registration_results_hasResults_scores_Speaking,
  "APPB2C.registration.results.hasResults.scores.Overall": APPB2C_registration_results_hasResults_scores_Overall,
  "APPB2C.registration.results.hasResults.scores.label": APPB2C_registration_results_hasResults_scores_label,
  "APPB2C.registration.results.hasResults.lifeSkills.result": APPB2C_registration_results_hasResults_lifeSkills_result,
  "APPB2C.registration.results.hasResults.lifeSkills.level": APPB2C_registration_results_hasResults_lifeSkills_level,
  "APPB2C.registration.results.hasResults.lifeSkills.passed": APPB2C_registration_results_hasResults_lifeSkills_passed,
  "APPB2C.registration.results.hasResults.lifeSkills.failed": APPB2C_registration_results_hasResults_lifeSkills_failed,
  "APPB2C.registration.results.hasResults.lifeSkills.note": APPB2C_registration_results_hasResults_lifeSkills_note,
  "APPB2C.registration.results.hasResults.rankNameConsonant": APPB2C_registration_results_hasResults_rankNameConsonant,
  "APPB2C.registration.results.hasResults.rankNameVowel": APPB2C_registration_results_hasResults_rankNameVowel,
  "APPB2C.registration.results.hasResults.explanations.heading": APPB2C_registration_results_hasResults_explanations_heading,
  "APPB2C.registration.results.hasResults.explanations.improve": APPB2C_registration_results_hasResults_explanations_improve,
  "APPB2C.registration.results.hasResults.posting.postedOn": APPB2C_registration_results_hasResults_posting_postedOn,
  "APPB2C.registration.results.hasResults.posting.willPostOn": APPB2C_registration_results_hasResults_posting_willPostOn,
  "APPB2C.registration.results.hasResults.posting.postedTo": APPB2C_registration_results_hasResults_posting_postedTo,
  "APPB2C.registration.results.hasResults.posting.willPostTo": APPB2C_registration_results_hasResults_posting_willPostTo,
  "APPB2C.registration.results.hasResults.posting.oneCopyWarning": APPB2C_registration_results_hasResults_posting_oneCopyWarning,
  "APPB2C.registration.results.hasResults.eorLink": APPB2C_registration_results_hasResults_eorLink,
  "APPB2C.registration.results.hasResults.eorLine1": APPB2C_registration_results_hasResults_eorLine1,
  "APPB2C.registration.results.hasResults.eorLine2": APPB2C_registration_results_hasResults_eorLine2,
  "APPB2C.registration.results.hasResults.eorLetter": APPB2C_registration_results_hasResults_eorLetter,
  "APPB2C.registration.results.hasResults.scoreImprove.title": APPB2C_registration_results_hasResults_scoreImprove_title,
  "APPB2C.registration.results.hasResults.scoreImprove.subtitle": APPB2C_registration_results_hasResults_scoreImprove_subtitle,
  "APPB2C.registration.results.hasResults.scoreImprove.btn": APPB2C_registration_results_hasResults_scoreImprove_btn,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.subtitle": APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.backBtn": APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.title": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.description": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.title": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.description": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.title": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.description": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description,
  "APPB2C.registration.results.hasResults.bandScore.increase": APPB2C_registration_results_hasResults_bandScore_increase,
  "APPB2C.registration.results.hasResults.bandScore.decrease": APPB2C_registration_results_hasResults_bandScore_decrease,
  "APPB2C.registration.results.hasResults.bandScore.unchanged": APPB2C_registration_results_hasResults_bandScore_unchanged,
  "APPB2C.registration.results.updateBar.osr.marked": APPB2C_registration_results_updateBar_osr_marked,
  "APPB2C.registration.results.updateBar.osr.booked": APPB2C_registration_results_updateBar_osr_booked,
  "APPB2C.registration.results.hasResults.osr.updateBar.title": APPB2C_registration_results_hasResults_osr_updateBar_title,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreIncreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreDecreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreUnchanged": APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged,
  "APPB2C.registration.results.hasResults.osr.previousResults": APPB2C_registration_results_hasResults_osr_previousResults,
  "APPB2C.registration.results.bandScore.osrBooked": APPB2C_registration_results_bandScore_osrBooked,
  "APPB2C.registration.results.bandScore.osrMarked": APPB2C_registration_results_bandScore_osrMarked,
  "APPB2C.registration.results.eor.title": APPB2C_registration_results_eor_title,
  "APPB2C.registration.results.eor.titlePayment": APPB2C_registration_results_eor_titlePayment,
  "APPB2C.registration.results.eor.preDeadline.line1": APPB2C_registration_results_eor_preDeadline_line1,
  "APPB2C.registration.results.eor.preDeadline.paymentTitle": APPB2C_registration_results_eor_preDeadline_paymentTitle,
  "APPB2C.registration.results.eor.preDeadline.line2": APPB2C_registration_results_eor_preDeadline_line2,
  "APPB2C.registration.results.eor.preDeadline.note": APPB2C_registration_results_eor_preDeadline_note,
  "APPB2C.registration.results.eor.preDeadline.fee": APPB2C_registration_results_eor_preDeadline_fee,
  "APPB2C.registration.results.eor.preDeadline.tax": APPB2C_registration_results_eor_preDeadline_tax,
  "APPB2C.registration.results.eor.preDeadline.back": APPB2C_registration_results_eor_preDeadline_back,
  "APPB2C.registration.results.eor.preDeadline.scoresTitle": APPB2C_registration_results_eor_preDeadline_scoresTitle,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Listening": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Reading": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Writing": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Speaking": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking,
  "APPB2C.registration.results.eor.preDeadline.trfNumberLabel": APPB2C_registration_results_eor_preDeadline_trfNumberLabel,
  "APPB2C.registration.results.eor.preDeadline.trfNumberSubnote": APPB2C_registration_results_eor_preDeadline_trfNumberSubnote,
  "APPB2C.registration.results.eor.preDeadline.trfNumberValidation": APPB2C_registration_results_eor_preDeadline_trfNumberValidation,
  "APPB2C.registration.results.eor.preDeadline.maxScore": APPB2C_registration_results_eor_preDeadline_maxScore,
  "APPB2C.registration.results.eor.preDeadline.scoresRequired": APPB2C_registration_results_eor_preDeadline_scoresRequired,
  "APPB2C.registration.results.eor.preDeadline.explanation": APPB2C_registration_results_eor_preDeadline_explanation,
  "APPB2C.registration.results.eor.preDeadline.explanationRequired": APPB2C_registration_results_eor_preDeadline_explanationRequired,
  "APPB2C.registration.results.eor.preDeadline.evidence": APPB2C_registration_results_eor_preDeadline_evidence,
  "APPB2C.registration.results.eor.preDeadline.evidenceRequired": APPB2C_registration_results_eor_preDeadline_evidenceRequired,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line2": APPB2C_registration_results_eor_preDeadline_iolNote_line2,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line3": APPB2C_registration_results_eor_preDeadline_iolNote_line3,
  "APPB2C.registration.results.eor.preDeadline.noteTitle": APPB2C_registration_results_eor_preDeadline_noteTitle,
  "APPB2C.registration.results.eor.preDeadline.legal": APPB2C_registration_results_eor_preDeadline_legal,
  "APPB2C.registration.results.eor.preDeadline.legalNote": APPB2C_registration_results_eor_preDeadline_legalNote,
  "APPB2C.registration.results.eor.preDeadline.orderAcknowledgementLabel": APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel,
  "APPB2C.registration.results.eor.preDeadline.paymentCta": APPB2C_registration_results_eor_preDeadline_paymentCta,
  "APPB2C.registration.results.eor.preDeadline.payOnlineCTA": APPB2C_registration_results_eor_preDeadline_payOnlineCTA,
  "APPB2C.registration.results.eor.preDeadline.payOfflineCTA": APPB2C_registration_results_eor_preDeadline_payOfflineCTA,
  "APPB2C.registration.results.eor.preDeadline.error.missingTrfNumber": APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber,
  "APPB2C.registration.results.eor.preDeadline.error.wrongTrfNumber": APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber,
  "APPB2C.registration.results.eor.preDeadline.error.explanationMaxLength": APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength,
  "APPB2C.registration.results.eor.postDeadline.line1": APPB2C_registration_results_eor_postDeadline_line1,
  "APPB2C.registration.results.eor.postDeadline.line2": APPB2C_registration_results_eor_postDeadline_line2,
  "APPB2C.registration.results.eor.postDeadline.line3": APPB2C_registration_results_eor_postDeadline_line3,
  "APPB2C.registration.results.eor.postDeadline.iol.line3": APPB2C_registration_results_eor_postDeadline_iol_line3,
  "APPB2C.registration.results.eor.postDeadline.iol.launchChatLine": APPB2C_registration_results_eor_postDeadline_iol_launchChatLine,
  "APPB2C.registration.results.eor.postDeadline.back": APPB2C_registration_results_eor_postDeadline_back,
  "APPB2C.registration.results.eor.postDeadline.phone": APPB2C_registration_results_eor_postDeadline_phone,
  "APPB2C.registration.results.eor.postDeadline.email": APPB2C_registration_results_eor_postDeadline_email,
  "APPB2C.registration.results.eor.postDeadline.help": APPB2C_registration_results_eor_postDeadline_help,
  "APPB2C.registration.results.eor.acknowledgement.title": APPB2C_registration_results_eor_acknowledgement_title,
  "APPB2C.registration.results.eor.acknowledgement.success": APPB2C_registration_results_eor_acknowledgement_success,
  "APPB2C.registration.results.eor.acknowledgement.info": APPB2C_registration_results_eor_acknowledgement_info,
  "APPB2C.registration.results.eor.acknowledgement.confirmation": APPB2C_registration_results_eor_acknowledgement_confirmation,
  "APPB2C.registration.results.eor.acknowledgement.components": APPB2C_registration_results_eor_acknowledgement_components,
  "APPB2C.registration.results.eor.acknowledgement.noteTitle": APPB2C_registration_results_eor_acknowledgement_noteTitle,
  "APPB2C.registration.results.eor.acknowledgement.payment": APPB2C_registration_results_eor_acknowledgement_payment,
  "APPB2C.registration.results.eor.acknowledgement.fee": APPB2C_registration_results_eor_acknowledgement_fee,
  "APPB2C.registration.results.eor.acknowledgement.tax": APPB2C_registration_results_eor_acknowledgement_tax,
  "APPB2C.registration.results.eor.acknowledgement.total": APPB2C_registration_results_eor_acknowledgement_total,
  "APPB2C.registration.results.eor.acknowledgement.reference": APPB2C_registration_results_eor_acknowledgement_reference,
  "APPB2C.registration.results.eor.acknowledgement.terms": APPB2C_registration_results_eor_acknowledgement_terms,
  "APPB2C.registration.results.eor.acknowledgement.backButton": APPB2C_registration_results_eor_acknowledgement_backButton,
  "APPB2C.registration.results.eor.acknowledgement.paymentNote": APPB2C_registration_results_eor_acknowledgement_paymentNote,
  "APPB2C.registration.results.eor.acknowledgement.paymentButton": APPB2C_registration_results_eor_acknowledgement_paymentButton,
  "APPB2C.registration.results.osr.title": APPB2C_registration_results_osr_title,
  "APPB2C.registration.results.osr.line1": APPB2C_registration_results_osr_line1,
  "APPB2C.registration.results.osr.line2": APPB2C_registration_results_osr_line2,
  "APPB2C.registration.results.osr.line3": APPB2C_registration_results_osr_line3,
  "APPB2C.registration.results.osr.line4": APPB2C_registration_results_osr_line4,
  "APPB2C.registration.results.osr.line5": APPB2C_registration_results_osr_line5,
  "APPB2C.registration.results.osr.submitBtn": APPB2C_registration_results_osr_submitBtn,
  "APPB2C.registration.results.noResults.title": APPB2C_registration_results_noResults_title,
  "APPB2C.registration.results.osr.successBanner": APPB2C_registration_results_osr_successBanner,
  "APPB2C.registration.results.osr.chooseSkill.formTitle": APPB2C_registration_results_osr_chooseSkill_formTitle,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.title": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.description": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.confirmation": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation,
  "APPB2C.registration.results.osr.chooseSkill.chooseDateBtn": APPB2C_registration_results_osr_chooseSkill_chooseDateBtn,
  "APPB2C.registration.results.osr.chooseSkill.backBtn": APPB2C_registration_results_osr_chooseSkill_backBtn,
  "APPB2C.registration.results.osr.chooseSkill.skillRequired": APPB2C_registration_results_osr_chooseSkill_skillRequired,
  "APPB2C.registration.results.osr.chooseSkill.confirmationRequired": APPB2C_registration_results_osr_chooseSkill_confirmationRequired,
  "APPB2C.registration.results.osr.chooseSkill.modal.title": APPB2C_registration_results_osr_chooseSkill_modal_title,
  "APPB2C.registration.results.osr.chooseSkill.modal.description": APPB2C_registration_results_osr_chooseSkill_modal_description,
  "APPB2C.registration.results.osr.chooseSkill.modal.contact": APPB2C_registration_results_osr_chooseSkill_modal_contact,
  "APPB2C.registration.results.osr.chooseSkill.modal.phone": APPB2C_registration_results_osr_chooseSkill_modal_phone,
  "APPB2C.registration.results.osr.chooseSkill.modal.email": APPB2C_registration_results_osr_chooseSkill_modal_email,
  "APPB2C.registration.results.osr.chooseDate.retakeListening": APPB2C_registration_results_osr_chooseDate_retakeListening,
  "APPB2C.registration.results.osr.chooseDate.retakeReading": APPB2C_registration_results_osr_chooseDate_retakeReading,
  "APPB2C.registration.results.osr.chooseDate.retakeWriting": APPB2C_registration_results_osr_chooseDate_retakeWriting,
  "APPB2C.registration.results.osr.chooseDate.retakeSpeaking": APPB2C_registration_results_osr_chooseDate_retakeSpeaking,
  "APPB2C.registration.results.osr.chooseDate.chooseDateTitle": APPB2C_registration_results_osr_chooseDate_chooseDateTitle,
  "APPB2C.registration.results.osr.chooseDate.note.title": APPB2C_registration_results_osr_chooseDate_note_title,
  "APPB2C.registration.results.osr.chooseDate.note.content": APPB2C_registration_results_osr_chooseDate_note_content,
  "APPB2C.registration.results.osr.chooseDate.accessibility": APPB2C_registration_results_osr_chooseDate_accessibility,
  "APPB2C.registration.results.osr.chooseDate.accessibilityLink": APPB2C_registration_results_osr_chooseDate_accessibilityLink,
  "APPB2C.registration.results.osr.chooseTest.availableDates": APPB2C_registration_results_osr_chooseTest_availableDates,
  "APPB2C.registration.results.osr.chooseTest.speaking.time": APPB2C_registration_results_osr_chooseTest_speaking_time,
  "APPB2C.registration.results.osr.review.note": APPB2C_registration_results_osr_review_note,
  "APPB2C.registration.results.osr.review.note.testDay": APPB2C_registration_results_osr_review_note_testDay,
  "APPB2C.registration.results.osr.error.noTestDates": APPB2C_registration_results_osr_error_noTestDates,
  "APPB2C.registration.results.osr.error.noTests": APPB2C_registration_results_osr_error_noTests,
  "APPB2C.registration.results.osr.error.missingFile": APPB2C_registration_results_osr_error_missingFile,
  "APPB2C.registration.results.noResults.note.title": APPB2C_registration_results_noResults_note_title,
  "APPB2C.registration.results.noResults.osr.booked": APPB2C_registration_results_noResults_osr_booked,
  "APPB2C.registration.results.noResults.osr.previousScores": APPB2C_registration_results_noResults_osr_previousScores,
  "APPB2C.registration.results.noResults.cmds.title": APPB2C_registration_results_noResults_cmds_title,
  "APPB2C.registration.results.organisations.chosenOrganisationsHeader": APPB2C_registration_results_organisations_chosenOrganisationsHeader,
  "APPB2C.registration.results.organisations.organisationsCount": APPB2C_registration_results_organisations_organisationsCount,
  "APPB2C.registration.results.organisations.organisationsCount_other": APPB2C_registration_results_organisations_organisationsCount_other,
  "APPB2C.registration.results.organisations.title": APPB2C_registration_results_organisations_title,
  "APPB2C.registration.results.organisations.note0": APPB2C_registration_results_organisations_note0,
  "APPB2C.registration.results.organisations.note1": APPB2C_registration_results_organisations_note1,
  "APPB2C.registration.results.organisations.note2": APPB2C_registration_results_organisations_note2,
  "APPB2C.registration.results.organisations.list.item1": APPB2C_registration_results_organisations_list_item1,
  "APPB2C.registration.results.organisations.list.item2": APPB2C_registration_results_organisations_list_item2,
  "APPB2C.registration.results.organisations.list.item3": APPB2C_registration_results_organisations_list_item3,
  "APPB2C.registration.results.organisations.list.item4": APPB2C_registration_results_organisations_list_item4,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote1": APPB2C_registration_results_organisations_availableReceivingOrgsNote1,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote2": APPB2C_registration_results_organisations_availableReceivingOrgsNote2,
  "APPB2C.registration.results.organisations.receivingOrgsLimitReachedNote": APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote,
  "APPB2C.registration.results.organisations.noReceivingOrgsSelectedNote": APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote,
  "APPB2C.registration.results.organisations.mod.howTo.title": APPB2C_registration_results_organisations_mod_howTo_title,
  "APPB2C.registration.results.organisations.mod.howTo.line1": APPB2C_registration_results_organisations_mod_howTo_line1,
  "APPB2C.registration.results.organisations.mod.howTo.item1": APPB2C_registration_results_organisations_mod_howTo_item1,
  "APPB2C.registration.results.organisations.mod.howTo.item2": APPB2C_registration_results_organisations_mod_howTo_item2,
  "APPB2C.registration.results.organisations.mod.delivery.auto.header": APPB2C_registration_results_organisations_mod_delivery_auto_header,
  "APPB2C.registration.results.organisations.mod.delivery.auto.note": APPB2C_registration_results_organisations_mod_delivery_auto_note,
  "APPB2C.registration.results.organisations.mod.delivery.auto.notSentNote": APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote,
  "APPB2C.registration.results.organisations.mod.delivery.auto.sentNote": APPB2C_registration_results_organisations_mod_delivery_auto_sentNote,
  "APPB2C.registration.results.organisations.mod.delivery.postal.header": APPB2C_registration_results_organisations_mod_delivery_postal_header,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note1": APPB2C_registration_results_organisations_mod_delivery_postal_note1,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note2": APPB2C_registration_results_organisations_mod_delivery_postal_note2,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note3": APPB2C_registration_results_organisations_mod_delivery_postal_note3,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note4": APPB2C_registration_results_organisations_mod_delivery_postal_note4,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note5": APPB2C_registration_results_organisations_mod_delivery_postal_note5,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount_other": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other,
  "APPB2C.registration.results.organisations.mod.delivery.postal.notSentNote": APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote,
  "APPB2C.registration.results.organisations.mod.delivery.postal.sentNote": APPB2C_registration_results_organisations_mod_delivery_postal_sentNote,
  "APPB2C.registration.results.organisations.iol.roAccept.title": APPB2C_registration_results_organisations_iol_roAccept_title,
  "APPB2C.registration.results.organisations.iol.roAccept.message": APPB2C_registration_results_organisations_iol_roAccept_message,
  "APPB2C.registration.results.organisations.iol.roAccept.link": APPB2C_registration_results_organisations_iol_roAccept_link,
  "APPB2C.registration.results.organisations.iol.delivery.postal.header": APPB2C_registration_results_organisations_iol_delivery_postal_header,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note1": APPB2C_registration_results_organisations_iol_delivery_postal_note1,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2": APPB2C_registration_results_organisations_iol_delivery_postal_note2,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2.results": APPB2C_registration_results_organisations_iol_delivery_postal_note2_results,
  "APPB2C.registration.results.organisations.iol.delivery.auto.header": APPB2C_registration_results_organisations_iol_delivery_auto_header,
  "APPB2C.registration.results.organisations.iol.delivery.auto.note": APPB2C_registration_results_organisations_iol_delivery_auto_note,
  "APPB2C.registration.results.organisations.iol.delivery.auto.notSentNote": APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote,
  "APPB2C.registration.results.organisations.iol.delivery.auto.sentNote": APPB2C_registration_results_organisations_iol_delivery_auto_sentNote,
  "APPB2C.registration.results.organisations.iol.chooseCta": APPB2C_registration_results_organisations_iol_chooseCta,
  "APPB2C.registration.results.organisations.iol.noConditions": APPB2C_registration_results_organisations_iol_noConditions,
  "APPB2C.registration.results.organisations.iol.generalCondition": APPB2C_registration_results_organisations_iol_generalCondition,
  "APPB2C.registration.results.organisations.iol.preciseCondition": APPB2C_registration_results_organisations_iol_preciseCondition,
  "APPB2C.registration.results.organisations.iol.notChosen": APPB2C_registration_results_organisations_iol_notChosen,
  "APPB2C.registration.results.organisations.iol.edit": APPB2C_registration_results_organisations_iol_edit,
  "APPB2C.registration.results.organisations.iol.error": APPB2C_registration_results_organisations_iol_error,
  "APPB2C.registration.results.organisations.iol.sendAnyway": APPB2C_registration_results_organisations_iol_sendAnyway,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.country": APPB2C_registration_results_organisations_editRo_chooseOrganisations_country,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryOptional": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryAll": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByCountry": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.org": APPB2C_registration_results_organisations_editRo_chooseOrganisations_org,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByOrg": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.addNew": APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.organisation": APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.person": APPB2C_registration_results_organisations_editRo_chooseOrganisations_person,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.file": APPB2C_registration_results_organisations_editRo_chooseOrganisations_file,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.address": APPB2C_registration_results_organisations_editRo_chooseOrganisations_address,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.postCode": APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode,
  "APPB2C.registration.results.organisations.editRo.iol.newOrgTitle": APPB2C_registration_results_organisations_editRo_iol_newOrgTitle,
  "APPB2C.registration.results.organisations.editRo.iol.cancelEdit": APPB2C_registration_results_organisations_editRo_iol_cancelEdit,
  "APPB2C.registration.results.organisations.editRo.iol.noResults": APPB2C_registration_results_organisations_editRo_iol_noResults,
  "APPB2C.registration.results.organisations.editRo.iol.address": APPB2C_registration_results_organisations_editRo_iol_address,
  "APPB2C.registration.results.organisations.editRo.iol.copy": APPB2C_registration_results_organisations_editRo_iol_copy,
  "APPB2C.registration.results.organisations.editRo.iol.copied": APPB2C_registration_results_organisations_editRo_iol_copied,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note1": APPB2C_registration_results_organisations_editRo_iol_delivery_note1,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2": APPB2C_registration_results_organisations_editRo_iol_delivery_note2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2.results": APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.trf": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.eResults": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.note": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.postal": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.edelivery": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.header": APPB2C_registration_results_organisations_editRo_iol_conditions_header,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.subHeader": APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.no": APPB2C_registration_results_organisations_editRo_iol_conditions_no,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.yes": APPB2C_registration_results_organisations_editRo_iol_conditions_yes,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.mandatoryPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.optionalPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.clear": APPB2C_registration_results_organisations_editRo_iol_conditions_clear,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note1": APPB2C_registration_results_organisations_editRo_iol_conditions_note1,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note2": APPB2C_registration_results_organisations_editRo_iol_conditions_note2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note3": APPB2C_registration_results_organisations_editRo_iol_conditions_note3,
  "APPB2C.registration.results.organisations.editRo.iol.addCta": APPB2C_registration_results_organisations_editRo_iol_addCta,
  "APPB2C.registration.results.organisations.editRo.iol.saveCta": APPB2C_registration_results_organisations_editRo_iol_saveCta,
  "APPB2C.registration.results.organisations.editRo.iol.removeLink": APPB2C_registration_results_organisations_editRo_iol_removeLink,
  "APPB2C.registration.results.organisations.editRo.iol.validation.selectedOrganisation": APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation,
  "APPB2C.registration.results.organisations.editRo.iol.validation.overallScore": APPB2C_registration_results_organisations_editRo_iol_validation_overallScore,
  "APPB2C.registration.results.organisations.editRo.addAnother": APPB2C_registration_results_organisations_editRo_addAnother,
  "APPB2C.registration.results.organisations.editRo.error.contactPersonMaxLength": APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength,
  "APPB2C.registration.results.organisations.editRo.error.caseNumberMaxLength": APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength,
  "APPB2C.registration.results.organisations.editRo.error.orgNameMaxLength": APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength,
  "APPB2C.registration.results.organisations.editRo.error.minScoreMissing": APPB2C_registration_results_organisations_editRo_error_minScoreMissing,
  "APPB2C.registration.results.organisations.editRo.error.missingAddressLine1": APPB2C_registration_results_organisations_editRo_error_missingAddressLine1,
  "APPB2C.registration.results.organisations.editRo.error.missingOrgName": APPB2C_registration_results_organisations_editRo_error_missingOrgName,
  "APPB2C.registration.results.ttAbsent.msg": APPB2C_registration_results_ttAbsent_msg,
  "APPB2C.registration.results.ttAbsent.btn": APPB2C_registration_results_ttAbsent_btn,
  "APPB2C.registration.payments.paidByAgent": APPB2C_registration_payments_paidByAgent,
  "APPB2C.registration.payments.unpaid": APPB2C_registration_payments_unpaid,
  "APPB2C.registration.payments.awaitingPayment": APPB2C_registration_payments_awaitingPayment,
  "APPB2C.registration.payments.expired": APPB2C_registration_payments_expired,
  "APPB2C.registration.payments.completed": APPB2C_registration_payments_completed,
  "APPB2C.registration.payments.back": APPB2C_registration_payments_back,
  "APPB2C.registration.payments.transaction.header": APPB2C_registration_payments_transaction_header,
  "APPB2C.registration.payments.transaction.description.registration": APPB2C_registration_payments_transaction_description_registration,
  "APPB2C.registration.payments.transaction.description.transfer": APPB2C_registration_payments_transaction_description_transfer,
  "APPB2C.registration.payments.transaction.description.eor": APPB2C_registration_payments_transaction_description_eor,
  "APPB2C.registration.payments.transaction.description.appeal": APPB2C_registration_payments_transaction_description_appeal,
  "APPB2C.registration.payments.transaction.underReviewSuffix": APPB2C_registration_payments_transaction_underReviewSuffix,
  "APPB2C.registration.payments.transaction.unpaidSuffix": APPB2C_registration_payments_transaction_unpaidSuffix,
  "APPB2C.registration.payments.transaction.cancelledSuffix": APPB2C_registration_payments_transaction_cancelledSuffix,
  "APPB2C.registration.payments.transaction.hideDetails": APPB2C_registration_payments_transaction_hideDetails,
  "APPB2C.registration.payments.transaction.showDetails": APPB2C_registration_payments_transaction_showDetails,
  "APPB2C.registration.payments.transaction.feeLabel": APPB2C_registration_payments_transaction_feeLabel,
  "APPB2C.registration.payments.transaction.taxLabel": APPB2C_registration_payments_transaction_taxLabel,
  "APPB2C.registration.payments.transaction.totalLabel": APPB2C_registration_payments_transaction_totalLabel,
  "APPB2C.registration.payments.transaction.promoCode": APPB2C_registration_payments_transaction_promoCode,
  "APPB2C.registration.payments.transaction.referenceLabel": APPB2C_registration_payments_transaction_referenceLabel,
  "APPB2C.registration.payments.transaction.receipt": APPB2C_registration_payments_transaction_receipt,
  "APPB2C.registration.payments.transaction.receiptCta": APPB2C_registration_payments_transaction_receiptCta,
  "APPB2C.registration.payments.transaction.deadlineWarning.default": APPB2C_registration_payments_transaction_deadlineWarning_default,
  "APPB2C.registration.payments.transaction.deadlineWarning.registration": APPB2C_registration_payments_transaction_deadlineWarning_registration,
  "APPB2C.registration.payments.transaction.deadlineWarning.eor": APPB2C_registration_payments_transaction_deadlineWarning_eor,
  "APPB2C.registration.payments.transaction.pastDeadlineContactUs": APPB2C_registration_payments_transaction_pastDeadlineContactUs,
  "APPB2C.registration.payments.transaction.pastDeadlinePhone": APPB2C_registration_payments_transaction_pastDeadlinePhone,
  "APPB2C.registration.payments.transaction.pastDeadlineEmail": APPB2C_registration_payments_transaction_pastDeadlineEmail,
  "APPB2C.registration.payments.transaction.pastDeadline": APPB2C_registration_payments_transaction_pastDeadline,
  "APPB2C.registration.payments.transaction.termsLabel": APPB2C_registration_payments_transaction_termsLabel,
  "APPB2C.registration.payments.transaction.outdatedTermsLabel": APPB2C_registration_payments_transaction_outdatedTermsLabel,
  "APPB2C.registration.payments.finishPayment.processing": APPB2C_registration_payments_finishPayment_processing,
  "APPB2C.registration.payments.finishPayment.pleaseWait": APPB2C_registration_payments_finishPayment_pleaseWait,
  "APPB2C.registration.payments.finishPayment.success.title": APPB2C_registration_payments_finishPayment_success_title,
  "APPB2C.registration.payments.finishPayment.success.alert": APPB2C_registration_payments_finishPayment_success_alert,
  "APPB2C.registration.payments.finishPayment.success.message": APPB2C_registration_payments_finishPayment_success_message,
  "APPB2C.registration.payments.finishPayment.success.cta": APPB2C_registration_payments_finishPayment_success_cta,
  "APPB2C.registration.payments.finishPayment.failure.title": APPB2C_registration_payments_finishPayment_failure_title,
  "APPB2C.registration.payments.finishPayment.failure.alert": APPB2C_registration_payments_finishPayment_failure_alert,
  "APPB2C.registration.payments.finishPayment.failure.message": APPB2C_registration_payments_finishPayment_failure_message,
  "APPB2C.registration.payments.finishPayment.failure.cta": APPB2C_registration_payments_finishPayment_failure_cta,
  "APPB2C.registration.payments.finishPayment.error.title": APPB2C_registration_payments_finishPayment_error_title,
  "APPB2C.registration.payments.finishPayment.error.messageLine1": APPB2C_registration_payments_finishPayment_error_messageLine1,
  "APPB2C.registration.payments.finishPayment.error.messageLine2": APPB2C_registration_payments_finishPayment_error_messageLine2,
  "APPB2C.registration.payments.finishPayment.error.cta": APPB2C_registration_payments_finishPayment_error_cta,
  "APPB2C.registration.payments.actions.header": APPB2C_registration_payments_actions_header,
  "APPB2C.registration.payments.actions.payOnlineCTA": APPB2C_registration_payments_actions_payOnlineCTA,
  "APPB2C.registration.payments.actions.paymentInProgressInfo": APPB2C_registration_payments_actions_paymentInProgressInfo,
  "APPB2C.registration.payments.actions.payOfflineCTA": APPB2C_registration_payments_actions_payOfflineCTA,
  "APPB2C.registration.payments.actions.subHeader": APPB2C_registration_payments_actions_subHeader,
  "APPB2C.registration.payments.actions.uploadProofCTA": APPB2C_registration_payments_actions_uploadProofCTA,
  "APPB2C.registration.payments.actions.editProofCTA": APPB2C_registration_payments_actions_editProofCTA,
  "APPB2C.registration.payments.offlineDetails.payBy": APPB2C_registration_payments_offlineDetails_payBy,
  "APPB2C.registration.payments.offlineDetails.accountName": APPB2C_registration_payments_offlineDetails_accountName,
  "APPB2C.registration.payments.offlineDetails.accountNumber": APPB2C_registration_payments_offlineDetails_accountNumber,
  "APPB2C.registration.payments.offlineDetails.sortCode": APPB2C_registration_payments_offlineDetails_sortCode,
  "APPB2C.registration.payments.proofUpload.intro": APPB2C_registration_payments_proofUpload_intro,
  "APPB2C.registration.payments.proofUpload.info": APPB2C_registration_payments_proofUpload_info,
  "APPB2C.registration.payments.proofUpload.cta": APPB2C_registration_payments_proofUpload_cta,
  "APPB2C.registration.payments.proofUpload.rules.intro": APPB2C_registration_payments_proofUpload_rules_intro,
  "APPB2C.registration.payments.proofUpload.rules.rule1": APPB2C_registration_payments_proofUpload_rules_rule1,
  "APPB2C.registration.payments.proofUpload.rules.rule2": APPB2C_registration_payments_proofUpload_rules_rule2,
  "APPB2C.registration.payments.proofUpload.rules.rule3": APPB2C_registration_payments_proofUpload_rules_rule3,
  "APPB2C.registration.payments.proofUpload.rules.rule4": APPB2C_registration_payments_proofUpload_rules_rule4,
  "APPB2C.registration.payments.proofUpload.rules.rule5": APPB2C_registration_payments_proofUpload_rules_rule5,
  "APPB2C.registration.payments.proofPreview.proofLabel": APPB2C_registration_payments_proofPreview_proofLabel,
  "APPB2C.registration.payments.proofPreview.fileAlt": APPB2C_registration_payments_proofPreview_fileAlt,
  "APPB2C.registration.prepare.header": APPB2C_registration_prepare_header,
  "APPB2C.registration.prepare.intro": APPB2C_registration_prepare_intro,
  "APPB2C.registration.prepare.introLifeSkills": APPB2C_registration_prepare_introLifeSkills,
  "APPB2C.registration.prepare.rteLastMinute.header": APPB2C_registration_prepare_rteLastMinute_header,
  "APPB2C.registration.prepare.rteLastMinute.body.line1": APPB2C_registration_prepare_rteLastMinute_body_line1,
  "APPB2C.registration.prepare.rteLastMinute.body.item1": APPB2C_registration_prepare_rteLastMinute_body_item1,
  "APPB2C.registration.prepare.rteLastMinute.body.item2": APPB2C_registration_prepare_rteLastMinute_body_item2,
  "APPB2C.registration.prepare.rteLastMinute.body.item3": APPB2C_registration_prepare_rteLastMinute_body_item3,
  "APPB2C.registration.prepare.rteLastMinute.body.cta": APPB2C_registration_prepare_rteLastMinute_body_cta,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.line1": APPB2C_registration_prepare_rteLastMinute_ukvi_line1,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.cta": APPB2C_registration_prepare_rteLastMinute_ukvi_cta,
  "APPB2C.registration.prepare.rteTestDrive.header": APPB2C_registration_prepare_rteTestDrive_header,
  "APPB2C.registration.prepare.rteTestDrive.body.line1": APPB2C_registration_prepare_rteTestDrive_body_line1,
  "APPB2C.registration.prepare.rteTestDrive.body.item1": APPB2C_registration_prepare_rteTestDrive_body_item1,
  "APPB2C.registration.prepare.rteTestDrive.body.item2": APPB2C_registration_prepare_rteTestDrive_body_item2,
  "APPB2C.registration.prepare.rteTestDrive.body.item3": APPB2C_registration_prepare_rteTestDrive_body_item3,
  "APPB2C.registration.prepare.rteTestDrive.body.line2": APPB2C_registration_prepare_rteTestDrive_body_line2,
  "APPB2C.registration.prepare.rteTestDrive.body.cta": APPB2C_registration_prepare_rteTestDrive_body_cta,
  "APPB2C.registration.prepare.onlineCourse.header": APPB2C_registration_prepare_onlineCourse_header,
  "APPB2C.registration.prepare.onlineCourse.body.line1": APPB2C_registration_prepare_onlineCourse_body_line1,
  "APPB2C.registration.prepare.onlineCourse.body.cta": APPB2C_registration_prepare_onlineCourse_body_cta,
  "APPB2C.registration.prepare.tutor.header": APPB2C_registration_prepare_tutor_header,
  "APPB2C.registration.prepare.tutor.body.line1": APPB2C_registration_prepare_tutor_body_line1,
  "APPB2C.registration.prepare.tutor.body.line2": APPB2C_registration_prepare_tutor_body_line2,
  "APPB2C.registration.prepare.tutor.body.cta": APPB2C_registration_prepare_tutor_body_cta,
  "APPB2C.registration.prepare.prepApp.header": APPB2C_registration_prepare_prepApp_header,
  "APPB2C.registration.prepare.prepApp.body.line1": APPB2C_registration_prepare_prepApp_body_line1,
  "APPB2C.registration.prepare.prepApp.body.cta": APPB2C_registration_prepare_prepApp_body_cta,
  "APPB2C.registration.prepare.prepApp.ukvi.line1": APPB2C_registration_prepare_prepApp_ukvi_line1,
  "APPB2C.registration.prepare.prepApp.ukvi.line2": APPB2C_registration_prepare_prepApp_ukvi_line2,
  "APPB2C.registration.prepare.prepApp.ukvi.cta1": APPB2C_registration_prepare_prepApp_ukvi_cta1,
  "APPB2C.registration.prepare.prepApp.ukvi.cta2": APPB2C_registration_prepare_prepApp_ukvi_cta2,
  "APPB2C.registration.prepare.blog.header": APPB2C_registration_prepare_blog_header,
  "APPB2C.registration.prepare.blog.body.line1": APPB2C_registration_prepare_blog_body_line1,
  "APPB2C.registration.prepare.blog.body.cta": APPB2C_registration_prepare_blog_body_cta,
  "APPB2C.registration.prepare.facebook.header": APPB2C_registration_prepare_facebook_header,
  "APPB2C.registration.prepare.facebook.body.line1": APPB2C_registration_prepare_facebook_body_line1,
  "APPB2C.registration.prepare.facebook.body.cta": APPB2C_registration_prepare_facebook_body_cta,
  "APPB2C.registration.prepare.prepVideos.header": APPB2C_registration_prepare_prepVideos_header,
  "APPB2C.registration.prepare.prepVideos.body.line1": APPB2C_registration_prepare_prepVideos_body_line1,
  "APPB2C.registration.prepare.prepVideos.body.cta": APPB2C_registration_prepare_prepVideos_body_cta,
  "APPB2C.registration.prepare.speaking.header": APPB2C_registration_prepare_speaking_header,
  "APPB2C.registration.prepare.speaking.body.line1": APPB2C_registration_prepare_speaking_body_line1,
  "APPB2C.registration.prepare.speaking.body.cta": APPB2C_registration_prepare_speaking_body_cta,
  "APPB2C.registration.prepare.reading.header": APPB2C_registration_prepare_reading_header,
  "APPB2C.registration.prepare.reading.body.line1": APPB2C_registration_prepare_reading_body_line1,
  "APPB2C.registration.prepare.reading.body.cta": APPB2C_registration_prepare_reading_body_cta,
  "APPB2C.registration.prepare.cdTests.header": APPB2C_registration_prepare_cdTests_header,
  "APPB2C.registration.prepare.cdTests.body.line1": APPB2C_registration_prepare_cdTests_body_line1,
  "APPB2C.registration.prepare.cdTests.body.cta": APPB2C_registration_prepare_cdTests_body_cta,
  "APPB2C.registration.prepare.cdTipsVideos.header": APPB2C_registration_prepare_cdTipsVideos_header,
  "APPB2C.registration.prepare.cdTipsVideos.body.line1": APPB2C_registration_prepare_cdTipsVideos_body_line1,
  "APPB2C.registration.prepare.cdTipsVideos.body.cta": APPB2C_registration_prepare_cdTipsVideos_body_cta,
  "APPB2C.registration.prepare.cdFamiliarVideos.header": APPB2C_registration_prepare_cdFamiliarVideos_header,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.line1": APPB2C_registration_prepare_cdFamiliarVideos_body_line1,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.cta": APPB2C_registration_prepare_cdFamiliarVideos_body_cta,
  "APPB2C.registration.prepare.cdFamiliarTest.header": APPB2C_registration_prepare_cdFamiliarTest_header,
  "APPB2C.registration.prepare.cdFamiliarTest.body.line1": APPB2C_registration_prepare_cdFamiliarTest_body_line1,
  "APPB2C.registration.prepare.cdFamiliarTest.body.cta": APPB2C_registration_prepare_cdFamiliarTest_body_cta,
  "APPB2C.registration.prepare.pbPractice.header": APPB2C_registration_prepare_pbPractice_header,
  "APPB2C.registration.prepare.pbPractice.body.line1": APPB2C_registration_prepare_pbPractice_body_line1,
  "APPB2C.registration.prepare.pbPractice.body.cta": APPB2C_registration_prepare_pbPractice_body_cta,
  "APPB2C.registration.prepare.webinars.header": APPB2C_registration_prepare_webinars_header,
  "APPB2C.registration.prepare.webinars.body.line1": APPB2C_registration_prepare_webinars_body_line1,
  "APPB2C.registration.prepare.webinars.body.item1": APPB2C_registration_prepare_webinars_body_item1,
  "APPB2C.registration.prepare.webinars.body.item2": APPB2C_registration_prepare_webinars_body_item2,
  "APPB2C.registration.prepare.webinars.body.item3": APPB2C_registration_prepare_webinars_body_item3,
  "APPB2C.registration.prepare.webinars.body.cta": APPB2C_registration_prepare_webinars_body_cta,
  "APPB2C.registration.prepare.gel.ukvi.header": APPB2C_registration_prepare_gel_ukvi_header,
  "APPB2C.registration.prepare.gel.ukvi.body.line1": APPB2C_registration_prepare_gel_ukvi_body_line1,
  "APPB2C.registration.prepare.gel.ielts.header": APPB2C_registration_prepare_gel_ielts_header,
  "APPB2C.registration.prepare.gel.ielts.body.line1": APPB2C_registration_prepare_gel_ielts_body_line1,
  "APPB2C.registration.prepare.gel.ielts.body.cta": APPB2C_registration_prepare_gel_ielts_body_cta,
  "APPB2C.registration.prepare.gel.disabledMsg": APPB2C_registration_prepare_gel_disabledMsg,
  "APPB2C.registration.prepare.practice.header": APPB2C_registration_prepare_practice_header,
  "APPB2C.registration.prepare.practice.body.line1": APPB2C_registration_prepare_practice_body_line1,
  "APPB2C.registration.prepare.practice.body.cta": APPB2C_registration_prepare_practice_body_cta,
  "APPB2C.registration.prepare.a1.header": APPB2C_registration_prepare_a1_header,
  "APPB2C.registration.prepare.a1.cta": APPB2C_registration_prepare_a1_cta,
  "APPB2C.registration.prepare.a2.header": APPB2C_registration_prepare_a2_header,
  "APPB2C.registration.prepare.a2.cta": APPB2C_registration_prepare_a2_cta,
  "APPB2C.registration.prepare.b1.header": APPB2C_registration_prepare_b1_header,
  "APPB2C.registration.prepare.b1.cta": APPB2C_registration_prepare_b1_cta,
  "APPB2C.registration.prepare.ieltsReady.title": APPB2C_registration_prepare_ieltsReady_title,
  "APPB2C.registration.prepare.ieltsReady.subTitle": APPB2C_registration_prepare_ieltsReady_subTitle,
  "APPB2C.registration.prepare.ieltsReadyMember.header": APPB2C_registration_prepare_ieltsReadyMember_header,
  "APPB2C.registration.prepare.ieltsReadyMember.body": APPB2C_registration_prepare_ieltsReadyMember_body,
  "APPB2C.registration.prepare.ieltsReadyMember.link": APPB2C_registration_prepare_ieltsReadyMember_link,
  "APPB2C.registration.prepare.ieltsReadyMember.freePrepLink": APPB2C_registration_prepare_ieltsReadyMember_freePrepLink,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestInfo": APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestBtn": APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn,
  "APPB2C.registration.prepare.ieltsReadyMember.payBtn": APPB2C_registration_prepare_ieltsReadyMember_payBtn,
  "APPB2C.registration.prepare.ieltsReadyMember.unpaidTestInfo": APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo,
  "APPB2C.registration.prepare.ieltsReadyMember.label": APPB2C_registration_prepare_ieltsReadyMember_label,
  "APPB2C.registration.incorrectOrganisation.line1": APPB2C_registration_incorrectOrganisation_line1,
  "APPB2C.registration.incorrectOrganisation.line2": APPB2C_registration_incorrectOrganisation_line2,
  "APPB2C.registration.incorrectOrganisation.homeLink": APPB2C_registration_incorrectOrganisation_homeLink,
  "APPB2C.testCard.lrw": APPB2C_testCard_lrw,
  "APPB2C.testCard.ls": APPB2C_testCard_ls,
  "APPB2C.testCard.speaking": APPB2C_testCard_speaking,
  "APPB2C.testCard.cd": APPB2C_testCard_cd,
  "APPB2C.testCard.pb": APPB2C_testCard_pb,
  "APPB2C.testCard.f2f": APPB2C_testCard_f2f,
  "APPB2C.testCard.vcs": APPB2C_testCard_vcs,
  "APPB2C.testCard.changeSpeaking": APPB2C_testCard_changeSpeaking,
  "APPB2C.testCard.notLinkedVenue": APPB2C_testCard_notLinkedVenue,
  "APPB2C.testCard.speakingAddress": APPB2C_testCard_speakingAddress,
  "APPB2C.testCard.arrival": APPB2C_testCard_arrival,
  "APPB2C.testCard.mixedSlots": APPB2C_testCard_mixedSlots,
  "APPB2C.testCard.timeUnknown": APPB2C_testCard_timeUnknown,
  "APPB2C.testCard.book": APPB2C_testCard_book,
  "APPB2C.testCard.bookFor": APPB2C_testCard_bookFor,
  "APPB2C.testCard.review": APPB2C_testCard_review,
  "APPB2C.testCard.hideSpeakingTests": APPB2C_testCard_hideSpeakingTests,
  "APPB2C.testCard.showSpeakingTests": APPB2C_testCard_showSpeakingTests,
  "APPB2C.testCard.bookingPrice": APPB2C_testCard_bookingPrice,
  "APPB2C.testCard.bookExam": APPB2C_testCard_bookExam,
  "APPB2C.testCard.bookForFree": APPB2C_testCard_bookForFree
};

// src/locale/en-us/b2c.json
var APPB2C_auth_changePassword_cta2 = "Set Password";
var APPB2C_auth_changePassword_error2 = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_auth_changePassword_error_differentPasswords2 = "Entered passwords are different.";
var APPB2C_auth_changePassword_error_wrongIdNumber2 = "ID number is incorrect.";
var APPB2C_auth_changePassword_error_wrongOldPassword2 = "Old password is incorrect.";
var APPB2C_auth_changePassword_error_wrongPassword2 = "Password does not meet requirements.";
var APPB2C_auth_changePassword_id_label2 = "Passport number / National ID number";
var APPB2C_auth_changePassword_id_note2 = "To continue logging-in, add your Passport number / National ID number below.";
var APPB2C_auth_changePassword_id_warn2 = "If you keep seeing the \u2018ID number is incorrect\u2019 error while providing a correct one, please contact the British Council center.";
var APPB2C_auth_changePassword_pass_label2 = "Password";
var APPB2C_auth_changePassword_pass_note2 = "Please set up a password of your choice to continue.";
var APPB2C_auth_changePassword_title2 = "Set your new password";
var APPB2C_auth_forgetPassword_cta2 = "Email me a recovery link";
var APPB2C_auth_forgetPassword_email2 = "E-mail address";
var APPB2C_auth_forgetPassword_success_msg12 = "Thanks! If an account exists, an email will be sent with further instructions.";
var APPB2C_auth_forgetPassword_success_msg22 = " Please click the link when you get it.";
var APPB2C_auth_forgetPassword_success_title2 = "Success!";
var APPB2C_auth_forgetPassword_text2 = "Forgotten your password? No problem, we can send you a link to recover it.";
var APPB2C_auth_forgetPassword_title2 = "Recover password";
var APPB2C_auth_resetPassword_cta2 = "Reset password";
var APPB2C_auth_resetPassword_error_expiredToken2 = "Link to reset your password has expired. Please use \u2018Forgot Password\u2019 on login screen to receive new link.";
var APPB2C_auth_resetPassword_error_wrongPassword2 = "The password you entered does not meet the requirements.";
var APPB2C_auth_resetPassword_error_wrongToken2 = "Your temporary link for password reset is invalid.";
var APPB2C_auth_resetPassword_form_newPassword2 = "New password";
var APPB2C_auth_resetPassword_noToken_cta2 = "Recover your password";
var APPB2C_auth_resetPassword_noToken_msg2 = "Provided token was not recognized";
var APPB2C_auth_resetPassword_success_msg2 = "Your password has been successfully changed. You will be redirected shortly...";
var APPB2C_auth_resetPassword_success_title2 = "Success!";
var APPB2C_auth_resetPassword_title2 = "Reset your password";
var APPB2C_cj_basic_cta2 = "Save and continue";
var APPB2C_cj_basic_goToReview2 = "Save and Review";
var APPB2C_cj_basic_scrollLink2 = "View answers to check.";
var APPB2C_cj_basic_selectDate2 = "Select date";
var APPB2C_cj_basic_selectTime2 = "Select time";
var APPB2C_cj_basic_validationError2 = "Please check all the highlighted answers to continue.";
var APPB2C_cj_bookingComplete_feeDetails_discount2 = "Promotion";
var APPB2C_cj_bookingComplete_feeDetails_fee2 = "Fee";
var APPB2C_cj_bookingComplete_feeDetails_newFee2 = "New fee";
var APPB2C_cj_bookingComplete_feeDetails_promo2 = "Promo";
var APPB2C_cj_bookingComplete_feeDetails_reference2 = "Reference";
var APPB2C_cj_bookingComplete_feeDetails_tax2 = "Tax";
var APPB2C_cj_bookingComplete_feeDetails_title2 = "Fee details";
var APPB2C_cj_bookingComplete_feeDetails_total2 = "Total";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine12 = "The test fee has been discounted to";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine22 = "by using a promotional code.";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine32 = "You are not required to make any payment.";
var APPB2C_cj_bookingComplete_loadingPaymentMethodsError2 = "We cannot load available payment methods right now. Please try again later.";
var APPB2C_cj_bookingComplete_loadingRegistrationError2 = "We cannot load your registration details right now. Please try again later.";
var APPB2C_cj_bookingComplete_note2 = "Congratulations, your IELTS test is booked. We have sent you a confirmation of this booking to your email address (don\u2019t forget to check your spam folder!).";
var APPB2C_cj_bookingComplete_noteUolPassportInfo2 = "Your booking is subject to your passport being verified. We will contact you soon to do this.";
var APPB2C_cj_bookingComplete_offlinePayment_code2 = "Sort code";
var APPB2C_cj_bookingComplete_offlinePayment_description2 = "Payment description:";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line12 = "The test fee has been discounted to";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line22 = "To confirm your booking, please make a payment for the remaining fee";
var APPB2C_cj_bookingComplete_offlinePayment_fee2 = "Fee";
var APPB2C_cj_bookingComplete_offlinePayment_hideDescription2 = "Hide payment description";
var APPB2C_cj_bookingComplete_offlinePayment_incTax2 = "inc. tax";
var APPB2C_cj_bookingComplete_offlinePayment_name2 = "Account name";
var APPB2C_cj_bookingComplete_offlinePayment_note_line12 = "You now have {{days}} working day(s) to pay. Your test place will be cancelled if you do not pay within this time.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours2 = "You now have {{hours}} hours to pay. Your test place will be cancelled if you do not pay within this time.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft2 = "You have until {{date}} to pay. Your test place will be cancelled if you do not pay within this time.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line22 = "You will then need to log back in to this site to upload your proof of payment.";
var APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote2 = "Please note";
var APPB2C_cj_bookingComplete_offlinePayment_number2 = "Account number";
var APPB2C_cj_bookingComplete_offlinePayment_reference2 = "Payment reference";
var APPB2C_cj_bookingComplete_offlinePayment_showDescription2 = "Show payment description";
var APPB2C_cj_bookingComplete_otherPaymentOptions2 = "See other payment options";
var APPB2C_cj_bookingComplete_paymentFailed2 = "Sorry \u2013 your online payment failed! We\u2019ve still booked your test for you, but you need to try and pay again for your test.";
var APPB2C_cj_bookingComplete_paymentOptions2 = "See payment options";
var APPB2C_cj_bookingComplete_prepareNote_accesNote2 = "Free access to your IELTS study materials and resources.";
var APPB2C_cj_bookingComplete_prepareNote_counselling_note2 = "With our IELTS Counselling packages, you will get the chance to get the optimum support from our IELTS experts who will guide and advise you on how to best prepare for your test to achieve your desired score.";
var APPB2C_cj_bookingComplete_prepareNote_counselling_view2 = "View our counselling service";
var APPB2C_cj_bookingComplete_prepareNote_note2 = "The first step making sure you are ready to take IELTS is to prepare for it so you know what to expect on the test day.";
var APPB2C_cj_bookingComplete_prepareNote_title2 = "Prepare for your test";
var APPB2C_cj_bookingComplete_prepareNote_view2 = "View preparation materials";
var APPB2C_cj_bookingComplete_title2 = "Booking complete";
var APPB2C_cj_bookingComplete_tnc2 = "You agreed to these";
var APPB2C_cj_bookingComplete_tncLink2 = "terms and conditions";
var APPB2C_cj_bookingComplete_tryAgain2 = "Try payment again";
var APPB2C_cj_bookingComplete_yourBooking2 = "Your booking";
var APPB2C_cj_childDetails_about2 = "About your dependent";
var APPB2C_cj_childDetails_aboutNote2 = "Please add the details for the dependent taking the test:";
var APPB2C_cj_childDetails_error_missingFirstName2 = "Please enter your child\u2019s first / given names.";
var APPB2C_cj_childDetails_error_missingSurname2 = "Please enter your child\u2019s surname / family name. If they do not have a surname, please put a hyphen ('-') in this field.";
var APPB2C_cj_childDetails_error_wrongDob2 = "Please provide your child\u2019s actual date of birth.";
var APPB2C_cj_childDetails_forms_personal_idMatch2 = "This must match the name(s) on their identification document";
var APPB2C_cj_childDetails_noLastName2 = "They don\u2019t have a surname / family name";
var APPB2C_cj_childDetails_note2 = "Their full name and date of birth must match the identification document presented at the test location.";
var APPB2C_cj_childDetails_onTestDay2 = "On the test day";
var APPB2C_cj_childDetails_title2 = "Your dependent\u2019s details";
var APPB2C_cj_finishPayment_error2 = "We cannot process your payment right now. Please try again later.";
var APPB2C_cj_finishPayment_pleaseWait2 = "Please wait";
var APPB2C_cj_finishPayment_processing2 = "We are processing your payment...";
var APPB2C_cj_idDetails_confirm_change2 = "I need to change these details";
var APPB2C_cj_idDetails_confirm_confirm2 = "I confirm these details are correct";
var APPB2C_cj_idDetails_confirm_note12 = "It\u2019s really important that you make sure the $t({{idName}}) details you\u2019ve told us are correct. Please check them below before proceeding.";
var APPB2C_cj_idDetails_confirm_note22 = "On the test day, you will be required to bring this $t({{idName}}) with you.";
var APPB2C_cj_idDetails_confirm_title2 = "Please check your $t({{idName}}) before you continue";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_content2 = "If your ID details are wrong it may delay the release of your results.";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_title2 = "Please note:";
var APPB2C_cj_idDetails_error_missingIdExpiry2 = "Please provide the expiry date of the identification document.";
var APPB2C_cj_idDetails_error_missingIdNumber2 = "Please provide the identification document number. It needs to match specific format (allowed are letters, digits, and special chars: _\\-/,).";
var APPB2C_cj_idDetails_error_missingIdType2 = "Please provide the identification document type.";
var APPB2C_cj_idDetails_error_missingIssuingAuthority2 = "Please provide the issuing authority of the identification document.";
var APPB2C_cj_idDetails_error_wrongFilename2 = "File name contains invalid characters.";
var APPB2C_cj_idDetails_error_wrongIdExpiry2 = "Please provide the identification document that does not expire before chosen test date.";
var APPB2C_cj_idDetails_forms_changeNote2 = "You have changed the selected identification type. Please ensure the images uploaded below match the identification document you will bring on the test day.";
var APPB2C_cj_idDetails_forms_changeNote_child2 = "You have changed the selected identification type. Please ensure the images uploaded below match the identification document they will bring on the test day.";
var APPB2C_cj_idDetails_forms_documentWithNoExpiryDate2 = "The document doesn\u2019t have an expiry date";
var APPB2C_cj_idDetails_forms_expiryDate2 = "$t({{idName}}, capitalise) expiry date";
var APPB2C_cj_idDetails_forms_idNumber2 = "$t({{idName}}, capitalise) number";
var APPB2C_cj_idDetails_forms_idType2 = "Identification type";
var APPB2C_cj_idDetails_forms_issuingAuthority2 = "Issuing authority";
var APPB2C_cj_idDetails_idExpired2 = "The identification document you registered with before has now expired. Please add new identification.";
var APPB2C_cj_idDetails_iolMessage2 = "On the test day, you will be required to show the same identification document you are using for registration.";
var APPB2C_cj_idDetails_noteAboutIdentification2 = "On the test day, you will be required to bring the same identification document you are using for registration.";
var APPB2C_cj_idDetails_noteAboutIdentification_child2 = "On the test day, your dependent will be required to bring the same identification document you are using for registration.";
var APPB2C_cj_idDetails_noteAboutIdentificationIol2 = "A passport is currently the only acceptable ID used for IELTS Online. If you wish to use alternative acceptable IDs, you should take an IELTS on Paper test or IELTS on Computer test instead.";
var APPB2C_cj_idDetails_noteAboutIdentificationIolLink2 = "If you don\u2019t have a valid passport, use this link to book a different test instead.";
var APPB2C_cj_idDetails_noteAboutIdentificationUol2 = "A passport is currently the only acceptable ID used for IELTS for UKVI Online. If you wish to use alternative acceptable IDs, you should take an IELTS UKVI on Paper test or IELTS UKVI on Computer test instead.";
var APPB2C_cj_idDetails_title2 = "Identification details";
var APPB2C_cj_idDetails_title_child2 = "Your dependent\u2019s identification";
var APPB2C_cj_idDetails_typeNames_card2 = "identity card";
var APPB2C_cj_idDetails_typeNames_other2 = "ID";
var APPB2C_cj_idDetails_typeNames_passport2 = "passport";
var APPB2C_cj_idDetails_upload_fetchError2 = "We cannot process your file. Please try again later.";
var APPB2C_cj_idDetails_upload_file12 = "Image 1";
var APPB2C_cj_idDetails_upload_file22 = "Image 2 (optional)";
var APPB2C_cj_idDetails_upload_instructions_capture2 = "Capture your entire document";
var APPB2C_cj_idDetails_upload_instructions_covers2 = "Make sure nothing covers your document (fingers, stickers, paperclips etc.)";
var APPB2C_cj_idDetails_upload_instructions_directLight2 = "Move away from direct light to avoid glare.";
var APPB2C_cj_idDetails_upload_instructions_noBlur2 = "Make sure the photo / scan is in focus with no blur present.";
var APPB2C_cj_idDetails_upload_instructions_title2 = "How to take a suitable photo / scan:";
var APPB2C_cj_idDetails_upload_mobile2 = "As it looks like you\u2019re on a mobile device, we suggest you upload a photo from your device.";
var APPB2C_cj_idDetails_upload_passport_guidelines_cover2 = "Make sure you do not cover the passport data (finger, paperclip etc.).";
var APPB2C_cj_idDetails_upload_passport_guidelines_crop2 = "Place your passport on a flat surface and make sure that the data is visible.";
var APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions2 = "Follow the instructions below to make sure your photo meets our requirements.";
var APPB2C_cj_idDetails_upload_passport_guidelines_glare2 = "The photo needs to be clear with no reflections, make sure all the data is readable.";
var APPB2C_cj_idDetails_upload_passport_guidelines_watermark2 = "Make sure that image watermark is disabled in the camera settings.";
var APPB2C_cj_idDetails_upload_title2 = "Upload proof of $t({{idName}})";
var APPB2C_cj_ieltsReady_description_line12 = "Access a wealth of IELTS Ready study materials covering grammar, vocabulary, reading, listening, and more. Improve your skills for the IELTS test, whether studying solo or with expert guidance. Get the score you want with IELTS Ready.";
var APPB2C_cj_ieltsReady_description_line22 = "Best of luck!";
var APPB2C_cj_ieltsReady_dobMin2 = "You need to be over 18 years to create an account. You will need your parent or guardian to get access to IELTS Ready Member area.";
var APPB2C_cj_ieltsReady_nameWarning2 = "When you register for an IELTS test your name <bold>must match</bold> your official Identification Document, ensure it is correct now to save time later.";
var APPB2C_cj_ieltsReady_note2 = "You\u2019ll need an account to access the <0>IELTS Ready Member area.</0>";
var APPB2C_cj_ieltsReady_personalDetails_title = "About you";
var APPB2C_cj_ieltsReady_submitBtnText2 = "Create account";
var APPB2C_cj_ieltsReady_targetScore_option2 = "I don't know yet";
var APPB2C_cj_ieltsReady_targetScore_title2 = "What is your overall target score for your IELTS test?";
var APPB2C_cj_ieltsReady_title2 = "Create an account";
var APPB2C_cj_incorrectOrganisation_bookNewLink2 = "Book a test on this website (we\u2019ll help you find a test center)";
var APPB2C_cj_incorrectOrganisation_line12 = "It looks like you\u2019re trying to book a test with an organization we don\u2019t recognize.";
var APPB2C_cj_incorrectOrganisation_line22 = "Don\u2019t worry; there\u2019s a couple of things you can try:";
var APPB2C_cj_incorrectOrganisation_locationsLink2 = "Look at our global locations on the IELTS website";
var APPB2C_cj_incorrectOrganisation_locationsLinkUsa2 = "Look at all the test centers in the USA on the IELTS website";
var APPB2C_cj_incorrectOrganisation_title2 = "Sorry!";
var APPB2C_cj_marketingPrefs2 = "Your marketing preferences";
var APPB2C_cj_onRequest_created_back2 = "< Back to all UKVI test options";
var APPB2C_cj_onRequest_created_in2 = "in";
var APPB2C_cj_onRequest_created_info2 = "The information you provide will be reviewed and we will contact you to follow up on this request within 48-72 hours.";
var APPB2C_cj_onRequest_created_note2 = "We have registered your interest in sitting for IELTS for UKVI test in ";
var APPB2C_cj_onRequest_created_title2 = "Your request has been received";
var APPB2C_cj_onRequest_form_labels_confirmEmail2 = "Confirm email address";
var APPB2C_cj_onRequest_form_labels_email2 = "Your email";
var APPB2C_cj_onRequest_form_labels_firstname2 = "First / given names (including middle names)";
var APPB2C_cj_onRequest_form_labels_surname2 = "Surname / family name";
var APPB2C_cj_onRequest_form_labels_telephone2 = "Mobile number";
var APPB2C_cj_onRequest_form_labels_testRangeQuestion2 = "How soon would you like to take the test?";
var APPB2C_cj_onRequest_form_submit2 = "Register my interest";
var APPB2C_cj_onRequest_form_testRangeOpt_oneMonth2 = "in the next 28 days";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonths2 = "within the next 3 months";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus2 = "in more than 3 months";
var APPB2C_cj_onRequest_form_testRangeOpt_twoMonths2 = "within the next 2 months";
var APPB2C_cj_onRequest_info12 = "Complete the form to register your interest in sitting for an IELTS for UKVI test. The information you provide will be reviewed and we will contact you to follow up on this request.";
var APPB2C_cj_onRequest_note2 = "There are currently no IELTS for UKVI test dates available for this location.";
var APPB2C_cj_onRequest_otherLocations_choose2 = "Choose a different location";
var APPB2C_cj_onRequest_otherLocations_hide2 = "- Hide";
var APPB2C_cj_onRequest_otherLocations_info12 = "You have two options to help you book a test date:";
var APPB2C_cj_onRequest_otherLocations_info22 = "We have IELTS for UKVI tests in the following locations.";
var APPB2C_cj_onRequest_otherLocations_show2 = "+ Show more";
var APPB2C_cj_onRequest_please2 = "Please note";
var APPB2C_cj_onRequest_prefNote2 = "The British Council will make every attempt to accommodate your preferred choice. If your preferred choice is not available, you will be offered an alternative option.";
var APPB2C_cj_onRequest_subtitles_aboutPreferences2 = "About your test preferences";
var APPB2C_cj_onRequest_subtitles_aboutYou2 = "About you";
var APPB2C_cj_onRequest_subtitles_markpref2 = "Your marketing preferences";
var APPB2C_cj_onRequest_subtitles_register2 = "Register your interest for taking your test in";
var APPB2C_cj_personalDetails_error_doubleBooking2 = "The Test taker has already registered for an IELTS test on the same date.";
var APPB2C_cj_personalDetails_error_missingAddress2 = "Please provide your postal address.";
var APPB2C_cj_personalDetails_error_missingEmail2 = "Please enter a valid email address.";
var APPB2C_cj_personalDetails_error_missingEmailConfirm2 = "Please confirm your email address by re-entering it.";
var APPB2C_cj_personalDetails_error_missingFirstName2 = "Please enter your first / given names.";
var APPB2C_cj_personalDetails_error_missingMobile2 = "Please provide your mobile telephone number.";
var APPB2C_cj_personalDetails_error_missingSex2 = "Please provide your sex.";
var APPB2C_cj_personalDetails_error_missingState2 = "Please provide your state.";
var APPB2C_cj_personalDetails_error_missingSurname2 = "Please enter your surname / family name. If you do not have a surname, please put a hyphen ('-') in this field.";
var APPB2C_cj_personalDetails_error_missingTown2 = "Please provide your town / city.";
var APPB2C_cj_personalDetails_error_missingZip2 = "Please provide your postcode / ZIP.";
var APPB2C_cj_personalDetails_error_wrongDob2 = "Please provide your actual date of birth.";
var APPB2C_cj_personalDetails_error_wrongEmailConfirm2 = "The re-entered email address does not match the one you have provided. Please check your spelling carefully.";
var APPB2C_cj_personalDetails_examTakenModal_cta2 = "Find a new test";
var APPB2C_cj_personalDetails_examTakenModal_message2 = "Don\u2019t worry though, we\u2019ve got lots of other tests for you to choose from, and we won\u2019t forget any of the details you just told us.";
var APPB2C_cj_personalDetails_examTakenModal_title2 = "We\u2019re sorry \u2013 your chosen test isn\u2019t available anymore";
var APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse2 = "Book a test for someone else";
var APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate2 = "Choose a different test date";
var APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle2 = "Don\u2019t forget you already have a test booked on";
var APPB2C_cj_personalDetails_existingRegistrationsModal_title2 = "You already have a test booked on this date!";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking2 = "You can view your current booking in the Test Taker Portal.";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking2 = "View my current booking";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest2 = "Your test";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest2 = "Your Speaking test";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest2 = "Your Written test";
var APPB2C_cj_personalDetails_forms_address_country2 = "Country / territory of residence";
var APPB2C_cj_personalDetails_forms_address_enterManually2 = "Enter address manually";
var APPB2C_cj_personalDetails_forms_address_postal2 = "Postal address";
var APPB2C_cj_personalDetails_forms_address_state2 = "State";
var APPB2C_cj_personalDetails_forms_address_town2 = "Town / City";
var APPB2C_cj_personalDetails_forms_address_zip2 = "Postcode / ZIP";
var APPB2C_cj_personalDetails_forms_contact_confirmEmail2 = "Confirm email address";
var APPB2C_cj_personalDetails_forms_contact_email2 = "Your email";
var APPB2C_cj_personalDetails_forms_contact_emailExist2 = "This email address is already in use. If that was you, please try to log in instead.";
var APPB2C_cj_personalDetails_forms_contact_emailInUse2 = "This email address is already in use. If that was you, would you like to <0>log in</0> instead?";
var APPB2C_cj_personalDetails_forms_contact_mobile2 = "Mobile number";
var APPB2C_cj_personalDetails_forms_contact_note2 = "Please provide your phone number and postal address so we may contact you if needed and mail necessary documents (e.g. the test report form).";
var APPB2C_cj_personalDetails_forms_contact_password2 = "Password";
var APPB2C_cj_personalDetails_forms_contact_smsConsent2 = "I agree to receive notifications or to be contacted about my test registration to this telephone number via SMS, WhatsApp, etc.";
var APPB2C_cj_personalDetails_forms_contact_smsNote2 = "<bold>Please note:</bold> this service might not be available in your location.";
var APPB2C_cj_personalDetails_forms_contact_title2 = "Your contact details";
var APPB2C_cj_personalDetails_forms_personal_change2 = "Change";
var APPB2C_cj_personalDetails_forms_personal_dob2 = "Date of birth";
var APPB2C_cj_personalDetails_forms_personal_dobCta2 = "Book IELTS in a test center";
var APPB2C_cj_personalDetails_forms_personal_firstMiddleName2 = "First / given names (including middle names)";
var APPB2C_cj_personalDetails_forms_personal_firstName2 = "First / given names";
var APPB2C_cj_personalDetails_forms_personal_idMatch2 = "This must match the name(s) on your identification document";
var APPB2C_cj_personalDetails_forms_personal_lastName2 = "Surname / family name";
var APPB2C_cj_personalDetails_forms_personal_name2 = "Name";
var APPB2C_cj_personalDetails_forms_personal_noLastName2 = "I don\u2019t have a surname / family name";
var APPB2C_cj_personalDetails_forms_personal_sectionTitle2 = "About you";
var APPB2C_cj_personalDetails_forWho_child2 = "My dependent";
var APPB2C_cj_personalDetails_forWho_childUnder182 = "(under 18 years old)";
var APPB2C_cj_personalDetails_forWho_dob2 = "Date of birth: ";
var APPB2C_cj_personalDetails_forWho_myself2 = "Myself";
var APPB2C_cj_personalDetails_forWho_note2 = "You cannot book a test for a dependent under the age of 11. If your dependent is 18 or over, please ask them to register for themselves.";
var APPB2C_cj_personalDetails_forWho_title2 = "Who are you booking the test for?";
var APPB2C_cj_personalDetails_gender2 = "Sex:";
var APPB2C_cj_personalDetails_idNote_idMatch2 = "All personal details provided on this page must match the Test Taker's identification document used for registration and presented at the test location, as these will appear on the Test Taker's Test Report Form (TRF). Once registration is complete, you may be charged a fee if you ask us to modify any of this information before we issue the TRF. Please note that no modifications can be made after the TRF has been issued.";
var APPB2C_cj_personalDetails_idNote_idMatch_change2 = "The full name and date of birth <bold>must match</bold> the Identification Document you will present on test day.";
var APPB2C_cj_personalDetails_idNote_idMatch_france = "Please ensure all personal details match your identification document that you used for registration as these will appear on your Test Report Form (TRF). Once registration is complete you may be charged a fee to change your personal details. Note that no personal details can be changed after the results are released.";
var APPB2C_cj_personalDetails_idNote_idMatch_france_adult = "Please ensure all personal details match your identification document that you use for registration as these will appear on your Test Report Form (TRF). Once registration is complete you may be charged a fee to change your personal details. Note that no personal details can be changed after the results are released.";
var APPB2C_cj_personalDetails_idNote_idMatch_france_minor = "Please ensure all personal details match the minor's identification document that you use for registration as these will appear on their Test Report Form (TRF). Once registration is complete you may be charged a fee to change their personal details. Note that no personal details can be changed after the results are released.";
var APPB2C_cj_personalDetails_idNote_idMatch_spain = "Please ensure all personal details match your identification document that you used for registration as these will appear on your Test Report Form (TRF). Once registration is complete you will not be able to make any changes to your personal details.";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_adult = "Please ensure all personal details match your identification document that you use for registration as these will appear on your Test Report Form (TRF). Once registration is complete you will not be able to make any changes to your personal details.";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_minor = "Please ensure all personal details match the minor's identification document that you use for registration as these will appear on their Test Report Form (TRF). Once registration is complete you will not be able to make any changes to their personal details.";
var APPB2C_cj_personalDetails_idNote_whenForChild2 = "we\u2019ll ask you about your dependent on the next page";
var APPB2C_cj_personalDetails_login_btn2 = "Log in";
var APPB2C_cj_personalDetails_login_emailNotEditable2 = "You can\u2019t edit your email address now that you\u2019ve created an account. To change this, please <1>contact us</1>";
var APPB2C_cj_personalDetails_login_link2 = "Already have an account? <1>Log in</1>";
var APPB2C_cj_personalDetails_login_loggedIn2 = "Hi! You are now logged in.";
var APPB2C_cj_personalDetails_login_logout2 = "Not your details? <1>Logout</1>";
var APPB2C_cj_personalDetails_login_note2 = "You\u2019ll need an account to manage your booking. Once you\u2019ve booked a test, you\u2019ll receive an email explaining how to access your booking online.";
var APPB2C_cj_personalDetails_login_title2 = "Create an account";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate2 = "Find a new test";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line12 = "You cannot book an IELTS Online test so close to your other test on {{nearestRegistratonExamDate}}.";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line22 = "Please find a test date 5 or more days earlier or later than your previous test.";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title2 = "Please choose a different test date";
var APPB2C_cj_personalDetails_title2 = "Personal details";
var APPB2C_cj_personalDetails_tncs_agreement2 = "I agree to the account registration <1>terms and conditions</1>";
var APPB2C_cj_personalDetails_tncs_title2 = "Our terms and conditions";
var APPB2C_cj_personalDetails_visaType_label2 = "What visa type do you need?";
var APPB2C_cj_personalDetails_visaType_label_child2 = "What visa type do they need?";
var APPB2C_cj_personalDetails_visaType_link2 = "Learn more about the visa types";
var APPB2C_cj_personalDetails_visaType_title2 = "Your visa type";
var APPB2C_cj_personalDetails_visaType_title_child2 = "Their visa type";
var APPB2C_cj_rebook_errorModal_message2 = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_cj_rebook_errorModal_title2 = "Booking reservation failed";
var APPB2C_cj_review_acknowledgement_anotherPerson2 = "Another Person";
var APPB2C_cj_review_acknowledgement_company2 = "A Company";
var APPB2C_cj_review_acknowledgement_fields_companyName2 = "Company name";
var APPB2C_cj_review_acknowledgement_fields_companyName_subnote2 = "use the original spelling of company name";
var APPB2C_cj_review_acknowledgement_fields_country2 = "Country / territory";
var APPB2C_cj_review_acknowledgement_fields_email2 = "Email";
var APPB2C_cj_review_acknowledgement_fields_familyName2 = "Family name";
var APPB2C_cj_review_acknowledgement_fields_firstName2 = "Other / given names";
var APPB2C_cj_review_acknowledgement_fields_mobile2 = "Telephone number";
var APPB2C_cj_review_acknowledgement_myself2 = "Myself";
var APPB2C_cj_review_acknowledgement_send2 = "Who should receive the order confirmation for the test booking?";
var APPB2C_cj_review_acknowledgement_title2 = "Order acknowledgement";
var APPB2C_cj_review_agent_label2 = "Please provide the name of your agent";
var APPB2C_cj_review_agent_note2 = "Do you have an agent who is acting on your behalf with your UKVI application?";
var APPB2C_cj_review_agent_title2 = "Agent name";
var APPB2C_cj_review_ai_note2 = "Would you be happy for anonymised test answers and test recordings to be used to train the remote proctoring and test marking software?";
var APPB2C_cj_review_ai_title2 = "Training consent";
var APPB2C_cj_review_bookingDetails_lrw_title2 = "Your Written test";
var APPB2C_cj_review_bookingDetails_lrw_title_child2 = "Your dependent\u2019s Written test";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills2 = "Your test";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child2 = "Your dependent\u2019s test";
var APPB2C_cj_review_bookingDetails_speaking_title2 = "Your Speaking test";
var APPB2C_cj_review_bookingDetails_speaking_title_child2 = "Your dependent\u2019s Speaking test";
var APPB2C_cj_review_bookingDetails_title2 = "You are booking";
var APPB2C_cj_review_bookNowNoFee_buttonText2 = "Book now";
var APPB2C_cj_review_bookTest2 = "Book test";
var APPB2C_cj_review_change2 = "Change";
var APPB2C_cj_review_changeLrwTest2 = "Change written test date";
var APPB2C_cj_review_changeSpeakingTest2 = "Change speaking schedule";
var APPB2C_cj_review_changeUkviLsTest2 = "Change test date";
var APPB2C_cj_review_childDetails_guardiansTitle2 = "Person(s) who will collect your dependent";
var APPB2C_cj_review_childDetails_onTestDay2 = "On the test day";
var APPB2C_cj_review_childDetails_onTestDayOptions_12 = "Dependent can leave the test premises on their own";
var APPB2C_cj_review_childDetails_onTestDayOptions_22 = "I will pick my dependent up from the test premises";
var APPB2C_cj_review_childDetails_onTestDayOptions_32 = "Someone else will pick my dependent up from the test premises";
var APPB2C_cj_review_childDetails_title2 = "Your dependent\u2019s details";
var APPB2C_cj_review_confirmPaymentModal_btnNote2 = "You may pay more than the <0></0> listed";
var APPB2C_cj_review_confirmPaymentModal_note2 = "Please note: Additional charges may apply from your bank for your online payment for the IELTS exam.";
var APPB2C_cj_review_confirmPaymentModal_optionsTitle2 = "You have two options.";
var APPB2C_cj_review_confirmPaymentModal_title2 = "Paying online may cost you more";
var APPB2C_cj_review_countryMsg_pakistan2 = "The IELTS fees paid with a credit card may be different from the mentioned price due to fluctuating exchange rates. Credit card transactions conducted in Pakistani Rupee at online or international merchants are settled by the merchant in a foreign currency.";
var APPB2C_cj_review_error_missingOrderAcknowledgement2 = "Please specify who should receive the order confirmation for the test booking.";
var APPB2C_cj_review_error_missingPickups2 = "Please specify who will pick your child up from the test premises.";
var APPB2C_cj_review_error_missingTown2 = "Please change your details and include town / city in your address.";
var APPB2C_cj_review_error_wrongCountrySetting2 = "Booking this test is not available in your country. Please select a different test.";
var APPB2C_cj_review_error_wrongPaymentConfig2 = "Payment configuration is missing or incorrect, please contact the test centre if this issue persists.";
var APPB2C_cj_review_error_wrongTaxNumber2 = "Please provide a valid VAT/NIP number. It has to match specific format (e.g. 13243546-3-35, 1324354635)";
var APPB2C_cj_review_expiredIdError2 = "The Test taker's ID must not expire before the chosen test date.";
var APPB2C_cj_review_idDetails_issuing2 = "Issuing authority";
var APPB2C_cj_review_idDetails_nationality2 = "Country / territory of nationality";
var APPB2C_cj_review_idDetails_title2 = "Your identification";
var APPB2C_cj_review_idDetails_title_child2 = "Your dependent\u2019s identification";
var APPB2C_cj_review_invoice_companyName2 = "Invoice header";
var APPB2C_cj_review_invoice_consent2 = "Do you consent to British Council (Taiwan) Limited handling invoice and proof of product return on your behalf for refund of purchased items in order to speed up the refund process?";
var APPB2C_cj_review_invoice_send2 = "What type of invoice would you like us to issue?";
var APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate2 = "Citizen Digital Certificate";
var APPB2C_cj_review_invoice_subtype_label2 = "Which carrier would you like to use?";
var APPB2C_cj_review_invoice_subtype_mobileBarcode2 = "Mobile Barcode";
var APPB2C_cj_review_invoice_taxCaption_donate2 = "Charitable organisation number";
var APPB2C_cj_review_invoice_taxCaption_duplicatePaper2 = "Invoice number";
var APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate2 = "Citizen Digital Certificate has to match the correct format of 16 alphanumeric characters (eg 1122AABB3344CCDD)";
var APPB2C_cj_review_invoice_taxValidation_donate2 = "Charitable organisation number has to match 3-7 digits specific format (eg 123)";
var APPB2C_cj_review_invoice_taxValidation_mobileBarcode2 = "Mobile Barcode has to match the correct format (eg /123-ABC)";
var APPB2C_cj_review_invoice_taxValidation_triplicate2 = "Invoice number has to match 8 digits specific format (eg 11223344)";
var APPB2C_cj_review_invoice_title2 = "Invoice information";
var APPB2C_cj_review_invoice_type_donate2 = "Donate Electronic Invoice";
var APPB2C_cj_review_invoice_type_duplicateElectronic2 = "Duplicate Uniform Electronic Invoice";
var APPB2C_cj_review_invoice_type_duplicatePaper2 = "Duplicate Uniform Paper Invoice";
var APPB2C_cj_review_invoice_type_triplicate2 = "Triplicate Uniform Paper Invoice";
var APPB2C_cj_review_localization_localTime2 = "Your local time";
var APPB2C_cj_review_localization_location2 = "We localised you in";
var APPB2C_cj_review_localization_timezone2 = "Your timezone";
var APPB2C_cj_review_missingMarketingCta2 = "Check your answers";
var APPB2C_cj_review_missingMarketingError2 = "It looks like you've tried to book a test with us before, and now we just need to check a few answers with you again.";
var APPB2C_cj_review_note_content2 = "Please check all the details carefully before you book.";
var APPB2C_cj_review_note_title2 = "You have not booked yet!";
var APPB2C_cj_review_or2 = "or";
var APPB2C_cj_review_payLater_buttonText2 = "Offline payment";
var APPB2C_cj_review_payLater_sideNote2 = "Payment options will be shown on next page";
var APPB2C_cj_review_payment_fee2 = "Fee";
var APPB2C_cj_review_payment_promoCodes_applied2 = "Promo code applied";
var APPB2C_cj_review_payment_promoCodes_applyCode2 = "Apply code";
var APPB2C_cj_review_payment_promoCodes_discount2 = "Promotion";
var APPB2C_cj_review_payment_promoCodes_generalError2 = "An error occurred \u2013 please try again.";
var APPB2C_cj_review_payment_promoCodes_haveCode2 = "I have a promo code";
var APPB2C_cj_review_payment_promoCodes_invalidCode2 = "The promo code you entered is not valid";
var APPB2C_cj_review_payment_promoCodes_newFee2 = "New fee";
var APPB2C_cj_review_payment_promoCodes_notHaveCode2 = "I don\u2019t have a promo code";
var APPB2C_cj_review_payment_promoCodes_promo2 = "Promo";
var APPB2C_cj_review_payment_promoCodes_promoCode2 = "Promo code";
var APPB2C_cj_review_payment_promoCodes_remove2 = "Remove";
var APPB2C_cj_review_payment_promoCodes_removeCode2 = "remove promo code";
var APPB2C_cj_review_payment_promoCodes_total2 = "Total";
var APPB2C_cj_review_payment_tax2 = "Tax";
var APPB2C_cj_review_payment_title2 = "Payment";
var APPB2C_cj_review_payment_total2 = "Total";
var APPB2C_cj_review_paymentError2 = "We cannot process your payment. Please try again later.";
var APPB2C_cj_review_paymentNote2 = "Book & pay:";
var APPB2C_cj_review_paymentNoteMultiple = "You are now ready to book your test:";
var APPB2C_cj_review_paymentNoteSingle = " Once you select the option below, we will book your test for you.";
var APPB2C_cj_review_paymentNoteZeroFee2 = "Get your free test";
var APPB2C_cj_review_payOnline_buttonText2 = "Pay online";
var APPB2C_cj_review_payOnline_sideNote2 = "Recommended payment method";
var APPB2C_cj_review_personalDetails_address2 = "Address";
var APPB2C_cj_review_personalDetails_dob2 = "Date of Birth";
var APPB2C_cj_review_personalDetails_email2 = "Email address";
var APPB2C_cj_review_personalDetails_gender2 = "Sex";
var APPB2C_cj_review_personalDetails_name2 = "Name";
var APPB2C_cj_review_personalDetails_phone2 = "Mobile telephone number";
var APPB2C_cj_review_personalDetails_smsNotificationsConsent2 = "I want to receive updates about my tests and results to this mobile telephone number";
var APPB2C_cj_review_personalDetails_title2 = "Your details";
var APPB2C_cj_review_registrationError2 = "We cannot create registration right now. Please try again later.";
var APPB2C_cj_review_registrationFailedModal_changedFee_btn2 = "Show new prices";
var APPB2C_cj_review_registrationFailedModal_changedFee_line12 = "We inform you that the prices of some test have changed.";
var APPB2C_cj_review_registrationFailedModal_changedFee_line22 = "Please review the new prices and re-select the test.";
var APPB2C_cj_review_registrationFailedModal_changedFee_line32 = "We apologize for any inconvenience.";
var APPB2C_cj_review_registrationFailedModal_changedFee_title2 = "Test prices have changed";
var APPB2C_cj_review_registrationFailedModal_cta2 = "Restart booking";
var APPB2C_cj_review_registrationFailedModal_message2 = "Oooops, something went wrong. Please try again.";
var APPB2C_cj_review_registrationFailedModal_title2 = "Restart your booking";
var APPB2C_cj_review_taxNumberError2 = "Please provide valid VAT/NIP number, it has to match specific format (e.g. 13243546-3-35, 1324354635).";
var APPB2C_cj_review_title2 = "Review";
var APPB2C_cj_review_tncs_agreement2 = "I agree to the IELTS <0>terms and conditions</0> and <2>cancellation policy</2>";
var APPB2C_cj_review_tncs_title2 = "Our terms and conditions";
var APPB2C_cj_review_tooHighPromoCodeError2 = "We couldn't complete your registration. Please attempt registration without using the promo code. If you believe the code should work, please contact your centre for help";
var APPB2C_cj_timer_modal_cta2 = "Restart booking";
var APPB2C_cj_timer_modal_message2 = "Sorry; you\u2019ve run out of time to complete this booking.";
var APPB2C_cj_timer_modal_title2 = "Restart your booking";
var APPB2C_cj_timer_nearEnd_minuteCount2 = "{{count}} minute";
var APPB2C_cj_timer_nearEnd_minuteCount_other2 = "{{count}} minutes";
var APPB2C_cj_timer_nearEnd_text2 = 'Your chosen test is only being held for another $t(APPB2C.cj.timer.nearEnd.minuteCount, {"count": {{count}} }).';
var APPB2C_cj_timer_nearEnd_title2 = "Attention!";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied2 = "Which country / territory do you want to study / work / live in?";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child2 = "Which country / territory do they want to study / work / live in?";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest2 = "Why are you taking the test?";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child2 = "Why are they taking the test?";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle2 = "Your interest in IELTS";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle_child2 = "Their interest in IELTS";
var APPB2C_cj_ttProfile_forms_occupation_occupationLevel2 = "What is your occupation level?";
var APPB2C_cj_ttProfile_forms_occupation_occupationSector2 = "What is your occupation sector?";
var APPB2C_cj_ttProfile_forms_occupation_sectionTitle2 = "Your occupation";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality2 = "What is your country / territory of nationality?";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child2 = "What is their country / territory of nationality?";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3842 = "Secondary (up to 16 years)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3852 = "Secondary (16-19 years)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3862 = "Degree (or equivalent)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3872 = "Post-graduate";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_title2 = "What level of education have you completed?";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken2 = "What is your first language?";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child2 = "What is their first language?";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle2 = "About you";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle_child2 = "About your dependent";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish2 = "How many years have you been studying English?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child2 = "How many years have they been studying English?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel2 = "Your answer to this question has no impact on your test score";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child2 = "Your answer to this question has no impact on their test score";
var APPB2C_cj_ttProfile_note2 = "Answering these questions has no impact on your IELTS test results. These questions will help us improve our services to test takers like you.";
var APPB2C_cj_ttProfile_note_child2 = "Answering these questions has no impact on your dependent\u2019s IELTS test results. These questions will help us improve our services to test takers like them.";
var APPB2C_cj_ttProfile_title2 = "Your profile";
var APPB2C_cj_ttProfile_title_child2 = "Your dependent\u2019s profile";
var APPB2C_cj_ukviStartPage_ac_content2 = "This test is for test takers wishing to study at undergraduate or postgraduate levels, and for those seeking professional registration in the UK.";
var APPB2C_cj_ukviStartPage_ac_title2 = "IELTS for UKVI Academic";
var APPB2C_cj_ukviStartPage_gt_content2 = "This test is for test takers wishing to migrate to the UK and for those wishing to train or study below degree level.";
var APPB2C_cj_ukviStartPage_gt_title2 = "IELTS for UKVI General Training";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_content2 = "This test is for those who need to prove their English speaking and listening skills as part of their application to UK Visas and Immigration for 'family of a settled person' visas.";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_title2 = "IELTS Life Skills A1";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_content2 = "This test is for those who need to prove their English speaking and listening skills as part of their application to UK Visas and Immigration for extension to Family, Spouse or Partner visa.";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_title2 = "IELTS Life Skills A2 (UK only)";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_content2 = "This test is for those who need to prove their English speaking and listening skills as part of their application to UK Visas and Immigration for indefinite leave to remain or citizenship.";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_title2 = "IELTS Life Skills B1";
var APPB2C_cj_ukviStartPage_lifeSkils_citizenship2 = "indefinite leave to remain or citizenship.";
var APPB2C_cj_ukviStartPage_lifeSkils_extension2 = "extension to family, spouse or partner visa";
var APPB2C_cj_ukviStartPage_lifeSkils_family2 = "family visa";
var APPB2C_cj_ukviStartPage_lifeSkils_note_content2 = "If you have taken an IELTS Life Skills test within the last 2 years and passed it, you should not take the exact same test.";
var APPB2C_cj_ukviStartPage_lifeSkils_note_title2 = "Please note:";
var APPB2C_cj_ukviStartPage_lifeSkils_title2 = "IELTS Life Skills";
var APPB2C_cj_ukviStartPage_lifeSkils_visaTypes2 = "This is the test to take if you are applying for the following types of UK visa:";
var APPB2C_cj_ukviStartPage_note1_content2 = "If you are NOT booking an IELTS test to support a UK Visas and Immigration application, <0>please see the other IELTS tests we offer</0>.";
var APPB2C_cj_ukviStartPage_note1_title2 = "Please note:";
var APPB2C_cj_ukviStartPage_terms2 = "Before you proceed, please read the <0>IELTS for UK Visas Terms and Conditions</0>.";
var APPB2C_cj_ukviStartPage_testsBelow2 = "The IELTS tests below have been approved for UK Visas and Immigration purposes:";
var APPB2C_cj_ukviStartPage_title2 = "IELTS for UK Visas";
var APPB2C_cj_ukviStartPage_whichUkvi2 = "Which IELTS for UK Visas test is right for me?";
var APPB2C_common_account_details_email_cancel = "Cancel";
var APPB2C_common_account_details_email_confirm2 = "Confirm email";
var APPB2C_common_account_details_email_label2 = "Email";
var APPB2C_common_account_details_email_maxLength2 = "Your email is too long, max length is 60.";
var APPB2C_common_account_details_email_mismatch2 = "This field needs to match the Email field above exactly.";
var APPB2C_common_account_details_email_note2 = "The email address you will provide must be valid and you need to have access to it. All correspondence regarding your account and registration will be sent to this email address. From now on, you will be able to log into the portal using username or email address.";
var APPB2C_common_account_details_email_save2 = "Save email";
var APPB2C_common_account_details_email_set2 = "Provide email";
var APPB2C_common_account_details_email_success2 = "Your email has been saved.";
var APPB2C_common_account_details_email_username2 = "Username";
var APPB2C_common_account_details_email_validEmail2 = "Please specify a valid email address.";
var APPB2C_common_account_details_name2 = "Your name";
var APPB2C_common_account_details_psw_cancel = "Cancel";
var APPB2C_common_account_details_psw_change2 = "Change password";
var APPB2C_common_account_details_psw_label2 = "Password";
var APPB2C_common_account_details_psw_new2 = "New password";
var APPB2C_common_account_details_psw_old2 = "Old password";
var APPB2C_common_account_details_psw_requirements_levels_02 = "Very Weak";
var APPB2C_common_account_details_psw_requirements_levels_12 = "Weak";
var APPB2C_common_account_details_psw_requirements_levels_22 = "Medium";
var APPB2C_common_account_details_psw_requirements_levels_32 = "Strong";
var APPB2C_common_account_details_psw_requirements_levels_42 = "Very Strong";
var APPB2C_common_account_details_psw_requirements_maxLength2 = "Your password is too long, max length is 100.";
var APPB2C_common_account_details_psw_requirements_mustHave2 = "Your password must have:";
var APPB2C_common_account_details_psw_requirements_ok2 = "Your password meets requirements";
var APPB2C_common_account_details_psw_requirements_reqs_length2 = "8 or more characters";
var APPB2C_common_account_details_psw_requirements_reqs_lower2 = "Lowercase letters";
var APPB2C_common_account_details_psw_requirements_reqs_number2 = "At least one number";
var APPB2C_common_account_details_psw_requirements_reqs_special2 = "At least one special character";
var APPB2C_common_account_details_psw_requirements_reqs_upper2 = "Uppercase letters";
var APPB2C_common_account_details_psw_requirements_shouldHave2 = "And it needs to meet 3 of the following requirements:";
var APPB2C_common_account_details_psw_requirements_strength2 = "Strength";
var APPB2C_common_account_details_psw_requirements_tooWeak2 = "Your password is too weak.";
var APPB2C_common_account_details_psw_show2 = "Show password";
var APPB2C_common_account_details_psw_success2 = "Your password has been changed.";
var APPB2C_common_account_details_subnote_address2 = "use the original spelling of address";
var APPB2C_common_account_details_subnote_city2 = "use the original spelling of town / city";
var APPB2C_common_account_error2 = "Oooops, something went wrong. Please try again in a few moments.";
var APPB2C_common_account_marketing_success2 = "Your marketing preferences have been updated successfully.";
var APPB2C_common_account_marketing_title2 = "Your marketing preferences";
var APPB2C_common_account_smsNotificationSettings_mobileLabel2 = "SMS messages will be sent to:";
var APPB2C_common_account_smsNotificationSettings_success2 = "Your SMS notifications setting has been updated successfully.";
var APPB2C_common_account_smsNotificationSettings_title2 = "SMS notifications setting";
var APPB2C_common_account_terms_cta2 = "Download T&Cs";
var APPB2C_common_account_terms_disclaimer2 = "The British Council will use the information that you are providing in connection with processing your registration. The legal basis for processing your information is agreement with our terms and conditions of registration (contract).";
var APPB2C_common_account_terms_download2 = "You can download your agreed Terms & Conditions version:";
var APPB2C_common_account_terms_extra_line12 = "The British Council complies with data protection law in the UK and laws in other countries that meet internationally accepted standards.";
var APPB2C_common_account_terms_extra_line22 = "You have the right to ask for a copy of the information we hold on you, and the right to ask us to correct any inaccuracies in that information. If you have concerns about how we have used your personal information, you also have the right to complain to a privacy regulator.";
var APPB2C_common_account_terms_extra_line3_12 = "For detailed information, please refer to the privacy section of our website,";
var APPB2C_common_account_terms_extra_line3_22 = " or contact your local British Council office. We will keep your information for a period of 3 years from the time of collection for your registration; for marketing purposes, we will keep your information for a period of 7 years.";
var APPB2C_common_account_terms_extra_title2 = "Data Protection";
var APPB2C_common_account_terms_previous2 = "You can find the link to the previous Terms & Conditions version here: <0>terms and conditions</0>";
var APPB2C_common_account_terms_title2 = "You agreed to the account registration <0>terms and conditions</0>";
var APPB2C_common_account_title2 = "My account";
var APPB2C_common_alertBar_afterTest2 = "You still need to:";
var APPB2C_common_alertBar_beforeTest2 = "Before the test day, you need to:";
var APPB2C_common_alertBar_cancelReupload2 = "Upload cancellation documents again";
var APPB2C_common_alertBar_check2 = "Take your pre-test check";
var APPB2C_common_alertBar_childConsentMissing2 = "Provide child consent information";
var APPB2C_common_alertBar_creds2 = "View my log-in details";
var APPB2C_common_alertBar_eorPayment2 = "Pay for your EOR";
var APPB2C_common_alertBar_eorPaymentProof2 = "Upload proof of payment for your EOR";
var APPB2C_common_alertBar_idReupload2 = "Upload identification documents again";
var APPB2C_common_alertBar_idUpload2 = "Upload identification documents";
var APPB2C_common_alertBar_medicalReupload2 = "Upload medical evidence again";
var APPB2C_common_alertBar_medicalUpload2 = "Upload medical evidence";
var APPB2C_common_alertBar_regPayment2 = "Pay for your test";
var APPB2C_common_alertBar_regPaymentProof2 = "Upload proof of payment for your test";
var APPB2C_common_alertBar_withCreds2 = "You can now log in to your test:";
var APPB2C_common_basic_add2 = "Add";
var APPB2C_common_basic_backToTop2 = "Back to top";
var APPB2C_common_basic_bookingDetails_arrival2 = "(please arrive at {{time}})";
var APPB2C_common_basic_bookingDetails_lifeSkills_results2 = "Listening & Speaking";
var APPB2C_common_basic_bookingDetails_lifeSkills_title2 = "Your test";
var APPB2C_common_basic_bookingDetails_lrw_cd2 = "IELTS on computer at test center";
var APPB2C_common_basic_bookingDetails_lrw_online2 = "Online";
var APPB2C_common_basic_bookingDetails_lrw_pb2 = "IELTS on paper";
var APPB2C_common_basic_bookingDetails_lrw_results2 = "Listening, Reading & Writing";
var APPB2C_common_basic_bookingDetails_lrw_title2 = "Your Written test";
var APPB2C_common_basic_bookingDetails_osrTitle_listening2 = "Your Listening test";
var APPB2C_common_basic_bookingDetails_osrTitle_reading2 = "Your Reading test";
var APPB2C_common_basic_bookingDetails_speaking_f2f2 = "Face to face";
var APPB2C_common_basic_bookingDetails_speaking_live2 = "Face to face";
var APPB2C_common_basic_bookingDetails_speaking_online2 = "Online";
var APPB2C_common_basic_bookingDetails_speaking_remote2 = "Video call at the test center";
var APPB2C_common_basic_bookingDetails_speaking_results2 = "Speaking";
var APPB2C_common_basic_bookingDetails_speaking_title2 = "Your Speaking test";
var APPB2C_common_basic_bookPrePaid2 = "Book pre-paid test";
var APPB2C_common_basic_cancel2 = "Cancel";
var APPB2C_common_basic_change2 = "Change";
var APPB2C_common_basic_choose2 = "Choose";
var APPB2C_common_basic_close2 = "Close";
var APPB2C_common_basic_copied2 = "Copied";
var APPB2C_common_basic_copy2 = "Copy";
var APPB2C_common_basic_dateField_day2 = "Day";
var APPB2C_common_basic_dateField_month2 = "Month";
var APPB2C_common_basic_dateField_months2 = "Choose month";
var APPB2C_common_basic_dateField_year2 = "Year";
var APPB2C_common_basic_edit2 = "Edit";
var APPB2C_common_basic_errors_contactTestCentre2 = "Sorry, there has been a problem. Please contact your local center to request a transfer.";
var APPB2C_common_basic_errors_network2 = "Possibly you\u2019ve lost connection to the Internet. Please try to reconnect and refresh the page.";
var APPB2C_common_basic_errors_noTest2 = "This test isn\u2019t available anymore. Please select another test instead.";
var APPB2C_common_basic_errors_server2 = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_common_basic_errors_title2 = "Oooops!";
var APPB2C_common_basic_errors_unableToDownloadFile2 = "We\u2019ve got your files, but we\u2019re having problems displaying them.";
var APPB2C_common_basic_errors_unknown2 = "It looks like something went wrong. Please try again in a few minutes.";
var APPB2C_common_basic_expando_collapse2 = "- Hide full notice";
var APPB2C_common_basic_expando_show2 = "+ Read full notice";
var APPB2C_common_basic_externalLinkTitle2 = "(opens new window)";
var APPB2C_common_basic_failure2 = "Failure";
var APPB2C_common_basic_forms_addressMaxLength2 = "Address can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_dobMin2 = "You need to be over 18 years old to create an account. You will need your parent or guardian to book the test for you.";
var APPB2C_common_basic_forms_dobMinIol2 = "Sorry \u2013 you have to be at least 18 years old to book IELTS Online. Please book IELTS in a test center instead.";
var APPB2C_common_basic_forms_dobOutOfRange2 = "Are you sure you were born that year?";
var APPB2C_common_basic_forms_firstNameMaxLength2 = "First / given names can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_idExpiration2 = "Your document expires before test term.";
var APPB2C_common_basic_forms_idMaxLength2 = "Document number can be at most 20 characters long.";
var APPB2C_common_basic_forms_idNumber2 = "Field has to match specific format (allowed are latin letters, digits, and hyphen '-')";
var APPB2C_common_basic_forms_idProofRequired2 = "Proof of identification is required.";
var APPB2C_common_basic_forms_maxLengthMessage2 = "Max length is {{count}}.";
var APPB2C_common_basic_forms_minorDobMax2 = "If your child is over 18, please ask them to register for an account themselves with the British Council";
var APPB2C_common_basic_forms_minorDobMin2 = "Children of age 11 and higher can take a test.";
var APPB2C_common_basic_forms_mobileBypass2 = "This is my correct mobile number";
var APPB2C_common_basic_forms_mobileLength2 = "Mobile number can be at most 20 characters long.";
var APPB2C_common_basic_forms_onlyLatin2 = "Field should contain only latin characters.";
var APPB2C_common_basic_forms_postCodeMaxLength2 = "Post code can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_postCodeMessage2 = "Postcode / ZIP has to match 5 digits specific format (eg 07102)";
var APPB2C_common_basic_forms_postCodeNotFound2 = "Sorry we can\u2019t find that postcode; please check you\u2019ve entered it correctly.";
var APPB2C_common_basic_forms_requiredMessage2 = "This field is required.";
var APPB2C_common_basic_forms_requiredNoSurmane2 = "This field is required. If you do not have a surname, please put a hyphen ('-') in this field.";
var APPB2C_common_basic_forms_stateMaxLength2 = "State can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_surnameMaxLength2 = "Surname / family name can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_townMaxLength2 = "Town / city can be at most {{count}} characters long.";
var APPB2C_common_basic_forms_unsupportedChars2 = "Field contains not supported characters set.";
var APPB2C_common_basic_forms_validMobile2 = "Please specify a valid mobile number.";
var APPB2C_common_basic_goBack2 = "Go back";
var APPB2C_common_basic_loading2 = "Loading...";
var APPB2C_common_basic_menu2 = "Menu";
var APPB2C_common_basic_no2 = "No";
var APPB2C_common_basic_notAllowed_msg2 = "You are not allowed to visit this page.";
var APPB2C_common_basic_notAllowed_title2 = "Oooops!";
var APPB2C_common_basic_notFound_goHome2 = "Go to home page";
var APPB2C_common_basic_notFound_msg2 = "The page you are looking for isn\u2019t there";
var APPB2C_common_basic_notFound_title2 = "Page not found";
var APPB2C_common_basic_notLoading_note2 = "We could not load the requested page. Most probably you\u2019ve lost your network connection. Try to refresh the page in a few moments.";
var APPB2C_common_basic_notLoading_refresh2 = "Refresh";
var APPB2C_common_basic_notLoading_title2 = "Unable to load page";
var APPB2C_common_basic_ok2 = "OK";
var APPB2C_common_basic_on2 = "on";
var APPB2C_common_basic_oneSecond2 = "One second, please";
var APPB2C_common_basic_pleaseNote2 = "Please note:";
var APPB2C_common_basic_retake2 = "Retake";
var APPB2C_common_basic_upload2 = "Upload";
var APPB2C_common_basic_validationError2 = "Please check all the marked questions to continue.";
var APPB2C_common_basic_waitReassurance2 = "We are almost there...";
var APPB2C_common_basic_wereSorry2 = "We\u2019re sorry";
var APPB2C_common_basic_yes2 = "Yes";
var APPB2C_common_bookTest_changeExamType2 = "Change test type";
var APPB2C_common_bookTest_filters_afternoon2 = "Afternoon";
var APPB2C_common_bookTest_filters_allDates2 = "All Dates";
var APPB2C_common_bookTest_filters_anyTime2 = "Any time of day";
var APPB2C_common_bookTest_filters_computer2 = "Computer";
var APPB2C_common_bookTest_filters_evening2 = "Evening";
var APPB2C_common_bookTest_filters_modal_cta2 = "Search results";
var APPB2C_common_bookTest_filters_modal_hide2 = "Hide";
var APPB2C_common_bookTest_filters_modal_iDontMind2 = "I don\u2019t mind";
var APPB2C_common_bookTest_filters_modal_resultsIn2 = "Results in {{days}} days";
var APPB2C_common_bookTest_filters_modal_subtitle2 = "Filter tests";
var APPB2C_common_bookTest_filters_modal_title2 = "Filters";
var APPB2C_common_bookTest_filters_modal_whichFormatTest2 = "Which format IELTS test would you like to take";
var APPB2C_common_bookTest_filters_modal_whichTimeOfDay2 = "Time of day for Listening, Reading and Writing test";
var APPB2C_common_bookTest_filters_morning2 = "Morning";
var APPB2C_common_bookTest_filters_paper2 = "Paper";
var APPB2C_common_bookTest_filters_seeAllFilters2 = "See all filters";
var APPB2C_common_bookTest_filters_title2 = "Filter by";
var APPB2C_common_bookTest_loadMoreTests2 = "Load more tests";
var APPB2C_common_bookTest_noDiscount_msg_countryNotMatched2 = "This voucher cannot be used in your selected country. Please go back to the original country selection.";
var APPB2C_common_bookTest_noDiscount_msg_notFound2 = "The voucher is not valid.";
var APPB2C_common_bookTest_noDiscount_msg_notSupported2 = "This voucher is dedicated to another product. Please go back to the original product selection.";
var APPB2C_common_bookTest_noDiscount_msg_reserved2 = "This voucher is already being used. Please finish the registration where you used it, or try again later.";
var APPB2C_common_bookTest_noDiscount_msg_used2 = "This voucher has already been used. You can proceed with your booking but you will have to pay the full price for the test.";
var APPB2C_common_bookTest_noDiscount_title2 = "Please note";
var APPB2C_common_bookTest_results_book2 = "Book Test";
var APPB2C_common_bookTest_results_bookFor2 = "Book for";
var APPB2C_common_bookTest_results_cd2 = "IELTS on computer";
var APPB2C_common_bookTest_results_changeSpeaking2 = "Change speaking schedule";
var APPB2C_common_bookTest_results_clearFilters2 = "Clear filters and show all tests";
var APPB2C_common_bookTest_results_fromCurrentLocation2 = "km from current location";
var APPB2C_common_bookTest_results_lrw2 = "Listening, Reading & Writing";
var APPB2C_common_bookTest_results_noResultsGoBack2 = "We could not find any tests, please <1>go back</1> and try changing your location or dates";
var APPB2C_common_bookTest_results_noResultsText2 = "We could not find any tests that meet your needs";
var APPB2C_common_bookTest_results_noResultsTitle2 = "Sorry";
var APPB2C_common_bookTest_results_pb2 = "IELTS on paper";
var APPB2C_common_bookTest_results_review2 = "and review";
var APPB2C_common_bookTest_results_searchText2 = "We are searching for your exams...";
var APPB2C_common_bookTest_results_speaking2 = "Speaking test";
var APPB2C_common_bookTest_results_viewOnMap2 = "View on map";
var APPB2C_common_bookTest_sortBy_date2 = "Date";
var APPB2C_common_bookTest_sortBy_distance2 = "Distance";
var APPB2C_common_bookTest_sortBy_price2 = "Price";
var APPB2C_common_bookTest_sortBy_title2 = "Sort by";
var APPB2C_common_bookTest_title2 = "Book a test";
var APPB2C_common_cancelBooking_alreadyRequested_cta2 = "Back to my test booking";
var APPB2C_common_cancelBooking_alreadyRequested_title2 = "You have already requested this service.";
var APPB2C_common_cancelBooking_back2 = "I don\u2019t want to cancel my booking";
var APPB2C_common_cancelBooking_bankDetails_form_accountHolder2 = "Bank account holder address";
var APPB2C_common_cancelBooking_bankDetails_form_bankAddress2 = "Bank branch address";
var APPB2C_common_cancelBooking_bankDetails_form_bankName2 = "Bank name";
var APPB2C_common_cancelBooking_bankDetails_form_bankNumber2 = "Bank account number";
var APPB2C_common_cancelBooking_bankDetails_form_changeCountry2 = "change country / territory";
var APPB2C_common_cancelBooking_bankDetails_form_city2 = "Town / City";
var APPB2C_common_cancelBooking_bankDetails_form_country2 = "Country / territory";
var APPB2C_common_cancelBooking_bankDetails_form_holderEmail2 = "Email address of the account holder";
var APPB2C_common_cancelBooking_bankDetails_form_holderName2 = "Account holder\u2019s name";
var APPB2C_common_cancelBooking_bankDetails_form_iban2 = "IBAN code";
var APPB2C_common_cancelBooking_bankDetails_form_invalidIban2 = "Incorrect IBAN code.";
var APPB2C_common_cancelBooking_bankDetails_form_invalidSwift2 = "Incorrect SWIFT code.";
var APPB2C_common_cancelBooking_bankDetails_form_postcode2 = "Postcode / ZIP";
var APPB2C_common_cancelBooking_bankDetails_form_sortCode2 = "Sort code";
var APPB2C_common_cancelBooking_bankDetails_form_swift2 = "Swift code";
var APPB2C_common_cancelBooking_bankDetails_note2 = "Please provide the bank account details you wish the refund to be paid into if your cancellation request is successful.";
var APPB2C_common_cancelBooking_bankDetails_privacy2 = "The British Council will use the information that you are providing in connection with processing your request. For detailed information, please refer to the <0>privacy section of our website.</0>";
var APPB2C_common_cancelBooking_bankDetails_terms_consent2 = "I hereby consent to providing a refund by an alternative method to the original payment method and processing my refund to the bank account details provided above. I understand that the British Council is not responsible for transfers to the wrong bank account in case of inaccurate account details provided by me.";
var APPB2C_common_cancelBooking_bankDetails_terms_title2 = "Our terms and conditions";
var APPB2C_common_cancelBooking_bankDetails_title2 = "Bank account details";
var APPB2C_common_cancelBooking_bottomNotes_note12 = "Once we receive your request to cancel, we will contact you directly if any additional information is needed.";
var APPB2C_common_cancelBooking_bottomNotes_note22 = "Refunds are subject to approval and you will receive a confirmation of acceptance / rejection of your request within 1 week. Your refund may have an administration fee deducted from it; please read the <0>IELTS cancellation and refund policy</0>";
var APPB2C_common_cancelBooking_bottomNotes_note32 = "If approved, your refund request will be processed within 3 weeks of submission.";
var APPB2C_common_cancelBooking_bottomNotes_terms2 = "Please read the <0>IELTS Postponement, Cancellation & Refund policy</0> carefully before submitting your request.";
var APPB2C_common_cancelBooking_cancelNotes_12 = "Cancellation requests are subject to approval by the British Council.";
var APPB2C_common_cancelBooking_cancelNotes_22 = "Your refund may have an administration fee deducted from it.";
var APPB2C_common_cancelBooking_cancelNotes_32 = "Before submitting your request, please carefully read the <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_cancelBooking_checkTest2 = "Please check that this is the test you wish to cancel:";
var APPB2C_common_cancelBooking_cta_addBankDetails2 = "Add your bank details";
var APPB2C_common_cancelBooking_cta_goBack2 = "Go back to edit cancellation reason";
var APPB2C_common_cancelBooking_cta_submit2 = "Submit cancellation request";
var APPB2C_common_cancelBooking_error_cancelledAlready2 = "This booking has been cancelled already.";
var APPB2C_common_cancelBooking_error_missingFile2 = "Please upload an evidence supporting your reason for cancellation.";
var APPB2C_common_cancelBooking_error_requestedAlready2 = "A request to cancel has been made for this booking already.";
var APPB2C_common_cancelBooking_reason_form_choose2 = "Please choose an option";
var APPB2C_common_cancelBooking_reason_form_freeform2 = "Please add more details below:";
var APPB2C_common_cancelBooking_reason_form_optional2 = "(optional)";
var APPB2C_common_cancelBooking_reason_form_requiredEvidence2 = "Evidence is required.";
var APPB2C_common_cancelBooking_reason_form_requiredReason2 = "Please choose a reason why you are cancelling booking.";
var APPB2C_common_cancelBooking_reason_form_title2 = "Why do you need to cancel this test?";
var APPB2C_common_cancelBooking_reason_note_content2 = "Because your test date is less than 5 weeks away, please provide a reason for cancelling and attach relevant documentation / evidence.";
var APPB2C_common_cancelBooking_reason_note_title2 = "Please note:";
var APPB2C_common_cancelBooking_subtitle2 = "Need to cancel your test?";
var APPB2C_common_cancelBooking_title2 = "Cancel your booking";
var APPB2C_common_cancelBooking_transferNote_cta2 = "Request transfer";
var APPB2C_common_cancelBooking_transferNote_title2 = "Did you know you can transfer your booking instead to a different date or location?";
var APPB2C_common_cancelBooking_transferNote_titleIol2 = "Did you know you can transfer your booking instead to a different date?";
var APPB2C_common_cancelBooking_upload_mobile2 = "As it looks like you\u2019re on a mobile device, we suggest you upload a photo from your device.";
var APPB2C_common_cancelBooking_upload_notes_12 = "You must upload a copy of the medical certificate issued by a Professional Medical Practitioner. The medical certificate must include nature of illness and other relevant information which will assist in any assessment of this application for special consideration.";
var APPB2C_common_cancelBooking_upload_notes_102 = "You must upload a copy of documentation that proves that you were unable to take your test due to a specific reason you feel is valid.";
var APPB2C_common_cancelBooking_upload_notes_22 = "You must upload a copy of documentation that proves that you were unable to attend the test due to military service.";
var APPB2C_common_cancelBooking_upload_notes_32 = "You must upload a copy of documentation that proves that you were unable to attend the test due to a bereavement in your family.";
var APPB2C_common_cancelBooking_upload_notes_42 = "You must upload a copy of documentation that proves that you were unable to attend the test due to a duplicate booking.";
var APPB2C_common_cancelBooking_upload_notes_52 = "You must upload a copy of documentation that proves that you were unable to attend the test due to official commitment or travel for official purposes.";
var APPB2C_common_cancelBooking_upload_notes_62 = "You must upload a copy of documentation that proves that you were unable to attend the test due events that were outside of your control.";
var APPB2C_common_cancelBooking_upload_notes_72 = "You must upload a copy of documentation that proves that you were unable to take your test due to a technical issue on the test day.";
var APPB2C_common_cancelBooking_upload_notes_82 = "You must upload a copy of documentation that proves that you were unable to take your test due to British Council cancelling your test.";
var APPB2C_common_cancelBooking_upload_requirements_note2 = "Your uploaded evidence should be easy to read. You can add a maximum of 4 files.";
var APPB2C_common_cancelBooking_upload_requirements_title2 = "Please note:";
var APPB2C_common_cancelBooking_upload_title2 = "Upload evidence";
var APPB2C_common_changeBooking_cancel_cta2 = "Request cancellation";
var APPB2C_common_changeBooking_cancel_text2 = "I need to cancel my test:";
var APPB2C_common_changeBooking_goBack2 = "I don\u2019t want to change my booking";
var APPB2C_common_changeBooking_subtitle2 = "What do you want to change about this booking?";
var APPB2C_common_changeBooking_title2 = "Change booking";
var APPB2C_common_changeBooking_transfer_cta2 = "Request transfer";
var APPB2C_common_changeBooking_transfer_text2 = "I need to change the date, time or location of my test:";
var APPB2C_common_changeBooking_transfer_textIol2 = "I need to transfer the test to a different date:";
var APPB2C_common_changeBooking_transferNotes_12 = "Transfer and cancellation requests are subject to approval by the test center.";
var APPB2C_common_changeBooking_transferNotes_22 = "We will aim to respond to all requests within 48-72 hours";
var APPB2C_common_changeBooking_transferNotesIol_12 = "Transfers and cancellations are subject to approval by the British Council.";
var APPB2C_common_changeBooking_transferNotesIol_22 = "Before submitting your request, please carefully read the <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_changeBookingAck_back2 = "Back to my test booking";
var APPB2C_common_changeBookingAck_cancel_banner2 = "We have received your request to cancel your test.";
var APPB2C_common_changeBookingAck_cancel_contact_email2 = "Email";
var APPB2C_common_changeBookingAck_cancel_contact_phone2 = "Phone";
var APPB2C_common_changeBookingAck_cancel_contact_title2 = "If you have any further queries, please contact:";
var APPB2C_common_changeBookingAck_cancel_contact_titleIol2 = "If you have any further queries, please <0>view our help page</0>.";
var APPB2C_common_changeBookingAck_cancel_refNr2 = "So we can help you quicker, please quote your IELTS reference number:";
var APPB2C_common_changeBookingAck_cancel_refunds2 = "Refunds are subject to approval and you will receive a confirmation of acceptance / rejection of your request within 1 week.";
var APPB2C_common_changeBookingAck_cancel_respondTime2 = "Please allow up to 3 weeks for your refund amount to be returned to your card or credited to your bank account.";
var APPB2C_common_changeBookingAck_cancel_terms2 = "For full details about our transferral policy, please see our <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_changeBookingAck_cancel_title2 = "Cancellation request acknowledgement";
var APPB2C_common_changeBookingAck_fees2 = "Fees may apply. We will confirm when we contact you directly.";
var APPB2C_common_changeBookingAck_feesIol2 = "Administrative charges may apply. If the request is approved, we will send the payment instructions to you.";
var APPB2C_common_changeBookingAck_speaking_banner2 = "Your speaking test has been transferred successfully.";
var APPB2C_common_changeBookingAck_speaking_note_line12 = "Changing your speaking test hasn\u2019t affected your written test.";
var APPB2C_common_changeBookingAck_speaking_note_line22 = "There was no charge for changing your speaking test.";
var APPB2C_common_changeBookingAck_speaking_note_title2 = "Please note:";
var APPB2C_common_changeBookingAck_speaking_title2 = "Speaking test transferred";
var APPB2C_common_changeBookingAck_terms2 = "For full details about our transferral policy, please see our <0>terms and conditions</0>";
var APPB2C_common_changeBookingAck_transfer_banner2 = "Your request to speak to somebody at the test center about transferring your test has been submitted.";
var APPB2C_common_changeBookingAck_transfer_note2 = "We will contact you directly to arrange a new test date. Once you have confirmed you are happy with the new date, we will send you confirmation of your updated test booking.";
var APPB2C_common_changeBookingAck_transfer_respondTime2 = "We will aim to respond to all requests within 48-72 hours.";
var APPB2C_common_changeBookingAck_transfer_title2 = "Transfer request acknowledgement";
var APPB2C_common_changeBookingAck_transferIol_banner2 = "We have received your request to transfer your test.";
var APPB2C_common_changeBookingAck_transferIol_contact2 = "If you have any further queries, please <0>view our help page</0>.";
var APPB2C_common_changeBookingAck_transferIol_note2 = "We will contact you directly to arrange a new test date. Once you have confirmed you are happy with the new date, we will send you confirmation of your updated test booking.";
var APPB2C_common_changeBookingAck_transferIol_refNr2 = "So we can help you quicker, please quote your IELTS reference number:";
var APPB2C_common_changeBookingAck_transferIol_terms2 = "For full details about our transferral policy, please see our <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_changeSpeaking_cards_change2 = "Change Test";
var APPB2C_common_changeSpeaking_cards_noSlots2 = "There are no free slots available";
var APPB2C_common_changeSpeaking_cards_noSlots_error2 = "Speaking capacity has been exhausted for the selected time. Try selecting a different slot.";
var APPB2C_common_changeSpeaking_cards_title2 = "Your new Speaking test";
var APPB2C_common_changeSpeaking_cta2 = "Transfer speaking test";
var APPB2C_common_changeSpeaking_modals_noSlots_keepTest2 = "Keep speaking test";
var APPB2C_common_changeSpeaking_modals_noSlots_line12 = "We just checked and no other speaking tests are available, sorry!";
var APPB2C_common_changeSpeaking_modals_noSlots_line22 = "If you really need to change your speaking test, request a transfer of your test date, and then you\u2019ll be able to choose a new speaking test.";
var APPB2C_common_changeSpeaking_modals_noSlots_title2 = "Sorry \u2013 no speaking tests are available!";
var APPB2C_common_changeSpeaking_modals_noSlots_transferTest2 = "Request transfer instead";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_cta2 = "Choose new speaking test";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line12 = "We just checked and the speaking test you just selected isn\u2019t available, sorry!";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line22 = "Please choose another speaking test instead.";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_title2 = "This speaking test isn\u2019t available!";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta2 = "Keep speaking test";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_line12 = "We just checked and no other speaking tests are available, sorry!";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_title2 = "Sorry \u2013 no speaking tests are available!";
var APPB2C_common_changeSpeaking_note_back2 = "I don\u2019t want to change my booking";
var APPB2C_common_changeSpeaking_note_line12 = "Changing your speaking test won\u2019t affect your written test.";
var APPB2C_common_changeSpeaking_note_line22 = "There is no charge for changing your speaking test.";
var APPB2C_common_changeSpeaking_note_title2 = "Please note:";
var APPB2C_common_changeSpeaking_title2 = "Change your speaking test";
var APPB2C_common_chooseTest_difference2 = "What\u2019s the difference between IELTS in a test center and IELTS Online";
var APPB2C_common_chooseTest_iol_consent2 = "I confirm I am happy for IELTS Online to use facial recognition and other proctoring technologies to securely monitor me during the test.";
var APPB2C_common_chooseTest_iol_cta2 = "Book IELTS Online";
var APPB2C_common_chooseTest_iol_desc2 = "Take this test anytime, anywhere in a new secure way. It combines the benefits of online testing with video monitoring to ensure a secure experience and reliable results.";
var APPB2C_common_chooseTest_iol_link2 = "Find out more about IELTS Online software";
var APPB2C_common_chooseTest_iol_note_content2 = "You currently have to be over 18 to take an IELTS Online test. If you are booking a test for somebody under 18, please book IELTS in a test center instead.";
var APPB2C_common_chooseTest_iol_note_title2 = "Please note:";
var APPB2C_common_chooseTest_iol_title2 = "IELTS Online";
var APPB2C_common_chooseTest_ors_cta2 = "Book IELTS in a test center";
var APPB2C_common_chooseTest_ors_descAc2 = "Take your IELTS Academic test at one of our official test centers. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ors_descGt2 = "Take your IELTS General Training test at one of our official test centers. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ors_title2 = "IELTS in a test center";
var APPB2C_common_chooseTest_title2 = "Choose your test";
var APPB2C_common_chooseTest_ukvi_difference2 = "What\u2019s the difference between IELTS for UKVI in a test center and IELTS for UKVI Online";
var APPB2C_common_chooseTest_ukvi_inCentre_cta2 = "Book IELTS for UKVI in a test center";
var APPB2C_common_chooseTest_ukvi_inCentre_descAc2 = "Take your IELTS for UKVI Academic test at one of our official test centers. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ukvi_inCentre_descGt2 = "Take your IELTS for UKVI General Training test at one of our official test centers. You will have the option to take your IELTS test on paper or on a computer.";
var APPB2C_common_chooseTest_ukvi_inCentre_title2 = "IELTS for UKVI in a test center";
var APPB2C_common_chooseTest_ukvi_online_consent2 = "I confirm I am happy for IELTS for UKVI Online to use facial recognition and other proctoring technologies to securely monitor me during the test.";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol2 = "(Look for this symbol on your passport!)";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_title2 = "I confirm I have a valid biometric passport with a RFID chip.";
var APPB2C_common_chooseTest_ukvi_online_cta2 = "Book IELTS for UKVI Online";
var APPB2C_common_chooseTest_ukvi_online_link2 = "Find out more about IELTS for UKVI Online software";
var APPB2C_common_chooseTest_ukvi_online_note_content2 = "You currently have to be over 18 to take an IELTS for UKVI Online test. If you are booking a test for somebody under 18, please book IELTS for UKVI in a test center instead.";
var APPB2C_common_chooseTest_ukvi_online_note_title2 = "Please note:";
var APPB2C_common_chooseTest_ukvi_online_title2 = "IELTS for UKVI Online";
var APPB2C_common_draft_cancel = "Cancel";
var APPB2C_common_draft_cta_confirm2 = "Confirm my details";
var APPB2C_common_draft_cta_decline2 = "Don\u2019t confirm my details with my agent";
var APPB2C_common_draft_cta_note2 = "If you don\u2019t confirm your details, {{agent}} will not be able to register you for a test. You will still be able to book tests for yourself";
var APPB2C_common_draft_difference2 = "Your agent added this different answer.";
var APPB2C_common_draft_edit2 = "Change";
var APPB2C_common_draft_error_pastDeadline2 = "The deadline for your details confirmation has passed.";
var APPB2C_common_draft_id_cta2 = "Update ID";
var APPB2C_common_draft_id_documentWithNoExpiryDate2 = "The document doesn\u2019t have an expiry date";
var APPB2C_common_draft_id_header2 = "Your identification";
var APPB2C_common_draft_id_issuingAuthority2 = "Issuing authority";
var APPB2C_common_draft_id_note2 = "On the test day, you will be required to bring the same identification you are providing below.";
var APPB2C_common_draft_id_title2 = "Check your ID details";
var APPB2C_common_draft_note_line12 = "Since you last visited this site, your IELTS agent ({{agent}}) has added different details for you when they were registering you for a test.";
var APPB2C_common_draft_note_line22 = "Please check their changes below and update your details if you notice they\u2019ve made a mistake.";
var APPB2C_common_draft_note_line32 = "If you don\u2019t confirm your details, {{agent}} will not be able to register you for a test.";
var APPB2C_common_draft_personal_cta2 = "Update personal details";
var APPB2C_common_draft_personal_header2 = "Your details";
var APPB2C_common_draft_personal_note2 = "All personal details provided on this page must match the Test Taker's identification document used for registration and presented at the test location, as these will appear on the Test Taker's Test Report Form (TRF). Once registration is complete, you may be charged a fee if you ask us to modify any of this information before we issue the TRF. Please note that no modifications can be made after the TRF has been issued.";
var APPB2C_common_draft_personal_title2 = "Check your personal details";
var APPB2C_common_draft_title2 = "Confirm your details";
var APPB2C_common_examType_iol_ac2 = "IELTS Academic (Online)";
var APPB2C_common_examType_iol_gt2 = "IELTS General Training (Online)";
var APPB2C_common_examType_ors_ac2 = "IELTS Academic";
var APPB2C_common_examType_ors_gt2 = "IELTS General Training";
var APPB2C_common_examType_ors_osr_ac2 = "IELTS Academic";
var APPB2C_common_examType_ors_osr_gt2 = "IELTS General Training";
var APPB2C_common_examType_ukvi_ac2 = "IELTS for UKVI Academic";
var APPB2C_common_examType_ukvi_gt2 = "IELTS for UKVI General Training";
var APPB2C_common_examType_ukvi_lfska12 = "IELTS Life Skills A1";
var APPB2C_common_examType_ukvi_lfska22 = "IELTS Life Skills A2";
var APPB2C_common_examType_ukvi_lfskb12 = "IELTS Life Skills B1";
var APPB2C_common_examType_ukvi_osr_ac2 = "IELTS for UKVI Academic";
var APPB2C_common_examType_ukvi_osr_gt2 = "IELTS for UKVI General Training";
var APPB2C_common_examType_uol_ac2 = "IELTS for UKVI (Academic) Online";
var APPB2C_common_examType_uol_gt2 = "IELTS for UKVI (General Training) Online";
var APPB2C_common_filePreview_label2 = "Image";
var APPB2C_common_filePreview_pdfPreviewLink2 = "Preview this file";
var APPB2C_common_filePreview_status2 = "Review status";
var APPB2C_common_filePreview_statusName_approved2 = "Approved";
var APPB2C_common_filePreview_statusName_deleted2 = "Deleted";
var APPB2C_common_filePreview_statusName_pending2 = "Pending";
var APPB2C_common_filePreview_statusName_rejected2 = "Rejected";
var APPB2C_common_filePreview_statusName_reviewed2 = "Reviewed";
var APPB2C_common_fileUpload_cta2 = "Choose a file";
var APPB2C_common_fileUpload_exceededSize2 = "This file exceeds maximum allowed size of";
var APPB2C_common_fileUpload_fileApproved2 = "This file has been approved and cannot be deleted";
var APPB2C_common_fileUpload_fileOptional2 = "Image {{number}} (optional)";
var APPB2C_common_fileUpload_fileRequired2 = "Image 1";
var APPB2C_common_fileUpload_filesCount2 = "Page {{current}} of {{total}}";
var APPB2C_common_fileUpload_fileSize2 = "Files no larger than {{size}} MB";
var APPB2C_common_fileUpload_imgAlt2 = "File Preview";
var APPB2C_common_fileUpload_pdfIeMsg2 = "Your PDF file is successfully uploaded, however preview is not available in the Internet Explorer browser. Did you know that IE is not actively supported since 2015?";
var APPB2C_common_fileUpload_pdfPasswordMsg2 = "Uploading secured file is disallowed.";
var APPB2C_common_fileUpload_remove2 = "remove this file";
var APPB2C_common_fileUpload_requirements2 = "Please only upload:";
var APPB2C_common_fileUpload_success2 = "File <bold><italic>{{fileName}}</italic></bold> added successfully!";
var APPB2C_common_findTest_changeCity2 = "Change city";
var APPB2C_common_findTest_changeCountry2 = "Change country / territory";
var APPB2C_common_findTest_cta2 = "Search for tests";
var APPB2C_common_findTest_dates_clear2 = "Clear";
var APPB2C_common_findTest_dates_from2 = "From";
var APPB2C_common_findTest_dates_noDatesSelected2 = "Sorry \u2013 there are no tests available on the dates you have selected. Please widen your date range";
var APPB2C_common_findTest_dates_noresults_details2 = "We\u2019re sorry; There are no available test dates. Please choose another location or change filters";
var APPB2C_common_findTest_dates_noresults_title2 = "No test dates found";
var APPB2C_common_findTest_dates_noresultsMobileLocation_cta2 = "Register your interest";
var APPB2C_common_findTest_dates_noresultsMobileLocation_details2 = "There are currently no test dates available for this location but you can register your interest in sitting for an IELTS for UK Visas test.";
var APPB2C_common_findTest_dates_noresultsMobileLocation_title2 = "No test dates found";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_details2 = "Because you have told us you need special arrangements on the test date, we aren\u2019t able to find any test dates in the location you\u2019ve selected. Please contact us directly using the details below to find out how we can help you.";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_title2 = "We\u2019re sorry; there are no available test dates";
var APPB2C_common_findTest_dates_pleaseSelect2 = "Please use the calendar below";
var APPB2C_common_findTest_dates_selected2 = "Selected";
var APPB2C_common_findTest_dates_showAll2 = "Show me all dates";
var APPB2C_common_findTest_dates_showDateRange2 = "Let me choose the dates";
var APPB2C_common_findTest_dates_title2 = "When would you like to take your test?";
var APPB2C_common_findTest_dates_to2 = "To";
var APPB2C_common_findTest_examFormat_all2 = "I don\u2019t mind";
var APPB2C_common_findTest_examFormat_cd2 = "IELTS on computer";
var APPB2C_common_findTest_examFormat_pb2 = "IELTS on paper";
var APPB2C_common_findTest_examFormat_results2 = "(results in {{days}} days)";
var APPB2C_common_findTest_examFormat_title2 = "Which format IELTS test would you like to take?";
var APPB2C_common_findTest_iol_noLrwResults2 = "We\u2019re sorry; There are no available test dates for your country.";
var APPB2C_common_findTest_iol_organisationCheck_check2 = "I confirm I have checked my intended organization(s) accept IELTS Online.";
var APPB2C_common_findTest_iol_organisationCheck_desc2 = "Before you book a test with us, you must check with your organization that they accept IELTS Online.";
var APPB2C_common_findTest_iol_organisationCheck_title2 = "Does your intended organization or institute accept IELTS online?";
var APPB2C_common_findTest_iol_softwareCheck_check2 = "I confirm I have checked the device and environment requirements to take the test";
var APPB2C_common_findTest_iol_softwareCheck_desc12 = "Your IELTS Online test uses Inspera software which you will need to download on either a Windows PC or MacOS (you can\u2019t take the test on a mobile or tablet!).";
var APPB2C_common_findTest_iol_softwareCheck_desc22 = "Before you book a test with us, you must check your computer meets the recommended system requirements.";
var APPB2C_common_findTest_iol_softwareCheck_link2 = "Check the device requirements for the test";
var APPB2C_common_findTest_iol_softwareCheck_title2 = "Can your computer run the software needed to take the test?";
var APPB2C_common_findTest_langModal_choose2 = "You were previously browsing this site in {{languageName}}. This language isn\u2019t supported for booking tests in {{countryName}}. Please choose one of the following options instead:";
var APPB2C_common_findTest_langModal_chooseSingle2 = "You were previously browsing this site in {{languageName}}. This language isn\u2019t supported for booking tests in {{countryName}}. Please choose the language below instead:";
var APPB2C_common_findTest_langModal_continue2 = "You can now continue with your booking in one of the following languages:";
var APPB2C_common_findTest_langModal_title2 = "You\u2019ve changed the location of your test";
var APPB2C_common_findTest_multipleLocations2 = "Multiple Locations";
var APPB2C_common_findTest_osrInfo2 = "<bold>One Skill Retake</bold> is not available with IELTS on Paper, consider booking IELTS on Computer to have this option.";
var APPB2C_common_findTest_osrInfoLink2 = "Read more about One Skill Retake";
var APPB2C_common_findTest_residencyConfirmation_btn2 = "Got it, continue searching";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_btn2 = "Change test type";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line12 = "If you are not a resident of the country you selected, you cannot book a paper test. Please <0>choose a computer test or choose a paper test located in your country of residence.</0>";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line22 = "On the \u2018Book a test\u2019 page, you can use filters to change the test type. You can also return to the country selection page.";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_title2 = "You can not book this test";
var APPB2C_common_findTest_residencyConfirmation_msg = "If you are interested in taking an <0>IELTS on Paper</0> test we must confirm that you are a resident of the country in which you are taking the test. If you are not a resident of your chosen country, <0>choose a computer test or choose a paper test located in your country of residence.</0> If you choose incorrectly, your test will be cancelled.";
var APPB2C_common_findTest_residencyConfirmation_msg_line12 = "If you are interested in taking an <0>IELTS on Paper</0> test we must confirm that you are a resident of the country in which you are taking the test.";
var APPB2C_common_findTest_residencyConfirmation_msg_line22 = "If you are not a resident of your chosen country, <0>choose a computer test or choose a paper test located in your country of residence</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_line32 = "If you choose incorrectly, your test will be cancelled.";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine22 = "If you are not a resident of your chosen country, <0>choose a test located in your country of residence</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine22 = "If you are not a resident of your chosen country, <0>choose a computer test</0> (available in other cities) <0>or choose a paper test located in your country of residence</0>.";
var APPB2C_common_findTest_residencyConfirmation_selectDates2 = "Please select a date first";
var APPB2C_common_findTest_residencyConfirmation_title2 = "Important!";
var APPB2C_common_findTest_searchIn2 = "Search in:";
var APPB2C_common_findTest_selectCountry_continue2 = "Continue booking";
var APPB2C_common_findTest_selectCountry_countryNotSupported2 = "To book a test in this country / territory you need to use a different site.";
var APPB2C_common_findTest_selectCountry_label2 = "Choose a country / territory";
var APPB2C_common_findTest_selectCountry_placeholder2 = "Search by country / territory name";
var APPB2C_common_findTest_selectLocation_closestToMe2 = "Closest to me";
var APPB2C_common_findTest_selectLocation_geolocationBlocked2 = "We can\u2019t get your location, please make sure location permission isn\u2019t blocked for this site.";
var APPB2C_common_findTest_selectLocation_label2 = "Search city";
var APPB2C_common_findTest_selectLocation_noExamForThisCountry2 = "We can\u2019t find any tests in this country / territory. Please <1>contact us</1> to see if we can help you.";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation2 = "We can\u2019t find any tests for this organisation. Choose one of the options:";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta2 = "Search for available tests";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option12 = "select a location where you want to book a test";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option22 = "<1>contact us</1> and we will try to help you";
var APPB2C_common_findTest_specialReqs_findOutMore2 = "Read about how we can help you";
var APPB2C_common_findTest_specialReqs_list_subtitle2 = "This will affect the test dates that are available. Please choose one or more options below.";
var APPB2C_common_findTest_specialReqs_list_title2 = "Which special arrangements do you need on the test day?";
var APPB2C_common_findTest_specialReqs_needCheckbox2 = "I need <1>special arrangements</1> for the test date";
var APPB2C_common_findTest_specialReqs_other2 = "What special arrangements do you need";
var APPB2C_common_findTest_specialReqs_pleaseNoteMessage2 = "In case you need to register for a test date prior to the earliest test date available, please contact the test center for options available. It may not be possible for the test center to provide an earlier test date in case you need special test materials, for eg. Enlarged print test papers, Braille, lip-reading version etc.";
var APPB2C_common_findTest_subtitle2 = "Where would you like to take your test?";
var APPB2C_common_findTest_title2 = "Find a test";
var APPB2C_common_findTest_uol_organisationCheck_check2 = "I confirm I understand IELTS for UKVI Online is only accepted by UK Visa and Immigration.";
var APPB2C_common_findTest_uol_organisationCheck_desc2 = "To take an IELTS test recognized by other organizations please <0>look at our other IELTS tests.</0>";
var APPB2C_common_findTest_uol_organisationCheck_title2 = "IELTS for UKVI Online is only accepted by UK Visa and Immigration";
var APPB2C_common_findTest_uol_softwareCheck_check2 = "I confirm I have checked the device and environment requirements to take the test";
var APPB2C_common_findTest_uol_softwareCheck_desc12 = "Your IELTS for UKVI Online test uses Inspera software which you will need to download on either a Windows PC or MacOS (you can\u2019t take the test on a mobile or tablet!).";
var APPB2C_common_findTest_uol_softwareCheck_desc22 = "Before you book a test with us, you must check your computer meets the recommended system requirements.";
var APPB2C_common_findTest_uol_softwareCheck_link2 = "Check the device requirements for the test";
var APPB2C_common_findTest_uol_softwareCheck_title2 = "Can your computer run the software needed to take the test?";
var APPB2C_common_footer_terms_accessibility2 = "Special arrangements";
var APPB2C_common_footer_terms_copyrightLine12 = "The United Kingdom's international organization for cultural relations and educational opportunities.";
var APPB2C_common_footer_terms_copyrightLine22 = "A registered charity: 209131 (England and Wales) SC037733 (Scotland).";
var APPB2C_common_footer_terms_dataProtection2 = "Data Protection";
var APPB2C_common_footer_terms_privacyAndCookies2 = "Privacy and cookies";
var APPB2C_common_footer_terms_termsOfUse2 = "Legal";
var APPB2C_common_gelRedirect_errorMessage2 = "This resource is currently unavailable.";
var APPB2C_common_gelRedirect_goBack = "View preparation materials";
var APPB2C_common_gelRedirect_header2 = "Prepare for your test";
var APPB2C_common_gelRedirect_info2 = "We can help you prepare for IELTS with our range of free resources.";
var APPB2C_common_general_next2 = "Next";
var APPB2C_common_general_previous2 = "Previous";
var APPB2C_common_general_regNotFound2 = "Registration not found";
var APPB2C_common_general_remove2 = "Remove";
var APPB2C_common_general_steps2 = "Step {{step}} of {{steps}}";
var APPB2C_common_general_stepsContinued2 = "Step {{step}} of {{steps}} continued...";
var APPB2C_common_helpAndContact_actionSection_contactFormLabel2 = "I can\u2019t chat right now, but I need somebody to contact me later:";
var APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre2 = "Do you need more help? Click the button below to complete the contact form.";
var APPB2C_common_helpAndContact_actionSection_contactFormLink2 = "Complete our contact form";
var APPB2C_common_helpAndContact_actionSection_faqLabel2 = "I have a quick question I need an answer for:";
var APPB2C_common_helpAndContact_actionSection_faqLink2 = "View our FAQs";
var APPB2C_common_helpAndContact_actionSection_icHeader2 = "Other ways we can help you\u2026";
var APPB2C_common_helpAndContact_actionSection_iolHeader2 = "What sort of support do you need?";
var APPB2C_common_helpAndContact_actionSection_launchChatBtn2 = "Launch live-chat";
var APPB2C_common_helpAndContact_actionSection_launchChatLabel2 = "I need to chat to somebody online about my test:";
var APPB2C_common_helpAndContact_form_back2 = "Back to help & contact";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLink2 = "For more information on making a complaint to the British Council, see <0>Make a complaint</0>";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle2 = "Find out more";
var APPB2C_common_helpAndContact_form_complaint_radio_complaint2 = "Complaint";
var APPB2C_common_helpAndContact_form_complaint_radio_other2 = "Regular request eg. a question";
var APPB2C_common_helpAndContact_form_complaint_title2 = "Please select the type of request:";
var APPB2C_common_helpAndContact_form_label2 = "Please briefly outline your query";
var APPB2C_common_helpAndContact_form_legal2 = "The British Council will use the information that you are providing in connection with processing your request. For detailed information, please refer to the <0>privacy section of our website</0>";
var APPB2C_common_helpAndContact_form_response2 = "We will aim to respond to all requests by email within 48-72 hours.";
var APPB2C_common_helpAndContact_form_submit2 = "Submit contact form";
var APPB2C_common_helpAndContact_form_thanks_back2 = "Back to my test booking";
var APPB2C_common_helpAndContact_form_thanks_banner2 = "We have received your contact request.";
var APPB2C_common_helpAndContact_form_thanks_info2 = "We will aim to respond to all requests within 48-72 hours.";
var APPB2C_common_helpAndContact_form_thanks_title2 = "Thanks for contacting us";
var APPB2C_common_helpAndContact_form_title2 = "How can we help you with your test?";
var APPB2C_common_helpAndContact_form_validation2 = "This field is required.";
var APPB2C_common_helpAndContact_icContent_commonQuestionsHeader2 = "Common questions";
var APPB2C_common_helpAndContact_icContent_emailLabel2 = "Email";
var APPB2C_common_helpAndContact_icContent_header2 = "If you have any questions about your IELTS test, please contact us.";
var APPB2C_common_helpAndContact_icContent_phoneLabel2 = "Phone";
var APPB2C_common_helpAndContact_icContent_referenceReminder2 = "So we can help you quicker, please quote your IELTS reference number:";
var APPB2C_common_helpAndContact_ieltsReference2 = "IELTS reference number:";
var APPB2C_common_helpAndContact_noTests_bookNewBtn2 = "Book a new test";
var APPB2C_common_helpAndContact_noTests_contactLink2 = "Contact details can be found on our <0>Take IELTS website</0>.";
var APPB2C_common_helpAndContact_noTests_header2 = "You don\u2019t have any tests to request help for.";
var APPB2C_common_helpAndContact_selectExamLabel2 = "Showing help for this test:";
var APPB2C_common_helpAndContact_title2 = "Help & contact";
var APPB2C_common_invitation_modal_header2 = "Invalid link";
var APPB2C_common_invitation_modal_invalid2 = "The URL you tried to use is either incorrect or no longer valid. You can continue registration without Agent context.";
var APPB2C_common_modals_newTermsAndConditions_checkboxNote2 = "I agree to the {{context}}";
var APPB2C_common_modals_newTermsAndConditions_checkboxTC2 = "terms and conditions";
var APPB2C_common_modals_newTermsAndConditions_cta2 = "Proceed";
var APPB2C_common_modals_newTermsAndConditions_error2 = "Oooops, something went wrong. Please try again in a few moments.";
var APPB2C_common_modals_newTermsAndConditions_note2 = "Please accept new terms & conditions before proceeding";
var APPB2C_common_modals_newTermsAndConditions_title2 = "New terms & conditions";
var APPB2C_common_modals_newTermsAndConditions_trigger2 = "Accept new terms and conditions";
var APPB2C_common_nav_bookNewTest2 = "Book new test";
var APPB2C_common_nav_chooseLanguage2 = "Choose language";
var APPB2C_common_nav_hello2 = "Hello";
var APPB2C_common_nav_helpAndContact2 = "Help & contact";
var APPB2C_common_nav_login2 = "Login";
var APPB2C_common_nav_logout2 = "Log out";
var APPB2C_common_nav_logoutModal_cancel = "Cancel";
var APPB2C_common_nav_logoutModal_confirm2 = "OK";
var APPB2C_common_nav_logoutModal_message2 = "Logging out will exit the Registration process.";
var APPB2C_common_nav_logoutModal_question2 = "Are you sure you want to continue?";
var APPB2C_common_nav_logoutModal_title2 = "Logging out";
var APPB2C_common_nav_myAccount2 = "My account";
var APPB2C_common_nav_preparation2 = "Preparation";
var APPB2C_common_nav_resultsTests2 = "Tests & results";
var APPB2C_common_nav_ttp2 = "Test Taker Portal";
var APPB2C_common_preparation_noTests_contactLink2 = "Free preparation materials are available on our <0>Take IELTS website</0>.";
var APPB2C_common_preparation_noTests_header2 = "You don\u2019t have any booked tests to prepare for.";
var APPB2C_common_preparation_selectExamLabel2 = "Showing materials for this test:";
var APPB2C_common_preparation_title2 = "Preparation";
var APPB2C_common_preparation_viewTestBtn2 = "View this test";
var APPB2C_common_recreateReg_errMsg2 = "We couldn\u2019t recreate this registration, but please don\u2019t worry. We will redirect you to the standard registration instead.";
var APPB2C_common_recreateReg_errTitle2 = "Recreate failed";
var APPB2C_common_recreateReg_progress2 = "Recreating your booking";
var APPB2C_common_regHeader_reference2 = "Reference";
var APPB2C_common_regHeader_trfLoading2 = "Just a second...";
var APPB2C_common_regHeader_trfNumber2 = "TRF number";
var APPB2C_common_regInProgressModal_continue2 = "Never mind";
var APPB2C_common_regInProgressModal_message12 = "You have the registration for <bold>{{date}}</bold> in progress already. Do you really want to start over with the selected test?";
var APPB2C_common_regInProgressModal_message22 = "Your current slots will be released in this case.";
var APPB2C_common_regInProgressModal_restart2 = "Start over";
var APPB2C_common_regInProgressModal_title2 = "Attention!";
var APPB2C_common_selfServices_requested_cancellation2 = "Cancellation requested";
var APPB2C_common_selfServices_requested_transfer2 = "Transfer requested";
var APPB2C_common_specialReqs_AMANU2 = "Amanuensis";
var APPB2C_common_specialReqs_ANTHR2 = "Another";
var APPB2C_common_specialReqs_BTP2 = "Braille test papers";
var APPB2C_common_specialReqs_BWP2 = "Braille word processor";
var APPB2C_common_specialReqs_EPTP2 = "Enlarged print test papers";
var APPB2C_common_specialReqs_ET2 = "Extra time";
var APPB2C_common_specialReqs_LRTV2 = "Lip-reading test version";
var APPB2C_common_specialReqs_SAE2 = "Special amplification equipment";
var APPB2C_common_specialReqs_VAS2 = "Voice-activated software";
var APPB2C_common_specialReqs_WP2 = "Word processor";
var APPB2C_common_startPage_book2 = "Book";
var APPB2C_common_startPage_candidates_nowAdult2 = "We have noticed that {{name}} is now legally an adult. According to our policy they have to manage their IELTS account themselves.";
var APPB2C_common_startPage_candidates_showingFor2 = "Showing test & results for: ";
var APPB2C_common_startPage_common_cancelled2 = "Cancelled";
var APPB2C_common_startPage_description_ac2 = "Take this test if you want to study at undergraduate or postgraduate level, or join a professional organization anywhere in the world.";
var APPB2C_common_startPage_description_gt2 = "Take this test if you want to study at below degree level or work anywhere in the world.";
var APPB2C_common_startPage_description_ukvi2 = "IELTS for UKVI and Life Skills tests are only offered at the IELTS USA Newark NJ test center in the United States.";
var APPB2C_common_startPage_endNote2 = "The British Council is a proud co-owner of IELTS.";
var APPB2C_common_startPage_error_body2 = "There might be some more tests but we\u2019ve encountered an error while loading them. Please try to refresh the page in a few moments.";
var APPB2C_common_startPage_error_cta2 = "Refresh page";
var APPB2C_common_startPage_error_title2 = "Unable to load all tests";
var APPB2C_common_startPage_hero_cta2 = "Book a new test";
var APPB2C_common_startPage_hero_subtitle2 = "Your Test Taker portal will help you prepare for your test, manage your bookings, get your results, and use your IELTS score to continue your journey with the English language.";
var APPB2C_common_startPage_hero_title2 = "Tests & Results";
var APPB2C_common_startPage_ielts2 = "IELTS";
var APPB2C_common_startPage_ieltsUkvi2 = "IELTS for UK Visas";
var APPB2C_common_startPage_missing_cancel2 = "I don\u2019t want to log in";
var APPB2C_common_startPage_missing_marketing_cta2 = "Save";
var APPB2C_common_startPage_missing_marketing_notes_full12 = "You may unsubscribe at any time from our emails within your \u2018My Account\u2019 area in the Test Taker Portal.";
var APPB2C_common_startPage_missing_marketing_notes_full22 = "Or you can";
var APPB2C_common_startPage_missing_marketing_notes_full32 = "We will process your personal information based on your consent.";
var APPB2C_common_startPage_missing_marketing_notes_link2 = "contact us";
var APPB2C_common_startPage_missing_marketing_notes_main2 = "The British Council would like to use the information you provide to send details of activities, services and events (including social events) which we think are of interest.";
var APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties2 = "I am happy to receive information about products, services and events organised by British Council and by third parties selected by the British Council.";
var APPB2C_common_startPage_missing_marketing_opts_BcOnly2 = "I am happy to receive updates about products, services and events provided or organised by the British Council (including members of the wider British Council group).";
var APPB2C_common_startPage_missing_marketing_opts_DoNotSend2 = "Please do not send me any marketing updates.";
var APPB2C_common_startPage_missing_marketing_title2 = "Your marketing preferences";
var APPB2C_common_startPage_missing_ttData_cta2 = "Confirm your details";
var APPB2C_common_startPage_missing_ttData_dob2 = "Date of birth";
var APPB2C_common_startPage_missing_ttData_firstName2 = "First / given names (including middle names)";
var APPB2C_common_startPage_missing_ttData_lastName2 = "Surname / family name";
var APPB2C_common_startPage_missing_ttData_line12 = "We can see you\u2019ve registered with us before for a different product or service.";
var APPB2C_common_startPage_missing_ttData_line22 = "So that we can log you in, please confirm your name and date of birth again.";
var APPB2C_common_startPage_missing_ttData_line32 = "Thanks!";
var APPB2C_common_startPage_missing_ttData_noLastName2 = "I don\u2019t have a surname / family name";
var APPB2C_common_startPage_missing_ttData_title2 = "We need to check something!";
var APPB2C_common_startPage_options_ac2 = "IELTS Academic";
var APPB2C_common_startPage_options_gt2 = "IELTS General Training";
var APPB2C_common_startPage_options_ukvi2 = "IELTS for UKVI or IELTS Life Skills";
var APPB2C_common_startPage_past_cd2 = "IELTS on computer";
var APPB2C_common_startPage_past_empty2 = "You don\u2019t have any tests";
var APPB2C_common_startPage_past_osr_newScore2 = "Your new score";
var APPB2C_common_startPage_past_osr_note_awaiting2 = "You\u2019re waiting for your results from your {{skill}} Retake to try and improve your overall band score.";
var APPB2C_common_startPage_past_osr_note_confirmed2 = "You\u2019ve booked a {{skill}} Retake to try and improve your overall band score.";
var APPB2C_common_startPage_past_osr_note_hasResults2 = "You booked a {{skill}} Retake to try and improve your overall band score. Your new score is now available.";
var APPB2C_common_startPage_past_osr_originalScore2 = "Your original score";
var APPB2C_common_startPage_past_osr_viewRetakeLink2 = "View booked retake";
var APPB2C_common_startPage_past_pb2 = "IELTS on paper";
var APPB2C_common_startPage_past_rebook2 = "Rebook this test with different dates";
var APPB2C_common_startPage_past_score2 = "You scored";
var APPB2C_common_startPage_past_scoreLifeSkills2 = "Your score";
var APPB2C_common_startPage_past_title2 = "Your previous tests & scores";
var APPB2C_common_startPage_past_upcomingResults2 = "Your result will be available by {{date}}";
var APPB2C_common_startPage_readTnCs2 = "Before you proceed, please read the IELTS";
var APPB2C_common_startPage_recent_cta2 = "See full result";
var APPB2C_common_startPage_recent_title2 = "Your recent result";
var APPB2C_common_startPage_subtitle2 = "Which IELTS test would you like to take?";
var APPB2C_common_startPage_title2 = "Choose your IELTS test";
var APPB2C_common_startPage_tncs2 = "Terms & Conditions";
var APPB2C_common_startPage_upcoming_cd2 = "Computer test";
var APPB2C_common_startPage_upcoming_empty2 = "You don\u2019t have any upcoming tests";
var APPB2C_common_startPage_upcoming_lifeSkills2 = "Listening & Speaking test";
var APPB2C_common_startPage_upcoming_live2 = "Speaking test";
var APPB2C_common_startPage_upcoming_manage2 = "View this booking";
var APPB2C_common_startPage_upcoming_missing_childConsent2 = "Update child consent information";
var APPB2C_common_startPage_upcoming_missing_id2 = "Upload ID document";
var APPB2C_common_startPage_upcoming_missing_medical2 = "Upload medical evidence";
var APPB2C_common_startPage_upcoming_missing_payment2 = "Upload proof of payment";
var APPB2C_common_startPage_upcoming_noSpeaking2 = "No info about speaking yet";
var APPB2C_common_startPage_upcoming_noTime2 = "We will send you the exact time of your test no more than 48 hours before your test date.";
var APPB2C_common_startPage_upcoming_osr_note2 = "Your {{skill}} Retake result will count towards your new overall band score.";
var APPB2C_common_startPage_upcoming_pastPaymentDeadline2 = "Payment date expired";
var APPB2C_common_startPage_upcoming_pay2 = "Pay for your test";
var APPB2C_common_startPage_upcoming_pb2 = "Written test";
var APPB2C_common_startPage_upcoming_prepare2 = "Prepare for this test";
var APPB2C_common_startPage_upcoming_remote2 = "Remote speaking test";
var APPB2C_common_startPage_upcoming_title2 = "Your upcoming tests";
var APPB2C_common_startPage_whichIELTS2 = "Which IELTS test is right for me?";
var APPB2C_common_terms_downloadInfo2 = "Your Terms and Conditions PDF download will begin shortly.";
var APPB2C_common_terms_downloadTitle2 = "Terms and conditions PDF download";
var APPB2C_common_terms_error2 = "Oooops, something went wrong. Please try again in a few moments.";
var APPB2C_common_terms_title2 = "Terms and conditions";
var APPB2C_common_testDetails_changeTest2 = "Change test";
var APPB2C_common_testDetails_changeWrittenTest2 = "Change written test";
var APPB2C_common_testDetails_completeYourBooking2 = "Complete your booking";
var APPB2C_common_testDetails_mapModal_close2 = "Close Window";
var APPB2C_common_testDetails_mapModal_title2 = "Location Details";
var APPB2C_common_testDetails_modifyNote_confirm2 = "I understand and agree to these Terms and Conditions.";
var APPB2C_common_testDetails_modifyNote_note2 = "Although every attempt is made to ensure that we honor your test venue, date, and time slot selection, we reserve the right to modify and replace your selected preference based on availability";
var APPB2C_common_testDetails_modifyNote_pleaseNote2 = "Please note";
var APPB2C_common_testDetails_title2 = "Test details";
var APPB2C_common_testDetails_yourSpeakingTest2 = "Your Speaking test";
var APPB2C_common_testDetails_yourWrittenTest2 = "Your Written test";
var APPB2C_common_transfer_agreement2 = "For full details about our transferral policy, please see our <1>terms and conditions</1>";
var APPB2C_common_transfer_contactNote2 = "We will contact you directly to process the transfer of your test. We will aim to respond to all requests within 48-72 hours";
var APPB2C_common_transfer_contactNoteIol2 = "We will contact you directly by email to process the transfer of your test. We will aim to respond to all requests within 48-72 hours";
var APPB2C_common_transfer_cta2 = "Submit transfer request";
var APPB2C_common_transfer_form_freeform2 = "Please tell us about your preferences for a new test";
var APPB2C_common_transfer_form_reasons_12 = "The type of test I booked";
var APPB2C_common_transfer_form_reasons_22 = "The location of my booking";
var APPB2C_common_transfer_form_reasons_32 = "The test date";
var APPB2C_common_transfer_form_reasons_42 = "Just the speaking part";
var APPB2C_common_transfer_form_reasons_choose2 = "Please choose an option";
var APPB2C_common_transfer_form_reasons_other2 = "Other";
var APPB2C_common_transfer_form_reasons_title2 = "What do you need to change?";
var APPB2C_common_transfer_goBack2 = "Back";
var APPB2C_common_transfer_notes_12 = "Transfer requests are subject to approval by the test center.";
var APPB2C_common_transfer_notes_22 = "IELTS tests can only be transferred once.";
var APPB2C_common_transfer_notes_32 = "You will be required to choose an alternative test day within 3 months.";
var APPB2C_common_transfer_notes_42 = "Administrative charges may apply. If the request is approved, we will send the payment instructions to you.";
var APPB2C_common_transfer_notesIol_12 = "Transfer requests are subject to approval by the British Council.";
var APPB2C_common_transfer_notesIol_22 = "Before submitting your request, please carefully read the <0>IELTS Postponement, Cancellation & Refund policy</0>";
var APPB2C_common_transfer_requestValidation2 = "This field is required.";
var APPB2C_common_transfer_selfService_availableDates_title2 = "Available dates";
var APPB2C_common_transfer_selfService_chooseDates_title2 = "Transfer your test";
var APPB2C_common_transfer_selfService_review_confirm_proceedBtn2 = "Transfer test";
var APPB2C_common_transfer_selfService_review_confirm_title2 = "Confirm transfer";
var APPB2C_common_transfer_selfService_review_errors_noDistricts2 = "There are no test dates available in this location anymore. Please contact the test centre to see if we can help you.";
var APPB2C_common_transfer_selfService_review_errors_noLrwSlots2 = "Test capacity has been exhausted for the selected date. Try selecting a different day.";
var APPB2C_common_transfer_selfService_review_errors_transferDisabled2 = "Transferring your test is no longer available. Contact your test centre for more information.";
var APPB2C_common_transfer_selfService_review_test_changeLrw2 = "Change written exam date";
var APPB2C_common_transfer_selfService_review_tncs_agreement2 = "You agreed to the IELTS <0>terms and conditions</0> and <2>cancellation policy</2>";
var APPB2C_common_transfer_title2 = "Transfer your booking";
var APPB2C_common_underConstruction_message2 = "Hi. You\u2019re visiting this page because you\u2019re part of our pilot to test this booking website (thanks for helping!) Don\u2019t worry, before we go live we\u2019ll make sure we show our real customers some proper content here :)";
var APPB2C_common_underConstruction_title2 = "Under construction...";
var APPB2C_common_unknownCountry_cta2 = "I\u2019ve turned off my VPN";
var APPB2C_common_unknownCountry_header2 = "We can\u2019t detect the country that you\u2019re browsing from :(";
var APPB2C_common_unknownCountry_msg12 = "Sometimes this happens because your device is using software that hides your location.";
var APPB2C_common_unknownCountry_msg22 = "If you are using a VPN or similar tools that hide your location, please turn them off and try again.";
var APPB2C_common_unknownCountry_title2 = "We can\u2019t find you!";
var APPB2C_common_unpaidErrorBar_paymentExpired2 = "Registration payment date has expired";
var APPB2C_common_updateBar_cancelled2 = "This test has been cancelled.";
var APPB2C_common_updateBar_cancelPending2 = "Your request to cancel this test is pending. <1>See details</1>.";
var APPB2C_common_updateBar_cancelRejected2 = "Your cancellation request has been rejected.";
var APPB2C_common_updateBar_close2 = "Close";
var APPB2C_common_updateBar_details2 = "Please see full details below:";
var APPB2C_common_updateBar_eorCompleted2 = "Your Enquiry on Results (EOR) has been completed.";
var APPB2C_common_updateBar_eorCompleted_scoreDecreased2 = "Your overall band score has decreased from {{prevOverallScore}} to {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreIncreased2 = "Your overall band score has increased from {{prevOverallScore}} to {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreUnchanged2 = "Your overall band score remains unchanged at {{currentOverallScore}}";
var APPB2C_common_updateBar_eorPending2 = "Your Enquiry on Results (EOR) is pending. <1>See details</1>.";
var APPB2C_common_updateBar_eorPendingAgentPays2 = "Your Enquiry on Results (EOR) is pending.";
var APPB2C_common_updateBar_eorRefunded2 = "Your Enquiry on Results (EOR) fee has been refunded.";
var APPB2C_common_updateBar_eorRefundPending2 = "Your Enquiry on Results (EOR) fee refund is being processed.";
var APPB2C_common_updateBar_eorRejected2 = "Your Enquiry on Result (EOR) was rejected.";
var APPB2C_common_updateBar_eorRejected_details = "Please see full details below:";
var APPB2C_common_updateBar_eorRejected_overallBand2 = "Your overall band score has not changed from {{overallBand}}";
var APPB2C_common_updateBar_hasResults2 = "Your results are now available.";
var APPB2C_common_updateBar_termsUpdated2 = "You need to <1>accept new terms & conditions</1> that will be in effect for your test day. <br> All test takers attending an IELTS test will be governed by these Terms and Conditions.";
var APPB2C_common_updateBar_transactionRefundRequestAccepted2 = "Your refund request has been accepted.";
var APPB2C_common_updateBar_transactionRefundRequestPending2 = "Your refund request is pending.";
var APPB2C_common_updateBar_transactionRefundRequestRejected2 = "Your refund request has been rejected.";
var APPB2C_common_updateBar_transferCancelled2 = "Your transfer request could not be processed. Try again or contact your test centre for more information.";
var APPB2C_common_updateBar_transferPending2 = "Your request to transfer this test is pending. <1>See details</1>.";
var APPB2C_common_updateBar_transferPendingCmds2 = "Your transfer is awaiting confirmation. You will be notified when your transfer request has been processed.";
var APPB2C_common_updateBar_transferred2 = "This test has been transferred. Your new dates are below.";
var APPB2C_common_updateBar_transferredCmds2 = "This test has been transferred. See booking details for new dates.";
var APPB2C_registration_bookingDetails_payment2 = "Payment";
var APPB2C_registration_bookingDetails_results2 = "Find out about Results";
var APPB2C_registration_bookingDetails_seePayment2 = "See Payment details";
var APPB2C_registration_bookingDetails_seeTestTaker2 = "See Test Taker details";
var APPB2C_registration_bookingDetails_whatNext2 = "What can I do next?";
var APPB2C_registration_date_check_back2 = "Back to the test details";
var APPB2C_registration_date_check_beforeTestDate2 = "Before the test day";
var APPB2C_registration_date_check_intro2 = "Before your test day, please make sure you do the following things:";
var APPB2C_registration_date_check_onTestDate2 = "On the test day";
var APPB2C_registration_date_check_step1_body2 = "Check the laptop or desktop computer you intend to take the test on meets the minimum requirement (PC or Mac only \u2013 you cannot take IELTS Online on mobile or tablet).";
var APPB2C_registration_date_check_step1_link2 = "Check your device meets the requirements";
var APPB2C_registration_date_check_step1_title2 = "Step 1 \u2013 Device check";
var APPB2C_registration_date_check_step2_body2 = "Before the test date, you will need to install Inspera software on the PC or Mac you intend to take the test on.";
var APPB2C_registration_date_check_step2_link2 = "Download Inspera software";
var APPB2C_registration_date_check_step2_title2 = "Step 2 \u2013 Install software";
var APPB2C_registration_date_check_step3_body12 = "Once you have Inspera installed on your device, open the software and login. To login you just need your username and password below \u2013 you can simply copy and paste them in.";
var APPB2C_registration_date_check_step3_body22 = "Once you\u2019re logged-in, you\u2019ll be taken through the following steps:";
var APPB2C_registration_date_check_step3_noDetailsBody2 = "They will be available nearer the test date.";
var APPB2C_registration_date_check_step3_noDetailsTitle2 = "Your login details are not available yet";
var APPB2C_registration_date_check_step3_noSpeakingPinBody2 = "This will be available nearer the test date.";
var APPB2C_registration_date_check_step3_noSpeakingPinTitle2 = "Your speaking test PIN is not available yet";
var APPB2C_registration_date_check_step3_password2 = "Your password:";
var APPB2C_registration_date_check_step3_speakingPin2 = "Your Speaking test PIN:";
var APPB2C_registration_date_check_step3_step1_body2 = "You\u2019ll be asked to check if your microphone and camera are working.";
var APPB2C_registration_date_check_step3_step1_title2 = "Step 1 \u2013 Device check";
var APPB2C_registration_date_check_step3_step2_body2 = "You\u2019ll be asked to take a photo of your face and your identification document (make sure you have the same identification document you originally registered with).";
var APPB2C_registration_date_check_step3_step2_title2 = "Step 2 \u2013 Identification";
var APPB2C_registration_date_check_step3_step3_body2 = "To access your IELTS Online test, you will be asked for a PIN.";
var APPB2C_registration_date_check_step3_step3_title2 = "Step 3 \u2013 Test login";
var APPB2C_registration_date_check_step3_username2 = "Your username:";
var APPB2C_registration_date_check_step3_writtenPin2 = "Your Written test PIN:";
var APPB2C_registration_date_zone_country2 = "We localised you in";
var APPB2C_registration_date_zone_header2 = "When you booked your test";
var APPB2C_registration_date_zone_name2 = "Your timezone";
var APPB2C_registration_date_zone_time2 = "Your local time";
var APPB2C_registration_dateLocation_changeLinkIol2 = "I want to change my test";
var APPB2C_registration_dateLocation_changeLrwLink2 = "I want to change my written test";
var APPB2C_registration_dateLocation_changeSpeakingLink2 = "I want to change my speaking test";
var APPB2C_registration_dateLocation_location2 = "Location";
var APPB2C_registration_dateLocation_manage_cancel2 = "Cancel my booking";
var APPB2C_registration_dateLocation_manage_proceed = "Proceed";
var APPB2C_registration_dateLocation_manage_title2 = "Manage your booking";
var APPB2C_registration_dateLocation_manage_transferSpeaking2 = "Change speaking";
var APPB2C_registration_dateLocation_manage_transferWhole2 = "Transfer test date";
var APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest2 = "Keep speaking test";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody12 = "We just checked and no other speaking tests are available, sorry!";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody22 = "If you really need to change your speaking test, request a transfer of your test date, and then you\u2019ll be able to choose a new speaking test.";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader2 = "Sorry \u2013 no speaking tests are available!";
var APPB2C_registration_dateLocation_speakingTransfer_requestTransfer2 = "Request transfer instead";
var APPB2C_registration_incorrectOrganisation_homeLink2 = "Show me Test Taker Portal without organization context";
var APPB2C_registration_incorrectOrganisation_line12 = "It looks like you\u2019re trying to use the Test Taker Portal in a context of an organization we don\u2019t recognize.";
var APPB2C_registration_incorrectOrganisation_line22 = "Please try following the original link that led you here again or simply use the TTP without any context:";
var APPB2C_registration_payments_actions_editProofCTA2 = "Edit proof of payment";
var APPB2C_registration_payments_actions_header2 = "How would you like to pay?";
var APPB2C_registration_payments_actions_paymentInProgressInfo2 = "Previous attempt to pay online is still being processed. Please wait for the result.";
var APPB2C_registration_payments_actions_payOfflineCTA2 = "Pay another way";
var APPB2C_registration_payments_actions_payOnlineCTA2 = "Pay online";
var APPB2C_registration_payments_actions_subHeader2 = "I\u2019ve already paid and need to upload my payment proof";
var APPB2C_registration_payments_actions_uploadProofCTA2 = "Upload payment proof";
var APPB2C_registration_payments_awaitingPayment2 = "Awaiting payment";
var APPB2C_registration_payments_back2 = "Back to test";
var APPB2C_registration_payments_completed2 = "Completed";
var APPB2C_registration_payments_expired2 = "Expired";
var APPB2C_registration_payments_finishPayment_error_cta2 = "Refresh page";
var APPB2C_registration_payments_finishPayment_error_messageLine12 = "We\u2019ve encountered an error while checking your payment status. Please try to refresh the page in a few moments.";
var APPB2C_registration_payments_finishPayment_error_messageLine22 = "If this issue persists, please go back to your payment options.";
var APPB2C_registration_payments_finishPayment_error_title2 = "Unknown payment status";
var APPB2C_registration_payments_finishPayment_failure_alert2 = "Sorry \u2013 your online payment failed!";
var APPB2C_registration_payments_finishPayment_failure_cta2 = "View payment options";
var APPB2C_registration_payments_finishPayment_failure_message2 = "Please go back to your payment options and try again.";
var APPB2C_registration_payments_finishPayment_failure_title2 = "Payment failed";
var APPB2C_registration_payments_finishPayment_pleaseWait2 = "Please wait";
var APPB2C_registration_payments_finishPayment_processing2 = "We are processing your payment...";
var APPB2C_registration_payments_finishPayment_success_alert2 = "Your payment has been successfully processed!";
var APPB2C_registration_payments_finishPayment_success_cta2 = "View this test";
var APPB2C_registration_payments_finishPayment_success_message2 = "We have sent you a confirmation of this payment to your email address.";
var APPB2C_registration_payments_finishPayment_success_title2 = "Payment confirmation";
var APPB2C_registration_payments_offlineDetails_accountName2 = "Account name";
var APPB2C_registration_payments_offlineDetails_accountNumber2 = "Account number";
var APPB2C_registration_payments_offlineDetails_payBy2 = "Pay by";
var APPB2C_registration_payments_offlineDetails_sortCode2 = "Sort code";
var APPB2C_registration_payments_paidByAgent2 = "Registration paid for by the agent";
var APPB2C_registration_payments_proofPreview_fileAlt2 = "Uploaded proof of payment";
var APPB2C_registration_payments_proofPreview_proofLabel2 = "Proof of payment:";
var APPB2C_registration_payments_proofUpload_cta2 = "Save your files";
var APPB2C_registration_payments_proofUpload_info2 = "You can upload 2 files (you must upload at least 1)";
var APPB2C_registration_payments_proofUpload_intro2 = "If you have already paid this fee you can upload proof of payment below.";
var APPB2C_registration_payments_proofUpload_rules_intro2 = "Your proof of payment must clearly show:";
var APPB2C_registration_payments_proofUpload_rules_rule12 = "the payee name";
var APPB2C_registration_payments_proofUpload_rules_rule22 = "the payee account number";
var APPB2C_registration_payments_proofUpload_rules_rule32 = "the amount paid";
var APPB2C_registration_payments_proofUpload_rules_rule42 = "the date it was paid";
var APPB2C_registration_payments_proofUpload_rules_rule52 = "the British Council account number";
var APPB2C_registration_payments_transaction_cancelledSuffix2 = "(cancelled)";
var APPB2C_registration_payments_transaction_deadlineWarning_default2 = "You have until {{date}} to pay this fee.";
var APPB2C_registration_payments_transaction_deadlineWarning_eor2 = "You have until {{date}} to pay this fee. After this date, your Enquiry On Results (EOR) will be cancelled.";
var APPB2C_registration_payments_transaction_deadlineWarning_registration2 = "You have until {{date}} to pay this fee. After this date, your registration will be cancelled.";
var APPB2C_registration_payments_transaction_description_appeal2 = "Appeal";
var APPB2C_registration_payments_transaction_description_eor2 = "EOR";
var APPB2C_registration_payments_transaction_description_registration2 = "Test Registration";
var APPB2C_registration_payments_transaction_description_transfer2 = "Transfer";
var APPB2C_registration_payments_transaction_feeLabel2 = "Fee";
var APPB2C_registration_payments_transaction_header2 = "Payment for";
var APPB2C_registration_payments_transaction_hideDetails2 = "Hide details";
var APPB2C_registration_payments_transaction_outdatedTermsLabel2 = "View or print the <0>Terms and Conditions</0> for your registration";
var APPB2C_registration_payments_transaction_pastDeadline2 = "Payment for this item has not been registered in our system.";
var APPB2C_registration_payments_transaction_pastDeadlineContactUs2 = "If you believe this is an error and can provide proof that the payment has been made on time, please contact us:";
var APPB2C_registration_payments_transaction_pastDeadlineEmail2 = "Email";
var APPB2C_registration_payments_transaction_pastDeadlinePhone2 = "Phone";
var APPB2C_registration_payments_transaction_promoCode2 = "Promo code";
var APPB2C_registration_payments_transaction_receipt2 = "Receipt";
var APPB2C_registration_payments_transaction_receiptCta2 = "You can download here";
var APPB2C_registration_payments_transaction_referenceLabel2 = "Payment reference";
var APPB2C_registration_payments_transaction_showDetails2 = "Show details";
var APPB2C_registration_payments_transaction_taxLabel2 = "Tax";
var APPB2C_registration_payments_transaction_termsLabel2 = "View or print the <0>Terms and Conditions</0> for your registration";
var APPB2C_registration_payments_transaction_totalLabel2 = "Total";
var APPB2C_registration_payments_transaction_underReviewSuffix2 = "(under review)";
var APPB2C_registration_payments_transaction_unpaidSuffix2 = "(unpaid)";
var APPB2C_registration_payments_unpaid2 = "Unpaid";
var APPB2C_registration_prepare_a1_cta2 = "Prepare for IELTS Life Skills \u2013 level A1";
var APPB2C_registration_prepare_a1_header2 = "Level A1";
var APPB2C_registration_prepare_a2_cta2 = "Prepare for IELTS Life Skills \u2013 level A2";
var APPB2C_registration_prepare_a2_header2 = "Level A2";
var APPB2C_registration_prepare_b1_cta2 = "Prepare for IELTS Life Skills \u2013 level B1";
var APPB2C_registration_prepare_b1_header2 = "Level B1";
var APPB2C_registration_prepare_blog_body_cta2 = "Access IELTS Opportunities Abroad blog";
var APPB2C_registration_prepare_blog_body_line12 = "This blog gives Experts\u2019 advice and tips on the IELTS test, plus an overview of US campuses.";
var APPB2C_registration_prepare_blog_header2 = "IELTS Opportunities Abroad blog";
var APPB2C_registration_prepare_cdFamiliarTest_body_cta2 = "Access CD IELTS Familiarisation Test";
var APPB2C_registration_prepare_cdFamiliarTest_body_line12 = "Free full sample version of the computer-delivered IELTS test \u2013 the Listening, Reading and Writing sections provide 2 hour 30 minutes of questions, exactly like the real IELTS test.";
var APPB2C_registration_prepare_cdFamiliarTest_header2 = "CD IELTS Familiarisation Test";
var APPB2C_registration_prepare_cdFamiliarVideos_body_cta2 = "Access Computer Delivered IELTS familiarisation videos";
var APPB2C_registration_prepare_cdFamiliarVideos_body_line12 = "These videos will help you understand how the Listening, Reading and Writing sections appear on the computer.";
var APPB2C_registration_prepare_cdFamiliarVideos_header2 = "Computer Delivered IELTS familiarisation video";
var APPB2C_registration_prepare_cdTests_body_cta2 = "Access Computer Delivered IELTS practice tests";
var APPB2C_registration_prepare_cdTests_body_line12 = "These sample test questions will prepare you for the test and help you decide which option you prefer.";
var APPB2C_registration_prepare_cdTests_header2 = "Computer Delivered IELTS practice tests";
var APPB2C_registration_prepare_cdTipsVideos_body_cta2 = "Access Computer Delivered IELTS tips videos";
var APPB2C_registration_prepare_cdTipsVideos_body_line12 = "Tips on computer-delivered IELTS.";
var APPB2C_registration_prepare_cdTipsVideos_header2 = "Computer Delivered IELTS tips videos";
var APPB2C_registration_prepare_facebook_body_cta2 = "Ask the Expert (Facebook)";
var APPB2C_registration_prepare_facebook_body_line12 = "Three live chats every month by an IELTS expert on Facebook, with on-the-spot answers to audience questions.";
var APPB2C_registration_prepare_facebook_header2 = "Ask the Expert (Facebook)";
var APPB2C_registration_prepare_gel_disabledMsg2 = "Access to <0>IELTS Ready Premium</0> is blocked by your test centre. Please contact them to be given access to the British Council's free preparation services.";
var APPB2C_registration_prepare_gel_ielts_body_cta2 = "Get access";
var APPB2C_registration_prepare_gel_ielts_body_line12 = "Personalised, smart support that works with you and your learning needs to help you reach your required IELTS score. Including mock tests and practice resources for all our skills. Powered by GEL IELTS Prep, <0>IELTS Ready Premium</0> will help you succeed.";
var APPB2C_registration_prepare_gel_ielts_header2 = "<0>IELTS Ready Premium</0>";
var APPB2C_registration_prepare_gel_ukvi_body_cta = "Access IELTS Ready Premium now";
var APPB2C_registration_prepare_gel_ukvi_body_line12 = "Personalised, smart support that works with you and your learning needs to help you reach your required IELTS for UKVI score. Including mock tests and practice resources for all our skills. Powered by GEL IELTS Prep, <0>IELTS Ready Premium</0> will help you succeed.";
var APPB2C_registration_prepare_gel_ukvi_header2 = "<0>IELTS Ready Premium</0> for UKVI";
var APPB2C_registration_prepare_header2 = "Prepare for your test";
var APPB2C_registration_prepare_ieltsReady_subTitle2 = "You have access to the following preparation services:";
var APPB2C_registration_prepare_ieltsReady_title2 = "Choose your preparation service";
var APPB2C_registration_prepare_ieltsReadyMember_body2 = "Wide range of support materials including practice tests, preparation webinars, tips, videos, Road to IELTS content and much more. Our <0>IELTS Ready Member</0> area will help you prepare for your IELTS test with confidence.";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn2 = "Book a new test";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo2 = "To access <0>IELTS Ready Premium</0> for free you need to have an upcoming test booked.";
var APPB2C_registration_prepare_ieltsReadyMember_freePrepLink2 = "Free preparation materials are available on our <0>Take IELTS website</0>";
var APPB2C_registration_prepare_ieltsReadyMember_header2 = "<0>IELTS Ready Member</0>";
var APPB2C_registration_prepare_ieltsReadyMember_label2 = "Free for everyone";
var APPB2C_registration_prepare_ieltsReadyMember_link2 = "Access for free";
var APPB2C_registration_prepare_ieltsReadyMember_payBtn2 = "Pay for your test";
var APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo2 = "To access <0>IELTS Ready Premium</0> for free you need to have paid for your upcoming test.";
var APPB2C_registration_prepare_intro2 = "We can help you prepare for IELTS with our range of free resources.";
var APPB2C_registration_prepare_introLifeSkills2 = "We can help you prepare for Life Skills with our range of free resources.";
var APPB2C_registration_prepare_onlineCourse_body_cta2 = "Start your free trial now";
var APPB2C_registration_prepare_onlineCourse_body_line12 = "Our four Understanding IELTS ExpertTrack courses will give you a complete guide to everything you need to know as you prepare for the IELTS test. You\u2019ll get a deep dive into each section of the IELTS test \u2013 Reading, Writing, Listening and Speaking. You\u2019ll also receive advice from our video tutor and a team of experienced IELTS educators. Join a free 7-day trial which provides you with access to the full suite of courses and assessments for a week.";
var APPB2C_registration_prepare_onlineCourse_header2 = "Online Course: Understanding IELTS";
var APPB2C_registration_prepare_pbPractice_body_cta2 = "Access Practice tests";
var APPB2C_registration_prepare_pbPractice_body_line12 = "Use these tests for timed practice sessions. You can also review and compare answers.";
var APPB2C_registration_prepare_pbPractice_header2 = "Practice papers";
var APPB2C_registration_prepare_practice_body_cta2 = "Access practice tests";
var APPB2C_registration_prepare_practice_body_line12 = "Take advantage of our free practice tests to get the IELTS score you need. If you prefer to practice offline, download the tests, blank answer sheets, transcripts and answers from the introductory pages.";
var APPB2C_registration_prepare_practice_header2 = "Practice tests";
var APPB2C_registration_prepare_prepApp_body_cta2 = "Access IELTS Prep App";
var APPB2C_registration_prepare_prepApp_body_line12 = "The IELTS App has sample questions, practice tests, grammar tips, exercises, quizzes and more. You can also use it to track your progress.";
var APPB2C_registration_prepare_prepApp_header2 = "IELTS Prep App";
var APPB2C_registration_prepare_prepApp_ukvi_cta12 = "iOS";
var APPB2C_registration_prepare_prepApp_ukvi_cta22 = "Android";
var APPB2C_registration_prepare_prepApp_ukvi_line12 = "While focusing on Listening and Speaking parts of the test, this app will also help you expand your vocabulary, practice grammar and understand what to do on your test day. You will be able to test your knowledge by doing grammar exercises, Listening and Speaking practice tests and watching videos of IELTS Speaking interviews.";
var APPB2C_registration_prepare_prepApp_ukvi_line22 = "Download for:";
var APPB2C_registration_prepare_prepVideos_body_cta2 = "Access IELTS preparation videos";
var APPB2C_registration_prepare_prepVideos_body_line12 = "Get to know the test format through a series of videos.";
var APPB2C_registration_prepare_prepVideos_header2 = "IELTS preparation videos";
var APPB2C_registration_prepare_reading_body_cta2 = "Access Understanding IELTS: Reading";
var APPB2C_registration_prepare_reading_body_line12 = "Find out what you need to know about the IELTS Reading test to improve your test performance.";
var APPB2C_registration_prepare_reading_header2 = "Understanding IELTS: Reading";
var APPB2C_registration_prepare_rteLastMinute_body_cta2 = "Start now";
var APPB2C_registration_prepare_rteLastMinute_body_item12 = "9 videos giving advice and tutorials,";
var APPB2C_registration_prepare_rteLastMinute_body_item22 = "100 interactive activities,";
var APPB2C_registration_prepare_rteLastMinute_body_item32 = "2 practice tests for each of the 4 skills.";
var APPB2C_registration_prepare_rteLastMinute_body_line12 = "Get ready for your IELTS test with:";
var APPB2C_registration_prepare_rteLastMinute_header2 = "Road to IELTS \u2013 Last Minute";
var APPB2C_registration_prepare_rteLastMinute_ukvi_cta2 = "Access the course";
var APPB2C_registration_prepare_rteLastMinute_ukvi_line12 = "Get unlimited access to Road to IELTS \u2013 Last Minute Course, British Council\u2019s popular online preparation course. Watch videos giving advice and tutorials, use 100 interactive activities and take two practice tests for each of the four core skills.";
var APPB2C_registration_prepare_rteTestDrive_body_cta2 = "Try Road to IELTS test drive version";
var APPB2C_registration_prepare_rteTestDrive_body_item12 = "30 interactive exercises,";
var APPB2C_registration_prepare_rteTestDrive_body_item22 = "4 videos giving tips and advice,";
var APPB2C_registration_prepare_rteTestDrive_body_item32 = "practice test for each of the 4 skills.";
var APPB2C_registration_prepare_rteTestDrive_body_line12 = "Jump start your IELTS journey with Road to IELTS Test Drive and get:";
var APPB2C_registration_prepare_rteTestDrive_body_line22 = "You will also get more materials once registration is complete.";
var APPB2C_registration_prepare_rteTestDrive_header2 = "Road to IELTS Test Drive \u2013 Free Version";
var APPB2C_registration_prepare_speaking_body_cta2 = "Access Understanding IELTS: Speaking";
var APPB2C_registration_prepare_speaking_body_line12 = "Find out what you need to know about the IELTS Speaking test to improve your test performance.";
var APPB2C_registration_prepare_speaking_header2 = "Understanding IELTS: Speaking";
var APPB2C_registration_prepare_tutor_body_cta2 = "Click here to try your first session for $1 and avail this offer.";
var APPB2C_registration_prepare_tutor_body_line12 = "Prepare for your IELTS test with a professional IELTS tutor. With a number of courses to choose from, you can get ready for your IELTS if it\u2019s a week, or a few months away. Try your first session today for $1.";
var APPB2C_registration_prepare_tutor_body_line22 = "Exclusive Offer: As an IELTS test taker with the British Council you get 10% off when you purchase one of our 1-to-1 preparation courses.";
var APPB2C_registration_prepare_tutor_header2 = "1-to-1 tutor \u2013 IELTS Preparation Course";
var APPB2C_registration_prepare_webinars_body_cta2 = "Sign up for access";
var APPB2C_registration_prepare_webinars_body_item12 = "Guidance videos";
var APPB2C_registration_prepare_webinars_body_item22 = "Detailed study plans for all four skills";
var APPB2C_registration_prepare_webinars_body_item32 = "Practice tests";
var APPB2C_registration_prepare_webinars_body_line12 = "Get test ready with free weekly IELTS webinars conducted by our IELTS experts. You\u2019ll also get access to our IELTS Study Pack with:";
var APPB2C_registration_prepare_webinars_header2 = "IELTS Webinars";
var APPB2C_registration_results_bandScore_osrBooked2 = "Retake booked <1>View booking</1>";
var APPB2C_registration_results_bandScore_osrMarked2 = "Retake marked <1>View result</1>";
var APPB2C_registration_results_eor_acknowledgement_backButton2 = "View this test";
var APPB2C_registration_results_eor_acknowledgement_components2 = "You have selected the following parts of your test to be re-marked:";
var APPB2C_registration_results_eor_acknowledgement_confirmation2 = "We have sent you a confirmation of this EOR booking to your email address.";
var APPB2C_registration_results_eor_acknowledgement_fee2 = "Fee";
var APPB2C_registration_results_eor_acknowledgement_info2 = "Your Enquiry On Results (EOR) has been cancelled.";
var APPB2C_registration_results_eor_acknowledgement_noteTitle2 = "Please note:";
var APPB2C_registration_results_eor_acknowledgement_payment2 = "Completed payment";
var APPB2C_registration_results_eor_acknowledgement_paymentButton2 = "See your payment options";
var APPB2C_registration_results_eor_acknowledgement_paymentNote2 = "You still have to settle the payment or your request will be cancelled";
var APPB2C_registration_results_eor_acknowledgement_reference2 = "EOR reference";
var APPB2C_registration_results_eor_acknowledgement_success2 = "Your Enquiry On Results (EOR) has been booked and a decision is pending.";
var APPB2C_registration_results_eor_acknowledgement_tax2 = "Tax";
var APPB2C_registration_results_eor_acknowledgement_terms2 = "You agreed to these <0>terms and conditions</0>";
var APPB2C_registration_results_eor_acknowledgement_title2 = "EOR Acknowledgement";
var APPB2C_registration_results_eor_acknowledgement_total2 = "Total";
var APPB2C_registration_results_eor_postDeadline_back2 = "I don\u2019t need my test to be re-marked";
var APPB2C_registration_results_eor_postDeadline_email2 = "Email";
var APPB2C_registration_results_eor_postDeadline_help2 = "Please quote your registration reference number for us to help you better:";
var APPB2C_registration_results_eor_postDeadline_iol_launchChatLine2 = "I need to chat to somebody online about this:";
var APPB2C_registration_results_eor_postDeadline_iol_line32 = "If you wish for us to consider your EOR, please contact us to provide details to support this request.";
var APPB2C_registration_results_eor_postDeadline_line12 = "A request for a re-mark (called an Enquiry on Results, or \u2018EOR\u2019) must be requested no later than {{count}} days from the date of your test.";
var APPB2C_registration_results_eor_postDeadline_line22 = "As the deadline for an EOR application has passed, your request cannot be processed online.";
var APPB2C_registration_results_eor_postDeadline_line32 = "If you wish the center to consider your EOR, please contact the test center to provide details to support this request.";
var APPB2C_registration_results_eor_postDeadline_phone2 = "Phone";
var APPB2C_registration_results_eor_preDeadline_back2 = "Back to result";
var APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength2 = "Your explanation can be at most {{count}} characters long.";
var APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber2 = "Please provide a valid Test Report Form (TRF) Number.";
var APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber2 = "The Test Report Form (TRF) Number has to match specific format (e.g. 11AA111110AAAA001A).";
var APPB2C_registration_results_eor_preDeadline_evidence2 = "Please upload evidence that supports your request for a re-mark.";
var APPB2C_registration_results_eor_preDeadline_evidenceRequired2 = "You must provide this evidence to support your request.";
var APPB2C_registration_results_eor_preDeadline_explanation2 = "Please provide a brief explanation below why you believe you need your selected test parts re-marked";
var APPB2C_registration_results_eor_preDeadline_explanationRequired2 = "You must provide this explanation to support your request.";
var APPB2C_registration_results_eor_preDeadline_fee2 = "Fee";
var APPB2C_registration_results_eor_preDeadline_iolNote_line1 = "The re-marking is done by trained senior Examiners.";
var APPB2C_registration_results_eor_preDeadline_iolNote_line22 = "The outcome can become available on the same day as your application and up to 21 days after your application, depending on several factors including the number of sections requested for the re-mark.";
var APPB2C_registration_results_eor_preDeadline_iolNote_line32 = "If you have not received a response after 21 days, please <0>contact us</0>.";
var APPB2C_registration_results_eor_preDeadline_legal2 = "I agree to the EOR <0>terms and conditions</0>";
var APPB2C_registration_results_eor_preDeadline_legalNote2 = "IELTS USA will use the information that you are providing in connection with processing your EOR. The legal basis for processing your information is agreement with our terms and conditions of EOR.";
var APPB2C_registration_results_eor_preDeadline_line12 = "If you\u2019re not happy with the way your test was marked, and you would like to have any section(s) of the test re-marked, you can apply for an Enquiry on Results, up to {{count}} days after your test date.";
var APPB2C_registration_results_eor_preDeadline_line22 = "There is a charge for an enquiry on IELTS test results, however this charge will be refunded if your score increases for any selected section of the test.";
var APPB2C_registration_results_eor_preDeadline_maxScore2 = "You received a full mark for this part. You cannot request a re-mark.";
var APPB2C_registration_results_eor_preDeadline_note2 = "Once you\u2019ve paid, you cannot cancel or change your EOR request.";
var APPB2C_registration_results_eor_preDeadline_noteTitle2 = "Please note:";
var APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel2 = "Who should receive the order confirmation for the EOR?";
var APPB2C_registration_results_eor_preDeadline_paymentCta2 = "View payment options";
var APPB2C_registration_results_eor_preDeadline_paymentTitle2 = "Paying for your re-mark";
var APPB2C_registration_results_eor_preDeadline_payOfflineCTA2 = "Pay another way";
var APPB2C_registration_results_eor_preDeadline_payOnlineCTA2 = "Pay online";
var APPB2C_registration_results_eor_preDeadline_scoresRequired2 = "Please select at least one part.";
var APPB2C_registration_results_eor_preDeadline_scoresTitle2 = "Please choose one or more parts of your test to be re-marked:";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr2 = "You have chosen to enquire about your";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening2 = "Listening Retake result";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading2 = "Reading Retake result";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking2 = "Speaking Retake result";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing2 = "Writing Retake result";
var APPB2C_registration_results_eor_preDeadline_tax2 = "inc. tax";
var APPB2C_registration_results_eor_preDeadline_trfNumberLabel2 = "Test Report Form (TRF) Number";
var APPB2C_registration_results_eor_preDeadline_trfNumberSubnote2 = "Please provide the 15\u201318 digit number found in the bottom right-hand corner of every Test Report Form.";
var APPB2C_registration_results_eor_preDeadline_trfNumberValidation2 = "Field has to match specific format (e.g. 11AA111110AAAA001A).";
var APPB2C_registration_results_eor_title2 = "Re-marking your test (Enquiry on Results)";
var APPB2C_registration_results_eor_titlePayment2 = "Paying for your re-mark (EOR)";
var APPB2C_registration_results_hasResults_bandScore_decrease2 = "decrease";
var APPB2C_registration_results_hasResults_bandScore_increase2 = "increase";
var APPB2C_registration_results_hasResults_bandScore_unchanged2 = "unchanged";
var APPB2C_registration_results_hasResults_eorAgentInfo = "If you would like your test to be re-marked, please speak to your agent that is acting on your behalf for your test.";
var APPB2C_registration_results_hasResults_eorLetter2 = "Please check your <0>EOR Results Outcome Letter<1></1></0> for further details.";
var APPB2C_registration_results_hasResults_eorLine12 = "Your re-mark has been completed.";
var APPB2C_registration_results_hasResults_eorLine22 = "Your score is available below. Re-marked sections are highlighted for easy reference.";
var APPB2C_registration_results_hasResults_eorLink2 = "I would like my test to be re-marked";
var APPB2C_registration_results_hasResults_eTrfCta = "Download your TRF";
var APPB2C_registration_results_hasResults_explanations_heading2 = "Your scores explained:";
var APPB2C_registration_results_hasResults_explanations_hideButton = "Hide score explanation";
var APPB2C_registration_results_hasResults_explanations_improve2 = "How to improve:";
var APPB2C_registration_results_hasResults_explanations_showButton = "Show score explanation";
var APPB2C_registration_results_hasResults_lifeSkills_failed2 = "Failed";
var APPB2C_registration_results_hasResults_lifeSkills_level2 = "CEFR level";
var APPB2C_registration_results_hasResults_lifeSkills_note2 = "Your results available online are only provisional and should not be used as an official confirmation of your performance. The IELTS Test Report Form (TRF) is the official document confirming your results.";
var APPB2C_registration_results_hasResults_lifeSkills_passed2 = "Passed";
var APPB2C_registration_results_hasResults_lifeSkills_result2 = "Result";
var APPB2C_registration_results_hasResults_osr_bandScore_decrease = "decrease";
var APPB2C_registration_results_hasResults_osr_bandScore_increase = "increase";
var APPB2C_registration_results_hasResults_osr_bandScore_unchanged = "unchanged";
var APPB2C_registration_results_hasResults_osr_previousResults2 = "See previous test result in full";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased2 = "Your overall band score has decreased from {{originalScore}} to {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased2 = "Your overall band score has increased from {{originalScore}} to {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged2 = "Your overall band score remains unchanged at {{originalScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_title2 = "Your {{osrSkillName}} Retake result ({{score}}) has been counted towards your new overall band score.";
var APPB2C_registration_results_hasResults_osrLink = "I would like to retake one part of my test";
var APPB2C_registration_results_hasResults_posting_oneCopyWarning2 = "Only one copy is sent out, so keep it safe!";
var APPB2C_registration_results_hasResults_posting_postedOn2 = "Your Test Report Form has been posted on:";
var APPB2C_registration_results_hasResults_posting_postedTo2 = "It has been posted to:";
var APPB2C_registration_results_hasResults_posting_willPostOn2 = "Your Test Report Form will be posted on:";
var APPB2C_registration_results_hasResults_posting_willPostTo2 = "It will be posted to:";
var APPB2C_registration_results_hasResults_rankNameConsonant2 = "This means you\u2019re a";
var APPB2C_registration_results_hasResults_rankNameVowel2 = "This means you\u2019re an";
var APPB2C_registration_results_hasResults_scoreImprove_btn2 = "See my options";
var APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn2 = "Back to test result details";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description2 = "If you\u2019re not happy with your score on more than one section of the test, you can quickly book a new test with us.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title2 = "Book a new test";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description2 = "If you\u2019re not happy with the way your test was marked, and you would like to have any section(s) of the test re-marked, you can apply for an Enquiry on Results, up to {{count}} days after your test date.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title2 = "Re-marking your test";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description2 = "If you\u2019re not happy with your score on any one section of the test and believe this was due to your performance on test day, you can register for a One Skill Retake.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title2 = "Retake part of your test";
var APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle2 = "If you\u2019d like to improve your overall band score, you have the following options:";
var APPB2C_registration_results_hasResults_scoreImprove_subtitle2 = "If you didn\u2019t get the score you needed, you might be able to improve it.";
var APPB2C_registration_results_hasResults_scoreImprove_title2 = "Didn\u2019t get the score you needed?";
var APPB2C_registration_results_hasResults_scores_label2 = "Your full score and explanation";
var APPB2C_registration_results_hasResults_scores_Listening2 = "Listening";
var APPB2C_registration_results_hasResults_scores_Overall2 = "Your overall band score";
var APPB2C_registration_results_hasResults_scores_Reading2 = "Reading";
var APPB2C_registration_results_hasResults_scores_Speaking2 = "Speaking";
var APPB2C_registration_results_hasResults_scores_Writing2 = "Writing";
var APPB2C_registration_results_hasResults_share_applyBoard2 = "Apply directly to programs";
var APPB2C_registration_results_hasResults_share_RecognisingOrgsLink2 = "Share results with organizations";
var APPB2C_registration_results_hasResults_share_subtitle2 = "Here\u2019s what you can do now:";
var APPB2C_registration_results_hasResults_share_title2 = "Got the score you needed?";
var APPB2C_registration_results_hasResults_share_trfCta2 = "Download your Test Report Form";
var APPB2C_registration_results_hasResults_viewExplanation2 = "View full scores and explanation";
var APPB2C_registration_results_noResults_cmds_title2 = "To see information about your results, including when they will be available, click <0>here</0>. We will send you a link to access your results and download your electronic Test Report Form (TRF).";
var APPB2C_registration_results_noResults_note_title2 = "Please note:";
var APPB2C_registration_results_noResults_osr_booked2 = "Retake booked";
var APPB2C_registration_results_noResults_osr_previousScores2 = "Your previous scores:";
var APPB2C_registration_results_noResults_title2 = "To see information about your results, including when they will be available, click <0>here</0>. You will be sent a link to access your results online once they are ready.";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote12 = 'You can still choose another $t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} }) to have your results sent to.';
var APPB2C_registration_results_organisations_availableReceivingOrgsNote22 = "Test Results are available until {{date}}.";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote3 = "Organizations may have different rules for accepting a TRF, with a minimum validity period of two years. Please check with your organization before applying.";
var APPB2C_registration_results_organisations_chosenOrganisationsHeader2 = "Chosen organizations to send your results to";
var APPB2C_registration_results_organisations_editRo_addAnother2 = "+ Add another organization";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew2 = "+ Add a new organization";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_address2 = "Address:";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_country2 = "Choose a country / territory";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll2 = "All";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional2 = "Choose a country / territory: (optional)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_file2 = "File / case number: (optional)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_org2 = "Choose an organization";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation2 = "Organization name";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_person2 = "Name of Person / Department: (optional)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode2 = "Post code";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry2 = "Search by country / territory name";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg2 = "Search by organization name";
var APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength2 = "File / case number can be at most {{count}} characters long.";
var APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength2 = "Name of Person / Department can be at most {{count}} characters long.";
var APPB2C_registration_results_organisations_editRo_error_minScoreMissing2 = "Please choose the minimum score for your overall band.";
var APPB2C_registration_results_organisations_editRo_error_missingAddressLine12 = "Please provide the organisation address.";
var APPB2C_registration_results_organisations_editRo_error_missingOrgName2 = "Please provide the organisation name.";
var APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength2 = "Organisation name can be at most {{count}} characters long.";
var APPB2C_registration_results_organisations_editRo_iol_addCta2 = "Add organization";
var APPB2C_registration_results_organisations_editRo_iol_address2 = "Address:";
var APPB2C_registration_results_organisations_editRo_iol_cancelAdd = "Cancel";
var APPB2C_registration_results_organisations_editRo_iol_cancelEdit2 = "Discard changes";
var APPB2C_registration_results_organisations_editRo_iol_conditions_clear2 = "clear";
var APPB2C_registration_results_organisations_editRo_iol_conditions_header2 = "Would you like to choose conditional scores for this organization?";
var APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder2 = "Please choose";
var APPB2C_registration_results_organisations_editRo_iol_conditions_no2 = "No";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note12 = "Please choose the minimum overall band you want to score before this organization receives your result.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note22 = "You can also choose your minimum scores for each test component:";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note32 = "We will only send your results to this organization if you achieve every score you have set.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder2 = "Optional";
var APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader2 = "We would only send your results to them if you get the score(s) you want.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_yes2 = "Yes";
var APPB2C_registration_results_organisations_editRo_iol_copied2 = "Email address copied";
var APPB2C_registration_results_organisations_editRo_iol_copy2 = "Copy email address";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery2 = "This organization accepts your eTRF directly from us. It does not affect how many more organizations you can choose.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults2 = "eTRF";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note2 = "You can have your eTRF sent to as many organizations as you like, but you can only choose up to {{number}} TRF organizations.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal2 = "We will send this organisation your Test Report Form (TRF). <br>It counts towards your limit of {{number}} TRF organisations.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf2 = "TRF";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note12 = "This organization accepts IELTS Online, but is not registered to receive eTRF directly from us.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note22 = "When you receive your results, you will need to email a copy of your TRF to the organization.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results2 = "You will need to email a copy of your TRF to the organization.";
var APPB2C_registration_results_organisations_editRo_iol_newOrgTitle2 = "Add an organization";
var APPB2C_registration_results_organisations_editRo_iol_noResults2 = "No results";
var APPB2C_registration_results_organisations_editRo_iol_removeLink2 = "Remove organization";
var APPB2C_registration_results_organisations_editRo_iol_saveCta2 = "Save changes";
var APPB2C_registration_results_organisations_editRo_iol_validation_overallScore2 = "The minimal score for your overall band is required.";
var APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation2 = "Please choose an organization.";
var APPB2C_registration_results_organisations_iol_chooseCta2 = "Choose organizations";
var APPB2C_registration_results_organisations_iol_delivery_auto_header2 = "Here are the organizations you chose which will receive your eTRF automatically:";
var APPB2C_registration_results_organisations_iol_delivery_auto_note2 = "We will send these organizations your eTRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote2 = "We haven\u2019t sent these organizations your eTRF as you didn\u2019t achieve the minimum overall band you set for each of them.";
var APPB2C_registration_results_organisations_iol_delivery_auto_sentNote2 = "We have sent these organizations your eTRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_iol_delivery_postal_header2 = "Here are the organizations you chose which need manual delivery:";
var APPB2C_registration_results_organisations_iol_delivery_postal_note12 = "These organizations accept IELTS Online, but are not registered to receive eTRF directly from us.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note22 = "When you receive your results, you will need to email a copy of your TRF to each organization.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2_results2 = "You will need to email a copy of your TRF to each organization.";
var APPB2C_registration_results_organisations_iol_edit2 = "Change";
var APPB2C_registration_results_organisations_iol_error2 = "Something went wrong while loading changes.<0></0> Please try <1>refreshing</1> the page.";
var APPB2C_registration_results_organisations_iol_generalCondition2 = "We will only send this organization your results if your achieve this minimum overall band:";
var APPB2C_registration_results_organisations_iol_noConditions2 = "You did not set any conditional scores for this organization.";
var APPB2C_registration_results_organisations_iol_notChosen2 = "Not chosen";
var APPB2C_registration_results_organisations_iol_preciseCondition2 = "We will only send your results to this organization if you achieve every score you have set.";
var APPB2C_registration_results_organisations_iol_roAccept_link2 = "Please check which organizations accept IELTS Online test scores";
var APPB2C_registration_results_organisations_iol_roAccept_message2 = "If you take IELTS Online then you need to check whether your organization accepts this.";
var APPB2C_registration_results_organisations_iol_roAccept_title2 = "Does your intended organization or institute accept IELTS online?";
var APPB2C_registration_results_organisations_iol_sendAnyway2 = "Send my results to this organization anyway";
var APPB2C_registration_results_organisations_list_item12 = "academic institutions";
var APPB2C_registration_results_organisations_list_item22 = "government agencies";
var APPB2C_registration_results_organisations_list_item32 = "professional bodies";
var APPB2C_registration_results_organisations_list_item42 = "employers";
var APPB2C_registration_results_organisations_mod_delivery_auto_header2 = "Here are the organizations you chose which will receive your eTRF automatically:";
var APPB2C_registration_results_organisations_mod_delivery_auto_note2 = "We will send these organizations your eTRF for you. You don\u2019t need to do anything. You can choose as many of these as you like.";
var APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote2 = "We haven\u2019t sent these organizations your eTRF as you didn\u2019t achieve the minimum overall band you set for each of them.";
var APPB2C_registration_results_organisations_mod_delivery_auto_sentNote2 = "We have sent these organizations your eTRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_mod_delivery_postal_header2 = "Here are the organizations you chose that we will post your TRF to:";
var APPB2C_registration_results_organisations_mod_delivery_postal_note12 = "We will post your Test Report Form (TRF) to these organizations. You can only choose up to {{count}} of these.";
var APPB2C_registration_results_organisations_mod_delivery_postal_note22 = "If you can\u2019t find an organization in our list, you can add your own. They will be counted as one of your TRF choices.";
var APPB2C_registration_results_organisations_mod_delivery_postal_note32 = 'You can still choose another $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} }) to have your results sent to.';
var APPB2C_registration_results_organisations_mod_delivery_postal_note42 = 'Now that you\u2019ve chosen $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} }) you can\u2019t choose anymore. You can still choose organizations that accept eTRF.';
var APPB2C_registration_results_organisations_mod_delivery_postal_note52 = "You have reached the limit for organizations we can send your results to.";
var APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote2 = "We haven\u2019t sent these organizations your TRF as you didn\u2019t achieve the minimum overall band you set for each of them.";
var APPB2C_registration_results_organisations_mod_delivery_postal_sentNote2 = "We have sent these organizations your TRF for you. You don\u2019t need to do anything.";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount2 = "{{count}} TRF organization";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other2 = "{{count}} TRF organizations";
var APPB2C_registration_results_organisations_mod_howTo_item12 = "<bold>eTRF</bold>: Organizations that accept your electronic Test Report Form (eTRF) directly from us. You can choose as many of these as you like.";
var APPB2C_registration_results_organisations_mod_howTo_item22 = "<bold>TRF</bold>: Organizations that we send your Test Report Form (TRF) to on your behalf. You can only choose <bold>up to {{number}}</bold> of these.";
var APPB2C_registration_results_organisations_mod_howTo_line12 = "You can choose from two types of organizations";
var APPB2C_registration_results_organisations_mod_howTo_title2 = "How to choose your organizations";
var APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote2 = "No organizations have been chosen.";
var APPB2C_registration_results_organisations_note02 = "We can send your results to organizations on your behalf.";
var APPB2C_registration_results_organisations_note12 = 'We can send your results to up to $t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} }).';
var APPB2C_registration_results_organisations_note22 = "These can include:";
var APPB2C_registration_results_organisations_organisationsCount2 = "{{count}} organization";
var APPB2C_registration_results_organisations_organisationsCount_other2 = "{{count}} organizations";
var APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote2 = "You have reached the limit for Additional TRFs requests and/or the deadline to apply has passed.";
var APPB2C_registration_results_organisations_title2 = "Sharing your results with organizations";
var APPB2C_registration_results_osr_chooseDate_accessibility2 = "I have <bold>accessibility</bold> needs for the test date";
var APPB2C_registration_results_osr_chooseDate_accessibilityLink2 = "Read about how we can help you";
var APPB2C_registration_results_osr_chooseDate_chooseDateTitle2 = "Choose your {{skillName}} Retake date";
var APPB2C_registration_results_osr_chooseDate_note_content2 = "We can only offer you a retake date within 60 days of your original test date ({{date}}).";
var APPB2C_registration_results_osr_chooseDate_note_title2 = "Please note:";
var APPB2C_registration_results_osr_chooseDate_retakeListening2 = "Listening Retake";
var APPB2C_registration_results_osr_chooseDate_retakeReading2 = "Reading Retake";
var APPB2C_registration_results_osr_chooseDate_retakeSpeaking2 = "Speaking Retake";
var APPB2C_registration_results_osr_chooseDate_retakeWriting2 = "Writing Retake";
var APPB2C_registration_results_osr_chooseSkill_backBtn2 = "Back to results";
var APPB2C_registration_results_osr_chooseSkill_chooseDateBtn2 = "Choose date of One Skill Retake";
var APPB2C_registration_results_osr_chooseSkill_confirmationRequired2 = "Please confirm that you have checked that intended organization(s) accept IELTS retakes.";
var APPB2C_registration_results_osr_chooseSkill_formTitle2 = "Please choose which part of your test to retake";
var APPB2C_registration_results_osr_chooseSkill_modal_contact2 = "Please contact us directly using the details below to find out how we can help you.";
var APPB2C_registration_results_osr_chooseSkill_modal_description2 = "We don\u2019t have any dates that you can book for your selected One Skill Retake.";
var APPB2C_registration_results_osr_chooseSkill_modal_email2 = "Email";
var APPB2C_registration_results_osr_chooseSkill_modal_phone2 = "Phone";
var APPB2C_registration_results_osr_chooseSkill_modal_title2 = "Sorry, we don\u2019t have any available dates";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation2 = "I confirm that my chosen institution accepts IELTS One Skill Retake.";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description2 = "Before you book a test with us, you should <0>check that your chosen institution accepts the relevant IELTS test version.</0>";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title2 = "Does the institution you intend to apply to accept IELTS One Skill Retake?";
var APPB2C_registration_results_osr_chooseSkill_skillRequired2 = "Please select a skill you wish to retake";
var APPB2C_registration_results_osr_chooseTest_availableDates2 = "Available dates";
var APPB2C_registration_results_osr_chooseTest_speaking_time2 = "Change time";
var APPB2C_registration_results_osr_error_missingFile2 = "There is a problem with files uploaded to your original registration. Please contact the test centre for further assistance.";
var APPB2C_registration_results_osr_error_noTestDates2 = "There are no test dates available in this location anymore. Please choose a different one or contact the test centre.";
var APPB2C_registration_results_osr_error_noTests2 = "There are no test dates available in the time period and location you have selected anymore. Please <0>go back</0> and change your search parameters.";
var APPB2C_registration_results_osr_line12 = "If you need a second chance to improve your IELTS score, you can retake the one part of your test you were unhappy with.";
var APPB2C_registration_results_osr_line22 = "This means all the great work you\u2019ve already done isn\u2019t wasted.";
var APPB2C_registration_results_osr_line32 = "Which part of your test score (shown above) would you like to retake, and when would you like to retake it?";
var APPB2C_registration_results_osr_line42 = "We will aim to respond to all requests by email within 48-72 hours.";
var APPB2C_registration_results_osr_line52 = "The British Council will use the information that you are providing in connection with processing your request. For detailed information, please refer to the <0>privacy section of our website</0>";
var APPB2C_registration_results_osr_review_note2 = "Please note, your personal details below have been retrieved from your original IELTS booking. These cannot be changed for your One Skill Retake.";
var APPB2C_registration_results_osr_review_note_testDay2 = "On the test day, you will be required to bring the same identification document you used to register with. If you do not have the original test ID with you, please contact your test centre.";
var APPB2C_registration_results_osr_submitBtn2 = "Submit retake request";
var APPB2C_registration_results_osr_successBanner2 = "We have received your IELTS One Skill Retake request.";
var APPB2C_registration_results_osr_title2 = "Retake one part of your test";
var APPB2C_registration_results_ttAbsent_btn2 = "Rebook this test with different dates";
var APPB2C_registration_results_ttAbsent_msg2 = "No results for this test can be made available as the Test Taker was absent on the test date.";
var APPB2C_registration_results_updateBar_osr_booked2 = "You have booked to retake your {{osrSkillName}} test. <1>View this booking</1>";
var APPB2C_registration_results_updateBar_osr_marked2 = "Your retake of your {{osrSkillName}} test has been marked. <1>View result</1>";
var APPB2C_registration_tabs_bookingDetails2 = "Booking details";
var APPB2C_registration_tabs_candidate2 = "Test Taker";
var APPB2C_registration_tabs_date2 = "Date";
var APPB2C_registration_tabs_dateLocation2 = "Date & location";
var APPB2C_registration_tabs_payments2 = "Payments";
var APPB2C_registration_tabs_recognisingOrgs2 = "Recognizing Organizations";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_12 = "Please contact the test center directly about sending your results to Recognizing Organizations:";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_22 = "Please quote your registration reference number for us to help you better:";
var APPB2C_registration_tabs_results2 = "Results";
var APPB2C_registration_testTaker_bookedFor_completed2 = "This test was taken by:";
var APPB2C_registration_testTaker_bookedFor_dob2 = "Date of Birth";
var APPB2C_registration_testTaker_bookedFor_email2 = "Email address";
var APPB2C_registration_testTaker_bookedFor_gender2 = "Sex";
var APPB2C_registration_testTaker_bookedFor_name2 = "Name";
var APPB2C_registration_testTaker_bookedFor_parent2 = "Parent / Guardian:";
var APPB2C_registration_testTaker_bookedFor_title2 = "This test is booked for:";
var APPB2C_registration_testTaker_childConsent_back2 = "I don\u2019t want to edit dependent consent";
var APPB2C_registration_testTaker_childConsent_context2 = "Do you give permission for your dependent to leave the test premises unaccompanied during a test break between modules and at the end of the test?";
var APPB2C_registration_testTaker_childConsent_cta2 = "Save consent details";
var APPB2C_registration_testTaker_childConsent_options_12 = "Yes; I give my permission";
var APPB2C_registration_testTaker_childConsent_options_22 = "No; I will pick them up";
var APPB2C_registration_testTaker_childConsent_options_32 = "No; I want to specify somebody else who will pick them up";
var APPB2C_registration_testTaker_childConsent_somebodyElse_add2 = "+ Add another person";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox2 = "Yes, I confirm";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_12 = "Do you confirm that the person(s) listed is authorized to pick up";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_22 = "at the end of the test, and the person(s) listed is at least 18 years old?";
var APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName2 = "They don\u2019t have a surname / family name";
var APPB2C_registration_testTaker_childConsent_somebodyElse_note2 = "Please provide the details for the person(s) picking up the dependent. If different people are picking up the dependent on the day of the Written test and the day of the Speaking test, please add their names below.";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phone2 = "Telephone number";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote2 = "In case of an emergency";
var APPB2C_registration_testTaker_childConsent_somebodyElse_policy2 = "Read our full dependent safe collection protection policy";
var APPB2C_registration_testTaker_childConsent_somebodyElse_relationship2 = "Relationship to the dependent";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_12 = "About the person(s) picking up the dependent";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_22 = "About the second person";
var APPB2C_registration_testTaker_childConsent_title2 = "Dependent consent";
var APPB2C_registration_testTaker_childConsent_warning2 = "Dependents aged 11 years or older are only permitted to leave test premises alone once parental / carer consent has been given";
var APPB2C_registration_testTaker_consent_edit2 = "Edit consent";
var APPB2C_registration_testTaker_consent_missing2 = "Please provide dependent consent information.";
var APPB2C_registration_testTaker_consent_title2 = "Consent:";
var APPB2C_registration_testTaker_contact_address2 = "Address";
var APPB2C_registration_testTaker_contact_edit2 = "Edit contact details";
var APPB2C_registration_testTaker_contact_mobile2 = "Mobile telephone number";
var APPB2C_registration_testTaker_contact_parent2 = "Parent / Guardian\u2019s contact details:";
var APPB2C_registration_testTaker_contact_title2 = "Contact details:";
var APPB2C_registration_testTaker_editContact_address2 = "Address";
var APPB2C_registration_testTaker_editContact_back2 = "I don\u2019t want to change my contact details";
var APPB2C_registration_testTaker_editContact_city2 = "Town / City";
var APPB2C_registration_testTaker_editContact_country_change2 = "change country / territory";
var APPB2C_registration_testTaker_editContact_country_residence2 = "Country / territory of residence";
var APPB2C_registration_testTaker_editContact_cta2 = "Update details";
var APPB2C_registration_testTaker_editContact_error_missingAddress2 = "Please provide your address.";
var APPB2C_registration_testTaker_editContact_error_missingCountry2 = "Please provide your country.";
var APPB2C_registration_testTaker_editContact_error_pastCutoff2 = "Editing your contact details is no longer available.";
var APPB2C_registration_testTaker_editContact_mobile2 = "Mobile telephone number";
var APPB2C_registration_testTaker_editContact_note2 = "Please note";
var APPB2C_registration_testTaker_editContact_postCode2 = "Postcode / ZIP";
var APPB2C_registration_testTaker_editContact_state2 = "State";
var APPB2C_registration_testTaker_editContact_warn2 = "Updates to the contact details will be changed in the test booking(s):";
var APPB2C_registration_testTaker_error2 = "Editing your details is no longer available for this booking.";
var APPB2C_registration_testTaker_files_editButton_add2 = "Upload your files";
var APPB2C_registration_testTaker_files_editButton_edit2 = "Edit uploaded files";
var APPB2C_registration_testTaker_files_upload_back_subtitle2 = "(this won\u2019t upload or remove any files)";
var APPB2C_registration_testTaker_files_upload_back_title2 = "back to Test Taker";
var APPB2C_registration_testTaker_files_upload_cta2 = "Save your files";
var APPB2C_registration_testTaker_files_upload_error_fileTooLarge2 = "The file you are trying to upload is too large. Please remove it and choose a different file.";
var APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded2 = "You have exceeded the number of files you can upload. Please remove files over the limit and try again.";
var APPB2C_registration_testTaker_files_upload_error_uploadUnavailable2 = "Uploading files is not available for this booking.";
var APPB2C_registration_testTaker_files_upload_error_wrongFileName2 = "The file you are trying to upload has a name containing unsupported characters. Please remove it, rename it locally and try uploading it again.";
var APPB2C_registration_testTaker_files_upload_error_wrongFileType2 = "You are trying to upload a file in an unsupported format. Please remove it and choose a different file.";
var APPB2C_registration_testTaker_files_upload_mobile_line12 = "As it looks like you\u2019re on a mobile device, we suggest you upload a photo from your device.";
var APPB2C_registration_testTaker_files_upload_mobile_line22 = "If you\u2019re unable to do this on your mobile device, please log back in on a computer.";
var APPB2C_registration_testTaker_files_upload_mobile_title2 = "Please note:";
var APPB2C_registration_testTaker_files_upload_note2 = "You can upload {{count}} files (you must upload at least 1)";
var APPB2C_registration_testTaker_id_child2 = "Dependent\u2019s identification details:";
var APPB2C_registration_testTaker_id_edit_rules_example2 = "See example of proof of $t({{idName}})";
var APPB2C_registration_testTaker_id_edit_subtitle2 = "Please upload proof of $t({{idName}}) shown below:";
var APPB2C_registration_testTaker_id_edit_title2 = "Upload proof of $t({{idName}})";
var APPB2C_registration_testTaker_id_edit_upload_exampleProof2 = "Example proof";
var APPB2C_registration_testTaker_id_edit_upload_imgAlt2 = "Proof of ID";
var APPB2C_registration_testTaker_id_expiryDate2 = "$t({{idName}}, capitalise) expiry date";
var APPB2C_registration_testTaker_id_fileAlt2 = "Uploaded proof of ID";
var APPB2C_registration_testTaker_id_idNumber2 = "$t({{idName}}, capitalise) number";
var APPB2C_registration_testTaker_id_issuingAuthority2 = "Issuing authority";
var APPB2C_registration_testTaker_id_noFilesBody2 = "You have not uploaded any proof of ID.";
var APPB2C_registration_testTaker_id_noFilesTitle2 = "No proof";
var APPB2C_registration_testTaker_id_title2 = "Identification details:";
var APPB2C_registration_testTaker_id_typeNames_card2 = "identity card";
var APPB2C_registration_testTaker_id_typeNames_other2 = "ID";
var APPB2C_registration_testTaker_id_typeNames_passport2 = "passport";
var APPB2C_registration_testTaker_specialArrangements_description2 = "You have requested the following special arrangements:";
var APPB2C_registration_testTaker_specialArrangements_edit_line12 = "Special arrangements needed:";
var APPB2C_registration_testTaker_specialArrangements_edit_line22 = "All applications for special requirements must be supported by medical evidence.";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule12 = "be accessible to an IELTS administrator in terms of language and legibility";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule22 = "should be an original document on headed paper, and bearing the name, relevant qualification(s) and signature of a recognised practitioner";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule32 = "should give a clear statement of your special needs";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule42 = "should justify the special arrangements you need";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule52 = "should be issued no more than two years before the test date";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_title2 = "The medical certificate should:";
var APPB2C_registration_testTaker_specialArrangements_edit_subtitle2 = "When you booked your test you indicated that you needed special arrangements.";
var APPB2C_registration_testTaker_specialArrangements_edit_title2 = "Upload medical evidence";
var APPB2C_registration_testTaker_specialArrangements_fileAlt2 = "Uploaded medical evidence";
var APPB2C_registration_testTaker_specialArrangements_noFilesBody2 = "You have not uploaded any medical evidence.";
var APPB2C_registration_testTaker_specialArrangements_noFilesTitle2 = "No evidence";
var APPB2C_registration_testTaker_specialArrangements_title2 = "Special arrangements:";
var APPB2C_testCard_arrival2 = "(please arrive at {{time}})";
var APPB2C_testCard_book2 = "Book";
var APPB2C_testCard_bookExam2 = "Book Test";
var APPB2C_testCard_bookFor2 = "Book for";
var APPB2C_testCard_bookForFree2 = "Book Test for free";
var APPB2C_testCard_bookingPrice2 = "Price";
var APPB2C_testCard_cd2 = "IELTS on computer";
var APPB2C_testCard_changeSpeaking2 = "Change speaking schedule";
var APPB2C_testCard_f2f2 = "Face to face";
var APPB2C_testCard_hideSpeakingTests2 = "hide speaking tests at this venue";
var APPB2C_testCard_lrw2 = "Listening, Reading & Writing";
var APPB2C_testCard_lrwHeader = "Written test";
var APPB2C_testCard_ls2 = "Listening & Speaking";
var APPB2C_testCard_mixedSlots2 = "Test will be recorded. Consent implied by booking. Please, choose other if you don't agree.";
var APPB2C_testCard_notLinkedVenue2 = "Speaking and written test locations are not the same. Plan your travel.";
var APPB2C_testCard_pb2 = "IELTS on paper";
var APPB2C_testCard_review2 = "and review";
var APPB2C_testCard_showSpeakingTests2 = "show speaking tests at this venue";
var APPB2C_testCard_speaking2 = "Speaking test";
var APPB2C_testCard_speakingAddress2 = "Speaking address";
var APPB2C_testCard_timeUnknown2 = "You will receive your test time 48 hours before your test date.";
var APPB2C_testCard_vcs2 = "Video call at the test center";
var b2c_default2 = {
  "APPB2C.auth.changePassword.cta": APPB2C_auth_changePassword_cta2,
  "APPB2C.auth.changePassword.error": APPB2C_auth_changePassword_error2,
  "APPB2C.auth.changePassword.error.differentPasswords": APPB2C_auth_changePassword_error_differentPasswords2,
  "APPB2C.auth.changePassword.error.wrongIdNumber": APPB2C_auth_changePassword_error_wrongIdNumber2,
  "APPB2C.auth.changePassword.error.wrongOldPassword": APPB2C_auth_changePassword_error_wrongOldPassword2,
  "APPB2C.auth.changePassword.error.wrongPassword": APPB2C_auth_changePassword_error_wrongPassword2,
  "APPB2C.auth.changePassword.id.label": APPB2C_auth_changePassword_id_label2,
  "APPB2C.auth.changePassword.id.note": APPB2C_auth_changePassword_id_note2,
  "APPB2C.auth.changePassword.id.warn": APPB2C_auth_changePassword_id_warn2,
  "APPB2C.auth.changePassword.pass.label": APPB2C_auth_changePassword_pass_label2,
  "APPB2C.auth.changePassword.pass.note": APPB2C_auth_changePassword_pass_note2,
  "APPB2C.auth.changePassword.title": APPB2C_auth_changePassword_title2,
  "APPB2C.auth.forgetPassword.cta": APPB2C_auth_forgetPassword_cta2,
  "APPB2C.auth.forgetPassword.email": APPB2C_auth_forgetPassword_email2,
  "APPB2C.auth.forgetPassword.success.msg1": APPB2C_auth_forgetPassword_success_msg12,
  "APPB2C.auth.forgetPassword.success.msg2": APPB2C_auth_forgetPassword_success_msg22,
  "APPB2C.auth.forgetPassword.success.title": APPB2C_auth_forgetPassword_success_title2,
  "APPB2C.auth.forgetPassword.text": APPB2C_auth_forgetPassword_text2,
  "APPB2C.auth.forgetPassword.title": APPB2C_auth_forgetPassword_title2,
  "APPB2C.auth.resetPassword.cta": APPB2C_auth_resetPassword_cta2,
  "APPB2C.auth.resetPassword.error.expiredToken": APPB2C_auth_resetPassword_error_expiredToken2,
  "APPB2C.auth.resetPassword.error.wrongPassword": APPB2C_auth_resetPassword_error_wrongPassword2,
  "APPB2C.auth.resetPassword.error.wrongToken": APPB2C_auth_resetPassword_error_wrongToken2,
  "APPB2C.auth.resetPassword.form.newPassword": APPB2C_auth_resetPassword_form_newPassword2,
  "APPB2C.auth.resetPassword.noToken.cta": APPB2C_auth_resetPassword_noToken_cta2,
  "APPB2C.auth.resetPassword.noToken.msg": APPB2C_auth_resetPassword_noToken_msg2,
  "APPB2C.auth.resetPassword.success.msg": APPB2C_auth_resetPassword_success_msg2,
  "APPB2C.auth.resetPassword.success.title": APPB2C_auth_resetPassword_success_title2,
  "APPB2C.auth.resetPassword.title": APPB2C_auth_resetPassword_title2,
  "APPB2C.cj.basic.cta": APPB2C_cj_basic_cta2,
  "APPB2C.cj.basic.goToReview": APPB2C_cj_basic_goToReview2,
  "APPB2C.cj.basic.scrollLink": APPB2C_cj_basic_scrollLink2,
  "APPB2C.cj.basic.selectDate": APPB2C_cj_basic_selectDate2,
  "APPB2C.cj.basic.selectTime": APPB2C_cj_basic_selectTime2,
  "APPB2C.cj.basic.validationError": APPB2C_cj_basic_validationError2,
  "APPB2C.cj.bookingComplete.feeDetails.discount": APPB2C_cj_bookingComplete_feeDetails_discount2,
  "APPB2C.cj.bookingComplete.feeDetails.fee": APPB2C_cj_bookingComplete_feeDetails_fee2,
  "APPB2C.cj.bookingComplete.feeDetails.newFee": APPB2C_cj_bookingComplete_feeDetails_newFee2,
  "APPB2C.cj.bookingComplete.feeDetails.promo": APPB2C_cj_bookingComplete_feeDetails_promo2,
  "APPB2C.cj.bookingComplete.feeDetails.reference": APPB2C_cj_bookingComplete_feeDetails_reference2,
  "APPB2C.cj.bookingComplete.feeDetails.tax": APPB2C_cj_bookingComplete_feeDetails_tax2,
  "APPB2C.cj.bookingComplete.feeDetails.title": APPB2C_cj_bookingComplete_feeDetails_title2,
  "APPB2C.cj.bookingComplete.feeDetails.total": APPB2C_cj_bookingComplete_feeDetails_total2,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine1": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine12,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine2": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine22,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine3": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine32,
  "APPB2C.cj.bookingComplete.loadingPaymentMethodsError": APPB2C_cj_bookingComplete_loadingPaymentMethodsError2,
  "APPB2C.cj.bookingComplete.loadingRegistrationError": APPB2C_cj_bookingComplete_loadingRegistrationError2,
  "APPB2C.cj.bookingComplete.note": APPB2C_cj_bookingComplete_note2,
  "APPB2C.cj.bookingComplete.noteUolPassportInfo": APPB2C_cj_bookingComplete_noteUolPassportInfo2,
  "APPB2C.cj.bookingComplete.offlinePayment.code": APPB2C_cj_bookingComplete_offlinePayment_code2,
  "APPB2C.cj.bookingComplete.offlinePayment.description": APPB2C_cj_bookingComplete_offlinePayment_description2,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line1": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line12,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line2": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line22,
  "APPB2C.cj.bookingComplete.offlinePayment.fee": APPB2C_cj_bookingComplete_offlinePayment_fee2,
  "APPB2C.cj.bookingComplete.offlinePayment.hideDescription": APPB2C_cj_bookingComplete_offlinePayment_hideDescription2,
  "APPB2C.cj.bookingComplete.offlinePayment.incTax": APPB2C_cj_bookingComplete_offlinePayment_incTax2,
  "APPB2C.cj.bookingComplete.offlinePayment.name": APPB2C_cj_bookingComplete_offlinePayment_name2,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1": APPB2C_cj_bookingComplete_offlinePayment_note_line12,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.hours": APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours2,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.timeLeft": APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft2,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line2": APPB2C_cj_bookingComplete_offlinePayment_note_line22,
  "APPB2C.cj.bookingComplete.offlinePayment.note.pleaseNote": APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote2,
  "APPB2C.cj.bookingComplete.offlinePayment.number": APPB2C_cj_bookingComplete_offlinePayment_number2,
  "APPB2C.cj.bookingComplete.offlinePayment.reference": APPB2C_cj_bookingComplete_offlinePayment_reference2,
  "APPB2C.cj.bookingComplete.offlinePayment.showDescription": APPB2C_cj_bookingComplete_offlinePayment_showDescription2,
  "APPB2C.cj.bookingComplete.otherPaymentOptions": APPB2C_cj_bookingComplete_otherPaymentOptions2,
  "APPB2C.cj.bookingComplete.paymentFailed": APPB2C_cj_bookingComplete_paymentFailed2,
  "APPB2C.cj.bookingComplete.paymentOptions": APPB2C_cj_bookingComplete_paymentOptions2,
  "APPB2C.cj.bookingComplete.prepareNote.accesNote": APPB2C_cj_bookingComplete_prepareNote_accesNote2,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.note": APPB2C_cj_bookingComplete_prepareNote_counselling_note2,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.view": APPB2C_cj_bookingComplete_prepareNote_counselling_view2,
  "APPB2C.cj.bookingComplete.prepareNote.note": APPB2C_cj_bookingComplete_prepareNote_note2,
  "APPB2C.cj.bookingComplete.prepareNote.title": APPB2C_cj_bookingComplete_prepareNote_title2,
  "APPB2C.cj.bookingComplete.prepareNote.view": APPB2C_cj_bookingComplete_prepareNote_view2,
  "APPB2C.cj.bookingComplete.title": APPB2C_cj_bookingComplete_title2,
  "APPB2C.cj.bookingComplete.tnc": APPB2C_cj_bookingComplete_tnc2,
  "APPB2C.cj.bookingComplete.tncLink": APPB2C_cj_bookingComplete_tncLink2,
  "APPB2C.cj.bookingComplete.tryAgain": APPB2C_cj_bookingComplete_tryAgain2,
  "APPB2C.cj.bookingComplete.yourBooking": APPB2C_cj_bookingComplete_yourBooking2,
  "APPB2C.cj.childDetails.about": APPB2C_cj_childDetails_about2,
  "APPB2C.cj.childDetails.aboutNote": APPB2C_cj_childDetails_aboutNote2,
  "APPB2C.cj.childDetails.error.missingFirstName": APPB2C_cj_childDetails_error_missingFirstName2,
  "APPB2C.cj.childDetails.error.missingSurname": APPB2C_cj_childDetails_error_missingSurname2,
  "APPB2C.cj.childDetails.error.wrongDob": APPB2C_cj_childDetails_error_wrongDob2,
  "APPB2C.cj.childDetails.forms.personal.idMatch": APPB2C_cj_childDetails_forms_personal_idMatch2,
  "APPB2C.cj.childDetails.noLastName": APPB2C_cj_childDetails_noLastName2,
  "APPB2C.cj.childDetails.note": APPB2C_cj_childDetails_note2,
  "APPB2C.cj.childDetails.onTestDay": APPB2C_cj_childDetails_onTestDay2,
  "APPB2C.cj.childDetails.title": APPB2C_cj_childDetails_title2,
  "APPB2C.cj.finishPayment.error": APPB2C_cj_finishPayment_error2,
  "APPB2C.cj.finishPayment.pleaseWait": APPB2C_cj_finishPayment_pleaseWait2,
  "APPB2C.cj.finishPayment.processing": APPB2C_cj_finishPayment_processing2,
  "APPB2C.cj.idDetails.confirm.change": APPB2C_cj_idDetails_confirm_change2,
  "APPB2C.cj.idDetails.confirm.confirm": APPB2C_cj_idDetails_confirm_confirm2,
  "APPB2C.cj.idDetails.confirm.note1": APPB2C_cj_idDetails_confirm_note12,
  "APPB2C.cj.idDetails.confirm.note2": APPB2C_cj_idDetails_confirm_note22,
  "APPB2C.cj.idDetails.confirm.title": APPB2C_cj_idDetails_confirm_title2,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.content": APPB2C_cj_idDetails_confirm_wrongDetailsNote_content2,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.title": APPB2C_cj_idDetails_confirm_wrongDetailsNote_title2,
  "APPB2C.cj.idDetails.error.missingIdExpiry": APPB2C_cj_idDetails_error_missingIdExpiry2,
  "APPB2C.cj.idDetails.error.missingIdNumber": APPB2C_cj_idDetails_error_missingIdNumber2,
  "APPB2C.cj.idDetails.error.missingIdType": APPB2C_cj_idDetails_error_missingIdType2,
  "APPB2C.cj.idDetails.error.missingIssuingAuthority": APPB2C_cj_idDetails_error_missingIssuingAuthority2,
  "APPB2C.cj.idDetails.error.wrongFilename": APPB2C_cj_idDetails_error_wrongFilename2,
  "APPB2C.cj.idDetails.error.wrongIdExpiry": APPB2C_cj_idDetails_error_wrongIdExpiry2,
  "APPB2C.cj.idDetails.forms.changeNote": APPB2C_cj_idDetails_forms_changeNote2,
  "APPB2C.cj.idDetails.forms.changeNote.child": APPB2C_cj_idDetails_forms_changeNote_child2,
  "APPB2C.cj.idDetails.forms.documentWithNoExpiryDate": APPB2C_cj_idDetails_forms_documentWithNoExpiryDate2,
  "APPB2C.cj.idDetails.forms.expiryDate": APPB2C_cj_idDetails_forms_expiryDate2,
  "APPB2C.cj.idDetails.forms.idNumber": APPB2C_cj_idDetails_forms_idNumber2,
  "APPB2C.cj.idDetails.forms.idType": APPB2C_cj_idDetails_forms_idType2,
  "APPB2C.cj.idDetails.forms.issuingAuthority": APPB2C_cj_idDetails_forms_issuingAuthority2,
  "APPB2C.cj.idDetails.idExpired": APPB2C_cj_idDetails_idExpired2,
  "APPB2C.cj.idDetails.iolMessage": APPB2C_cj_idDetails_iolMessage2,
  "APPB2C.cj.idDetails.noteAboutIdentification": APPB2C_cj_idDetails_noteAboutIdentification2,
  "APPB2C.cj.idDetails.noteAboutIdentification.child": APPB2C_cj_idDetails_noteAboutIdentification_child2,
  "APPB2C.cj.idDetails.noteAboutIdentificationIol": APPB2C_cj_idDetails_noteAboutIdentificationIol2,
  "APPB2C.cj.idDetails.noteAboutIdentificationIolLink": APPB2C_cj_idDetails_noteAboutIdentificationIolLink2,
  "APPB2C.cj.idDetails.noteAboutIdentificationUol": APPB2C_cj_idDetails_noteAboutIdentificationUol2,
  "APPB2C.cj.idDetails.title": APPB2C_cj_idDetails_title2,
  "APPB2C.cj.idDetails.title.child": APPB2C_cj_idDetails_title_child2,
  "APPB2C.cj.idDetails.typeNames.card": APPB2C_cj_idDetails_typeNames_card2,
  "APPB2C.cj.idDetails.typeNames.other": APPB2C_cj_idDetails_typeNames_other2,
  "APPB2C.cj.idDetails.typeNames.passport": APPB2C_cj_idDetails_typeNames_passport2,
  "APPB2C.cj.idDetails.upload.fetchError": APPB2C_cj_idDetails_upload_fetchError2,
  "APPB2C.cj.idDetails.upload.file1": APPB2C_cj_idDetails_upload_file12,
  "APPB2C.cj.idDetails.upload.file2": APPB2C_cj_idDetails_upload_file22,
  "APPB2C.cj.idDetails.upload.instructions.capture": APPB2C_cj_idDetails_upload_instructions_capture2,
  "APPB2C.cj.idDetails.upload.instructions.covers": APPB2C_cj_idDetails_upload_instructions_covers2,
  "APPB2C.cj.idDetails.upload.instructions.directLight": APPB2C_cj_idDetails_upload_instructions_directLight2,
  "APPB2C.cj.idDetails.upload.instructions.noBlur": APPB2C_cj_idDetails_upload_instructions_noBlur2,
  "APPB2C.cj.idDetails.upload.instructions.title": APPB2C_cj_idDetails_upload_instructions_title2,
  "APPB2C.cj.idDetails.upload.mobile": APPB2C_cj_idDetails_upload_mobile2,
  "APPB2C.cj.idDetails.upload.passport.guidelines.cover": APPB2C_cj_idDetails_upload_passport_guidelines_cover2,
  "APPB2C.cj.idDetails.upload.passport.guidelines.crop": APPB2C_cj_idDetails_upload_passport_guidelines_crop2,
  "APPB2C.cj.idDetails.upload.passport.guidelines.followInstructions": APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions2,
  "APPB2C.cj.idDetails.upload.passport.guidelines.glare": APPB2C_cj_idDetails_upload_passport_guidelines_glare2,
  "APPB2C.cj.idDetails.upload.passport.guidelines.watermark": APPB2C_cj_idDetails_upload_passport_guidelines_watermark2,
  "APPB2C.cj.idDetails.upload.title": APPB2C_cj_idDetails_upload_title2,
  "APPB2C.cj.ieltsReady.description.line1": APPB2C_cj_ieltsReady_description_line12,
  "APPB2C.cj.ieltsReady.description.line2": APPB2C_cj_ieltsReady_description_line22,
  "APPB2C.cj.ieltsReady.dobMin": APPB2C_cj_ieltsReady_dobMin2,
  "APPB2C.cj.ieltsReady.nameWarning": APPB2C_cj_ieltsReady_nameWarning2,
  "APPB2C.cj.ieltsReady.note": APPB2C_cj_ieltsReady_note2,
  "APPB2C.cj.ieltsReady.personalDetails.title": APPB2C_cj_ieltsReady_personalDetails_title,
  "APPB2C.cj.ieltsReady.submitBtnText": APPB2C_cj_ieltsReady_submitBtnText2,
  "APPB2C.cj.ieltsReady.targetScore.option": APPB2C_cj_ieltsReady_targetScore_option2,
  "APPB2C.cj.ieltsReady.targetScore.title": APPB2C_cj_ieltsReady_targetScore_title2,
  "APPB2C.cj.ieltsReady.title": APPB2C_cj_ieltsReady_title2,
  "APPB2C.cj.incorrectOrganisation.bookNewLink": APPB2C_cj_incorrectOrganisation_bookNewLink2,
  "APPB2C.cj.incorrectOrganisation.line1": APPB2C_cj_incorrectOrganisation_line12,
  "APPB2C.cj.incorrectOrganisation.line2": APPB2C_cj_incorrectOrganisation_line22,
  "APPB2C.cj.incorrectOrganisation.locationsLink": APPB2C_cj_incorrectOrganisation_locationsLink2,
  "APPB2C.cj.incorrectOrganisation.locationsLinkUsa": APPB2C_cj_incorrectOrganisation_locationsLinkUsa2,
  "APPB2C.cj.incorrectOrganisation.title": APPB2C_cj_incorrectOrganisation_title2,
  "APPB2C.cj.marketingPrefs": APPB2C_cj_marketingPrefs2,
  "APPB2C.cj.onRequest.created.back": APPB2C_cj_onRequest_created_back2,
  "APPB2C.cj.onRequest.created.in": APPB2C_cj_onRequest_created_in2,
  "APPB2C.cj.onRequest.created.info": APPB2C_cj_onRequest_created_info2,
  "APPB2C.cj.onRequest.created.note": APPB2C_cj_onRequest_created_note2,
  "APPB2C.cj.onRequest.created.title": APPB2C_cj_onRequest_created_title2,
  "APPB2C.cj.onRequest.form.labels.confirmEmail": APPB2C_cj_onRequest_form_labels_confirmEmail2,
  "APPB2C.cj.onRequest.form.labels.email": APPB2C_cj_onRequest_form_labels_email2,
  "APPB2C.cj.onRequest.form.labels.firstname": APPB2C_cj_onRequest_form_labels_firstname2,
  "APPB2C.cj.onRequest.form.labels.surname": APPB2C_cj_onRequest_form_labels_surname2,
  "APPB2C.cj.onRequest.form.labels.telephone": APPB2C_cj_onRequest_form_labels_telephone2,
  "APPB2C.cj.onRequest.form.labels.testRangeQuestion": APPB2C_cj_onRequest_form_labels_testRangeQuestion2,
  "APPB2C.cj.onRequest.form.submit": APPB2C_cj_onRequest_form_submit2,
  "APPB2C.cj.onRequest.form.testRangeOpt.oneMonth": APPB2C_cj_onRequest_form_testRangeOpt_oneMonth2,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonths": APPB2C_cj_onRequest_form_testRangeOpt_threeMonths2,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonthsPlus": APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus2,
  "APPB2C.cj.onRequest.form.testRangeOpt.twoMonths": APPB2C_cj_onRequest_form_testRangeOpt_twoMonths2,
  "APPB2C.cj.onRequest.info1": APPB2C_cj_onRequest_info12,
  "APPB2C.cj.onRequest.note": APPB2C_cj_onRequest_note2,
  "APPB2C.cj.onRequest.otherLocations.choose": APPB2C_cj_onRequest_otherLocations_choose2,
  "APPB2C.cj.onRequest.otherLocations.hide": APPB2C_cj_onRequest_otherLocations_hide2,
  "APPB2C.cj.onRequest.otherLocations.info1": APPB2C_cj_onRequest_otherLocations_info12,
  "APPB2C.cj.onRequest.otherLocations.info2": APPB2C_cj_onRequest_otherLocations_info22,
  "APPB2C.cj.onRequest.otherLocations.show": APPB2C_cj_onRequest_otherLocations_show2,
  "APPB2C.cj.onRequest.please": APPB2C_cj_onRequest_please2,
  "APPB2C.cj.onRequest.prefNote": APPB2C_cj_onRequest_prefNote2,
  "APPB2C.cj.onRequest.subtitles.aboutPreferences": APPB2C_cj_onRequest_subtitles_aboutPreferences2,
  "APPB2C.cj.onRequest.subtitles.aboutYou": APPB2C_cj_onRequest_subtitles_aboutYou2,
  "APPB2C.cj.onRequest.subtitles.markpref": APPB2C_cj_onRequest_subtitles_markpref2,
  "APPB2C.cj.onRequest.subtitles.register": APPB2C_cj_onRequest_subtitles_register2,
  "APPB2C.cj.personalDetails.error.doubleBooking": APPB2C_cj_personalDetails_error_doubleBooking2,
  "APPB2C.cj.personalDetails.error.missingAddress": APPB2C_cj_personalDetails_error_missingAddress2,
  "APPB2C.cj.personalDetails.error.missingEmail": APPB2C_cj_personalDetails_error_missingEmail2,
  "APPB2C.cj.personalDetails.error.missingEmailConfirm": APPB2C_cj_personalDetails_error_missingEmailConfirm2,
  "APPB2C.cj.personalDetails.error.missingFirstName": APPB2C_cj_personalDetails_error_missingFirstName2,
  "APPB2C.cj.personalDetails.error.missingMobile": APPB2C_cj_personalDetails_error_missingMobile2,
  "APPB2C.cj.personalDetails.error.missingSex": APPB2C_cj_personalDetails_error_missingSex2,
  "APPB2C.cj.personalDetails.error.missingState": APPB2C_cj_personalDetails_error_missingState2,
  "APPB2C.cj.personalDetails.error.missingSurname": APPB2C_cj_personalDetails_error_missingSurname2,
  "APPB2C.cj.personalDetails.error.missingTown": APPB2C_cj_personalDetails_error_missingTown2,
  "APPB2C.cj.personalDetails.error.missingZip": APPB2C_cj_personalDetails_error_missingZip2,
  "APPB2C.cj.personalDetails.error.wrongDob": APPB2C_cj_personalDetails_error_wrongDob2,
  "APPB2C.cj.personalDetails.error.wrongEmailConfirm": APPB2C_cj_personalDetails_error_wrongEmailConfirm2,
  "APPB2C.cj.personalDetails.examTakenModal.cta": APPB2C_cj_personalDetails_examTakenModal_cta2,
  "APPB2C.cj.personalDetails.examTakenModal.message": APPB2C_cj_personalDetails_examTakenModal_message2,
  "APPB2C.cj.personalDetails.examTakenModal.title": APPB2C_cj_personalDetails_examTakenModal_title2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse": APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate": APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle": APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.title": APPB2C_cj_personalDetails_existingRegistrationsModal_title2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourLifeSkillsTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourSpeakingTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest2,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourWrittenTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest2,
  "APPB2C.cj.personalDetails.forms.address.country": APPB2C_cj_personalDetails_forms_address_country2,
  "APPB2C.cj.personalDetails.forms.address.enterManually": APPB2C_cj_personalDetails_forms_address_enterManually2,
  "APPB2C.cj.personalDetails.forms.address.postal": APPB2C_cj_personalDetails_forms_address_postal2,
  "APPB2C.cj.personalDetails.forms.address.state": APPB2C_cj_personalDetails_forms_address_state2,
  "APPB2C.cj.personalDetails.forms.address.town": APPB2C_cj_personalDetails_forms_address_town2,
  "APPB2C.cj.personalDetails.forms.address.zip": APPB2C_cj_personalDetails_forms_address_zip2,
  "APPB2C.cj.personalDetails.forms.contact.confirmEmail": APPB2C_cj_personalDetails_forms_contact_confirmEmail2,
  "APPB2C.cj.personalDetails.forms.contact.email": APPB2C_cj_personalDetails_forms_contact_email2,
  "APPB2C.cj.personalDetails.forms.contact.emailExist": APPB2C_cj_personalDetails_forms_contact_emailExist2,
  "APPB2C.cj.personalDetails.forms.contact.emailInUse": APPB2C_cj_personalDetails_forms_contact_emailInUse2,
  "APPB2C.cj.personalDetails.forms.contact.mobile": APPB2C_cj_personalDetails_forms_contact_mobile2,
  "APPB2C.cj.personalDetails.forms.contact.note": APPB2C_cj_personalDetails_forms_contact_note2,
  "APPB2C.cj.personalDetails.forms.contact.password": APPB2C_cj_personalDetails_forms_contact_password2,
  "APPB2C.cj.personalDetails.forms.contact.smsConsent": APPB2C_cj_personalDetails_forms_contact_smsConsent2,
  "APPB2C.cj.personalDetails.forms.contact.smsNote": APPB2C_cj_personalDetails_forms_contact_smsNote2,
  "APPB2C.cj.personalDetails.forms.contact.title": APPB2C_cj_personalDetails_forms_contact_title2,
  "APPB2C.cj.personalDetails.forms.personal.change": APPB2C_cj_personalDetails_forms_personal_change2,
  "APPB2C.cj.personalDetails.forms.personal.dob": APPB2C_cj_personalDetails_forms_personal_dob2,
  "APPB2C.cj.personalDetails.forms.personal.dobCta": APPB2C_cj_personalDetails_forms_personal_dobCta2,
  "APPB2C.cj.personalDetails.forms.personal.firstMiddleName": APPB2C_cj_personalDetails_forms_personal_firstMiddleName2,
  "APPB2C.cj.personalDetails.forms.personal.firstName": APPB2C_cj_personalDetails_forms_personal_firstName2,
  "APPB2C.cj.personalDetails.forms.personal.idMatch": APPB2C_cj_personalDetails_forms_personal_idMatch2,
  "APPB2C.cj.personalDetails.forms.personal.lastName": APPB2C_cj_personalDetails_forms_personal_lastName2,
  "APPB2C.cj.personalDetails.forms.personal.name": APPB2C_cj_personalDetails_forms_personal_name2,
  "APPB2C.cj.personalDetails.forms.personal.noLastName": APPB2C_cj_personalDetails_forms_personal_noLastName2,
  "APPB2C.cj.personalDetails.forms.personal.sectionTitle": APPB2C_cj_personalDetails_forms_personal_sectionTitle2,
  "APPB2C.cj.personalDetails.forWho.child": APPB2C_cj_personalDetails_forWho_child2,
  "APPB2C.cj.personalDetails.forWho.childUnder18": APPB2C_cj_personalDetails_forWho_childUnder182,
  "APPB2C.cj.personalDetails.forWho.dob": APPB2C_cj_personalDetails_forWho_dob2,
  "APPB2C.cj.personalDetails.forWho.myself": APPB2C_cj_personalDetails_forWho_myself2,
  "APPB2C.cj.personalDetails.forWho.note": APPB2C_cj_personalDetails_forWho_note2,
  "APPB2C.cj.personalDetails.forWho.title": APPB2C_cj_personalDetails_forWho_title2,
  "APPB2C.cj.personalDetails.gender": APPB2C_cj_personalDetails_gender2,
  "APPB2C.cj.personalDetails.idNote.idMatch": APPB2C_cj_personalDetails_idNote_idMatch2,
  "APPB2C.cj.personalDetails.idNote.idMatch.change": APPB2C_cj_personalDetails_idNote_idMatch_change2,
  "APPB2C.cj.personalDetails.idNote.idMatch.france": APPB2C_cj_personalDetails_idNote_idMatch_france,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.adult": APPB2C_cj_personalDetails_idNote_idMatch_france_adult,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.minor": APPB2C_cj_personalDetails_idNote_idMatch_france_minor,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain": APPB2C_cj_personalDetails_idNote_idMatch_spain,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.adult": APPB2C_cj_personalDetails_idNote_idMatch_spain_adult,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.minor": APPB2C_cj_personalDetails_idNote_idMatch_spain_minor,
  "APPB2C.cj.personalDetails.idNote.whenForChild": APPB2C_cj_personalDetails_idNote_whenForChild2,
  "APPB2C.cj.personalDetails.login.btn": APPB2C_cj_personalDetails_login_btn2,
  "APPB2C.cj.personalDetails.login.emailNotEditable": APPB2C_cj_personalDetails_login_emailNotEditable2,
  "APPB2C.cj.personalDetails.login.link": APPB2C_cj_personalDetails_login_link2,
  "APPB2C.cj.personalDetails.login.loggedIn": APPB2C_cj_personalDetails_login_loggedIn2,
  "APPB2C.cj.personalDetails.login.logout": APPB2C_cj_personalDetails_login_logout2,
  "APPB2C.cj.personalDetails.login.note": APPB2C_cj_personalDetails_login_note2,
  "APPB2C.cj.personalDetails.login.title": APPB2C_cj_personalDetails_login_title2,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.changeDate": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate2,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line1": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line12,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line2": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line22,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.title": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title2,
  "APPB2C.cj.personalDetails.title": APPB2C_cj_personalDetails_title2,
  "APPB2C.cj.personalDetails.tncs.agreement": APPB2C_cj_personalDetails_tncs_agreement2,
  "APPB2C.cj.personalDetails.tncs.title": APPB2C_cj_personalDetails_tncs_title2,
  "APPB2C.cj.personalDetails.visaType.label": APPB2C_cj_personalDetails_visaType_label2,
  "APPB2C.cj.personalDetails.visaType.label.child": APPB2C_cj_personalDetails_visaType_label_child2,
  "APPB2C.cj.personalDetails.visaType.link": APPB2C_cj_personalDetails_visaType_link2,
  "APPB2C.cj.personalDetails.visaType.title": APPB2C_cj_personalDetails_visaType_title2,
  "APPB2C.cj.personalDetails.visaType.title.child": APPB2C_cj_personalDetails_visaType_title_child2,
  "APPB2C.cj.rebook.errorModal.message": APPB2C_cj_rebook_errorModal_message2,
  "APPB2C.cj.rebook.errorModal.title": APPB2C_cj_rebook_errorModal_title2,
  "APPB2C.cj.review.acknowledgement.anotherPerson": APPB2C_cj_review_acknowledgement_anotherPerson2,
  "APPB2C.cj.review.acknowledgement.company": APPB2C_cj_review_acknowledgement_company2,
  "APPB2C.cj.review.acknowledgement.fields.companyName": APPB2C_cj_review_acknowledgement_fields_companyName2,
  "APPB2C.cj.review.acknowledgement.fields.companyName.subnote": APPB2C_cj_review_acknowledgement_fields_companyName_subnote2,
  "APPB2C.cj.review.acknowledgement.fields.country": APPB2C_cj_review_acknowledgement_fields_country2,
  "APPB2C.cj.review.acknowledgement.fields.email": APPB2C_cj_review_acknowledgement_fields_email2,
  "APPB2C.cj.review.acknowledgement.fields.familyName": APPB2C_cj_review_acknowledgement_fields_familyName2,
  "APPB2C.cj.review.acknowledgement.fields.firstName": APPB2C_cj_review_acknowledgement_fields_firstName2,
  "APPB2C.cj.review.acknowledgement.fields.mobile": APPB2C_cj_review_acknowledgement_fields_mobile2,
  "APPB2C.cj.review.acknowledgement.myself": APPB2C_cj_review_acknowledgement_myself2,
  "APPB2C.cj.review.acknowledgement.send": APPB2C_cj_review_acknowledgement_send2,
  "APPB2C.cj.review.acknowledgement.title": APPB2C_cj_review_acknowledgement_title2,
  "APPB2C.cj.review.agent.label": APPB2C_cj_review_agent_label2,
  "APPB2C.cj.review.agent.note": APPB2C_cj_review_agent_note2,
  "APPB2C.cj.review.agent.title": APPB2C_cj_review_agent_title2,
  "APPB2C.cj.review.ai.note": APPB2C_cj_review_ai_note2,
  "APPB2C.cj.review.ai.title": APPB2C_cj_review_ai_title2,
  "APPB2C.cj.review.bookingDetails.lrw.title": APPB2C_cj_review_bookingDetails_lrw_title2,
  "APPB2C.cj.review.bookingDetails.lrw.title.child": APPB2C_cj_review_bookingDetails_lrw_title_child2,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills2,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills.child": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child2,
  "APPB2C.cj.review.bookingDetails.speaking.title": APPB2C_cj_review_bookingDetails_speaking_title2,
  "APPB2C.cj.review.bookingDetails.speaking.title.child": APPB2C_cj_review_bookingDetails_speaking_title_child2,
  "APPB2C.cj.review.bookingDetails.title": APPB2C_cj_review_bookingDetails_title2,
  "APPB2C.cj.review.bookNowNoFee.buttonText": APPB2C_cj_review_bookNowNoFee_buttonText2,
  "APPB2C.cj.review.bookTest": APPB2C_cj_review_bookTest2,
  "APPB2C.cj.review.change": APPB2C_cj_review_change2,
  "APPB2C.cj.review.changeLrwTest": APPB2C_cj_review_changeLrwTest2,
  "APPB2C.cj.review.changeSpeakingTest": APPB2C_cj_review_changeSpeakingTest2,
  "APPB2C.cj.review.changeUkviLsTest": APPB2C_cj_review_changeUkviLsTest2,
  "APPB2C.cj.review.childDetails.guardiansTitle": APPB2C_cj_review_childDetails_guardiansTitle2,
  "APPB2C.cj.review.childDetails.onTestDay": APPB2C_cj_review_childDetails_onTestDay2,
  "APPB2C.cj.review.childDetails.onTestDayOptions.1": APPB2C_cj_review_childDetails_onTestDayOptions_12,
  "APPB2C.cj.review.childDetails.onTestDayOptions.2": APPB2C_cj_review_childDetails_onTestDayOptions_22,
  "APPB2C.cj.review.childDetails.onTestDayOptions.3": APPB2C_cj_review_childDetails_onTestDayOptions_32,
  "APPB2C.cj.review.childDetails.title": APPB2C_cj_review_childDetails_title2,
  "APPB2C.cj.review.confirmPaymentModal.btnNote": APPB2C_cj_review_confirmPaymentModal_btnNote2,
  "APPB2C.cj.review.confirmPaymentModal.note": APPB2C_cj_review_confirmPaymentModal_note2,
  "APPB2C.cj.review.confirmPaymentModal.optionsTitle": APPB2C_cj_review_confirmPaymentModal_optionsTitle2,
  "APPB2C.cj.review.confirmPaymentModal.title": APPB2C_cj_review_confirmPaymentModal_title2,
  "APPB2C.cj.review.countryMsg.pakistan": APPB2C_cj_review_countryMsg_pakistan2,
  "APPB2C.cj.review.error.missingOrderAcknowledgement": APPB2C_cj_review_error_missingOrderAcknowledgement2,
  "APPB2C.cj.review.error.missingPickups": APPB2C_cj_review_error_missingPickups2,
  "APPB2C.cj.review.error.missingTown": APPB2C_cj_review_error_missingTown2,
  "APPB2C.cj.review.error.wrongCountrySetting": APPB2C_cj_review_error_wrongCountrySetting2,
  "APPB2C.cj.review.error.wrongPaymentConfig": APPB2C_cj_review_error_wrongPaymentConfig2,
  "APPB2C.cj.review.error.wrongTaxNumber": APPB2C_cj_review_error_wrongTaxNumber2,
  "APPB2C.cj.review.expiredIdError": APPB2C_cj_review_expiredIdError2,
  "APPB2C.cj.review.idDetails.issuing": APPB2C_cj_review_idDetails_issuing2,
  "APPB2C.cj.review.idDetails.nationality": APPB2C_cj_review_idDetails_nationality2,
  "APPB2C.cj.review.idDetails.title": APPB2C_cj_review_idDetails_title2,
  "APPB2C.cj.review.idDetails.title.child": APPB2C_cj_review_idDetails_title_child2,
  "APPB2C.cj.review.invoice.companyName": APPB2C_cj_review_invoice_companyName2,
  "APPB2C.cj.review.invoice.consent": APPB2C_cj_review_invoice_consent2,
  "APPB2C.cj.review.invoice.send": APPB2C_cj_review_invoice_send2,
  "APPB2C.cj.review.invoice.subtype.citizenDigitalCertificate": APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate2,
  "APPB2C.cj.review.invoice.subtype.label": APPB2C_cj_review_invoice_subtype_label2,
  "APPB2C.cj.review.invoice.subtype.mobileBarcode": APPB2C_cj_review_invoice_subtype_mobileBarcode2,
  "APPB2C.cj.review.invoice.taxCaption.donate": APPB2C_cj_review_invoice_taxCaption_donate2,
  "APPB2C.cj.review.invoice.taxCaption.duplicatePaper": APPB2C_cj_review_invoice_taxCaption_duplicatePaper2,
  "APPB2C.cj.review.invoice.taxValidation.citizenDigitalCertificate": APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate2,
  "APPB2C.cj.review.invoice.taxValidation.donate": APPB2C_cj_review_invoice_taxValidation_donate2,
  "APPB2C.cj.review.invoice.taxValidation.mobileBarcode": APPB2C_cj_review_invoice_taxValidation_mobileBarcode2,
  "APPB2C.cj.review.invoice.taxValidation.triplicate": APPB2C_cj_review_invoice_taxValidation_triplicate2,
  "APPB2C.cj.review.invoice.title": APPB2C_cj_review_invoice_title2,
  "APPB2C.cj.review.invoice.type.donate": APPB2C_cj_review_invoice_type_donate2,
  "APPB2C.cj.review.invoice.type.duplicateElectronic": APPB2C_cj_review_invoice_type_duplicateElectronic2,
  "APPB2C.cj.review.invoice.type.duplicatePaper": APPB2C_cj_review_invoice_type_duplicatePaper2,
  "APPB2C.cj.review.invoice.type.triplicate": APPB2C_cj_review_invoice_type_triplicate2,
  "APPB2C.cj.review.localization.localTime": APPB2C_cj_review_localization_localTime2,
  "APPB2C.cj.review.localization.location": APPB2C_cj_review_localization_location2,
  "APPB2C.cj.review.localization.timezone": APPB2C_cj_review_localization_timezone2,
  "APPB2C.cj.review.missingMarketingCta": APPB2C_cj_review_missingMarketingCta2,
  "APPB2C.cj.review.missingMarketingError": APPB2C_cj_review_missingMarketingError2,
  "APPB2C.cj.review.note.content": APPB2C_cj_review_note_content2,
  "APPB2C.cj.review.note.title": APPB2C_cj_review_note_title2,
  "APPB2C.cj.review.or": APPB2C_cj_review_or2,
  "APPB2C.cj.review.payLater.buttonText": APPB2C_cj_review_payLater_buttonText2,
  "APPB2C.cj.review.payLater.sideNote": APPB2C_cj_review_payLater_sideNote2,
  "APPB2C.cj.review.payment.fee": APPB2C_cj_review_payment_fee2,
  "APPB2C.cj.review.payment.promoCodes.applied": APPB2C_cj_review_payment_promoCodes_applied2,
  "APPB2C.cj.review.payment.promoCodes.applyCode": APPB2C_cj_review_payment_promoCodes_applyCode2,
  "APPB2C.cj.review.payment.promoCodes.discount": APPB2C_cj_review_payment_promoCodes_discount2,
  "APPB2C.cj.review.payment.promoCodes.generalError": APPB2C_cj_review_payment_promoCodes_generalError2,
  "APPB2C.cj.review.payment.promoCodes.haveCode": APPB2C_cj_review_payment_promoCodes_haveCode2,
  "APPB2C.cj.review.payment.promoCodes.invalidCode": APPB2C_cj_review_payment_promoCodes_invalidCode2,
  "APPB2C.cj.review.payment.promoCodes.newFee": APPB2C_cj_review_payment_promoCodes_newFee2,
  "APPB2C.cj.review.payment.promoCodes.notHaveCode": APPB2C_cj_review_payment_promoCodes_notHaveCode2,
  "APPB2C.cj.review.payment.promoCodes.promo": APPB2C_cj_review_payment_promoCodes_promo2,
  "APPB2C.cj.review.payment.promoCodes.promoCode": APPB2C_cj_review_payment_promoCodes_promoCode2,
  "APPB2C.cj.review.payment.promoCodes.remove": APPB2C_cj_review_payment_promoCodes_remove2,
  "APPB2C.cj.review.payment.promoCodes.removeCode": APPB2C_cj_review_payment_promoCodes_removeCode2,
  "APPB2C.cj.review.payment.promoCodes.total": APPB2C_cj_review_payment_promoCodes_total2,
  "APPB2C.cj.review.payment.tax": APPB2C_cj_review_payment_tax2,
  "APPB2C.cj.review.payment.title": APPB2C_cj_review_payment_title2,
  "APPB2C.cj.review.payment.total": APPB2C_cj_review_payment_total2,
  "APPB2C.cj.review.paymentError": APPB2C_cj_review_paymentError2,
  "APPB2C.cj.review.paymentNote": APPB2C_cj_review_paymentNote2,
  "APPB2C.cj.review.paymentNoteMultiple": APPB2C_cj_review_paymentNoteMultiple,
  "APPB2C.cj.review.paymentNoteSingle": APPB2C_cj_review_paymentNoteSingle,
  "APPB2C.cj.review.paymentNoteZeroFee": APPB2C_cj_review_paymentNoteZeroFee2,
  "APPB2C.cj.review.payOnline.buttonText": APPB2C_cj_review_payOnline_buttonText2,
  "APPB2C.cj.review.payOnline.sideNote": APPB2C_cj_review_payOnline_sideNote2,
  "APPB2C.cj.review.personalDetails.address": APPB2C_cj_review_personalDetails_address2,
  "APPB2C.cj.review.personalDetails.dob": APPB2C_cj_review_personalDetails_dob2,
  "APPB2C.cj.review.personalDetails.email": APPB2C_cj_review_personalDetails_email2,
  "APPB2C.cj.review.personalDetails.gender": APPB2C_cj_review_personalDetails_gender2,
  "APPB2C.cj.review.personalDetails.name": APPB2C_cj_review_personalDetails_name2,
  "APPB2C.cj.review.personalDetails.phone": APPB2C_cj_review_personalDetails_phone2,
  "APPB2C.cj.review.personalDetails.smsNotificationsConsent": APPB2C_cj_review_personalDetails_smsNotificationsConsent2,
  "APPB2C.cj.review.personalDetails.title": APPB2C_cj_review_personalDetails_title2,
  "APPB2C.cj.review.registrationError": APPB2C_cj_review_registrationError2,
  "APPB2C.cj.review.registrationFailedModal.changedFee.btn": APPB2C_cj_review_registrationFailedModal_changedFee_btn2,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line1": APPB2C_cj_review_registrationFailedModal_changedFee_line12,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line2": APPB2C_cj_review_registrationFailedModal_changedFee_line22,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line3": APPB2C_cj_review_registrationFailedModal_changedFee_line32,
  "APPB2C.cj.review.registrationFailedModal.changedFee.title": APPB2C_cj_review_registrationFailedModal_changedFee_title2,
  "APPB2C.cj.review.registrationFailedModal.cta": APPB2C_cj_review_registrationFailedModal_cta2,
  "APPB2C.cj.review.registrationFailedModal.message": APPB2C_cj_review_registrationFailedModal_message2,
  "APPB2C.cj.review.registrationFailedModal.title": APPB2C_cj_review_registrationFailedModal_title2,
  "APPB2C.cj.review.taxNumberError": APPB2C_cj_review_taxNumberError2,
  "APPB2C.cj.review.title": APPB2C_cj_review_title2,
  "APPB2C.cj.review.tncs.agreement": APPB2C_cj_review_tncs_agreement2,
  "APPB2C.cj.review.tncs.title": APPB2C_cj_review_tncs_title2,
  "APPB2C.cj.review.tooHighPromoCodeError": APPB2C_cj_review_tooHighPromoCodeError2,
  "APPB2C.cj.timer.modal.cta": APPB2C_cj_timer_modal_cta2,
  "APPB2C.cj.timer.modal.message": APPB2C_cj_timer_modal_message2,
  "APPB2C.cj.timer.modal.title": APPB2C_cj_timer_modal_title2,
  "APPB2C.cj.timer.nearEnd.minuteCount": APPB2C_cj_timer_nearEnd_minuteCount2,
  "APPB2C.cj.timer.nearEnd.minuteCount_other": APPB2C_cj_timer_nearEnd_minuteCount_other2,
  "APPB2C.cj.timer.nearEnd.text": APPB2C_cj_timer_nearEnd_text2,
  "APPB2C.cj.timer.nearEnd.title": APPB2C_cj_timer_nearEnd_title2,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied2,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied.child": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child2,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest2,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest.child": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child2,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle": APPB2C_cj_ttProfile_forms_interest_sectionTitle2,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle.child": APPB2C_cj_ttProfile_forms_interest_sectionTitle_child2,
  "APPB2C.cj.ttProfile.forms.occupation.occupationLevel": APPB2C_cj_ttProfile_forms_occupation_occupationLevel2,
  "APPB2C.cj.ttProfile.forms.occupation.occupationSector": APPB2C_cj_ttProfile_forms_occupation_occupationSector2,
  "APPB2C.cj.ttProfile.forms.occupation.sectionTitle": APPB2C_cj_ttProfile_forms_occupation_sectionTitle2,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality": APPB2C_cj_ttProfile_forms_personal_countryOfNationality2,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality.child": APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child2,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.384": APPB2C_cj_ttProfile_forms_personal_educationLevel_3842,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.385": APPB2C_cj_ttProfile_forms_personal_educationLevel_3852,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.386": APPB2C_cj_ttProfile_forms_personal_educationLevel_3862,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.387": APPB2C_cj_ttProfile_forms_personal_educationLevel_3872,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.title": APPB2C_cj_ttProfile_forms_personal_educationLevel_title2,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken2,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken.child": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child2,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle": APPB2C_cj_ttProfile_forms_personal_sectionTitle2,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle.child": APPB2C_cj_ttProfile_forms_personal_sectionTitle_child2,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish2,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child2,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel2,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child2,
  "APPB2C.cj.ttProfile.note": APPB2C_cj_ttProfile_note2,
  "APPB2C.cj.ttProfile.note.child": APPB2C_cj_ttProfile_note_child2,
  "APPB2C.cj.ttProfile.title": APPB2C_cj_ttProfile_title2,
  "APPB2C.cj.ttProfile.title.child": APPB2C_cj_ttProfile_title_child2,
  "APPB2C.cj.ukviStartPage.ac.content": APPB2C_cj_ukviStartPage_ac_content2,
  "APPB2C.cj.ukviStartPage.ac.title": APPB2C_cj_ukviStartPage_ac_title2,
  "APPB2C.cj.ukviStartPage.gt.content": APPB2C_cj_ukviStartPage_gt_content2,
  "APPB2C.cj.ukviStartPage.gt.title": APPB2C_cj_ukviStartPage_gt_title2,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.content": APPB2C_cj_ukviStartPage_lifeSkils_a1_content2,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.title": APPB2C_cj_ukviStartPage_lifeSkils_a1_title2,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.content": APPB2C_cj_ukviStartPage_lifeSkils_a2_content2,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.title": APPB2C_cj_ukviStartPage_lifeSkils_a2_title2,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.content": APPB2C_cj_ukviStartPage_lifeSkils_b1_content2,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.title": APPB2C_cj_ukviStartPage_lifeSkils_b1_title2,
  "APPB2C.cj.ukviStartPage.lifeSkils.citizenship": APPB2C_cj_ukviStartPage_lifeSkils_citizenship2,
  "APPB2C.cj.ukviStartPage.lifeSkils.extension": APPB2C_cj_ukviStartPage_lifeSkils_extension2,
  "APPB2C.cj.ukviStartPage.lifeSkils.family": APPB2C_cj_ukviStartPage_lifeSkils_family2,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.content": APPB2C_cj_ukviStartPage_lifeSkils_note_content2,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.title": APPB2C_cj_ukviStartPage_lifeSkils_note_title2,
  "APPB2C.cj.ukviStartPage.lifeSkils.title": APPB2C_cj_ukviStartPage_lifeSkils_title2,
  "APPB2C.cj.ukviStartPage.lifeSkils.visaTypes": APPB2C_cj_ukviStartPage_lifeSkils_visaTypes2,
  "APPB2C.cj.ukviStartPage.note1.content": APPB2C_cj_ukviStartPage_note1_content2,
  "APPB2C.cj.ukviStartPage.note1.title": APPB2C_cj_ukviStartPage_note1_title2,
  "APPB2C.cj.ukviStartPage.terms": APPB2C_cj_ukviStartPage_terms2,
  "APPB2C.cj.ukviStartPage.testsBelow": APPB2C_cj_ukviStartPage_testsBelow2,
  "APPB2C.cj.ukviStartPage.title": APPB2C_cj_ukviStartPage_title2,
  "APPB2C.cj.ukviStartPage.whichUkvi": APPB2C_cj_ukviStartPage_whichUkvi2,
  "APPB2C.common.account.details.email.cancel": APPB2C_common_account_details_email_cancel,
  "APPB2C.common.account.details.email.confirm": APPB2C_common_account_details_email_confirm2,
  "APPB2C.common.account.details.email.label": APPB2C_common_account_details_email_label2,
  "APPB2C.common.account.details.email.maxLength": APPB2C_common_account_details_email_maxLength2,
  "APPB2C.common.account.details.email.mismatch": APPB2C_common_account_details_email_mismatch2,
  "APPB2C.common.account.details.email.note": APPB2C_common_account_details_email_note2,
  "APPB2C.common.account.details.email.save": APPB2C_common_account_details_email_save2,
  "APPB2C.common.account.details.email.set": APPB2C_common_account_details_email_set2,
  "APPB2C.common.account.details.email.success": APPB2C_common_account_details_email_success2,
  "APPB2C.common.account.details.email.username": APPB2C_common_account_details_email_username2,
  "APPB2C.common.account.details.email.validEmail": APPB2C_common_account_details_email_validEmail2,
  "APPB2C.common.account.details.name": APPB2C_common_account_details_name2,
  "APPB2C.common.account.details.psw.cancel": APPB2C_common_account_details_psw_cancel,
  "APPB2C.common.account.details.psw.change": APPB2C_common_account_details_psw_change2,
  "APPB2C.common.account.details.psw.label": APPB2C_common_account_details_psw_label2,
  "APPB2C.common.account.details.psw.new": APPB2C_common_account_details_psw_new2,
  "APPB2C.common.account.details.psw.old": APPB2C_common_account_details_psw_old2,
  "APPB2C.common.account.details.psw.requirements.levels.0": APPB2C_common_account_details_psw_requirements_levels_02,
  "APPB2C.common.account.details.psw.requirements.levels.1": APPB2C_common_account_details_psw_requirements_levels_12,
  "APPB2C.common.account.details.psw.requirements.levels.2": APPB2C_common_account_details_psw_requirements_levels_22,
  "APPB2C.common.account.details.psw.requirements.levels.3": APPB2C_common_account_details_psw_requirements_levels_32,
  "APPB2C.common.account.details.psw.requirements.levels.4": APPB2C_common_account_details_psw_requirements_levels_42,
  "APPB2C.common.account.details.psw.requirements.maxLength": APPB2C_common_account_details_psw_requirements_maxLength2,
  "APPB2C.common.account.details.psw.requirements.mustHave": APPB2C_common_account_details_psw_requirements_mustHave2,
  "APPB2C.common.account.details.psw.requirements.ok": APPB2C_common_account_details_psw_requirements_ok2,
  "APPB2C.common.account.details.psw.requirements.reqs.length": APPB2C_common_account_details_psw_requirements_reqs_length2,
  "APPB2C.common.account.details.psw.requirements.reqs.lower": APPB2C_common_account_details_psw_requirements_reqs_lower2,
  "APPB2C.common.account.details.psw.requirements.reqs.number": APPB2C_common_account_details_psw_requirements_reqs_number2,
  "APPB2C.common.account.details.psw.requirements.reqs.special": APPB2C_common_account_details_psw_requirements_reqs_special2,
  "APPB2C.common.account.details.psw.requirements.reqs.upper": APPB2C_common_account_details_psw_requirements_reqs_upper2,
  "APPB2C.common.account.details.psw.requirements.shouldHave": APPB2C_common_account_details_psw_requirements_shouldHave2,
  "APPB2C.common.account.details.psw.requirements.strength": APPB2C_common_account_details_psw_requirements_strength2,
  "APPB2C.common.account.details.psw.requirements.tooWeak": APPB2C_common_account_details_psw_requirements_tooWeak2,
  "APPB2C.common.account.details.psw.show": APPB2C_common_account_details_psw_show2,
  "APPB2C.common.account.details.psw.success": APPB2C_common_account_details_psw_success2,
  "APPB2C.common.account.details.subnote.address": APPB2C_common_account_details_subnote_address2,
  "APPB2C.common.account.details.subnote.city": APPB2C_common_account_details_subnote_city2,
  "APPB2C.common.account.error": APPB2C_common_account_error2,
  "APPB2C.common.account.marketing.success": APPB2C_common_account_marketing_success2,
  "APPB2C.common.account.marketing.title": APPB2C_common_account_marketing_title2,
  "APPB2C.common.account.smsNotificationSettings.mobileLabel": APPB2C_common_account_smsNotificationSettings_mobileLabel2,
  "APPB2C.common.account.smsNotificationSettings.success": APPB2C_common_account_smsNotificationSettings_success2,
  "APPB2C.common.account.smsNotificationSettings.title": APPB2C_common_account_smsNotificationSettings_title2,
  "APPB2C.common.account.terms.cta": APPB2C_common_account_terms_cta2,
  "APPB2C.common.account.terms.disclaimer": APPB2C_common_account_terms_disclaimer2,
  "APPB2C.common.account.terms.download": APPB2C_common_account_terms_download2,
  "APPB2C.common.account.terms.extra.line1": APPB2C_common_account_terms_extra_line12,
  "APPB2C.common.account.terms.extra.line2": APPB2C_common_account_terms_extra_line22,
  "APPB2C.common.account.terms.extra.line3.1": APPB2C_common_account_terms_extra_line3_12,
  "APPB2C.common.account.terms.extra.line3.2": APPB2C_common_account_terms_extra_line3_22,
  "APPB2C.common.account.terms.extra.title": APPB2C_common_account_terms_extra_title2,
  "APPB2C.common.account.terms.previous": APPB2C_common_account_terms_previous2,
  "APPB2C.common.account.terms.title": APPB2C_common_account_terms_title2,
  "APPB2C.common.account.title": APPB2C_common_account_title2,
  "APPB2C.common.alertBar.afterTest": APPB2C_common_alertBar_afterTest2,
  "APPB2C.common.alertBar.beforeTest": APPB2C_common_alertBar_beforeTest2,
  "APPB2C.common.alertBar.cancelReupload": APPB2C_common_alertBar_cancelReupload2,
  "APPB2C.common.alertBar.check": APPB2C_common_alertBar_check2,
  "APPB2C.common.alertBar.childConsentMissing": APPB2C_common_alertBar_childConsentMissing2,
  "APPB2C.common.alertBar.creds": APPB2C_common_alertBar_creds2,
  "APPB2C.common.alertBar.eorPayment": APPB2C_common_alertBar_eorPayment2,
  "APPB2C.common.alertBar.eorPaymentProof": APPB2C_common_alertBar_eorPaymentProof2,
  "APPB2C.common.alertBar.idReupload": APPB2C_common_alertBar_idReupload2,
  "APPB2C.common.alertBar.idUpload": APPB2C_common_alertBar_idUpload2,
  "APPB2C.common.alertBar.medicalReupload": APPB2C_common_alertBar_medicalReupload2,
  "APPB2C.common.alertBar.medicalUpload": APPB2C_common_alertBar_medicalUpload2,
  "APPB2C.common.alertBar.regPayment": APPB2C_common_alertBar_regPayment2,
  "APPB2C.common.alertBar.regPaymentProof": APPB2C_common_alertBar_regPaymentProof2,
  "APPB2C.common.alertBar.withCreds": APPB2C_common_alertBar_withCreds2,
  "APPB2C.common.basic.add": APPB2C_common_basic_add2,
  "APPB2C.common.basic.backToTop": APPB2C_common_basic_backToTop2,
  "APPB2C.common.basic.bookingDetails.arrival": APPB2C_common_basic_bookingDetails_arrival2,
  "APPB2C.common.basic.bookingDetails.lifeSkills.results": APPB2C_common_basic_bookingDetails_lifeSkills_results2,
  "APPB2C.common.basic.bookingDetails.lifeSkills.title": APPB2C_common_basic_bookingDetails_lifeSkills_title2,
  "APPB2C.common.basic.bookingDetails.lrw.cd": APPB2C_common_basic_bookingDetails_lrw_cd2,
  "APPB2C.common.basic.bookingDetails.lrw.online": APPB2C_common_basic_bookingDetails_lrw_online2,
  "APPB2C.common.basic.bookingDetails.lrw.pb": APPB2C_common_basic_bookingDetails_lrw_pb2,
  "APPB2C.common.basic.bookingDetails.lrw.results": APPB2C_common_basic_bookingDetails_lrw_results2,
  "APPB2C.common.basic.bookingDetails.lrw.title": APPB2C_common_basic_bookingDetails_lrw_title2,
  "APPB2C.common.basic.bookingDetails.osrTitle.listening": APPB2C_common_basic_bookingDetails_osrTitle_listening2,
  "APPB2C.common.basic.bookingDetails.osrTitle.reading": APPB2C_common_basic_bookingDetails_osrTitle_reading2,
  "APPB2C.common.basic.bookingDetails.speaking.f2f": APPB2C_common_basic_bookingDetails_speaking_f2f2,
  "APPB2C.common.basic.bookingDetails.speaking.live": APPB2C_common_basic_bookingDetails_speaking_live2,
  "APPB2C.common.basic.bookingDetails.speaking.online": APPB2C_common_basic_bookingDetails_speaking_online2,
  "APPB2C.common.basic.bookingDetails.speaking.remote": APPB2C_common_basic_bookingDetails_speaking_remote2,
  "APPB2C.common.basic.bookingDetails.speaking.results": APPB2C_common_basic_bookingDetails_speaking_results2,
  "APPB2C.common.basic.bookingDetails.speaking.title": APPB2C_common_basic_bookingDetails_speaking_title2,
  "APPB2C.common.basic.bookPrePaid": APPB2C_common_basic_bookPrePaid2,
  "APPB2C.common.basic.cancel": APPB2C_common_basic_cancel2,
  "APPB2C.common.basic.change": APPB2C_common_basic_change2,
  "APPB2C.common.basic.choose": APPB2C_common_basic_choose2,
  "APPB2C.common.basic.close": APPB2C_common_basic_close2,
  "APPB2C.common.basic.copied": APPB2C_common_basic_copied2,
  "APPB2C.common.basic.copy": APPB2C_common_basic_copy2,
  "APPB2C.common.basic.dateField.day": APPB2C_common_basic_dateField_day2,
  "APPB2C.common.basic.dateField.month": APPB2C_common_basic_dateField_month2,
  "APPB2C.common.basic.dateField.months": APPB2C_common_basic_dateField_months2,
  "APPB2C.common.basic.dateField.year": APPB2C_common_basic_dateField_year2,
  "APPB2C.common.basic.edit": APPB2C_common_basic_edit2,
  "APPB2C.common.basic.errors.contactTestCentre": APPB2C_common_basic_errors_contactTestCentre2,
  "APPB2C.common.basic.errors.network": APPB2C_common_basic_errors_network2,
  "APPB2C.common.basic.errors.noTest": APPB2C_common_basic_errors_noTest2,
  "APPB2C.common.basic.errors.server": APPB2C_common_basic_errors_server2,
  "APPB2C.common.basic.errors.title": APPB2C_common_basic_errors_title2,
  "APPB2C.common.basic.errors.unableToDownloadFile": APPB2C_common_basic_errors_unableToDownloadFile2,
  "APPB2C.common.basic.errors.unknown": APPB2C_common_basic_errors_unknown2,
  "APPB2C.common.basic.expando.collapse": APPB2C_common_basic_expando_collapse2,
  "APPB2C.common.basic.expando.show": APPB2C_common_basic_expando_show2,
  "APPB2C.common.basic.externalLinkTitle": APPB2C_common_basic_externalLinkTitle2,
  "APPB2C.common.basic.failure": APPB2C_common_basic_failure2,
  "APPB2C.common.basic.forms.addressMaxLength": APPB2C_common_basic_forms_addressMaxLength2,
  "APPB2C.common.basic.forms.dobMin": APPB2C_common_basic_forms_dobMin2,
  "APPB2C.common.basic.forms.dobMinIol": APPB2C_common_basic_forms_dobMinIol2,
  "APPB2C.common.basic.forms.dobOutOfRange": APPB2C_common_basic_forms_dobOutOfRange2,
  "APPB2C.common.basic.forms.firstNameMaxLength": APPB2C_common_basic_forms_firstNameMaxLength2,
  "APPB2C.common.basic.forms.idExpiration": APPB2C_common_basic_forms_idExpiration2,
  "APPB2C.common.basic.forms.idMaxLength": APPB2C_common_basic_forms_idMaxLength2,
  "APPB2C.common.basic.forms.idNumber": APPB2C_common_basic_forms_idNumber2,
  "APPB2C.common.basic.forms.idProofRequired": APPB2C_common_basic_forms_idProofRequired2,
  "APPB2C.common.basic.forms.maxLengthMessage": APPB2C_common_basic_forms_maxLengthMessage2,
  "APPB2C.common.basic.forms.minorDobMax": APPB2C_common_basic_forms_minorDobMax2,
  "APPB2C.common.basic.forms.minorDobMin": APPB2C_common_basic_forms_minorDobMin2,
  "APPB2C.common.basic.forms.mobileBypass": APPB2C_common_basic_forms_mobileBypass2,
  "APPB2C.common.basic.forms.mobileLength": APPB2C_common_basic_forms_mobileLength2,
  "APPB2C.common.basic.forms.onlyLatin": APPB2C_common_basic_forms_onlyLatin2,
  "APPB2C.common.basic.forms.postCodeMaxLength": APPB2C_common_basic_forms_postCodeMaxLength2,
  "APPB2C.common.basic.forms.postCodeMessage": APPB2C_common_basic_forms_postCodeMessage2,
  "APPB2C.common.basic.forms.postCodeNotFound": APPB2C_common_basic_forms_postCodeNotFound2,
  "APPB2C.common.basic.forms.requiredMessage": APPB2C_common_basic_forms_requiredMessage2,
  "APPB2C.common.basic.forms.requiredNoSurmane": APPB2C_common_basic_forms_requiredNoSurmane2,
  "APPB2C.common.basic.forms.stateMaxLength": APPB2C_common_basic_forms_stateMaxLength2,
  "APPB2C.common.basic.forms.surnameMaxLength": APPB2C_common_basic_forms_surnameMaxLength2,
  "APPB2C.common.basic.forms.townMaxLength": APPB2C_common_basic_forms_townMaxLength2,
  "APPB2C.common.basic.forms.unsupportedChars": APPB2C_common_basic_forms_unsupportedChars2,
  "APPB2C.common.basic.forms.validMobile": APPB2C_common_basic_forms_validMobile2,
  "APPB2C.common.basic.goBack": APPB2C_common_basic_goBack2,
  "APPB2C.common.basic.loading": APPB2C_common_basic_loading2,
  "APPB2C.common.basic.menu": APPB2C_common_basic_menu2,
  "APPB2C.common.basic.no": APPB2C_common_basic_no2,
  "APPB2C.common.basic.notAllowed.msg": APPB2C_common_basic_notAllowed_msg2,
  "APPB2C.common.basic.notAllowed.title": APPB2C_common_basic_notAllowed_title2,
  "APPB2C.common.basic.notFound.goHome": APPB2C_common_basic_notFound_goHome2,
  "APPB2C.common.basic.notFound.msg": APPB2C_common_basic_notFound_msg2,
  "APPB2C.common.basic.notFound.title": APPB2C_common_basic_notFound_title2,
  "APPB2C.common.basic.notLoading.note": APPB2C_common_basic_notLoading_note2,
  "APPB2C.common.basic.notLoading.refresh": APPB2C_common_basic_notLoading_refresh2,
  "APPB2C.common.basic.notLoading.title": APPB2C_common_basic_notLoading_title2,
  "APPB2C.common.basic.ok": APPB2C_common_basic_ok2,
  "APPB2C.common.basic.on": APPB2C_common_basic_on2,
  "APPB2C.common.basic.oneSecond": APPB2C_common_basic_oneSecond2,
  "APPB2C.common.basic.pleaseNote": APPB2C_common_basic_pleaseNote2,
  "APPB2C.common.basic.retake": APPB2C_common_basic_retake2,
  "APPB2C.common.basic.upload": APPB2C_common_basic_upload2,
  "APPB2C.common.basic.validationError": APPB2C_common_basic_validationError2,
  "APPB2C.common.basic.waitReassurance": APPB2C_common_basic_waitReassurance2,
  "APPB2C.common.basic.wereSorry": APPB2C_common_basic_wereSorry2,
  "APPB2C.common.basic.yes": APPB2C_common_basic_yes2,
  "APPB2C.common.bookTest.changeExamType": APPB2C_common_bookTest_changeExamType2,
  "APPB2C.common.bookTest.filters.afternoon": APPB2C_common_bookTest_filters_afternoon2,
  "APPB2C.common.bookTest.filters.allDates": APPB2C_common_bookTest_filters_allDates2,
  "APPB2C.common.bookTest.filters.anyTime": APPB2C_common_bookTest_filters_anyTime2,
  "APPB2C.common.bookTest.filters.computer": APPB2C_common_bookTest_filters_computer2,
  "APPB2C.common.bookTest.filters.evening": APPB2C_common_bookTest_filters_evening2,
  "APPB2C.common.bookTest.filters.modal.cta": APPB2C_common_bookTest_filters_modal_cta2,
  "APPB2C.common.bookTest.filters.modal.hide": APPB2C_common_bookTest_filters_modal_hide2,
  "APPB2C.common.bookTest.filters.modal.iDontMind": APPB2C_common_bookTest_filters_modal_iDontMind2,
  "APPB2C.common.bookTest.filters.modal.resultsIn": APPB2C_common_bookTest_filters_modal_resultsIn2,
  "APPB2C.common.bookTest.filters.modal.subtitle": APPB2C_common_bookTest_filters_modal_subtitle2,
  "APPB2C.common.bookTest.filters.modal.title": APPB2C_common_bookTest_filters_modal_title2,
  "APPB2C.common.bookTest.filters.modal.whichFormatTest": APPB2C_common_bookTest_filters_modal_whichFormatTest2,
  "APPB2C.common.bookTest.filters.modal.whichTimeOfDay": APPB2C_common_bookTest_filters_modal_whichTimeOfDay2,
  "APPB2C.common.bookTest.filters.morning": APPB2C_common_bookTest_filters_morning2,
  "APPB2C.common.bookTest.filters.paper": APPB2C_common_bookTest_filters_paper2,
  "APPB2C.common.bookTest.filters.seeAllFilters": APPB2C_common_bookTest_filters_seeAllFilters2,
  "APPB2C.common.bookTest.filters.title": APPB2C_common_bookTest_filters_title2,
  "APPB2C.common.bookTest.loadMoreTests": APPB2C_common_bookTest_loadMoreTests2,
  "APPB2C.common.bookTest.noDiscount.msg.countryNotMatched": APPB2C_common_bookTest_noDiscount_msg_countryNotMatched2,
  "APPB2C.common.bookTest.noDiscount.msg.notFound": APPB2C_common_bookTest_noDiscount_msg_notFound2,
  "APPB2C.common.bookTest.noDiscount.msg.notSupported": APPB2C_common_bookTest_noDiscount_msg_notSupported2,
  "APPB2C.common.bookTest.noDiscount.msg.reserved": APPB2C_common_bookTest_noDiscount_msg_reserved2,
  "APPB2C.common.bookTest.noDiscount.msg.used": APPB2C_common_bookTest_noDiscount_msg_used2,
  "APPB2C.common.bookTest.noDiscount.title": APPB2C_common_bookTest_noDiscount_title2,
  "APPB2C.common.bookTest.results.book": APPB2C_common_bookTest_results_book2,
  "APPB2C.common.bookTest.results.bookFor": APPB2C_common_bookTest_results_bookFor2,
  "APPB2C.common.bookTest.results.cd": APPB2C_common_bookTest_results_cd2,
  "APPB2C.common.bookTest.results.changeSpeaking": APPB2C_common_bookTest_results_changeSpeaking2,
  "APPB2C.common.bookTest.results.clearFilters": APPB2C_common_bookTest_results_clearFilters2,
  "APPB2C.common.bookTest.results.fromCurrentLocation": APPB2C_common_bookTest_results_fromCurrentLocation2,
  "APPB2C.common.bookTest.results.lrw": APPB2C_common_bookTest_results_lrw2,
  "APPB2C.common.bookTest.results.noResultsGoBack": APPB2C_common_bookTest_results_noResultsGoBack2,
  "APPB2C.common.bookTest.results.noResultsText": APPB2C_common_bookTest_results_noResultsText2,
  "APPB2C.common.bookTest.results.noResultsTitle": APPB2C_common_bookTest_results_noResultsTitle2,
  "APPB2C.common.bookTest.results.pb": APPB2C_common_bookTest_results_pb2,
  "APPB2C.common.bookTest.results.review": APPB2C_common_bookTest_results_review2,
  "APPB2C.common.bookTest.results.searchText": APPB2C_common_bookTest_results_searchText2,
  "APPB2C.common.bookTest.results.speaking": APPB2C_common_bookTest_results_speaking2,
  "APPB2C.common.bookTest.results.viewOnMap": APPB2C_common_bookTest_results_viewOnMap2,
  "APPB2C.common.bookTest.sortBy.date": APPB2C_common_bookTest_sortBy_date2,
  "APPB2C.common.bookTest.sortBy.distance": APPB2C_common_bookTest_sortBy_distance2,
  "APPB2C.common.bookTest.sortBy.price": APPB2C_common_bookTest_sortBy_price2,
  "APPB2C.common.bookTest.sortBy.title": APPB2C_common_bookTest_sortBy_title2,
  "APPB2C.common.bookTest.title": APPB2C_common_bookTest_title2,
  "APPB2C.common.cancelBooking.alreadyRequested.cta": APPB2C_common_cancelBooking_alreadyRequested_cta2,
  "APPB2C.common.cancelBooking.alreadyRequested.title": APPB2C_common_cancelBooking_alreadyRequested_title2,
  "APPB2C.common.cancelBooking.back": APPB2C_common_cancelBooking_back2,
  "APPB2C.common.cancelBooking.bankDetails.form.accountHolder": APPB2C_common_cancelBooking_bankDetails_form_accountHolder2,
  "APPB2C.common.cancelBooking.bankDetails.form.bankAddress": APPB2C_common_cancelBooking_bankDetails_form_bankAddress2,
  "APPB2C.common.cancelBooking.bankDetails.form.bankName": APPB2C_common_cancelBooking_bankDetails_form_bankName2,
  "APPB2C.common.cancelBooking.bankDetails.form.bankNumber": APPB2C_common_cancelBooking_bankDetails_form_bankNumber2,
  "APPB2C.common.cancelBooking.bankDetails.form.changeCountry": APPB2C_common_cancelBooking_bankDetails_form_changeCountry2,
  "APPB2C.common.cancelBooking.bankDetails.form.city": APPB2C_common_cancelBooking_bankDetails_form_city2,
  "APPB2C.common.cancelBooking.bankDetails.form.country": APPB2C_common_cancelBooking_bankDetails_form_country2,
  "APPB2C.common.cancelBooking.bankDetails.form.holderEmail": APPB2C_common_cancelBooking_bankDetails_form_holderEmail2,
  "APPB2C.common.cancelBooking.bankDetails.form.holderName": APPB2C_common_cancelBooking_bankDetails_form_holderName2,
  "APPB2C.common.cancelBooking.bankDetails.form.iban": APPB2C_common_cancelBooking_bankDetails_form_iban2,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidIban": APPB2C_common_cancelBooking_bankDetails_form_invalidIban2,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidSwift": APPB2C_common_cancelBooking_bankDetails_form_invalidSwift2,
  "APPB2C.common.cancelBooking.bankDetails.form.postcode": APPB2C_common_cancelBooking_bankDetails_form_postcode2,
  "APPB2C.common.cancelBooking.bankDetails.form.sortCode": APPB2C_common_cancelBooking_bankDetails_form_sortCode2,
  "APPB2C.common.cancelBooking.bankDetails.form.swift": APPB2C_common_cancelBooking_bankDetails_form_swift2,
  "APPB2C.common.cancelBooking.bankDetails.note": APPB2C_common_cancelBooking_bankDetails_note2,
  "APPB2C.common.cancelBooking.bankDetails.privacy": APPB2C_common_cancelBooking_bankDetails_privacy2,
  "APPB2C.common.cancelBooking.bankDetails.terms.consent": APPB2C_common_cancelBooking_bankDetails_terms_consent2,
  "APPB2C.common.cancelBooking.bankDetails.terms.title": APPB2C_common_cancelBooking_bankDetails_terms_title2,
  "APPB2C.common.cancelBooking.bankDetails.title": APPB2C_common_cancelBooking_bankDetails_title2,
  "APPB2C.common.cancelBooking.bottomNotes.note1": APPB2C_common_cancelBooking_bottomNotes_note12,
  "APPB2C.common.cancelBooking.bottomNotes.note2": APPB2C_common_cancelBooking_bottomNotes_note22,
  "APPB2C.common.cancelBooking.bottomNotes.note3": APPB2C_common_cancelBooking_bottomNotes_note32,
  "APPB2C.common.cancelBooking.bottomNotes.terms": APPB2C_common_cancelBooking_bottomNotes_terms2,
  "APPB2C.common.cancelBooking.cancelNotes.1": APPB2C_common_cancelBooking_cancelNotes_12,
  "APPB2C.common.cancelBooking.cancelNotes.2": APPB2C_common_cancelBooking_cancelNotes_22,
  "APPB2C.common.cancelBooking.cancelNotes.3": APPB2C_common_cancelBooking_cancelNotes_32,
  "APPB2C.common.cancelBooking.checkTest": APPB2C_common_cancelBooking_checkTest2,
  "APPB2C.common.cancelBooking.cta.addBankDetails": APPB2C_common_cancelBooking_cta_addBankDetails2,
  "APPB2C.common.cancelBooking.cta.goBack": APPB2C_common_cancelBooking_cta_goBack2,
  "APPB2C.common.cancelBooking.cta.submit": APPB2C_common_cancelBooking_cta_submit2,
  "APPB2C.common.cancelBooking.error.cancelledAlready": APPB2C_common_cancelBooking_error_cancelledAlready2,
  "APPB2C.common.cancelBooking.error.missingFile": APPB2C_common_cancelBooking_error_missingFile2,
  "APPB2C.common.cancelBooking.error.requestedAlready": APPB2C_common_cancelBooking_error_requestedAlready2,
  "APPB2C.common.cancelBooking.reason.form.choose": APPB2C_common_cancelBooking_reason_form_choose2,
  "APPB2C.common.cancelBooking.reason.form.freeform": APPB2C_common_cancelBooking_reason_form_freeform2,
  "APPB2C.common.cancelBooking.reason.form.optional": APPB2C_common_cancelBooking_reason_form_optional2,
  "APPB2C.common.cancelBooking.reason.form.requiredEvidence": APPB2C_common_cancelBooking_reason_form_requiredEvidence2,
  "APPB2C.common.cancelBooking.reason.form.requiredReason": APPB2C_common_cancelBooking_reason_form_requiredReason2,
  "APPB2C.common.cancelBooking.reason.form.title": APPB2C_common_cancelBooking_reason_form_title2,
  "APPB2C.common.cancelBooking.reason.note.content": APPB2C_common_cancelBooking_reason_note_content2,
  "APPB2C.common.cancelBooking.reason.note.title": APPB2C_common_cancelBooking_reason_note_title2,
  "APPB2C.common.cancelBooking.subtitle": APPB2C_common_cancelBooking_subtitle2,
  "APPB2C.common.cancelBooking.title": APPB2C_common_cancelBooking_title2,
  "APPB2C.common.cancelBooking.transferNote.cta": APPB2C_common_cancelBooking_transferNote_cta2,
  "APPB2C.common.cancelBooking.transferNote.title": APPB2C_common_cancelBooking_transferNote_title2,
  "APPB2C.common.cancelBooking.transferNote.titleIol": APPB2C_common_cancelBooking_transferNote_titleIol2,
  "APPB2C.common.cancelBooking.upload.mobile": APPB2C_common_cancelBooking_upload_mobile2,
  "APPB2C.common.cancelBooking.upload.notes.1": APPB2C_common_cancelBooking_upload_notes_12,
  "APPB2C.common.cancelBooking.upload.notes.10": APPB2C_common_cancelBooking_upload_notes_102,
  "APPB2C.common.cancelBooking.upload.notes.2": APPB2C_common_cancelBooking_upload_notes_22,
  "APPB2C.common.cancelBooking.upload.notes.3": APPB2C_common_cancelBooking_upload_notes_32,
  "APPB2C.common.cancelBooking.upload.notes.4": APPB2C_common_cancelBooking_upload_notes_42,
  "APPB2C.common.cancelBooking.upload.notes.5": APPB2C_common_cancelBooking_upload_notes_52,
  "APPB2C.common.cancelBooking.upload.notes.6": APPB2C_common_cancelBooking_upload_notes_62,
  "APPB2C.common.cancelBooking.upload.notes.7": APPB2C_common_cancelBooking_upload_notes_72,
  "APPB2C.common.cancelBooking.upload.notes.8": APPB2C_common_cancelBooking_upload_notes_82,
  "APPB2C.common.cancelBooking.upload.requirements.note": APPB2C_common_cancelBooking_upload_requirements_note2,
  "APPB2C.common.cancelBooking.upload.requirements.title": APPB2C_common_cancelBooking_upload_requirements_title2,
  "APPB2C.common.cancelBooking.upload.title": APPB2C_common_cancelBooking_upload_title2,
  "APPB2C.common.changeBooking.cancel.cta": APPB2C_common_changeBooking_cancel_cta2,
  "APPB2C.common.changeBooking.cancel.text": APPB2C_common_changeBooking_cancel_text2,
  "APPB2C.common.changeBooking.goBack": APPB2C_common_changeBooking_goBack2,
  "APPB2C.common.changeBooking.subtitle": APPB2C_common_changeBooking_subtitle2,
  "APPB2C.common.changeBooking.title": APPB2C_common_changeBooking_title2,
  "APPB2C.common.changeBooking.transfer.cta": APPB2C_common_changeBooking_transfer_cta2,
  "APPB2C.common.changeBooking.transfer.text": APPB2C_common_changeBooking_transfer_text2,
  "APPB2C.common.changeBooking.transfer.textIol": APPB2C_common_changeBooking_transfer_textIol2,
  "APPB2C.common.changeBooking.transferNotes.1": APPB2C_common_changeBooking_transferNotes_12,
  "APPB2C.common.changeBooking.transferNotes.2": APPB2C_common_changeBooking_transferNotes_22,
  "APPB2C.common.changeBooking.transferNotesIol.1": APPB2C_common_changeBooking_transferNotesIol_12,
  "APPB2C.common.changeBooking.transferNotesIol.2": APPB2C_common_changeBooking_transferNotesIol_22,
  "APPB2C.common.changeBookingAck.back": APPB2C_common_changeBookingAck_back2,
  "APPB2C.common.changeBookingAck.cancel.banner": APPB2C_common_changeBookingAck_cancel_banner2,
  "APPB2C.common.changeBookingAck.cancel.contact.email": APPB2C_common_changeBookingAck_cancel_contact_email2,
  "APPB2C.common.changeBookingAck.cancel.contact.phone": APPB2C_common_changeBookingAck_cancel_contact_phone2,
  "APPB2C.common.changeBookingAck.cancel.contact.title": APPB2C_common_changeBookingAck_cancel_contact_title2,
  "APPB2C.common.changeBookingAck.cancel.contact.titleIol": APPB2C_common_changeBookingAck_cancel_contact_titleIol2,
  "APPB2C.common.changeBookingAck.cancel.refNr": APPB2C_common_changeBookingAck_cancel_refNr2,
  "APPB2C.common.changeBookingAck.cancel.refunds": APPB2C_common_changeBookingAck_cancel_refunds2,
  "APPB2C.common.changeBookingAck.cancel.respondTime": APPB2C_common_changeBookingAck_cancel_respondTime2,
  "APPB2C.common.changeBookingAck.cancel.terms": APPB2C_common_changeBookingAck_cancel_terms2,
  "APPB2C.common.changeBookingAck.cancel.title": APPB2C_common_changeBookingAck_cancel_title2,
  "APPB2C.common.changeBookingAck.fees": APPB2C_common_changeBookingAck_fees2,
  "APPB2C.common.changeBookingAck.feesIol": APPB2C_common_changeBookingAck_feesIol2,
  "APPB2C.common.changeBookingAck.speaking.banner": APPB2C_common_changeBookingAck_speaking_banner2,
  "APPB2C.common.changeBookingAck.speaking.note.line1": APPB2C_common_changeBookingAck_speaking_note_line12,
  "APPB2C.common.changeBookingAck.speaking.note.line2": APPB2C_common_changeBookingAck_speaking_note_line22,
  "APPB2C.common.changeBookingAck.speaking.note.title": APPB2C_common_changeBookingAck_speaking_note_title2,
  "APPB2C.common.changeBookingAck.speaking.title": APPB2C_common_changeBookingAck_speaking_title2,
  "APPB2C.common.changeBookingAck.terms": APPB2C_common_changeBookingAck_terms2,
  "APPB2C.common.changeBookingAck.transfer.banner": APPB2C_common_changeBookingAck_transfer_banner2,
  "APPB2C.common.changeBookingAck.transfer.note": APPB2C_common_changeBookingAck_transfer_note2,
  "APPB2C.common.changeBookingAck.transfer.respondTime": APPB2C_common_changeBookingAck_transfer_respondTime2,
  "APPB2C.common.changeBookingAck.transfer.title": APPB2C_common_changeBookingAck_transfer_title2,
  "APPB2C.common.changeBookingAck.transferIol.banner": APPB2C_common_changeBookingAck_transferIol_banner2,
  "APPB2C.common.changeBookingAck.transferIol.contact": APPB2C_common_changeBookingAck_transferIol_contact2,
  "APPB2C.common.changeBookingAck.transferIol.note": APPB2C_common_changeBookingAck_transferIol_note2,
  "APPB2C.common.changeBookingAck.transferIol.refNr": APPB2C_common_changeBookingAck_transferIol_refNr2,
  "APPB2C.common.changeBookingAck.transferIol.terms": APPB2C_common_changeBookingAck_transferIol_terms2,
  "APPB2C.common.changeSpeaking.cards.change": APPB2C_common_changeSpeaking_cards_change2,
  "APPB2C.common.changeSpeaking.cards.noSlots": APPB2C_common_changeSpeaking_cards_noSlots2,
  "APPB2C.common.changeSpeaking.cards.noSlots.error": APPB2C_common_changeSpeaking_cards_noSlots_error2,
  "APPB2C.common.changeSpeaking.cards.title": APPB2C_common_changeSpeaking_cards_title2,
  "APPB2C.common.changeSpeaking.cta": APPB2C_common_changeSpeaking_cta2,
  "APPB2C.common.changeSpeaking.modals.noSlots.keepTest": APPB2C_common_changeSpeaking_modals_noSlots_keepTest2,
  "APPB2C.common.changeSpeaking.modals.noSlots.line1": APPB2C_common_changeSpeaking_modals_noSlots_line12,
  "APPB2C.common.changeSpeaking.modals.noSlots.line2": APPB2C_common_changeSpeaking_modals_noSlots_line22,
  "APPB2C.common.changeSpeaking.modals.noSlots.title": APPB2C_common_changeSpeaking_modals_noSlots_title2,
  "APPB2C.common.changeSpeaking.modals.noSlots.transferTest": APPB2C_common_changeSpeaking_modals_noSlots_transferTest2,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.cta": APPB2C_common_changeSpeaking_modals_testNotAvailable_cta2,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line1": APPB2C_common_changeSpeaking_modals_testNotAvailable_line12,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line2": APPB2C_common_changeSpeaking_modals_testNotAvailable_line22,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.title": APPB2C_common_changeSpeaking_modals_testNotAvailable_title2,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.cta": APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta2,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.line1": APPB2C_common_changeSpeaking_modals_transferNotAvailable_line12,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.title": APPB2C_common_changeSpeaking_modals_transferNotAvailable_title2,
  "APPB2C.common.changeSpeaking.note.back": APPB2C_common_changeSpeaking_note_back2,
  "APPB2C.common.changeSpeaking.note.line1": APPB2C_common_changeSpeaking_note_line12,
  "APPB2C.common.changeSpeaking.note.line2": APPB2C_common_changeSpeaking_note_line22,
  "APPB2C.common.changeSpeaking.note.title": APPB2C_common_changeSpeaking_note_title2,
  "APPB2C.common.changeSpeaking.title": APPB2C_common_changeSpeaking_title2,
  "APPB2C.common.chooseTest.difference": APPB2C_common_chooseTest_difference2,
  "APPB2C.common.chooseTest.iol.consent": APPB2C_common_chooseTest_iol_consent2,
  "APPB2C.common.chooseTest.iol.cta": APPB2C_common_chooseTest_iol_cta2,
  "APPB2C.common.chooseTest.iol.desc": APPB2C_common_chooseTest_iol_desc2,
  "APPB2C.common.chooseTest.iol.link": APPB2C_common_chooseTest_iol_link2,
  "APPB2C.common.chooseTest.iol.note.content": APPB2C_common_chooseTest_iol_note_content2,
  "APPB2C.common.chooseTest.iol.note.title": APPB2C_common_chooseTest_iol_note_title2,
  "APPB2C.common.chooseTest.iol.title": APPB2C_common_chooseTest_iol_title2,
  "APPB2C.common.chooseTest.ors.cta": APPB2C_common_chooseTest_ors_cta2,
  "APPB2C.common.chooseTest.ors.descAc": APPB2C_common_chooseTest_ors_descAc2,
  "APPB2C.common.chooseTest.ors.descGt": APPB2C_common_chooseTest_ors_descGt2,
  "APPB2C.common.chooseTest.ors.title": APPB2C_common_chooseTest_ors_title2,
  "APPB2C.common.chooseTest.title": APPB2C_common_chooseTest_title2,
  "APPB2C.common.chooseTest.ukvi.difference": APPB2C_common_chooseTest_ukvi_difference2,
  "APPB2C.common.chooseTest.ukvi.inCentre.cta": APPB2C_common_chooseTest_ukvi_inCentre_cta2,
  "APPB2C.common.chooseTest.ukvi.inCentre.descAc": APPB2C_common_chooseTest_ukvi_inCentre_descAc2,
  "APPB2C.common.chooseTest.ukvi.inCentre.descGt": APPB2C_common_chooseTest_ukvi_inCentre_descGt2,
  "APPB2C.common.chooseTest.ukvi.inCentre.title": APPB2C_common_chooseTest_ukvi_inCentre_title2,
  "APPB2C.common.chooseTest.ukvi.online.consent": APPB2C_common_chooseTest_ukvi_online_consent2,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.symbol": APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol2,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.title": APPB2C_common_chooseTest_ukvi_online_consentBiometric_title2,
  "APPB2C.common.chooseTest.ukvi.online.cta": APPB2C_common_chooseTest_ukvi_online_cta2,
  "APPB2C.common.chooseTest.ukvi.online.link": APPB2C_common_chooseTest_ukvi_online_link2,
  "APPB2C.common.chooseTest.ukvi.online.note.content": APPB2C_common_chooseTest_ukvi_online_note_content2,
  "APPB2C.common.chooseTest.ukvi.online.note.title": APPB2C_common_chooseTest_ukvi_online_note_title2,
  "APPB2C.common.chooseTest.ukvi.online.title": APPB2C_common_chooseTest_ukvi_online_title2,
  "APPB2C.common.draft.cancel": APPB2C_common_draft_cancel,
  "APPB2C.common.draft.cta.confirm": APPB2C_common_draft_cta_confirm2,
  "APPB2C.common.draft.cta.decline": APPB2C_common_draft_cta_decline2,
  "APPB2C.common.draft.cta.note": APPB2C_common_draft_cta_note2,
  "APPB2C.common.draft.difference": APPB2C_common_draft_difference2,
  "APPB2C.common.draft.edit": APPB2C_common_draft_edit2,
  "APPB2C.common.draft.error.pastDeadline": APPB2C_common_draft_error_pastDeadline2,
  "APPB2C.common.draft.id.cta": APPB2C_common_draft_id_cta2,
  "APPB2C.common.draft.id.documentWithNoExpiryDate": APPB2C_common_draft_id_documentWithNoExpiryDate2,
  "APPB2C.common.draft.id.header": APPB2C_common_draft_id_header2,
  "APPB2C.common.draft.id.issuingAuthority": APPB2C_common_draft_id_issuingAuthority2,
  "APPB2C.common.draft.id.note": APPB2C_common_draft_id_note2,
  "APPB2C.common.draft.id.title": APPB2C_common_draft_id_title2,
  "APPB2C.common.draft.note.line1": APPB2C_common_draft_note_line12,
  "APPB2C.common.draft.note.line2": APPB2C_common_draft_note_line22,
  "APPB2C.common.draft.note.line3": APPB2C_common_draft_note_line32,
  "APPB2C.common.draft.personal.cta": APPB2C_common_draft_personal_cta2,
  "APPB2C.common.draft.personal.header": APPB2C_common_draft_personal_header2,
  "APPB2C.common.draft.personal.note": APPB2C_common_draft_personal_note2,
  "APPB2C.common.draft.personal.title": APPB2C_common_draft_personal_title2,
  "APPB2C.common.draft.title": APPB2C_common_draft_title2,
  "APPB2C.common.examType.iol.ac": APPB2C_common_examType_iol_ac2,
  "APPB2C.common.examType.iol.gt": APPB2C_common_examType_iol_gt2,
  "APPB2C.common.examType.ors.ac": APPB2C_common_examType_ors_ac2,
  "APPB2C.common.examType.ors.gt": APPB2C_common_examType_ors_gt2,
  "APPB2C.common.examType.ors.osr.ac": APPB2C_common_examType_ors_osr_ac2,
  "APPB2C.common.examType.ors.osr.gt": APPB2C_common_examType_ors_osr_gt2,
  "APPB2C.common.examType.ukvi.ac": APPB2C_common_examType_ukvi_ac2,
  "APPB2C.common.examType.ukvi.gt": APPB2C_common_examType_ukvi_gt2,
  "APPB2C.common.examType.ukvi.lfska1": APPB2C_common_examType_ukvi_lfska12,
  "APPB2C.common.examType.ukvi.lfska2": APPB2C_common_examType_ukvi_lfska22,
  "APPB2C.common.examType.ukvi.lfskb1": APPB2C_common_examType_ukvi_lfskb12,
  "APPB2C.common.examType.ukvi.osr.ac": APPB2C_common_examType_ukvi_osr_ac2,
  "APPB2C.common.examType.ukvi.osr.gt": APPB2C_common_examType_ukvi_osr_gt2,
  "APPB2C.common.examType.uol.ac": APPB2C_common_examType_uol_ac2,
  "APPB2C.common.examType.uol.gt": APPB2C_common_examType_uol_gt2,
  "APPB2C.common.filePreview.label": APPB2C_common_filePreview_label2,
  "APPB2C.common.filePreview.pdfPreviewLink": APPB2C_common_filePreview_pdfPreviewLink2,
  "APPB2C.common.filePreview.status": APPB2C_common_filePreview_status2,
  "APPB2C.common.filePreview.statusName.approved": APPB2C_common_filePreview_statusName_approved2,
  "APPB2C.common.filePreview.statusName.deleted": APPB2C_common_filePreview_statusName_deleted2,
  "APPB2C.common.filePreview.statusName.pending": APPB2C_common_filePreview_statusName_pending2,
  "APPB2C.common.filePreview.statusName.rejected": APPB2C_common_filePreview_statusName_rejected2,
  "APPB2C.common.filePreview.statusName.reviewed": APPB2C_common_filePreview_statusName_reviewed2,
  "APPB2C.common.fileUpload.cta": APPB2C_common_fileUpload_cta2,
  "APPB2C.common.fileUpload.exceededSize": APPB2C_common_fileUpload_exceededSize2,
  "APPB2C.common.fileUpload.fileApproved": APPB2C_common_fileUpload_fileApproved2,
  "APPB2C.common.fileUpload.fileOptional": APPB2C_common_fileUpload_fileOptional2,
  "APPB2C.common.fileUpload.fileRequired": APPB2C_common_fileUpload_fileRequired2,
  "APPB2C.common.fileUpload.filesCount": APPB2C_common_fileUpload_filesCount2,
  "APPB2C.common.fileUpload.fileSize": APPB2C_common_fileUpload_fileSize2,
  "APPB2C.common.fileUpload.imgAlt": APPB2C_common_fileUpload_imgAlt2,
  "APPB2C.common.fileUpload.pdfIeMsg": APPB2C_common_fileUpload_pdfIeMsg2,
  "APPB2C.common.fileUpload.pdfPasswordMsg": APPB2C_common_fileUpload_pdfPasswordMsg2,
  "APPB2C.common.fileUpload.remove": APPB2C_common_fileUpload_remove2,
  "APPB2C.common.fileUpload.requirements": APPB2C_common_fileUpload_requirements2,
  "APPB2C.common.fileUpload.success": APPB2C_common_fileUpload_success2,
  "APPB2C.common.findTest.changeCity": APPB2C_common_findTest_changeCity2,
  "APPB2C.common.findTest.changeCountry": APPB2C_common_findTest_changeCountry2,
  "APPB2C.common.findTest.cta": APPB2C_common_findTest_cta2,
  "APPB2C.common.findTest.dates.clear": APPB2C_common_findTest_dates_clear2,
  "APPB2C.common.findTest.dates.from": APPB2C_common_findTest_dates_from2,
  "APPB2C.common.findTest.dates.noDatesSelected": APPB2C_common_findTest_dates_noDatesSelected2,
  "APPB2C.common.findTest.dates.noresults.details": APPB2C_common_findTest_dates_noresults_details2,
  "APPB2C.common.findTest.dates.noresults.title": APPB2C_common_findTest_dates_noresults_title2,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.cta": APPB2C_common_findTest_dates_noresultsMobileLocation_cta2,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.details": APPB2C_common_findTest_dates_noresultsMobileLocation_details2,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.title": APPB2C_common_findTest_dates_noresultsMobileLocation_title2,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.details": APPB2C_common_findTest_dates_noresultsSpecialReqs_details2,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.title": APPB2C_common_findTest_dates_noresultsSpecialReqs_title2,
  "APPB2C.common.findTest.dates.pleaseSelect": APPB2C_common_findTest_dates_pleaseSelect2,
  "APPB2C.common.findTest.dates.selected": APPB2C_common_findTest_dates_selected2,
  "APPB2C.common.findTest.dates.showAll": APPB2C_common_findTest_dates_showAll2,
  "APPB2C.common.findTest.dates.showDateRange": APPB2C_common_findTest_dates_showDateRange2,
  "APPB2C.common.findTest.dates.title": APPB2C_common_findTest_dates_title2,
  "APPB2C.common.findTest.dates.to": APPB2C_common_findTest_dates_to2,
  "APPB2C.common.findTest.examFormat.all": APPB2C_common_findTest_examFormat_all2,
  "APPB2C.common.findTest.examFormat.cd": APPB2C_common_findTest_examFormat_cd2,
  "APPB2C.common.findTest.examFormat.pb": APPB2C_common_findTest_examFormat_pb2,
  "APPB2C.common.findTest.examFormat.results": APPB2C_common_findTest_examFormat_results2,
  "APPB2C.common.findTest.examFormat.title": APPB2C_common_findTest_examFormat_title2,
  "APPB2C.common.findTest.iol.noLrwResults": APPB2C_common_findTest_iol_noLrwResults2,
  "APPB2C.common.findTest.iol.organisationCheck.check": APPB2C_common_findTest_iol_organisationCheck_check2,
  "APPB2C.common.findTest.iol.organisationCheck.desc": APPB2C_common_findTest_iol_organisationCheck_desc2,
  "APPB2C.common.findTest.iol.organisationCheck.title": APPB2C_common_findTest_iol_organisationCheck_title2,
  "APPB2C.common.findTest.iol.softwareCheck.check": APPB2C_common_findTest_iol_softwareCheck_check2,
  "APPB2C.common.findTest.iol.softwareCheck.desc1": APPB2C_common_findTest_iol_softwareCheck_desc12,
  "APPB2C.common.findTest.iol.softwareCheck.desc2": APPB2C_common_findTest_iol_softwareCheck_desc22,
  "APPB2C.common.findTest.iol.softwareCheck.link": APPB2C_common_findTest_iol_softwareCheck_link2,
  "APPB2C.common.findTest.iol.softwareCheck.title": APPB2C_common_findTest_iol_softwareCheck_title2,
  "APPB2C.common.findTest.langModal.choose": APPB2C_common_findTest_langModal_choose2,
  "APPB2C.common.findTest.langModal.chooseSingle": APPB2C_common_findTest_langModal_chooseSingle2,
  "APPB2C.common.findTest.langModal.continue": APPB2C_common_findTest_langModal_continue2,
  "APPB2C.common.findTest.langModal.title": APPB2C_common_findTest_langModal_title2,
  "APPB2C.common.findTest.multipleLocations": APPB2C_common_findTest_multipleLocations2,
  "APPB2C.common.findTest.osrInfo": APPB2C_common_findTest_osrInfo2,
  "APPB2C.common.findTest.osrInfoLink": APPB2C_common_findTest_osrInfoLink2,
  "APPB2C.common.findTest.residencyConfirmation.btn": APPB2C_common_findTest_residencyConfirmation_btn2,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.btn": APPB2C_common_findTest_residencyConfirmation_errorMsg_btn2,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line1": APPB2C_common_findTest_residencyConfirmation_errorMsg_line12,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line2": APPB2C_common_findTest_residencyConfirmation_errorMsg_line22,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.title": APPB2C_common_findTest_residencyConfirmation_errorMsg_title2,
  "APPB2C.common.findTest.residencyConfirmation.msg": APPB2C_common_findTest_residencyConfirmation_msg,
  "APPB2C.common.findTest.residencyConfirmation.msg.line1": APPB2C_common_findTest_residencyConfirmation_msg_line12,
  "APPB2C.common.findTest.residencyConfirmation.msg.line2": APPB2C_common_findTest_residencyConfirmation_msg_line22,
  "APPB2C.common.findTest.residencyConfirmation.msg.line3": APPB2C_common_findTest_residencyConfirmation_msg_line32,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInCountryLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine22,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInLocationLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine22,
  "APPB2C.common.findTest.residencyConfirmation.selectDates": APPB2C_common_findTest_residencyConfirmation_selectDates2,
  "APPB2C.common.findTest.residencyConfirmation.title": APPB2C_common_findTest_residencyConfirmation_title2,
  "APPB2C.common.findTest.searchIn": APPB2C_common_findTest_searchIn2,
  "APPB2C.common.findTest.selectCountry.continue": APPB2C_common_findTest_selectCountry_continue2,
  "APPB2C.common.findTest.selectCountry.countryNotSupported": APPB2C_common_findTest_selectCountry_countryNotSupported2,
  "APPB2C.common.findTest.selectCountry.label": APPB2C_common_findTest_selectCountry_label2,
  "APPB2C.common.findTest.selectCountry.placeholder": APPB2C_common_findTest_selectCountry_placeholder2,
  "APPB2C.common.findTest.selectLocation.closestToMe": APPB2C_common_findTest_selectLocation_closestToMe2,
  "APPB2C.common.findTest.selectLocation.geolocationBlocked": APPB2C_common_findTest_selectLocation_geolocationBlocked2,
  "APPB2C.common.findTest.selectLocation.label": APPB2C_common_findTest_selectLocation_label2,
  "APPB2C.common.findTest.selectLocation.noExamForThisCountry": APPB2C_common_findTest_selectLocation_noExamForThisCountry2,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation2,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.cta": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta2,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option1": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option12,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option2": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option22,
  "APPB2C.common.findTest.specialReqs.findOutMore": APPB2C_common_findTest_specialReqs_findOutMore2,
  "APPB2C.common.findTest.specialReqs.list.subtitle": APPB2C_common_findTest_specialReqs_list_subtitle2,
  "APPB2C.common.findTest.specialReqs.list.title": APPB2C_common_findTest_specialReqs_list_title2,
  "APPB2C.common.findTest.specialReqs.needCheckbox": APPB2C_common_findTest_specialReqs_needCheckbox2,
  "APPB2C.common.findTest.specialReqs.other": APPB2C_common_findTest_specialReqs_other2,
  "APPB2C.common.findTest.specialReqs.pleaseNoteMessage": APPB2C_common_findTest_specialReqs_pleaseNoteMessage2,
  "APPB2C.common.findTest.subtitle": APPB2C_common_findTest_subtitle2,
  "APPB2C.common.findTest.title": APPB2C_common_findTest_title2,
  "APPB2C.common.findTest.uol.organisationCheck.check": APPB2C_common_findTest_uol_organisationCheck_check2,
  "APPB2C.common.findTest.uol.organisationCheck.desc": APPB2C_common_findTest_uol_organisationCheck_desc2,
  "APPB2C.common.findTest.uol.organisationCheck.title": APPB2C_common_findTest_uol_organisationCheck_title2,
  "APPB2C.common.findTest.uol.softwareCheck.check": APPB2C_common_findTest_uol_softwareCheck_check2,
  "APPB2C.common.findTest.uol.softwareCheck.desc1": APPB2C_common_findTest_uol_softwareCheck_desc12,
  "APPB2C.common.findTest.uol.softwareCheck.desc2": APPB2C_common_findTest_uol_softwareCheck_desc22,
  "APPB2C.common.findTest.uol.softwareCheck.link": APPB2C_common_findTest_uol_softwareCheck_link2,
  "APPB2C.common.findTest.uol.softwareCheck.title": APPB2C_common_findTest_uol_softwareCheck_title2,
  "APPB2C.common.footer.terms.accessibility": APPB2C_common_footer_terms_accessibility2,
  "APPB2C.common.footer.terms.copyrightLine1": APPB2C_common_footer_terms_copyrightLine12,
  "APPB2C.common.footer.terms.copyrightLine2": APPB2C_common_footer_terms_copyrightLine22,
  "APPB2C.common.footer.terms.dataProtection": APPB2C_common_footer_terms_dataProtection2,
  "APPB2C.common.footer.terms.privacyAndCookies": APPB2C_common_footer_terms_privacyAndCookies2,
  "APPB2C.common.footer.terms.termsOfUse": APPB2C_common_footer_terms_termsOfUse2,
  "APPB2C.common.gelRedirect.errorMessage": APPB2C_common_gelRedirect_errorMessage2,
  "APPB2C.common.gelRedirect.goBack": APPB2C_common_gelRedirect_goBack,
  "APPB2C.common.gelRedirect.header": APPB2C_common_gelRedirect_header2,
  "APPB2C.common.gelRedirect.info": APPB2C_common_gelRedirect_info2,
  "APPB2C.common.general.next": APPB2C_common_general_next2,
  "APPB2C.common.general.previous": APPB2C_common_general_previous2,
  "APPB2C.common.general.regNotFound": APPB2C_common_general_regNotFound2,
  "APPB2C.common.general.remove": APPB2C_common_general_remove2,
  "APPB2C.common.general.steps": APPB2C_common_general_steps2,
  "APPB2C.common.general.stepsContinued": APPB2C_common_general_stepsContinued2,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabel": APPB2C_common_helpAndContact_actionSection_contactFormLabel2,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabelInCentre": APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre2,
  "APPB2C.common.helpAndContact.actionSection.contactFormLink": APPB2C_common_helpAndContact_actionSection_contactFormLink2,
  "APPB2C.common.helpAndContact.actionSection.faqLabel": APPB2C_common_helpAndContact_actionSection_faqLabel2,
  "APPB2C.common.helpAndContact.actionSection.faqLink": APPB2C_common_helpAndContact_actionSection_faqLink2,
  "APPB2C.common.helpAndContact.actionSection.icHeader": APPB2C_common_helpAndContact_actionSection_icHeader2,
  "APPB2C.common.helpAndContact.actionSection.iolHeader": APPB2C_common_helpAndContact_actionSection_iolHeader2,
  "APPB2C.common.helpAndContact.actionSection.launchChatBtn": APPB2C_common_helpAndContact_actionSection_launchChatBtn2,
  "APPB2C.common.helpAndContact.actionSection.launchChatLabel": APPB2C_common_helpAndContact_actionSection_launchChatLabel2,
  "APPB2C.common.helpAndContact.form.back": APPB2C_common_helpAndContact_form_back2,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLink": APPB2C_common_helpAndContact_form_complaint_moreInfoLink2,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLinkTitle": APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle2,
  "APPB2C.common.helpAndContact.form.complaint.radio.complaint": APPB2C_common_helpAndContact_form_complaint_radio_complaint2,
  "APPB2C.common.helpAndContact.form.complaint.radio.other": APPB2C_common_helpAndContact_form_complaint_radio_other2,
  "APPB2C.common.helpAndContact.form.complaint.title": APPB2C_common_helpAndContact_form_complaint_title2,
  "APPB2C.common.helpAndContact.form.label": APPB2C_common_helpAndContact_form_label2,
  "APPB2C.common.helpAndContact.form.legal": APPB2C_common_helpAndContact_form_legal2,
  "APPB2C.common.helpAndContact.form.response": APPB2C_common_helpAndContact_form_response2,
  "APPB2C.common.helpAndContact.form.submit": APPB2C_common_helpAndContact_form_submit2,
  "APPB2C.common.helpAndContact.form.thanks.back": APPB2C_common_helpAndContact_form_thanks_back2,
  "APPB2C.common.helpAndContact.form.thanks.banner": APPB2C_common_helpAndContact_form_thanks_banner2,
  "APPB2C.common.helpAndContact.form.thanks.info": APPB2C_common_helpAndContact_form_thanks_info2,
  "APPB2C.common.helpAndContact.form.thanks.title": APPB2C_common_helpAndContact_form_thanks_title2,
  "APPB2C.common.helpAndContact.form.title": APPB2C_common_helpAndContact_form_title2,
  "APPB2C.common.helpAndContact.form.validation": APPB2C_common_helpAndContact_form_validation2,
  "APPB2C.common.helpAndContact.icContent.commonQuestionsHeader": APPB2C_common_helpAndContact_icContent_commonQuestionsHeader2,
  "APPB2C.common.helpAndContact.icContent.emailLabel": APPB2C_common_helpAndContact_icContent_emailLabel2,
  "APPB2C.common.helpAndContact.icContent.header": APPB2C_common_helpAndContact_icContent_header2,
  "APPB2C.common.helpAndContact.icContent.phoneLabel": APPB2C_common_helpAndContact_icContent_phoneLabel2,
  "APPB2C.common.helpAndContact.icContent.referenceReminder": APPB2C_common_helpAndContact_icContent_referenceReminder2,
  "APPB2C.common.helpAndContact.ieltsReference": APPB2C_common_helpAndContact_ieltsReference2,
  "APPB2C.common.helpAndContact.noTests.bookNewBtn": APPB2C_common_helpAndContact_noTests_bookNewBtn2,
  "APPB2C.common.helpAndContact.noTests.contactLink": APPB2C_common_helpAndContact_noTests_contactLink2,
  "APPB2C.common.helpAndContact.noTests.header": APPB2C_common_helpAndContact_noTests_header2,
  "APPB2C.common.helpAndContact.selectExamLabel": APPB2C_common_helpAndContact_selectExamLabel2,
  "APPB2C.common.helpAndContact.title": APPB2C_common_helpAndContact_title2,
  "APPB2C.common.invitation.modal.header": APPB2C_common_invitation_modal_header2,
  "APPB2C.common.invitation.modal.invalid": APPB2C_common_invitation_modal_invalid2,
  "APPB2C.common.modals.newTermsAndConditions.checkboxNote": APPB2C_common_modals_newTermsAndConditions_checkboxNote2,
  "APPB2C.common.modals.newTermsAndConditions.checkboxTC": APPB2C_common_modals_newTermsAndConditions_checkboxTC2,
  "APPB2C.common.modals.newTermsAndConditions.cta": APPB2C_common_modals_newTermsAndConditions_cta2,
  "APPB2C.common.modals.newTermsAndConditions.error": APPB2C_common_modals_newTermsAndConditions_error2,
  "APPB2C.common.modals.newTermsAndConditions.note": APPB2C_common_modals_newTermsAndConditions_note2,
  "APPB2C.common.modals.newTermsAndConditions.title": APPB2C_common_modals_newTermsAndConditions_title2,
  "APPB2C.common.modals.newTermsAndConditions.trigger": APPB2C_common_modals_newTermsAndConditions_trigger2,
  "APPB2C.common.nav.bookNewTest": APPB2C_common_nav_bookNewTest2,
  "APPB2C.common.nav.chooseLanguage": APPB2C_common_nav_chooseLanguage2,
  "APPB2C.common.nav.hello": APPB2C_common_nav_hello2,
  "APPB2C.common.nav.helpAndContact": APPB2C_common_nav_helpAndContact2,
  "APPB2C.common.nav.login": APPB2C_common_nav_login2,
  "APPB2C.common.nav.logout": APPB2C_common_nav_logout2,
  "APPB2C.common.nav.logoutModal.cancel": APPB2C_common_nav_logoutModal_cancel,
  "APPB2C.common.nav.logoutModal.confirm": APPB2C_common_nav_logoutModal_confirm2,
  "APPB2C.common.nav.logoutModal.message": APPB2C_common_nav_logoutModal_message2,
  "APPB2C.common.nav.logoutModal.question": APPB2C_common_nav_logoutModal_question2,
  "APPB2C.common.nav.logoutModal.title": APPB2C_common_nav_logoutModal_title2,
  "APPB2C.common.nav.myAccount": APPB2C_common_nav_myAccount2,
  "APPB2C.common.nav.preparation": APPB2C_common_nav_preparation2,
  "APPB2C.common.nav.resultsTests": APPB2C_common_nav_resultsTests2,
  "APPB2C.common.nav.ttp": APPB2C_common_nav_ttp2,
  "APPB2C.common.preparation.noTests.contactLink": APPB2C_common_preparation_noTests_contactLink2,
  "APPB2C.common.preparation.noTests.header": APPB2C_common_preparation_noTests_header2,
  "APPB2C.common.preparation.selectExamLabel": APPB2C_common_preparation_selectExamLabel2,
  "APPB2C.common.preparation.title": APPB2C_common_preparation_title2,
  "APPB2C.common.preparation.viewTestBtn": APPB2C_common_preparation_viewTestBtn2,
  "APPB2C.common.recreateReg.errMsg": APPB2C_common_recreateReg_errMsg2,
  "APPB2C.common.recreateReg.errTitle": APPB2C_common_recreateReg_errTitle2,
  "APPB2C.common.recreateReg.progress": APPB2C_common_recreateReg_progress2,
  "APPB2C.common.regHeader.reference": APPB2C_common_regHeader_reference2,
  "APPB2C.common.regHeader.trfLoading": APPB2C_common_regHeader_trfLoading2,
  "APPB2C.common.regHeader.trfNumber": APPB2C_common_regHeader_trfNumber2,
  "APPB2C.common.regInProgressModal.continue": APPB2C_common_regInProgressModal_continue2,
  "APPB2C.common.regInProgressModal.message1": APPB2C_common_regInProgressModal_message12,
  "APPB2C.common.regInProgressModal.message2": APPB2C_common_regInProgressModal_message22,
  "APPB2C.common.regInProgressModal.restart": APPB2C_common_regInProgressModal_restart2,
  "APPB2C.common.regInProgressModal.title": APPB2C_common_regInProgressModal_title2,
  "APPB2C.common.selfServices.requested.cancellation": APPB2C_common_selfServices_requested_cancellation2,
  "APPB2C.common.selfServices.requested.transfer": APPB2C_common_selfServices_requested_transfer2,
  "APPB2C.common.specialReqs.AMANU": APPB2C_common_specialReqs_AMANU2,
  "APPB2C.common.specialReqs.ANTHR": APPB2C_common_specialReqs_ANTHR2,
  "APPB2C.common.specialReqs.BTP": APPB2C_common_specialReqs_BTP2,
  "APPB2C.common.specialReqs.BWP": APPB2C_common_specialReqs_BWP2,
  "APPB2C.common.specialReqs.EPTP": APPB2C_common_specialReqs_EPTP2,
  "APPB2C.common.specialReqs.ET": APPB2C_common_specialReqs_ET2,
  "APPB2C.common.specialReqs.LRTV": APPB2C_common_specialReqs_LRTV2,
  "APPB2C.common.specialReqs.SAE": APPB2C_common_specialReqs_SAE2,
  "APPB2C.common.specialReqs.VAS": APPB2C_common_specialReqs_VAS2,
  "APPB2C.common.specialReqs.WP": APPB2C_common_specialReqs_WP2,
  "APPB2C.common.startPage.book": APPB2C_common_startPage_book2,
  "APPB2C.common.startPage.candidates.nowAdult": APPB2C_common_startPage_candidates_nowAdult2,
  "APPB2C.common.startPage.candidates.showingFor": APPB2C_common_startPage_candidates_showingFor2,
  "APPB2C.common.startPage.common.cancelled": APPB2C_common_startPage_common_cancelled2,
  "APPB2C.common.startPage.description.ac": APPB2C_common_startPage_description_ac2,
  "APPB2C.common.startPage.description.gt": APPB2C_common_startPage_description_gt2,
  "APPB2C.common.startPage.description.ukvi": APPB2C_common_startPage_description_ukvi2,
  "APPB2C.common.startPage.endNote": APPB2C_common_startPage_endNote2,
  "APPB2C.common.startPage.error.body": APPB2C_common_startPage_error_body2,
  "APPB2C.common.startPage.error.cta": APPB2C_common_startPage_error_cta2,
  "APPB2C.common.startPage.error.title": APPB2C_common_startPage_error_title2,
  "APPB2C.common.startPage.hero.cta": APPB2C_common_startPage_hero_cta2,
  "APPB2C.common.startPage.hero.subtitle": APPB2C_common_startPage_hero_subtitle2,
  "APPB2C.common.startPage.hero.title": APPB2C_common_startPage_hero_title2,
  "APPB2C.common.startPage.ielts": APPB2C_common_startPage_ielts2,
  "APPB2C.common.startPage.ieltsUkvi": APPB2C_common_startPage_ieltsUkvi2,
  "APPB2C.common.startPage.missing.cancel": APPB2C_common_startPage_missing_cancel2,
  "APPB2C.common.startPage.missing.marketing.cta": APPB2C_common_startPage_missing_marketing_cta2,
  "APPB2C.common.startPage.missing.marketing.notes.full1": APPB2C_common_startPage_missing_marketing_notes_full12,
  "APPB2C.common.startPage.missing.marketing.notes.full2": APPB2C_common_startPage_missing_marketing_notes_full22,
  "APPB2C.common.startPage.missing.marketing.notes.full3": APPB2C_common_startPage_missing_marketing_notes_full32,
  "APPB2C.common.startPage.missing.marketing.notes.link": APPB2C_common_startPage_missing_marketing_notes_link2,
  "APPB2C.common.startPage.missing.marketing.notes.main": APPB2C_common_startPage_missing_marketing_notes_main2,
  "APPB2C.common.startPage.missing.marketing.opts.BcAndThirdParties": APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties2,
  "APPB2C.common.startPage.missing.marketing.opts.BcOnly": APPB2C_common_startPage_missing_marketing_opts_BcOnly2,
  "APPB2C.common.startPage.missing.marketing.opts.DoNotSend": APPB2C_common_startPage_missing_marketing_opts_DoNotSend2,
  "APPB2C.common.startPage.missing.marketing.title": APPB2C_common_startPage_missing_marketing_title2,
  "APPB2C.common.startPage.missing.ttData.cta": APPB2C_common_startPage_missing_ttData_cta2,
  "APPB2C.common.startPage.missing.ttData.dob": APPB2C_common_startPage_missing_ttData_dob2,
  "APPB2C.common.startPage.missing.ttData.firstName": APPB2C_common_startPage_missing_ttData_firstName2,
  "APPB2C.common.startPage.missing.ttData.lastName": APPB2C_common_startPage_missing_ttData_lastName2,
  "APPB2C.common.startPage.missing.ttData.line1": APPB2C_common_startPage_missing_ttData_line12,
  "APPB2C.common.startPage.missing.ttData.line2": APPB2C_common_startPage_missing_ttData_line22,
  "APPB2C.common.startPage.missing.ttData.line3": APPB2C_common_startPage_missing_ttData_line32,
  "APPB2C.common.startPage.missing.ttData.noLastName": APPB2C_common_startPage_missing_ttData_noLastName2,
  "APPB2C.common.startPage.missing.ttData.title": APPB2C_common_startPage_missing_ttData_title2,
  "APPB2C.common.startPage.options.ac": APPB2C_common_startPage_options_ac2,
  "APPB2C.common.startPage.options.gt": APPB2C_common_startPage_options_gt2,
  "APPB2C.common.startPage.options.ukvi": APPB2C_common_startPage_options_ukvi2,
  "APPB2C.common.startPage.past.cd": APPB2C_common_startPage_past_cd2,
  "APPB2C.common.startPage.past.empty": APPB2C_common_startPage_past_empty2,
  "APPB2C.common.startPage.past.osr.newScore": APPB2C_common_startPage_past_osr_newScore2,
  "APPB2C.common.startPage.past.osr.note.awaiting": APPB2C_common_startPage_past_osr_note_awaiting2,
  "APPB2C.common.startPage.past.osr.note.confirmed": APPB2C_common_startPage_past_osr_note_confirmed2,
  "APPB2C.common.startPage.past.osr.note.hasResults": APPB2C_common_startPage_past_osr_note_hasResults2,
  "APPB2C.common.startPage.past.osr.originalScore": APPB2C_common_startPage_past_osr_originalScore2,
  "APPB2C.common.startPage.past.osr.viewRetakeLink": APPB2C_common_startPage_past_osr_viewRetakeLink2,
  "APPB2C.common.startPage.past.pb": APPB2C_common_startPage_past_pb2,
  "APPB2C.common.startPage.past.rebook": APPB2C_common_startPage_past_rebook2,
  "APPB2C.common.startPage.past.score": APPB2C_common_startPage_past_score2,
  "APPB2C.common.startPage.past.scoreLifeSkills": APPB2C_common_startPage_past_scoreLifeSkills2,
  "APPB2C.common.startPage.past.title": APPB2C_common_startPage_past_title2,
  "APPB2C.common.startPage.past.upcomingResults": APPB2C_common_startPage_past_upcomingResults2,
  "APPB2C.common.startPage.readTnCs": APPB2C_common_startPage_readTnCs2,
  "APPB2C.common.startPage.recent.cta": APPB2C_common_startPage_recent_cta2,
  "APPB2C.common.startPage.recent.title": APPB2C_common_startPage_recent_title2,
  "APPB2C.common.startPage.subtitle": APPB2C_common_startPage_subtitle2,
  "APPB2C.common.startPage.title": APPB2C_common_startPage_title2,
  "APPB2C.common.startPage.tncs": APPB2C_common_startPage_tncs2,
  "APPB2C.common.startPage.upcoming.cd": APPB2C_common_startPage_upcoming_cd2,
  "APPB2C.common.startPage.upcoming.empty": APPB2C_common_startPage_upcoming_empty2,
  "APPB2C.common.startPage.upcoming.lifeSkills": APPB2C_common_startPage_upcoming_lifeSkills2,
  "APPB2C.common.startPage.upcoming.live": APPB2C_common_startPage_upcoming_live2,
  "APPB2C.common.startPage.upcoming.manage": APPB2C_common_startPage_upcoming_manage2,
  "APPB2C.common.startPage.upcoming.missing.childConsent": APPB2C_common_startPage_upcoming_missing_childConsent2,
  "APPB2C.common.startPage.upcoming.missing.id": APPB2C_common_startPage_upcoming_missing_id2,
  "APPB2C.common.startPage.upcoming.missing.medical": APPB2C_common_startPage_upcoming_missing_medical2,
  "APPB2C.common.startPage.upcoming.missing.payment": APPB2C_common_startPage_upcoming_missing_payment2,
  "APPB2C.common.startPage.upcoming.noSpeaking": APPB2C_common_startPage_upcoming_noSpeaking2,
  "APPB2C.common.startPage.upcoming.noTime": APPB2C_common_startPage_upcoming_noTime2,
  "APPB2C.common.startPage.upcoming.osr.note": APPB2C_common_startPage_upcoming_osr_note2,
  "APPB2C.common.startPage.upcoming.pastPaymentDeadline": APPB2C_common_startPage_upcoming_pastPaymentDeadline2,
  "APPB2C.common.startPage.upcoming.pay": APPB2C_common_startPage_upcoming_pay2,
  "APPB2C.common.startPage.upcoming.pb": APPB2C_common_startPage_upcoming_pb2,
  "APPB2C.common.startPage.upcoming.prepare": APPB2C_common_startPage_upcoming_prepare2,
  "APPB2C.common.startPage.upcoming.remote": APPB2C_common_startPage_upcoming_remote2,
  "APPB2C.common.startPage.upcoming.title": APPB2C_common_startPage_upcoming_title2,
  "APPB2C.common.startPage.whichIELTS": APPB2C_common_startPage_whichIELTS2,
  "APPB2C.common.terms.downloadInfo": APPB2C_common_terms_downloadInfo2,
  "APPB2C.common.terms.downloadTitle": APPB2C_common_terms_downloadTitle2,
  "APPB2C.common.terms.error": APPB2C_common_terms_error2,
  "APPB2C.common.terms.title": APPB2C_common_terms_title2,
  "APPB2C.common.testDetails.changeTest": APPB2C_common_testDetails_changeTest2,
  "APPB2C.common.testDetails.changeWrittenTest": APPB2C_common_testDetails_changeWrittenTest2,
  "APPB2C.common.testDetails.completeYourBooking": APPB2C_common_testDetails_completeYourBooking2,
  "APPB2C.common.testDetails.mapModal.close": APPB2C_common_testDetails_mapModal_close2,
  "APPB2C.common.testDetails.mapModal.title": APPB2C_common_testDetails_mapModal_title2,
  "APPB2C.common.testDetails.modifyNote.confirm": APPB2C_common_testDetails_modifyNote_confirm2,
  "APPB2C.common.testDetails.modifyNote.note": APPB2C_common_testDetails_modifyNote_note2,
  "APPB2C.common.testDetails.modifyNote.pleaseNote": APPB2C_common_testDetails_modifyNote_pleaseNote2,
  "APPB2C.common.testDetails.title": APPB2C_common_testDetails_title2,
  "APPB2C.common.testDetails.yourSpeakingTest": APPB2C_common_testDetails_yourSpeakingTest2,
  "APPB2C.common.testDetails.yourWrittenTest": APPB2C_common_testDetails_yourWrittenTest2,
  "APPB2C.common.transfer.agreement": APPB2C_common_transfer_agreement2,
  "APPB2C.common.transfer.contactNote": APPB2C_common_transfer_contactNote2,
  "APPB2C.common.transfer.contactNoteIol": APPB2C_common_transfer_contactNoteIol2,
  "APPB2C.common.transfer.cta": APPB2C_common_transfer_cta2,
  "APPB2C.common.transfer.form.freeform": APPB2C_common_transfer_form_freeform2,
  "APPB2C.common.transfer.form.reasons.1": APPB2C_common_transfer_form_reasons_12,
  "APPB2C.common.transfer.form.reasons.2": APPB2C_common_transfer_form_reasons_22,
  "APPB2C.common.transfer.form.reasons.3": APPB2C_common_transfer_form_reasons_32,
  "APPB2C.common.transfer.form.reasons.4": APPB2C_common_transfer_form_reasons_42,
  "APPB2C.common.transfer.form.reasons.choose": APPB2C_common_transfer_form_reasons_choose2,
  "APPB2C.common.transfer.form.reasons.other": APPB2C_common_transfer_form_reasons_other2,
  "APPB2C.common.transfer.form.reasons.title": APPB2C_common_transfer_form_reasons_title2,
  "APPB2C.common.transfer.goBack": APPB2C_common_transfer_goBack2,
  "APPB2C.common.transfer.notes.1": APPB2C_common_transfer_notes_12,
  "APPB2C.common.transfer.notes.2": APPB2C_common_transfer_notes_22,
  "APPB2C.common.transfer.notes.3": APPB2C_common_transfer_notes_32,
  "APPB2C.common.transfer.notes.4": APPB2C_common_transfer_notes_42,
  "APPB2C.common.transfer.notesIol.1": APPB2C_common_transfer_notesIol_12,
  "APPB2C.common.transfer.notesIol.2": APPB2C_common_transfer_notesIol_22,
  "APPB2C.common.transfer.requestValidation": APPB2C_common_transfer_requestValidation2,
  "APPB2C.common.transfer.selfService.availableDates.title": APPB2C_common_transfer_selfService_availableDates_title2,
  "APPB2C.common.transfer.selfService.chooseDates.title": APPB2C_common_transfer_selfService_chooseDates_title2,
  "APPB2C.common.transfer.selfService.review.confirm.proceedBtn": APPB2C_common_transfer_selfService_review_confirm_proceedBtn2,
  "APPB2C.common.transfer.selfService.review.confirm.title": APPB2C_common_transfer_selfService_review_confirm_title2,
  "APPB2C.common.transfer.selfService.review.errors.noDistricts": APPB2C_common_transfer_selfService_review_errors_noDistricts2,
  "APPB2C.common.transfer.selfService.review.errors.noLrwSlots": APPB2C_common_transfer_selfService_review_errors_noLrwSlots2,
  "APPB2C.common.transfer.selfService.review.errors.transferDisabled": APPB2C_common_transfer_selfService_review_errors_transferDisabled2,
  "APPB2C.common.transfer.selfService.review.test.changeLrw": APPB2C_common_transfer_selfService_review_test_changeLrw2,
  "APPB2C.common.transfer.selfService.review.tncs.agreement": APPB2C_common_transfer_selfService_review_tncs_agreement2,
  "APPB2C.common.transfer.title": APPB2C_common_transfer_title2,
  "APPB2C.common.underConstruction.message": APPB2C_common_underConstruction_message2,
  "APPB2C.common.underConstruction.title": APPB2C_common_underConstruction_title2,
  "APPB2C.common.unknownCountry.cta": APPB2C_common_unknownCountry_cta2,
  "APPB2C.common.unknownCountry.header": APPB2C_common_unknownCountry_header2,
  "APPB2C.common.unknownCountry.msg1": APPB2C_common_unknownCountry_msg12,
  "APPB2C.common.unknownCountry.msg2": APPB2C_common_unknownCountry_msg22,
  "APPB2C.common.unknownCountry.title": APPB2C_common_unknownCountry_title2,
  "APPB2C.common.unpaidErrorBar.paymentExpired": APPB2C_common_unpaidErrorBar_paymentExpired2,
  "APPB2C.common.updateBar.cancelled": APPB2C_common_updateBar_cancelled2,
  "APPB2C.common.updateBar.cancelPending": APPB2C_common_updateBar_cancelPending2,
  "APPB2C.common.updateBar.cancelRejected": APPB2C_common_updateBar_cancelRejected2,
  "APPB2C.common.updateBar.close": APPB2C_common_updateBar_close2,
  "APPB2C.common.updateBar.details": APPB2C_common_updateBar_details2,
  "APPB2C.common.updateBar.eorCompleted": APPB2C_common_updateBar_eorCompleted2,
  "APPB2C.common.updateBar.eorCompleted.scoreDecreased": APPB2C_common_updateBar_eorCompleted_scoreDecreased2,
  "APPB2C.common.updateBar.eorCompleted.scoreIncreased": APPB2C_common_updateBar_eorCompleted_scoreIncreased2,
  "APPB2C.common.updateBar.eorCompleted.scoreUnchanged": APPB2C_common_updateBar_eorCompleted_scoreUnchanged2,
  "APPB2C.common.updateBar.eorPending": APPB2C_common_updateBar_eorPending2,
  "APPB2C.common.updateBar.eorPendingAgentPays": APPB2C_common_updateBar_eorPendingAgentPays2,
  "APPB2C.common.updateBar.eorRefunded": APPB2C_common_updateBar_eorRefunded2,
  "APPB2C.common.updateBar.eorRefundPending": APPB2C_common_updateBar_eorRefundPending2,
  "APPB2C.common.updateBar.eorRejected": APPB2C_common_updateBar_eorRejected2,
  "APPB2C.common.updateBar.eorRejected.details": APPB2C_common_updateBar_eorRejected_details,
  "APPB2C.common.updateBar.eorRejected.overallBand": APPB2C_common_updateBar_eorRejected_overallBand2,
  "APPB2C.common.updateBar.hasResults": APPB2C_common_updateBar_hasResults2,
  "APPB2C.common.updateBar.termsUpdated": APPB2C_common_updateBar_termsUpdated2,
  "APPB2C.common.updateBar.transactionRefundRequestAccepted": APPB2C_common_updateBar_transactionRefundRequestAccepted2,
  "APPB2C.common.updateBar.transactionRefundRequestPending": APPB2C_common_updateBar_transactionRefundRequestPending2,
  "APPB2C.common.updateBar.transactionRefundRequestRejected": APPB2C_common_updateBar_transactionRefundRequestRejected2,
  "APPB2C.common.updateBar.transferCancelled": APPB2C_common_updateBar_transferCancelled2,
  "APPB2C.common.updateBar.transferPending": APPB2C_common_updateBar_transferPending2,
  "APPB2C.common.updateBar.transferPendingCmds": APPB2C_common_updateBar_transferPendingCmds2,
  "APPB2C.common.updateBar.transferred": APPB2C_common_updateBar_transferred2,
  "APPB2C.common.updateBar.transferredCmds": APPB2C_common_updateBar_transferredCmds2,
  "APPB2C.registration.bookingDetails.payment": APPB2C_registration_bookingDetails_payment2,
  "APPB2C.registration.bookingDetails.results": APPB2C_registration_bookingDetails_results2,
  "APPB2C.registration.bookingDetails.seePayment": APPB2C_registration_bookingDetails_seePayment2,
  "APPB2C.registration.bookingDetails.seeTestTaker": APPB2C_registration_bookingDetails_seeTestTaker2,
  "APPB2C.registration.bookingDetails.whatNext": APPB2C_registration_bookingDetails_whatNext2,
  "APPB2C.registration.date.check.back": APPB2C_registration_date_check_back2,
  "APPB2C.registration.date.check.beforeTestDate": APPB2C_registration_date_check_beforeTestDate2,
  "APPB2C.registration.date.check.intro": APPB2C_registration_date_check_intro2,
  "APPB2C.registration.date.check.onTestDate": APPB2C_registration_date_check_onTestDate2,
  "APPB2C.registration.date.check.step1.body": APPB2C_registration_date_check_step1_body2,
  "APPB2C.registration.date.check.step1.link": APPB2C_registration_date_check_step1_link2,
  "APPB2C.registration.date.check.step1.title": APPB2C_registration_date_check_step1_title2,
  "APPB2C.registration.date.check.step2.body": APPB2C_registration_date_check_step2_body2,
  "APPB2C.registration.date.check.step2.link": APPB2C_registration_date_check_step2_link2,
  "APPB2C.registration.date.check.step2.title": APPB2C_registration_date_check_step2_title2,
  "APPB2C.registration.date.check.step3.body1": APPB2C_registration_date_check_step3_body12,
  "APPB2C.registration.date.check.step3.body2": APPB2C_registration_date_check_step3_body22,
  "APPB2C.registration.date.check.step3.noDetailsBody": APPB2C_registration_date_check_step3_noDetailsBody2,
  "APPB2C.registration.date.check.step3.noDetailsTitle": APPB2C_registration_date_check_step3_noDetailsTitle2,
  "APPB2C.registration.date.check.step3.noSpeakingPinBody": APPB2C_registration_date_check_step3_noSpeakingPinBody2,
  "APPB2C.registration.date.check.step3.noSpeakingPinTitle": APPB2C_registration_date_check_step3_noSpeakingPinTitle2,
  "APPB2C.registration.date.check.step3.password": APPB2C_registration_date_check_step3_password2,
  "APPB2C.registration.date.check.step3.speakingPin": APPB2C_registration_date_check_step3_speakingPin2,
  "APPB2C.registration.date.check.step3.step1.body": APPB2C_registration_date_check_step3_step1_body2,
  "APPB2C.registration.date.check.step3.step1.title": APPB2C_registration_date_check_step3_step1_title2,
  "APPB2C.registration.date.check.step3.step2.body": APPB2C_registration_date_check_step3_step2_body2,
  "APPB2C.registration.date.check.step3.step2.title": APPB2C_registration_date_check_step3_step2_title2,
  "APPB2C.registration.date.check.step3.step3.body": APPB2C_registration_date_check_step3_step3_body2,
  "APPB2C.registration.date.check.step3.step3.title": APPB2C_registration_date_check_step3_step3_title2,
  "APPB2C.registration.date.check.step3.username": APPB2C_registration_date_check_step3_username2,
  "APPB2C.registration.date.check.step3.writtenPin": APPB2C_registration_date_check_step3_writtenPin2,
  "APPB2C.registration.date.zone.country": APPB2C_registration_date_zone_country2,
  "APPB2C.registration.date.zone.header": APPB2C_registration_date_zone_header2,
  "APPB2C.registration.date.zone.name": APPB2C_registration_date_zone_name2,
  "APPB2C.registration.date.zone.time": APPB2C_registration_date_zone_time2,
  "APPB2C.registration.dateLocation.changeLinkIol": APPB2C_registration_dateLocation_changeLinkIol2,
  "APPB2C.registration.dateLocation.changeLrwLink": APPB2C_registration_dateLocation_changeLrwLink2,
  "APPB2C.registration.dateLocation.changeSpeakingLink": APPB2C_registration_dateLocation_changeSpeakingLink2,
  "APPB2C.registration.dateLocation.location": APPB2C_registration_dateLocation_location2,
  "APPB2C.registration.dateLocation.manage.cancel": APPB2C_registration_dateLocation_manage_cancel2,
  "APPB2C.registration.dateLocation.manage.proceed": APPB2C_registration_dateLocation_manage_proceed,
  "APPB2C.registration.dateLocation.manage.title": APPB2C_registration_dateLocation_manage_title2,
  "APPB2C.registration.dateLocation.manage.transferSpeaking": APPB2C_registration_dateLocation_manage_transferSpeaking2,
  "APPB2C.registration.dateLocation.manage.transferWhole": APPB2C_registration_dateLocation_manage_transferWhole2,
  "APPB2C.registration.dateLocation.speakingTransfer.keepSpeakingTest": APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest2,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody1": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody12,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody2": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody22,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsHeader": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader2,
  "APPB2C.registration.dateLocation.speakingTransfer.requestTransfer": APPB2C_registration_dateLocation_speakingTransfer_requestTransfer2,
  "APPB2C.registration.incorrectOrganisation.homeLink": APPB2C_registration_incorrectOrganisation_homeLink2,
  "APPB2C.registration.incorrectOrganisation.line1": APPB2C_registration_incorrectOrganisation_line12,
  "APPB2C.registration.incorrectOrganisation.line2": APPB2C_registration_incorrectOrganisation_line22,
  "APPB2C.registration.payments.actions.editProofCTA": APPB2C_registration_payments_actions_editProofCTA2,
  "APPB2C.registration.payments.actions.header": APPB2C_registration_payments_actions_header2,
  "APPB2C.registration.payments.actions.paymentInProgressInfo": APPB2C_registration_payments_actions_paymentInProgressInfo2,
  "APPB2C.registration.payments.actions.payOfflineCTA": APPB2C_registration_payments_actions_payOfflineCTA2,
  "APPB2C.registration.payments.actions.payOnlineCTA": APPB2C_registration_payments_actions_payOnlineCTA2,
  "APPB2C.registration.payments.actions.subHeader": APPB2C_registration_payments_actions_subHeader2,
  "APPB2C.registration.payments.actions.uploadProofCTA": APPB2C_registration_payments_actions_uploadProofCTA2,
  "APPB2C.registration.payments.awaitingPayment": APPB2C_registration_payments_awaitingPayment2,
  "APPB2C.registration.payments.back": APPB2C_registration_payments_back2,
  "APPB2C.registration.payments.completed": APPB2C_registration_payments_completed2,
  "APPB2C.registration.payments.expired": APPB2C_registration_payments_expired2,
  "APPB2C.registration.payments.finishPayment.error.cta": APPB2C_registration_payments_finishPayment_error_cta2,
  "APPB2C.registration.payments.finishPayment.error.messageLine1": APPB2C_registration_payments_finishPayment_error_messageLine12,
  "APPB2C.registration.payments.finishPayment.error.messageLine2": APPB2C_registration_payments_finishPayment_error_messageLine22,
  "APPB2C.registration.payments.finishPayment.error.title": APPB2C_registration_payments_finishPayment_error_title2,
  "APPB2C.registration.payments.finishPayment.failure.alert": APPB2C_registration_payments_finishPayment_failure_alert2,
  "APPB2C.registration.payments.finishPayment.failure.cta": APPB2C_registration_payments_finishPayment_failure_cta2,
  "APPB2C.registration.payments.finishPayment.failure.message": APPB2C_registration_payments_finishPayment_failure_message2,
  "APPB2C.registration.payments.finishPayment.failure.title": APPB2C_registration_payments_finishPayment_failure_title2,
  "APPB2C.registration.payments.finishPayment.pleaseWait": APPB2C_registration_payments_finishPayment_pleaseWait2,
  "APPB2C.registration.payments.finishPayment.processing": APPB2C_registration_payments_finishPayment_processing2,
  "APPB2C.registration.payments.finishPayment.success.alert": APPB2C_registration_payments_finishPayment_success_alert2,
  "APPB2C.registration.payments.finishPayment.success.cta": APPB2C_registration_payments_finishPayment_success_cta2,
  "APPB2C.registration.payments.finishPayment.success.message": APPB2C_registration_payments_finishPayment_success_message2,
  "APPB2C.registration.payments.finishPayment.success.title": APPB2C_registration_payments_finishPayment_success_title2,
  "APPB2C.registration.payments.offlineDetails.accountName": APPB2C_registration_payments_offlineDetails_accountName2,
  "APPB2C.registration.payments.offlineDetails.accountNumber": APPB2C_registration_payments_offlineDetails_accountNumber2,
  "APPB2C.registration.payments.offlineDetails.payBy": APPB2C_registration_payments_offlineDetails_payBy2,
  "APPB2C.registration.payments.offlineDetails.sortCode": APPB2C_registration_payments_offlineDetails_sortCode2,
  "APPB2C.registration.payments.paidByAgent": APPB2C_registration_payments_paidByAgent2,
  "APPB2C.registration.payments.proofPreview.fileAlt": APPB2C_registration_payments_proofPreview_fileAlt2,
  "APPB2C.registration.payments.proofPreview.proofLabel": APPB2C_registration_payments_proofPreview_proofLabel2,
  "APPB2C.registration.payments.proofUpload.cta": APPB2C_registration_payments_proofUpload_cta2,
  "APPB2C.registration.payments.proofUpload.info": APPB2C_registration_payments_proofUpload_info2,
  "APPB2C.registration.payments.proofUpload.intro": APPB2C_registration_payments_proofUpload_intro2,
  "APPB2C.registration.payments.proofUpload.rules.intro": APPB2C_registration_payments_proofUpload_rules_intro2,
  "APPB2C.registration.payments.proofUpload.rules.rule1": APPB2C_registration_payments_proofUpload_rules_rule12,
  "APPB2C.registration.payments.proofUpload.rules.rule2": APPB2C_registration_payments_proofUpload_rules_rule22,
  "APPB2C.registration.payments.proofUpload.rules.rule3": APPB2C_registration_payments_proofUpload_rules_rule32,
  "APPB2C.registration.payments.proofUpload.rules.rule4": APPB2C_registration_payments_proofUpload_rules_rule42,
  "APPB2C.registration.payments.proofUpload.rules.rule5": APPB2C_registration_payments_proofUpload_rules_rule52,
  "APPB2C.registration.payments.transaction.cancelledSuffix": APPB2C_registration_payments_transaction_cancelledSuffix2,
  "APPB2C.registration.payments.transaction.deadlineWarning.default": APPB2C_registration_payments_transaction_deadlineWarning_default2,
  "APPB2C.registration.payments.transaction.deadlineWarning.eor": APPB2C_registration_payments_transaction_deadlineWarning_eor2,
  "APPB2C.registration.payments.transaction.deadlineWarning.registration": APPB2C_registration_payments_transaction_deadlineWarning_registration2,
  "APPB2C.registration.payments.transaction.description.appeal": APPB2C_registration_payments_transaction_description_appeal2,
  "APPB2C.registration.payments.transaction.description.eor": APPB2C_registration_payments_transaction_description_eor2,
  "APPB2C.registration.payments.transaction.description.registration": APPB2C_registration_payments_transaction_description_registration2,
  "APPB2C.registration.payments.transaction.description.transfer": APPB2C_registration_payments_transaction_description_transfer2,
  "APPB2C.registration.payments.transaction.feeLabel": APPB2C_registration_payments_transaction_feeLabel2,
  "APPB2C.registration.payments.transaction.header": APPB2C_registration_payments_transaction_header2,
  "APPB2C.registration.payments.transaction.hideDetails": APPB2C_registration_payments_transaction_hideDetails2,
  "APPB2C.registration.payments.transaction.outdatedTermsLabel": APPB2C_registration_payments_transaction_outdatedTermsLabel2,
  "APPB2C.registration.payments.transaction.pastDeadline": APPB2C_registration_payments_transaction_pastDeadline2,
  "APPB2C.registration.payments.transaction.pastDeadlineContactUs": APPB2C_registration_payments_transaction_pastDeadlineContactUs2,
  "APPB2C.registration.payments.transaction.pastDeadlineEmail": APPB2C_registration_payments_transaction_pastDeadlineEmail2,
  "APPB2C.registration.payments.transaction.pastDeadlinePhone": APPB2C_registration_payments_transaction_pastDeadlinePhone2,
  "APPB2C.registration.payments.transaction.promoCode": APPB2C_registration_payments_transaction_promoCode2,
  "APPB2C.registration.payments.transaction.receipt": APPB2C_registration_payments_transaction_receipt2,
  "APPB2C.registration.payments.transaction.receiptCta": APPB2C_registration_payments_transaction_receiptCta2,
  "APPB2C.registration.payments.transaction.referenceLabel": APPB2C_registration_payments_transaction_referenceLabel2,
  "APPB2C.registration.payments.transaction.showDetails": APPB2C_registration_payments_transaction_showDetails2,
  "APPB2C.registration.payments.transaction.taxLabel": APPB2C_registration_payments_transaction_taxLabel2,
  "APPB2C.registration.payments.transaction.termsLabel": APPB2C_registration_payments_transaction_termsLabel2,
  "APPB2C.registration.payments.transaction.totalLabel": APPB2C_registration_payments_transaction_totalLabel2,
  "APPB2C.registration.payments.transaction.underReviewSuffix": APPB2C_registration_payments_transaction_underReviewSuffix2,
  "APPB2C.registration.payments.transaction.unpaidSuffix": APPB2C_registration_payments_transaction_unpaidSuffix2,
  "APPB2C.registration.payments.unpaid": APPB2C_registration_payments_unpaid2,
  "APPB2C.registration.prepare.a1.cta": APPB2C_registration_prepare_a1_cta2,
  "APPB2C.registration.prepare.a1.header": APPB2C_registration_prepare_a1_header2,
  "APPB2C.registration.prepare.a2.cta": APPB2C_registration_prepare_a2_cta2,
  "APPB2C.registration.prepare.a2.header": APPB2C_registration_prepare_a2_header2,
  "APPB2C.registration.prepare.b1.cta": APPB2C_registration_prepare_b1_cta2,
  "APPB2C.registration.prepare.b1.header": APPB2C_registration_prepare_b1_header2,
  "APPB2C.registration.prepare.blog.body.cta": APPB2C_registration_prepare_blog_body_cta2,
  "APPB2C.registration.prepare.blog.body.line1": APPB2C_registration_prepare_blog_body_line12,
  "APPB2C.registration.prepare.blog.header": APPB2C_registration_prepare_blog_header2,
  "APPB2C.registration.prepare.cdFamiliarTest.body.cta": APPB2C_registration_prepare_cdFamiliarTest_body_cta2,
  "APPB2C.registration.prepare.cdFamiliarTest.body.line1": APPB2C_registration_prepare_cdFamiliarTest_body_line12,
  "APPB2C.registration.prepare.cdFamiliarTest.header": APPB2C_registration_prepare_cdFamiliarTest_header2,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.cta": APPB2C_registration_prepare_cdFamiliarVideos_body_cta2,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.line1": APPB2C_registration_prepare_cdFamiliarVideos_body_line12,
  "APPB2C.registration.prepare.cdFamiliarVideos.header": APPB2C_registration_prepare_cdFamiliarVideos_header2,
  "APPB2C.registration.prepare.cdTests.body.cta": APPB2C_registration_prepare_cdTests_body_cta2,
  "APPB2C.registration.prepare.cdTests.body.line1": APPB2C_registration_prepare_cdTests_body_line12,
  "APPB2C.registration.prepare.cdTests.header": APPB2C_registration_prepare_cdTests_header2,
  "APPB2C.registration.prepare.cdTipsVideos.body.cta": APPB2C_registration_prepare_cdTipsVideos_body_cta2,
  "APPB2C.registration.prepare.cdTipsVideos.body.line1": APPB2C_registration_prepare_cdTipsVideos_body_line12,
  "APPB2C.registration.prepare.cdTipsVideos.header": APPB2C_registration_prepare_cdTipsVideos_header2,
  "APPB2C.registration.prepare.facebook.body.cta": APPB2C_registration_prepare_facebook_body_cta2,
  "APPB2C.registration.prepare.facebook.body.line1": APPB2C_registration_prepare_facebook_body_line12,
  "APPB2C.registration.prepare.facebook.header": APPB2C_registration_prepare_facebook_header2,
  "APPB2C.registration.prepare.gel.disabledMsg": APPB2C_registration_prepare_gel_disabledMsg2,
  "APPB2C.registration.prepare.gel.ielts.body.cta": APPB2C_registration_prepare_gel_ielts_body_cta2,
  "APPB2C.registration.prepare.gel.ielts.body.line1": APPB2C_registration_prepare_gel_ielts_body_line12,
  "APPB2C.registration.prepare.gel.ielts.header": APPB2C_registration_prepare_gel_ielts_header2,
  "APPB2C.registration.prepare.gel.ukvi.body.cta": APPB2C_registration_prepare_gel_ukvi_body_cta,
  "APPB2C.registration.prepare.gel.ukvi.body.line1": APPB2C_registration_prepare_gel_ukvi_body_line12,
  "APPB2C.registration.prepare.gel.ukvi.header": APPB2C_registration_prepare_gel_ukvi_header2,
  "APPB2C.registration.prepare.header": APPB2C_registration_prepare_header2,
  "APPB2C.registration.prepare.ieltsReady.subTitle": APPB2C_registration_prepare_ieltsReady_subTitle2,
  "APPB2C.registration.prepare.ieltsReady.title": APPB2C_registration_prepare_ieltsReady_title2,
  "APPB2C.registration.prepare.ieltsReadyMember.body": APPB2C_registration_prepare_ieltsReadyMember_body2,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestBtn": APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn2,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestInfo": APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo2,
  "APPB2C.registration.prepare.ieltsReadyMember.freePrepLink": APPB2C_registration_prepare_ieltsReadyMember_freePrepLink2,
  "APPB2C.registration.prepare.ieltsReadyMember.header": APPB2C_registration_prepare_ieltsReadyMember_header2,
  "APPB2C.registration.prepare.ieltsReadyMember.label": APPB2C_registration_prepare_ieltsReadyMember_label2,
  "APPB2C.registration.prepare.ieltsReadyMember.link": APPB2C_registration_prepare_ieltsReadyMember_link2,
  "APPB2C.registration.prepare.ieltsReadyMember.payBtn": APPB2C_registration_prepare_ieltsReadyMember_payBtn2,
  "APPB2C.registration.prepare.ieltsReadyMember.unpaidTestInfo": APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo2,
  "APPB2C.registration.prepare.intro": APPB2C_registration_prepare_intro2,
  "APPB2C.registration.prepare.introLifeSkills": APPB2C_registration_prepare_introLifeSkills2,
  "APPB2C.registration.prepare.onlineCourse.body.cta": APPB2C_registration_prepare_onlineCourse_body_cta2,
  "APPB2C.registration.prepare.onlineCourse.body.line1": APPB2C_registration_prepare_onlineCourse_body_line12,
  "APPB2C.registration.prepare.onlineCourse.header": APPB2C_registration_prepare_onlineCourse_header2,
  "APPB2C.registration.prepare.pbPractice.body.cta": APPB2C_registration_prepare_pbPractice_body_cta2,
  "APPB2C.registration.prepare.pbPractice.body.line1": APPB2C_registration_prepare_pbPractice_body_line12,
  "APPB2C.registration.prepare.pbPractice.header": APPB2C_registration_prepare_pbPractice_header2,
  "APPB2C.registration.prepare.practice.body.cta": APPB2C_registration_prepare_practice_body_cta2,
  "APPB2C.registration.prepare.practice.body.line1": APPB2C_registration_prepare_practice_body_line12,
  "APPB2C.registration.prepare.practice.header": APPB2C_registration_prepare_practice_header2,
  "APPB2C.registration.prepare.prepApp.body.cta": APPB2C_registration_prepare_prepApp_body_cta2,
  "APPB2C.registration.prepare.prepApp.body.line1": APPB2C_registration_prepare_prepApp_body_line12,
  "APPB2C.registration.prepare.prepApp.header": APPB2C_registration_prepare_prepApp_header2,
  "APPB2C.registration.prepare.prepApp.ukvi.cta1": APPB2C_registration_prepare_prepApp_ukvi_cta12,
  "APPB2C.registration.prepare.prepApp.ukvi.cta2": APPB2C_registration_prepare_prepApp_ukvi_cta22,
  "APPB2C.registration.prepare.prepApp.ukvi.line1": APPB2C_registration_prepare_prepApp_ukvi_line12,
  "APPB2C.registration.prepare.prepApp.ukvi.line2": APPB2C_registration_prepare_prepApp_ukvi_line22,
  "APPB2C.registration.prepare.prepVideos.body.cta": APPB2C_registration_prepare_prepVideos_body_cta2,
  "APPB2C.registration.prepare.prepVideos.body.line1": APPB2C_registration_prepare_prepVideos_body_line12,
  "APPB2C.registration.prepare.prepVideos.header": APPB2C_registration_prepare_prepVideos_header2,
  "APPB2C.registration.prepare.reading.body.cta": APPB2C_registration_prepare_reading_body_cta2,
  "APPB2C.registration.prepare.reading.body.line1": APPB2C_registration_prepare_reading_body_line12,
  "APPB2C.registration.prepare.reading.header": APPB2C_registration_prepare_reading_header2,
  "APPB2C.registration.prepare.rteLastMinute.body.cta": APPB2C_registration_prepare_rteLastMinute_body_cta2,
  "APPB2C.registration.prepare.rteLastMinute.body.item1": APPB2C_registration_prepare_rteLastMinute_body_item12,
  "APPB2C.registration.prepare.rteLastMinute.body.item2": APPB2C_registration_prepare_rteLastMinute_body_item22,
  "APPB2C.registration.prepare.rteLastMinute.body.item3": APPB2C_registration_prepare_rteLastMinute_body_item32,
  "APPB2C.registration.prepare.rteLastMinute.body.line1": APPB2C_registration_prepare_rteLastMinute_body_line12,
  "APPB2C.registration.prepare.rteLastMinute.header": APPB2C_registration_prepare_rteLastMinute_header2,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.cta": APPB2C_registration_prepare_rteLastMinute_ukvi_cta2,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.line1": APPB2C_registration_prepare_rteLastMinute_ukvi_line12,
  "APPB2C.registration.prepare.rteTestDrive.body.cta": APPB2C_registration_prepare_rteTestDrive_body_cta2,
  "APPB2C.registration.prepare.rteTestDrive.body.item1": APPB2C_registration_prepare_rteTestDrive_body_item12,
  "APPB2C.registration.prepare.rteTestDrive.body.item2": APPB2C_registration_prepare_rteTestDrive_body_item22,
  "APPB2C.registration.prepare.rteTestDrive.body.item3": APPB2C_registration_prepare_rteTestDrive_body_item32,
  "APPB2C.registration.prepare.rteTestDrive.body.line1": APPB2C_registration_prepare_rteTestDrive_body_line12,
  "APPB2C.registration.prepare.rteTestDrive.body.line2": APPB2C_registration_prepare_rteTestDrive_body_line22,
  "APPB2C.registration.prepare.rteTestDrive.header": APPB2C_registration_prepare_rteTestDrive_header2,
  "APPB2C.registration.prepare.speaking.body.cta": APPB2C_registration_prepare_speaking_body_cta2,
  "APPB2C.registration.prepare.speaking.body.line1": APPB2C_registration_prepare_speaking_body_line12,
  "APPB2C.registration.prepare.speaking.header": APPB2C_registration_prepare_speaking_header2,
  "APPB2C.registration.prepare.tutor.body.cta": APPB2C_registration_prepare_tutor_body_cta2,
  "APPB2C.registration.prepare.tutor.body.line1": APPB2C_registration_prepare_tutor_body_line12,
  "APPB2C.registration.prepare.tutor.body.line2": APPB2C_registration_prepare_tutor_body_line22,
  "APPB2C.registration.prepare.tutor.header": APPB2C_registration_prepare_tutor_header2,
  "APPB2C.registration.prepare.webinars.body.cta": APPB2C_registration_prepare_webinars_body_cta2,
  "APPB2C.registration.prepare.webinars.body.item1": APPB2C_registration_prepare_webinars_body_item12,
  "APPB2C.registration.prepare.webinars.body.item2": APPB2C_registration_prepare_webinars_body_item22,
  "APPB2C.registration.prepare.webinars.body.item3": APPB2C_registration_prepare_webinars_body_item32,
  "APPB2C.registration.prepare.webinars.body.line1": APPB2C_registration_prepare_webinars_body_line12,
  "APPB2C.registration.prepare.webinars.header": APPB2C_registration_prepare_webinars_header2,
  "APPB2C.registration.results.bandScore.osrBooked": APPB2C_registration_results_bandScore_osrBooked2,
  "APPB2C.registration.results.bandScore.osrMarked": APPB2C_registration_results_bandScore_osrMarked2,
  "APPB2C.registration.results.eor.acknowledgement.backButton": APPB2C_registration_results_eor_acknowledgement_backButton2,
  "APPB2C.registration.results.eor.acknowledgement.components": APPB2C_registration_results_eor_acknowledgement_components2,
  "APPB2C.registration.results.eor.acknowledgement.confirmation": APPB2C_registration_results_eor_acknowledgement_confirmation2,
  "APPB2C.registration.results.eor.acknowledgement.fee": APPB2C_registration_results_eor_acknowledgement_fee2,
  "APPB2C.registration.results.eor.acknowledgement.info": APPB2C_registration_results_eor_acknowledgement_info2,
  "APPB2C.registration.results.eor.acknowledgement.noteTitle": APPB2C_registration_results_eor_acknowledgement_noteTitle2,
  "APPB2C.registration.results.eor.acknowledgement.payment": APPB2C_registration_results_eor_acknowledgement_payment2,
  "APPB2C.registration.results.eor.acknowledgement.paymentButton": APPB2C_registration_results_eor_acknowledgement_paymentButton2,
  "APPB2C.registration.results.eor.acknowledgement.paymentNote": APPB2C_registration_results_eor_acknowledgement_paymentNote2,
  "APPB2C.registration.results.eor.acknowledgement.reference": APPB2C_registration_results_eor_acknowledgement_reference2,
  "APPB2C.registration.results.eor.acknowledgement.success": APPB2C_registration_results_eor_acknowledgement_success2,
  "APPB2C.registration.results.eor.acknowledgement.tax": APPB2C_registration_results_eor_acknowledgement_tax2,
  "APPB2C.registration.results.eor.acknowledgement.terms": APPB2C_registration_results_eor_acknowledgement_terms2,
  "APPB2C.registration.results.eor.acknowledgement.title": APPB2C_registration_results_eor_acknowledgement_title2,
  "APPB2C.registration.results.eor.acknowledgement.total": APPB2C_registration_results_eor_acknowledgement_total2,
  "APPB2C.registration.results.eor.postDeadline.back": APPB2C_registration_results_eor_postDeadline_back2,
  "APPB2C.registration.results.eor.postDeadline.email": APPB2C_registration_results_eor_postDeadline_email2,
  "APPB2C.registration.results.eor.postDeadline.help": APPB2C_registration_results_eor_postDeadline_help2,
  "APPB2C.registration.results.eor.postDeadline.iol.launchChatLine": APPB2C_registration_results_eor_postDeadline_iol_launchChatLine2,
  "APPB2C.registration.results.eor.postDeadline.iol.line3": APPB2C_registration_results_eor_postDeadline_iol_line32,
  "APPB2C.registration.results.eor.postDeadline.line1": APPB2C_registration_results_eor_postDeadline_line12,
  "APPB2C.registration.results.eor.postDeadline.line2": APPB2C_registration_results_eor_postDeadline_line22,
  "APPB2C.registration.results.eor.postDeadline.line3": APPB2C_registration_results_eor_postDeadline_line32,
  "APPB2C.registration.results.eor.postDeadline.phone": APPB2C_registration_results_eor_postDeadline_phone2,
  "APPB2C.registration.results.eor.preDeadline.back": APPB2C_registration_results_eor_preDeadline_back2,
  "APPB2C.registration.results.eor.preDeadline.error.explanationMaxLength": APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength2,
  "APPB2C.registration.results.eor.preDeadline.error.missingTrfNumber": APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber2,
  "APPB2C.registration.results.eor.preDeadline.error.wrongTrfNumber": APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber2,
  "APPB2C.registration.results.eor.preDeadline.evidence": APPB2C_registration_results_eor_preDeadline_evidence2,
  "APPB2C.registration.results.eor.preDeadline.evidenceRequired": APPB2C_registration_results_eor_preDeadline_evidenceRequired2,
  "APPB2C.registration.results.eor.preDeadline.explanation": APPB2C_registration_results_eor_preDeadline_explanation2,
  "APPB2C.registration.results.eor.preDeadline.explanationRequired": APPB2C_registration_results_eor_preDeadline_explanationRequired2,
  "APPB2C.registration.results.eor.preDeadline.fee": APPB2C_registration_results_eor_preDeadline_fee2,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line1": APPB2C_registration_results_eor_preDeadline_iolNote_line1,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line2": APPB2C_registration_results_eor_preDeadline_iolNote_line22,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line3": APPB2C_registration_results_eor_preDeadline_iolNote_line32,
  "APPB2C.registration.results.eor.preDeadline.legal": APPB2C_registration_results_eor_preDeadline_legal2,
  "APPB2C.registration.results.eor.preDeadline.legalNote": APPB2C_registration_results_eor_preDeadline_legalNote2,
  "APPB2C.registration.results.eor.preDeadline.line1": APPB2C_registration_results_eor_preDeadline_line12,
  "APPB2C.registration.results.eor.preDeadline.line2": APPB2C_registration_results_eor_preDeadline_line22,
  "APPB2C.registration.results.eor.preDeadline.maxScore": APPB2C_registration_results_eor_preDeadline_maxScore2,
  "APPB2C.registration.results.eor.preDeadline.note": APPB2C_registration_results_eor_preDeadline_note2,
  "APPB2C.registration.results.eor.preDeadline.noteTitle": APPB2C_registration_results_eor_preDeadline_noteTitle2,
  "APPB2C.registration.results.eor.preDeadline.orderAcknowledgementLabel": APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel2,
  "APPB2C.registration.results.eor.preDeadline.paymentCta": APPB2C_registration_results_eor_preDeadline_paymentCta2,
  "APPB2C.registration.results.eor.preDeadline.paymentTitle": APPB2C_registration_results_eor_preDeadline_paymentTitle2,
  "APPB2C.registration.results.eor.preDeadline.payOfflineCTA": APPB2C_registration_results_eor_preDeadline_payOfflineCTA2,
  "APPB2C.registration.results.eor.preDeadline.payOnlineCTA": APPB2C_registration_results_eor_preDeadline_payOnlineCTA2,
  "APPB2C.registration.results.eor.preDeadline.scoresRequired": APPB2C_registration_results_eor_preDeadline_scoresRequired2,
  "APPB2C.registration.results.eor.preDeadline.scoresTitle": APPB2C_registration_results_eor_preDeadline_scoresTitle2,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr2,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Listening": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening2,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Reading": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading2,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Speaking": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking2,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Writing": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing2,
  "APPB2C.registration.results.eor.preDeadline.tax": APPB2C_registration_results_eor_preDeadline_tax2,
  "APPB2C.registration.results.eor.preDeadline.trfNumberLabel": APPB2C_registration_results_eor_preDeadline_trfNumberLabel2,
  "APPB2C.registration.results.eor.preDeadline.trfNumberSubnote": APPB2C_registration_results_eor_preDeadline_trfNumberSubnote2,
  "APPB2C.registration.results.eor.preDeadline.trfNumberValidation": APPB2C_registration_results_eor_preDeadline_trfNumberValidation2,
  "APPB2C.registration.results.eor.title": APPB2C_registration_results_eor_title2,
  "APPB2C.registration.results.eor.titlePayment": APPB2C_registration_results_eor_titlePayment2,
  "APPB2C.registration.results.hasResults.bandScore.decrease": APPB2C_registration_results_hasResults_bandScore_decrease2,
  "APPB2C.registration.results.hasResults.bandScore.increase": APPB2C_registration_results_hasResults_bandScore_increase2,
  "APPB2C.registration.results.hasResults.bandScore.unchanged": APPB2C_registration_results_hasResults_bandScore_unchanged2,
  "APPB2C.registration.results.hasResults.eorAgentInfo": APPB2C_registration_results_hasResults_eorAgentInfo,
  "APPB2C.registration.results.hasResults.eorLetter": APPB2C_registration_results_hasResults_eorLetter2,
  "APPB2C.registration.results.hasResults.eorLine1": APPB2C_registration_results_hasResults_eorLine12,
  "APPB2C.registration.results.hasResults.eorLine2": APPB2C_registration_results_hasResults_eorLine22,
  "APPB2C.registration.results.hasResults.eorLink": APPB2C_registration_results_hasResults_eorLink2,
  "APPB2C.registration.results.hasResults.eTrfCta": APPB2C_registration_results_hasResults_eTrfCta,
  "APPB2C.registration.results.hasResults.explanations.heading": APPB2C_registration_results_hasResults_explanations_heading2,
  "APPB2C.registration.results.hasResults.explanations.hideButton": APPB2C_registration_results_hasResults_explanations_hideButton,
  "APPB2C.registration.results.hasResults.explanations.improve": APPB2C_registration_results_hasResults_explanations_improve2,
  "APPB2C.registration.results.hasResults.explanations.showButton": APPB2C_registration_results_hasResults_explanations_showButton,
  "APPB2C.registration.results.hasResults.lifeSkills.failed": APPB2C_registration_results_hasResults_lifeSkills_failed2,
  "APPB2C.registration.results.hasResults.lifeSkills.level": APPB2C_registration_results_hasResults_lifeSkills_level2,
  "APPB2C.registration.results.hasResults.lifeSkills.note": APPB2C_registration_results_hasResults_lifeSkills_note2,
  "APPB2C.registration.results.hasResults.lifeSkills.passed": APPB2C_registration_results_hasResults_lifeSkills_passed2,
  "APPB2C.registration.results.hasResults.lifeSkills.result": APPB2C_registration_results_hasResults_lifeSkills_result2,
  "APPB2C.registration.results.hasResults.osr.bandScore.decrease": APPB2C_registration_results_hasResults_osr_bandScore_decrease,
  "APPB2C.registration.results.hasResults.osr.bandScore.increase": APPB2C_registration_results_hasResults_osr_bandScore_increase,
  "APPB2C.registration.results.hasResults.osr.bandScore.unchanged": APPB2C_registration_results_hasResults_osr_bandScore_unchanged,
  "APPB2C.registration.results.hasResults.osr.previousResults": APPB2C_registration_results_hasResults_osr_previousResults2,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreDecreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased2,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreIncreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased2,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreUnchanged": APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged2,
  "APPB2C.registration.results.hasResults.osr.updateBar.title": APPB2C_registration_results_hasResults_osr_updateBar_title2,
  "APPB2C.registration.results.hasResults.osrLink": APPB2C_registration_results_hasResults_osrLink,
  "APPB2C.registration.results.hasResults.posting.oneCopyWarning": APPB2C_registration_results_hasResults_posting_oneCopyWarning2,
  "APPB2C.registration.results.hasResults.posting.postedOn": APPB2C_registration_results_hasResults_posting_postedOn2,
  "APPB2C.registration.results.hasResults.posting.postedTo": APPB2C_registration_results_hasResults_posting_postedTo2,
  "APPB2C.registration.results.hasResults.posting.willPostOn": APPB2C_registration_results_hasResults_posting_willPostOn2,
  "APPB2C.registration.results.hasResults.posting.willPostTo": APPB2C_registration_results_hasResults_posting_willPostTo2,
  "APPB2C.registration.results.hasResults.rankNameConsonant": APPB2C_registration_results_hasResults_rankNameConsonant2,
  "APPB2C.registration.results.hasResults.rankNameVowel": APPB2C_registration_results_hasResults_rankNameVowel2,
  "APPB2C.registration.results.hasResults.scoreImprove.btn": APPB2C_registration_results_hasResults_scoreImprove_btn2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.backBtn": APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.description": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.title": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.description": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.title": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.description": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.title": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title2,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.subtitle": APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle2,
  "APPB2C.registration.results.hasResults.scoreImprove.subtitle": APPB2C_registration_results_hasResults_scoreImprove_subtitle2,
  "APPB2C.registration.results.hasResults.scoreImprove.title": APPB2C_registration_results_hasResults_scoreImprove_title2,
  "APPB2C.registration.results.hasResults.scores.label": APPB2C_registration_results_hasResults_scores_label2,
  "APPB2C.registration.results.hasResults.scores.Listening": APPB2C_registration_results_hasResults_scores_Listening2,
  "APPB2C.registration.results.hasResults.scores.Overall": APPB2C_registration_results_hasResults_scores_Overall2,
  "APPB2C.registration.results.hasResults.scores.Reading": APPB2C_registration_results_hasResults_scores_Reading2,
  "APPB2C.registration.results.hasResults.scores.Speaking": APPB2C_registration_results_hasResults_scores_Speaking2,
  "APPB2C.registration.results.hasResults.scores.Writing": APPB2C_registration_results_hasResults_scores_Writing2,
  "APPB2C.registration.results.hasResults.share.applyBoard": APPB2C_registration_results_hasResults_share_applyBoard2,
  "APPB2C.registration.results.hasResults.share.RecognisingOrgsLink": APPB2C_registration_results_hasResults_share_RecognisingOrgsLink2,
  "APPB2C.registration.results.hasResults.share.subtitle": APPB2C_registration_results_hasResults_share_subtitle2,
  "APPB2C.registration.results.hasResults.share.title": APPB2C_registration_results_hasResults_share_title2,
  "APPB2C.registration.results.hasResults.share.trfCta": APPB2C_registration_results_hasResults_share_trfCta2,
  "APPB2C.registration.results.hasResults.viewExplanation": APPB2C_registration_results_hasResults_viewExplanation2,
  "APPB2C.registration.results.noResults.cmds.title": APPB2C_registration_results_noResults_cmds_title2,
  "APPB2C.registration.results.noResults.note.title": APPB2C_registration_results_noResults_note_title2,
  "APPB2C.registration.results.noResults.osr.booked": APPB2C_registration_results_noResults_osr_booked2,
  "APPB2C.registration.results.noResults.osr.previousScores": APPB2C_registration_results_noResults_osr_previousScores2,
  "APPB2C.registration.results.noResults.title": APPB2C_registration_results_noResults_title2,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote1": APPB2C_registration_results_organisations_availableReceivingOrgsNote12,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote2": APPB2C_registration_results_organisations_availableReceivingOrgsNote22,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote3": APPB2C_registration_results_organisations_availableReceivingOrgsNote3,
  "APPB2C.registration.results.organisations.chosenOrganisationsHeader": APPB2C_registration_results_organisations_chosenOrganisationsHeader2,
  "APPB2C.registration.results.organisations.editRo.addAnother": APPB2C_registration_results_organisations_editRo_addAnother2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.addNew": APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.address": APPB2C_registration_results_organisations_editRo_chooseOrganisations_address2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.country": APPB2C_registration_results_organisations_editRo_chooseOrganisations_country2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryAll": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryOptional": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.file": APPB2C_registration_results_organisations_editRo_chooseOrganisations_file2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.org": APPB2C_registration_results_organisations_editRo_chooseOrganisations_org2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.organisation": APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.person": APPB2C_registration_results_organisations_editRo_chooseOrganisations_person2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.postCode": APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByCountry": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry2,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByOrg": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg2,
  "APPB2C.registration.results.organisations.editRo.error.caseNumberMaxLength": APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength2,
  "APPB2C.registration.results.organisations.editRo.error.contactPersonMaxLength": APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength2,
  "APPB2C.registration.results.organisations.editRo.error.minScoreMissing": APPB2C_registration_results_organisations_editRo_error_minScoreMissing2,
  "APPB2C.registration.results.organisations.editRo.error.missingAddressLine1": APPB2C_registration_results_organisations_editRo_error_missingAddressLine12,
  "APPB2C.registration.results.organisations.editRo.error.missingOrgName": APPB2C_registration_results_organisations_editRo_error_missingOrgName2,
  "APPB2C.registration.results.organisations.editRo.error.orgNameMaxLength": APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength2,
  "APPB2C.registration.results.organisations.editRo.iol.addCta": APPB2C_registration_results_organisations_editRo_iol_addCta2,
  "APPB2C.registration.results.organisations.editRo.iol.address": APPB2C_registration_results_organisations_editRo_iol_address2,
  "APPB2C.registration.results.organisations.editRo.iol.cancelAdd": APPB2C_registration_results_organisations_editRo_iol_cancelAdd,
  "APPB2C.registration.results.organisations.editRo.iol.cancelEdit": APPB2C_registration_results_organisations_editRo_iol_cancelEdit2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.clear": APPB2C_registration_results_organisations_editRo_iol_conditions_clear2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.header": APPB2C_registration_results_organisations_editRo_iol_conditions_header2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.mandatoryPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.no": APPB2C_registration_results_organisations_editRo_iol_conditions_no2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note1": APPB2C_registration_results_organisations_editRo_iol_conditions_note12,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note2": APPB2C_registration_results_organisations_editRo_iol_conditions_note22,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note3": APPB2C_registration_results_organisations_editRo_iol_conditions_note32,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.optionalPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.subHeader": APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader2,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.yes": APPB2C_registration_results_organisations_editRo_iol_conditions_yes2,
  "APPB2C.registration.results.organisations.editRo.iol.copied": APPB2C_registration_results_organisations_editRo_iol_copied2,
  "APPB2C.registration.results.organisations.editRo.iol.copy": APPB2C_registration_results_organisations_editRo_iol_copy2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.edelivery": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.eResults": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.note": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.postal": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.trf": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf2,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note1": APPB2C_registration_results_organisations_editRo_iol_delivery_note12,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2": APPB2C_registration_results_organisations_editRo_iol_delivery_note22,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2.results": APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results2,
  "APPB2C.registration.results.organisations.editRo.iol.newOrgTitle": APPB2C_registration_results_organisations_editRo_iol_newOrgTitle2,
  "APPB2C.registration.results.organisations.editRo.iol.noResults": APPB2C_registration_results_organisations_editRo_iol_noResults2,
  "APPB2C.registration.results.organisations.editRo.iol.removeLink": APPB2C_registration_results_organisations_editRo_iol_removeLink2,
  "APPB2C.registration.results.organisations.editRo.iol.saveCta": APPB2C_registration_results_organisations_editRo_iol_saveCta2,
  "APPB2C.registration.results.organisations.editRo.iol.validation.overallScore": APPB2C_registration_results_organisations_editRo_iol_validation_overallScore2,
  "APPB2C.registration.results.organisations.editRo.iol.validation.selectedOrganisation": APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation2,
  "APPB2C.registration.results.organisations.iol.chooseCta": APPB2C_registration_results_organisations_iol_chooseCta2,
  "APPB2C.registration.results.organisations.iol.delivery.auto.header": APPB2C_registration_results_organisations_iol_delivery_auto_header2,
  "APPB2C.registration.results.organisations.iol.delivery.auto.note": APPB2C_registration_results_organisations_iol_delivery_auto_note2,
  "APPB2C.registration.results.organisations.iol.delivery.auto.notSentNote": APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote2,
  "APPB2C.registration.results.organisations.iol.delivery.auto.sentNote": APPB2C_registration_results_organisations_iol_delivery_auto_sentNote2,
  "APPB2C.registration.results.organisations.iol.delivery.postal.header": APPB2C_registration_results_organisations_iol_delivery_postal_header2,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note1": APPB2C_registration_results_organisations_iol_delivery_postal_note12,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2": APPB2C_registration_results_organisations_iol_delivery_postal_note22,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2.results": APPB2C_registration_results_organisations_iol_delivery_postal_note2_results2,
  "APPB2C.registration.results.organisations.iol.edit": APPB2C_registration_results_organisations_iol_edit2,
  "APPB2C.registration.results.organisations.iol.error": APPB2C_registration_results_organisations_iol_error2,
  "APPB2C.registration.results.organisations.iol.generalCondition": APPB2C_registration_results_organisations_iol_generalCondition2,
  "APPB2C.registration.results.organisations.iol.noConditions": APPB2C_registration_results_organisations_iol_noConditions2,
  "APPB2C.registration.results.organisations.iol.notChosen": APPB2C_registration_results_organisations_iol_notChosen2,
  "APPB2C.registration.results.organisations.iol.preciseCondition": APPB2C_registration_results_organisations_iol_preciseCondition2,
  "APPB2C.registration.results.organisations.iol.roAccept.link": APPB2C_registration_results_organisations_iol_roAccept_link2,
  "APPB2C.registration.results.organisations.iol.roAccept.message": APPB2C_registration_results_organisations_iol_roAccept_message2,
  "APPB2C.registration.results.organisations.iol.roAccept.title": APPB2C_registration_results_organisations_iol_roAccept_title2,
  "APPB2C.registration.results.organisations.iol.sendAnyway": APPB2C_registration_results_organisations_iol_sendAnyway2,
  "APPB2C.registration.results.organisations.list.item1": APPB2C_registration_results_organisations_list_item12,
  "APPB2C.registration.results.organisations.list.item2": APPB2C_registration_results_organisations_list_item22,
  "APPB2C.registration.results.organisations.list.item3": APPB2C_registration_results_organisations_list_item32,
  "APPB2C.registration.results.organisations.list.item4": APPB2C_registration_results_organisations_list_item42,
  "APPB2C.registration.results.organisations.mod.delivery.auto.header": APPB2C_registration_results_organisations_mod_delivery_auto_header2,
  "APPB2C.registration.results.organisations.mod.delivery.auto.note": APPB2C_registration_results_organisations_mod_delivery_auto_note2,
  "APPB2C.registration.results.organisations.mod.delivery.auto.notSentNote": APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote2,
  "APPB2C.registration.results.organisations.mod.delivery.auto.sentNote": APPB2C_registration_results_organisations_mod_delivery_auto_sentNote2,
  "APPB2C.registration.results.organisations.mod.delivery.postal.header": APPB2C_registration_results_organisations_mod_delivery_postal_header2,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note1": APPB2C_registration_results_organisations_mod_delivery_postal_note12,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note2": APPB2C_registration_results_organisations_mod_delivery_postal_note22,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note3": APPB2C_registration_results_organisations_mod_delivery_postal_note32,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note4": APPB2C_registration_results_organisations_mod_delivery_postal_note42,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note5": APPB2C_registration_results_organisations_mod_delivery_postal_note52,
  "APPB2C.registration.results.organisations.mod.delivery.postal.notSentNote": APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote2,
  "APPB2C.registration.results.organisations.mod.delivery.postal.sentNote": APPB2C_registration_results_organisations_mod_delivery_postal_sentNote2,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount2,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount_other": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other2,
  "APPB2C.registration.results.organisations.mod.howTo.item1": APPB2C_registration_results_organisations_mod_howTo_item12,
  "APPB2C.registration.results.organisations.mod.howTo.item2": APPB2C_registration_results_organisations_mod_howTo_item22,
  "APPB2C.registration.results.organisations.mod.howTo.line1": APPB2C_registration_results_organisations_mod_howTo_line12,
  "APPB2C.registration.results.organisations.mod.howTo.title": APPB2C_registration_results_organisations_mod_howTo_title2,
  "APPB2C.registration.results.organisations.noReceivingOrgsSelectedNote": APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote2,
  "APPB2C.registration.results.organisations.note0": APPB2C_registration_results_organisations_note02,
  "APPB2C.registration.results.organisations.note1": APPB2C_registration_results_organisations_note12,
  "APPB2C.registration.results.organisations.note2": APPB2C_registration_results_organisations_note22,
  "APPB2C.registration.results.organisations.organisationsCount": APPB2C_registration_results_organisations_organisationsCount2,
  "APPB2C.registration.results.organisations.organisationsCount_other": APPB2C_registration_results_organisations_organisationsCount_other2,
  "APPB2C.registration.results.organisations.receivingOrgsLimitReachedNote": APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote2,
  "APPB2C.registration.results.organisations.title": APPB2C_registration_results_organisations_title2,
  "APPB2C.registration.results.osr.chooseDate.accessibility": APPB2C_registration_results_osr_chooseDate_accessibility2,
  "APPB2C.registration.results.osr.chooseDate.accessibilityLink": APPB2C_registration_results_osr_chooseDate_accessibilityLink2,
  "APPB2C.registration.results.osr.chooseDate.chooseDateTitle": APPB2C_registration_results_osr_chooseDate_chooseDateTitle2,
  "APPB2C.registration.results.osr.chooseDate.note.content": APPB2C_registration_results_osr_chooseDate_note_content2,
  "APPB2C.registration.results.osr.chooseDate.note.title": APPB2C_registration_results_osr_chooseDate_note_title2,
  "APPB2C.registration.results.osr.chooseDate.retakeListening": APPB2C_registration_results_osr_chooseDate_retakeListening2,
  "APPB2C.registration.results.osr.chooseDate.retakeReading": APPB2C_registration_results_osr_chooseDate_retakeReading2,
  "APPB2C.registration.results.osr.chooseDate.retakeSpeaking": APPB2C_registration_results_osr_chooseDate_retakeSpeaking2,
  "APPB2C.registration.results.osr.chooseDate.retakeWriting": APPB2C_registration_results_osr_chooseDate_retakeWriting2,
  "APPB2C.registration.results.osr.chooseSkill.backBtn": APPB2C_registration_results_osr_chooseSkill_backBtn2,
  "APPB2C.registration.results.osr.chooseSkill.chooseDateBtn": APPB2C_registration_results_osr_chooseSkill_chooseDateBtn2,
  "APPB2C.registration.results.osr.chooseSkill.confirmationRequired": APPB2C_registration_results_osr_chooseSkill_confirmationRequired2,
  "APPB2C.registration.results.osr.chooseSkill.formTitle": APPB2C_registration_results_osr_chooseSkill_formTitle2,
  "APPB2C.registration.results.osr.chooseSkill.modal.contact": APPB2C_registration_results_osr_chooseSkill_modal_contact2,
  "APPB2C.registration.results.osr.chooseSkill.modal.description": APPB2C_registration_results_osr_chooseSkill_modal_description2,
  "APPB2C.registration.results.osr.chooseSkill.modal.email": APPB2C_registration_results_osr_chooseSkill_modal_email2,
  "APPB2C.registration.results.osr.chooseSkill.modal.phone": APPB2C_registration_results_osr_chooseSkill_modal_phone2,
  "APPB2C.registration.results.osr.chooseSkill.modal.title": APPB2C_registration_results_osr_chooseSkill_modal_title2,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.confirmation": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation2,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.description": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description2,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.title": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title2,
  "APPB2C.registration.results.osr.chooseSkill.skillRequired": APPB2C_registration_results_osr_chooseSkill_skillRequired2,
  "APPB2C.registration.results.osr.chooseTest.availableDates": APPB2C_registration_results_osr_chooseTest_availableDates2,
  "APPB2C.registration.results.osr.chooseTest.speaking.time": APPB2C_registration_results_osr_chooseTest_speaking_time2,
  "APPB2C.registration.results.osr.error.missingFile": APPB2C_registration_results_osr_error_missingFile2,
  "APPB2C.registration.results.osr.error.noTestDates": APPB2C_registration_results_osr_error_noTestDates2,
  "APPB2C.registration.results.osr.error.noTests": APPB2C_registration_results_osr_error_noTests2,
  "APPB2C.registration.results.osr.line1": APPB2C_registration_results_osr_line12,
  "APPB2C.registration.results.osr.line2": APPB2C_registration_results_osr_line22,
  "APPB2C.registration.results.osr.line3": APPB2C_registration_results_osr_line32,
  "APPB2C.registration.results.osr.line4": APPB2C_registration_results_osr_line42,
  "APPB2C.registration.results.osr.line5": APPB2C_registration_results_osr_line52,
  "APPB2C.registration.results.osr.review.note": APPB2C_registration_results_osr_review_note2,
  "APPB2C.registration.results.osr.review.note.testDay": APPB2C_registration_results_osr_review_note_testDay2,
  "APPB2C.registration.results.osr.submitBtn": APPB2C_registration_results_osr_submitBtn2,
  "APPB2C.registration.results.osr.successBanner": APPB2C_registration_results_osr_successBanner2,
  "APPB2C.registration.results.osr.title": APPB2C_registration_results_osr_title2,
  "APPB2C.registration.results.ttAbsent.btn": APPB2C_registration_results_ttAbsent_btn2,
  "APPB2C.registration.results.ttAbsent.msg": APPB2C_registration_results_ttAbsent_msg2,
  "APPB2C.registration.results.updateBar.osr.booked": APPB2C_registration_results_updateBar_osr_booked2,
  "APPB2C.registration.results.updateBar.osr.marked": APPB2C_registration_results_updateBar_osr_marked2,
  "APPB2C.registration.tabs.bookingDetails": APPB2C_registration_tabs_bookingDetails2,
  "APPB2C.registration.tabs.candidate": APPB2C_registration_tabs_candidate2,
  "APPB2C.registration.tabs.date": APPB2C_registration_tabs_date2,
  "APPB2C.registration.tabs.dateLocation": APPB2C_registration_tabs_dateLocation2,
  "APPB2C.registration.tabs.payments": APPB2C_registration_tabs_payments2,
  "APPB2C.registration.tabs.recognisingOrgs": APPB2C_registration_tabs_recognisingOrgs2,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.1": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_12,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.2": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_22,
  "APPB2C.registration.tabs.results": APPB2C_registration_tabs_results2,
  "APPB2C.registration.testTaker.bookedFor.completed": APPB2C_registration_testTaker_bookedFor_completed2,
  "APPB2C.registration.testTaker.bookedFor.dob": APPB2C_registration_testTaker_bookedFor_dob2,
  "APPB2C.registration.testTaker.bookedFor.email": APPB2C_registration_testTaker_bookedFor_email2,
  "APPB2C.registration.testTaker.bookedFor.gender": APPB2C_registration_testTaker_bookedFor_gender2,
  "APPB2C.registration.testTaker.bookedFor.name": APPB2C_registration_testTaker_bookedFor_name2,
  "APPB2C.registration.testTaker.bookedFor.parent": APPB2C_registration_testTaker_bookedFor_parent2,
  "APPB2C.registration.testTaker.bookedFor.title": APPB2C_registration_testTaker_bookedFor_title2,
  "APPB2C.registration.testTaker.childConsent.back": APPB2C_registration_testTaker_childConsent_back2,
  "APPB2C.registration.testTaker.childConsent.context": APPB2C_registration_testTaker_childConsent_context2,
  "APPB2C.registration.testTaker.childConsent.cta": APPB2C_registration_testTaker_childConsent_cta2,
  "APPB2C.registration.testTaker.childConsent.options.1": APPB2C_registration_testTaker_childConsent_options_12,
  "APPB2C.registration.testTaker.childConsent.options.2": APPB2C_registration_testTaker_childConsent_options_22,
  "APPB2C.registration.testTaker.childConsent.options.3": APPB2C_registration_testTaker_childConsent_options_32,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.add": APPB2C_registration_testTaker_childConsent_somebodyElse_add2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmCheckbox": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.1": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_12,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.2": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_22,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.noLastName": APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.note": APPB2C_registration_testTaker_childConsent_somebodyElse_note2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phone": APPB2C_registration_testTaker_childConsent_somebodyElse_phone2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phoneNote": APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.policy": APPB2C_registration_testTaker_childConsent_somebodyElse_policy2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.relationship": APPB2C_registration_testTaker_childConsent_somebodyElse_relationship2,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.1": APPB2C_registration_testTaker_childConsent_somebodyElse_title_12,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.2": APPB2C_registration_testTaker_childConsent_somebodyElse_title_22,
  "APPB2C.registration.testTaker.childConsent.title": APPB2C_registration_testTaker_childConsent_title2,
  "APPB2C.registration.testTaker.childConsent.warning": APPB2C_registration_testTaker_childConsent_warning2,
  "APPB2C.registration.testTaker.consent.edit": APPB2C_registration_testTaker_consent_edit2,
  "APPB2C.registration.testTaker.consent.missing": APPB2C_registration_testTaker_consent_missing2,
  "APPB2C.registration.testTaker.consent.title": APPB2C_registration_testTaker_consent_title2,
  "APPB2C.registration.testTaker.contact.address": APPB2C_registration_testTaker_contact_address2,
  "APPB2C.registration.testTaker.contact.edit": APPB2C_registration_testTaker_contact_edit2,
  "APPB2C.registration.testTaker.contact.mobile": APPB2C_registration_testTaker_contact_mobile2,
  "APPB2C.registration.testTaker.contact.parent": APPB2C_registration_testTaker_contact_parent2,
  "APPB2C.registration.testTaker.contact.title": APPB2C_registration_testTaker_contact_title2,
  "APPB2C.registration.testTaker.editContact.address": APPB2C_registration_testTaker_editContact_address2,
  "APPB2C.registration.testTaker.editContact.back": APPB2C_registration_testTaker_editContact_back2,
  "APPB2C.registration.testTaker.editContact.city": APPB2C_registration_testTaker_editContact_city2,
  "APPB2C.registration.testTaker.editContact.country.change": APPB2C_registration_testTaker_editContact_country_change2,
  "APPB2C.registration.testTaker.editContact.country.residence": APPB2C_registration_testTaker_editContact_country_residence2,
  "APPB2C.registration.testTaker.editContact.cta": APPB2C_registration_testTaker_editContact_cta2,
  "APPB2C.registration.testTaker.editContact.error.missingAddress": APPB2C_registration_testTaker_editContact_error_missingAddress2,
  "APPB2C.registration.testTaker.editContact.error.missingCountry": APPB2C_registration_testTaker_editContact_error_missingCountry2,
  "APPB2C.registration.testTaker.editContact.error.pastCutoff": APPB2C_registration_testTaker_editContact_error_pastCutoff2,
  "APPB2C.registration.testTaker.editContact.mobile": APPB2C_registration_testTaker_editContact_mobile2,
  "APPB2C.registration.testTaker.editContact.note": APPB2C_registration_testTaker_editContact_note2,
  "APPB2C.registration.testTaker.editContact.postCode": APPB2C_registration_testTaker_editContact_postCode2,
  "APPB2C.registration.testTaker.editContact.state": APPB2C_registration_testTaker_editContact_state2,
  "APPB2C.registration.testTaker.editContact.warn": APPB2C_registration_testTaker_editContact_warn2,
  "APPB2C.registration.testTaker.error": APPB2C_registration_testTaker_error2,
  "APPB2C.registration.testTaker.files.editButton.add": APPB2C_registration_testTaker_files_editButton_add2,
  "APPB2C.registration.testTaker.files.editButton.edit": APPB2C_registration_testTaker_files_editButton_edit2,
  "APPB2C.registration.testTaker.files.upload.back.subtitle": APPB2C_registration_testTaker_files_upload_back_subtitle2,
  "APPB2C.registration.testTaker.files.upload.back.title": APPB2C_registration_testTaker_files_upload_back_title2,
  "APPB2C.registration.testTaker.files.upload.cta": APPB2C_registration_testTaker_files_upload_cta2,
  "APPB2C.registration.testTaker.files.upload.error.fileTooLarge": APPB2C_registration_testTaker_files_upload_error_fileTooLarge2,
  "APPB2C.registration.testTaker.files.upload.error.maxNumberOfFilesExceeded": APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded2,
  "APPB2C.registration.testTaker.files.upload.error.uploadUnavailable": APPB2C_registration_testTaker_files_upload_error_uploadUnavailable2,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileName": APPB2C_registration_testTaker_files_upload_error_wrongFileName2,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileType": APPB2C_registration_testTaker_files_upload_error_wrongFileType2,
  "APPB2C.registration.testTaker.files.upload.mobile.line1": APPB2C_registration_testTaker_files_upload_mobile_line12,
  "APPB2C.registration.testTaker.files.upload.mobile.line2": APPB2C_registration_testTaker_files_upload_mobile_line22,
  "APPB2C.registration.testTaker.files.upload.mobile.title": APPB2C_registration_testTaker_files_upload_mobile_title2,
  "APPB2C.registration.testTaker.files.upload.note": APPB2C_registration_testTaker_files_upload_note2,
  "APPB2C.registration.testTaker.id.child": APPB2C_registration_testTaker_id_child2,
  "APPB2C.registration.testTaker.id.edit.rules.example": APPB2C_registration_testTaker_id_edit_rules_example2,
  "APPB2C.registration.testTaker.id.edit.subtitle": APPB2C_registration_testTaker_id_edit_subtitle2,
  "APPB2C.registration.testTaker.id.edit.title": APPB2C_registration_testTaker_id_edit_title2,
  "APPB2C.registration.testTaker.id.edit.upload.exampleProof": APPB2C_registration_testTaker_id_edit_upload_exampleProof2,
  "APPB2C.registration.testTaker.id.edit.upload.imgAlt": APPB2C_registration_testTaker_id_edit_upload_imgAlt2,
  "APPB2C.registration.testTaker.id.expiryDate": APPB2C_registration_testTaker_id_expiryDate2,
  "APPB2C.registration.testTaker.id.fileAlt": APPB2C_registration_testTaker_id_fileAlt2,
  "APPB2C.registration.testTaker.id.idNumber": APPB2C_registration_testTaker_id_idNumber2,
  "APPB2C.registration.testTaker.id.issuingAuthority": APPB2C_registration_testTaker_id_issuingAuthority2,
  "APPB2C.registration.testTaker.id.noFilesBody": APPB2C_registration_testTaker_id_noFilesBody2,
  "APPB2C.registration.testTaker.id.noFilesTitle": APPB2C_registration_testTaker_id_noFilesTitle2,
  "APPB2C.registration.testTaker.id.title": APPB2C_registration_testTaker_id_title2,
  "APPB2C.registration.testTaker.id.typeNames.card": APPB2C_registration_testTaker_id_typeNames_card2,
  "APPB2C.registration.testTaker.id.typeNames.other": APPB2C_registration_testTaker_id_typeNames_other2,
  "APPB2C.registration.testTaker.id.typeNames.passport": APPB2C_registration_testTaker_id_typeNames_passport2,
  "APPB2C.registration.testTaker.specialArrangements.description": APPB2C_registration_testTaker_specialArrangements_description2,
  "APPB2C.registration.testTaker.specialArrangements.edit.line1": APPB2C_registration_testTaker_specialArrangements_edit_line12,
  "APPB2C.registration.testTaker.specialArrangements.edit.line2": APPB2C_registration_testTaker_specialArrangements_edit_line22,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule1": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule12,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule2": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule22,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule3": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule32,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule4": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule42,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule5": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule52,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.title": APPB2C_registration_testTaker_specialArrangements_edit_rules_title2,
  "APPB2C.registration.testTaker.specialArrangements.edit.subtitle": APPB2C_registration_testTaker_specialArrangements_edit_subtitle2,
  "APPB2C.registration.testTaker.specialArrangements.edit.title": APPB2C_registration_testTaker_specialArrangements_edit_title2,
  "APPB2C.registration.testTaker.specialArrangements.fileAlt": APPB2C_registration_testTaker_specialArrangements_fileAlt2,
  "APPB2C.registration.testTaker.specialArrangements.noFilesBody": APPB2C_registration_testTaker_specialArrangements_noFilesBody2,
  "APPB2C.registration.testTaker.specialArrangements.noFilesTitle": APPB2C_registration_testTaker_specialArrangements_noFilesTitle2,
  "APPB2C.registration.testTaker.specialArrangements.title": APPB2C_registration_testTaker_specialArrangements_title2,
  "APPB2C.testCard.arrival": APPB2C_testCard_arrival2,
  "APPB2C.testCard.book": APPB2C_testCard_book2,
  "APPB2C.testCard.bookExam": APPB2C_testCard_bookExam2,
  "APPB2C.testCard.bookFor": APPB2C_testCard_bookFor2,
  "APPB2C.testCard.bookForFree": APPB2C_testCard_bookForFree2,
  "APPB2C.testCard.bookingPrice": APPB2C_testCard_bookingPrice2,
  "APPB2C.testCard.cd": APPB2C_testCard_cd2,
  "APPB2C.testCard.changeSpeaking": APPB2C_testCard_changeSpeaking2,
  "APPB2C.testCard.f2f": APPB2C_testCard_f2f2,
  "APPB2C.testCard.hideSpeakingTests": APPB2C_testCard_hideSpeakingTests2,
  "APPB2C.testCard.lrw": APPB2C_testCard_lrw2,
  "APPB2C.testCard.lrwHeader": APPB2C_testCard_lrwHeader,
  "APPB2C.testCard.ls": APPB2C_testCard_ls2,
  "APPB2C.testCard.mixedSlots": APPB2C_testCard_mixedSlots2,
  "APPB2C.testCard.notLinkedVenue": APPB2C_testCard_notLinkedVenue2,
  "APPB2C.testCard.pb": APPB2C_testCard_pb2,
  "APPB2C.testCard.review": APPB2C_testCard_review2,
  "APPB2C.testCard.showSpeakingTests": APPB2C_testCard_showSpeakingTests2,
  "APPB2C.testCard.speaking": APPB2C_testCard_speaking2,
  "APPB2C.testCard.speakingAddress": APPB2C_testCard_speakingAddress2,
  "APPB2C.testCard.timeUnknown": APPB2C_testCard_timeUnknown2,
  "APPB2C.testCard.vcs": APPB2C_testCard_vcs2
};

// src/locale/ko/b2c.json
var APPB2C_auth_changePassword_cta3 = "\uBE44\uBC00\uBC88\uD638 \uC785\uB825";
var APPB2C_auth_changePassword_error3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uBA87 \uBD84 \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_auth_changePassword_error_differentPasswords3 = "\uC785\uB825\uD55C \uBE44\uBC00\uBC88\uD638\uAC00 \uB2E4\uB985\uB2C8\uB2E4.";
var APPB2C_auth_changePassword_error_wrongIdNumber3 = "ID \uBC88\uD638\uAC00 \uC798\uBABB\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_auth_changePassword_error_wrongOldPassword3 = "\uC774\uC804 \uBE44\uBC00\uBC88\uD638\uAC00 \uC798\uBABB\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_auth_changePassword_error_wrongPassword3 = "\uBE44\uBC00\uBC88\uD638\uAC00 \uC694\uAD6C \uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_auth_changePassword_id_label3 = "\uC5EC\uAD8C\uBC88\uD638/\uC8FC\uBBFC\uB4F1\uB85D\uBC88\uD638";
var APPB2C_auth_changePassword_id_note3 = "\uB85C\uADF8\uC778\uC744 \uACC4\uC18D\uD558\uB824\uBA74 \uC544\uB798\uC5D0 \uC5EC\uAD8C\uBC88\uD638/\uC8FC\uBBFC\uB4F1\uB85D\uBC88\uD638\uB97C \uC785\uB825\uD558\uC138\uC694.";
var APPB2C_auth_changePassword_id_warn3 = "\uC62C\uBC14\uB978 \uBC88\uD638\uB97C \uC785\uB825\uD588\uC74C\uC5D0\uB3C4 '\uC62C\uBC14\uB978 \uC8FC\uBBFC\uB4F1\uB85D\uBC88\uD638\uAC00 \uC544\uB2D9\uB2C8\uB2E4'\uB77C\uB294 \uBA54\uC2DC\uC9C0\uAC00 \uACC4\uC18D \uD45C\uC2DC\uB418\uBA74 \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC73C\uB85C \uBB38\uC758\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_auth_changePassword_pass_label3 = "\uBE44\uBC00\uBC88\uD638";
var APPB2C_auth_changePassword_pass_note3 = "\uACC4\uC18D\uD558\uB824\uBA74 \uC6D0\uD558\uB294 \uBE44\uBC00\uBC88\uD638\uB97C \uC124\uC815\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_auth_changePassword_title3 = "\uC0C8 \uBE44\uBC00\uBC88\uD638\uB97C \uC785\uB825\uD558\uC2ED\uC2DC\uC624";
var APPB2C_auth_forgetPassword_cta3 = "\uBE44\uBC00\uBC88\uD638 \uC7AC\uC124\uC815 \uB9C1\uD06C\uB97C \uC774\uBA54\uC77C\uB85C \uBC1B\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_auth_forgetPassword_email3 = "\uC774\uBA54\uC77C \uC8FC\uC18C";
var APPB2C_auth_forgetPassword_success_msg13 = "\uAC10\uC0AC\uD569\uB2C8\uB2E4. \uACC4\uC815\uC774 \uC788\uB294 \uACBD\uC6B0 \uC774\uBA54\uC77C\uB85C \uCD94\uAC00 \uC9C0\uCE68\uC744 \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_auth_forgetPassword_success_msg23 = "\uB9C1\uD06C\uB97C \uBC1B\uC73C\uC2DC\uBA74 \uD574\uB2F9 \uB9C1\uD06C\uB97C \uD074\uB9AD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_auth_forgetPassword_success_title3 = "\uC131\uACF5!";
var APPB2C_auth_forgetPassword_text3 = "\uBE44\uBC00\uBC88\uD638\uB97C \uC78A\uC73C\uC168\uB2E4\uBA74 \uBE44\uBC00\uBC88\uD638 \uC7AC\uC124\uC815 \uB9C1\uD06C\uB97C \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_auth_forgetPassword_title3 = "\uBE44\uBC00\uBC88\uD638 \uC7AC\uC124\uC815";
var APPB2C_auth_resetPassword_cta3 = "\uBE44\uBC00\uBC88\uD638\uB97C \uC7AC\uC124\uC815";
var APPB2C_auth_resetPassword_error_expiredToken3 = "\uBE44\uBC00\uBC88\uD638\uB97C \uC7AC\uC124\uC815\uD560 \uC218 \uC788\uB294 \uB9C1\uD06C\uAC00 \uB9CC\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uB85C\uADF8\uC778 \uD654\uBA74\uC758 '\uBE44\uBC00\uBC88\uD638 \uCC3E\uAE30'\uB97C \uC0AC\uC6A9\uD558\uC5EC \uC0C8 \uB9C1\uD06C\uB97C \uBC1B\uC73C\uC2ED\uC2DC\uC624.";
var APPB2C_auth_resetPassword_error_wrongPassword3 = "\uC785\uB825\uD55C \uBE44\uBC00\uBC88\uD638\uAC00 \uC694\uAD6C \uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_auth_resetPassword_error_wrongToken3 = "\uC554\uD638 \uC7AC\uC124\uC815\uC744 \uC704\uD55C \uC784\uC2DC \uB9C1\uD06C\uAC00 \uC798\uBABB\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_auth_resetPassword_form_newPassword3 = "\uC0C8 \uBE44\uBC00\uBC88\uD638";
var APPB2C_auth_resetPassword_noToken_cta3 = "\uBE44\uBC00\uBC88\uD638\uB97C \uC7AC\uC124\uC815 \uD558\uC2ED\uC2DC\uC624";
var APPB2C_auth_resetPassword_noToken_msg3 = "\uC81C\uACF5\uD558\uC2E0 \uD1A0\uD070(token)\uC744 \uC778\uC2DD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_auth_resetPassword_success_msg3 = "\uBE44\uBC00\uBC88\uD638\uAC00 \uC7AC\uC124\uC815 \uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uC774\uC804 \uD654\uBA74\uC73C\uB85C \uB3CC\uC544\uAC11\uB2C8\uB2E4.";
var APPB2C_auth_resetPassword_success_title3 = "\uC131\uACF5!";
var APPB2C_auth_resetPassword_title3 = "\uBE44\uBC00\uBC88\uD638\uB97C \uC7AC\uC124\uC815 \uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_basic_cta3 = "\uC800\uC7A5 \uBC0F \uACC4\uC18D";
var APPB2C_cj_basic_goToReview3 = "\uC800\uC7A5 \uBC0F \uAC80\uD1A0";
var APPB2C_cj_basic_scrollLink3 = "\uB2F5\uBCC0\uC774 \uC62C\uBC14\uB978\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_basic_selectDate3 = "\uB0A0\uC9DC \uC120\uD0DD";
var APPB2C_cj_basic_selectTime3 = "\uC2DC\uAC04 \uC120\uD0DD";
var APPB2C_cj_basic_validationError3 = "\uACC4\uC18D\uD558\uB824\uBA74 \uAC15\uC870 \uD45C\uC2DC\uB41C \uB2F5\uBCC0\uC774 \uBAA8\uB450 \uC62C\uBC14\uB978\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_bookingComplete_feeDetails_discount3 = "\uD504\uB85C\uBAA8\uC158";
var APPB2C_cj_bookingComplete_feeDetails_fee3 = "\uC218\uC218\uB8CC";
var APPB2C_cj_bookingComplete_feeDetails_newFee3 = "\uC0C8 \uC218\uC218\uB8CC";
var APPB2C_cj_bookingComplete_feeDetails_promo3 = "\uD504\uB85C\uBAA8\uC158";
var APPB2C_cj_bookingComplete_feeDetails_reference3 = "\uB808\uD37C\uB7F0\uC2A4(Reference)";
var APPB2C_cj_bookingComplete_feeDetails_tax3 = "\uC138\uAE08";
var APPB2C_cj_bookingComplete_feeDetails_title3 = "\uC218\uC218\uB8CC \uB0B4\uC5ED";
var APPB2C_cj_bookingComplete_feeDetails_total3 = "\uD569\uACC4";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine13 = "\uC751\uC2DC\uB8CC\uAC00 \uB2E4\uC74C\uACFC \uAC19\uC774 \uD560\uC778\uB418\uC5C8\uC73C\uBA70";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine23 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC\uC774 \uC0AC\uC6A9\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine33 = "\uACB0\uC81C\uD560 \uD544\uC694\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_loadingPaymentMethodsError3 = "\uD604\uC7AC \uC774\uC6A9 \uAC00\uB2A5\uD55C \uACB0\uC81C \uC218\uB2E8\uC744 \uBD88\uB7EC\uC62C \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC138\uC694.";
var APPB2C_cj_bookingComplete_loadingRegistrationError3 = "\uD604\uC7AC \uADC0\uD558\uC758 \uC2DC\uD5D8 \uB4F1\uB85D \uC815\uBCF4\uB97C \uAC00\uC838\uC62C \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_bookingComplete_note3 = "\uCD95\uD558\uD569\uB2C8\uB2E4. IELTS \uC2DC\uD5D8\uC774 \uC608\uC57D\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uC758 \uC774\uBA54\uC77C \uC8FC\uC18C\uB85C \uC608\uC57D \uD655\uC778 \uBA54\uC77C\uC774 \uBC1C\uC1A1\uB418\uC5C8\uC2B5\uB2C8\uB2E4(\uBA54\uC77C\uC774 \uBCF4\uC774\uC9C0 \uC54A\uC744 \uB54C\uB294 \uC2A4\uD338 \uD3F4\uB354\uB97C \uD655\uC778\uD574 \uC8FC\uC138\uC694).";
var APPB2C_cj_bookingComplete_noteUolPassportInfo3 = "IELTS \uC2DC\uD5D8 \uC608\uC57D\uC5D0\uB294 \uC5EC\uAD8C \uD655\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4. \uC774\uB97C \uC704\uD574 \uACE7 \uC5F0\uB77D\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_offlinePayment_code3 = "\uC740\uD589\uCF54\uB4DC(Sort code)";
var APPB2C_cj_bookingComplete_offlinePayment_description3 = "\uACB0\uC81C\uB0B4\uC6A9(Payment description):";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line13 = "\uC751\uC2DC\uB8CC\uAC00 \uB2E4\uC74C\uACFC \uAC19\uC774 \uD560\uC778\uB418\uC5C8\uC73C\uBA70";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line23 = "\uC608\uC57D\uC744 \uD655\uC815\uD558\uB824\uBA74 \uB0A8\uC740 \uAE08\uC561\uC744 \uACB0\uC81C\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_bookingComplete_offlinePayment_fee3 = "\uC218\uC218\uB8CC";
var APPB2C_cj_bookingComplete_offlinePayment_hideDescription3 = "\uACB0\uC81C\uB0B4\uC6A9 \uC228\uAE30\uAE30";
var APPB2C_cj_bookingComplete_offlinePayment_incTax3 = "\uC138\uAE08 \uD3EC\uD568";
var APPB2C_cj_bookingComplete_offlinePayment_name3 = "\uACC4\uC88C\uBA85(Account name)";
var APPB2C_cj_bookingComplete_offlinePayment_note_line13 = "\uACB0\uC81C \uAE30\uD55C\uC774 {{days}}\uC77C(\uC601\uC5C5\uC77C) \uB0A8\uC558\uC2B5\uB2C8\uB2E4. \uAE30\uD55C \uB0B4 \uACB0\uC81C\uD558\uC9C0 \uC54A\uC73C\uBA74 \uACE0\uC0AC\uC7A5 \uC608\uC57D\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours3 = "\uACB0\uC81C \uAE30\uD55C\uC774 {{hours}}\uC2DC\uAC04 \uB0A8\uC558\uC2B5\uB2C8\uB2E4. \uAE30\uD55C \uB0B4 \uACB0\uC81C\uD558\uC9C0 \uC54A\uC73C\uBA74 \uACE0\uC0AC\uC7A5 \uC608\uC57D\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft3 = "{{date}} \uAE4C\uC9C0 \uC9C0\uBD88\uD574\uC57C \uD569\uB2C8\uB2E4. \uC774 \uC2DC\uAC04 \uB0B4\uC5D0 \uBE44\uC6A9\uC744 \uC9C0\uBD88\uD558\uC9C0 \uC54A\uC73C\uBA74 \uADC0\uD558\uC758 \uC2DC\uD5D8\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_offlinePayment_note_line23 = "\uACB0\uC81C \uD6C4\uC5D0\uB294 \uC774 \uC0AC\uC774\uD2B8\uC5D0 \uB2E4\uC2DC \uB85C\uADF8\uC778\uD558\uC5EC \uACB0\uC81C \uC99D\uBE59\uC744 \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_cj_bookingComplete_offlinePayment_number3 = "\uACC4\uC88C\uBC88\uD638(Account number)";
var APPB2C_cj_bookingComplete_offlinePayment_reference3 = "\uC785\uAE08\uC790\uBA85(Payment reference)";
var APPB2C_cj_bookingComplete_offlinePayment_showDescription3 = "\uACB0\uC81C\uB0B4\uC6A9 \uBCF4\uC774\uAE30";
var APPB2C_cj_bookingComplete_otherPaymentOptions3 = "\uB2E4\uB978 \uACB0\uC81C \uC635\uC158 \uBCF4\uAE30";
var APPB2C_cj_bookingComplete_paymentFailed3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC628\uB77C\uC778 \uACB0\uC81C\uAC00 \uC2E4\uD328\uD588\uC2B5\uB2C8\uB2E4. \uC2DC\uD5D8 \uC77C\uC815\uC774 \uC7A1\uD614\uC73C\uB098 \uC608\uC57D \uD655\uC815\uC744 \uC704\uD574\uC11C\uB294 \uACB0\uC81C\uB97C \uC644\uB8CC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_paymentOptions3 = "\uACB0\uC81C \uC635\uC158 \uBCF4\uAE30";
var APPB2C_cj_bookingComplete_prepareNote_accesNote3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 IELTS \uD559\uC2B5 \uC790\uB8CC \uBC0F \uC790\uC6D0\uC744 \uBB34\uB8CC\uB85C \uC774\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_prepareNote_counselling_note3 = "IELTS \uC0C1\uB2F4 \uD328\uD0A4\uC9C0\uB97C \uC774\uC6A9\uD574 \uBCF4\uC138\uC694. IELTS \uC804\uBB38\uAC00\uB4E4\uC774 \uAC00\uC7A5 \uD6A8\uACFC\uC801\uC73C\uB85C \uC2DC\uD5D8\uC5D0 \uB300\uBE44\uD558\uACE0 \uC6D0\uD558\uB294 \uC810\uC218\uB97C \uC5BB\uC744 \uC218 \uC788\uB294 \uBC29\uBC95\uC744 \uC548\uB0B4\uD558\uACE0 \uC870\uC5B8\uD558\uBA70 \uADC0\uD558\uB97C \uB4E0\uB4E0\uD788 \uC9C0\uC6D0\uD574\uB4DC\uB9BD\uB2C8\uB2E4. ";
var APPB2C_cj_bookingComplete_prepareNote_counselling_view3 = "IELTS \uC0C1\uB2F4 \uC11C\uBE44\uC2A4 \uBCF4\uAE30";
var APPB2C_cj_bookingComplete_prepareNote_note3 = "IELTS \uC2DC\uD5D8 \uB300\uBE44\uB97C \uC704\uD55C \uCCAB \uBC88\uC9F8 \uB2E8\uACC4\uB294 \uC2DC\uD5D8 \uB2F9\uC77C\uC5D0 \uACBD\uD5D8\uD558\uAC8C \uB420 \uBAA8\uB4E0 \uAC83\uC5D0 \uB300\uBE44\uD558\uB294 \uC77C\uC785\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_prepareNote_title3 = "IELTS \uC2DC\uD5D8 \uB300\uBE44";
var APPB2C_cj_bookingComplete_prepareNote_view3 = "\uC2DC\uD5D8 \uB300\uBE44 \uC790\uB8CC \uBCF4\uAE30";
var APPB2C_cj_bookingComplete_title3 = "\uC608\uC57D \uC644\uB8CC";
var APPB2C_cj_bookingComplete_tnc3 = "\uADC0\uD558\uB294 \uC774";
var APPB2C_cj_bookingComplete_tncLink3 = "\uC774\uC6A9 \uADDC\uC815\uC5D0 \uB3D9\uC758\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_bookingComplete_tryAgain3 = "\uACB0\uC81C\uB97C \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_bookingComplete_yourBooking3 = "\uC608\uC57D \uB0B4\uC5ED";
var APPB2C_cj_childDetails_about3 = "\uC790\uB140\uC5D0 \uB300\uD558\uC5EC";
var APPB2C_cj_childDetails_aboutNote3 = "\uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD558\uB294 \uC790\uB140\uC758 \uC815\uBCF4\uB97C \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_childDetails_error_missingFirstName3 = "\uC790\uB140\uC758 \uC774\uB984/\uC774\uB984\uC744 \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_childDetails_error_missingSurname3 = "\uC790\uB140\uC758 \uC131/\uC131\uC744 \uC785\uB825\uD574 \uC8FC\uC138\uC694. \uC131\uC774 \uC5C6\uB294 \uACBD\uC6B0 \uC774 \uD544\uB4DC\uC5D0 \uD558\uC774\uD508('-')\uC744 \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_childDetails_error_wrongDob3 = "\uC790\uB140\uC758 \uC2E4\uC81C \uC0DD\uB144\uC6D4\uC77C\uC744 \uAE30\uC785\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_childDetails_forms_personal_idMatch3 = "\uC774 \uC774\uB984\uC740 \uC2E0\uBD84\uC99D\uC758 \uC774\uB984\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_cj_childDetails_noLastName3 = "\uC131\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_childDetails_note3 = "\uC790\uB140\uC758 \uC774\uB984, \uC0DD\uB144\uC6D4\uC77C\uC740 \uACE0\uC0AC\uC7A5\uC5D0\uC11C \uC0AC\uC6A9\uD560 \uC2E0\uBD84\uC99D\uC758 \uB0B4\uC6A9\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_childDetails_onTestDay3 = "\uC2DC\uD5D8 \uB2F9\uC77C";
var APPB2C_cj_childDetails_title3 = "\uC790\uB140 \uC815\uBCF4";
var APPB2C_cj_finishPayment_error3 = "\uC9C0\uAE08\uC740 \uACB0\uC81C\uB97C \uCC98\uB9AC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_finishPayment_pleaseWait3 = "\uC7A0\uC2DC \uAE30\uB2E4\uB824 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_cj_finishPayment_processing3 = "\uACB0\uC81C\uB97C \uCC98\uB9AC\uD558\uACE0 \uC788\uC2B5\uB2C8\uB2E4...";
var APPB2C_cj_idDetails_confirm_change3 = "\uC774 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD574\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_cj_idDetails_confirm_confirm3 = "\uC774 \uC815\uBCF4\uB294 \uC815\uD655\uD569\uB2C8\uB2E4";
var APPB2C_cj_idDetails_confirm_note13 = "\uC81C\uACF5\uD558\uC2E0 $t({{idName}})\uC758 \uC815\uBCF4\uAC00 \uBC18\uB4DC\uC2DC \uC815\uD655\uD574\uC57C \uD569\uB2C8\uB2E4. \uACC4\uC18D\uD558\uAE30 \uC804\uC5D0 \uC544\uB798\uB97C \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_confirm_note23 = "\uC2DC\uD5D8 \uB2F9\uC77C \uD574\uB2F9 $t({{idName}})\uC744 \uC9C0\uCC38\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_confirm_title3 = "\uACC4\uC18D\uD558\uAE30 \uC804\uC5D0 $t({{idName}})\uC744 \uD655\uC778\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_content3 = "\uC2E0\uBD84\uC99D \uC815\uBCF4\uC5D0 \uC774\uC0C1\uC774 \uC788\uC73C\uBA74 \uC131\uC801 \uBC1C\uAE09\uC774 \uC9C0\uC5F0\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_cj_idDetails_error_missingIdExpiry3 = "\uC2E0\uBD84\uC99D\uC758 \uB9CC\uB8CC\uC77C\uC744 \uAE30\uC785\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_error_missingIdNumber3 = "\uC2E0\uBD84\uC99D \uBC88\uD638\uB97C \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624. \uD2B9\uC815 \uD615\uC2DD\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4(\uBB38\uC790, \uC22B\uC790 \uBC0F \uD2B9\uC218 \uBB38\uC790: _\\-/,).";
var APPB2C_cj_idDetails_error_missingIdType3 = "\uC2E0\uBD84\uC99D \uC885\uB958\uB97C \uAE30\uC785\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_error_missingIssuingAuthority3 = "\uC2E0\uBD84\uC99D \uBC1C\uAE09 \uAE30\uAD00\uC744 \uAE30\uC7AC\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_error_wrongFilename3 = "\uD30C\uC77C \uC774\uB984\uC5D0 \uC798\uBABB\uB41C \uBB38\uC790\uAC00 \uD3EC\uD568\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_error_wrongIdExpiry3 = "\uC120\uD0DD\uD55C \uC2DC\uD5D8 \uB0A0\uC9DC \uC774\uC804\uC5D0 \uB9CC\uB8CC\uB418\uC9C0 \uC54A\uB294 \uC2E0\uBD84\uC99D\uC744 \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_forms_changeNote3 = "\uC2E0\uBD84\uC99D \uC720\uD615\uC744 \uBCC0\uACBD\uD558\uC168\uC2B5\uB2C8\uB2E4. \uC544\uB798 \uC5C5\uB85C\uB4DC \uB41C \uC774\uBBF8\uC9C0\uC640 \uADC0\uD558\uAC00 \uC2DC\uD5D8 \uB2F9\uC77C \uC9C0\uCC38\uD560 \uC2E0\uBD84\uC99D\uC758 \uC0AC\uC9C4\uC774 \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_forms_changeNote_child3 = "\uC2E0\uBD84\uC99D \uC720\uD615\uC744 \uBCC0\uACBD\uD558\uC168\uC2B5\uB2C8\uB2E4. \uC544\uB798 \uC5C5\uB85C\uB4DC\uB41C \uC774\uBBF8\uC9C0\uC640 \uADC0\uD558\uC758 \uC790\uB140\uAC00 \uC2DC\uD5D8 \uB2F9\uC77C \uC9C0\uCC38\uD560 \uC2E0\uBD84\uC99D\uC758 \uC0AC\uC9C4\uC774 \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_forms_documentWithNoExpiryDate3 = "\uC2E0\uBD84\uC99D\uC5D0 \uC720\uD6A8\uAE30\uAC04\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_idDetails_forms_expiryDate3 = "$t({{idName}}, capitalise) \uC720\uD6A8\uAE30\uAC04(\uB9CC\uB8CC\uC77C)";
var APPB2C_cj_idDetails_forms_idNumber3 = "$t({{idName}}, capitalise) \uC2E0\uBD84\uC99D \uBC88\uD638";
var APPB2C_cj_idDetails_forms_idType3 = "\uC2E0\uBD84\uC99D \uC720\uD615";
var APPB2C_cj_idDetails_forms_issuingAuthority3 = "\uBC1C\uAE09\uAE30\uAD00";
var APPB2C_cj_idDetails_idExpired3 = "\uAE30\uC874\uC5D0 \uB4F1\uB85D\uD558\uC2E0 \uC2E0\uBD84\uC99D\uC758 \uC720\uD6A8\uAE30\uAC04\uC774 \uB9CC\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uC0C8 \uC2E0\uBD84\uC99D\uC744 \uCD94\uAC00\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_iolMessage3 = "\uC2DC\uD5D8\uC77C\uC5D0\uB294 IELTS \uC2DC\uD5D8 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD588\uB358 \uAC83\uACFC \uB3D9\uC77C\uD55C \uC2E0\uBD84\uC99D\uC744 \uC81C\uC2DC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_noteAboutIdentification3 = "\uC2DC\uD5D8\uC77C\uC5D0\uB294 IELTS \uC2DC\uD5D8 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD588\uB358 \uAC83\uACFC \uB3D9\uC77C\uD55C \uC2E0\uBD84\uC99D\uC744 \uC9C0\uCC38\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_noteAboutIdentification_child3 = "\uC2DC\uD5D8\uC77C\uC5D0\uB294 IELTS \uC2DC\uD5D8 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD588\uB358 \uAC83\uACFC \uB3D9\uC77C\uD55C \uC2E0\uBD84\uC99D\uC744 \uC9C0\uCC38\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_noteAboutIdentificationIol3 = "IELTS Online\uC5D0\uC11C\uB294 \uC5EC\uAD8C\uB9CC \uC2E0\uBD84\uC99D\uC73C\uB85C \uC778\uC815\uB429\uB2C8\uB2E4. \uB2E4\uB978 \uC2E0\uBD84\uC99D\uC744 \uC0AC\uC6A9\uD558\uB824\uBA74 \uC9C0\uD544 IELTS \uB610\uB294 \uCEF4\uD4E8\uD130 IELTS \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_noteAboutIdentificationIolLink3 = "\uC720\uD6A8\uD55C \uC5EC\uAD8C\uC774 \uC5C6\uB294 \uACBD\uC6B0 \uC774 \uB9C1\uD06C\uB97C \uD1B5\uD574 \uB2E4\uB978 \uC885\uB958\uC758 \uC2DC\uD5D8\uC744 \uC608\uC57D\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_noteAboutIdentificationUol3 = "IELTS UKVI Online\uC5D0\uC11C\uB294 \uC5EC\uAD8C\uB9CC \uC2E0\uBD84\uC99D\uC73C\uB85C \uC778\uC815\uB429\uB2C8\uB2E4. \uB2E4\uB978 \uC2E0\uBD84\uC99D\uC744 \uC0AC\uC6A9\uD558\uB824\uBA74 \uC9C0\uD544 IELTS for UKVI \uB610\uB294 \uCEF4\uD4E8\uD130 IELTS for UKVI \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_idDetails_title3 = "\uC2E0\uC6D0\uC815\uBCF4";
var APPB2C_cj_idDetails_title_child3 = "\uC790\uB140\uC758 \uC2E0\uBD84\uC99D";
var APPB2C_cj_idDetails_typeNames_card3 = "\uC2E0\uBD84\uC99D";
var APPB2C_cj_idDetails_typeNames_other3 = "\uC2E0\uBD84\uC99D";
var APPB2C_cj_idDetails_typeNames_passport3 = "\uC5EC\uAD8C";
var APPB2C_cj_idDetails_upload_fetchError3 = "\uD30C\uC77C\uC744 \uCC98\uB9AC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_file13 = "\uC774\uBBF8\uC9C0 1";
var APPB2C_cj_idDetails_upload_file23 = "\uC774\uBBF8\uC9C0 2(\uC120\uD0DD)";
var APPB2C_cj_idDetails_upload_instructions_capture3 = "\uBB38\uC11C \uC804\uCCB4\uAC00 \uBCF4\uC5EC\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_cj_idDetails_upload_instructions_covers3 = "\uBB38\uC11C\uB97C \uB2E4\uB978 \uBB3C\uCCB4(\uC2A4\uD2F0\uCEE4, \uD074\uB9BD, \uC190\uAC00\uB77D \uB4F1)\uAC00 \uAC00\uB9AC\uC9C0 \uC54A\uB3C4\uB85D \uC8FC\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_instructions_directLight3 = "\uBE5B\uC774 \uBC88\uC9C0\uC9C0 \uC54A\uB3C4\uB85D \uC9C1\uC0AC\uAD11\uC120\uC744 \uD53C\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_instructions_noBlur3 = "\uC0AC\uC9C4/\uC2A4\uCE94\uC774 \uD750\uB9BF\uD558\uC9C0 \uC54A\uB3C4\uB85D \uCD08\uC810\uC744 \uB9DE\uCD94\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_instructions_title3 = "\uC62C\uBC14\uB978 \uC0AC\uC9C4/\uC2A4\uCE94 \uBC29\uBC95:";
var APPB2C_cj_idDetails_upload_mobile3 = "\uD604\uC7AC \uBAA8\uBC14\uC77C \uAE30\uAE30\uB85C \uC811\uC18D\uD558\uC168\uC73C\uBBC0\uB85C \uD574\uB2F9 \uAE30\uAE30\uB97C \uD1B5\uD574 \uC0AC\uC9C4\uC744 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. ";
var APPB2C_cj_idDetails_upload_passport_guidelines_cover3 = "\uC5EC\uAD8C \uC815\uBCF4(\uC190\uAC00\uB77D, \uD074\uB9BD \uB4F1)\uB97C \uAC00\uB9AC\uC9C0 \uC54A\uB3C4\uB85D \uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_passport_guidelines_crop3 = "\uC5EC\uAD8C\uC744 \uD3C9\uD3C9\uD55C \uD45C\uBA74\uC5D0 \uB193\uACE0 \uB370\uC774\uD130\uAC00 \uBCF4\uC774\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions3 = "\uC544\uB798 \uC548\uB0B4\uC5D0 \uB530\uB77C \uC0AC\uC9C4\uC774 Google\uC758 \uC694\uAD6C\uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC138\uC694.";
var APPB2C_cj_idDetails_upload_passport_guidelines_glare3 = "\uC0AC\uC9C4\uC740 \uBC18\uC0AC \uC5C6\uC774 \uC120\uBA85\uD574\uC57C \uD558\uBA70 \uBAA8\uB4E0 \uB370\uC774\uD130\uB97C \uC77D\uC744 \uC218 \uC788\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_passport_guidelines_watermark3 = "\uCE74\uBA54\uB77C \uC124\uC815\uC5D0\uC11C \uC774\uBBF8\uC9C0 \uC6CC\uD130\uB9C8\uD06C\uAC00 \uBE44\uD65C\uC131\uD654\uB418\uC5B4 \uC788\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_idDetails_upload_title3 = "$t({{idName}}) \uC99D\uBE59\uC790\uB8CC \uC5C5\uB85C\uB4DC";
var APPB2C_cj_ieltsReady_description_line13 = "\uBB38\uBC95, \uC5B4\uD718, \uC77D\uAE30, \uB4E3\uAE30 \uB4F1\uC744 \uB2E4\uB8E8\uB294 \uD48D\uBD80\uD55C IELTS Ready \uD559\uC2B5 \uC790\uB8CC\uC5D0 \uC561\uC138\uC2A4\uD558\uC2ED\uC2DC\uC624. \uD63C\uC790 \uACF5\uBD80\uD558\uB4E0 \uC804\uBB38\uAC00\uC758 \uC9C0\uB3C4\uB97C \uBC1B\uC73C\uBA70 IELTS \uC2DC\uD5D8\uC744 \uC704\uD55C \uC2E4\uB825\uC744 \uD5A5\uC0C1\uC2DC\uD0A4\uC138\uC694. IELTS Ready\uB85C \uC6D0\uD558\uB294 \uC810\uC218\uB97C \uC5BB\uC73C\uC138\uC694.";
var APPB2C_cj_ieltsReady_description_line23 = "\uD589\uC6B4\uC744 \uBE55\uB2C8\uB2E4!";
var APPB2C_cj_ieltsReady_dobMin3 = "\uACC4\uC815\uC744 \uB9CC\uB4E4\uB824\uBA74 18\uC138 \uC774\uC0C1\uC774\uC5B4\uC57C \uD569\uB2C8\uB2E4. IELTS Ready Members\uB97C \uC774\uC6A9\uD558\uB824\uBA74 \uBD80\uBAA8\uB2D8\uC774\uB098 \uBCF4\uD638\uC790\uC758 \uB3C4\uC6C0\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_cj_ieltsReady_nameWarning3 = "IELTS \uC2DC\uD5D8\uC5D0 \uB4F1\uB85D\uD560 \uB54C \uC774\uB984\uC740 \uACF5\uC2DD \uC2E0\uBD84\uC99D\uACFC <bold>\uC77C\uCE58\uD574\uC57C \uD558\uBA70</bold>, \uB098\uC911\uC5D0 \uC2DC\uAC04\uC744 \uC808\uC57D\uD560 \uC218 \uC788\uB3C4\uB85D \uC9C0\uAE08 \uC815\uD655\uD55C\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_ieltsReady_note3 = "<0>IELTS Ready \uD68C\uC6D0 \uC601\uC5ED\uC5D0 \uC811\uC18D\uD558\uB824\uBA74 \uACC4\uC815\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.</0>";
var APPB2C_cj_ieltsReady_personalDetails_title2 = "\uADC0\uD558\uC5D0 \uB300\uD558\uC5EC";
var APPB2C_cj_ieltsReady_submitBtnText3 = "\uACC4\uC815 \uB9CC\uB4E4\uAE30";
var APPB2C_cj_ieltsReady_targetScore_option3 = "\uC544\uC9C1 \uBAA8\uB985\uB2C8\uB2E4.";
var APPB2C_cj_ieltsReady_targetScore_title3 = "\uADC0\uD558\uC758 IELTS \uBAA9\uD45C \uC810\uC218\uB294 \uC5BC\uB9C8\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ieltsReady_title3 = "\uACC4\uC815 \uB9CC\uB4E4\uAE30";
var APPB2C_cj_incorrectOrganisation_bookNewLink3 = "\uBCF8 \uC6F9\uC0AC\uC774\uD2B8\uC5D0\uC11C \uC2DC\uD5D8 \uC811\uC218 (\uC2DC\uD5D8 \uC13C\uD130\uB97C \uCC3E\uB294 \uB370 \uB3C4\uC6C0\uC744 \uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4)";
var APPB2C_cj_incorrectOrganisation_line13 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uC778\uC815\uD558\uB294 \uC2DC\uD5D8 \uB4F1\uB85D \uAE30\uAD00\uC774 \uC544\uB2D9\uB2C8\uB2E4.";
var APPB2C_cj_incorrectOrganisation_line23 = "\uAC71\uC815 \uB9C8\uC138\uC694. \uC2DC\uB3C4\uD574 \uBCFC \uC218 \uC788\uB294 \uBA87 \uAC00\uC9C0 \uBC29\uBC95\uC774 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_incorrectOrganisation_locationsLink3 = "IELTS \uC6F9\uC0AC\uC774\uD2B8\uC5D0\uC11C \uC804 \uC138\uACC4 \uC2DC\uD5D8 \uC13C\uD130\uB97C \uC870\uD68C\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_incorrectOrganisation_locationsLinkUsa3 = "IELTS \uC6F9\uC0AC\uC774\uD2B8\uC5D0\uC11C \uBBF8\uAD6D \uB0B4 \uBAA8\uB4E0 \uC2DC\uD5D8 \uC13C\uD130\uB97C \uC870\uD68C\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_incorrectOrganisation_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_cj_marketingPrefs3 = "\uAC1C\uC778\uC815\uBCF4 \uB9C8\uCF00\uD305 \uC774\uC6A9 \uB3D9\uC758";
var APPB2C_cj_onRequest_created_back3 = "< \uBAA8\uB4E0 UKVI \uC2DC\uD5D8 \uC635\uC158\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_cj_onRequest_created_in3 = "\uC548";
var APPB2C_cj_onRequest_created_info3 = "\uADC0\uD558\uAC00 \uC81C\uCD9C\uD55C \uC815\uBCF4\uB97C \uAC80\uD1A0\uD558\uACE0 48-72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC694\uCCAD\uC5D0 \uB300\uD55C \uD6C4\uC18D \uC870\uCE58\uB97C \uC704\uD574 \uC5F0\uB77D\uC744 \uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_created_note3 = "IELTS for UKVI \uC2DC\uD5D8 \uC751\uC2DC\uB97C \uC704\uD55C \uADC0\uD558\uC758 \uAD00\uC2EC\uC744 \uB4F1\uB85D\uD588\uC2B5\uB2C8\uB2E4. ";
var APPB2C_cj_onRequest_created_title3 = "\uADC0\uD558\uC758 \uC694\uCCAD\uC774 \uC811\uC218\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_form_labels_confirmEmail3 = "\uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uD655\uC778\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_onRequest_form_labels_email3 = "\uC774\uBA54\uC77C \uC8FC\uC18C";
var APPB2C_cj_onRequest_form_labels_firstname3 = "\uC774\uB984(\uBBF8\uB4E4\uB124\uC784 \uD3EC\uD568)";
var APPB2C_cj_onRequest_form_labels_surname3 = "\uC131";
var APPB2C_cj_onRequest_form_labels_telephone3 = "\uD734\uB300\uD3F0 \uBC88\uD638";
var APPB2C_cj_onRequest_form_labels_testRangeQuestion3 = "\uC5BC\uB9C8\uB098 \uBE60\uB978 \uC2DC\uC77C \uB0B4\uC5D0 \uC2DC\uD5D8\uC744 \uCE58\uB974\uACE0 \uC2F6\uC73C\uC2ED\uB2C8\uAE4C?";
var APPB2C_cj_onRequest_form_submit3 = "\uAD00\uC2EC\uC744 \uB4F1\uB85D\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_onRequest_form_testRangeOpt_oneMonth3 = "\uD5A5\uD6C4 28\uC77C \uC774\uB0B4";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonths3 = "\uD5A5\uD6C4 3\uAC1C\uC6D4 \uC774\uB0B4";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus3 = "3\uAC1C\uC6D4 \uACBD\uACFC \uC774\uD6C4";
var APPB2C_cj_onRequest_form_testRangeOpt_twoMonths3 = "2\uAC1C\uC6D4 \uC774\uB0B4";
var APPB2C_cj_onRequest_info13 = "IELTS for UKVI \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uADC0\uD558\uC758 \uAD00\uC2EC\uC744 \uB4F1\uB85D\uD558\uAE30 \uC704\uD574 \uC591\uC2DD\uC744 \uC791\uC131\uD558\uC2ED\uC2DC\uC624. \uADC0\uD558\uAC00 \uC81C\uACF5\uD55C \uC815\uBCF4\uB97C \uAC80\uD1A0\uD558\uACE0 \uD6C4\uC18D \uC870\uCE58\uB97C \uC704\uD574 \uC5F0\uB77D\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_note3 = "\uD604\uC7AC \uC774 \uC9C0\uC5ED\uC5D0\uC11C \uAC00\uB2A5\uD55C IELTS for UKVI \uC2DC\uD5D8 \uB0A0\uC9DC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_otherLocations_choose3 = "\uB2E4\uB978 \uC9C0\uC5ED \uC120\uD0DD";
var APPB2C_cj_onRequest_otherLocations_hide3 = "- \uC228\uAE30\uAE30";
var APPB2C_cj_onRequest_otherLocations_info13 = "\uC2DC\uD5D8\uC77C \uC608\uC57D\uC5D0\uB294 \uB2E4\uC74C \uB450 \uAC00\uC9C0 \uC635\uC158\uC774 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_otherLocations_info23 = "IELTS for UKVI \uC751\uC2DC \uAC00\uB2A5 \uC9C0\uC5ED\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_otherLocations_show3 = "+ \uB354 \uBCF4\uAE30";
var APPB2C_cj_onRequest_please3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_cj_onRequest_prefNote3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC120\uD638\uB3C4\uB97C \uC218\uC6A9\uD558\uAE30 \uC704\uD574 \uCD5C\uC120\uC744 \uB2E4\uD560 \uAC83\uC785\uB2C8\uB2E4. \uC6D0\uD558\uB294 \uC635\uC158\uC774 \uBD88\uAC00\uD55C \uACBD\uC6B0 \uB2E4\uB978 \uC635\uC158\uC774 \uC81C\uACF5\uB429\uB2C8\uB2E4.";
var APPB2C_cj_onRequest_subtitles_aboutPreferences3 = "\uC2DC\uD5D8 \uAE30\uBCF8 \uC124\uC815";
var APPB2C_cj_onRequest_subtitles_aboutYou3 = "\uADC0\uD558\uC5D0 \uB300\uD558\uC5EC";
var APPB2C_cj_onRequest_subtitles_markpref3 = "\uAC1C\uC778\uC815\uBCF4 \uB9C8\uCF00\uD305 \uC774\uC6A9 \uB3D9\uC758";
var APPB2C_cj_onRequest_subtitles_register3 = "\uC2DC\uD5D8 \uC751\uC2DC\uC5D0 \uB300\uD55C \uADC0\uD558\uC758 \uAD00\uC2EC\uC744 \uB4F1\uB85D\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_doubleBooking3 = "\uC751\uC2DC\uC790\uB294 \uC774\uBBF8 \uAC19\uC740 \uB0A0\uC9DC\uC5D0 IELTS \uC2DC\uD5D8\uC5D0 \uB4F1\uB85D\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_error_missingAddress3 = "\uC6B0\uD3B8 \uC8FC\uC18C\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingEmail3 = "\uC720\uD6A8\uD55C \uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingEmailConfirm3 = "\uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uB2E4\uC2DC \uC785\uB825\uD558\uC5EC \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingFirstName3 = "\uC774\uB984/\uC774\uB984\uC744 \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingMobile3 = "\uD734\uB300\uC804\uD654 \uBC88\uD638\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingSex3 = "\uC131\uBCC4\uC744 \uC54C\uB824\uC8FC\uC138\uC694.";
var APPB2C_cj_personalDetails_error_missingState3 = "\uADC0\uD558\uC758 \uC8FC\uB97C \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingSurname3 = "\uC131/\uC131\uC744 \uC785\uB825\uD574 \uC8FC\uC138\uC694. \uC131\uC774 \uC5C6\uB294 \uACBD\uC6B0 \uC774 \uD544\uB4DC\uC5D0 \uD558\uC774\uD508('-')\uC744 \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingTown3 = "\uADC0\uD558\uC758 \uB9C8\uC744 / \uB3C4\uC2DC\uB97C \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_missingZip3 = "\uC6B0\uD3B8 \uBC88\uD638 / ZIP\uC744 \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_error_wrongDob3 = "\uC2E4\uC81C \uC0DD\uB144\uC6D4\uC77C\uC744 \uC785\uB825\uD574 \uC8FC\uC138\uC694.";
var APPB2C_cj_personalDetails_error_wrongEmailConfirm3 = "\uB2E4\uC2DC \uC785\uB825\uD55C \uC774\uBA54\uC77C \uC8FC\uC18C\uAC00 \uC785\uB825\uD55C \uC774\uBA54\uC77C \uC8FC\uC18C\uC640 \uC77C\uCE58\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uCCA0\uC790\uB97C \uAF3C\uAF3C\uD788 \uD655\uC778\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_examTakenModal_cta3 = "\uC0C8 \uC2DC\uD5D8 \uCC3E\uAE30";
var APPB2C_cj_personalDetails_examTakenModal_message3 = "\uD558\uC9C0\uB9CC \uAD1C\uCC2E\uC2B5\uB2C8\uB2E4. \uC544\uC9C1 \uC120\uD0DD\uD560 \uC218 \uC788\uB294 \uC2DC\uD5D8\uC774 \uB9CE\uC774 \uC788\uC73C\uBA70 \uADC0\uD558\uC758 \uC815\uBCF4\uB3C4 \uC800\uC7A5\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_examTakenModal_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC120\uD0DD\uD558\uC2E0 \uC2DC\uD5D8\uC740 \uB354 \uC774\uC0C1 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse3 = "\uB300\uC2E0 \uC608\uC57D\uD558\uAE30";
var APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate3 = "\uB2E4\uB978 \uC2DC\uD5D8\uC77C \uC120\uD0DD";
var APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle3 = "\uB2E4\uC74C \uB0A0\uC9DC\uC5D0 \uADC0\uD558\uAC00 \uC608\uC57D\uD558\uC2E0 \uC2DC\uD5D8\uC744 \uC78A\uC9C0 \uB9C8\uC2ED\uC2DC\uC624:";
var APPB2C_cj_personalDetails_existingRegistrationsModal_title3 = "\uD574\uB2F9 \uB0A0\uC9DC\uC5D0 \uC774\uBBF8 \uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking3 = "\uC751\uC2DC\uC790 \uD3EC\uD138\uC5D0\uC11C \uC2DC\uD5D8 \uC608\uC57D \uB0B4\uC5ED\uC744 \uC870\uD68C\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking3 = "\uC2DC\uD5D8 \uC608\uC57D \uB0B4\uC5ED";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest3 = "\uB098\uC758 \uC2DC\uD5D8";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest3 = "\uC4F0\uAE30 \uC2DC\uD5D8";
var APPB2C_cj_personalDetails_forms_address_country3 = "\uAD6D\uAC00";
var APPB2C_cj_personalDetails_forms_address_enterManually3 = "\uC8FC\uC18C \uC9C1\uC811 \uC785\uB825";
var APPB2C_cj_personalDetails_forms_address_postal3 = "\uC8FC\uC18C";
var APPB2C_cj_personalDetails_forms_address_state3 = "\uB3C4";
var APPB2C_cj_personalDetails_forms_address_town3 = "\uC2DC/\uAD70/\uAD6C";
var APPB2C_cj_personalDetails_forms_address_zip3 = "\uC6B0\uD3B8\uBC88\uD638";
var APPB2C_cj_personalDetails_forms_contact_confirmEmail3 = "\uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uD655\uC778\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_personalDetails_forms_contact_email3 = "\uC774\uBA54\uC77C \uC8FC\uC18C";
var APPB2C_cj_personalDetails_forms_contact_emailExist3 = "\uC774\uBBF8 \uB4F1\uB85D\uB41C \uC774\uBA54\uC77C \uC8FC\uC18C\uC785\uB2C8\uB2E4. \uBCF8\uC778\uC758 \uC774\uBA54\uC77C\uC774 \uB9DE\uB2E4\uBA74 \uB85C\uADF8\uC778 \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_forms_contact_emailInUse3 = "\uC774\uBBF8 \uB4F1\uB85D\uB41C \uC774\uBA54\uC77C\uC785\uB2C8\uB2E4. \uBCF8\uC778\uC758 \uC774\uBA54\uC77C\uC774 \uB9DE\uB2E4\uBA74 <0>\uB85C\uADF8\uC778</0> \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_forms_contact_mobile3 = "\uD734\uB300\uD3F0 \uBC88\uD638";
var APPB2C_cj_personalDetails_forms_contact_note3 = "\uC5F0\uB77D \uBC0F \uBB38\uC11C(\uC131\uC801\uD45C \uB4F1) \uBC1C\uC1A1\uC744 \uC704\uD574 \uC804\uD654\uBC88\uD638\uC640 \uC8FC\uC18C\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_forms_contact_password3 = "\uBE44\uBC00\uBC88\uD638";
var APPB2C_cj_personalDetails_forms_contact_smsConsent3 = "SMS, WhatsApp \uB4F1\uC744 \uD1B5\uD574 \uC774 \uC804\uD654\uBC88\uD638\uB85C \uC2DC\uD5D8 \uB4F1\uB85D\uACFC \uAD00\uB828\uB41C \uC54C\uB9BC\uC774\uB098 \uC5F0\uB77D\uC744 \uBC1B\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_forms_contact_smsNote3 = "<bold>\uCC38\uACE0:</bold> \uC774 \uC11C\uBE44\uC2A4\uB294 \uADC0\uD558\uC758 \uC9C0\uC5ED\uC5D0\uC11C \uC81C\uACF5\uB418\uC9C0 \uC54A\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_forms_contact_title3 = "\uC5F0\uB77D\uCC98";
var APPB2C_cj_personalDetails_forms_personal_change3 = "\uC218\uC815\uD558\uAE30";
var APPB2C_cj_personalDetails_forms_personal_dob3 = "\uC0DD\uB144\uC6D4\uC77C";
var APPB2C_cj_personalDetails_forms_personal_dobCta3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS \uC608\uC57D\uD558\uAE30";
var APPB2C_cj_personalDetails_forms_personal_firstMiddleName3 = "\uC774\uB984(\uBBF8\uB4E4\uB124\uC784 \uD3EC\uD568)";
var APPB2C_cj_personalDetails_forms_personal_firstName3 = "\uC774\uB984";
var APPB2C_cj_personalDetails_forms_personal_idMatch3 = "\uC2E0\uBD84\uC99D\uC758 \uC774\uB984\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_cj_personalDetails_forms_personal_lastName3 = "\uC131";
var APPB2C_cj_personalDetails_forms_personal_name3 = "\uC774\uB984";
var APPB2C_cj_personalDetails_forms_personal_noLastName3 = "\uC131\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_forms_personal_sectionTitle3 = "\uADC0\uD558\uC5D0 \uB300\uD558\uC5EC";
var APPB2C_cj_personalDetails_forWho_child3 = "\uC790\uB140";
var APPB2C_cj_personalDetails_forWho_childUnder183 = "(18\uC138 \uBBF8\uB9CC)";
var APPB2C_cj_personalDetails_forWho_dob3 = "\uC0DD\uB144\uC6D4\uC77C:";
var APPB2C_cj_personalDetails_forWho_myself3 = "\uBCF8\uC778";
var APPB2C_cj_personalDetails_forWho_note3 = "11\uC138 \uBBF8\uB9CC\uC758 \uC544\uB3D9 \uB300\uC2E0 \uC2DC\uD5D8\uC744 \uC608\uC57D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC790\uB140\uAC00 18\uC138 \uC774\uC0C1\uC778 \uACBD\uC6B0 \uC2A4\uC2A4\uB85C \uC608\uC57D\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_forWho_title3 = "\uB204\uAD6C\uB97C \uB300\uC2E0\uD558\uC5EC \uC2DC\uD5D8\uC744 \uC608\uC57D\uD569\uB2C8\uAE4C?";
var APPB2C_cj_personalDetails_gender3 = "\uC131\uBCC4:";
var APPB2C_cj_personalDetails_idNote_idMatch3 = "\uC774 \uD398\uC774\uC9C0\uC5D0 \uC81C\uACF5\uB41C \uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4\uB294 \uB4F1\uB85D\uC5D0 \uC0AC\uC6A9\uB41C \uC2DC\uD5D8 \uC751\uC2DC\uC790\uC758 \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD574\uC57C \uD558\uBA70 \uC2DC\uD5D8 \uC7A5\uC18C\uC5D0 \uC81C\uC2DC\uD574\uC57C \uD558\uBA70, \uC774\uB294 \uC2DC\uD5D8 \uC751\uC2DC\uC790\uC758 \uC2DC\uD5D8 \uBCF4\uACE0\uC11C \uC591\uC2DD(TRF)\uC5D0 \uD45C\uC2DC\uB429\uB2C8\uB2E4. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB418\uBA74 TRF\uB97C \uBC1C\uD589\uD558\uAE30 \uC804\uC5D0 \uC774 \uC815\uBCF4\uB97C \uC218\uC815\uD558\uB3C4\uB85D \uC694\uCCAD\uD558\uB294 \uACBD\uC6B0 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. TRF\uAC00 \uBC1C\uD589\uB41C \uD6C4\uC5D0\uB294 \uC218\uC815\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_idMatch_change3 = "\uC131\uBA85\uACFC \uC0DD\uB144\uC6D4\uC77C\uC740 \uC2DC\uD5D8 \uB2F9\uC77C\uC5D0 \uC81C\uC2DC\uD560 \uC2E0\uBD84\uC99D\uACFC <bold>\uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4</bold>.";
var APPB2C_cj_personalDetails_idNote_idMatch_france2 = "\uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4\uB294 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF) \uC5D0 \uD45C\uC2DC\uB418\uBBC0\uB85C \uB4F1\uB85D\uC5D0 \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB418\uBA74 \uAC1C\uC778 \uC815\uBCF4 \uBCC0\uACBD \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uACB0\uACFC\uAC00 \uBC1C\uD45C\uB41C \uD6C4\uC5D0\uB294 \uAC1C\uC778 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_idMatch_france_adult2 = "\uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC740 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF) \uC5D0 \uD45C\uC2DC\uB418\uBBC0\uB85C \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC774 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB41C \uC774\uD6C4 \uAC1C\uC778 \uC815\uBCF4 \uBCC0\uACBD\uC744 \uD560 \uACBD\uC6B0 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uACB0\uACFC\uAC00 \uBC1C\uD45C\uB41C \uD6C4\uC5D0\uB294 \uAC1C\uC778 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_idMatch_france_minor2 = "\uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC740 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF) \uC5D0 \uD45C\uC2DC\uB418\uBBC0\uB85C \uBBF8\uC131\uB144\uC790\uC758 \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC774 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB41C \uC774\uD6C4 \uAC1C\uC778 \uC815\uBCF4 \uBCC0\uACBD\uC744 \uD560 \uACBD\uC6B0 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uACB0\uACFC\uAC00 \uBC1C\uD45C\uB41C \uD6C4\uC5D0\uB294 \uAC1C\uC778 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_idMatch_spain2 = "\uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4\uB294 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF) \uC5D0 \uD45C\uC2DC\uB418\uBBC0\uB85C \uB4F1\uB85D\uC5D0 \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB418\uBA74 \uAC1C\uC778 \uC815\uBCF4 \uBCC0\uACBD \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uACB0\uACFC\uAC00 \uBC1C\uD45C\uB41C \uD6C4\uC5D0\uB294 \uAC1C\uC778 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_adult2 = "\uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC740 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF) \uC5D0 \uD45C\uC2DC\uB418\uBBC0\uB85C \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC774 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB41C \uC774\uD6C4\uC5D0\uB294 \uAC1C\uC778 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_minor2 = "\uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC740 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF) \uC5D0 \uD45C\uC2DC\uB418\uBBC0\uB85C \uBBF8\uC131\uB144\uC790\uC758 \uAC1C\uC778 \uC815\uBCF4 \uB0B4\uC5ED\uC774 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB41C \uC774\uD6C4\uC5D0\uB294 \uAC1C\uC778 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_idNote_whenForChild3 = "\uB2E4\uC74C \uD398\uC774\uC9C0\uC5D0\uC11C \uADC0\uD558\uC758 \uC790\uB140\uC5D0 \uB300\uD574 \uC9C8\uBB38\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_personalDetails_login_btn3 = "\uB85C\uADF8\uC778";
var APPB2C_cj_personalDetails_login_emailNotEditable3 = "\uACC4\uC815\uC744 \uB9CC\uB4E0 \uD6C4\uC5D0\uB294 \uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uC218\uC815\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC774\uB97C \uBCC0\uACBD\uD558\uB824\uBA74 <1>\uBB38\uC758\uD558\uAE30</1>";
var APPB2C_cj_personalDetails_login_link3 = "\uC774\uBBF8 \uACC4\uC815\uC774 \uC788\uC73C\uC2E0\uAC00\uC694? <1>\uB85C\uADF8\uC778</1>";
var APPB2C_cj_personalDetails_login_loggedIn3 = "\uD658\uC601\uD569\uB2C8\uB2E4! \uB85C\uADF8\uC778\uB418\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_login_logout3 = "\uC138\uBD80 \uC815\uBCF4\uAC00 \uC544\uB2D9\uB2C8\uAE4C? <1>\uB85C\uADF8\uC544\uC6C3</1>";
var APPB2C_cj_personalDetails_login_note3 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uB824\uBA74 \uACC4\uC815\uC774 \uD544\uC694\uD569\uB2C8\uB2E4. \uC608\uC57D\uC774 \uD655\uC815\uB418\uBA74 \uC628\uB77C\uC778\uC73C\uB85C \uC608\uC57D \uB0B4\uC5ED\uC744 \uC870\uD68C\uD558\uB294 \uBC29\uBC95\uC744 \uC774\uBA54\uC77C\uB85C \uC548\uB0B4\uD574\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_login_title3 = "\uACC4\uC815 \uC0DD\uC131";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate3 = "\uC0C8 \uC2DC\uD5D8 \uCC3E\uAE30";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line13 = "\uADC0\uD558\uC758 {{nearestRegistratonExamDate}} \uC2DC\uD5D8 \uB0A0\uC9DC\uACFC \uB108\uBB34 \uAC00\uAE4C\uC6B4 \uB0A0\uC9DC\uC5D0 \uCE58\uB904\uC9C8 IELTS \uC628\uB77C\uC778 \uC2DC\uD5D8\uC744 \uC608\uC57D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line23 = "\uC774\uC804 \uC2DC\uD5D8 5\uC77C \uC774\uC0C1 \uC774\uC804\uC774\uAC70\uB098 \uC774\uD6C4\uC5D0 \uC2DC\uD5D8 \uB0A0\uC9DC\uB97C \uCC3E\uC73C\uC2ED\uC2DC\uC624.";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title3 = "\uB2E4\uB978 \uC2DC\uD5D8\uC77C\uC744 \uC120\uD0DD\uD558\uC138\uC694";
var APPB2C_cj_personalDetails_title3 = "\uAC1C\uC778\uC815\uBCF4";
var APPB2C_cj_personalDetails_tncs_agreement3 = "\uBCF8\uC778\uC740 \uACC4\uC815 \uB4F1\uB85D <1>\uC774\uC6A9 \uADDC\uC815</1>\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4.";
var APPB2C_cj_personalDetails_tncs_title3 = "\uC774\uC6A9 \uADDC\uC815";
var APPB2C_cj_personalDetails_visaType_label3 = "\uC5B4\uB5A4 \uC885\uB958\uC758 \uBE44\uC790\uAC00 \uD544\uC694\uD55C\uAC00\uC694?";
var APPB2C_cj_personalDetails_visaType_label_child3 = "\uC5B4\uB5A4 \uC885\uB958\uC758 \uBE44\uC790\uAC00 \uD544\uC694\uD55C\uAC00\uC694?";
var APPB2C_cj_personalDetails_visaType_link3 = "\uBE44\uC790 \uC720\uD615\uC5D0 \uB300\uD574 \uC790\uC138\uD788 \uC54C\uC544\uBCF4\uAE30";
var APPB2C_cj_personalDetails_visaType_title3 = "\uBE44\uC790 \uC720\uD615";
var APPB2C_cj_personalDetails_visaType_title_child3 = "\uBE44\uC790 \uC885\uB958";
var APPB2C_cj_rebook_errorModal_message3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uBA87 \uBD84 \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_rebook_errorModal_title3 = "\uC608\uC57D \uC2E4\uD328";
var APPB2C_cj_review_acknowledgement_anotherPerson3 = "\uB2E4\uB978 \uC0AC\uB78C";
var APPB2C_cj_review_acknowledgement_company3 = "\uD68C\uC0AC";
var APPB2C_cj_review_acknowledgement_fields_companyName3 = "\uD68C\uC0AC\uBA85";
var APPB2C_cj_review_acknowledgement_fields_companyName_subnote3 = "\uD68C\uC0AC \uC774\uB984\uC758 \uC6D0\uB798 \uCCA0\uC790 \uC0AC\uC6A9";
var APPB2C_cj_review_acknowledgement_fields_country3 = "\uAD6D\uAC00";
var APPB2C_cj_review_acknowledgement_fields_email3 = "\uC774\uBA54\uC77C";
var APPB2C_cj_review_acknowledgement_fields_familyName3 = "\uC131";
var APPB2C_cj_review_acknowledgement_fields_firstName3 = "\uAE30\uD0C0 / \uC774\uB984";
var APPB2C_cj_review_acknowledgement_fields_mobile3 = "\uC804\uD654\uBC88\uD638";
var APPB2C_cj_review_acknowledgement_myself3 = "\uBCF8\uC778";
var APPB2C_cj_review_acknowledgement_send3 = "\uC608\uC57D\uD558\uC2E0 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC8FC\uBB38\uD655\uC815\uC11C\uB97C \uC218\uB839\uD560 \uC0AC\uB78C\uC740 \uB204\uAD6C\uC785\uB2C8\uAE4C?";
var APPB2C_cj_review_acknowledgement_title3 = "\uC8FC\uBB38\uD655\uC778";
var APPB2C_cj_review_agent_label3 = "\uB300\uD589\uC5C5\uCCB4\uBA85\uC744 \uC785\uB825\uD558\uC2ED\uC2DC\uC624";
var APPB2C_cj_review_agent_note3 = "\uADC0\uD558\uC758 UKVI \uC2E0\uCCAD\uC744 \uB300\uD589\uD558\uB294 \uC5C5\uCCB4\uAC00 \uC788\uC2B5\uB2C8\uAE4C?";
var APPB2C_cj_review_agent_title3 = "\uB300\uD589\uC5C5\uCCB4\uBA85";
var APPB2C_cj_review_ai_note3 = "\uADC0\uD558\uC758 \uC2DC\uD5D8 \uB2F5\uC548 \uBC0F \uAE30\uB85D\uC774 \uC775\uBA85\uC758 \uD615\uD0DC\uB85C \uC6D0\uACA9 \uAC10\uB3C5 \uBC0F \uC2DC\uD5D8 \uCC44\uC810 \uC18C\uD504\uD2B8\uC6E8\uC5B4\uC758 \uD2B8\uB808\uC774\uB2DD\uC5D0 \uC0AC\uC6A9\uB418\uB294 \uAC83\uC5D0 \uB3D9\uC758\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_cj_review_ai_title3 = "\uC815\uBCF4 \uC774\uC6A9 \uB3D9\uC758";
var APPB2C_cj_review_bookingDetails_lrw_title3 = "\uD544\uAE30 \uC2DC\uD5D8";
var APPB2C_cj_review_bookingDetails_lrw_title_child3 = "\uC790\uB140\uC758 \uD544\uAE30 \uC2DC\uD5D8";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills3 = "\uBCF8\uC778\uC758 \uC2DC\uD5D8";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child3 = "\uC790\uB140\uC758 \uC2DC\uD5D8";
var APPB2C_cj_review_bookingDetails_speaking_title3 = "\uBCF8\uC778\uC758 \uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_cj_review_bookingDetails_speaking_title_child3 = "\uC790\uB140\uC758 \uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_cj_review_bookingDetails_title3 = "\uADC0\uD558\uB294 \uB2E4\uC74C\uC744 \uC608\uC57D \uC911\uC785\uB2C8\uB2E4";
var APPB2C_cj_review_bookNowNoFee_buttonText3 = "\uC9C0\uAE08 \uC608\uC57D";
var APPB2C_cj_review_bookTest3 = "\uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_cj_review_change3 = "\uC218\uC815\uD558\uAE30";
var APPB2C_cj_review_changeLrwTest3 = "\uD544\uAE30\uC2DC\uD5D8 \uB0A0\uC9DC \uBCC0\uACBD";
var APPB2C_cj_review_changeSpeakingTest3 = "\uC5F0\uC124 \uC77C\uC815 \uBCC0\uACBD";
var APPB2C_cj_review_changeUkviLsTest3 = "\uC2DC\uD5D8 \uB0A0\uC9DC \uBCC0\uACBD";
var APPB2C_cj_review_childDetails_guardiansTitle3 = "\uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uC624\uB294 \uC0AC\uB78C";
var APPB2C_cj_review_childDetails_onTestDay3 = "\uC2DC\uD5D8 \uB2F9\uC77C";
var APPB2C_cj_review_childDetails_onTestDayOptions_13 = "\uC790\uB140\uAC00 \uC2A4\uC2A4\uB85C \uACE0\uC0AC\uC7A5\uC744 \uB098\uAC08 \uC218 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_childDetails_onTestDayOptions_23 = "\uC81C\uAC00 \uACE0\uC0AC\uC7A5\uC5D0 \uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uAC11\uB2C8\uB2E4";
var APPB2C_cj_review_childDetails_onTestDayOptions_33 = "\uB2E4\uB978 \uC0AC\uB78C\uC774 \uACE0\uC0AC\uC7A5\uC5D0 \uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uAC11\uB2C8\uB2E4";
var APPB2C_cj_review_childDetails_title3 = "\uC790\uB140 \uC815\uBCF4";
var APPB2C_cj_review_confirmPaymentModal_btnNote3 = "\uB098\uC5F4\uB41C <0></0>\uBCF4\uB2E4 \uB354 \uB9CE\uC740 \uBE44\uC6A9\uC744 \uC9C0\uBD88\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_confirmPaymentModal_note3 = "\uCC38\uACE0: IELTS \uC2DC\uD5D8\uC5D0 \uB300\uD574 \uC628\uB77C\uC778 \uACB0\uC81C\uC744 \uD55C\uB2E4\uBA74 \uC740\uD589\uC774 \uCD94\uAC00 \uC694\uAE08\uC744 \uBD80\uACFC\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_review_confirmPaymentModal_optionsTitle3 = "\uACB0\uC81C \uBC29\uBC95\uC740 \uB450 \uAC00\uC9C0\uC785\uB2C8\uB2E4.";
var APPB2C_cj_review_confirmPaymentModal_title3 = "\uC628\uB77C\uC778 \uACB0\uC81C \uC2DC \uCD94\uAC00 \uBE44\uC6A9\uC774 \uBC1C\uC0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_countryMsg_pakistan3 = "IELTS \uC751\uC2DC\uB8CC \uC2E0\uC6A9\uCE74\uB4DC \uACB0\uC81C \uC2DC \uD658\uC728 \uBCC0\uB3D9\uC73C\uB85C \uC778\uD574 \uD45C\uC2DC\uB41C \uAE08\uC561\uACFC \uACB0\uC81C\uB41C \uAE08\uC561\uC774 \uB2E4\uB97C \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uC628\uB77C\uC778 \uB610\uB294 \uAD6D\uC81C \uAC00\uB9F9\uC810\uC5D0\uC11C \uD30C\uD0A4\uC2A4\uD0C4 \uB8E8\uD53C\uD654\uB85C \uC774\uB8E8\uC5B4\uC9C0\uB294 \uC2E0\uC6A9\uCE74\uB4DC \uAC70\uB798\uB294 \uAC00\uB9F9\uC810\uC774 \uC678\uD654\uB85C \uACB0\uC81C\uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_error_missingOrderAcknowledgement3 = "\uD14C\uC2A4\uD2B8 \uC608\uC57D\uC5D0 \uB300\uD55C \uC8FC\uBB38 \uD655\uC778\uC11C\uB97C \uBC1B\uC744 \uC0AC\uB78C\uC744 \uC9C0\uC815\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_error_missingPickups3 = "\uC2DC\uD5D8\uC7A5\uC5D0\uC11C \uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uC62C \uC0AC\uB78C\uC744 \uC9C0\uC815\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_error_missingTown3 = "\uC138\uBD80 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD558\uACE0 \uC8FC\uC18C\uC5D0 \uB9C8\uC744 / \uB3C4\uC2DC\uB97C \uD3EC\uD568\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_error_wrongCountrySetting3 = "\uADC0\uD558\uC758 \uAD6D\uAC00\uC5D0\uC11C\uB294 \uC774 \uD14C\uC2A4\uD2B8\uB97C \uC608\uC57D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB2E4\uB978 \uD14C\uC2A4\uD2B8\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_error_wrongPaymentConfig3 = "\uACB0\uC81C \uAD6C\uC131\uC774 \uB204\uB77D\uB418\uC5C8\uAC70\uB098 \uC798\uBABB\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uC774 \uBB38\uC81C\uAC00 \uC9C0\uC18D\uB418\uBA74 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_error_wrongTaxNumber3 = "\uC720\uD6A8\uD55C VAT/NIP \uBC88\uD638\uB97C \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624. \uD2B9\uC815 \uD615\uC2DD(\uC608: 13243546-3-35, 1324354635)\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_expiredIdError3 = "\uC751\uC2DC\uC790\uC758 \uC2E0\uBD84\uC99D\uC740 \uC120\uD0DD\uD558\uC2E0 \uC2DC\uD5D8\uC77C\uAE4C\uC9C0 \uC720\uD6A8\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_idDetails_issuing3 = "\uBC1C\uAE09\uAE30\uAD00";
var APPB2C_cj_review_idDetails_nationality3 = "\uAD6D\uC801";
var APPB2C_cj_review_idDetails_title3 = "\uADC0\uD558\uC758 \uC2E0\uBD84\uC99D";
var APPB2C_cj_review_idDetails_title_child3 = "\uC790\uB140\uC758 \uC2E0\uBD84\uC99D";
var APPB2C_cj_review_invoice_companyName3 = "\uC1A1\uC7A5 \uD56D\uBAA9(Invoice Header)";
var APPB2C_cj_review_invoice_consent3 = "\uBCF4\uB2E4 \uC2E0\uC18D\uD55C \uD658\uBD88\uC744 \uC704\uD574 \uB300\uB9CC\uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uAD6C\uB9E4\uD55C \uD488\uBAA9\uC758 \uD658\uBD88\uC744 \uC704\uD55C \uC1A1\uC7A5 \uBC0F \uBC18\uD488 \uC99D\uBE59 \uC790\uB8CC\uB97C \uCC98\uB9AC\uD558\uB294 \uB370 \uB3D9\uC758\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_cj_review_invoice_send3 = "\uC6D0\uD558\uC2DC\uB294 \uC1A1\uC7A5 \uC720\uD615\uC740 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate3 = "\uC790\uC5F0\uC778 \uC99D\uBA85\uC11C(Citizen Digital Certificate)(\uC5ED\uC790\uC8FC: \uB300\uB9CC\uC758 \uC8FC\uBBFC\uB4F1\uB85D\uC99D\uC5D0 \uC900\uD558\uB294 \uAC83\uC73C\uB85C \uC870\uC0AC\uB428)";
var APPB2C_cj_review_invoice_subtype_label3 = "\uC6D0\uD558\uC2DC\uB294 \uC804\uC1A1 \uC218\uB2E8\uC740 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_review_invoice_subtype_mobileBarcode3 = "\uBAA8\uBC14\uC77C \uBC14\uCF54\uB4DC";
var APPB2C_cj_review_invoice_taxCaption_donate3 = "\uC790\uC120\uB2E8\uCCB4 \uBC88\uD638";
var APPB2C_cj_review_invoice_taxCaption_duplicatePaper3 = "\uC1A1\uC7A5 \uBC88\uD638";
var APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate3 = "\uC790\uC5F0\uC778 \uC99D\uBA85\uC11C\uB294 16\uC790\uC758 \uC601\uBB38+\uC22B\uC790 \uD615\uC2DD(\uC608: 1122AABB3344CCDD)\uC744 \uC900\uC218\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_invoice_taxValidation_donate3 = "\uC790\uC120\uB2E8\uCCB4 \uBC88\uD638\uB294 3~7\uC790\uB9AC \uC22B\uC790 \uD615\uC2DD(\uC608: 123)\uC744 \uC900\uC218\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_invoice_taxValidation_mobileBarcode3 = "\uBAA8\uBC14\uC77C \uBC14\uCF54\uB4DC\uB294 \uC62C\uBC14\uB978 \uD615\uC2DD(\uC608: /123-ABC)\uC744 \uC900\uC218\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_invoice_taxValidation_triplicate3 = "\uC1A1\uC7A5 \uBC88\uD638\uB294 8\uC790\uB9AC \uD615\uC2DD(\uC608: 11223344)\uC744 \uC900\uC218\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_invoice_title3 = "\uC1A1\uC7A5 \uC815\uBCF4(\uC5ED\uC790\uC8FC: \uC544\uB798 \uB0B4\uC6A9\uC740 \uB300\uB9CC\uC758 \uC815\uBD80 \uADDC\uACA9 \uC1A1\uC7A5\uC744 \uC758\uBBF8\uD558\uB294 \uAC83\uC73C\uB85C \uC870\uC0AC\uB428)";
var APPB2C_cj_review_invoice_type_donate3 = "\uAE30\uBD80 \uC804\uC790 \uC1A1\uC7A5(Donate Electronic Invoice)";
var APPB2C_cj_review_invoice_type_duplicateElectronic3 = "\uC774\uC911 \uD1B5\uC77C \uC804\uC790 \uC1A1\uC7A5(Duplicate Uniform Electronic Invoice)";
var APPB2C_cj_review_invoice_type_duplicatePaper3 = "\uC774\uC911 \uD1B5\uC77C \uC885\uC774 \uC1A1\uC7A5(Duplicate Uniform Paper Invoice)";
var APPB2C_cj_review_invoice_type_triplicate3 = "\uC0BC\uC911 \uD1B5\uC77C \uC885\uC774 \uC1A1\uC7A5(Triplicate Uniform Paper Invoice)";
var APPB2C_cj_review_localization_localTime3 = "\uD604\uC9C0 \uC2DC\uAC04";
var APPB2C_cj_review_localization_location3 = "\uADC0\uD558\uC758 \uC704\uCE58\uB294 \uB2E4\uC74C\uC73C\uB85C \uD30C\uC545\uB429\uB2C8\uB2E4:";
var APPB2C_cj_review_localization_timezone3 = "\uC2DC\uAC04\uB300";
var APPB2C_cj_review_missingMarketingCta3 = "\uB2F5\uBCC0 \uD655\uC778";
var APPB2C_cj_review_missingMarketingError3 = "\uC774\uC804\uC5D0 \uC2DC\uD5D8 \uC608\uC57D\uC744 \uC2DC\uB3C4\uD558\uC2E0 \uAE30\uB85D\uC774 \uC788\uC2B5\uB2C8\uB2E4. \uBA87 \uAC00\uC9C0 \uB2F5\uBCC0\uB9CC \uD655\uC778\uD558\uC2DC\uBA74 \uB429\uB2C8\uB2E4.";
var APPB2C_cj_review_note_content3 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uAE30 \uC804\uC5D0 \uBAA8\uB4E0 \uC815\uBCF4\uAC00 \uC62C\uBC14\uB978\uC9C0 \uB2E4\uC2DC \uD55C \uBC88 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_note_title3 = "\uC544\uC9C1 \uC608\uC57D\uC744 \uD558\uC9C0 \uC54A\uC73C\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_review_or3 = "\uB610\uB294";
var APPB2C_cj_review_payLater_buttonText3 = "\uC624\uD504\uB77C\uC778 \uACB0\uC81C";
var APPB2C_cj_review_payLater_sideNote3 = "\uACB0\uC81C \uC635\uC158\uC740 \uB2E4\uC74C \uD398\uC774\uC9C0\uC5D0 \uD45C\uC2DC\uB429\uB2C8\uB2E4";
var APPB2C_cj_review_payment_fee3 = "\uC218\uC218\uB8CC";
var APPB2C_cj_review_payment_promoCodes_applied3 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC \uC801\uC6A9\uB428";
var APPB2C_cj_review_payment_promoCodes_applyCode3 = "\uCF54\uB4DC \uC801\uC6A9";
var APPB2C_cj_review_payment_promoCodes_discount3 = "\uD504\uB85C\uBAA8\uC158";
var APPB2C_cj_review_payment_promoCodes_generalError3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_payment_promoCodes_haveCode3 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC\uB97C \uAC00\uC9C0\uACE0 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_payment_promoCodes_invalidCode3 = "\uC785\uB825\uD558\uC2E0 \uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC\uAC00 \uC720\uD6A8\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_payment_promoCodes_newFee3 = "\uC0C8 \uC218\uC218\uB8CC";
var APPB2C_cj_review_payment_promoCodes_notHaveCode3 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC\uB97C \uAC00\uC9C0\uACE0 \uC788\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_payment_promoCodes_promo3 = "\uD504\uB85C\uBAA8\uC158";
var APPB2C_cj_review_payment_promoCodes_promoCode3 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC";
var APPB2C_cj_review_payment_promoCodes_remove3 = "\uC0AD\uC81C";
var APPB2C_cj_review_payment_promoCodes_removeCode3 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC \uC0AD\uC81C";
var APPB2C_cj_review_payment_promoCodes_total3 = "\uD569\uACC4";
var APPB2C_cj_review_payment_tax3 = "\uC138\uAE08";
var APPB2C_cj_review_payment_title3 = "\uACB0\uC81C";
var APPB2C_cj_review_payment_total3 = "\uD569\uACC4";
var APPB2C_cj_review_paymentError3 = "\uACB0\uC81C\uB97C \uCC98\uB9AC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_paymentNote3 = "\uC608\uC57D \uBC0F \uC9C0\uBD88:";
var APPB2C_cj_review_paymentNoteMultiple2 = "\uC774\uC81C \uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_review_paymentNoteSingle2 = "\uC544\uB798 \uC635\uC158\uC744 \uC120\uD0DD\uD558\uBA74 \uC2DC\uD5D8\uC774 \uC608\uC57D\uB429\uB2C8\uB2E4.";
var APPB2C_cj_review_paymentNoteZeroFee3 = "\uBB34\uB8CC \uD14C\uC2A4\uD2B8 \uBC1B\uAE30";
var APPB2C_cj_review_payOnline_buttonText3 = "\uC628\uB77C\uC778 \uACB0\uC81C";
var APPB2C_cj_review_payOnline_sideNote3 = "\uAD8C\uC7A5 \uC9C0\uBD88 \uBC29\uBC95";
var APPB2C_cj_review_personalDetails_address3 = "\uC8FC\uC18C";
var APPB2C_cj_review_personalDetails_dob3 = "\uC0DD\uB144\uC6D4\uC77C";
var APPB2C_cj_review_personalDetails_email3 = "\uC774\uBA54\uC77C \uC8FC\uC18C";
var APPB2C_cj_review_personalDetails_gender3 = "\uC131\uBCC4";
var APPB2C_cj_review_personalDetails_name3 = "\uC774\uB984";
var APPB2C_cj_review_personalDetails_phone3 = "\uD734\uB300\uD3F0 \uBC88\uD638";
var APPB2C_cj_review_personalDetails_smsNotificationsConsent3 = "\uC774 \uD734\uB300\uD3F0 \uBC88\uD638\uB85C \uBCF8\uC778\uC758 \uC2DC\uD5D8 \uBC0F \uC131\uC801\uACFC \uAD00\uB828\uB41C \uC815\uBCF4\uB97C \uBC1B\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_review_personalDetails_title3 = "\uADC0\uD558\uC758 \uC815\uBCF4";
var APPB2C_cj_review_registrationError3 = "\uC9C0\uAE08\uC740 \uC608\uC57D\uC744 \uCC98\uB9AC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_registrationFailedModal_changedFee_btn3 = "\uC0C8 \uAC00\uACA9 \uD45C\uC2DC";
var APPB2C_cj_review_registrationFailedModal_changedFee_line13 = "\uC77C\uBD80 \uD14C\uC2A4\uD2B8\uC758 \uAC00\uACA9\uC774 \uBCC0\uACBD\uB418\uC5C8\uC74C\uC744 \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_cj_review_registrationFailedModal_changedFee_line23 = "\uC0C8 \uAC00\uACA9\uC744 \uAC80\uD1A0\uD558\uACE0 \uD14C\uC2A4\uD2B8\uB97C \uB2E4\uC2DC \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_registrationFailedModal_changedFee_line33 = "\uBD88\uD3B8\uC744 \uB07C\uCCD0 \uB4DC\uB824 \uC8C4\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_cj_review_registrationFailedModal_changedFee_title3 = "\uD14C\uC2A4\uD2B8 \uAC00\uACA9\uC774 \uBCC0\uACBD\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_review_registrationFailedModal_cta3 = "\uC608\uC57D \uB2E4\uC2DC \uC2DC\uC791";
var APPB2C_cj_review_registrationFailedModal_message3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_registrationFailedModal_title3 = "\uC608\uC57D \uB2E4\uC2DC \uC2DC\uC791";
var APPB2C_cj_review_taxNumberError3 = "\uD2B9\uC815 \uD615\uC2DD(\uC608: 13243546-3-35, 1324354635)\uACFC \uC77C\uCE58\uD574\uC57C \uD558\uB294 \uC720\uD6A8\uD55C VAT/NIP \uBC88\uD638\uB97C \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_cj_review_title3 = "\uAC80\uD1A0";
var APPB2C_cj_review_tncs_agreement3 = "\uBCF8\uC778\uC740 IELTS <0>\uC2DC\uD5D8 \uADDC\uC815</0>\uACFC <2>\uCDE8\uC18C \uC815\uCC45</2>\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4";
var APPB2C_cj_review_tncs_title3 = "\uC774\uC6A9 \uADDC\uC815";
var APPB2C_cj_review_tooHighPromoCodeError3 = "\uB4F1\uB85D\uC744 \uC644\uB8CC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC\uB97C \uC0AC\uC6A9\uD558\uC9C0 \uC54A\uACE0 \uB4F1\uB85D\uC744 \uC2DC\uB3C4\uD558\uC2ED\uC2DC\uC624. \uCF54\uB4DC\uAC00 \uC801\uC6A9\uB418\uC5B4\uC57C \uD55C\uB2E4\uACE0 \uC0DD\uAC01\uD558\uC2E0\uB2E4\uBA74 \uC13C\uD130\uC5D0 \uB3C4\uC6C0\uC744 \uC694\uCCAD\uD558\uC138\uC694";
var APPB2C_cj_timer_modal_cta3 = "\uC608\uC57D \uB2E4\uC2DC \uC2DC\uC791";
var APPB2C_cj_timer_modal_message3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC608\uC57D \uAC00\uB2A5 \uC2DC\uAC04\uC774 \uCD08\uACFC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_timer_modal_title3 = "\uC608\uC57D \uB2E4\uC2DC \uC2DC\uC791";
var APPB2C_cj_timer_nearEnd_minuteCount3 = "{{count}}\uBD84";
var APPB2C_cj_timer_nearEnd_minuteCount_other3 = "{{count}}\uBD84";
var APPB2C_cj_timer_nearEnd_text3 = '\uC120\uD0DD\uD558\uC2E0 \uC2DC\uD5D8\uC740 \uADC0\uD558\uB97C \uC704\uD574 $t(APPB2C.cj.timer.nearEnd.minuteCount, {"count": {{count}} }) \uB3D9\uC548\uB9CC \uC608\uC57D\uB429\uB2C8\uB2E4.';
var APPB2C_cj_timer_nearEnd_title3 = "\uC8FC\uC758!";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied3 = "\uADC0\uD558\uB294 \uC5B4\uB290 \uAD6D\uAC00/\uC9C0\uC5ED\uC5D0\uC11C \uACF5\uBD80/\uC77C/\uAC70\uC8FC\uB97C \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child3 = "\uADC0\uD558\uC758 \uC790\uB140\uB294 \uC5B4\uB290 \uAD6D\uAC00/\uC9C0\uC5ED\uC5D0\uC11C \uACF5\uBD80/\uC77C/\uAC70\uC8FC\uB97C \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest3 = "\uADC0\uD558\uAC00 IELTS\uC5D0 \uC751\uC2DC\uD558\uB294 \uC774\uC720\uB294 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child3 = "\uADC0\uD558\uC758 \uC790\uB140\uAC00 IELTS\uC5D0 \uC751\uC2DC\uD558\uB294 \uC774\uC720\uB294 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle3 = "\uADC0\uD558\uC758 IELTS\uC5D0 \uB300\uD55C \uAD00\uC2EC";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle_child3 = "\uADC0\uD558 \uC790\uB140\uC758 IELTS\uC5D0 \uB300\uD55C \uAD00\uC2EC";
var APPB2C_cj_ttProfile_forms_occupation_occupationLevel3 = "\uADC0\uD558\uC758 \uC9C1\uAE09/\uC9C1\uCC45\uC740 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_occupation_occupationSector3 = "\uADC0\uD558\uAC00 \uC885\uC0AC\uD558\uB294 \uBD84\uC57C\uB294 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_occupation_sectionTitle3 = "\uADC0\uD558\uC758 \uC9C1\uC5C5";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality3 = "\uADC0\uD558\uC758 \uAD6D\uC801\uC740 \uC5B4\uB514\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child3 = "\uC790\uB140\uC758 \uAD6D\uC801\uC740 \uC5B4\uB514\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3843 = "\uC911\uD559\uC0DD(~16\uC138)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3853 = "\uACE0\uB4F1\uD559\uC0DD(16~19\uC138)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3863 = "\uD559\uC704(\uB610\uB294 \uADF8\uC640 \uB3D9\uB4F1\uD55C \uAC83)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3873 = "\uB300\uD559\uC6D0";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_title3 = "\uADC0\uD558\uC758 \uCD5C\uC885 \uD559\uB825\uC740 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken3 = "\uADC0\uD558\uC758 \uBAA8\uAD6D\uC5B4\uB294 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child3 = "\uC790\uB140\uC758 \uBAA8\uAD6D\uC5B4\uB294 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle3 = "\uADC0\uD558\uC5D0 \uB300\uD558\uC5EC";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle_child3 = "\uC790\uB140\uC5D0 \uB300\uD558\uC5EC";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish3 = "\uADC0\uD558\uB294 \uC601\uC5B4\uB97C \uACF5\uBD80\uD55C \uC9C0 \uBA87 \uB144 \uB418\uC5C8\uC2B5\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child3 = "\uADC0\uD558\uC758 \uC790\uB140\uB294 \uC601\uC5B4\uB97C \uACF5\uBD80\uD55C \uC9C0 \uBA87 \uB144 \uB418\uC5C8\uC2B5\uB2C8\uAE4C?";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel3 = "\uC774 \uC9C8\uBB38\uC5D0 \uB300\uD55C \uB2F5\uBCC0\uC740 \uADC0\uD558\uC758 \uC2DC\uD5D8 \uC810\uC218\uC5D0 \uC601\uD5A5\uC744 \uC8FC\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child3 = "\uC774 \uC9C8\uBB38\uC5D0 \uB300\uD55C \uB2F5\uBCC0\uC740 \uADC0\uD558 \uC790\uB140\uC758 \uC2DC\uD5D8 \uC810\uC218\uC5D0 \uC601\uD5A5\uC744 \uC8FC\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_cj_ttProfile_note3 = "\uC774 \uC9C8\uBB38\uC5D0 \uB300\uD55C \uB2F5\uBCC0\uC740 \uADC0\uD558\uC758 IELTS \uC2DC\uD5D8 \uACB0\uACFC\uC5D0 \uC601\uD5A5\uC744 \uC8FC\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uB2F5\uBCC0 \uB0B4\uC6A9\uC740 \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC751\uC2DC\uC790 \uC11C\uBE44\uC2A4 \uAC1C\uC120\uC5D0 \uD65C\uC6A9\uB429\uB2C8\uB2E4.";
var APPB2C_cj_ttProfile_note_child3 = "\uC774 \uC9C8\uBB38\uC5D0 \uB300\uD55C \uB2F5\uBCC0\uC740 \uADC0\uD558\uC758 \uC790\uB140\uC758 IELTS \uC2DC\uD5D8 \uACB0\uACFC\uC5D0 \uC601\uD5A5\uC744 \uC8FC\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uB2F5\uBCC0 \uB0B4\uC6A9\uC740 \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC751\uC2DC\uC790 \uC11C\uBE44\uC2A4 \uAC1C\uC120\uC5D0 \uD65C\uC6A9\uB429\uB2C8\uB2E4.";
var APPB2C_cj_ttProfile_title3 = "\uADC0\uD558\uC758 \uD504\uB85C\uD544";
var APPB2C_cj_ttProfile_title_child3 = "\uC790\uB140\uC758 \uD504\uB85C\uD544";
var APPB2C_cj_ukviStartPage_ac_content3 = "IELTS for UKVI Academic\uC740 \uC601\uAD6D\uC5D0\uC11C \uD559\uBD80/\uB300\uD559\uC6D0 \uC218\uC900\uC758 \uACF5\uBD80\uB97C \uD558\uAC70\uB098 \uC804\uBB38\uC9C1 \uCDE8\uC5C5\uC744 \uC6D0\uD558\uB294 \uC751\uC2DC\uC790\uC5D0\uAC8C \uC801\uD569\uD569\uB2C8\uB2E4.";
var APPB2C_cj_ukviStartPage_ac_title3 = "UKVI Academic\uC744 \uC704\uD55C IELTS";
var APPB2C_cj_ukviStartPage_gt_content3 = "IELTS for UKVI General Training\uC740 \uC601\uAD6D\uC5D0\uC11C \uD559\uBD80 \uC785\uD559 \uC804 \uB2E8\uACC4\uC758 \uAD50\uC721\uC774\uB098 \uD6C8\uB828\uC744 \uBC1B\uACE0\uC790 \uD558\uB294 \uC751\uC2DC\uC790\uC5D0\uAC8C \uC801\uD569\uD569\uB2C8\uB2E4.";
var APPB2C_cj_ukviStartPage_gt_title3 = "UKVI General Training\uC744 \uC704\uD55C IELTS";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_content3 = "\uC601\uAD6D \uBE44\uC790\uC774\uBBFC\uAD6D(UK Visas and Immigration)\uC5D0 \u2018\uC815\uCC29\uBBFC\uC758 \uAC00\uC871(family of a settled person)\u2019 \uBE44\uC790\uB97C \uC2E0\uCCAD\uD558\uAE30 \uC704\uD574 \uC601\uC5B4 \uB9D0\uD558\uAE30\xB7\uB4E3\uAE30 \uB2A5\uB825\uC744 \uC99D\uBE59\uD558\uB294 \uB370 \uD544\uC694\uD55C \uC2DC\uD5D8\uC785\uB2C8\uB2E4. ";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_title3 = "IELTS Life Skills A1";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_content3 = "\uC601\uAD6D \uBE44\uC790\uC774\uBBFC\uAD6D\uC5D0 \uAC00\uC871, \uBC30\uC6B0\uC790 \uB610\uB294 \uD30C\uD2B8\uB108\uC758 \uBE44\uC790 \uC5F0\uC7A5\uC744 \uC2E0\uCCAD\uD558\uAE30 \uC704\uD574 \uC601\uC5B4 \uB9D0\uD558\uAE30\xB7\uB4E3\uAE30 \uB2A5\uB825\uC744 \uC99D\uBE59\uD558\uB294 \uB370 \uD544\uC694\uD55C \uC2DC\uD5D8\uC785\uB2C8\uB2E4. ";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_title3 = "IELTS Life Skills A2 (\uC601\uAD6D\uB9CC \uD574\uB2F9)";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_content3 = "\uC601\uAD6D \uBE44\uC790\uC774\uBBFC\uAD6D\uC5D0 \uC601\uC8FC\uAD8C\uC774\uB098 \uC2DC\uBBFC\uAD8C\uC744 \uC2E0\uCCAD\uD558\uAE30 \uC704\uD574 \uC601\uC5B4 \uB9D0\uD558\uAE30\uC640 \uB4E3\uAE30 \uB2A5\uB825\uC744 \uC99D\uBE59\uD558\uB294 \uB370 \uD544\uC694\uD55C \uC2DC\uD5D8\uC785\uB2C8\uB2E4.";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_title3 = "IELTS Life Skills B1";
var APPB2C_cj_ukviStartPage_lifeSkils_citizenship3 = "\uC601\uC8FC\uAD8C/\uC2DC\uBBFC\uAD8C \uC2E0\uCCAD.";
var APPB2C_cj_ukviStartPage_lifeSkils_extension3 = "\uAC00\uC871, \uBC30\uC6B0\uC790/\uD30C\uD2B8\uB108\uC758 \uBE44\uC790 \uC5F0\uC7A5";
var APPB2C_cj_ukviStartPage_lifeSkils_family3 = "\uAC00\uC871 \uBE44\uC790(family visa)";
var APPB2C_cj_ukviStartPage_lifeSkils_note_content3 = "\uCD5C\uADFC 2\uB144 \uC774\uB0B4\uC5D0 IELTS Life Skills \uC2DC\uD5D8\uC744 \uCE58\uB974\uACE0 \uD569\uACA9\uD588\uB2E4\uBA74 \uB3D9\uC77C\uD55C IELTS Life Skills \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_cj_ukviStartPage_lifeSkils_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_cj_ukviStartPage_lifeSkils_title3 = "IELTS Life Skills";
var APPB2C_cj_ukviStartPage_lifeSkils_visaTypes3 = "IELTS Life Skills\uB294 \uB2E4\uC74C \uC720\uD615\uC758 \uC601\uAD6D \uBE44\uC790\uB97C \uC2E0\uCCAD\uD558\uB294 \uC751\uC2DC\uC790\uC5D0\uAC8C \uC801\uD569\uD569\uB2C8\uB2E4.";
var APPB2C_cj_ukviStartPage_note1_content3 = "\uADC0\uD558\uC758 IELTS \uC2DC\uD5D8 \uC751\uC2DC \uBAA9\uC801\uC774 \uC601\uAD6D \uBE44\uC790 \uCDE8\uB4DD/\uC774\uBBFC \uC2E0\uCCAD\uC774 \uC544\uB2CC \uACBD\uC6B0 <0>\uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uC81C\uACF5\uD558\uB294 \uB2E4\uB978 \uC885\uB958\uC758 IELTS \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4</0>.";
var APPB2C_cj_ukviStartPage_note1_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_cj_ukviStartPage_terms3 = "\uACC4\uC18D\uD558\uAE30 \uC804\uC5D0 <0>IELTS for UK Visas \uC774\uC6A9 \uADDC\uC815</0>\uC744 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_cj_ukviStartPage_testsBelow3 = "\uC544\uB798\uC758 IELTS \uC2DC\uD5D8\uC740 \uC601\uAD6D \uBE44\uC790 \uCDE8\uB4DD/\uC774\uBBFC\uC744 \uC704\uD55C \uACF5\uC778 \uC2DC\uD5D8\uC785\uB2C8\uB2E4.";
var APPB2C_cj_ukviStartPage_title3 = "\uC601\uAD6D \uBE44\uC790\uB97C \uC704\uD55C IELTS";
var APPB2C_cj_ukviStartPage_whichUkvi3 = "\uC5B4\uB5A4 IELTS for UK Visas \uC2DC\uD5D8\uC774 \uB098\uC5D0\uAC8C \uC801\uD569\uD55C\uAC00\uC694?";
var APPB2C_common_account_details_email_cancel2 = "\uCDE8\uC18C";
var APPB2C_common_account_details_email_confirm3 = "\uC774\uBA54\uC77C \uC8FC\uC18C \uD655\uC778";
var APPB2C_common_account_details_email_label3 = "\uC774\uBA54\uC77C";
var APPB2C_common_account_details_email_maxLength3 = "\uC774\uBA54\uC77C \uC8FC\uC18C\uAC00 \uB108\uBB34 \uAE41\uB2C8\uB2E4. \uCD5C\uB300 \uAE38\uC774\uB294 60\uC790\uC785\uB2C8\uB2E4.";
var APPB2C_common_account_details_email_mismatch3 = "\uC704\uC758 \uC774\uBA54\uC77C \uC8FC\uC18C\uC640 \uC815\uD655\uD788 \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_account_details_email_note3 = "\uADC0\uD558\uC758 \uC774\uBA54\uC77C \uC8FC\uC18C\uB294 \uC720\uD6A8\uD558\uACE0 \uC0AC\uC6A9 \uAC00\uB2A5\uD574\uC57C \uD569\uB2C8\uB2E4. \uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uD574\uB2F9 \uBA54\uC77C \uC8FC\uC18C\uB85C \uADC0\uD558\uC758 \uACC4\uC815 \uBC0F \uC2DC\uD5D8 \uB4F1\uB85D\uACFC \uAD00\uB828\uB41C \uBA54\uC77C\uC744 \uBC1C\uC1A1\uD569\uB2C8\uB2E4. \uC774\uC81C\uBD80\uD130 \uC0AC\uC6A9\uC790\uBA85 \uB610\uB294 \uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uC0AC\uC6A9\uD574 \uC751\uC2DC\uC790 \uD3EC\uD138\uC5D0 \uB85C\uADF8\uC778\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_details_email_save3 = "\uC774\uBA54\uC77C \uC8FC\uC18C \uC800\uC7A5";
var APPB2C_common_account_details_email_set3 = "\uC774\uBA54\uC77C \uC8FC\uC18C \uC785\uB825";
var APPB2C_common_account_details_email_success3 = "\uC774\uBA54\uC77C \uC8FC\uC18C\uAC00 \uC800\uC7A5\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_details_email_username3 = "\uC0AC\uC6A9\uC790\uBA85";
var APPB2C_common_account_details_email_validEmail3 = "\uC720\uD6A8\uD55C \uC774\uBA54\uC77C \uC8FC\uC18C\uB97C \uC9C0\uC815\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_account_details_name3 = "\uADC0\uD558\uC758 \uC774\uB984";
var APPB2C_common_account_details_psw_cancel2 = "\uCDE8\uC18C";
var APPB2C_common_account_details_psw_change3 = "\uBE44\uBC00\uBC88\uD638 \uBCC0\uACBD";
var APPB2C_common_account_details_psw_label3 = "\uBE44\uBC00\uBC88\uD638";
var APPB2C_common_account_details_psw_new3 = "\uC0C8 \uBE44\uBC00\uBC88\uD638";
var APPB2C_common_account_details_psw_old3 = "\uC774\uC804 \uBE44\uBC00\uBC88\uD638";
var APPB2C_common_account_details_psw_requirements_levels_03 = "\uB9E4\uC6B0 \uCDE8\uC57D";
var APPB2C_common_account_details_psw_requirements_levels_13 = "\uCDE8\uC57D";
var APPB2C_common_account_details_psw_requirements_levels_23 = "\uBCF4\uD1B5";
var APPB2C_common_account_details_psw_requirements_levels_33 = "\uC548\uC804";
var APPB2C_common_account_details_psw_requirements_levels_43 = "\uB9E4\uC6B0 \uC548\uC804";
var APPB2C_common_account_details_psw_requirements_maxLength3 = "\uBE44\uBC00\uBC88\uD638\uAC00 \uB108\uBB34 \uAE41\uB2C8\uB2E4. \uCD5C\uB300 \uAE38\uC774\uB294 100\uC790\uC785\uB2C8\uB2E4.";
var APPB2C_common_account_details_psw_requirements_mustHave3 = "\uBE44\uBC00\uBC88\uD638\uB294 \uB2E4\uC74C\uC744 \uD3EC\uD568\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_account_details_psw_requirements_ok3 = "\uBE44\uBC00\uBC88\uD638 \uC694\uAC74\uC774 \uCDA9\uC871\uB418\uC5C8\uC2B5\uB2C8\uB2E4";
var APPB2C_common_account_details_psw_requirements_reqs_length3 = "8\uC790 \uC774\uC0C1";
var APPB2C_common_account_details_psw_requirements_reqs_lower3 = "\uC18C\uBB38\uC790";
var APPB2C_common_account_details_psw_requirements_reqs_number3 = "\uD558\uB098 \uC774\uC0C1\uC758 \uC22B\uC790";
var APPB2C_common_account_details_psw_requirements_reqs_special3 = "\uD558\uB098 \uC774\uC0C1\uC758 \uD2B9\uC218\uBB38\uC790";
var APPB2C_common_account_details_psw_requirements_reqs_upper3 = "\uB300\uBB38\uC790";
var APPB2C_common_account_details_psw_requirements_shouldHave3 = "\uB610\uD55C \uB2E4\uC74C \uC138 \uAC00\uC9C0 \uC694\uAC74\uC744 \uCDA9\uC871\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_account_details_psw_requirements_strength3 = "\uBCF4\uC548\uC131";
var APPB2C_common_account_details_psw_requirements_tooWeak3 = "\uBE44\uBC00\uBC88\uD638\uC758 \uBCF4\uC548\uC774 \uCDE8\uC57D\uD569\uB2C8\uB2E4.";
var APPB2C_common_account_details_psw_show3 = "\uBE44\uBC00\uBC88\uD638 \uD45C\uC2DC";
var APPB2C_common_account_details_psw_success3 = "\uBE44\uBC00\uBC88\uD638\uAC00 \uBCC0\uACBD\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_details_subnote_address3 = "\uC8FC\uC18C\uC758 \uC6D0\uB798 \uCCA0\uC790 \uC0AC\uC6A9";
var APPB2C_common_account_details_subnote_city3 = "town / city\uC758 \uC6D0\uB798 \uCCA0\uC790\uB97C \uC0AC\uC6A9\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_account_error3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uC7A0\uC2DC \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_account_marketing_success3 = "\uB9C8\uCF00\uD305 \uC815\uBCF4 \uC218\uC2E0 \uC124\uC815\uC774 \uBCC0\uACBD\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_marketing_title3 = "\uAC1C\uC778\uC815\uBCF4 \uB9C8\uCF00\uD305 \uC774\uC6A9 \uB3D9\uC758";
var APPB2C_common_account_smsNotificationSettings_mobileLabel3 = "\uBB38\uC790 \uBA54\uC2DC\uC9C0\uB294 \uB2E4\uC74C \uBC88\uD638\uB85C \uC804\uC1A1\uB429\uB2C8\uB2E4.";
var APPB2C_common_account_smsNotificationSettings_success3 = "\uBB38\uC790 \uBA54\uC2DC\uC9C0 \uC218\uC2E0 \uC124\uC815\uC774 \uBCC0\uACBD\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_smsNotificationSettings_title3 = "\uBB38\uC790 \uBA54\uC2DC\uC9C0 \uC124\uC815";
var APPB2C_common_account_terms_cta3 = "\uC774\uC6A9 \uADDC\uC815 \uB2E4\uC6B4\uB85C\uB4DC";
var APPB2C_common_account_terms_disclaimer3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uB4F1\uB85D\uC744 \uCC98\uB9AC\uD558\uAE30 \uC704\uD574 \uADC0\uD558\uAC00 \uC81C\uACF5\uD558\uB294 \uAC1C\uC778\uC815\uBCF4\uB97C \uC0AC\uC6A9\uD569\uB2C8\uB2E4. \uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uADC0\uD558\uC758 \uAC1C\uC778\uC815\uBCF4\uB97C \uCC98\uB9AC\uD558\uB294 \uBC95\uC801 \uADFC\uAC70\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0 \uB4F1\uB85D \uC774\uC6A9 \uADDC\uC815(\uACC4\uC57D)\uC5D0 \uB300\uD55C \uADC0\uD558\uC758 \uB3D9\uC758\uC5D0 \uAE30\uCD08\uD569\uB2C8\uB2E4.";
var APPB2C_common_account_terms_download3 = "\uB3D9\uC758\uD558\uC2E0 \uC774\uC6A9 \uADDC\uC815\uC740 \uB2E4\uC74C \uB9C1\uD06C\uC5D0\uC11C \uB2E4\uC6B4\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_terms_extra_line13 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uAD6D\uC81C\uC801\uC73C\uB85C \uC801\uC6A9\uB418\uB294 \uAE30\uC900\uC744 \uC9C0\uD0A4\uAE30 \uC704\uD574 \uC601\uAD6D\uC740 \uBB3C\uB860 \uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uC8FC\uC7AC\uD558\uACE0 \uC788\uB294 \uAD6D\uAC00\uC758 \uAC1C\uC778\uC815\uBCF4 \uBCF4\uD638\uBC95\uC744 \uC900\uC218\uD558\uACE0 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_terms_extra_line23 = "\uC815\uBCF4\uC8FC\uCCB4\uB294 \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC5D0 \uB300\uD574 \uC5B8\uC81C\uB4E0\uC9C0 \uAC1C\uC778\uC815\uBCF4 \uC5F4\uB78C \uC694\uAD6C \uB610\uB294 \uC624\uB958 \uB4F1\uC774 \uC788\uC744 \uACBD\uC6B0 \uC815\uC815\uC744 \uC694\uAD6C\uD560 \uAD8C\uB9AC\uAC00 \uC788\uC2B5\uB2C8\uB2E4. \uAC1C\uC778\uC815\uBCF4\uC640 \uAD00\uB828\uD55C \uBD88\uB9CC \uC0AC\uD56D\uC774 \uC788\uC744 \uACBD\uC6B0 \uAC1C\uC778\uC815\uBCF4\uBCF4\uD638\uBC95 \uB2F9\uAD6D(\uD55C\uAD6D\uC778\uD130\uB137\uC9C4\uD765\uC6D0)\uC5D0 \uBB38\uC758\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_terms_extra_line3_13 = '\uC790\uC138\uD55C \uC815\uBCF4\uB294 <a href="http://www.britishcouncil.org/privacy" target="_blank">\uC601\uAD6D\uBB38\uD654\uC6D0 \uBCF8\uBD80 \uC6F9\uC0AC\uC774\uD2B8</a>\u202F\uC5D0\uC11C \uD655\uC778\uD558\uAC70\uB098';
var APPB2C_common_account_terms_extra_line3_23 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC5D0 \uBB38\uC758\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4. \uC815\uBCF4\uC8FC\uCCB4\uC758 \uAC1C\uC778\uC815\uBCF4\uB294 \uADC0\uD558\uC758 \uB4F1\uB85D\uC744 \uC704\uD55C \uAC1C\uC778\uC815\uBCF4 \uC218\uC9D1\uC77C\uB85C\uBD80\uD130 3\uB144 \uAC04 \uBCF4\uC720\uB418\uBA70, \uB9C8\uCF00\uD305 \uBAA9\uC801\uC73C\uB85C\uB294 7\uB144 \uAC04 \uBCF4\uC720\uB429\uB2C8\uB2E4.";
var APPB2C_common_account_terms_extra_title3 = "\uAC1C\uC778\uC815\uBCF4 \uBCF4\uD638";
var APPB2C_common_account_terms_previous3 = "\uC774\uC804\uC758 \uC774\uC6A9 \uADDC\uC815\uC740 \uB2E4\uC74C \uB9C1\uD06C\uC5D0 \uC788\uC2B5\uB2C8\uB2E4: <0>\uC774\uC6A9 \uADDC\uC815</0>";
var APPB2C_common_account_terms_title3 = "\uADC0\uD558\uB294 \uACC4\uC815 \uB4F1\uB85D <0>\uC774\uC6A9 \uADDC\uC815</0>\uC5D0 \uB3D9\uC758\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_account_title3 = "\uB0B4 \uACC4\uC815";
var APPB2C_common_alertBar_afterTest3 = "\uC544\uC9C1 \uB2E4\uC74C\uC744 \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_alertBar_beforeTest3 = "\uC2DC\uD5D8\uC77C \uC804\uC5D0 \uB2E4\uC74C\uC744 \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_alertBar_cancelReupload3 = "\uCDE8\uC18C \uC99D\uBE59 \uB2E4\uC2DC \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_check3 = "\uC2DC\uD5D8 \uC804 \uC900\uBE44\uC0AC\uD56D \uD655\uC778";
var APPB2C_common_alertBar_childConsentMissing3 = "\uC790\uB140 \uB3D9\uC758(child consent) \uC815\uBCF4 \uC81C\uACF5";
var APPB2C_common_alertBar_creds3 = "\uB0B4 \uB85C\uADF8\uC778 \uC815\uBCF4 \uBCF4\uAE30";
var APPB2C_common_alertBar_eorPayment3 = "\uC7AC\uCC44\uC810 \uACB0\uC81C";
var APPB2C_common_alertBar_eorPaymentProof3 = "\uC7AC\uCC44\uC810 \uACB0\uC81C \uC99D\uBE59 \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_idReupload3 = "\uC2E0\uBD84\uC99D \uB2E4\uC2DC \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_idUpload3 = "\uC2E0\uBD84\uC99D \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_medicalReupload3 = "\uC9C4\uB2E8\uC11C \uB2E4\uC2DC \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_medicalUpload3 = "\uC9C4\uB2E8\uC11C \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_regPayment3 = "\uC751\uC2DC\uB8CC \uACB0\uC81C";
var APPB2C_common_alertBar_regPaymentProof3 = "\uC751\uC2DC\uB8CC \uACB0\uC81C \uC99D\uBE59 \uC5C5\uB85C\uB4DC";
var APPB2C_common_alertBar_withCreds3 = "\uC774\uC81C \uC2DC\uD5D8\uC5D0 \uB85C\uADF8\uC778\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_add3 = "\uCD94\uAC00";
var APPB2C_common_basic_backToTop3 = "\uB9E8 \uC704\uB85C";
var APPB2C_common_basic_bookingDetails_arrival3 = "({{time}}\uC2DC\uC5D0 \uB3C4\uCC29\uD574 \uC8FC\uC2ED\uC2DC\uC624)";
var APPB2C_common_basic_bookingDetails_lifeSkills_results3 = "\uB4E3\uAE30, \uB9D0\uD558\uAE30";
var APPB2C_common_basic_bookingDetails_lifeSkills_title3 = "\uADC0\uD558\uC758 \uC2DC\uD5D8";
var APPB2C_common_basic_bookingDetails_lrw_cd3 = "\uC2DC\uD5D8 \uC13C\uD130 \uCEF4\uD4E8\uD130 IELTS";
var APPB2C_common_basic_bookingDetails_lrw_online3 = "\uC628\uB77C\uC778";
var APPB2C_common_basic_bookingDetails_lrw_pb3 = "\uC9C0\uD544 IELTS";
var APPB2C_common_basic_bookingDetails_lrw_results3 = "\uB4E3\uAE30, \uC77D\uAE30, \uC4F0\uAE30";
var APPB2C_common_basic_bookingDetails_lrw_title3 = "\uD544\uAE30 \uC2DC\uD5D8";
var APPB2C_common_basic_bookingDetails_osrTitle_listening3 = "\uB4E3\uAE30 \uC2DC\uD5D8";
var APPB2C_common_basic_bookingDetails_osrTitle_reading3 = "\uC77D\uAE30 \uC2DC\uD5D8";
var APPB2C_common_basic_bookingDetails_speaking_f2f3 = "\uB300\uBA74";
var APPB2C_common_basic_bookingDetails_speaking_live3 = "\uB300\uBA74";
var APPB2C_common_basic_bookingDetails_speaking_online3 = "\uC628\uB77C\uC778";
var APPB2C_common_basic_bookingDetails_speaking_remote3 = "\uC2DC\uD5D8 \uC13C\uD130 \uD654\uC0C1 \uC2DC\uD5D8";
var APPB2C_common_basic_bookingDetails_speaking_results3 = "\uB9D0\uD558\uAE30";
var APPB2C_common_basic_bookingDetails_speaking_title3 = "\uADC0\uD558\uC758 \uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_basic_bookPrePaid3 = "\uC120\uBD88 \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_common_basic_cancel3 = "\uCDE8\uC18C";
var APPB2C_common_basic_change3 = "\uBCC0\uACBD";
var APPB2C_common_basic_choose3 = "\uC120\uD0DD";
var APPB2C_common_basic_close3 = "\uB2EB\uAE30";
var APPB2C_common_basic_copied3 = "\uBCF5\uC0AC\uB428";
var APPB2C_common_basic_copy3 = "\uBCF5\uC0AC";
var APPB2C_common_basic_dateField_day3 = "\uC77C";
var APPB2C_common_basic_dateField_month3 = "\uC6D4";
var APPB2C_common_basic_dateField_months3 = "\uC6D4 \uC120\uD0DD";
var APPB2C_common_basic_dateField_year3 = "\uB144";
var APPB2C_common_basic_edit3 = "\uD3B8\uC9D1";
var APPB2C_common_basic_errors_contactTestCentre3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uBB38\uC81C\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uC9C0\uC5ED \uC13C\uD130\uC5D0 \uC5F0\uB77D\uD558\uC5EC \uC2DC\uD5D8 \uB0A0\uC9DC \uBCC0\uACBD\uC744 \uC694\uCCAD\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_common_basic_errors_network3 = "\uC778\uD130\uB137 \uC5F0\uACB0\uC5D0 \uBB38\uC81C\uAC00 \uC788\uC5C8\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uC811\uC18D\uD558\uC5EC \uD398\uC774\uC9C0\uB97C \uC0C8\uB85C\uACE0\uCE68 \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_errors_noTest3 = "\uC774 \uC2DC\uD5D8\uC5D0\uB294 \uB354 \uC774\uC0C1 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB2E4\uB978 \uC2DC\uD5D8\uC744 \uC120\uD0DD\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_errors_server3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uBA87 \uBD84 \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_errors_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_common_basic_errors_unableToDownloadFile3 = "\uADC0\uD558\uC758 \uD30C\uC77C\uC774 \uC811\uC218\uB418\uC5C8\uC73C\uB098 \uC774\uB97C \uD45C\uC2DC\uD558\uB294 \uB370 \uBB38\uC81C\uAC00 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_errors_unknown3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uBA87 \uBD84 \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_expando_collapse3 = "- \uACF5\uC9C0\uC0AC\uD56D \uBAA8\uB450 \uC228\uAE30\uAE30";
var APPB2C_common_basic_expando_show3 = "+ \uACF5\uC9C0\uC0AC\uD56D \uBAA8\uB450 \uBCF4\uAE30";
var APPB2C_common_basic_externalLinkTitle3 = "(\uC0C8 \uCC3D \uC5F4\uB9BC)";
var APPB2C_common_basic_failure3 = "\uC2E4\uD328";
var APPB2C_common_basic_forms_addressMaxLength3 = "\uC8FC\uC18C\uB294 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_dobMin3 = "\uACC4\uC815\uC740 18\uC138 \uC774\uC0C1\uB9CC \uB9CC\uB4E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uC758 \uBD80\uBAA8\uB2D8\uC774\uB098 \uBCF4\uD638\uC790\uAC00 IELTS \uC2DC\uD5D8\uC744 \uB300\uC2E0 \uC608\uC57D\uD558\uC154\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_dobMinIol3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. IELTS Online \uC608\uC57D\uC740 18\uC138 \uC774\uC0C1\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4. \uB300\uC2E0, \uC2DC\uD5D8 \uC13C\uD130 IELTS\uB97C \uC608\uC57D\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_dobOutOfRange3 = "\uCD9C\uC0DD\uC5F0\uB3C4\uAC00 \uC815\uD655\uD569\uB2C8\uAE4C?";
var APPB2C_common_basic_forms_firstNameMaxLength3 = "\uC774\uB984/\uC774\uB984\uC740 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_idExpiration3 = "\uC2DC\uD5D8 \uAE30\uAC04 \uC804\uC5D0 \uADC0\uD558\uC758 \uC11C\uB958\uAC00 \uB9CC\uB8CC\uB429\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_idMaxLength3 = "\uBB38\uC11C \uBC88\uD638\uB294 \uCD5C\uB300 20\uC790\uC785\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_idNumber3 = "\uC815\uD574\uC9C4 \uD615\uC2DD\uB9CC \uD5C8\uC6A9\uB429\uB2C8\uB2E4(\uD5C8\uC6A9 \uBB38\uC790: \uC54C\uD30C\uBCB3, \uC22B\uC790, \uD558\uC774\uD508(-))";
var APPB2C_common_basic_forms_idProofRequired3 = "\uC2E0\uBD84 \uC99D\uBE59\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_maxLengthMessage3 = "\uCD5C\uB300 \uAE38\uC774\uB294 {{count}}\uC785\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_minorDobMax3 = "18\uC138 \uC774\uC0C1\uC758 \uC790\uB140\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0 \uACC4\uC815\uC744 \uC9C1\uC811 \uB9CC\uB4E4\uC5B4\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_minorDobMin3 = "11\uC138 \uC774\uC0C1\uC758 \uC544\uB3D9\uB3C4 \uC2DC\uD5D8\uC744 \uBCFC \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_mobileBypass3 = "\uC774 \uD734\uB300\uD3F0 \uBC88\uD638\uAC00 \uB9DE\uC2B5\uB2C8\uB2E4";
var APPB2C_common_basic_forms_mobileLength3 = "\uD734\uB300\uD3F0 \uBC88\uD638\uB294 \uCD5C\uB300 20\uC790\uC785\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_onlyLatin3 = "\uC54C\uD30C\uBCB3\uB9CC \uD5C8\uC6A9\uB429\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_postCodeMaxLength3 = "\uC6B0\uD3B8\uBC88\uD638\uB294 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_postCodeMessage3 = "\uC6B0\uD3B8\uBC88\uD638\uB294 \uB2E4\uC12F \uC790\uB9AC(\uC608: 07102)\uB85C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_common_basic_forms_postCodeNotFound3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC785\uB825\uD558\uC2E0 \uC6B0\uD3B8\uBC88\uD638\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC6B0\uD3B8\uBC88\uD638\uAC00 \uC62C\uBC14\uB978\uC9C0 \uD655\uC778\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_forms_requiredMessage3 = "\uD544\uC218 \uC785\uB825 \uD56D\uBAA9\uC785\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_requiredNoSurmane3 = "\uD544\uC218 \uC785\uB825 \uD56D\uBAA9\uC785\uB2C8\uB2E4. \uC131\uC774 \uC5C6\uB294 \uACBD\uC6B0 \uD558\uC774\uD508('-')\uC744 \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_forms_stateMaxLength3 = "\uC0C1\uD0DC\uB294 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_surnameMaxLength3 = "\uC131/\uC131\uC740 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_townMaxLength3 = "\uB9C8\uC744/\uB3C4\uC2DC\uB294 \uCD5C\uB300 {{count}}\uC790 \uAE38\uC774\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_unsupportedChars3 = "\uC9C0\uC6D0\uD558\uC9C0 \uC54A\uB294 \uBB38\uC790\uAC00 \uD3EC\uD568\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_forms_validMobile3 = "\uC720\uD6A8\uD55C \uD734\uB300\uD3F0 \uBC88\uD638\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_goBack3 = "\uB3CC\uC544\uAC00\uAE30";
var APPB2C_common_basic_loading3 = "\uB85C\uB529 \uC911...";
var APPB2C_common_basic_menu3 = "\uBA54\uB274";
var APPB2C_common_basic_no3 = "\uC544\uB2C8\uC624";
var APPB2C_common_basic_notAllowed_msg3 = "\uC774 \uD398\uC774\uC9C0\uC5D0 \uC811\uC18D\uD560 \uAD8C\uD55C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_basic_notAllowed_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_common_basic_notFound_goHome3 = "\uD648\uC73C\uB85C";
var APPB2C_common_basic_notFound_msg3 = "\uD398\uC774\uC9C0\uAC00 \uC874\uC7AC\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_common_basic_notFound_title3 = "\uD398\uC774\uC9C0\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_basic_notLoading_note3 = "\uC694\uCCAD\uD558\uC2E0 \uD398\uC774\uC9C0\uB97C \uB85C\uB4DC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB124\uD2B8\uC6CC\uD06C \uC5F0\uACB0\uC774 \uBD88\uC548\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uC7A0\uC2DC \uD6C4 \uD398\uC774\uC9C0\uB97C \uC0C8\uB85C\uACE0\uCE68 \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_notLoading_refresh3 = "\uC0C8\uB85C\uACE0\uCE68";
var APPB2C_common_basic_notLoading_title3 = "\uD398\uC774\uC9C0\uB97C \uB85C\uB4DC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_basic_ok3 = "\uC608";
var APPB2C_common_basic_on3 = "\uC5D0";
var APPB2C_common_basic_oneSecond3 = "\uC7A0\uC2DC \uAE30\uB2E4\uB824 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_common_basic_pleaseNote3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_basic_retake3 = "\uC7AC\uC751\uC2DC";
var APPB2C_common_basic_upload3 = "\uC5C5\uB85C\uB4DC";
var APPB2C_common_basic_validationError3 = "\uACC4\uC18D\uD558\uB824\uBA74 \uAC15\uC870 \uD45C\uC2DC\uB41C \uC9C8\uBB38\uC744 \uBAA8\uB450 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_basic_waitReassurance3 = "\uAC70\uC758 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4...";
var APPB2C_common_basic_wereSorry3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4";
var APPB2C_common_basic_yes3 = "\uC608";
var APPB2C_common_bookTest_changeExamType3 = "\uC2DC\uD5D8 \uC720\uD615 \uBCC0\uACBD";
var APPB2C_common_bookTest_filters_afternoon3 = "\uC624\uD6C4";
var APPB2C_common_bookTest_filters_allDates3 = "\uBAA8\uB4E0 \uB0A0\uC9DC";
var APPB2C_common_bookTest_filters_anyTime3 = "\uD558\uB8E8 \uC911 \uC5B8\uC81C\uB4E0";
var APPB2C_common_bookTest_filters_computer3 = "\uCEF4\uD4E8\uD130";
var APPB2C_common_bookTest_filters_evening3 = "\uC800\uB141";
var APPB2C_common_bookTest_filters_modal_cta3 = "\uAC80\uC0C9 \uACB0\uACFC";
var APPB2C_common_bookTest_filters_modal_hide3 = "\uC228\uAE30\uAE30";
var APPB2C_common_bookTest_filters_modal_iDontMind3 = "\uB458 \uB2E4 \uAD1C\uCC2E\uC2B5\uB2C8\uB2E4";
var APPB2C_common_bookTest_filters_modal_resultsIn3 = "(\uC131\uC801\uC740 {{days}}\uC77C \uD6C4 \uBC1C\uAE09)";
var APPB2C_common_bookTest_filters_modal_subtitle3 = "\uC2DC\uD5D8 \uC0C1\uC138\uAC80\uC0C9";
var APPB2C_common_bookTest_filters_modal_title3 = "\uAC80\uC0C9\uC870\uAC74";
var APPB2C_common_bookTest_filters_modal_whichFormatTest3 = "\uC5B4\uB5A4 IELTS \uC2DC\uD5D8 \uBC29\uC2DD\uC744 \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_bookTest_filters_modal_whichTimeOfDay3 = "\uD558\uB8E8 \uC911 \uB4E3\uAE30, \uC77D\uAE30, \uC4F0\uAE30 \uC2DC\uD5D8 \uC2DC\uAC04";
var APPB2C_common_bookTest_filters_morning3 = "\uC624\uC804";
var APPB2C_common_bookTest_filters_paper3 = "\uC9C0\uD544";
var APPB2C_common_bookTest_filters_seeAllFilters3 = "\uBAA8\uB4E0 \uAC80\uC0C9\uC870\uAC74 \uBCF4\uAE30";
var APPB2C_common_bookTest_filters_title3 = "\uAC80\uC0C9\uC870\uAC74";
var APPB2C_common_bookTest_loadMoreTests3 = "\uB2E4\uB978 \uC2DC\uD5D8 \uBD88\uB7EC\uC624\uAE30";
var APPB2C_common_bookTest_noDiscount_msg_countryNotMatched3 = "\uC774 \uBC14\uC6B0\uCC98\uB294 \uC120\uD0DD\uD55C \uAD6D\uAC00\uC5D0\uC11C \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC6D0\uB798 \uAD6D\uAC00 \uC120\uD0DD\uC73C\uB85C \uB3CC\uC544\uAC00\uC2ED\uC2DC\uC624.";
var APPB2C_common_bookTest_noDiscount_msg_notFound3 = "\uBC14\uC6B0\uCC98\uB294 \uC720\uD6A8\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_bookTest_noDiscount_msg_notSupported3 = "\uC774 \uBC14\uC6B0\uCC98\uB294 \uB2E4\uB978 \uC81C\uD488 \uC804\uC6A9\uC785\uB2C8\uB2E4. \uC6D0\uB798 \uC81C\uD488 \uC120\uD0DD\uC73C\uB85C \uB3CC\uC544\uAC00\uC2ED\uC2DC\uC624.";
var APPB2C_common_bookTest_noDiscount_msg_reserved3 = "\uC774 \uBC14\uC6B0\uCC98\uB294 \uC774\uBBF8 \uC0AC\uC6A9 \uC911\uC785\uB2C8\uB2E4. \uC0AC\uC6A9\uD55C \uACF3\uC5D0\uC11C \uB4F1\uB85D\uC744 \uC644\uB8CC\uD558\uAC70\uB098 \uB098\uC911\uC5D0 \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_bookTest_noDiscount_msg_used3 = "\uC774 \uBC14\uC6B0\uCC98\uB294 \uC774\uBBF8 \uC0AC\uC6A9\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uC608\uC57D\uC744 \uC9C4\uD589\uD560 \uC218 \uC788\uC9C0\uB9CC \uD14C\uC2A4\uD2B8 \uBE44\uC6A9 \uC804\uC561\uC744 \uC9C0\uBD88\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_bookTest_noDiscount_title3 = "\uC591\uD574 \uBC14\uB78D\uB2C8\uB2E4";
var APPB2C_common_bookTest_results_book3 = "\uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_common_bookTest_results_bookFor3 = "\uB2E4\uC74C \uC2DC\uD5D8 \uC608\uC57D\uD558\uAE30";
var APPB2C_common_bookTest_results_cd3 = "\uCEF4\uD4E8\uD130 IELTS";
var APPB2C_common_bookTest_results_changeSpeaking3 = "\uC5F0\uC124 \uC77C\uC815 \uBCC0\uACBD";
var APPB2C_common_bookTest_results_clearFilters3 = "\uAC80\uC0C9\uC870\uAC74 \uCD08\uAE30\uD654 \uD6C4 \uBAA8\uB4E0 \uC2DC\uD5D8 \uD45C\uC2DC";
var APPB2C_common_bookTest_results_fromCurrentLocation3 = "\uB0B4 \uC704\uCE58\uB85C\uBD80\uD130\uC758 \uAC70\uB9AC(km)";
var APPB2C_common_bookTest_results_lrw3 = "\uB4E3\uAE30, \uC77D\uAE30, \uC4F0\uAE30";
var APPB2C_common_bookTest_results_noResultsGoBack3 = "\uAC80\uC0AC\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. 1>\uB3CC\uC544\uAC00\uAE30</1>\uC744 <\uD558\uACE0 \uC704\uCE58 \uB610\uB294 \uB0A0\uC9DC\uB97C \uBCC0\uACBD\uD574 \uBCF4\uC138\uC694.";
var APPB2C_common_bookTest_results_noResultsText3 = "\uADC0\uD558\uC758 \uC694\uAD6C\uC5D0 \uB9DE\uB294 \uD14C\uC2A4\uD2B8\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_bookTest_results_noResultsTitle3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4";
var APPB2C_common_bookTest_results_pb3 = "\uC9C0\uD544 IELTS";
var APPB2C_common_bookTest_results_review3 = "\uADF8\uB9AC\uACE0 \uAC80\uD1A0\uD558\uAE30";
var APPB2C_common_bookTest_results_searchText3 = "\uADC0\uD558\uC758 \uC2DC\uD5D8\uC744 \uCC3E\uACE0 \uC788\uC2B5\uB2C8\uB2E4...";
var APPB2C_common_bookTest_results_speaking3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_bookTest_results_viewOnMap3 = "\uC9C0\uB3C4\uC5D0\uC11C \uBCF4\uAE30";
var APPB2C_common_bookTest_sortBy_date3 = "\uB0A0\uC9DC";
var APPB2C_common_bookTest_sortBy_distance3 = "\uAC70\uB9AC";
var APPB2C_common_bookTest_sortBy_price3 = "\uAC00\uACA9";
var APPB2C_common_bookTest_sortBy_title3 = "\uC815\uB82C \uAE30\uC900";
var APPB2C_common_bookTest_title3 = "\uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_common_cancelBooking_alreadyRequested_cta3 = "\uB0B4 \uC2DC\uD5D8 \uC608\uC57D\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_common_cancelBooking_alreadyRequested_title3 = "\uC774\uBBF8 \uC774 \uC11C\uBE44\uC2A4\uB97C \uC2E0\uCCAD\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_back3 = "\uC608\uC57D\uC744 \uCDE8\uC18C\uD558\uC9C0 \uC54A\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_common_cancelBooking_bankDetails_form_accountHolder3 = "\uC608\uAE08\uC8FC \uC8FC\uC18C(Bank account holder address)";
var APPB2C_common_cancelBooking_bankDetails_form_bankAddress3 = "\uC740\uD589 \uC8FC\uC18C(Bank branch address)";
var APPB2C_common_cancelBooking_bankDetails_form_bankName3 = "\uC740\uD589\uBA85(Bank name)";
var APPB2C_common_cancelBooking_bankDetails_form_bankNumber3 = "\uACC4\uC88C\uBC88\uD638(Bank account number)";
var APPB2C_common_cancelBooking_bankDetails_form_changeCountry3 = "\uAD6D\uAC00 \uBCC0\uACBD";
var APPB2C_common_cancelBooking_bankDetails_form_city3 = "\uC2DC/\uAD70/\uAD6C";
var APPB2C_common_cancelBooking_bankDetails_form_country3 = "\uAD6D\uAC00";
var APPB2C_common_cancelBooking_bankDetails_form_holderEmail3 = "\uC608\uAE08\uC8FC \uC774\uBA54\uC77C \uC8FC\uC18C(Email address of the account holder)";
var APPB2C_common_cancelBooking_bankDetails_form_holderName3 = "\uC608\uAE08\uC8FC\uBA85(Account holder\u2019s name)";
var APPB2C_common_cancelBooking_bankDetails_form_iban3 = "IBAN Code";
var APPB2C_common_cancelBooking_bankDetails_form_invalidIban3 = "\uC62C\uBC14\uB978 IBAN Code\uAC00 \uC544\uB2D9\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_bankDetails_form_invalidSwift3 = "\uC62C\uBC14\uB978 SWIFT Code\uAC00 \uC544\uB2D9\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_bankDetails_form_postcode3 = "\uC6B0\uD3B8\uBC88\uD638";
var APPB2C_common_cancelBooking_bankDetails_form_sortCode3 = "\uC740\uD589\uCF54\uB4DC(Sort code)";
var APPB2C_common_cancelBooking_bankDetails_form_swift3 = "SWIFT Code";
var APPB2C_common_cancelBooking_bankDetails_note3 = "\uCDE8\uC18C \uC2E0\uCCAD \uC2B9\uC778 \uC2DC \uD658\uBD88\uC744 \uBC1B\uC73C\uC2E4 \uC740\uD589 \uACC4\uC88C \uC815\uBCF4\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_bankDetails_privacy3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD\uC744 \uCC98\uB9AC\uD558\uAE30 \uC704\uD574 \uADC0\uD558\uAC00 \uC81C\uACF5\uD558\uB294 \uAC1C\uC778\uC815\uBCF4\uB97C \uC0AC\uC6A9\uD569\uB2C8\uB2E4. \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>\uB2F9\uC0AC \uC6F9\uC0AC\uC774\uD2B8\uC758 \uAC1C\uC778\uC815\uBCF4 \uBCF4\uD638 \uC139\uC158</0>\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_bankDetails_terms_consent3 = "\uBCF8\uC778\uC740 \uAE30\uC874 \uACB0\uC81C \uBC29\uBC95\uACFC \uB2E4\uB978 \uBC29\uBC95\uC73C\uB85C \uD658\uBD88\uC774 \uCC98\uB9AC\uB418\uACE0 \uC0C1\uAE30 \uC81C\uACF5\uD55C \uC740\uD589 \uACC4\uC88C\uB85C \uD658\uBD88\uC774 \uCC98\uB9AC\uB418\uB294 \uB370 \uB3D9\uC758\uD569\uB2C8\uB2E4. \uD574\uB2F9 \uACC4\uC88C \uC815\uBCF4\uAC00 \uBD80\uC815\uD655\uD560 \uACBD\uC6B0 \uC798\uBABB\uB41C \uACC4\uC88C\uB85C \uC785\uAE08\uB418\uB354\uB77C\uB3C4 \uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uC774\uC5D0 \uCC45\uC784\uC774 \uC5C6\uC74C\uC744 \uC774\uD574\uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_bankDetails_terms_title3 = "\uC774\uC6A9 \uADDC\uC815";
var APPB2C_common_cancelBooking_bankDetails_title3 = "\uC740\uD589 \uACC4\uC88C \uC815\uBCF4";
var APPB2C_common_cancelBooking_bottomNotes_note13 = "\uCDE8\uC18C \uC2E0\uCCAD\uC744 \uC811\uC218\uD55C \uD6C4 \uCD94\uAC00 \uC815\uBCF4\uAC00 \uD544\uC694\uD55C \uACBD\uC6B0\uC5D0\uB294 \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC5D0\uC11C \uC9C1\uC811 \uC5F0\uB77D\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_bottomNotes_note23 = "\uC751\uC2DC\uB8CC \uD658\uBD88\uC5D0\uB294 \uC2B9\uC778\uC774 \uD544\uC694\uD558\uBA70 1\uC8FC\uC77C \uC774\uB0B4\uC5D0 \uADC0\uD558\uC758 \uCDE8\uC18C \uC2E0\uCCAD\uC5D0 \uB300\uD55C \uC2B9\uC778/\uAC70\uBD80 \uC5EC\uBD80\uB97C \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4. \uB610\uD55C, \uD658\uBD88\uC561\uC5D0\uC11C \uC218\uC218\uB8CC\uAC00 \uCC28\uAC10\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. <0>IELTS \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uC77D\uC5B4\uBCF4\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4. ";
var APPB2C_common_cancelBooking_bottomNotes_note33 = "\uCDE8\uC18C \uC2E0\uCCAD\uC774 \uC2B9\uC778\uB418\uBA74 \uC2E0\uCCAD\uC11C \uC81C\uCD9C\uC77C\uB85C\uBD80\uD130 3\uC8FC \uC774\uB0B4\uC5D0 \uC751\uC2DC\uB8CC\uAC00 \uD658\uBD88\uB429\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_bottomNotes_terms3 = "\uCDE8\uC18C \uC2E0\uCCAD\uC11C\uB97C \uC81C\uCD9C\uD558\uAE30 \uC804\uC5D0 <0>IELTS \uC5F0\uAE30, \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uC790\uC138\uD788 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_cancelNotes_13 = "\uCDE8\uC18C \uC2E0\uCCAD\uC5D0\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC2B9\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_cancelNotes_23 = "\uD658\uBD88\uC561\uC5D0\uC11C \uC218\uC218\uB8CC\uAC00 \uCC28\uAC10\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_cancelNotes_33 = "\uC2E0\uCCAD\uC11C\uB97C \uC81C\uCD9C\uD558\uAE30 \uC804\uC5D0 <0>IELTS \uC5F0\uAE30, \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uC790\uC138\uD788 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624";
var APPB2C_common_cancelBooking_checkTest3 = "\uC774 \uC2DC\uD5D8\uC744 \uCDE8\uC18C\uD558\uB294 \uAC83\uC774 \uB9DE\uB294\uC9C0 \uD655\uC778\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_cta_addBankDetails3 = "\uC740\uD589 \uACC4\uC88C \uC815\uBCF4 \uCD94\uAC00";
var APPB2C_common_cancelBooking_cta_goBack3 = "\uCDE8\uC18C \uC0AC\uC720 \uC218\uC815\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_common_cancelBooking_cta_submit3 = "\uCDE8\uC18C \uC2E0\uCCAD \uC81C\uCD9C";
var APPB2C_common_cancelBooking_error_cancelledAlready3 = "\uC774 \uC608\uC57D\uC740 \uC774\uBBF8 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_error_missingFile3 = "\uCDE8\uC18C \uC0AC\uC720\uB97C \uB4B7\uBC1B\uCE68\uD558\uB294 \uC99D\uBE59 \uC790\uB8CC\uB97C \uC5C5\uB85C\uB4DC\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_error_requestedAlready3 = "\uC774 \uC608\uC57D\uC5D0 \uB300\uD55C \uCDE8\uC18C \uC694\uCCAD\uC774 \uC774\uBBF8 \uC774\uB8E8\uC5B4\uC84C\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_reason_form_choose3 = "\uC635\uC158\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624";
var APPB2C_common_cancelBooking_reason_form_freeform3 = "\uC790\uC138\uD55C \uC124\uBA85\uC744 \uC544\uB798\uC5D0 \uCD94\uAC00\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_reason_form_optional3 = "(\uC120\uD0DD)";
var APPB2C_common_cancelBooking_reason_form_requiredEvidence3 = "\uC99D\uBE59\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_reason_form_requiredReason3 = "\uC608\uC57D \uCDE8\uC18C \uC0AC\uC720\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_reason_form_title3 = "\uC2DC\uD5D8 \uCDE8\uC18C \uC0AC\uC720\uB294 \uBB34\uC5C7\uC785\uB2C8\uAE4C?";
var APPB2C_common_cancelBooking_reason_note_content3 = "\uC2DC\uD5D8\uC77C\uAE4C\uC9C0 5\uC8FC \uBBF8\uB9CC\uC774 \uB0A8\uC558\uC73C\uBBC0\uB85C \uCDE8\uC18C \uC0AC\uC720\uB97C \uAE30\uC7AC\uD558\uACE0 \uAD00\uB828 \uBB38\uC11C/\uC99D\uBE59\uC744 \uCCA8\uBD80\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_cancelBooking_reason_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_cancelBooking_subtitle3 = "\uC2DC\uD5D8 \uCDE8\uC18C\uB97C \uC6D0\uD558\uC2DC\uB098\uC694?";
var APPB2C_common_cancelBooking_title3 = "\uC2DC\uD5D8 \uC608\uC57D \uCDE8\uC18C";
var APPB2C_common_cancelBooking_transferNote_cta3 = "\uC5F0\uAE30 \uC2E0\uCCAD";
var APPB2C_common_cancelBooking_transferNote_title3 = "\uC608\uC57D\uC744 \uB2E4\uB978 \uB0A0\uC9DC\uB098 \uC7A5\uC18C\uB85C \uC5F0\uAE30\uD560 \uC218 \uC788\uB2E4\uB294 \uAC83\uC744 \uC54C\uACE0 \uACC4\uC168\uB098\uC694?";
var APPB2C_common_cancelBooking_transferNote_titleIol3 = "\uC608\uC57D\uC744 \uB2E4\uB978 \uB0A0\uC9DC\uB85C \uC5F0\uAE30\uD560 \uC218 \uC788\uB2E4\uB294 \uAC83\uC744 \uC54C\uACE0 \uACC4\uC168\uB098\uC694?";
var APPB2C_common_cancelBooking_upload_mobile3 = "\uD604\uC7AC \uBAA8\uBC14\uC77C \uAE30\uAE30\uB85C \uC811\uC18D\uD558\uC168\uC73C\uBBC0\uB85C \uD574\uB2F9 \uAE30\uAE30\uB97C \uD1B5\uD574 \uC0AC\uC9C4\uC744 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_13 = "\uC804\uBB38 \uC758\uB8CC\uC778\uC774 \uBC1C\uAE09\uD55C \uC9C4\uB2E8\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4. \uC9C4\uB2E8\uC11C\uC5D0\uB294 \uC9C8\uBCD1\uC758 \uC815\uD655\uD55C \uC885\uB958\uC640 \uC774 \uC2E0\uCCAD\uC744 \uC2EC\uC0AC\uD568\uC5D0 \uC788\uC5B4 \uB3C4\uC6C0\uC774 \uB420 \uB9CC\uD55C \uC815\uBCF4\uAC00 \uBA85\uC2DC\uB418\uC5B4\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_103 = "\uADC0\uD558\uAC00 \uD0C0\uB2F9\uD558\uB2E4\uACE0 \uC0DD\uAC01\uD558\uB294 \uD2B9\uC815\uD55C \uC0AC\uC720\uB85C \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_23 = "\uAD70 \uBCF5\uBB34\uB85C \uC778\uD574 \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_33 = "\uAC00\uC871\uC758 \uC0AC\uB9DD\uC73C\uB85C \uC778\uD574 \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_43 = "\uC2DC\uD5D8\uC744 \uC911\uBCF5\uD574\uC11C \uC608\uC57D\uD558\uC5EC \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_53 = "\uACF5\uBB34 \uB610\uB294 \uACF5\uC801 \uBAA9\uC801\uC758 \uC5EC\uD589\uC73C\uB85C \uC778\uD574 \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_63 = "\uADC0\uD558\uC758 \uD1B5\uC81C\uD560 \uC218 \uC5C6\uB294 \uC0AC\uAC74\uC73C\uB85C \uC778\uD574 \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_73 = "\uC2DC\uD5D8 \uB2F9\uC77C \uBC1C\uC0DD\uD55C \uAE30\uC220\uC801 \uBB38\uC81C\uB85C \uC778\uD574 \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_notes_83 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uADC0\uD558\uC758 \uC2DC\uD5D8\uC744 \uCDE8\uC18C\uD558\uC5EC \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC5C8\uC74C\uC744 \uC99D\uBE59\uD558\uB294 \uBB38\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_requirements_note3 = "\uC5C5\uB85C\uB4DC \uB41C \uC99D\uBE59\uC790\uB8CC\uB294 \uC77D\uAE30 \uC26C\uC6CC\uC57C \uD569\uB2C8\uB2E4. \uD30C\uC77C\uC740 4\uAC1C\uAE4C\uC9C0 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_cancelBooking_upload_requirements_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_cancelBooking_upload_title3 = "\uC99D\uBE59\uC790\uB8CC \uC5C5\uB85C\uB4DC";
var APPB2C_common_changeBooking_cancel_cta3 = "\uCDE8\uC18C \uC2E0\uCCAD";
var APPB2C_common_changeBooking_cancel_text3 = "\uC2DC\uD5D8\uC744 \uCDE8\uC18C\uD558\uACE0 \uC2F6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_changeBooking_goBack3 = "\uC608\uC57D\uC744 \uBCC0\uACBD\uD558\uC9C0 \uC54A\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_common_changeBooking_subtitle3 = "\uC774 \uC608\uC57D\uC758 \uBB34\uC5C7\uC744 \uBC14\uAFB8\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?";
var APPB2C_common_changeBooking_title3 = "\uC608\uC57D \uBCC0\uACBD";
var APPB2C_common_changeBooking_transfer_cta3 = "\uC5F0\uAE30 \uC2E0\uCCAD";
var APPB2C_common_changeBooking_transfer_text3 = "\uC2DC\uD5D8 \uB0A0\uC9DC, \uC2DC\uAC04 \uB610\uB294 \uC7A5\uC18C\uB97C \uBCC0\uACBD\uD558\uACE0 \uC2F6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBooking_transfer_textIol3 = "\uC2DC\uD5D8\uC744 \uB2E4\uB978 \uB0A0\uC9DC\uB85C \uC5F0\uAE30\uD558\uACE0 \uC2F6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBooking_transferNotes_13 = "\uC2DC\uD5D8 \uC5F0\uAE30 \uB610\uB294 \uCDE8\uC18C \uC2E0\uCCAD\uC5D0\uB294 \uC2DC\uD5D8 \uC13C\uD130\uC758 \uC2B9\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_changeBooking_transferNotes_23 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC2E0\uCCAD\uC5D0 \uB300\uD574 \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBooking_transferNotesIol_13 = "\uC2DC\uD5D8 \uC5F0\uAE30 \uB610\uB294 \uCDE8\uC18C\uC5D0\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC2B9\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_changeBooking_transferNotesIol_23 = "\uC2E0\uCCAD\uC11C\uB97C \uC81C\uCD9C\uD558\uAE30 \uC804\uC5D0 <0>IELTS \uC5F0\uAE30, \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uC790\uC138\uD788 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624";
var APPB2C_common_changeBookingAck_back3 = "\uB0B4 \uC2DC\uD5D8 \uC608\uC57D\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_common_changeBookingAck_cancel_banner3 = "\uADC0\uD558\uC758 \uC2DC\uD5D8 \uCDE8\uC18C \uC2E0\uCCAD\uC774 \uC811\uC218\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_cancel_contact_email3 = "\uC774\uBA54\uC77C";
var APPB2C_common_changeBookingAck_cancel_contact_phone3 = "\uC804\uD654";
var APPB2C_common_changeBookingAck_cancel_contact_title3 = "\uB354 \uAD81\uAE08\uD558\uC2E0 \uC810\uC740 \uB2E4\uC74C \uC5F0\uB77D\uCC98\uB85C \uBB38\uC758\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_cancel_contact_titleIol3 = "\uB354 \uAD81\uAE08\uD558\uC2E0 \uC810\uC740 <0>\uB3C4\uC6C0\uB9D0 \uD398\uC774\uC9C0\uB97C \uCC38\uC870\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4</0>.";
var APPB2C_common_changeBookingAck_cancel_refNr3 = "\uADC0\uD558\uC5D0\uAC8C \uC2E0\uC18D\uD788 \uB3C4\uC6C0\uC744 \uB4DC\uB9B4 \uC218 \uC788\uB3C4\uB85D IELTS reference number\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_changeBookingAck_cancel_refunds3 = "\uC751\uC2DC\uB8CC \uD658\uBD88\uC5D0\uB294 \uC2B9\uC778\uC774 \uD544\uC694\uD558\uBA70 1\uC8FC\uC77C \uC774\uB0B4\uC5D0 \uCDE8\uC18C \uC2E0\uCCAD\uC5D0 \uB300\uD55C \uC2B9\uC778/\uAC70\uBD80 \uC5EC\uBD80\uB97C \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_cancel_respondTime3 = "\uCE74\uB4DC \uAC70\uB798 \uCDE8\uC18C\uB098 \uC740\uD589 \uACC4\uC88C \uC785\uAE08\uC744 \uD1B5\uD55C \uD658\uBD88\uC740 \uCD5C\uB300 3\uC8FC\uAC00 \uC18C\uC694\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_cancel_terms3 = "\uC2DC\uD5D8 \uC5F0\uAE30 \uC815\uCC45\uC5D0 \uB300\uD55C \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>IELTS \uC5F0\uAE30, \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624";
var APPB2C_common_changeBookingAck_cancel_title3 = "\uCDE8\uC18C \uC2E0\uCCAD \uD655\uC778";
var APPB2C_common_changeBookingAck_fees3 = "\uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uC5D0\uAC8C \uC9C1\uC811 \uC5F0\uB77D\uD560 \uB54C \uC54C\uB824\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_feesIol3 = "\uC218\uC218\uB8CC\uAC00 \uBC1C\uC0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uC2E0\uCCAD\uC774 \uC2B9\uC778\uB418\uBA74 \uACB0\uC81C \uBC29\uBC95\uC744 \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_speaking_banner3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5F0\uAE30\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_speaking_note_line13 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC77C\uC815 \uBCC0\uACBD\uC740 \uD544\uAE30 \uC2DC\uD5D8\uC5D0 \uC601\uD5A5\uC744 \uC8FC\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_speaking_note_line23 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC77C\uC815 \uBCC0\uACBD\uC5D0\uB294 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_speaking_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_changeBookingAck_speaking_title3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC5F0\uAE30";
var APPB2C_common_changeBookingAck_terms3 = "\uC2DC\uD5D8 \uC5F0\uAE30 \uC815\uCC45\uC5D0 \uB300\uD55C \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>\uC774\uC6A9 \uADDC\uC815</0>\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_changeBookingAck_transfer_banner3 = "\uC5F0\uAE30 \uC2E0\uCCAD\uACFC \uAD00\uB828\uD558\uC5EC \uC2DC\uD5D8 \uC13C\uD130 \uB2F4\uB2F9\uC790 \uC0C1\uB2F4\uC744 \uC694\uCCAD\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_transfer_note3 = "\uC2DC\uD5D8\uC77C\uC744 \uB2E4\uC2DC \uC815\uD558\uAE30 \uC704\uD574 \uC9C1\uC811 \uC5F0\uB77D\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uAC00 \uC0C8 \uC2DC\uD5D8\uC77C\uC744 \uC218\uB77D\uD558\uC2DC\uBA74 \uC218\uC815\uB41C \uC2DC\uD5D8 \uC608\uC57D \uD655\uC778\uC11C\uB97C \uBCF4\uB0B4\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_transfer_respondTime3 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD\uC5D0 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_transfer_title3 = "\uC5F0\uAE30 \uC2E0\uCCAD \uD655\uC778";
var APPB2C_common_changeBookingAck_transferIol_banner3 = "\uADC0\uD558\uC758 \uC2DC\uD5D8 \uCDE8\uC18C \uC2E0\uCCAD\uC774 \uC811\uC218\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_transferIol_contact3 = "\uB354 \uAD81\uAE08\uD558\uC2E0 \uC810\uC740 <0>\uB3C4\uC6C0\uB9D0 \uD398\uC774\uC9C0\uB97C \uCC38\uC870\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4</0>.";
var APPB2C_common_changeBookingAck_transferIol_note3 = "\uC2DC\uD5D8\uC77C\uC744 \uB2E4\uC2DC \uC815\uD558\uAE30 \uC704\uD574 \uC9C1\uC811 \uC5F0\uB77D\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uAC00 \uC0C8 \uC2DC\uD5D8\uC77C\uC744 \uC218\uB77D\uD558\uC2DC\uBA74 \uC218\uC815\uB41C \uC2DC\uD5D8 \uC608\uC57D \uD655\uC778\uC11C\uB97C \uBCF4\uB0B4\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeBookingAck_transferIol_refNr3 = "\uADC0\uD558\uC5D0\uAC8C \uC2E0\uC18D\uD788 \uB3C4\uC6C0\uC744 \uB4DC\uB9B4 \uC218 \uC788\uB3C4\uB85D IELTS reference number\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_changeBookingAck_transferIol_terms3 = "\uC2DC\uD5D8 \uC5F0\uAE30 \uC815\uCC45\uC5D0 \uB300\uD55C \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>IELTS \uC5F0\uAE30, \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624";
var APPB2C_common_changeSpeaking_cards_change3 = "\uC2DC\uD5D8 \uBCC0\uACBD";
var APPB2C_common_changeSpeaking_cards_noSlots3 = "\uBE48 \uC790\uB9AC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_changeSpeaking_cards_noSlots_error3 = "\uC120\uD0DD\uD558\uC2E0 \uC2DC\uAC04\uB300\uC758 \uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC218\uC6A9 \uC778\uC6D0\uC774 \uCD08\uACFC\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uB2E4\uB978 \uC2DC\uAC04\uB300\uB97C \uC120\uD0DD\uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_changeSpeaking_cards_title3 = "\uC0C8 \uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_changeSpeaking_cta3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC5F0\uAE30";
var APPB2C_common_changeSpeaking_modals_noSlots_keepTest3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC720\uC9C0";
var APPB2C_common_changeSpeaking_modals_noSlots_line13 = "\uD655\uC778 \uACB0\uACFC \uC751\uC2DC \uAC00\uB2A5\uD55C \uB2E4\uB978 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. \uC8C4\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_modals_noSlots_line23 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC744 \uBCC0\uACBD\uD574\uC57C \uD558\uB294 \uACBD\uC6B0 \uC2DC\uD5D8 \uB0A0\uC9DC \uBCC0\uACBD\uC744 \uC694\uCCAD\uD558\uBA74 \uC0C8 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC744 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_modals_noSlots_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC751\uC2DC \uAC00\uB2A5\uD55C \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_modals_noSlots_transferTest3 = "\uB300\uC2E0 \uC5F0\uAE30 \uC2E0\uCCAD";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_cta3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uB2E4\uC2DC \uC120\uD0DD";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line13 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uD655\uC778 \uACB0\uACFC \uADC0\uD558\uAC00 \uC120\uD0DD\uD558\uC2E0 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC740 \uC751\uC2DC\uAC00 \uBD88\uAC00\uD569\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line23 = "\uB2E4\uB978 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC744 \uC120\uD0DD\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_title3 = "\uC774 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC740 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC720\uC9C0";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_line13 = "\uD655\uC778 \uACB0\uACFC \uC751\uC2DC \uAC00\uB2A5\uD55C \uB2E4\uB978 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. \uC8C4\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC751\uC2DC \uAC00\uB2A5\uD55C \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_note_back3 = "\uC608\uC57D\uC744 \uBCC0\uACBD\uD558\uC9C0 \uC54A\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_common_changeSpeaking_note_line13 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC77C\uC815 \uBCC0\uACBD\uC740 \uD544\uAE30 \uC2DC\uD5D8\uC5D0 \uC601\uD5A5\uC744 \uC8FC\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_note_line23 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC77C\uC815 \uBCC0\uACBD\uC5D0\uB294 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_changeSpeaking_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_changeSpeaking_title3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC77C\uC815 \uBCC0\uACBD";
var APPB2C_common_chooseTest_difference3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS\uC640 IELTS Online\uB294 \uBB34\uC5C7\uC774 \uB2E4\uB978\uAC00\uC694?";
var APPB2C_common_chooseTest_iol_consent3 = "IELTS Online\uC774 \uC548\uBA74 \uC778\uC2DD \uAE30\uC220\uACFC \uC5EC\uD0C0 \uC2DC\uD5D8 \uAC10\uB3C5 \uAE30\uC220\uC744 \uC774\uC6A9\uD558\uC5EC \uC2DC\uD5D8 \uC911\uC5D0 \uBCF8\uC778\uC744 \uC548\uC804\uD558\uAC8C \uBAA8\uB2C8\uD130\uB9C1 \uD558\uB294 \uAC83\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_iol_cta3 = "IELTS Online \uC608\uC57D";
var APPB2C_common_chooseTest_iol_desc3 = "\uC774\uC81C \uC5B8\uC81C \uC5B4\uB514\uC11C\uB4E0 \uC0C8\uB86D\uACE0 \uC548\uC804\uD55C \uBC29\uC2DD\uC73C\uB85C IELTS \uC2DC\uD5D8\uC744 \uBCF4\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4. IELTS Online\uC740 \uC628\uB77C\uC778 \uC2DC\uD5D8\uACFC \uBE44\uB514\uC624 \uBAA8\uB2C8\uD130\uB9C1\uC758 \uC7A5\uC810\uC744 \uACB0\uD569\uD558\uC5EC \uC2DC\uD5D8\uC758 \uBCF4\uC548\uC131\uACFC \uC131\uC801\uC758 \uC2E0\uB8B0\uC131\uC744 \uD655\uBCF4\uD588\uC2B5\uB2C8\uB2E4. ";
var APPB2C_common_chooseTest_iol_link3 = "IELTS Online \uC18C\uD504\uD2B8\uC6E8\uC5B4\uC5D0 \uB300\uD574 \uC54C\uC544\uBCF4\uAE30";
var APPB2C_common_chooseTest_iol_note_content3 = "IELTS Online \uC2DC\uD5D8\uC740 18\uC138 \uC774\uC0C1\uB9CC \uC751\uC2DC\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. 18\uC138 \uBBF8\uB9CC\uC758 \uC0AC\uB78C\uC744 \uB300\uC2E0\uD558\uC5EC \uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uB294 \uACBD\uC6B0 \uC2DC\uD5D8 \uC13C\uD130 IELTS\uB97C \uC608\uC57D\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_chooseTest_iol_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_chooseTest_iol_title3 = "IELTS \uC628\uB77C\uC778";
var APPB2C_common_chooseTest_ors_cta3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS \uC608\uC57D\uD558\uAE30";
var APPB2C_common_chooseTest_ors_descAc3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uACF5\uC778 \uC2DC\uD5D8 \uC13C\uD130\uC5D0\uC11C IELTS Academic\uC5D0 \uC751\uC2DC\uD558\uC138\uC694. \uC9C0\uD544 IELTS\uC640 \uCEF4\uD4E8\uD130 IELTS \uC911 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_ors_descGt3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uACF5\uC778 \uC2DC\uD5D8 \uC13C\uD130\uC5D0\uC11C IELTS for UKVI General Training\uC5D0 \uC751\uC2DC\uD558\uC138\uC694. \uC9C0\uD544 IELTS\uC640 \uCEF4\uD4E8\uD130 IELTS \uC911 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_ors_title3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS";
var APPB2C_common_chooseTest_title3 = "\uC2DC\uD5D8 \uC120\uD0DD";
var APPB2C_common_chooseTest_ukvi_difference3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS for UKVI\uC640 IELTS for UKVI Online\uC740 \uBB34\uC5C7\uC774 \uB2E4\uB978\uAC00\uC694?";
var APPB2C_common_chooseTest_ukvi_inCentre_cta3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS for UKVI \uC608\uC57D\uD558\uAE30";
var APPB2C_common_chooseTest_ukvi_inCentre_descAc3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uACF5\uC778 \uC2DC\uD5D8 \uC13C\uD130\uC5D0\uC11C IELTS for UKVI Academic\uC5D0 \uC751\uC2DC\uD558\uC138\uC694. \uC9C0\uD544 IELTS\uC640 \uCEF4\uD4E8\uD130 IELTS \uC911 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_ukvi_inCentre_descGt3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uACF5\uC778 \uC2DC\uD5D8 \uC13C\uD130\uC5D0\uC11C IELTS for UKVI General Training\uC5D0 \uC751\uC2DC\uD558\uC138\uC694. \uC9C0\uD544 IELTS\uC640 \uCEF4\uD4E8\uD130 IELTS \uC911 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_ukvi_inCentre_title3 = "\uC2DC\uD5D8 \uC13C\uD130 IELTS for UKVI";
var APPB2C_common_chooseTest_ukvi_online_consent3 = "IELTS Online\uC774 \uC548\uBA74 \uC778\uC2DD \uAE30\uC220\uACFC \uC5EC\uD0C0 \uC2DC\uD5D8 \uAC10\uB3C5 \uAE30\uC220\uC744 \uC774\uC6A9\uD558\uC5EC \uC2DC\uD5D8 \uC911\uC5D0 \uBCF8\uC778\uC744 \uC548\uC804\uD558\uAC8C \uBAA8\uB2C8\uD130\uB9C1 \uD558\uB294 \uAC83\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol3 = "(\uC5EC\uAD8C\uC5D0 \uC774 \uAE30\uD638\uAC00 \uC788\uB294\uC9C0 \uD655\uC778\uD558\uC138\uC694!)";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_title3 = "RFID\uCE69\uC774 \uB0B4\uC7A5\uB41C \uC720\uD6A8\uD55C \uC804\uC790\uC5EC\uAD8C\uC744 \uAC00\uC9C0\uACE0 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_chooseTest_ukvi_online_cta3 = "IELTS for UKVI Online \uC608\uC57D";
var APPB2C_common_chooseTest_ukvi_online_link3 = "IELTS for UKVI Online \uC18C\uD504\uD2B8\uC6E8\uC5B4 \uC790\uC138\uD788 \uC54C\uC544\uBCF4\uAE30";
var APPB2C_common_chooseTest_ukvi_online_note_content3 = "IELTS Online \uC2DC\uD5D8\uC740 18\uC138 \uC774\uC0C1\uB9CC \uC751\uC2DC\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. 18\uC138 \uBBF8\uB9CC\uC758 \uC0AC\uB78C\uC744 \uB300\uC2E0\uD558\uC5EC \uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uB294 \uACBD\uC6B0 \uC2DC\uD5D8 \uC13C\uD130 IELTS\uB97C \uC608\uC57D\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_chooseTest_ukvi_online_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_chooseTest_ukvi_online_title3 = "IELTS for UKVI Online";
var APPB2C_common_draft_cancel2 = "\uCDE8\uC18C";
var APPB2C_common_draft_cta_confirm3 = "\uB0B4 \uC815\uBCF4 \uD655\uC778";
var APPB2C_common_draft_cta_decline3 = "\uC800\uC758 \uAC1C\uC778\uC815\uBCF4\uB97C \uB300\uD589\uC5C5\uCCB4\uC5D0 \uD655\uC778\uD558\uC9C0 \uB9C8\uC2ED\uC2DC\uC624";
var APPB2C_common_draft_cta_note3 = "\uAC1C\uC778\uC815\uBCF4\uC5D0 \uC774\uC0C1\uC774 \uC5C6\uC74C\uC744 \uADC0\uD558\uAC00 \uD655\uC778\uD558\uC9C0 \uC54A\uC73C\uBA74 {{agent}}\uAC00 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uC2DC\uD5D8\uC5D0 \uB4F1\uB85D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uADF8\uB7EC\uB098 \uADC0\uD558\uAC00 \uC9C1\uC811 \uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uB294 \uAC83\uC740 \uC5EC\uC804\uD788 \uAC00\uB2A5\uD569\uB2C8\uB2E4";
var APPB2C_common_draft_difference3 = "\uB300\uD589\uC5C5\uCCB4\uAC00 \uB2E4\uC74C\uACFC \uAC19\uC740 \uB2F5\uBCC0\uC744 \uCD94\uAC00\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_draft_edit3 = "\uBCC0\uACBD";
var APPB2C_common_draft_error_pastDeadline3 = "\uC138\uBD80 \uC815\uBCF4 \uD655\uC778 \uAE30\uD55C\uC774 \uC9C0\uB0AC\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_draft_id_cta3 = "\uC2E0\uBD84\uC99D \uC5C5\uB370\uC774\uD2B8";
var APPB2C_common_draft_id_documentWithNoExpiryDate3 = "\uC2E0\uBD84\uC99D\uC5D0 \uC720\uD6A8\uAE30\uAC04\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_draft_id_header3 = "\uADC0\uD558\uC758 \uC2E0\uBD84\uC99D";
var APPB2C_common_draft_id_issuingAuthority3 = "\uBC1C\uAE09\uAE30\uAD00";
var APPB2C_common_draft_id_note3 = "\uC2DC\uD5D8 \uB2F9\uC77C\uC5D0\uB294 \uC544\uB798 \uC81C\uACF5\uD558\uC2E0 \uAC83\uACFC \uB3D9\uC77C\uD55C \uC2E0\uBD84\uC99D\uC744 \uC9C0\uCC38\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_draft_id_title3 = "\uC2E0\uBD84\uC99D \uC815\uBCF4 \uD655\uC778";
var APPB2C_common_draft_note_line13 = "\uADC0\uD558\uAC00 \uC774 \uC0AC\uC774\uD2B8\uB97C \uB9C8\uC9C0\uB9C9\uC73C\uB85C \uBC29\uBB38\uD55C \uD6C4 \uADC0\uD558\uC758 \uB300\uD589\uC5C5\uCCB4\uC778 ({{agent}})\uC774(\uAC00) \uADC0\uD558\uC758 \uC2DC\uD5D8\uC744 \uB4F1\uB85D\uD558\uBA74\uC11C \uADC0\uD558\uC5D0 \uB300\uD55C \uC815\uBCF4\uB97C \uCD94\uAC00\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_draft_note_line23 = "\uB300\uD589\uC5C5\uCCB4\uAC00 \uCD94\uAC00\uD55C \uC544\uD574\uC758 \uC815\uBCF4\uB97C \uD655\uC778\uD558\uACE0 \uC798\uBABB\uC774 \uC788\uC73C\uBA74 \uC218\uC815\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_common_draft_note_line33 = "\uAC1C\uC778\uC815\uBCF4\uC5D0 \uC774\uC0C1\uC774 \uC5C6\uC74C\uC744 \uADC0\uD558\uAC00 \uD655\uC778\uD558\uC9C0 \uC54A\uC73C\uBA74 {{agent}}\uAC00 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uC2DC\uD5D8\uC5D0 \uB4F1\uB85D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_draft_personal_cta3 = "\uAC1C\uC778\uC815\uBCF4 \uC5C5\uB370\uC774\uD2B8";
var APPB2C_common_draft_personal_header3 = "\uADC0\uD558\uC758 \uC815\uBCF4";
var APPB2C_common_draft_personal_note3 = "\uC774 \uD398\uC774\uC9C0\uC5D0 \uC81C\uACF5\uB41C \uBAA8\uB4E0 \uAC1C\uC778 \uC815\uBCF4\uB294 \uB4F1\uB85D\uC5D0 \uC0AC\uC6A9\uB41C \uC2DC\uD5D8 \uC751\uC2DC\uC790\uC758 \uC2E0\uBD84\uC99D\uACFC \uC77C\uCE58\uD574\uC57C \uD558\uBA70 \uC2DC\uD5D8 \uC7A5\uC18C\uC5D0 \uC81C\uC2DC\uD574\uC57C \uD558\uBA70, \uC774\uB294 \uC2DC\uD5D8 \uC751\uC2DC\uC790\uC758 \uC2DC\uD5D8 \uBCF4\uACE0\uC11C \uC591\uC2DD(TRF)\uC5D0 \uD45C\uC2DC\uB429\uB2C8\uB2E4. \uB4F1\uB85D\uC774 \uC644\uB8CC\uB418\uBA74 TRF\uB97C \uBC1C\uD589\uD558\uAE30 \uC804\uC5D0 \uC774 \uC815\uBCF4\uB97C \uC218\uC815\uD558\uB3C4\uB85D \uC694\uCCAD\uD558\uB294 \uACBD\uC6B0 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4. TRF\uAC00 \uBC1C\uD589\uB41C \uD6C4\uC5D0\uB294 \uC218\uC815\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_draft_personal_title3 = "\uAC1C\uC778\uC815\uBCF4 \uD655\uC778";
var APPB2C_common_draft_title3 = "\uAC1C\uC778\uC815\uBCF4 \uD655\uC778";
var APPB2C_common_examType_iol_ac3 = "IELTS Academic (\uC628\uB77C\uC778)";
var APPB2C_common_examType_iol_gt3 = "IELTS General Training (\uC628\uB77C\uC778)";
var APPB2C_common_examType_ors_ac3 = "IELTS Academic";
var APPB2C_common_examType_ors_gt3 = "IELTS General Training";
var APPB2C_common_examType_ors_osr_ac3 = "\uC544\uC774\uC5D8\uCE20 \uC544\uCE74\uB370\uBBF9";
var APPB2C_common_examType_ors_osr_gt3 = "IELTS General Training(\uC544\uC774\uC5D8\uCE20 \uC81C\uB108\uB7F4";
var APPB2C_common_examType_ukvi_ac3 = "IELTS for UKVI Academic";
var APPB2C_common_examType_ukvi_gt3 = "IELTS for UKVI General Training";
var APPB2C_common_examType_ukvi_lfska13 = "IELTS Life Skills A1";
var APPB2C_common_examType_ukvi_lfska23 = "IELTS Life Skills A2";
var APPB2C_common_examType_ukvi_lfskb13 = "IELTS Life Skills B1";
var APPB2C_common_examType_ukvi_osr_ac3 = "UKVI \uC544\uCE74\uB370\uBBF9\uC744 \uC704\uD55C IELTS";
var APPB2C_common_examType_ukvi_osr_gt3 = "IELTS UKVI \uC81C\uB108\uB7F4 \uD2B8\uB808\uC774\uB2DD";
var APPB2C_common_examType_uol_ac3 = "IELTS for UKVI (Academic) \uC628\uB77C\uC778";
var APPB2C_common_examType_uol_gt3 = "IELTS for UKVI (General Training) \uC628\uB77C\uC778";
var APPB2C_common_filePreview_label3 = "\uC774\uBBF8\uC9C0";
var APPB2C_common_filePreview_pdfPreviewLink3 = "\uD30C\uC77C \uBBF8\uB9AC\uBCF4\uAE30";
var APPB2C_common_filePreview_status3 = "\uAC80\uD1A0 \uC0C1\uD0DC";
var APPB2C_common_filePreview_statusName_approved3 = "\uC2B9\uC778\uB428";
var APPB2C_common_filePreview_statusName_deleted3 = "\uC0AD\uC81C\uB428";
var APPB2C_common_filePreview_statusName_pending3 = "\uBCF4\uB958 \uC911";
var APPB2C_common_filePreview_statusName_rejected3 = "\uAC70\uBD80\uB428";
var APPB2C_common_filePreview_statusName_reviewed3 = "\uAC80\uD1A0\uB428";
var APPB2C_common_fileUpload_cta3 = "\uD30C\uC77C \uC120\uD0DD";
var APPB2C_common_fileUpload_exceededSize3 = "\uD30C\uC77C\uC774 \uB2E4\uC74C \uCD5C\uB300 \uD5C8\uC6A9 \uD06C\uAE30\uB97C \uCD08\uACFC\uD588\uC2B5\uB2C8\uB2E4";
var APPB2C_common_fileUpload_fileApproved3 = "\uD30C\uC77C\uC774 \uC774\uBBF8 \uC2B9\uC778\uB418\uC5C8\uC73C\uBBC0\uB85C \uC0AD\uC81C\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_fileUpload_fileOptional3 = "\uC774\uBBF8\uC9C0 {{number}} (\uC120\uD0DD)";
var APPB2C_common_fileUpload_fileRequired3 = "\uC774\uBBF8\uC9C0 1";
var APPB2C_common_fileUpload_filesCount3 = "{{total}} \uC911 {{current}} \uD398\uC774\uC9C0";
var APPB2C_common_fileUpload_fileSize3 = "{{size}}MB \uBBF8\uB9CC";
var APPB2C_common_fileUpload_imgAlt3 = "\uD30C\uC77C \uBBF8\uB9AC\uBCF4\uAE30";
var APPB2C_common_fileUpload_pdfIeMsg3 = "PDF \uD30C\uC77C\uC774 \uC131\uACF5\uC801\uC73C\uB85C \uC5C5\uB85C\uB4DC \uB418\uC5C8\uC73C\uB098 Internet Explorer\uC5D0\uC11C\uB294 \uBBF8\uB9AC\uBCF4\uAE30\uAC00 \uC9C0\uC6D0\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. Internet Explorer\uB294 2015\uB144 \uC774\uD6C4 \uC5C5\uB370\uC774\uD2B8\uAC00 \uC911\uB2E8\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_fileUpload_pdfPasswordMsg3 = "\uBCF4\uC548\uB41C \uD30C\uC77C\uC740 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_fileUpload_remove3 = "\uD30C\uC77C \uC0AD\uC81C";
var APPB2C_common_fileUpload_requirements3 = "\uC5C5\uB85C\uB4DC \uC81C\uD55C:";
var APPB2C_common_fileUpload_success3 = "<bold><italic>{{fileName}}</italic></bold> \uD30C\uC77C\uC774 \uC815\uC0C1\uC801\uC73C\uB85C \uCD94\uAC00\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_changeCity3 = "\uB3C4\uC2DC \uBCC0\uACBD";
var APPB2C_common_findTest_changeCountry3 = "\uAD6D\uAC00 \uBCC0\uACBD";
var APPB2C_common_findTest_cta3 = "\uC2DC\uD5D8 \uAC80\uC0C9";
var APPB2C_common_findTest_dates_clear3 = "\uCD08\uAE30\uD654";
var APPB2C_common_findTest_dates_from3 = "\uBD80\uD130";
var APPB2C_common_findTest_dates_noDatesSelected3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC120\uD0DD\uD558\uC2E0 \uB0A0\uC9DC\uC5D0 \uC751\uC2DC\uD558\uC2E4 \uC218 \uC788\uB294 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. \uB0A0\uC9DC \uBC94\uC704\uB97C \uB113\uD600\uC8FC\uC138\uC694.";
var APPB2C_common_findTest_dates_noresults_details3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC751\uC2DC \uAC00\uB2A5\uD55C \uC2DC\uD5D8\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. \uB2E4\uB978 \uC9C0\uC5ED\uC744 \uC120\uD0DD\uD558\uAC70\uB098 \uAC80\uC0C9\uC870\uAC74\uC744 \uBCC0\uACBD\uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_dates_noresults_title3 = "\uC2DC\uD5D8 \uB0A0\uC9DC\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_dates_noresultsMobileLocation_cta3 = "\uAD00\uC2EC \uB4F1\uB85D";
var APPB2C_common_findTest_dates_noresultsMobileLocation_details3 = "\uD604\uC7AC \uC774 \uC9C0\uC5ED\uC5D0\uC11C \uC751\uC2DC \uAC00\uB2A5\uD55C \uC2DC\uD5D8 \uB0A0\uC9DC\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uADF8\uB7EC\uB098 IELTS for UK Visas\uC5D0 \uB300\uD55C \uAD00\uC2EC \uB4F1\uB85D\uC744 \uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_dates_noresultsMobileLocation_title3 = "\uC2DC\uD5D8 \uB0A0\uC9DC\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_details3 = "\uC120\uD0DD\uD558\uC2E0 \uC9C0\uC5ED\uC5D0\uC11C\uB294 \uADC0\uD558\uAC00 \uC694\uCCAD\uD558\uC2E0 \uD2B9\uC218 \uC870\uCE58\uB97C \uC81C\uACF5\uD560 \uC218 \uC788\uB294 \uC2DC\uD5D8 \uB0A0\uC9DC\uAC00 \uAC80\uC0C9\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uC544\uB798 \uC815\uBCF4\uB97C \uCC38\uACE0\uD558\uC5EC \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC73C\uB85C \uC9C1\uC811 \uC5F0\uB77D\uC8FC\uC2DC\uBA74 \uB3C4\uC640\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC751\uC2DC \uAC00\uB2A5\uD55C \uC2DC\uD5D8\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_dates_pleaseSelect3 = "\uC544\uB798 \uB2EC\uB825\uC744 \uC774\uC6A9\uD558\uC2ED\uC2DC\uC624";
var APPB2C_common_findTest_dates_selected3 = "\uC120\uD0DD\uB428";
var APPB2C_common_findTest_dates_showAll3 = "\uC2DC\uD5D8 \uB0A0\uC9DC \uBAA8\uB450 \uBCF4\uAE30";
var APPB2C_common_findTest_dates_showDateRange3 = "\uB0A0\uC9DC\uB97C \uC120\uD0DD\uD558\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_dates_title3 = "\uC5B8\uC81C \uC751\uC2DC\uD558\uAE38 \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_findTest_dates_to3 = "\uAE4C\uC9C0";
var APPB2C_common_findTest_examFormat_all3 = "\uB458 \uB2E4 \uAD1C\uCC2E\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_examFormat_cd3 = "\uCEF4\uD4E8\uD130 IELTS";
var APPB2C_common_findTest_examFormat_pb3 = "\uC9C0\uD544 IELTS";
var APPB2C_common_findTest_examFormat_results3 = "(\uC131\uC801\uC740 {{days}}\uC77C \uD6C4 \uBC1C\uAE09)";
var APPB2C_common_findTest_examFormat_title3 = "\uC5B4\uB5A4 IELTS \uC2DC\uD5D8 \uBC29\uC2DD\uC744 \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_findTest_iol_noLrwResults3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uADC0\uD558\uC758 \uAD6D\uAC00\uC5D0\uC11C \uC751\uC2DC \uAC00\uB2A5\uD55C \uC2DC\uD5D8\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_iol_organisationCheck_check3 = "\uC9C0\uC6D0\uD558\uB824\uB294 \uAE30\uAD00\uC774 IELTS Online\uC744 \uC778\uC815\uD55C\uB2E4\uB294 \uAC83\uC744 \uD655\uC778\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_iol_organisationCheck_desc3 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uAE30 \uC804\uC5D0 \uC9C0\uC6D0\uD558\uB824\uB294 \uAE30\uAD00\uC774 IELTS Online\uC744 \uC778\uC815\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_iol_organisationCheck_title3 = "\uC9C0\uC6D0\uD558\uB824\uB294 \uAE30\uAD00\uC774\uB098 \uB2E8\uCCB4\uAC00 IELTS Online\uC744 \uC778\uC815\uD569\uB2C8\uAE4C?";
var APPB2C_common_findTest_iol_softwareCheck_check3 = "\uC751\uC2DC\uB97C \uC704\uD55C \uAE30\uAE30 \uBC0F \uC2DC\uC2A4\uD15C \uC694\uAD6C\uC0AC\uD56D\uC744 \uD655\uC778\uD588\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_iol_softwareCheck_desc13 = "IELTS Online\uC5D0 \uC751\uC2DC\uD558\uB824\uBA74 Windows PC\uB098 MacOS\uC5D0\uC11C Inspera \uC18C\uD504\uD2B8\uC6E8\uC5B4\uB97C \uC0AC\uC6A9\uD574\uC57C \uD569\uB2C8\uB2E4(\uBAA8\uBC14\uC77C \uAE30\uAE30\uB098 \uD0DC\uBE14\uB9BF PC\uB294 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4).";
var APPB2C_common_findTest_iol_softwareCheck_desc23 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uAE30 \uC804 \uADC0\uD558\uC758 \uCEF4\uD4E8\uD130\uAC00 \uAD8C\uC7A5 \uC2DC\uC2A4\uD15C \uC694\uAD6C\uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_iol_softwareCheck_link3 = "\uC751\uC2DC\uB97C \uC704\uD55C \uAE30\uAE30 \uC694\uAD6C\uC0AC\uD56D \uD655\uC778";
var APPB2C_common_findTest_iol_softwareCheck_title3 = "\uC2DC\uD5D8\uC5D0 \uD544\uC694\uD55C \uC18C\uD504\uD2B8\uC6E8\uC5B4\uB97C \uAD6C\uB3D9\uD560 \uC218 \uC788\uB294 \uCEF4\uD4E8\uD130\uB97C \uAD6C\uBE44\uD588\uC2B5\uB2C8\uAE4C?";
var APPB2C_common_findTest_langModal_choose3 = "\uC774\uC804\uC5D0 {{languageName}}\uB85C \uC774 \uC0AC\uC774\uD2B8\uC5D0 \uC811\uC18D\uD558\uC168\uC2B5\uB2C8\uB2E4. \uC774 \uC5B8\uC5B4\uB294 {{countryName}}\uC758 \uC2DC\uD5D8 \uC608\uC57D \uC2DC \uC9C0\uC6D0\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uB300\uC2E0 \uB2E4\uC74C \uC635\uC158 \uC911 \uD558\uB098\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_langModal_chooseSingle3 = "\uC774\uC804\uC5D0 {{languageName}}\uB85C \uC774 \uC0AC\uC774\uD2B8\uC5D0 \uC811\uC18D\uD558\uC168\uC2B5\uB2C8\uB2E4. \uC774 \uC5B8\uC5B4\uB294 {{countryName}}\uC758 \uC2DC\uD5D8 \uC608\uC57D \uC2DC \uC9C0\uC6D0\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uB300\uC2E0 \uB2E4\uC74C \uC5B8\uC5B4\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_langModal_continue3 = "\uC544\uB798 \uC5B8\uC5B4 \uC911 \uD558\uB098\uB85C \uC608\uC57D\uC744 \uACC4\uC18D\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_langModal_title3 = "\uC2DC\uD5D8\uC744 \uCE58\uB97C \uC7A5\uC18C\uB97C \uBCC0\uACBD\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_multipleLocations3 = "\uBCF5\uC218 \uC9C0\uC5ED";
var APPB2C_common_findTest_osrInfo3 = "<bold>One Skill Retake</bold>\uB294 IELTS on Paper\uC5D0\uC11C \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC73C\uBBC0\uB85C \uC774 \uC635\uC158\uC744 \uC0AC\uC6A9\uD558\uB824\uBA74 IELTS on Computer\uB97C \uC608\uC57D\uD558\uB294 \uAC83\uC774 \uC88B\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_osrInfoLink3 = "One Skill Retake\uC5D0 \uB300\uD574\uC11C \uB354 \uC77D\uC5B4 \uBCF4\uC138\uC694.";
var APPB2C_common_findTest_residencyConfirmation_btn3 = "\uC54C\uC558\uC5B4, \uACC4\uC18D \uAC80\uC0C9";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_btn3 = "\uD14C\uC2A4\uD2B8 \uC885\uB958 \uBCC0\uACBD";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line13 = "\uC120\uD0DD\uD55C \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uAC00 \uC544\uB2CC \uACBD\uC6B0 \uC9C0\uD544 \uC2DC\uD5D8\uC744 \uC608\uC57D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. <0>\uCEF4\uD4E8\uD130 \uC2DC\uD5D8\uC744 \uC120\uD0DD\uD558\uAC70\uB098 \uAC70\uC8FC \uAD6D\uAC00\uC5D0 \uC788\uB294 \uC9C0\uD544 \uC2DC\uD5D8\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.</0>";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line23 = "'\uC2DC\uD5D8 \uC608\uC57D' \uD398\uC774\uC9C0\uC5D0\uC11C \uD544\uD130\uB97C \uC0AC\uC6A9\uD558\uC5EC \uC2DC\uD5D8 \uC720\uD615\uC744 \uBCC0\uACBD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uAD6D\uAC00 \uC120\uD0DD \uD398\uC774\uC9C0\uB85C \uB3CC\uC544\uAC08 \uC218\uB3C4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_title3 = "\uC774 \uC2DC\uD5D8\uC740 \uC608\uC57D\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_residencyConfirmation_msg2 = "<0>IELTS on Paper</0> \uC2DC\uD5D8\uC5D0 \uAD00\uC2EC\uC774 \uC788\uB294 \uACBD\uC6B0 \uC2DC\uD5D8\uC744 \uCE58\uB974\uB294 \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uC784\uC744 \uD655\uC778\uD574\uC57C \uD569\uB2C8\uB2E4. \uC120\uD0DD\uD55C \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uAC00 \uC544\uB2CC \uACBD\uC6B0 <0>\uCEF4\uD4E8\uD130 \uD14C\uC2A4\uD2B8\uB97C \uC120\uD0DD\uD558\uAC70\uB098 \uAC70\uC8FC \uAD6D\uAC00\uC5D0 \uC788\uB294 \uC9C0\uD544 \uD14C\uC2A4\uD2B8\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.</0> \uC798\uBABB \uC120\uD0DD\uD558\uBA74 \uC2DC\uD5D8\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_common_findTest_residencyConfirmation_msg_line13 = "<0>IELTS on Paper</0> \uC2DC\uD5D8\uC5D0 \uAD00\uC2EC\uC774 \uC788\uB294 \uACBD\uC6B0 \uC2DC\uD5D8\uC744 \uCE58\uB974\uB294 \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uC784\uC744 \uD655\uC778\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_findTest_residencyConfirmation_msg_line23 = "\uC120\uD0DD\uD55C \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uAC00 \uC544\uB2CC \uACBD\uC6B0 <0>\uCEF4\uD4E8\uD130 \uD14C\uC2A4\uD2B8\uB97C \uC120\uD0DD\uD558\uAC70\uB098 \uAC70\uC8FC \uAD6D\uAC00\uC5D0 \uC788\uB294 \uC9C0\uD544 \uD14C\uC2A4\uD2B8\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_line33 = "\uC798\uBABB \uC120\uD0DD\uD558\uBA74 \uC2DC\uD5D8\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine23 = "\uC120\uD0DD\uD55C \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uAC00 \uC544\uB2CC \uACBD\uC6B0 <0>\uAC70\uC8FC \uAD6D\uAC00\uC5D0 \uC788\uB294 \uAC80\uC0AC\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624</0>.";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine23 = "\uC120\uD0DD\uD55C \uAD6D\uAC00\uC758 \uAC70\uC8FC\uC790\uAC00 \uC544\uB2CC \uACBD\uC6B0 <0>\uCEF4\uD4E8\uD130 \uC2DC\uD5D8 \uC120\uD0DD</0> (\uB2E4\uB978 \uB3C4\uC2DC\uC5D0\uC11C \uC0AC\uC6A9 \uAC00\uB2A5) <0>\uAC70\uC8FC \uAD6D\uAC00\uC5D0 \uC788\uB294 \uC9C0\uD544 \uC2DC\uD5D8 \uC120\uD0DD</0>.";
var APPB2C_common_findTest_residencyConfirmation_selectDates3 = "\uBA3C\uC800 \uB0A0\uC9DC\uB97C \uC120\uD0DD\uD558\uC138\uC694.";
var APPB2C_common_findTest_residencyConfirmation_title3 = "\uC911\uC694\uD558\uB2E4!";
var APPB2C_common_findTest_searchIn3 = "\uC9C0\uC5ED \uAC80\uC0C9:";
var APPB2C_common_findTest_selectCountry_continue3 = "\uC608\uC57D \uACC4\uC18D\uD558\uAE30";
var APPB2C_common_findTest_selectCountry_countryNotSupported3 = "\uC774 \uAD6D\uAC00\uC5D0\uC11C \uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uB824\uBA74 \uB2E4\uB978 \uC6F9\uC0AC\uC774\uD2B8\uB97C \uC774\uC6A9\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_findTest_selectCountry_label3 = "\uAD6D\uAC00 \uC120\uD0DD";
var APPB2C_common_findTest_selectCountry_placeholder3 = "\uAD6D\uAC00\uBA85\uC73C\uB85C \uAC80\uC0C9";
var APPB2C_common_findTest_selectLocation_closestToMe3 = "\uB0B4 \uC704\uCE58 \uC8FC\uBCC0";
var APPB2C_common_findTest_selectLocation_geolocationBlocked3 = "\uADC0\uD558\uC758 \uC704\uCE58\uB97C \uC54C \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC774 \uC0AC\uC774\uD2B8\uC5D0 \uB300\uD558\uC5EC \uC0AC\uC6A9\uC790 \uC704\uCE58 \uC815\uBCF4 \uC811\uADFC \uAD8C\uD55C\uC774 \uD5C8\uC6A9\uB418\uC5B4 \uC788\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_selectLocation_label3 = "\uB3C4\uC2DC \uAC80\uC0C9";
var APPB2C_common_findTest_selectLocation_noExamForThisCountry3 = "\uC774 \uAD6D\uAC00\uC5D0\uC11C\uB294 \uC2DC\uD5D8\uC774 \uC870\uD68C\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. <1>\uC601\uAD6D\uBB38\uD654\uC6D0\uC73C\uB85C \uBB38\uC758\uD558\uC2DC\uBA74</1> \uB3C4\uC640\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation3 = "\uC774 \uC870\uC9C1\uC5D0 \uB300\uD55C \uD14C\uC2A4\uD2B8\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uB2E4\uC74C \uC635\uC158 \uC911 \uD558\uB098\uB97C \uC120\uD0DD\uD569\uB2C8\uB2E4.";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta3 = "\uC0AC\uC6A9 \uAC00\uB2A5\uD55C \uD14C\uC2A4\uD2B8 \uAC80\uC0C9";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option13 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uB824\uB294 \uC704\uCE58\uB97C \uC120\uD0DD\uD569\uB2C8\uB2E4.";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option23 = "<1>\uBB38\uC758</1> \uADF8\uB9AC\uACE0 \uC6B0\uB9AC\uB294 \uB2F9\uC2E0\uC744 \uB3D5\uAE30 \uC704\uD574 \uB178\uB825\uD560 \uAC83\uC785\uB2C8\uB2E4";
var APPB2C_common_findTest_specialReqs_findOutMore3 = "\uADC0\uD558\uB97C \uB3C4\uC6B8 \uC218 \uC788\uB294 \uBC29\uC548\uC5D0 \uB300\uD574 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624";
var APPB2C_common_findTest_specialReqs_list_subtitle3 = "\uD2B9\uC218 \uC870\uCE58 \uC694\uCCAD\uC740 \uC2DC\uD5D8\uC77C \uC120\uD0DD \uBC94\uC704\uC5D0 \uC601\uD5A5\uC744 \uC904 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uC544\uB798\uC758 \uC635\uC158\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624(\uBCF5\uC218 \uC120\uD0DD \uAC00\uB2A5).";
var APPB2C_common_findTest_specialReqs_list_title3 = "\uC2DC\uD5D8 \uB2F9\uC77C \uC5B4\uB5A4 \uD2B9\uC218 \uC870\uCE58\uAC00 \uD544\uC694\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_findTest_specialReqs_needCheckbox3 = "\uC2DC\uD5D8 \uB2F9\uC77C <1>\uD2B9\uC218 \uC870\uCE58</1>\uAC00 \uD544\uC694\uD569\uB2C8\uB2E4";
var APPB2C_common_findTest_specialReqs_other3 = "\uC5B4\uB5A4 \uD2B9\uC218 \uC870\uCE58\uAC00 \uD544\uC694\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_findTest_specialReqs_pleaseNoteMessage3 = "\uC751\uC2DC \uAC00\uB2A5\uD55C \uAC00\uC7A5 \uBE60\uB978 \uC2DC\uD5D8\uC77C\uBCF4\uB2E4 \uB354 \uC77C\uCC0D \uC2DC\uD5D8\uC744 \uC608\uC57D\uD574\uC57C \uD558\uB294 \uACBD\uC6B0 \uC2DC\uD5D8 \uC13C\uD130\uB85C \uC5F0\uB77D\uD558\uC5EC \uBC29\uC548\uC744 \uB17C\uC758\uD558\uC2ED\uC2DC\uC624. \uD655\uB300 \uC778\uC1C4 \uC2DC\uD5D8\uC9C0, \uC810\uC790 \uC2DC\uD5D8\uC9C0, \uAD6C\uD654\uB85C \uB179\uD654\uB41C \uC2DC\uD5D8 \uC790\uB8CC \uB4F1 \uD2B9\uC218 \uC81C\uC791 \uC2DC\uD5D8\uC9C0\uAC00 \uD544\uC694\uD558\uC2E0 \uACBD\uC6B0\uC5D0\uB294 \uC2DC\uD5D8 \uC13C\uD130\uC5D0\uC11C \uB354 \uBE60\uB978 \uC2DC\uD5D8 \uB0A0\uC9DC\uB97C \uC81C\uACF5\uD558\uAE30 \uC5B4\uB824\uC6B8 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_findTest_subtitle3 = "\uC5B8\uC81C \uC751\uC2DC\uD558\uAE38 \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_findTest_title3 = "\uC2DC\uD5D8 \uCC3E\uAE30";
var APPB2C_common_findTest_uol_organisationCheck_check3 = "IELTS for UKVI Online\uC740 \uC601\uAD6D \uBE44\uC790\uC774\uBBFC\uAD6D\uC5D0\uC11C\uB9CC \uC778\uC815\uB41C\uB2E4\uB294 \uAC83\uC744 \uC774\uD574\uD569\uB2C8\uB2E4.";
var APPB2C_common_findTest_uol_organisationCheck_desc3 = "\uB2E4\uB978 \uAE30\uAD00\uC5D0\uC11C \uC778\uC815\uD558\uB294 IELTS \uC2DC\uD5D8\uC744 \uBCF4\uB824\uBA74 <0>\uB2E4\uB978 IELTS \uC2DC\uD5D8</0>\uC744 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_uol_organisationCheck_title3 = "IELTS for UKVI Online\uC740 \uC601\uAD6D \uBE44\uC790\uC774\uBBFC\uAD6D\uC5D0\uC11C\uB9CC \uC778\uC815\uB429\uB2C8\uB2E4";
var APPB2C_common_findTest_uol_softwareCheck_check3 = "\uC751\uC2DC\uB97C \uC704\uD55C \uAE30\uAE30 \uBC0F \uC2DC\uC2A4\uD15C \uC694\uAD6C\uC0AC\uD56D\uC744 \uD655\uC778\uD588\uC2B5\uB2C8\uB2E4";
var APPB2C_common_findTest_uol_softwareCheck_desc13 = "IELTS for UKVI Online\uC5D0 \uC751\uC2DC\uD558\uB824\uBA74 Windows PC\uB098 MacOS\uC5D0\uC11C Inspera \uC18C\uD504\uD2B8\uC6E8\uC5B4\uB97C \uC0AC\uC6A9\uD574\uC57C \uD569\uB2C8\uB2E4(\uBAA8\uBC14\uC77C \uAE30\uAE30\uB098 \uD0DC\uBE14\uB9BF PC\uB294 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4).";
var APPB2C_common_findTest_uol_softwareCheck_desc23 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uAE30 \uC804 \uADC0\uD558\uC758 \uCEF4\uD4E8\uD130\uAC00 \uAD8C\uC7A5 \uC2DC\uC2A4\uD15C \uC694\uAD6C\uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_findTest_uol_softwareCheck_link3 = "\uC751\uC2DC\uB97C \uC704\uD55C \uAE30\uAE30 \uC694\uAD6C\uC0AC\uD56D \uD655\uC778";
var APPB2C_common_findTest_uol_softwareCheck_title3 = "\uC2DC\uD5D8\uC5D0 \uD544\uC694\uD55C \uC18C\uD504\uD2B8\uC6E8\uC5B4\uB97C \uAD6C\uB3D9\uD560 \uC218 \uC788\uB294 \uCEF4\uD4E8\uD130\uB97C \uAD6C\uBE44\uD588\uC2B5\uB2C8\uAE4C?";
var APPB2C_common_footer_terms_accessibility3 = "\uC811\uADFC\uC131";
var APPB2C_common_footer_terms_copyrightLine13 = "\uBB38\uD654 \uAD00\uACC4 \uBC0F \uAD50\uC721 \uAE30\uD68C\uB97C \uC704\uD55C \uC601\uAD6D\uC758 \uAD6D\uC81C \uAE30\uAD6C.";
var APPB2C_common_footer_terms_copyrightLine23 = "\uB4F1\uB85D\uB41C \uC790\uC120 \uB2E8\uCCB4: 209131(\uC789\uAE00\uB79C\uB4DC \uBC0F \uC6E8\uC77C\uC988) SC037733(\uC2A4\uCF54\uD2C0\uB79C\uB4DC).";
var APPB2C_common_footer_terms_dataProtection3 = "\uAC1C\uC778\uC815\uBCF4 \uBCF4\uD638";
var APPB2C_common_footer_terms_privacyAndCookies3 = "\uAC1C\uC778\uC815\uBCF4 \uBC0F \uCFE0\uD0A4";
var APPB2C_common_footer_terms_termsOfUse3 = "\uC774\uC6A9 \uADDC\uC815";
var APPB2C_common_gelRedirect_errorMessage3 = "\uD604\uC7AC \uC774 \uC790\uB8CC\uB294 \uC0AC\uC6A9\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_gelRedirect_goBack2 = "\uC2DC\uD5D8 \uB300\uBE44 \uC790\uB8CC \uBCF4\uAE30";
var APPB2C_common_gelRedirect_header3 = "\uC2DC\uD5D8 \uB300\uBE44";
var APPB2C_common_gelRedirect_info3 = "\uC2DC\uD5D8 \uB300\uBE44\uC5D0 \uC720\uC6A9\uD55C \uB2E4\uC591\uD55C \uBB34\uB8CC \uC790\uB8CC\uAC00 \uB9C8\uB828\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_general_next3 = "\uB2E4\uC74C";
var APPB2C_common_general_previous3 = "\uC774\uC804";
var APPB2C_common_general_regNotFound3 = "\uB4F1\uB85D \uB0B4\uC5ED\uC744 \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_general_remove3 = "\uC0AD\uC81C";
var APPB2C_common_general_steps3 = "{{steps}}\uB2E8\uACC4 \uC911 {{step}}\uB2E8\uACC4";
var APPB2C_common_general_stepsContinued3 = "{{steps}}\uB2E8\uACC4 \uC911 {{step}}\uB2E8\uACC4\uAC00 \uACC4\uC18D\uB429\uB2C8\uB2E4...";
var APPB2C_common_helpAndContact_actionSection_contactFormLabel3 = "\uC9C0\uAE08 \uCC44\uD305\uC744 \uD558\uC9C0 \uC54A\uACE0 \uB098\uC911\uC5D0 \uC5F0\uB77D\uC744 \uBC1B\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre3 = "\uB3C4\uC6C0\uC774 \uB354 \uD544\uC694\uD558\uC2E0\uAC00\uC694? \uC544\uB798 \uBC84\uD2BC\uC744 \uD074\uB9AD\uD558\uC5EC \uBB38\uC758 \uC591\uC2DD\uC744 \uC791\uC131\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_helpAndContact_actionSection_contactFormLink3 = "\uBB38\uC758 \uC591\uC2DD \uC791\uC131";
var APPB2C_common_helpAndContact_actionSection_faqLabel3 = "\uB2E4\uC74C \uC9C8\uBB38\uC5D0 \uB2F5\uBCC0\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_helpAndContact_actionSection_faqLink3 = "FAQ \uBCF4\uAE30";
var APPB2C_common_helpAndContact_actionSection_icHeader3 = "\uB3C4\uC6C0\uC744 \uB4DC\uB9B4 \uC218 \uC788\uB294 \uB2E4\uB978 \uBC29\uBC95";
var APPB2C_common_helpAndContact_actionSection_iolHeader3 = "\uC5B4\uB5A4 \uC885\uB958\uC758 \uB3C4\uC6C0\uC774 \uD544\uC694\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_helpAndContact_actionSection_launchChatBtn3 = "\uB77C\uC774\uBE0C \uCC44\uD305 \uC2DC\uC791";
var APPB2C_common_helpAndContact_actionSection_launchChatLabel3 = "\uC2DC\uD5D8\uACFC \uAD00\uB828\uD558\uC5EC \uC628\uB77C\uC778 \uCC44\uD305 \uC0C1\uB2F4\uC744 \uC694\uCCAD\uD569\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_form_back3 = "\uACE0\uAC1D\uC9C0\uC6D0(help & contact)\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLink3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC5D0 \uBD88\uB9CC\uC744 \uC81C\uAE30\uD558\uB294 \uBC29\uBC95\uC5D0 \uB300\uD55C \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>Make a complaint</0\uC744 \uCC38\uC870\uD558\uC138\uC694>";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle3 = "\uC790\uC138\uD788 \uBCF4\uAE30";
var APPB2C_common_helpAndContact_form_complaint_radio_complaint3 = "\uBD88\uB9CC";
var APPB2C_common_helpAndContact_form_complaint_radio_other3 = "\uC608\uB97C \uB4E4\uC5B4 \uC77C\uBC18 \uC694\uCCAD. \uC9C8\uBB38";
var APPB2C_common_helpAndContact_form_complaint_title3 = "\uC694\uCCAD \uC720\uD615\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_helpAndContact_form_label3 = "\uBB38\uC758\uC0AC\uD56D\uC744 \uAC04\uB7B5\uD788 \uC694\uC57D\uD574 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_common_helpAndContact_form_legal3 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD\uC744 \uCC98\uB9AC\uD558\uAE30 \uC704\uD574 \uADC0\uD558\uAC00 \uC81C\uACF5\uD558\uB294 \uAC1C\uC778\uC815\uBCF4\uB97C \uC0AC\uC6A9\uD569\uB2C8\uB2E4. \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>\uB2F9\uC0AC \uC6F9\uC0AC\uC774\uD2B8\uC758 \uAC1C\uC778\uC815\uBCF4 \uBCF4\uD638 \uC139\uC158</0>\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_helpAndContact_form_response3 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD\uC5D0 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC774\uBA54\uC77C\uB85C \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_form_submit3 = "\uBB38\uC758 \uC591\uC2DD \uC81C\uCD9C";
var APPB2C_common_helpAndContact_form_thanks_back3 = "\uB0B4 \uC2DC\uD5D8 \uC608\uC57D\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_common_helpAndContact_form_thanks_banner3 = "\uADC0\uD558\uC758 \uBB38\uC758 \uC694\uCCAD\uC774 \uC811\uC218\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_form_thanks_info3 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD\uC5D0 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC774\uBA54\uC77C\uB85C \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_form_thanks_title3 = "\uBB38\uC758\uD574\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4";
var APPB2C_common_helpAndContact_form_title3 = "\uADC0\uD558\uC758 \uC2DC\uD5D8\uACFC \uAD00\uB828\uD574 \uBB34\uC5C7\uC744 \uB3C4\uC640\uB4DC\uB9B4\uAE4C\uC694?";
var APPB2C_common_helpAndContact_form_validation3 = "\uD544\uC218 \uC785\uB825 \uD56D\uBAA9\uC785\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_icContent_commonQuestionsHeader3 = "\uC790\uC8FC \uBB3B\uB294 \uC9C8\uBB38";
var APPB2C_common_helpAndContact_icContent_emailLabel3 = "\uC774\uBA54\uC77C";
var APPB2C_common_helpAndContact_icContent_header3 = "IELTS \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uAD81\uAE08\uD55C \uC810\uC740 \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC73C\uB85C \uBB38\uC758\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_icContent_phoneLabel3 = "\uC804\uD654";
var APPB2C_common_helpAndContact_icContent_referenceReminder3 = "\uADC0\uD558\uC5D0\uAC8C \uC2E0\uC18D\uD788 \uB3C4\uC6C0\uC744 \uB4DC\uB9B4 \uC218 \uC788\uB3C4\uB85D IELTS reference number\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_helpAndContact_ieltsReference3 = "IELTS \uB808\uD37C\uB7F0\uC2A4 \uBC88\uD638:";
var APPB2C_common_helpAndContact_noTests_bookNewBtn3 = "\uC0C8 \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_common_helpAndContact_noTests_contactLink3 = "\uC790\uC138\uD55C \uC5F0\uB77D\uCC98\uB294 <0>IELTS \uC751\uC2DC \uC6F9\uC0AC\uC774\uD2B8</0>\uB97C \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_helpAndContact_noTests_header3 = "\uB3C4\uC6C0\uC744 \uC694\uCCAD\uD560 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_helpAndContact_selectExamLabel3 = "\uC774 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uB3C4\uC6C0\uB9D0 \uD45C\uC2DC:";
var APPB2C_common_helpAndContact_title3 = "\uACE0\uAC1D\uC9C0\uC6D0";
var APPB2C_common_invitation_modal_header3 = "\uC720\uD6A8\uD558\uC9C0 \uC54A\uC740 \uB9C1\uD06C";
var APPB2C_common_invitation_modal_invalid3 = "URL\uC774 \uC798\uBABB\uB418\uC5C8\uAC70\uB098 \uC720\uD6A8\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uB294 \uB300\uD589\uC5C5\uCCB4\uB97C \uAC70\uCE58\uC9C0 \uC54A\uACE0 \uB2E8\uB3C5\uC73C\uB85C \uB4F1\uB85D\uC744 \uC9C4\uD589\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_modals_newTermsAndConditions_checkboxNote3 = "{{context}}\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4";
var APPB2C_common_modals_newTermsAndConditions_checkboxTC3 = "\uC774\uC6A9 \uADDC\uC815\uC5D0 \uB3D9\uC758\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_modals_newTermsAndConditions_cta3 = "\uACC4\uC18D";
var APPB2C_common_modals_newTermsAndConditions_error3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uC7A0\uC2DC \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_modals_newTermsAndConditions_note3 = "\uACC4\uC18D\uD558\uC2DC\uB824\uBA74 \uC2E0\uADDC \uC774\uC6A9 \uADDC\uC815\uC5D0 \uB3D9\uC758\uD574 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_common_modals_newTermsAndConditions_title3 = "\uC2E0\uADDC \uC774\uC6A9 \uADDC\uC815";
var APPB2C_common_modals_newTermsAndConditions_trigger3 = "\uC2E0\uADDC \uC774\uC6A9 \uADDC\uC815\uC5D0 \uB3D9\uC758";
var APPB2C_common_nav_bookNewTest3 = "\uC0C8 \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_common_nav_chooseLanguage3 = "\uC5B8\uC5B4 \uC120\uD0DD";
var APPB2C_common_nav_hello3 = "\uC548\uB155\uD558\uC138\uC694";
var APPB2C_common_nav_helpAndContact3 = "\uACE0\uAC1D\uC9C0\uC6D0";
var APPB2C_common_nav_login3 = "\uB85C\uADF8\uC778";
var APPB2C_common_nav_logout3 = "\uB85C\uADF8\uC544\uC6C3";
var APPB2C_common_nav_logoutModal_cancel2 = "\uCDE8\uC18C";
var APPB2C_common_nav_logoutModal_confirm3 = "\uC608";
var APPB2C_common_nav_logoutModal_message3 = "\uB85C\uADF8\uC544\uC6C3 \uD558\uC2DC\uBA74 \uC2DC\uD5D8 \uB4F1\uB85D\uC774 \uC885\uB8CC\uB429\uB2C8\uB2E4.";
var APPB2C_common_nav_logoutModal_question3 = "\uB85C\uADF8\uC544\uC6C3 \uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?";
var APPB2C_common_nav_logoutModal_title3 = "\uB85C\uADF8\uC544\uC6C3 \uC911\uC785\uB2C8\uB2E4";
var APPB2C_common_nav_myAccount3 = "\uB0B4 \uACC4\uC815";
var APPB2C_common_nav_preparation3 = "\uC2DC\uD5D8 \uB300\uBE44";
var APPB2C_common_nav_resultsTests3 = "\uC2DC\uD5D8 \uBC0F \uACB0\uACFC";
var APPB2C_common_nav_ttp3 = "\uC751\uC2DC\uC790 \uD3EC\uD138";
var APPB2C_common_preparation_noTests_contactLink3 = "\uBB34\uB8CC \uC2DC\uD5D8 \uB300\uBE44 \uC790\uB8CC\uB294 <0>IELTS \uC751\uC2DC \uC6F9\uC0AC\uC774\uD2B8</0>\uC5D0\uC11C \uC774\uC6A9 \uAC00\uB2A5\uD569\uB2C8\uB2E4.";
var APPB2C_common_preparation_noTests_header3 = "\uB300\uBE44\uD560 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_preparation_selectExamLabel3 = "\uC774 \uC2DC\uD5D8 \uAD00\uB828 \uC790\uB8CC \uD45C\uC2DC:";
var APPB2C_common_preparation_title3 = "\uC2DC\uD5D8 \uB300\uBE44";
var APPB2C_common_preparation_viewTestBtn3 = "\uC774 \uC2DC\uD5D8 \uBCF4\uAE30";
var APPB2C_common_recreateReg_errMsg3 = "\uC774 \uB4F1\uB85D \uAC74\uC744 \uB2E4\uC2DC \uC0DD\uC131\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC77C\uBC18 \uB4F1\uB85D \uC808\uCC28\uB85C \uC5F0\uACB0\uB429\uB2C8\uB2E4. ";
var APPB2C_common_recreateReg_errTitle3 = "\uB2E4\uC2DC \uC0DD\uC131 \uC2E4\uD328";
var APPB2C_common_recreateReg_progress3 = "\uC608\uC57D \uB2E4\uC2DC \uC0DD\uC131";
var APPB2C_common_regHeader_reference3 = "\uB808\uD37C\uB7F0\uC2A4(Reference)";
var APPB2C_common_regHeader_trfLoading3 = "\uC7A0\uC2DC \uAE30\uB2E4\uB824 \uC8FC\uC2ED\uC2DC\uC624...";
var APPB2C_common_regHeader_trfNumber3 = "\uC131\uC801\uD45C(TRF) \uBC88\uD638";
var APPB2C_common_regInProgressModal_continue3 = "\uC0C8\uB85C \uB4F1\uB85D\uD558\uC9C0 \uC54A\uC74C";
var APPB2C_common_regInProgressModal_message13 = "\uC774\uBBF8 <bold>{{date}}</bold> \uC2DC\uD5D8\uC758 \uB4F1\uB85D\uC774 \uC9C4\uD589 \uC911\uC785\uB2C8\uB2E4. \uC120\uD0DD\uD558\uC2E0 \uC2DC\uD5D8\uC73C\uB85C \uC0C8\uB85C \uB4F1\uB85D\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?";
var APPB2C_common_regInProgressModal_message23 = "\uC0C8\uB85C \uB4F1\uB85D\uD560 \uACBD\uC6B0 \uAE30\uC874 \uC608\uC57D\uC740 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_common_regInProgressModal_restart3 = "\uC0C8\uB85C \uB4F1\uB85D";
var APPB2C_common_regInProgressModal_title3 = "\uC8FC\uC758!";
var APPB2C_common_selfServices_requested_cancellation3 = "\uCDE8\uC18C \uC2E0\uCCAD\uB428";
var APPB2C_common_selfServices_requested_transfer3 = "\uC5F0\uAE30 \uC2E0\uCCAD\uB428";
var APPB2C_common_specialReqs_AMANU3 = "\uB300\uD544 \uC778\uB825";
var APPB2C_common_specialReqs_ANTHR3 = "\uB2E4\uB978 \uD558\uB098";
var APPB2C_common_specialReqs_BTP3 = "\uC810\uC790 \uC2DC\uD5D8\uC9C0";
var APPB2C_common_specialReqs_BWP3 = "\uC810\uC790 \uC6CC\uB4DC \uD504\uB85C\uC138\uC11C";
var APPB2C_common_specialReqs_EPTP3 = "\uAE00\uC790\uAC00 \uD655\uB300 \uC778\uC1C4\uB41C \uC2DC\uD5D8\uC9C0";
var APPB2C_common_specialReqs_ET3 = "\uCD94\uAC00 \uC2DC\uAC04";
var APPB2C_common_specialReqs_LRTV3 = "\uAD6C\uD654(\u53E3\u8A71)\uB85C \uB179\uD654\uB41C \uB4E3\uAE30 \uACFC\uBAA9 \uC2DC\uD5D8 \uC790\uB8CC";
var APPB2C_common_specialReqs_SAE3 = "\uC74C\uC131 \uC99D\uD3ED \uC7A5\uCE58";
var APPB2C_common_specialReqs_VAS3 = "\uC74C\uC131 \uC778\uC2DD \uC18C\uD504\uD2B8\uC6E8\uC5B4";
var APPB2C_common_specialReqs_WP3 = "\uC6CC\uB4DC \uD504\uB85C\uC138\uC11C";
var APPB2C_common_startPage_book3 = "\uC608\uC57D";
var APPB2C_common_startPage_candidates_nowAdult3 = "{{name}}\uC740(\uB294) \uBC95\uC801\uC73C\uB85C \uC131\uC778\uC785\uB2C8\uB2E4. \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC815\uCC45\uC5D0 \uB530\uB77C {{name}}\uC740(\uB294) IELTS \uACC4\uC815\uC744 \uC9C1\uC811 \uAD00\uB9AC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_startPage_candidates_showingFor3 = "~\uC5D0 \uB300\uD55C \uC2DC\uD5D8 \uBC0F \uC131\uC801 \uD45C\uC2DC ";
var APPB2C_common_startPage_common_cancelled3 = "\uCDE8\uC18C\uB428";
var APPB2C_common_startPage_description_ac3 = "\uD559\uBD80/\uB300\uD559\uC6D0 \uC218\uC900\uC758 \uACF5\uBD80\uB97C \uD558\uAC70\uB098 \uC804 \uC138\uACC4\uC5D0\uC11C \uC804\uBB38\uC9C1 \uCDE8\uC5C5\uC744 \uC6D0\uD558\uB294 \uC751\uC2DC\uC790\uC5D0\uAC8C \uC801\uD569\uD569\uB2C8\uB2E4.";
var APPB2C_common_startPage_description_gt3 = "\uD559\uBD80 \uC785\uD559 \uC804 \uB2E8\uACC4\uC758 \uACF5\uBD80\uB97C \uD558\uAC70\uB098 \uC804 \uC138\uACC4\uC5D0\uC11C \uCDE8\uC5C5\uC744 \uC6D0\uD558\uB294 \uC751\uC2DC\uC790\uC5D0\uAC8C \uC801\uD569\uD569\uB2C8\uB2E4";
var APPB2C_common_startPage_description_ukvi3 = "\uC601\uAD6D \uC815\uBD80\uAC00 \uC778\uC99D\uD558\uB294 Secure English Language Tests (SELT)\uB294 \uCDE8\uC5C5/\uBC30\uC6B0\uC790 \uBE44\uC790\uAC00 \uD544\uC694\uD558\uAC70\uB098, \uD559\uBD80 \uC785\uD559 \uC804 \uB2E8\uACC4 \uD639\uC740 UKVI\uAC00 \uC778\uC815\uD558\uC9C0 \uC54A\uB294 \uC601\uAD6D \uAE30\uAD00\uC5D0\uC11C \uACF5\uBD80\uD558\uAE38 \uC6D0\uD558\uB294 \uC751\uC2DC\uC790\uB97C \uC704\uD55C \uC2DC\uD5D8\uC785\uB2C8\uB2E4.";
var APPB2C_common_startPage_endNote3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 IELTS\uC758 \uACF5\uB3D9 \uC8FC\uAD00\uC0AC\uC785\uB2C8\uB2E4.";
var APPB2C_common_startPage_error_body3 = "\uB2E4\uB978 \uC2DC\uD5D8\uC774 \uC788\uC744 \uC218 \uC788\uC73C\uB098 \uBD88\uB7EC\uC624\uB294 \uB3D9\uC548 \uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uC7A0\uC2DC \uD6C4 \uD398\uC774\uC9C0\uB97C \uC0C8\uB85C\uACE0\uCE68 \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_startPage_error_cta3 = "\uD398\uC774\uC9C0 \uC0C8\uB85C\uACE0\uCE68";
var APPB2C_common_startPage_error_title3 = "\uBAA8\uB4E0 \uC2DC\uD5D8\uC744 \uBD88\uB7EC\uC62C \uC218 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_hero_cta3 = "\uC0C8 \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_common_startPage_hero_subtitle3 = "\uC751\uC2DC\uC790 \uD3EC\uD138\uC740 \uC2DC\uD5D8 \uB300\uBE44, \uC608\uC57D \uAD00\uB9AC, \uC131\uC801 \uD655\uC778, IELTS \uC810\uC218\uB97C \uD65C\uC6A9\uD55C \uC601\uC5B4 \uD559\uC2B5\uC744 \uC9C0\uC6D0\uD558\uB294 \uC11C\uBE44\uC2A4\uC785\uB2C8\uB2E4.";
var APPB2C_common_startPage_hero_title3 = "\uC2DC\uD5D8 \uBC0F \uACB0\uACFC";
var APPB2C_common_startPage_ielts3 = "IELTS";
var APPB2C_common_startPage_ieltsUkvi3 = "\uC601\uAD6D \uBE44\uC790\uB97C \uC704\uD55C IELTS";
var APPB2C_common_startPage_missing_cancel3 = "\uB85C\uADF8\uC778 \uD558\uACE0 \uC2F6\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_missing_marketing_cta3 = "\uC800\uC7A5";
var APPB2C_common_startPage_missing_marketing_notes_full13 = "\uC774\uBA54\uC77C \uC218\uC2E0\uC744 \uC6D0\uCE58 \uC54A\uC73C\uC2DC\uBA74 \uC5B8\uC81C\uB4E0 \uC751\uC2DC\uC790 \uD3EC\uD138\uC758 '\uB0B4 \uACC4\uC815'\uC5D0\uC11C \uC218\uC2E0\uC744 \uAC70\uBD80\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_notes_full23 = "\uB610\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0\uC5D0";
var APPB2C_common_startPage_missing_marketing_notes_full33 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uB3D9\uC758\uC5D0 \uAE30\uCD08\uD558\uC5EC \uADC0\uD558\uC758 \uAC1C\uC778\uC815\uBCF4\uB97C \uCC98\uB9AC\uD569\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_notes_link3 = "\uC9C1\uC811 \uC5F0\uB77D\uD558\uC5EC \uC218\uC2E0\uC744 \uAC70\uBD80\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_notes_main3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC815\uBCF4\uB97C \uC774\uC6A9\uD558\uC5EC \uADC0\uD558\uC5D0\uAC8C \uC720\uC6A9\uD560 \uC218 \uC788\uB294 \uD65C\uB3D9, \uC11C\uBE44\uC2A4, \uD589\uC0AC(\uCE5C\uBAA9 \uD589\uC0AC \uD3EC\uD568) \uAD00\uB828 \uC815\uBCF4\uB97C \uBCF4\uB0B4\uB4DC\uB9AC\uACE0\uC790 \uD569\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties3 = "\uBCF8\uC778\uC740 \uC601\uAD6D\uBB38\uD654\uC6D0\uACFC \uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uC120\uC815\uD55C \uC81C3\uC790\uAC00 \uC8FC\uAD00\uD558\uB294 \uC81C\uD488, \uC11C\uBE44\uC2A4 \uBC0F \uD589\uC0AC\uC5D0 \uB300\uD55C \uC815\uBCF4\uB97C \uBC1B\uAC8C \uB418\uC5B4 \uAE30\uC069\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_opts_BcOnly3 = "\uC601\uAD6D\uBB38\uD654\uC6D0(\uC601\uAD6D\uBB38\uD654\uC6D0 \uADF8\uB8F9\uC758 \uB2E4\uB978 \uAD00\uACC4\uC0AC \uD3EC\uD568)\uC774 \uC81C\uACF5\uD558\uACE0 \uAE30\uD68D\uD558\uB294 \uC81C\uD488, \uC11C\uBE44\uC2A4, \uC774\uBCA4\uD2B8 \uAD00\uB828 \uC815\uBCF4\uB97C \uC218\uC2E0\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_opts_DoNotSend3 = "\uB9C8\uCF00\uD305 \uC815\uBCF4\uB97C \uC218\uC2E0\uD558\uC9C0 \uC54A\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_marketing_title3 = "\uAC1C\uC778\uC815\uBCF4 \uB9C8\uCF00\uD305 \uC774\uC6A9 \uB3D9\uC758";
var APPB2C_common_startPage_missing_ttData_cta3 = "\uAC1C\uC778\uC815\uBCF4 \uD655\uC778";
var APPB2C_common_startPage_missing_ttData_dob3 = "\uC0DD\uB144\uC6D4\uC77C";
var APPB2C_common_startPage_missing_ttData_firstName3 = "\uC774\uB984(\uBBF8\uB4E4\uB124\uC784 \uD3EC\uD568)";
var APPB2C_common_startPage_missing_ttData_lastName3 = "\uC131";
var APPB2C_common_startPage_missing_ttData_line13 = "\uC774\uC804\uC5D0 \uB2E4\uB978 \uC81C\uD488/\uC11C\uBE44\uC2A4\uC5D0 \uB4F1\uB85D\uD558\uC2E0 \uAE30\uB85D\uC774 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_missing_ttData_line23 = "\uB85C\uADF8\uC778 \uD558\uB824\uBA74 \uC774\uB984, \uC0DD\uB144\uC6D4\uC77C\uC744 \uD655\uC778\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_startPage_missing_ttData_line33 = "\uAC10\uC0AC\uD569\uB2C8\uB2E4!";
var APPB2C_common_startPage_missing_ttData_noLastName3 = "\uC131\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_missing_ttData_title3 = "\uD655\uC778\uC774 \uD544\uC694\uD55C \uC0AC\uD56D\uC774 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_options_ac3 = "IELTS Academic";
var APPB2C_common_startPage_options_gt3 = "IELTS General Training";
var APPB2C_common_startPage_options_ukvi3 = "IELTS for UKVI \uB610\uB294 IELTS Life Skills";
var APPB2C_common_startPage_past_cd3 = "\uCEF4\uD4E8\uD130 IELTS";
var APPB2C_common_startPage_past_empty3 = "\uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_past_osr_newScore3 = "\uC0C8 \uC810\uC218";
var APPB2C_common_startPage_past_osr_note_awaiting3 = "\uADC0\uD558\uB294 Overall Band Score \uD5A5\uC0C1\uC744 \uC704\uD55C {{skill}} \uC7AC\uC2DC\uD5D8 \uACB0\uACFC\uB97C \uAE30\uB2E4\uB9AC\uACE0 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_past_osr_note_confirmed3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uB97C \uD5A5\uC0C1\uC2DC\uD0A4\uAE30 \uC704\uD574 {{skill}} \uC7AC\uC2DC\uD5D8\uC744 \uC811\uC218\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_past_osr_note_hasResults3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uB97C \uD5A5\uC0C1\uC2DC\uD0A4\uAE30 \uC704\uD574 {{skill}} \uC7AC\uC2DC\uD5D8\uC744 \uC811\uC218\uD588\uC2B5\uB2C8\uB2E4. \uC774\uC81C \uC0C8\uB85C\uC6B4 \uC810\uC218\uB97C \uBC1B\uC544\uBCF4\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_startPage_past_osr_originalScore3 = "\uAE30\uC874 \uC810\uC218";
var APPB2C_common_startPage_past_osr_viewRetakeLink3 = "\uC811\uC218\uB41C \uC7AC\uC2DC\uD5D8 \uC870\uD68C\uD558\uAE30";
var APPB2C_common_startPage_past_pb3 = "\uC9C0\uD544 IELTS";
var APPB2C_common_startPage_past_rebook3 = "\uC774 \uC2DC\uD5D8\uC744 \uB2E4\uB978 \uB0A0\uC9DC\uB85C \uC608\uC57D";
var APPB2C_common_startPage_past_score3 = "\uADC0\uD558\uC758 \uC810\uC218\uB294 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4:";
var APPB2C_common_startPage_past_scoreLifeSkills3 = "\uC810\uC218";
var APPB2C_common_startPage_past_title3 = "\uC774\uC804 \uC2DC\uD5D8 \uBC0F \uC131\uC801";
var APPB2C_common_startPage_past_upcomingResults3 = "\uC131\uC801\uC740 {{date}} \uC774\uC804\uC5D0 \uBC1C\uD45C\uB429\uB2C8\uB2E4";
var APPB2C_common_startPage_readTnCs3 = "\uACC4\uC18D\uD558\uAE30 \uC804\uC5D0 IELTS\uB97C \uC77D\uC5B4\uBCF4\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4";
var APPB2C_common_startPage_recent_cta3 = "\uC804\uCCB4 \uC131\uC801 \uBCF4\uAE30";
var APPB2C_common_startPage_recent_title3 = "\uCD5C\uADFC \uC131\uC801";
var APPB2C_common_startPage_subtitle3 = "\uC5B4\uB5A4 IELTS \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD558\uAE38 \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_common_startPage_title3 = "IELTS \uC2DC\uD5D8 \uC120\uD0DD";
var APPB2C_common_startPage_tncs3 = "\uC774\uC6A9 \uADDC\uC815";
var APPB2C_common_startPage_upcoming_cd3 = "\uCEF4\uD4E8\uD130 \uC2DC\uD5D8";
var APPB2C_common_startPage_upcoming_empty3 = "\uC608\uC815\uB41C \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_upcoming_lifeSkills3 = "\uB4E3\uAE30 \uBC0F \uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_startPage_upcoming_live3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_startPage_upcoming_manage3 = "\uC774 \uC608\uC57D \uBCF4\uAE30";
var APPB2C_common_startPage_upcoming_missing_childConsent3 = "\uC790\uB140 \uB3D9\uC758(child consent) \uC815\uBCF4 \uC5C5\uB370\uC774\uD2B8";
var APPB2C_common_startPage_upcoming_missing_id3 = "\uC2E0\uBD84\uC99D \uC5C5\uB85C\uB4DC";
var APPB2C_common_startPage_upcoming_missing_medical3 = "\uC9C4\uB2E8\uC11C \uC5C5\uB85C\uB4DC";
var APPB2C_common_startPage_upcoming_missing_payment3 = "\uACB0\uC81C \uC99D\uBE59 \uC5C5\uB85C\uB4DC";
var APPB2C_common_startPage_upcoming_noSpeaking3 = "\uC544\uC9C1 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC815\uBCF4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_common_startPage_upcoming_noTime3 = "\uC2DC\uD5D8\uC77C \uC804 48\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC815\uD655\uD55C \uC2DC\uD5D8 \uC2DC\uAC04\uC744 \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_common_startPage_upcoming_osr_note3 = "\uADC0\uD558\uC758 {{skill}} \uC7AC\uC2DC\uD5D8 \uACB0\uACFC\uB294 \uC0C8\uB85C\uC6B4 \uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uC5D0 \uBC18\uC601\uB429\uB2C8\uB2E4.";
var APPB2C_common_startPage_upcoming_pastPaymentDeadline3 = "\uACB0\uC81C \uB0A0\uC9DC \uB9CC\uB8CC";
var APPB2C_common_startPage_upcoming_pay3 = "\uC751\uC2DC\uB8CC \uACB0\uC81C";
var APPB2C_common_startPage_upcoming_pb3 = "\uD544\uAE30 \uC2DC\uD5D8";
var APPB2C_common_startPage_upcoming_prepare3 = "\uC774 \uC2DC\uD5D8 \uB300\uBE44\uD558\uAE30";
var APPB2C_common_startPage_upcoming_remote3 = "\uC6D0\uACA9 \uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_startPage_upcoming_title3 = "\uC608\uC815\uB41C \uC2DC\uD5D8";
var APPB2C_common_startPage_whichIELTS3 = "\uC5B4\uB5A4 IELTS \uC2DC\uD5D8\uC774 \uB098\uC5D0\uAC8C \uC801\uD569\uD55C\uAC00\uC694?";
var APPB2C_common_terms_downloadInfo3 = "\uC774\uC6A9 \uADDC\uC815 PDF \uB2E4\uC6B4\uB85C\uB4DC\uAC00 \uACE7 \uC2DC\uC791\uB429\uB2C8\uB2E4.";
var APPB2C_common_terms_downloadTitle3 = "\uC774\uC6A9 \uADDC\uC815 PDF \uB2E4\uC6B4\uB85C\uB4DC";
var APPB2C_common_terms_error3 = "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uC7A0\uC2DC \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_common_terms_title3 = "\uC774\uC6A9 \uADDC\uC815";
var APPB2C_common_testDetails_changeTest3 = "\uC2DC\uD5D8 \uBCC0\uACBD";
var APPB2C_common_testDetails_changeWrittenTest3 = "\uD544\uAE30 \uC2DC\uD5D8 \uBCC0\uACBD";
var APPB2C_common_testDetails_completeYourBooking3 = "\uC608\uC57D \uC644\uB8CC";
var APPB2C_common_testDetails_mapModal_close3 = "\uCC3D \uB2EB\uAE30";
var APPB2C_common_testDetails_mapModal_title3 = "\uC704\uCE58 \uC138\uBD80 \uC815\uBCF4";
var APPB2C_common_testDetails_modifyNote_confirm3 = "\uBCF8\uC778\uC740 \uC774 \uC774\uC6A9 \uADDC\uC815\uC744 \uC774\uD574\uD558\uACE0 \uB3D9\uC758\uD569\uB2C8\uB2E4.";
var APPB2C_common_testDetails_modifyNote_note3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uAC00 \uC120\uD0DD\uD558\uC2E0 \uC2DC\uD5D8 \uC7A5\uC18C, \uB0A0\uC9DC, \uC2DC\uAC04\uB300\uB97C \uC81C\uACF5\uD558\uAE30 \uC704\uD574 \uCD5C\uC120\uC744 \uB2E4\uD560 \uAC83\uC774\uB098 \uAC00\uC6A9 \uC5EC\uBD80 \uBC0F \uC774\uC6A9 \uADDC\uC815\uC5D0 \uB530\uB77C \uC774\uB97C \uC218\uC815, \uC911\uB2E8, \uB300\uCCB4\uD560 \uC218 \uC788\uB294 \uAD8C\uB9AC\uB97C \uBCF4\uC720\uD569\uB2C8\uB2E4.";
var APPB2C_common_testDetails_modifyNote_pleaseNote3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_common_testDetails_title3 = "\uC2DC\uD5D8 \uC815\uBCF4";
var APPB2C_common_testDetails_yourSpeakingTest3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_common_testDetails_yourWrittenTest3 = "\uD544\uAE30 \uC2DC\uD5D8";
var APPB2C_common_transfer_agreement3 = "\uC2DC\uD5D8 \uC5F0\uAE30 \uC815\uCC45\uC5D0 \uB300\uD55C \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <1>\uC774\uC6A9 \uADDC\uC815</1>\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_transfer_contactNote3 = "\uADC0\uD558\uC758 \uC5F0\uAE30 \uC2E0\uCCAD\uC744 \uCC98\uB9AC\uD558\uAE30 \uC704\uD574 \uC9C1\uC811 \uC5F0\uB77D\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4. \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC2E0\uCCAD\uC5D0 \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_transfer_contactNoteIol3 = "\uADC0\uD558\uC758 \uC5F0\uAE30 \uC2E0\uCCAD\uC744 \uCC98\uB9AC\uD558\uAE30 \uC704\uD574 \uC774\uBA54\uC77C\uB85C \uC9C1\uC811 \uC5F0\uB77D\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4. \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC2E0\uCCAD\uC5D0 \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_transfer_cta3 = "\uC5F0\uAE30 \uC2E0\uCCAD \uC81C\uCD9C";
var APPB2C_common_transfer_form_freeform3 = "\uC0C8 \uC2DC\uD5D8\uACFC \uAD00\uB828\uD558\uC5EC \uADC0\uD558\uC758 \uC120\uD638\uB97C \uC54C\uB824\uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_common_transfer_form_reasons_13 = "\uC608\uC57D\uD55C \uC2DC\uD5D8\uC758 \uC885\uB958";
var APPB2C_common_transfer_form_reasons_23 = "\uC2DC\uD5D8 \uC7A5\uC18C";
var APPB2C_common_transfer_form_reasons_33 = "\uC2DC\uD5D8 \uB0A0\uC9DC";
var APPB2C_common_transfer_form_reasons_43 = "\uB9D0\uD558\uAE30 \uACFC\uBAA9\uB9CC \uC751\uC2DC";
var APPB2C_common_transfer_form_reasons_choose3 = "\uC635\uC158\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624";
var APPB2C_common_transfer_form_reasons_other3 = "\uAE30\uD0C0";
var APPB2C_common_transfer_form_reasons_title3 = "\uBB34\uC5C7\uC744 \uBCC0\uACBD\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?";
var APPB2C_common_transfer_goBack3 = "\uB4A4\uB85C";
var APPB2C_common_transfer_notes_13 = "\uC5F0\uAE30 \uC2E0\uCCAD\uC5D0\uB294 \uC2DC\uD5D8 \uC13C\uD130\uC758 \uC2B9\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_transfer_notes_23 = "IELTS \uC2DC\uD5D8\uC740 1\uD68C\uB9CC \uC5F0\uAE30\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_transfer_notes_33 = "3\uAC1C\uC6D4 \uC774\uB0B4\uC758 \uB2E4\uB978 \uC2DC\uD5D8\uC77C\uC744 \uC120\uD0DD\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_common_transfer_notes_43 = "\uC218\uC218\uB8CC\uAC00 \uBC1C\uC0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uC2E0\uCCAD\uC774 \uC2B9\uC778\uB418\uBA74 \uACB0\uC81C \uBC29\uBC95\uC744 \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_common_transfer_notesIol_13 = "\uC5F0\uAE30 \uC2E0\uCCAD\uC5D0\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC2B9\uC778\uC774 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_common_transfer_notesIol_23 = "\uC2E0\uCCAD\uC11C\uB97C \uC81C\uCD9C\uD558\uAE30 \uC804\uC5D0 <0>IELTS \uC5F0\uAE30, \uCDE8\uC18C \uBC0F \uD658\uBD88 \uC815\uCC45</0>\uC744 \uC790\uC138\uD788 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624";
var APPB2C_common_transfer_requestValidation3 = "\uD544\uC218 \uC785\uB825 \uD56D\uBAA9\uC785\uB2C8\uB2E4.";
var APPB2C_common_transfer_selfService_availableDates_title3 = "\uC774\uC6A9 \uAC00\uB2A5\uD55C \uB0A0\uC9DC";
var APPB2C_common_transfer_selfService_chooseDates_title3 = "\uD14C\uC2A4\uD2B8 \uC804\uC1A1";
var APPB2C_common_transfer_selfService_review_confirm_proceedBtn3 = "\uC804\uC1A1 \uD14C\uC2A4\uD2B8";
var APPB2C_common_transfer_selfService_review_confirm_title3 = "\uC804\uC1A1 \uD655\uC778";
var APPB2C_common_transfer_selfService_review_errors_noDistricts3 = "\uC774 \uC704\uCE58\uC5D0\uB294 \uB354 \uC774\uC0C1 \uC0AC\uC6A9\uD560 \uC218 \uC788\uB294 \uC2DC\uD5D8 \uB0A0\uC9DC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4. \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uC5F0\uB77D\uD558\uC5EC \uB3C4\uC6C0\uC744 \uBC1B\uC744 \uC218 \uC788\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_transfer_selfService_review_errors_noLrwSlots3 = "\uC120\uD0DD\uD55C \uB0A0\uC9DC\uC5D0 \uB300\uD55C \uD14C\uC2A4\uD2B8 \uC6A9\uB7C9\uC774 \uC18C\uC9C4\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uB2E4\uB978 \uB0A0\uC9DC\uB97C \uC120\uD0DD\uD574 \uBCF4\uC138\uC694.";
var APPB2C_common_transfer_selfService_review_errors_transferDisabled3 = "\uD14C\uC2A4\uD2B8\uB97C \uB354 \uC774\uC0C1 \uC804\uC1A1\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_transfer_selfService_review_test_changeLrw3 = "\uD544\uAE30 \uC2DC\uD5D8 \uB0A0\uC9DC \uBCC0\uACBD";
var APPB2C_common_transfer_selfService_review_tncs_agreement3 = "IELTS <0>\uC774\uC6A9 \uC57D\uAD00</0> \uBC0F <2>\uCDE8\uC18C \uC815\uCC45</2\uC5D0 \uB3D9\uC758\uD588\uC2B5\uB2C8\uB2E4>";
var APPB2C_common_transfer_title3 = "\uC2DC\uD5D8 \uC5F0\uAE30";
var APPB2C_common_underConstruction_message3 = "\uBCF8 \uC2DC\uD5D8 \uC811\uC218 \uC0AC\uC774\uD2B8\uC758 \uC2DC\uBC94 \uC6B4\uC601\uC5D0 \uCC38\uC5EC\uD558\uC2E0 \uC5EC\uB7EC\uBD84\uC744 \uD658\uC601\uD569\uB2C8\uB2E4(\uB3C4\uC640\uC8FC\uC154\uC11C \uAC10\uC0AC\uD569\uB2C8\uB2E4)! \uC544\uC9C1 \uBBF8\uBE44\uD558\uC9C0\uB9CC \uC815\uC2DD \uCD9C\uBC94 \uD6C4 \uC2E4\uC81C \uACE0\uAC1D\uC5D0\uAC8C\uB294 \uC801\uC808\uD55C \uCF58\uD150\uCE20\uAC00 \uC81C\uACF5\uB420 \uC608\uC815\uC774\uB2C8 \uC5FC\uB824\uD558\uC9C0 \uC54A\uC73C\uC154\uB3C4 \uB429\uB2C8\uB2E4. ";
var APPB2C_common_underConstruction_title3 = "\uC900\uBE44 \uC911\uC785\uB2C8\uB2E4...";
var APPB2C_common_unknownCountry_cta3 = "VPN\uC744 \uC885\uB8CC\uD588\uC2B5\uB2C8\uB2E4";
var APPB2C_common_unknownCountry_header3 = "\uADC0\uD558\uAC00 \uC811\uC18D\uD558\uC2E0 \uAD6D\uAC00\uB97C \uC778\uC2DD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_unknownCountry_msg13 = "\uAC04\uD639 \uAE30\uAE30\uC5D0\uC11C \uC811\uC18D \uC704\uCE58\uB97C \uC228\uAE30\uB294 \uD504\uB85C\uADF8\uB7A8\uC774 \uC0AC\uC6A9\uB418\uB294 \uACBD\uC6B0 \uC774\uB7EC\uD55C \uC77C\uC774 \uBC1C\uC0DD\uD569\uB2C8\uB2E4.";
var APPB2C_common_unknownCountry_msg23 = "\uC0AC\uC6A9\uC790\uC758 \uC704\uCE58\uB97C \uC228\uAE30\uB294 VPN\uC774\uB098 \uC720\uC0AC\uD55C \uD234\uC744 \uC0AC\uC6A9 \uC911\uC774\uB77C\uBA74 \uC774\uB97C \uC885\uB8CC\uD558\uACE0 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_common_unknownCountry_title3 = "\uADC0\uD558\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_unpaidErrorBar_paymentExpired3 = "\uB4F1\uB85D \uACB0\uC81C \uB0A0\uC9DC\uAC00 \uB9CC\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_cancelled3 = "\uC774 \uC2DC\uD5D8\uC740 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_cancelPending3 = "\uC774 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uCDE8\uC18C \uC2E0\uCCAD\uC774 \uBCF4\uB958 \uC911\uC785\uB2C8\uB2E4. <1>\uB0B4\uC6A9 \uBCF4\uAE30</1>.";
var APPB2C_common_updateBar_cancelRejected3 = "\uCDE8\uC18C \uC694\uCCAD\uC774 \uAC70\uBD80\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_close3 = "\uB2EB\uAE30";
var APPB2C_common_updateBar_details3 = "\uC790\uC138\uD55C \uB0B4\uC6A9\uC740 \uC544\uB798\uB97C \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_updateBar_eorCompleted3 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810(EOR)\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorCompleted_scoreDecreased3 = "\uBC34\uB4DC \uC810\uC218\uAC00 {{prevOverallScore}}\uC5D0\uC11C {{currentOverallScore}}\uB85C \uAC10\uC18C\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorCompleted_scoreIncreased3 = "\uBC34\uB4DC \uC810\uC218\uAC00 {{prevOverallScore}}\uC5D0\uC11C {{currentOverallScore}}\uB85C \uC99D\uAC00\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorCompleted_scoreUnchanged3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uB294 {{currentOverallScore}}\uC5D0\uC11C \uBCC0\uACBD\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorPending3 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810(EOR)\uC740 \uBCF4\uB958 \uC911\uC785\uB2C8\uB2E4. <1>\uB0B4\uC6A9 \uBCF4\uAE30</1>.";
var APPB2C_common_updateBar_eorPendingAgentPays3 = "\uC7AC\uCC44\uC810(Enquiry on Results; EOR) \uC774 \uBCF4\uB958 \uC911\uC785\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorRefunded3 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810(EOR) \uC218\uC218\uB8CC\uAC00 \uD658\uBD88\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorRefundPending3 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810(EOR) \uC218\uC218\uB8CC \uD658\uBD88\uC774 \uC9C4\uD589 \uC911\uC785\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorRejected3 = "\uACB0\uACFC\uC5D0 \uB300\uD55C \uBB38\uC758(EOR)\uAC00 \uAC70\uBD80\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_eorRejected_details2 = "\uC790\uC138\uD55C \uB0B4\uC6A9\uC740 \uC544\uB798\uB97C \uCC38\uC870\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_updateBar_eorRejected_overallBand3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uAC00 {{overallBand}}\uC5D0\uC11C \uBCC0\uACBD\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_hasResults3 = "\uADC0\uD558\uC758 \uC131\uC801\uC774 \uBC1C\uAE09\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_termsUpdated3 = "\uC2DC\uD5D8 \uB2F9\uC77C\uC5D0 \uC801\uC6A9\uB418\uB294 <1>\uC0C8\uB85C\uC6B4 \uC774\uC6A9 \uC57D\uAD00\uC5D0 \uB3D9\uC758</1>\uD574\uC57C \uD569\uB2C8\uB2E4. <br> IELTS \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD558\uB294 \uBAA8\uB4E0 \uC751\uC2DC\uC790\uB294 \uBCF8 \uC774\uC6A9 \uC57D\uAD00\uC758 \uC801\uC6A9\uC744 \uBC1B\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_transactionRefundRequestAccepted3 = "\uD658\uBD88 \uC694\uCCAD\uC774 \uC218\uB77D\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_transactionRefundRequestPending3 = "\uD658\uBD88 \uC694\uCCAD\uC774 \uB300\uAE30 \uC911\uC785\uB2C8\uB2E4.";
var APPB2C_common_updateBar_transactionRefundRequestRejected3 = "\uD658\uBD88 \uC694\uCCAD\uC774 \uAC70\uBD80\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_transferCancelled3 = "\uC774\uC804 \uC694\uCCAD\uC744 \uCC98\uB9AC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uAC70\uB098 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_common_updateBar_transferPending3 = "\uC774 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC5F0\uAE30 \uC2E0\uCCAD\uC774 \uBCF4\uB958 \uC911\uC785\uB2C8\uB2E4. <1>\uB0B4\uC6A9 \uBCF4\uAE30</1>.";
var APPB2C_common_updateBar_transferPendingCmds3 = "\uC1A1\uAE08\uC774 \uD655\uC778 \uB300\uAE30 \uC911\uC785\uB2C8\uB2E4. \uC1A1\uAE08 \uC694\uCCAD\uC774 \uCC98\uB9AC\uB418\uBA74 \uC54C\uB9BC\uC744 \uBC1B\uAC8C \uB429\uB2C8\uB2E4.";
var APPB2C_common_updateBar_transferred3 = "\uC774 \uC2DC\uD5D8\uC740 \uC5F0\uAE30 \uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uC0C8 \uC2DC\uD5D8\uC77C\uC740 \uC544\uB798\uC640 \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_common_updateBar_transferredCmds3 = "\uC774 \uD14C\uC2A4\uD2B8\uB294 \uC804\uC1A1\uB418\uC5C8\uC2B5\uB2C8\uB2E4. \uC0C8\uB85C\uC6B4 \uB0A0\uC9DC\uC5D0 \uB300\uD55C \uC608\uC57D \uC138\uBD80 \uC815\uBCF4\uB97C \uD655\uC778\uD558\uC138\uC694.";
var APPB2C_registration_bookingDetails_payment3 = "\uACB0\uC81C";
var APPB2C_registration_bookingDetails_results3 = "\uACB0\uACFC \uC54C\uC544\uBCF4\uAE30";
var APPB2C_registration_bookingDetails_seePayment3 = "\uACB0\uC81C \uC138\uBD80 \uC815\uBCF4 \uBCF4\uAE30";
var APPB2C_registration_bookingDetails_seeTestTaker3 = "\uC751\uC2DC\uC790 \uC815\uBCF4 \uC870\uD68C";
var APPB2C_registration_bookingDetails_whatNext3 = "\uAC00\uB2A5\uD55C \uB2E4\uC74C \uB2E8\uACC4\uB85C\uB294 \uBB34\uC5C7\uC774 \uC788\uC2B5\uB2C8\uAE4C?";
var APPB2C_registration_date_check_back3 = "\uC2DC\uD5D8 \uC815\uBCF4\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_registration_date_check_beforeTestDate3 = "\uC2DC\uD5D8\uC77C \uC804";
var APPB2C_registration_date_check_intro3 = "\uC2DC\uD5D8\uC77C \uC804\uC5D0 \uB2E4\uC74C \uC0AC\uD56D\uC744 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_date_check_onTestDate3 = "\uC2DC\uD5D8 \uB2F9\uC77C";
var APPB2C_registration_date_check_step1_body3 = "\uC2DC\uD5D8\uC5D0 \uC0AC\uC6A9\uD560 \uB178\uD2B8\uBD81\uC774\uB098 \uB370\uC2A4\uD06C\uD1B1 \uCEF4\uD4E8\uD130\uAC00 \uCD5C\uC18C \uC694\uAD6C\uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624(PC, Mac\uB9CC \uC0AC\uC6A9 \uAC00\uB2A5. \uBAA8\uBC14\uC77C \uAE30\uAE30, \uD0DC\uBE14\uB9BF PC\uB85C\uB294 IELTS Online\uC5D0 \uC751\uC2DC\uD560 \uC218 \uC5C6\uC74C).";
var APPB2C_registration_date_check_step1_link3 = "\uAE30\uAE30\uAC00 \uCD5C\uC18C \uC694\uAD6C\uC0AC\uD56D\uC744 \uCDA9\uC871\uD558\uB294\uC9C0 \uD655\uC778";
var APPB2C_registration_date_check_step1_title3 = "1\uB2E8\uACC4 \u2013 \uAE30\uAE30 \uD655\uC778";
var APPB2C_registration_date_check_step2_body3 = "\uC2DC\uD5D8\uC77C \uC804\uC5D0 \uC2DC\uD5D8\uC5D0 \uC0AC\uC6A9\uD560 PC\uB098 Mac\uC5D0 Inspera \uC18C\uD504\uD2B8\uC6E8\uC5B4\uB97C \uC124\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step2_link3 = "Inspera \uC18C\uD504\uD2B8\uC6E8\uC5B4 \uB2E4\uC6B4\uB85C\uB4DC";
var APPB2C_registration_date_check_step2_title3 = "2\uB2E8\uACC4 \u2013 \uC18C\uD504\uD2B8\uC6E8\uC5B4 \uC124\uCE58";
var APPB2C_registration_date_check_step3_body13 = "Inspera\uB97C \uC2E4\uD589\uD558\uACE0 \uB85C\uADF8\uC778 \uD569\uB2C8\uB2E4. \uB85C\uADF8\uC778\uC740 \uC544\uB798\uC758 \uC0AC\uC6A9\uC790\uBA85\uACFC \uBE44\uBC00\uBC88\uD638\uB85C \uD560 \uC218 \uC788\uC73C\uBA70, \uBCF5\uC0AC-\uBD99\uC5EC\uB123\uAE30 \uD558\uC154\uB3C4 \uB429\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step3_body23 = "\uB85C\uADF8\uC778 \uD6C4\uC5D0\uB294 \uC544\uB798\uC758 \uB2E8\uACC4\uB97C \uAC70\uCE58\uAC8C \uB429\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step3_noDetailsBody3 = "\uB85C\uADF8\uC778 \uC815\uBCF4\uB294 \uC2DC\uD5D8\uC77C\uC774 \uAC00\uAE4C\uC6CC\uC9C0\uBA74 \uACF5\uAC1C\uB429\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step3_noDetailsTitle3 = "\uC9C0\uAE08\uC740 \uB85C\uADF8\uC778 \uC815\uBCF4\uAC00 \uACF5\uAC1C\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_date_check_step3_noSpeakingPinBody3 = "\uC2DC\uD5D8\uC77C\uC774 \uAC00\uAE4C\uC6CC\uC9C0\uBA74 \uACF5\uAC1C\uB429\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step3_noSpeakingPinTitle3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 PIN\uC740 \uC544\uC9C1 \uACF5\uAC1C\uB418\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_date_check_step3_password3 = "\uBE44\uBC00\uBC88\uD638:";
var APPB2C_registration_date_check_step3_speakingPin3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 PIN:";
var APPB2C_registration_date_check_step3_step1_body3 = "\uB9C8\uC774\uD06C\uC640 \uCE74\uBA54\uB77C\uAC00 \uC815\uC0C1 \uC791\uB3D9\uD558\uB294\uC9C0 \uD655\uC778\uD569\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step3_step1_title3 = "1\uB2E8\uACC4 \u2013 \uAE30\uAE30 \uD655\uC778";
var APPB2C_registration_date_check_step3_step2_body3 = "\uC5BC\uAD74 \uC0AC\uC9C4\uACFC \uC2E0\uBD84\uC99D \uC0AC\uC9C4\uC744 \uCD2C\uC601\uD569\uB2C8\uB2E4(\uC2DC\uD5D8 \uB4F1\uB85D \uC2DC \uC0AC\uC6A9\uD55C \uC2E0\uBD84\uC99D\uACFC \uB3D9\uC77C\uD55C \uC2E0\uBD84\uC99D \uC9C0\uCC38).";
var APPB2C_registration_date_check_step3_step2_title3 = "2\uB2E8\uACC4 \u2013 \uC2E0\uBD84 \uD655\uC778";
var APPB2C_registration_date_check_step3_step3_body3 = "IELTS Online \uC2DC\uD5D8\uC5D0 \uC751\uC2DC\uD558\uB824\uBA74 PIN\uC744 \uC785\uB825\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_date_check_step3_step3_title3 = "3\uB2E8\uACC4 \u2013 \uB85C\uADF8\uC778 \uD14C\uC2A4\uD2B8";
var APPB2C_registration_date_check_step3_username3 = "\uC0AC\uC6A9\uC790\uBA85:";
var APPB2C_registration_date_check_step3_writtenPin3 = "\uD544\uAE30 \uC2DC\uD5D8 PIN:";
var APPB2C_registration_date_zone_country3 = "\uADC0\uD558\uC758 \uC704\uCE58\uB294 \uB2E4\uC74C\uC73C\uB85C \uD30C\uC545\uB429\uB2C8\uB2E4";
var APPB2C_registration_date_zone_header3 = "\uC2DC\uD5D8 \uC608\uC57D \uC2DC\uC810";
var APPB2C_registration_date_zone_name3 = "\uC2DC\uAC04\uB300";
var APPB2C_registration_date_zone_time3 = "\uD604\uC9C0 \uC2DC\uAC04";
var APPB2C_registration_dateLocation_changeLinkIol3 = "\uC2DC\uD5D8\uC744 \uBCC0\uACBD\uD558\uACE0 \uC2F6\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_dateLocation_changeLrwLink3 = "\uD544\uAE30 \uC2DC\uD5D8\uC744 \uBCC0\uACBD\uD558\uACE0 \uC2F6\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_dateLocation_changeSpeakingLink3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC744 \uBCC0\uACBD\uD558\uACE0 \uC2F6\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_dateLocation_location3 = "\uC704\uCE58";
var APPB2C_registration_dateLocation_manage_cancel3 = "\uB0B4 \uC608\uC57D \uCDE8\uC18C";
var APPB2C_registration_dateLocation_manage_proceed2 = "\uC9C4\uD589";
var APPB2C_registration_dateLocation_manage_title3 = "\uC608\uC57D \uAD00\uB9AC";
var APPB2C_registration_dateLocation_manage_transferSpeaking3 = "\uB9D0\uD558\uAE30 \uBC14\uAFB8\uAE30";
var APPB2C_registration_dateLocation_manage_transferWhole3 = "\uD3B8\uC785 \uC2DC\uD5D8 \uB0A0\uC9DC";
var APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC720\uC9C0";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody13 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uD655\uC778 \uACB0\uACFC \uC751\uC2DC \uAC00\uB2A5\uD55C \uB2E4\uB978 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody23 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC744 \uBCC0\uACBD\uD574\uC57C \uD558\uB294 \uACBD\uC6B0 \uC2DC\uD5D8 \uB0A0\uC9DC \uBCC0\uACBD\uC744 \uC694\uCCAD\uD558\uBA74 \uC0C8 \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC744 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC751\uC2DC \uAC00\uB2A5\uD55C \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_dateLocation_speakingTransfer_requestTransfer3 = "\uB300\uC2E0 \uC5F0\uAE30 \uC2E0\uCCAD";
var APPB2C_registration_incorrectOrganisation_homeLink3 = "\uC870\uC9C1 \uCEE8\uD14D\uC2A4\uD2B8\uAC00 \uC5C6\uB294 Test Taker Portal \uBCF4\uAE30";
var APPB2C_registration_incorrectOrganisation_line13 = "\uC800\uD76C\uAC00 \uC778\uC2DD\uD558\uC9C0 \uBABB\uD558\uB294 \uC870\uC9C1\uC758 \uB9E5\uB77D\uC5D0\uC11C \uC2DC\uD5D8 \uC751\uC2DC\uC790 \uD3EC\uD138\uC744 \uC0AC\uC6A9\uD558\uB824\uACE0 \uD558\uB294 \uAC83 \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_incorrectOrganisation_line23 = "\uC5EC\uAE30\uB85C \uC548\uB0B4\uD55C \uC6D0\uB798 \uB9C1\uD06C\uB97C \uB2E4\uC2DC \uB530\uB77C\uAC00\uAC70\uB098 \uCEE8\uD14D\uC2A4\uD2B8 \uC5C6\uC774 TTP\uB97C \uC0AC\uC6A9\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_payments_actions_editProofCTA3 = "\uACB0\uC81C \uC99D\uBE59 \uC218\uC815";
var APPB2C_registration_payments_actions_header3 = "\uC5B4\uB5A4 \uACB0\uC81C \uBC29\uBC95\uC744 \uC6D0\uD558\uC2DC\uB098\uC694?";
var APPB2C_registration_payments_actions_paymentInProgressInfo3 = "\uAE30\uC874\uC758 \uC628\uB77C\uC778 \uACB0\uC81C \uC2DC\uB3C4\uAC00 \uC544\uC9C1 \uCC98\uB9AC \uC911\uC785\uB2C8\uB2E4. \uACB0\uACFC\uB97C \uAE30\uB2E4\uB824 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_payments_actions_payOfflineCTA3 = "\uB2E4\uB978 \uBC29\uBC95\uC73C\uB85C \uACB0\uC81C";
var APPB2C_registration_payments_actions_payOnlineCTA3 = "\uC628\uB77C\uC778 \uACB0\uC81C";
var APPB2C_registration_payments_actions_subHeader3 = "\uC774\uBBF8 \uACB0\uC81C\uB97C \uC644\uB8CC\uD588\uC73C\uBA70 \uACB0\uC81C \uC99D\uBE59\uC744 \uC5C5\uB85C\uB4DC \uD558\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_payments_actions_uploadProofCTA3 = "\uACB0\uC81C \uC99D\uBE59 \uC5C5\uB85C\uB4DC";
var APPB2C_registration_payments_awaitingPayment3 = "\uACB0\uC81C \uB300\uAE30 \uC911";
var APPB2C_registration_payments_back3 = "\uC2DC\uD5D8\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_registration_payments_completed3 = "\uC644\uB8CC\uB428";
var APPB2C_registration_payments_expired3 = "\uAE30\uD55C \uB9CC\uB8CC";
var APPB2C_registration_payments_finishPayment_error_cta3 = "\uD398\uC774\uC9C0 \uC0C8\uB85C\uACE0\uCE68";
var APPB2C_registration_payments_finishPayment_error_messageLine13 = "\uADC0\uD558\uC758 \uACB0\uC81C \uC0C1\uD0DC\uB97C \uD655\uC778\uD558\uB294 \uB3D9\uC548 \uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. \uC7A0\uC2DC \uD6C4 \uD398\uC774\uC9C0\uB97C \uC0C8\uB85C\uACE0\uCE68 \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_registration_payments_finishPayment_error_messageLine23 = "\uC774 \uBB38\uC81C\uAC00 \uC9C0\uC18D\uB418\uBA74 \uACB0\uC81C \uC635\uC158\uC73C\uB85C \uB3CC\uC544\uAC00\uC2ED\uC2DC\uC624.";
var APPB2C_registration_payments_finishPayment_error_title3 = "\uACB0\uC81C \uC0C1\uD0DC\uB97C \uC54C \uC218 \uC5C6\uC74C";
var APPB2C_registration_payments_finishPayment_failure_alert3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC628\uB77C\uC778 \uACB0\uC81C\uAC00 \uC815\uC0C1\uC801\uC73C\uB85C \uC644\uB8CC\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_payments_finishPayment_failure_cta3 = "\uACB0\uC81C \uC635\uC158 \uBCF4\uAE30";
var APPB2C_registration_payments_finishPayment_failure_message3 = "\uACB0\uC81C \uC635\uC158\uC73C\uB85C \uB3CC\uC544\uAC00 \uB2E4\uC2DC \uC2DC\uB3C4\uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_registration_payments_finishPayment_failure_title3 = "\uACB0\uC81C \uC2E4\uD328";
var APPB2C_registration_payments_finishPayment_pleaseWait3 = "\uC7A0\uC2DC \uAE30\uB2E4\uB824 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_registration_payments_finishPayment_processing3 = "\uACB0\uC81C\uB97C \uCC98\uB9AC\uD558\uACE0 \uC788\uC2B5\uB2C8\uB2E4...";
var APPB2C_registration_payments_finishPayment_success_alert3 = "\uACB0\uC81C\uAC00 \uC815\uC0C1\uC801\uC73C\uB85C \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_payments_finishPayment_success_cta3 = "\uC774 \uC2DC\uD5D8 \uBCF4\uAE30";
var APPB2C_registration_payments_finishPayment_success_message3 = "\uC7AC\uCC44\uC810 \uC608\uC57D \uD655\uC815\uC11C\uB97C \uADC0\uD558\uC758 \uC774\uBA54\uC77C \uC8FC\uC18C\uB85C \uBC1C\uC1A1\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_payments_finishPayment_success_title3 = "\uACB0\uC81C \uD655\uC778";
var APPB2C_registration_payments_offlineDetails_accountName3 = "\uACC4\uC88C\uBA85(Account name)";
var APPB2C_registration_payments_offlineDetails_accountNumber3 = "\uACC4\uC88C\uBC88\uD638(Account number)";
var APPB2C_registration_payments_offlineDetails_payBy3 = "\uACB0\uC81C \uC218\uB2E8";
var APPB2C_registration_payments_offlineDetails_sortCode3 = "\uC740\uD589\uCF54\uB4DC(Sort code)";
var APPB2C_registration_payments_paidByAgent3 = "\uC5D0\uC774\uC804\uD2B8\uAC00 \uC9C0\uBD88\uD55C \uB4F1\uB85D \uBE44\uC6A9";
var APPB2C_registration_payments_proofPreview_fileAlt3 = "\uC5C5\uB85C\uB4DC\uB41C \uACB0\uC81C \uC99D\uBE59";
var APPB2C_registration_payments_proofPreview_proofLabel3 = "\uACB0\uC81C \uC99D\uBE59:";
var APPB2C_registration_payments_proofUpload_cta3 = "\uD30C\uC77C \uC800\uC7A5";
var APPB2C_registration_payments_proofUpload_info3 = "\uD30C\uC77C\uC740 2\uAC1C \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4(\uCD5C\uC18C 1\uAC1C \uC5C5\uB85C\uB4DC \uD544\uC694).";
var APPB2C_registration_payments_proofUpload_intro3 = "\uC774 \uC218\uC218\uB8CC\uB97C \uC774\uBBF8 \uACB0\uC81C\uD588\uB2E4\uBA74 \uC544\uB798\uC5D0\uC11C \uACB0\uC81C \uC99D\uBE59\uC744 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_payments_proofUpload_rules_intro3 = "\uACB0\uC81C \uC99D\uBE59\uC5D0\uB294 \uB2E4\uC74C\uC774 \uBA85\uD655\uD788 \uD45C\uC2DC\uB418\uC5B4\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_payments_proofUpload_rules_rule13 = "\uC218\uCDE8\uC778";
var APPB2C_registration_payments_proofUpload_rules_rule23 = "\uC218\uCDE8\uC778 \uACC4\uC88C\uBC88\uD638";
var APPB2C_registration_payments_proofUpload_rules_rule33 = "\uACB0\uC81C \uAE08\uC561";
var APPB2C_registration_payments_proofUpload_rules_rule43 = "\uACB0\uC81C\uC77C";
var APPB2C_registration_payments_proofUpload_rules_rule53 = "\uC601\uAD6D\uBB38\uD654\uC6D0 \uACC4\uC88C\uBC88\uD638";
var APPB2C_registration_payments_transaction_cancelledSuffix3 = "(\uCDE8\uC18C\uB428)";
var APPB2C_registration_payments_transaction_deadlineWarning_default3 = "\uC774 \uC218\uC218\uB8CC\uC758 \uACB0\uC81C \uAE30\uD55C\uC774 {{date}}\uC77C \uB0A8\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_payments_transaction_deadlineWarning_eor3 = "\uC774 \uC218\uC218\uB8CC\uC758 \uACB0\uC81C \uAE30\uD55C\uC774 {{date}}\uC77C \uB0A8\uC558\uC2B5\uB2C8\uB2E4. \uAE30\uD55C \uB0B4 \uACB0\uC81C\uD558\uC9C0 \uC54A\uC73C\uBA74 \uC7AC\uCC44\uC810(EOR)\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_registration_payments_transaction_deadlineWarning_registration3 = "\uC774 \uC218\uC218\uB8CC\uC758 \uACB0\uC81C \uAE30\uD55C\uC774 {{date}}\uC77C \uB0A8\uC558\uC2B5\uB2C8\uB2E4. \uAE30\uD55C \uB0B4 \uACB0\uC81C\uD558\uC9C0 \uC54A\uC73C\uBA74 \uB4F1\uB85D\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4.";
var APPB2C_registration_payments_transaction_description_appeal3 = "\uC774\uC758 \uC2E0\uCCAD";
var APPB2C_registration_payments_transaction_description_eor3 = "\uC7AC\uCC44\uC810(EOR)";
var APPB2C_registration_payments_transaction_description_registration3 = "\uC2DC\uD5D8 \uB4F1\uB85D";
var APPB2C_registration_payments_transaction_description_transfer3 = "\uC5F0\uAE30";
var APPB2C_registration_payments_transaction_feeLabel3 = "\uC218\uC218\uB8CC";
var APPB2C_registration_payments_transaction_header3 = "\uACB0\uC81C \uB300\uC0C1";
var APPB2C_registration_payments_transaction_hideDetails3 = "\uB0B4\uC6A9 \uC228\uAE40";
var APPB2C_registration_payments_transaction_outdatedTermsLabel3 = "\uADC0\uD558\uB294 \uC774 <0>\uC774\uC6A9 \uADDC\uC815</0>\uC5D0 \uB3D9\uC758\uD558\uC168\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_payments_transaction_pastDeadline3 = "\uC774 \uD56D\uBAA9\uC5D0 \uB300\uD55C \uACB0\uC81C\uAC00 \uB2F9\uC0AC \uC2DC\uC2A4\uD15C\uC5D0 \uB4F1\uB85D\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_payments_transaction_pastDeadlineContactUs3 = "\uC774\uAC83\uC774 \uC624\uB958\uB77C\uACE0 \uC0DD\uAC01\uD558\uC2DC\uACE0 \uC81C \uC2DC\uAC04\uC5D0 \uACB0\uC81C\uAC00 \uCC98\uB9AC\uB418\uC5C8\uB2E4\uB294 \uC99D\uAC70\uB97C \uC81C\uACF5\uD560 \uC218 \uC788\uB294 \uACBD\uC6B0 \uB2E4\uC74C \uC5F0\uB77D\uCC98\uB85C \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_payments_transaction_pastDeadlineEmail3 = "\uC774\uBA54\uC77C";
var APPB2C_registration_payments_transaction_pastDeadlinePhone3 = "\uC804\uD654";
var APPB2C_registration_payments_transaction_promoCode3 = "\uD504\uB85C\uBAA8\uC158 \uCF54\uB4DC";
var APPB2C_registration_payments_transaction_receipt3 = "\uC601\uC218\uC99D";
var APPB2C_registration_payments_transaction_receiptCta3 = "\uC5EC\uAE30\uC5D0\uC11C \uB2E4\uC6B4\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_payments_transaction_referenceLabel3 = "\uC785\uAE08\uC790\uBA85(Payment reference)";
var APPB2C_registration_payments_transaction_showDetails3 = "\uB0B4\uC6A9 \uD45C\uC2DC";
var APPB2C_registration_payments_transaction_taxLabel3 = "\uC138\uAE08";
var APPB2C_registration_payments_transaction_termsLabel3 = "<0>\uC774\uC6A9 \uADDC\uC815</0> \uBCF4\uAE30";
var APPB2C_registration_payments_transaction_totalLabel3 = "\uD569\uACC4";
var APPB2C_registration_payments_transaction_underReviewSuffix3 = "(\uAC80\uD1A0 \uC911)";
var APPB2C_registration_payments_transaction_unpaidSuffix3 = "(\uACB0\uC81C \uC804)";
var APPB2C_registration_payments_unpaid3 = "\uACB0\uC81C \uC804";
var APPB2C_registration_prepare_a1_cta3 = "IELTS Life Skills \uC2DC\uD5D8 \uB300\uBE44 \u2013 \uB808\uBCA8 A1";
var APPB2C_registration_prepare_a1_header3 = "\uB808\uBCA8 A1";
var APPB2C_registration_prepare_a2_cta3 = "IELTS Life Skills \uC2DC\uD5D8 \uB300\uBE44 \u2013 \uB808\uBCA8 A2";
var APPB2C_registration_prepare_a2_header3 = "\uB808\uBCA8 A2";
var APPB2C_registration_prepare_b1_cta3 = "IELTS Life Skills \uC2DC\uD5D8 \uB300\uBE44 \u2013 \uB808\uBCA8 B1";
var APPB2C_registration_prepare_b1_header3 = "\uB808\uBCA8 B1";
var APPB2C_registration_prepare_blog_body_cta3 = "IELTS Opportunities Abroad \uBE14\uB85C\uADF8 \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_blog_body_line13 = "\uC774 \uBE14\uB85C\uADF8\uC5D0\uC11C\uB294 IELTS \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC804\uBB38\uAC00\uC758 \uC870\uC5B8\uACFC \uD301, \uADF8\uB9AC\uACE0 \uBBF8\uAD6D \uB300\uD559\uB4E4\uC5D0 \uB300\uD55C \uAC04\uB7B5\uD55C \uC18C\uAC1C\uB97C \uBCFC \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_blog_header3 = "IELTS Opportunities Abroad \uBE14\uB85C\uADF8";
var APPB2C_registration_prepare_cdFamiliarTest_body_cta3 = "\uCEF4\uD4E8\uD130 IELTS \uC775\uC219\uD574\uC9C0\uAE30 \uC2DC\uD5D8 \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_cdFamiliarTest_body_line13 = "\uC2E4\uC804\uACFC \uB3D9\uC77C\uD558\uAC8C 2\uC2DC\uAC04 30\uBD84 \uBD84\uB7C9\uC758 \uB4E3\uAE30, \uC77D\uAE30, \uC4F0\uAE30 \uBB38\uC81C\uAC00 \uC81C\uACF5\uB418\uB294 \uBB34\uB8CC \uCEF4\uD4E8\uD130 IELTS \uBAA8\uC758\uACE0\uC0AC\uC785\uB2C8\uB2E4.";
var APPB2C_registration_prepare_cdFamiliarTest_header3 = "\uCEF4\uD4E8\uD130 IELTS \uC775\uC219\uD574\uC9C0\uAE30 \uC2DC\uD5D8";
var APPB2C_registration_prepare_cdFamiliarVideos_body_cta3 = "\uCEF4\uD4E8\uD130 IELTS \uC775\uC219\uD574\uC9C0\uAE30 \uB3D9\uC601\uC0C1 \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_cdFamiliarVideos_body_line13 = "\uC774 \uB3D9\uC601\uC0C1\uC744 \uD1B5\uD574 \uCEF4\uD4E8\uD130 IELTS\uC758 \uB4E3\uAE30, \uC77D\uAE30, \uC4F0\uAE30 \uC2DC\uD5D8\uC774 \uD654\uBA74\uC5D0 \uC5B4\uB5BB\uAC8C \uD45C\uC2DC\uB418\uB294\uC9C0 \uC774\uD574\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_cdFamiliarVideos_header3 = "\uCEF4\uD4E8\uD130 IELTS \uC775\uC219\uD574\uC9C0\uAE30 \uB3D9\uC601\uC0C1";
var APPB2C_registration_prepare_cdTests_body_cta3 = "\uCEF4\uD4E8\uD130 IELTS \uBAA8\uC758\uACE0\uC0AC \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_cdTests_body_line13 = "\uC0D8\uD50C \uBB38\uC81C\uB97C \uD480\uC5B4\uBCF4\uBA74\uC11C \uC2DC\uD5D8\uB3C4 \uB300\uBE44\uD558\uACE0 \uB098\uC5D0\uAC8C \uB9DE\uB294 \uC2DC\uD5D8 \uBC29\uC2DD\uB3C4 \uACB0\uC815\uD574 \uBCF4\uC138\uC694.";
var APPB2C_registration_prepare_cdTests_header3 = "\uCEF4\uD4E8\uD130 IELTS \uBAA8\uC758\uACE0\uC0AC";
var APPB2C_registration_prepare_cdTipsVideos_body_cta3 = "\uCEF4\uD4E8\uD130 IELTS \uD301 \uB3D9\uC601\uC0C1 \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_cdTipsVideos_body_line13 = "\uCEF4\uD4E8\uD130 IELTS \uC2DC\uD5D8\uC5D0 \uC720\uC6A9\uD55C \uD301.";
var APPB2C_registration_prepare_cdTipsVideos_header3 = "\uCEF4\uD4E8\uD130 IELTS \uD301 \uB3D9\uC601\uC0C1";
var APPB2C_registration_prepare_facebook_body_cta3 = "IELTS \uC804\uBB38\uAC00\uC5D0\uAC8C \uBB3C\uC5B4\uBCF4\uC138\uC694(Facebook)";
var APPB2C_registration_prepare_facebook_body_line13 = "\uB9E4\uB2EC 3\uD68C\uC758 \uD398\uC774\uC2A4\uBD81 \uB77C\uC774\uBE0C \uCC44\uD305\uC744 \uD1B5\uD574 IELTS \uC804\uBB38\uAC00\uB4E4\uC774 \uCC38\uC5EC\uC790\uC5D0\uAC8C \uC989\uC11D \uB2F5\uBCC0\uC744 \uC81C\uACF5\uD569\uB2C8\uB2E4.";
var APPB2C_registration_prepare_facebook_header3 = "IELTS \uC804\uBB38\uAC00\uC5D0\uAC8C \uBB3C\uC5B4\uBCF4\uC138\uC694(Facebook)";
var APPB2C_registration_prepare_gel_disabledMsg3 = "<0>IELTS Ready Premium</0>\uC5D0 \uB300\uD55C \uC561\uC138\uC2A4\uB294 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uC758\uD574 \uCC28\uB2E8\uB429\uB2C8\uB2E4. \uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uBB34\uB8CC \uC900\uBE44 \uC11C\uBE44\uC2A4\uB97C \uC774\uC6A9\uD558\uB824\uBA74 \uD574\uB2F9 \uAE30\uAD00\uC5D0 \uC5F0\uB77D\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_prepare_gel_ielts_body_cta3 = "\uC561\uC138\uC2A4 \uAD8C\uD55C \uC5BB\uAE30";
var APPB2C_registration_prepare_gel_ielts_body_line13 = "\uADC0\uD558\uC640 \uADC0\uD558\uC758 \uD559\uC2B5 \uC694\uAD6C \uC0AC\uD56D\uC5D0 \uB530\uB77C \uD544\uC694\uD55C IELTS \uC810\uC218\uC5D0 \uB3C4\uB2EC\uD558\uB294 \uB370 \uB3C4\uC6C0\uC774 \uB418\uB294 \uAC1C\uC778\uD654\uB418\uACE0 \uC2A4\uB9C8\uD2B8\uD55C \uC9C0\uC6D0. \uBAA8\uB4E0 \uAE30\uC220\uC5D0 \uB300\uD55C \uBAA8\uC758 \uD14C\uC2A4\uD2B8 \uBC0F \uC5F0\uC2B5 \uB9AC\uC18C\uC2A4\uB97C \uD3EC\uD568\uD569\uB2C8\uB2E4. GEL IELTS Prep\uC73C\uB85C \uAD6C\uB3D9\uB418\uB294 <0>IELTS Ready Premium</0>\uC740 \uC5EC\uB7EC\uBD84\uC758 \uC131\uACF5\uC744 \uB3C4\uC6B8 \uAC83\uC785\uB2C8\uB2E4.";
var APPB2C_registration_prepare_gel_ielts_header3 = "<0>IELTS Ready \uD504\uB9AC\uBBF8\uC5C4</0>";
var APPB2C_registration_prepare_gel_ukvi_body_cta2 = "\uC9C0\uAE08 IELTS Ready Premium for UKVI \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_gel_ukvi_body_line13 = "\uADC0\uD558\uC640 \uADC0\uD558\uC758 \uD559\uC2B5 \uC694\uAD6C \uC0AC\uD56D\uC5D0 \uB530\uB77C \uC791\uB3D9\uD558\uB294 \uAC1C\uC778\uD654\uB418\uACE0 \uC2A4\uB9C8\uD2B8\uD55C \uC9C0\uC6D0\uC740 \uD544\uC694\uD55C UKVI IELTS \uC810\uC218\uC5D0 \uB3C4\uB2EC\uD558\uB294 \uB370 \uB3C4\uC6C0\uC774 \uB429\uB2C8\uB2E4. \uBAA8\uB4E0 \uAE30\uC220\uC5D0 \uB300\uD55C \uBAA8\uC758 \uD14C\uC2A4\uD2B8 \uBC0F \uC5F0\uC2B5 \uB9AC\uC18C\uC2A4\uB97C \uD3EC\uD568\uD569\uB2C8\uB2E4. GEL IELTS Prep\uC73C\uB85C \uAD6C\uB3D9\uB418\uB294 <0>IELTS Ready Premium</0>\uC740 \uC5EC\uB7EC\uBD84\uC758 \uC131\uACF5\uC744 \uB3C4\uC6B8 \uAC83\uC785\uB2C8\uB2E4.";
var APPB2C_registration_prepare_gel_ukvi_header3 = "<0>IELTS Ready Premium</0> UKVI";
var APPB2C_registration_prepare_header3 = "\uC2DC\uD5D8 \uB300\uBE44";
var APPB2C_registration_prepare_ieltsReady_subTitle3 = "\uB2E4\uC74C\uACFC \uAC19\uC740 \uC900\uBE44 \uC11C\uBE44\uC2A4(preparation service)\uB97C \uC774\uC6A9\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_ieltsReady_title3 = "\uC2DC\uD5D8 \uB300\uBE44 \uC11C\uBE44\uC2A4\uB97C \uC120\uD0DD\uD558\uC138\uC694";
var APPB2C_registration_prepare_ieltsReadyMember_body3 = "\uBAA8\uC758\uACE0\uC0AC, \uC900\uBE44 \uC6E8\uBE44\uB098, \uD301, \uBE44\uB514\uC624, Road to IELTS \uCF58\uD150\uCE20 \uB4F1\uC744 \uD3EC\uD568\uD55C \uB2E4\uC591\uD55C \uC9C0\uC6D0 \uC790\uB8CC. <0>IELTS Ready Member</0> \uC601\uC5ED\uC740 \uC5EC\uB7EC\uBD84\uC774 \uC790\uC2E0 \uC788\uAC8C IELTS \uC2DC\uD5D8\uC744 \uC900\uBE44\uD560 \uC218 \uC788\uB3C4\uB85D \uB3C4\uC640\uB4DC\uB9B4 \uAC83\uC785\uB2C8\uB2E4.";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn3 = "IELTS \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo3 = "<0>IELTS Ready Premium</0>\uC744 \uBB34\uB8CC\uB85C \uC774\uC6A9\uD558\uB824\uBA74 \uC608\uC815\uB41C \uC2DC\uD5D8\uC744 \uC608\uC57D\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_prepare_ieltsReadyMember_freePrepLink3 = "\uBB34\uB8CC \uC900\uBE44 \uC790\uB8CC\uB294 <0>IELTS \uC6F9 \uC0AC\uC774\uD2B8 < / 0\uC5D0\uC11C \uC81C\uACF5\uB429\uB2C8\uB2E4.</0>";
var APPB2C_registration_prepare_ieltsReadyMember_header3 = "<0>IELTS Ready \uD68C\uC6D0</0>";
var APPB2C_registration_prepare_ieltsReadyMember_label3 = "\uB204\uAD6C\uB098 \uBB34\uB8CC\uB85C \uC774\uC6A9 \uAC00\uB2A5";
var APPB2C_registration_prepare_ieltsReadyMember_link3 = "\uBB34\uB8CC \uC774\uC6A9";
var APPB2C_registration_prepare_ieltsReadyMember_payBtn3 = "\uC751\uC2DC\uB8CC \uACB0\uC81C";
var APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo3 = "<0>IELTS Ready Premium</0>\uC744 \uBB34\uB8CC\uB85C \uC774\uC6A9\uD558\uB824\uBA74 \uB2E4\uAC00\uC624\uB294 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uBE44\uC6A9\uC744 \uC9C0\uBD88\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_prepare_intro3 = "IELTS \uB300\uBE44\uC5D0 \uC720\uC6A9\uD55C \uB2E4\uC591\uD55C \uBB34\uB8CC \uC790\uB8CC\uAC00 \uB9C8\uB828\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_introLifeSkills3 = "Life Skills \uB300\uBE44\uC5D0 \uC720\uC6A9\uD55C \uB2E4\uC591\uD55C \uBB34\uB8CC \uC790\uB8CC\uAC00 \uB9C8\uB828\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_onlineCourse_body_cta3 = "\uC9C0\uAE08 \uBB34\uB8CC \uCCB4\uD5D8 \uC2DC\uC791";
var APPB2C_registration_prepare_onlineCourse_body_line13 = "Understanding IELTS\uC5D0 \uD3EC\uD568\uB41C ExpertTrack 4\uAC1C \uACFC\uC815\uC740 IELTS \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC644\uBCBD\uD55C \uC548\uB0B4\uB97C \uC81C\uACF5\uD569\uB2C8\uB2E4. \uC77D\uAE30, \uC4F0\uAE30, \uB4E3\uAE30, \uB9D0\uD558\uAE30\uC758 4\uAC1C \uC2DC\uD5D8 \uACFC\uBAA9\uC744 \uC2EC\uB3C4 \uC788\uAC8C \uD559\uC2B5\uD560 \uC218 \uC788\uC744 \uBFD0 \uC544\uB2C8\uB77C \uBE44\uB514\uC624 \uD29C\uD130(video tutor)\uC640 \uACBD\uD5D8 \uB9CE\uC740 IELTS \uAC15\uC0AC\uC9C4\uC758 \uC870\uC5B8\uB3C4 \uBC1B\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4. 1\uC8FC\uC77C \uAC04 \uC804 \uACFC\uC815\uC744 \uBB34\uB8CC \uCCB4\uD5D8\uD574 \uBCF4\uC138\uC694. ";
var APPB2C_registration_prepare_onlineCourse_header3 = "\uC628\uB77C\uC778 \uACFC\uC815: Understanding IELTS";
var APPB2C_registration_prepare_pbPractice_body_cta3 = "\uC2E4\uC804 \uBAA8\uC758\uACE0\uC0AC \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_pbPractice_body_line13 = "\uC2DC\uAC04 \uC81C\uD55C\uC774 \uC788\uB294 \uACFC\uBAA9\uB4E4\uC744 \uC5F0\uC2B5\uD560 \uC218 \uC788\uB294 \uBAA8\uC758\uACE0\uC0AC\uC785\uB2C8\uB2E4. \uB098\uC911\uC5D0 \uB2F5\uC548\uC744 \uAC80\uD1A0\uD558\uACE0 \uC815\uB2F5\uACFC \uBE44\uAD50\uD574 \uBCFC \uC218\uB3C4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_pbPractice_header3 = "\uC2E4\uC804 \uBAA8\uC758\uACE0\uC0AC";
var APPB2C_registration_prepare_practice_body_cta3 = "\uC2E4\uC804 \uBAA8\uC758\uACE0\uC0AC \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_practice_body_line13 = "\uBB34\uB8CC \uBAA8\uC758\uACE0\uC0AC\uB97C \uD1B5\uD574 \uC6D0\uD558\uB294 IELTS \uC810\uC218\uB97C \uB2EC\uC131\uD558\uC138\uC694. \uC624\uD504\uB77C\uC778 \uD559\uC2B5\uC744 \uC120\uD638\uD558\uC2E0\uB2E4\uBA74 \uC18C\uAC1C \uD398\uC774\uC9C0(introductory pages)\uC5D0\uC11C \uBB38\uC81C\uC9C0, \uB2F5\uC548\uC9C0, \uB300\uBCF8, \uC815\uB2F5\uC744 \uB2E4\uC6B4\uB85C\uB4DC \uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_prepare_practice_header3 = "\uC2E4\uC804 \uBAA8\uC758\uACE0\uC0AC";
var APPB2C_registration_prepare_prepApp_body_cta3 = "IELTS Prep App \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_prepApp_body_line13 = "IELTS App\uC744 \uD1B5\uD574 \uC0D8\uD50C \uBB38\uC81C, \uBAA8\uC758\uACE0\uC0AC, \uBB38\uBC95 \uD301, \uC5F0\uC2B5\uBB38\uC81C, \uD034\uC988 \uB4F1\uC744 \uC774\uC6A9\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uB610, \uC774\uB97C \uD1B5\uD574 \uC790\uC2E0\uC758 \uC2E4\uB825\uC744 \uC810\uAC80\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. ";
var APPB2C_registration_prepare_prepApp_header3 = "IELTS \uC900\uBE44 \uC571";
var APPB2C_registration_prepare_prepApp_ukvi_cta13 = "\uC544\uC774\uD3F0 OS";
var APPB2C_registration_prepare_prepApp_ukvi_cta23 = "\uC548\uB4DC\uB85C\uC774\uB4DC";
var APPB2C_registration_prepare_prepApp_ukvi_line13 = "IELTS Prep App\uC740 IELTS \uB4E3\uAE30\xB7\uB9D0\uD558\uAE30 \uC601\uC5ED\uC744 \uC9D1\uC911\uC801\uC73C\uB85C \uD559\uC2B5\uD558\uB294 \uB3D9\uC2DC\uC5D0 \uC5B4\uD718\uB825 \uD655\uC7A5\uACFC \uBB38\uBC95 \uC5F0\uC2B5\uC744 \uBCD1\uD589\uD560 \uC218 \uC788\uB294 \uD559\uC2B5 \uB3C4\uAD6C\uC785\uB2C8\uB2E4. \uC2DC\uD5D8 \uB2F9\uC77C \uC751\uC2DC \uC694\uB839\uB3C4 \uBBF8\uB9AC \uC219\uC9C0\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uB610, \uBB38\uBC95 \uC5F0\uC2B5, \uB4E3\uAE30\xB7\uB9D0\uD558\uAE30 \uBAA8\uC758\uACE0\uC0AC, IELTS \uB9D0\uD558\uAE30 \uC778\uD130\uBDF0 \uB3D9\uC601\uC0C1 \uC2DC\uCCAD\uC744 \uD1B5\uD574 \uC790\uC2E0\uC758 \uC2E4\uB825\uC744 \uC810\uAC80\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_prepApp_ukvi_line23 = "\uB2E4\uC74C \uBC84\uC804 \uB2E4\uC6B4\uB85C\uB4DC:";
var APPB2C_registration_prepare_prepVideos_body_cta3 = "IELTS \uB300\uBE44 \uB3D9\uC601\uC0C1 \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_prepVideos_body_line13 = "\uC77C\uB828\uC758 \uB3D9\uC601\uC0C1\uC744 \uBCF4\uBA74\uC11C IELTS \uC2DC\uD5D8 \uBC29\uC2DD\uC5D0 \uB300\uD574 \uC54C\uC544\uBCF4\uC138\uC694.";
var APPB2C_registration_prepare_prepVideos_header3 = "IELTS \uB300\uBE44 \uB3D9\uC601\uC0C1";
var APPB2C_registration_prepare_reading_body_cta3 = "Understanding IELTS: Reading \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_reading_body_line13 = "IELTS \uC77D\uAE30 \uC2DC\uD5D8\uC5D0 \uB300\uD574 \uBBF8\uB9AC \uC0B4\uD3B4\uBCF4\uACE0 \uC88B\uC740 \uC131\uACFC\uB97C \uAC70\uB450\uC138\uC694.";
var APPB2C_registration_prepare_reading_header3 = "IELTS \uC774\uD574\uD558\uAE30: \uC77D\uAE30";
var APPB2C_registration_prepare_rteLastMinute_body_cta3 = "\uC9C0\uAE08 \uC2DC\uC791\uD558\uAE30";
var APPB2C_registration_prepare_rteLastMinute_body_item13 = "\uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC870\uC5B8\uC744 \uB2F4\uC740 \uB3D9\uC601\uC0C1 9\uAC1C,";
var APPB2C_registration_prepare_rteLastMinute_body_item23 = "\uC591\uBC29\uD5A5 \uC5F0\uC2B5\uBB38\uC81C 100\uAC1C,";
var APPB2C_registration_prepare_rteLastMinute_body_item33 = "IELTS \uC804\uACFC\uBAA9 \uBAA8\uC758\uACE0\uC0AC 2\uD68C.";
var APPB2C_registration_prepare_rteLastMinute_body_line13 = "\uB2E4\uC74C \uC790\uB8CC\uB4E4\uC744 \uD65C\uC6A9\uD574 IELTS \uC2DC\uD5D8\uC5D0 \uB300\uBE44\uD558\uC138\uC694.";
var APPB2C_registration_prepare_rteLastMinute_header3 = "Road to IELTS \u2013 \uB9C8\uC9C0\uB9C9 \uC21C\uAC04";
var APPB2C_registration_prepare_rteLastMinute_ukvi_cta3 = "\uACFC\uC815 \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_rteLastMinute_ukvi_line13 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC778\uAE30 \uC628\uB77C\uC778 \uC2DC\uD5D8 \uB300\uBE44 \uD504\uB85C\uADF8\uB7A8 Road to IELTS \u2013 Last Minute Course\uB97C \uBB34\uC81C\uD55C \uC774\uC6A9\uD558\uC138\uC694. \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uC870\uC5B8\uC744 \uB2F4\uC740 \uB3D9\uC601\uC0C1\uC744 \uC2DC\uCCAD\uD558\uACE0, 100\uAC1C\uC758 \uC591\uBC29\uD5A5 \uC5F0\uC2B5\uBB38\uC81C\uB97C \uD480\uACE0, \uB450 \uBC88\uC758 \uC804\uACFC\uBAA9 \uBAA8\uC758\uACE0\uC0AC\uB97C \uD1B5\uD574 IELTS\uB97C \uBBF8\uB9AC \uACBD\uD5D8\uD574 \uBCF4\uC138\uC694. ";
var APPB2C_registration_prepare_rteTestDrive_body_cta3 = "Road to IELTS Test Driver \uC774\uC6A9\uD574 \uBCF4\uAE30";
var APPB2C_registration_prepare_rteTestDrive_body_item13 = "\uC591\uBC29\uD5A5 \uC5F0\uC2B5\uBB38\uC81C 30\uAC1C,";
var APPB2C_registration_prepare_rteTestDrive_body_item23 = "\uC2DC\uD5D8\uC5D0 \uB300\uD55C \uD301\uACFC \uC870\uC5B8\uC774 \uB2F4\uAE34 \uB3D9\uC601\uC0C1 4\uAC1C,";
var APPB2C_registration_prepare_rteTestDrive_body_item33 = "IELTS \uC804\uACFC\uBAA9 \uBAA8\uC758\uACE0\uC0AC.";
var APPB2C_registration_prepare_rteTestDrive_body_line13 = "\uC544\uB798 Road to IELTS Test Drive \uCF58\uD150\uCE20\uC640 \uD568\uAED8 IELTS\uB97C \uD5A5\uD55C \uC5EC\uC815\uC744 \uD798\uCC28\uAC8C \uC2DC\uC791\uD558\uC138\uC694.";
var APPB2C_registration_prepare_rteTestDrive_body_line23 = "\uB4F1\uB85D\uC744 \uC644\uB8CC\uD558\uC2DC\uBA74 \uB354 \uB9CE\uC740 \uC790\uB8CC\uB97C \uBCF4\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_rteTestDrive_header3 = "Road to IELTS Test Drive \u2013 \uBB34\uB8CC \uBC84\uC804";
var APPB2C_registration_prepare_speaking_body_cta3 = "Understanding IELTS: Speaking \uC811\uC18D\uD558\uAE30";
var APPB2C_registration_prepare_speaking_body_line13 = "IELTS \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC5D0 \uB300\uD574 \uBBF8\uB9AC \uC0B4\uD3B4\uBCF4\uACE0 \uC88B\uC740 \uC131\uACFC\uB97C \uAC70\uB450\uC138\uC694.";
var APPB2C_registration_prepare_speaking_header3 = "IELTS \uC774\uD574\uD558\uAE30: \uB9D0\uD558\uAE30";
var APPB2C_registration_prepare_tutor_body_cta3 = "\uC5EC\uAE30\uB97C \uD074\uB9AD\uD558\uC5EC \uCCAB \uC218\uC5C5\uC744 $1\uC5D0 \uC2DC\uC791\uD558\uACE0 \uD61C\uD0DD\uC744 \uB204\uB9AC\uC138\uC694.";
var APPB2C_registration_prepare_tutor_body_line13 = "IELTS \uC804\uBB38 \uAC15\uC0AC\uC640 \uC2DC\uD5D8\uC744 \uC900\uBE44\uD558\uC138\uC694. \uB2E4\uC591\uD55C \uACFC\uC815 \uC911 \uC120\uD0DD\uD560 \uC218 \uC788\uC5B4 \uC2DC\uD5D8\uC77C\uAE4C\uC9C0 \uB0A8\uC740 \uAE30\uAC04\uC774 1\uC8FC\uC77C\uC774\uB4E0 \uBA87 \uAC1C\uC6D4\uC774\uB4E0 \uB300\uBE44\uAC00 \uAC00\uB2A5\uD569\uB2C8\uB2E4. \uC624\uB298 \uCCAB \uC218\uC5C5\uC744 $1\uB85C \uC2DC\uC791\uD574 \uBCF4\uC138\uC694.";
var APPB2C_registration_prepare_tutor_body_line23 = "\uD2B9\uBCC4 \uD61C\uD0DD: \uC601\uAD6D\uBB38\uD654\uC6D0 IELTS \uC751\uC2DC\uC790\uB294 1:1 \uC2DC\uD5D8 \uB300\uBE44 \uACFC\uC815 \uC911 \uD558\uB098\uB97C 10% \uD560\uC778\uB41C \uAC00\uACA9\uC73C\uB85C \uAD6C\uB9E4\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_prepare_tutor_header3 = "1:1 \uC218\uC5C5 \u2013 IELTS \uC900\uBE44 \uACFC\uC815";
var APPB2C_registration_prepare_webinars_body_cta3 = "\uC774\uC6A9\uC744 \uC704\uD574 \uAC00\uC785\uD558\uAE30";
var APPB2C_registration_prepare_webinars_body_item13 = "\uC2DC\uD5D8 \uC548\uB0B4 \uB3D9\uC601\uC0C1";
var APPB2C_registration_prepare_webinars_body_item23 = "\uACFC\uBAA9\uBCC4 \uC0C1\uC138 \uD559\uC2B5 \uACC4\uD68D";
var APPB2C_registration_prepare_webinars_body_item33 = "\uC2E4\uC804 \uBAA8\uC758\uACE0\uC0AC";
var APPB2C_registration_prepare_webinars_body_line13 = "IELTS \uC804\uBB38\uAC00\uAC00 \uC9C4\uD589\uD558\uB294 \uBB34\uB8CC \uC8FC\uAC04 IELTS \uC6E8\uBE44\uB098\uB85C \uC2DC\uD5D8\uC5D0 \uB300\uBE44\uD558\uC138\uC694. \uAC01\uC885 \uC2DC\uD5D8 \uB300\uBE44 \uC790\uB8CC\uAC00 \uB2F4\uAE34 IELTS Study Pack\uB3C4 \uC81C\uACF5\uB429\uB2C8\uB2E4.";
var APPB2C_registration_prepare_webinars_header3 = "IELTS \uC6E8\uBE44\uB098";
var APPB2C_registration_results_bandScore_osrBooked3 = "\uC7AC\uC2DC\uD5D8 \uC811\uC218 \uC644\uB8CC <1>\uC811\uC218\uB41C \uC2DC\uD5D8 \uC870\uD68C</1>";
var APPB2C_registration_results_bandScore_osrMarked3 = "\uCC44\uC810\uB41C \uC7AC\uC2DC\uD5D8 <1>\uACB0\uACFC \uBCF4\uAE30</1>";
var APPB2C_registration_results_eor_acknowledgement_backButton3 = "\uC774 \uC2DC\uD5D8 \uBCF4\uAE30";
var APPB2C_registration_results_eor_acknowledgement_components3 = "\uC120\uD0DD\uD558\uC2E0 \uC7AC\uCC44\uC810 \uB300\uC0C1 \uACFC\uBAA9(\uB4E4)\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4,";
var APPB2C_registration_results_eor_acknowledgement_confirmation3 = "\uC7AC\uCC44\uC810 \uC608\uC57D \uD655\uC815\uC11C\uB97C \uADC0\uD558\uC758 \uC774\uBA54\uC77C \uC8FC\uC18C\uB85C \uBC1C\uC1A1\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_acknowledgement_fee3 = "\uC218\uC218\uB8CC";
var APPB2C_registration_results_eor_acknowledgement_info3 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810(EOR) \uC774 \uCDE8\uC18C\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_acknowledgement_noteTitle3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_registration_results_eor_acknowledgement_payment3 = "\uACB0\uC81C \uC644\uB8CC";
var APPB2C_registration_results_eor_acknowledgement_paymentButton3 = "\uACB0\uC81C \uC635\uC158 \uBCF4\uAE30";
var APPB2C_registration_results_eor_acknowledgement_paymentNote3 = "\uACB0\uC81C\uB97C \uC644\uB8CC\uD558\uC9C0 \uC54A\uC73C\uBA74 \uC2E0\uCCAD\uC774 \uCDE8\uC18C\uB429\uB2C8\uB2E4";
var APPB2C_registration_results_eor_acknowledgement_reference3 = "\uC7AC\uCC44\uC810(EOR) reference";
var APPB2C_registration_results_eor_acknowledgement_success3 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810(EOR) \uC2E0\uCCAD\uC774 \uC811\uC218\uB418\uC5C8\uC73C\uBA70 \uC2EC\uC0AC\uAC00 \uC9C4\uD589 \uC911\uC785\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_acknowledgement_tax3 = "\uC138\uAE08";
var APPB2C_registration_results_eor_acknowledgement_terms3 = "\uADC0\uD558\uB294 \uC774 <0>\uC774\uC6A9 \uADDC\uC815</0>\uC5D0 \uB3D9\uC758\uD558\uC168\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_results_eor_acknowledgement_title3 = "\uC7AC\uCC44\uC810 \uC2E0\uCCAD \uD655\uC778";
var APPB2C_registration_results_eor_acknowledgement_total3 = "\uD569\uACC4";
var APPB2C_registration_results_eor_postDeadline_back3 = "\uC7AC\uCC44\uC810\uC744 \uC6D0\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_results_eor_postDeadline_email3 = "\uC774\uBA54\uC77C";
var APPB2C_registration_results_eor_postDeadline_help3 = "\uC2E0\uC18D\uD788 \uB3C4\uC6C0\uC744 \uB4DC\uB9B4 \uC218 \uC788\uB3C4\uB85D registration reference number\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_postDeadline_iol_launchChatLine3 = "\uC774\uC5D0 \uB300\uD574 \uB204\uAD70\uAC00\uC640 \uC628\uB77C\uC778\uC73C\uB85C \uCC44\uD305\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_postDeadline_iol_line33 = "\uADC0\uD558\uC758 \uC7AC\uCC44\uC810 (EOR) \uC774 \uACE0\uB824\uB418\uAE30\uB97C \uC6D0\uD558\uC2DC\uB294 \uACBD\uC6B0, \uB2F9\uC0AC\uC5D0 \uC5F0\uB77D\uD558\uC5EC \uC774\uC5D0 \uB300\uD574 \uC138\uBD80\uC801\uC73C\uB85C \uC54C\uB824\uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_postDeadline_line13 = "\uC7AC\uCC44\uC810(EOR)\uC740 \uC2DC\uD5D8\uC77C \uD6C4 {{count}}\uC77C \uC774\uB0B4\uC5D0 \uC2E0\uCCAD\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_postDeadline_line23 = "\uC7AC\uCC44\uC810 \uC2E0\uCCAD \uAE30\uD55C\uC774 \uC9C0\uB0AC\uC73C\uBBC0\uB85C \uC628\uB77C\uC778\uC5D0\uC11C \uADC0\uD558\uC758 \uC694\uCCAD\uC744 \uCC98\uB9AC\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_postDeadline_line33 = "\uC7AC\uCC44\uC810 \uAC00\uB2A5 \uC5EC\uBD80\uC5D0 \uB300\uD55C \uC2DC\uD5D8 \uC13C\uD130\uC758 \uD310\uB2E8\uC744 \uC6D0\uD558\uC2DC\uB294 \uACBD\uC6B0 \uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC5D0 \uC5F0\uB77D\uD558\uC5EC \uAD00\uB828 \uC815\uBCF4\uB97C \uC81C\uACF5\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_postDeadline_phone3 = "\uC804\uD654";
var APPB2C_registration_results_eor_preDeadline_back3 = "\uC131\uC801 \uC870\uD68C \uD654\uBA74\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength3 = "\uC124\uBA85\uC740 \uCD5C\uB300 {{count}}\uC790\uAE4C\uC9C0 \uAC00\uB2A5\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber3 = "\uC720\uD6A8\uD55C \uC2DC\uD5D8 \uBCF4\uACE0\uC11C \uC591\uC2DD(TRF) \uBC88\uD638\uB97C \uC81C\uACF5\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber3 = "\uD14C\uC2A4\uD2B8 \uBCF4\uACE0\uC11C \uC591\uC2DD(TRF) \uBC88\uD638\uB294 \uD2B9\uC815 \uD615\uC2DD(\uC608: 11AA111110AAAA001A)\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_evidence3 = "\uC7AC\uCC44\uC810 \uC2E0\uCCAD\uC744 \uB4B7\uBC1B\uCE68\uD558\uB294 \uC99D\uBE59\uC744 \uC5C5\uB85C\uB4DC \uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_preDeadline_evidenceRequired3 = "\uC7AC\uCC44\uC810 \uC2E0\uCCAD\uC744 \uB4B7\uBC1B\uCE68\uD558\uB294 \uC124\uBA85\uC744 \uC81C\uC2DC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_explanation3 = "\uC120\uD0DD\uD558\uC2E0 \uACFC\uBAA9\uC774 \uC7AC\uCC44\uC810 \uB418\uC5B4\uC57C \uD558\uB294 \uC774\uC720\uB97C \uC544\uB798\uC5D0 \uAC04\uB7B5\uD788 \uC124\uBA85\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_preDeadline_explanationRequired3 = "\uC7AC\uCC44\uC810 \uC2E0\uCCAD\uC744 \uB4B7\uBC1B\uCE68\uD558\uB294 \uC124\uBA85\uC744 \uC81C\uC2DC\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_fee3 = "\uC218\uC218\uB8CC";
var APPB2C_registration_results_eor_preDeadline_iolNote_line12 = "\uC7AC\uCC44\uC810\uC740 \uD6C8\uB828\uB41C \uC120\uC784 \uCC44\uC810\uAD00\uC774 \uC218\uD589\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_iolNote_line23 = "\uACB0\uACFC\uB294 \uC2E0\uCCAD\uC11C\uC640 \uAC19\uC740 \uB0A0\uACFC \uC2E0\uCCAD \uD6C4 \uCD5C\uB300 21\uC77C \uC774\uB0B4\uC5D0 \uD655\uC778\uD560 \uC218 \uC788\uC73C\uBA70, \uC774\uB294 \uC7AC\uCC44\uC810\uC744 \uC704\uD574 \uC694\uCCAD\uB41C \uC139\uC158 \uC218\uB97C \uD3EC\uD568\uD55C \uC5EC\uB7EC \uC694\uC778\uC5D0 \uB530\uB77C \uB2EC\uB77C\uC9D1\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_iolNote_line33 = "21\uC77C \uD6C4\uC5D0\uB3C4 \uB2F5\uBCC0\uC744 \uBC1B\uC9C0 \uBABB\uD55C \uACBD\uC6B0 <0>\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC73C\uB85C \uBB38\uC758</0>\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_legal3 = "\uBCF8\uC778\uC740 \uC7AC\uCC44\uC810 <1>\uC774\uC6A9 \uADDC\uC815</1>\uC5D0 \uB3D9\uC758\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_legalNote3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC7AC\uCC44\uC810 \uC2E0\uCCAD\uC744 \uCC98\uB9AC\uD558\uAE30 \uC704\uD574 \uADC0\uD558\uAC00 \uC81C\uACF5\uD558\uB294 \uAC1C\uC778\uC815\uBCF4\uB97C \uC0AC\uC6A9\uD569\uB2C8\uB2E4. \uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uADC0\uD558\uC758 \uAC1C\uC778\uC815\uBCF4\uB97C \uCC98\uB9AC\uD558\uB294 \uBC95\uC801 \uADFC\uAC70\uB294 \uC601\uAD6D\uBB38\uD654\uC6D0 \uC7AC\uCC44\uC810 \uC774\uC6A9 \uADDC\uC815\uC5D0 \uB300\uD55C \uADC0\uD558\uC758 \uB3D9\uC758\uC5D0 \uAE30\uCD08\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_line13 = "\uC2DC\uD5D8 \uCC44\uC810 \uBC29\uC2DD\uC5D0 \uBD88\uB9CC\uC871\uD558\uC154\uC11C \uC77C\uBD80 \uC601\uC5ED(\uB4E4)\uC758 \uC7AC\uCC44\uC810\uC744 \uD76C\uB9DD\uD558\uC2DC\uB294 \uACBD\uC6B0, \uC2DC\uD5D8\uC77C\uB85C\uBD80\uD130 \uCD5C\uB300 {{count}} \uC77C\uAE4C\uC9C0 \uC7AC\uCC44\uC810\uC744 \uC2E0\uCCAD\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_line23 = "IELTS \uC131\uC801 \uC7AC\uCC44\uC810\uC5D0\uB294 \uC218\uC218\uB8CC\uAC00 \uBD80\uACFC\uB418\uB098, \uC5B4\uB290 \uACFC\uBAA9\uC774\uB4E0 \uC7AC\uCC44\uC810 \uD6C4 \uC131\uC801\uC774 \uC0C1\uD5A5 \uC870\uC815\uB418\uB294 \uACBD\uC6B0 \uC218\uC218\uB8CC\uB294 \uD658\uBD88\uB429\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_maxScore3 = "\uC774 \uACFC\uBAA9\uC740 \uB9CC\uC810\uC744 \uBC1B\uC558\uC73C\uBBC0\uB85C \uC7AC\uCC44\uC810\uC744 \uC2E0\uCCAD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_note3 = "\uC7AC\uCC44\uC810 \uC218\uC218\uB8CC \uACB0\uC81C \uD6C4\uC5D0\uB294 \uC7AC\uCC44\uC810\uC744 \uCDE8\uC18C\uD558\uAC70\uB098 \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_noteTitle3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel3 = "\uC7AC\uCC44\uC810 \uC2E0\uCCAD\uC5D0 \uB300\uD55C \uC8FC\uBB38\uD655\uC815\uC11C\uB97C \uC218\uB839\uD558\uB294 \uC0AC\uB78C\uC740 \uB204\uAD6C\uC785\uB2C8\uAE4C?";
var APPB2C_registration_results_eor_preDeadline_paymentCta3 = "\uACB0\uC81C \uC635\uC158 \uBCF4\uAE30";
var APPB2C_registration_results_eor_preDeadline_paymentTitle3 = "\uC7AC\uCC44\uC810 \uC218\uC218\uB8CC \uACB0\uC81C";
var APPB2C_registration_results_eor_preDeadline_payOfflineCTA3 = "\uB2E4\uB978 \uBC29\uBC95\uC73C\uB85C \uACB0\uC81C";
var APPB2C_registration_results_eor_preDeadline_payOnlineCTA3 = "\uC628\uB77C\uC778 \uACB0\uC81C";
var APPB2C_registration_results_eor_preDeadline_scoresRequired3 = "\uCD5C\uC18C\uD55C \uD558\uB098\uC758 \uACFC\uBAA9\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_preDeadline_scoresTitle3 = "\uC7AC\uCC44\uC810 \uD76C\uB9DD \uACFC\uBAA9\uC744 \uD558\uB098 \uC774\uC0C1 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr3 = "\uADC0\uD558\uB294 \uB2E4\uC74C\uACFC \uAC19\uC740 \uACB0\uACFC\uC5D0 \uB300\uD574 \uBB38\uC758\uD558\uAE30\uB85C \uC120\uD0DD\uD558\uC600\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening3 = "\uB4E3\uAE30 \uC7AC\uC2DC\uD5D8 \uC131\uC801";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading3 = "\uC77D\uAE30 \uC7AC\uC751\uC2DC \uACB0\uACFC";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking3 = "\uB9D0\uD558\uAE30 \uC7AC\uC2DC\uD5D8 \uC131\uC801";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing3 = "\uC4F0\uAE30 \uC7AC\uC751\uC2DC \uACB0\uACFC";
var APPB2C_registration_results_eor_preDeadline_tax3 = "\uC138\uAE08 \uD3EC\uD568";
var APPB2C_registration_results_eor_preDeadline_trfNumberLabel3 = "\uC131\uC801\uD45C(TRF) \uBC88\uD638";
var APPB2C_registration_results_eor_preDeadline_trfNumberSubnote3 = "\uC131\uC801\uD45C \uC624\uB978\uCABD \uD558\uB2E8\uC758 15~18\uC790\uB9AC \uC22B\uC790\uB97C \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_eor_preDeadline_trfNumberValidation3 = "\uBC88\uD638\uB294 \uC815\uD574\uC9C4 \uD615\uC2DD(\uC608: 11AA111110AAAA001A)\uACFC \uC77C\uCE58\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_eor_title3 = "\uC2DC\uD5D8 \uC7AC\uCC44\uC810 (Enquiry on Results)";
var APPB2C_registration_results_eor_titlePayment3 = "\uC7AC\uCC44\uC810(EOR) \uC218\uC218\uB8CC \uACB0\uC81C";
var APPB2C_registration_results_hasResults_bandScore_decrease3 = "\uC904\uB2E4";
var APPB2C_registration_results_hasResults_bandScore_increase3 = "\uC99D\uAC00";
var APPB2C_registration_results_hasResults_bandScore_unchanged3 = "\uBCC0\uACBD";
var APPB2C_registration_results_hasResults_eorAgentInfo2 = "\uC7AC\uCC44\uC810\uC744 \uC6D0\uD558\uC2DC\uBA74 \uADC0\uD558\uC758 \uC2DC\uD5D8 \uC5C5\uBB34\uB97C \uB300\uB9AC\uD558\uB294 \uB300\uD589\uC5C5\uCCB4\uC5D0 \uBB38\uC758\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_eorLetter3 = "\uC790\uC138\uD55C \uB0B4\uC6A9\uC740 <0>EOR \uACB0\uACFC \uACB0\uACFC \uC11C\uC2E0<1></1></0>\uC744 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_hasResults_eorLine13 = "\uC7AC\uCC44\uC810\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_eorLine23 = "\uC810\uC218\uB294 \uC544\uB798\uC5D0\uC11C \uD655\uC778\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uD45C\uC2DC\uB41C \uC139\uC158\uC740 \uC27D\uAC8C \uCC38\uC870\uD560 \uC218 \uC788\uB3C4\uB85D \uAC15\uC870 \uD45C\uC2DC\uB429\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_eorLink3 = "\uC131\uC801\uC758 \uC7AC\uCC44\uC810\uC744 \uC6D0\uD569\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_eTrfCta2 = "\uC131\uC801\uD45C(TRF) \uB2E4\uC6B4\uB85C\uB4DC";
var APPB2C_registration_results_hasResults_explanations_heading3 = "\uC810\uC218 \uC124\uBA85";
var APPB2C_registration_results_hasResults_explanations_hideButton2 = "\uC810\uC218 \uC124\uBA85 \uC228\uAE40";
var APPB2C_registration_results_hasResults_explanations_improve3 = "\uAC1C\uC120 \uBC29\uC548";
var APPB2C_registration_results_hasResults_explanations_showButton2 = "\uC810\uC218 \uC124\uBA85 \uD45C\uC2DC";
var APPB2C_registration_results_hasResults_lifeSkills_failed3 = "\uBD88\uD569\uACA9";
var APPB2C_registration_results_hasResults_lifeSkills_level3 = "CEFR \uB808\uBCA8";
var APPB2C_registration_results_hasResults_lifeSkills_note3 = "\uC628\uB77C\uC778\uC5D0\uC11C \uC870\uD68C \uAC00\uB2A5\uD55C \uC131\uC801\uC740 \uC7A0\uC815 \uC131\uC801\uD45C\uC774\uBBC0\uB85C \uACF5\uC2DD \uD655\uC778 \uC6A9\uB3C4\uB85C \uC0AC\uC6A9\uD558\uC2E4 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. IELTS \uC131\uC801\uD45C(TRF)\uAC00 \uADC0\uD558\uC758 \uC131\uC801\uC744 \uC99D\uBA85\uD558\uB294 \uACF5\uC2DD \uBB38\uC11C\uC785\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_lifeSkills_passed3 = "\uD569\uACA9";
var APPB2C_registration_results_hasResults_lifeSkills_result3 = "\uC131\uC801";
var APPB2C_registration_results_hasResults_osr_bandScore_decrease2 = "\uD558\uB77D";
var APPB2C_registration_results_hasResults_osr_bandScore_increase2 = "\uC0C1\uC2B9";
var APPB2C_registration_results_hasResults_osr_bandScore_unchanged2 = "\uBCC0\uACBD \uC5C6\uC74C";
var APPB2C_registration_results_hasResults_osr_previousResults3 = "\uC774\uC804 \uC2DC\uD5D8 \uACB0\uACFC \uC804\uCCB4 \uBCF4\uAE30";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uAC00 {{originalScore}}\uC5D0\uC11C {{osrScore}}\uB85C \uD558\uB77D\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uAC00 {{originalScore}}\uC5D0\uC11C {{osrScore}}\uB85C \uC0C1\uC2B9\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uAC00 {{originalScore}}\uC5D0\uC11C \uBCC0\uD558\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_osr_updateBar_title3 = "\uADC0\uD558\uC758 {{osrSkillName}} \uC7AC\uC2DC\uD5D8 \uACB0\uACFC({{score}}) \uAC00 \uC0C8\uB85C\uC6B4 \uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uC5D0 \uBC18\uC601\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_osrLink2 = "\uD55C \uACFC\uBAA9\uC5D0 \uB300\uD55C \uC7AC\uC2DC\uD5D8\uC744 \uC6D0\uD569\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_posting_oneCopyWarning3 = "\uC131\uC801\uD45C\uB294 1\uBD80\uB9CC \uBC1C\uC1A1\uB418\uBBC0\uB85C \uC548\uC804\uD558\uAC8C \uBCF4\uAD00\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_posting_postedOn3 = "\uADC0\uD558\uC758 \uC131\uC801\uD45C\uAC00 \uB2E4\uC74C \uC704\uCE58\uC5D0 \uAC8C\uC2DC\uB418\uC5C8\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_posting_postedTo3 = "\uB2E4\uC74C \uC704\uCE58\uC5D0 \uAC8C\uC2DC\uB418\uC5C8\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_posting_willPostOn3 = "\uADC0\uD558\uC758 \uC131\uC801\uD45C\uB294 \uB2E4\uC74C \uC704\uCE58\uC5D0 \uAC8C\uC2DC\uB420 \uC608\uC815\uC785\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_posting_willPostTo3 = "\uB2E4\uC74C \uC704\uCE58\uC5D0 \uAC8C\uC2DC\uB420 \uC608\uC815\uC785\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_rankNameConsonant3 = "\uC774\uB294 \uADC0\uD558\uAC00 ~\uC784\uC744 \uC758\uBBF8\uD569\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_rankNameVowel3 = "\uC774\uB294 \uADC0\uD558\uAC00 ~\uC784\uC744 \uC758\uBBF8\uD569\uB2C8\uB2E4";
var APPB2C_registration_results_hasResults_scoreImprove_btn3 = "\uB0B4 \uC635\uC158 \uBCF4\uAE30";
var APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn3 = "\uC2DC\uD5D8 \uACB0\uACFC \uB0B4\uC5ED\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description3 = "\uB450 \uAC1C \uC774\uC0C1 \uC601\uC5ED\uC758 \uC810\uC218\uAC00 \uBD88\uB9CC\uC871\uC2A4\uB7EC\uC6B0\uC2DC\uB2E4\uBA74 \uC0C8\uB85C\uC6B4 \uC2DC\uD5D8\uC5D0 \uBE68\uB9AC \uC811\uC218\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title3 = "IELTS \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description3 = "\uC2DC\uD5D8 \uCC44\uC810 \uBC29\uC2DD\uC5D0 \uBD88\uB9CC\uC871\uD558\uC154\uC11C \uC77C\uBD80 \uC601\uC5ED(\uB4E4) \uC758 \uC7AC\uCC44\uC810\uC744 \uD76C\uB9DD\uD558\uC2E0\uB2E4\uBA74 \uC2DC\uD5D8\uC77C\uB85C\uBD80\uD130 \uCD5C\uB300 {{count}} \uC77C\uAE4C\uC9C0 \uC7AC\uCC44\uC810\uC744 \uC2E0\uCCAD\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title3 = "\uC131\uC801 \uC7AC\uCC44\uC810";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description3 = "\uD55C \uC601\uC5ED\uC758 \uC810\uC218\uAC00 \uBD88\uB9CC\uC871\uC2A4\uB7EC\uC6B0\uC2DC\uACE0 \uC2DC\uD5D8 \uB2F9\uC77C\uC758 \uD37C\uD3EC\uBA3C\uC2A4 \uB54C\uBB38\uC774\uB77C\uACE0 \uC0DD\uAC01\uB418\uC2E0\uB2E4\uBA74 One Skill Retake\uC744 \uB4F1\uB85D\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title3 = "\uC2DC\uD5D8\uC758 \uC77C\uBD80 \uC601\uC5ED\uC5D0 \uC7AC\uC751\uC2DC\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle3 = "\uC804\uCCB4 \uBC34\uB4DC \uC810\uC218\uB97C \uD5A5\uC0C1\uC2DC\uD0A4\uB824\uBA74 \uB2E4\uC74C\uACFC \uAC19\uC740 \uC635\uC158\uC744 \uC120\uD0DD\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_scoreImprove_subtitle3 = "\uD544\uC694\uD558\uC2E0 \uC810\uC218\uB97C \uCDE8\uB4DD\uD558\uC9C0 \uBABB\uD558\uC168\uB2E4\uBA74 \uC810\uC218\uB97C \uD5A5\uC0C1\uC2DC\uD0AC \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_scoreImprove_title3 = "\uD544\uC694\uD55C \uC810\uC218\uB97C \uCDE8\uB4DD\uD558\uC9C0 \uBABB\uD558\uC168\uB098\uC694?";
var APPB2C_registration_results_hasResults_scores_label3 = "\uC804\uCCB4 \uC810\uC218 \uBC0F \uC124\uBA85";
var APPB2C_registration_results_hasResults_scores_Listening3 = "\uB4E3\uAE30";
var APPB2C_registration_results_hasResults_scores_Overall3 = "Overall Band Score";
var APPB2C_registration_results_hasResults_scores_Reading3 = "\uC77D\uAE30";
var APPB2C_registration_results_hasResults_scores_Speaking3 = "\uB9D0\uD558\uAE30";
var APPB2C_registration_results_hasResults_scores_Writing3 = "\uC4F0\uAE30";
var APPB2C_registration_results_hasResults_share_applyBoard3 = "\uD504\uB85C\uADF8\uB7A8\uC5D0 \uC9C1\uC811 \uC9C0\uC6D0";
var APPB2C_registration_results_hasResults_share_RecognisingOrgsLink3 = "\uAE30\uAD00\uC5D0 \uACB0\uACFC \uACF5\uC720";
var APPB2C_registration_results_hasResults_share_subtitle3 = "\uC9C0\uAE08 \uC218\uD589\uD558\uC2E4 \uC218 \uC788\uB294 \uC791\uC5C5\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_hasResults_share_title3 = "\uD544\uC694\uD55C \uC810\uC218\uB97C \uCDE8\uB4DD\uD558\uC168\uC2B5\uB2C8\uAE4C?";
var APPB2C_registration_results_hasResults_share_trfCta3 = "\uC2DC\uD5D8 \uACB0\uACFC \uC591\uC2DD \uB2E4\uC6B4\uB85C\uB4DC";
var APPB2C_registration_results_hasResults_viewExplanation3 = "\uC804\uCCB4 \uC810\uC218 \uBC0F \uC124\uBA85 \uC870\uD68C";
var APPB2C_registration_results_noResults_cmds_title3 = "\uC810\uC218 \uBC1C\uD45C \uC2DC\uAE30\uB97C \uD3EC\uD568\uD55C \uC2DC\uD5D8 \uACB0\uACFC\uC5D0 \uB300\uD55C \uC815\uBCF4\uB97C \uBCF4\uC2DC\uB824\uBA74 <0>\uC5EC\uAE30</0>\uB97C \uD074\uB9AD\uD558\uC2ED\uC2DC\uC624. \uACB0\uACFC\uB97C \uBCF4\uACE0 \uC2DC\uD5D8 \uACB0\uACFC\uC9C0(TRF)\uB97C \uB2E4\uC6B4\uB85C\uB4DC\uD560 \uC218 \uC788\uB294 \uB9C1\uD06C\uB97C \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_registration_results_noResults_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_registration_results_noResults_osr_booked3 = "\uC7AC\uC2DC\uD5D8 \uC608\uC57D \uC644\uB8CC";
var APPB2C_registration_results_noResults_osr_previousScores3 = "\uAE30\uC874 \uC810\uC218:";
var APPB2C_registration_results_noResults_title3 = "\uC810\uC218 \uBC1C\uD45C \uC2DC\uAE30\uB97C \uD3EC\uD568\uD55C \uC2DC\uD5D8 \uACB0\uACFC\uC5D0 \uB300\uD55C \uC815\uBCF4\uB97C \uBCF4\uC2DC\uB824\uBA74 <0>\uC5EC\uAE30</0>\uB97C \uD074\uB9AD\uD558\uC2ED\uC2DC\uC624. \uC900\uBE44\uAC00 \uC644\uB8CC\uB418\uBA74 \uACB0\uACFC\uB97C \uBCF4\uC2E4 \uC218 \uC788\uB294 \uB9C1\uD06C\uB97C \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote13 = '\uC544\uC9C1 $t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })\uAC1C \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.';
var APPB2C_registration_results_organisations_availableReceivingOrgsNote23 = "\uC131\uC801\uD45C \uBC1C\uC1A1 \uC2E0\uCCAD\uC740 {{date}}\uAE4C\uC9C0 \uAC00\uB2A5\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote32 = "\uC870\uC9C1\uB9C8\uB2E4 TRF \uC218\uB77D\uC5D0 \uB300\uD55C \uADDC\uC815\uC774 \uB2E4\uB97C \uC218 \uC788\uC73C\uBA70, \uCD5C\uC18C \uC720\uD6A8 \uAE30\uAC04\uC740 2\uB144\uC785\uB2C8\uB2E4. \uC2E0\uCCAD\uD558\uAE30 \uC804\uC5D0 \uC18C\uC18D \uB2E8\uCCB4\uC5D0 \uD655\uC778\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_chosenOrganisationsHeader3 = "\uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uAE30\uAD00";
var APPB2C_registration_results_organisations_editRo_addAnother3 = "+ \uAE30\uAD00 \uCD94\uAC00";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew3 = "+ \uC0C8 \uAE30\uAD00 \uCD94\uAC00";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_address3 = "\uC8FC\uC18C";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_country3 = "\uAD6D\uAC00 \uC120\uD0DD";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll3 = "\uBAA8\uB450";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional3 = "\uAD6D\uAC00 \uC120\uD0DD: (\uC120\uD0DD)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_file3 = "\uD30C\uC77C/\uCF00\uC774\uC2A4 \uBC88\uD638: (\uC120\uD0DD)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_org3 = "\uAE30\uAD00 \uC120\uD0DD";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation3 = "\uAE30\uAD00\uBA85";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_person3 = "\uB2F4\uB2F9\uC790/\uBD80\uC11C\uBA85: (\uC120\uD0DD)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode3 = "\uC6B0\uD3B8\uBC88\uD638";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry3 = "\uAD6D\uAC00\uBA85\uC73C\uB85C \uAC80\uC0C9";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg3 = "\uAE30\uAD00\uBA85\uC73C\uB85C \uAC80\uC0C9";
var APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength3 = "\uD30C\uC77C/\uCF00\uC774\uC2A4 \uBC88\uD638\uB294 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength3 = "\uC0AC\uB78C/\uBD80\uC11C\uC758 \uC774\uB984\uC740 \uCD5C\uB300 {{count}}\uC790\uC77C \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_error_minScoreMissing3 = "\uC804\uCCB4 \uBC34\uB4DC\uC758 \uCD5C\uC18C \uC810\uC218\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_editRo_error_missingAddressLine13 = "\uB2E8\uCCB4 \uC8FC\uC18C\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_editRo_error_missingOrgName3 = "\uC870\uC9C1 \uC774\uB984\uC744 \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength3 = "\uB2E8\uCCB4 \uC774\uB984\uC740 \uCD5C\uB300 {{count}}\uC790\uAE4C\uC9C0 \uAC00\uB2A5\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_addCta3 = "\uAE30\uAD00 \uCD94\uAC00";
var APPB2C_registration_results_organisations_editRo_iol_address3 = "\uC8FC\uC18C";
var APPB2C_registration_results_organisations_editRo_iol_cancelAdd2 = "\uCDE8\uC18C";
var APPB2C_registration_results_organisations_editRo_iol_cancelEdit3 = "\uBCC0\uACBD \uB0B4\uC6A9 \uCDE8\uC18C";
var APPB2C_registration_results_organisations_editRo_iol_conditions_clear3 = "\uCD08\uAE30\uD654";
var APPB2C_registration_results_organisations_editRo_iol_conditions_header3 = "\uC774 \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD558\uAE30 \uC704\uD55C \uCD5C\uC18C \uC810\uC218\uB97C \uC124\uC815\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?";
var APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder3 = "\uC120\uD0DD\uD558\uC2ED\uC2DC\uC624";
var APPB2C_registration_results_organisations_editRo_iol_conditions_no3 = "\uC544\uB2C8\uC624";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note13 = "\uC774 \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uAC00 \uBC1C\uC1A1\uB418\uAE30 \uC704\uD55C \uCD5C\uC18C Overall Band Score\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note23 = "\uACFC\uBAA9\uBCC4\uB85C \uCD5C\uC18C \uC810\uC218\uB97C \uC124\uC815\uD560 \uC218\uB3C4 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note33 = "\uADC0\uD558\uAC00 \uC124\uC815\uD558\uC2E0 \uC810\uC218\uAC00 \uBAA8\uB450 \uB2EC\uC131\uB418\uB294 \uACBD\uC6B0\uC5D0\uB9CC \uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uC774 \uAE30\uAD00\uC5D0 \uADC0\uD558\uC758 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder3 = "\uC120\uD0DD\uC0AC\uD56D";
var APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader3 = "\uD574\uB2F9 \uAE30\uAD00\uC5D0\uB294 \uC6D0\uD558\uB294 \uC810\uC218\uAC00 \uB2EC\uC131\uB41C \uACBD\uC6B0\uC5D0\uB9CC \uC131\uC801\uD45C\uAC00 \uBC1C\uC1A1\uB429\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_conditions_yes3 = "\uC608";
var APPB2C_registration_results_organisations_editRo_iol_copied3 = "\uC774\uBA54\uC77C \uC8FC\uC18C \uBCF5\uC0AC\uB428";
var APPB2C_registration_results_organisations_editRo_iol_copy3 = "\uC774\uBA54\uC77C \uC8FC\uC18C \uBCF5\uC0AC";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery3 = "\uC774 \uC870\uC9C1\uC740 \uB2F9\uC0AC\uB85C\uBD80\uD130 \uC9C1\uC811 eTRF\uB97C \uC218\uB77D\uD569\uB2C8\uB2E4. \uC5BC\uB9C8\uB098 \uB9CE\uC740 \uC870\uC9C1\uC744 \uC120\uD0DD\uD560 \uC218 \uC788\uB294\uC9C0\uC5D0\uB294 \uC601\uD5A5\uC744 \uBBF8\uCE58\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults3 = "\uC99D\uAD8C \uC2DC\uC138 \uD45C\uC2DC\uAE30";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note3 = "eTRF\uB294 \uC6D0\uD558\uB294 \uB9CC\uD07C \uB9CE\uC740 \uB2E8\uCCB4\uC5D0 \uBCF4\uB0BC \uC218 \uC788\uC9C0\uB9CC, \uCD5C\uB300 {{number}}\uAC1C\uC758 TRF \uB2E8\uCCB4\uB9CC \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal3 = "\uC774 \uAE30\uAD00\uC5D0 \uD14C\uC2A4\uD2B8 \uBCF4\uACE0\uC11C \uC591\uC2DD(TRF)\uC744 \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4. <br>\uC774\uB294 {{number}} TRF \uB2E8\uCCB4 \uC218 \uC81C\uD55C\uC5D0 \uD3EC\uD568\uB429\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf3 = "\uC131\uC801\uD45C(TRF)";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note13 = "\uC774 \uAE30\uAD00\uC740 IELTS Online\uC744 \uC778\uC815\uD558\uC9C0\uB9CC eTRF\uB97C \uC9C1\uC811 \uBC1B\uB3C4\uB85D \uB4F1\uB85D\uB418\uC5B4 \uC788\uC9C0\uB294 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note23 = "\uC774 \uAE30\uAD00(\uB4E4)\uC5D0\uB294 \uC131\uC801\uD45C(TRF) \uC218\uB839 \uD6C4 \uC0AC\uBCF8\uC744 \uC774\uBA54\uC77C\uB85C \uBC1C\uC1A1\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results3 = "\uC774 \uAE30\uAD00(\uB4E4)\uC5D0\uB294 \uC131\uC801\uD45C(TRF) \uC0AC\uBCF8\uC744 \uC774\uBA54\uC77C\uB85C \uBC1C\uC1A1\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_newOrgTitle3 = "\uAE30\uAD00 \uCD94\uAC00";
var APPB2C_registration_results_organisations_editRo_iol_noResults3 = "\uACB0\uACFC \uC5C6\uC74C";
var APPB2C_registration_results_organisations_editRo_iol_removeLink3 = "\uAE30\uAD00 \uC0AD\uC81C";
var APPB2C_registration_results_organisations_editRo_iol_saveCta3 = "\uBCC0\uACBD\uC0AC\uD56D \uC800\uC7A5";
var APPB2C_registration_results_organisations_editRo_iol_validation_overallScore3 = "\uCD5C\uC18C Overall Band Score\uAC00 \uD544\uC694\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation3 = "\uAE30\uAD00\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_iol_chooseCta3 = "\uC778\uC815\uAE30\uAD00 \uC120\uD0DD";
var APPB2C_registration_results_organisations_iol_delivery_auto_header3 = "eTRF\uB97C \uC790\uB3D9\uC73C\uB85C \uBC1B\uC744 \uC218 \uC788\uB294 \uAE30\uAD00\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_auto_note3 = "\uC774\uB7EC\uD55C \uB2E8\uCCB4\uC5D0 eTRF\uB97C \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4. \uC544\uBB34 \uAC83\uB3C4 \uD560 \uD544\uC694\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote3 = "\uAC01 \uC870\uC9C1\uC5D0 \uB300\uD574 \uC124\uC815\uD55C \uCD5C\uC18C \uC804\uCCB4 \uBC94\uC704\uB97C \uB2EC\uC131\uD558\uC9C0 \uBABB\uD588\uAE30 \uB54C\uBB38\uC5D0 \uC774\uB7EC\uD55C \uC870\uC9C1\uC5D0 eTRF\uB97C \uBCF4\uB0B4\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_auto_sentNote3 = "\uB2F9\uC0AC\uB294 \uC774\uB7EC\uD55C \uB2E8\uCCB4\uC5D0 \uADC0\uD558\uC758 eTRF\uB97C \uBCF4\uB0B4\uB4DC\uB838\uC2B5\uB2C8\uB2E4. \uC544\uBB34 \uAC83\uB3C4 \uD560 \uD544\uC694\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_postal_header3 = "\uADC0\uD558\uAC00 \uC120\uD0DD\uD558\uC2E0 \uAE30\uAD00 \uC911 \uC6B0\uD3B8 \uBC1C\uC1A1\uC774 \uD544\uC694\uD55C \uAE30\uAD00\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note13 = "\uC774\uB7EC\uD55C \uAE30\uAD00\uC740 IELTS Online\uC744 \uC778\uC815\uD558\uC9C0\uB9CC eTRF\uB97C \uC9C1\uC811 \uBC1B\uB3C4\uB85D \uB4F1\uB85D\uB418\uC5B4 \uC788\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note23 = "\uC774 \uAE30\uAD00(\uB4E4)\uC5D0\uB294 \uC131\uC801\uD45C(TRF) \uC218\uB839 \uD6C4 \uC0AC\uBCF8\uC744 \uC774\uBA54\uC77C\uB85C \uBC1C\uC1A1\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2_results3 = "\uC774 \uAE30\uAD00(\uB4E4)\uC5D0\uB294 \uC131\uC801\uD45C(TRF) \uC0AC\uBCF8\uC744 \uC774\uBA54\uC77C\uB85C \uBC1C\uC1A1\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_edit3 = "\uBCC0\uACBD";
var APPB2C_registration_results_organisations_iol_error3 = "\uBCC0\uACBD\uC0AC\uD56D\uC744 \uBD88\uB7EC\uC624\uB294 \uB3D9\uC548 \uBB38\uC81C\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4.<0></0> \uD398\uC774\uC9C0\uB97C <1>\uC0C8\uB85C\uACE0\uCE68</1> \uD574 \uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_iol_generalCondition3 = "\uB2E4\uC74C\uC758 \uCD5C\uC18C Overall Band Score \uB2EC\uC131 \uC2DC\uC5D0\uB9CC \uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uC774 \uAE30\uAD00\uC5D0 \uADC0\uD558\uC758 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_noConditions3 = "\uC774 \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD558\uAE30 \uC704\uD55C \uCD5C\uC18C \uC810\uC218\uAC00 \uC9C0\uC815\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_notChosen3 = "\uC120\uD0DD\uB418\uC9C0 \uC54A\uC74C";
var APPB2C_registration_results_organisations_iol_preciseCondition3 = "\uADC0\uD558\uAC00 \uC124\uC815\uD558\uC2E0 \uC810\uC218\uAC00 \uBAA8\uB450 \uB2EC\uC131\uB418\uB294 \uACBD\uC6B0\uC5D0\uB9CC \uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uC774 \uAE30\uAD00\uC5D0 \uADC0\uD558\uC758 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_iol_roAccept_link3 = "IELTS Online \uC810\uC218\uB97C \uC778\uC815\uD558\uB294 \uAE30\uAD00\uC744 \uD655\uC778\uD558\uC2ED\uC2DC\uC624";
var APPB2C_registration_results_organisations_iol_roAccept_message3 = "IELTS Online\uC5D0 \uC751\uC2DC\uD558\uAE30 \uC804\uC5D0 \uADC0\uD558\uAC00 \uC9C0\uC6D0\uD558\uB824\uB294 \uAE30\uAD00\uC774 IELTS Online \uC810\uC218\uB97C \uC778\uC815\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_organisations_iol_roAccept_title3 = "\uC9C0\uC6D0\uD558\uB824\uB294 \uAE30\uAD00\uC774\uB098 \uB2E8\uCCB4\uAC00 IELTS Online\uC744 \uC778\uC815\uD569\uB2C8\uAE4C?";
var APPB2C_registration_results_organisations_iol_sendAnyway3 = "\uADF8\uB807\uB354\uB77C\uB3C4 \uC774 \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD574 \uC8FC\uC2ED\uC2DC\uC624";
var APPB2C_registration_results_organisations_list_item13 = "\uAD50\uC721\uAE30\uAD00";
var APPB2C_registration_results_organisations_list_item23 = "\uC815\uBD80\uAE30\uAD00";
var APPB2C_registration_results_organisations_list_item33 = "\uC804\uBB38\uAE30\uAD00";
var APPB2C_registration_results_organisations_list_item43 = "\uAE30\uC5C5";
var APPB2C_registration_results_organisations_mod_delivery_auto_header3 = "eTRF\uB97C \uC790\uB3D9\uC73C\uB85C \uBC1B\uC744 \uC218 \uC788\uB294 \uAE30\uAD00\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_auto_note3 = "\uC774\uB7EC\uD55C \uB2E8\uCCB4\uC5D0 eTRF\uB97C \uBCF4\uB0B4\uB4DC\uB9BD\uB2C8\uB2E4. \uC544\uBB34 \uAC83\uB3C4 \uD560 \uD544\uC694\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4. \uC6D0\uD558\uB294 \uB9CC\uD07C \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote3 = "\uAC01 \uC870\uC9C1\uC5D0 \uB300\uD574 \uC124\uC815\uD55C \uCD5C\uC18C \uC804\uCCB4 \uBC94\uC704\uB97C \uB2EC\uC131\uD558\uC9C0 \uBABB\uD588\uAE30 \uB54C\uBB38\uC5D0 \uC774\uB7EC\uD55C \uC870\uC9C1\uC5D0 eTRF\uB97C \uBCF4\uB0B4\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_auto_sentNote3 = "\uB2F9\uC0AC\uB294 \uC774\uB7EC\uD55C \uB2E8\uCCB4\uC5D0 \uADC0\uD558\uC758 eTRF\uB97C \uBCF4\uB0B4\uB4DC\uB838\uC2B5\uB2C8\uB2E4. \uC544\uBB34 \uAC83\uB3C4 \uD560 \uD544\uC694\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_postal_header3 = "\uC131\uC801\uD45C(TRF)\uAC00 \uC6B0\uD3B8 \uBC1C\uC1A1\uB418\uB3C4\uB85D \uC9C0\uC815\uD558\uC2E0 \uAE30\uAD00\uC740 \uB2E4\uC74C\uACFC \uAC19\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_postal_note13 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uC774 \uAE30\uAD00(\uB4E4)\uC5D0 \uC6B0\uD3B8\uC73C\uB85C \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD558\uBA70, \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uC218 \uC788\uB294 \uAE30\uAD00\uC758 \uC218\uB294 \uCD5C\uB300 {{count}}\uAC1C\uC785\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_postal_note23 = "\uBAA9\uB85D\uC5D0\uC11C \uAE30\uAD00\uC744 \uCC3E\uC744 \uC218 \uC5C6\uB294 \uACBD\uC6B0 \uC9C1\uC811 \uCD94\uAC00\uD558\uC2E4 \uC218 \uC788\uC73C\uBA70, \uD574\uB2F9 \uAE30\uAD00\uC740 \uC131\uC801\uD45C\uB97C \uC6B0\uD3B8\uC73C\uB85C \uBC1C\uC1A1\uD558\uB294 \uAE30\uAD00\uC5D0 \uD3EC\uD568\uB429\uB2C8\uB2E4. ";
var APPB2C_registration_results_organisations_mod_delivery_postal_note33 = '\uC544\uC9C1 $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} })\uAC1C \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.';
var APPB2C_registration_results_organisations_mod_delivery_postal_note43 = '\uC774\uC81C $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} })\uB97C \uC120\uD0DD\uD588\uC73C\uBBC0\uB85C \uB354 \uC774\uC0C1 \uC120\uD0DD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4. eTRF\uB97C \uC218\uB77D\uD558\uB294 \uC870\uC9C1\uC744 \uACC4\uC18D \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.';
var APPB2C_registration_results_organisations_mod_delivery_postal_note53 = "\uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uC218 \uC788\uB294 \uAE30\uAD00\uC758 \uC218\uAC00 \uD55C\uB3C4\uC5D0 \uB3C4\uB2EC\uD588\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote3 = "\uC774 \uAE30\uAD00(\uB4E4)\uC5D0 \uB300\uD574 \uC124\uC815\uD558\uC2E0 \uCD5C\uC18C band score\uB97C \uB2EC\uC131\uB418\uC9C0 \uC54A\uC558\uC73C\uBBC0\uB85C \uC131\uC801\uD45C(TRF)\uAC00 \uBC1C\uC1A1\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_postal_sentNote3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uC774 \uAE30\uAD00(\uB4E4)\uC5D0 e-Results\uB97C \uBC1C\uC1A1\uD588\uC2B5\uB2C8\uB2E4. \uADC0\uD558\uAC00 \uD558\uC2E4 \uC77C\uC740 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount3 = "{{count}} \uC131\uC801\uD45C \uBC1C\uC1A1 \uAE30\uAD00";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other3 = "{{count}} \uC131\uC801\uD45C \uBC1C\uC1A1 \uAE30\uAD00";
var APPB2C_registration_results_organisations_mod_howTo_item13 = "<bold>eTRF</bold>: SGS\uC5D0\uC11C \uC9C1\uC811 \uC804\uC790 \uC2DC\uD5D8 \uBCF4\uACE0\uC11C \uC591\uC2DD(eTRF)\uC744 \uC218\uB77D\uD558\uB294 \uAE30\uAD00. \uC6D0\uD558\uB294 \uB9CC\uD07C \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_howTo_item23 = "<bold>\uC131\uC801\uD45C</bold>: \uC601\uAD6D\uBB38\uD654\uC6D0\uC774 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uBC1C\uC1A1\uD558\uB294 \uC131\uC801\uD45C(TRF)\uB97C \uC778\uC815\uD558\uB294 \uAE30\uAD00\uC785\uB2C8\uB2E4. \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uC218 \uC788\uB294 \uAE30\uAD00\uC758 \uC218\uB294 <bold>\uCD5C\uB300 {{number}}</bold>\uAC1C\uC785\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_mod_howTo_line13 = "\uB450 \uAC00\uC9C0 \uC720\uD615\uC758 \uAE30\uAD00 \uC911 \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_results_organisations_mod_howTo_title3 = "\uC778\uC815\uAE30\uAD00 \uC120\uD0DD \uBC29\uBC95";
var APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote3 = "\uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uAE30\uAD00\uC774 \uC120\uD0DD\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_note03 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uB97C \uB300\uC2E0\uD558\uC5EC \uC778\uC815\uAE30\uAD00\uC73C\uB85C \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_note13 = '\uCD5C\uB300 $t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })\uAC1C \uAE30\uAD00\uC5D0 \uC131\uC801\uD45C\uB97C \uBC1C\uC1A1\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.';
var APPB2C_registration_results_organisations_note23 = "\uC778\uC815\uAE30\uAD00\uC5D0\uB294 \uB2E4\uC74C\uC774 \uD3EC\uD568\uB429\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_organisationsCount3 = "{{count}} \uAE30\uAD00";
var APPB2C_registration_results_organisations_organisationsCount_other3 = "{{count}} \uAE30\uAD00";
var APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote3 = "\uBC1C\uC1A1\uD560 \uC218 \uC788\uB294 \uC131\uC801\uD45C(TRF) \uCD5C\uB300 \uAC1C\uC218\uC5D0 \uB3C4\uB2EC\uD588\uAC70\uB098 \uBC1C\uC1A1 \uC2E0\uCCAD \uAE30\uD55C\uC774 \uC9C0\uB0AC\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_organisations_title3 = "\uC778\uC815\uAE30\uAD00\uC73C\uB85C \uC131\uC801\uD45C \uBC1C\uC1A1";
var APPB2C_registration_results_osr_chooseDate_accessibility3 = "\uC2DC\uD5D8 \uB0A0\uC9DC\uC640 \uAD00\uB828\uD558\uC5EC <bold>\uC811\uADFC\uC131</bold> \uC694\uAD6C \uC0AC\uD56D\uC774 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_chooseDate_accessibilityLink3 = "\uC800\uD76C\uAC00 \uB4DC\uB9B4 \uC218 \uC788\uB294 \uB3C4\uC6C0\uC744 \uC77D\uC5B4\uBCF4\uC2ED\uC2DC\uC624";
var APPB2C_registration_results_osr_chooseDate_chooseDateTitle3 = "{{skillName}} \uC7AC\uC2DC\uD5D8 \uB0A0\uC9DC\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624";
var APPB2C_registration_results_osr_chooseDate_note_content3 = "\uAE30\uC874 \uC2DC\uD5D8 \uB0A0\uC9DC({{date}}) \uB85C\uBD80\uD130 60\uC77C \uC774\uB0B4\uC5D0\uB9CC \uC7AC\uC2DC\uD5D8\uC744 \uBCF4\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_chooseDate_note_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_registration_results_osr_chooseDate_retakeListening3 = "\uB4E3\uAE30 \uC7AC\uC2DC\uD5D8";
var APPB2C_registration_results_osr_chooseDate_retakeReading3 = "\uC77D\uAE30 \uC7AC\uC2DC\uD5D8";
var APPB2C_registration_results_osr_chooseDate_retakeSpeaking3 = "\uB9D0\uD558\uAE30 \uC7AC\uC2DC\uD5D8";
var APPB2C_registration_results_osr_chooseDate_retakeWriting3 = "\uC4F0\uAE30 \uC7AC\uC2DC\uD5D8";
var APPB2C_registration_results_osr_chooseSkill_backBtn3 = "\uC131\uC801 \uD654\uBA74\uC73C\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_registration_results_osr_chooseSkill_chooseDateBtn3 = "One Skill \uC7AC\uC751\uC2DC \uB0A0\uC9DC \uC120\uD0DD";
var APPB2C_registration_results_osr_chooseSkill_confirmationRequired3 = "\uC6D0\uD558\uC2DC\uB294 \uAE30\uAD00(\uB4E4)\uC774 IELTS \uC7AC\uC2DC\uD5D8\uC744 \uC218\uB77D\uD558\uB294\uC9C0 \uD655\uC778\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_chooseSkill_formTitle3 = "\uC7AC\uC751\uC2DC\uD560 \uC601\uC5ED\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_osr_chooseSkill_modal_contact3 = "\uC544\uB798 \uC138\uBD80 \uC815\uBCF4\uB97C \uD1B5\uD574 \uB2F9\uC0AC\uC5D0 \uC9C1\uC811 \uC5F0\uB77D\uD558\uC5EC \uC5B4\uB5A4 \uB3C4\uC6C0\uC744 \uBC1B\uC744 \uC218 \uC788\uB294\uC9C0 \uC0B4\uD3B4\uBCF4\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_osr_chooseSkill_modal_description3 = "\uC120\uD0DD\uD558\uC2E0 One Skill Retake\uB97C \uC608\uC57D\uD560 \uC218 \uC788\uB294 \uB0A0\uC9DC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_chooseSkill_modal_email3 = "\uC774\uBA54\uC77C";
var APPB2C_registration_results_osr_chooseSkill_modal_phone3 = "\uC804\uD654";
var APPB2C_registration_results_osr_chooseSkill_modal_title3 = "\uC8C4\uC1A1\uD569\uB2C8\uB2E4. \uC608\uC57D \uAC00\uB2A5\uD55C \uB0A0\uC9DC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation3 = "\uBCF8\uC778\uC740 \uC120\uD0DD\uB41C \uAE30\uAD00\uC774 IELTS One Skill \uC7AC\uC2DC\uD5D8\uC744 \uC218\uB77D\uD568\uC744 \uD655\uC778\uD569\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description3 = "\uC2DC\uD5D8\uC744 \uC608\uC57D\uD558\uAE30 \uC804\uC5D0 \uC120\uD0DD\uD55C \uAE30\uAD00\uC5D0\uC11C \uD574\uB2F9 IELTS \uC2DC\uD5D8 \uBC84\uC804\uC744 \uC218\uB77D\uD558\uB294\uC9C0 <0>\uD655\uC778\uD574\uC57C \uD569\uB2C8\uB2E4.</0>";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title3 = "\uC9C0\uC6D0\uD558\uB824\uB294 \uAE30\uAD00\uC774 IELTS One Skill Retake\uB97C \uC218\uB77D\uD569\uB2C8\uAE4C?";
var APPB2C_registration_results_osr_chooseSkill_skillRequired3 = "\uC7AC\uC751\uC2DC\uD558\uACE0\uC790 \uD558\uB294 \uC2A4\uD0AC(skill) \uC744 \uC120\uD0DD\uD558\uC138\uC694.";
var APPB2C_registration_results_osr_chooseTest_availableDates3 = "\uC751\uC2DC \uAC00\uB2A5 \uB0A0\uC9DC";
var APPB2C_registration_results_osr_chooseTest_speaking_time3 = "\uC2DC\uAC04 \uBCC0\uACBD";
var APPB2C_registration_results_osr_error_missingFile3 = "\uC6D0\uB798 \uB4F1\uB85D\uC5D0 \uC5C5\uB85C\uB4DC\uB41C \uD30C\uC77C\uC5D0 \uBB38\uC81C\uAC00 \uC788\uC2B5\uB2C8\uB2E4. \uCD94\uAC00 \uC9C0\uC6D0\uC774 \uD544\uC694\uD55C \uACBD\uC6B0 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_osr_error_noTestDates3 = "\uC774 \uC704\uCE58\uC5D0\uB294 \uB354 \uC774\uC0C1 \uC0AC\uC6A9\uD560 \uC218 \uC788\uB294 \uC2DC\uD5D8 \uB0A0\uC9DC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4. \uB2E4\uB978 \uAC83\uC744 \uC120\uD0DD\uD558\uAC70\uB098 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_osr_error_noTests3 = "\uC120\uD0DD\uD55C \uAE30\uAC04\uACFC \uC7A5\uC18C\uC5D0\uC11C \uB354 \uC774\uC0C1 \uC0AC\uC6A9\uD560 \uC218 \uC788\uB294 \uC2DC\uD5D8 \uB0A0\uC9DC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4. <0>\uB3CC\uC544\uAC00\uAE30</0> \uAC80\uC0C9 \uB9E4\uAC1C \uBCC0\uC218\uB97C \uBCC0\uACBD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_osr_line13 = "IELTS \uC131\uC801\uC774 \uB9CC\uC871\uC2A4\uB7FD\uC9C0 \uC54A\uB2E4\uBA74 \uACFC\uBAA9 \uD558\uB098\uB97C \uD0DD\uD558\uC5EC \uC7AC\uC2DC\uD5D8\uC744 \uBCFC \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_line23 = "\uD55C \uACFC\uBAA9 \uC7AC\uC2DC\uD5D8\uC744 \uD65C\uC6A9\uD558\uBA74 \uADF8\uAC04\uC758 \uBAA8\uB4E0 \uB178\uB825\uC744 \uC6D0\uC810\uC5D0\uC11C \uB2E4\uC2DC \uC2DC\uC791\uD558\uC9C0 \uC54A\uC544\uB3C4 \uB429\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_line33 = "\uC704\uC758 \uACFC\uBAA9 \uC911 \uC5B4\uB5A4 \uACFC\uBAA9\uC744, \uC5B8\uC81C \uC7AC\uC2DC\uD5D8 \uBCF4\uAE30 \uC6D0\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_registration_results_osr_line43 = "\uC8FC\uD55C\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD\uC5D0 48~72\uC2DC\uAC04 \uC774\uB0B4\uC5D0 \uC774\uBA54\uC77C\uB85C \uB2F5\uBCC0\uB4DC\uB9AC\uB3C4\uB85D \uCD5C\uC120\uC744 \uB2E4\uD558\uACA0\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_line53 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC740 \uADC0\uD558\uC758 \uC694\uCCAD \uCC98\uB9AC\uC640 \uAD00\uB828\uD558\uC5EC \uADC0\uD558\uAC00 \uC81C\uACF5\uD558\uB294 \uC815\uBCF4\uB97C \uC0AC\uC6A9\uD560 \uAC83\uC785\uB2C8\uB2E4. \uC790\uC138\uD55C \uB0B4\uC6A9\uC740 \uB2F9\uC0AC \uC6F9\uC0AC\uC774\uD2B8\uC758 <0>\uAC1C\uC778 \uC815\uBCF4 \uBCF4\uD638 \uC139\uC158</0\uC744 \uCC38\uC870\uD558\uC2ED\uC2DC\uC624>";
var APPB2C_registration_results_osr_review_note3 = "\uC544\uB798\uC640 \uAC19\uC740 \uAC1C\uC778 \uC815\uBCF4\uAC00 \uAE30\uC874 IELTS \uC608\uC57D\uC5D0\uC11C \uC870\uD68C\uB418\uC5C8\uC2B5\uB2C8\uB2E4. One Skill Retake\uB97C \uC704\uD574 \uD574\uB2F9 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD560 \uC218\uB294 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_review_note_testDay3 = "\uC2DC\uD5D8 \uB2F9\uC77C\uC5D0\uB294 \uB4F1\uB85D\uD560 \uB54C \uC0AC\uC6A9\uD55C \uAC83\uACFC \uB3D9\uC77C\uD55C \uC2E0\uBD84\uC99D\uC744 \uC9C0\uCC38\uD574\uC57C \uD569\uB2C8\uB2E4. \uC6D0\uBCF8 \uC2DC\uD5D8 ID\uB97C \uC18C\uC9C0\uD558\uC9C0 \uC54A\uC740 \uACBD\uC6B0 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_results_osr_submitBtn3 = "\uC7AC\uC2DC\uD5D8 \uC2E0\uCCAD\uC11C \uC81C\uCD9C";
var APPB2C_registration_results_osr_successBanner3 = "\uADC0\uD558\uC758 IELTS One Skill Retake \uC694\uCCAD\uC774 \uC811\uC218\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_osr_title3 = "\uD55C \uACFC\uBAA9 \uC7AC\uC2DC\uD5D8";
var APPB2C_registration_results_ttAbsent_btn3 = "\uC774 \uC2DC\uD5D8\uC744 \uB2E4\uB978 \uB0A0\uC9DC\uB85C \uC608\uC57D";
var APPB2C_registration_results_ttAbsent_msg3 = "\uC751\uC2DC\uC790\uAC00 \uC2DC\uD5D8\uC5D0 \uCC38\uC11D\uD558\uC9C0 \uC54A\uC558\uAE30\uC5D0 \uC774 \uC2DC\uD5D8\uC5D0 \uB300\uD55C \uACB0\uACFC\uB97C \uC81C\uACF5\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_results_updateBar_osr_booked3 = "{{osrSkillName}} \uC2DC\uD5D8\uC5D0 \uC7AC\uC751\uC2DC\uD558\uAE30 \uC704\uD574 \uC811\uC218\uD588\uC2B5\uB2C8\uB2E4. <1>\uC811\uC218 \uC870\uD68C</1>";
var APPB2C_registration_results_updateBar_osr_marked3 = "{{osrSkillName}} \uC2DC\uD5D8\uC5D0 \uC7AC\uC751\uC2DC\uD558\uAE30 \uC704\uD574 \uC811\uC218\uD588\uC2B5\uB2C8\uB2E4. <1>\uACB0\uACFC \uC870\uD68C</1>";
var APPB2C_registration_tabs_bookingDetails3 = "\uC608\uC57D \uB0B4\uC5ED";
var APPB2C_registration_tabs_candidate3 = "\uC751\uC2DC\uC790 (Test Taker)";
var APPB2C_registration_tabs_date3 = "\uB0A0\uC9DC";
var APPB2C_registration_tabs_dateLocation3 = "\uB0A0\uC9DC \uBC0F \uC7A5\uC18C";
var APPB2C_registration_tabs_payments3 = "\uACB0\uC81C";
var APPB2C_registration_tabs_recognisingOrgs3 = "\uC778\uC815\uAE30\uAD00";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_13 = "\uC131\uC801\uC744 \uC778\uC815\uAE30\uAD00\uC73C\uB85C \uBCF4\uB0B4\uB294 \uBC29\uBC95\uC740 \uC2DC\uD5D8 \uC13C\uD130\uC5D0 \uBB38\uC758\uD558\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_23 = "\uC2E0\uC18D\uD788 \uB3C4\uC6C0\uC744 \uB4DC\uB9B4 \uC218 \uC788\uB3C4\uB85D registration reference number\uB97C \uC785\uB825\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_tabs_results3 = "\uC131\uC801";
var APPB2C_registration_testTaker_bookedFor_completed3 = "\uC774 \uC2DC\uD5D8\uC740 \uB2E4\uC74C\uC758 \uC0AC\uB78C\uC774 \uC751\uC2DC\uD569\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_bookedFor_dob3 = "\uC0DD\uB144\uC6D4\uC77C";
var APPB2C_registration_testTaker_bookedFor_email3 = "\uC774\uBA54\uC77C \uC8FC\uC18C";
var APPB2C_registration_testTaker_bookedFor_gender3 = "\uC131\uBCC4";
var APPB2C_registration_testTaker_bookedFor_name3 = "\uC774\uB984";
var APPB2C_registration_testTaker_bookedFor_parent3 = "\uBD80\uBAA8/\uBCF4\uD638\uC790:";
var APPB2C_registration_testTaker_bookedFor_title3 = "\uC774 \uC2DC\uD5D8\uC740 \uB2E4\uC74C\uC758 \uC0AC\uB78C\uC744 \uC704\uD574 \uC608\uC57D\uB418\uC5C8\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_childConsent_back3 = "\uC790\uB140 \uB3D9\uC758 \uC815\uBCF4\uB97C \uC218\uC815\uD558\uC9C0 \uC54A\uACA0\uC2B5\uB2C8\uB2E4 ";
var APPB2C_registration_testTaker_childConsent_context3 = "\uACFC\uBAA9(\uBAA8\uB4C8) \uC0AC\uC774\uC758 \uD734\uC2DD \uC2DC\uAC04\uACFC \uC2DC\uD5D8 \uC885\uB8CC \uD6C4 \uADC0\uD558\uC758 \uC790\uB140\uAC00 \uD63C\uC790 \uACE0\uC0AC\uC7A5\uC744 \uB098\uAC00\uB3C4 \uB418\uACA0\uC2B5\uB2C8\uAE4C?";
var APPB2C_registration_testTaker_childConsent_cta3 = "\uB3D9\uC758 \uC815\uBCF4 \uC800\uC7A5";
var APPB2C_registration_testTaker_childConsent_options_13 = "\uC608, \uC790\uB140\uAC00 \uD63C\uC790 \uB098\uAC00\uB3C4 \uB429\uB2C8\uB2E4";
var APPB2C_registration_testTaker_childConsent_options_23 = "\uC544\uB2C8\uC624, \uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uAC00\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_testTaker_childConsent_options_33 = "\uC544\uB2C8\uC624, \uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uAC08 \uB3D9\uD589\uC778\uC744 \uC9C0\uC815\uD558\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_testTaker_childConsent_somebodyElse_add3 = "+ \uB2E4\uB978 \uC0AC\uB78C \uCD94\uAC00";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox3 = "\uC608, \uB3D9\uC758\uD569\uB2C8\uB2E4";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_13 = "\uC0C1\uAE30 \uB3D9\uD589\uC778(\uB4E4)\uC774 15\uC138 \uC774\uC0C1\uC774\uBA70, \uC2DC\uD5D8 \uC885\uB8CC \uD6C4 \uC790\uB140\uB97C \uB370\uB824\uAC00\uB294 \uAC83\uC5D0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_23 = "\uB3D9\uC758\uD558\uC2ED\uB2C8\uAE4C?";
var APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName3 = "\uC131\uC774 \uC5C6\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_testTaker_childConsent_somebodyElse_note3 = "\uC790\uB140\uC640 \uB370\uB9AC\uB7EC \uC62C \uB3D9\uD589\uC778\uC5D0 \uB300\uD55C \uC815\uBCF4\uB97C \uC81C\uACF5\uD574 \uC8FC\uC2ED\uC2DC\uC624. \uD544\uAE30 \uC2DC\uD5D8\uC77C\uACFC \uB9D0\uD558\uAE30 \uC2DC\uD5D8\uC77C\uC5D0 \uC790\uB140\uB97C \uB370\uB9AC\uB7EC \uAC08 \uB3D9\uD589\uC778\uC774 \uB2E4\uB978 \uACBD\uC6B0 \uC774\uB4E4\uC758 \uC774\uB984\uC744 \uC544\uB798\uC5D0 \uCD94\uAC00\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phone3 = "\uC804\uD654\uBC88\uD638";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote3 = "\uBE44\uC0C1 \uC0C1\uD669 \uBC1C\uC0DD \uC2DC";
var APPB2C_registration_testTaker_childConsent_somebodyElse_policy3 = "\uC601\uAD6D\uBB38\uD654\uC6D0\uC758 \uC790\uB140 \uC548\uC804 \uADC0\uAC00 \uBCF4\uD638 \uC815\uCC45(child safe collection protection policy) \uC804\uCCB4 \uC77D\uAE30";
var APPB2C_registration_testTaker_childConsent_somebodyElse_relationship3 = "\uC790\uB140\uC640\uC758 \uAD00\uACC4";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_13 = "\uB3D9\uD589\uC778(\uB4E4) \uC815\uBCF4";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_23 = "\uADF8 \uC678 \uB3D9\uD589\uC778 \uC815\uBCF4";
var APPB2C_registration_testTaker_childConsent_title3 = "\uC790\uB140 \uB3D9\uC758";
var APPB2C_registration_testTaker_childConsent_warning3 = "11\uC138 \uC774\uC0C1\uC758 \uC544\uB3D9\uC740 \uBD80\uBAA8/\uBCF4\uD638\uC790\uC758 \uB3D9\uC758\uAC00 \uC788\uC5B4\uC57C\uB9CC \uD63C\uC790 \uACE0\uC0AC\uC7A5\uC744 \uB098\uAC08 \uC218 \uC788\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_testTaker_consent_edit3 = "\uB3D9\uC758 \uC815\uBCF4 \uC218\uC815";
var APPB2C_registration_testTaker_consent_missing3 = "\uC790\uB140 \uB3D9\uC758(child consent) \uC815\uBCF4\uB97C \uC81C\uACF5\uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_consent_title3 = "\uB3D9\uC758:";
var APPB2C_registration_testTaker_contact_address3 = "\uC8FC\uC18C";
var APPB2C_registration_testTaker_contact_edit3 = "\uC5F0\uB77D\uCC98 \uC218\uC815";
var APPB2C_registration_testTaker_contact_mobile3 = "\uD734\uB300\uD3F0 \uBC88\uD638";
var APPB2C_registration_testTaker_contact_parent3 = "\uBD80\uBAA8/\uBCF4\uD638\uC790 \uC5F0\uB77D\uCC98:";
var APPB2C_registration_testTaker_contact_title3 = "\uC5F0\uB77D\uCC98:";
var APPB2C_registration_testTaker_editContact_address3 = "\uC8FC\uC18C";
var APPB2C_registration_testTaker_editContact_back3 = "\uC5F0\uB77D\uCC98 \uC815\uBCF4\uB97C \uBCC0\uACBD\uD558\uC9C0 \uC54A\uACA0\uC2B5\uB2C8\uB2E4";
var APPB2C_registration_testTaker_editContact_city3 = "\uC2DC/\uAD70/\uAD6C";
var APPB2C_registration_testTaker_editContact_country_change3 = "\uAD6D\uAC00 \uBCC0\uACBD";
var APPB2C_registration_testTaker_editContact_country_residence3 = "\uAD6D\uAC00";
var APPB2C_registration_testTaker_editContact_cta3 = "\uC815\uBCF4 \uC5C5\uB370\uC774\uD2B8";
var APPB2C_registration_testTaker_editContact_error_missingAddress3 = "\uC8FC\uC18C\uB97C \uC785\uB825\uD574 \uC8FC\uC138\uC694.";
var APPB2C_registration_testTaker_editContact_error_missingCountry3 = "\uAD6D\uAC00\uB97C \uC785\uB825\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_editContact_error_pastCutoff3 = "\uC5F0\uB77D\uCC98 \uC138\uBD80 \uC815\uBCF4\uB294 \uB354 \uC774\uC0C1 \uD3B8\uC9D1\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_editContact_mobile3 = "\uD734\uB300\uD3F0 \uBC88\uD638";
var APPB2C_registration_testTaker_editContact_note3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_registration_testTaker_editContact_postCode3 = "\uC6B0\uD3B8\uBC88\uD638";
var APPB2C_registration_testTaker_editContact_state3 = "\uB3C4";
var APPB2C_registration_testTaker_editContact_warn3 = "\uC5F0\uB77D\uCC98 \uC815\uBCF4\uB97C \uC218\uC815\uD558\uC2DC\uBA74 \uC2DC\uD5D8 \uB4F1\uB85D \uC815\uBCF4\uC5D0 \uBC18\uC601\uB429\uB2C8\uB2E4. (\uC5ED\uC790\uC8FC: \uC6D0\uBB38\uC758 \uC758\uBBF8\uAC00 \uB2E4\uC18C \uBAA8\uD638\uD558\uC5EC \uBB38\uB9E5\uC744 \uACE0\uB824\uD574 \uC720\uCD94\uD558\uC5EC \uBC88\uC5ED\uD558\uC600\uC73C\uB2C8 \uD655\uC778 \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4) ";
var APPB2C_registration_testTaker_error3 = "\uC774 \uC608\uC57D\uC5D0\uC11C\uB294 \uB354 \uC774\uC0C1 \uC138\uBD80 \uC815\uBCF4\uB97C \uC218\uC815\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_files_editButton_add3 = "\uD30C\uC77C \uC5C5\uB85C\uB4DC";
var APPB2C_registration_testTaker_files_editButton_edit3 = "\uC5C5\uB85C\uB4DC \uB41C \uD30C\uC77C \uC218\uC815";
var APPB2C_registration_testTaker_files_upload_back_subtitle3 = "(\uC774 \uC791\uC5C5\uC740 \uD30C\uC77C\uC744 \uC5C5\uB85C\uB4DC \uD558\uAC70\uB098 \uC0AD\uC81C\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4)";
var APPB2C_registration_testTaker_files_upload_back_title3 = "\uC751\uC2DC\uC790(Test Taker)\uB85C \uB3CC\uC544\uAC00\uAE30";
var APPB2C_registration_testTaker_files_upload_cta3 = "\uD30C\uC77C \uC800\uC7A5";
var APPB2C_registration_testTaker_files_upload_error_fileTooLarge3 = "\uC5C5\uB85C\uB4DC\uD558\uB824\uB294 \uD30C\uC77C\uC774 \uB108\uBB34 \uD07D\uB2C8\uB2E4. \uC81C\uAC70\uD558\uACE0 \uB2E4\uB978 \uD30C\uC77C\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded3 = "\uC5C5\uB85C\uB4DC\uD560 \uC218 \uC788\uB294 \uD30C\uC77C \uC218\uB97C \uCD08\uACFC\uD588\uC2B5\uB2C8\uB2E4. \uD55C\uB3C4\uB97C \uCD08\uACFC\uD55C \uD30C\uC77C\uC744 \uC81C\uAC70\uD558\uACE0 \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_files_upload_error_uploadUnavailable3 = "\uC774 \uC608\uC57D\uC5D0\uB294 \uD30C\uC77C \uC5C5\uB85C\uB4DC\uAC00 \uBD88\uAC00\uB2A5\uD569\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_files_upload_error_wrongFileName3 = "\uC5C5\uB85C\uB4DC\uD558\uB824\uB294 \uD30C\uC77C\uC5D0 \uC9C0\uC6D0\uB418\uC9C0 \uC54A\uB294 \uBB38\uC790\uAC00 \uD3EC\uD568\uB41C \uC774\uB984\uC774 \uC788\uC2B5\uB2C8\uB2E4. \uC81C\uAC70\uD558\uACE0 \uB85C\uCEEC\uC5D0\uC11C \uC774\uB984\uC744 \uBC14\uAFBC \uB2E4\uC74C \uB2E4\uC2DC \uC5C5\uB85C\uB4DC\uD574 \uBCF4\uC138\uC694.";
var APPB2C_registration_testTaker_files_upload_error_wrongFileType3 = "\uC9C0\uC6D0\uB418\uC9C0 \uC54A\uB294 \uD615\uC2DD\uC758 \uD30C\uC77C\uC744 \uC5C5\uB85C\uB4DC\uD558\uB824\uACE0 \uD569\uB2C8\uB2E4. \uC81C\uAC70\uD558\uACE0 \uB2E4\uB978 \uD30C\uC77C\uC744 \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_files_upload_mobile_line13 = "\uD604\uC7AC \uBAA8\uBC14\uC77C \uAE30\uAE30\uB85C \uC811\uC18D\uD558\uC168\uC73C\uBBC0\uB85C \uD574\uB2F9 \uAE30\uAE30\uB97C \uD1B5\uD574 \uC0AC\uC9C4\uC744 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_files_upload_mobile_line23 = "\uBAA8\uBC14\uC77C \uAE30\uAE30\uC5D0\uC11C \uC774 \uC791\uC5C5\uC744 \uC218\uD589\uD560 \uC218 \uC5C6\uB294 \uACBD\uC6B0 \uCEF4\uD4E8\uD130\uB85C \uB2E4\uC2DC \uB85C\uADF8\uC778 \uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_files_upload_mobile_title3 = "\uCC38\uACE0\uD558\uC138\uC694";
var APPB2C_registration_testTaker_files_upload_note3 = "\uD30C\uC77C\uC740 {{count}}\uAC1C\uAE4C\uC9C0 \uC5C5\uB85C\uB4DC \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4(\uCD5C\uC18C 1\uAC1C \uC774\uC0C1 \uC5C5\uB85C\uB4DC \uD574\uC57C \uD568)";
var APPB2C_registration_testTaker_id_child3 = "\uC790\uB140\uC758 \uC2E0\uC6D0 \uC815\uBCF4:";
var APPB2C_registration_testTaker_id_edit_rules_example3 = "$t({{idName}})\uC758 \uC99D\uBE59 \uC608\uC2DC\uB97C \uCC38\uC870\uD558\uC2ED\uC2DC\uC624";
var APPB2C_registration_testTaker_id_edit_subtitle3 = "\uC544\uB798 $t({{idName}})\uC758 \uC99D\uBE59\uC744 \uC5C5\uB85C\uB4DC \uD574 \uC8FC\uC2ED\uC2DC\uC624.";
var APPB2C_registration_testTaker_id_edit_title3 = "$t({{idName}}) \uC99D\uBE59 \uC5C5\uB85C\uB4DC";
var APPB2C_registration_testTaker_id_edit_upload_exampleProof3 = "\uC99D\uBE59 \uC608\uC2DC";
var APPB2C_registration_testTaker_id_edit_upload_imgAlt3 = "\uC2E0\uBD84 \uC99D\uBA85";
var APPB2C_registration_testTaker_id_expiryDate3 = "$t({{idName}}, capitalise) \uC720\uD6A8\uAE30\uAC04";
var APPB2C_registration_testTaker_id_fileAlt3 = "\uC5C5\uB85C\uB4DC\uB41C \uC2E0\uBD84 \uC99D\uBE59";
var APPB2C_registration_testTaker_id_idNumber3 = "$t({{idName}}, capitalise) \uC2E0\uBD84\uC99D \uBC88\uD638";
var APPB2C_registration_testTaker_id_issuingAuthority3 = "\uBC1C\uAE09\uAE30\uAD00";
var APPB2C_registration_testTaker_id_noFilesBody3 = "\uC5C5\uB85C\uB4DC \uB41C \uC2E0\uBD84 \uC99D\uBE59\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_id_noFilesTitle3 = "\uC99D\uBE59 \uC5C6\uC74C";
var APPB2C_registration_testTaker_id_title3 = "\uC2E0\uC6D0 \uC815\uBCF4:";
var APPB2C_registration_testTaker_id_typeNames_card3 = "\uC2E0\uBD84\uC99D";
var APPB2C_registration_testTaker_id_typeNames_other3 = "\uC2E0\uBD84\uC99D";
var APPB2C_registration_testTaker_id_typeNames_passport3 = "\uC5EC\uAD8C";
var APPB2C_registration_testTaker_specialArrangements_description3 = "\uADC0\uD558\uB294 \uB2E4\uC74C\uC758 \uD2B9\uC218 \uC870\uCE58\uB97C \uC694\uCCAD\uD558\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_specialArrangements_edit_line13 = "\uD544\uC694\uD55C \uD2B9\uC218 \uC870\uCE58";
var APPB2C_registration_testTaker_specialArrangements_edit_line23 = "\uBAA8\uB4E0 \uD2B9\uC218 \uC870\uCE58 \uC2E0\uCCAD\uC740 \uC9C4\uB2E8\uC11C\uB85C \uC99D\uBE59\uB418\uC5B4\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule13 = "IELTS \uAD00\uB9AC\uC790\uAC00 \uC774\uD574\uD560 \uC218 \uC788\uB294 \uC5B8\uC5B4\uB85C \uC791\uC131\uB418\uACE0 \uC77D\uAE30 \uC26C\uC6CC\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule23 = "\uC758\uB8CC\uAE30\uAD00 \uACF5\uC2DD \uC591\uC2DD\uC5D0 \uC791\uC131\uB418\uACE0, \uC6D0\uBCF8\uC774\uC5B4\uC57C \uD558\uBA70, \uACF5\uC778\uB41C \uC758\uB8CC\uC778\uC758 \uC774\uB984, \uC790\uACA9 \uB0B4\uC6A9, \uC11C\uBA85\uC774 \uC788\uC5B4\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule33 = "\uADC0\uD558\uC5D0\uAC8C \uD544\uC694\uD55C \uD2B9\uC218 \uC870\uCE58\uAC00 \uBA85\uD655\uD788 \uC801\uC2DC\uB418\uC5B4\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule43 = "\uADC0\uD558\uC5D0\uAC8C \uD544\uC694\uD55C \uD2B9\uC218 \uC870\uCE58\uC758 \uADFC\uAC70\uAC00 \uC801\uC2DC\uB418\uC5B4\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule53 = "\uC2DC\uD5D8\uC77C \uC804 2\uB144 \uC774\uB0B4\uC5D0 \uBC1C\uAE09\uB41C \uAC83\uC774\uC5B4\uC57C \uD569\uB2C8\uB2E4";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_title3 = "\uC9C4\uB2E8\uC11C\uB294 \uB2E4\uC74C \uC694\uAC74\uC744 \uCDA9\uC871\uD574\uC57C \uD569\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_specialArrangements_edit_subtitle3 = "\uADC0\uD558\uB294 \uC2DC\uD5D8 \uC608\uC57D \uC2DC \uD2B9\uC218 \uC870\uCE58\uAC00 \uD544\uC694\uD558\uB2E4\uACE0 \uC54C\uB9AC\uC168\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_specialArrangements_edit_title3 = "\uC9C4\uB2E8\uC11C \uC5C5\uB85C\uB4DC";
var APPB2C_registration_testTaker_specialArrangements_fileAlt3 = "\uC5C5\uB85C\uB4DC \uB41C \uC9C4\uB2E8\uC11C";
var APPB2C_registration_testTaker_specialArrangements_noFilesBody3 = "\uC9C4\uB2E8\uC11C\uB97C \uC5C5\uB85C\uB4DC \uD558\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.";
var APPB2C_registration_testTaker_specialArrangements_noFilesTitle3 = "\uC99D\uBE59 \uC5C6\uC74C";
var APPB2C_registration_testTaker_specialArrangements_title3 = "\uD2B9\uC218 \uC870\uCE58";
var APPB2C_testCard_arrival3 = "({{time}}\uC2DC\uC5D0 \uB3C4\uCC29\uD574 \uC8FC\uC2ED\uC2DC\uC624)";
var APPB2C_testCard_book3 = "\uC608\uC57D";
var APPB2C_testCard_bookExam3 = "\uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_testCard_bookFor3 = "\uB2E4\uC74C \uC2DC\uD5D8 \uC608\uC57D\uD558\uAE30";
var APPB2C_testCard_bookForFree3 = "\uBB34\uB8CC\uB85C \uC2DC\uD5D8 \uC608\uC57D";
var APPB2C_testCard_bookingPrice3 = "\uAC12";
var APPB2C_testCard_cd3 = "\uCEF4\uD4E8\uD130 IELTS";
var APPB2C_testCard_changeSpeaking3 = "\uC5F0\uC124 \uC77C\uC815 \uBCC0\uACBD";
var APPB2C_testCard_f2f3 = "\uB300\uBA74";
var APPB2C_testCard_hideSpeakingTests3 = "\uC774 \uACE0\uC0AC\uC7A5\uC758 \uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uC228\uAE30\uAE30";
var APPB2C_testCard_lrw3 = "\uB4E3\uAE30, \uC77D\uAE30, \uC4F0\uAE30";
var APPB2C_testCard_lrwHeader2 = "\uD544\uAE30\uC2DC\uD5D8";
var APPB2C_testCard_ls3 = "\uB4E3\uAE30, \uB9D0\uD558\uAE30";
var APPB2C_testCard_mixedSlots3 = "\uD14C\uC2A4\uD2B8\uAC00 \uAE30\uB85D\uB429\uB2C8\uB2E4. \uC608\uC57D\uC5D0 \uBB35\uC2DC\uC801 \uB3D9\uC758. \uB3D9\uC758\uD558\uC9C0 \uC54A\uC73C\uBA74 \uAE30\uD0C0\uB97C \uC120\uD0DD\uD558\uC2ED\uC2DC\uC624.";
var APPB2C_testCard_notLinkedVenue3 = "\uB9D0\uD558\uAE30\uC640 \uD544\uAE30 \uC2DC\uD5D8 \uC7A5\uC18C\uB294 \uB3D9\uC77C\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uC5EC\uD589 \uACC4\uD68D\uC744 \uC138\uC6B0\uC138\uC694.";
var APPB2C_testCard_pb3 = "\uC9C0\uD544 IELTS";
var APPB2C_testCard_review3 = "\uADF8\uB9AC\uACE0 \uAC80\uD1A0\uD558\uAE30";
var APPB2C_testCard_showSpeakingTests3 = "\uC774 \uACE0\uC0AC\uC7A5\uC758 \uB9D0\uD558\uAE30 \uC2DC\uD5D8 \uBCF4\uC774\uAE30";
var APPB2C_testCard_speaking3 = "\uB9D0\uD558\uAE30 \uC2DC\uD5D8";
var APPB2C_testCard_speakingAddress3 = "\uB9D0\uD558\uAE30 \uACE0\uC0AC\uC7A5 \uC8FC\uC18C";
var APPB2C_testCard_timeUnknown3 = "\uC2DC\uD5D8 \uC2DC\uAC04\uC740 \uC2DC\uD5D8\uC77C 48\uC2DC\uAC04 \uC804\uC5D0 \uC54C\uB824\uB4DC\uB9BD\uB2C8\uB2E4.";
var APPB2C_testCard_vcs3 = "\uC2DC\uD5D8 \uC13C\uD130 \uD654\uC0C1 \uC2DC\uD5D8";
var b2c_default3 = {
  "APPB2C.auth.changePassword.cta": APPB2C_auth_changePassword_cta3,
  "APPB2C.auth.changePassword.error": APPB2C_auth_changePassword_error3,
  "APPB2C.auth.changePassword.error.differentPasswords": APPB2C_auth_changePassword_error_differentPasswords3,
  "APPB2C.auth.changePassword.error.wrongIdNumber": APPB2C_auth_changePassword_error_wrongIdNumber3,
  "APPB2C.auth.changePassword.error.wrongOldPassword": APPB2C_auth_changePassword_error_wrongOldPassword3,
  "APPB2C.auth.changePassword.error.wrongPassword": APPB2C_auth_changePassword_error_wrongPassword3,
  "APPB2C.auth.changePassword.id.label": APPB2C_auth_changePassword_id_label3,
  "APPB2C.auth.changePassword.id.note": APPB2C_auth_changePassword_id_note3,
  "APPB2C.auth.changePassword.id.warn": APPB2C_auth_changePassword_id_warn3,
  "APPB2C.auth.changePassword.pass.label": APPB2C_auth_changePassword_pass_label3,
  "APPB2C.auth.changePassword.pass.note": APPB2C_auth_changePassword_pass_note3,
  "APPB2C.auth.changePassword.title": APPB2C_auth_changePassword_title3,
  "APPB2C.auth.forgetPassword.cta": APPB2C_auth_forgetPassword_cta3,
  "APPB2C.auth.forgetPassword.email": APPB2C_auth_forgetPassword_email3,
  "APPB2C.auth.forgetPassword.success.msg1": APPB2C_auth_forgetPassword_success_msg13,
  "APPB2C.auth.forgetPassword.success.msg2": APPB2C_auth_forgetPassword_success_msg23,
  "APPB2C.auth.forgetPassword.success.title": APPB2C_auth_forgetPassword_success_title3,
  "APPB2C.auth.forgetPassword.text": APPB2C_auth_forgetPassword_text3,
  "APPB2C.auth.forgetPassword.title": APPB2C_auth_forgetPassword_title3,
  "APPB2C.auth.resetPassword.cta": APPB2C_auth_resetPassword_cta3,
  "APPB2C.auth.resetPassword.error.expiredToken": APPB2C_auth_resetPassword_error_expiredToken3,
  "APPB2C.auth.resetPassword.error.wrongPassword": APPB2C_auth_resetPassword_error_wrongPassword3,
  "APPB2C.auth.resetPassword.error.wrongToken": APPB2C_auth_resetPassword_error_wrongToken3,
  "APPB2C.auth.resetPassword.form.newPassword": APPB2C_auth_resetPassword_form_newPassword3,
  "APPB2C.auth.resetPassword.noToken.cta": APPB2C_auth_resetPassword_noToken_cta3,
  "APPB2C.auth.resetPassword.noToken.msg": APPB2C_auth_resetPassword_noToken_msg3,
  "APPB2C.auth.resetPassword.success.msg": APPB2C_auth_resetPassword_success_msg3,
  "APPB2C.auth.resetPassword.success.title": APPB2C_auth_resetPassword_success_title3,
  "APPB2C.auth.resetPassword.title": APPB2C_auth_resetPassword_title3,
  "APPB2C.cj.basic.cta": APPB2C_cj_basic_cta3,
  "APPB2C.cj.basic.goToReview": APPB2C_cj_basic_goToReview3,
  "APPB2C.cj.basic.scrollLink": APPB2C_cj_basic_scrollLink3,
  "APPB2C.cj.basic.selectDate": APPB2C_cj_basic_selectDate3,
  "APPB2C.cj.basic.selectTime": APPB2C_cj_basic_selectTime3,
  "APPB2C.cj.basic.validationError": APPB2C_cj_basic_validationError3,
  "APPB2C.cj.bookingComplete.feeDetails.discount": APPB2C_cj_bookingComplete_feeDetails_discount3,
  "APPB2C.cj.bookingComplete.feeDetails.fee": APPB2C_cj_bookingComplete_feeDetails_fee3,
  "APPB2C.cj.bookingComplete.feeDetails.newFee": APPB2C_cj_bookingComplete_feeDetails_newFee3,
  "APPB2C.cj.bookingComplete.feeDetails.promo": APPB2C_cj_bookingComplete_feeDetails_promo3,
  "APPB2C.cj.bookingComplete.feeDetails.reference": APPB2C_cj_bookingComplete_feeDetails_reference3,
  "APPB2C.cj.bookingComplete.feeDetails.tax": APPB2C_cj_bookingComplete_feeDetails_tax3,
  "APPB2C.cj.bookingComplete.feeDetails.title": APPB2C_cj_bookingComplete_feeDetails_title3,
  "APPB2C.cj.bookingComplete.feeDetails.total": APPB2C_cj_bookingComplete_feeDetails_total3,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine1": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine13,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine2": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine23,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine3": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine33,
  "APPB2C.cj.bookingComplete.loadingPaymentMethodsError": APPB2C_cj_bookingComplete_loadingPaymentMethodsError3,
  "APPB2C.cj.bookingComplete.loadingRegistrationError": APPB2C_cj_bookingComplete_loadingRegistrationError3,
  "APPB2C.cj.bookingComplete.note": APPB2C_cj_bookingComplete_note3,
  "APPB2C.cj.bookingComplete.noteUolPassportInfo": APPB2C_cj_bookingComplete_noteUolPassportInfo3,
  "APPB2C.cj.bookingComplete.offlinePayment.code": APPB2C_cj_bookingComplete_offlinePayment_code3,
  "APPB2C.cj.bookingComplete.offlinePayment.description": APPB2C_cj_bookingComplete_offlinePayment_description3,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line1": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line13,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line2": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line23,
  "APPB2C.cj.bookingComplete.offlinePayment.fee": APPB2C_cj_bookingComplete_offlinePayment_fee3,
  "APPB2C.cj.bookingComplete.offlinePayment.hideDescription": APPB2C_cj_bookingComplete_offlinePayment_hideDescription3,
  "APPB2C.cj.bookingComplete.offlinePayment.incTax": APPB2C_cj_bookingComplete_offlinePayment_incTax3,
  "APPB2C.cj.bookingComplete.offlinePayment.name": APPB2C_cj_bookingComplete_offlinePayment_name3,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1": APPB2C_cj_bookingComplete_offlinePayment_note_line13,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.hours": APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours3,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.timeLeft": APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft3,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line2": APPB2C_cj_bookingComplete_offlinePayment_note_line23,
  "APPB2C.cj.bookingComplete.offlinePayment.note.pleaseNote": APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote3,
  "APPB2C.cj.bookingComplete.offlinePayment.number": APPB2C_cj_bookingComplete_offlinePayment_number3,
  "APPB2C.cj.bookingComplete.offlinePayment.reference": APPB2C_cj_bookingComplete_offlinePayment_reference3,
  "APPB2C.cj.bookingComplete.offlinePayment.showDescription": APPB2C_cj_bookingComplete_offlinePayment_showDescription3,
  "APPB2C.cj.bookingComplete.otherPaymentOptions": APPB2C_cj_bookingComplete_otherPaymentOptions3,
  "APPB2C.cj.bookingComplete.paymentFailed": APPB2C_cj_bookingComplete_paymentFailed3,
  "APPB2C.cj.bookingComplete.paymentOptions": APPB2C_cj_bookingComplete_paymentOptions3,
  "APPB2C.cj.bookingComplete.prepareNote.accesNote": APPB2C_cj_bookingComplete_prepareNote_accesNote3,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.note": APPB2C_cj_bookingComplete_prepareNote_counselling_note3,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.view": APPB2C_cj_bookingComplete_prepareNote_counselling_view3,
  "APPB2C.cj.bookingComplete.prepareNote.note": APPB2C_cj_bookingComplete_prepareNote_note3,
  "APPB2C.cj.bookingComplete.prepareNote.title": APPB2C_cj_bookingComplete_prepareNote_title3,
  "APPB2C.cj.bookingComplete.prepareNote.view": APPB2C_cj_bookingComplete_prepareNote_view3,
  "APPB2C.cj.bookingComplete.title": APPB2C_cj_bookingComplete_title3,
  "APPB2C.cj.bookingComplete.tnc": APPB2C_cj_bookingComplete_tnc3,
  "APPB2C.cj.bookingComplete.tncLink": APPB2C_cj_bookingComplete_tncLink3,
  "APPB2C.cj.bookingComplete.tryAgain": APPB2C_cj_bookingComplete_tryAgain3,
  "APPB2C.cj.bookingComplete.yourBooking": APPB2C_cj_bookingComplete_yourBooking3,
  "APPB2C.cj.childDetails.about": APPB2C_cj_childDetails_about3,
  "APPB2C.cj.childDetails.aboutNote": APPB2C_cj_childDetails_aboutNote3,
  "APPB2C.cj.childDetails.error.missingFirstName": APPB2C_cj_childDetails_error_missingFirstName3,
  "APPB2C.cj.childDetails.error.missingSurname": APPB2C_cj_childDetails_error_missingSurname3,
  "APPB2C.cj.childDetails.error.wrongDob": APPB2C_cj_childDetails_error_wrongDob3,
  "APPB2C.cj.childDetails.forms.personal.idMatch": APPB2C_cj_childDetails_forms_personal_idMatch3,
  "APPB2C.cj.childDetails.noLastName": APPB2C_cj_childDetails_noLastName3,
  "APPB2C.cj.childDetails.note": APPB2C_cj_childDetails_note3,
  "APPB2C.cj.childDetails.onTestDay": APPB2C_cj_childDetails_onTestDay3,
  "APPB2C.cj.childDetails.title": APPB2C_cj_childDetails_title3,
  "APPB2C.cj.finishPayment.error": APPB2C_cj_finishPayment_error3,
  "APPB2C.cj.finishPayment.pleaseWait": APPB2C_cj_finishPayment_pleaseWait3,
  "APPB2C.cj.finishPayment.processing": APPB2C_cj_finishPayment_processing3,
  "APPB2C.cj.idDetails.confirm.change": APPB2C_cj_idDetails_confirm_change3,
  "APPB2C.cj.idDetails.confirm.confirm": APPB2C_cj_idDetails_confirm_confirm3,
  "APPB2C.cj.idDetails.confirm.note1": APPB2C_cj_idDetails_confirm_note13,
  "APPB2C.cj.idDetails.confirm.note2": APPB2C_cj_idDetails_confirm_note23,
  "APPB2C.cj.idDetails.confirm.title": APPB2C_cj_idDetails_confirm_title3,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.content": APPB2C_cj_idDetails_confirm_wrongDetailsNote_content3,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.title": APPB2C_cj_idDetails_confirm_wrongDetailsNote_title3,
  "APPB2C.cj.idDetails.error.missingIdExpiry": APPB2C_cj_idDetails_error_missingIdExpiry3,
  "APPB2C.cj.idDetails.error.missingIdNumber": APPB2C_cj_idDetails_error_missingIdNumber3,
  "APPB2C.cj.idDetails.error.missingIdType": APPB2C_cj_idDetails_error_missingIdType3,
  "APPB2C.cj.idDetails.error.missingIssuingAuthority": APPB2C_cj_idDetails_error_missingIssuingAuthority3,
  "APPB2C.cj.idDetails.error.wrongFilename": APPB2C_cj_idDetails_error_wrongFilename3,
  "APPB2C.cj.idDetails.error.wrongIdExpiry": APPB2C_cj_idDetails_error_wrongIdExpiry3,
  "APPB2C.cj.idDetails.forms.changeNote": APPB2C_cj_idDetails_forms_changeNote3,
  "APPB2C.cj.idDetails.forms.changeNote.child": APPB2C_cj_idDetails_forms_changeNote_child3,
  "APPB2C.cj.idDetails.forms.documentWithNoExpiryDate": APPB2C_cj_idDetails_forms_documentWithNoExpiryDate3,
  "APPB2C.cj.idDetails.forms.expiryDate": APPB2C_cj_idDetails_forms_expiryDate3,
  "APPB2C.cj.idDetails.forms.idNumber": APPB2C_cj_idDetails_forms_idNumber3,
  "APPB2C.cj.idDetails.forms.idType": APPB2C_cj_idDetails_forms_idType3,
  "APPB2C.cj.idDetails.forms.issuingAuthority": APPB2C_cj_idDetails_forms_issuingAuthority3,
  "APPB2C.cj.idDetails.idExpired": APPB2C_cj_idDetails_idExpired3,
  "APPB2C.cj.idDetails.iolMessage": APPB2C_cj_idDetails_iolMessage3,
  "APPB2C.cj.idDetails.noteAboutIdentification": APPB2C_cj_idDetails_noteAboutIdentification3,
  "APPB2C.cj.idDetails.noteAboutIdentification.child": APPB2C_cj_idDetails_noteAboutIdentification_child3,
  "APPB2C.cj.idDetails.noteAboutIdentificationIol": APPB2C_cj_idDetails_noteAboutIdentificationIol3,
  "APPB2C.cj.idDetails.noteAboutIdentificationIolLink": APPB2C_cj_idDetails_noteAboutIdentificationIolLink3,
  "APPB2C.cj.idDetails.noteAboutIdentificationUol": APPB2C_cj_idDetails_noteAboutIdentificationUol3,
  "APPB2C.cj.idDetails.title": APPB2C_cj_idDetails_title3,
  "APPB2C.cj.idDetails.title.child": APPB2C_cj_idDetails_title_child3,
  "APPB2C.cj.idDetails.typeNames.card": APPB2C_cj_idDetails_typeNames_card3,
  "APPB2C.cj.idDetails.typeNames.other": APPB2C_cj_idDetails_typeNames_other3,
  "APPB2C.cj.idDetails.typeNames.passport": APPB2C_cj_idDetails_typeNames_passport3,
  "APPB2C.cj.idDetails.upload.fetchError": APPB2C_cj_idDetails_upload_fetchError3,
  "APPB2C.cj.idDetails.upload.file1": APPB2C_cj_idDetails_upload_file13,
  "APPB2C.cj.idDetails.upload.file2": APPB2C_cj_idDetails_upload_file23,
  "APPB2C.cj.idDetails.upload.instructions.capture": APPB2C_cj_idDetails_upload_instructions_capture3,
  "APPB2C.cj.idDetails.upload.instructions.covers": APPB2C_cj_idDetails_upload_instructions_covers3,
  "APPB2C.cj.idDetails.upload.instructions.directLight": APPB2C_cj_idDetails_upload_instructions_directLight3,
  "APPB2C.cj.idDetails.upload.instructions.noBlur": APPB2C_cj_idDetails_upload_instructions_noBlur3,
  "APPB2C.cj.idDetails.upload.instructions.title": APPB2C_cj_idDetails_upload_instructions_title3,
  "APPB2C.cj.idDetails.upload.mobile": APPB2C_cj_idDetails_upload_mobile3,
  "APPB2C.cj.idDetails.upload.passport.guidelines.cover": APPB2C_cj_idDetails_upload_passport_guidelines_cover3,
  "APPB2C.cj.idDetails.upload.passport.guidelines.crop": APPB2C_cj_idDetails_upload_passport_guidelines_crop3,
  "APPB2C.cj.idDetails.upload.passport.guidelines.followInstructions": APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions3,
  "APPB2C.cj.idDetails.upload.passport.guidelines.glare": APPB2C_cj_idDetails_upload_passport_guidelines_glare3,
  "APPB2C.cj.idDetails.upload.passport.guidelines.watermark": APPB2C_cj_idDetails_upload_passport_guidelines_watermark3,
  "APPB2C.cj.idDetails.upload.title": APPB2C_cj_idDetails_upload_title3,
  "APPB2C.cj.ieltsReady.description.line1": APPB2C_cj_ieltsReady_description_line13,
  "APPB2C.cj.ieltsReady.description.line2": APPB2C_cj_ieltsReady_description_line23,
  "APPB2C.cj.ieltsReady.dobMin": APPB2C_cj_ieltsReady_dobMin3,
  "APPB2C.cj.ieltsReady.nameWarning": APPB2C_cj_ieltsReady_nameWarning3,
  "APPB2C.cj.ieltsReady.note": APPB2C_cj_ieltsReady_note3,
  "APPB2C.cj.ieltsReady.personalDetails.title": APPB2C_cj_ieltsReady_personalDetails_title2,
  "APPB2C.cj.ieltsReady.submitBtnText": APPB2C_cj_ieltsReady_submitBtnText3,
  "APPB2C.cj.ieltsReady.targetScore.option": APPB2C_cj_ieltsReady_targetScore_option3,
  "APPB2C.cj.ieltsReady.targetScore.title": APPB2C_cj_ieltsReady_targetScore_title3,
  "APPB2C.cj.ieltsReady.title": APPB2C_cj_ieltsReady_title3,
  "APPB2C.cj.incorrectOrganisation.bookNewLink": APPB2C_cj_incorrectOrganisation_bookNewLink3,
  "APPB2C.cj.incorrectOrganisation.line1": APPB2C_cj_incorrectOrganisation_line13,
  "APPB2C.cj.incorrectOrganisation.line2": APPB2C_cj_incorrectOrganisation_line23,
  "APPB2C.cj.incorrectOrganisation.locationsLink": APPB2C_cj_incorrectOrganisation_locationsLink3,
  "APPB2C.cj.incorrectOrganisation.locationsLinkUsa": APPB2C_cj_incorrectOrganisation_locationsLinkUsa3,
  "APPB2C.cj.incorrectOrganisation.title": APPB2C_cj_incorrectOrganisation_title3,
  "APPB2C.cj.marketingPrefs": APPB2C_cj_marketingPrefs3,
  "APPB2C.cj.onRequest.created.back": APPB2C_cj_onRequest_created_back3,
  "APPB2C.cj.onRequest.created.in": APPB2C_cj_onRequest_created_in3,
  "APPB2C.cj.onRequest.created.info": APPB2C_cj_onRequest_created_info3,
  "APPB2C.cj.onRequest.created.note": APPB2C_cj_onRequest_created_note3,
  "APPB2C.cj.onRequest.created.title": APPB2C_cj_onRequest_created_title3,
  "APPB2C.cj.onRequest.form.labels.confirmEmail": APPB2C_cj_onRequest_form_labels_confirmEmail3,
  "APPB2C.cj.onRequest.form.labels.email": APPB2C_cj_onRequest_form_labels_email3,
  "APPB2C.cj.onRequest.form.labels.firstname": APPB2C_cj_onRequest_form_labels_firstname3,
  "APPB2C.cj.onRequest.form.labels.surname": APPB2C_cj_onRequest_form_labels_surname3,
  "APPB2C.cj.onRequest.form.labels.telephone": APPB2C_cj_onRequest_form_labels_telephone3,
  "APPB2C.cj.onRequest.form.labels.testRangeQuestion": APPB2C_cj_onRequest_form_labels_testRangeQuestion3,
  "APPB2C.cj.onRequest.form.submit": APPB2C_cj_onRequest_form_submit3,
  "APPB2C.cj.onRequest.form.testRangeOpt.oneMonth": APPB2C_cj_onRequest_form_testRangeOpt_oneMonth3,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonths": APPB2C_cj_onRequest_form_testRangeOpt_threeMonths3,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonthsPlus": APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus3,
  "APPB2C.cj.onRequest.form.testRangeOpt.twoMonths": APPB2C_cj_onRequest_form_testRangeOpt_twoMonths3,
  "APPB2C.cj.onRequest.info1": APPB2C_cj_onRequest_info13,
  "APPB2C.cj.onRequest.note": APPB2C_cj_onRequest_note3,
  "APPB2C.cj.onRequest.otherLocations.choose": APPB2C_cj_onRequest_otherLocations_choose3,
  "APPB2C.cj.onRequest.otherLocations.hide": APPB2C_cj_onRequest_otherLocations_hide3,
  "APPB2C.cj.onRequest.otherLocations.info1": APPB2C_cj_onRequest_otherLocations_info13,
  "APPB2C.cj.onRequest.otherLocations.info2": APPB2C_cj_onRequest_otherLocations_info23,
  "APPB2C.cj.onRequest.otherLocations.show": APPB2C_cj_onRequest_otherLocations_show3,
  "APPB2C.cj.onRequest.please": APPB2C_cj_onRequest_please3,
  "APPB2C.cj.onRequest.prefNote": APPB2C_cj_onRequest_prefNote3,
  "APPB2C.cj.onRequest.subtitles.aboutPreferences": APPB2C_cj_onRequest_subtitles_aboutPreferences3,
  "APPB2C.cj.onRequest.subtitles.aboutYou": APPB2C_cj_onRequest_subtitles_aboutYou3,
  "APPB2C.cj.onRequest.subtitles.markpref": APPB2C_cj_onRequest_subtitles_markpref3,
  "APPB2C.cj.onRequest.subtitles.register": APPB2C_cj_onRequest_subtitles_register3,
  "APPB2C.cj.personalDetails.error.doubleBooking": APPB2C_cj_personalDetails_error_doubleBooking3,
  "APPB2C.cj.personalDetails.error.missingAddress": APPB2C_cj_personalDetails_error_missingAddress3,
  "APPB2C.cj.personalDetails.error.missingEmail": APPB2C_cj_personalDetails_error_missingEmail3,
  "APPB2C.cj.personalDetails.error.missingEmailConfirm": APPB2C_cj_personalDetails_error_missingEmailConfirm3,
  "APPB2C.cj.personalDetails.error.missingFirstName": APPB2C_cj_personalDetails_error_missingFirstName3,
  "APPB2C.cj.personalDetails.error.missingMobile": APPB2C_cj_personalDetails_error_missingMobile3,
  "APPB2C.cj.personalDetails.error.missingSex": APPB2C_cj_personalDetails_error_missingSex3,
  "APPB2C.cj.personalDetails.error.missingState": APPB2C_cj_personalDetails_error_missingState3,
  "APPB2C.cj.personalDetails.error.missingSurname": APPB2C_cj_personalDetails_error_missingSurname3,
  "APPB2C.cj.personalDetails.error.missingTown": APPB2C_cj_personalDetails_error_missingTown3,
  "APPB2C.cj.personalDetails.error.missingZip": APPB2C_cj_personalDetails_error_missingZip3,
  "APPB2C.cj.personalDetails.error.wrongDob": APPB2C_cj_personalDetails_error_wrongDob3,
  "APPB2C.cj.personalDetails.error.wrongEmailConfirm": APPB2C_cj_personalDetails_error_wrongEmailConfirm3,
  "APPB2C.cj.personalDetails.examTakenModal.cta": APPB2C_cj_personalDetails_examTakenModal_cta3,
  "APPB2C.cj.personalDetails.examTakenModal.message": APPB2C_cj_personalDetails_examTakenModal_message3,
  "APPB2C.cj.personalDetails.examTakenModal.title": APPB2C_cj_personalDetails_examTakenModal_title3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse": APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate": APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle": APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.title": APPB2C_cj_personalDetails_existingRegistrationsModal_title3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourLifeSkillsTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourSpeakingTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest3,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourWrittenTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest3,
  "APPB2C.cj.personalDetails.forms.address.country": APPB2C_cj_personalDetails_forms_address_country3,
  "APPB2C.cj.personalDetails.forms.address.enterManually": APPB2C_cj_personalDetails_forms_address_enterManually3,
  "APPB2C.cj.personalDetails.forms.address.postal": APPB2C_cj_personalDetails_forms_address_postal3,
  "APPB2C.cj.personalDetails.forms.address.state": APPB2C_cj_personalDetails_forms_address_state3,
  "APPB2C.cj.personalDetails.forms.address.town": APPB2C_cj_personalDetails_forms_address_town3,
  "APPB2C.cj.personalDetails.forms.address.zip": APPB2C_cj_personalDetails_forms_address_zip3,
  "APPB2C.cj.personalDetails.forms.contact.confirmEmail": APPB2C_cj_personalDetails_forms_contact_confirmEmail3,
  "APPB2C.cj.personalDetails.forms.contact.email": APPB2C_cj_personalDetails_forms_contact_email3,
  "APPB2C.cj.personalDetails.forms.contact.emailExist": APPB2C_cj_personalDetails_forms_contact_emailExist3,
  "APPB2C.cj.personalDetails.forms.contact.emailInUse": APPB2C_cj_personalDetails_forms_contact_emailInUse3,
  "APPB2C.cj.personalDetails.forms.contact.mobile": APPB2C_cj_personalDetails_forms_contact_mobile3,
  "APPB2C.cj.personalDetails.forms.contact.note": APPB2C_cj_personalDetails_forms_contact_note3,
  "APPB2C.cj.personalDetails.forms.contact.password": APPB2C_cj_personalDetails_forms_contact_password3,
  "APPB2C.cj.personalDetails.forms.contact.smsConsent": APPB2C_cj_personalDetails_forms_contact_smsConsent3,
  "APPB2C.cj.personalDetails.forms.contact.smsNote": APPB2C_cj_personalDetails_forms_contact_smsNote3,
  "APPB2C.cj.personalDetails.forms.contact.title": APPB2C_cj_personalDetails_forms_contact_title3,
  "APPB2C.cj.personalDetails.forms.personal.change": APPB2C_cj_personalDetails_forms_personal_change3,
  "APPB2C.cj.personalDetails.forms.personal.dob": APPB2C_cj_personalDetails_forms_personal_dob3,
  "APPB2C.cj.personalDetails.forms.personal.dobCta": APPB2C_cj_personalDetails_forms_personal_dobCta3,
  "APPB2C.cj.personalDetails.forms.personal.firstMiddleName": APPB2C_cj_personalDetails_forms_personal_firstMiddleName3,
  "APPB2C.cj.personalDetails.forms.personal.firstName": APPB2C_cj_personalDetails_forms_personal_firstName3,
  "APPB2C.cj.personalDetails.forms.personal.idMatch": APPB2C_cj_personalDetails_forms_personal_idMatch3,
  "APPB2C.cj.personalDetails.forms.personal.lastName": APPB2C_cj_personalDetails_forms_personal_lastName3,
  "APPB2C.cj.personalDetails.forms.personal.name": APPB2C_cj_personalDetails_forms_personal_name3,
  "APPB2C.cj.personalDetails.forms.personal.noLastName": APPB2C_cj_personalDetails_forms_personal_noLastName3,
  "APPB2C.cj.personalDetails.forms.personal.sectionTitle": APPB2C_cj_personalDetails_forms_personal_sectionTitle3,
  "APPB2C.cj.personalDetails.forWho.child": APPB2C_cj_personalDetails_forWho_child3,
  "APPB2C.cj.personalDetails.forWho.childUnder18": APPB2C_cj_personalDetails_forWho_childUnder183,
  "APPB2C.cj.personalDetails.forWho.dob": APPB2C_cj_personalDetails_forWho_dob3,
  "APPB2C.cj.personalDetails.forWho.myself": APPB2C_cj_personalDetails_forWho_myself3,
  "APPB2C.cj.personalDetails.forWho.note": APPB2C_cj_personalDetails_forWho_note3,
  "APPB2C.cj.personalDetails.forWho.title": APPB2C_cj_personalDetails_forWho_title3,
  "APPB2C.cj.personalDetails.gender": APPB2C_cj_personalDetails_gender3,
  "APPB2C.cj.personalDetails.idNote.idMatch": APPB2C_cj_personalDetails_idNote_idMatch3,
  "APPB2C.cj.personalDetails.idNote.idMatch.change": APPB2C_cj_personalDetails_idNote_idMatch_change3,
  "APPB2C.cj.personalDetails.idNote.idMatch.france": APPB2C_cj_personalDetails_idNote_idMatch_france2,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.adult": APPB2C_cj_personalDetails_idNote_idMatch_france_adult2,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.minor": APPB2C_cj_personalDetails_idNote_idMatch_france_minor2,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain": APPB2C_cj_personalDetails_idNote_idMatch_spain2,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.adult": APPB2C_cj_personalDetails_idNote_idMatch_spain_adult2,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.minor": APPB2C_cj_personalDetails_idNote_idMatch_spain_minor2,
  "APPB2C.cj.personalDetails.idNote.whenForChild": APPB2C_cj_personalDetails_idNote_whenForChild3,
  "APPB2C.cj.personalDetails.login.btn": APPB2C_cj_personalDetails_login_btn3,
  "APPB2C.cj.personalDetails.login.emailNotEditable": APPB2C_cj_personalDetails_login_emailNotEditable3,
  "APPB2C.cj.personalDetails.login.link": APPB2C_cj_personalDetails_login_link3,
  "APPB2C.cj.personalDetails.login.loggedIn": APPB2C_cj_personalDetails_login_loggedIn3,
  "APPB2C.cj.personalDetails.login.logout": APPB2C_cj_personalDetails_login_logout3,
  "APPB2C.cj.personalDetails.login.note": APPB2C_cj_personalDetails_login_note3,
  "APPB2C.cj.personalDetails.login.title": APPB2C_cj_personalDetails_login_title3,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.changeDate": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate3,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line1": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line13,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line2": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line23,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.title": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title3,
  "APPB2C.cj.personalDetails.title": APPB2C_cj_personalDetails_title3,
  "APPB2C.cj.personalDetails.tncs.agreement": APPB2C_cj_personalDetails_tncs_agreement3,
  "APPB2C.cj.personalDetails.tncs.title": APPB2C_cj_personalDetails_tncs_title3,
  "APPB2C.cj.personalDetails.visaType.label": APPB2C_cj_personalDetails_visaType_label3,
  "APPB2C.cj.personalDetails.visaType.label.child": APPB2C_cj_personalDetails_visaType_label_child3,
  "APPB2C.cj.personalDetails.visaType.link": APPB2C_cj_personalDetails_visaType_link3,
  "APPB2C.cj.personalDetails.visaType.title": APPB2C_cj_personalDetails_visaType_title3,
  "APPB2C.cj.personalDetails.visaType.title.child": APPB2C_cj_personalDetails_visaType_title_child3,
  "APPB2C.cj.rebook.errorModal.message": APPB2C_cj_rebook_errorModal_message3,
  "APPB2C.cj.rebook.errorModal.title": APPB2C_cj_rebook_errorModal_title3,
  "APPB2C.cj.review.acknowledgement.anotherPerson": APPB2C_cj_review_acknowledgement_anotherPerson3,
  "APPB2C.cj.review.acknowledgement.company": APPB2C_cj_review_acknowledgement_company3,
  "APPB2C.cj.review.acknowledgement.fields.companyName": APPB2C_cj_review_acknowledgement_fields_companyName3,
  "APPB2C.cj.review.acknowledgement.fields.companyName.subnote": APPB2C_cj_review_acknowledgement_fields_companyName_subnote3,
  "APPB2C.cj.review.acknowledgement.fields.country": APPB2C_cj_review_acknowledgement_fields_country3,
  "APPB2C.cj.review.acknowledgement.fields.email": APPB2C_cj_review_acknowledgement_fields_email3,
  "APPB2C.cj.review.acknowledgement.fields.familyName": APPB2C_cj_review_acknowledgement_fields_familyName3,
  "APPB2C.cj.review.acknowledgement.fields.firstName": APPB2C_cj_review_acknowledgement_fields_firstName3,
  "APPB2C.cj.review.acknowledgement.fields.mobile": APPB2C_cj_review_acknowledgement_fields_mobile3,
  "APPB2C.cj.review.acknowledgement.myself": APPB2C_cj_review_acknowledgement_myself3,
  "APPB2C.cj.review.acknowledgement.send": APPB2C_cj_review_acknowledgement_send3,
  "APPB2C.cj.review.acknowledgement.title": APPB2C_cj_review_acknowledgement_title3,
  "APPB2C.cj.review.agent.label": APPB2C_cj_review_agent_label3,
  "APPB2C.cj.review.agent.note": APPB2C_cj_review_agent_note3,
  "APPB2C.cj.review.agent.title": APPB2C_cj_review_agent_title3,
  "APPB2C.cj.review.ai.note": APPB2C_cj_review_ai_note3,
  "APPB2C.cj.review.ai.title": APPB2C_cj_review_ai_title3,
  "APPB2C.cj.review.bookingDetails.lrw.title": APPB2C_cj_review_bookingDetails_lrw_title3,
  "APPB2C.cj.review.bookingDetails.lrw.title.child": APPB2C_cj_review_bookingDetails_lrw_title_child3,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills3,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills.child": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child3,
  "APPB2C.cj.review.bookingDetails.speaking.title": APPB2C_cj_review_bookingDetails_speaking_title3,
  "APPB2C.cj.review.bookingDetails.speaking.title.child": APPB2C_cj_review_bookingDetails_speaking_title_child3,
  "APPB2C.cj.review.bookingDetails.title": APPB2C_cj_review_bookingDetails_title3,
  "APPB2C.cj.review.bookNowNoFee.buttonText": APPB2C_cj_review_bookNowNoFee_buttonText3,
  "APPB2C.cj.review.bookTest": APPB2C_cj_review_bookTest3,
  "APPB2C.cj.review.change": APPB2C_cj_review_change3,
  "APPB2C.cj.review.changeLrwTest": APPB2C_cj_review_changeLrwTest3,
  "APPB2C.cj.review.changeSpeakingTest": APPB2C_cj_review_changeSpeakingTest3,
  "APPB2C.cj.review.changeUkviLsTest": APPB2C_cj_review_changeUkviLsTest3,
  "APPB2C.cj.review.childDetails.guardiansTitle": APPB2C_cj_review_childDetails_guardiansTitle3,
  "APPB2C.cj.review.childDetails.onTestDay": APPB2C_cj_review_childDetails_onTestDay3,
  "APPB2C.cj.review.childDetails.onTestDayOptions.1": APPB2C_cj_review_childDetails_onTestDayOptions_13,
  "APPB2C.cj.review.childDetails.onTestDayOptions.2": APPB2C_cj_review_childDetails_onTestDayOptions_23,
  "APPB2C.cj.review.childDetails.onTestDayOptions.3": APPB2C_cj_review_childDetails_onTestDayOptions_33,
  "APPB2C.cj.review.childDetails.title": APPB2C_cj_review_childDetails_title3,
  "APPB2C.cj.review.confirmPaymentModal.btnNote": APPB2C_cj_review_confirmPaymentModal_btnNote3,
  "APPB2C.cj.review.confirmPaymentModal.note": APPB2C_cj_review_confirmPaymentModal_note3,
  "APPB2C.cj.review.confirmPaymentModal.optionsTitle": APPB2C_cj_review_confirmPaymentModal_optionsTitle3,
  "APPB2C.cj.review.confirmPaymentModal.title": APPB2C_cj_review_confirmPaymentModal_title3,
  "APPB2C.cj.review.countryMsg.pakistan": APPB2C_cj_review_countryMsg_pakistan3,
  "APPB2C.cj.review.error.missingOrderAcknowledgement": APPB2C_cj_review_error_missingOrderAcknowledgement3,
  "APPB2C.cj.review.error.missingPickups": APPB2C_cj_review_error_missingPickups3,
  "APPB2C.cj.review.error.missingTown": APPB2C_cj_review_error_missingTown3,
  "APPB2C.cj.review.error.wrongCountrySetting": APPB2C_cj_review_error_wrongCountrySetting3,
  "APPB2C.cj.review.error.wrongPaymentConfig": APPB2C_cj_review_error_wrongPaymentConfig3,
  "APPB2C.cj.review.error.wrongTaxNumber": APPB2C_cj_review_error_wrongTaxNumber3,
  "APPB2C.cj.review.expiredIdError": APPB2C_cj_review_expiredIdError3,
  "APPB2C.cj.review.idDetails.issuing": APPB2C_cj_review_idDetails_issuing3,
  "APPB2C.cj.review.idDetails.nationality": APPB2C_cj_review_idDetails_nationality3,
  "APPB2C.cj.review.idDetails.title": APPB2C_cj_review_idDetails_title3,
  "APPB2C.cj.review.idDetails.title.child": APPB2C_cj_review_idDetails_title_child3,
  "APPB2C.cj.review.invoice.companyName": APPB2C_cj_review_invoice_companyName3,
  "APPB2C.cj.review.invoice.consent": APPB2C_cj_review_invoice_consent3,
  "APPB2C.cj.review.invoice.send": APPB2C_cj_review_invoice_send3,
  "APPB2C.cj.review.invoice.subtype.citizenDigitalCertificate": APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate3,
  "APPB2C.cj.review.invoice.subtype.label": APPB2C_cj_review_invoice_subtype_label3,
  "APPB2C.cj.review.invoice.subtype.mobileBarcode": APPB2C_cj_review_invoice_subtype_mobileBarcode3,
  "APPB2C.cj.review.invoice.taxCaption.donate": APPB2C_cj_review_invoice_taxCaption_donate3,
  "APPB2C.cj.review.invoice.taxCaption.duplicatePaper": APPB2C_cj_review_invoice_taxCaption_duplicatePaper3,
  "APPB2C.cj.review.invoice.taxValidation.citizenDigitalCertificate": APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate3,
  "APPB2C.cj.review.invoice.taxValidation.donate": APPB2C_cj_review_invoice_taxValidation_donate3,
  "APPB2C.cj.review.invoice.taxValidation.mobileBarcode": APPB2C_cj_review_invoice_taxValidation_mobileBarcode3,
  "APPB2C.cj.review.invoice.taxValidation.triplicate": APPB2C_cj_review_invoice_taxValidation_triplicate3,
  "APPB2C.cj.review.invoice.title": APPB2C_cj_review_invoice_title3,
  "APPB2C.cj.review.invoice.type.donate": APPB2C_cj_review_invoice_type_donate3,
  "APPB2C.cj.review.invoice.type.duplicateElectronic": APPB2C_cj_review_invoice_type_duplicateElectronic3,
  "APPB2C.cj.review.invoice.type.duplicatePaper": APPB2C_cj_review_invoice_type_duplicatePaper3,
  "APPB2C.cj.review.invoice.type.triplicate": APPB2C_cj_review_invoice_type_triplicate3,
  "APPB2C.cj.review.localization.localTime": APPB2C_cj_review_localization_localTime3,
  "APPB2C.cj.review.localization.location": APPB2C_cj_review_localization_location3,
  "APPB2C.cj.review.localization.timezone": APPB2C_cj_review_localization_timezone3,
  "APPB2C.cj.review.missingMarketingCta": APPB2C_cj_review_missingMarketingCta3,
  "APPB2C.cj.review.missingMarketingError": APPB2C_cj_review_missingMarketingError3,
  "APPB2C.cj.review.note.content": APPB2C_cj_review_note_content3,
  "APPB2C.cj.review.note.title": APPB2C_cj_review_note_title3,
  "APPB2C.cj.review.or": APPB2C_cj_review_or3,
  "APPB2C.cj.review.payLater.buttonText": APPB2C_cj_review_payLater_buttonText3,
  "APPB2C.cj.review.payLater.sideNote": APPB2C_cj_review_payLater_sideNote3,
  "APPB2C.cj.review.payment.fee": APPB2C_cj_review_payment_fee3,
  "APPB2C.cj.review.payment.promoCodes.applied": APPB2C_cj_review_payment_promoCodes_applied3,
  "APPB2C.cj.review.payment.promoCodes.applyCode": APPB2C_cj_review_payment_promoCodes_applyCode3,
  "APPB2C.cj.review.payment.promoCodes.discount": APPB2C_cj_review_payment_promoCodes_discount3,
  "APPB2C.cj.review.payment.promoCodes.generalError": APPB2C_cj_review_payment_promoCodes_generalError3,
  "APPB2C.cj.review.payment.promoCodes.haveCode": APPB2C_cj_review_payment_promoCodes_haveCode3,
  "APPB2C.cj.review.payment.promoCodes.invalidCode": APPB2C_cj_review_payment_promoCodes_invalidCode3,
  "APPB2C.cj.review.payment.promoCodes.newFee": APPB2C_cj_review_payment_promoCodes_newFee3,
  "APPB2C.cj.review.payment.promoCodes.notHaveCode": APPB2C_cj_review_payment_promoCodes_notHaveCode3,
  "APPB2C.cj.review.payment.promoCodes.promo": APPB2C_cj_review_payment_promoCodes_promo3,
  "APPB2C.cj.review.payment.promoCodes.promoCode": APPB2C_cj_review_payment_promoCodes_promoCode3,
  "APPB2C.cj.review.payment.promoCodes.remove": APPB2C_cj_review_payment_promoCodes_remove3,
  "APPB2C.cj.review.payment.promoCodes.removeCode": APPB2C_cj_review_payment_promoCodes_removeCode3,
  "APPB2C.cj.review.payment.promoCodes.total": APPB2C_cj_review_payment_promoCodes_total3,
  "APPB2C.cj.review.payment.tax": APPB2C_cj_review_payment_tax3,
  "APPB2C.cj.review.payment.title": APPB2C_cj_review_payment_title3,
  "APPB2C.cj.review.payment.total": APPB2C_cj_review_payment_total3,
  "APPB2C.cj.review.paymentError": APPB2C_cj_review_paymentError3,
  "APPB2C.cj.review.paymentNote": APPB2C_cj_review_paymentNote3,
  "APPB2C.cj.review.paymentNoteMultiple": APPB2C_cj_review_paymentNoteMultiple2,
  "APPB2C.cj.review.paymentNoteSingle": APPB2C_cj_review_paymentNoteSingle2,
  "APPB2C.cj.review.paymentNoteZeroFee": APPB2C_cj_review_paymentNoteZeroFee3,
  "APPB2C.cj.review.payOnline.buttonText": APPB2C_cj_review_payOnline_buttonText3,
  "APPB2C.cj.review.payOnline.sideNote": APPB2C_cj_review_payOnline_sideNote3,
  "APPB2C.cj.review.personalDetails.address": APPB2C_cj_review_personalDetails_address3,
  "APPB2C.cj.review.personalDetails.dob": APPB2C_cj_review_personalDetails_dob3,
  "APPB2C.cj.review.personalDetails.email": APPB2C_cj_review_personalDetails_email3,
  "APPB2C.cj.review.personalDetails.gender": APPB2C_cj_review_personalDetails_gender3,
  "APPB2C.cj.review.personalDetails.name": APPB2C_cj_review_personalDetails_name3,
  "APPB2C.cj.review.personalDetails.phone": APPB2C_cj_review_personalDetails_phone3,
  "APPB2C.cj.review.personalDetails.smsNotificationsConsent": APPB2C_cj_review_personalDetails_smsNotificationsConsent3,
  "APPB2C.cj.review.personalDetails.title": APPB2C_cj_review_personalDetails_title3,
  "APPB2C.cj.review.registrationError": APPB2C_cj_review_registrationError3,
  "APPB2C.cj.review.registrationFailedModal.changedFee.btn": APPB2C_cj_review_registrationFailedModal_changedFee_btn3,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line1": APPB2C_cj_review_registrationFailedModal_changedFee_line13,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line2": APPB2C_cj_review_registrationFailedModal_changedFee_line23,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line3": APPB2C_cj_review_registrationFailedModal_changedFee_line33,
  "APPB2C.cj.review.registrationFailedModal.changedFee.title": APPB2C_cj_review_registrationFailedModal_changedFee_title3,
  "APPB2C.cj.review.registrationFailedModal.cta": APPB2C_cj_review_registrationFailedModal_cta3,
  "APPB2C.cj.review.registrationFailedModal.message": APPB2C_cj_review_registrationFailedModal_message3,
  "APPB2C.cj.review.registrationFailedModal.title": APPB2C_cj_review_registrationFailedModal_title3,
  "APPB2C.cj.review.taxNumberError": APPB2C_cj_review_taxNumberError3,
  "APPB2C.cj.review.title": APPB2C_cj_review_title3,
  "APPB2C.cj.review.tncs.agreement": APPB2C_cj_review_tncs_agreement3,
  "APPB2C.cj.review.tncs.title": APPB2C_cj_review_tncs_title3,
  "APPB2C.cj.review.tooHighPromoCodeError": APPB2C_cj_review_tooHighPromoCodeError3,
  "APPB2C.cj.timer.modal.cta": APPB2C_cj_timer_modal_cta3,
  "APPB2C.cj.timer.modal.message": APPB2C_cj_timer_modal_message3,
  "APPB2C.cj.timer.modal.title": APPB2C_cj_timer_modal_title3,
  "APPB2C.cj.timer.nearEnd.minuteCount": APPB2C_cj_timer_nearEnd_minuteCount3,
  "APPB2C.cj.timer.nearEnd.minuteCount_other": APPB2C_cj_timer_nearEnd_minuteCount_other3,
  "APPB2C.cj.timer.nearEnd.text": APPB2C_cj_timer_nearEnd_text3,
  "APPB2C.cj.timer.nearEnd.title": APPB2C_cj_timer_nearEnd_title3,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied3,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied.child": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child3,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest3,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest.child": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child3,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle": APPB2C_cj_ttProfile_forms_interest_sectionTitle3,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle.child": APPB2C_cj_ttProfile_forms_interest_sectionTitle_child3,
  "APPB2C.cj.ttProfile.forms.occupation.occupationLevel": APPB2C_cj_ttProfile_forms_occupation_occupationLevel3,
  "APPB2C.cj.ttProfile.forms.occupation.occupationSector": APPB2C_cj_ttProfile_forms_occupation_occupationSector3,
  "APPB2C.cj.ttProfile.forms.occupation.sectionTitle": APPB2C_cj_ttProfile_forms_occupation_sectionTitle3,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality": APPB2C_cj_ttProfile_forms_personal_countryOfNationality3,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality.child": APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child3,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.384": APPB2C_cj_ttProfile_forms_personal_educationLevel_3843,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.385": APPB2C_cj_ttProfile_forms_personal_educationLevel_3853,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.386": APPB2C_cj_ttProfile_forms_personal_educationLevel_3863,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.387": APPB2C_cj_ttProfile_forms_personal_educationLevel_3873,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.title": APPB2C_cj_ttProfile_forms_personal_educationLevel_title3,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken3,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken.child": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child3,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle": APPB2C_cj_ttProfile_forms_personal_sectionTitle3,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle.child": APPB2C_cj_ttProfile_forms_personal_sectionTitle_child3,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish3,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child3,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel3,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child3,
  "APPB2C.cj.ttProfile.note": APPB2C_cj_ttProfile_note3,
  "APPB2C.cj.ttProfile.note.child": APPB2C_cj_ttProfile_note_child3,
  "APPB2C.cj.ttProfile.title": APPB2C_cj_ttProfile_title3,
  "APPB2C.cj.ttProfile.title.child": APPB2C_cj_ttProfile_title_child3,
  "APPB2C.cj.ukviStartPage.ac.content": APPB2C_cj_ukviStartPage_ac_content3,
  "APPB2C.cj.ukviStartPage.ac.title": APPB2C_cj_ukviStartPage_ac_title3,
  "APPB2C.cj.ukviStartPage.gt.content": APPB2C_cj_ukviStartPage_gt_content3,
  "APPB2C.cj.ukviStartPage.gt.title": APPB2C_cj_ukviStartPage_gt_title3,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.content": APPB2C_cj_ukviStartPage_lifeSkils_a1_content3,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.title": APPB2C_cj_ukviStartPage_lifeSkils_a1_title3,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.content": APPB2C_cj_ukviStartPage_lifeSkils_a2_content3,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.title": APPB2C_cj_ukviStartPage_lifeSkils_a2_title3,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.content": APPB2C_cj_ukviStartPage_lifeSkils_b1_content3,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.title": APPB2C_cj_ukviStartPage_lifeSkils_b1_title3,
  "APPB2C.cj.ukviStartPage.lifeSkils.citizenship": APPB2C_cj_ukviStartPage_lifeSkils_citizenship3,
  "APPB2C.cj.ukviStartPage.lifeSkils.extension": APPB2C_cj_ukviStartPage_lifeSkils_extension3,
  "APPB2C.cj.ukviStartPage.lifeSkils.family": APPB2C_cj_ukviStartPage_lifeSkils_family3,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.content": APPB2C_cj_ukviStartPage_lifeSkils_note_content3,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.title": APPB2C_cj_ukviStartPage_lifeSkils_note_title3,
  "APPB2C.cj.ukviStartPage.lifeSkils.title": APPB2C_cj_ukviStartPage_lifeSkils_title3,
  "APPB2C.cj.ukviStartPage.lifeSkils.visaTypes": APPB2C_cj_ukviStartPage_lifeSkils_visaTypes3,
  "APPB2C.cj.ukviStartPage.note1.content": APPB2C_cj_ukviStartPage_note1_content3,
  "APPB2C.cj.ukviStartPage.note1.title": APPB2C_cj_ukviStartPage_note1_title3,
  "APPB2C.cj.ukviStartPage.terms": APPB2C_cj_ukviStartPage_terms3,
  "APPB2C.cj.ukviStartPage.testsBelow": APPB2C_cj_ukviStartPage_testsBelow3,
  "APPB2C.cj.ukviStartPage.title": APPB2C_cj_ukviStartPage_title3,
  "APPB2C.cj.ukviStartPage.whichUkvi": APPB2C_cj_ukviStartPage_whichUkvi3,
  "APPB2C.common.account.details.email.cancel": APPB2C_common_account_details_email_cancel2,
  "APPB2C.common.account.details.email.confirm": APPB2C_common_account_details_email_confirm3,
  "APPB2C.common.account.details.email.label": APPB2C_common_account_details_email_label3,
  "APPB2C.common.account.details.email.maxLength": APPB2C_common_account_details_email_maxLength3,
  "APPB2C.common.account.details.email.mismatch": APPB2C_common_account_details_email_mismatch3,
  "APPB2C.common.account.details.email.note": APPB2C_common_account_details_email_note3,
  "APPB2C.common.account.details.email.save": APPB2C_common_account_details_email_save3,
  "APPB2C.common.account.details.email.set": APPB2C_common_account_details_email_set3,
  "APPB2C.common.account.details.email.success": APPB2C_common_account_details_email_success3,
  "APPB2C.common.account.details.email.username": APPB2C_common_account_details_email_username3,
  "APPB2C.common.account.details.email.validEmail": APPB2C_common_account_details_email_validEmail3,
  "APPB2C.common.account.details.name": APPB2C_common_account_details_name3,
  "APPB2C.common.account.details.psw.cancel": APPB2C_common_account_details_psw_cancel2,
  "APPB2C.common.account.details.psw.change": APPB2C_common_account_details_psw_change3,
  "APPB2C.common.account.details.psw.label": APPB2C_common_account_details_psw_label3,
  "APPB2C.common.account.details.psw.new": APPB2C_common_account_details_psw_new3,
  "APPB2C.common.account.details.psw.old": APPB2C_common_account_details_psw_old3,
  "APPB2C.common.account.details.psw.requirements.levels.0": APPB2C_common_account_details_psw_requirements_levels_03,
  "APPB2C.common.account.details.psw.requirements.levels.1": APPB2C_common_account_details_psw_requirements_levels_13,
  "APPB2C.common.account.details.psw.requirements.levels.2": APPB2C_common_account_details_psw_requirements_levels_23,
  "APPB2C.common.account.details.psw.requirements.levels.3": APPB2C_common_account_details_psw_requirements_levels_33,
  "APPB2C.common.account.details.psw.requirements.levels.4": APPB2C_common_account_details_psw_requirements_levels_43,
  "APPB2C.common.account.details.psw.requirements.maxLength": APPB2C_common_account_details_psw_requirements_maxLength3,
  "APPB2C.common.account.details.psw.requirements.mustHave": APPB2C_common_account_details_psw_requirements_mustHave3,
  "APPB2C.common.account.details.psw.requirements.ok": APPB2C_common_account_details_psw_requirements_ok3,
  "APPB2C.common.account.details.psw.requirements.reqs.length": APPB2C_common_account_details_psw_requirements_reqs_length3,
  "APPB2C.common.account.details.psw.requirements.reqs.lower": APPB2C_common_account_details_psw_requirements_reqs_lower3,
  "APPB2C.common.account.details.psw.requirements.reqs.number": APPB2C_common_account_details_psw_requirements_reqs_number3,
  "APPB2C.common.account.details.psw.requirements.reqs.special": APPB2C_common_account_details_psw_requirements_reqs_special3,
  "APPB2C.common.account.details.psw.requirements.reqs.upper": APPB2C_common_account_details_psw_requirements_reqs_upper3,
  "APPB2C.common.account.details.psw.requirements.shouldHave": APPB2C_common_account_details_psw_requirements_shouldHave3,
  "APPB2C.common.account.details.psw.requirements.strength": APPB2C_common_account_details_psw_requirements_strength3,
  "APPB2C.common.account.details.psw.requirements.tooWeak": APPB2C_common_account_details_psw_requirements_tooWeak3,
  "APPB2C.common.account.details.psw.show": APPB2C_common_account_details_psw_show3,
  "APPB2C.common.account.details.psw.success": APPB2C_common_account_details_psw_success3,
  "APPB2C.common.account.details.subnote.address": APPB2C_common_account_details_subnote_address3,
  "APPB2C.common.account.details.subnote.city": APPB2C_common_account_details_subnote_city3,
  "APPB2C.common.account.error": APPB2C_common_account_error3,
  "APPB2C.common.account.marketing.success": APPB2C_common_account_marketing_success3,
  "APPB2C.common.account.marketing.title": APPB2C_common_account_marketing_title3,
  "APPB2C.common.account.smsNotificationSettings.mobileLabel": APPB2C_common_account_smsNotificationSettings_mobileLabel3,
  "APPB2C.common.account.smsNotificationSettings.success": APPB2C_common_account_smsNotificationSettings_success3,
  "APPB2C.common.account.smsNotificationSettings.title": APPB2C_common_account_smsNotificationSettings_title3,
  "APPB2C.common.account.terms.cta": APPB2C_common_account_terms_cta3,
  "APPB2C.common.account.terms.disclaimer": APPB2C_common_account_terms_disclaimer3,
  "APPB2C.common.account.terms.download": APPB2C_common_account_terms_download3,
  "APPB2C.common.account.terms.extra.line1": APPB2C_common_account_terms_extra_line13,
  "APPB2C.common.account.terms.extra.line2": APPB2C_common_account_terms_extra_line23,
  "APPB2C.common.account.terms.extra.line3.1": APPB2C_common_account_terms_extra_line3_13,
  "APPB2C.common.account.terms.extra.line3.2": APPB2C_common_account_terms_extra_line3_23,
  "APPB2C.common.account.terms.extra.title": APPB2C_common_account_terms_extra_title3,
  "APPB2C.common.account.terms.previous": APPB2C_common_account_terms_previous3,
  "APPB2C.common.account.terms.title": APPB2C_common_account_terms_title3,
  "APPB2C.common.account.title": APPB2C_common_account_title3,
  "APPB2C.common.alertBar.afterTest": APPB2C_common_alertBar_afterTest3,
  "APPB2C.common.alertBar.beforeTest": APPB2C_common_alertBar_beforeTest3,
  "APPB2C.common.alertBar.cancelReupload": APPB2C_common_alertBar_cancelReupload3,
  "APPB2C.common.alertBar.check": APPB2C_common_alertBar_check3,
  "APPB2C.common.alertBar.childConsentMissing": APPB2C_common_alertBar_childConsentMissing3,
  "APPB2C.common.alertBar.creds": APPB2C_common_alertBar_creds3,
  "APPB2C.common.alertBar.eorPayment": APPB2C_common_alertBar_eorPayment3,
  "APPB2C.common.alertBar.eorPaymentProof": APPB2C_common_alertBar_eorPaymentProof3,
  "APPB2C.common.alertBar.idReupload": APPB2C_common_alertBar_idReupload3,
  "APPB2C.common.alertBar.idUpload": APPB2C_common_alertBar_idUpload3,
  "APPB2C.common.alertBar.medicalReupload": APPB2C_common_alertBar_medicalReupload3,
  "APPB2C.common.alertBar.medicalUpload": APPB2C_common_alertBar_medicalUpload3,
  "APPB2C.common.alertBar.regPayment": APPB2C_common_alertBar_regPayment3,
  "APPB2C.common.alertBar.regPaymentProof": APPB2C_common_alertBar_regPaymentProof3,
  "APPB2C.common.alertBar.withCreds": APPB2C_common_alertBar_withCreds3,
  "APPB2C.common.basic.add": APPB2C_common_basic_add3,
  "APPB2C.common.basic.backToTop": APPB2C_common_basic_backToTop3,
  "APPB2C.common.basic.bookingDetails.arrival": APPB2C_common_basic_bookingDetails_arrival3,
  "APPB2C.common.basic.bookingDetails.lifeSkills.results": APPB2C_common_basic_bookingDetails_lifeSkills_results3,
  "APPB2C.common.basic.bookingDetails.lifeSkills.title": APPB2C_common_basic_bookingDetails_lifeSkills_title3,
  "APPB2C.common.basic.bookingDetails.lrw.cd": APPB2C_common_basic_bookingDetails_lrw_cd3,
  "APPB2C.common.basic.bookingDetails.lrw.online": APPB2C_common_basic_bookingDetails_lrw_online3,
  "APPB2C.common.basic.bookingDetails.lrw.pb": APPB2C_common_basic_bookingDetails_lrw_pb3,
  "APPB2C.common.basic.bookingDetails.lrw.results": APPB2C_common_basic_bookingDetails_lrw_results3,
  "APPB2C.common.basic.bookingDetails.lrw.title": APPB2C_common_basic_bookingDetails_lrw_title3,
  "APPB2C.common.basic.bookingDetails.osrTitle.listening": APPB2C_common_basic_bookingDetails_osrTitle_listening3,
  "APPB2C.common.basic.bookingDetails.osrTitle.reading": APPB2C_common_basic_bookingDetails_osrTitle_reading3,
  "APPB2C.common.basic.bookingDetails.speaking.f2f": APPB2C_common_basic_bookingDetails_speaking_f2f3,
  "APPB2C.common.basic.bookingDetails.speaking.live": APPB2C_common_basic_bookingDetails_speaking_live3,
  "APPB2C.common.basic.bookingDetails.speaking.online": APPB2C_common_basic_bookingDetails_speaking_online3,
  "APPB2C.common.basic.bookingDetails.speaking.remote": APPB2C_common_basic_bookingDetails_speaking_remote3,
  "APPB2C.common.basic.bookingDetails.speaking.results": APPB2C_common_basic_bookingDetails_speaking_results3,
  "APPB2C.common.basic.bookingDetails.speaking.title": APPB2C_common_basic_bookingDetails_speaking_title3,
  "APPB2C.common.basic.bookPrePaid": APPB2C_common_basic_bookPrePaid3,
  "APPB2C.common.basic.cancel": APPB2C_common_basic_cancel3,
  "APPB2C.common.basic.change": APPB2C_common_basic_change3,
  "APPB2C.common.basic.choose": APPB2C_common_basic_choose3,
  "APPB2C.common.basic.close": APPB2C_common_basic_close3,
  "APPB2C.common.basic.copied": APPB2C_common_basic_copied3,
  "APPB2C.common.basic.copy": APPB2C_common_basic_copy3,
  "APPB2C.common.basic.dateField.day": APPB2C_common_basic_dateField_day3,
  "APPB2C.common.basic.dateField.month": APPB2C_common_basic_dateField_month3,
  "APPB2C.common.basic.dateField.months": APPB2C_common_basic_dateField_months3,
  "APPB2C.common.basic.dateField.year": APPB2C_common_basic_dateField_year3,
  "APPB2C.common.basic.edit": APPB2C_common_basic_edit3,
  "APPB2C.common.basic.errors.contactTestCentre": APPB2C_common_basic_errors_contactTestCentre3,
  "APPB2C.common.basic.errors.network": APPB2C_common_basic_errors_network3,
  "APPB2C.common.basic.errors.noTest": APPB2C_common_basic_errors_noTest3,
  "APPB2C.common.basic.errors.server": APPB2C_common_basic_errors_server3,
  "APPB2C.common.basic.errors.title": APPB2C_common_basic_errors_title3,
  "APPB2C.common.basic.errors.unableToDownloadFile": APPB2C_common_basic_errors_unableToDownloadFile3,
  "APPB2C.common.basic.errors.unknown": APPB2C_common_basic_errors_unknown3,
  "APPB2C.common.basic.expando.collapse": APPB2C_common_basic_expando_collapse3,
  "APPB2C.common.basic.expando.show": APPB2C_common_basic_expando_show3,
  "APPB2C.common.basic.externalLinkTitle": APPB2C_common_basic_externalLinkTitle3,
  "APPB2C.common.basic.failure": APPB2C_common_basic_failure3,
  "APPB2C.common.basic.forms.addressMaxLength": APPB2C_common_basic_forms_addressMaxLength3,
  "APPB2C.common.basic.forms.dobMin": APPB2C_common_basic_forms_dobMin3,
  "APPB2C.common.basic.forms.dobMinIol": APPB2C_common_basic_forms_dobMinIol3,
  "APPB2C.common.basic.forms.dobOutOfRange": APPB2C_common_basic_forms_dobOutOfRange3,
  "APPB2C.common.basic.forms.firstNameMaxLength": APPB2C_common_basic_forms_firstNameMaxLength3,
  "APPB2C.common.basic.forms.idExpiration": APPB2C_common_basic_forms_idExpiration3,
  "APPB2C.common.basic.forms.idMaxLength": APPB2C_common_basic_forms_idMaxLength3,
  "APPB2C.common.basic.forms.idNumber": APPB2C_common_basic_forms_idNumber3,
  "APPB2C.common.basic.forms.idProofRequired": APPB2C_common_basic_forms_idProofRequired3,
  "APPB2C.common.basic.forms.maxLengthMessage": APPB2C_common_basic_forms_maxLengthMessage3,
  "APPB2C.common.basic.forms.minorDobMax": APPB2C_common_basic_forms_minorDobMax3,
  "APPB2C.common.basic.forms.minorDobMin": APPB2C_common_basic_forms_minorDobMin3,
  "APPB2C.common.basic.forms.mobileBypass": APPB2C_common_basic_forms_mobileBypass3,
  "APPB2C.common.basic.forms.mobileLength": APPB2C_common_basic_forms_mobileLength3,
  "APPB2C.common.basic.forms.onlyLatin": APPB2C_common_basic_forms_onlyLatin3,
  "APPB2C.common.basic.forms.postCodeMaxLength": APPB2C_common_basic_forms_postCodeMaxLength3,
  "APPB2C.common.basic.forms.postCodeMessage": APPB2C_common_basic_forms_postCodeMessage3,
  "APPB2C.common.basic.forms.postCodeNotFound": APPB2C_common_basic_forms_postCodeNotFound3,
  "APPB2C.common.basic.forms.requiredMessage": APPB2C_common_basic_forms_requiredMessage3,
  "APPB2C.common.basic.forms.requiredNoSurmane": APPB2C_common_basic_forms_requiredNoSurmane3,
  "APPB2C.common.basic.forms.stateMaxLength": APPB2C_common_basic_forms_stateMaxLength3,
  "APPB2C.common.basic.forms.surnameMaxLength": APPB2C_common_basic_forms_surnameMaxLength3,
  "APPB2C.common.basic.forms.townMaxLength": APPB2C_common_basic_forms_townMaxLength3,
  "APPB2C.common.basic.forms.unsupportedChars": APPB2C_common_basic_forms_unsupportedChars3,
  "APPB2C.common.basic.forms.validMobile": APPB2C_common_basic_forms_validMobile3,
  "APPB2C.common.basic.goBack": APPB2C_common_basic_goBack3,
  "APPB2C.common.basic.loading": APPB2C_common_basic_loading3,
  "APPB2C.common.basic.menu": APPB2C_common_basic_menu3,
  "APPB2C.common.basic.no": APPB2C_common_basic_no3,
  "APPB2C.common.basic.notAllowed.msg": APPB2C_common_basic_notAllowed_msg3,
  "APPB2C.common.basic.notAllowed.title": APPB2C_common_basic_notAllowed_title3,
  "APPB2C.common.basic.notFound.goHome": APPB2C_common_basic_notFound_goHome3,
  "APPB2C.common.basic.notFound.msg": APPB2C_common_basic_notFound_msg3,
  "APPB2C.common.basic.notFound.title": APPB2C_common_basic_notFound_title3,
  "APPB2C.common.basic.notLoading.note": APPB2C_common_basic_notLoading_note3,
  "APPB2C.common.basic.notLoading.refresh": APPB2C_common_basic_notLoading_refresh3,
  "APPB2C.common.basic.notLoading.title": APPB2C_common_basic_notLoading_title3,
  "APPB2C.common.basic.ok": APPB2C_common_basic_ok3,
  "APPB2C.common.basic.on": APPB2C_common_basic_on3,
  "APPB2C.common.basic.oneSecond": APPB2C_common_basic_oneSecond3,
  "APPB2C.common.basic.pleaseNote": APPB2C_common_basic_pleaseNote3,
  "APPB2C.common.basic.retake": APPB2C_common_basic_retake3,
  "APPB2C.common.basic.upload": APPB2C_common_basic_upload3,
  "APPB2C.common.basic.validationError": APPB2C_common_basic_validationError3,
  "APPB2C.common.basic.waitReassurance": APPB2C_common_basic_waitReassurance3,
  "APPB2C.common.basic.wereSorry": APPB2C_common_basic_wereSorry3,
  "APPB2C.common.basic.yes": APPB2C_common_basic_yes3,
  "APPB2C.common.bookTest.changeExamType": APPB2C_common_bookTest_changeExamType3,
  "APPB2C.common.bookTest.filters.afternoon": APPB2C_common_bookTest_filters_afternoon3,
  "APPB2C.common.bookTest.filters.allDates": APPB2C_common_bookTest_filters_allDates3,
  "APPB2C.common.bookTest.filters.anyTime": APPB2C_common_bookTest_filters_anyTime3,
  "APPB2C.common.bookTest.filters.computer": APPB2C_common_bookTest_filters_computer3,
  "APPB2C.common.bookTest.filters.evening": APPB2C_common_bookTest_filters_evening3,
  "APPB2C.common.bookTest.filters.modal.cta": APPB2C_common_bookTest_filters_modal_cta3,
  "APPB2C.common.bookTest.filters.modal.hide": APPB2C_common_bookTest_filters_modal_hide3,
  "APPB2C.common.bookTest.filters.modal.iDontMind": APPB2C_common_bookTest_filters_modal_iDontMind3,
  "APPB2C.common.bookTest.filters.modal.resultsIn": APPB2C_common_bookTest_filters_modal_resultsIn3,
  "APPB2C.common.bookTest.filters.modal.subtitle": APPB2C_common_bookTest_filters_modal_subtitle3,
  "APPB2C.common.bookTest.filters.modal.title": APPB2C_common_bookTest_filters_modal_title3,
  "APPB2C.common.bookTest.filters.modal.whichFormatTest": APPB2C_common_bookTest_filters_modal_whichFormatTest3,
  "APPB2C.common.bookTest.filters.modal.whichTimeOfDay": APPB2C_common_bookTest_filters_modal_whichTimeOfDay3,
  "APPB2C.common.bookTest.filters.morning": APPB2C_common_bookTest_filters_morning3,
  "APPB2C.common.bookTest.filters.paper": APPB2C_common_bookTest_filters_paper3,
  "APPB2C.common.bookTest.filters.seeAllFilters": APPB2C_common_bookTest_filters_seeAllFilters3,
  "APPB2C.common.bookTest.filters.title": APPB2C_common_bookTest_filters_title3,
  "APPB2C.common.bookTest.loadMoreTests": APPB2C_common_bookTest_loadMoreTests3,
  "APPB2C.common.bookTest.noDiscount.msg.countryNotMatched": APPB2C_common_bookTest_noDiscount_msg_countryNotMatched3,
  "APPB2C.common.bookTest.noDiscount.msg.notFound": APPB2C_common_bookTest_noDiscount_msg_notFound3,
  "APPB2C.common.bookTest.noDiscount.msg.notSupported": APPB2C_common_bookTest_noDiscount_msg_notSupported3,
  "APPB2C.common.bookTest.noDiscount.msg.reserved": APPB2C_common_bookTest_noDiscount_msg_reserved3,
  "APPB2C.common.bookTest.noDiscount.msg.used": APPB2C_common_bookTest_noDiscount_msg_used3,
  "APPB2C.common.bookTest.noDiscount.title": APPB2C_common_bookTest_noDiscount_title3,
  "APPB2C.common.bookTest.results.book": APPB2C_common_bookTest_results_book3,
  "APPB2C.common.bookTest.results.bookFor": APPB2C_common_bookTest_results_bookFor3,
  "APPB2C.common.bookTest.results.cd": APPB2C_common_bookTest_results_cd3,
  "APPB2C.common.bookTest.results.changeSpeaking": APPB2C_common_bookTest_results_changeSpeaking3,
  "APPB2C.common.bookTest.results.clearFilters": APPB2C_common_bookTest_results_clearFilters3,
  "APPB2C.common.bookTest.results.fromCurrentLocation": APPB2C_common_bookTest_results_fromCurrentLocation3,
  "APPB2C.common.bookTest.results.lrw": APPB2C_common_bookTest_results_lrw3,
  "APPB2C.common.bookTest.results.noResultsGoBack": APPB2C_common_bookTest_results_noResultsGoBack3,
  "APPB2C.common.bookTest.results.noResultsText": APPB2C_common_bookTest_results_noResultsText3,
  "APPB2C.common.bookTest.results.noResultsTitle": APPB2C_common_bookTest_results_noResultsTitle3,
  "APPB2C.common.bookTest.results.pb": APPB2C_common_bookTest_results_pb3,
  "APPB2C.common.bookTest.results.review": APPB2C_common_bookTest_results_review3,
  "APPB2C.common.bookTest.results.searchText": APPB2C_common_bookTest_results_searchText3,
  "APPB2C.common.bookTest.results.speaking": APPB2C_common_bookTest_results_speaking3,
  "APPB2C.common.bookTest.results.viewOnMap": APPB2C_common_bookTest_results_viewOnMap3,
  "APPB2C.common.bookTest.sortBy.date": APPB2C_common_bookTest_sortBy_date3,
  "APPB2C.common.bookTest.sortBy.distance": APPB2C_common_bookTest_sortBy_distance3,
  "APPB2C.common.bookTest.sortBy.price": APPB2C_common_bookTest_sortBy_price3,
  "APPB2C.common.bookTest.sortBy.title": APPB2C_common_bookTest_sortBy_title3,
  "APPB2C.common.bookTest.title": APPB2C_common_bookTest_title3,
  "APPB2C.common.cancelBooking.alreadyRequested.cta": APPB2C_common_cancelBooking_alreadyRequested_cta3,
  "APPB2C.common.cancelBooking.alreadyRequested.title": APPB2C_common_cancelBooking_alreadyRequested_title3,
  "APPB2C.common.cancelBooking.back": APPB2C_common_cancelBooking_back3,
  "APPB2C.common.cancelBooking.bankDetails.form.accountHolder": APPB2C_common_cancelBooking_bankDetails_form_accountHolder3,
  "APPB2C.common.cancelBooking.bankDetails.form.bankAddress": APPB2C_common_cancelBooking_bankDetails_form_bankAddress3,
  "APPB2C.common.cancelBooking.bankDetails.form.bankName": APPB2C_common_cancelBooking_bankDetails_form_bankName3,
  "APPB2C.common.cancelBooking.bankDetails.form.bankNumber": APPB2C_common_cancelBooking_bankDetails_form_bankNumber3,
  "APPB2C.common.cancelBooking.bankDetails.form.changeCountry": APPB2C_common_cancelBooking_bankDetails_form_changeCountry3,
  "APPB2C.common.cancelBooking.bankDetails.form.city": APPB2C_common_cancelBooking_bankDetails_form_city3,
  "APPB2C.common.cancelBooking.bankDetails.form.country": APPB2C_common_cancelBooking_bankDetails_form_country3,
  "APPB2C.common.cancelBooking.bankDetails.form.holderEmail": APPB2C_common_cancelBooking_bankDetails_form_holderEmail3,
  "APPB2C.common.cancelBooking.bankDetails.form.holderName": APPB2C_common_cancelBooking_bankDetails_form_holderName3,
  "APPB2C.common.cancelBooking.bankDetails.form.iban": APPB2C_common_cancelBooking_bankDetails_form_iban3,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidIban": APPB2C_common_cancelBooking_bankDetails_form_invalidIban3,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidSwift": APPB2C_common_cancelBooking_bankDetails_form_invalidSwift3,
  "APPB2C.common.cancelBooking.bankDetails.form.postcode": APPB2C_common_cancelBooking_bankDetails_form_postcode3,
  "APPB2C.common.cancelBooking.bankDetails.form.sortCode": APPB2C_common_cancelBooking_bankDetails_form_sortCode3,
  "APPB2C.common.cancelBooking.bankDetails.form.swift": APPB2C_common_cancelBooking_bankDetails_form_swift3,
  "APPB2C.common.cancelBooking.bankDetails.note": APPB2C_common_cancelBooking_bankDetails_note3,
  "APPB2C.common.cancelBooking.bankDetails.privacy": APPB2C_common_cancelBooking_bankDetails_privacy3,
  "APPB2C.common.cancelBooking.bankDetails.terms.consent": APPB2C_common_cancelBooking_bankDetails_terms_consent3,
  "APPB2C.common.cancelBooking.bankDetails.terms.title": APPB2C_common_cancelBooking_bankDetails_terms_title3,
  "APPB2C.common.cancelBooking.bankDetails.title": APPB2C_common_cancelBooking_bankDetails_title3,
  "APPB2C.common.cancelBooking.bottomNotes.note1": APPB2C_common_cancelBooking_bottomNotes_note13,
  "APPB2C.common.cancelBooking.bottomNotes.note2": APPB2C_common_cancelBooking_bottomNotes_note23,
  "APPB2C.common.cancelBooking.bottomNotes.note3": APPB2C_common_cancelBooking_bottomNotes_note33,
  "APPB2C.common.cancelBooking.bottomNotes.terms": APPB2C_common_cancelBooking_bottomNotes_terms3,
  "APPB2C.common.cancelBooking.cancelNotes.1": APPB2C_common_cancelBooking_cancelNotes_13,
  "APPB2C.common.cancelBooking.cancelNotes.2": APPB2C_common_cancelBooking_cancelNotes_23,
  "APPB2C.common.cancelBooking.cancelNotes.3": APPB2C_common_cancelBooking_cancelNotes_33,
  "APPB2C.common.cancelBooking.checkTest": APPB2C_common_cancelBooking_checkTest3,
  "APPB2C.common.cancelBooking.cta.addBankDetails": APPB2C_common_cancelBooking_cta_addBankDetails3,
  "APPB2C.common.cancelBooking.cta.goBack": APPB2C_common_cancelBooking_cta_goBack3,
  "APPB2C.common.cancelBooking.cta.submit": APPB2C_common_cancelBooking_cta_submit3,
  "APPB2C.common.cancelBooking.error.cancelledAlready": APPB2C_common_cancelBooking_error_cancelledAlready3,
  "APPB2C.common.cancelBooking.error.missingFile": APPB2C_common_cancelBooking_error_missingFile3,
  "APPB2C.common.cancelBooking.error.requestedAlready": APPB2C_common_cancelBooking_error_requestedAlready3,
  "APPB2C.common.cancelBooking.reason.form.choose": APPB2C_common_cancelBooking_reason_form_choose3,
  "APPB2C.common.cancelBooking.reason.form.freeform": APPB2C_common_cancelBooking_reason_form_freeform3,
  "APPB2C.common.cancelBooking.reason.form.optional": APPB2C_common_cancelBooking_reason_form_optional3,
  "APPB2C.common.cancelBooking.reason.form.requiredEvidence": APPB2C_common_cancelBooking_reason_form_requiredEvidence3,
  "APPB2C.common.cancelBooking.reason.form.requiredReason": APPB2C_common_cancelBooking_reason_form_requiredReason3,
  "APPB2C.common.cancelBooking.reason.form.title": APPB2C_common_cancelBooking_reason_form_title3,
  "APPB2C.common.cancelBooking.reason.note.content": APPB2C_common_cancelBooking_reason_note_content3,
  "APPB2C.common.cancelBooking.reason.note.title": APPB2C_common_cancelBooking_reason_note_title3,
  "APPB2C.common.cancelBooking.subtitle": APPB2C_common_cancelBooking_subtitle3,
  "APPB2C.common.cancelBooking.title": APPB2C_common_cancelBooking_title3,
  "APPB2C.common.cancelBooking.transferNote.cta": APPB2C_common_cancelBooking_transferNote_cta3,
  "APPB2C.common.cancelBooking.transferNote.title": APPB2C_common_cancelBooking_transferNote_title3,
  "APPB2C.common.cancelBooking.transferNote.titleIol": APPB2C_common_cancelBooking_transferNote_titleIol3,
  "APPB2C.common.cancelBooking.upload.mobile": APPB2C_common_cancelBooking_upload_mobile3,
  "APPB2C.common.cancelBooking.upload.notes.1": APPB2C_common_cancelBooking_upload_notes_13,
  "APPB2C.common.cancelBooking.upload.notes.10": APPB2C_common_cancelBooking_upload_notes_103,
  "APPB2C.common.cancelBooking.upload.notes.2": APPB2C_common_cancelBooking_upload_notes_23,
  "APPB2C.common.cancelBooking.upload.notes.3": APPB2C_common_cancelBooking_upload_notes_33,
  "APPB2C.common.cancelBooking.upload.notes.4": APPB2C_common_cancelBooking_upload_notes_43,
  "APPB2C.common.cancelBooking.upload.notes.5": APPB2C_common_cancelBooking_upload_notes_53,
  "APPB2C.common.cancelBooking.upload.notes.6": APPB2C_common_cancelBooking_upload_notes_63,
  "APPB2C.common.cancelBooking.upload.notes.7": APPB2C_common_cancelBooking_upload_notes_73,
  "APPB2C.common.cancelBooking.upload.notes.8": APPB2C_common_cancelBooking_upload_notes_83,
  "APPB2C.common.cancelBooking.upload.requirements.note": APPB2C_common_cancelBooking_upload_requirements_note3,
  "APPB2C.common.cancelBooking.upload.requirements.title": APPB2C_common_cancelBooking_upload_requirements_title3,
  "APPB2C.common.cancelBooking.upload.title": APPB2C_common_cancelBooking_upload_title3,
  "APPB2C.common.changeBooking.cancel.cta": APPB2C_common_changeBooking_cancel_cta3,
  "APPB2C.common.changeBooking.cancel.text": APPB2C_common_changeBooking_cancel_text3,
  "APPB2C.common.changeBooking.goBack": APPB2C_common_changeBooking_goBack3,
  "APPB2C.common.changeBooking.subtitle": APPB2C_common_changeBooking_subtitle3,
  "APPB2C.common.changeBooking.title": APPB2C_common_changeBooking_title3,
  "APPB2C.common.changeBooking.transfer.cta": APPB2C_common_changeBooking_transfer_cta3,
  "APPB2C.common.changeBooking.transfer.text": APPB2C_common_changeBooking_transfer_text3,
  "APPB2C.common.changeBooking.transfer.textIol": APPB2C_common_changeBooking_transfer_textIol3,
  "APPB2C.common.changeBooking.transferNotes.1": APPB2C_common_changeBooking_transferNotes_13,
  "APPB2C.common.changeBooking.transferNotes.2": APPB2C_common_changeBooking_transferNotes_23,
  "APPB2C.common.changeBooking.transferNotesIol.1": APPB2C_common_changeBooking_transferNotesIol_13,
  "APPB2C.common.changeBooking.transferNotesIol.2": APPB2C_common_changeBooking_transferNotesIol_23,
  "APPB2C.common.changeBookingAck.back": APPB2C_common_changeBookingAck_back3,
  "APPB2C.common.changeBookingAck.cancel.banner": APPB2C_common_changeBookingAck_cancel_banner3,
  "APPB2C.common.changeBookingAck.cancel.contact.email": APPB2C_common_changeBookingAck_cancel_contact_email3,
  "APPB2C.common.changeBookingAck.cancel.contact.phone": APPB2C_common_changeBookingAck_cancel_contact_phone3,
  "APPB2C.common.changeBookingAck.cancel.contact.title": APPB2C_common_changeBookingAck_cancel_contact_title3,
  "APPB2C.common.changeBookingAck.cancel.contact.titleIol": APPB2C_common_changeBookingAck_cancel_contact_titleIol3,
  "APPB2C.common.changeBookingAck.cancel.refNr": APPB2C_common_changeBookingAck_cancel_refNr3,
  "APPB2C.common.changeBookingAck.cancel.refunds": APPB2C_common_changeBookingAck_cancel_refunds3,
  "APPB2C.common.changeBookingAck.cancel.respondTime": APPB2C_common_changeBookingAck_cancel_respondTime3,
  "APPB2C.common.changeBookingAck.cancel.terms": APPB2C_common_changeBookingAck_cancel_terms3,
  "APPB2C.common.changeBookingAck.cancel.title": APPB2C_common_changeBookingAck_cancel_title3,
  "APPB2C.common.changeBookingAck.fees": APPB2C_common_changeBookingAck_fees3,
  "APPB2C.common.changeBookingAck.feesIol": APPB2C_common_changeBookingAck_feesIol3,
  "APPB2C.common.changeBookingAck.speaking.banner": APPB2C_common_changeBookingAck_speaking_banner3,
  "APPB2C.common.changeBookingAck.speaking.note.line1": APPB2C_common_changeBookingAck_speaking_note_line13,
  "APPB2C.common.changeBookingAck.speaking.note.line2": APPB2C_common_changeBookingAck_speaking_note_line23,
  "APPB2C.common.changeBookingAck.speaking.note.title": APPB2C_common_changeBookingAck_speaking_note_title3,
  "APPB2C.common.changeBookingAck.speaking.title": APPB2C_common_changeBookingAck_speaking_title3,
  "APPB2C.common.changeBookingAck.terms": APPB2C_common_changeBookingAck_terms3,
  "APPB2C.common.changeBookingAck.transfer.banner": APPB2C_common_changeBookingAck_transfer_banner3,
  "APPB2C.common.changeBookingAck.transfer.note": APPB2C_common_changeBookingAck_transfer_note3,
  "APPB2C.common.changeBookingAck.transfer.respondTime": APPB2C_common_changeBookingAck_transfer_respondTime3,
  "APPB2C.common.changeBookingAck.transfer.title": APPB2C_common_changeBookingAck_transfer_title3,
  "APPB2C.common.changeBookingAck.transferIol.banner": APPB2C_common_changeBookingAck_transferIol_banner3,
  "APPB2C.common.changeBookingAck.transferIol.contact": APPB2C_common_changeBookingAck_transferIol_contact3,
  "APPB2C.common.changeBookingAck.transferIol.note": APPB2C_common_changeBookingAck_transferIol_note3,
  "APPB2C.common.changeBookingAck.transferIol.refNr": APPB2C_common_changeBookingAck_transferIol_refNr3,
  "APPB2C.common.changeBookingAck.transferIol.terms": APPB2C_common_changeBookingAck_transferIol_terms3,
  "APPB2C.common.changeSpeaking.cards.change": APPB2C_common_changeSpeaking_cards_change3,
  "APPB2C.common.changeSpeaking.cards.noSlots": APPB2C_common_changeSpeaking_cards_noSlots3,
  "APPB2C.common.changeSpeaking.cards.noSlots.error": APPB2C_common_changeSpeaking_cards_noSlots_error3,
  "APPB2C.common.changeSpeaking.cards.title": APPB2C_common_changeSpeaking_cards_title3,
  "APPB2C.common.changeSpeaking.cta": APPB2C_common_changeSpeaking_cta3,
  "APPB2C.common.changeSpeaking.modals.noSlots.keepTest": APPB2C_common_changeSpeaking_modals_noSlots_keepTest3,
  "APPB2C.common.changeSpeaking.modals.noSlots.line1": APPB2C_common_changeSpeaking_modals_noSlots_line13,
  "APPB2C.common.changeSpeaking.modals.noSlots.line2": APPB2C_common_changeSpeaking_modals_noSlots_line23,
  "APPB2C.common.changeSpeaking.modals.noSlots.title": APPB2C_common_changeSpeaking_modals_noSlots_title3,
  "APPB2C.common.changeSpeaking.modals.noSlots.transferTest": APPB2C_common_changeSpeaking_modals_noSlots_transferTest3,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.cta": APPB2C_common_changeSpeaking_modals_testNotAvailable_cta3,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line1": APPB2C_common_changeSpeaking_modals_testNotAvailable_line13,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line2": APPB2C_common_changeSpeaking_modals_testNotAvailable_line23,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.title": APPB2C_common_changeSpeaking_modals_testNotAvailable_title3,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.cta": APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta3,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.line1": APPB2C_common_changeSpeaking_modals_transferNotAvailable_line13,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.title": APPB2C_common_changeSpeaking_modals_transferNotAvailable_title3,
  "APPB2C.common.changeSpeaking.note.back": APPB2C_common_changeSpeaking_note_back3,
  "APPB2C.common.changeSpeaking.note.line1": APPB2C_common_changeSpeaking_note_line13,
  "APPB2C.common.changeSpeaking.note.line2": APPB2C_common_changeSpeaking_note_line23,
  "APPB2C.common.changeSpeaking.note.title": APPB2C_common_changeSpeaking_note_title3,
  "APPB2C.common.changeSpeaking.title": APPB2C_common_changeSpeaking_title3,
  "APPB2C.common.chooseTest.difference": APPB2C_common_chooseTest_difference3,
  "APPB2C.common.chooseTest.iol.consent": APPB2C_common_chooseTest_iol_consent3,
  "APPB2C.common.chooseTest.iol.cta": APPB2C_common_chooseTest_iol_cta3,
  "APPB2C.common.chooseTest.iol.desc": APPB2C_common_chooseTest_iol_desc3,
  "APPB2C.common.chooseTest.iol.link": APPB2C_common_chooseTest_iol_link3,
  "APPB2C.common.chooseTest.iol.note.content": APPB2C_common_chooseTest_iol_note_content3,
  "APPB2C.common.chooseTest.iol.note.title": APPB2C_common_chooseTest_iol_note_title3,
  "APPB2C.common.chooseTest.iol.title": APPB2C_common_chooseTest_iol_title3,
  "APPB2C.common.chooseTest.ors.cta": APPB2C_common_chooseTest_ors_cta3,
  "APPB2C.common.chooseTest.ors.descAc": APPB2C_common_chooseTest_ors_descAc3,
  "APPB2C.common.chooseTest.ors.descGt": APPB2C_common_chooseTest_ors_descGt3,
  "APPB2C.common.chooseTest.ors.title": APPB2C_common_chooseTest_ors_title3,
  "APPB2C.common.chooseTest.title": APPB2C_common_chooseTest_title3,
  "APPB2C.common.chooseTest.ukvi.difference": APPB2C_common_chooseTest_ukvi_difference3,
  "APPB2C.common.chooseTest.ukvi.inCentre.cta": APPB2C_common_chooseTest_ukvi_inCentre_cta3,
  "APPB2C.common.chooseTest.ukvi.inCentre.descAc": APPB2C_common_chooseTest_ukvi_inCentre_descAc3,
  "APPB2C.common.chooseTest.ukvi.inCentre.descGt": APPB2C_common_chooseTest_ukvi_inCentre_descGt3,
  "APPB2C.common.chooseTest.ukvi.inCentre.title": APPB2C_common_chooseTest_ukvi_inCentre_title3,
  "APPB2C.common.chooseTest.ukvi.online.consent": APPB2C_common_chooseTest_ukvi_online_consent3,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.symbol": APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol3,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.title": APPB2C_common_chooseTest_ukvi_online_consentBiometric_title3,
  "APPB2C.common.chooseTest.ukvi.online.cta": APPB2C_common_chooseTest_ukvi_online_cta3,
  "APPB2C.common.chooseTest.ukvi.online.link": APPB2C_common_chooseTest_ukvi_online_link3,
  "APPB2C.common.chooseTest.ukvi.online.note.content": APPB2C_common_chooseTest_ukvi_online_note_content3,
  "APPB2C.common.chooseTest.ukvi.online.note.title": APPB2C_common_chooseTest_ukvi_online_note_title3,
  "APPB2C.common.chooseTest.ukvi.online.title": APPB2C_common_chooseTest_ukvi_online_title3,
  "APPB2C.common.draft.cancel": APPB2C_common_draft_cancel2,
  "APPB2C.common.draft.cta.confirm": APPB2C_common_draft_cta_confirm3,
  "APPB2C.common.draft.cta.decline": APPB2C_common_draft_cta_decline3,
  "APPB2C.common.draft.cta.note": APPB2C_common_draft_cta_note3,
  "APPB2C.common.draft.difference": APPB2C_common_draft_difference3,
  "APPB2C.common.draft.edit": APPB2C_common_draft_edit3,
  "APPB2C.common.draft.error.pastDeadline": APPB2C_common_draft_error_pastDeadline3,
  "APPB2C.common.draft.id.cta": APPB2C_common_draft_id_cta3,
  "APPB2C.common.draft.id.documentWithNoExpiryDate": APPB2C_common_draft_id_documentWithNoExpiryDate3,
  "APPB2C.common.draft.id.header": APPB2C_common_draft_id_header3,
  "APPB2C.common.draft.id.issuingAuthority": APPB2C_common_draft_id_issuingAuthority3,
  "APPB2C.common.draft.id.note": APPB2C_common_draft_id_note3,
  "APPB2C.common.draft.id.title": APPB2C_common_draft_id_title3,
  "APPB2C.common.draft.note.line1": APPB2C_common_draft_note_line13,
  "APPB2C.common.draft.note.line2": APPB2C_common_draft_note_line23,
  "APPB2C.common.draft.note.line3": APPB2C_common_draft_note_line33,
  "APPB2C.common.draft.personal.cta": APPB2C_common_draft_personal_cta3,
  "APPB2C.common.draft.personal.header": APPB2C_common_draft_personal_header3,
  "APPB2C.common.draft.personal.note": APPB2C_common_draft_personal_note3,
  "APPB2C.common.draft.personal.title": APPB2C_common_draft_personal_title3,
  "APPB2C.common.draft.title": APPB2C_common_draft_title3,
  "APPB2C.common.examType.iol.ac": APPB2C_common_examType_iol_ac3,
  "APPB2C.common.examType.iol.gt": APPB2C_common_examType_iol_gt3,
  "APPB2C.common.examType.ors.ac": APPB2C_common_examType_ors_ac3,
  "APPB2C.common.examType.ors.gt": APPB2C_common_examType_ors_gt3,
  "APPB2C.common.examType.ors.osr.ac": APPB2C_common_examType_ors_osr_ac3,
  "APPB2C.common.examType.ors.osr.gt": APPB2C_common_examType_ors_osr_gt3,
  "APPB2C.common.examType.ukvi.ac": APPB2C_common_examType_ukvi_ac3,
  "APPB2C.common.examType.ukvi.gt": APPB2C_common_examType_ukvi_gt3,
  "APPB2C.common.examType.ukvi.lfska1": APPB2C_common_examType_ukvi_lfska13,
  "APPB2C.common.examType.ukvi.lfska2": APPB2C_common_examType_ukvi_lfska23,
  "APPB2C.common.examType.ukvi.lfskb1": APPB2C_common_examType_ukvi_lfskb13,
  "APPB2C.common.examType.ukvi.osr.ac": APPB2C_common_examType_ukvi_osr_ac3,
  "APPB2C.common.examType.ukvi.osr.gt": APPB2C_common_examType_ukvi_osr_gt3,
  "APPB2C.common.examType.uol.ac": APPB2C_common_examType_uol_ac3,
  "APPB2C.common.examType.uol.gt": APPB2C_common_examType_uol_gt3,
  "APPB2C.common.filePreview.label": APPB2C_common_filePreview_label3,
  "APPB2C.common.filePreview.pdfPreviewLink": APPB2C_common_filePreview_pdfPreviewLink3,
  "APPB2C.common.filePreview.status": APPB2C_common_filePreview_status3,
  "APPB2C.common.filePreview.statusName.approved": APPB2C_common_filePreview_statusName_approved3,
  "APPB2C.common.filePreview.statusName.deleted": APPB2C_common_filePreview_statusName_deleted3,
  "APPB2C.common.filePreview.statusName.pending": APPB2C_common_filePreview_statusName_pending3,
  "APPB2C.common.filePreview.statusName.rejected": APPB2C_common_filePreview_statusName_rejected3,
  "APPB2C.common.filePreview.statusName.reviewed": APPB2C_common_filePreview_statusName_reviewed3,
  "APPB2C.common.fileUpload.cta": APPB2C_common_fileUpload_cta3,
  "APPB2C.common.fileUpload.exceededSize": APPB2C_common_fileUpload_exceededSize3,
  "APPB2C.common.fileUpload.fileApproved": APPB2C_common_fileUpload_fileApproved3,
  "APPB2C.common.fileUpload.fileOptional": APPB2C_common_fileUpload_fileOptional3,
  "APPB2C.common.fileUpload.fileRequired": APPB2C_common_fileUpload_fileRequired3,
  "APPB2C.common.fileUpload.filesCount": APPB2C_common_fileUpload_filesCount3,
  "APPB2C.common.fileUpload.fileSize": APPB2C_common_fileUpload_fileSize3,
  "APPB2C.common.fileUpload.imgAlt": APPB2C_common_fileUpload_imgAlt3,
  "APPB2C.common.fileUpload.pdfIeMsg": APPB2C_common_fileUpload_pdfIeMsg3,
  "APPB2C.common.fileUpload.pdfPasswordMsg": APPB2C_common_fileUpload_pdfPasswordMsg3,
  "APPB2C.common.fileUpload.remove": APPB2C_common_fileUpload_remove3,
  "APPB2C.common.fileUpload.requirements": APPB2C_common_fileUpload_requirements3,
  "APPB2C.common.fileUpload.success": APPB2C_common_fileUpload_success3,
  "APPB2C.common.findTest.changeCity": APPB2C_common_findTest_changeCity3,
  "APPB2C.common.findTest.changeCountry": APPB2C_common_findTest_changeCountry3,
  "APPB2C.common.findTest.cta": APPB2C_common_findTest_cta3,
  "APPB2C.common.findTest.dates.clear": APPB2C_common_findTest_dates_clear3,
  "APPB2C.common.findTest.dates.from": APPB2C_common_findTest_dates_from3,
  "APPB2C.common.findTest.dates.noDatesSelected": APPB2C_common_findTest_dates_noDatesSelected3,
  "APPB2C.common.findTest.dates.noresults.details": APPB2C_common_findTest_dates_noresults_details3,
  "APPB2C.common.findTest.dates.noresults.title": APPB2C_common_findTest_dates_noresults_title3,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.cta": APPB2C_common_findTest_dates_noresultsMobileLocation_cta3,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.details": APPB2C_common_findTest_dates_noresultsMobileLocation_details3,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.title": APPB2C_common_findTest_dates_noresultsMobileLocation_title3,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.details": APPB2C_common_findTest_dates_noresultsSpecialReqs_details3,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.title": APPB2C_common_findTest_dates_noresultsSpecialReqs_title3,
  "APPB2C.common.findTest.dates.pleaseSelect": APPB2C_common_findTest_dates_pleaseSelect3,
  "APPB2C.common.findTest.dates.selected": APPB2C_common_findTest_dates_selected3,
  "APPB2C.common.findTest.dates.showAll": APPB2C_common_findTest_dates_showAll3,
  "APPB2C.common.findTest.dates.showDateRange": APPB2C_common_findTest_dates_showDateRange3,
  "APPB2C.common.findTest.dates.title": APPB2C_common_findTest_dates_title3,
  "APPB2C.common.findTest.dates.to": APPB2C_common_findTest_dates_to3,
  "APPB2C.common.findTest.examFormat.all": APPB2C_common_findTest_examFormat_all3,
  "APPB2C.common.findTest.examFormat.cd": APPB2C_common_findTest_examFormat_cd3,
  "APPB2C.common.findTest.examFormat.pb": APPB2C_common_findTest_examFormat_pb3,
  "APPB2C.common.findTest.examFormat.results": APPB2C_common_findTest_examFormat_results3,
  "APPB2C.common.findTest.examFormat.title": APPB2C_common_findTest_examFormat_title3,
  "APPB2C.common.findTest.iol.noLrwResults": APPB2C_common_findTest_iol_noLrwResults3,
  "APPB2C.common.findTest.iol.organisationCheck.check": APPB2C_common_findTest_iol_organisationCheck_check3,
  "APPB2C.common.findTest.iol.organisationCheck.desc": APPB2C_common_findTest_iol_organisationCheck_desc3,
  "APPB2C.common.findTest.iol.organisationCheck.title": APPB2C_common_findTest_iol_organisationCheck_title3,
  "APPB2C.common.findTest.iol.softwareCheck.check": APPB2C_common_findTest_iol_softwareCheck_check3,
  "APPB2C.common.findTest.iol.softwareCheck.desc1": APPB2C_common_findTest_iol_softwareCheck_desc13,
  "APPB2C.common.findTest.iol.softwareCheck.desc2": APPB2C_common_findTest_iol_softwareCheck_desc23,
  "APPB2C.common.findTest.iol.softwareCheck.link": APPB2C_common_findTest_iol_softwareCheck_link3,
  "APPB2C.common.findTest.iol.softwareCheck.title": APPB2C_common_findTest_iol_softwareCheck_title3,
  "APPB2C.common.findTest.langModal.choose": APPB2C_common_findTest_langModal_choose3,
  "APPB2C.common.findTest.langModal.chooseSingle": APPB2C_common_findTest_langModal_chooseSingle3,
  "APPB2C.common.findTest.langModal.continue": APPB2C_common_findTest_langModal_continue3,
  "APPB2C.common.findTest.langModal.title": APPB2C_common_findTest_langModal_title3,
  "APPB2C.common.findTest.multipleLocations": APPB2C_common_findTest_multipleLocations3,
  "APPB2C.common.findTest.osrInfo": APPB2C_common_findTest_osrInfo3,
  "APPB2C.common.findTest.osrInfoLink": APPB2C_common_findTest_osrInfoLink3,
  "APPB2C.common.findTest.residencyConfirmation.btn": APPB2C_common_findTest_residencyConfirmation_btn3,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.btn": APPB2C_common_findTest_residencyConfirmation_errorMsg_btn3,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line1": APPB2C_common_findTest_residencyConfirmation_errorMsg_line13,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line2": APPB2C_common_findTest_residencyConfirmation_errorMsg_line23,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.title": APPB2C_common_findTest_residencyConfirmation_errorMsg_title3,
  "APPB2C.common.findTest.residencyConfirmation.msg": APPB2C_common_findTest_residencyConfirmation_msg2,
  "APPB2C.common.findTest.residencyConfirmation.msg.line1": APPB2C_common_findTest_residencyConfirmation_msg_line13,
  "APPB2C.common.findTest.residencyConfirmation.msg.line2": APPB2C_common_findTest_residencyConfirmation_msg_line23,
  "APPB2C.common.findTest.residencyConfirmation.msg.line3": APPB2C_common_findTest_residencyConfirmation_msg_line33,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInCountryLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine23,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInLocationLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine23,
  "APPB2C.common.findTest.residencyConfirmation.selectDates": APPB2C_common_findTest_residencyConfirmation_selectDates3,
  "APPB2C.common.findTest.residencyConfirmation.title": APPB2C_common_findTest_residencyConfirmation_title3,
  "APPB2C.common.findTest.searchIn": APPB2C_common_findTest_searchIn3,
  "APPB2C.common.findTest.selectCountry.continue": APPB2C_common_findTest_selectCountry_continue3,
  "APPB2C.common.findTest.selectCountry.countryNotSupported": APPB2C_common_findTest_selectCountry_countryNotSupported3,
  "APPB2C.common.findTest.selectCountry.label": APPB2C_common_findTest_selectCountry_label3,
  "APPB2C.common.findTest.selectCountry.placeholder": APPB2C_common_findTest_selectCountry_placeholder3,
  "APPB2C.common.findTest.selectLocation.closestToMe": APPB2C_common_findTest_selectLocation_closestToMe3,
  "APPB2C.common.findTest.selectLocation.geolocationBlocked": APPB2C_common_findTest_selectLocation_geolocationBlocked3,
  "APPB2C.common.findTest.selectLocation.label": APPB2C_common_findTest_selectLocation_label3,
  "APPB2C.common.findTest.selectLocation.noExamForThisCountry": APPB2C_common_findTest_selectLocation_noExamForThisCountry3,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation3,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.cta": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta3,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option1": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option13,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option2": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option23,
  "APPB2C.common.findTest.specialReqs.findOutMore": APPB2C_common_findTest_specialReqs_findOutMore3,
  "APPB2C.common.findTest.specialReqs.list.subtitle": APPB2C_common_findTest_specialReqs_list_subtitle3,
  "APPB2C.common.findTest.specialReqs.list.title": APPB2C_common_findTest_specialReqs_list_title3,
  "APPB2C.common.findTest.specialReqs.needCheckbox": APPB2C_common_findTest_specialReqs_needCheckbox3,
  "APPB2C.common.findTest.specialReqs.other": APPB2C_common_findTest_specialReqs_other3,
  "APPB2C.common.findTest.specialReqs.pleaseNoteMessage": APPB2C_common_findTest_specialReqs_pleaseNoteMessage3,
  "APPB2C.common.findTest.subtitle": APPB2C_common_findTest_subtitle3,
  "APPB2C.common.findTest.title": APPB2C_common_findTest_title3,
  "APPB2C.common.findTest.uol.organisationCheck.check": APPB2C_common_findTest_uol_organisationCheck_check3,
  "APPB2C.common.findTest.uol.organisationCheck.desc": APPB2C_common_findTest_uol_organisationCheck_desc3,
  "APPB2C.common.findTest.uol.organisationCheck.title": APPB2C_common_findTest_uol_organisationCheck_title3,
  "APPB2C.common.findTest.uol.softwareCheck.check": APPB2C_common_findTest_uol_softwareCheck_check3,
  "APPB2C.common.findTest.uol.softwareCheck.desc1": APPB2C_common_findTest_uol_softwareCheck_desc13,
  "APPB2C.common.findTest.uol.softwareCheck.desc2": APPB2C_common_findTest_uol_softwareCheck_desc23,
  "APPB2C.common.findTest.uol.softwareCheck.link": APPB2C_common_findTest_uol_softwareCheck_link3,
  "APPB2C.common.findTest.uol.softwareCheck.title": APPB2C_common_findTest_uol_softwareCheck_title3,
  "APPB2C.common.footer.terms.accessibility": APPB2C_common_footer_terms_accessibility3,
  "APPB2C.common.footer.terms.copyrightLine1": APPB2C_common_footer_terms_copyrightLine13,
  "APPB2C.common.footer.terms.copyrightLine2": APPB2C_common_footer_terms_copyrightLine23,
  "APPB2C.common.footer.terms.dataProtection": APPB2C_common_footer_terms_dataProtection3,
  "APPB2C.common.footer.terms.privacyAndCookies": APPB2C_common_footer_terms_privacyAndCookies3,
  "APPB2C.common.footer.terms.termsOfUse": APPB2C_common_footer_terms_termsOfUse3,
  "APPB2C.common.gelRedirect.errorMessage": APPB2C_common_gelRedirect_errorMessage3,
  "APPB2C.common.gelRedirect.goBack": APPB2C_common_gelRedirect_goBack2,
  "APPB2C.common.gelRedirect.header": APPB2C_common_gelRedirect_header3,
  "APPB2C.common.gelRedirect.info": APPB2C_common_gelRedirect_info3,
  "APPB2C.common.general.next": APPB2C_common_general_next3,
  "APPB2C.common.general.previous": APPB2C_common_general_previous3,
  "APPB2C.common.general.regNotFound": APPB2C_common_general_regNotFound3,
  "APPB2C.common.general.remove": APPB2C_common_general_remove3,
  "APPB2C.common.general.steps": APPB2C_common_general_steps3,
  "APPB2C.common.general.stepsContinued": APPB2C_common_general_stepsContinued3,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabel": APPB2C_common_helpAndContact_actionSection_contactFormLabel3,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabelInCentre": APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre3,
  "APPB2C.common.helpAndContact.actionSection.contactFormLink": APPB2C_common_helpAndContact_actionSection_contactFormLink3,
  "APPB2C.common.helpAndContact.actionSection.faqLabel": APPB2C_common_helpAndContact_actionSection_faqLabel3,
  "APPB2C.common.helpAndContact.actionSection.faqLink": APPB2C_common_helpAndContact_actionSection_faqLink3,
  "APPB2C.common.helpAndContact.actionSection.icHeader": APPB2C_common_helpAndContact_actionSection_icHeader3,
  "APPB2C.common.helpAndContact.actionSection.iolHeader": APPB2C_common_helpAndContact_actionSection_iolHeader3,
  "APPB2C.common.helpAndContact.actionSection.launchChatBtn": APPB2C_common_helpAndContact_actionSection_launchChatBtn3,
  "APPB2C.common.helpAndContact.actionSection.launchChatLabel": APPB2C_common_helpAndContact_actionSection_launchChatLabel3,
  "APPB2C.common.helpAndContact.form.back": APPB2C_common_helpAndContact_form_back3,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLink": APPB2C_common_helpAndContact_form_complaint_moreInfoLink3,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLinkTitle": APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle3,
  "APPB2C.common.helpAndContact.form.complaint.radio.complaint": APPB2C_common_helpAndContact_form_complaint_radio_complaint3,
  "APPB2C.common.helpAndContact.form.complaint.radio.other": APPB2C_common_helpAndContact_form_complaint_radio_other3,
  "APPB2C.common.helpAndContact.form.complaint.title": APPB2C_common_helpAndContact_form_complaint_title3,
  "APPB2C.common.helpAndContact.form.label": APPB2C_common_helpAndContact_form_label3,
  "APPB2C.common.helpAndContact.form.legal": APPB2C_common_helpAndContact_form_legal3,
  "APPB2C.common.helpAndContact.form.response": APPB2C_common_helpAndContact_form_response3,
  "APPB2C.common.helpAndContact.form.submit": APPB2C_common_helpAndContact_form_submit3,
  "APPB2C.common.helpAndContact.form.thanks.back": APPB2C_common_helpAndContact_form_thanks_back3,
  "APPB2C.common.helpAndContact.form.thanks.banner": APPB2C_common_helpAndContact_form_thanks_banner3,
  "APPB2C.common.helpAndContact.form.thanks.info": APPB2C_common_helpAndContact_form_thanks_info3,
  "APPB2C.common.helpAndContact.form.thanks.title": APPB2C_common_helpAndContact_form_thanks_title3,
  "APPB2C.common.helpAndContact.form.title": APPB2C_common_helpAndContact_form_title3,
  "APPB2C.common.helpAndContact.form.validation": APPB2C_common_helpAndContact_form_validation3,
  "APPB2C.common.helpAndContact.icContent.commonQuestionsHeader": APPB2C_common_helpAndContact_icContent_commonQuestionsHeader3,
  "APPB2C.common.helpAndContact.icContent.emailLabel": APPB2C_common_helpAndContact_icContent_emailLabel3,
  "APPB2C.common.helpAndContact.icContent.header": APPB2C_common_helpAndContact_icContent_header3,
  "APPB2C.common.helpAndContact.icContent.phoneLabel": APPB2C_common_helpAndContact_icContent_phoneLabel3,
  "APPB2C.common.helpAndContact.icContent.referenceReminder": APPB2C_common_helpAndContact_icContent_referenceReminder3,
  "APPB2C.common.helpAndContact.ieltsReference": APPB2C_common_helpAndContact_ieltsReference3,
  "APPB2C.common.helpAndContact.noTests.bookNewBtn": APPB2C_common_helpAndContact_noTests_bookNewBtn3,
  "APPB2C.common.helpAndContact.noTests.contactLink": APPB2C_common_helpAndContact_noTests_contactLink3,
  "APPB2C.common.helpAndContact.noTests.header": APPB2C_common_helpAndContact_noTests_header3,
  "APPB2C.common.helpAndContact.selectExamLabel": APPB2C_common_helpAndContact_selectExamLabel3,
  "APPB2C.common.helpAndContact.title": APPB2C_common_helpAndContact_title3,
  "APPB2C.common.invitation.modal.header": APPB2C_common_invitation_modal_header3,
  "APPB2C.common.invitation.modal.invalid": APPB2C_common_invitation_modal_invalid3,
  "APPB2C.common.modals.newTermsAndConditions.checkboxNote": APPB2C_common_modals_newTermsAndConditions_checkboxNote3,
  "APPB2C.common.modals.newTermsAndConditions.checkboxTC": APPB2C_common_modals_newTermsAndConditions_checkboxTC3,
  "APPB2C.common.modals.newTermsAndConditions.cta": APPB2C_common_modals_newTermsAndConditions_cta3,
  "APPB2C.common.modals.newTermsAndConditions.error": APPB2C_common_modals_newTermsAndConditions_error3,
  "APPB2C.common.modals.newTermsAndConditions.note": APPB2C_common_modals_newTermsAndConditions_note3,
  "APPB2C.common.modals.newTermsAndConditions.title": APPB2C_common_modals_newTermsAndConditions_title3,
  "APPB2C.common.modals.newTermsAndConditions.trigger": APPB2C_common_modals_newTermsAndConditions_trigger3,
  "APPB2C.common.nav.bookNewTest": APPB2C_common_nav_bookNewTest3,
  "APPB2C.common.nav.chooseLanguage": APPB2C_common_nav_chooseLanguage3,
  "APPB2C.common.nav.hello": APPB2C_common_nav_hello3,
  "APPB2C.common.nav.helpAndContact": APPB2C_common_nav_helpAndContact3,
  "APPB2C.common.nav.login": APPB2C_common_nav_login3,
  "APPB2C.common.nav.logout": APPB2C_common_nav_logout3,
  "APPB2C.common.nav.logoutModal.cancel": APPB2C_common_nav_logoutModal_cancel2,
  "APPB2C.common.nav.logoutModal.confirm": APPB2C_common_nav_logoutModal_confirm3,
  "APPB2C.common.nav.logoutModal.message": APPB2C_common_nav_logoutModal_message3,
  "APPB2C.common.nav.logoutModal.question": APPB2C_common_nav_logoutModal_question3,
  "APPB2C.common.nav.logoutModal.title": APPB2C_common_nav_logoutModal_title3,
  "APPB2C.common.nav.myAccount": APPB2C_common_nav_myAccount3,
  "APPB2C.common.nav.preparation": APPB2C_common_nav_preparation3,
  "APPB2C.common.nav.resultsTests": APPB2C_common_nav_resultsTests3,
  "APPB2C.common.nav.ttp": APPB2C_common_nav_ttp3,
  "APPB2C.common.preparation.noTests.contactLink": APPB2C_common_preparation_noTests_contactLink3,
  "APPB2C.common.preparation.noTests.header": APPB2C_common_preparation_noTests_header3,
  "APPB2C.common.preparation.selectExamLabel": APPB2C_common_preparation_selectExamLabel3,
  "APPB2C.common.preparation.title": APPB2C_common_preparation_title3,
  "APPB2C.common.preparation.viewTestBtn": APPB2C_common_preparation_viewTestBtn3,
  "APPB2C.common.recreateReg.errMsg": APPB2C_common_recreateReg_errMsg3,
  "APPB2C.common.recreateReg.errTitle": APPB2C_common_recreateReg_errTitle3,
  "APPB2C.common.recreateReg.progress": APPB2C_common_recreateReg_progress3,
  "APPB2C.common.regHeader.reference": APPB2C_common_regHeader_reference3,
  "APPB2C.common.regHeader.trfLoading": APPB2C_common_regHeader_trfLoading3,
  "APPB2C.common.regHeader.trfNumber": APPB2C_common_regHeader_trfNumber3,
  "APPB2C.common.regInProgressModal.continue": APPB2C_common_regInProgressModal_continue3,
  "APPB2C.common.regInProgressModal.message1": APPB2C_common_regInProgressModal_message13,
  "APPB2C.common.regInProgressModal.message2": APPB2C_common_regInProgressModal_message23,
  "APPB2C.common.regInProgressModal.restart": APPB2C_common_regInProgressModal_restart3,
  "APPB2C.common.regInProgressModal.title": APPB2C_common_regInProgressModal_title3,
  "APPB2C.common.selfServices.requested.cancellation": APPB2C_common_selfServices_requested_cancellation3,
  "APPB2C.common.selfServices.requested.transfer": APPB2C_common_selfServices_requested_transfer3,
  "APPB2C.common.specialReqs.AMANU": APPB2C_common_specialReqs_AMANU3,
  "APPB2C.common.specialReqs.ANTHR": APPB2C_common_specialReqs_ANTHR3,
  "APPB2C.common.specialReqs.BTP": APPB2C_common_specialReqs_BTP3,
  "APPB2C.common.specialReqs.BWP": APPB2C_common_specialReqs_BWP3,
  "APPB2C.common.specialReqs.EPTP": APPB2C_common_specialReqs_EPTP3,
  "APPB2C.common.specialReqs.ET": APPB2C_common_specialReqs_ET3,
  "APPB2C.common.specialReqs.LRTV": APPB2C_common_specialReqs_LRTV3,
  "APPB2C.common.specialReqs.SAE": APPB2C_common_specialReqs_SAE3,
  "APPB2C.common.specialReqs.VAS": APPB2C_common_specialReqs_VAS3,
  "APPB2C.common.specialReqs.WP": APPB2C_common_specialReqs_WP3,
  "APPB2C.common.startPage.book": APPB2C_common_startPage_book3,
  "APPB2C.common.startPage.candidates.nowAdult": APPB2C_common_startPage_candidates_nowAdult3,
  "APPB2C.common.startPage.candidates.showingFor": APPB2C_common_startPage_candidates_showingFor3,
  "APPB2C.common.startPage.common.cancelled": APPB2C_common_startPage_common_cancelled3,
  "APPB2C.common.startPage.description.ac": APPB2C_common_startPage_description_ac3,
  "APPB2C.common.startPage.description.gt": APPB2C_common_startPage_description_gt3,
  "APPB2C.common.startPage.description.ukvi": APPB2C_common_startPage_description_ukvi3,
  "APPB2C.common.startPage.endNote": APPB2C_common_startPage_endNote3,
  "APPB2C.common.startPage.error.body": APPB2C_common_startPage_error_body3,
  "APPB2C.common.startPage.error.cta": APPB2C_common_startPage_error_cta3,
  "APPB2C.common.startPage.error.title": APPB2C_common_startPage_error_title3,
  "APPB2C.common.startPage.hero.cta": APPB2C_common_startPage_hero_cta3,
  "APPB2C.common.startPage.hero.subtitle": APPB2C_common_startPage_hero_subtitle3,
  "APPB2C.common.startPage.hero.title": APPB2C_common_startPage_hero_title3,
  "APPB2C.common.startPage.ielts": APPB2C_common_startPage_ielts3,
  "APPB2C.common.startPage.ieltsUkvi": APPB2C_common_startPage_ieltsUkvi3,
  "APPB2C.common.startPage.missing.cancel": APPB2C_common_startPage_missing_cancel3,
  "APPB2C.common.startPage.missing.marketing.cta": APPB2C_common_startPage_missing_marketing_cta3,
  "APPB2C.common.startPage.missing.marketing.notes.full1": APPB2C_common_startPage_missing_marketing_notes_full13,
  "APPB2C.common.startPage.missing.marketing.notes.full2": APPB2C_common_startPage_missing_marketing_notes_full23,
  "APPB2C.common.startPage.missing.marketing.notes.full3": APPB2C_common_startPage_missing_marketing_notes_full33,
  "APPB2C.common.startPage.missing.marketing.notes.link": APPB2C_common_startPage_missing_marketing_notes_link3,
  "APPB2C.common.startPage.missing.marketing.notes.main": APPB2C_common_startPage_missing_marketing_notes_main3,
  "APPB2C.common.startPage.missing.marketing.opts.BcAndThirdParties": APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties3,
  "APPB2C.common.startPage.missing.marketing.opts.BcOnly": APPB2C_common_startPage_missing_marketing_opts_BcOnly3,
  "APPB2C.common.startPage.missing.marketing.opts.DoNotSend": APPB2C_common_startPage_missing_marketing_opts_DoNotSend3,
  "APPB2C.common.startPage.missing.marketing.title": APPB2C_common_startPage_missing_marketing_title3,
  "APPB2C.common.startPage.missing.ttData.cta": APPB2C_common_startPage_missing_ttData_cta3,
  "APPB2C.common.startPage.missing.ttData.dob": APPB2C_common_startPage_missing_ttData_dob3,
  "APPB2C.common.startPage.missing.ttData.firstName": APPB2C_common_startPage_missing_ttData_firstName3,
  "APPB2C.common.startPage.missing.ttData.lastName": APPB2C_common_startPage_missing_ttData_lastName3,
  "APPB2C.common.startPage.missing.ttData.line1": APPB2C_common_startPage_missing_ttData_line13,
  "APPB2C.common.startPage.missing.ttData.line2": APPB2C_common_startPage_missing_ttData_line23,
  "APPB2C.common.startPage.missing.ttData.line3": APPB2C_common_startPage_missing_ttData_line33,
  "APPB2C.common.startPage.missing.ttData.noLastName": APPB2C_common_startPage_missing_ttData_noLastName3,
  "APPB2C.common.startPage.missing.ttData.title": APPB2C_common_startPage_missing_ttData_title3,
  "APPB2C.common.startPage.options.ac": APPB2C_common_startPage_options_ac3,
  "APPB2C.common.startPage.options.gt": APPB2C_common_startPage_options_gt3,
  "APPB2C.common.startPage.options.ukvi": APPB2C_common_startPage_options_ukvi3,
  "APPB2C.common.startPage.past.cd": APPB2C_common_startPage_past_cd3,
  "APPB2C.common.startPage.past.empty": APPB2C_common_startPage_past_empty3,
  "APPB2C.common.startPage.past.osr.newScore": APPB2C_common_startPage_past_osr_newScore3,
  "APPB2C.common.startPage.past.osr.note.awaiting": APPB2C_common_startPage_past_osr_note_awaiting3,
  "APPB2C.common.startPage.past.osr.note.confirmed": APPB2C_common_startPage_past_osr_note_confirmed3,
  "APPB2C.common.startPage.past.osr.note.hasResults": APPB2C_common_startPage_past_osr_note_hasResults3,
  "APPB2C.common.startPage.past.osr.originalScore": APPB2C_common_startPage_past_osr_originalScore3,
  "APPB2C.common.startPage.past.osr.viewRetakeLink": APPB2C_common_startPage_past_osr_viewRetakeLink3,
  "APPB2C.common.startPage.past.pb": APPB2C_common_startPage_past_pb3,
  "APPB2C.common.startPage.past.rebook": APPB2C_common_startPage_past_rebook3,
  "APPB2C.common.startPage.past.score": APPB2C_common_startPage_past_score3,
  "APPB2C.common.startPage.past.scoreLifeSkills": APPB2C_common_startPage_past_scoreLifeSkills3,
  "APPB2C.common.startPage.past.title": APPB2C_common_startPage_past_title3,
  "APPB2C.common.startPage.past.upcomingResults": APPB2C_common_startPage_past_upcomingResults3,
  "APPB2C.common.startPage.readTnCs": APPB2C_common_startPage_readTnCs3,
  "APPB2C.common.startPage.recent.cta": APPB2C_common_startPage_recent_cta3,
  "APPB2C.common.startPage.recent.title": APPB2C_common_startPage_recent_title3,
  "APPB2C.common.startPage.subtitle": APPB2C_common_startPage_subtitle3,
  "APPB2C.common.startPage.title": APPB2C_common_startPage_title3,
  "APPB2C.common.startPage.tncs": APPB2C_common_startPage_tncs3,
  "APPB2C.common.startPage.upcoming.cd": APPB2C_common_startPage_upcoming_cd3,
  "APPB2C.common.startPage.upcoming.empty": APPB2C_common_startPage_upcoming_empty3,
  "APPB2C.common.startPage.upcoming.lifeSkills": APPB2C_common_startPage_upcoming_lifeSkills3,
  "APPB2C.common.startPage.upcoming.live": APPB2C_common_startPage_upcoming_live3,
  "APPB2C.common.startPage.upcoming.manage": APPB2C_common_startPage_upcoming_manage3,
  "APPB2C.common.startPage.upcoming.missing.childConsent": APPB2C_common_startPage_upcoming_missing_childConsent3,
  "APPB2C.common.startPage.upcoming.missing.id": APPB2C_common_startPage_upcoming_missing_id3,
  "APPB2C.common.startPage.upcoming.missing.medical": APPB2C_common_startPage_upcoming_missing_medical3,
  "APPB2C.common.startPage.upcoming.missing.payment": APPB2C_common_startPage_upcoming_missing_payment3,
  "APPB2C.common.startPage.upcoming.noSpeaking": APPB2C_common_startPage_upcoming_noSpeaking3,
  "APPB2C.common.startPage.upcoming.noTime": APPB2C_common_startPage_upcoming_noTime3,
  "APPB2C.common.startPage.upcoming.osr.note": APPB2C_common_startPage_upcoming_osr_note3,
  "APPB2C.common.startPage.upcoming.pastPaymentDeadline": APPB2C_common_startPage_upcoming_pastPaymentDeadline3,
  "APPB2C.common.startPage.upcoming.pay": APPB2C_common_startPage_upcoming_pay3,
  "APPB2C.common.startPage.upcoming.pb": APPB2C_common_startPage_upcoming_pb3,
  "APPB2C.common.startPage.upcoming.prepare": APPB2C_common_startPage_upcoming_prepare3,
  "APPB2C.common.startPage.upcoming.remote": APPB2C_common_startPage_upcoming_remote3,
  "APPB2C.common.startPage.upcoming.title": APPB2C_common_startPage_upcoming_title3,
  "APPB2C.common.startPage.whichIELTS": APPB2C_common_startPage_whichIELTS3,
  "APPB2C.common.terms.downloadInfo": APPB2C_common_terms_downloadInfo3,
  "APPB2C.common.terms.downloadTitle": APPB2C_common_terms_downloadTitle3,
  "APPB2C.common.terms.error": APPB2C_common_terms_error3,
  "APPB2C.common.terms.title": APPB2C_common_terms_title3,
  "APPB2C.common.testDetails.changeTest": APPB2C_common_testDetails_changeTest3,
  "APPB2C.common.testDetails.changeWrittenTest": APPB2C_common_testDetails_changeWrittenTest3,
  "APPB2C.common.testDetails.completeYourBooking": APPB2C_common_testDetails_completeYourBooking3,
  "APPB2C.common.testDetails.mapModal.close": APPB2C_common_testDetails_mapModal_close3,
  "APPB2C.common.testDetails.mapModal.title": APPB2C_common_testDetails_mapModal_title3,
  "APPB2C.common.testDetails.modifyNote.confirm": APPB2C_common_testDetails_modifyNote_confirm3,
  "APPB2C.common.testDetails.modifyNote.note": APPB2C_common_testDetails_modifyNote_note3,
  "APPB2C.common.testDetails.modifyNote.pleaseNote": APPB2C_common_testDetails_modifyNote_pleaseNote3,
  "APPB2C.common.testDetails.title": APPB2C_common_testDetails_title3,
  "APPB2C.common.testDetails.yourSpeakingTest": APPB2C_common_testDetails_yourSpeakingTest3,
  "APPB2C.common.testDetails.yourWrittenTest": APPB2C_common_testDetails_yourWrittenTest3,
  "APPB2C.common.transfer.agreement": APPB2C_common_transfer_agreement3,
  "APPB2C.common.transfer.contactNote": APPB2C_common_transfer_contactNote3,
  "APPB2C.common.transfer.contactNoteIol": APPB2C_common_transfer_contactNoteIol3,
  "APPB2C.common.transfer.cta": APPB2C_common_transfer_cta3,
  "APPB2C.common.transfer.form.freeform": APPB2C_common_transfer_form_freeform3,
  "APPB2C.common.transfer.form.reasons.1": APPB2C_common_transfer_form_reasons_13,
  "APPB2C.common.transfer.form.reasons.2": APPB2C_common_transfer_form_reasons_23,
  "APPB2C.common.transfer.form.reasons.3": APPB2C_common_transfer_form_reasons_33,
  "APPB2C.common.transfer.form.reasons.4": APPB2C_common_transfer_form_reasons_43,
  "APPB2C.common.transfer.form.reasons.choose": APPB2C_common_transfer_form_reasons_choose3,
  "APPB2C.common.transfer.form.reasons.other": APPB2C_common_transfer_form_reasons_other3,
  "APPB2C.common.transfer.form.reasons.title": APPB2C_common_transfer_form_reasons_title3,
  "APPB2C.common.transfer.goBack": APPB2C_common_transfer_goBack3,
  "APPB2C.common.transfer.notes.1": APPB2C_common_transfer_notes_13,
  "APPB2C.common.transfer.notes.2": APPB2C_common_transfer_notes_23,
  "APPB2C.common.transfer.notes.3": APPB2C_common_transfer_notes_33,
  "APPB2C.common.transfer.notes.4": APPB2C_common_transfer_notes_43,
  "APPB2C.common.transfer.notesIol.1": APPB2C_common_transfer_notesIol_13,
  "APPB2C.common.transfer.notesIol.2": APPB2C_common_transfer_notesIol_23,
  "APPB2C.common.transfer.requestValidation": APPB2C_common_transfer_requestValidation3,
  "APPB2C.common.transfer.selfService.availableDates.title": APPB2C_common_transfer_selfService_availableDates_title3,
  "APPB2C.common.transfer.selfService.chooseDates.title": APPB2C_common_transfer_selfService_chooseDates_title3,
  "APPB2C.common.transfer.selfService.review.confirm.proceedBtn": APPB2C_common_transfer_selfService_review_confirm_proceedBtn3,
  "APPB2C.common.transfer.selfService.review.confirm.title": APPB2C_common_transfer_selfService_review_confirm_title3,
  "APPB2C.common.transfer.selfService.review.errors.noDistricts": APPB2C_common_transfer_selfService_review_errors_noDistricts3,
  "APPB2C.common.transfer.selfService.review.errors.noLrwSlots": APPB2C_common_transfer_selfService_review_errors_noLrwSlots3,
  "APPB2C.common.transfer.selfService.review.errors.transferDisabled": APPB2C_common_transfer_selfService_review_errors_transferDisabled3,
  "APPB2C.common.transfer.selfService.review.test.changeLrw": APPB2C_common_transfer_selfService_review_test_changeLrw3,
  "APPB2C.common.transfer.selfService.review.tncs.agreement": APPB2C_common_transfer_selfService_review_tncs_agreement3,
  "APPB2C.common.transfer.title": APPB2C_common_transfer_title3,
  "APPB2C.common.underConstruction.message": APPB2C_common_underConstruction_message3,
  "APPB2C.common.underConstruction.title": APPB2C_common_underConstruction_title3,
  "APPB2C.common.unknownCountry.cta": APPB2C_common_unknownCountry_cta3,
  "APPB2C.common.unknownCountry.header": APPB2C_common_unknownCountry_header3,
  "APPB2C.common.unknownCountry.msg1": APPB2C_common_unknownCountry_msg13,
  "APPB2C.common.unknownCountry.msg2": APPB2C_common_unknownCountry_msg23,
  "APPB2C.common.unknownCountry.title": APPB2C_common_unknownCountry_title3,
  "APPB2C.common.unpaidErrorBar.paymentExpired": APPB2C_common_unpaidErrorBar_paymentExpired3,
  "APPB2C.common.updateBar.cancelled": APPB2C_common_updateBar_cancelled3,
  "APPB2C.common.updateBar.cancelPending": APPB2C_common_updateBar_cancelPending3,
  "APPB2C.common.updateBar.cancelRejected": APPB2C_common_updateBar_cancelRejected3,
  "APPB2C.common.updateBar.close": APPB2C_common_updateBar_close3,
  "APPB2C.common.updateBar.details": APPB2C_common_updateBar_details3,
  "APPB2C.common.updateBar.eorCompleted": APPB2C_common_updateBar_eorCompleted3,
  "APPB2C.common.updateBar.eorCompleted.scoreDecreased": APPB2C_common_updateBar_eorCompleted_scoreDecreased3,
  "APPB2C.common.updateBar.eorCompleted.scoreIncreased": APPB2C_common_updateBar_eorCompleted_scoreIncreased3,
  "APPB2C.common.updateBar.eorCompleted.scoreUnchanged": APPB2C_common_updateBar_eorCompleted_scoreUnchanged3,
  "APPB2C.common.updateBar.eorPending": APPB2C_common_updateBar_eorPending3,
  "APPB2C.common.updateBar.eorPendingAgentPays": APPB2C_common_updateBar_eorPendingAgentPays3,
  "APPB2C.common.updateBar.eorRefunded": APPB2C_common_updateBar_eorRefunded3,
  "APPB2C.common.updateBar.eorRefundPending": APPB2C_common_updateBar_eorRefundPending3,
  "APPB2C.common.updateBar.eorRejected": APPB2C_common_updateBar_eorRejected3,
  "APPB2C.common.updateBar.eorRejected.details": APPB2C_common_updateBar_eorRejected_details2,
  "APPB2C.common.updateBar.eorRejected.overallBand": APPB2C_common_updateBar_eorRejected_overallBand3,
  "APPB2C.common.updateBar.hasResults": APPB2C_common_updateBar_hasResults3,
  "APPB2C.common.updateBar.termsUpdated": APPB2C_common_updateBar_termsUpdated3,
  "APPB2C.common.updateBar.transactionRefundRequestAccepted": APPB2C_common_updateBar_transactionRefundRequestAccepted3,
  "APPB2C.common.updateBar.transactionRefundRequestPending": APPB2C_common_updateBar_transactionRefundRequestPending3,
  "APPB2C.common.updateBar.transactionRefundRequestRejected": APPB2C_common_updateBar_transactionRefundRequestRejected3,
  "APPB2C.common.updateBar.transferCancelled": APPB2C_common_updateBar_transferCancelled3,
  "APPB2C.common.updateBar.transferPending": APPB2C_common_updateBar_transferPending3,
  "APPB2C.common.updateBar.transferPendingCmds": APPB2C_common_updateBar_transferPendingCmds3,
  "APPB2C.common.updateBar.transferred": APPB2C_common_updateBar_transferred3,
  "APPB2C.common.updateBar.transferredCmds": APPB2C_common_updateBar_transferredCmds3,
  "APPB2C.registration.bookingDetails.payment": APPB2C_registration_bookingDetails_payment3,
  "APPB2C.registration.bookingDetails.results": APPB2C_registration_bookingDetails_results3,
  "APPB2C.registration.bookingDetails.seePayment": APPB2C_registration_bookingDetails_seePayment3,
  "APPB2C.registration.bookingDetails.seeTestTaker": APPB2C_registration_bookingDetails_seeTestTaker3,
  "APPB2C.registration.bookingDetails.whatNext": APPB2C_registration_bookingDetails_whatNext3,
  "APPB2C.registration.date.check.back": APPB2C_registration_date_check_back3,
  "APPB2C.registration.date.check.beforeTestDate": APPB2C_registration_date_check_beforeTestDate3,
  "APPB2C.registration.date.check.intro": APPB2C_registration_date_check_intro3,
  "APPB2C.registration.date.check.onTestDate": APPB2C_registration_date_check_onTestDate3,
  "APPB2C.registration.date.check.step1.body": APPB2C_registration_date_check_step1_body3,
  "APPB2C.registration.date.check.step1.link": APPB2C_registration_date_check_step1_link3,
  "APPB2C.registration.date.check.step1.title": APPB2C_registration_date_check_step1_title3,
  "APPB2C.registration.date.check.step2.body": APPB2C_registration_date_check_step2_body3,
  "APPB2C.registration.date.check.step2.link": APPB2C_registration_date_check_step2_link3,
  "APPB2C.registration.date.check.step2.title": APPB2C_registration_date_check_step2_title3,
  "APPB2C.registration.date.check.step3.body1": APPB2C_registration_date_check_step3_body13,
  "APPB2C.registration.date.check.step3.body2": APPB2C_registration_date_check_step3_body23,
  "APPB2C.registration.date.check.step3.noDetailsBody": APPB2C_registration_date_check_step3_noDetailsBody3,
  "APPB2C.registration.date.check.step3.noDetailsTitle": APPB2C_registration_date_check_step3_noDetailsTitle3,
  "APPB2C.registration.date.check.step3.noSpeakingPinBody": APPB2C_registration_date_check_step3_noSpeakingPinBody3,
  "APPB2C.registration.date.check.step3.noSpeakingPinTitle": APPB2C_registration_date_check_step3_noSpeakingPinTitle3,
  "APPB2C.registration.date.check.step3.password": APPB2C_registration_date_check_step3_password3,
  "APPB2C.registration.date.check.step3.speakingPin": APPB2C_registration_date_check_step3_speakingPin3,
  "APPB2C.registration.date.check.step3.step1.body": APPB2C_registration_date_check_step3_step1_body3,
  "APPB2C.registration.date.check.step3.step1.title": APPB2C_registration_date_check_step3_step1_title3,
  "APPB2C.registration.date.check.step3.step2.body": APPB2C_registration_date_check_step3_step2_body3,
  "APPB2C.registration.date.check.step3.step2.title": APPB2C_registration_date_check_step3_step2_title3,
  "APPB2C.registration.date.check.step3.step3.body": APPB2C_registration_date_check_step3_step3_body3,
  "APPB2C.registration.date.check.step3.step3.title": APPB2C_registration_date_check_step3_step3_title3,
  "APPB2C.registration.date.check.step3.username": APPB2C_registration_date_check_step3_username3,
  "APPB2C.registration.date.check.step3.writtenPin": APPB2C_registration_date_check_step3_writtenPin3,
  "APPB2C.registration.date.zone.country": APPB2C_registration_date_zone_country3,
  "APPB2C.registration.date.zone.header": APPB2C_registration_date_zone_header3,
  "APPB2C.registration.date.zone.name": APPB2C_registration_date_zone_name3,
  "APPB2C.registration.date.zone.time": APPB2C_registration_date_zone_time3,
  "APPB2C.registration.dateLocation.changeLinkIol": APPB2C_registration_dateLocation_changeLinkIol3,
  "APPB2C.registration.dateLocation.changeLrwLink": APPB2C_registration_dateLocation_changeLrwLink3,
  "APPB2C.registration.dateLocation.changeSpeakingLink": APPB2C_registration_dateLocation_changeSpeakingLink3,
  "APPB2C.registration.dateLocation.location": APPB2C_registration_dateLocation_location3,
  "APPB2C.registration.dateLocation.manage.cancel": APPB2C_registration_dateLocation_manage_cancel3,
  "APPB2C.registration.dateLocation.manage.proceed": APPB2C_registration_dateLocation_manage_proceed2,
  "APPB2C.registration.dateLocation.manage.title": APPB2C_registration_dateLocation_manage_title3,
  "APPB2C.registration.dateLocation.manage.transferSpeaking": APPB2C_registration_dateLocation_manage_transferSpeaking3,
  "APPB2C.registration.dateLocation.manage.transferWhole": APPB2C_registration_dateLocation_manage_transferWhole3,
  "APPB2C.registration.dateLocation.speakingTransfer.keepSpeakingTest": APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest3,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody1": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody13,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody2": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody23,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsHeader": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader3,
  "APPB2C.registration.dateLocation.speakingTransfer.requestTransfer": APPB2C_registration_dateLocation_speakingTransfer_requestTransfer3,
  "APPB2C.registration.incorrectOrganisation.homeLink": APPB2C_registration_incorrectOrganisation_homeLink3,
  "APPB2C.registration.incorrectOrganisation.line1": APPB2C_registration_incorrectOrganisation_line13,
  "APPB2C.registration.incorrectOrganisation.line2": APPB2C_registration_incorrectOrganisation_line23,
  "APPB2C.registration.payments.actions.editProofCTA": APPB2C_registration_payments_actions_editProofCTA3,
  "APPB2C.registration.payments.actions.header": APPB2C_registration_payments_actions_header3,
  "APPB2C.registration.payments.actions.paymentInProgressInfo": APPB2C_registration_payments_actions_paymentInProgressInfo3,
  "APPB2C.registration.payments.actions.payOfflineCTA": APPB2C_registration_payments_actions_payOfflineCTA3,
  "APPB2C.registration.payments.actions.payOnlineCTA": APPB2C_registration_payments_actions_payOnlineCTA3,
  "APPB2C.registration.payments.actions.subHeader": APPB2C_registration_payments_actions_subHeader3,
  "APPB2C.registration.payments.actions.uploadProofCTA": APPB2C_registration_payments_actions_uploadProofCTA3,
  "APPB2C.registration.payments.awaitingPayment": APPB2C_registration_payments_awaitingPayment3,
  "APPB2C.registration.payments.back": APPB2C_registration_payments_back3,
  "APPB2C.registration.payments.completed": APPB2C_registration_payments_completed3,
  "APPB2C.registration.payments.expired": APPB2C_registration_payments_expired3,
  "APPB2C.registration.payments.finishPayment.error.cta": APPB2C_registration_payments_finishPayment_error_cta3,
  "APPB2C.registration.payments.finishPayment.error.messageLine1": APPB2C_registration_payments_finishPayment_error_messageLine13,
  "APPB2C.registration.payments.finishPayment.error.messageLine2": APPB2C_registration_payments_finishPayment_error_messageLine23,
  "APPB2C.registration.payments.finishPayment.error.title": APPB2C_registration_payments_finishPayment_error_title3,
  "APPB2C.registration.payments.finishPayment.failure.alert": APPB2C_registration_payments_finishPayment_failure_alert3,
  "APPB2C.registration.payments.finishPayment.failure.cta": APPB2C_registration_payments_finishPayment_failure_cta3,
  "APPB2C.registration.payments.finishPayment.failure.message": APPB2C_registration_payments_finishPayment_failure_message3,
  "APPB2C.registration.payments.finishPayment.failure.title": APPB2C_registration_payments_finishPayment_failure_title3,
  "APPB2C.registration.payments.finishPayment.pleaseWait": APPB2C_registration_payments_finishPayment_pleaseWait3,
  "APPB2C.registration.payments.finishPayment.processing": APPB2C_registration_payments_finishPayment_processing3,
  "APPB2C.registration.payments.finishPayment.success.alert": APPB2C_registration_payments_finishPayment_success_alert3,
  "APPB2C.registration.payments.finishPayment.success.cta": APPB2C_registration_payments_finishPayment_success_cta3,
  "APPB2C.registration.payments.finishPayment.success.message": APPB2C_registration_payments_finishPayment_success_message3,
  "APPB2C.registration.payments.finishPayment.success.title": APPB2C_registration_payments_finishPayment_success_title3,
  "APPB2C.registration.payments.offlineDetails.accountName": APPB2C_registration_payments_offlineDetails_accountName3,
  "APPB2C.registration.payments.offlineDetails.accountNumber": APPB2C_registration_payments_offlineDetails_accountNumber3,
  "APPB2C.registration.payments.offlineDetails.payBy": APPB2C_registration_payments_offlineDetails_payBy3,
  "APPB2C.registration.payments.offlineDetails.sortCode": APPB2C_registration_payments_offlineDetails_sortCode3,
  "APPB2C.registration.payments.paidByAgent": APPB2C_registration_payments_paidByAgent3,
  "APPB2C.registration.payments.proofPreview.fileAlt": APPB2C_registration_payments_proofPreview_fileAlt3,
  "APPB2C.registration.payments.proofPreview.proofLabel": APPB2C_registration_payments_proofPreview_proofLabel3,
  "APPB2C.registration.payments.proofUpload.cta": APPB2C_registration_payments_proofUpload_cta3,
  "APPB2C.registration.payments.proofUpload.info": APPB2C_registration_payments_proofUpload_info3,
  "APPB2C.registration.payments.proofUpload.intro": APPB2C_registration_payments_proofUpload_intro3,
  "APPB2C.registration.payments.proofUpload.rules.intro": APPB2C_registration_payments_proofUpload_rules_intro3,
  "APPB2C.registration.payments.proofUpload.rules.rule1": APPB2C_registration_payments_proofUpload_rules_rule13,
  "APPB2C.registration.payments.proofUpload.rules.rule2": APPB2C_registration_payments_proofUpload_rules_rule23,
  "APPB2C.registration.payments.proofUpload.rules.rule3": APPB2C_registration_payments_proofUpload_rules_rule33,
  "APPB2C.registration.payments.proofUpload.rules.rule4": APPB2C_registration_payments_proofUpload_rules_rule43,
  "APPB2C.registration.payments.proofUpload.rules.rule5": APPB2C_registration_payments_proofUpload_rules_rule53,
  "APPB2C.registration.payments.transaction.cancelledSuffix": APPB2C_registration_payments_transaction_cancelledSuffix3,
  "APPB2C.registration.payments.transaction.deadlineWarning.default": APPB2C_registration_payments_transaction_deadlineWarning_default3,
  "APPB2C.registration.payments.transaction.deadlineWarning.eor": APPB2C_registration_payments_transaction_deadlineWarning_eor3,
  "APPB2C.registration.payments.transaction.deadlineWarning.registration": APPB2C_registration_payments_transaction_deadlineWarning_registration3,
  "APPB2C.registration.payments.transaction.description.appeal": APPB2C_registration_payments_transaction_description_appeal3,
  "APPB2C.registration.payments.transaction.description.eor": APPB2C_registration_payments_transaction_description_eor3,
  "APPB2C.registration.payments.transaction.description.registration": APPB2C_registration_payments_transaction_description_registration3,
  "APPB2C.registration.payments.transaction.description.transfer": APPB2C_registration_payments_transaction_description_transfer3,
  "APPB2C.registration.payments.transaction.feeLabel": APPB2C_registration_payments_transaction_feeLabel3,
  "APPB2C.registration.payments.transaction.header": APPB2C_registration_payments_transaction_header3,
  "APPB2C.registration.payments.transaction.hideDetails": APPB2C_registration_payments_transaction_hideDetails3,
  "APPB2C.registration.payments.transaction.outdatedTermsLabel": APPB2C_registration_payments_transaction_outdatedTermsLabel3,
  "APPB2C.registration.payments.transaction.pastDeadline": APPB2C_registration_payments_transaction_pastDeadline3,
  "APPB2C.registration.payments.transaction.pastDeadlineContactUs": APPB2C_registration_payments_transaction_pastDeadlineContactUs3,
  "APPB2C.registration.payments.transaction.pastDeadlineEmail": APPB2C_registration_payments_transaction_pastDeadlineEmail3,
  "APPB2C.registration.payments.transaction.pastDeadlinePhone": APPB2C_registration_payments_transaction_pastDeadlinePhone3,
  "APPB2C.registration.payments.transaction.promoCode": APPB2C_registration_payments_transaction_promoCode3,
  "APPB2C.registration.payments.transaction.receipt": APPB2C_registration_payments_transaction_receipt3,
  "APPB2C.registration.payments.transaction.receiptCta": APPB2C_registration_payments_transaction_receiptCta3,
  "APPB2C.registration.payments.transaction.referenceLabel": APPB2C_registration_payments_transaction_referenceLabel3,
  "APPB2C.registration.payments.transaction.showDetails": APPB2C_registration_payments_transaction_showDetails3,
  "APPB2C.registration.payments.transaction.taxLabel": APPB2C_registration_payments_transaction_taxLabel3,
  "APPB2C.registration.payments.transaction.termsLabel": APPB2C_registration_payments_transaction_termsLabel3,
  "APPB2C.registration.payments.transaction.totalLabel": APPB2C_registration_payments_transaction_totalLabel3,
  "APPB2C.registration.payments.transaction.underReviewSuffix": APPB2C_registration_payments_transaction_underReviewSuffix3,
  "APPB2C.registration.payments.transaction.unpaidSuffix": APPB2C_registration_payments_transaction_unpaidSuffix3,
  "APPB2C.registration.payments.unpaid": APPB2C_registration_payments_unpaid3,
  "APPB2C.registration.prepare.a1.cta": APPB2C_registration_prepare_a1_cta3,
  "APPB2C.registration.prepare.a1.header": APPB2C_registration_prepare_a1_header3,
  "APPB2C.registration.prepare.a2.cta": APPB2C_registration_prepare_a2_cta3,
  "APPB2C.registration.prepare.a2.header": APPB2C_registration_prepare_a2_header3,
  "APPB2C.registration.prepare.b1.cta": APPB2C_registration_prepare_b1_cta3,
  "APPB2C.registration.prepare.b1.header": APPB2C_registration_prepare_b1_header3,
  "APPB2C.registration.prepare.blog.body.cta": APPB2C_registration_prepare_blog_body_cta3,
  "APPB2C.registration.prepare.blog.body.line1": APPB2C_registration_prepare_blog_body_line13,
  "APPB2C.registration.prepare.blog.header": APPB2C_registration_prepare_blog_header3,
  "APPB2C.registration.prepare.cdFamiliarTest.body.cta": APPB2C_registration_prepare_cdFamiliarTest_body_cta3,
  "APPB2C.registration.prepare.cdFamiliarTest.body.line1": APPB2C_registration_prepare_cdFamiliarTest_body_line13,
  "APPB2C.registration.prepare.cdFamiliarTest.header": APPB2C_registration_prepare_cdFamiliarTest_header3,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.cta": APPB2C_registration_prepare_cdFamiliarVideos_body_cta3,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.line1": APPB2C_registration_prepare_cdFamiliarVideos_body_line13,
  "APPB2C.registration.prepare.cdFamiliarVideos.header": APPB2C_registration_prepare_cdFamiliarVideos_header3,
  "APPB2C.registration.prepare.cdTests.body.cta": APPB2C_registration_prepare_cdTests_body_cta3,
  "APPB2C.registration.prepare.cdTests.body.line1": APPB2C_registration_prepare_cdTests_body_line13,
  "APPB2C.registration.prepare.cdTests.header": APPB2C_registration_prepare_cdTests_header3,
  "APPB2C.registration.prepare.cdTipsVideos.body.cta": APPB2C_registration_prepare_cdTipsVideos_body_cta3,
  "APPB2C.registration.prepare.cdTipsVideos.body.line1": APPB2C_registration_prepare_cdTipsVideos_body_line13,
  "APPB2C.registration.prepare.cdTipsVideos.header": APPB2C_registration_prepare_cdTipsVideos_header3,
  "APPB2C.registration.prepare.facebook.body.cta": APPB2C_registration_prepare_facebook_body_cta3,
  "APPB2C.registration.prepare.facebook.body.line1": APPB2C_registration_prepare_facebook_body_line13,
  "APPB2C.registration.prepare.facebook.header": APPB2C_registration_prepare_facebook_header3,
  "APPB2C.registration.prepare.gel.disabledMsg": APPB2C_registration_prepare_gel_disabledMsg3,
  "APPB2C.registration.prepare.gel.ielts.body.cta": APPB2C_registration_prepare_gel_ielts_body_cta3,
  "APPB2C.registration.prepare.gel.ielts.body.line1": APPB2C_registration_prepare_gel_ielts_body_line13,
  "APPB2C.registration.prepare.gel.ielts.header": APPB2C_registration_prepare_gel_ielts_header3,
  "APPB2C.registration.prepare.gel.ukvi.body.cta": APPB2C_registration_prepare_gel_ukvi_body_cta2,
  "APPB2C.registration.prepare.gel.ukvi.body.line1": APPB2C_registration_prepare_gel_ukvi_body_line13,
  "APPB2C.registration.prepare.gel.ukvi.header": APPB2C_registration_prepare_gel_ukvi_header3,
  "APPB2C.registration.prepare.header": APPB2C_registration_prepare_header3,
  "APPB2C.registration.prepare.ieltsReady.subTitle": APPB2C_registration_prepare_ieltsReady_subTitle3,
  "APPB2C.registration.prepare.ieltsReady.title": APPB2C_registration_prepare_ieltsReady_title3,
  "APPB2C.registration.prepare.ieltsReadyMember.body": APPB2C_registration_prepare_ieltsReadyMember_body3,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestBtn": APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn3,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestInfo": APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo3,
  "APPB2C.registration.prepare.ieltsReadyMember.freePrepLink": APPB2C_registration_prepare_ieltsReadyMember_freePrepLink3,
  "APPB2C.registration.prepare.ieltsReadyMember.header": APPB2C_registration_prepare_ieltsReadyMember_header3,
  "APPB2C.registration.prepare.ieltsReadyMember.label": APPB2C_registration_prepare_ieltsReadyMember_label3,
  "APPB2C.registration.prepare.ieltsReadyMember.link": APPB2C_registration_prepare_ieltsReadyMember_link3,
  "APPB2C.registration.prepare.ieltsReadyMember.payBtn": APPB2C_registration_prepare_ieltsReadyMember_payBtn3,
  "APPB2C.registration.prepare.ieltsReadyMember.unpaidTestInfo": APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo3,
  "APPB2C.registration.prepare.intro": APPB2C_registration_prepare_intro3,
  "APPB2C.registration.prepare.introLifeSkills": APPB2C_registration_prepare_introLifeSkills3,
  "APPB2C.registration.prepare.onlineCourse.body.cta": APPB2C_registration_prepare_onlineCourse_body_cta3,
  "APPB2C.registration.prepare.onlineCourse.body.line1": APPB2C_registration_prepare_onlineCourse_body_line13,
  "APPB2C.registration.prepare.onlineCourse.header": APPB2C_registration_prepare_onlineCourse_header3,
  "APPB2C.registration.prepare.pbPractice.body.cta": APPB2C_registration_prepare_pbPractice_body_cta3,
  "APPB2C.registration.prepare.pbPractice.body.line1": APPB2C_registration_prepare_pbPractice_body_line13,
  "APPB2C.registration.prepare.pbPractice.header": APPB2C_registration_prepare_pbPractice_header3,
  "APPB2C.registration.prepare.practice.body.cta": APPB2C_registration_prepare_practice_body_cta3,
  "APPB2C.registration.prepare.practice.body.line1": APPB2C_registration_prepare_practice_body_line13,
  "APPB2C.registration.prepare.practice.header": APPB2C_registration_prepare_practice_header3,
  "APPB2C.registration.prepare.prepApp.body.cta": APPB2C_registration_prepare_prepApp_body_cta3,
  "APPB2C.registration.prepare.prepApp.body.line1": APPB2C_registration_prepare_prepApp_body_line13,
  "APPB2C.registration.prepare.prepApp.header": APPB2C_registration_prepare_prepApp_header3,
  "APPB2C.registration.prepare.prepApp.ukvi.cta1": APPB2C_registration_prepare_prepApp_ukvi_cta13,
  "APPB2C.registration.prepare.prepApp.ukvi.cta2": APPB2C_registration_prepare_prepApp_ukvi_cta23,
  "APPB2C.registration.prepare.prepApp.ukvi.line1": APPB2C_registration_prepare_prepApp_ukvi_line13,
  "APPB2C.registration.prepare.prepApp.ukvi.line2": APPB2C_registration_prepare_prepApp_ukvi_line23,
  "APPB2C.registration.prepare.prepVideos.body.cta": APPB2C_registration_prepare_prepVideos_body_cta3,
  "APPB2C.registration.prepare.prepVideos.body.line1": APPB2C_registration_prepare_prepVideos_body_line13,
  "APPB2C.registration.prepare.prepVideos.header": APPB2C_registration_prepare_prepVideos_header3,
  "APPB2C.registration.prepare.reading.body.cta": APPB2C_registration_prepare_reading_body_cta3,
  "APPB2C.registration.prepare.reading.body.line1": APPB2C_registration_prepare_reading_body_line13,
  "APPB2C.registration.prepare.reading.header": APPB2C_registration_prepare_reading_header3,
  "APPB2C.registration.prepare.rteLastMinute.body.cta": APPB2C_registration_prepare_rteLastMinute_body_cta3,
  "APPB2C.registration.prepare.rteLastMinute.body.item1": APPB2C_registration_prepare_rteLastMinute_body_item13,
  "APPB2C.registration.prepare.rteLastMinute.body.item2": APPB2C_registration_prepare_rteLastMinute_body_item23,
  "APPB2C.registration.prepare.rteLastMinute.body.item3": APPB2C_registration_prepare_rteLastMinute_body_item33,
  "APPB2C.registration.prepare.rteLastMinute.body.line1": APPB2C_registration_prepare_rteLastMinute_body_line13,
  "APPB2C.registration.prepare.rteLastMinute.header": APPB2C_registration_prepare_rteLastMinute_header3,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.cta": APPB2C_registration_prepare_rteLastMinute_ukvi_cta3,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.line1": APPB2C_registration_prepare_rteLastMinute_ukvi_line13,
  "APPB2C.registration.prepare.rteTestDrive.body.cta": APPB2C_registration_prepare_rteTestDrive_body_cta3,
  "APPB2C.registration.prepare.rteTestDrive.body.item1": APPB2C_registration_prepare_rteTestDrive_body_item13,
  "APPB2C.registration.prepare.rteTestDrive.body.item2": APPB2C_registration_prepare_rteTestDrive_body_item23,
  "APPB2C.registration.prepare.rteTestDrive.body.item3": APPB2C_registration_prepare_rteTestDrive_body_item33,
  "APPB2C.registration.prepare.rteTestDrive.body.line1": APPB2C_registration_prepare_rteTestDrive_body_line13,
  "APPB2C.registration.prepare.rteTestDrive.body.line2": APPB2C_registration_prepare_rteTestDrive_body_line23,
  "APPB2C.registration.prepare.rteTestDrive.header": APPB2C_registration_prepare_rteTestDrive_header3,
  "APPB2C.registration.prepare.speaking.body.cta": APPB2C_registration_prepare_speaking_body_cta3,
  "APPB2C.registration.prepare.speaking.body.line1": APPB2C_registration_prepare_speaking_body_line13,
  "APPB2C.registration.prepare.speaking.header": APPB2C_registration_prepare_speaking_header3,
  "APPB2C.registration.prepare.tutor.body.cta": APPB2C_registration_prepare_tutor_body_cta3,
  "APPB2C.registration.prepare.tutor.body.line1": APPB2C_registration_prepare_tutor_body_line13,
  "APPB2C.registration.prepare.tutor.body.line2": APPB2C_registration_prepare_tutor_body_line23,
  "APPB2C.registration.prepare.tutor.header": APPB2C_registration_prepare_tutor_header3,
  "APPB2C.registration.prepare.webinars.body.cta": APPB2C_registration_prepare_webinars_body_cta3,
  "APPB2C.registration.prepare.webinars.body.item1": APPB2C_registration_prepare_webinars_body_item13,
  "APPB2C.registration.prepare.webinars.body.item2": APPB2C_registration_prepare_webinars_body_item23,
  "APPB2C.registration.prepare.webinars.body.item3": APPB2C_registration_prepare_webinars_body_item33,
  "APPB2C.registration.prepare.webinars.body.line1": APPB2C_registration_prepare_webinars_body_line13,
  "APPB2C.registration.prepare.webinars.header": APPB2C_registration_prepare_webinars_header3,
  "APPB2C.registration.results.bandScore.osrBooked": APPB2C_registration_results_bandScore_osrBooked3,
  "APPB2C.registration.results.bandScore.osrMarked": APPB2C_registration_results_bandScore_osrMarked3,
  "APPB2C.registration.results.eor.acknowledgement.backButton": APPB2C_registration_results_eor_acknowledgement_backButton3,
  "APPB2C.registration.results.eor.acknowledgement.components": APPB2C_registration_results_eor_acknowledgement_components3,
  "APPB2C.registration.results.eor.acknowledgement.confirmation": APPB2C_registration_results_eor_acknowledgement_confirmation3,
  "APPB2C.registration.results.eor.acknowledgement.fee": APPB2C_registration_results_eor_acknowledgement_fee3,
  "APPB2C.registration.results.eor.acknowledgement.info": APPB2C_registration_results_eor_acknowledgement_info3,
  "APPB2C.registration.results.eor.acknowledgement.noteTitle": APPB2C_registration_results_eor_acknowledgement_noteTitle3,
  "APPB2C.registration.results.eor.acknowledgement.payment": APPB2C_registration_results_eor_acknowledgement_payment3,
  "APPB2C.registration.results.eor.acknowledgement.paymentButton": APPB2C_registration_results_eor_acknowledgement_paymentButton3,
  "APPB2C.registration.results.eor.acknowledgement.paymentNote": APPB2C_registration_results_eor_acknowledgement_paymentNote3,
  "APPB2C.registration.results.eor.acknowledgement.reference": APPB2C_registration_results_eor_acknowledgement_reference3,
  "APPB2C.registration.results.eor.acknowledgement.success": APPB2C_registration_results_eor_acknowledgement_success3,
  "APPB2C.registration.results.eor.acknowledgement.tax": APPB2C_registration_results_eor_acknowledgement_tax3,
  "APPB2C.registration.results.eor.acknowledgement.terms": APPB2C_registration_results_eor_acknowledgement_terms3,
  "APPB2C.registration.results.eor.acknowledgement.title": APPB2C_registration_results_eor_acknowledgement_title3,
  "APPB2C.registration.results.eor.acknowledgement.total": APPB2C_registration_results_eor_acknowledgement_total3,
  "APPB2C.registration.results.eor.postDeadline.back": APPB2C_registration_results_eor_postDeadline_back3,
  "APPB2C.registration.results.eor.postDeadline.email": APPB2C_registration_results_eor_postDeadline_email3,
  "APPB2C.registration.results.eor.postDeadline.help": APPB2C_registration_results_eor_postDeadline_help3,
  "APPB2C.registration.results.eor.postDeadline.iol.launchChatLine": APPB2C_registration_results_eor_postDeadline_iol_launchChatLine3,
  "APPB2C.registration.results.eor.postDeadline.iol.line3": APPB2C_registration_results_eor_postDeadline_iol_line33,
  "APPB2C.registration.results.eor.postDeadline.line1": APPB2C_registration_results_eor_postDeadline_line13,
  "APPB2C.registration.results.eor.postDeadline.line2": APPB2C_registration_results_eor_postDeadline_line23,
  "APPB2C.registration.results.eor.postDeadline.line3": APPB2C_registration_results_eor_postDeadline_line33,
  "APPB2C.registration.results.eor.postDeadline.phone": APPB2C_registration_results_eor_postDeadline_phone3,
  "APPB2C.registration.results.eor.preDeadline.back": APPB2C_registration_results_eor_preDeadline_back3,
  "APPB2C.registration.results.eor.preDeadline.error.explanationMaxLength": APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength3,
  "APPB2C.registration.results.eor.preDeadline.error.missingTrfNumber": APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber3,
  "APPB2C.registration.results.eor.preDeadline.error.wrongTrfNumber": APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber3,
  "APPB2C.registration.results.eor.preDeadline.evidence": APPB2C_registration_results_eor_preDeadline_evidence3,
  "APPB2C.registration.results.eor.preDeadline.evidenceRequired": APPB2C_registration_results_eor_preDeadline_evidenceRequired3,
  "APPB2C.registration.results.eor.preDeadline.explanation": APPB2C_registration_results_eor_preDeadline_explanation3,
  "APPB2C.registration.results.eor.preDeadline.explanationRequired": APPB2C_registration_results_eor_preDeadline_explanationRequired3,
  "APPB2C.registration.results.eor.preDeadline.fee": APPB2C_registration_results_eor_preDeadline_fee3,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line1": APPB2C_registration_results_eor_preDeadline_iolNote_line12,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line2": APPB2C_registration_results_eor_preDeadline_iolNote_line23,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line3": APPB2C_registration_results_eor_preDeadline_iolNote_line33,
  "APPB2C.registration.results.eor.preDeadline.legal": APPB2C_registration_results_eor_preDeadline_legal3,
  "APPB2C.registration.results.eor.preDeadline.legalNote": APPB2C_registration_results_eor_preDeadline_legalNote3,
  "APPB2C.registration.results.eor.preDeadline.line1": APPB2C_registration_results_eor_preDeadline_line13,
  "APPB2C.registration.results.eor.preDeadline.line2": APPB2C_registration_results_eor_preDeadline_line23,
  "APPB2C.registration.results.eor.preDeadline.maxScore": APPB2C_registration_results_eor_preDeadline_maxScore3,
  "APPB2C.registration.results.eor.preDeadline.note": APPB2C_registration_results_eor_preDeadline_note3,
  "APPB2C.registration.results.eor.preDeadline.noteTitle": APPB2C_registration_results_eor_preDeadline_noteTitle3,
  "APPB2C.registration.results.eor.preDeadline.orderAcknowledgementLabel": APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel3,
  "APPB2C.registration.results.eor.preDeadline.paymentCta": APPB2C_registration_results_eor_preDeadline_paymentCta3,
  "APPB2C.registration.results.eor.preDeadline.paymentTitle": APPB2C_registration_results_eor_preDeadline_paymentTitle3,
  "APPB2C.registration.results.eor.preDeadline.payOfflineCTA": APPB2C_registration_results_eor_preDeadline_payOfflineCTA3,
  "APPB2C.registration.results.eor.preDeadline.payOnlineCTA": APPB2C_registration_results_eor_preDeadline_payOnlineCTA3,
  "APPB2C.registration.results.eor.preDeadline.scoresRequired": APPB2C_registration_results_eor_preDeadline_scoresRequired3,
  "APPB2C.registration.results.eor.preDeadline.scoresTitle": APPB2C_registration_results_eor_preDeadline_scoresTitle3,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr3,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Listening": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening3,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Reading": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading3,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Speaking": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking3,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Writing": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing3,
  "APPB2C.registration.results.eor.preDeadline.tax": APPB2C_registration_results_eor_preDeadline_tax3,
  "APPB2C.registration.results.eor.preDeadline.trfNumberLabel": APPB2C_registration_results_eor_preDeadline_trfNumberLabel3,
  "APPB2C.registration.results.eor.preDeadline.trfNumberSubnote": APPB2C_registration_results_eor_preDeadline_trfNumberSubnote3,
  "APPB2C.registration.results.eor.preDeadline.trfNumberValidation": APPB2C_registration_results_eor_preDeadline_trfNumberValidation3,
  "APPB2C.registration.results.eor.title": APPB2C_registration_results_eor_title3,
  "APPB2C.registration.results.eor.titlePayment": APPB2C_registration_results_eor_titlePayment3,
  "APPB2C.registration.results.hasResults.bandScore.decrease": APPB2C_registration_results_hasResults_bandScore_decrease3,
  "APPB2C.registration.results.hasResults.bandScore.increase": APPB2C_registration_results_hasResults_bandScore_increase3,
  "APPB2C.registration.results.hasResults.bandScore.unchanged": APPB2C_registration_results_hasResults_bandScore_unchanged3,
  "APPB2C.registration.results.hasResults.eorAgentInfo": APPB2C_registration_results_hasResults_eorAgentInfo2,
  "APPB2C.registration.results.hasResults.eorLetter": APPB2C_registration_results_hasResults_eorLetter3,
  "APPB2C.registration.results.hasResults.eorLine1": APPB2C_registration_results_hasResults_eorLine13,
  "APPB2C.registration.results.hasResults.eorLine2": APPB2C_registration_results_hasResults_eorLine23,
  "APPB2C.registration.results.hasResults.eorLink": APPB2C_registration_results_hasResults_eorLink3,
  "APPB2C.registration.results.hasResults.eTrfCta": APPB2C_registration_results_hasResults_eTrfCta2,
  "APPB2C.registration.results.hasResults.explanations.heading": APPB2C_registration_results_hasResults_explanations_heading3,
  "APPB2C.registration.results.hasResults.explanations.hideButton": APPB2C_registration_results_hasResults_explanations_hideButton2,
  "APPB2C.registration.results.hasResults.explanations.improve": APPB2C_registration_results_hasResults_explanations_improve3,
  "APPB2C.registration.results.hasResults.explanations.showButton": APPB2C_registration_results_hasResults_explanations_showButton2,
  "APPB2C.registration.results.hasResults.lifeSkills.failed": APPB2C_registration_results_hasResults_lifeSkills_failed3,
  "APPB2C.registration.results.hasResults.lifeSkills.level": APPB2C_registration_results_hasResults_lifeSkills_level3,
  "APPB2C.registration.results.hasResults.lifeSkills.note": APPB2C_registration_results_hasResults_lifeSkills_note3,
  "APPB2C.registration.results.hasResults.lifeSkills.passed": APPB2C_registration_results_hasResults_lifeSkills_passed3,
  "APPB2C.registration.results.hasResults.lifeSkills.result": APPB2C_registration_results_hasResults_lifeSkills_result3,
  "APPB2C.registration.results.hasResults.osr.bandScore.decrease": APPB2C_registration_results_hasResults_osr_bandScore_decrease2,
  "APPB2C.registration.results.hasResults.osr.bandScore.increase": APPB2C_registration_results_hasResults_osr_bandScore_increase2,
  "APPB2C.registration.results.hasResults.osr.bandScore.unchanged": APPB2C_registration_results_hasResults_osr_bandScore_unchanged2,
  "APPB2C.registration.results.hasResults.osr.previousResults": APPB2C_registration_results_hasResults_osr_previousResults3,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreDecreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased3,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreIncreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased3,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreUnchanged": APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged3,
  "APPB2C.registration.results.hasResults.osr.updateBar.title": APPB2C_registration_results_hasResults_osr_updateBar_title3,
  "APPB2C.registration.results.hasResults.osrLink": APPB2C_registration_results_hasResults_osrLink2,
  "APPB2C.registration.results.hasResults.posting.oneCopyWarning": APPB2C_registration_results_hasResults_posting_oneCopyWarning3,
  "APPB2C.registration.results.hasResults.posting.postedOn": APPB2C_registration_results_hasResults_posting_postedOn3,
  "APPB2C.registration.results.hasResults.posting.postedTo": APPB2C_registration_results_hasResults_posting_postedTo3,
  "APPB2C.registration.results.hasResults.posting.willPostOn": APPB2C_registration_results_hasResults_posting_willPostOn3,
  "APPB2C.registration.results.hasResults.posting.willPostTo": APPB2C_registration_results_hasResults_posting_willPostTo3,
  "APPB2C.registration.results.hasResults.rankNameConsonant": APPB2C_registration_results_hasResults_rankNameConsonant3,
  "APPB2C.registration.results.hasResults.rankNameVowel": APPB2C_registration_results_hasResults_rankNameVowel3,
  "APPB2C.registration.results.hasResults.scoreImprove.btn": APPB2C_registration_results_hasResults_scoreImprove_btn3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.backBtn": APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.description": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.title": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.description": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.title": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.description": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.title": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title3,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.subtitle": APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle3,
  "APPB2C.registration.results.hasResults.scoreImprove.subtitle": APPB2C_registration_results_hasResults_scoreImprove_subtitle3,
  "APPB2C.registration.results.hasResults.scoreImprove.title": APPB2C_registration_results_hasResults_scoreImprove_title3,
  "APPB2C.registration.results.hasResults.scores.label": APPB2C_registration_results_hasResults_scores_label3,
  "APPB2C.registration.results.hasResults.scores.Listening": APPB2C_registration_results_hasResults_scores_Listening3,
  "APPB2C.registration.results.hasResults.scores.Overall": APPB2C_registration_results_hasResults_scores_Overall3,
  "APPB2C.registration.results.hasResults.scores.Reading": APPB2C_registration_results_hasResults_scores_Reading3,
  "APPB2C.registration.results.hasResults.scores.Speaking": APPB2C_registration_results_hasResults_scores_Speaking3,
  "APPB2C.registration.results.hasResults.scores.Writing": APPB2C_registration_results_hasResults_scores_Writing3,
  "APPB2C.registration.results.hasResults.share.applyBoard": APPB2C_registration_results_hasResults_share_applyBoard3,
  "APPB2C.registration.results.hasResults.share.RecognisingOrgsLink": APPB2C_registration_results_hasResults_share_RecognisingOrgsLink3,
  "APPB2C.registration.results.hasResults.share.subtitle": APPB2C_registration_results_hasResults_share_subtitle3,
  "APPB2C.registration.results.hasResults.share.title": APPB2C_registration_results_hasResults_share_title3,
  "APPB2C.registration.results.hasResults.share.trfCta": APPB2C_registration_results_hasResults_share_trfCta3,
  "APPB2C.registration.results.hasResults.viewExplanation": APPB2C_registration_results_hasResults_viewExplanation3,
  "APPB2C.registration.results.noResults.cmds.title": APPB2C_registration_results_noResults_cmds_title3,
  "APPB2C.registration.results.noResults.note.title": APPB2C_registration_results_noResults_note_title3,
  "APPB2C.registration.results.noResults.osr.booked": APPB2C_registration_results_noResults_osr_booked3,
  "APPB2C.registration.results.noResults.osr.previousScores": APPB2C_registration_results_noResults_osr_previousScores3,
  "APPB2C.registration.results.noResults.title": APPB2C_registration_results_noResults_title3,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote1": APPB2C_registration_results_organisations_availableReceivingOrgsNote13,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote2": APPB2C_registration_results_organisations_availableReceivingOrgsNote23,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote3": APPB2C_registration_results_organisations_availableReceivingOrgsNote32,
  "APPB2C.registration.results.organisations.chosenOrganisationsHeader": APPB2C_registration_results_organisations_chosenOrganisationsHeader3,
  "APPB2C.registration.results.organisations.editRo.addAnother": APPB2C_registration_results_organisations_editRo_addAnother3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.addNew": APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.address": APPB2C_registration_results_organisations_editRo_chooseOrganisations_address3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.country": APPB2C_registration_results_organisations_editRo_chooseOrganisations_country3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryAll": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryOptional": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.file": APPB2C_registration_results_organisations_editRo_chooseOrganisations_file3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.org": APPB2C_registration_results_organisations_editRo_chooseOrganisations_org3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.organisation": APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.person": APPB2C_registration_results_organisations_editRo_chooseOrganisations_person3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.postCode": APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByCountry": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry3,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByOrg": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg3,
  "APPB2C.registration.results.organisations.editRo.error.caseNumberMaxLength": APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength3,
  "APPB2C.registration.results.organisations.editRo.error.contactPersonMaxLength": APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength3,
  "APPB2C.registration.results.organisations.editRo.error.minScoreMissing": APPB2C_registration_results_organisations_editRo_error_minScoreMissing3,
  "APPB2C.registration.results.organisations.editRo.error.missingAddressLine1": APPB2C_registration_results_organisations_editRo_error_missingAddressLine13,
  "APPB2C.registration.results.organisations.editRo.error.missingOrgName": APPB2C_registration_results_organisations_editRo_error_missingOrgName3,
  "APPB2C.registration.results.organisations.editRo.error.orgNameMaxLength": APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength3,
  "APPB2C.registration.results.organisations.editRo.iol.addCta": APPB2C_registration_results_organisations_editRo_iol_addCta3,
  "APPB2C.registration.results.organisations.editRo.iol.address": APPB2C_registration_results_organisations_editRo_iol_address3,
  "APPB2C.registration.results.organisations.editRo.iol.cancelAdd": APPB2C_registration_results_organisations_editRo_iol_cancelAdd2,
  "APPB2C.registration.results.organisations.editRo.iol.cancelEdit": APPB2C_registration_results_organisations_editRo_iol_cancelEdit3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.clear": APPB2C_registration_results_organisations_editRo_iol_conditions_clear3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.header": APPB2C_registration_results_organisations_editRo_iol_conditions_header3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.mandatoryPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.no": APPB2C_registration_results_organisations_editRo_iol_conditions_no3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note1": APPB2C_registration_results_organisations_editRo_iol_conditions_note13,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note2": APPB2C_registration_results_organisations_editRo_iol_conditions_note23,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note3": APPB2C_registration_results_organisations_editRo_iol_conditions_note33,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.optionalPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.subHeader": APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader3,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.yes": APPB2C_registration_results_organisations_editRo_iol_conditions_yes3,
  "APPB2C.registration.results.organisations.editRo.iol.copied": APPB2C_registration_results_organisations_editRo_iol_copied3,
  "APPB2C.registration.results.organisations.editRo.iol.copy": APPB2C_registration_results_organisations_editRo_iol_copy3,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.edelivery": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery3,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.eResults": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults3,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.note": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note3,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.postal": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal3,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.trf": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf3,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note1": APPB2C_registration_results_organisations_editRo_iol_delivery_note13,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2": APPB2C_registration_results_organisations_editRo_iol_delivery_note23,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2.results": APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results3,
  "APPB2C.registration.results.organisations.editRo.iol.newOrgTitle": APPB2C_registration_results_organisations_editRo_iol_newOrgTitle3,
  "APPB2C.registration.results.organisations.editRo.iol.noResults": APPB2C_registration_results_organisations_editRo_iol_noResults3,
  "APPB2C.registration.results.organisations.editRo.iol.removeLink": APPB2C_registration_results_organisations_editRo_iol_removeLink3,
  "APPB2C.registration.results.organisations.editRo.iol.saveCta": APPB2C_registration_results_organisations_editRo_iol_saveCta3,
  "APPB2C.registration.results.organisations.editRo.iol.validation.overallScore": APPB2C_registration_results_organisations_editRo_iol_validation_overallScore3,
  "APPB2C.registration.results.organisations.editRo.iol.validation.selectedOrganisation": APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation3,
  "APPB2C.registration.results.organisations.iol.chooseCta": APPB2C_registration_results_organisations_iol_chooseCta3,
  "APPB2C.registration.results.organisations.iol.delivery.auto.header": APPB2C_registration_results_organisations_iol_delivery_auto_header3,
  "APPB2C.registration.results.organisations.iol.delivery.auto.note": APPB2C_registration_results_organisations_iol_delivery_auto_note3,
  "APPB2C.registration.results.organisations.iol.delivery.auto.notSentNote": APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote3,
  "APPB2C.registration.results.organisations.iol.delivery.auto.sentNote": APPB2C_registration_results_organisations_iol_delivery_auto_sentNote3,
  "APPB2C.registration.results.organisations.iol.delivery.postal.header": APPB2C_registration_results_organisations_iol_delivery_postal_header3,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note1": APPB2C_registration_results_organisations_iol_delivery_postal_note13,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2": APPB2C_registration_results_organisations_iol_delivery_postal_note23,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2.results": APPB2C_registration_results_organisations_iol_delivery_postal_note2_results3,
  "APPB2C.registration.results.organisations.iol.edit": APPB2C_registration_results_organisations_iol_edit3,
  "APPB2C.registration.results.organisations.iol.error": APPB2C_registration_results_organisations_iol_error3,
  "APPB2C.registration.results.organisations.iol.generalCondition": APPB2C_registration_results_organisations_iol_generalCondition3,
  "APPB2C.registration.results.organisations.iol.noConditions": APPB2C_registration_results_organisations_iol_noConditions3,
  "APPB2C.registration.results.organisations.iol.notChosen": APPB2C_registration_results_organisations_iol_notChosen3,
  "APPB2C.registration.results.organisations.iol.preciseCondition": APPB2C_registration_results_organisations_iol_preciseCondition3,
  "APPB2C.registration.results.organisations.iol.roAccept.link": APPB2C_registration_results_organisations_iol_roAccept_link3,
  "APPB2C.registration.results.organisations.iol.roAccept.message": APPB2C_registration_results_organisations_iol_roAccept_message3,
  "APPB2C.registration.results.organisations.iol.roAccept.title": APPB2C_registration_results_organisations_iol_roAccept_title3,
  "APPB2C.registration.results.organisations.iol.sendAnyway": APPB2C_registration_results_organisations_iol_sendAnyway3,
  "APPB2C.registration.results.organisations.list.item1": APPB2C_registration_results_organisations_list_item13,
  "APPB2C.registration.results.organisations.list.item2": APPB2C_registration_results_organisations_list_item23,
  "APPB2C.registration.results.organisations.list.item3": APPB2C_registration_results_organisations_list_item33,
  "APPB2C.registration.results.organisations.list.item4": APPB2C_registration_results_organisations_list_item43,
  "APPB2C.registration.results.organisations.mod.delivery.auto.header": APPB2C_registration_results_organisations_mod_delivery_auto_header3,
  "APPB2C.registration.results.organisations.mod.delivery.auto.note": APPB2C_registration_results_organisations_mod_delivery_auto_note3,
  "APPB2C.registration.results.organisations.mod.delivery.auto.notSentNote": APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote3,
  "APPB2C.registration.results.organisations.mod.delivery.auto.sentNote": APPB2C_registration_results_organisations_mod_delivery_auto_sentNote3,
  "APPB2C.registration.results.organisations.mod.delivery.postal.header": APPB2C_registration_results_organisations_mod_delivery_postal_header3,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note1": APPB2C_registration_results_organisations_mod_delivery_postal_note13,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note2": APPB2C_registration_results_organisations_mod_delivery_postal_note23,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note3": APPB2C_registration_results_organisations_mod_delivery_postal_note33,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note4": APPB2C_registration_results_organisations_mod_delivery_postal_note43,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note5": APPB2C_registration_results_organisations_mod_delivery_postal_note53,
  "APPB2C.registration.results.organisations.mod.delivery.postal.notSentNote": APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote3,
  "APPB2C.registration.results.organisations.mod.delivery.postal.sentNote": APPB2C_registration_results_organisations_mod_delivery_postal_sentNote3,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount3,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount_other": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other3,
  "APPB2C.registration.results.organisations.mod.howTo.item1": APPB2C_registration_results_organisations_mod_howTo_item13,
  "APPB2C.registration.results.organisations.mod.howTo.item2": APPB2C_registration_results_organisations_mod_howTo_item23,
  "APPB2C.registration.results.organisations.mod.howTo.line1": APPB2C_registration_results_organisations_mod_howTo_line13,
  "APPB2C.registration.results.organisations.mod.howTo.title": APPB2C_registration_results_organisations_mod_howTo_title3,
  "APPB2C.registration.results.organisations.noReceivingOrgsSelectedNote": APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote3,
  "APPB2C.registration.results.organisations.note0": APPB2C_registration_results_organisations_note03,
  "APPB2C.registration.results.organisations.note1": APPB2C_registration_results_organisations_note13,
  "APPB2C.registration.results.organisations.note2": APPB2C_registration_results_organisations_note23,
  "APPB2C.registration.results.organisations.organisationsCount": APPB2C_registration_results_organisations_organisationsCount3,
  "APPB2C.registration.results.organisations.organisationsCount_other": APPB2C_registration_results_organisations_organisationsCount_other3,
  "APPB2C.registration.results.organisations.receivingOrgsLimitReachedNote": APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote3,
  "APPB2C.registration.results.organisations.title": APPB2C_registration_results_organisations_title3,
  "APPB2C.registration.results.osr.chooseDate.accessibility": APPB2C_registration_results_osr_chooseDate_accessibility3,
  "APPB2C.registration.results.osr.chooseDate.accessibilityLink": APPB2C_registration_results_osr_chooseDate_accessibilityLink3,
  "APPB2C.registration.results.osr.chooseDate.chooseDateTitle": APPB2C_registration_results_osr_chooseDate_chooseDateTitle3,
  "APPB2C.registration.results.osr.chooseDate.note.content": APPB2C_registration_results_osr_chooseDate_note_content3,
  "APPB2C.registration.results.osr.chooseDate.note.title": APPB2C_registration_results_osr_chooseDate_note_title3,
  "APPB2C.registration.results.osr.chooseDate.retakeListening": APPB2C_registration_results_osr_chooseDate_retakeListening3,
  "APPB2C.registration.results.osr.chooseDate.retakeReading": APPB2C_registration_results_osr_chooseDate_retakeReading3,
  "APPB2C.registration.results.osr.chooseDate.retakeSpeaking": APPB2C_registration_results_osr_chooseDate_retakeSpeaking3,
  "APPB2C.registration.results.osr.chooseDate.retakeWriting": APPB2C_registration_results_osr_chooseDate_retakeWriting3,
  "APPB2C.registration.results.osr.chooseSkill.backBtn": APPB2C_registration_results_osr_chooseSkill_backBtn3,
  "APPB2C.registration.results.osr.chooseSkill.chooseDateBtn": APPB2C_registration_results_osr_chooseSkill_chooseDateBtn3,
  "APPB2C.registration.results.osr.chooseSkill.confirmationRequired": APPB2C_registration_results_osr_chooseSkill_confirmationRequired3,
  "APPB2C.registration.results.osr.chooseSkill.formTitle": APPB2C_registration_results_osr_chooseSkill_formTitle3,
  "APPB2C.registration.results.osr.chooseSkill.modal.contact": APPB2C_registration_results_osr_chooseSkill_modal_contact3,
  "APPB2C.registration.results.osr.chooseSkill.modal.description": APPB2C_registration_results_osr_chooseSkill_modal_description3,
  "APPB2C.registration.results.osr.chooseSkill.modal.email": APPB2C_registration_results_osr_chooseSkill_modal_email3,
  "APPB2C.registration.results.osr.chooseSkill.modal.phone": APPB2C_registration_results_osr_chooseSkill_modal_phone3,
  "APPB2C.registration.results.osr.chooseSkill.modal.title": APPB2C_registration_results_osr_chooseSkill_modal_title3,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.confirmation": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation3,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.description": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description3,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.title": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title3,
  "APPB2C.registration.results.osr.chooseSkill.skillRequired": APPB2C_registration_results_osr_chooseSkill_skillRequired3,
  "APPB2C.registration.results.osr.chooseTest.availableDates": APPB2C_registration_results_osr_chooseTest_availableDates3,
  "APPB2C.registration.results.osr.chooseTest.speaking.time": APPB2C_registration_results_osr_chooseTest_speaking_time3,
  "APPB2C.registration.results.osr.error.missingFile": APPB2C_registration_results_osr_error_missingFile3,
  "APPB2C.registration.results.osr.error.noTestDates": APPB2C_registration_results_osr_error_noTestDates3,
  "APPB2C.registration.results.osr.error.noTests": APPB2C_registration_results_osr_error_noTests3,
  "APPB2C.registration.results.osr.line1": APPB2C_registration_results_osr_line13,
  "APPB2C.registration.results.osr.line2": APPB2C_registration_results_osr_line23,
  "APPB2C.registration.results.osr.line3": APPB2C_registration_results_osr_line33,
  "APPB2C.registration.results.osr.line4": APPB2C_registration_results_osr_line43,
  "APPB2C.registration.results.osr.line5": APPB2C_registration_results_osr_line53,
  "APPB2C.registration.results.osr.review.note": APPB2C_registration_results_osr_review_note3,
  "APPB2C.registration.results.osr.review.note.testDay": APPB2C_registration_results_osr_review_note_testDay3,
  "APPB2C.registration.results.osr.submitBtn": APPB2C_registration_results_osr_submitBtn3,
  "APPB2C.registration.results.osr.successBanner": APPB2C_registration_results_osr_successBanner3,
  "APPB2C.registration.results.osr.title": APPB2C_registration_results_osr_title3,
  "APPB2C.registration.results.ttAbsent.btn": APPB2C_registration_results_ttAbsent_btn3,
  "APPB2C.registration.results.ttAbsent.msg": APPB2C_registration_results_ttAbsent_msg3,
  "APPB2C.registration.results.updateBar.osr.booked": APPB2C_registration_results_updateBar_osr_booked3,
  "APPB2C.registration.results.updateBar.osr.marked": APPB2C_registration_results_updateBar_osr_marked3,
  "APPB2C.registration.tabs.bookingDetails": APPB2C_registration_tabs_bookingDetails3,
  "APPB2C.registration.tabs.candidate": APPB2C_registration_tabs_candidate3,
  "APPB2C.registration.tabs.date": APPB2C_registration_tabs_date3,
  "APPB2C.registration.tabs.dateLocation": APPB2C_registration_tabs_dateLocation3,
  "APPB2C.registration.tabs.payments": APPB2C_registration_tabs_payments3,
  "APPB2C.registration.tabs.recognisingOrgs": APPB2C_registration_tabs_recognisingOrgs3,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.1": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_13,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.2": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_23,
  "APPB2C.registration.tabs.results": APPB2C_registration_tabs_results3,
  "APPB2C.registration.testTaker.bookedFor.completed": APPB2C_registration_testTaker_bookedFor_completed3,
  "APPB2C.registration.testTaker.bookedFor.dob": APPB2C_registration_testTaker_bookedFor_dob3,
  "APPB2C.registration.testTaker.bookedFor.email": APPB2C_registration_testTaker_bookedFor_email3,
  "APPB2C.registration.testTaker.bookedFor.gender": APPB2C_registration_testTaker_bookedFor_gender3,
  "APPB2C.registration.testTaker.bookedFor.name": APPB2C_registration_testTaker_bookedFor_name3,
  "APPB2C.registration.testTaker.bookedFor.parent": APPB2C_registration_testTaker_bookedFor_parent3,
  "APPB2C.registration.testTaker.bookedFor.title": APPB2C_registration_testTaker_bookedFor_title3,
  "APPB2C.registration.testTaker.childConsent.back": APPB2C_registration_testTaker_childConsent_back3,
  "APPB2C.registration.testTaker.childConsent.context": APPB2C_registration_testTaker_childConsent_context3,
  "APPB2C.registration.testTaker.childConsent.cta": APPB2C_registration_testTaker_childConsent_cta3,
  "APPB2C.registration.testTaker.childConsent.options.1": APPB2C_registration_testTaker_childConsent_options_13,
  "APPB2C.registration.testTaker.childConsent.options.2": APPB2C_registration_testTaker_childConsent_options_23,
  "APPB2C.registration.testTaker.childConsent.options.3": APPB2C_registration_testTaker_childConsent_options_33,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.add": APPB2C_registration_testTaker_childConsent_somebodyElse_add3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmCheckbox": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.1": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_13,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.2": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_23,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.noLastName": APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.note": APPB2C_registration_testTaker_childConsent_somebodyElse_note3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phone": APPB2C_registration_testTaker_childConsent_somebodyElse_phone3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phoneNote": APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.policy": APPB2C_registration_testTaker_childConsent_somebodyElse_policy3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.relationship": APPB2C_registration_testTaker_childConsent_somebodyElse_relationship3,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.1": APPB2C_registration_testTaker_childConsent_somebodyElse_title_13,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.2": APPB2C_registration_testTaker_childConsent_somebodyElse_title_23,
  "APPB2C.registration.testTaker.childConsent.title": APPB2C_registration_testTaker_childConsent_title3,
  "APPB2C.registration.testTaker.childConsent.warning": APPB2C_registration_testTaker_childConsent_warning3,
  "APPB2C.registration.testTaker.consent.edit": APPB2C_registration_testTaker_consent_edit3,
  "APPB2C.registration.testTaker.consent.missing": APPB2C_registration_testTaker_consent_missing3,
  "APPB2C.registration.testTaker.consent.title": APPB2C_registration_testTaker_consent_title3,
  "APPB2C.registration.testTaker.contact.address": APPB2C_registration_testTaker_contact_address3,
  "APPB2C.registration.testTaker.contact.edit": APPB2C_registration_testTaker_contact_edit3,
  "APPB2C.registration.testTaker.contact.mobile": APPB2C_registration_testTaker_contact_mobile3,
  "APPB2C.registration.testTaker.contact.parent": APPB2C_registration_testTaker_contact_parent3,
  "APPB2C.registration.testTaker.contact.title": APPB2C_registration_testTaker_contact_title3,
  "APPB2C.registration.testTaker.editContact.address": APPB2C_registration_testTaker_editContact_address3,
  "APPB2C.registration.testTaker.editContact.back": APPB2C_registration_testTaker_editContact_back3,
  "APPB2C.registration.testTaker.editContact.city": APPB2C_registration_testTaker_editContact_city3,
  "APPB2C.registration.testTaker.editContact.country.change": APPB2C_registration_testTaker_editContact_country_change3,
  "APPB2C.registration.testTaker.editContact.country.residence": APPB2C_registration_testTaker_editContact_country_residence3,
  "APPB2C.registration.testTaker.editContact.cta": APPB2C_registration_testTaker_editContact_cta3,
  "APPB2C.registration.testTaker.editContact.error.missingAddress": APPB2C_registration_testTaker_editContact_error_missingAddress3,
  "APPB2C.registration.testTaker.editContact.error.missingCountry": APPB2C_registration_testTaker_editContact_error_missingCountry3,
  "APPB2C.registration.testTaker.editContact.error.pastCutoff": APPB2C_registration_testTaker_editContact_error_pastCutoff3,
  "APPB2C.registration.testTaker.editContact.mobile": APPB2C_registration_testTaker_editContact_mobile3,
  "APPB2C.registration.testTaker.editContact.note": APPB2C_registration_testTaker_editContact_note3,
  "APPB2C.registration.testTaker.editContact.postCode": APPB2C_registration_testTaker_editContact_postCode3,
  "APPB2C.registration.testTaker.editContact.state": APPB2C_registration_testTaker_editContact_state3,
  "APPB2C.registration.testTaker.editContact.warn": APPB2C_registration_testTaker_editContact_warn3,
  "APPB2C.registration.testTaker.error": APPB2C_registration_testTaker_error3,
  "APPB2C.registration.testTaker.files.editButton.add": APPB2C_registration_testTaker_files_editButton_add3,
  "APPB2C.registration.testTaker.files.editButton.edit": APPB2C_registration_testTaker_files_editButton_edit3,
  "APPB2C.registration.testTaker.files.upload.back.subtitle": APPB2C_registration_testTaker_files_upload_back_subtitle3,
  "APPB2C.registration.testTaker.files.upload.back.title": APPB2C_registration_testTaker_files_upload_back_title3,
  "APPB2C.registration.testTaker.files.upload.cta": APPB2C_registration_testTaker_files_upload_cta3,
  "APPB2C.registration.testTaker.files.upload.error.fileTooLarge": APPB2C_registration_testTaker_files_upload_error_fileTooLarge3,
  "APPB2C.registration.testTaker.files.upload.error.maxNumberOfFilesExceeded": APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded3,
  "APPB2C.registration.testTaker.files.upload.error.uploadUnavailable": APPB2C_registration_testTaker_files_upload_error_uploadUnavailable3,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileName": APPB2C_registration_testTaker_files_upload_error_wrongFileName3,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileType": APPB2C_registration_testTaker_files_upload_error_wrongFileType3,
  "APPB2C.registration.testTaker.files.upload.mobile.line1": APPB2C_registration_testTaker_files_upload_mobile_line13,
  "APPB2C.registration.testTaker.files.upload.mobile.line2": APPB2C_registration_testTaker_files_upload_mobile_line23,
  "APPB2C.registration.testTaker.files.upload.mobile.title": APPB2C_registration_testTaker_files_upload_mobile_title3,
  "APPB2C.registration.testTaker.files.upload.note": APPB2C_registration_testTaker_files_upload_note3,
  "APPB2C.registration.testTaker.id.child": APPB2C_registration_testTaker_id_child3,
  "APPB2C.registration.testTaker.id.edit.rules.example": APPB2C_registration_testTaker_id_edit_rules_example3,
  "APPB2C.registration.testTaker.id.edit.subtitle": APPB2C_registration_testTaker_id_edit_subtitle3,
  "APPB2C.registration.testTaker.id.edit.title": APPB2C_registration_testTaker_id_edit_title3,
  "APPB2C.registration.testTaker.id.edit.upload.exampleProof": APPB2C_registration_testTaker_id_edit_upload_exampleProof3,
  "APPB2C.registration.testTaker.id.edit.upload.imgAlt": APPB2C_registration_testTaker_id_edit_upload_imgAlt3,
  "APPB2C.registration.testTaker.id.expiryDate": APPB2C_registration_testTaker_id_expiryDate3,
  "APPB2C.registration.testTaker.id.fileAlt": APPB2C_registration_testTaker_id_fileAlt3,
  "APPB2C.registration.testTaker.id.idNumber": APPB2C_registration_testTaker_id_idNumber3,
  "APPB2C.registration.testTaker.id.issuingAuthority": APPB2C_registration_testTaker_id_issuingAuthority3,
  "APPB2C.registration.testTaker.id.noFilesBody": APPB2C_registration_testTaker_id_noFilesBody3,
  "APPB2C.registration.testTaker.id.noFilesTitle": APPB2C_registration_testTaker_id_noFilesTitle3,
  "APPB2C.registration.testTaker.id.title": APPB2C_registration_testTaker_id_title3,
  "APPB2C.registration.testTaker.id.typeNames.card": APPB2C_registration_testTaker_id_typeNames_card3,
  "APPB2C.registration.testTaker.id.typeNames.other": APPB2C_registration_testTaker_id_typeNames_other3,
  "APPB2C.registration.testTaker.id.typeNames.passport": APPB2C_registration_testTaker_id_typeNames_passport3,
  "APPB2C.registration.testTaker.specialArrangements.description": APPB2C_registration_testTaker_specialArrangements_description3,
  "APPB2C.registration.testTaker.specialArrangements.edit.line1": APPB2C_registration_testTaker_specialArrangements_edit_line13,
  "APPB2C.registration.testTaker.specialArrangements.edit.line2": APPB2C_registration_testTaker_specialArrangements_edit_line23,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule1": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule13,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule2": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule23,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule3": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule33,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule4": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule43,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule5": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule53,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.title": APPB2C_registration_testTaker_specialArrangements_edit_rules_title3,
  "APPB2C.registration.testTaker.specialArrangements.edit.subtitle": APPB2C_registration_testTaker_specialArrangements_edit_subtitle3,
  "APPB2C.registration.testTaker.specialArrangements.edit.title": APPB2C_registration_testTaker_specialArrangements_edit_title3,
  "APPB2C.registration.testTaker.specialArrangements.fileAlt": APPB2C_registration_testTaker_specialArrangements_fileAlt3,
  "APPB2C.registration.testTaker.specialArrangements.noFilesBody": APPB2C_registration_testTaker_specialArrangements_noFilesBody3,
  "APPB2C.registration.testTaker.specialArrangements.noFilesTitle": APPB2C_registration_testTaker_specialArrangements_noFilesTitle3,
  "APPB2C.registration.testTaker.specialArrangements.title": APPB2C_registration_testTaker_specialArrangements_title3,
  "APPB2C.testCard.arrival": APPB2C_testCard_arrival3,
  "APPB2C.testCard.book": APPB2C_testCard_book3,
  "APPB2C.testCard.bookExam": APPB2C_testCard_bookExam3,
  "APPB2C.testCard.bookFor": APPB2C_testCard_bookFor3,
  "APPB2C.testCard.bookForFree": APPB2C_testCard_bookForFree3,
  "APPB2C.testCard.bookingPrice": APPB2C_testCard_bookingPrice3,
  "APPB2C.testCard.cd": APPB2C_testCard_cd3,
  "APPB2C.testCard.changeSpeaking": APPB2C_testCard_changeSpeaking3,
  "APPB2C.testCard.f2f": APPB2C_testCard_f2f3,
  "APPB2C.testCard.hideSpeakingTests": APPB2C_testCard_hideSpeakingTests3,
  "APPB2C.testCard.lrw": APPB2C_testCard_lrw3,
  "APPB2C.testCard.lrwHeader": APPB2C_testCard_lrwHeader2,
  "APPB2C.testCard.ls": APPB2C_testCard_ls3,
  "APPB2C.testCard.mixedSlots": APPB2C_testCard_mixedSlots3,
  "APPB2C.testCard.notLinkedVenue": APPB2C_testCard_notLinkedVenue3,
  "APPB2C.testCard.pb": APPB2C_testCard_pb3,
  "APPB2C.testCard.review": APPB2C_testCard_review3,
  "APPB2C.testCard.showSpeakingTests": APPB2C_testCard_showSpeakingTests3,
  "APPB2C.testCard.speaking": APPB2C_testCard_speaking3,
  "APPB2C.testCard.speakingAddress": APPB2C_testCard_speakingAddress3,
  "APPB2C.testCard.timeUnknown": APPB2C_testCard_timeUnknown3,
  "APPB2C.testCard.vcs": APPB2C_testCard_vcs3
};

// src/locale/zh-TW/b2c.json
var APPB2C_auth_changePassword_cta4 = "\u8A2D\u7F6E\u5BC6\u78BC";
var APPB2C_auth_changePassword_error4 = "\u7CFB\u7D71\u51FA\u73FE\u7570\u5E38, \u8ACB\u65BC\u5E7E\u5206\u9418\u5F8C\u518D\u6B21\u5617\u8A66\u3002";
var APPB2C_auth_changePassword_error_differentPasswords4 = "\u8F38\u5165\u7684\u5BC6\u78BC\u4E0D\u540C\u3002";
var APPB2C_auth_changePassword_error_wrongIdNumber4 = "\u8EAB\u4EFD\u8B49\u865F\u78BC\u4E0D\u6B63\u78BA\u3002";
var APPB2C_auth_changePassword_error_wrongOldPassword4 = "\u820A\u5BC6\u78BC\u4E0D\u6B63\u78BA\u3002";
var APPB2C_auth_changePassword_error_wrongPassword4 = "\u5BC6\u78BC\u4E0D\u7B26\u5408\u8981\u6C42\u3002";
var APPB2C_auth_changePassword_id_label4 = "\u8B77\u7167\u865F\u78BC/\u8EAB\u4EFD\u8B49\u865F\u78BC";
var APPB2C_auth_changePassword_id_note4 = "\u70BA\u7E7C\u7E8C\u767B\u5165\u4F5C\u696D, \u8ACB\u5728\u4E0B\u9762\u586B\u5165\u60A8\u7684\u8B77\u7167\u865F\u78BC/\u8EAB\u4EFD\u8B49\u865F\u78BC\u3002";
var APPB2C_auth_changePassword_id_warn4 = '\u82E5\u60A8\u5DF2\u8F38\u5165\u6B63\u78BA\u7684\u8EAB\u4EFD\u8B49\u4EF6\u865F\u78BC, \u4F46\u4ECD\u770B\u5230"\u8EAB\u4EFD\u8B49\u865F\u78BC\u4E0D\u6B63\u78BA"\u932F\u8AA4, \u8ACB\u806F\u7E6B\u82F1\u570B\u6587\u5316\u5354\u6703\u3002';
var APPB2C_auth_changePassword_pass_label4 = "\u5BC6\u78BC";
var APPB2C_auth_changePassword_pass_note4 = "\u8ACB\u81EA\u884C\u8A2D\u5B9A\u60A8\u7684\u5BC6\u78BC\u4EE5\u7E7C\u7E8C\u3002";
var APPB2C_auth_changePassword_title4 = "\u8A2D\u7F6E\u65B0\u5BC6\u78BC";
var APPB2C_auth_forgetPassword_cta4 = "\u5C07\u6062\u5FA9\u9023\u7D50\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u7D66\u6211\u3002";
var APPB2C_auth_forgetPassword_email4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_auth_forgetPassword_success_msg14 = "\u8B1D\u8B1D\uFF01\u82E5\u6B64\u5E33\u6236\u5DF2\u8A3B\u518A, \u5C07\u767C\u9001\u4E00\u5C01\u96FB\u5B50\u90F5\u4EF6\u5C0D\u60A8\u9032\u4E00\u6B65\u8AAA\u660E\u3002";
var APPB2C_auth_forgetPassword_success_msg24 = " \u8ACB\u5728\u6536\u5230\u9023\u7D50\u6642\u9EDE\u64CA\u8A72\u9023\u7D50\u3002";
var APPB2C_auth_forgetPassword_success_title4 = "\u6210\u529F\uFF01";
var APPB2C_auth_forgetPassword_text4 = "\u5FD8\u8A18\u5BC6\u78BC\uFF1F\u6C92\u554F\u984C, \u6211\u5011\u5C07\u767C\u9001\u4E00\u500B\u9023\u7D50\u7D66\u60A8\u4F86\u627E\u56DE\u5BC6\u78BC\u3002";
var APPB2C_auth_forgetPassword_title4 = "\u627E\u56DE\u5BC6\u78BC";
var APPB2C_auth_resetPassword_cta4 = "\u91CD\u8A2D\u5BC6\u78BC";
var APPB2C_auth_resetPassword_error_expiredToken4 = "\u91CD\u7F6E\u5BC6\u78BC\u7684\u9023\u7D50\u5DF2\u904E\u671F\u3002\u8ACB\u4F7F\u7528\u767B\u9304\u87A2\u5E55\u4E0A\u7684\u300C\u5FD8\u8A18\u5BC6\u78BC\u300D\u4F86\u63A5\u6536\u65B0\u9023\u7D50\u3002";
var APPB2C_auth_resetPassword_error_wrongPassword4 = "\u60A8\u8F38\u5165\u7684\u5BC6\u78BC\u4E0D\u7B26\u5408\u8981\u6C42\u3002";
var APPB2C_auth_resetPassword_error_wrongToken4 = "\u5BC6\u78BC\u91CD\u7F6E\u7684\u81E8\u6642\u9023\u7D50\u7121\u6548\u3002";
var APPB2C_auth_resetPassword_form_newPassword4 = "\u65B0\u5BC6\u78BC";
var APPB2C_auth_resetPassword_noToken_cta4 = "\u627E\u56DE\u60A8\u7684\u5BC6\u78BC";
var APPB2C_auth_resetPassword_noToken_msg4 = "\u6B64\u6307\u4EE4\u7121\u6CD5\u8B58\u5225";
var APPB2C_auth_resetPassword_success_msg4 = "\u60A8\u7684\u5BC6\u78BC\u5DF2\u6210\u529F\u66F4\u6539\u3002\u60A8\u5C07\u5F88\u5FEB\u88AB\u91CD\u65B0\u5C0E\u5411...";
var APPB2C_auth_resetPassword_success_title4 = "\u6210\u529F\uFF01";
var APPB2C_auth_resetPassword_title4 = "\u91CD\u8A2D\u5BC6\u78BC";
var APPB2C_cj_basic_cta4 = "\u5132\u5B58\u4E26\u7E7C\u7E8C";
var APPB2C_cj_basic_goToReview4 = "\u5132\u5B58\u4E26\u91CD\u65B0\u6AA2\u8996";
var APPB2C_cj_basic_scrollLink4 = "\u518D\u6B21\u6AA2\u67E5\u7B54\u6848\u3002";
var APPB2C_cj_basic_selectDate4 = "\u9078\u64C7\u65E5\u671F";
var APPB2C_cj_basic_selectTime4 = "\u9078\u64C7\u6642\u9593";
var APPB2C_cj_basic_validationError4 = "\u8ACB\u6AA2\u67E5\u6240\u6709\u7279\u5225\u6A19\u793A\u7684\u7B54\u6848\u4EE5\u7E7C\u7E8C\u3002";
var APPB2C_cj_bookingComplete_feeDetails_discount4 = "\u6649\u5347";
var APPB2C_cj_bookingComplete_feeDetails_fee4 = "\u8CBB\u7528";
var APPB2C_cj_bookingComplete_feeDetails_newFee4 = "\u65B0\u8CBB\u7528";
var APPB2C_cj_bookingComplete_feeDetails_promo4 = "\u4FC3\u92B7";
var APPB2C_cj_bookingComplete_feeDetails_reference4 = "\u53C3\u8003";
var APPB2C_cj_bookingComplete_feeDetails_tax4 = "\u7A05";
var APPB2C_cj_bookingComplete_feeDetails_title4 = "\u8CBB\u7528\u8A73\u60C5";
var APPB2C_cj_bookingComplete_feeDetails_total4 = "\u7E3D";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine14 = "\u8003\u8A66\u8CBB\u7528\u5DF2\u4EAB\u6709\u6298\u6263\u81F3";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine24 = "\u4F7F\u7528\u6298\u6263\u78BC\u3002";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine34 = "\u60A8\u7121\u9700\u652F\u4ED8\u4EFB\u4F55\u8CBB\u7528\u3002";
var APPB2C_cj_bookingComplete_loadingPaymentMethodsError4 = "\u6211\u5011\u76EE\u524D\u7121\u6CD5\u8655\u7406\u60A8\u7684\u4ED8\u6B3E, \u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_cj_bookingComplete_loadingRegistrationError4 = "\u6211\u5011\u76EE\u524D\u7121\u6CD5\u8F09\u5165\u60A8\u7684\u8A3B\u518A\u8A73\u7D30\u8CC7\u8A0A\u3002\u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_cj_bookingComplete_note4 = "\u606D\u559C, \u60A8\u7684\u96C5\u601D\u8003\u8A66\u5DF2\u5831\u540D\u5B8C\u6210\u3002\u6211\u5011\u5DF2\u5C07\u5831\u8003\u5B8C\u6210\u78BA\u8A8D\u767C\u9001\u5230\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740(\u4E0D\u8981\u5FD8\u8A18\u6AA2\u67E5\u60A8\u7684\u5783\u573E\u90F5\u4EF6\u8CC7\u6599\u593E\uFF01)";
var APPB2C_cj_bookingComplete_noteUolPassportInfo4 = "\u60A8\u7684\u5831\u540D\u4ECD\u9700\u7B49\u5019\u8B77\u7167\u9A57\u8B49, \u6211\u5011\u5C07\u6703\u5118\u5FEB\u8207\u60A8\u806F\u7E6B\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_code4 = "\u6392\u5E8F\u4EE3\u78BC";
var APPB2C_cj_bookingComplete_offlinePayment_description4 = "\u4ED8\u6B3E\u8AAA\u660E: ";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line14 = "\u8003\u8A66\u8CBB\u7528\u5DF2\u4EAB\u6709\u6298\u6263\u81F3";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line24 = "\u70BA\u4E86\u4FDD\u7559\u60A8\u7684\u5831\u540D, \u8ACB\u652F\u4ED8\u5269\u9918\u7684\u8CBB\u7528";
var APPB2C_cj_bookingComplete_offlinePayment_fee4 = "\u8CBB\u7528";
var APPB2C_cj_bookingComplete_offlinePayment_hideDescription4 = "\u96B1\u85CF\u4ED8\u6B3E\u8AAA\u660E";
var APPB2C_cj_bookingComplete_offlinePayment_incTax4 = "\u542B\u7A05";
var APPB2C_cj_bookingComplete_offlinePayment_name4 = "\u5E33\u6236\u540D\u7A31";
var APPB2C_cj_bookingComplete_offlinePayment_note_line14 = "\u8ACB\u60A8\u65BC {{days}} \u500B\u5DE5\u4F5C\u65E5\u5B8C\u6210\u4ED8\u6B3E\u3002\u82E5\u672A\u65BC\u6B64\u6642\u9593\u5167\u4ED8\u6B3E, \u60A8\u7684\u8003\u8A66\u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours4 = "\u8ACB\u60A8\u65BC{{hours}} \u5C0F\u6642\u5167\u5B8C\u6210\u4ED8\u6B3E\u3002\u82E5\u672A\u65BC\u6B64\u6642\u9593\u5167\u4ED8\u6B3E, \u60A8\u7684\u8003\u8A66\u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft4 = "\u60A8\u5FC5\u9808\u5728 {{date}} \u4E4B\u524D\u4ED8\u6B3E\u3002\u5982\u679C\u672A\u5728\u6B64\u6642\u6642\u9650\u4ED8\u6B3E, \u60A8\u7684\u8003\u8A66\u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_line24 = "\u8ACB\u60A8\u65BC\u4ED8\u6B3E\u5F8C\u91CD\u65B0\u767B\u5165\u4E26\u4E0A\u50B3\u4ED8\u6B3E\u8B49\u660E\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote4 = "\u8ACB\u6CE8\u610F";
var APPB2C_cj_bookingComplete_offlinePayment_number4 = "\u5E33\u865F";
var APPB2C_cj_bookingComplete_offlinePayment_reference4 = "\u4EA4\u6613\u5E8F\u865F";
var APPB2C_cj_bookingComplete_offlinePayment_showDescription4 = "\u986F\u793A\u4ED8\u6B3E\u8AAA\u660E";
var APPB2C_cj_bookingComplete_otherPaymentOptions4 = "\u67E5\u770B\u5176\u4ED6\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_cj_bookingComplete_paymentFailed4 = "\u62B1\u6B49, \u60A8\u7684\u7DDA\u4E0A\u4ED8\u6B3E\u5931\u6557\uFF01\u6211\u5011\u5148\u70BA\u60A8\u4FDD\u7559\u5831\u540D\u8CC7\u683C, \u4F46\u8ACB\u76E1\u901F\u652F\u4ED8\u8003\u8A66\u8CBB\u7528\u3002";
var APPB2C_cj_bookingComplete_paymentOptions4 = "\u67E5\u770B\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_cj_bookingComplete_prepareNote_accesNote4 = "\u53D6\u5F97\u82F1\u570B\u6587\u5316\u5354\u6703\u6240\u63D0\u4F9B\u7684\u514D\u8CBB\u96C5\u601D\u5099\u8003\u8CC7\u6E90\u3002";
var APPB2C_cj_bookingComplete_prepareNote_counselling_note4 = "\u7D93\u7531 IELTS Counselling \u8CC7\u6E90, \u5C07\u6709\u96C5\u601D\u5C08\u5BB6\u5354\u52A9\u4E26\u6307\u5C0E\u60A8\u6E96\u5099\u8003\u8A66\u4E26\u53D6\u5F97\u60A8\u671F\u671B\u7684\u6210\u7E3E\u3002";
var APPB2C_cj_bookingComplete_prepareNote_counselling_view4 = "\u67E5\u770B\u8AEE\u8A62\u670D\u52D9";
var APPB2C_cj_bookingComplete_prepareNote_note4 = "\u78BA\u4FDD\u60A8\u6E96\u5099\u597D\u53C3\u52A0\u96C5\u601D\u8003\u8A66\u7684\u7B2C\u4E00\u6B65\u662F\u505A\u597D\u6E96\u5099, \u4EE5\u4FBF\u60A8\u77E5\u9053\u8003\u8A66\u7576\u5929\u6703\u767C\u751F\u4EC0\u9EBC\u3002";
var APPB2C_cj_bookingComplete_prepareNote_title4 = "\u6E96\u5099\u8003\u8A66";
var APPB2C_cj_bookingComplete_prepareNote_view4 = "\u67E5\u770B\u96C5\u601D\u5099\u8003\u8CC7\u6E90";
var APPB2C_cj_bookingComplete_title4 = "\u5831\u540D\u5B8C\u6210";
var APPB2C_cj_bookingComplete_tnc4 = "\u60A8\u540C\u610F\u9019\u4E9B";
var APPB2C_cj_bookingComplete_tncLink4 = "\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_cj_bookingComplete_tryAgain4 = "\u518D\u6B21\u5617\u8A66\u4ED8\u6B3E";
var APPB2C_cj_bookingComplete_yourBooking4 = "\u60A8\u7684\u5831\u540D";
var APPB2C_cj_childDetails_about4 = "\u95DC\u65BC\u60A8\u7684\u5B69\u5B50";
var APPB2C_cj_childDetails_aboutNote4 = "\u8ACB\u8F38\u5165\u6B32\u61C9\u8A66\u7684\u5B69\u5B50\u8A73\u7D30\u8CC7\u8A0A: ";
var APPB2C_cj_childDetails_error_missingFirstName4 = "\u8ACB\u8F38\u5165\u60A8\u5B69\u5B50\u7684\u540D\u5B57/\u540D\u5B57\u3002";
var APPB2C_cj_childDetails_error_missingSurname4 = '\u8ACB\u8F38\u5165\u60A8\u5B69\u5B50\u7684\u59D3\u6C0F/\u59D3\u6C0F\u3002\u5982\u679C\u4ED6\u5011\u6C92\u6709\u59D3\u6C0F, \u8ACB\u5728\u6B64\u5B57\u6BB5\u4E2D\u8F38\u5165\u9023\u5B57\u5143("-")\u3002';
var APPB2C_cj_childDetails_error_wrongDob4 = "\u8ACB\u63D0\u4F9B\u60A8\u5B69\u5B50\u7684\u5BE6\u969B\u51FA\u751F\u65E5\u671F\u3002";
var APPB2C_cj_childDetails_forms_personal_idMatch4 = "\u9019\u5FC5\u9808\u8207\u5176\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E0A\u7684\u59D3\u540D\u4E00\u81F4";
var APPB2C_cj_childDetails_noLastName4 = "\u4ED6\u5011\u6C92\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_childDetails_note4 = "\u4ED6\u5011\u7684\u5168\u540D\u548C\u51FA\u751F\u65E5\u671F\u5FC5\u9808\u8207\u8003\u8A66\u7576\u5929\u6240\u51FA\u793A\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_childDetails_onTestDay4 = "\u8003\u8A66\u7576\u5929";
var APPB2C_cj_childDetails_title4 = "\u60A8\u5B69\u5B50\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_cj_finishPayment_error4 = "\u6211\u5011\u76EE\u524D\u7121\u6CD5\u8655\u7406\u60A8\u7684\u4ED8\u6B3E, \u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_cj_finishPayment_pleaseWait4 = "\u8ACB\u7A0D\u5019";
var APPB2C_cj_finishPayment_processing4 = "\u6211\u5011\u6B63\u5728\u8655\u7406\u60A8\u7684\u4ED8\u6B3E...";
var APPB2C_cj_idDetails_confirm_change4 = "\u6211\u9700\u8981\u4FEE\u6539\u8CC7\u8A0A";
var APPB2C_cj_idDetails_confirm_confirm4 = "\u6211\u78BA\u8A8D\u6240\u6709\u8CC7\u8A0A\u6B63\u78BA";
var APPB2C_cj_idDetails_confirm_note14 = "\u70BA\u78BA\u4FDD\u60A8\u544A\u8A34\u6211\u5011$t({{idName}})\u7684\u8A73\u7D30\u8CC7\u8A0A\u6B63\u78BA, \u5728\u7E7C\u7E8C\u4E4B\u524D, \u8ACB\u518D\u6B21\u6AA2\u67E5\u4EE5\u4E0B\u8CC7\u8A0A\u3002";
var APPB2C_cj_idDetails_confirm_note24 = "\u5728\u8003\u8A66\u7576\u5929, \u60A8\u5FC5\u9808\u651C\u5E36\u6B64$t({{idName}})\u3002";
var APPB2C_cj_idDetails_confirm_title4 = "\u8ACB\u5728\u7E7C\u7E8C\u4E4B\u524D\u6AA2\u67E5\u60A8\u7684$t({{idName}})";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_content4 = "\u82E5\u60A8\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u8CC7\u8A0A\u6709\u8AA4, \u5C07\u53EF\u80FD\u5EF6\u9072\u6210\u7E3E\u6838\u767C\u3002";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_cj_idDetails_error_missingIdExpiry4 = "\u8ACB\u63D0\u4F9B\u8EAB\u4EFD\u8B49\u660E\u6A94\u7684\u6709\u6548\u671F\u3002";
var APPB2C_cj_idDetails_error_missingIdNumber4 = "\u8ACB\u63D0\u4F9B\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u865F\u78BC\u3002\u5B83\u9700\u8981\u5339\u914D\u7279\u5B9A\u7684\u683C\u5F0F(\u5141\u8A31\u4F7F\u7528\u5B57\u6BCD\u3001\u6578\u4F4D\u548C\u7279\u6B8A\u5B57\u5143: _\\-/, )\u3002";
var APPB2C_cj_idDetails_error_missingIdType4 = "\u8ACB\u63D0\u4F9B\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u985E\u578B\u3002";
var APPB2C_cj_idDetails_error_missingIssuingAuthority4 = "\u8ACB\u63D0\u4F9B\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u7684\u7C3D\u767C\u6A5F\u95DC\u3002";
var APPB2C_cj_idDetails_error_wrongFilename4 = "\u6A94\u540D\u5305\u542B\u7121\u6548\u5B57\u5143\u3002";
var APPB2C_cj_idDetails_error_wrongIdExpiry4 = "\u8ACB\u63D0\u4F9B\u5728\u6240\u9078\u8003\u8A66\u65E5\u671F\u4E4B\u524D\u4E0D\u6703\u904E\u671F\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u3002";
var APPB2C_cj_idDetails_forms_changeNote4 = "\u60A8\u5DF2\u66F4\u6539\u6240\u9078\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u985E\u5225\u3002\u8ACB\u78BA\u4FDD\u6240\u4E0A\u50B3\u7684\u5716\u7247\u8207\u60A8\u5728\u8003\u8A66\u7576\u5929\u651C\u5E36\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_idDetails_forms_changeNote_child4 = "\u60A8\u5DF2\u66F4\u6539\u6240\u9078\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u985E\u5225\u3002\u8ACB\u78BA\u4FDD\u6240\u4E0A\u50B3\u7684\u5716\u7247\u8207\u8003\u8A66\u7576\u5929\u651C\u5E36\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_idDetails_forms_documentWithNoExpiryDate4 = "\u6B64\u6587\u4EF6\u6C92\u6709\u5230\u671F\u65E5";
var APPB2C_cj_idDetails_forms_expiryDate4 = "$t({{idName}}, capitalise) \u5230\u671F\u65E5";
var APPB2C_cj_idDetails_forms_idNumber4 = "$t({{idName}}, capitalise) \u865F\u78BC";
var APPB2C_cj_idDetails_forms_idType4 = "\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u985E\u5225";
var APPB2C_cj_idDetails_forms_issuingAuthority4 = "\u767C\u8B49\u6A5F\u95DC";
var APPB2C_cj_idDetails_idExpired4 = "\u60A8\u4E4B\u524D\u8A3B\u518A\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u5DF2\u904E\u671F\u3002\u8ACB\u65B0\u589E\u65B0\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u3002";
var APPB2C_cj_idDetails_iolMessage4 = "\u8003\u8A66\u7576\u5929, \u60A8\u5C07\u9700\u8981\u51FA\u793A\u8207\u5831\u540D\u6642\u76F8\u540C\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u6B63\u672C\u3002";
var APPB2C_cj_idDetails_noteAboutIdentification4 = "\u5728\u8003\u8A66\u7576\u5929, \u60A8\u5C07\u88AB\u8981\u6C42\u651C\u5E36\u8207\u5831\u540D\u6642\u76F8\u540C\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u6B63\u672C\u3002";
var APPB2C_cj_idDetails_noteAboutIdentification_child4 = "\u5728\u8003\u8A66\u7576\u5929, \u60A8\u7684\u5B69\u5B50\u5C07\u88AB\u8981\u6C42\u651C\u5E36\u8207\u5831\u540D\u6642\u76F8\u540C\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u3002";
var APPB2C_cj_idDetails_noteAboutIdentificationIol4 = "\u8B77\u7167\u662F\u96C5\u601D\u7DDA\u4E0A\u8003\u8A66\u76EE\u524D\u552F\u4E00\u63A5\u53D7\u7684\u8EAB\u4EFD\u8B49\u4EF6, \u82E5\u60A8\u5E0C\u671B\u4F7F\u7528\u5176\u4ED6\u53EF\u63A5\u53D7\u7684\u8EAB\u4EFD\u8B49\u4EF6, \u8ACB\u9078\u64C7\u96C5\u601D\u7D19\u7B46\u8003\u8A66\u6216\u96C5\u601D\u96FB\u8166\u8003\u8A66\u3002";
var APPB2C_cj_idDetails_noteAboutIdentificationIolLink4 = "\u82E5\u60A8\u6C92\u6709\u6709\u6548\u8B77\u7167, \u8ACB\u4F7F\u7528\u6B64\u9023\u7D50\u5831\u540D\u5176\u4ED6\u8003\u8A66\u3002";
var APPB2C_cj_idDetails_noteAboutIdentificationUol4 = "\u8B77\u7167\u662F\u96C5\u601D UKVI \u7DDA\u4E0A\u8003\u8A66\u76EE\u524D\u552F\u4E00\u63A5\u53D7\u7684\u8EAB\u4EFD\u8B49\u4EF6\u3002\u82E5\u60A8\u5E0C\u671B\u4F7F\u7528\u5176\u4ED6\u53EF\u63A5\u53D7\u7684\u8EAB\u4EFD\u8B49\u4EF6, \u8ACB\u9078\u64C7\u96C5\u601D UKVI \u7D19\u7B46\u8003\u8A66\u6216\u96C5\u601D UKVI \u96FB\u8166\u8003\u8A66\u3002";
var APPB2C_cj_idDetails_title4 = "\u8A73\u7D30\u8EAB\u4EFD\u8CC7\u8A0A";
var APPB2C_cj_idDetails_title_child4 = "\u60A8\u5B69\u5B50\u7684\u8EAB\u4EFD\u8B49\u660E";
var APPB2C_cj_idDetails_typeNames_card4 = "\u8EAB\u4EFD\u8B49";
var APPB2C_cj_idDetails_typeNames_other4 = "\u8EAB\u4EFD\u8B49";
var APPB2C_cj_idDetails_typeNames_passport4 = "\u8B77\u7167";
var APPB2C_cj_idDetails_upload_fetchError4 = "\u6211\u5011\u7121\u6CD5\u8655\u7406\u60A8\u7684\u6A94\u6848, \u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_cj_idDetails_upload_file14 = "\u5716\u7247 1";
var APPB2C_cj_idDetails_upload_file24 = "\u5716 2(\u975E\u5FC5\u9078)";
var APPB2C_cj_idDetails_upload_instructions_capture4 = "\u5B8C\u6574\u64F7\u53D6\u6587\u4EF6";
var APPB2C_cj_idDetails_upload_instructions_covers4 = "\u78BA\u4FDD\u6C92\u6709\u4EFB\u4F55\u6771\u897F\u8986\u84CB\u60A8\u7684\u6587\u4EF6(\u624B\u6307\u3001\u8CBC\u7D19\u3001\u56DE\u5F62\u91DD\u7B49)";
var APPB2C_cj_idDetails_upload_instructions_directLight4 = "\u9060\u96E2\u76F4\u5C04\u5149\u4EE5\u907F\u514D\u7729\u5149\u3002";
var APPB2C_cj_idDetails_upload_instructions_noBlur4 = "\u78BA\u4FDD\u7167\u7247/\u6383\u63CF\u6E05\u6670\u5C0D\u7126, \u6C92\u6709\u6A21\u7CCA\u3002";
var APPB2C_cj_idDetails_upload_instructions_title4 = "\u5982\u4F55\u62CD\u651D\u5408\u9069\u7684\u7167\u7247/\u6383\u63CF: ";
var APPB2C_cj_idDetails_upload_mobile4 = "\u82E5\u60A8\u76EE\u524D\u4F7F\u7528\u884C\u52D5\u88DD\u7F6E\u64CD\u4F5C, \u6211\u5011\u5EFA\u8B70\u60A8\u5F9E\u8A72\u88DD\u7F6E\u4E0A\u50B3\u7167\u7247\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_cover4 = "\u78BA\u4FDD\u60A8\u6C92\u6709\u906E\u84CB\u8B77\u7167\u6578\u64DA(\u624B\u6307\u3001\u56DE\u5F62\u91DD\u7B49)\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_crop4 = "\u5C07\u60A8\u7684\u8B77\u7167\u653E\u5728\u5E73\u5766\u7684\u8868\u9762\u4E0A, \u4E26\u78BA\u4FDD\u6578\u64DA\u53EF\u898B\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions4 = "\u8ACB\u6309\u7167\u4EE5\u4E0B\u8AAA\u660E\u64CD\u4F5C, \u78BA\u4FDD\u60A8\u7684\u7167\u7247\u7B26\u5408\u6211\u5011\u7684\u8981\u6C42\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_glare4 = "\u7167\u7247\u9700\u8981\u6E05\u6670, \u6C92\u6709\u53CD\u5149, \u78BA\u4FDD\u6240\u6709\u6578\u64DA\u90FD\u662F\u53EF\u8B80\u7684\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_watermark4 = "\u78BA\u4FDD\u5728\u76F8\u6A5F\u8A2D\u7F6E\u4E2D\u7981\u7528\u5716\u50CF\u6D6E\u6C34\u5370\u3002";
var APPB2C_cj_idDetails_upload_title4 = "\u4E0A\u50B3$t\u8B49\u660E({{idName}})";
var APPB2C_cj_ieltsReady_description_line14 = "\u8A2A\u554F\u8C50\u5BCC\u7684\u96C5\u601D\u6E96\u5099\u5B78\u7FD2\u6750\u6599, \u6DB5\u84CB\u8A9E\u6CD5\u3001\u8A5E\u5F59\u3001\u95B1\u8B80\u3001\u807D\u529B\u7B49\u3002\u63D0\u9AD8\u60A8\u7684\u96C5\u601D\u8003\u8A66\u6280\u80FD, \u7121\u8AD6\u662F\u55AE\u7368\u5B78\u7FD2\u9084\u662F\u5728\u5C08\u5BB6\u6307\u5C0E\u4E0B\u5B78\u7FD2\u3002\u901A\u904E IELTS Ready \u7372\u5F97\u60A8\u60F3\u8981\u7684\u5206\u6578\u3002";
var APPB2C_cj_ieltsReady_description_line24 = "\u795D\u4F60\u597D\u904B\uFF01";
var APPB2C_cj_ieltsReady_dobMin4 = "\u60A8\u9700\u8981\u5E74\u6EFF 18 \u6B72\u624D\u80FD\u5275\u5EFA\u5E33\u6236\u3002\u60A8\u9700\u8981\u60A8\u7684\u7236\u6BCD\u6216\u76E3\u8B77\u4EBA\u624D\u80FD\u9032\u5165\u96C5\u601D\u5099\u8003\u6703\u54E1\u5C08\u5340\u3002";
var APPB2C_cj_ieltsReady_nameWarning4 = "\u7576\u60A8\u5831\u540D\u53C3\u52A0\u96C5\u601D\u8003\u8A66\u6642, \u60A8\u7684\u59D3\u540D\u5FC5\u9808\u8207\u60A8\u7684\u5B98\u65B9\u8EAB\u4EFD\u8B49\u660E\u6A94<bold>\u76F8\u7B26</bold>, \u8ACB\u78BA\u4FDD\u73FE\u5728\u6B63\u78BA\u7121\u8AA4, \u4EE5\u7BC0\u7701\u4E4B\u5F8C\u7684\u6642\u9593\u3002";
var APPB2C_cj_ieltsReady_note4 = "\u60A8\u9700\u8981\u4E00\u500B\u5E33\u6236\u624D\u80FD\u8A2A\u554F <0>IELTS Ready \u6703\u54E1\u5C08\u5340.</0>";
var APPB2C_cj_ieltsReady_personalDetails_title3 = "\u95DC\u65BC\u4F60";
var APPB2C_cj_ieltsReady_submitBtnText4 = "\u8A3B\u518A\u5E33\u865F";
var APPB2C_cj_ieltsReady_targetScore_option4 = "\u6211\u9084\u4E0D\u77E5\u9053";
var APPB2C_cj_ieltsReady_targetScore_title4 = "\u96C5\u601D\u8003\u8A66\u7684\u7E3D\u9AD4\u76EE\u6A19\u5206\u6578\u662F\u591A\u5C11\uFF1F";
var APPB2C_cj_ieltsReady_title4 = "\u8A3B\u518A\u5E33\u865F";
var APPB2C_cj_incorrectOrganisation_bookNewLink4 = "\u5728\u6B64\u7DB2\u7AD9\u5831\u540D\u8003\u8A66(\u6211\u5011\u5C07\u5354\u52A9\u60A8\u627E\u5230\u5408\u9069\u8003\u5834)";
var APPB2C_cj_incorrectOrganisation_line14 = "\u770B\u8D77\u4F86\u60A8\u6B63\u5728\u5411\u7121\u6CD5\u8B58\u5225\u7684\u6A5F\u69CB\u5831\u540D\u8003\u8A66\u3002";
var APPB2C_cj_incorrectOrganisation_line24 = "\u4E0D\u7528\u64D4\u5FC3\uFF1B\u60A8\u53EF\u4EE5\u5617\u8A66\u4EE5\u4E0B\u5E7E\u7A2E\u65B9\u6CD5: ";
var APPB2C_cj_incorrectOrganisation_locationsLink4 = "\u5728\u96C5\u601D\u7DB2\u7AD9\u4E0A\u67E5\u770B\u6211\u5011\u5728\u5168\u7403\u7684\u64DA\u9EDE";
var APPB2C_cj_incorrectOrganisation_locationsLinkUsa4 = "\u5728\u96C5\u601D\u7DB2\u7AD9\u4E0A\u67E5\u770B\u7F8E\u570B\u6240\u6709\u7684\u8003\u8A66\u4E2D\u5FC3";
var APPB2C_cj_incorrectOrganisation_title4 = "\u62B1\u6B49\uFF01";
var APPB2C_cj_marketingPrefs4 = "\u60A8\u7684\u884C\u92B7\u504F\u597D";
var APPB2C_cj_onRequest_created_back4 = "< \u8FD4\u56DE\u6240\u6709\u7C3D\u8B49\u96C5\u601D\u6E2C\u9A57\u9078\u9805";
var APPB2C_cj_onRequest_created_in4 = "\u5728";
var APPB2C_cj_onRequest_created_info4 = "\u6211\u5011\u5C07\u5BE9\u6838\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A, \u4E26\u65BC 48-72 \u5C0F\u6642\u5167\u8207\u60A8\u806F\u7E6B\u4EE5\u8DDF\u9032\u6B64\u7533\u8ACB\u3002";
var APPB2C_cj_onRequest_created_note4 = "\u6211\u5011\u5DF2\u7D93\u8A3B\u518A\u4E86\u60A8\u53C3\u52A0\u7528\u65BCUKVI\u7684\u96C5\u601D\u8003\u8A66\u7684\u8208\u8DA3";
var APPB2C_cj_onRequest_created_title4 = "\u5DF2\u6536\u5230\u60A8\u7684\u8ACB\u6C42";
var APPB2C_cj_onRequest_form_labels_confirmEmail4 = "\u78BA\u8A8D\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_cj_onRequest_form_labels_email4 = "\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_cj_onRequest_form_labels_firstname4 = "\u540D\u7A31\uFF0F\u540D\u5B57(\u5305\u62EC\u4E2D\u9593\u540D)";
var APPB2C_cj_onRequest_form_labels_surname4 = "\u59D3\uFF0F\u59D3\u6C0F";
var APPB2C_cj_onRequest_form_labels_telephone4 = "\u624B\u6A5F\u865F\u78BC";
var APPB2C_cj_onRequest_form_labels_testRangeQuestion4 = "\u60A8\u60F3\u591A\u5FEB\u53C3\u52A0\u8003\u8A66\uFF1F";
var APPB2C_cj_onRequest_form_submit4 = "\u8A3B\u518A\u6211\u7684\u8208\u8DA3";
var APPB2C_cj_onRequest_form_testRangeOpt_oneMonth4 = "\u5728\u63A5\u4E0B\u4F86\u768428\u5929\u5167";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonths4 = "\u672A\u4F863\u500B\u6708\u5167";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus4 = "\u57283\u500B\u6708\u4E4B\u5F8C";
var APPB2C_cj_onRequest_form_testRangeOpt_twoMonths4 = "\u672A\u4F862\u500B\u6708\u5167";
var APPB2C_cj_onRequest_info14 = "\u586B\u5BEB\u8868\u683C\u4EE5\u8A3B\u518A\u60A8\u5C0D\u53C3\u52A0\u7528\u65BCUKVI\u7684\u96C5\u601D\u8003\u8A66\u7684\u8208\u8DA3\u3002\u6211\u5011\u5C07\u5BE9\u6838\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A, \u4E26\u8207\u60A8\u806F\u7E6B\u4EE5\u8DDF\u9032\u6B64\u8ACB\u6C42\u3002";
var APPB2C_cj_onRequest_note4 = "\u76EE\u524D\u6C92\u6709\u9069\u7528\u65BCUKVI\u7684\u96C5\u601D\u8003\u8A66\u65E5\u671F\u3002";
var APPB2C_cj_onRequest_otherLocations_choose4 = "\u9078\u64C7\u5176\u4ED6\u4F4D\u7F6E";
var APPB2C_cj_onRequest_otherLocations_hide4 = "-\u96B1\u85CF";
var APPB2C_cj_onRequest_otherLocations_info14 = "\u60A8\u6709\u5169\u7A2E\u65B9\u5F0F\u4F86\u5831\u540D\u6E2C\u9A57: ";
var APPB2C_cj_onRequest_otherLocations_info24 = "\u6211\u5011\u5728\u4EE5\u4E0B\u5730\u9EDE\u8209\u8FA6\u7C3D\u8B49\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_cj_onRequest_otherLocations_show4 = "+ \u986F\u793A\u66F4\u591A";
var APPB2C_cj_onRequest_please4 = "\u8ACB\u6CE8\u610F";
var APPB2C_cj_onRequest_prefNote4 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u4EE5\u60A8\u7684\u9996\u9078\u70BA\u7B2C\u4E00\u512A\u5148\u8003\u91CF\u3002\u5982\u679C\u60A8\u7684\u9996\u9078\u4E0D\u9069\u7528, \u5C07\u70BA\u60A8\u63D0\u4F9B\u66FF\u4EE3\u9078\u9805\u3002";
var APPB2C_cj_onRequest_subtitles_aboutPreferences4 = "\u95DC\u65BC\u60A8\u7684\u6E2C\u9A57\u504F\u597D";
var APPB2C_cj_onRequest_subtitles_aboutYou4 = "\u95DC\u65BC\u4F60";
var APPB2C_cj_onRequest_subtitles_markpref4 = "\u60A8\u7684\u884C\u92B7\u504F\u597D";
var APPB2C_cj_onRequest_subtitles_register4 = "\u9078\u64C7\u60A8\u6709\u8208\u8DA3\u7684\u8003\u8A66\u5831\u540D";
var APPB2C_cj_personalDetails_error_doubleBooking4 = "\u8003\u751F\u5DF2\u65BC\u540C\u65E5\u5831\u540D\u53C3\u52A0\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_cj_personalDetails_error_missingAddress4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u90F5\u5BC4\u4F4D\u5740\u3002";
var APPB2C_cj_personalDetails_error_missingEmail4 = "\u8ACB\u8F38\u5165\u6709\u6548\u7684\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740\u3002";
var APPB2C_cj_personalDetails_error_missingEmailConfirm4 = "\u8ACB\u91CD\u65B0\u8F38\u5165\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6\u5730\u5740\u4EE5\u78BA\u8A8D\u8A72\u4F4D\u5740\u3002";
var APPB2C_cj_personalDetails_error_missingFirstName4 = "\u8ACB\u8F38\u5165\u60A8\u7684\u540D\u5B57/\u540D\u5B57\u3002";
var APPB2C_cj_personalDetails_error_missingMobile4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u624B\u6A5F\u865F\u78BC\u3002";
var APPB2C_cj_personalDetails_error_missingSex4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u6027\u5225\u3002";
var APPB2C_cj_personalDetails_error_missingState4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u72C0\u614B\u3002";
var APPB2C_cj_personalDetails_error_missingSurname4 = '\u8ACB\u8F38\u5165\u60A8\u7684\u59D3\u6C0F/\u59D3\u6C0F\u3002\u5982\u679C\u60A8\u6C92\u6709\u59D3\u6C0F, \u8ACB\u5728\u6B64\u5B57\u6BB5\u4E2D\u8F38\u5165\u9023\u5B57\u5143("-")\u3002';
var APPB2C_cj_personalDetails_error_missingTown4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u57CE\u93AE/\u57CE\u5E02\u3002";
var APPB2C_cj_personalDetails_error_missingZip4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u90F5\u905E\u5340\u5340\u865F/\u90F5\u905E\u5340\u865F\u7DE8\u78BC\u3002";
var APPB2C_cj_personalDetails_error_wrongDob4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u5BE6\u969B\u51FA\u751F\u65E5\u671F\u3002";
var APPB2C_cj_personalDetails_error_wrongEmailConfirm4 = "\u91CD\u65B0\u8F38\u5165\u7684\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740\u8207\u60A8\u63D0\u4F9B\u7684\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740\u4E0D\u5339\u914D\u3002\u8ACB\u4ED4\u7D30\u6AA2\u67E5\u60A8\u7684\u62FC\u5BEB\u3002";
var APPB2C_cj_personalDetails_examTakenModal_cta4 = "\u627E\u65B0\u7684\u8003\u8A66";
var APPB2C_cj_personalDetails_examTakenModal_message4 = "\u4E0D\u904E\u5225\u64D4\u5FC3, \u6211\u5011\u9084\u6709\u5F88\u591A\u5176\u4ED6\u8003\u8A66\u4F9B\u60A8\u9078\u64C7, \u6211\u5011\u4E0D\u6703\u5FD8\u8A18\u60A8\u525B\u525B\u8F38\u5165\u7684\u8CC7\u8A0A\u3002";
var APPB2C_cj_personalDetails_examTakenModal_title4 = "\u5F88\u62B1\u6B49, \u60A8\u9078\u64C7\u7684\u6E2C\u9A57\u5DF2\u7121\u6CD5\u5831\u540D";
var APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse4 = "\u70BA\u4ED6\u4EBA\u9810\u7D04\u8003\u8A66";
var APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate4 = "\u9078\u64C7\u5176\u4ED6\u8003\u8A66\u65E5\u671F";
var APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle4 = "\u4E0D\u8981\u5FD8\u8A18\u60A8\u5DF2\u7D93\u5831\u540D\u4E86\u8003\u8A66";
var APPB2C_cj_personalDetails_existingRegistrationsModal_title4 = "\u60A8\u5DF2\u7D93\u5728\u6B64\u65E5\u671F\u5DF2\u5831\u540D\u4E86\u8003\u8A66\uFF01";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking4 = "\u60A8\u53EF\u4EE5\u5728\u8003\u751F\u7CFB\u7D71\u5167\u6AA2\u8996\u5DF2\u5831\u540D\u7684\u8003\u8A66";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking4 = "\u67E5\u770B\u6211\u76EE\u524D\u7684\u5831\u540D";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest4 = "\u60A8\u7684\u6E2C\u9A57";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest4 = "\u60A8\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest4 = "\u60A8\u7684\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_cj_personalDetails_forms_address_country4 = "\u5C45\u4F4F\u570B\u5BB6/\u5730\u5340";
var APPB2C_cj_personalDetails_forms_address_enterManually4 = "\u624B\u52D5\u8F38\u5165\u5730\u5740";
var APPB2C_cj_personalDetails_forms_address_postal4 = "\u90F5\u4EF6\u5730\u5740";
var APPB2C_cj_personalDetails_forms_address_state4 = "\u5DDE";
var APPB2C_cj_personalDetails_forms_address_town4 = "\u9109\u93AE / \u57CE\u5E02";
var APPB2C_cj_personalDetails_forms_address_zip4 = "\u90F5\u905E\u5340\u865F ";
var APPB2C_cj_personalDetails_forms_contact_confirmEmail4 = "\u78BA\u8A8D\u96FB\u5B50\u90F5\u4EF6\u5730\u5740";
var APPB2C_cj_personalDetails_forms_contact_email4 = "\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_cj_personalDetails_forms_contact_emailExist4 = "\u6B64\u96FB\u5B50\u90F5\u4EF6\u5DF2\u88AB\u4F7F\u7528\u3002\u82E5\u662F\u60A8, \u8ACB\u5617\u8A66\u767B\u9304\u3002";
var APPB2C_cj_personalDetails_forms_contact_emailInUse4 = "\u6B64\u96FB\u5B50\u90F5\u4EF6\u5DF2\u88AB\u4F7F\u7528\u3002\u82E5\u662F\u60A8, \u8ACB\u5617\u8A66\u767B\u9304\u3002";
var APPB2C_cj_personalDetails_forms_contact_mobile4 = "\u624B\u6A5F\u865F\u78BC";
var APPB2C_cj_personalDetails_forms_contact_note4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u96FB\u8A71\u865F\u78BC\u548C\u90F5\u4EF6\u5730\u5740, \u4EE5\u4FBF\u6211\u5011\u9700\u8981\u8207\u60A8\u806F\u7E6B\u6216\u5BC4\u9001\u6587\u4EF6(\u4F8B\u5982\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE)\u3002";
var APPB2C_cj_personalDetails_forms_contact_password4 = "\u5BC6\u78BC";
var APPB2C_cj_personalDetails_forms_contact_smsConsent4 = "\u6211\u540C\u610F\u4F7F\u7528\u9019\u500B\u624B\u6A5F\u865F\u78BC\u4F5C\u70BA\u63A5\u6536\u7C21\u8A0A\u3001WhatsApp \u53CA\u806F\u7E6B\u6211\u6709\u95DC\u65BC\u6211\u7684\u8003\u8A66\u548C\u6210\u7E3E\u7684\u76F8\u95DC\u8A0A\u606F \u3002";
var APPB2C_cj_personalDetails_forms_contact_smsNote4 = "<bold>\u8ACB\u6CE8\u610F: </bold>\u60A8\u6240\u5728\u7684\u4F4D\u7F6E\u53EF\u80FD\u7121\u6CD5\u63D0\u4F9B\u6B64\u670D\u52D9\u3002";
var APPB2C_cj_personalDetails_forms_contact_title4 = "\u60A8\u7684\u806F\u7E6B\u65B9\u5F0F";
var APPB2C_cj_personalDetails_forms_personal_change4 = "\u7570\u52D5";
var APPB2C_cj_personalDetails_forms_personal_dob4 = "\u751F\u65E5";
var APPB2C_cj_personalDetails_forms_personal_dobCta4 = "\u5728\u8003\u8A66\u4E2D\u5FC3\u5831\u540D\u96C5\u601D\u8003\u8A66";
var APPB2C_cj_personalDetails_forms_personal_firstMiddleName4 = "\u540D\u7A31/\u540D\u5B57(\u5305\u62EC\u4E2D\u9593\u540D)";
var APPB2C_cj_personalDetails_forms_personal_firstName4 = "\u540D\u7A31/\u540D\u5B57";
var APPB2C_cj_personalDetails_forms_personal_idMatch4 = "\u9019\u5FC5\u9808\u8207\u60A8\u8EAB\u4EFD\u8B49\u4EF6\u4E0A\u7684\u59D3\u540D\u4E00\u81F4";
var APPB2C_cj_personalDetails_forms_personal_lastName4 = "\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_personalDetails_forms_personal_name4 = "\u59D3\u540D";
var APPB2C_cj_personalDetails_forms_personal_noLastName4 = "\u6211\u6C92\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_personalDetails_forms_personal_sectionTitle4 = "\u95DC\u65BC\u4F60";
var APPB2C_cj_personalDetails_forWho_child4 = "\u6211\u7684\u5B50\u5973";
var APPB2C_cj_personalDetails_forWho_childUnder184 = "(18\u6B72\u4EE5\u4E0B)";
var APPB2C_cj_personalDetails_forWho_dob4 = "\u751F\u65E5: ";
var APPB2C_cj_personalDetails_forWho_myself4 = "\u672C\u4EBA";
var APPB2C_cj_personalDetails_forWho_note4 = "\u60A8\u4E0D\u80FD\u70BA 11 \u6B72\u4EE5\u4E0B\u7684\u5152\u7AE5\u5831\u540D\u8003\u8A66\u3002\u5982\u679C\u60A8\u7684\u5B69\u5B50\u5E74\u6EFF 18 \u6B72, \u8ACB\u8B93\u4ED6\u5011\u81EA\u884C\u8A3B\u518A\u3002";
var APPB2C_cj_personalDetails_forWho_title4 = "\u60A8\u70BA\u8AB0\u5831\u540D\u8003\u8A66\uFF1F";
var APPB2C_cj_personalDetails_gender4 = "\u6027\u5225: ";
var APPB2C_cj_personalDetails_idNote_idMatch4 = "\u6B64\u9801\u9762\u4E0A\u63D0\u4F9B\u7684\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u4FE1\u606F\u5FC5\u9808\u8207\u7528\u65BC\u8A3B\u518A\u4E26\u5728\u8003\u8A66\u5730\u9EDE\u51FA\u793A\u7684\u61C9\u8A66\u8005\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u8EAB\u4EFD\u8B49\u660E\u6A94\u5C07\u986F\u793A\u5728\u61C9\u8A66\u8005\u7684\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u5982\u679C\u60A8\u5728\u6211\u5011\u7C3D\u767C\u6210\u7E3E\u55AE\u4E4B\u524D\u8981\u6C42\u6211\u5011\u4FEE\u6539\u4EFB\u4F55\u6B64\u985E\u8CC7\u8A0A, \u60A8\u53EF\u80FD\u6703\u88AB\u6536\u53D6\u8CBB\u7528\u3002\u8ACB\u6CE8\u610F, \u6210\u7E3E\u55AE\u767C\u51FA\u540E\u4E0D\u5F97\u9032\u884C\u4EFB\u4F55\u4FEE\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_change4 = "\u5168\u540D\u548C\u51FA\u751F\u65E5\u671F<bold>\u5FC5\u9808\u8207\u60A8</bold>\u5728\u8003\u8A66\u7576\u5929\u51FA\u793A\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_france3 = '\u8ACB\u78BA\u4FDD\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u8207\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u6587\u4EF6\u5C07\u986F\u793A\u5728\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE "("TRF")" \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u66F4\u6539\u500B\u4EBA\u8A73\u7D30\u8A0A\u606F\u7684\u8CBB\u7528\u3002\u8ACB\u6CE8\u610F, \u6210\u7E3E\u516C\u4F48\u5F8C, \u7121\u6CD5\u66F4\u6539\u4EFB\u4F55\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u3002';
var APPB2C_cj_personalDetails_idNote_idMatch_france_adult3 = "\u8ACB\u78BA\u4FDD\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u8207\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u6587\u4EF6\u5C07\u986F\u793A\u5728\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u66F4\u6539\u500B\u4EBA\u8A73\u7D30\u4FE1\u606F\u7684\u8CBB\u7528\u3002\u8ACB\u6CE8\u610F, \u7D50\u679C\u767C\u4F48\u5F8C, \u7121\u6CD5\u66F4\u6539\u4EFB\u4F55\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_france_minor3 = "\u8ACB\u78BA\u4FDD\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u8207\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u672A\u6210\u5E74\u4EBA\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u6587\u4EF6\u5C07\u986F\u793A\u5728\u5176\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u66F4\u6539\u5176\u500B\u4EBA\u8A73\u7D30\u4FE1\u606F\u7684\u8CBB\u7528\u3002\u8ACB\u6CE8\u610F, \u7D50\u679C\u767C\u4F48\u540E, \u7121\u6CD5\u66F4\u6539\u4EFB\u4F55\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_spain3 = "\u8ACB\u78BA\u4FDD\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u8207\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u6587\u4EF6\u5C07\u986F\u793A\u5728\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u5C07\u7121\u6CD5\u5C0D\u60A8\u7684\u500B\u4EBA\u8CC7\u8A0A\u9032\u884C\u4EFB\u4F55\u66F4\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_adult3 = "\u8ACB\u78BA\u4FDD\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u8207\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u6587\u4EF6\u5C07\u986F\u793A\u5728\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u5C07\u7121\u6CD5\u5C0D\u60A8\u7684\u500B\u4EBA\u8CC7\u8A0A\u9032\u884C\u4EFB\u4F55\u66F4\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_minor3 = "\u8ACB\u78BA\u4FDD\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u8207\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u672A\u6210\u5E74\u4EBA\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u6587\u4EF6\u5C07\u986F\u793A\u5728\u5176\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u5C07\u7121\u6CD5\u5C0D\u5176\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A\u9032\u884C\u4EFB\u4F55\u66F4\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_whenForChild4 = "\u6211\u5011\u5C07\u5728\u4E0B\u4E00\u9801\u8A62\u554F\u6709\u95DC\u60A8\u5B50\u5973\u7684\u8CC7\u8A0A";
var APPB2C_cj_personalDetails_login_btn4 = "\u767B\u5165";
var APPB2C_cj_personalDetails_login_emailNotEditable4 = "\u5275\u5EFA\u5E33\u6236\u5F8C, \u60A8\u5C07\u7121\u6CD5\u7DE8\u8F2F\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740\u3002\u8981\u66F4\u6539\u6B64\u8A2D\u7F6E, \u8ACB<1>\u806F\u7E6B\u6211\u5011</1>";
var APPB2C_cj_personalDetails_login_link4 = "\u5DF2\u7D93\u6709\u5E33\u6236\u4E86\uFF1F<1>\u767B\u9304</1>";
var APPB2C_cj_personalDetails_login_loggedIn4 = "\u4F60\u597D\uFF01\u60A8\u73FE\u5728\u5DF2\u767B\u5165\u3002";
var APPB2C_cj_personalDetails_login_logout4 = "\u4E0D\u662F\u4F60\u7684\u8A73\u7D30\u8CC7\u8A0A\uFF1F<1>\u8A3B\u92B7</1>";
var APPB2C_cj_personalDetails_login_note4 = "\u60A8\u9700\u8981\u4E00\u500B\u5E33\u865F\u4F86\u7BA1\u7406\u60A8\u7684\u5831\u540D\u7D00\u9304\u3002\u7576\u8003\u8A66\u5831\u540D\u5B8C\u6210\u5F8C, \u60A8\u5C07\u6536\u5230\u4E00\u5C01\u96FB\u5B50\u90F5\u4EF6, \u8AAA\u660E\u5982\u4F55\u767B\u5165\u60A8\u7684\u7DDA\u4E0A\u5831\u540D\u7CFB\u7D71\u3002";
var APPB2C_cj_personalDetails_login_title4 = "\u5EFA\u7ACB\u4E00\u500B\u5E33\u865F";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate4 = "\u627E\u65B0\u7684\u8003\u8A66";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line14 = "\u60A8\u7121\u6CD5\u5728 {{nearestRegistratonExamDate}} \u4E0A\u9810\u8A02\u8207\u5176\u4ED6\u8003\u8A66\u65E5\u671F\u904E\u65BC\u63A5\u8FD1\u7684IELTS\u7DDA\u4E0A\u8003\u8A66\u3002";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line24 = "\u8ACB\u9078\u64C7\u524D\u4E00\u6B21\u8003\u8A66\u65E5\u671F\u524D\u5F8C\u4E94\u5929\u4EE5\u5916\u7684\u8003\u671F\u3002";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title4 = "\u8ACB\u9078\u64C7\u5176\u4ED6\u7684\u8003\u8A66\u65E5\u671F";
var APPB2C_cj_personalDetails_title4 = "\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_cj_personalDetails_tncs_agreement4 = "\u6211\u540C\u610F\u8A3B\u518A\u5E33\u6236<1>\u689D\u6B3E\u548C\u689D\u4EF6</1>";
var APPB2C_cj_personalDetails_tncs_title4 = "\u6211\u5011\u7684\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_cj_personalDetails_visaType_label4 = "\u60A8\u9700\u8981\u4EC0\u9EBC\u985E\u578B\u7684\u7C3D\u8B49\uFF1F";
var APPB2C_cj_personalDetails_visaType_label_child4 = "\u4ED6\u5011\u9700\u8981\u4EC0\u9EBC\u7C3D\u8B49\u985E\u578B\uFF1F";
var APPB2C_cj_personalDetails_visaType_link4 = "\u77AD\u89E3\u6709\u95DC\u7C3D\u8B49\u985E\u578B\u7684\u66F4\u591A\u8CC7\u8A0A";
var APPB2C_cj_personalDetails_visaType_title4 = "\u60A8\u7684\u7C3D\u8B49\u985E\u578B";
var APPB2C_cj_personalDetails_visaType_title_child4 = "\u4ED6\u5011\u7684\u7C3D\u8B49\u985E\u578B";
var APPB2C_cj_rebook_errorModal_message4 = "\u7CFB\u7D71\u51FA\u73FE\u7570\u5E38, \u8ACB\u65BC\u5E7E\u5206\u9418\u5F8C\u518D\u6B21\u5617\u8A66\u3002";
var APPB2C_cj_rebook_errorModal_title4 = "\u5831\u540D\u5931\u6557";
var APPB2C_cj_review_acknowledgement_anotherPerson4 = "\u4ED6\u4EBA";
var APPB2C_cj_review_acknowledgement_company4 = "\u4E00\u5BB6\u516C\u53F8";
var APPB2C_cj_review_acknowledgement_fields_companyName4 = "\u516C\u53F8\u540D\u7A31";
var APPB2C_cj_review_acknowledgement_fields_companyName_subnote4 = "\u8ACB\u8F38\u5165\u516C\u53F8\u5168\u540D";
var APPB2C_cj_review_acknowledgement_fields_country4 = "\u570B\u5BB6/\u5730\u5340";
var APPB2C_cj_review_acknowledgement_fields_email4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_cj_review_acknowledgement_fields_familyName4 = "\u59D3";
var APPB2C_cj_review_acknowledgement_fields_firstName4 = "\u5176\u4ED6/\u540D\u5B57";
var APPB2C_cj_review_acknowledgement_fields_mobile4 = "\u96FB\u8A71\u865F\u78BC";
var APPB2C_cj_review_acknowledgement_myself4 = "\u672C\u4EBA";
var APPB2C_cj_review_acknowledgement_send4 = "\u8AB0\u61C9\u8A72\u6536\u5230\u8003\u8A66\u5831\u540D\u7684\u8A02\u55AE\u78BA\u8A8D\uFF1F";
var APPB2C_cj_review_acknowledgement_title4 = "\u8A02\u55AE\u78BA\u8A8D";
var APPB2C_cj_review_agent_label4 = "\u8ACB\u63D0\u4F9B\u60A8\u4EE3\u7406\u4EBA\u540D\u7A31";
var APPB2C_cj_review_agent_note4 = "\u8ACB\u554F\u60A8\u7684UKVI \u7533\u8ACB\u662F\u5426\u7531\u5176\u4ED6\u4EBA\u4EE3\u70BA\u4F5C\u696D\u5462\uFF1F";
var APPB2C_cj_review_agent_title4 = "\u6388\u6B0A\u5831\u540D\u4E2D\u5FC3\u540D\u7A31";
var APPB2C_cj_review_ai_note4 = "\u60A8\u662F\u5426\u9858\u610F\u533F\u540D\u63D0\u4F9B\u8003\u8A66\u7B54\u6848\u548C\u8A18\u9304\u4F5C\u70BA\u8A13\u7DF4\u76E3\u8003\u548C\u8003\u8A66\u8A55\u5206\u8EDF\u9AD4\u7684\u8CC7\u6599\uFF1F";
var APPB2C_cj_review_ai_title4 = "\u57F9\u8A13\u540C\u610F\u66F8";
var APPB2C_cj_review_bookingDetails_lrw_title4 = "\u60A8\u7684\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_cj_review_bookingDetails_lrw_title_child4 = "\u60A8\u5B69\u5B50\u7684\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills4 = "\u60A8\u7684\u6E2C\u9A57";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child4 = "\u4F60\u7684\u5B69\u5B50\u7684\u6E2C\u9A57";
var APPB2C_cj_review_bookingDetails_speaking_title4 = "\u60A8\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_cj_review_bookingDetails_speaking_title_child4 = "\u60A8\u5B69\u5B50\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_cj_review_bookingDetails_title4 = "\u60A8\u6B63\u5728\u5831\u540D";
var APPB2C_cj_review_bookNowNoFee_buttonText4 = "\u7ACB\u5373\u9810\u8A02";
var APPB2C_cj_review_bookTest4 = "\u8003\u8A66\u5831\u540D";
var APPB2C_cj_review_change4 = "\u7570\u52D5";
var APPB2C_cj_review_changeLrwTest4 = "\u66F4\u6539\u7B46\u8A66\u65E5\u671F";
var APPB2C_cj_review_changeSpeakingTest4 = "\u66F4\u6539\u6F14\u8B1B\u6642\u7A0B\u8868";
var APPB2C_cj_review_changeUkviLsTest4 = "\u66F4\u6539\u8003\u8A66\u65E5\u671F";
var APPB2C_cj_review_childDetails_guardiansTitle4 = "\u5C07\u63A5\u9001\u60A8\u5B69\u5B50\u7684\u4EBA";
var APPB2C_cj_review_childDetails_onTestDay4 = "\u8003\u8A66\u7576\u5929";
var APPB2C_cj_review_childDetails_onTestDayOptions_14 = "\u5B69\u7AE5\u53EF\u4EE5\u81EA\u884C\u96E2\u958B\u8003\u5834";
var APPB2C_cj_review_childDetails_onTestDayOptions_24 = "\u6211\u6703\u5F9E\u81EA\u884C\u63A5\u9001\u6211\u7684\u5B69\u5B50";
var APPB2C_cj_review_childDetails_onTestDayOptions_34 = "\u5176\u4ED6\u4EBA\u6703\u5F9E\u8003\u5834\u63A5\u9001\u6211\u7684\u5B69\u5B50";
var APPB2C_cj_review_childDetails_title4 = "\u60A8\u5B69\u5B50\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_cj_review_confirmPaymentModal_btnNote4 = "\u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u6BD4\u5217\u51FA\u7684 <0></0> \u66F4\u591A\u7684\u8CBB\u7528";
var APPB2C_cj_review_confirmPaymentModal_note4 = "\u8ACB\u6CE8\u610F: \u60A8\u7684\u9280\u884C\u53EF\u80FD\u6703\u5728\u96C5\u601D\u8003\u8A66\u7DDA\u4E0A\u4ED8\u6B3E\u6642\u6536\u53D6\u984D\u5916\u8CBB\u7528\u3002";
var APPB2C_cj_review_confirmPaymentModal_optionsTitle4 = "\u60A8\u6709\u5169\u7A2E\u9078\u64C7\u3002";
var APPB2C_cj_review_confirmPaymentModal_title4 = "\u7DDA\u4E0A\u652F\u4ED8\u53EF\u80FD\u6703\u652F\u51FA\u66F4\u591A";
var APPB2C_cj_review_countryMsg_pakistan4 = "\u7531\u65BC\u532F\u7387\u6CE2\u52D5, \u4F7F\u7528\u4FE1\u7528\u5361\u652F\u4ED8\u7684\u96C5\u601D\u8CBB\u7528\u53EF\u80FD\u8207\u4E0A\u8FF0\u50F9\u683C\u4E0D\u540C\u3002\u7DDA\u4E0A\u4EA4\u6613\u5C07\u4EE5\u5DF4\u57FA\u65AF\u5766- \u76E7\u6BD4\u4F5C\u70BA\u5916\u5E63\u7D50\u7B97\u532F\u7387\u3002";
var APPB2C_cj_review_error_missingOrderAcknowledgement4 = "\u8ACB\u6307\u5B9A\u8AB0\u61C9\u8A72\u6536\u5230\u6E2C\u8A66\u9810\u8A02\u7684\u8A02\u55AE\u78BA\u8A8D\u3002";
var APPB2C_cj_review_error_missingPickups4 = "\u8ACB\u8A3B\u660E\u8AB0\u5C07\u5F9E\u8003\u8A66\u5834\u6240\u63A5\u60A8\u7684\u5B69\u5B50\u3002";
var APPB2C_cj_review_error_missingTown4 = "\u8ACB\u66F4\u6539\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A, \u4E26\u5728\u60A8\u7684\u4F4D\u5740\u4E2D\u5305\u542B\u57CE\u93AE/\u57CE\u5E02\u3002";
var APPB2C_cj_review_error_wrongCountrySetting4 = "\u60A8\u6240\u5728\u7684\u570B\u5BB6/\u5730\u5340\u7121\u6CD5\u9810\u8A02\u6B64\u6E2C\u8A66\u3002\u8ACB\u9078\u64C7\u5176\u4ED6\u6E2C\u8A66\u3002";
var APPB2C_cj_review_error_wrongPaymentConfig4 = "\u4ED8\u6B3E\u914D\u7F6E\u7F3A\u5931\u6216\u4E0D\u6B63\u78BA, \u5982\u679C\u554F\u984C\u4ECD\u7136\u5B58\u5728, \u8ACB\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3\u3002";
var APPB2C_cj_review_error_wrongTaxNumber4 = "\u8ACB\u63D0\u4F9B\u6709\u6548\u7684\u589E\u503C\u7A05/\u570B\u5BB6\u5DE5\u696D\u8A31\u53EF\u8B49\u865F\u3002\u5B83\u5FC5\u9808\u7B26\u5408\u7279\u5B9A\u683C\u5F0F(\u4F8B\u5982 13243546-3-35\u30011324354635)";
var APPB2C_cj_review_expiredIdError4 = "\u8003\u751F\u7684\u8EAB\u5206\u8B49\u660E\u6587\u4EF6\u5728\u8003\u8A66\u65E5\u671F\u7576\u5929\u4ECD\u9808\u5728\u6709\u6548\u671F\u9650\u5167\u3002";
var APPB2C_cj_review_idDetails_issuing4 = "\u767C\u8B49\u6A5F\u95DC";
var APPB2C_cj_review_idDetails_nationality4 = "\u570B\u7C4D\u570B\u5BB6/\u5730\u5340";
var APPB2C_cj_review_idDetails_title4 = "\u60A8\u7684\u8EAB\u4EFD\u8B49\u660E";
var APPB2C_cj_review_idDetails_title_child4 = "\u60A8\u5B69\u5B50\u7684\u8EAB\u4EFD\u8B49\u660E";
var APPB2C_cj_review_invoice_companyName4 = "\u767C\u7968\u62AC\u982D";
var APPB2C_cj_review_invoice_consent4 = '\u60A8\u662F\u5426\u540C\u610F\u82F1\u570B\u6587\u5316\u5354\u6703 "("\u81FA\u7063")"\u6709\u9650\u516C\u53F8\u4EE3\u8868\u60A8\u8655\u7406\u767C\u7968\u548C\u7522\u54C1\u9000\u8CA8\u8B49\u660E, \u4EE5\u52A0\u5FEB\u9000\u6B3E\u6D41\u7A0B\uFF1F';
var APPB2C_cj_review_invoice_send4 = "\u60A8\u5E0C\u671B\u958B\u7ACB\u4EC0\u9EBC\u985E\u578B\u7684\u767C\u7968\uFF1F";
var APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate4 = "\u81EA\u7136\u4EBA\u6191\u8B49";
var APPB2C_cj_review_invoice_subtype_label4 = "\u60A8\u60F3\u4F7F\u7528\u4EC0\u9EBC\u985E\u578B\u7684\u8F09\u5177\uFF1F";
var APPB2C_cj_review_invoice_subtype_mobileBarcode4 = "\u624B\u6A5F\u8F09\u5177\u689D\u78BC";
var APPB2C_cj_review_invoice_taxCaption_donate4 = "\u6148\u5584\u6A5F\u69CB\u4EE3\u78BC";
var APPB2C_cj_review_invoice_taxCaption_duplicatePaper4 = "\u516C\u53F8\u7D71\u7DE8";
var APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate4 = '\u81EA\u7136\u4EBA\u6191\u8B49\u5FC5\u9808\u7B26\u5408 16 \u500B\u5B57\u6BCD\u6578\u4F4D\u5B57\u5143\u7684\u6B63\u78BA\u683C\u5F0F "("\u4F8B\u5982 1122AABB3344CCDD")"';
var APPB2C_cj_review_invoice_taxValidation_donate4 = '\u6148\u5584\u6A5F\u69CB\u4EE3\u78BC\u5FC5\u9808\u7B26\u5408 3-7 \u4F4D\u6578\u4F4D\u7684\u7279\u5B9A\u683C\u5F0F "("\u4F8B\u5982 123")"';
var APPB2C_cj_review_invoice_taxValidation_mobileBarcode4 = '\u624B\u6A5F\u689D\u78BC\u5FC5\u9808\u7B26\u5408\u6B63\u78BA\u7684\u683C\u5F0F"("\u4F8B\u5982/123-ABC")"';
var APPB2C_cj_review_invoice_taxValidation_triplicate4 = '\u516C\u53F8\u7D71\u7DE8\u5FC5\u9808\u7B26\u5408 8 \u4F4D\u7279\u5B9A\u683C\u5F0F "("\u4F8B\u5982 11223344")"';
var APPB2C_cj_review_invoice_title4 = "\u767C\u7968\u985E\u578B";
var APPB2C_cj_review_invoice_type_donate4 = "\u6350\u8D08\u96FB\u5B50\u767C\u7968";
var APPB2C_cj_review_invoice_type_duplicateElectronic4 = "\u4E8C\u806F\u5F0F\u96FB\u5B50\u767C\u7968";
var APPB2C_cj_review_invoice_type_duplicatePaper4 = "\u4E8C\u806F\u5F0F\u7D19\u672C\u767C\u7968";
var APPB2C_cj_review_invoice_type_triplicate4 = "\u4E09\u806F\u5F0F\u7D19\u672C\u767C\u7968";
var APPB2C_cj_review_localization_localTime4 = "\u60A8\u7684\u7576\u5730\u6642\u9593";
var APPB2C_cj_review_localization_location4 = "\u6211\u5011\u5C07\u60A8\u8A9E\u7CFB\u8A2D\u5B9A\u70BA";
var APPB2C_cj_review_localization_timezone4 = "\u60A8\u7684\u6642\u5340";
var APPB2C_cj_review_missingMarketingCta4 = "\u6AA2\u67E5\u60A8\u7684\u7B54\u6848";
var APPB2C_cj_review_missingMarketingError4 = "\u60A8\u5148\u524D\u5DF2\u5617\u8A66\u5831\u540D\u8003\u8A66, \u73FE\u5728\u6211\u5011\u50C5\u9700\u8981\u518D\u6B21\u8207\u60A8\u6838\u5C0D\u4E00\u4E9B\u7B54\u6848\u3002";
var APPB2C_cj_review_note_content4 = "\u8ACB\u5728\u5831\u540D\u5B8C\u6210\u524D\u4ED4\u7D30\u6AA2\u67E5\u6240\u6709\u8A73\u7D30\u8CC7\u8A0A\u3002";
var APPB2C_cj_review_note_title4 = "\u60A8\u9084\u6C92\u6709\u5B8C\u6210\u5831\u540D";
var APPB2C_cj_review_or4 = "\u6216";
var APPB2C_cj_review_payLater_buttonText4 = "\u7DDA\u4E0B\u652F\u4ED8";
var APPB2C_cj_review_payLater_sideNote4 = "\u4ED8\u6B3E\u65B9\u5F0F\u5C07\u986F\u793A\u5728\u4E0B\u4E00\u9801";
var APPB2C_cj_review_payment_fee4 = "\u8CBB\u7528";
var APPB2C_cj_review_payment_promoCodes_applied4 = "\u5DF2\u4F7F\u7528\u6298\u6263\u78BC";
var APPB2C_cj_review_payment_promoCodes_applyCode4 = "\u61C9\u7528\u4EE3\u78BC";
var APPB2C_cj_review_payment_promoCodes_discount4 = "\u6D3B\u52D5";
var APPB2C_cj_review_payment_promoCodes_generalError4 = "\u767C\u751F\u932F\u8AA4 \u2500 \u8ACB\u91CD\u8A66\u3002";
var APPB2C_cj_review_payment_promoCodes_haveCode4 = "\u6211\u6709\u4E00\u500B\u6D3B\u52D5\u8A0A\u606F\u4EE3\u78BC";
var APPB2C_cj_review_payment_promoCodes_invalidCode4 = "\u60A8\u8F38\u5165\u7684\u4FC3\u92B7\u4EE3\u78BC\u7121\u6548";
var APPB2C_cj_review_payment_promoCodes_newFee4 = "\u65B0\u8CBB\u7528";
var APPB2C_cj_review_payment_promoCodes_notHaveCode4 = "\u6211\u6C92\u6709\u6D3B\u52D5\u8A0A\u606F\u4EE3\u78BC";
var APPB2C_cj_review_payment_promoCodes_promo4 = "\u4FC3\u92B7\u6D3B\u52D5";
var APPB2C_cj_review_payment_promoCodes_promoCode4 = "\u6D3B\u52D5\u8A0A\u606F\u4EE3\u78BC";
var APPB2C_cj_review_payment_promoCodes_remove4 = "\u79FB\u9664";
var APPB2C_cj_review_payment_promoCodes_removeCode4 = "\u522A\u9664\u6D3B\u52D5\u8A0A\u606F\u4EE3\u78BC";
var APPB2C_cj_review_payment_promoCodes_total4 = "\u7E3D\u91D1\u984D";
var APPB2C_cj_review_payment_tax4 = "\u7A05";
var APPB2C_cj_review_payment_title4 = "\u4ED8\u6B3E";
var APPB2C_cj_review_payment_total4 = "\u7E3D\u91D1\u984D";
var APPB2C_cj_review_paymentError4 = "\u6211\u5011\u7121\u6CD5\u8655\u7406\u60A8\u7684\u4ED8\u6B3E, \u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_cj_review_paymentNote4 = "\u9810\u8A02\u548C\u4ED8\u6B3E: ";
var APPB2C_cj_review_paymentNoteMultiple3 = "\u60A8\u73FE\u5728\u53EF\u4EE5\u5831\u540D\u8003\u8A66\u4E86: ";
var APPB2C_cj_review_paymentNoteSingle3 = " \u4E00\u65E6\u60A8\u52FE\u9078\u5B8C\u6210\u4EE5\u4E0B\u9078\u9805, \u6211\u5011\u5C07\u70BA\u60A8\u5831\u540D\u8003\u8A66\u3002";
var APPB2C_cj_review_paymentNoteZeroFee4 = "\u7372\u53D6\u514D\u8CBB\u6E2C\u8A66";
var APPB2C_cj_review_payOnline_buttonText4 = "\u5728\u7DDA\u652F\u4ED8";
var APPB2C_cj_review_payOnline_sideNote4 = "\u63A8\u85A6\u7684\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_cj_review_personalDetails_address4 = "\u4F4D\u5740";
var APPB2C_cj_review_personalDetails_dob4 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_cj_review_personalDetails_email4 = "\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740";
var APPB2C_cj_review_personalDetails_gender4 = "\u6027\u5225";
var APPB2C_cj_review_personalDetails_name4 = "\u540D\u5B57";
var APPB2C_cj_review_personalDetails_phone4 = "\u624B\u6A5F\u865F\u78BC";
var APPB2C_cj_review_personalDetails_smsNotificationsConsent4 = "\u6211\u5E0C\u671B\u901A\u904E\u9019\u500B\u624B\u6A5F\u865F\u78BC\u63A5\u6536\u6709\u95DC\u6211\u7684\u8003\u8A66\u548C\u6210\u7E3E\u7684\u8A0A\u606F";
var APPB2C_cj_review_personalDetails_title4 = "\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_cj_review_registrationError4 = "\u6211\u5011\u73FE\u5728\u7121\u6CD5\u8A3B\u518A, \u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_btn4 = "\u986F\u793A\u65B0\u50F9\u683C";
var APPB2C_cj_review_registrationFailedModal_changedFee_line14 = "\u6211\u5011\u901A\u77E5\u60A8, \u67D0\u4E9B\u6E2C\u8A66\u7684\u50F9\u683C\u5DF2\u66F4\u6539\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_line24 = "\u8ACB\u67E5\u770B\u65B0\u50F9\u683C\u4E26\u91CD\u65B0\u9078\u64C7\u6E2C\u8A66\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_line34 = "\u5C0D\u65BC\u7D66\u60A8\u5E36\u4F86\u7684\u4E0D\u4FBF, \u6211\u5011\u6DF1\u8868\u6B49\u610F\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_title4 = "\u6E2C\u8A66\u50F9\u683C\u5DF2\u66F4\u6539";
var APPB2C_cj_review_registrationFailedModal_cta4 = "\u91CD\u65B0\u958B\u59CB\u5831\u540D";
var APPB2C_cj_review_registrationFailedModal_message4 = "\u54CE\u5440, \u6709\u4E9B\u932F\u8AA4, \u656C\u8ACB\u91CD\u8A66\u3002";
var APPB2C_cj_review_registrationFailedModal_title4 = "\u91CD\u65B0\u958B\u59CB\u5831\u540D";
var APPB2C_cj_review_taxNumberError4 = "\u8ACB\u63D0\u4F9B\u6709\u6548\u7684\u52A0\u503C\u7A05/\u570B\u5BB6\u7A05\u865F, \u8A72\u865F\u78BC\u5FC5\u9808\u8207\u7279\u5B9A\u683C\u5F0F\u76F8\u5339\u914D(\u4F8B\u598213243546-3-35,1324354635)\u3002";
var APPB2C_cj_review_title4 = "\u518D\u6B21\u6AA2\u8996";
var APPB2C_cj_review_tncs_agreement4 = "\u6211\u540C\u610F\u96C5\u601D\u8003\u8A66<0>\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6</0>\u548C<2>\u53D6\u6D88\u898F\u7BC4</2>";
var APPB2C_cj_review_tncs_title4 = "\u6211\u5011\u7684\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_cj_review_tooHighPromoCodeError4 = "\u6211\u5011\u7121\u6CD5\u5B8C\u6210\u60A8\u7684\u8A3B\u518A\u3002\u8ACB\u5728\u4E0D\u4F7F\u7528\u4FC3\u92B7\u4EE3\u78BC\u7684\u60C5\u6CC1\u4E0B\u5617\u8A66\u8A3B\u518A\u3002\u5982\u679C\u60A8\u8A8D\u70BA\u4EE3\u78BC\u61C9\u8A72\u6709\u6548, \u8ACB\u806F\u7E6B\u60A8\u7684\u4E2D\u5FC3\u5C0B\u6C42\u8AAC\u660E";
var APPB2C_cj_timer_modal_cta4 = "\u91CD\u65B0\u958B\u59CB\u5831\u540D";
var APPB2C_cj_timer_modal_message4 = "\u4E0D\u597D\u610F\u601D, \u60A8\u8D85\u904E\u5831\u540D\u7A0B\u5E8F\u7684\u4F5C\u696D\u6642\u9593\u3002";
var APPB2C_cj_timer_modal_title4 = "\u91CD\u65B0\u958B\u59CB\u5831\u540D";
var APPB2C_cj_timer_nearEnd_minuteCount4 = "{{count}} \u5206\u9418";
var APPB2C_cj_timer_nearEnd_minuteCount_other4 = "{{count}} \u5206\u9418";
var APPB2C_cj_timer_nearEnd_text4 = '\u60A8\u6240\u9078\u64C7\u7684\u8003\u8A66\u50C5\u4FDD\u7559\u53E6\u4E00\u500B$t(APPB2C.cj.timer.nearEnd.minuteCount, {"count": {{count}} })\u3002';
var APPB2C_cj_timer_nearEnd_title4 = "\u8ACB\u6CE8\u610F\uFF01";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied4 = "\u60A8\u60F3\u5728\u54EA\u500B\u570B\u5BB6/\u5730\u5340\u5B78\u7FD2/\u5DE5\u4F5C/\u751F\u6D3B\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child4 = "\u4ED6\u5011\u60F3\u5728\u54EA\u500B\u570B\u5BB6/\u5730\u5340\u5B78\u7FD2/\u5DE5\u4F5C/\u751F\u6D3B\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest4 = "\u4F60\u53C3\u52A0\u8003\u8A66\u7684\u539F\u56E0\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child4 = "\u4ED6\u5011\u53C3\u52A0\u8003\u8A66\u7684\u539F\u56E0\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle4 = "\u60A8\u5C0D\u96C5\u601D\u611F\u8208\u8DA3";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle_child4 = "\u4ED6\u5011\u5C0D\u96C5\u601D\u611F\u8208\u8DA3";
var APPB2C_cj_ttProfile_forms_occupation_occupationLevel4 = "\u60A8\u7684\u5DE5\u4F5C\u8077\u7B49\uFF1F";
var APPB2C_cj_ttProfile_forms_occupation_occupationSector4 = "\u60A8\u7684\u8077\u696D\u662F\u4EC0\u9EBC\uFF1F";
var APPB2C_cj_ttProfile_forms_occupation_sectionTitle4 = "\u60A8\u7684\u8077\u696D";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality4 = "\u60A8\u7684\u570B\u7C4D\u570B\u5BB6/\u5730\u5340\u662F\u4EC0\u9EBC\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child4 = "\u4ED6\u5011\u7684\u570B\u7C4D\u570B\u5BB6/\u5730\u5340\u662F\u4EC0\u9EBC\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3844 = "\u6B21\u8981(\uFF5E16\u6B72)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3854 = "\u4E2D\u5B78 (16-19 \u6B72)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3864 = "\u5B78\u4F4D(\u6216\u540C\u7B49\u5B78\u6B77)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3874 = "\u7814\u7A76\u751F";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_title4 = "\u4F60\u5B8C\u6210\u4E86\u4EC0\u9EBC\u7D1A\u5225\u7684\u6559\u80B2\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken4 = "\u4F60\u7684\u7B2C\u4E00\u8A9E\u8A00\u662F\u4EC0\u9EBC\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child4 = "\u4ED6\u5011\u7684\u7B2C\u4E00\u8A9E\u8A00\u662F\u4EC0\u9EBC\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle4 = "\u95DC\u65BC\u4F60";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle_child4 = "\u95DC\u65BC\u60A8\u7684\u5B69\u5B50";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish4 = "\u4F60\u5B78\u82F1\u8A9E\u591A\u5C11\u5E74\u4E86\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child4 = "\u4ED6\u5011\u5B78\u7FD2\u82F1\u8A9E\u591A\u5C11\u5E74\u4E86\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel4 = "\u60A8\u5C0D\u6B64\u554F\u984C\u7684\u56DE\u7B54\u5C07\u4E0D\u5F71\u97FF\u60A8\u7684\u8003\u8A66\u6210\u7E3E";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child4 = "\u60A8\u5C0D\u6B64\u554F\u984C\u7684\u56DE\u7B54\u5C07\u4E0D\u5F71\u97FF\u8003\u8A66\u6210\u7E3E";
var APPB2C_cj_ttProfile_note4 = "\u56DE\u7B54\u4EE5\u4E0B\u554F\u984C\u5C07\u4E0D\u6703\u5F71\u97FF\u60A8\u7684\u96C5\u601D\u8003\u8A66\u6210\u7E3E, \u6B64\u70BA\u5E6B\u52A9\u8003\u8A66\u4E2D\u5FC3\u6539\u9032\u5C0D\u8003\u751F\u7684\u670D\u52D9\u3002";
var APPB2C_cj_ttProfile_note_child4 = "\u56DE\u7B54\u4EE5\u4E0B\u554F\u984C\u5C07\u4E0D\u6703\u5F71\u97FF\u60A8\u5B69\u5B50\u7684\u96C5\u601D\u8003\u8A66\u6210\u7E3E, \u6B64\u70BA\u5E6B\u52A9\u8003\u8A66\u4E2D\u5FC3\u6539\u9032\u5C0D\u61C9\u8A66\u8005\u7684\u670D\u52D9\u3002";
var APPB2C_cj_ttProfile_title4 = "\u60A8\u7684\u500B\u4EBA\u8CC7\u6599";
var APPB2C_cj_ttProfile_title_child4 = "\u60A8\u5B69\u5B50\u7684\u500B\u4EBA\u8CC7\u6599";
var APPB2C_cj_ukviStartPage_ac_content4 = "\u8A72\u8003\u8A66\u9069\u7528\u65BC\u6B32\u5C31\u8B80\u82F1\u570BFoundation\u3001Pre-sessional\u3001\u8A9E\u8A00\u8AB2\u7A0B\u3001\u5927\u5B78\u5B78\u4F4D\u4EE5\u4E0B\u8AB2\u7A0B\u53CA\u90E8\u4EFD\u5927\u5B78\u8AB2\u7A0B\u3001\u7814\u7A76\u6240\u53CA\u4EE5\u4E0A\u5B78\u4F4D\u8AB2\u7A0B\u4E4B\u8003\u751F";
var APPB2C_cj_ukviStartPage_ac_title4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u5B78\u8853\u985E\u8003\u8A66";
var APPB2C_cj_ukviStartPage_gt_content4 = "\u8A72\u8003\u8A66\u9069\u7528\u65BC\u6B32\u79FB\u6C11\u5230\u82F1\u570B\u6216\u63A5\u53D7\u57F9\u8A13\u3001\u4E00\u822C\u975E\u5B78\u4F4D\u8AB2\u7A0B\u7684\u8003\u751F\u3002";
var APPB2C_cj_ukviStartPage_gt_title4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_content4 = "\u8A72\u6E2C\u9A57\u9069\u7528\u65BC\u9700\u8981\u8B49\u660E\u81EA\u5DF1\u7684\u82F1\u8A9E\u53E3\u8AAA\u53CA\u807D\u529B\u80FD\u529B\u7684\u4EBA, \u4F5C\u70BA\u4ED6\u5011\u8207\u82F1\u570B\u5167\u653F\u90E8\u79FB\u6C11\u7F72\u7533\u8ACB\u82F1\u570B\u7C3D\u8B49\u548C\u63A2\u89AA\u7C3D\u8B49\u7684\u8B49\u660E\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_title4 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57 A1";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_content4 = "\u8A72\u6E2C\u9A57\u9069\u7528\u65BC\u9700\u8981\u8B49\u660E\u81EA\u5DF1\u7684\u82F1\u8A9E\u53E3\u8AAA\u53CA\u807D\u529B\u80FD\u529B\u7684\u4EBA, \u4F5C\u70BA\u4ED6\u5011\u8207\u82F1\u570B\u5167\u653F\u90E8\u79FB\u6C11\u7F72\u7533\u8ACB\u90E8\u4EFD\u7C3D\u8B49\u5EF6\u671F\u4E4B\u8B49\u660E\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_title4 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u985E\u8003\u8A66A2(\u50C5\u9650\u82F1\u570B)";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_content4 = "\u8A72\u6E2C\u9A57\u9069\u7528\u65BC\u9700\u8981\u8B49\u660E\u81EA\u5DF1\u7684\u82F1\u8A9E\u53E3\u8AAA\u53CA\u807D\u529B\u80FD\u529B\u7684\u4EBA, \u4F5C\u70BA\u4ED6\u5011\u8207\u82F1\u570B\u5167\u653F\u90E8\u79FB\u6C11\u7F72\u7533\u8ACB\u7121\u9650\u671F\u5C45\u7559\u6216\u516C\u6C11\u8EAB\u4EFD\u7684\u8B49\u660E\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_title4 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u985E\u8003\u8A66B1";
var APPB2C_cj_ukviStartPage_lifeSkils_citizenship4 = "\u7121\u9650\u671F\u5C45\u7559\u6216\u516C\u6C11\u8EAB\u4EFD\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_extension4 = "\u5EF6\u9577\u5BB6\u5EAD\u3001\u914D\u5076\u6216\u4F34\u4FB6\u7C3D\u8B49";
var APPB2C_cj_ukviStartPage_lifeSkils_family4 = "\u5BB6\u5EAD\u7C3D\u8B49";
var APPB2C_cj_ukviStartPage_lifeSkils_note_content4 = "\u82E5\u60A8\u65BC\u904E\u53BB\u5169\u5E74\u5167\u66FE\u901A\u904E\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57, \u60A8\u5C07\u4E0D\u61C9\u8A72\u518D\u6B21\u5831\u8003\u540C\u6A23\u7684\u8003\u8A66\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_cj_ukviStartPage_lifeSkils_title4 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57";
var APPB2C_cj_ukviStartPage_lifeSkils_visaTypes4 = "\u5982\u679C\u60A8\u7533\u8ACB\u4EE5\u4E0B\u985E\u578B\u7684\u82F1\u570B\u7C3D\u8B49, \u9019\u662F\u8981\u53C3\u52A0\u7684\u6E2C\u8A66: ";
var APPB2C_cj_ukviStartPage_note1_content4 = "\u5982\u679C\u60A8\u4E26\u975E\u8981\u9810\u8A02\u96C5\u601DUKVI\u8003\u8A66\u4F86\u505A\u82F1\u570B\u7C3D\u8B49\u53CA\u79FB\u6C11\u7533\u8ACB, <0>\u8ACB\u53C3\u95B1\u6211\u5011\u63D0\u4F9B\u7684\u5176\u4ED6\u96C5\u601D\u8003\u8A66</0>\u3002";
var APPB2C_cj_ukviStartPage_note1_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_cj_ukviStartPage_terms4 = "\u5728\u7E7C\u7E8C\u4E4B\u524D, \u8ACB\u95B1\u8B80<0>\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u7684\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6</0>\u3002";
var APPB2C_cj_ukviStartPage_testsBelow4 = "\u4EE5\u4E0B\u96C5\u601D\u8003\u8A66\u5DF2\u7372\u5F97\u82F1\u570B\u5167\u653F\u90E8\u79FB\u6C11\u7F72\u7C3D\u8B49\u7533\u8ACB: ";
var APPB2C_cj_ukviStartPage_title4 = "\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u7684\u96C5\u601D\u8003\u8A66";
var APPB2C_cj_ukviStartPage_whichUkvi4 = "\u54EA\u7A2E\u7C3D\u8B49\u96C5\u601D\u8003\u8A66\u9069\u5408\u6211\uFF1F";
var APPB2C_common_account_details_email_cancel3 = "\u53D6\u6D88";
var APPB2C_common_account_details_email_confirm4 = "\u78BA\u8A8D\u96FB\u5B50\u90F5\u4EF6\u5730\u5740";
var APPB2C_common_account_details_email_label4 = "\u96FB\u5B50\u90F5\u4EF6\u5730\u5740";
var APPB2C_common_account_details_email_maxLength4 = "\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6\u5730\u5740\u8D85\u904E\u6700\u9577\u5B57\u6578, 60\u5B57\u5143\u3002";
var APPB2C_common_account_details_email_mismatch4 = "\u6B64\u6B04\u4F4D\u9700\u8981\u8207\u4E0A\u9762\u7684\u96FB\u5B50\u90F5\u4EF6\u5730\u5740\u5B8C\u5168\u76F8\u7B26\u3002";
var APPB2C_common_account_details_email_note4 = "\u8ACB\u63D0\u4F9B\u6709\u6548\u4E14\u53EF\u4F7F\u7528\u7684\u96FB\u5B50\u90F5\u4EF6\u5730\u5740, \u6240\u6709\u76F8\u95DC\u8CC7\u8A0A\u5C07\u900F\u904E\u6B64\u96FB\u5B50\u90F5\u4EF6\u5730\u5740\u63D0\u4F9B\u3002\u5F9E\u73FE\u5728\u958B\u59CB, \u60A8\u5C07\u53EF\u4F7F\u7528\u6B64\u96FB\u5B50\u90F5\u4EF6\u5730\u5740\u6216\u4F7F\u7528\u8005\u540D\u7A31\u767B\u5165\u60A8\u7684\u8003\u751F\u7CFB\u7D71\u3002";
var APPB2C_common_account_details_email_save4 = "\u4FDD\u5B58\u96FB\u5B50\u90F5\u4EF6\u5730\u5740";
var APPB2C_common_account_details_email_set4 = "\u63D0\u4F9B\u96FB\u5B50\u90F5\u4EF6\u5730\u5740";
var APPB2C_common_account_details_email_success4 = "\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6\u5730\u5740\u5DF2\u4FDD\u5B58\u3002";
var APPB2C_common_account_details_email_username4 = "\u4F7F\u7528\u8005\u540D\u7A31";
var APPB2C_common_account_details_email_validEmail4 = "\u8ACB\u6307\u5B9A\u6709\u6548\u7684\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740\u3002";
var APPB2C_common_account_details_name4 = "\u60A8\u7684\u59D3\u540D";
var APPB2C_common_account_details_psw_cancel3 = "\u53D6\u6D88";
var APPB2C_common_account_details_psw_change4 = "\u66F4\u6539\u5BC6\u78BC";
var APPB2C_common_account_details_psw_label4 = "\u5BC6\u78BC";
var APPB2C_common_account_details_psw_new4 = "\u65B0\u5BC6\u78BC";
var APPB2C_common_account_details_psw_old4 = "\u820A\u5BC6\u78BC";
var APPB2C_common_account_details_psw_requirements_levels_04 = "\u975E\u5E38\u5F31";
var APPB2C_common_account_details_psw_requirements_levels_14 = "\u5F31";
var APPB2C_common_account_details_psw_requirements_levels_24 = "\u4E2D\u7B49";
var APPB2C_common_account_details_psw_requirements_levels_34 = "\u5F37";
var APPB2C_common_account_details_psw_requirements_levels_44 = "\u975E\u5E38\u5B89\u5168";
var APPB2C_common_account_details_psw_requirements_maxLength4 = "\u60A8\u7684\u5BC6\u78BC\u8D85\u904E\u6700\u9577\u5B57\u6578, 100\u5B57\u5143\u3002";
var APPB2C_common_account_details_psw_requirements_mustHave4 = "\u60A8\u7684\u5BC6\u78BC\u5FC5\u9808\u5177\u6709: ";
var APPB2C_common_account_details_psw_requirements_ok4 = "\u60A8\u7684\u5BC6\u78BC\u7B26\u5408\u8981\u6C42";
var APPB2C_common_account_details_psw_requirements_reqs_length4 = "8 \u500B\u6216\u66F4\u591A\u5B57\u5143";
var APPB2C_common_account_details_psw_requirements_reqs_lower4 = "\u5C0F\u5BEB\u5B57\u6BCD";
var APPB2C_common_account_details_psw_requirements_reqs_number4 = "\u81F3\u5C11\u4E00\u500B\u6578\u5B57";
var APPB2C_common_account_details_psw_requirements_reqs_special4 = "\u81F3\u5C11\u4E00\u500B\u7279\u6B8A\u5B57\u5143";
var APPB2C_common_account_details_psw_requirements_reqs_upper4 = "\u5927\u5BEB\u5B57\u6BCD";
var APPB2C_common_account_details_psw_requirements_shouldHave4 = "\u4E26\u4E14\u9700\u8981\u7B26\u5408\u4EE5\u4E0B\u8981\u6C42\u4E2D\u7684 3 \u500B: ";
var APPB2C_common_account_details_psw_requirements_strength4 = "\u5F37\u5EA6";
var APPB2C_common_account_details_psw_requirements_tooWeak4 = "\u60A8\u7684\u5BC6\u78BC\u592A\u5F31\u3002";
var APPB2C_common_account_details_psw_show4 = "\u986F\u793A\u5BC6\u78BC";
var APPB2C_common_account_details_psw_success4 = "\u60A8\u7684\u5BC6\u78BC\u5DF2\u66F4\u6539\u3002";
var APPB2C_common_account_details_subnote_address4 = "\u8F38\u5165\u4E2D\u6587\u8A73\u7D30\u5730\u5740";
var APPB2C_common_account_details_subnote_city4 = "\u8ACB\u8F38\u5165\u9109\u93AE/\u57CE\u5E02\u540D\u7A31";
var APPB2C_common_account_error4 = "\u7CFB\u7D71\u51FA\u73FE\u7570\u5E38, \u8ACB\u65BC\u5E7E\u5206\u9418\u5F8C\u518D\u6B21\u5617\u8A66\u3002";
var APPB2C_common_account_marketing_success4 = "\u60A8\u7684\u884C\u92B7\u504F\u597D\u5DF2\u6210\u529F\u66F4\u65B0\u3002";
var APPB2C_common_account_marketing_title4 = "\u60A8\u7684\u884C\u92B7\u559C\u597D";
var APPB2C_common_account_smsNotificationSettings_mobileLabel4 = "\u7C21\u8A0A\u5C07\u9001\u81F3: ";
var APPB2C_common_account_smsNotificationSettings_success4 = "\u60A8\u7684\u7C21\u8A0A\u901A\u77E5\u8A2D\u5B9A\u5DF2\u66F4\u65B0\u6210\u529F\u3002";
var APPB2C_common_account_smsNotificationSettings_title4 = "\u7C21\u8A0A\u901A\u77E5\u8A2D\u5B9A";
var APPB2C_common_account_terms_cta4 = "\u4E0B\u8F09\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_account_terms_disclaimer4 = "\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u5C07\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A\u4F86\u8655\u7406\u60A8\u7684\u8A3B\u518A\u3002\u8655\u7406\u60A8\u7684\u8CC7\u8A0A\u7684\u6CD5\u5F8B\u4F9D\u64DA\u662F\u540C\u610F\u6211\u5011\u7684\u8A3B\u518A\u689D\u6B3E\u548C\u689D\u4EF6(\u5408\u540C)\u3002";
var APPB2C_common_account_terms_download4 = "\u60A8\u53EF\u4EE5\u4E0B\u8F09\u5148\u524D\u5DF2\u540C\u610F\u7684\u670D\u52D9\u689D\u6B3E\u53CA\u689D\u4EF6: ";
var APPB2C_common_account_terms_extra_line14 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u9075\u5B88\u82F1\u570B\u7684\u8CC7\u8A0A\u4FDD\u8B77\u6CD5\u4EE5\u53CA\u7B26\u5408\u570B\u969B\u516C\u8A8D\u6A19\u6E96\u7684\u5176\u4ED6\u570B\u5BB6/\u5730\u5340\u7684\u6CD5\u5F8B\u3002";
var APPB2C_common_account_terms_extra_line24 = "\u60A8\u5C07\u6709\u6B0A\u5229\u5411\u5354\u6703\u7D22\u53D6\u60A8\u6240\u63D0\u4F9B\u7684\u8CC7\u6599\u526F\u672C, \u4EE5\u53CA\u4FEE\u6539\u76F8\u95DC\u8CC7\u8A0A\u3002\u82E5\u60A8\u5C0D\u65BC\u4F7F\u7528\u76F8\u95DC\u8CC7\u8A0A\u6709\u7591\u616E, \u4EA6\u6709\u6B0A\u5229\u5411\u4E3B\u7BA1\u500B\u8CC7\u4FDD\u8B77\u6A5F\u69CB\u7533\u8A34\u3002";
var APPB2C_common_account_terms_extra_line3_14 = "\u66F4\u591A\u8A73\u7D30\u8CC7\u8A0A, \u8ACB\u53C3\u95B1\u6211\u5011\u7DB2\u7AD9\u7684\u96B1\u79C1\u689D\u6B3E, ";
var APPB2C_common_account_terms_extra_line3_24 = "\u6216\u806F\u7E6B\u60A8\u7576\u5730\u7684\u82F1\u570B\u6587\u5316\u5354\u6703\u8FA6\u516C\u5BA4\u3002\u6211\u5011\u5C07\u4FDD\u7559\u60A8\u7684\u8A3B\u518A\u8CC7\u8A0A3\u5E74; \u884C\u92B7\u76EE\u7684\u8CC7\u8A0A, \u5C07\u4FDD\u7559 7 \u5E74\u3002(\u8D77\u59CB\u65E5\u70BA\u8A3B\u518A\u4E4B\u65E5)";
var APPB2C_common_account_terms_extra_title4 = "\u8CC7\u8A0A\u4FDD\u8B77";
var APPB2C_common_account_terms_previous4 = "\u6B64\u70BA\u5148\u524D\u7684\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6: <0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_common_account_terms_title4 = "\u60A8\u5DF2\u540C\u610F\u8A3B\u518A\u5E33\u865F\u7684<0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_common_account_title4 = "\u6211\u7684\u5E33\u865F";
var APPB2C_common_alertBar_afterTest4 = "\u60A8\u4ECD\u7136\u9700\u8981: ";
var APPB2C_common_alertBar_beforeTest4 = "\u5728\u8003\u8A66\u65E5\u4E4B\u524D, \u60A8\u9700\u8981: ";
var APPB2C_common_alertBar_cancelReupload4 = "\u518D\u6B21\u4E0A\u50B3\u53D6\u6D88\u5831\u540D\u6587\u4EF6";
var APPB2C_common_alertBar_check4 = "\u9032\u884C\u6E2C\u8A66\u524D\u6AA2\u67E5";
var APPB2C_common_alertBar_childConsentMissing4 = "\u63D0\u4F9B\u5152\u7AE5\u540C\u610F\u66F8\u8CC7\u8A0A";
var APPB2C_common_alertBar_creds4 = "\u67E5\u770B\u6211\u7684\u767B\u5165\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_alertBar_eorPayment4 = "\u652F\u4ED8\u60A8\u7684\u8907\u67E5\u8CBB\u7528";
var APPB2C_common_alertBar_eorPaymentProof4 = "\u4E0A\u50B3\u60A8\u7684\u8907\u67E5\u4ED8\u6B3E\u8B49\u660E";
var APPB2C_common_alertBar_idReupload4 = "\u518D\u6B21\u4E0A\u50B3\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6";
var APPB2C_common_alertBar_idUpload4 = "\u4E0A\u50B3\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6";
var APPB2C_common_alertBar_medicalReupload4 = "\u518D\u6B21\u4E0A\u50B3\u91AB\u7642\u8B49\u660E";
var APPB2C_common_alertBar_medicalUpload4 = "\u4E0A\u50B3\u91AB\u7642\u8B49\u660E";
var APPB2C_common_alertBar_regPayment4 = "\u652F\u4ED8\u8003\u8A66\u8CBB\u7528";
var APPB2C_common_alertBar_regPaymentProof4 = "\u4E0A\u50B3\u8003\u8A66\u4ED8\u6B3E\u8B49\u660E";
var APPB2C_common_alertBar_withCreds4 = "\u60A8\u73FE\u5728\u53EF\u4EE5\u767B\u5165\u6E2C\u9A57: ";
var APPB2C_common_basic_add4 = "\u52A0";
var APPB2C_common_basic_backToTop4 = "\u8FD4\u56DE\u9801\u9996";
var APPB2C_common_basic_bookingDetails_arrival4 = "(\u8ACB\u65BC {{time}} \u5230\u9054)";
var APPB2C_common_basic_bookingDetails_lifeSkills_results4 = "\u807D\u529B\u548C\u53E3\u8AAA";
var APPB2C_common_basic_bookingDetails_lifeSkills_title4 = "\u60A8\u7684\u6E2C\u9A57";
var APPB2C_common_basic_bookingDetails_lrw_cd4 = "\u65BC\u8003\u8A66\u4E2D\u5FC3\u7684\u96C5\u601D\u96FB\u8166\u6E2C\u9A57";
var APPB2C_common_basic_bookingDetails_lrw_online4 = "\u7DDA\u4E0A";
var APPB2C_common_basic_bookingDetails_lrw_pb4 = "\u96C5\u601D\u7D19\u7B46\u8003\u8A66";
var APPB2C_common_basic_bookingDetails_lrw_results4 = "\u807D\u529B\u3001\u95B1\u8B80\u53CA\u5BEB\u4F5C";
var APPB2C_common_basic_bookingDetails_lrw_title4 = "\u60A8\u7684\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_common_basic_bookingDetails_osrTitle_listening4 = "\u60A8\u7684\u807D\u529B\u6E2C\u9A57";
var APPB2C_common_basic_bookingDetails_osrTitle_reading4 = "\u60A8\u7684\u95B1\u8B80\u6E2C\u9A57";
var APPB2C_common_basic_bookingDetails_speaking_f2f4 = "\u9762\u5C0D\u9762";
var APPB2C_common_basic_bookingDetails_speaking_live4 = "\u9762\u5C0D\u9762";
var APPB2C_common_basic_bookingDetails_speaking_online4 = "\u7DDA\u4E0A";
var APPB2C_common_basic_bookingDetails_speaking_remote4 = "\u65BC\u8003\u8A66\u4E2D\u5FC3\u8996\u8A0A";
var APPB2C_common_basic_bookingDetails_speaking_results4 = "\u53E3\u8AAA";
var APPB2C_common_basic_bookingDetails_speaking_title4 = "\u60A8\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_basic_bookPrePaid4 = "\u9810\u7D04\u9810\u4ED8\u8CBB\u8003\u8A66";
var APPB2C_common_basic_cancel4 = "\u53D6\u6D88";
var APPB2C_common_basic_change4 = "\u7570\u52D5";
var APPB2C_common_basic_choose4 = "\u9078\u64C7";
var APPB2C_common_basic_close4 = "\u95DC\u9589";
var APPB2C_common_basic_copied4 = "\u8907\u88FD";
var APPB2C_common_basic_copy4 = "\u8907\u88FD";
var APPB2C_common_basic_dateField_day4 = "\u65E5";
var APPB2C_common_basic_dateField_month4 = "\u6708";
var APPB2C_common_basic_dateField_months4 = "\u9078\u64C7\u6708\u4EFD";
var APPB2C_common_basic_dateField_year4 = "\u5E74";
var APPB2C_common_basic_edit4 = "\u7DE8\u8F2F";
var APPB2C_common_basic_errors_contactTestCentre4 = "\u5F88\u62B1\u6B49, \u51FA\u73FE\u4E86\u554F\u984C\u3002\u8ACB\u806F\u7E6B\u60A8\u7576\u5730\u7684\u4E2D\u5FC3\u7533\u8ACB\u8F49\u79FB\u670D\u52D9\u3002";
var APPB2C_common_basic_errors_network4 = "\u76EE\u524D\u7DB2\u8DEF\u9023\u7DDA\u7570\u5E38, \u8ACB\u5617\u8A66\u91CD\u65B0\u9023\u7DDA\u4E26\u91CD\u65B0\u6574\u7406\u9801\u9762\u3002";
var APPB2C_common_basic_errors_noTest4 = "\u6B64\u6E2C\u9A57\u76EE\u524D\u5C07\u7121\u6CD5\u9078\u64C7, \u8ACB\u9078\u64C7\u5176\u4ED6\u6E2C\u9A57\u3002";
var APPB2C_common_basic_errors_server4 = "\u7CFB\u7D71\u51FA\u73FE\u7570\u5E38, \u8ACB\u65BC\u5E7E\u5206\u9418\u5F8C\u518D\u6B21\u5617\u8A66\u3002";
var APPB2C_common_basic_errors_title4 = "\u54CE\u5440\uFF01";
var APPB2C_common_basic_errors_unableToDownloadFile4 = "\u6211\u5011\u5DF2\u5132\u5B58\u60A8\u7684\u8CC7\u6599, \u4F46\u986F\u793A\u6642\u7570\u5E38\u3002";
var APPB2C_common_basic_errors_unknown4 = "\u7CFB\u7D71\u51FA\u73FE\u7570\u5E38, \u8ACB\u65BC\u5E7E\u5206\u9418\u5F8C\u518D\u6B21\u5617\u8A66\u3002";
var APPB2C_common_basic_expando_collapse4 = "- \u96B1\u85CF\u5B8C\u6574\u8CC7\u8A0A";
var APPB2C_common_basic_expando_show4 = "+ \u95B1\u8B80\u5B8C\u6574\u8CC7\u8A0A";
var APPB2C_common_basic_externalLinkTitle4 = "(\u958B\u555F\u65B0\u8996\u7A97)";
var APPB2C_common_basic_failure4 = "\u5931\u6557";
var APPB2C_common_basic_forms_addressMaxLength4 = "\u5730\u5740\u9577\u5EA6\u6700\u591A\u70BA {{count}} \u500B\u5B57\u5143\u3002";
var APPB2C_common_basic_forms_dobMin4 = "\u82E5\u6B32\u7533\u8ACB\u5E33\u865F, \u9700\u5E74\u6EFF18\u6B72\uFF1B\u82E5\u60A8\u672A\u6EFF18\u6B72, \u8ACB\u7531\u60A8\u7684\u5BB6\u9577\u6216\u76E3\u8B77\u4EBA\u5354\u52A9\u3002";
var APPB2C_common_basic_forms_dobMinIol4 = "\u62B1\u6B49, \u60A8\u5FC5\u9808\u6EFF 18 \u6B72\u624D\u80FD\u7DDA\u4E0A\u5831\u540D\u8003\u8A66\u3002\u8ACB\u8207\u8003\u8A66\u4E2D\u5FC3\u806F\u7E6B\u3002";
var APPB2C_common_basic_forms_dobOutOfRange4 = "\u8ACB\u78BA\u8A8D\u60A8\u7684\u51FA\u751F\u5E74\u4EFD\u3002";
var APPB2C_common_basic_forms_firstNameMaxLength4 = "\u540D\u5B57/\u540D\u5B57\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u500B\u5B57\u7B26\u9577\u3002";
var APPB2C_common_basic_forms_idExpiration4 = "\u8003\u8A66\u7576\u65E5\u60A8\u7684\u8B49\u660E\u6587\u4EF6\u5DF2\u904E\u671F";
var APPB2C_common_basic_forms_idMaxLength4 = "\u6587\u4EF6\u7DE8\u865F\u6700\u591A\u53EF\u4EE5\u5305\u542B 20 \u500B\u5B57\u5143\u3002";
var APPB2C_common_basic_forms_idNumber4 = '\u6B04\u4F4D\u5FC5\u9808\u7B26\u5408\u7279\u5B9A\u683C\u5F0F(\u5141\u8A31\u4F7F\u7528\u62C9\u4E01\u5B57\u6BCD\u3001\u6578\u4F4D\u548C"-")';
var APPB2C_common_basic_forms_idProofRequired4 = "\u9700\u8981\u8EAB\u4EFD\u8B49\u660E\u3002";
var APPB2C_common_basic_forms_maxLengthMessage4 = "\u6700\u5927\u9577\u5EA6\u70BA {{count}}\u3002";
var APPB2C_common_basic_forms_minorDobMax4 = "\u82E5\u60A8\u7684\u5B69\u5B50\u5E74\u6EFF 18 \u6B72, \u8ACB\u7531\u4ED6\u5011\u81EA\u884C\u8A3B\u518ABritish Council \u5E33\u865F\u3002";
var APPB2C_common_basic_forms_minorDobMin4 = "11\u6B72\u53CA\u4EE5\u4E0A\u7684\u5152\u7AE5\u53EF\u4EE5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_basic_forms_mobileBypass4 = "\u9019\u662F\u6211\u6B63\u78BA\u7684\u624B\u6A5F\u865F\u78BC";
var APPB2C_common_basic_forms_mobileLength4 = "\u624B\u6A5F\u865F\u78BC\u6700\u9577\u53EF\u9054 20 \u500B\u5B57\u5143\u3002";
var APPB2C_common_basic_forms_onlyLatin4 = "\u6B04\u4F4D\u61C9\u50C5\u5305\u542B\u62C9\u4E01\u5B57\u5143\u3002";
var APPB2C_common_basic_forms_postCodeMaxLength4 = "\u90F5\u905E\u5340\u7DE8\u78BC\u7684\u9577\u5EA6\u6700\u591A\u70BA {{count}} \u500B\u5B57\u5143\u3002";
var APPB2C_common_basic_forms_postCodeMessage4 = "\u90F5\u905E\u5340\u865F/\u90F5\u7DE8\u9808\u7B26\u54085\u4F4D\u6578\u683C\u5F0F(\u4F8B\u598207102)";
var APPB2C_common_basic_forms_postCodeNotFound4 = "\u62B1\u6B49, \u6211\u5011\u627E\u4E0D\u5230\u8A72\u90F5\u905E\u5340\u865F, \u8ACB\u6AA2\u67E5\u662F\u5426\u6B63\u78BA\u3002";
var APPB2C_common_basic_forms_requiredMessage4 = "\u6B64\u6B04\u4F4D\u70BA\u5FC5\u586B\u6B04\u4F4D\u3002";
var APPB2C_common_basic_forms_requiredNoSurmane4 = '\u6B64\u6B04\u4F4D\u70BA\u5FC5\u586B\u6B04\u4F4D\u3002\u5982\u679C\u60A8\u6C92\u6709\u59D3\u6C0F, \u8ACB\u5728\u6B64\u5B57\u6BB5\u4E2D\u8F38\u5165\u9023\u63A5\u865F("-")\u3002';
var APPB2C_common_basic_forms_stateMaxLength4 = "\u72C0\u614B\u6700\u591A\u53EF\u4EE5\u662F {{count}} \u500B\u5B57\u5143\u9577\u3002";
var APPB2C_common_basic_forms_surnameMaxLength4 = "\u59D3\u6C0F/\u59D3\u6C0F\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u500B\u5B57\u5143\u9577\u3002";
var APPB2C_common_basic_forms_townMaxLength4 = "\u57CE\u93AE/\u57CE\u5E02\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u500B\u5B57\u5143\u9577\u3002";
var APPB2C_common_basic_forms_unsupportedChars4 = "\u6B04\u4F4D\u5305\u542B\u4E0D\u652F\u63F4\u7684\u5B57\u5143\u96C6\u3002";
var APPB2C_common_basic_forms_validMobile4 = "\u8ACB\u6307\u5B9A\u6709\u6548\u7684\u624B\u6A5F\u865F\u78BC\u3002";
var APPB2C_common_basic_goBack4 = "\u8FD4\u56DE";
var APPB2C_common_basic_loading4 = "\u88DD\u8F09\u3002\u3002\u3002";
var APPB2C_common_basic_menu4 = "\u529F\u80FD\u8868";
var APPB2C_common_basic_no4 = "\u4E0D\u662F";
var APPB2C_common_basic_notAllowed_msg4 = "\u60A8\u4E0D\u5F97\u5B58\u53D6\u6B64\u9801\u9762\u3002";
var APPB2C_common_basic_notAllowed_title4 = "\u5662\uFF01";
var APPB2C_common_basic_notFound_goHome4 = "\u56DE\u5230\u9996\u9801";
var APPB2C_common_basic_notFound_msg4 = "\u6B64\u9801\u9762\u4E0D\u5B58\u5728";
var APPB2C_common_basic_notFound_title4 = "\u672A\u627E\u5230\u9801\u9762";
var APPB2C_common_basic_notLoading_note4 = "\u56E0\u7DB2\u8DEF\u7570\u5E38, \u6211\u5011\u7121\u6CD5\u8F09\u5165\u6B64\u9801\u9762, \u8ACB\u5617\u8A66\u65BC\u5E7E\u5206\u9418\u5F8C\u91CD\u65B0\u6574\u7406\u9801\u9762\u3002";
var APPB2C_common_basic_notLoading_refresh4 = "\u91CD\u65B0\u6574\u7406";
var APPB2C_common_basic_notLoading_title4 = "\u7121\u6CD5\u8F09\u5165\u9801\u9762";
var APPB2C_common_basic_ok4 = "OK";
var APPB2C_common_basic_on4 = "\u4E0A";
var APPB2C_common_basic_oneSecond4 = "\u8ACB\u4E00\u79D2\u9418";
var APPB2C_common_basic_pleaseNote4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_basic_retake4 = "\u91CD\u8003";
var APPB2C_common_basic_upload4 = "\u4E0A\u50B3";
var APPB2C_common_basic_validationError4 = "\u8ACB\u518D\u6B21\u6AA2\u67E5\u5DF2\u8A3B\u8A18\u7684\u554F\u984C\u5F8C\u7E7C\u7E8C\u3002";
var APPB2C_common_basic_waitReassurance4 = "\u6211\u5011\u5FEB\u5230\u4E86...";
var APPB2C_common_basic_wereSorry4 = "\u5C0D\u4E0D\u8D77";
var APPB2C_common_basic_yes4 = "\u662F\u7684";
var APPB2C_common_bookTest_changeExamType4 = "\u66F4\u6539\u6E2C\u9A57\u985E\u578B";
var APPB2C_common_bookTest_filters_afternoon4 = "\u4E0B\u5348";
var APPB2C_common_bookTest_filters_allDates4 = "\u6240\u6709\u65E5\u671F";
var APPB2C_common_bookTest_filters_anyTime4 = "\u4E00\u5929\u4E2D\u7684\u4EFB\u4F55\u6642\u9593";
var APPB2C_common_bookTest_filters_computer4 = "\u96FB\u8166";
var APPB2C_common_bookTest_filters_evening4 = "\u508D\u665A";
var APPB2C_common_bookTest_filters_modal_cta4 = "\u641C\u5C0B\u7D50\u679C";
var APPB2C_common_bookTest_filters_modal_hide4 = "\u96B1\u85CF";
var APPB2C_common_bookTest_filters_modal_iDontMind4 = "\u6211\u4E0D\u4ECB\u610F";
var APPB2C_common_bookTest_filters_modal_resultsIn4 = "\u7D50\u679C\u5728 {{days}} \u5929\u5167\u91CB\u51FA";
var APPB2C_common_bookTest_filters_modal_subtitle4 = "\u7BE9\u9078\u689D\u4EF6\u6E2C\u9A57";
var APPB2C_common_bookTest_filters_modal_title4 = "\u7BE9\u9078\u689D\u4EF6";
var APPB2C_common_bookTest_filters_modal_whichFormatTest4 = "\u60A8\u60F3\u53C3\u52A0\u54EA\u7A2E\u5F62\u5F0F\u7684\u96C5\u601D\u8003\u8A66";
var APPB2C_common_bookTest_filters_modal_whichTimeOfDay4 = "\u4E00\u5929\u4E2D\u807D\u529B\u3001\u95B1\u8B80\u548C\u5BEB\u4F5C\u6E2C\u9A57\u7684\u6642\u9593";
var APPB2C_common_bookTest_filters_morning4 = "\u4E0A\u5348";
var APPB2C_common_bookTest_filters_paper4 = "\u7D19";
var APPB2C_common_bookTest_filters_seeAllFilters4 = "\u67E5\u770B\u6240\u6709\u7BE9\u9078\u689D\u4EF6";
var APPB2C_common_bookTest_filters_title4 = "\u7BE9\u9078\u4F9D\u64DA";
var APPB2C_common_bookTest_loadMoreTests4 = "\u8F09\u5165\u66F4\u591A\u6E2C\u9A57";
var APPB2C_common_bookTest_noDiscount_msg_countryNotMatched4 = "\u6B64\u4EE3\u91D1\u5238\u4E0D\u80FD\u5728\u60A8\u9078\u64C7\u7684\u570B\u5BB6/\u5730\u5340\u4F7F\u7528\u3002\u8ACB\u8FD4\u56DE\u539F\u4F86\u7684\u570B\u5BB6/\u5730\u5340\u9078\u64C7\u3002";
var APPB2C_common_bookTest_noDiscount_msg_notFound4 = "\u6B64\u6191\u8B49\u7121\u6548\u3002";
var APPB2C_common_bookTest_noDiscount_msg_notSupported4 = "\u6B64\u4EE3\u91D1\u5238\u5C08\u7528\u65BC\u5176\u4ED6\u7522\u54C1\u3002\u8ACB\u8FD4\u56DE\u539F\u59CB\u7522\u54C1\u9078\u64C7\u3002";
var APPB2C_common_bookTest_noDiscount_msg_reserved4 = "\u6B64\u4EE3\u91D1\u5238\u5DF2\u88AB\u4F7F\u7528\u3002\u8ACB\u5728\u4F7F\u7528\u7684\u5730\u65B9\u5B8C\u6210\u8A3B\u518A, \u6216\u7A0D\u5F8C\u518D\u8A66\u3002";
var APPB2C_common_bookTest_noDiscount_msg_used4 = "\u6B64\u4EE3\u91D1\u5238\u5DF2\u88AB\u4F7F\u7528\u3002\u60A8\u53EF\u4EE5\u7E7C\u7E8C\u9810\u8A02, \u4F46\u60A8\u5FC5\u9808\u652F\u4ED8\u6E2C\u8A66\u7684\u5168\u50F9\u3002";
var APPB2C_common_bookTest_noDiscount_title4 = "\u8ACB\u6CE8\u610F";
var APPB2C_common_bookTest_results_book4 = "\u5831\u540D\u6E2C\u8A66";
var APPB2C_common_bookTest_results_bookFor4 = "\u9810\u8A02";
var APPB2C_common_bookTest_results_cd4 = "\u96C5\u601D\u96FB\u8166\u6E2C\u9A57";
var APPB2C_common_bookTest_results_changeSpeaking4 = "\u66F4\u6539\u6F14\u8B1B\u6642\u7A0B\u8868";
var APPB2C_common_bookTest_results_clearFilters4 = "\u6E05\u9664\u7BE9\u9078\u689D\u4EF6\u8A2D\u5B9A\u4E26\u986F\u793A\u6240\u6709\u8003\u8A66";
var APPB2C_common_bookTest_results_fromCurrentLocation4 = "\u8DDD\u7576\u524D\u4F4D\u7F6E\u516C\u91CC";
var APPB2C_common_bookTest_results_lrw4 = "\u807D\u529B\u3001\u95B1\u8B80\u548C\u5BEB\u4F5C";
var APPB2C_common_bookTest_results_noResultsGoBack4 = "\u6211\u5011\u627E\u4E0D\u5230\u4EFB\u4F55\u6E2C\u8A66, \u8ACB<1>\u8FD4\u56DE</1>\u4E26\u5617\u8A66\u66F4\u6539\u60A8\u7684\u4F4D\u7F6E\u6216\u65E5\u671F";
var APPB2C_common_bookTest_results_noResultsText4 = "\u6211\u5011\u7121\u6CD5\u627E\u5230\u4EFB\u4F55\u6EFF\u8DB3\u60A8\u9700\u6C42\u7684\u6E2C\u8A66";
var APPB2C_common_bookTest_results_noResultsTitle4 = "\u4E0D\u597D\u610F\u601D";
var APPB2C_common_bookTest_results_pb4 = "\u96C5\u601D\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_common_bookTest_results_review4 = "\u548C\u5BE9\u67E5";
var APPB2C_common_bookTest_results_searchText4 = "\u6211\u5011\u6B63\u5728\u641C\u5C0B\u60A8\u7684\u6E2C\u9A57...";
var APPB2C_common_bookTest_results_speaking4 = "\u53E3\u8A9E\u6E2C\u8A66";
var APPB2C_common_bookTest_results_viewOnMap4 = "\u5728\u5730\u5716\u4E0A\u67E5\u770B";
var APPB2C_common_bookTest_sortBy_date4 = "\u65E5\u671F";
var APPB2C_common_bookTest_sortBy_distance4 = "\u8DDD\u96E2";
var APPB2C_common_bookTest_sortBy_price4 = "\u50F9\u683C";
var APPB2C_common_bookTest_sortBy_title4 = "\u6392\u5E8F\u65B9\u5F0F";
var APPB2C_common_bookTest_title4 = "\u5831\u540D\u6E2C\u9A57";
var APPB2C_common_cancelBooking_alreadyRequested_cta4 = "\u56DE\u5230\u6211\u7684\u8003\u8A66\u5831\u540D";
var APPB2C_common_cancelBooking_alreadyRequested_title4 = "\u60A8\u5DF2\u7D93\u7533\u8ACB\u6B64\u670D\u52D9\u3002";
var APPB2C_common_cancelBooking_back4 = "\u6211\u4E0D\u60F3\u53D6\u6D88\u8003\u8A66";
var APPB2C_common_cancelBooking_bankDetails_form_accountHolder4 = "\u9280\u884C\u5E33\u6236\u6301\u6709\u4EBA\u5730\u5740";
var APPB2C_common_cancelBooking_bankDetails_form_bankAddress4 = "\u9280\u884C\u5206\u884C\u5730\u5740";
var APPB2C_common_cancelBooking_bankDetails_form_bankName4 = "\u9280\u884C\u540D\u7A31";
var APPB2C_common_cancelBooking_bankDetails_form_bankNumber4 = "\u9280\u884C\u5E33\u865F";
var APPB2C_common_cancelBooking_bankDetails_form_changeCountry4 = "\u66F4\u6539\u570B\u5BB6/\u5730\u5340";
var APPB2C_common_cancelBooking_bankDetails_form_city4 = "\u57CE\u93AE / \u57CE\u5E02";
var APPB2C_common_cancelBooking_bankDetails_form_country4 = "\u570B\u5BB6/\u5730\u5340";
var APPB2C_common_cancelBooking_bankDetails_form_holderEmail4 = "\u5E33\u6236\u6301\u6709\u4EBA\u7684\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_common_cancelBooking_bankDetails_form_holderName4 = "\u5E33\u6236\u6301\u6709\u4EBA\u59D3\u540D";
var APPB2C_common_cancelBooking_bankDetails_form_iban4 = "IBAN \u4EE3\u78BC";
var APPB2C_common_cancelBooking_bankDetails_form_invalidIban4 = "IBAN \u4EE3\u78BC\u4E0D\u6B63\u78BA\u3002";
var APPB2C_common_cancelBooking_bankDetails_form_invalidSwift4 = "\u4E0D\u6B63\u78BA\u7684 SWIFT \u4EE3\u78BC\u3002";
var APPB2C_common_cancelBooking_bankDetails_form_postcode4 = "\u90F5\u905E\u5340\u865F / \u90F5\u7DE8";
var APPB2C_common_cancelBooking_bankDetails_form_sortCode4 = "\u6392\u5E8F\u4EE3\u78BC";
var APPB2C_common_cancelBooking_bankDetails_form_swift4 = "SWIFT \u4EE3\u78BC";
var APPB2C_common_cancelBooking_bankDetails_note4 = "\u5982\u679C\u60A8\u7684\u53D6\u6D88\u7533\u8ACB\u6210\u529F, \u8ACB\u63D0\u4F9B\u60A8\u6B32\u6536\u9000\u6B3E\u7684\u9280\u884C\u5E33\u6236\u8A73\u7D30\u8CC7\u8A0A\u3002";
var APPB2C_common_cancelBooking_bankDetails_privacy4 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u5C07\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A\u4F86\u8655\u7406\u76F8\u95DC\u8ACB\u6C42\u3002\u8A73\u7D30\u8CC7\u8A0A\u8ACB\u53C3\u95B1\u6211\u5011\u7DB2\u7AD9\u7684<0>\u96B1\u79C1\u90E8\u5206.</0>";
var APPB2C_common_cancelBooking_bankDetails_terms_consent4 = "\u6211\u5728\u6B64\u540C\u610F\u900F\u904E\u539F\u59CB\u4ED8\u6B3E\u65B9\u5F0F\u7684\u66FF\u4EE3\u65B9\u6848\u9032\u884C\u9000\u6B3E, \u4E26\u5C07\u6B3E\u9805\u9000\u81F3\u6240\u63D0\u4F9B\u7684\u9280\u884C\u5E33\u6236\u8CC7\u8A0A\u3002\u6211\u77AD\u89E3, \u82E5\u6211\u63D0\u4F9B\u7684\u5E33\u6236\u8CC7\u8A0A\u4E0D\u6B63\u78BA, \u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u4E26\u4E0D\u6703\u5C0D\u8F49\u5E33\u932F\u8AA4\u8CA0\u8CAC\u3002";
var APPB2C_common_cancelBooking_bankDetails_terms_title4 = "\u6211\u5011\u7684\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_cancelBooking_bankDetails_title4 = "\u9280\u884C\u5E33\u6236\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_cancelBooking_bottomNotes_note14 = "\u7576\u6211\u5011\u6536\u5230\u60A8\u7684\u53D6\u6D88\u8ACB\u6C42, \u5982\u679C\u9700\u8981\u5176\u4ED6\u8CC7\u8A0A, \u6211\u5011\u5C07\u76F4\u63A5\u8207\u60A8\u806F\u7E6B\u3002";
var APPB2C_common_cancelBooking_bottomNotes_note24 = "\u9000\u6B3E\u9808\u7D93\u6279\u51C6, \u60A8\u5C07\u57281\u9031\u5167\u6536\u5230\u63A5\u53D7/\u62D2\u7D55\u8ACB\u6C42\u7684\u78BA\u8A8D\u3002\u60A8\u7684\u9000\u6B3E\u53EF\u80FD\u6703\u5F9E\u4E2D\u6263\u9664\u624B\u7E8C\u8CBB\uFF1B\u8ACB\u95B1\u8B80 <0>\u96C5\u601D\u8003\u8A66\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_cancelBooking_bottomNotes_note34 = "\u5982\u7372\u5F97\u6279\u51C6, \u60A8\u7684\u9000\u6B3E\u7533\u8ACB\u5C07\u5728\u63D0\u4EA4\u5F8C\u7684 3 \u5468\u5167\u8655\u7406\u3002";
var APPB2C_common_cancelBooking_bottomNotes_terms4 = "\u5728\u63D0\u4EA4\u7533\u8ACB\u4E4B\u524D, \u8ACB\u4ED4\u7D30\u95B1\u8B80<0>\u96C5\u601D\u8003\u8A66\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>\u3002";
var APPB2C_common_cancelBooking_cancelNotes_14 = "\u53D6\u6D88\u8003\u8A66\u9808\u7D93\u82F1\u570B\u6587\u5316\u5354\u6703\u6279\u51C6\u3002";
var APPB2C_common_cancelBooking_cancelNotes_24 = "\u4F5C\u696D\u624B\u7E8C\u8CBB\u5C07\u5F9E\u60A8\u7684\u9000\u8CBB\u4E2D\u6263\u9664\u3002";
var APPB2C_common_cancelBooking_cancelNotes_34 = "\u5728\u63D0\u4EA4\u7533\u8ACB\u4E4B\u524D, \u8ACB\u4ED4\u7D30\u95B1\u8B80<0>\u96C5\u601D\u8003\u8A66\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u8CBB\u653F\u7B56</0>";
var APPB2C_common_cancelBooking_checkTest4 = "\u8ACB\u78BA\u8A8D\u6B64\u70BA\u60A8\u6B32\u53D6\u6D88\u7684\u8003\u8A66: ";
var APPB2C_common_cancelBooking_cta_addBankDetails4 = "\u65B0\u589E\u60A8\u7684\u9280\u884C\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_cancelBooking_cta_goBack4 = "\u8FD4\u56DE\u7DE8\u8F2F\u53D6\u6D88\u539F\u56E0";
var APPB2C_common_cancelBooking_cta_submit4 = "\u63D0\u4EA4\u53D6\u6D88\u7533\u8ACB";
var APPB2C_common_cancelBooking_error_cancelledAlready4 = "\u6B64\u9810\u8A02\u5DF2\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_cancelBooking_error_missingFile4 = "\u8ACB\u4E0A\u50B3\u8B49\u660E\u60A8\u53D6\u6D88\u539F\u56E0\u7684\u8B49\u64DA\u3002";
var APPB2C_common_cancelBooking_error_requestedAlready4 = "\u6B64\u9810\u8A02\u5DF2\u63D0\u51FA\u53D6\u6D88\u8ACB\u6C42\u3002";
var APPB2C_common_cancelBooking_reason_form_choose4 = "\u8ACB\u9078\u64C7\u4E00\u500B\u9078\u9805";
var APPB2C_common_cancelBooking_reason_form_freeform4 = "\u8ACB\u65BC\u4E0B\u65B9\u65B0\u589E\u66F4\u591A\u8A73\u7D30\u8CC7\u8A0A: ";
var APPB2C_common_cancelBooking_reason_form_optional4 = "(\u975E\u5FC5\u9078)";
var APPB2C_common_cancelBooking_reason_form_requiredEvidence4 = "\u9700\u8981\u8B49\u660E\u3002";
var APPB2C_common_cancelBooking_reason_form_requiredReason4 = "\u8ACB\u9078\u64C7\u53D6\u6D88\u9810\u8A02\u7684\u539F\u56E0\u3002";
var APPB2C_common_cancelBooking_reason_form_title4 = "\u70BA\u4EC0\u9EBC\u9700\u8981\u53D6\u6D88\u6B64\u8003\u8A66\uFF1F";
var APPB2C_common_cancelBooking_reason_note_content4 = "\u7531\u65BC\u8DDD\u96E2\u60A8\u7684\u8003\u8A66\u65E5\u671F\u4E0D\u5230 5 \u9031, \u8ACB\u63D0\u4F9B\u53D6\u6D88\u8003\u8A66\u7684\u539F\u56E0\u4E26\u9644\u4E0A\u76F8\u95DC\u6587\u4EF6/\u8B49\u660E\u3002";
var APPB2C_common_cancelBooking_reason_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_cancelBooking_subtitle4 = "\u9700\u8981\u53D6\u6D88\u8003\u8A66\uFF1F";
var APPB2C_common_cancelBooking_title4 = "\u53D6\u6D88\u60A8\u7684\u8003\u8A66";
var APPB2C_common_cancelBooking_transferNote_cta4 = "\u7533\u8ACB\u7570\u52D5";
var APPB2C_common_cancelBooking_transferNote_title4 = "\u60A8\u662F\u5426\u77E5\u9053\u60A8\u53EF\u4EE5\u5C07\u8003\u8A66\u7570\u52D5\u81F3\u5176\u4ED6\u65E5\u671F\u6216\u5730\u9EDE\uFF1F";
var APPB2C_common_cancelBooking_transferNote_titleIol4 = "\u60A8\u77E5\u9053\u60A8\u53EF\u4EE5\u5C07\u8003\u8A66\u7570\u52D5\u5230\u5176\u4ED6\u65E5\u671F\u55CE\uFF1F";
var APPB2C_common_cancelBooking_upload_mobile4 = "\u60A8\u770B\u8D77\u4F86\u6B63\u5728\u4F7F\u7528\u884C\u52D5\u88DD\u7F6E, \u6211\u5011\u5EFA\u8B70\u60A8\u5F9E\u96FB\u8166\u4E0A\u50B3\u7167\u7247\u3002";
var APPB2C_common_cancelBooking_upload_notes_14 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u7531\u5C08\u696D\u91AB\u751F\u7C3D\u767C\u7684\u91AB\u7642\u8B49\u660E\u526F\u672C\u3002\u91AB\u7642\u8B49\u660E\u5FC5\u9808\u5305\u542B\u75BE\u75C5\u6027\u8CEA\u548C\u5176\u4ED6\u76F8\u95DC\u8CC7\u8A0A, \u9019\u80FD\u5E6B\u52A9\u8A55\u4F30\u6B64\u7B46\u7533\u8ACB, \u4EE5\u4F9B\u7279\u5225\u8003\u616E\u3002";
var APPB2C_common_cancelBooking_upload_notes_104 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u7531\u65BC\u60A8\u6240\u8A8D\u5B9A\u4E4B\u6709\u6548\u7279\u5B9A\u539F\u56E0\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_24 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u56E0\u670D\u5175\u5F79\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_34 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u56E0\u55AA\u89AA\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_44 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u7531\u65BC\u91CD\u8907\u5831\u540D\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_54 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u56E0\u5B98\u65B9\u8981\u6C42\u6216\u51FA\u65BC\u5B98\u65B9\u76EE\u7684\u51FA\u5DEE\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_64 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u56E0\u4E0D\u53EF\u6297\u529B\u56E0\u7D20\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_74 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u5728\u8003\u8A66\u7576\u5929\u7531\u65BC\u6280\u8853\u554F\u984C\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_notes_84 = "\u60A8\u5FC5\u9808\u4E0A\u50B3\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8B49\u660E\u60A8\u56E0\u82F1\u570B\u6587\u5316\u5354\u6703\u53D6\u6D88\u6E2C\u9A57\u800C\u7121\u6CD5\u53C3\u52A0\u8003\u8A66\u3002";
var APPB2C_common_cancelBooking_upload_requirements_note4 = "\u60A8\u4E0A\u50B3\u7684\u8B49\u660E\u9700\u80FD\u6E05\u695A\u95B1\u8B80\u3002\u60A8\u6700\u591A\u53EF\u4EE5\u65B0\u589E 4 \u500B\u6A94\u6848\u3002";
var APPB2C_common_cancelBooking_upload_requirements_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_cancelBooking_upload_title4 = "\u4E0A\u50B3\u8B49\u660E";
var APPB2C_common_changeBooking_cancel_cta4 = "\u8ACB\u6C42\u53D6\u6D88";
var APPB2C_common_changeBooking_cancel_text4 = "\u6211\u9700\u8981\u53D6\u6D88\u6E2C\u8A66: ";
var APPB2C_common_changeBooking_goBack4 = "\u6211\u4E0D\u60F3\u66F4\u6539\u6211\u7684\u9810\u8A02";
var APPB2C_common_changeBooking_subtitle4 = "\u60A8\u60F3\u5C0D\u6B64\u9810\u8A02\u9032\u884C\u54EA\u4E9B\u66F4\u6539\uFF1F";
var APPB2C_common_changeBooking_title4 = "\u66F4\u6539\u9810\u8A02";
var APPB2C_common_changeBooking_transfer_cta4 = "\u8ACB\u6C42\u8F49\u79FB";
var APPB2C_common_changeBooking_transfer_text4 = "\u6211\u9700\u8981\u66F4\u6539\u6E2C\u8A66\u7684\u65E5\u671F\u3001\u6642\u9593\u6216\u5730\u9EDE: ";
var APPB2C_common_changeBooking_transfer_textIol4 = "\u6211\u9700\u8981\u5C07\u6E2C\u8A66\u8F49\u79FB\u5230\u5176\u4ED6\u65E5\u671F: ";
var APPB2C_common_changeBooking_transferNotes_14 = "\u8F49\u5B78\u548C\u53D6\u6D88\u7533\u8ACB\u9808\u7D93\u8003\u8A66\u4E2D\u5FC3\u6279\u51C6\u3002";
var APPB2C_common_changeBooking_transferNotes_24 = "\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u56DE\u5FA9\u6240\u6709\u8ACB\u6C42";
var APPB2C_common_changeBooking_transferNotesIol_14 = "\u63A5\u9001\u548C\u53D6\u6D88\u9808\u7D93\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u6279\u51C6\u3002";
var APPB2C_common_changeBooking_transferNotesIol_24 = "\u5728\u63D0\u4EA4\u7533\u8ACB\u4E4B\u524D, \u8ACB\u4ED4\u7D30\u95B1\u8B80<0>\u96C5\u601D\u8003\u8A66\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_changeBookingAck_back4 = "\u8FD4\u56DE\u6211\u7684\u8003\u8A66\u5831\u540D\u8A18\u9304";
var APPB2C_common_changeBookingAck_cancel_banner4 = "\u6211\u5011\u5DF2\u6536\u5230\u60A8\u53D6\u6D88\u6E2C\u9A57\u7684\u7533\u8ACB\u3002";
var APPB2C_common_changeBookingAck_cancel_contact_email4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_common_changeBookingAck_cancel_contact_phone4 = "\u96FB\u8A71";
var APPB2C_common_changeBookingAck_cancel_contact_title4 = "\u5982\u6709\u4EFB\u4F55\u7591\u554F, \u8ACB\u806F\u7E6B: ";
var APPB2C_common_changeBookingAck_cancel_contact_titleIol4 = "\u5982\u679C\u60A8\u6709\u4EFB\u4F55\u5176\u4ED6\u7591\u554F, \u8ACB<0>\u67E5\u770B\u6211\u5011\u7684\u5E6B\u52A9\u9801\u9762</0>\u3002";
var APPB2C_common_changeBookingAck_cancel_refNr4 = "\u6211\u5011\u53EF\u4EE5\u66F4\u5FEB\u5730\u70BA\u60A8\u63D0\u4F9B\u8AAC\u660E, \u8ACB\u63D0\u4F9B\u60A8\u7684\u96C5\u601D\u5831\u540D\u5E8F\u865F: ";
var APPB2C_common_changeBookingAck_cancel_refunds4 = "\u9000\u6B3E\u9808\u7D93\u6279\u51C6, \u60A8\u5C07\u57281\u9031\u5167\u6536\u5230\u63A5\u53D7/\u62D2\u7D55\u60A8\u7684\u8ACB\u6C42\u7684\u78BA\u8A8D\u3002";
var APPB2C_common_changeBookingAck_cancel_respondTime4 = "\u60A8\u7684\u9000\u6B3E\u91D1\u984D\u6700\u591A\u9700\u8981 3 \u9031\u7684\u6642\u9593\u9000\u9084\u5230\u60A8\u7684\u5361\u4E2D\u6216\u8A18\u5165\u60A8\u7684\u9280\u884C\u5E33\u6236\u3002";
var APPB2C_common_changeBookingAck_cancel_terms4 = "\u6709\u95DC\u8F49\u8B93\u653F\u7B56\u7684\u5B8C\u6574\u8A73\u60C5, \u8ACB\u53C3\u95B1\u6211\u5011\u7684<0>\u96C5\u601D\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_changeBookingAck_cancel_title4 = "\u53D6\u6D88\u7533\u8ACB\u78BA\u8A8D";
var APPB2C_common_changeBookingAck_fees4 = "\u53EF\u80FD\u6703\u6536\u53D6\u8CBB\u7528\u3002\u6211\u5011\u5C07\u76F4\u63A5\u806F\u7E6B\u60A8\u4E26\u78BA\u8A8D\u3002";
var APPB2C_common_changeBookingAck_feesIol4 = "\u53EF\u80FD\u6703\u6536\u53D6\u884C\u653F\u8CBB\u7528\u3002\u5982\u679C\u8ACB\u6C42\u7372\u5F97\u6279\u51C6, \u6211\u5011\u5C07\u5411\u60A8\u767C\u9001\u4ED8\u6B3E\u8AAA\u660E\u3002";
var APPB2C_common_changeBookingAck_speaking_banner4 = "\u60A8\u7684\u53E3\u8AAA\u6E2C\u9A57\u5DF2\u6210\u529F\u8F49\u5834\u3002";
var APPB2C_common_changeBookingAck_speaking_note_line14 = "\u66F4\u6539\u53E3\u8AAA\u6E2C\u9A57\u4E0D\u6703\u5F71\u97FF\u60A8\u7684\u7B46\u8A66\u3002";
var APPB2C_common_changeBookingAck_speaking_note_line24 = "\u66F4\u6539\u53E3\u8AAA\u6E2C\u9A57\u4E0D\u6536\u53D6\u4EFB\u4F55\u8CBB\u7528\u3002";
var APPB2C_common_changeBookingAck_speaking_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_changeBookingAck_speaking_title4 = "\u53E3\u8AAA\u6E2C\u9A57\u8F49\u79FB";
var APPB2C_common_changeBookingAck_terms4 = "\u6709\u95DC\u8F49\u8B93\u653F\u7B56\u7684\u5B8C\u6574\u8A73\u60C5, \u8ACB\u53C3\u95B1\u6211\u5011\u7684<0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_common_changeBookingAck_transfer_banner4 = "\u60A8\u6240\u63D0\u51FA\u8207\u8003\u8A66\u4E2D\u5FC3\u5C08\u4EBA\u8A0E\u8AD6\u8003\u8A66\u7570\u52D5\u7684\u7533\u8ACB\u5DF2\u53D7\u7406\u3002";
var APPB2C_common_changeBookingAck_transfer_note4 = "\u6211\u5011\u5C07\u76F4\u63A5\u806F\u7E6B\u60A8\u5B89\u6392\u65B0\u7684\u8003\u8A66\u65E5\u671F\u3002\u4E00\u65E6\u60A8\u78BA\u8A8D\u65B0\u7684\u8003\u8A66\u65E5\u671F, \u6211\u5011\u5C07\u767C\u9001\u8003\u8A66\u5831\u540D\u66F4\u65B0\u8A73\u60C5\u7D66\u60A8\u3002";
var APPB2C_common_changeBookingAck_transfer_respondTime4 = "\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u56DE\u8986\u6240\u6709\u7533\u8ACB\u3002";
var APPB2C_common_changeBookingAck_transfer_title4 = "\u8F49\u79FB\u7533\u8ACB\u78BA\u8A8D";
var APPB2C_common_changeBookingAck_transferIol_banner4 = "\u6211\u5011\u5DF2\u6536\u5230\u60A8\u6E2C\u9A57\u7570\u52D5\u7684\u8ACB\u6C42\u3002";
var APPB2C_common_changeBookingAck_transferIol_contact4 = "\u5982\u679C\u60A8\u6709\u4EFB\u4F55\u5176\u4ED6\u7591\u554F, \u8ACB<0>\u67E5\u770B\u6211\u5011\u7684\u5E6B\u52A9\u9801\u9762</0>\u3002";
var APPB2C_common_changeBookingAck_transferIol_note4 = "\u6211\u5011\u5C07\u76F4\u63A5\u8207\u60A8\u806F\u7E6B\u5B89\u6392\u65B0\u7684\u8003\u8A66\u65E5\u671F\u3002\u4E00\u65E6\u60A8\u78BA\u8A8D\u65B0\u7684\u8003\u8A66\u65E5\u671F, \u6211\u5011\u5C07\u767C\u9001\u8003\u8A66\u5831\u540D\u66F4\u65B0\u8A73\u60C5\u7D66\u60A8\u3002";
var APPB2C_common_changeBookingAck_transferIol_refNr4 = "\u6211\u5011\u53EF\u4EE5\u66F4\u5FEB\u5730\u70BA\u60A8\u63D0\u4F9B\u8AAC\u660E, \u8ACB\u63D0\u4F9B\u60A8\u7684\u96C5\u601D\u5831\u540D\u5E8F\u865F: ";
var APPB2C_common_changeBookingAck_transferIol_terms4 = "\u6709\u95DC\u8F49\u8B93\u653F\u7B56\u7684\u5B8C\u6574\u8A73\u60C5, \u8ACB\u53C3\u95B1\u6211\u5011\u7684<0>\u96C5\u601D\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_changeSpeaking_cards_change4 = "\u66F4\u6539\u6E2C\u9A57";
var APPB2C_common_changeSpeaking_cards_noSlots4 = "\u5DF2\u6C92\u6709\u540D\u984D";
var APPB2C_common_changeSpeaking_cards_noSlots_error4 = "\u6240\u9078\u53D6\u7684\u53E3\u8AAA\u6642\u9593\u5DF2\u7D93\u984D\u6EFF\u3002\u8ACB\u5617\u8A66\u9078\u64C7\u5176\u4ED6\u53E3\u8AAA\u6642\u9593\u3002";
var APPB2C_common_changeSpeaking_cards_title4 = "\u60A8\u7684\u65B0\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_changeSpeaking_cta4 = "\u8F49\u79FB\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_changeSpeaking_modals_noSlots_keepTest4 = "\u7E7C\u7E8C\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_changeSpeaking_modals_noSlots_line14 = "\u6211\u5011\u525B\u525B\u78BA\u8A8D\u904E, \u6C92\u6709\u5176\u4ED6\u53E3\u8A66\u5834\u6B21\u53EF\u4EE5\u5831\u540D, \u5F88\u62B1\u6B49\uFF01";
var APPB2C_common_changeSpeaking_modals_noSlots_line24 = "\u5982\u679C\u60A8\u78BA\u5BE6\u9700\u8981\u66F4\u6539\u53E3\u8A9E\u8003\u8A66, \u8ACB\u8ACB\u6C42\u66F4\u6539\u8003\u8A66\u65E5\u671F, \u7136\u5F8C\u60A8\u53EF\u4EE5\u9078\u64C7\u65B0\u7684\u53E3\u8A9E\u8003\u8A66\u3002";
var APPB2C_common_changeSpeaking_modals_noSlots_title4 = "\u5C0D\u4E0D\u8D77 - \u6C92\u6709\u53EF\u7528\u7684\u53E3\u8A9E\u6E2C\u8A66\uFF01";
var APPB2C_common_changeSpeaking_modals_noSlots_transferTest4 = "\u6539\u70BA\u7533\u8ACB\u8F49\u79FB";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_cta4 = "\u9078\u64C7\u65B0\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line14 = "\u6211\u5011\u5DF2\u78BA\u8A8D\u60A8\u9078\u64C7\u7684\u53E3\u8AAA\u6E2C\u9A57\u7121\u6CD5\u5831\u540D, \u5F88\u62B1\u6B49\uFF01";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line24 = "\u8ACB\u9078\u64C7\u5176\u4ED6\u53E3\u8AAA\u6E2C\u9A57\u3002";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_title4 = "\u9019\u5834\u53E3\u8AAA\u6E2C\u9A57\u7121\u6CD5\u5831\u540D\uFF01";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta4 = "\u7E7C\u7E8C\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_line14 = "\u6211\u5011\u5DF2\u78BA\u8A8D\u6C92\u6709\u5176\u4ED6\u53E3\u8AAA\u6E2C\u9A57\u53EF\u5831\u540D, \u62B1\u6B49\uFF01";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_title4 = "\u5C0D\u4E0D\u8D77 - \u6C92\u6709\u53EF\u7528\u7684\u53E3\u8A9E\u6E2C\u8A66\uFF01";
var APPB2C_common_changeSpeaking_note_back4 = "\u6211\u4E0D\u60F3\u7570\u52D5\u6211\u7684\u5831\u540D";
var APPB2C_common_changeSpeaking_note_line14 = "\u66F4\u6539\u53E3\u8AAA\u6E2C\u9A57\u4E0D\u6703\u5F71\u97FF\u60A8\u7684\u7B46\u8A66\u3002";
var APPB2C_common_changeSpeaking_note_line24 = "\u66F4\u6539\u53E3\u8AAA\u6E2C\u9A57\u4E0D\u6536\u53D6\u4EFB\u4F55\u8CBB\u7528\u3002";
var APPB2C_common_changeSpeaking_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_changeSpeaking_title4 = "\u66F4\u6539\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_chooseTest_difference4 = "\u96C5\u601D\u6E2C\u9A57\u5728\u73FE\u5834\u8003\u8A66\u8207\u7DDA\u4E0A\u8003\u8A66\u7684\u5DEE\u5225";
var APPB2C_common_chooseTest_iol_consent4 = "\u6211\u78BA\u8A8D\u5728\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u671F\u9593, \u6211\u9858\u610F\u4F7F\u7528\u81C9\u90E8\u8B58\u5225\u548C\u5176\u4ED6\u76E3\u8003\u6280\u8853\u9032\u884C\u5B89\u5168\u76E3\u63A7\u3002";
var APPB2C_common_chooseTest_iol_cta4 = "\u7DDA\u4E0A\u5831\u540D\u96C5\u601D\u8003\u8A66";
var APPB2C_common_chooseTest_iol_desc4 = "\u5DF2\u5D84\u65B0\u4E14\u5B89\u5168\u7684\u6A21\u5F0F\u96A8\u6642\u96A8\u5730\u9032\u884C\u8003\u8A66\u3002 \u7D50\u5408\u4E86\u7DDA\u4E0A\u6E2C\u9A57\u8207\u8996\u8A0A\u76E3\u63A7\u7684\u512A\u52E2, \u4EE5\u78BA\u4FDD\u5B89\u5168\u6027\u548C\u9AD8\u53EF\u4FE1\u5EA6\u7684\u6210\u7E3E\u3002";
var APPB2C_common_chooseTest_iol_link4 = "\u77AD\u89E3\u66F4\u591A\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u8EDF\u9AD4\u76F8\u95DC\u8CC7\u8A0A";
var APPB2C_common_chooseTest_iol_note_content4 = "\u60A8\u5FC5\u9808\u5E74\u6EFF 18 \u6B72\u624D\u80FD\u53C3\u52A0\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u3002\u5982\u679C\u60A8\u70BA18\u6B72\u4EE5\u4E0B\u4F46\u9700\u5831\u540D\u8003\u8A66, \u8ACB\u5728\u8003\u8A66\u4E2D\u5FC3\u5831\u540D\u96C5\u601D\u73FE\u5834\u8003\u8A66\u3002";
var APPB2C_common_chooseTest_iol_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_chooseTest_iol_title4 = "\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57";
var APPB2C_common_chooseTest_ors_cta4 = "\u5728\u8003\u8A66\u4E2D\u5FC3\u5831\u540D\u96C5\u601D\u8003\u8A66";
var APPB2C_common_chooseTest_ors_descAc4 = "\u5728\u6211\u5011\u7684\u5B98\u65B9\u8003\u8A66\u4E2D\u5FC3\u53C3\u52A0\u96C5\u601D\u5B78\u8853\u7D44\u8003\u8A66, \u60A8\u53EF\u4EE5\u9078\u64C7\u5728\u7D19\u7B46\u6E2C\u9A57\u6216\u96FB\u8166\u6E2C\u9A57\u3002";
var APPB2C_common_chooseTest_ors_descGt4 = "\u5728\u6211\u5011\u7684\u5B98\u65B9\u8003\u8A66\u4E2D\u5FC3\u53C3\u52A0\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66, \u60A8\u53EF\u4EE5\u9078\u64C7\u5728\u7D19\u7B46\u6E2C\u9A57\u6216\u96FB\u8166\u6E2C\u9A57\u3002";
var APPB2C_common_chooseTest_ors_title4 = "\u8003\u8A66\u4E2D\u5FC3\u7684\u96C5\u601D\u8003\u8A66";
var APPB2C_common_chooseTest_title4 = "\u9078\u64C7\u60A8\u7684\u6E2C\u8A66";
var APPB2C_common_chooseTest_ukvi_difference4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u6E2C\u9A57\u5728\u73FE\u5834\u8003\u8A66\u8207\u7DDA\u4E0A\u8003\u8A66\u7684\u5DEE\u5225";
var APPB2C_common_chooseTest_ukvi_inCentre_cta4 = "\u5728\u8003\u8A66\u4E2D\u5FC3\u5831\u540D\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u7684\u96C5\u601D\u8003\u8A66";
var APPB2C_common_chooseTest_ukvi_inCentre_descAc4 = "\u5728\u6211\u5011\u7684\u5B98\u65B9\u8003\u8A66\u4E2D\u5FC3\u53C3\u52A0\u7C3D\u8B49\u96C5\u601D\u5B78\u8853\u7D44\u8003\u8A66, \u60A8\u53EF\u4EE5\u9078\u64C7\u5728\u7D19\u7B46\u6E2C\u9A57\u6216\u96FB\u8166\u6E2C\u9A57\u3002";
var APPB2C_common_chooseTest_ukvi_inCentre_descGt4 = "\u5728\u6211\u5011\u7684\u5B98\u65B9\u8003\u8A66\u4E2D\u5FC3\u53C3\u52A0\u7C3D\u8B49\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66, \u60A8\u53EF\u4EE5\u9078\u64C7\u5728\u7D19\u7B46\u6E2C\u9A57\u6216\u96FB\u8166\u6E2C\u9A57\u3002";
var APPB2C_common_chooseTest_ukvi_inCentre_title4 = "\u5728\u8003\u8A66\u4E2D\u5FC3\u7684\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u8003\u8A66";
var APPB2C_common_chooseTest_ukvi_online_consent4 = "\u6211\u78BA\u8A8D\u5728\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u671F\u9593, \u6211\u9858\u610F\u4F7F\u7528\u81C9\u90E8\u8B58\u5225\u548C\u5176\u4ED6\u76E3\u8003\u6280\u8853\u9032\u884C\u5B89\u5168\u76E3\u63A7\u3002";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol4 = "(\u5728\u8B77\u7167\u4E0A\u5C0B\u627E\u9019\u500B\u7B26\u865F\uFF01)";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_title4 = "\u6211\u78BA\u8A8D\u6211\u6709\u4E00\u672C\u5177RFID\u6676\u5143\u7684\u6709\u6548\u751F\u7269\u7279\u5FB5\u8FA8\u8B58\u8B77\u7167\u3002";
var APPB2C_common_chooseTest_ukvi_online_cta4 = "\u5831\u540D\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57";
var APPB2C_common_chooseTest_ukvi_online_link4 = "\u77AD\u89E3\u66F4\u591A\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u8EDF\u9AD4\u76F8\u95DC\u8CC7\u8A0A";
var APPB2C_common_chooseTest_ukvi_online_note_content4 = "\u60A8\u5FC5\u9808\u5E74\u6EFF 18 \u6B72\u624D\u80FD\u53C3\u52A0\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u7684\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u3002\u5982\u679C\u60A8\u70BA18\u6B72\u4EE5\u4E0B\u4F46\u9700\u5831\u540D\u8003\u8A66, \u8ACB\u5728\u8003\u8A66\u4E2D\u5FC3\u5831\u540D\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u7684\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_common_chooseTest_ukvi_online_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_common_chooseTest_ukvi_online_title4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57";
var APPB2C_common_draft_cancel3 = "\u53D6\u6D88";
var APPB2C_common_draft_cta_confirm4 = "\u78BA\u8A8D\u6211\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_draft_cta_decline4 = "\u4E0D\u8981\u8207\u6211\u7684\u4EE3\u7406\u78BA\u8A8D\u6211\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_draft_cta_note4 = "\u5982\u679C\u60A8\u4E0D\u78BA\u8A8D\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A, {\u4EE3\u7406}} \u5C07\u7121\u6CD5\u70BA\u60A8\u8A3B\u518A\u6E2C\u8A66\u3002\u60A8\u4ECD\u7136\u53EF\u4EE5\u70BA\u81EA\u5DF1\u9810\u8A02\u6E2C\u8A66";
var APPB2C_common_draft_difference4 = "\u60A8\u7684\u4EE3\u7406\u6DFB\u52A0\u4E86\u9019\u500B\u4E0D\u540C\u7684\u7B54\u6848\u3002";
var APPB2C_common_draft_edit4 = "\u6539\u8B8A";
var APPB2C_common_draft_error_pastDeadline4 = "\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A\u78BA\u8A8D\u622A\u6B62\u65E5\u671F\u5DF2\u904E\u3002";
var APPB2C_common_draft_id_cta4 = "\u66F4\u65B0\u6A19\u8B58";
var APPB2C_common_draft_id_documentWithNoExpiryDate4 = "\u6587\u4EF6\u6C92\u6709\u5230\u671F\u65E5\u671F";
var APPB2C_common_draft_id_header4 = "\u60A8\u7684\u8EAB\u4EFD\u8B49\u660E";
var APPB2C_common_draft_id_issuingAuthority4 = "\u767C\u8B49\u6A5F\u95DC";
var APPB2C_common_draft_id_note4 = "\u5728\u8003\u8A66\u7576\u5929, \u60A8\u5C07\u9700\u8981\u651C\u5E36\u60A8\u5728\u4E0B\u9762\u63D0\u4F9B\u7684\u76F8\u540C\u8EAB\u4EFD\u8B49\u660E\u3002";
var APPB2C_common_draft_id_title4 = "\u6AA2\u67E5\u60A8\u7684\u8EAB\u4EFD\u8B49\u4EF6\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_draft_note_line14 = "\u81EA\u60A8\u4E0A\u6B21\u8A2A\u554F\u672C\u7DB2\u7AD9\u4EE5\u4F86, \u60A8\u7684\u96C5\u601D\u8003\u8A66\u4EE3\u7406({{agent}})\u5728\u70BA\u60A8\u8A3B\u518A\u8003\u8A66\u6642\u70BA\u60A8\u6DFB\u52A0\u4E86\u4E0D\u540C\u7684\u8A73\u7D30\u8CC7\u8A0A\u3002";
var APPB2C_common_draft_note_line24 = "\u8ACB\u5728\u4E0B\u9762\u67E5\u770B\u4ED6\u5011\u7684\u66F4\u6539, \u5982\u679C\u60A8\u767C\u73FE\u4ED6\u5011\u72AF\u4E86\u932F\u8AA4, \u8ACB\u66F4\u65B0\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A\u3002";
var APPB2C_common_draft_note_line34 = "\u5982\u679C\u60A8\u4E0D\u78BA\u8A8D\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A, {\u4EE3\u7406}} \u5C07\u7121\u6CD5\u70BA\u60A8\u8A3B\u518A\u6E2C\u8A66\u3002";
var APPB2C_common_draft_personal_cta4 = "\u66F4\u65B0\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_draft_personal_header4 = "\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_draft_personal_note4 = "\u6B64\u9801\u9762\u4E0A\u63D0\u4F9B\u7684\u6240\u6709\u500B\u4EBA\u8A73\u7D30\u4FE1\u606F\u5FC5\u9808\u8207\u7528\u65BC\u8A3B\u518A\u4E26\u5728\u8003\u8A66\u5730\u9EDE\u51FA\u793A\u7684\u61C9\u8A66\u8005\u7684\u8EAB\u4EFD\u8B49\u660E\u6A94\u4E00\u81F4, \u56E0\u70BA\u9019\u4E9B\u8EAB\u4EFD\u8B49\u660E\u6A94\u5C07\u986F\u793A\u5728\u61C9\u8A66\u8005\u7684\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u4E0A\u3002\u8A3B\u518A\u5B8C\u6210\u5F8C, \u5982\u679C\u60A8\u5728\u6211\u5011\u7C3D\u767C\u6210\u7E3E\u55AE\u4E4B\u524D\u8981\u6C42\u6211\u5011\u4FEE\u6539\u4EFB\u4F55\u6B64\u985E\u8CC7\u8A0A, \u60A8\u53EF\u80FD\u6703\u88AB\u6536\u53D6\u8CBB\u7528\u3002\u8ACB\u6CE8\u610F, \u6210\u7E3E\u55AE\u767C\u51FA\u540E\u4E0D\u5F97\u9032\u884C\u4EFB\u4F55\u4FEE\u6539\u3002";
var APPB2C_common_draft_personal_title4 = "\u6AA2\u67E5\u60A8\u7684\u500B\u4EBA\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_draft_title4 = "\u78BA\u8A8D\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_examType_iol_ac4 = "\u96C5\u601D\u5B78\u8853\u985E\u8003\u8A66(\u5728\u7DDA)";
var APPB2C_common_examType_iol_gt4 = "\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66(\u7DDA\u4E0A)";
var APPB2C_common_examType_ors_ac4 = "\u96C5\u601D\u5B78\u8853\u7D44\u8003\u8A66";
var APPB2C_common_examType_ors_gt4 = "\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66";
var APPB2C_common_examType_ors_osr_ac4 = "\u96C5\u601D\u5B78\u8853\u7D44";
var APPB2C_common_examType_ors_osr_gt4 = "\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44";
var APPB2C_common_examType_ukvi_ac4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u5B78\u8853\u985E\u8003\u8A66";
var APPB2C_common_examType_ukvi_gt4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66";
var APPB2C_common_examType_ukvi_lfska14 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57 A1";
var APPB2C_common_examType_ukvi_lfska24 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57 A2";
var APPB2C_common_examType_ukvi_lfskb14 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57 B1";
var APPB2C_common_examType_ukvi_osr_ac4 = "UKVI\u5B78\u8853\u985E\u96C5\u601D\u8003\u8A66";
var APPB2C_common_examType_ukvi_osr_gt4 = "UKVI \u57F9\u8A13\u985E\u96C5\u601D\u8003\u8A66";
var APPB2C_common_examType_uol_ac4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u5B78\u8853\u7D44\u8003\u8A66(\u7DDA\u4E0A)";
var APPB2C_common_examType_uol_gt4 = "\u7528\u65BCUKVI(\u57F9\u8A13\u985E)\u7684\u96C5\u601D\u8003\u8A66(\u666E\u901A\u57F9\u8A13\u985E)\u5728\u7DDA\u8003\u8A66";
var APPB2C_common_filePreview_label4 = "\u5716\u50CF";
var APPB2C_common_filePreview_pdfPreviewLink4 = "\u9810\u89BD\u6B64\u6A94\u6848";
var APPB2C_common_filePreview_status4 = "\u5BE9\u6838\u72C0\u614B";
var APPB2C_common_filePreview_statusName_approved4 = "\u6279\u51C6";
var APPB2C_common_filePreview_statusName_deleted4 = "\u5DF2\u522A\u9664";
var APPB2C_common_filePreview_statusName_pending4 = "\u5F85\u5B9A";
var APPB2C_common_filePreview_statusName_rejected4 = "\u62D2\u7D55";
var APPB2C_common_filePreview_statusName_reviewed4 = "\u5BE9\u67E5\u4E2D";
var APPB2C_common_fileUpload_cta4 = "\u9078\u64C7\u4E00\u500B\u6A94\u6848";
var APPB2C_common_fileUpload_exceededSize4 = "\u6B64\u6587\u4EF6\u5927\u5C0F\u8D85\u904E\u4E0A\u9650";
var APPB2C_common_fileUpload_fileApproved4 = "\u6B64\u6A94\u6848\u5DF2\u6279\u51C6, \u7121\u6CD5\u522A\u9664";
var APPB2C_common_fileUpload_fileOptional4 = "\u5716\u50CF {{number}} (\u975E\u5FC5\u9078)";
var APPB2C_common_fileUpload_fileRequired4 = "\u5716\u7247 1";
var APPB2C_common_fileUpload_filesCount4 = "{{total}} \u7684\u7B2C {{current}} \u9801";
var APPB2C_common_fileUpload_fileSize4 = "\u6587\u4EF6\u4E0D\u5927\u65BC {{size}} MB";
var APPB2C_common_fileUpload_imgAlt4 = "\u6A94\u9810\u89BD";
var APPB2C_common_fileUpload_pdfIeMsg4 = "\u60A8\u7684 PDF \u6A94\u5DF2\u6210\u529F\u4E0A\u50B3, \u4F46\u9810\u89BD\u5728 IE \u700F\u89BD\u5668\u4E2D\u4E0D\u9069\u7528\u3002\u60A8\u662F\u5426\u77E5\u9053\u81EA 2015 \u5E74\u4EE5\u4F86\u4E0D\u518D\u652F\u63F4 IE\uFF1F";
var APPB2C_common_fileUpload_pdfPasswordMsg4 = "\u4E0D\u5141\u8A31\u4E0A\u50B3\u52A0\u5BC6\u6A94\u6848\u3002";
var APPB2C_common_fileUpload_remove4 = "\u522A\u9664\u6B64\u6A94\u6848";
var APPB2C_common_fileUpload_requirements4 = "\u8ACB\u50C5\u4E0A\u50B3: ";
var APPB2C_common_fileUpload_success4 = "\u6587\u4EF6<bold><italic>{{fileName}}</italic></bold> \u65B0\u589E\u6210\u529F\uFF01";
var APPB2C_common_findTest_changeCity4 = "\u66F4\u6539\u57CE\u5E02";
var APPB2C_common_findTest_changeCountry4 = "\u66F4\u6539\u570B\u5BB6/\u5730\u5340";
var APPB2C_common_findTest_cta4 = "\u641C\u5C0B\u8003\u8A66";
var APPB2C_common_findTest_dates_clear4 = "\u6E05\u9664";
var APPB2C_common_findTest_dates_from4 = "\u5F9E";
var APPB2C_common_findTest_dates_noDatesSelected4 = "\u62B1\u6B49 \u2013 \u60A8\u9078\u64C7\u7684\u65E5\u671F\u7BC4\u570D\u5167\u6C92\u6709\u53EF\u5831\u540D\u7684\u8003\u8A66\u3002\u8ACB\u64F4\u5927\u60A8\u7684\u65E5\u671F\u7BC4\u570D";
var APPB2C_common_findTest_dates_noresults_details4 = "\u5C0D\u4E0D\u8D77\uFF1B\u6C92\u6709\u53EF\u884C\u7684\u8003\u8A66\u65E5\u671F\u3002\u8ACB\u9078\u64C7\u5176\u4ED6\u4F4D\u7F6E\u6216\u66F4\u6539\u7BE9\u9078\u689D\u4EF6";
var APPB2C_common_findTest_dates_noresults_title4 = "\u672A\u627E\u5230\u8003\u8A66\u65E5\u671F";
var APPB2C_common_findTest_dates_noresultsMobileLocation_cta4 = "\u95DC\u6CE8\u60A8\u7684\u504F\u597D";
var APPB2C_common_findTest_dates_noresultsMobileLocation_details4 = "\u76EE\u524D\u6C92\u6709\u8003\u8A66\u65E5\u671F\u8A2D\u65BC\u6B64\u5730\u9EDE, \u60A8\u53EF\u4EE5\u95DC\u6CE8\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u7684\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_common_findTest_dates_noresultsMobileLocation_title4 = "\u672A\u627E\u5230\u8003\u8A66\u65E5\u671F";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_details4 = "\u7531\u65BC\u60A8\u5DF2\u544A\u77E5\u8003\u8A66\u7576\u5929\u9700\u8981\u7279\u6B8A\u5B89\u6392, \u56E0\u6B64\u6211\u5011\u7121\u6CD5\u5728\u60A8\u9078\u64C7\u7684\u5730\u9EDE\u627E\u5230\u4EFB\u4F55\u8003\u8A66\u65E5\u671F\u3002\u8ACB\u900F\u904E\u4EE5\u4E0B\u8A73\u60C5\u76F4\u63A5\u8207\u6211\u5011\u806F\u7E6B, \u4EE5\u8AAA\u660E\u5982\u4F55\u70BA\u60A8\u63D0\u4F9B\u5354\u52A9\u3002";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_title4 = "\u5C0D\u4E0D\u8D77\uFF1B\u6C92\u6709\u53EF\u884C\u7684\u8003\u8A66\u65E5\u671F";
var APPB2C_common_findTest_dates_pleaseSelect4 = "\u8ACB\u4F7F\u7528\u4E0B\u9762\u7684\u65E5\u66C6";
var APPB2C_common_findTest_dates_selected4 = "\u9078\u64C7";
var APPB2C_common_findTest_dates_showAll4 = "\u986F\u793A\u6240\u6709\u65E5\u671F";
var APPB2C_common_findTest_dates_showDateRange4 = "\u8B93\u6211\u9078\u64C7\u65E5\u671F";
var APPB2C_common_findTest_dates_title4 = "\u60A8\u60F3\u4EC0\u9EBC\u6642\u5019\u53C3\u52A0\u8003\u8A66\uFF1F";
var APPB2C_common_findTest_dates_to4 = "\u81EA";
var APPB2C_common_findTest_examFormat_all4 = "\u6211\u4E0D\u4ECB\u610F";
var APPB2C_common_findTest_examFormat_cd4 = "\u96C5\u601D\u6A5F\u8003";
var APPB2C_common_findTest_examFormat_pb4 = "\u96C5\u601D\u7D19\u7B46\u8003\u8A66";
var APPB2C_common_findTest_examFormat_results4 = "(\u7D50\u679C\u65BC {{days}} \u5929\u5167\u91CB\u51FA)";
var APPB2C_common_findTest_examFormat_title4 = "\u60A8\u60F3\u53C3\u52A0\u54EA\u7A2E\u5F62\u5F0F\u7684\u96C5\u601D\u8003\u8A66\uFF1F";
var APPB2C_common_findTest_iol_noLrwResults4 = "\u5C0D\u4E0D\u8D77\uFF1B\u60A8\u6240\u5728\u570B\u5BB6/\u5730\u5340\u6C92\u6709\u53EF\u7528\u7684\u8003\u8A66\u65E5\u671F\u3002";
var APPB2C_common_findTest_iol_organisationCheck_check4 = "\u6211\u5DF2\u78BA\u8A8D\u6211\u7684\u76EE\u6A19\u6A5F\u69CB\u662F\u5426\u63A5\u53D7\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u3002";
var APPB2C_common_findTest_iol_organisationCheck_desc4 = "\u5728\u5831\u540D\u8003\u8A66\u4E4B\u524D, \u60A8\u5FC5\u9808\u8207\u60A8\u7684\u6A5F\u69CB\u78BA\u8A8D\u4ED6\u5011\u662F\u5426\u63A5\u53D7\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u3002";
var APPB2C_common_findTest_iol_organisationCheck_title4 = "\u60A8\u7684\u76EE\u6A19\u7D44\u7E54\u6216\u6A5F\u69CB\u662F\u5426\u63A5\u53D7\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\uFF1F";
var APPB2C_common_findTest_iol_softwareCheck_check4 = "\u6211\u78BA\u8A8D\u5DF2\u6AA2\u67E5\u8A2D\u5099\u548C\u74B0\u5883\u8981\u6C42\u4EE5\u53C3\u52A0\u6E2C\u9A57";
var APPB2C_common_findTest_iol_softwareCheck_desc14 = "\u60A8\u7684\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u4F7F\u7528 Inspera \u8EDF\u9AD4, \u9700\u8981\u5728 Windows PC \u6216 MacOS \u4E0A\u4E0B\u8F09(\u60A8\u4E0D\u80FD\u5728\u624B\u6A5F\u6216\u5E73\u677F\u96FB\u8166\u4E0A\u53C3\u52A0\u8003\u8A66\uFF01)\u3002";
var APPB2C_common_findTest_iol_softwareCheck_desc24 = "\u5411\u6211\u5011\u5831\u540D\u8003\u8A66\u4E4B\u524D, \u60A8\u5FC5\u9808\u6AA2\u67E5\u96FB\u8166\u8A2D\u5099\u662F\u5426\u7B26\u5408\u5EFA\u8B70\u7684\u7CFB\u7D71\u8981\u6C42\u3002";
var APPB2C_common_findTest_iol_softwareCheck_link4 = "\u6AA2\u67E5\u6E2C\u9A57\u7684\u8A2D\u5099\u8981\u6C42";
var APPB2C_common_findTest_iol_softwareCheck_title4 = "\u60A8\u7684\u96FB\u8166\u53EF\u4EE5\u904B\u884C\u8003\u8A66\u6240\u9700\u7684\u8EDF\u9AD4\u55CE\uFF1F";
var APPB2C_common_findTest_langModal_choose4 = "\u60A8\u4E4B\u524D\u4EE5{{languageName}}. \u700F\u89BD\u6B64\u7DB2\u7AD9\u3002\u6B64\u8A9E\u8A00\u4E0D\u652F\u63F4\u5728{{countryName}}. \u9810\u8A02\u8003\u8A66\u3002\u8ACB\u5F9E\u4E0B\u5217\u9078\u9805\u4E2D\u64C7\u4E00\u9032\u884C: ";
var APPB2C_common_findTest_langModal_chooseSingle4 = "\u60A8\u4E4B\u524D\u4EE5{{languageName}}. \u700F\u89BD\u6B64\u7DB2\u7AD9\u3002\u6B64\u8A9E\u8A00\u4E0D\u652F\u63F4\u5728{{countryName}}. \u9810\u8A02\u8003\u8A66\u3002\u8ACB\u5F9E\u4E0B\u5217\u8A9E\u8A00\u4E2D\u64C7\u4E00\u9032\u884C: ";
var APPB2C_common_findTest_langModal_continue4 = "\u60A8\u73FE\u5728\u53EF\u4EE5\u4E0B\u5217\u5176\u4E2D\u4E4B\u4E00\u7A2E\u8A9E\u8A00\u7E7C\u7E8C\u5B8C\u6210\u9810\u5B9A: ";
var APPB2C_common_findTest_langModal_title4 = "\u60A8\u5DF2\u66F4\u6539\u53C3\u52A0\u8003\u8A66\u7684\u5730\u9EDE";
var APPB2C_common_findTest_multipleLocations4 = "\u591A\u500B\u5730\u9EDE";
var APPB2C_common_findTest_osrInfo4 = "\u96C5\u601D\u7D19\u7B46\u985E\u8003\u8A66\u4E0D\u63D0\u4F9B<bold>\u4E00\u6B21\u6280\u80FD\u91CD\u8003</bold>, \u8ACB\u8003\u616E\u9810\u8A02\u96C5\u601D\u6A5F\u8003\u4EE5\u7372\u5F97\u6B64\u9078\u9805\u3002";
var APPB2C_common_findTest_osrInfoLink4 = "\u67E5\u770B\u66F4\u591A about One Skill Retake";
var APPB2C_common_findTest_residencyConfirmation_btn4 = "\u77E5\u9053\u4E86, \u7E7C\u7E8C\u641C\u7D22";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_btn4 = "\u66F4\u6539\u6E2C\u8A66\u985E\u578B";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line14 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9078\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11, \u5247\u7121\u6CD5\u9810\u8A02\u7D19\u7B46\u8003\u8A66\u3002\u8ACB<0>\u9078\u64C7\u8A08\u7B97\u6A5F\u8003\u8A66\u6216\u9078\u64C7\u60A8\u5C45\u4F4F\u570B\u7684\u7D19\u7B46\u8003\u8A66.</0>";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line24 = "\u5728\u300C\u9810\u7D04\u6E2C\u8A66\u300D\u9801\u9762\u4E0A, \u60A8\u53EF\u4EE5\u4F7F\u7528\u904E\u6FFE\u689D\u4EF6\u66F4\u6539\u6E2C\u8A66\u985E\u578B\u3002\u60A8\u4E5F\u53EF\u4EE5\u8FD4\u56DE\u570B\u5BB6/\u5730\u5340\u9078\u64C7\u9801\u9762\u3002";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_title4 = "\u4F60\u4E0D\u80FD\u9810\u8A02\u9019\u500B\u6E2C\u8A66";
var APPB2C_common_findTest_residencyConfirmation_msg3 = "\u5982\u679C\u60A8\u6709\u8208\u8DA3\u53C3\u52A0 <0>\u96C5\u601D\u8003\u8A66\u7D19\u7B46</0> \u8003\u8A66, \u6211\u5011\u5FC5\u9808\u78BA\u8A8D\u60A8\u662F\u53C3\u52A0\u8003\u8A66\u7684\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11\u3002\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9078\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11, \u8ACB<0>\u9078\u64C7\u8A08\u7B97\u6A5F\u6E2C\u8A66\u6216\u9078\u64C7\u60A8\u5C45\u4F4F\u570B\u5BB6/\u5730\u5340\u7684\u7D19\u7B46\u6E2C\u8A66</0>\u5982\u679C\u60A8\u9078\u64C7\u4E0D\u6B63\u78BA, \u60A8\u7684\u6E2C\u8A66\u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_line14 = "\u5982\u679C\u60A8\u6709\u8208\u8DA3\u53C3\u52A0 <0>IELTS on Paper</0> \u8003\u8A66, \u6211\u5011\u5FC5\u9808\u78BA\u8A8D\u60A8\u662F\u53C3\u52A0\u8003\u8A66\u6240\u5728\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_line24 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9078\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11, \u8ACB<0>\u9078\u64C7\u8A08\u7B97\u6A5F\u6E2C\u8A66\u6216\u9078\u64C7\u4F4D\u65BC\u60A8\u5C45\u4F4F\u570B\u5BB6/\u5730\u5340\u7684\u7D19\u8CEA\u6E2C\u8A66</0>\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_line34 = "\u5982\u679C\u60A8\u9078\u64C7\u932F\u8AA4, \u60A8\u7684\u8003\u8A66\u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine24 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9078\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11, \u8ACB<0>\u9078\u64C7\u4F4D\u65BC\u60A8\u5C45\u4F4F\u570B\u5BB6/\u5730\u5340\u7684\u6E2C\u8A66</0>\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine24 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9078\u570B\u5BB6/\u5730\u5340\u7684\u5C45\u6C11, \u8ACB<0>\u9078\u64C7\u8A08\u7B97\u6A5F\u6E2C\u8A66</0>(\u5728\u5176\u4ED6\u57CE\u5E02\u53EF\u7528)<0>\u6216\u9078\u64C7\u60A8\u5C45\u4F4F\u570B\u5BB6/\u5730\u5340\u7684\u7D19\u8CEA\u6E2C\u8A66</0>\u3002";
var APPB2C_common_findTest_residencyConfirmation_selectDates4 = "\u8ACB\u5148\u9078\u64C7\u65E5\u671F";
var APPB2C_common_findTest_residencyConfirmation_title4 = "\u91CD\u8981\uFF01";
var APPB2C_common_findTest_searchIn4 = "\u641C\u5C0B\u4F4D\u7F6E: ";
var APPB2C_common_findTest_selectCountry_continue4 = "\u7E7C\u7E8C\u5831\u540D";
var APPB2C_common_findTest_selectCountry_countryNotSupported4 = "\u8981\u5728\u6B64\u570B\u5BB6/\u5730\u5340\u9810\u8A02\u8003\u8A66, \u60A8\u9700\u8981\u4F7F\u7528\u5176\u4ED6\u7DB2\u7AD9\u3002";
var APPB2C_common_findTest_selectCountry_label4 = "\u9078\u64C7\u4E00\u500B\u570B\u5BB6/\u5730\u5340";
var APPB2C_common_findTest_selectCountry_placeholder4 = "\u4F9D\u570B\u5BB6/\u5730\u5340\u540D\u7A31\u641C\u5C0B";
var APPB2C_common_findTest_selectLocation_closestToMe4 = "\u96E2\u6211\u6700\u8FD1";
var APPB2C_common_findTest_selectLocation_geolocationBlocked4 = "\u6211\u5011\u7121\u6CD5\u5F97\u5230\u60A8\u7684\u4F4D\u7F6E, \u8ACB\u78BA\u4FDD\u6B64\u7DB2\u7AD9\u7684\u4F4D\u7F6E\u8A31\u53EF\u6B0A\u672A\u88AB\u5C01\u9396\u3002";
var APPB2C_common_findTest_selectLocation_label4 = "\u641C\u5C0B\u57CE\u5E02";
var APPB2C_common_findTest_selectLocation_noExamForThisCountry4 = "\u6211\u5011\u5728\u9019\u500B\u570B\u5BB6/\u5730\u5340\u627E\u4E0D\u5230\u4EFB\u4F55\u6E2C\u9A57\u3002\u8ACB<1>\u806F\u7E6B\u6211\u5011</1>\u770B\u770B\u662F\u5426\u80FD\u70BA\u60A8\u63D0\u4F9B\u8AAC\u660E\u3002";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation4 = "\u6211\u5011\u627E\u4E0D\u5230\u4EFB\u4F55\u95DC\u65BC\u9019\u500B\u7D44\u7E54\u7684\u6E2C\u8A66\u3002\u9078\u64C7\u4EE5\u4E0B\u9078\u9805\u4E4B\u4E00: ";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta4 = "\u641C\u7D22\u53EF\u7528\u7684\u6E2C\u8A66";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option14 = "\u9078\u64C7\u8981\u9810\u7D04\u8003\u8A66\u7684\u5730\u9EDE";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option24 = "<1>\u806F\u7E6B\u6211\u5011</1>\u6211\u5011\u5C07\u76E1\u529B\u70BA\u60A8\u63D0\u4F9B\u8AAC\u660E";
var APPB2C_common_findTest_specialReqs_findOutMore4 = "\u4E86\u89E3\u6211\u5011\u5982\u4F55\u70BA\u60A8\u63D0\u4F9B\u5354\u52A9";
var APPB2C_common_findTest_specialReqs_list_subtitle4 = "\u9019\u5C07\u5F71\u97FF\u53EF\u884C\u7684\u8003\u8A66\u65E5\u671F\u3002\u8ACB\u5728\u4E0B\u9762\u9078\u64C7\u4E00\u6216\u591A\u500B\u9078\u9805\u3002";
var APPB2C_common_findTest_specialReqs_list_title4 = "\u8003\u8A66\u7576\u5929\u9700\u8981\u54EA\u4E9B\u7279\u6B8A\u5B89\u6392\uFF1F";
var APPB2C_common_findTest_specialReqs_needCheckbox4 = "\u6211\u5C0D\u8003\u8A66\u65E5\u671F\u6709 <1>\u7279\u6B8A\u5354\u52A9</1> \u7684\u9700\u6C42";
var APPB2C_common_findTest_specialReqs_other4 = "\u60A8\u9700\u8981\u54EA\u4E9B\u7279\u6B8A\u5B89\u6392";
var APPB2C_common_findTest_specialReqs_pleaseNoteMessage4 = "\u5982\u679C\u76EE\u524D\u53EF\u5831\u540D\u7684\u6700\u65E9\u8003\u8A66\u5834\u6B21\u4ECD\u665A\u65BC\u60A8\u7684\u9700\u6C42, \u8ACB\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3\u4EE5\u4E86\u89E3\u662F\u5426\u6709\u5176\u4ED6\u53EF\u884C\u65B9\u6848\u3002\u82E5\u9700\u8981\u7279\u6B8A\u7684\u8003\u8A66\u5167\u5BB9, \u4F8B\u5982: \u653E\u5927\u8A66\u5377\u3001\u9EDE\u5B57\u6587\u7248\u3001\u8B80\u5507\u7248\u672C\u7B49\u7B49, \u8003\u8A66\u4E2D\u5FC3\u53EF\u80FD\u7121\u6CD5\u63D0\u4F9B\u66F4\u65E9\u7684\u8003\u8A66\u65E5\u671F\u3002";
var APPB2C_common_findTest_subtitle4 = "\u60A8\u60F3\u5728\u54EA\u88E1\u53C3\u52A0\u8003\u8A66\uFF1F";
var APPB2C_common_findTest_title4 = "\u9078\u64C7\u6E2C\u9A57";
var APPB2C_common_findTest_uol_organisationCheck_check4 = "\u6211\u78BA\u8A8D\u5DF2\u4E86\u89E3\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u8003\u8A66\u50C5\u9069\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u548C\u79FB\u6C11\u5C40\u3002";
var APPB2C_common_findTest_uol_organisationCheck_desc4 = "\u8981\u53C3\u52A0\u5176\u4ED6\u6A5F\u69CB\u8A8D\u53EF\u7684\u96C5\u601D\u8003\u8A66, \u8ACB<0>\u67E5\u770B\u6211\u5011\u7684\u5176\u4ED6\u96C5\u601D\u8003\u8A66</0>\u3002";
var APPB2C_common_findTest_uol_organisationCheck_title4 = "\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u8003\u8A66\u50C5\u9069\u7528\u65BC\u82F1\u570B\u7C3D\u8B49\u548C\u79FB\u6C11\u5C40";
var APPB2C_common_findTest_uol_softwareCheck_check4 = "\u6211\u78BA\u8A8D\u5DF2\u6AA2\u67E5\u8A2D\u5099\u548C\u74B0\u5883\u8981\u6C42\u4EE5\u53C3\u52A0\u6E2C\u9A57";
var APPB2C_common_findTest_uol_softwareCheck_desc14 = "\u60A8\u7684\u7C3D\u8B49\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u4F7F\u7528 Inspera \u8EDF\u9AD4, \u9700\u8981\u5728 Windows PC \u6216 MacOS \u4E0A\u4E0B\u8F09(\u60A8\u4E0D\u80FD\u5728\u624B\u6A5F\u6216\u5E73\u677F\u96FB\u8166\u4E0A\u53C3\u52A0\u8003\u8A66\uFF01)\u3002";
var APPB2C_common_findTest_uol_softwareCheck_desc24 = "\u5411\u6211\u5011\u5831\u540D\u8003\u8A66\u4E4B\u524D, \u60A8\u5FC5\u9808\u6AA2\u67E5\u96FB\u8166\u8A2D\u5099\u662F\u5426\u7B26\u5408\u5EFA\u8B70\u7684\u7CFB\u7D71\u8981\u6C42\u3002";
var APPB2C_common_findTest_uol_softwareCheck_link4 = "\u6AA2\u67E5\u6E2C\u9A57\u7684\u8A2D\u5099\u8981\u6C42";
var APPB2C_common_findTest_uol_softwareCheck_title4 = "\u60A8\u7684\u96FB\u8166\u53EF\u4EE5\u904B\u884C\u8003\u8A66\u6240\u9700\u7684\u8EDF\u9AD4\u55CE\uFF1F";
var APPB2C_common_footer_terms_accessibility4 = "\u53EF\u89F8\u53CA\u6027";
var APPB2C_common_footer_terms_copyrightLine14 = "\u8CA0\u8CAC\u6587\u5316\u95DC\u4FC2\u548C\u6559\u80B2\u6A5F\u6703\u7684\u806F\u5408\u738B\u570B\u570B\u969B\u7D44\u7E54\u3002";
var APPB2C_common_footer_terms_copyrightLine24 = "\u8A3B\u518A\u6148\u5584\u6A5F\u69CB: 209131(\u82F1\u683C\u862D\u548C\u5A01\u723E\u58EB)SC037733(\u8607\u683C\u862D)\u3002";
var APPB2C_common_footer_terms_dataProtection4 = "\u6578\u64DA\u4FDD\u8B77";
var APPB2C_common_footer_terms_privacyAndCookies4 = "\u96B1\u79C1\u548C\u5FEB\u53D6";
var APPB2C_common_footer_terms_termsOfUse4 = "\u4F7F\u7528\u689D\u6B3E";
var APPB2C_common_gelRedirect_errorMessage4 = "\u6B64\u8CC7\u6E90\u76EE\u524D\u7121\u6CD5\u4F7F\u7528\u3002";
var APPB2C_common_gelRedirect_goBack3 = "\u67E5\u770B\u5099\u8003\u8CC7\u6E90";
var APPB2C_common_gelRedirect_header4 = "\u6E96\u5099\u8003\u8A66";
var APPB2C_common_gelRedirect_info4 = "\u6211\u5011\u80FD\u900F\u904E\u4E00\u7CFB\u5217\u7684\u514D\u8CBB\u8CC7\u6E90\u5E6B\u52A9\u60A8\u6E96\u5099\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_common_general_next4 = "\u4E0B\u4E00\u500B";
var APPB2C_common_general_previous4 = "\u4EE5\u524D";
var APPB2C_common_general_regNotFound4 = "\u672A\u627E\u5230\u8A72\u5831\u540D";
var APPB2C_common_general_remove4 = "\u522A\u9664";
var APPB2C_common_general_steps4 = "\u6B65\u9A5F{{step}} \u7684{{steps}}";
var APPB2C_common_general_stepsContinued4 = "\u6B65\u9A5F {{step}} \u7684 {{steps}} \u7E7C\u7E8C...";
var APPB2C_common_helpAndContact_actionSection_contactFormLabel4 = "\u6211\u73FE\u5728\u4E0D\u65B9\u4FBF\u8AC7\u8A71, \u4F46\u6211\u9700\u8981\u6709\u4EBA\u7A0D\u5019\u8207\u6211\u806F\u7E6B: ";
var APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre4 = "\u60A8\u9700\u8981\u66F4\u591A\u8AAC\u660E\u55CE\uFF1F\u9EDE\u64CA\u4E0B\u9762\u7684\u6309\u9215\u586B\u5BEB\u806F\u7E6B\u8868\u683C\u3002";
var APPB2C_common_helpAndContact_actionSection_contactFormLink4 = "\u586B\u5BEB\u806F\u7E6B\u8868\u683C";
var APPB2C_common_helpAndContact_actionSection_faqLabel4 = "\u6211\u9700\u8981\u9019\u500B\u5FEB\u901F\u554F\u7B54: ";
var APPB2C_common_helpAndContact_actionSection_faqLink4 = "\u67E5\u770B\u6211\u5011\u7684\u5E38\u898B\u554F\u984C";
var APPB2C_common_helpAndContact_actionSection_icHeader4 = "\u5176\u4ED6\u5354\u52A9\u65B9\u6CD5...";
var APPB2C_common_helpAndContact_actionSection_iolHeader4 = "\u60A8\u9700\u8981\u4EC0\u9EBC\u6A23\u7684\u5354\u52A9\uFF1F";
var APPB2C_common_helpAndContact_actionSection_launchChatBtn4 = "\u555F\u52D5\u5373\u6642\u901A\u8A0A";
var APPB2C_common_helpAndContact_actionSection_launchChatLabel4 = "\u6211\u9700\u8981\u7DDA\u4E0A\u8A0E\u8AD6\u6211\u7684\u8003\u8A66: ";
var APPB2C_common_helpAndContact_form_back4 = "\u8FD4\u56DE\u5E6B\u52A9\u548C\u806F\u7E6B";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLink4 = "\u6709\u95DC\u5411\u82F1\u570B\u6587\u5316\u5354\u6703\u6295\u8A34\u7684\u66F4\u591A\u8CC7\u8A0A, \u8ACB\u53C3\u95B1<0>\u6295\u8A34</0>";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle4 = "\u77AD\u89E3\u66F4\u591A";
var APPB2C_common_helpAndContact_form_complaint_radio_complaint4 = "\u62B1\u6028";
var APPB2C_common_helpAndContact_form_complaint_radio_other4 = "\u5E38\u898F\u8ACB\u6C42, \u4F8B\u5982\u3002\u4E00\u500B\u554F\u984C";
var APPB2C_common_helpAndContact_form_complaint_title4 = "\u8ACB\u9078\u64C7\u8981\u6C42\u985E\u578B: ";
var APPB2C_common_helpAndContact_form_label4 = "\u8ACB\u7C21\u8981\u6982\u8FF0\u60A8\u7684\u7591\u554F";
var APPB2C_common_helpAndContact_form_legal4 = "\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u5C07\u4F7F\u7528\u60A8\u6240\u63D0\u4F9B\u7684\u8CC7\u8A0A\u4F86\u8655\u7406\u60A8\u7684\u7533\u8ACB\u3002\u8ACB\u53C3\u95B1\u6211\u5011\u7DB2\u7AD9<0>\u96B1\u79C1\u689D\u6B3E<0/>\u4EE5\u4E86\u89E3\u8A73\u60C5\u3002";
var APPB2C_common_helpAndContact_form_response4 = "\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u56DE\u8986\u6240\u6709\u8A62\u554F\u3002";
var APPB2C_common_helpAndContact_form_submit4 = "\u63D0\u4EA4\u806F\u7E6B\u8868\u683C";
var APPB2C_common_helpAndContact_form_thanks_back4 = "\u56DE\u5230\u6211\u7684\u5831\u8003\u660E\u7D30";
var APPB2C_common_helpAndContact_form_thanks_banner4 = "\u6211\u5011\u5DF2\u6536\u5230\u8981\u6C42\u8207\u60A8\u806F\u7E6B\u7684\u8A0A\u606F\u3002";
var APPB2C_common_helpAndContact_form_thanks_info4 = "\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u56DE\u8986\u6240\u6709\u806F\u7E6B\u3002";
var APPB2C_common_helpAndContact_form_thanks_title4 = "\u611F\u8B1D\u60A8\u8207\u6211\u5011\u806F\u7E6B";
var APPB2C_common_helpAndContact_form_title4 = "\u6211\u5011\u8A72\u5982\u4F55\u5354\u52A9\u60A8\u7684\u8003\u8A66\uFF1F";
var APPB2C_common_helpAndContact_form_validation4 = "\u6B64\u6B04\u4F4D\u70BA\u5FC5\u586B\u6B04\u4F4D\u3002";
var APPB2C_common_helpAndContact_icContent_commonQuestionsHeader4 = "\u5E38\u898B\u554F\u984C";
var APPB2C_common_helpAndContact_icContent_emailLabel4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_common_helpAndContact_icContent_header4 = "\u82E5\u60A8\u5C0D\u96C5\u601D\u8003\u8A66\u6709\u4EFB\u4F55\u7591\u554F, \u8ACB\u8207\u6211\u5011\u806F\u7E6B\u3002";
var APPB2C_common_helpAndContact_icContent_phoneLabel4 = "\u96FB\u8A71";
var APPB2C_common_helpAndContact_icContent_referenceReminder4 = "\u70BA\u63D0\u4F9B\u66F4\u5FEB\u901F\u7684\u5354\u52A9, \u656C\u8ACB\u63D0\u4F9B\u60A8\u7684IELTS \u53C3\u8003\u7DE8\u865F: ";
var APPB2C_common_helpAndContact_ieltsReference4 = "\u96C5\u601D\u53C3\u8003\u7DE8\u865F: ";
var APPB2C_common_helpAndContact_noTests_bookNewBtn4 = "\u5831\u540D\u65B0\u6E2C\u9A57";
var APPB2C_common_helpAndContact_noTests_contactLink4 = "\u806F\u7E6B\u65B9\u5F0F\u53EF\u65BC\u6211\u5011\u7684<0>\u96C5\u601D\u8003\u8A66\u7DB2\u7AD9</0>\u4E0A\u627E\u5230\u3002";
var APPB2C_common_helpAndContact_noTests_header4 = "\u60A8\u6C92\u6709\u4EFB\u4F55\u95DC\u65BC\u8003\u8A66\u5DF2\u8A62\u554F\u7684\u5354\u52A9\u3002";
var APPB2C_common_helpAndContact_selectExamLabel4 = "\u986F\u793A\u6B64\u8003\u8A66\u7684\u5354\u52A9: ";
var APPB2C_common_helpAndContact_title4 = "\u5E6B\u52A9\u548C\u806F\u7E6B";
var APPB2C_common_invitation_modal_header4 = "\u7121\u6548\u9023\u7D50";
var APPB2C_common_invitation_modal_invalid4 = "\u60A8\u5617\u8A66\u4F7F\u7528\u7684\u7DB2\u5740\u4E0D\u6B63\u78BA\u6216\u4E0D\u518D\u6709\u6548\u3002\u60A8\u53EF\u4EE5\u5728\u6C92\u6709\u4EE3\u7406\u4E0A\u4E0B\u6587\u7684\u60C5\u6CC1\u4E0B\u7E7C\u7E8C\u8A3B\u518A\u3002";
var APPB2C_common_modals_newTermsAndConditions_checkboxNote4 = "\u6211\u540C\u610F {{context}}";
var APPB2C_common_modals_newTermsAndConditions_checkboxTC4 = "\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_modals_newTermsAndConditions_cta4 = "\u9032\u884C";
var APPB2C_common_modals_newTermsAndConditions_error4 = "\u54CE\u5440, \u51FA\u932F\u4E86\u3002\u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_common_modals_newTermsAndConditions_note4 = "\u8ACB\u63A5\u53D7\u65B0\u7684\u689D\u6B3E\u548C\u689D\u4EF6\u4EE5\u7E7C\u7E8C";
var APPB2C_common_modals_newTermsAndConditions_title4 = "\u65B0\u7684\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_modals_newTermsAndConditions_trigger4 = "\u63A5\u53D7\u65B0\u7684\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_nav_bookNewTest4 = "\u5831\u540D\u8003\u8A66";
var APPB2C_common_nav_chooseLanguage4 = "\u9078\u64C7\u4F7F\u7528\u7684\u8A9E\u8A00";
var APPB2C_common_nav_hello4 = "\u60A8\u597D";
var APPB2C_common_nav_helpAndContact4 = "\u5E6B\u52A9\u548C\u806F\u7E6B";
var APPB2C_common_nav_login4 = "\u767B\u5165";
var APPB2C_common_nav_logout4 = "\u767B\u51FA";
var APPB2C_common_nav_logoutModal_cancel3 = "\u53D6\u6D88";
var APPB2C_common_nav_logoutModal_confirm4 = "OK";
var APPB2C_common_nav_logoutModal_message4 = "\u767B\u51FA\u5C07\u6703\u96E2\u958B\u5831\u540D\u7A0B\u5E8F\u3002";
var APPB2C_common_nav_logoutModal_question4 = "\u60A8\u78BA\u5B9A\u8981\u7E7C\u7E8C\u55CE\uFF1F";
var APPB2C_common_nav_logoutModal_title4 = "\u767B\u51FA";
var APPB2C_common_nav_myAccount4 = "\u6211\u7684\u5E33\u865F";
var APPB2C_common_nav_preparation4 = "\u6E96\u5099";
var APPB2C_common_nav_resultsTests4 = "\u8003\u8A66\u53CA\u6210\u7E3E";
var APPB2C_common_nav_ttp4 = "\u8003\u751F\u7CFB\u7D71";
var APPB2C_common_preparation_noTests_contactLink4 = "\u514D\u8CBB\u7684\u5099\u8003\u8CC7\u6E90\u53EF\u65BC\u6211\u5011\u7684<0>\u53C3\u52A0\u96C5\u601D\u8003\u8A66\u7DB2\u7AD9</0>\u4E0A\u7372\u5F97\u3002";
var APPB2C_common_preparation_noTests_header4 = "\u60A8\u6C92\u6709\u4EFB\u4F55\u5DF2\u5831\u540D\u7684\u8003\u8A66\u9700\u6E96\u5099\u3002";
var APPB2C_common_preparation_selectExamLabel4 = "\u986F\u793A\u6B64\u8003\u8A66\u7684\u5354\u52A9: ";
var APPB2C_common_preparation_title4 = "\u6E96\u5099";
var APPB2C_common_preparation_viewTestBtn4 = "\u67E5\u770B\u6B64\u6E2C\u9A57";
var APPB2C_common_recreateReg_errMsg4 = "\u6211\u5011\u7121\u6CD5\u91CD\u65B0\u5275\u5EFA\u6B64\u8A3B\u518A, \u4F46\u8ACB\u4E0D\u8981\u64D4\u5FC3\u3002\u6211\u5011\u6703\u5C07\u60A8\u91CD\u5B9A\u5411\u5230\u6A19\u6E96\u8A3B\u518A\u3002";
var APPB2C_common_recreateReg_errTitle4 = "\u91CD\u65B0\u5EFA\u7ACB\u5931\u6557";
var APPB2C_common_recreateReg_progress4 = "\u91CD\u65B0\u5EFA\u7ACB\u60A8\u7684\u5831\u540D";
var APPB2C_common_regHeader_reference4 = "\u53C3\u8003";
var APPB2C_common_regHeader_trfLoading4 = "\u7B49\u4E00\u4E0B\u3002\u3002\u3002";
var APPB2C_common_regHeader_trfNumber4 = "\u6210\u7E3E\u55AE\u7DE8\u865F";
var APPB2C_common_regInProgressModal_continue4 = "\u6C92\u95DC\u4FC2";
var APPB2C_common_regInProgressModal_message14 = "\u60A8\u5DF2\u6709<bold>{{date}}</bold> \u8003\u8A66\u5831\u540D\u9032\u884C\u4E2D, \u60A8\u771F\u7684\u60F3\u5F9E\u6240\u9078\u6E2C\u9A57\u91CD\u65B0\u958B\u59CB\u55CE\uFF1F";
var APPB2C_common_regInProgressModal_message24 = "\u5728\u9019\u500B\u60C5\u6CC1\u4E0B, \u60A8\u76EE\u524D\u9810\u5B9A\u7684\u6642\u6BB5\u5C07\u88AB\u91CB\u51FA\u3002";
var APPB2C_common_regInProgressModal_restart4 = "\u91CD\u65B0\u958B\u59CB";
var APPB2C_common_regInProgressModal_title4 = "\u6CE8\u610F\uFF01";
var APPB2C_common_selfServices_requested_cancellation4 = "\u5DF2\u8ACB\u6C42\u53D6\u6D88";
var APPB2C_common_selfServices_requested_transfer4 = "\u5DF2\u8ACB\u6C42\u8F49\u79FB";
var APPB2C_common_specialReqs_AMANU4 = "\u66F8\u8A18\u54E1";
var APPB2C_common_specialReqs_ANTHR4 = "\u53E6\u4E00\u500B";
var APPB2C_common_specialReqs_BTP4 = "\u9EDE\u5B57\u8003\u5377";
var APPB2C_common_specialReqs_BWP4 = "\u76F2\u4EBA\u6253\u5B57\u6A5F";
var APPB2C_common_specialReqs_EPTP4 = "\u653E\u5927\u5B57\u9AD4\u8A66\u5377";
var APPB2C_common_specialReqs_ET4 = "\u6642\u9593\u52A0\u9577";
var APPB2C_common_specialReqs_LRTV4 = "\u8B80\u5507\u6E2C\u8A66";
var APPB2C_common_specialReqs_SAE4 = "\u64F4\u97F3\u8A2D\u5099";
var APPB2C_common_specialReqs_VAS4 = "\u8072\u63A7\u8EDF\u9AD4";
var APPB2C_common_specialReqs_WP4 = "\u6587\u5B57\u8655\u7406\u8EDF\u9AD4";
var APPB2C_common_startPage_book4 = "\u5831\u540D";
var APPB2C_common_startPage_candidates_nowAdult4 = "\u6211\u5011\u6CE8\u610F\u5230{{name}} \u73FE\u5728\u5DF2\u5E74\u6EFF18\u6B72, \u6839\u64DA\u898F\u7BC4, \u4ED6\u5011\u5FC5\u9808\u81EA\u5DF1\u7BA1\u7406\u96C5\u601D\u5E33\u865F\u3002";
var APPB2C_common_startPage_candidates_showingFor4 = "\u986F\u793A\u6E2C\u9A57\u53CA\u6210\u7E3E: ";
var APPB2C_common_startPage_common_cancelled4 = "\u53D6\u6D88";
var APPB2C_common_startPage_description_ac4 = "\u82E5\u60A8\u6B32\u5C31\u8B80\u5927\u5B78\u6216\u5C08\u79D1\u3001\u7814\u7A76\u6240, \u6216\u52A0\u5165\u5168\u4E16\u754C\u7684\u5C08\u696D\u7D44\u7E54, \u8ACB\u9078\u64C7\u6B64\u6E2C\u9A57\u3002";
var APPB2C_common_startPage_description_gt4 = "\u82E5\u60A8\u70BA\u5DE5\u4F5C\u6216\u57F9\u8A13\u7533\u8ACB\u53CA\u975E\u5B78\u4F4D\u985E\u8AB2\u7A0B, \u8ACB\u9078\u64C7\u6B64\u6E2C\u9A57\u3002";
var APPB2C_common_startPage_description_ukvi4 = "\u82E5\u60A8\u6E96\u5099\u8FA6\u7406\u8D74\u82F1\u5C45\u7559\u6216\u79FB\u6C11\u7C3D\u8B49\u3001\u5C31\u8B80\u975E\u5B78\u4F4D\u985E\u8AB2\u7A0B, \u6216\u975E\u70BA\u82F1\u570B\u5167\u653F\u90E8\u79FB\u6C11\u7F72\u8A8D\u8B49\u6A5F\u69CB, \u82F1\u570B\u653F\u5E9C\u5BA3\u4F48\u4E86\u7C3D\u8B49\u985E\u5B89\u5168\u82F1\u8A9E\u8A9E\u8A00\u8003\u8A66(SELT)\u7684\u91CD\u8981\u8B8A\u52D5\u3002";
var APPB2C_common_startPage_endNote4 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u662F\u96C5\u601D\u8003\u8A66\u7684\u91CD\u8981\u5925\u4F34\u3002";
var APPB2C_common_startPage_error_body4 = "\u6211\u5011\u65BC\u6B64\u9801\u9762\u986F\u793A\u66F4\u591A\u8003\u8A66\u6642\u767C\u751F\u7570\u5E38, \u656C\u8ACB\u66AB\u5019\u6578\u5206\u9418\u5F8C\u91CD\u65B0\u6574\u7406\u3002";
var APPB2C_common_startPage_error_cta4 = "\u91CD\u65B0\u6574\u7406";
var APPB2C_common_startPage_error_title4 = "\u7121\u6CD5\u8F09\u5165\u6240\u6709\u8003\u8A66\u3001\u6E2C\u9A57";
var APPB2C_common_startPage_hero_cta4 = "\u5831\u540D\u65B0\u8003\u8A66";
var APPB2C_common_startPage_hero_subtitle4 = "\u60A8\u7684\u8003\u751F\u7CFB\u7D71\u5167\u5C07\u6709\u5099\u8003\u8CC7\u6E90\u3001\u5831\u8003\u7BA1\u7406\u53CA\u660E\u7D30\u3001\u6210\u7E3E\u670D\u52D9\u7B49, \u4E26\u53EF\u4F7F\u7528\u6210\u7E3E\u670D\u52D9\u7E7C\u7E8C\u60A8\u7684\u82F1\u8A9E\u5B78\u7FD2\u65C5\u7A0B\u3002";
var APPB2C_common_startPage_hero_title4 = "\u8003\u8A66\u53CA\u6210\u7E3E";
var APPB2C_common_startPage_ielts4 = "\u96C5\u601D";
var APPB2C_common_startPage_ieltsUkvi4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u8003\u8A66";
var APPB2C_common_startPage_missing_cancel4 = "\u6211\u4E0D\u60F3\u767B\u5165";
var APPB2C_common_startPage_missing_marketing_cta4 = "\u5132\u5B58";
var APPB2C_common_startPage_missing_marketing_notes_full14 = "\u60A8\u53EF\u4EE5\u96A8\u6642\u65BC\u8003\u751F\u7CFB\u7D71\u5167, \u300C\u6211\u7684\u5E33\u865F\u300D\u53D6\u6D88\u8A02\u95B1\u6211\u5011\u7684\u96FB\u5B50\u90F5\u4EF6\u3002";
var APPB2C_common_startPage_missing_marketing_notes_full24 = "\u6216\u60A8\u53EF\u4EE5";
var APPB2C_common_startPage_missing_marketing_notes_full34 = "\u6211\u5011\u5C07\u6839\u64DA\u60A8\u7684\u540C\u610F\u8655\u7406\u60A8\u7684\u500B\u4EBA\u8CC7\u8A0A\u3002";
var APPB2C_common_startPage_missing_marketing_notes_link4 = "\u806F\u7E6B\u6211\u5011";
var APPB2C_common_startPage_missing_marketing_notes_main4 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u5E0C\u671B\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A, \u901A\u77E5\u60A8\u53EF\u80FD\u611F\u8208\u8DA3\u7684\u6D3B\u52D5\u3001\u670D\u52D9\u53CA\u63A8\u5EE3\u6D88\u606F\u3002\u60A8\u53EF\u4EE5\u96A8\u6642\u65BC\u8003\u751F\u7CFB\u7D71\u5167, \u6211\u7684\u5E33\u865F\u4E2D\u53D6\u6D88\u8A02\u95B1\u6211\u5011\u7684\u96FB\u5B50\u90F5\u4EF6\u3002";
var APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties4 = "\u6211\u5F88\u9AD8\u8208\u6536\u5230\u6709\u95DC\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u548C\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u9078\u64C7\u7684\u7B2C\u4E09\u65B9\u7D44\u7E54\u7684\u7522\u54C1\u3001\u670D\u52D9\u548C\u6D3B\u52D5\u7684\u8CC7\u8A0A\u3002";
var APPB2C_common_startPage_missing_marketing_opts_BcOnly4 = "\u6211\u9858\u610F\u6536\u5230\u6709\u95DC\u82F1\u570B\u6587\u5316\u5354\u6703(\u5305\u62EC\u5176\u76F8\u95DC\u6A5F\u69CB)\u6240\u63D0\u4F9B\u7684\u7522\u54C1\u3001\u670D\u52D9\u548C\u6D3B\u52D5\u7684\u6700\u65B0\u8CC7\u8A0A\u3002";
var APPB2C_common_startPage_missing_marketing_opts_DoNotSend4 = "\u8ACB\u4E0D\u8981\u5411\u6211\u767C\u9001\u4EFB\u4F55\u884C\u92B7\u8A0A\u606F\u3002";
var APPB2C_common_startPage_missing_marketing_title4 = "\u60A8\u7684\u884C\u92B7\u559C\u597D";
var APPB2C_common_startPage_missing_ttData_cta4 = "\u78BA\u8A8D\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_startPage_missing_ttData_dob4 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_common_startPage_missing_ttData_firstName4 = "\u540D\u7A31/\u540D\u5B57(\u5305\u62EC\u4E2D\u9593\u540D)";
var APPB2C_common_startPage_missing_ttData_lastName4 = "\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_common_startPage_missing_ttData_line14 = "\u6211\u5011\u53EF\u4EE5\u770B\u5230\u60A8\u4E4B\u524D\u5DF2\u8A3B\u518A\u3001\u5831\u540D\u904E\u5176\u4ED6\u7522\u54C1\u6216\u670D\u52D9\u3002";
var APPB2C_common_startPage_missing_ttData_line24 = "\u70BA\u4E86\u767B\u5165\u60A8\u7684\u8CC7\u8A0A, \u8ACB\u518D\u6B21\u78BA\u8A8D\u60A8\u7684\u59D3\u540D\u548C\u51FA\u751F\u65E5\u671F\u3002";
var APPB2C_common_startPage_missing_ttData_line34 = "\u8B1D\u8B1D\uFF01";
var APPB2C_common_startPage_missing_ttData_noLastName4 = "\u6211\u6C92\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_common_startPage_missing_ttData_title4 = "\u6211\u5011\u9700\u8981\u78BA\u8A8D\u4E00\u4E9B\u8CC7\u8A0A\uFF01";
var APPB2C_common_startPage_options_ac4 = "\u96C5\u601D\u5B78\u8853\u7D44\u8003\u8A66";
var APPB2C_common_startPage_options_gt4 = "\u96C5\u601D\u4E00\u822C\u8A13\u7DF4\u7D44\u8003\u8A66";
var APPB2C_common_startPage_options_ukvi4 = "\u82F1\u570B\u7C3D\u8B49\u96C5\u601D\u8003\u8A66\u6216\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57";
var APPB2C_common_startPage_past_cd4 = "\u96C5\u601D\u96FB\u8166\u6E2C\u9A57";
var APPB2C_common_startPage_past_empty4 = "\u60A8\u6C92\u6709\u4EFB\u4F55\u6E2C\u9A57";
var APPB2C_common_startPage_past_osr_newScore4 = "\u60A8\u7684\u65B0\u5206\u6578";
var APPB2C_common_startPage_past_osr_note_awaiting4 = "\u60A8\u6B63\u5728\u7B49\u5F85 {{skill}} \u91CD\u8003\u7684\u7D50\u679C\u4EE5\u8A66\u8457\u63D0\u9AD8\u60A8\u7684\u7E3D\u6210\u7E3E\u3002";
var APPB2C_common_startPage_past_osr_note_confirmed4 = "\u60A8\u5DF2\u7D93\u5831\u540D {{skill}} \u91CD\u8003, \u4EE5\u5617\u8A66\u63D0\u9AD8\u60A8\u7684\u7E3D\u6210\u7E3E\u3002";
var APPB2C_common_startPage_past_osr_note_hasResults4 = "\u60A8\u5831\u540D\u4E86 {{skill}} \u91CD\u8003, \u4EE5\u5617\u8A66\u63D0\u9AD8\u7E3D\u6210\u7E3E\u3002\u60A8\u7684\u65B0\u5206\u6578\u5DF2\u53EF\u67E5\u8A62\u3002";
var APPB2C_common_startPage_past_osr_originalScore4 = "\u60A8\u7684\u539F\u59CB\u5206\u6578";
var APPB2C_common_startPage_past_osr_viewRetakeLink4 = "\u67E5\u770B\u5DF2\u5831\u540D\u7684\u91CD\u8003";
var APPB2C_common_startPage_past_pb4 = "\u96C5\u601D\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_common_startPage_past_rebook4 = "\u4EE5\u5176\u4ED6\u65E5\u671F\u91CD\u65B0\u5831\u540D\u6B64\u6E2C\u9A57";
var APPB2C_common_startPage_past_score4 = "\u4F60\u5F97\u5230";
var APPB2C_common_startPage_past_scoreLifeSkills4 = "\u60A8\u7684\u5206\u6578";
var APPB2C_common_startPage_past_title4 = "\u60A8\u4E4B\u524D\u7684\u8003\u8A66\u53CA\u6210\u7E3E";
var APPB2C_common_startPage_past_upcomingResults4 = "\u60A8\u7684\u6210\u7E3E\u5C07\u5728 {{date}} \u4E4B\u524D\u63D0\u4F9B";
var APPB2C_common_startPage_readTnCs4 = "\u5728\u7E7C\u7E8C\u4F5C\u696D\u524D, \u8ACB\u8A73\u7D30\u95B1\u8B80\u96C5\u601D\u8003\u8A66";
var APPB2C_common_startPage_recent_cta4 = "\u67E5\u770B\u5B8C\u6574\u7D50\u679C";
var APPB2C_common_startPage_recent_title4 = "\u60A8\u6700\u8FD1\u7684\u6210\u7E3E";
var APPB2C_common_startPage_subtitle4 = "\u60A8\u60F3\u53C3\u52A0\u54EA\u7A2E\u96C5\u601D\u8003\u8A66\uFF1F";
var APPB2C_common_startPage_title4 = "\u9078\u64C7\u60A8\u6B32\u53C3\u8207\u7684\u96C5\u601D\u8003\u8A66";
var APPB2C_common_startPage_tncs4 = "\u670D\u52D9\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_startPage_upcoming_cd4 = "\u96FB\u8166\u6E2C\u9A57";
var APPB2C_common_startPage_upcoming_empty4 = "\u60A8\u6C92\u6709\u4EFB\u4F55\u5373\u5C07\u5230\u4F86\u7684\u8003\u8A66";
var APPB2C_common_startPage_upcoming_lifeSkills4 = "\u807D\u529B\u548C\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_startPage_upcoming_live4 = "\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_startPage_upcoming_manage4 = "\u67E5\u770B\u6B64\u5831\u540D";
var APPB2C_common_startPage_upcoming_missing_childConsent4 = "\u66F4\u65B0\u5B69\u7AE5\u540C\u610F\u8CC7\u8A0A";
var APPB2C_common_startPage_upcoming_missing_id4 = "\u4E0A\u50B3\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6";
var APPB2C_common_startPage_upcoming_missing_medical4 = "\u4E0A\u50B3\u91AB\u7642\u8B49\u660E";
var APPB2C_common_startPage_upcoming_missing_payment4 = "\u4E0A\u50B3\u4ED8\u6B3E\u8B49\u660E";
var APPB2C_common_startPage_upcoming_noSpeaking4 = "\u5C1A\u672A\u6709\u95DC\u65BC\u53E3\u8AAA\u7684\u8CC7\u8A0A";
var APPB2C_common_startPage_upcoming_noTime4 = "\u6211\u5011\u5C07\u5728\u8003\u8A66\u65E548\u5C0F\u6642\u5167\u63D0\u4F9B\u60A8\u78BA\u5207\u7684\u8003\u8A66\u6642\u9593\u3002";
var APPB2C_common_startPage_upcoming_osr_note4 = "\u60A8\u7684 {{skill}} \u91CD\u8003\u6210\u7E3E\u5C07\u8A08\u5165\u60A8\u7684\u65B0\u7E3D\u6210\u7E3E\u3002";
var APPB2C_common_startPage_upcoming_pastPaymentDeadline4 = "\u4ED8\u6B3E\u5DF2\u904E\u671F";
var APPB2C_common_startPage_upcoming_pay4 = "\u652F\u4ED8\u8003\u8A66\u8CBB\u7528";
var APPB2C_common_startPage_upcoming_pb4 = "\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_common_startPage_upcoming_prepare4 = "\u6E96\u5099\u6B64\u6E2C\u9A57";
var APPB2C_common_startPage_upcoming_remote4 = "\u9060\u7AEF\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_common_startPage_upcoming_title4 = "\u5373\u5C07\u5230\u4F86\u7684\u8003\u8A66";
var APPB2C_common_startPage_whichIELTS4 = "\u6211\u9069\u5408\u54EA\u7A2E\u96C5\u601D\u8003\u8A66\uFF1F";
var APPB2C_common_terms_downloadInfo4 = "\u60A8\u7684\u689D\u6B3E\u548C\u689D\u4EF6PDF\u6A94\u6848\u4E0B\u8F09\u5373\u5C07\u958B\u59CB\u3002";
var APPB2C_common_terms_downloadTitle4 = "\u689D\u6B3E\u8207\u689D\u4EF6 PDF \u6A94\u6848\u4E0B\u8F09";
var APPB2C_common_terms_error4 = "\u54CE\u5440, \u51FA\u932F\u4E86\u3002\u8ACB\u7A0D\u5F8C\u91CD\u8A66\u3002";
var APPB2C_common_terms_title4 = "\u689D\u6B3E\u548C\u689D\u4EF6";
var APPB2C_common_testDetails_changeTest4 = "\u66F4\u6539\u6E2C\u9A57";
var APPB2C_common_testDetails_changeWrittenTest4 = "\u66F4\u6539\u7B46\u8A66";
var APPB2C_common_testDetails_completeYourBooking4 = "\u5B8C\u6210\u60A8\u7684\u9810\u8A02";
var APPB2C_common_testDetails_mapModal_close4 = "\u95DC\u9589\u8996\u7A97";
var APPB2C_common_testDetails_mapModal_title4 = "\u4F4D\u7F6E\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_common_testDetails_modifyNote_confirm4 = "\u6211\u7406\u89E3\u4E26\u540C\u610F\u9019\u4E9B\u689D\u6B3E\u548C\u689D\u4EF6\u3002";
var APPB2C_common_testDetails_modifyNote_note4 = "\u6211\u5011\u76E1\u4E00\u5207\u52AA\u529B\u78BA\u4FDD\u9075\u5FAA\u60A8\u7684\u8003\u8A66\u5730\u9EDE\u3001\u65E5\u671F\u548C\u6642\u9593\u9078\u64C7, \u4F46\u6211\u5011\u6703\u4F9D\u53EF\u884C\u6027\u53CA\u689D\u4EF6\u548C\u689D\u6B3E\u4FDD\u6709\u4FEE\u6539\u3001\u7D42\u6B62\u548C\u66FF\u63DB\u60A8\u9078\u64C7\u7684\u6B0A\u5229\u3002";
var APPB2C_common_testDetails_modifyNote_pleaseNote4 = "\u8ACB\u6CE8\u610F";
var APPB2C_common_testDetails_title4 = "\u6E2C\u9A57\u8A73\u60C5";
var APPB2C_common_testDetails_yourSpeakingTest4 = "\u60A8\u7684\u53E3\u8A66";
var APPB2C_common_testDetails_yourWrittenTest4 = "\u60A8\u7684\u7B46\u8A66";
var APPB2C_common_transfer_agreement4 = "\u6709\u95DC\u6211\u5011\u7684\u7570\u52D5\u898F\u7BC4\u8A73\u7D30\u8CC7\u8A0A, \u8ACB\u53C3\u95B1\u6211\u5011\u7684<1>\u689D\u6B3E\u548C\u689D\u4EF6</1>";
var APPB2C_common_transfer_contactNote4 = "\u6211\u5011\u5C07\u76F4\u63A5\u8207\u60A8\u806F\u7E6B\u78BA\u8A8D\u60A8\u7684\u8003\u8A66\u7570\u52D5\u3002\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u56DE\u8986\u76F8\u95DC\u806F\u7E6B\u3002";
var APPB2C_common_transfer_contactNoteIol4 = "\u6211\u5011\u5C07\u76F4\u63A5\u4EE5\u96FB\u5B50\u90F5\u4EF6\u8207\u60A8\u806F\u7E6B\u8655\u7406\u60A8\u7684\u8003\u8A66\u7570\u52D5\u3002\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u56DE\u8986\u6240\u6709\u806F\u7E6B\u3002";
var APPB2C_common_transfer_cta4 = "\u63D0\u4EA4\u7570\u52D5\u7533\u8ACB";
var APPB2C_common_transfer_form_freeform4 = "\u8ACB\u544A\u8A34\u6211\u5011\u60A8\u5C0D\u65B0\u6E2C\u8A66\u7684\u504F\u597D";
var APPB2C_common_transfer_form_reasons_14 = "\u6211\u9810\u8A02\u7684\u6E2C\u8A66\u985E\u578B";
var APPB2C_common_transfer_form_reasons_24 = "\u6211\u7684\u9810\u8A02\u5730\u9EDE";
var APPB2C_common_transfer_form_reasons_34 = "\u8003\u8A66\u65E5\u671F";
var APPB2C_common_transfer_form_reasons_44 = "\u53EA\u662F\u53E3\u8A9E\u90E8\u5206";
var APPB2C_common_transfer_form_reasons_choose4 = "\u8ACB\u9078\u64C7\u4E00\u500B\u9078\u9805";
var APPB2C_common_transfer_form_reasons_other4 = "\u5176\u4ED6";
var APPB2C_common_transfer_form_reasons_title4 = "\u60A8\u9700\u8981\u8B8A\u66F4\u4EC0\u9EBC\uFF1F";
var APPB2C_common_transfer_goBack4 = "\u8FD4\u56DE";
var APPB2C_common_transfer_notes_14 = "\u8F49\u5B78\u7533\u8ACB\u9808\u7D93\u8003\u8A66\u4E2D\u5FC3\u6279\u51C6\u3002";
var APPB2C_common_transfer_notes_24 = "\u96C5\u601D\u8003\u8A66\u53EA\u80FD\u8F49\u4E00\u6B21\u3002";
var APPB2C_common_transfer_notes_34 = "\u60A8\u5C07\u9700\u8981\u5728 3 \u500B\u6708\u5167\u9078\u64C7\u4E00\u500B\u66FF\u4EE3\u8003\u8A66\u65E5\u3002";
var APPB2C_common_transfer_notes_44 = "\u53EF\u80FD\u6703\u6536\u53D6\u884C\u653F\u8CBB\u7528\u3002\u5982\u679C\u8ACB\u6C42\u7372\u5F97\u6279\u51C6, \u6211\u5011\u5C07\u5411\u60A8\u767C\u9001\u4ED8\u6B3E\u8AAA\u660E\u3002";
var APPB2C_common_transfer_notesIol_14 = "\u8F49\u79FB\u7533\u8ACB\u9808\u7D93\u82F1\u570B\u6587\u5316\u5354\u6703\u6279\u51C6\u3002";
var APPB2C_common_transfer_notesIol_24 = "\u5728\u63D0\u4EA4\u7533\u8ACB\u4E4B\u524D, \u8ACB\u4ED4\u7D30\u95B1\u8B80<0>\u96C5\u601D\u8003\u8A66\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_transfer_requestValidation4 = "\u6B64\u6B04\u4F4D\u70BA\u5FC5\u586B\u6B04\u4F4D\u3002";
var APPB2C_common_transfer_selfService_availableDates_title4 = "\u53EF\u7528\u65E5\u671F";
var APPB2C_common_transfer_selfService_chooseDates_title4 = "\u8F49\u79FB\u6E2C\u8A66";
var APPB2C_common_transfer_selfService_review_confirm_proceedBtn4 = "\u8F49\u79FB\u6E2C\u8A66";
var APPB2C_common_transfer_selfService_review_confirm_title4 = "\u78BA\u8A8D\u8F49\u5E33";
var APPB2C_common_transfer_selfService_review_errors_noDistricts4 = "\u6B64\u4F4D\u7F6E\u4E0D\u518D\u63D0\u4F9B\u6E2C\u8A66\u65E5\u671F\u3002\u8ACB\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3, \u770B\u770B\u6211\u5011\u662F\u5426\u53EF\u4EE5\u70BA\u60A8\u63D0\u4F9B\u8AAC\u660E\u3002";
var APPB2C_common_transfer_selfService_review_errors_noLrwSlots4 = "\u6240\u9078\u65E5\u671F\u7684\u6E2C\u8A66\u5BB9\u91CF\u5DF2\u7528\u76E1\u3002\u5617\u8A66\u9078\u64C7\u5176\u4ED6\u65E5\u671F\u3002";
var APPB2C_common_transfer_selfService_review_errors_transferDisabled4 = "\u8F49\u79FB\u60A8\u7684\u6E2C\u8A66\u4E0D\u518D\u53EF\u7528\u3002\u8ACB\u806F\u7E6B\u60A8\u7684\u8003\u8A66\u4E2D\u5FC3\u77AD\u89E3\u66F4\u591A\u8CC7\u8A0A\u3002";
var APPB2C_common_transfer_selfService_review_test_changeLrw4 = "\u66F4\u6539\u7B46\u8A66\u65E5\u671F";
var APPB2C_common_transfer_selfService_review_tncs_agreement4 = "\u60A8\u540C\u610F\u96C5\u601D <0>\u689D\u6B3E\u548C\u689D\u4EF6</0> \u548C <2>\u53D6\u6D88\u653F\u7B56</2>";
var APPB2C_common_transfer_title4 = "\u8F49\u79FB\u60A8\u7684\u9810\u8A02";
var APPB2C_common_underConstruction_message4 = "\u4F60\u597D\u3002\u9032\u5165\u6B64\u9801\u9762\u662F\u7531\u65BC\u60A8\u662F\u6211\u5011\u5831\u540D\u7DB2\u7AD9\u7684\u512A\u5148\u6E2C\u8A66\u4EBA\u54E1\u4E4B\u4E00(\u611F\u8B1D\u5354\u52A9\uFF01)\u5225\u64D4\u5FC3, \u5728\u4E0A\u7DDA\u4E4B\u524D, \u6211\u5011\u6703\u78BA\u4FDD\u5728\u9019\u88E1\u5411\u771F\u6B63\u7684\u5BA2\u6236\u5C55\u793A\u4E00\u4E9B\u9069\u7576\u7684\u5167\u5BB9: )";
var APPB2C_common_underConstruction_title4 = "\u6B63\u5728\u65BD\u5DE5\u4E2D\u2026\u2026";
var APPB2C_common_unknownCountry_cta4 = "\u6211\u5DF2\u95DC\u9589\u6211\u7684 VPN";
var APPB2C_common_unknownCountry_header4 = "\u6211\u5011\u7121\u6CD5\u6AA2\u6E2C\u5230\u60A8\u6B63\u5728\u700F\u89BD\u7684\u570B\u5BB6/\u5730\u5340: (";
var APPB2C_common_unknownCountry_msg14 = "\u6709\u6642\u767C\u751F\u9019\u7A2E\u60C5\u6CC1\u662F\u56E0\u70BA\u60A8\u7684\u8A2D\u5099\u6B63\u5728\u4F7F\u7528\u96B1\u85CF\u60A8\u4F4D\u7F6E\u7684\u8EDF\u9AD4\u3002";
var APPB2C_common_unknownCountry_msg24 = "\u5982\u679C\u60A8\u4F7F\u7528\u7684\u662F\u53EF\u96B1\u85CF\u4F4D\u7F6E\u7684 VPN \u6216\u985E\u4F3C\u5DE5\u5177, \u8ACB\u5C07\u5176\u95DC\u9589\u4E26\u91CD\u8A66\u3002";
var APPB2C_common_unknownCountry_title4 = "\u6211\u5011\u627E\u4E0D\u5230\u4F60\uFF01";
var APPB2C_common_unpaidErrorBar_paymentExpired4 = "\u8A3B\u518A\u4ED8\u6B3E\u65E5\u671F\u5DF2\u904E\u671F";
var APPB2C_common_updateBar_cancelled4 = "\u6B64\u5834\u8003\u8A66\u5DF2\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_updateBar_cancelPending4 = "\u60A8\u53D6\u6D88\u6B64\u6E2C\u8A66\u7684\u8ACB\u6C42\u6B63\u5728\u7B49\u5F85\u4E2D\u3002<1>\u67E5\u770B\u8A73\u60C5</1>\u3002";
var APPB2C_common_updateBar_cancelRejected4 = "\u60A8\u7684\u53D6\u6D88\u8ACB\u6C42\u5DF2\u88AB\u62D2\u7D55\u3002";
var APPB2C_common_updateBar_close4 = "\u95DC\u9589";
var APPB2C_common_updateBar_details4 = "\u8ACB\u53C3\u95B1\u4E0B\u9762\u7684\u5B8C\u6574\u8A73\u7D30\u8CC7\u8A0A: ";
var APPB2C_common_updateBar_eorCompleted4 = "\u60A8\u7684\u8907\u67E5\u7533\u8ACB (EOR) \u5DF2\u5B8C\u6210\u3002";
var APPB2C_common_updateBar_eorCompleted_scoreDecreased4 = "\u60A8\u7684\u7E3D\u5206\u5F9E {{prevOverallScore}} \u4E0B\u964D\u5230 {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreIncreased4 = "\u60A8\u7684\u7E3D\u5206\u5F9E {{prevOverallScore}} \u589E\u52A0\u5230 {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreUnchanged4 = "\u60A8\u7684\u7E3D\u5206\u4FDD\u6301\u4E0D\u8B8A, \u70BA {{currentOverallScore}}";
var APPB2C_common_updateBar_eorPending4 = "\u60A8\u7684\u8907\u67E5\u7533\u8ACB (EOR) \u6B63\u5728\u8655\u7406\u4E2D\u3002<1>\u67E5\u770B\u8A73\u60C5</1>\u3002";
var APPB2C_common_updateBar_eorPendingAgentPays4 = "\u60A8\u7684\u7D50\u679C\u8907\u67E5 (EOR) \u6B63\u5728\u8655\u7406\u4E2D\u3002";
var APPB2C_common_updateBar_eorRefunded4 = "\u60A8\u7684\u8907\u67E5\u7533\u8ACB (EOR) \u8CBB\u7528\u5DF2\u9000\u9084\u3002";
var APPB2C_common_updateBar_eorRefundPending4 = "\u60A8\u7684\u8907\u67E5\u7533\u8ACB (EOR) \u8CBB\u7528\u9000\u6B3E\u6B63\u5728\u8655\u7406\u4E2D\u3002";
var APPB2C_common_updateBar_eorRejected4 = "\u60A8\u7684\u67E5\u8A62\u7D50\u679C(EOR)\u88AB\u62D2\u7D55\u3002";
var APPB2C_common_updateBar_eorRejected_details3 = "\u8ACB\u53C3\u95B1\u4E0B\u9762\u7684\u5B8C\u6574\u8A73\u7D30\u8CC7\u8A0A: ";
var APPB2C_common_updateBar_eorRejected_overallBand4 = "\u60A8\u7684\u7E3D\u5206\u8207 {{overallBand}} \u76F8\u6BD4\u6C92\u6709\u8B8A\u5316";
var APPB2C_common_updateBar_hasResults4 = "\u60A8\u7684\u6210\u7E3E\u5DF2\u53EF\u67E5\u770B\u3002";
var APPB2C_common_updateBar_termsUpdated4 = "\u60A8\u9700\u8981<1>\u63A5\u53D7\u65B0\u7684\u689D\u6B3E\u548C\u689D\u4EF6</1>\u9019\u4E9B\u689D\u6B3E\u548C\u689D\u4EF6\u5C07\u5728\u60A8\u7684\u8003\u8A66\u7576\u5929\u751F\u6548\u3002<br> \u6240\u6709\u53C3\u52A0\u96C5\u601D\u8003\u8A66\u7684\u8003\u751F\u5747\u9808\u9075\u5B88\u672C\u689D\u6B3E\u548C\u689D\u4EF6\u3002";
var APPB2C_common_updateBar_transactionRefundRequestAccepted4 = "\u60A8\u7684\u9000\u6B3E\u7533\u8ACB\u5DF2\u88AB\u63A5\u53D7\u3002";
var APPB2C_common_updateBar_transactionRefundRequestPending4 = "\u60A8\u7684\u9000\u6B3E\u7533\u8ACB\u6B63\u5728\u7B49\u5F85\u4E2D\u3002";
var APPB2C_common_updateBar_transactionRefundRequestRejected4 = "\u60A8\u7684\u9000\u6B3E\u7533\u8ACB\u5DF2\u88AB\u62D2\u7D55\u3002";
var APPB2C_common_updateBar_transferCancelled4 = "\u60A8\u7684\u8F49\u5E33\u8ACB\u6C42\u7121\u6CD5\u5F97\u5230\u8655\u7406\u3002\u8ACB\u91CD\u8A66\u6216\u806F\u7E6B\u60A8\u7684\u8003\u8A66\u4E2D\u5FC3\u77AD\u89E3\u66F4\u591A\u8CC7\u8A0A\u3002";
var APPB2C_common_updateBar_transferPending4 = "\u60A8\u8F49\u79FB\u6B64\u6E2C\u8A66\u7684\u8ACB\u6C42\u6B63\u5728\u7B49\u5F85\u4E2D\u3002<1>\u67E5\u770B\u8A73\u60C5</1>\u3002";
var APPB2C_common_updateBar_transferPendingCmds4 = "\u60A8\u7684\u8F49\u5E33\u6B63\u5728\u7B49\u5F85\u78BA\u8A8D\u3002\u7576\u60A8\u7684\u8F49\u79FB\u8ACB\u6C42\u5F97\u5230\u8655\u7406\u540E, \u60A8\u5C07\u6536\u5230\u901A\u77E5\u3002";
var APPB2C_common_updateBar_transferred4 = "\u6B64\u5834\u8003\u8A66\u5DF2\u8F49\u79FB\u3002\u60A8\u7684\u65B0\u65E5\u671F\u5982\u4E0B\u3002";
var APPB2C_common_updateBar_transferredCmds4 = "\u6B64\u6E2C\u8A66\u5DF2\u8F49\u79FB\u3002\u6709\u95DC\u65B0\u65E5\u671F, \u8ACB\u53C3\u95B1\u9810\u8A02\u8A73\u60C5\u3002";
var APPB2C_registration_bookingDetails_payment4 = "\u4ED8\u6B3E";
var APPB2C_registration_bookingDetails_results4 = "\u67E5\u8A62\u7D50\u679C";
var APPB2C_registration_bookingDetails_seePayment4 = "\u67E5\u770B\u4ED8\u6B3E\u8A73\u60C5";
var APPB2C_registration_bookingDetails_seeTestTaker4 = "\u67E5\u770B\u8003\u751F\u8CC7\u8A0A";
var APPB2C_registration_bookingDetails_whatNext4 = "\u63A5\u4E0B\u4F86\u6211\u80FD\u505A\u4EC0\u9EBC\uFF1F";
var APPB2C_registration_date_check_back4 = "\u8FD4\u56DE\u6E2C\u9A57\u8A73\u60C5";
var APPB2C_registration_date_check_beforeTestDate4 = "\u8003\u8A66\u65E5\u524D";
var APPB2C_registration_date_check_intro4 = "\u5728\u8003\u8A66\u65E5\u524D, \u8ACB\u78BA\u4FDD\u60A8\u6709\u57F7\u884C\u4EE5\u4E0B\u64CD\u4F5C: ";
var APPB2C_registration_date_check_onTestDate4 = "\u8003\u8A66\u7576\u5929";
var APPB2C_registration_date_check_step1_body4 = "\u6AA2\u67E5\u60A8\u8003\u8A66\u9810\u8A08\u4F7F\u7528\u7684\u7B46\u8A18\u578B\u96FB\u8166\u6216\u684C\u4E0A\u578B\u96FB\u8166\u662F\u5426\u7B26\u5408\u6700\u4F4E\u9700\u6C42(\u50C5\u9650 PC \u6216 Mac \u2013 \u60A8\u4E0D\u80FD\u5728\u624B\u6A5F\u6216\u5E73\u677F\u96FB\u8166\u4E0A\u53C3\u52A0\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57)\u3002";
var APPB2C_registration_date_check_step1_link4 = "\u6AA2\u67E5\u60A8\u7684\u8A2D\u5099\u662F\u5426\u7B26\u5408\u9700\u6C42";
var APPB2C_registration_date_check_step1_title4 = "\u7B2C 1 \u6B65 \u2013 \u8A2D\u5099\u6AA2\u67E5";
var APPB2C_registration_date_check_step2_body4 = "\u5728\u8003\u8A66\u65E5\u671F\u524D, \u60A8\u9700\u8981\u5728\u9810\u8A08\u4F7F\u7528\u7684PC\u6216Mac\u4E0A\u5B89\u88DD Inspera \u8EDF\u9AD4\u3002";
var APPB2C_registration_date_check_step2_link4 = "\u4E0B\u8F09 Inspera \u8EDF\u9AD4";
var APPB2C_registration_date_check_step2_title4 = "\u6B65\u9A5F 2 \u2013 \u5B89\u88DD\u8EDF\u9AD4";
var APPB2C_registration_date_check_step3_body14 = "\u5728\u60A8\u7684\u8A2D\u5099\u4E0A\u5B89\u88DD Inspera \u5F8C, \u958B\u555F\u8EDF\u9AD4\u4E26\u767B\u5165\u3002\u50C5\u9700\u5728\u4E0B\u65B9\u8F38\u5165\u4F7F\u7528\u8005\u540D\u7A31\u548C\u5BC6\u78BC - \u8CC7\u8A0A\u8907\u88FD\u4E26\u8CBC\u4E0A\u5373\u53EF\u3002";
var APPB2C_registration_date_check_step3_body24 = "\u767B\u5165\u5F8C, \u60A8\u5C07\u9032\u884C\u4EE5\u4E0B\u6B65\u9A5F: ";
var APPB2C_registration_date_check_step3_noDetailsBody4 = "\u4ED6\u5011\u5728\u63A5\u8FD1\u8003\u8A66\u65E5\u671F\u6642\u5C07\u53EF\u4F7F\u7528\u3002";
var APPB2C_registration_date_check_step3_noDetailsTitle4 = "\u60A8\u7684\u767B\u5165\u8A73\u7D30\u8CC7\u8A0A\u5C1A\u7121\u6CD5\u4F7F\u7528";
var APPB2C_registration_date_check_step3_noSpeakingPinBody4 = "\u9019\u5728\u63A5\u8FD1\u8003\u8A66\u65E5\u671F\u6642\u5C07\u53EF\u4EE5\u4F7F\u7528\u3002";
var APPB2C_registration_date_check_step3_noSpeakingPinTitle4 = "\u60A8\u7684\u53E3\u8AAA\u6E2C\u9A57 PIN \u78BC\u9084\u7121\u6CD5\u4F7F\u7528";
var APPB2C_registration_date_check_step3_password4 = "\u60A8\u7684\u5BC6\u78BC: ";
var APPB2C_registration_date_check_step3_speakingPin4 = "\u60A8\u7684\u53E3\u8AAA\u6E2C\u9A57\u5BC6\u78BC: ";
var APPB2C_registration_date_check_step3_step1_body4 = "\u7CFB\u7D71\u6703\u8981\u6C42\u60A8\u6AA2\u67E5\u9EA5\u514B\u98A8\u548C\u651D\u5F71\u6A5F\u662F\u5426\u6B63\u5E38\u4F5C\u696D\u3002";
var APPB2C_registration_date_check_step3_step1_title4 = "\u7B2C 1 \u6B65 \u2013 \u8A2D\u5099\u6AA2\u67E5";
var APPB2C_registration_date_check_step3_step2_body4 = "\u60A8\u5C07\u88AB\u8981\u6C42\u62CD\u651D\u81C9\u90E8\u548C\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6\u7684\u7167\u7247(\u8ACB\u78BA\u8A8D\u60A8\u6709\u539F\u672C\u5831\u540D\u6642\u4F7F\u7528\u7684\u8EAB\u4EFD\u8B49\u660E\u6587\u4EF6)\u3002";
var APPB2C_registration_date_check_step3_step2_title4 = "\u7B2C 2 \u6B65 \u2013 \u8B58\u5225";
var APPB2C_registration_date_check_step3_step3_body4 = "\u8981\u8A2A\u554F\u60A8\u7684\u96C5\u601D\u5728\u7DDA\u8003\u8A66, \u7CFB\u7D71\u6703\u8981\u6C42\u60A8\u8F38\u5165 PIN \u78BC\u3002";
var APPB2C_registration_date_check_step3_step3_title4 = "\u7B2C 3 \u6B65 \u2013 \u767B\u5165\u6E2C\u8A66";
var APPB2C_registration_date_check_step3_username4 = "\u60A8\u7684\u4F7F\u7528\u8005\u540D\u7A31: ";
var APPB2C_registration_date_check_step3_writtenPin4 = "\u60A8\u7684\u7B46\u8A66\u5BC6\u78BC: ";
var APPB2C_registration_date_zone_country4 = "\u6211\u5011\u63D0\u4F9B\u60A8\u7576\u5730\u8A9E\u7CFB\u70BA";
var APPB2C_registration_date_zone_header4 = "\u7576\u60A8\u5831\u540D\u8003\u8A66\u6642";
var APPB2C_registration_date_zone_name4 = "\u60A8\u7684\u6642\u5340";
var APPB2C_registration_date_zone_time4 = "\u60A8\u7684\u7576\u5730\u6642\u9593";
var APPB2C_registration_dateLocation_changeLinkIol4 = "\u6211\u60F3\u66F4\u6539\u6211\u7684\u6E2C\u8A66";
var APPB2C_registration_dateLocation_changeLrwLink4 = "\u6211\u60F3\u66F4\u6539\u7B46\u8A66";
var APPB2C_registration_dateLocation_changeSpeakingLink4 = "\u6211\u60F3\u66F4\u6539\u6211\u7684\u53E3\u8A9E\u6E2C\u8A66";
var APPB2C_registration_dateLocation_location4 = "\u4F4D\u7F6E";
var APPB2C_registration_dateLocation_manage_cancel4 = "\u53D6\u6D88\u6211\u7684\u9810\u8A02";
var APPB2C_registration_dateLocation_manage_proceed3 = "\u9032\u884C";
var APPB2C_registration_dateLocation_manage_title4 = "\u7BA1\u7406\u60A8\u7684\u9810\u8A02";
var APPB2C_registration_dateLocation_manage_transferSpeaking4 = "\u63DB\u500B\u8AAA\u8A71";
var APPB2C_registration_dateLocation_manage_transferWhole4 = "\u8F49\u5B78\u8003\u8A66\u65E5\u671F";
var APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest4 = "\u7E7C\u7E8C\u53E3\u8A9E\u6E2C\u8A66";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody14 = "\u6211\u5011\u525B\u525B\u78BA\u8A8D\u904E, \u6C92\u6709\u5176\u4ED6\u53E3\u8A66\u5834\u6B21\u53EF\u4EE5\u5831\u540D, \u5F88\u62B1\u6B49\uFF01";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody24 = "\u5982\u679C\u60A8\u78BA\u5BE6\u9700\u8981\u66F4\u6539\u53E3\u8A9E\u8003\u8A66, \u8ACB\u8ACB\u6C42\u66F4\u6539\u8003\u8A66\u65E5\u671F, \u7136\u5F8C\u60A8\u53EF\u4EE5\u9078\u64C7\u65B0\u7684\u53E3\u8A9E\u8003\u8A66\u3002";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader4 = "\u5C0D\u4E0D\u8D77 - \u6C92\u6709\u53EF\u7528\u7684\u53E3\u8A9E\u6E2C\u8A66\uFF01";
var APPB2C_registration_dateLocation_speakingTransfer_requestTransfer4 = "\u6539\u70BA\u8ACB\u6C42\u8F49\u79FB";
var APPB2C_registration_incorrectOrganisation_homeLink4 = "\u986F\u793A\u6C92\u6709\u7D44\u7E54\u80CC\u666F\u7684\u61C9\u8A66\u8005\u9580\u6236";
var APPB2C_registration_incorrectOrganisation_line14 = "\u770B\u8D77\u4F86\u60A8\u6B63\u5728\u5617\u8A66\u5728\u6211\u5011\u4E0D\u8A8D\u8B58\u7684\u7D44\u7E54\u74B0\u5883\u4E2D\u4F7F\u7528\u61C9\u8A66\u8005\u9580\u6236\u3002";
var APPB2C_registration_incorrectOrganisation_line24 = "\u8ACB\u5617\u8A66\u9EDE\u64CA\u5C07\u60A8\u518D\u6B21\u5F15\u5C0E\u5230\u9019\u88E1\u7684\u539F\u59CB\u9023\u7D50, \u6216\u8005\u76F4\u63A5\u4F7F\u7528\u6C92\u6709\u4EFB\u4F55\u4E0A\u4E0B\u6587\u7684 TTP: ";
var APPB2C_registration_payments_actions_editProofCTA4 = "\u7DE8\u8F2F\u4ED8\u6B3E\u8B49\u660E";
var APPB2C_registration_payments_actions_header4 = "\u60A8\u60F3\u5982\u4F55\u4ED8\u6B3E\uFF1F";
var APPB2C_registration_payments_actions_paymentInProgressInfo4 = "\u7DDA\u4E0A\u4ED8\u6B3E\u672A\u5B8C\u6210, \u8ACB\u7B49\u5F85\u7D50\u679C\u3002";
var APPB2C_registration_payments_actions_payOfflineCTA4 = "\u4EE5\u5176\u4ED6\u65B9\u5F0F\u652F\u4ED8";
var APPB2C_registration_payments_actions_payOnlineCTA4 = "\u7DDA\u4E0A\u4ED8\u6B3E";
var APPB2C_registration_payments_actions_subHeader4 = "\u6211\u5DF2\u7D93\u4ED8\u6B3E, \u9700\u8981\u4E0A\u50B3\u4ED8\u6B3E\u8B49\u660E\u3002";
var APPB2C_registration_payments_actions_uploadProofCTA4 = "\u4E0A\u50B3\u4ED8\u6B3E\u8B49\u660E";
var APPB2C_registration_payments_awaitingPayment4 = "\u7B49\u5F85\u4ED8\u6B3E";
var APPB2C_registration_payments_back4 = "\u56DE\u5230\u8003\u8A66";
var APPB2C_registration_payments_completed4 = "\u5B8C\u6210";
var APPB2C_registration_payments_expired4 = "\u904E\u671F";
var APPB2C_registration_payments_finishPayment_error_cta4 = "\u91CD\u65B0\u6574\u7406\u9801\u9762";
var APPB2C_registration_payments_finishPayment_error_messageLine14 = "\u78BA\u8A8D\u60A8\u7684\u4ED8\u6B3E\u72C0\u614B\u6642\u767C\u73FE\u932F\u8AA4, \u8ACB\u65BC\u5E7E\u5206\u9418\u5F8C\u518D\u6B21\u91CD\u65B0\u6574\u7406\u9801\u9762\u3002";
var APPB2C_registration_payments_finishPayment_error_messageLine24 = "\u82E5\u6B64\u554F\u984C\u4F9D\u7136\u5B58\u5728, \u8ACB\u56DE\u5230\u4ED8\u6B3E\u65B9\u5F0F\u9078\u64C7\u9801\u9762\u3002";
var APPB2C_registration_payments_finishPayment_error_title4 = "\u672A\u77E5\u7684\u4ED8\u6B3E\u72C0\u614B";
var APPB2C_registration_payments_finishPayment_failure_alert4 = "\u5F88\u62B1\u6B49, \u60A8\u7684\u7DDA\u4E0A\u4ED8\u6B3E\u672A\u6210\u529F\uFF01";
var APPB2C_registration_payments_finishPayment_failure_cta4 = "\u67E5\u770B\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_registration_payments_finishPayment_failure_message4 = "\u8ACB\u8FD4\u56DE\u60A8\u7684\u4ED8\u6B3E\u65B9\u5F0F\u9078\u64C7\u9801\u4E26\u4E14\u518D\u6B21\u9001\u51FA\u3002";
var APPB2C_registration_payments_finishPayment_failure_title4 = "\u4ED8\u6B3E\u5931\u6557";
var APPB2C_registration_payments_finishPayment_pleaseWait4 = "\u8ACB\u7A0D\u5019";
var APPB2C_registration_payments_finishPayment_processing4 = "\u6211\u5011\u6B63\u5728\u8655\u7406\u60A8\u7684\u4ED8\u6B3E...";
var APPB2C_registration_payments_finishPayment_success_alert4 = "\u60A8\u7684\u4ED8\u6B3E\u5DF2\u6210\u529F\uFF01";
var APPB2C_registration_payments_finishPayment_success_cta4 = "\u67E5\u770B\u6B64\u6E2C\u9A57";
var APPB2C_registration_payments_finishPayment_success_message4 = "\u6211\u5011\u5DF2\u5C07\u4ED8\u6B3E\u78BA\u8A8D\u4FE1\u767C\u9001\u81F3\u60A8\u7684\u96FB\u5B50\u90F5\u4EF6\u3002";
var APPB2C_registration_payments_finishPayment_success_title4 = "\u4ED8\u6B3E\u78BA\u8A8D";
var APPB2C_registration_payments_offlineDetails_accountName4 = "\u5E33\u6236\u540D\u7A31";
var APPB2C_registration_payments_offlineDetails_accountNumber4 = "\u5E33\u865F";
var APPB2C_registration_payments_offlineDetails_payBy4 = "\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_registration_payments_offlineDetails_sortCode4 = "\u6392\u5E8F\u4EE3\u78BC";
var APPB2C_registration_payments_paidByAgent4 = "\u8A3B\u518A\u8CBB\u7528\u7531\u4EE3\u7406\u652F\u4ED8";
var APPB2C_registration_payments_proofPreview_fileAlt4 = "\u4E0A\u50B3\u7684\u4ED8\u6B3E\u8B49\u660E";
var APPB2C_registration_payments_proofPreview_proofLabel4 = "\u4ED8\u6B3E\u8B49\u660E: ";
var APPB2C_registration_payments_proofUpload_cta4 = "\u4FDD\u5B58\u60A8\u7684\u6A94\u6848";
var APPB2C_registration_payments_proofUpload_info4 = "\u60A8\u53EF\u4EE5\u4E0A\u50B3 2 \u500B\u6A94\u6848(\u60A8\u5FC5\u9808\u81F3\u5C11\u4E0A\u50B3 1 \u500B)";
var APPB2C_registration_payments_proofUpload_intro4 = "\u82E5\u60A8\u5DF2\u652F\u4ED8\u6B64\u8CBB\u7528, \u53EF\u4EE5\u5728\u4E0B\u65B9\u4E0A\u50B3\u4ED8\u6B3E\u8B49\u660E\u3002";
var APPB2C_registration_payments_proofUpload_rules_intro4 = "\u60A8\u7684\u4ED8\u6B3E\u8B49\u660E\u5FC5\u9808\u6E05\u695A\u986F\u793A: ";
var APPB2C_registration_payments_proofUpload_rules_rule14 = "\u6536\u6B3E\u4EBA\u59D3\u540D";
var APPB2C_registration_payments_proofUpload_rules_rule24 = "\u6536\u6B3E\u4EBA\u5E33\u6236\u865F\u78BC";
var APPB2C_registration_payments_proofUpload_rules_rule34 = "\u652F\u4ED8\u91D1\u984D";
var APPB2C_registration_payments_proofUpload_rules_rule44 = "\u4ED8\u6B3E\u65E5\u671F";
var APPB2C_registration_payments_proofUpload_rules_rule54 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u5E33\u865F";
var APPB2C_registration_payments_transaction_cancelledSuffix4 = "(\u5DF2\u53D6\u6D88)";
var APPB2C_registration_payments_transaction_deadlineWarning_default4 = "\u60A8\u5FC5\u9808\u5728 {{date}} \u524D\u652F\u4ED8\u6B64\u8CBB\u7528\u3002";
var APPB2C_registration_payments_transaction_deadlineWarning_eor4 = "\u60A8\u5FC5\u9808\u5728 {{date}} \u524D\u652F\u4ED8\u6B64\u8CBB\u7528, \u5426\u5247\u60A8\u7684\u8907\u67E5\u7533\u8ACB (EOR) \u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_registration_payments_transaction_deadlineWarning_registration4 = "\u60A8\u5FC5\u9808\u5728 {{date}} \u524D\u652F\u4ED8\u6B64\u8CBB\u7528, \u5426\u5247\u60A8\u7684\u5831\u540D\u5C07\u88AB\u53D6\u6D88\u3002";
var APPB2C_registration_payments_transaction_description_appeal4 = "\u4E0A\u8A34";
var APPB2C_registration_payments_transaction_description_eor4 = "\u8907\u67E5";
var APPB2C_registration_payments_transaction_description_registration4 = "\u8003\u8A66\u5831\u540D";
var APPB2C_registration_payments_transaction_description_transfer4 = "\u8F49\u79FB";
var APPB2C_registration_payments_transaction_feeLabel4 = "\u8CBB\u7528";
var APPB2C_registration_payments_transaction_header4 = "\u4ED8\u6B3E";
var APPB2C_registration_payments_transaction_hideDetails4 = "\u96B1\u85CF\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_registration_payments_transaction_outdatedTermsLabel4 = "\u60A8\u6700\u521D\u5DF2\u540C\u610F\u6B64<0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_registration_payments_transaction_pastDeadline4 = "\u6B64\u9805\u76EE\u7684\u4ED8\u6B3E\u5C1A\u672A\u65BC\u6211\u5011\u7684\u7CFB\u7D71\u4E2D\u8A3B\u518A\u3002";
var APPB2C_registration_payments_transaction_pastDeadlineContactUs4 = "\u5982\u679C\u60A8\u767C\u73FE\u932F\u8AA4, \u4E26\u4E14\u53EF\u4EE5\u63D0\u4F9B\u6E96\u6642\u4ED8\u6B3E\u7684\u8B49\u660E, \u8ACB\u806F\u7E6B\u6211\u5011: ";
var APPB2C_registration_payments_transaction_pastDeadlineEmail4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_registration_payments_transaction_pastDeadlinePhone4 = "\u96FB\u8A71";
var APPB2C_registration_payments_transaction_promoCode4 = "\u4FC3\u92B7\u4EE3\u78BC";
var APPB2C_registration_payments_transaction_receipt4 = "\u6536\u64DA";
var APPB2C_registration_payments_transaction_receiptCta4 = "\u60A8\u53EF\u65BC\u6B64\u8655\u4E0B\u8F09";
var APPB2C_registration_payments_transaction_referenceLabel4 = "\u4EA4\u6613\u5E8F\u865F";
var APPB2C_registration_payments_transaction_showDetails4 = "\u986F\u793A\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_registration_payments_transaction_taxLabel4 = "\u7A05";
var APPB2C_registration_payments_transaction_termsLabel4 = "\u67E5\u770B<0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_registration_payments_transaction_totalLabel4 = "\u7E3D\u548C";
var APPB2C_registration_payments_transaction_underReviewSuffix4 = "(\u5BE9\u67E5\u4E2D)";
var APPB2C_registration_payments_transaction_unpaidSuffix4 = "(\u5C1A\u672A\u4ED8\u6B3E)";
var APPB2C_registration_payments_unpaid4 = "\u672A\u4ED8\u6B3E\u7684";
var APPB2C_registration_prepare_a1_cta4 = "\u6E96\u5099\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u985E\u8003\u8A66 \u2013 A1 \u7D1A";
var APPB2C_registration_prepare_a1_header4 = "A1 \u7D1A\u6578";
var APPB2C_registration_prepare_a2_cta4 = "\u6E96\u5099\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u985E\u8003\u8A66 \u2013 A2 \u7D1A";
var APPB2C_registration_prepare_a2_header4 = "A2 \u7D1A";
var APPB2C_registration_prepare_b1_cta4 = "\u6E96\u5099\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u985E\u8003\u8A66 - B1 \u7D1A";
var APPB2C_registration_prepare_b1_header4 = "\u7D1A\u5225 B1";
var APPB2C_registration_prepare_blog_body_cta4 = "\u67E5\u770B\u96C5\u601D\u6D77\u5916\u6A5F\u6703\u90E8\u843D\u683C";
var APPB2C_registration_prepare_blog_body_line14 = "\u9019\u500B\u90E8\u843D\u683C\u63D0\u4F9B\u96C5\u601D\u8003\u8A66\u7684\u5C08\u5BB6\u5EFA\u8B70\u548C\u6280\u5DE7, \u4EE5\u53CA\u7F8E\u570B\u6821\u5712\u7684\u6982\u8FF0\u3002";
var APPB2C_registration_prepare_blog_header4 = "\u96C5\u601D\u6D77\u5916\u6A5F\u6703\u90E8\u843D\u683C";
var APPB2C_registration_prepare_cdFamiliarTest_body_cta4 = "\u4F7F\u7528\u96C5\u601D\u6A5F\u8003\u6A21\u64EC\u8A66\u984C";
var APPB2C_registration_prepare_cdFamiliarTest_body_line14 = "\u96C5\u601D\u6A5F\u8003\u7684\u514D\u8CBB\u5B8C\u6574\u7BC4\u4F8B\u63D0\u4F9B\u5171 2 \u5C0F\u6642 30 \u5206\u9418\u7684\u807D\u529B\u3001\u95B1\u8B80\u53CA\u5BEB\u4F5C\u554F\u984C, \u8207\u771F\u6B63\u7684\u96C5\u601D\u8003\u8A66\u5B8C\u5168\u76F8\u540C\u3002";
var APPB2C_registration_prepare_cdFamiliarTest_header4 = "\u96C5\u601D\u96FB\u8166\u8003\u719F\u6089\u5EA6\u6E2C\u9A57";
var APPB2C_registration_prepare_cdFamiliarVideos_body_cta4 = "\u89C0\u770B\u96C5\u601D\u96FB\u8166\u8003\u719F\u6089\u5F71\u7247";
var APPB2C_registration_prepare_cdFamiliarVideos_body_line14 = "\u9019\u4E9B\u5F71\u7247\u5C07\u70BA\u60A8\u4ECB\u7D39\u807D\u529B\u3001\u95B1\u8B80\u548C\u5BEB\u4F5C\u90E8\u5206\u5728\u96FB\u8166\u4E0A\u7684\u986F\u793A\u65B9\u5F0F\u3002";
var APPB2C_registration_prepare_cdFamiliarVideos_header4 = "\u96C5\u601D\u96FB\u8166\u8003\u719F\u6089\u5F71\u7247";
var APPB2C_registration_prepare_cdTests_body_cta4 = "\u53C3\u52A0\u96C5\u601D\u6A21\u64EC\u6E2C\u9A57\u96FB\u8166\u8003";
var APPB2C_registration_prepare_cdTests_body_line14 = "\u9019\u4E9B\u6A21\u64EC\u8A66\u984C\u80FD\u5E6B\u52A9\u4F60\u6E96\u5099\u8003\u8A66\u4EE5\u53CA\u4E86\u89E3\u9069\u7528\u7684\u9078\u9805\u3002";
var APPB2C_registration_prepare_cdTests_header4 = "\u96C5\u601D\u6A21\u64EC\u6E2C\u9A57\u96FB\u8166\u8003";
var APPB2C_registration_prepare_cdTipsVideos_body_cta4 = "\u89C0\u770B\u96C5\u601D\u96FB\u8166\u8003\u6280\u5DE7\u5F71\u7247";
var APPB2C_registration_prepare_cdTipsVideos_body_line14 = "\u96C5\u601D\u96FB\u8166\u8003\u6280\u5DE7";
var APPB2C_registration_prepare_cdTipsVideos_header4 = "\u96C5\u601D\u96FB\u8166\u8003\u6280\u5DE7\u5F71\u7247";
var APPB2C_registration_prepare_facebook_body_cta4 = "\u8AEE\u8A62\u5C08\u5BB6 (Facebook)";
var APPB2C_registration_prepare_facebook_body_line14 = "\u96C5\u601D\u5C08\u5BB6\u6BCF\u500B\u6708\u6703\u5728 Facebook \u4E0A\u9032\u884C\u4E09\u6B21\u5373\u6642\u901A\u8A0A, \u73FE\u5834\u56DE\u7B54\u89C0\u773E\u7684\u554F\u984C\u3002";
var APPB2C_registration_prepare_facebook_header4 = "\u8AEE\u8A62\u5C08\u5BB6 (Facebook)";
var APPB2C_registration_prepare_gel_disabledMsg4 = "\u60A8\u7684\u8003\u8A66\u4E2D\u5FC3\u963B\u6B62\u4E86\u5C0D <0>IELTS Ready Premium</0> \u7684\u8A2A\u554F\u3002\u8ACB\u8207\u4ED6\u5011\u806F\u7E6B, \u4EE5\u7372\u5F97\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u7684\u514D\u8CBB\u6E96\u5099\u670D\u52D9\u3002";
var APPB2C_registration_prepare_gel_ielts_body_cta4 = "\u7372\u53D6\u8A2A\u554F\u8A31\u53EF\u6B0A";
var APPB2C_registration_prepare_gel_ielts_body_line14 = "\u500B\u6027\u5316\u3001\u667A\u6167\u7684\u652F\u6301, \u9069\u5408\u60A8\u548C\u60A8\u7684\u5B78\u7FD2\u9700\u6C42, \u8AAC\u660E\u60A8\u9054\u5230\u6240\u9700\u7684\u96C5\u601D\u6210\u7E3E\u3002\u5305\u62EC\u6211\u5011\u6240\u6709\u6280\u80FD\u7684\u6A21\u64EC\u6E2C\u8A66\u548C\u7DF4\u7FD2\u8CC7\u6E90\u3002<0>IELTS Ready Premium</0>\u7531 GEL IELTS Prep \u63D0\u4F9B\u652F\u63F4, \u5C07\u8AAC\u660E\u60A8\u53D6\u5F97\u6210\u529F\u3002";
var APPB2C_registration_prepare_gel_ielts_header4 = "<0>IELTS Ready Premium</0>";
var APPB2C_registration_prepare_gel_ukvi_body_cta3 = "\u7ACB\u5373\u4F7F\u7528IELTS Ready Premium";
var APPB2C_registration_prepare_gel_ukvi_body_line14 = "\u500B\u6027\u5316\u3001\u667A\u80FD\u7684\u652F\u63F4, \u914D\u5408\u60A8\u7684\u5B78\u7FD2\u9700\u6C42, \u8AAC\u660E\u60A8\u9054\u5230\u6240\u9700\u7684UKVI\u96C5\u601D\u6210\u7E3E\u3002\u5305\u62EC\u6211\u5011\u6240\u6709\u6280\u80FD\u7684\u6A21\u64EC\u6E2C\u8A66\u548C\u7DF4\u7FD2\u8CC7\u6E90\u3002<0>IELTS Ready Premium</0>\u7531 GEL IELTS Prep \u63D0\u4F9B\u652F\u63F4, \u5C07\u8AAC\u660E\u60A8\u53D6\u5F97\u6210\u529F\u3002";
var APPB2C_registration_prepare_gel_ukvi_header4 = "<0>IELTS Ready Premium</0> UKVI";
var APPB2C_registration_prepare_header4 = "\u6E96\u5099\u8003\u8A66";
var APPB2C_registration_prepare_ieltsReady_subTitle4 = "\u60A8\u53EF\u4EE5\u4F7F\u7528\u4EE5\u4E0B\u5099\u8003\u670D\u52D9: ";
var APPB2C_registration_prepare_ieltsReady_title4 = "\u9078\u64C7\u60A8\u7684\u5099\u8003\u670D\u52D9";
var APPB2C_registration_prepare_ieltsReadyMember_body4 = "\u5EE3\u6CDB\u7684\u652F\u63F4\u6750\u6599, \u5305\u62EC\u6A21\u64EC\u8003\u8A66\u3001\u5099\u8003\u7DB2\u8DEF\u7814\u8A0E\u6703\u3001\u6280\u5DE7\u3001\u8996\u983B\u3001\u96C5\u601D\u4E4B\u8DEF\u5167\u5BB9\u7B49\u7B49\u3002\u6211\u5011\u7684 <0>IELTS Ready Member</0> \u5C08\u5340\u5C07\u8AAC\u660E\u60A8\u81EA\u4FE1\u5730\u6E96\u5099\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn4 = "\u9810\u7D04\u65B0\u6E2C\u8A66";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo4 = "\u8981\u514D\u8CBB\u7372\u5F97 <0>IELTS Ready Premium</0>, \u60A8\u9700\u8981\u9810\u8A02\u5373\u5C07\u5230\u4F86\u7684\u8003\u8A66\u3002";
var APPB2C_registration_prepare_ieltsReadyMember_freePrepLink4 = "\u514D\u8CBB\u7684\u5099\u8003\u8CC7\u6E90\u53EF\u5728\u6211\u5011\u7684<0>\u53C3\u52A0\u96C5\u601D\u8003\u8A66\u7DB2\u7AD9</0>\u67E5\u8A62";
var APPB2C_registration_prepare_ieltsReadyMember_header4 = "<0>IELTS Ready \u6703\u54E1</0>";
var APPB2C_registration_prepare_ieltsReadyMember_label4 = "\u5C0D\u6240\u6709\u4EBA\u514D\u8CBB";
var APPB2C_registration_prepare_ieltsReadyMember_link4 = "\u514D\u8CBB\u8A2A\u554F";
var APPB2C_registration_prepare_ieltsReadyMember_payBtn4 = "\u652F\u4ED8\u8003\u8A66\u8CBB\u7528";
var APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo4 = "\u8981\u514D\u8CBB\u7372\u5F97 <0>IELTS Ready Premium</0>, \u60A8\u9700\u8981\u70BA\u5373\u5C07\u5230\u4F86\u7684\u8003\u8A66\u4ED8\u8CBB\u3002";
var APPB2C_registration_prepare_intro4 = "\u6211\u5011\u53EF\u4EE5\u900F\u904E\u4E00\u7CFB\u5217\u514D\u8CBB\u8CC7\u6E90\u5E6B\u52A9\u60A8\u6E96\u5099\u96C5\u601D\u8003\u8A66\u3002";
var APPB2C_registration_prepare_introLifeSkills4 = "\u6211\u5011\u53EF\u4EE5\u900F\u904E\u4E00\u7CFB\u5217\u514D\u8CBB\u8CC7\u6E90\u5E6B\u52A9\u60A8\u5099\u8003\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u6E2C\u9A57\u3002";
var APPB2C_registration_prepare_onlineCourse_body_cta4 = "\u7ACB\u5373\u958B\u59CB\u514D\u8CBB\u8A66\u7528";
var APPB2C_registration_prepare_onlineCourse_body_line14 = "\u6211\u5011\u7684 4 \u9580\u300C\u4E86\u89E3IELTS \u2013 ExpertTrack (Understanding IELTS \u2013 ExpertTrack)\u300D\u8AB2\u7A0B\u63D0\u4F9B\u60A8\u96C5\u601D\u8003\u8A66\u5099\u8003\u6642\u6240\u9700\u7684\u5B8C\u6574\u6307\u5357\u3002\u60A8\u80FD\u6DF1\u5165\u77AD\u89E3\u96C5\u601D\u8003\u8A66\u7684\u6BCF\u500B\u9805\u76EE\u2014\u95B1\u8B80\u3001\u5BEB\u4F5C\u3001\u807D\u529B\u548C\u53E3\u8AAA\u3002\u60A8\u9084\u80FD\u900F\u904E\u6211\u5011\u7684\u5F71\u7247\u5F9E\u5177\u8C50\u5BCC\u7D93\u9A57\u7684\u5C0E\u5E2B\u548C\u6559\u80B2\u5C08\u5BB6\u5718\u968A\u5F97\u5230\u5EFA\u8B70\u3002\u52A0\u5165\u70BA\u671F 7 \u5929\u7684\u514D\u8CBB\u8A66\u7528\u7248, \u5229\u7528\u4E00\u9031\u7684\u6642\u9593\u4F7F\u7528\u5168\u5957\u8AB2\u7A0B\u548C\u6559\u80B2\u8A55\u4F30\u3002";
var APPB2C_registration_prepare_onlineCourse_header4 = "\u5728\u7DDA\u8AB2\u7A0B: \u77AD\u89E3\u96C5\u601D";
var APPB2C_registration_prepare_pbPractice_body_cta4 = "\u4F7F\u7528\u7DF4\u7FD2\u8A66\u984C";
var APPB2C_registration_prepare_pbPractice_body_line14 = "\u5C07\u9019\u4E9B\u8A66\u984C\u7528\u65BC\u8A08\u6642\u6027\u7684\u7DF4\u7FD2\u8003\u8A66\u3002\u60A8\u9084\u53EF\u4EE5\u67E5\u770B\u53CA\u6BD4\u8F03\u7B54\u6848\u3002";
var APPB2C_registration_prepare_pbPractice_header4 = "\u7DF4\u7FD2\u8A66\u5377";
var APPB2C_registration_prepare_practice_body_cta4 = "\u4F7F\u7528\u6A21\u64EC\u6E2C\u9A57";
var APPB2C_registration_prepare_practice_body_line14 = "\u904B\u7528\u6211\u5011\u7684\u514D\u8CBB\u6A21\u64EC\u8A66\u984C\u7DF4\u7FD2\u5E6B\u52A9\u60A8\u7372\u5F97\u7406\u60F3\u96C5\u601D\u6210\u7E3E\u3002\u5982\u679C\u60A8\u5E0C\u671B\u4F7F\u7528\u96E2\u7DDA\u8CC7\u6E90, \u8ACB\u5F9E\u4ECB\u7D39\u9801\u9762\u4E0B\u8F09\u8A66\u984C\u3001\u7A7A\u767D\u7B54\u6848\u5377\u3001\u807D\u529B\u6587\u5B57\u7A3F\u548C\u89E3\u7B54\u3002";
var APPB2C_registration_prepare_practice_header4 = "\u7DF4\u7FD2\u6E2C\u9A57";
var APPB2C_registration_prepare_prepApp_body_cta4 = "\u4F7F\u7528\u96C5\u601D\u5099\u8003\u61C9\u7528\u7A0B\u5F0F";
var APPB2C_registration_prepare_prepApp_body_line14 = "\u96C5\u601D\u61C9\u7528\u7A0B\u5F0F\u5305\u542B\u7BC4\u4F8B\u8A66\u984C\u3001\u6A21\u64EC\u6E2C\u9A57\u3001\u6587\u6CD5\u6280\u5DE7\u3001\u6D3B\u52D5\u7DF4\u7FD2\u3001\u5C0F\u8003\u7B49\u7B49\u3002\u60A8\u9084\u53EF\u4EE5\u900F\u904E\u5B83\u4F86\u8FFD\u8E64\u5099\u8003\u9032\u5EA6\u3002";
var APPB2C_registration_prepare_prepApp_header4 = "\u96C5\u601D\u5099\u8003\u61C9\u7528\u7A0B\u5F0F";
var APPB2C_registration_prepare_prepApp_ukvi_cta14 = "iOS";
var APPB2C_registration_prepare_prepApp_ukvi_cta24 = "Android";
var APPB2C_registration_prepare_prepApp_ukvi_line14 = "\u8457\u91CD\u5728\u807D\u529B\u548C\u53E3\u8AAA\u6E2C\u9A57\u7684\u540C\u6642, \u6B64\u61C9\u7528\u7A0B\u5F0F\u9084\u80FD\u5E6B\u52A9\u60A8\u64F4\u5C55\u5B57\u5F59\u91CF, \u7DF4\u7FD2\u6587\u6CD5\u4E26\u4E86\u89E3\u8003\u8A66\u7576\u5929\u8A72\u505A\u4EC0\u9EBC\u3002\u60A8\u80FD\u900F\u904E\u6587\u6CD5\u7DF4\u7FD2\u3001\u807D\u529B\u548C\u53E3\u8AAA\u6A21\u64EC\u6E2C\u9A57\u4EE5\u53CA\u96C5\u601D\u53E3\u8AAA\u793A\u7BC4\u5F71\u7247\u4F86\u4E86\u89E3\u81EA\u5DF1\u7684\u7A0B\u5EA6\u3002";
var APPB2C_registration_prepare_prepApp_ukvi_line24 = "\u4E0B\u8F09: ";
var APPB2C_registration_prepare_prepVideos_body_cta4 = "\u89C0\u770B\u96C5\u601D\u5099\u8003\u5F71\u7247";
var APPB2C_registration_prepare_prepVideos_body_line14 = "\u901A\u904E\u4E00\u7CFB\u5217\u5F71\u7247\u4E86\u89E3\u6E2C\u9A57\u6A21\u5F0F\u3002";
var APPB2C_registration_prepare_prepVideos_header4 = "\u96C5\u601D\u5099\u8003\u5F71\u7247";
var APPB2C_registration_prepare_reading_body_cta4 = "\u67E5\u770B\u300C\u7406\u89E3\u96C5\u601D: \u95B1\u8B80\u300D";
var APPB2C_registration_prepare_reading_body_line14 = "\u77AD\u89E3\u60A8\u6240\u9700\u7684\u96C5\u601D\u95B1\u8B80\u6E2C\u9A57\u7684\u76F8\u95DC\u8CC7\u8A0A, \u4EE5\u63D0\u9AD8\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u3002";
var APPB2C_registration_prepare_reading_header4 = "\u300C\u7406\u89E3\u96C5\u601D: \u95B1\u8B80\u300D";
var APPB2C_registration_prepare_rteLastMinute_body_cta4 = "\u73FE\u5728\u958B\u59CB";
var APPB2C_registration_prepare_rteLastMinute_body_item14 = "9 \u652F\u6307\u5F15\u548C\u6559\u7A0B\u5F71\u7247";
var APPB2C_registration_prepare_rteLastMinute_body_item24 = "100 \u9805\u4E92\u52D5\u6D3B\u52D5, ";
var APPB2C_registration_prepare_rteLastMinute_body_item34 = "4 \u7A2E\u6280\u80FD\u90FD\u6709 2 \u6B21\u7DF4\u7FD2\u6E2C\u9A57\u3002";
var APPB2C_registration_prepare_rteLastMinute_body_line14 = "\u70BA\u96C5\u601D\u8003\u8A66\u505A\u597D\u6E96\u5099: ";
var APPB2C_registration_prepare_rteLastMinute_header4 = "\u96C5\u601D\u4E4B\u8DEF \u2013 Last Minute";
var APPB2C_registration_prepare_rteLastMinute_ukvi_cta4 = "\u4F7F\u7528\u8AB2\u7A0B";
var APPB2C_registration_prepare_rteLastMinute_ukvi_line14 = "\u7121\u9650\u6B21\u4F7F\u7528\u82F1\u570B\u6587\u5316\u5354\u6703\u6700\u53D7\u6B61\u8FCE\u7684\u5728\u7DDA\u5099\u8003\u8AB2\u7A0B - \u96C5\u601D\u4E4B\u8DEFLast Minute, \u53EF\u89C0\u770B\u6307\u5F15\u548C\u6559\u7A0B\u5F71\u7247\u3001\u4F7F\u7528100\u500B\u4E92\u52D5\u7DF4\u7FD2, \u4E14\u80FD\u91DD\u5C0D 4 \u7A2E\u6838\u5FC3\u6280\u80FD\u9032\u884C 2 \u6B21\u6A21\u64EC\u6E2C\u9A57\u3002";
var APPB2C_registration_prepare_rteTestDrive_body_cta4 = "\u5617\u8A66\u96C5\u601D\u8DEF\u8A66\u99D5\u7248";
var APPB2C_registration_prepare_rteTestDrive_body_item14 = "30\u500B\u4E92\u52D5\u7DF4\u7FD2, ";
var APPB2C_registration_prepare_rteTestDrive_body_item24 = "4\u652F\u63D0\u4F9B\u6280\u5DE7\u548C\u6307\u5F15\u7684\u5F71\u7247";
var APPB2C_registration_prepare_rteTestDrive_body_item34 = "\u91DD\u5C0D 4 \u7A2E\u6280\u80FD\u7684\u6A21\u64EC\u6E2C\u9A57\u3002";
var APPB2C_registration_prepare_rteTestDrive_body_line14 = "\u8DDF\u8457\u96C5\u601D\u4E4B\u8DEF\u8A66\u99D5\u7248\u958B\u59CB\u60A8\u7684\u96C5\u601D\u4E4B\u65C5, \u4E26\u7372\u5F97: ";
var APPB2C_registration_prepare_rteTestDrive_body_line24 = "\u8A3B\u518A\u5B8C\u6210\u5F8C, \u60A8\u5C07\u7372\u5F97\u66F4\u591A\u8CC7\u6E90\u3002";
var APPB2C_registration_prepare_rteTestDrive_header4 = "\u96C5\u601D\u4E4B\u8DEF\u8A66\u99D5\u7248 \u2013 \u514D\u8CBB\u7248";
var APPB2C_registration_prepare_speaking_body_cta4 = "\u67E5\u770B\u300C\u7406\u89E3\u96C5\u601D: \u53E3\u8AAA\u300D";
var APPB2C_registration_prepare_speaking_body_line14 = "\u77AD\u89E3\u60A8\u6240\u9700\u7684\u96C5\u601D\u53E3\u8AAA\u6E2C\u9A57\u7684\u76F8\u95DC\u8CC7\u8A0A, \u4EE5\u63D0\u9AD8\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u3002";
var APPB2C_registration_prepare_speaking_header4 = "\u77AD\u89E3\u96C5\u601D: \u53E3\u8AAA";
var APPB2C_registration_prepare_tutor_body_cta4 = "\u6309\u6B64\u8655\u5373\u53EF\u7528 1 \u7F8E\u5143\u7684\u512A\u60E0\u50F9\u683C\u5617\u8A66\u60A8\u7684\u7B2C\u4E00\u6B21\u8AB2\u7A0B\u3002";
var APPB2C_registration_prepare_tutor_body_line14 = "\u8207\u5C08\u696D\u7684\u96C5\u601D\u5C0E\u5E2B\u4E00\u8D77\u6E96\u5099\u96C5\u601D\u8003\u8A66, \u6709\u5927\u91CF\u7684\u8AB2\u7A0B\u53EF\u4F9B\u9078\u64C7, \u4E0D\u8AD6\u662F\u53EA\u6709\u4E00\u9031\u3001\u6216\u662F\u6709\u5E7E\u500B\u6708\u7684\u6642\u9593, \u90FD\u80FD\u70BA\u96C5\u601D\u4F5C\u597D\u6E96\u5099\u3002\u53EA\u8981 1 \u7F8E\u5143, \u53EF\u4EE5\u7ACB\u523B\u958B\u59CB\u60A8\u7684\u7B2C\u4E00\u500B\u8AB2\u7A0B\u3002";
var APPB2C_registration_prepare_tutor_body_line24 = "\u7368\u5BB6\u512A\u60E0: \u4F5C\u70BA\u82F1\u570B\u6587\u5316\u5354\u6703\u7684\u96C5\u601D\u8003\u751F, \u7576\u60A8\u8CFC\u8CB7\u6211\u5011\u7684\u4E00\u5C0D\u4E00\u6E96\u5099\u8AB2\u7A0B\u6642, \u60A8\u53EF\u4EE5\u4EAB\u6709 10% \u7684\u6298\u6263\u3002";
var APPB2C_registration_prepare_tutor_header4 = "1\u5C0D1\u5BB6\u6559 \u2013 \u96C5\u601D\u5099\u8003\u8AB2\u7A0B";
var APPB2C_registration_prepare_webinars_body_cta4 = "\u8A3B\u518A\u4EE5\u4F7F\u7528";
var APPB2C_registration_prepare_webinars_body_item14 = "\u6307\u5C0E\u5F71\u7247";
var APPB2C_registration_prepare_webinars_body_item24 = "\u56DB\u7A2E\u6280\u80FD\u7684\u8A73\u7D30\u5B78\u7FD2\u8A08\u5283";
var APPB2C_registration_prepare_webinars_body_item34 = "\u7DF4\u7FD2\u6E2C\u9A57";
var APPB2C_registration_prepare_webinars_body_line14 = "\u85C9\u7531\u6211\u5011\u7684\u96C5\u601D\u5C08\u5BB6\u6BCF\u9031\u514D\u8CBB\u8209\u8FA6\u7684\u96C5\u601D\u7DB2\u8DEF\u7814\u8A0E\u6703, \u70BA\u8003\u8A66\u505A\u597D\u6E96\u5099\u3002\u60A8\u9084\u53EF\u4EE5\u900F\u904E\u4EE5\u4E0B\u65B9\u5F0F\u5F97\u5230\u6211\u5011\u7684\u96C5\u601D\u5B78\u7FD2\u5305: ";
var APPB2C_registration_prepare_webinars_header4 = "\u96C5\u601D\u7DB2\u8DEF\u7814\u8A0E\u6703";
var APPB2C_registration_results_bandScore_osrBooked4 = "\u91CD\u8003\u9810\u8A02<1>\u67E5\u770B\u9810\u8A02</1>";
var APPB2C_registration_results_bandScore_osrMarked4 = "\u91CD\u8003\u8A55\u5206\u70BA <1>\u67E5\u770B\u7D50\u679C</1>";
var APPB2C_registration_results_eor_acknowledgement_backButton4 = "\u67E5\u770B\u6B64\u6E2C\u9A57";
var APPB2C_registration_results_eor_acknowledgement_components4 = "\u60A8\u5DF2\u9078\u64C7\u9700\u91CD\u65B0\u8A55\u5206\u7684\u9805\u76EE\u5982\u4E0B: ";
var APPB2C_registration_results_eor_acknowledgement_confirmation4 = "\u6211\u5011\u5DF2\u767C\u9001\u6B64\u7B46\u8907\u67E5\u7533\u8ACB\u78BA\u8A8D\u51FD\u81F3\u60A8\u7684\u96FB\u5B50\u4FE1\u7BB1\u3002";
var APPB2C_registration_results_eor_acknowledgement_fee4 = "\u8CBB\u7528";
var APPB2C_registration_results_eor_acknowledgement_info4 = "\u60A8\u7684\u6210\u7E3E\u8907\u67E5\u7D50\u679C (EOR) \u5DF2\u88AB\u53D6\u6D88\u3002";
var APPB2C_registration_results_eor_acknowledgement_noteTitle4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_registration_results_eor_acknowledgement_payment4 = "\u5DF2\u5B8C\u6210\u4ED8\u6B3E";
var APPB2C_registration_results_eor_acknowledgement_paymentButton4 = "\u67E5\u770B\u60A8\u7684\u4ED8\u6B3E\u65B9\u5F0F\u9078\u9805";
var APPB2C_registration_results_eor_acknowledgement_paymentNote4 = "\u60A8\u4ECD\u9700\u5B8C\u6210\u4ED8\u6B3E, \u5426\u5247\u60A8\u7684\u7533\u8ACB\u5C07\u88AB\u53D6\u6D88";
var APPB2C_registration_results_eor_acknowledgement_reference4 = "\u8907\u67E5\u53C3\u8003";
var APPB2C_registration_results_eor_acknowledgement_success4 = "\u60A8\u7684\u6210\u7E3E\u8907\u67E5 (EOR) \u5DF2\u88AB\u9810\u8A02, \u6B63\u5728\u7B49\u5F85\u6700\u7D42\u7D50\u679C\u3002";
var APPB2C_registration_results_eor_acknowledgement_tax4 = "\u7A05";
var APPB2C_registration_results_eor_acknowledgement_terms4 = "\u60A8\u540C\u610F\u9019\u4E9B<0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_registration_results_eor_acknowledgement_title4 = "\u8907\u67E5\u78BA\u8A8D";
var APPB2C_registration_results_eor_acknowledgement_total4 = "\u7E3D";
var APPB2C_registration_results_eor_postDeadline_back4 = "\u6211\u4E0D\u9700\u8981\u91CD\u65B0\u8A55\u5206\u6211\u7684\u6E2C\u9A57\u3002";
var APPB2C_registration_results_eor_postDeadline_email4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_registration_results_eor_postDeadline_help4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u5831\u540D\u5E8F\u865F, \u4EE5\u80FD\u66F4\u597D\u5730\u5354\u52A9\u60A8: ";
var APPB2C_registration_results_eor_postDeadline_iol_launchChatLine4 = "\u6211\u9700\u8981\u5728\u7DB2\u4E0A\u548C\u67D0\u4EBA\u804A\u5929: ";
var APPB2C_registration_results_eor_postDeadline_iol_line34 = "\u5982\u679C\u60A8\u5E0C\u671B\u6211\u5011\u8003\u616E\u60A8\u7684 EOR, \u8ACB\u806F\u7E6B\u6211\u5011\u4EE5\u63D0\u4F9B\u8A73\u7D30\u8CC7\u8A0A\u4EE5\u652F\u63F4\u6B64\u8ACB\u6C42\u3002";
var APPB2C_registration_results_eor_postDeadline_line14 = "\u91CD\u65B0\u8A55\u5206\u8ACB\u6C42(\u7A31\u70BA\u7D50\u679C\u8907\u67E5\u6216EOR)\u5FC5\u9808\u5728\u8003\u8A66\u4E4B\u65E5\u8D77\u7B97 {{count}} \u5929\u5167\u63D0\u51FA\u3002";
var APPB2C_registration_results_eor_postDeadline_line24 = "\u7531\u65BC EOR \u7533\u8ACB\u7684\u622A\u6B62\u65E5\u671F\u5DF2\u904E, \u60A8\u7684\u7533\u8ACB\u7121\u6CD5\u7DDA\u4E0A\u8655\u7406\u3002";
var APPB2C_registration_results_eor_postDeadline_line34 = "\u5982\u679C\u60A8\u5E0C\u671B\u4E2D\u5FC3\u53D7\u7406\u60A8\u7684\u8907\u67E5, \u8ACB\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3\u4EE5\u63D0\u4F9B\u8A73\u7D30\u8CC7\u8A0A\u5B8C\u6210\u6B64\u7533\u8ACB\u3002";
var APPB2C_registration_results_eor_postDeadline_phone4 = "\u96FB\u8A71";
var APPB2C_registration_results_eor_preDeadline_back4 = "\u56DE\u5230\u7D50\u679C";
var APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength4 = "\u60A8\u7684\u89E3\u91CB\u6700\u591A\u53EF\u4EE5\u662F {{count}} \u500B\u5B57\u5143\u3002";
var APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber4 = "\u8ACB\u63D0\u4F9B\u6709\u6548\u7684\u6E2C\u8A66\u5831\u544A\u8868(TRF)\u7DE8\u865F\u3002";
var APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber4 = "\u6E2C\u8A66\u5831\u544A\u8868(TRF)\u7DE8\u865F\u5FC5\u9808\u8207\u7279\u5B9A\u683C\u5F0F\u4E00\u81F4(\u4F8B\u598211AA111110AAAA001A)\u3002";
var APPB2C_registration_results_eor_preDeadline_evidence4 = "\u8ACB\u4E0A\u50B3\u7533\u8ACB\u91CD\u65B0\u8A55\u5206\u7684\u8B49\u660E\u3002";
var APPB2C_registration_results_eor_preDeadline_evidenceRequired4 = "\u60A8\u5FC5\u9808\u63D0\u4F9B\u6B64\u8B49\u64DA\u4F86\u652F\u63F4\u60A8\u7684\u8ACB\u6C42\u3002";
var APPB2C_registration_results_eor_preDeadline_explanation4 = "\u8ACB\u65BC\u4E0B\u65B9\u7C21\u8981\u8AAA\u660E\u60A8\u8A8D\u70BA\u6240\u9078\u8003\u8A66\u9805\u76EE\u9700\u8981\u91CD\u65B0\u8A55\u5206\u7684\u539F\u56E0";
var APPB2C_registration_results_eor_preDeadline_explanationRequired4 = "\u60A8\u5FC5\u9808\u63D0\u4F9B\u76F8\u95DC\u8AAA\u660E\u4EE5\u8B49\u5BE6\u60A8\u7684\u8ACB\u6C42\u3002";
var APPB2C_registration_results_eor_preDeadline_fee4 = "\u8CBB\u7528";
var APPB2C_registration_results_eor_preDeadline_iolNote_line13 = "\u91CD\u65B0\u8A55\u5206\u7531\u8A13\u7DF4\u6709\u7D20\u7684\u8CC7\u6DF1\u8003\u5B98\u5B8C\u6210\u3002";
var APPB2C_registration_results_eor_preDeadline_iolNote_line24 = "\u7D50\u679C\u53EF\u4EE5\u5728\u7533\u8ACB\u7684\u540C\u4E00\u5929\u548C\u7533\u8ACB\u540E\u6700\u591A 21 \u5929\u5167\u63D0\u4F9B, \u5177\u9AD4\u53D6\u6C7A\u65BC\u5E7E\u500B\u56E0\u7D20, \u5305\u62EC\u8981\u6C42\u91CD\u65B0\u6A19\u8A18\u7684\u90E8\u5206\u6578\u91CF\u3002";
var APPB2C_registration_results_eor_preDeadline_iolNote_line34 = "\u5982\u679C\u60A8\u5728 21 \u5929\u5F8C\u4ECD\u672A\u6536\u5230\u56DE\u8986, \u8ACB<0>\u806F\u7E6B\u6211\u5011</0>\u3002";
var APPB2C_registration_results_eor_preDeadline_legal4 = "\u6211\u540C\u610F\u8907\u67E5 <0>\u689D\u6B3E\u548C\u689D\u4EF6</0>";
var APPB2C_registration_results_eor_preDeadline_legalNote4 = "\u82F1\u570B\u6587\u5316\u5354\u6703\u5C07\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A\u4F86\u8655\u7406\u60A8\u7684\u8907\u67E5\u7533\u8ACB\u3002\u76F8\u95DC\u6CD5\u5F8B\u4F9D\u64DA\u662F\u6211\u5011\u7684\u8907\u67E5\u689D\u6B3E\u548C\u689D\u4EF6\u540C\u610F\u66F8\u3002";
var APPB2C_registration_results_eor_preDeadline_line14 = "\u5982\u679C\u60A8\u5C0D\u8003\u8A66\u7D50\u679C\u4E0D\u6EFF\u610F, \u4E26\u4E14\u5E0C\u671B\u91CD\u65B0\u8A55\u5206, \u60A8\u53EF\u4EE5\u5728\u8003\u8A66\u65E5\u671F\u5F8C {{count}} \u5929\u5167\u7533\u8ACB\u8907\u67E5\u3002";
var APPB2C_registration_results_eor_preDeadline_line24 = "\u8907\u67E5\u96C5\u601D\u8003\u8A66\u6210\u7E3E\u9700\u8981\u4ED8\u8CBB, \u4F46\u82E5\u60A8\u5728\u4EFB\u4F55\u9078\u5B9A\u9805\u76EE\u7684\u5206\u6578\u589E\u52A0, \u9019\u7B46\u8CBB\u7528\u5C07\u9000\u9084\u3002";
var APPB2C_registration_results_eor_preDeadline_maxScore4 = "\u60A8\u65BC\u9019\u90E8\u5206\u7372\u5F97\u6EFF\u5206\u3002\u4E0D\u80FD\u8ACB\u6C42\u91CD\u65B0\u8A55\u5206\u3002";
var APPB2C_registration_results_eor_preDeadline_note4 = "\u4ED8\u6B3E\u5F8C, \u5C07\u7121\u6CD5\u53D6\u6D88\u6216\u66F4\u6539\u60A8\u7684 EOR \u7533\u8ACB\u3002";
var APPB2C_registration_results_eor_preDeadline_noteTitle4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel4 = "\u8AB0\u61C9\u8A72\u6536\u5230\u8907\u67E5\u7684\u8A02\u55AE\u78BA\u8A8D\uFF1F";
var APPB2C_registration_results_eor_preDeadline_paymentCta4 = "\u67E5\u770B\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_registration_results_eor_preDeadline_paymentTitle4 = "\u652F\u4ED8\u91CD\u65B0\u8A55\u5206\u8CBB\u7528";
var APPB2C_registration_results_eor_preDeadline_payOfflineCTA4 = "\u4EE5\u5176\u4ED6\u65B9\u5F0F\u652F\u4ED8";
var APPB2C_registration_results_eor_preDeadline_payOnlineCTA4 = "\u7DDA\u4E0A\u4ED8\u6B3E";
var APPB2C_registration_results_eor_preDeadline_scoresRequired4 = "\u8ACB\u9078\u64C7\u81F3\u5C11\u4E00\u500B\u90E8\u5206\u3002";
var APPB2C_registration_results_eor_preDeadline_scoresTitle4 = "\u8ACB\u9078\u64C7\u6E2C\u9A57\u7684\u4E00\u500B\u6216\u591A\u500B\u90E8\u5206\u9032\u884C\u91CD\u65B0\u8A55\u5206: ";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr4 = "\u60A8\u5DF2\u9078\u64C7\u67E5\u8A62\u60A8\u7684";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening4 = "\u807D\u529B\u91CD\u8003\u7D50\u679C";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading4 = "\u95B1\u8B80\u91CD\u8003\u7D50\u679C";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking4 = "\u53E3\u8AAA\u91CD\u8003\u7D50\u679C";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing4 = "\u5BEB\u4F5C\u91CD\u8003\u7D50\u679C";
var APPB2C_registration_results_eor_preDeadline_tax4 = "\u542B\u7A05";
var APPB2C_registration_results_eor_preDeadline_trfNumberLabel4 = "\u6210\u7E3E\u55AE (TRF) \u7DE8\u865F";
var APPB2C_registration_results_eor_preDeadline_trfNumberSubnote4 = "\u8ACB\u63D0\u4F9B\u6210\u7E3E\u55AE\u53F3\u4E0B\u89D2\u768415-18\u4F4D\u865F\u78BC\u3002";
var APPB2C_registration_results_eor_preDeadline_trfNumberValidation4 = "\u6B04\u4F4D\u5FC5\u9808\u8207\u7B26\u5408\u7279\u5B9A\u683C\u5F0F(\u4F8B\u5982 11AA111110AAAA001A)\u3002";
var APPB2C_registration_results_eor_title4 = "\u91CD\u65B0\u8A55\u5206(\u8907\u67E5)";
var APPB2C_registration_results_eor_titlePayment4 = "\u652F\u4ED8\u91CD\u65B0\u8A55\u5206\u8CBB\u7528 (EOR)";
var APPB2C_registration_results_hasResults_bandScore_decrease4 = "\u6E1B\u5C11";
var APPB2C_registration_results_hasResults_bandScore_increase4 = "\u589E\u52A0";
var APPB2C_registration_results_hasResults_bandScore_unchanged4 = "\u8B8A";
var APPB2C_registration_results_hasResults_eorAgentInfo3 = "\u5982\u679C\u60A8\u5E0C\u671B\u6E2C\u9A57\u91CD\u65B0\u8A55\u5206, \u8ACB\u8207\u5354\u52A9\u60A8\u53C3\u52A0\u6E2C\u9A57\u7684\u4EE3\u7406\u5831\u540D\u4E2D\u5FC3\u806F\u7E6B\u3002";
var APPB2C_registration_results_hasResults_eorLetter4 = "\u8ACB\u67E5\u770B\u60A8\u7684 <0>EOR \u7D50\u679C\u7D50\u679C\u4FE1\u51FD<1></1></0>\u77AD\u89E3\u66F4\u591A\u8A73\u60C5\u3002";
var APPB2C_registration_results_hasResults_eorLine14 = "\u60A8\u7684\u91CD\u65B0\u8A55\u5206\u5DF2\u5B8C\u6210\u3002";
var APPB2C_registration_results_hasResults_eorLine24 = "\u60A8\u7684\u5206\u6578\u5728\u4E0B\u9762\u63D0\u4F9B\u3002\u91CD\u65B0\u6A19\u8A18\u7684\u90E8\u5206\u7A81\u51FA\u986F\u793A, \u4EE5\u4FBF\u65BC\u53C3\u8003\u3002";
var APPB2C_registration_results_hasResults_eorLink4 = "\u6211\u5E0C\u671B\u6211\u7684\u6E2C\u9A57\u88AB\u91CD\u65B0\u8A55\u5206";
var APPB2C_registration_results_hasResults_eTrfCta3 = "\u4E0B\u8F09\u60A8\u7684\u6210\u7E3E\u55AE";
var APPB2C_registration_results_hasResults_explanations_heading4 = "\u60A8\u7684\u5206\u6578\u89E3\u91CB: ";
var APPB2C_registration_results_hasResults_explanations_hideButton3 = "\u96B1\u85CF\u5206\u6578\u8AAA\u660E";
var APPB2C_registration_results_hasResults_explanations_improve4 = "\u5982\u4F55\u6539\u9032: ";
var APPB2C_registration_results_hasResults_explanations_showButton3 = "\u986F\u793A\u5206\u6578\u8AAA\u660E";
var APPB2C_registration_results_hasResults_lifeSkills_failed4 = "\u5931\u6557";
var APPB2C_registration_results_hasResults_lifeSkills_level4 = "\u4E2D\u6B50\u6CD5\u7D1A\u5225";
var APPB2C_registration_results_hasResults_lifeSkills_note4 = "\u60A8\u7684\u7DDA\u4E0A\u6210\u7E3E\u53EA\u662F\u81E8\u6642\u7684, \u4E0D\u61C9\u7528\u4F5C\u6210\u7E3E\u8868\u73FE\u7684\u5B98\u65B9\u8B49\u660E\u3002\u96C5\u601D\u8003\u8A66\u6210\u7E3E\u55AE (TRF) \u662F\u78BA\u8A8D\u60A8\u6210\u7E3E\u7684\u5B98\u65B9\u8B49\u660E\u3002";
var APPB2C_registration_results_hasResults_lifeSkills_passed4 = "\u901A\u904E";
var APPB2C_registration_results_hasResults_lifeSkills_result4 = "\u7D50\u679C";
var APPB2C_registration_results_hasResults_osr_bandScore_decrease3 = "\u6E1B\u5C11";
var APPB2C_registration_results_hasResults_osr_bandScore_increase3 = "\u589E\u52A0";
var APPB2C_registration_results_hasResults_osr_bandScore_unchanged3 = "\u4E0D\u8B8A";
var APPB2C_registration_results_hasResults_osr_previousResults4 = "\u5B8C\u6574\u67E5\u770B\u5148\u524D\u7684\u6E2C\u9A57\u7D50\u679C";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased4 = "\u60A8\u7684\u7E3D\u6210\u7E3E\u5DF2\u5F9E {{originalScore}} \u4E0B\u964D\u5230 {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased4 = "\u60A8\u7684\u7E3D\u6210\u7E3E\u5DF2\u5F9E {{originalScore}} \u589E\u52A0\u5230 {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged4 = "\u60A8\u7684\u7E3D\u6210\u7E3E\u4FDD\u6301\u4E0D\u8B8A, \u70BA {{originalScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_title4 = "\u60A8\u7684 {{osrSkillName}} \u91CD\u8003\u6210\u7E3E ({{score}}) \u5DF2\u8A08\u5165\u60A8\u7684\u65B0\u7E3D\u6210\u7E3E\u3002";
var APPB2C_registration_results_hasResults_osrLink3 = "\u6211\u60F3\u91CD\u65B0\u53C3\u52A0\u90E8\u5206\u8003\u8A66";
var APPB2C_registration_results_hasResults_posting_oneCopyWarning4 = "\u53EA\u767C\u9001\u4E00\u4EFD\u7D19\u672C, \u8ACB\u59A5\u5584\u4FDD\u7BA1\uFF01";
var APPB2C_registration_results_hasResults_posting_postedOn4 = "\u60A8\u7684\u6E2C\u8A66\u5831\u544A\u8868\u5DF2\u767C\u4F48\u5728: ";
var APPB2C_registration_results_hasResults_posting_postedTo4 = "\u5B83\u5DF2\u767C\u4F48\u5230: ";
var APPB2C_registration_results_hasResults_posting_willPostOn4 = "\u60A8\u7684\u6E2C\u8A66\u5831\u544A\u8868\u5C07\u767C\u4F48\u5728: ";
var APPB2C_registration_results_hasResults_posting_willPostTo4 = "\u5B83\u5C07\u88AB\u767C\u4F48\u5230: ";
var APPB2C_registration_results_hasResults_rankNameConsonant4 = "\u9019\u610F\u5473\u8457\u60A8\u662F";
var APPB2C_registration_results_hasResults_rankNameVowel4 = "\u9019\u610F\u5473\u8457\u60A8\u662F";
var APPB2C_registration_results_hasResults_scoreImprove_btn4 = "\u67E5\u770B\u6211\u7684\u9078\u9805";
var APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn4 = "\u8FD4\u56DE\u6E2C\u9A57\u7D50\u679C\u8A73\u60C5";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description4 = "\u5982\u679C\u60A8\u5C0D\u8003\u8A66\u591A\u500B\u90E8\u5206\u7684\u5206\u6578\u4E0D\u6EFF\u610F, \u53EF\u4EE5\u5FEB\u901F\u5B8C\u6210\u65B0\u8003\u8A66\u5831\u540D\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title4 = "\u5831\u540D\u65B0\u6E2C\u9A57";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description4 = "\u5982\u679C\u60A8\u5C0D\u8003\u8A66\u8A55\u5206\u4E0D\u6EFF\u610F, \u4E26\u4E14\u5E0C\u671B\u91CD\u65B0\u8A55\u5206\u8003\u8A66\u7684\u4EFB\u4F55\u90E8\u5206, \u60A8\u53EF\u4EE5\u5728\u8003\u8A66\u65E5\u671F\u5F8C {{count}} \u5929\u5167\u7533\u8ACB\u8907\u67E5\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title4 = "\u91CD\u65B0\u8A55\u5206\u60A8\u7684\u6E2C\u9A57";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description4 = "\u5982\u679C\u60A8\u5C0D\u8003\u8A66\u4EFB\u4F55\u4E00\u500B\u79D1\u76EE\u7684\u5206\u6578\u4E0D\u6EFF\u610F, \u4E26\u8A8D\u70BA\u9019\u662F\u7531\u65BC\u8003\u8A66\u7576\u5929\u8868\u73FE\u4E0D\u4F73, \u60A8\u53EF\u4EE5\u5831\u540D\u55AE\u79D1\u91CD\u8003\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title4 = "\u91CD\u65B0\u53C3\u8207\u90E8\u5206\u8003\u8A66";
var APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle4 = "\u5982\u679C\u60A8\u60F3\u63D0\u9AD8\u7E3D\u5206, \u6709\u4EE5\u4E0B\u9078\u64C7: ";
var APPB2C_registration_results_hasResults_scoreImprove_subtitle4 = "\u5982\u679C\u60A8\u6C92\u6709\u7372\u5F97\u671F\u671B\u7684\u5206\u6578, \u60A8\u53EF\u4EE5\u63D0\u5347\u60A8\u7684\u5206\u6578\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_title4 = "\u6C92\u6709\u5F97\u5230\u60A8\u9700\u8981\u7684\u5206\u6578\uFF1F";
var APPB2C_registration_results_hasResults_scores_label4 = "\u60A8\u7684\u6EFF\u5206\u548C\u89E3\u91CB";
var APPB2C_registration_results_hasResults_scores_Listening4 = "\u807D\u529B";
var APPB2C_registration_results_hasResults_scores_Overall4 = "\u60A8\u7684\u7E3D\u6210\u7E3E";
var APPB2C_registration_results_hasResults_scores_Reading4 = "\u95B1\u8B80";
var APPB2C_registration_results_hasResults_scores_Speaking4 = "\u53E3\u8AAA";
var APPB2C_registration_results_hasResults_scores_Writing4 = "\u5BEB\u4F5C";
var APPB2C_registration_results_hasResults_share_applyBoard4 = "\u76F4\u63A5\u61C9\u7528\u65BC\u7A0B\u5F0F";
var APPB2C_registration_results_hasResults_share_RecognisingOrgsLink4 = "\u8207\u6A5F\u69CB\u5206\u4EAB\u6210\u7E3E";
var APPB2C_registration_results_hasResults_share_subtitle4 = "\u4EE5\u4E0B\u662F\u60A8\u73FE\u5728\u53EF\u4EE5\u57F7\u884C\u7684\u64CD\u4F5C: ";
var APPB2C_registration_results_hasResults_share_title4 = "\u62FF\u5230\u4E86\u4F60\u9700\u8981\u7684\u5206\u6578\u55CE\uFF1F";
var APPB2C_registration_results_hasResults_share_trfCta4 = "\u4E0B\u8F09\u60A8\u7684\u6210\u7E3E\u55AE";
var APPB2C_registration_results_hasResults_viewExplanation4 = "\u67E5\u770B\u5B8C\u6574\u5206\u6578\u548C\u8AAA\u660E";
var APPB2C_registration_results_noResults_cmds_title4 = '\u8981\u67E5\u770B\u95DC\u65BC\u6210\u7E3E\u7B49\u8CC7\u8A0A(\u5305\u62EC\u4F55\u6642\u6838\u767C\u6210\u7E3E), \u8ACB\u6309\u5169\u4E0B<0>\u9019\u88E1</0>\u3002\u6211\u5011\u5C07\u5411\u60A8\u767C\u9001\u4E00\u500B\u9023\u7D50, \u9EDE\u5165\u8A72\u9023\u7D50\u5373\u53EF\u67E5\u8A62\u60A8\u7684\u6210\u7E3E\u4E26\u4E0B\u8F09\u96FB\u5B50\u8003\u8A66\u6210\u7E3E\u55AE "("TRF")"\u3002';
var APPB2C_registration_results_noResults_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_registration_results_noResults_osr_booked4 = "\u5831\u540D\u91CD\u8003";
var APPB2C_registration_results_noResults_osr_previousScores4 = "\u60A8\u4E4B\u524D\u7684\u5206\u6578: ";
var APPB2C_registration_results_noResults_title4 = "\u6B32\u67E5\u770B\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u8207\u5F97\u77E5\u6210\u7E3E\u4F55\u6642\u516C\u5E03, \u8ACB\u9EDE\u9078<0>here</0>\u3002\u7576\u6210\u7E3E\u7D50\u679C\u51FA\u4F86, \u60A8\u5C07\u6536\u5230\u53EF\u67E5\u770B\u76F8\u95DC\u8CC7\u8A0A\u7684\u9023\u7D50\u3002";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote14 = '\u60A8\u4ECD\u7136\u53EF\u4EE5\u9078\u64C7\u5176\u4ED6$t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} }) \u5C07\u767C\u9001\u6210\u7E3E\u3002';
var APPB2C_registration_results_organisations_availableReceivingOrgsNote24 = "\u7533\u8ACB\u958B\u653E\u81F3{{date}}\u3002";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote33 = "\u7D44\u7E54\u5728\u63A5\u53D7\u6210\u7E3E\u55AE\u65B9\u9762\u53EF\u80FD\u6709\u4E0D\u540C\u7684\u898F\u5247, \u6700\u77ED\u6709\u6548\u671F\u70BA\u5169\u5E74\u3002\u8ACB\u5728\u7533\u8ACB\u524D\u8AEE\u8A62\u60A8\u7684\u7D44\u7E54\u3002";
var APPB2C_registration_results_organisations_chosenOrganisationsHeader4 = "\u5C07\u7D50\u679C\u767C\u9001\u5230\u6240\u9078\u7684\u6A5F\u69CB";
var APPB2C_registration_results_organisations_editRo_addAnother4 = "+ \u65B0\u589E\u5176\u4ED6\u6A5F\u69CB";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew4 = "+ \u65B0\u589E\u6A5F\u69CB";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_address4 = "\u5730\u5740: ";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_country4 = "\u9078\u64C7\u4E00\u500B\u570B\u5BB6/\u5730\u5340";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll4 = "\u5168\u90E8";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional4 = "\u9078\u64C7\u570B\u5BB6/\u5730\u5340: (\u53EF\u9078)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_file4 = "\u9078\u64C7\u570B\u5BB6/\u5730\u5340: (\u975E\u5FC5\u9078)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_org4 = "\u9078\u64C7\u4E00\u500B\u6A5F\u69CB";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation4 = "\u6A5F\u69CB\u540D\u7A31";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_person4 = "\u4EBA\u54E1/\u90E8\u9580\u59D3\u540D: (\u53EF\u9078)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode4 = "\u90F5\u905E\u5340\u865F";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry4 = "\u4F9D\u570B\u5BB6/\u5730\u5340\u540D\u7A31\u641C\u7D22";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg4 = "\u4F9D\u6A5F\u69CB\u540D\u7A31\u641C\u7D22";
var APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength4 = "\u6A94/\u6848\u4F8B\u7DE8\u865F\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u500B\u5B57\u5143\u9577\u3002";
var APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength4 = "\u4EBA\u54E1/\u90E8\u9580\u540D\u7A31\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u500B\u5B57\u5143\u9577\u3002";
var APPB2C_registration_results_organisations_editRo_error_minScoreMissing4 = "\u8ACB\u70BA\u60A8\u7684\u6574\u9AD4\u6A02\u968A\u9078\u64C7\u6700\u4F4E\u5206\u6578\u3002";
var APPB2C_registration_results_organisations_editRo_error_missingAddressLine14 = "\u8ACB\u63D0\u4F9B\u7D44\u7E54\u4F4D\u5740\u3002";
var APPB2C_registration_results_organisations_editRo_error_missingOrgName4 = "\u8ACB\u63D0\u4F9B\u7D44\u7E54\u540D\u7A31\u3002";
var APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength4 = "\u7D44\u7E54\u540D\u7A31\u7684\u9577\u5EA6\u6700\u591A\u70BA {{count}} \u500B\u5B57\u5143\u3002";
var APPB2C_registration_results_organisations_editRo_iol_addCta4 = "\u65B0\u589E\u7D44\u7E54";
var APPB2C_registration_results_organisations_editRo_iol_address4 = "\u5730\u5740: ";
var APPB2C_registration_results_organisations_editRo_iol_cancelAdd3 = "\u53D6\u6D88";
var APPB2C_registration_results_organisations_editRo_iol_cancelEdit4 = "\u653E\u68C4\u8B8A\u66F4";
var APPB2C_registration_results_organisations_editRo_iol_conditions_clear4 = "\u6E05\u695A";
var APPB2C_registration_results_organisations_editRo_iol_conditions_header4 = "\u60A8\u60F3\u70BA\u9019\u500B\u6A5F\u69CB\u9078\u64C7\u689D\u4EF6\u5206\u6578\u55CE\uFF1F";
var APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder4 = "\u8ACB\u9078\u64C7";
var APPB2C_registration_results_organisations_editRo_iol_conditions_no4 = "\u4E0D";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note14 = "\u8ACB\u5728\u8A72\u6A5F\u69CB\u6536\u5230\u60A8\u7684\u7D50\u679C\u4E4B\u524D\u8A2D\u5B9A\u9808\u9054\u5230\u7684\u7E3D\u6210\u7E3E\u9580\u6ABB\u3002";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note24 = "\u60A8\u9084\u53EF\u4EE5\u70BA\u6BCF\u500B\u6E2C\u9A57\u9805\u76EE\u9078\u64C7\u6700\u4F4E\u5206\u6578: ";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note34 = "\u53EA\u6709\u9054\u5230\u60A8\u8A2D\u5B9A\u7684\u6BCF\u500B\u5206\u6578\u6642, \u6211\u5011\u624D\u6703\u5C07\u60A8\u7684\u7D50\u679C\u767C\u9001\u7D66\u8A72\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder4 = "\u975E\u5FC5\u9078";
var APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader4 = "\u53EA\u6709\u7576\u60A8\u7372\u5F97\u6240\u9700\u7684\u5206\u6578\u6642, \u6211\u5011\u624D\u6703\u5C07\u60A8\u7684\u7D50\u679C\u767C\u9001\u7D66\u4ED6\u5011\u3002";
var APPB2C_registration_results_organisations_editRo_iol_conditions_yes4 = "\u662F\u7684";
var APPB2C_registration_results_organisations_editRo_iol_copied4 = "\u96FB\u5B50\u90F5\u4EF6\u5DF2\u8907\u88FD";
var APPB2C_registration_results_organisations_editRo_iol_copy4 = "\u8907\u88FD\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery4 = "\u8A72\u7D44\u7E54\u76F4\u63A5\u5F9E\u6211\u5011\u9019\u88E1\u63A5\u53D7\u60A8\u7684\u96FB\u5B50\u6210\u7E3E\u55AE\u3002\u5B83\u4E0D\u6703\u5F71\u97FF\u60A8\u53EF\u4EE5\u9078\u64C7\u591A\u5C11\u500B\u7D44\u7E54\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults4 = "\u96FB\u5B50\u6210\u7E3E\u55AE";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note4 = "\u60A8\u53EF\u4EE5\u5C07\u60A8\u7684 eTRF \u767C\u9001\u7D66\u4EFB\u610F\u6578\u91CF\u7684\u7D44\u7E54, \u4F46\u60A8\u6700\u591A\u53EA\u80FD\u9078\u64C7 {{number}} \u500B TRF \u7D44\u7E54\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal4 = "\u6211\u5011\u5C07\u5411\u8A72\u6A5F\u69CB\u767C\u9001\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE(TRF)\u3002<br>\u5B83\u8A08\u5165\u60A8 {{number}} \u500B TRF \u7D44\u7E54\u7684\u9650\u5236\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf4 = "\u6210\u7E3E\u55AE";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note14 = "\u8A72\u7D44\u7E54\u63A5\u53D7\u96C5\u601D\u5728\u7DDA\u8003\u8A66, \u4F46\u672A\u8A3B\u518A\u76F4\u63A5\u5F9E\u6211\u5011\u9019\u88E1\u63A5\u6536\u96FB\u5B50\u6210\u7E3E\u55AE\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note24 = "\u6536\u5230\u6210\u7E3E\u55AE\u4E4B\u5F8C, \u60A8\u9700\u8981\u5C07\u6210\u7E3E\u55AE\u526F\u672C\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u767C\u9001\u5230\u8A72\u55AE\u4F4D\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results4 = "\u60A8\u9700\u8981\u5C07\u6210\u7E3E\u55AE\u526F\u672C\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u767C\u9001\u5230\u8A72\u55AE\u4F4D\u3002";
var APPB2C_registration_results_organisations_editRo_iol_newOrgTitle4 = "\u65B0\u589E\u6A5F\u69CB";
var APPB2C_registration_results_organisations_editRo_iol_noResults4 = "\u66AB\u7121\u7D50\u679C";
var APPB2C_registration_results_organisations_editRo_iol_removeLink4 = "\u79FB\u9664\u7D44\u7E54";
var APPB2C_registration_results_organisations_editRo_iol_saveCta4 = "\u5132\u5B58\u8B8A\u66F4";
var APPB2C_registration_results_organisations_editRo_iol_validation_overallScore4 = "\u60A8\u7684\u7E3D\u6210\u7E3E\u6700\u4F4E\u5206\u6578\u662F\u5FC5\u9700\u7684\u3002";
var APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation4 = "\u8ACB\u9078\u64C7\u4E00\u500B\u7D44\u7E54\u3002";
var APPB2C_registration_results_organisations_iol_chooseCta4 = "\u9078\u64C7\u7D44\u7E54";
var APPB2C_registration_results_organisations_iol_delivery_auto_header4 = "\u4EE5\u4E0B\u662F\u60A8\u9078\u64C7\u7684\u5C07\u81EA\u52D5\u6536\u5230\u60A8\u7684eTRF\u7684\u7D44\u7E54: ";
var APPB2C_registration_results_organisations_iol_delivery_auto_note4 = "\u6211\u5011\u5C07\u70BA\u60A8\u5411\u9019\u4E9B\u7D44\u7E54\u767C\u9001\u60A8\u7684\u96FB\u5B50\u6210\u7E3E\u55AE\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote4 = "\u6211\u5011\u5C1A\u672A\u5411\u9019\u4E9B\u7D44\u7E54\u767C\u9001\u60A8\u7684 eTRF, \u56E0\u70BA\u60A8\u6C92\u6709\u9054\u5230\u60A8\u70BA\u6BCF\u500B\u7D44\u7E54\u8A2D\u7F6E\u7684\u6700\u4F4E\u7E3D\u7B49\u7D1A\u3002";
var APPB2C_registration_results_organisations_iol_delivery_auto_sentNote4 = "\u6211\u5011\u5DF2\u5C07\u60A8\u7684eTRF\u767C\u9001\u7D66\u9019\u4E9B\u7D44\u7E54\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_iol_delivery_postal_header4 = "\u60A8\u9078\u64C7\u7684\u55AE\u4F4D\u4E2D\u9700\u8981\u4EBA\u5DE5\u905E\u9001\u7684\u5982\u4E0B: ";
var APPB2C_registration_results_organisations_iol_delivery_postal_note14 = "\u9019\u4E9B\u7D44\u7E54\u63A5\u53D7\u96C5\u601D\u5728\u7DDA\u8003\u8A66, \u4F46\u672A\u8A3B\u518A\u76F4\u63A5\u5F9E\u6211\u5011\u9019\u88E1\u63A5\u6536\u96FB\u5B50\u6210\u7E3E\u55AE\u3002";
var APPB2C_registration_results_organisations_iol_delivery_postal_note24 = "\u6536\u5230\u6210\u7E3E\u55AE\u5F8C, \u60A8\u9700\u8981\u5C07\u6210\u7E3E\u55AE\u526F\u672C\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u767C\u9001\u7D66\u6BCF\u500B\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2_results4 = "\u60A8\u9700\u8981\u5C07\u6210\u7E3E\u55AE\u526F\u672C\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u767C\u9001\u7D66\u6BCF\u500B\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_iol_edit4 = "\u6539\u8B8A";
var APPB2C_registration_results_organisations_iol_error4 = "\u8F09\u5165\u8B8A\u66F4\u6642\u767C\u751F\u932F\u8AA4\u3002<0></0>\u8ACB\u5617\u8A66<1>\u91CD\u65B0\u6574\u7406</1>\u9801\u9762\u3002";
var APPB2C_registration_results_organisations_iol_generalCondition4 = "\u53EA\u6709\u7576\u60A8\u9054\u5230\u7E3D\u6210\u7E3E\u9580\u6ABB\u6642, \u6211\u5011\u624D\u6703\u5411\u8A72\u6A5F\u69CB\u767C\u9001\u60A8\u7684\u7D50\u679C: ";
var APPB2C_registration_results_organisations_iol_noConditions4 = "\u60A8\u6C92\u6709\u70BA\u6B64\u7D44\u7E54\u8A2D\u5B9A\u4EFB\u4F55\u689D\u4EF6\u5206\u6578\u3002";
var APPB2C_registration_results_organisations_iol_notChosen4 = "\u672A\u9078\u64C7";
var APPB2C_registration_results_organisations_iol_preciseCondition4 = "\u53EA\u6709\u7576\u9054\u5230\u60A8\u70BA\u6BCF\u500B\u9805\u76EE\u8A2D\u5B9A\u7684\u5206\u6578\u6642, \u6211\u5011\u624D\u6703\u5C07\u60A8\u7684\u7D50\u679C\u767C\u9001\u7D66\u8A72\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_iol_roAccept_link4 = "\u8ACB\u67E5\u770B\u54EA\u4E9B\u55AE\u4F4D\u63A5\u53D7\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\u6210\u7E3E";
var APPB2C_registration_results_organisations_iol_roAccept_message4 = "\u5982\u679C\u60A8\u53C3\u52A0\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57, \u90A3\u5C07\u9700\u8981\u78BA\u8A8D\u60A8\u7684\u7D44\u7E54\u662F\u5426\u63A5\u53D7\u9019\u4E00\u9EDE\u3002";
var APPB2C_registration_results_organisations_iol_roAccept_title4 = "\u60A8\u7684\u76EE\u6A19\u7D44\u7E54\u6216\u6A5F\u69CB\u662F\u5426\u63A5\u53D7\u96C5\u601D\u7DDA\u4E0A\u6E2C\u9A57\uFF1F";
var APPB2C_registration_results_organisations_iol_sendAnyway4 = "\u7121\u8AD6\u7D50\u679C\u5982\u4F55\u90FD\u767C\u9001\u7D66\u8A72\u6A5F\u69CB";
var APPB2C_registration_results_organisations_list_item14 = "\u5B78\u8853\u6A5F\u69CB";
var APPB2C_registration_results_organisations_list_item24 = "\u653F\u5E9C\u6A5F\u69CB";
var APPB2C_registration_results_organisations_list_item34 = "\u5C08\u696D\u5718\u9AD4";
var APPB2C_registration_results_organisations_list_item44 = "\u96C7\u4E3B";
var APPB2C_registration_results_organisations_mod_delivery_auto_header4 = "\u4EE5\u4E0B\u662F\u60A8\u9078\u64C7\u7684\u5C07\u81EA\u52D5\u6536\u5230\u60A8\u7684eTRF\u7684\u7D44\u7E54: ";
var APPB2C_registration_results_organisations_mod_delivery_auto_note4 = "\u6211\u5011\u5C07\u70BA\u60A8\u5411\u9019\u4E9B\u7D44\u7E54\u767C\u9001\u60A8\u7684\u96FB\u5B50\u6210\u7E3E\u55AE\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002\u60A8\u53EF\u4EE5\u6839\u64DA\u9700\u8981\u9078\u64C7\u4EFB\u610F\u6578\u91CF\u7684\u9019\u4E9B\u3002";
var APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote4 = "\u6211\u5011\u5C1A\u672A\u5411\u9019\u4E9B\u7D44\u7E54\u767C\u9001\u60A8\u7684 eTRF, \u56E0\u70BA\u60A8\u6C92\u6709\u9054\u5230\u60A8\u70BA\u6BCF\u500B\u7D44\u7E54\u8A2D\u7F6E\u7684\u6700\u4F4E\u7E3D\u7B49\u7D1A\u3002";
var APPB2C_registration_results_organisations_mod_delivery_auto_sentNote4 = "\u6211\u5011\u5DF2\u5C07\u60A8\u7684eTRF\u767C\u9001\u7D66\u9019\u4E9B\u7D44\u7E54\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_header4 = "\u4EE5\u4E0B\u662F\u60A8\u9078\u64C7\u7684\u6A5F\u69CB, \u6211\u5011\u6703\u5C07\u60A8\u7684\u6210\u7E3E\u55AE\u90F5\u5BC4\u5230: ";
var APPB2C_registration_results_organisations_mod_delivery_postal_note14 = "\u6211\u5011\u6703\u5C07\u60A8\u7684\u8003\u8A66\u6210\u7E3E\u55AE(TRF) \u90F5\u5BC4\u7D66\u9019\u4E9B\u6A5F\u69CB, \u60A8\u6700\u591A\u53EA\u80FD\u9078\u64C7\u5176\u4E2D\u7684 {{count}}\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_note24 = "\u5982\u679C\u60A8\u5728\u6211\u5011\u7684\u6E05\u55AE\u4E2D\u627E\u4E0D\u5230\u67D0\u9593\u6A5F\u69CB, \u53EF\u4EE5\u81EA\u884C\u65B0\u589E, \u4ED6\u5011\u5C07\u88AB\u8A08\u5165\u60A8\u7684\u6210\u7E3E\u55AE\u9078\u64C7\u4E4B\u4E00\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_note34 = '\u60A8\u4ECD\u7136\u53EF\u4EE5\u9078\u64C7\u5C07\u7D50\u679C\u767C\u9001\u5230\u5176\u4ED6 $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} })\u3002';
var APPB2C_registration_results_organisations_mod_delivery_postal_note44 = '\u65E2\u7136\u60A8\u5DF2\u7D93\u9078\u64C7\u4E86 $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} }), \u60A8\u5C31\u4E0D\u80FD\u518D\u9078\u64C7\u4E86\u3002\u60A8\u4ECD\u7136\u53EF\u4EE5\u9078\u64C7\u63A5\u53D7 eTRF \u7684\u7D44\u7E54\u3002';
var APPB2C_registration_results_organisations_mod_delivery_postal_note54 = "\u60A8\u5DF2\u9054\u5230\u53EF\u767C\u9001\u6210\u7E3E\u7684\u6A5F\u69CB\u6578\u91CF\u9650\u5236\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote4 = "\u6211\u5011\u6C92\u6709\u767C\u9001\u60A8\u7684\u6210\u7E3E\u55AE\u7D66\u9019\u4E9B\u6A5F\u69CB, \u56E0\u70BA\u5C1A\u672A\u9054\u5230\u70BA\u6BCF\u500B\u6A5F\u69CB\u8A2D\u7F6E\u7684\u7E3D\u6210\u7E3E\u9580\u6ABB\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_sentNote4 = "\u6211\u5011\u5DF2\u767C\u9001\u60A8\u7684\u6210\u7E3E\u55AE\u81F3\u9019\u4E9B\u6A5F\u69CB\u3002\u4F60\u4E0D\u9700\u8981\u984D\u5916\u4F5C\u696D\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount4 = "{{count}} \u6210\u7E3E\u55AE\u6A5F\u69CB";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other4 = "{{count}} \u6210\u7E3E\u55AE\u6A5F\u69CB";
var APPB2C_registration_results_organisations_mod_howTo_item14 = "<bold>eTRF</bold>: \u76F4\u63A5\u5F9E\u6211\u5011\u9019\u88E1\u63A5\u53D7\u60A8\u7684\u96FB\u5B50\u6E2C\u8A66\u5831\u544A\u8868(eTRF)\u7684\u7D44\u7E54\u3002\u60A8\u53EF\u4EE5\u6839\u64DA\u9700\u8981\u9078\u64C7\u4EFB\u610F\u6578\u91CF\u7684\u9019\u4E9B\u3002";
var APPB2C_registration_results_organisations_mod_howTo_item24 = "TRF: \u6211\u5011\u4EE3\u8868\u60A8\u5411\u5176\u767C\u9001\u8003\u8A66\u6210\u7E3E\u55AE (<bold>TRF</bold>) \u7684\u7D44\u7E54\u3002\u60A8\u6700\u591A\u53EA\u80FD\u9078\u64C7\u5176\u4E2D\u7684 {<bold>{{number}}</bold>\u3002";
var APPB2C_registration_results_organisations_mod_howTo_line14 = "\u60A8\u53EF\u4EE5\u5F9E\u5169\u7A2E\u985E\u578B\u7684\u6A5F\u69CB\u4E2D\u9032\u884C\u9078\u64C7";
var APPB2C_registration_results_organisations_mod_howTo_title4 = "\u5982\u4F55\u9078\u64C7\u60A8\u7684\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote4 = "\u6C92\u6709\u9078\u64C7\u4EFB\u4F55\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_note04 = "\u6211\u5011\u53EF\u4EE5\u4EE3\u8868\u60A8\u5C07\u6210\u7E3E\u767C\u9001\u7D66\u6A5F\u69CB\u3002";
var APPB2C_registration_results_organisations_note14 = '\u6211\u5011\u53EF\u4EE5\u5C07\u60A8\u7684\u6210\u7E3E\u767C\u9001\u6700\u591A\u5230$t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })\u3002';
var APPB2C_registration_results_organisations_note24 = "\u9019\u4E9B\u53EF\u80FD\u5305\u62EC: ";
var APPB2C_registration_results_organisations_organisationsCount4 = "{{count}} \u6A5F\u69CB";
var APPB2C_registration_results_organisations_organisationsCount_other4 = "{{count}} \u6A5F\u69CB";
var APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote4 = "\u60A8\u5DF2\u9054\u5230\u984D\u5916\u6210\u7E3E\u55AE\u7533\u8ACB\u7684\u6578\u91CF\u4E0A\u9650\u548C/\u6216\u7533\u8ACB\u622A\u6B62\u65E5\u671F\u5DF2\u904E\u3002";
var APPB2C_registration_results_organisations_title4 = "\u8207\u6A5F\u69CB\u5206\u4EAB\u60A8\u7684\u7D50\u679C";
var APPB2C_registration_results_osr_chooseDate_accessibility4 = "\u6211\u5C0D\u8003\u8A66\u65E5\u671F\u6709<bold>\u7279\u6B8A\u5354\u52A9</bold>\u7684\u9700\u6C42";
var APPB2C_registration_results_osr_chooseDate_accessibilityLink4 = "\u4E86\u89E3\u6211\u5011\u5982\u4F55\u5354\u52A9\u60A8";
var APPB2C_registration_results_osr_chooseDate_chooseDateTitle4 = "\u9078\u64C7\u60A8\u7684 {{skillName}} \u91CD\u8003\u65E5\u671F";
var APPB2C_registration_results_osr_chooseDate_note_content4 = "\u6211\u5011\u53EA\u80FD\u5728\u60A8\u539F\u59CB\u8003\u8A66\u65E5\u671F({{date}})\u5F8C 60 \u5929\u5167\u63D0\u4F9B\u91CD\u8003\u3002";
var APPB2C_registration_results_osr_chooseDate_note_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_registration_results_osr_chooseDate_retakeListening4 = "\u807D\u529B\u91CD\u8003";
var APPB2C_registration_results_osr_chooseDate_retakeReading4 = "\u95B1\u8B80\u91CD\u8003";
var APPB2C_registration_results_osr_chooseDate_retakeSpeaking4 = "\u53E3\u8AAA\u91CD\u8003";
var APPB2C_registration_results_osr_chooseDate_retakeWriting4 = "\u5BEB\u4F5C\u91CD\u8003";
var APPB2C_registration_results_osr_chooseSkill_backBtn4 = "\u8FD4\u56DE\u7D50\u679C";
var APPB2C_registration_results_osr_chooseSkill_chooseDateBtn4 = "\u9078\u64C7\u55AE\u79D1\u9805\u76EE\u91CD\u8003\u7684\u65E5\u671F";
var APPB2C_registration_results_osr_chooseSkill_confirmationRequired4 = "\u8ACB\u78BA\u8A8D\u60A8\u7684\u76EE\u6A19\u6A5F\u69CB\u662F\u5426\u63A5\u53D7\u96C5\u601D\u91CD\u8003\u3002";
var APPB2C_registration_results_osr_chooseSkill_formTitle4 = "\u8ACB\u9078\u64C7\u8981\u91CD\u8003\u7684\u79D1\u76EE";
var APPB2C_registration_results_osr_chooseSkill_modal_contact4 = "\u8ACB\u900F\u904E\u4E0B\u65B9\u8CC7\u8A0A\u76F4\u63A5\u8207\u6211\u5011\u806F\u7E6B, \u4EE5\u4E86\u89E3\u6211\u5011\u5982\u4F55\u70BA\u60A8\u63D0\u4F9B\u5354\u52A9\u3002";
var APPB2C_registration_results_osr_chooseSkill_modal_description4 = "\u6211\u5011\u6C92\u6709\u4EFB\u4F55\u65E5\u671F\u53EF\u4F9B\u60A8\u5831\u540D\u6240\u9078\u7684\u55AE\u79D1\u91CD\u8003\u3002";
var APPB2C_registration_results_osr_chooseSkill_modal_email4 = "\u96FB\u5B50\u90F5\u4EF6";
var APPB2C_registration_results_osr_chooseSkill_modal_phone4 = "\u96FB\u8A71";
var APPB2C_registration_results_osr_chooseSkill_modal_title4 = "\u5C0D\u4E0D\u8D77, \u6211\u5011\u6C92\u6709\u4EFB\u4F55\u53EF\u8003\u8A66\u7684\u65E5\u671F";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation4 = "\u6211\u78BA\u8A8D\u6211\u9078\u64C7\u7684\u6A5F\u69CB\u63A5\u53D7\u96C5\u601D\u55AE\u79D1\u91CD\u8003\u3002";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description4 = "\u5728\u60A8\u5411\u6211\u5011\u9810\u8A02\u8003\u8A66\u4E4B\u524D, \u60A8\u61C9\u8A72<0>\u6AA2\u67E5\u60A8\u9078\u64C7\u7684\u6A5F\u69CB\u662F\u5426\u63A5\u53D7\u76F8\u95DC\u7684\u96C5\u601D\u8003\u8A66\u7248\u672C.</0>";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title4 = "\u60A8\u60F3\u7533\u8ACB\u7684\u6A5F\u69CB\u63A5\u53D7\u96C5\u601D\u55AE\u79D1\u91CD\u8003\u55CE\uFF1F";
var APPB2C_registration_results_osr_chooseSkill_skillRequired4 = "\u8ACB\u9078\u64C7\u60A8\u5E0C\u671B\u91CD\u8003\u7684\u6280\u80FD";
var APPB2C_registration_results_osr_chooseTest_availableDates4 = "\u53EF\u9078\u64C7\u7684\u65E5\u671F";
var APPB2C_registration_results_osr_chooseTest_speaking_time4 = "\u66F4\u6539\u6642\u9593";
var APPB2C_registration_results_osr_error_missingFile4 = "\u4E0A\u50B3\u5230\u539F\u59CB\u8A3B\u518A\u7684\u6A94\u5B58\u5728\u554F\u984C\u3002\u8ACB\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3\u4EE5\u7372\u5F97\u9032\u4E00\u6B65\u7684\u8AAC\u660E\u3002";
var APPB2C_registration_results_osr_error_noTestDates4 = "\u6B64\u4F4D\u7F6E\u4E0D\u518D\u63D0\u4F9B\u6E2C\u8A66\u65E5\u671F\u3002\u8ACB\u9078\u64C7\u5176\u4ED6\u8003\u8A66\u6216\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3\u3002";
var APPB2C_registration_results_osr_error_noTests4 = "\u60A8\u9078\u64C7\u7684\u6642\u9593\u6BB5\u548C\u5730\u9EDE\u4E2D\u4E0D\u518D\u6709\u53EF\u7528\u7684\u8003\u8A66\u65E5\u671F\u3002\u8ACB<0>\u8FD4\u56DE</0>\u4E26\u66F4\u6539\u60A8\u7684\u641C\u7D22\u53C3\u6578\u3002";
var APPB2C_registration_results_osr_line14 = "\u5982\u679C\u60A8\u9700\u8981\u7B2C\u4E8C\u6B21\u6A5F\u6703\u4F86\u63D0\u9AD8\u96C5\u601D\u6210\u7E3E, \u60A8\u53EF\u4EE5\u91CD\u65B0\u6E2C\u9A57\u4E0D\u6EFF\u610F\u7684\u9805\u76EE\u3002";
var APPB2C_registration_results_osr_line24 = "\u9019\u610F\u5473\u8457\u60A8\u6240\u6709\u7684\u52AA\u529B\u6E96\u5099\u90FD\u4E0D\u6703\u767D\u8CBB\u3002";
var APPB2C_registration_results_osr_line34 = "\u60A8\u60F3\u91CD\u8003\u8003\u8A66\u6210\u7E3E\u7684\u54EA\u4E00\u90E8\u5206(\u5982\u4E0A\u6240\u793A), \u4EE5\u53CA\u4F55\u6642\u91CD\u8003\uFF1F";
var APPB2C_registration_results_osr_line44 = "\u6211\u5011\u7684\u76EE\u6A19\u662F\u572848-72\u5C0F\u6642\u5167\u900F\u904E\u96FB\u5B50\u90F5\u4EF6\u56DE\u8986\u6240\u6709\u8ACB\u6C42\u3002";
var APPB2C_registration_results_osr_line54 = "\u82F1\u570B\u6587\u5316\u6559\u80B2\u5354\u6703\u5C07\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u8CC7\u8A0A\u4F86\u8655\u7406\u60A8\u7684\u8ACB\u6C42\u3002\u6709\u95DC\u8A73\u7D30\u8CC7\u8A0A, \u8ACB\u53C3\u95B1\u6211\u5011\u7DB2\u7AD9\u7684 <0>privacy \u90E8\u5206</0>";
var APPB2C_registration_results_osr_review_note4 = "\u8ACB\u6CE8\u610F, \u4EE5\u4E0B\u7684\u500B\u4EBA\u8CC7\u8A0A\u662F\u53D6\u81EA\u65BC\u60A8\u7684\u96C5\u601D\u8003\u8A66\u539F\u59CB\u5831\u540D\u8A18\u9304, \u9019\u4E9B\u7121\u6CD5\u65BC\u60A8\u7684\u91CD\u65B0\u6E2C\u9A57\u6642\u4FEE\u6539\u3002";
var APPB2C_registration_results_osr_review_note_testDay4 = "\u5728\u8003\u8A66\u7576\u5929, \u60A8\u5C07\u88AB\u8981\u6C42\u651C\u5E36\u60A8\u7528\u65BC\u8A3B\u518A\u7684\u76F8\u540C\u8EAB\u4EFD\u8B49\u660E\u6A94\u3002\u5982\u679C\u60A8\u6C92\u6709\u96A8\u8EAB\u651C\u5E36\u539F\u59CB\u8003\u8A66ID, \u8ACB\u806F\u7E6B\u60A8\u7684\u8003\u8A66\u4E2D\u5FC3\u3002";
var APPB2C_registration_results_osr_submitBtn4 = "\u63D0\u4EA4\u91CD\u8003\u7533\u8ACB";
var APPB2C_registration_results_osr_successBanner4 = "\u6211\u5011\u5DF2\u6536\u5230\u60A8\u7684\u96C5\u601D\u6280\u80FD\u985E\u8003\u8A66\u91CD\u8003\u7533\u8ACB\u3002";
var APPB2C_registration_results_osr_title4 = "\u91CD\u8003\u8003\u8A66\u7684\u4E00\u90E8\u5206";
var APPB2C_registration_results_ttAbsent_btn4 = "\u4F7F\u7528\u4E0D\u540C\u65E5\u671F\u91CD\u65B0\u9810\u8A02\u6B64\u6E2C\u8A66";
var APPB2C_registration_results_ttAbsent_msg4 = "\u7531\u65BC\u8003\u751F\u5728\u8003\u8A66\u65E5\u671F\u7F3A\u5E2D, \u56E0\u6B64\u7121\u6CD5\u63D0\u4F9B\u6B64\u6E2C\u8A66\u7684\u7D50\u679C\u3002";
var APPB2C_registration_results_updateBar_osr_booked4 = "\u60A8\u5DF2\u5831\u540D\u91CD\u65B0\u53C3\u52A0 {{osrSkillName}} \u8003\u8A66\u3002<1>\u67E5\u770B\u6B64\u5831\u540D</1>";
var APPB2C_registration_results_updateBar_osr_marked4 = "\u60A8\u7684 {{osrSkillName}} \u6E2C\u9A57\u91CD\u8003\u5DF2\u88AB\u8A55\u5206\u3002<1>\u67E5\u770B\u7D50\u679C</1>";
var APPB2C_registration_tabs_bookingDetails4 = "\u5831\u540D\u8A73\u60C5";
var APPB2C_registration_tabs_candidate4 = "\u61C9\u8A66\u8005";
var APPB2C_registration_tabs_date4 = "\u65E5\u671F";
var APPB2C_registration_tabs_dateLocation4 = "\u65E5\u671F\u548C\u5730\u9EDE";
var APPB2C_registration_tabs_payments4 = "\u4ED8\u6B3E";
var APPB2C_registration_tabs_recognisingOrgs4 = "\u8A8D\u53EF\u7D44\u7E54";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_14 = "\u8ACB\u76F4\u63A5\u806F\u7E6B\u8003\u8A66\u4E2D\u5FC3, \u5C07\u60A8\u7684\u6210\u7E3E\u767C\u9001\u7D66\u8A8D\u53EF\u6A5F\u69CB: ";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_24 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u5831\u540D\u5E8F\u865F, \u4EE5\u4FBF\u6211\u5011\u66F4\u597D\u5730\u5354\u52A9\u60A8: ";
var APPB2C_registration_tabs_results4 = "\u6210\u7E3E";
var APPB2C_registration_testTaker_bookedFor_completed4 = "\u8A72\u6E2C\u8A66\u7531\u4EE5\u4E0B\u4EBA\u54E1\u9032\u884C: ";
var APPB2C_registration_testTaker_bookedFor_dob4 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_registration_testTaker_bookedFor_email4 = "\u96FB\u5B50\u90F5\u4EF6\u4F4D\u5740";
var APPB2C_registration_testTaker_bookedFor_gender4 = "\u6027\u5225";
var APPB2C_registration_testTaker_bookedFor_name4 = "\u540D\u5B57";
var APPB2C_registration_testTaker_bookedFor_parent4 = "\u5BB6\u9577/\u76E3\u8B77\u4EBA: ";
var APPB2C_registration_testTaker_bookedFor_title4 = "\u6B64\u6E2C\u8A66\u9069\u7528\u65BC: ";
var APPB2C_registration_testTaker_childConsent_back4 = "\u6211\u4E0D\u60F3\u7DE8\u8F2F\u5152\u7AE5\u540C\u610F\u66F8";
var APPB2C_registration_testTaker_childConsent_context4 = "\u60A8\u662F\u5426\u5141\u8A31\u60A8\u7684\u5B69\u5B50\u5728\u8003\u8A66\u79D1\u76EE\u4E4B\u9593\u7684\u4F11\u606F\u6642\u9593\u548C\u8003\u8A66\u7D50\u675F\u6642\u7121\u4EBA\u966A\u4F34\u96E2\u958B\u8003\u5834\uFF1F";
var APPB2C_registration_testTaker_childConsent_cta4 = "\u4FDD\u5B58\u540C\u610F\u66F8\u8A73\u60C5";
var APPB2C_registration_testTaker_childConsent_options_14 = "\u662F\u7684\uFF1B\u6211\u7D66\u4E88\u8A31\u53EF";
var APPB2C_registration_testTaker_childConsent_options_24 = "\u4E0D;\u6211\u6703\u63A5\u4ED6\u5011";
var APPB2C_registration_testTaker_childConsent_options_34 = "\u4E0D;\u6211\u60F3\u6307\u5B9A\u5176\u4ED6\u4EBA\u4F86\u63A5\u4ED6\u5011";
var APPB2C_registration_testTaker_childConsent_somebodyElse_add4 = "+ \u65B0\u589E\u5176\u4ED6\u4EBA";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox4 = "\u662F\u7684, \u6211\u78BA\u5B9A";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_14 = "\u60A8\u662F\u5426\u78BA\u8A8D\u6240\u5217\u4EBA\u54E1\u6709\u6B0A\u6536\u96C6";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_24 = "\u5728\u8003\u8A66\u7D50\u675F\u6642, \u4ED6\u5011\u5DF2\u7D93\u8D85\u904E14\u6B72\u4E86\uFF1F";
var APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName4 = "\u4ED6\u5011\u6C92\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_registration_testTaker_childConsent_somebodyElse_note4 = "\u8ACB\u63D0\u4F9B\u63A5\u9001\u5B69\u5B50\u4EBA\u54E1\u7684\u8A73\u7D30\u8CC7\u8A0A\u3002\u5982\u679C\u7B46\u8A66\u548C\u53E3\u8A66\u6709\u4E0D\u540C\u7684\u4EBA\u63A5\u5B69\u5B50, \u8ACB\u5728\u4E0B\u65B9\u8A3B\u660E\u4ED6\u5011\u7684\u540D\u5B57\u3002";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phone4 = "\u96FB\u8A71\u865F\u78BC";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote4 = "\u5728\u7DCA\u6025\u60C5\u6CC1\u4E0B";
var APPB2C_registration_testTaker_childConsent_somebodyElse_policy4 = "\u95B1\u8B80\u6211\u5011\u7684\u5152\u7AE5\u5B89\u5168\u6536\u96C6\u4FDD\u8B77\u5B8C\u6574\u653F\u7B56";
var APPB2C_registration_testTaker_childConsent_somebodyElse_relationship4 = "\u8207\u5B69\u5B50\u7684\u95DC\u4FC2";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_14 = "\u95DC\u65BC\u63A5\u5B69\u5B50\u7684\u4EBA";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_24 = "\u95DC\u65BC\u7B2C\u4E8C\u4F4D\u4EBA\u58EB";
var APPB2C_registration_testTaker_childConsent_title4 = "\u5152\u7AE5\u540C\u610F\u66F8";
var APPB2C_registration_testTaker_childConsent_warning4 = "11\u6B72\u6216\u4EE5\u4E0A\u7684\u5152\u7AE5\u53EA\u6709\u5728\u7236\u6BCD/\u7167\u9867\u8005\u540C\u610F\u5F8C\u65B9\u53EF\u55AE\u7368\u96E2\u958B\u8003\u8A66\u5834\u6240";
var APPB2C_registration_testTaker_consent_edit4 = "\u7DE8\u8F2F\u540C\u610F";
var APPB2C_registration_testTaker_consent_missing4 = "\u8ACB\u63D0\u4F9B\u5152\u7AE5\u540C\u610F\u8CC7\u8A0A\u3002";
var APPB2C_registration_testTaker_consent_title4 = "\u540C\u610F: ";
var APPB2C_registration_testTaker_contact_address4 = "\u4F4D\u5740";
var APPB2C_registration_testTaker_contact_edit4 = "\u7DE8\u8F2F\u806F\u7E6B\u4EBA\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_registration_testTaker_contact_mobile4 = "\u624B\u6A5F\u865F\u78BC";
var APPB2C_registration_testTaker_contact_parent4 = "\u5BB6\u9577/\u76E3\u8B77\u4EBA\u7684\u806F\u7E6B\u65B9\u5F0F: ";
var APPB2C_registration_testTaker_contact_title4 = "\u806F\u7E6B\u65B9\u5F0F: ";
var APPB2C_registration_testTaker_editContact_address4 = "\u5730\u5740";
var APPB2C_registration_testTaker_editContact_back4 = "\u6211\u4E0D\u60F3\u66F4\u6539\u6211\u7684\u806F\u7E6B\u65B9\u5F0F";
var APPB2C_registration_testTaker_editContact_city4 = "\u9109\u93AE / \u57CE\u5E02";
var APPB2C_registration_testTaker_editContact_country_change4 = "\u66F4\u6539\u570B\u5BB6/\u5730\u5340";
var APPB2C_registration_testTaker_editContact_country_residence4 = "\u5C45\u4F4F\u570B\u5BB6/\u5730\u5340";
var APPB2C_registration_testTaker_editContact_cta4 = "\u66F4\u65B0\u8A73\u7D30\u8CC7\u8A0A";
var APPB2C_registration_testTaker_editContact_error_missingAddress4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u4F4D\u5740\u3002";
var APPB2C_registration_testTaker_editContact_error_missingCountry4 = "\u8ACB\u63D0\u4F9B\u60A8\u7684\u570B\u5BB6/\u5730\u5340\u3002";
var APPB2C_registration_testTaker_editContact_error_pastCutoff4 = "\u7DE8\u8F2F\u60A8\u7684\u806F\u7E6B\u65B9\u5F0F\u4E0D\u518D\u53EF\u7528\u3002";
var APPB2C_registration_testTaker_editContact_mobile4 = "\u624B\u6A5F\u865F\u78BC";
var APPB2C_registration_testTaker_editContact_note4 = "\u8ACB\u6CE8\u610F";
var APPB2C_registration_testTaker_editContact_postCode4 = "\u90F5\u905E\u5340\u865F / \u90F5\u7DE8";
var APPB2C_registration_testTaker_editContact_state4 = "\u5DDE";
var APPB2C_registration_testTaker_editContact_warn4 = "\u806F\u7D61\u8CC7\u8A0A\u7684\u66F4\u65B0\u5C07\u5728\u8003\u8A66\u5831\u540D\u6642\u9032\u884C: ";
var APPB2C_registration_testTaker_error4 = "\u6B64\u9810\u8A02\u4E0D\u518D\u63D0\u4F9B\u7DE8\u8F2F\u60A8\u7684\u8A73\u7D30\u8CC7\u8A0A\u7684\u529F\u80FD\u3002";
var APPB2C_registration_testTaker_files_editButton_add4 = "\u4E0A\u50B3\u60A8\u7684\u6A94\u6848";
var APPB2C_registration_testTaker_files_editButton_edit4 = "\u7DE8\u8F2F\u5DF2\u4E0A\u50B3\u7684\u6A94\u6848";
var APPB2C_registration_testTaker_files_upload_back_subtitle4 = "(\u9019\u4E0D\u6703\u4E0A\u50B3\u6216\u522A\u9664\u4EFB\u4F55\u6A94\u6848)";
var APPB2C_registration_testTaker_files_upload_back_title4 = "\u8FD4\u56DE\u61C9\u8A66\u8005";
var APPB2C_registration_testTaker_files_upload_cta4 = "\u4FDD\u5B58\u60A8\u7684\u6A94\u6848";
var APPB2C_registration_testTaker_files_upload_error_fileTooLarge4 = "\u60A8\u5617\u8A66\u4E0A\u50B3\u7684\u6A94\u592A\u5927\u3002\u8ACB\u5C07\u5176\u522A\u9664\u4E26\u9078\u64C7\u5176\u4ED6\u6A94\u3002";
var APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded4 = "\u60A8\u5DF2\u8D85\u51FA\u53EF\u4EE5\u4E0A\u50B3\u7684\u6587\u4EF6\u6578\u3002\u8ACB\u522A\u9664\u8D85\u51FA\u9650\u5236\u7684\u6587\u4EF6, \u7136\u5F8C\u91CD\u8A66\u3002";
var APPB2C_registration_testTaker_files_upload_error_uploadUnavailable4 = "\u6B64\u9810\u8A02\u7121\u6CD5\u4E0A\u50B3\u6A94\u3002";
var APPB2C_registration_testTaker_files_upload_error_wrongFileName4 = "\u60A8\u5617\u8A66\u4E0A\u50B3\u7684\u6A94\u6848\u7684\u540D\u7A31\u5305\u542B\u4E0D\u53D7\u652F\u63F4\u7684\u5B57\u5143\u3002\u8ACB\u5C07\u5176\u522A\u9664, \u5728\u672C\u5730\u91CD\u547D\u540D, \u7136\u5F8C\u518D\u6B21\u5617\u8A66\u4E0A\u50B3\u3002";
var APPB2C_registration_testTaker_files_upload_error_wrongFileType4 = "\u60A8\u8981\u5617\u8A66\u4EE5\u4E0D\u53D7\u652F\u63F4\u7684\u683C\u5F0F\u4E0A\u50B3\u6A94\u3002\u8ACB\u5C07\u5176\u522A\u9664\u4E26\u9078\u64C7\u5176\u4ED6\u6A94\u3002";
var APPB2C_registration_testTaker_files_upload_mobile_line14 = "\u7531\u65BC\u60A8\u770B\u8D77\u4F86\u50CF\u662F\u5728\u884C\u52D5\u88DD\u7F6E\u4E0A, \u6211\u5011\u5EFA\u8B70\u60A8\u5F9E\u8A2D\u5099\u4E0A\u50B3\u7167\u7247\u3002";
var APPB2C_registration_testTaker_files_upload_mobile_line24 = "\u5982\u679C\u60A8\u7121\u6CD5\u5728\u884C\u52D5\u88DD\u7F6E\u4E0A\u57F7\u884C\u6B64\u64CD\u4F5C, \u8ACB\u5728\u96FB\u8166\u4E0A\u91CD\u65B0\u767B\u9304\u3002";
var APPB2C_registration_testTaker_files_upload_mobile_title4 = "\u8ACB\u6CE8\u610F: ";
var APPB2C_registration_testTaker_files_upload_note4 = "\u60A8\u53EF\u4EE5\u4E0A\u50B3 {{count}} \u6587\u4EF6(\u81F3\u5C11\u9700\u4E0A\u50B3 1 \u500B)";
var APPB2C_registration_testTaker_id_child4 = "\u5152\u7AE5\u8EAB\u4EFD\u8B49\u660E\u8A73\u7D30\u8CC7\u8A0A: ";
var APPB2C_registration_testTaker_id_edit_rules_example4 = "$t({{idName}}) \u7684\u8B49\u660E\u7BC4\u4F8B";
var APPB2C_registration_testTaker_id_edit_subtitle4 = "\u8ACB\u4E0A\u50B3\u5982\u4E0B\u6240\u793A\u7684$t({{idName}}) \u8B49\u660E: ";
var APPB2C_registration_testTaker_id_edit_title4 = "\u4E0A\u50B3$t({{idName}}) \u8B49\u660E";
var APPB2C_registration_testTaker_id_edit_upload_exampleProof4 = "\u8B49\u660E\u7BC4\u4F8B";
var APPB2C_registration_testTaker_id_edit_upload_imgAlt4 = "\u8EAB\u4EFD\u8B49\u660E";
var APPB2C_registration_testTaker_id_expiryDate4 = "$t({{idName}}, capitalise) \u6548\u671F";
var APPB2C_registration_testTaker_id_fileAlt4 = "\u4E0A\u50B3\u7684\u8EAB\u4EFD\u8B49\u660E";
var APPB2C_registration_testTaker_id_idNumber4 = "$t({{idName}}, capitalise) \u6578\u91CF";
var APPB2C_registration_testTaker_id_issuingAuthority4 = "\u767C\u8B49\u6A5F\u69CB";
var APPB2C_registration_testTaker_id_noFilesBody4 = "\u60A8\u5C1A\u672A\u4E0A\u50B3\u4EFB\u4F55\u8EAB\u4EFD\u8B49\u660E\u3002";
var APPB2C_registration_testTaker_id_noFilesTitle4 = "\u6C92\u6709\u8B49\u660E";
var APPB2C_registration_testTaker_id_title4 = "\u8EAB\u4EFD\u8B49\u660E\u8A73\u60C5: ";
var APPB2C_registration_testTaker_id_typeNames_card4 = "\u8EAB\u4EFD\u8B49";
var APPB2C_registration_testTaker_id_typeNames_other4 = "ID";
var APPB2C_registration_testTaker_id_typeNames_passport4 = "\u8B77\u7167";
var APPB2C_registration_testTaker_specialArrangements_description4 = "\u60A8\u8981\u6C42\u4EE5\u4E0B\u7279\u6B8A\u5B89\u6392: ";
var APPB2C_registration_testTaker_specialArrangements_edit_line14 = "\u9700\u8981\u7279\u6B8A\u5B89\u6392: ";
var APPB2C_registration_testTaker_specialArrangements_edit_line24 = "\u6240\u6709\u7279\u6B8A\u5B89\u6392\u7684\u7533\u8ACB\u5FC5\u9808\u6709\u91AB\u7642\u8B49\u660E\u8B49\u5BE6\u3002";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule14 = "\u96C5\u601D\u7BA1\u7406\u54E1\u5728\u8A9E\u8A00\u548C\u6613\u8B80\u6027\u65B9\u9762\u53EF\u4EE5\u67E5\u770B";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule24 = "\u9808\u70BA\u62AC\u982D\u7D19\u6B63\u672C, \u4E26\u9644\u6709\u8A8D\u53EF\u5F9E\u696D\u54E1\u7684\u59D3\u540D\u3001\u76F8\u95DC\u8CC7\u683C\u53CA\u7C3D\u540D";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule34 = "\u9808\u70BA\u542B\u62AC\u982D\u7684\u6B63\u672C, \u4E26\u9644\u6709\u8A8D\u53EF\u5F9E\u696D\u54E1\u7684\u59D3\u540D\u3001\u76F8\u95DC\u8CC7\u683C\u53CA\u7C3D\u540D";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule44 = "\u61C9\u8A72\u8B49\u660E\u60A8\u9700\u8981\u7684\u7279\u6B8A\u5B89\u6392\u662F\u5408\u7406\u7684";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule54 = "\u61C9\u5728\u8003\u8A66\u65E5\u671F\u524D\u5169\u5E74\u5167\u6838\u767C";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_title4 = "\u91AB\u7642\u8B49\u660E\u61C9: ";
var APPB2C_registration_testTaker_specialArrangements_edit_subtitle4 = "\u7576\u60A8\u5831\u540D\u8003\u8A66\u6642, \u60A8\u8868\u660E\u9700\u8981\u7279\u6B8A\u5B89\u6392\u3002";
var APPB2C_registration_testTaker_specialArrangements_edit_title4 = "\u4E0A\u50B3\u91AB\u7642\u8B49\u660E";
var APPB2C_registration_testTaker_specialArrangements_fileAlt4 = "\u4E0A\u50B3\u7684\u91AB\u7642\u8B49\u64DA";
var APPB2C_registration_testTaker_specialArrangements_noFilesBody4 = "\u60A8\u5C1A\u672A\u4E0A\u50B3\u4EFB\u4F55\u91AB\u7642\u8B49\u660E\u3002";
var APPB2C_registration_testTaker_specialArrangements_noFilesTitle4 = "\u6C92\u6709\u8B49\u64DA";
var APPB2C_registration_testTaker_specialArrangements_title4 = "\u7279\u6B8A\u5B89\u6392: ";
var APPB2C_testCard_arrival4 = "(\u8ACB\u65BC {{time}} \u5230\u9054)";
var APPB2C_testCard_book4 = "\u9810\u5B9A";
var APPB2C_testCard_bookExam4 = "\u5831\u540D\u6E2C\u8A66";
var APPB2C_testCard_bookFor4 = "\u9810\u5B9A";
var APPB2C_testCard_bookForFree4 = "\u514D\u8CBB\u9810\u8A02\u6E2C\u8A66";
var APPB2C_testCard_bookingPrice4 = "\u50F9\u683C";
var APPB2C_testCard_cd4 = "\u96C5\u601D\u96FB\u8166\u6E2C\u9A57";
var APPB2C_testCard_changeSpeaking4 = "\u66F4\u6539\u6F14\u8B1B\u6642\u7A0B\u8868";
var APPB2C_testCard_f2f4 = "\u9762\u5C0D\u9762";
var APPB2C_testCard_hideSpeakingTests4 = "\u96B1\u85CF\u6B64\u5834\u5730\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_testCard_lrw4 = "\u807D\u529B\u3001\u95B1\u8B80\u548C\u5BEB\u4F5C";
var APPB2C_testCard_lrwHeader3 = "\u7B46\u8A66";
var APPB2C_testCard_ls4 = "\u807D\u529B\u548C\u53E3\u8AAA";
var APPB2C_testCard_mixedSlots4 = "\u6E2C\u9A57\u5C07\u88AB\u9304\u5F71, \u7E7C\u7E8C\u5831\u540D\u5373\u8868\u793A\u540C\u610F\u3002\u5982\u679C\u60A8\u4E0D\u540C\u610F, \u8ACB\u9078\u64C7\u5176\u4ED6\u3002";
var APPB2C_testCard_notLinkedVenue4 = "\u53E3\u8A9E\u548C\u7B46\u8A66\u5730\u9EDE\u4E0D\u4E00\u6A23, \u8ACB\u898F\u5283\u60A8\u7684\u884C\u7A0B\u3002";
var APPB2C_testCard_pb4 = "\u96C5\u601D\u7D19\u7B46\u6E2C\u9A57";
var APPB2C_testCard_review4 = "\u548C\u5BE9\u67E5";
var APPB2C_testCard_showSpeakingTests4 = "\u986F\u793A\u6B64\u5834\u5730\u7684\u53E3\u8AAA\u6E2C\u9A57";
var APPB2C_testCard_speaking4 = "\u53E3\u8A9E\u6E2C\u8A66";
var APPB2C_testCard_speakingAddress4 = "\u53E3\u8AAA\u5730\u5740";
var APPB2C_testCard_timeUnknown4 = "\u60A8\u5C07\u5728\u8003\u8A66\u65E5\u671F\u524D 48 \u5C0F\u6642\u6536\u5230\u8003\u8A66\u6642\u9593\u3002";
var APPB2C_testCard_vcs4 = "\u8003\u8A66\u4E2D\u5FC3\u8996\u8A0A\u901A\u8A71";
var b2c_default4 = {
  "APPB2C.auth.changePassword.cta": APPB2C_auth_changePassword_cta4,
  "APPB2C.auth.changePassword.error": APPB2C_auth_changePassword_error4,
  "APPB2C.auth.changePassword.error.differentPasswords": APPB2C_auth_changePassword_error_differentPasswords4,
  "APPB2C.auth.changePassword.error.wrongIdNumber": APPB2C_auth_changePassword_error_wrongIdNumber4,
  "APPB2C.auth.changePassword.error.wrongOldPassword": APPB2C_auth_changePassword_error_wrongOldPassword4,
  "APPB2C.auth.changePassword.error.wrongPassword": APPB2C_auth_changePassword_error_wrongPassword4,
  "APPB2C.auth.changePassword.id.label": APPB2C_auth_changePassword_id_label4,
  "APPB2C.auth.changePassword.id.note": APPB2C_auth_changePassword_id_note4,
  "APPB2C.auth.changePassword.id.warn": APPB2C_auth_changePassword_id_warn4,
  "APPB2C.auth.changePassword.pass.label": APPB2C_auth_changePassword_pass_label4,
  "APPB2C.auth.changePassword.pass.note": APPB2C_auth_changePassword_pass_note4,
  "APPB2C.auth.changePassword.title": APPB2C_auth_changePassword_title4,
  "APPB2C.auth.forgetPassword.cta": APPB2C_auth_forgetPassword_cta4,
  "APPB2C.auth.forgetPassword.email": APPB2C_auth_forgetPassword_email4,
  "APPB2C.auth.forgetPassword.success.msg1": APPB2C_auth_forgetPassword_success_msg14,
  "APPB2C.auth.forgetPassword.success.msg2": APPB2C_auth_forgetPassword_success_msg24,
  "APPB2C.auth.forgetPassword.success.title": APPB2C_auth_forgetPassword_success_title4,
  "APPB2C.auth.forgetPassword.text": APPB2C_auth_forgetPassword_text4,
  "APPB2C.auth.forgetPassword.title": APPB2C_auth_forgetPassword_title4,
  "APPB2C.auth.resetPassword.cta": APPB2C_auth_resetPassword_cta4,
  "APPB2C.auth.resetPassword.error.expiredToken": APPB2C_auth_resetPassword_error_expiredToken4,
  "APPB2C.auth.resetPassword.error.wrongPassword": APPB2C_auth_resetPassword_error_wrongPassword4,
  "APPB2C.auth.resetPassword.error.wrongToken": APPB2C_auth_resetPassword_error_wrongToken4,
  "APPB2C.auth.resetPassword.form.newPassword": APPB2C_auth_resetPassword_form_newPassword4,
  "APPB2C.auth.resetPassword.noToken.cta": APPB2C_auth_resetPassword_noToken_cta4,
  "APPB2C.auth.resetPassword.noToken.msg": APPB2C_auth_resetPassword_noToken_msg4,
  "APPB2C.auth.resetPassword.success.msg": APPB2C_auth_resetPassword_success_msg4,
  "APPB2C.auth.resetPassword.success.title": APPB2C_auth_resetPassword_success_title4,
  "APPB2C.auth.resetPassword.title": APPB2C_auth_resetPassword_title4,
  "APPB2C.cj.basic.cta": APPB2C_cj_basic_cta4,
  "APPB2C.cj.basic.goToReview": APPB2C_cj_basic_goToReview4,
  "APPB2C.cj.basic.scrollLink": APPB2C_cj_basic_scrollLink4,
  "APPB2C.cj.basic.selectDate": APPB2C_cj_basic_selectDate4,
  "APPB2C.cj.basic.selectTime": APPB2C_cj_basic_selectTime4,
  "APPB2C.cj.basic.validationError": APPB2C_cj_basic_validationError4,
  "APPB2C.cj.bookingComplete.feeDetails.discount": APPB2C_cj_bookingComplete_feeDetails_discount4,
  "APPB2C.cj.bookingComplete.feeDetails.fee": APPB2C_cj_bookingComplete_feeDetails_fee4,
  "APPB2C.cj.bookingComplete.feeDetails.newFee": APPB2C_cj_bookingComplete_feeDetails_newFee4,
  "APPB2C.cj.bookingComplete.feeDetails.promo": APPB2C_cj_bookingComplete_feeDetails_promo4,
  "APPB2C.cj.bookingComplete.feeDetails.reference": APPB2C_cj_bookingComplete_feeDetails_reference4,
  "APPB2C.cj.bookingComplete.feeDetails.tax": APPB2C_cj_bookingComplete_feeDetails_tax4,
  "APPB2C.cj.bookingComplete.feeDetails.title": APPB2C_cj_bookingComplete_feeDetails_title4,
  "APPB2C.cj.bookingComplete.feeDetails.total": APPB2C_cj_bookingComplete_feeDetails_total4,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine1": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine14,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine2": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine24,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine3": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine34,
  "APPB2C.cj.bookingComplete.loadingPaymentMethodsError": APPB2C_cj_bookingComplete_loadingPaymentMethodsError4,
  "APPB2C.cj.bookingComplete.loadingRegistrationError": APPB2C_cj_bookingComplete_loadingRegistrationError4,
  "APPB2C.cj.bookingComplete.note": APPB2C_cj_bookingComplete_note4,
  "APPB2C.cj.bookingComplete.noteUolPassportInfo": APPB2C_cj_bookingComplete_noteUolPassportInfo4,
  "APPB2C.cj.bookingComplete.offlinePayment.code": APPB2C_cj_bookingComplete_offlinePayment_code4,
  "APPB2C.cj.bookingComplete.offlinePayment.description": APPB2C_cj_bookingComplete_offlinePayment_description4,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line1": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line14,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line2": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line24,
  "APPB2C.cj.bookingComplete.offlinePayment.fee": APPB2C_cj_bookingComplete_offlinePayment_fee4,
  "APPB2C.cj.bookingComplete.offlinePayment.hideDescription": APPB2C_cj_bookingComplete_offlinePayment_hideDescription4,
  "APPB2C.cj.bookingComplete.offlinePayment.incTax": APPB2C_cj_bookingComplete_offlinePayment_incTax4,
  "APPB2C.cj.bookingComplete.offlinePayment.name": APPB2C_cj_bookingComplete_offlinePayment_name4,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1": APPB2C_cj_bookingComplete_offlinePayment_note_line14,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.hours": APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours4,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.timeLeft": APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft4,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line2": APPB2C_cj_bookingComplete_offlinePayment_note_line24,
  "APPB2C.cj.bookingComplete.offlinePayment.note.pleaseNote": APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote4,
  "APPB2C.cj.bookingComplete.offlinePayment.number": APPB2C_cj_bookingComplete_offlinePayment_number4,
  "APPB2C.cj.bookingComplete.offlinePayment.reference": APPB2C_cj_bookingComplete_offlinePayment_reference4,
  "APPB2C.cj.bookingComplete.offlinePayment.showDescription": APPB2C_cj_bookingComplete_offlinePayment_showDescription4,
  "APPB2C.cj.bookingComplete.otherPaymentOptions": APPB2C_cj_bookingComplete_otherPaymentOptions4,
  "APPB2C.cj.bookingComplete.paymentFailed": APPB2C_cj_bookingComplete_paymentFailed4,
  "APPB2C.cj.bookingComplete.paymentOptions": APPB2C_cj_bookingComplete_paymentOptions4,
  "APPB2C.cj.bookingComplete.prepareNote.accesNote": APPB2C_cj_bookingComplete_prepareNote_accesNote4,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.note": APPB2C_cj_bookingComplete_prepareNote_counselling_note4,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.view": APPB2C_cj_bookingComplete_prepareNote_counselling_view4,
  "APPB2C.cj.bookingComplete.prepareNote.note": APPB2C_cj_bookingComplete_prepareNote_note4,
  "APPB2C.cj.bookingComplete.prepareNote.title": APPB2C_cj_bookingComplete_prepareNote_title4,
  "APPB2C.cj.bookingComplete.prepareNote.view": APPB2C_cj_bookingComplete_prepareNote_view4,
  "APPB2C.cj.bookingComplete.title": APPB2C_cj_bookingComplete_title4,
  "APPB2C.cj.bookingComplete.tnc": APPB2C_cj_bookingComplete_tnc4,
  "APPB2C.cj.bookingComplete.tncLink": APPB2C_cj_bookingComplete_tncLink4,
  "APPB2C.cj.bookingComplete.tryAgain": APPB2C_cj_bookingComplete_tryAgain4,
  "APPB2C.cj.bookingComplete.yourBooking": APPB2C_cj_bookingComplete_yourBooking4,
  "APPB2C.cj.childDetails.about": APPB2C_cj_childDetails_about4,
  "APPB2C.cj.childDetails.aboutNote": APPB2C_cj_childDetails_aboutNote4,
  "APPB2C.cj.childDetails.error.missingFirstName": APPB2C_cj_childDetails_error_missingFirstName4,
  "APPB2C.cj.childDetails.error.missingSurname": APPB2C_cj_childDetails_error_missingSurname4,
  "APPB2C.cj.childDetails.error.wrongDob": APPB2C_cj_childDetails_error_wrongDob4,
  "APPB2C.cj.childDetails.forms.personal.idMatch": APPB2C_cj_childDetails_forms_personal_idMatch4,
  "APPB2C.cj.childDetails.noLastName": APPB2C_cj_childDetails_noLastName4,
  "APPB2C.cj.childDetails.note": APPB2C_cj_childDetails_note4,
  "APPB2C.cj.childDetails.onTestDay": APPB2C_cj_childDetails_onTestDay4,
  "APPB2C.cj.childDetails.title": APPB2C_cj_childDetails_title4,
  "APPB2C.cj.finishPayment.error": APPB2C_cj_finishPayment_error4,
  "APPB2C.cj.finishPayment.pleaseWait": APPB2C_cj_finishPayment_pleaseWait4,
  "APPB2C.cj.finishPayment.processing": APPB2C_cj_finishPayment_processing4,
  "APPB2C.cj.idDetails.confirm.change": APPB2C_cj_idDetails_confirm_change4,
  "APPB2C.cj.idDetails.confirm.confirm": APPB2C_cj_idDetails_confirm_confirm4,
  "APPB2C.cj.idDetails.confirm.note1": APPB2C_cj_idDetails_confirm_note14,
  "APPB2C.cj.idDetails.confirm.note2": APPB2C_cj_idDetails_confirm_note24,
  "APPB2C.cj.idDetails.confirm.title": APPB2C_cj_idDetails_confirm_title4,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.content": APPB2C_cj_idDetails_confirm_wrongDetailsNote_content4,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.title": APPB2C_cj_idDetails_confirm_wrongDetailsNote_title4,
  "APPB2C.cj.idDetails.error.missingIdExpiry": APPB2C_cj_idDetails_error_missingIdExpiry4,
  "APPB2C.cj.idDetails.error.missingIdNumber": APPB2C_cj_idDetails_error_missingIdNumber4,
  "APPB2C.cj.idDetails.error.missingIdType": APPB2C_cj_idDetails_error_missingIdType4,
  "APPB2C.cj.idDetails.error.missingIssuingAuthority": APPB2C_cj_idDetails_error_missingIssuingAuthority4,
  "APPB2C.cj.idDetails.error.wrongFilename": APPB2C_cj_idDetails_error_wrongFilename4,
  "APPB2C.cj.idDetails.error.wrongIdExpiry": APPB2C_cj_idDetails_error_wrongIdExpiry4,
  "APPB2C.cj.idDetails.forms.changeNote": APPB2C_cj_idDetails_forms_changeNote4,
  "APPB2C.cj.idDetails.forms.changeNote.child": APPB2C_cj_idDetails_forms_changeNote_child4,
  "APPB2C.cj.idDetails.forms.documentWithNoExpiryDate": APPB2C_cj_idDetails_forms_documentWithNoExpiryDate4,
  "APPB2C.cj.idDetails.forms.expiryDate": APPB2C_cj_idDetails_forms_expiryDate4,
  "APPB2C.cj.idDetails.forms.idNumber": APPB2C_cj_idDetails_forms_idNumber4,
  "APPB2C.cj.idDetails.forms.idType": APPB2C_cj_idDetails_forms_idType4,
  "APPB2C.cj.idDetails.forms.issuingAuthority": APPB2C_cj_idDetails_forms_issuingAuthority4,
  "APPB2C.cj.idDetails.idExpired": APPB2C_cj_idDetails_idExpired4,
  "APPB2C.cj.idDetails.iolMessage": APPB2C_cj_idDetails_iolMessage4,
  "APPB2C.cj.idDetails.noteAboutIdentification": APPB2C_cj_idDetails_noteAboutIdentification4,
  "APPB2C.cj.idDetails.noteAboutIdentification.child": APPB2C_cj_idDetails_noteAboutIdentification_child4,
  "APPB2C.cj.idDetails.noteAboutIdentificationIol": APPB2C_cj_idDetails_noteAboutIdentificationIol4,
  "APPB2C.cj.idDetails.noteAboutIdentificationIolLink": APPB2C_cj_idDetails_noteAboutIdentificationIolLink4,
  "APPB2C.cj.idDetails.noteAboutIdentificationUol": APPB2C_cj_idDetails_noteAboutIdentificationUol4,
  "APPB2C.cj.idDetails.title": APPB2C_cj_idDetails_title4,
  "APPB2C.cj.idDetails.title.child": APPB2C_cj_idDetails_title_child4,
  "APPB2C.cj.idDetails.typeNames.card": APPB2C_cj_idDetails_typeNames_card4,
  "APPB2C.cj.idDetails.typeNames.other": APPB2C_cj_idDetails_typeNames_other4,
  "APPB2C.cj.idDetails.typeNames.passport": APPB2C_cj_idDetails_typeNames_passport4,
  "APPB2C.cj.idDetails.upload.fetchError": APPB2C_cj_idDetails_upload_fetchError4,
  "APPB2C.cj.idDetails.upload.file1": APPB2C_cj_idDetails_upload_file14,
  "APPB2C.cj.idDetails.upload.file2": APPB2C_cj_idDetails_upload_file24,
  "APPB2C.cj.idDetails.upload.instructions.capture": APPB2C_cj_idDetails_upload_instructions_capture4,
  "APPB2C.cj.idDetails.upload.instructions.covers": APPB2C_cj_idDetails_upload_instructions_covers4,
  "APPB2C.cj.idDetails.upload.instructions.directLight": APPB2C_cj_idDetails_upload_instructions_directLight4,
  "APPB2C.cj.idDetails.upload.instructions.noBlur": APPB2C_cj_idDetails_upload_instructions_noBlur4,
  "APPB2C.cj.idDetails.upload.instructions.title": APPB2C_cj_idDetails_upload_instructions_title4,
  "APPB2C.cj.idDetails.upload.mobile": APPB2C_cj_idDetails_upload_mobile4,
  "APPB2C.cj.idDetails.upload.passport.guidelines.cover": APPB2C_cj_idDetails_upload_passport_guidelines_cover4,
  "APPB2C.cj.idDetails.upload.passport.guidelines.crop": APPB2C_cj_idDetails_upload_passport_guidelines_crop4,
  "APPB2C.cj.idDetails.upload.passport.guidelines.followInstructions": APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions4,
  "APPB2C.cj.idDetails.upload.passport.guidelines.glare": APPB2C_cj_idDetails_upload_passport_guidelines_glare4,
  "APPB2C.cj.idDetails.upload.passport.guidelines.watermark": APPB2C_cj_idDetails_upload_passport_guidelines_watermark4,
  "APPB2C.cj.idDetails.upload.title": APPB2C_cj_idDetails_upload_title4,
  "APPB2C.cj.ieltsReady.description.line1": APPB2C_cj_ieltsReady_description_line14,
  "APPB2C.cj.ieltsReady.description.line2": APPB2C_cj_ieltsReady_description_line24,
  "APPB2C.cj.ieltsReady.dobMin": APPB2C_cj_ieltsReady_dobMin4,
  "APPB2C.cj.ieltsReady.nameWarning": APPB2C_cj_ieltsReady_nameWarning4,
  "APPB2C.cj.ieltsReady.note": APPB2C_cj_ieltsReady_note4,
  "APPB2C.cj.ieltsReady.personalDetails.title": APPB2C_cj_ieltsReady_personalDetails_title3,
  "APPB2C.cj.ieltsReady.submitBtnText": APPB2C_cj_ieltsReady_submitBtnText4,
  "APPB2C.cj.ieltsReady.targetScore.option": APPB2C_cj_ieltsReady_targetScore_option4,
  "APPB2C.cj.ieltsReady.targetScore.title": APPB2C_cj_ieltsReady_targetScore_title4,
  "APPB2C.cj.ieltsReady.title": APPB2C_cj_ieltsReady_title4,
  "APPB2C.cj.incorrectOrganisation.bookNewLink": APPB2C_cj_incorrectOrganisation_bookNewLink4,
  "APPB2C.cj.incorrectOrganisation.line1": APPB2C_cj_incorrectOrganisation_line14,
  "APPB2C.cj.incorrectOrganisation.line2": APPB2C_cj_incorrectOrganisation_line24,
  "APPB2C.cj.incorrectOrganisation.locationsLink": APPB2C_cj_incorrectOrganisation_locationsLink4,
  "APPB2C.cj.incorrectOrganisation.locationsLinkUsa": APPB2C_cj_incorrectOrganisation_locationsLinkUsa4,
  "APPB2C.cj.incorrectOrganisation.title": APPB2C_cj_incorrectOrganisation_title4,
  "APPB2C.cj.marketingPrefs": APPB2C_cj_marketingPrefs4,
  "APPB2C.cj.onRequest.created.back": APPB2C_cj_onRequest_created_back4,
  "APPB2C.cj.onRequest.created.in": APPB2C_cj_onRequest_created_in4,
  "APPB2C.cj.onRequest.created.info": APPB2C_cj_onRequest_created_info4,
  "APPB2C.cj.onRequest.created.note": APPB2C_cj_onRequest_created_note4,
  "APPB2C.cj.onRequest.created.title": APPB2C_cj_onRequest_created_title4,
  "APPB2C.cj.onRequest.form.labels.confirmEmail": APPB2C_cj_onRequest_form_labels_confirmEmail4,
  "APPB2C.cj.onRequest.form.labels.email": APPB2C_cj_onRequest_form_labels_email4,
  "APPB2C.cj.onRequest.form.labels.firstname": APPB2C_cj_onRequest_form_labels_firstname4,
  "APPB2C.cj.onRequest.form.labels.surname": APPB2C_cj_onRequest_form_labels_surname4,
  "APPB2C.cj.onRequest.form.labels.telephone": APPB2C_cj_onRequest_form_labels_telephone4,
  "APPB2C.cj.onRequest.form.labels.testRangeQuestion": APPB2C_cj_onRequest_form_labels_testRangeQuestion4,
  "APPB2C.cj.onRequest.form.submit": APPB2C_cj_onRequest_form_submit4,
  "APPB2C.cj.onRequest.form.testRangeOpt.oneMonth": APPB2C_cj_onRequest_form_testRangeOpt_oneMonth4,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonths": APPB2C_cj_onRequest_form_testRangeOpt_threeMonths4,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonthsPlus": APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus4,
  "APPB2C.cj.onRequest.form.testRangeOpt.twoMonths": APPB2C_cj_onRequest_form_testRangeOpt_twoMonths4,
  "APPB2C.cj.onRequest.info1": APPB2C_cj_onRequest_info14,
  "APPB2C.cj.onRequest.note": APPB2C_cj_onRequest_note4,
  "APPB2C.cj.onRequest.otherLocations.choose": APPB2C_cj_onRequest_otherLocations_choose4,
  "APPB2C.cj.onRequest.otherLocations.hide": APPB2C_cj_onRequest_otherLocations_hide4,
  "APPB2C.cj.onRequest.otherLocations.info1": APPB2C_cj_onRequest_otherLocations_info14,
  "APPB2C.cj.onRequest.otherLocations.info2": APPB2C_cj_onRequest_otherLocations_info24,
  "APPB2C.cj.onRequest.otherLocations.show": APPB2C_cj_onRequest_otherLocations_show4,
  "APPB2C.cj.onRequest.please": APPB2C_cj_onRequest_please4,
  "APPB2C.cj.onRequest.prefNote": APPB2C_cj_onRequest_prefNote4,
  "APPB2C.cj.onRequest.subtitles.aboutPreferences": APPB2C_cj_onRequest_subtitles_aboutPreferences4,
  "APPB2C.cj.onRequest.subtitles.aboutYou": APPB2C_cj_onRequest_subtitles_aboutYou4,
  "APPB2C.cj.onRequest.subtitles.markpref": APPB2C_cj_onRequest_subtitles_markpref4,
  "APPB2C.cj.onRequest.subtitles.register": APPB2C_cj_onRequest_subtitles_register4,
  "APPB2C.cj.personalDetails.error.doubleBooking": APPB2C_cj_personalDetails_error_doubleBooking4,
  "APPB2C.cj.personalDetails.error.missingAddress": APPB2C_cj_personalDetails_error_missingAddress4,
  "APPB2C.cj.personalDetails.error.missingEmail": APPB2C_cj_personalDetails_error_missingEmail4,
  "APPB2C.cj.personalDetails.error.missingEmailConfirm": APPB2C_cj_personalDetails_error_missingEmailConfirm4,
  "APPB2C.cj.personalDetails.error.missingFirstName": APPB2C_cj_personalDetails_error_missingFirstName4,
  "APPB2C.cj.personalDetails.error.missingMobile": APPB2C_cj_personalDetails_error_missingMobile4,
  "APPB2C.cj.personalDetails.error.missingSex": APPB2C_cj_personalDetails_error_missingSex4,
  "APPB2C.cj.personalDetails.error.missingState": APPB2C_cj_personalDetails_error_missingState4,
  "APPB2C.cj.personalDetails.error.missingSurname": APPB2C_cj_personalDetails_error_missingSurname4,
  "APPB2C.cj.personalDetails.error.missingTown": APPB2C_cj_personalDetails_error_missingTown4,
  "APPB2C.cj.personalDetails.error.missingZip": APPB2C_cj_personalDetails_error_missingZip4,
  "APPB2C.cj.personalDetails.error.wrongDob": APPB2C_cj_personalDetails_error_wrongDob4,
  "APPB2C.cj.personalDetails.error.wrongEmailConfirm": APPB2C_cj_personalDetails_error_wrongEmailConfirm4,
  "APPB2C.cj.personalDetails.examTakenModal.cta": APPB2C_cj_personalDetails_examTakenModal_cta4,
  "APPB2C.cj.personalDetails.examTakenModal.message": APPB2C_cj_personalDetails_examTakenModal_message4,
  "APPB2C.cj.personalDetails.examTakenModal.title": APPB2C_cj_personalDetails_examTakenModal_title4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse": APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate": APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle": APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.title": APPB2C_cj_personalDetails_existingRegistrationsModal_title4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourLifeSkillsTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourSpeakingTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest4,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourWrittenTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest4,
  "APPB2C.cj.personalDetails.forms.address.country": APPB2C_cj_personalDetails_forms_address_country4,
  "APPB2C.cj.personalDetails.forms.address.enterManually": APPB2C_cj_personalDetails_forms_address_enterManually4,
  "APPB2C.cj.personalDetails.forms.address.postal": APPB2C_cj_personalDetails_forms_address_postal4,
  "APPB2C.cj.personalDetails.forms.address.state": APPB2C_cj_personalDetails_forms_address_state4,
  "APPB2C.cj.personalDetails.forms.address.town": APPB2C_cj_personalDetails_forms_address_town4,
  "APPB2C.cj.personalDetails.forms.address.zip": APPB2C_cj_personalDetails_forms_address_zip4,
  "APPB2C.cj.personalDetails.forms.contact.confirmEmail": APPB2C_cj_personalDetails_forms_contact_confirmEmail4,
  "APPB2C.cj.personalDetails.forms.contact.email": APPB2C_cj_personalDetails_forms_contact_email4,
  "APPB2C.cj.personalDetails.forms.contact.emailExist": APPB2C_cj_personalDetails_forms_contact_emailExist4,
  "APPB2C.cj.personalDetails.forms.contact.emailInUse": APPB2C_cj_personalDetails_forms_contact_emailInUse4,
  "APPB2C.cj.personalDetails.forms.contact.mobile": APPB2C_cj_personalDetails_forms_contact_mobile4,
  "APPB2C.cj.personalDetails.forms.contact.note": APPB2C_cj_personalDetails_forms_contact_note4,
  "APPB2C.cj.personalDetails.forms.contact.password": APPB2C_cj_personalDetails_forms_contact_password4,
  "APPB2C.cj.personalDetails.forms.contact.smsConsent": APPB2C_cj_personalDetails_forms_contact_smsConsent4,
  "APPB2C.cj.personalDetails.forms.contact.smsNote": APPB2C_cj_personalDetails_forms_contact_smsNote4,
  "APPB2C.cj.personalDetails.forms.contact.title": APPB2C_cj_personalDetails_forms_contact_title4,
  "APPB2C.cj.personalDetails.forms.personal.change": APPB2C_cj_personalDetails_forms_personal_change4,
  "APPB2C.cj.personalDetails.forms.personal.dob": APPB2C_cj_personalDetails_forms_personal_dob4,
  "APPB2C.cj.personalDetails.forms.personal.dobCta": APPB2C_cj_personalDetails_forms_personal_dobCta4,
  "APPB2C.cj.personalDetails.forms.personal.firstMiddleName": APPB2C_cj_personalDetails_forms_personal_firstMiddleName4,
  "APPB2C.cj.personalDetails.forms.personal.firstName": APPB2C_cj_personalDetails_forms_personal_firstName4,
  "APPB2C.cj.personalDetails.forms.personal.idMatch": APPB2C_cj_personalDetails_forms_personal_idMatch4,
  "APPB2C.cj.personalDetails.forms.personal.lastName": APPB2C_cj_personalDetails_forms_personal_lastName4,
  "APPB2C.cj.personalDetails.forms.personal.name": APPB2C_cj_personalDetails_forms_personal_name4,
  "APPB2C.cj.personalDetails.forms.personal.noLastName": APPB2C_cj_personalDetails_forms_personal_noLastName4,
  "APPB2C.cj.personalDetails.forms.personal.sectionTitle": APPB2C_cj_personalDetails_forms_personal_sectionTitle4,
  "APPB2C.cj.personalDetails.forWho.child": APPB2C_cj_personalDetails_forWho_child4,
  "APPB2C.cj.personalDetails.forWho.childUnder18": APPB2C_cj_personalDetails_forWho_childUnder184,
  "APPB2C.cj.personalDetails.forWho.dob": APPB2C_cj_personalDetails_forWho_dob4,
  "APPB2C.cj.personalDetails.forWho.myself": APPB2C_cj_personalDetails_forWho_myself4,
  "APPB2C.cj.personalDetails.forWho.note": APPB2C_cj_personalDetails_forWho_note4,
  "APPB2C.cj.personalDetails.forWho.title": APPB2C_cj_personalDetails_forWho_title4,
  "APPB2C.cj.personalDetails.gender": APPB2C_cj_personalDetails_gender4,
  "APPB2C.cj.personalDetails.idNote.idMatch": APPB2C_cj_personalDetails_idNote_idMatch4,
  "APPB2C.cj.personalDetails.idNote.idMatch.change": APPB2C_cj_personalDetails_idNote_idMatch_change4,
  "APPB2C.cj.personalDetails.idNote.idMatch.france": APPB2C_cj_personalDetails_idNote_idMatch_france3,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.adult": APPB2C_cj_personalDetails_idNote_idMatch_france_adult3,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.minor": APPB2C_cj_personalDetails_idNote_idMatch_france_minor3,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain": APPB2C_cj_personalDetails_idNote_idMatch_spain3,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.adult": APPB2C_cj_personalDetails_idNote_idMatch_spain_adult3,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.minor": APPB2C_cj_personalDetails_idNote_idMatch_spain_minor3,
  "APPB2C.cj.personalDetails.idNote.whenForChild": APPB2C_cj_personalDetails_idNote_whenForChild4,
  "APPB2C.cj.personalDetails.login.btn": APPB2C_cj_personalDetails_login_btn4,
  "APPB2C.cj.personalDetails.login.emailNotEditable": APPB2C_cj_personalDetails_login_emailNotEditable4,
  "APPB2C.cj.personalDetails.login.link": APPB2C_cj_personalDetails_login_link4,
  "APPB2C.cj.personalDetails.login.loggedIn": APPB2C_cj_personalDetails_login_loggedIn4,
  "APPB2C.cj.personalDetails.login.logout": APPB2C_cj_personalDetails_login_logout4,
  "APPB2C.cj.personalDetails.login.note": APPB2C_cj_personalDetails_login_note4,
  "APPB2C.cj.personalDetails.login.title": APPB2C_cj_personalDetails_login_title4,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.changeDate": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate4,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line1": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line14,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line2": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line24,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.title": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title4,
  "APPB2C.cj.personalDetails.title": APPB2C_cj_personalDetails_title4,
  "APPB2C.cj.personalDetails.tncs.agreement": APPB2C_cj_personalDetails_tncs_agreement4,
  "APPB2C.cj.personalDetails.tncs.title": APPB2C_cj_personalDetails_tncs_title4,
  "APPB2C.cj.personalDetails.visaType.label": APPB2C_cj_personalDetails_visaType_label4,
  "APPB2C.cj.personalDetails.visaType.label.child": APPB2C_cj_personalDetails_visaType_label_child4,
  "APPB2C.cj.personalDetails.visaType.link": APPB2C_cj_personalDetails_visaType_link4,
  "APPB2C.cj.personalDetails.visaType.title": APPB2C_cj_personalDetails_visaType_title4,
  "APPB2C.cj.personalDetails.visaType.title.child": APPB2C_cj_personalDetails_visaType_title_child4,
  "APPB2C.cj.rebook.errorModal.message": APPB2C_cj_rebook_errorModal_message4,
  "APPB2C.cj.rebook.errorModal.title": APPB2C_cj_rebook_errorModal_title4,
  "APPB2C.cj.review.acknowledgement.anotherPerson": APPB2C_cj_review_acknowledgement_anotherPerson4,
  "APPB2C.cj.review.acknowledgement.company": APPB2C_cj_review_acknowledgement_company4,
  "APPB2C.cj.review.acknowledgement.fields.companyName": APPB2C_cj_review_acknowledgement_fields_companyName4,
  "APPB2C.cj.review.acknowledgement.fields.companyName.subnote": APPB2C_cj_review_acknowledgement_fields_companyName_subnote4,
  "APPB2C.cj.review.acknowledgement.fields.country": APPB2C_cj_review_acknowledgement_fields_country4,
  "APPB2C.cj.review.acknowledgement.fields.email": APPB2C_cj_review_acknowledgement_fields_email4,
  "APPB2C.cj.review.acknowledgement.fields.familyName": APPB2C_cj_review_acknowledgement_fields_familyName4,
  "APPB2C.cj.review.acknowledgement.fields.firstName": APPB2C_cj_review_acknowledgement_fields_firstName4,
  "APPB2C.cj.review.acknowledgement.fields.mobile": APPB2C_cj_review_acknowledgement_fields_mobile4,
  "APPB2C.cj.review.acknowledgement.myself": APPB2C_cj_review_acknowledgement_myself4,
  "APPB2C.cj.review.acknowledgement.send": APPB2C_cj_review_acknowledgement_send4,
  "APPB2C.cj.review.acknowledgement.title": APPB2C_cj_review_acknowledgement_title4,
  "APPB2C.cj.review.agent.label": APPB2C_cj_review_agent_label4,
  "APPB2C.cj.review.agent.note": APPB2C_cj_review_agent_note4,
  "APPB2C.cj.review.agent.title": APPB2C_cj_review_agent_title4,
  "APPB2C.cj.review.ai.note": APPB2C_cj_review_ai_note4,
  "APPB2C.cj.review.ai.title": APPB2C_cj_review_ai_title4,
  "APPB2C.cj.review.bookingDetails.lrw.title": APPB2C_cj_review_bookingDetails_lrw_title4,
  "APPB2C.cj.review.bookingDetails.lrw.title.child": APPB2C_cj_review_bookingDetails_lrw_title_child4,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills4,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills.child": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child4,
  "APPB2C.cj.review.bookingDetails.speaking.title": APPB2C_cj_review_bookingDetails_speaking_title4,
  "APPB2C.cj.review.bookingDetails.speaking.title.child": APPB2C_cj_review_bookingDetails_speaking_title_child4,
  "APPB2C.cj.review.bookingDetails.title": APPB2C_cj_review_bookingDetails_title4,
  "APPB2C.cj.review.bookNowNoFee.buttonText": APPB2C_cj_review_bookNowNoFee_buttonText4,
  "APPB2C.cj.review.bookTest": APPB2C_cj_review_bookTest4,
  "APPB2C.cj.review.change": APPB2C_cj_review_change4,
  "APPB2C.cj.review.changeLrwTest": APPB2C_cj_review_changeLrwTest4,
  "APPB2C.cj.review.changeSpeakingTest": APPB2C_cj_review_changeSpeakingTest4,
  "APPB2C.cj.review.changeUkviLsTest": APPB2C_cj_review_changeUkviLsTest4,
  "APPB2C.cj.review.childDetails.guardiansTitle": APPB2C_cj_review_childDetails_guardiansTitle4,
  "APPB2C.cj.review.childDetails.onTestDay": APPB2C_cj_review_childDetails_onTestDay4,
  "APPB2C.cj.review.childDetails.onTestDayOptions.1": APPB2C_cj_review_childDetails_onTestDayOptions_14,
  "APPB2C.cj.review.childDetails.onTestDayOptions.2": APPB2C_cj_review_childDetails_onTestDayOptions_24,
  "APPB2C.cj.review.childDetails.onTestDayOptions.3": APPB2C_cj_review_childDetails_onTestDayOptions_34,
  "APPB2C.cj.review.childDetails.title": APPB2C_cj_review_childDetails_title4,
  "APPB2C.cj.review.confirmPaymentModal.btnNote": APPB2C_cj_review_confirmPaymentModal_btnNote4,
  "APPB2C.cj.review.confirmPaymentModal.note": APPB2C_cj_review_confirmPaymentModal_note4,
  "APPB2C.cj.review.confirmPaymentModal.optionsTitle": APPB2C_cj_review_confirmPaymentModal_optionsTitle4,
  "APPB2C.cj.review.confirmPaymentModal.title": APPB2C_cj_review_confirmPaymentModal_title4,
  "APPB2C.cj.review.countryMsg.pakistan": APPB2C_cj_review_countryMsg_pakistan4,
  "APPB2C.cj.review.error.missingOrderAcknowledgement": APPB2C_cj_review_error_missingOrderAcknowledgement4,
  "APPB2C.cj.review.error.missingPickups": APPB2C_cj_review_error_missingPickups4,
  "APPB2C.cj.review.error.missingTown": APPB2C_cj_review_error_missingTown4,
  "APPB2C.cj.review.error.wrongCountrySetting": APPB2C_cj_review_error_wrongCountrySetting4,
  "APPB2C.cj.review.error.wrongPaymentConfig": APPB2C_cj_review_error_wrongPaymentConfig4,
  "APPB2C.cj.review.error.wrongTaxNumber": APPB2C_cj_review_error_wrongTaxNumber4,
  "APPB2C.cj.review.expiredIdError": APPB2C_cj_review_expiredIdError4,
  "APPB2C.cj.review.idDetails.issuing": APPB2C_cj_review_idDetails_issuing4,
  "APPB2C.cj.review.idDetails.nationality": APPB2C_cj_review_idDetails_nationality4,
  "APPB2C.cj.review.idDetails.title": APPB2C_cj_review_idDetails_title4,
  "APPB2C.cj.review.idDetails.title.child": APPB2C_cj_review_idDetails_title_child4,
  "APPB2C.cj.review.invoice.companyName": APPB2C_cj_review_invoice_companyName4,
  "APPB2C.cj.review.invoice.consent": APPB2C_cj_review_invoice_consent4,
  "APPB2C.cj.review.invoice.send": APPB2C_cj_review_invoice_send4,
  "APPB2C.cj.review.invoice.subtype.citizenDigitalCertificate": APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate4,
  "APPB2C.cj.review.invoice.subtype.label": APPB2C_cj_review_invoice_subtype_label4,
  "APPB2C.cj.review.invoice.subtype.mobileBarcode": APPB2C_cj_review_invoice_subtype_mobileBarcode4,
  "APPB2C.cj.review.invoice.taxCaption.donate": APPB2C_cj_review_invoice_taxCaption_donate4,
  "APPB2C.cj.review.invoice.taxCaption.duplicatePaper": APPB2C_cj_review_invoice_taxCaption_duplicatePaper4,
  "APPB2C.cj.review.invoice.taxValidation.citizenDigitalCertificate": APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate4,
  "APPB2C.cj.review.invoice.taxValidation.donate": APPB2C_cj_review_invoice_taxValidation_donate4,
  "APPB2C.cj.review.invoice.taxValidation.mobileBarcode": APPB2C_cj_review_invoice_taxValidation_mobileBarcode4,
  "APPB2C.cj.review.invoice.taxValidation.triplicate": APPB2C_cj_review_invoice_taxValidation_triplicate4,
  "APPB2C.cj.review.invoice.title": APPB2C_cj_review_invoice_title4,
  "APPB2C.cj.review.invoice.type.donate": APPB2C_cj_review_invoice_type_donate4,
  "APPB2C.cj.review.invoice.type.duplicateElectronic": APPB2C_cj_review_invoice_type_duplicateElectronic4,
  "APPB2C.cj.review.invoice.type.duplicatePaper": APPB2C_cj_review_invoice_type_duplicatePaper4,
  "APPB2C.cj.review.invoice.type.triplicate": APPB2C_cj_review_invoice_type_triplicate4,
  "APPB2C.cj.review.localization.localTime": APPB2C_cj_review_localization_localTime4,
  "APPB2C.cj.review.localization.location": APPB2C_cj_review_localization_location4,
  "APPB2C.cj.review.localization.timezone": APPB2C_cj_review_localization_timezone4,
  "APPB2C.cj.review.missingMarketingCta": APPB2C_cj_review_missingMarketingCta4,
  "APPB2C.cj.review.missingMarketingError": APPB2C_cj_review_missingMarketingError4,
  "APPB2C.cj.review.note.content": APPB2C_cj_review_note_content4,
  "APPB2C.cj.review.note.title": APPB2C_cj_review_note_title4,
  "APPB2C.cj.review.or": APPB2C_cj_review_or4,
  "APPB2C.cj.review.payLater.buttonText": APPB2C_cj_review_payLater_buttonText4,
  "APPB2C.cj.review.payLater.sideNote": APPB2C_cj_review_payLater_sideNote4,
  "APPB2C.cj.review.payment.fee": APPB2C_cj_review_payment_fee4,
  "APPB2C.cj.review.payment.promoCodes.applied": APPB2C_cj_review_payment_promoCodes_applied4,
  "APPB2C.cj.review.payment.promoCodes.applyCode": APPB2C_cj_review_payment_promoCodes_applyCode4,
  "APPB2C.cj.review.payment.promoCodes.discount": APPB2C_cj_review_payment_promoCodes_discount4,
  "APPB2C.cj.review.payment.promoCodes.generalError": APPB2C_cj_review_payment_promoCodes_generalError4,
  "APPB2C.cj.review.payment.promoCodes.haveCode": APPB2C_cj_review_payment_promoCodes_haveCode4,
  "APPB2C.cj.review.payment.promoCodes.invalidCode": APPB2C_cj_review_payment_promoCodes_invalidCode4,
  "APPB2C.cj.review.payment.promoCodes.newFee": APPB2C_cj_review_payment_promoCodes_newFee4,
  "APPB2C.cj.review.payment.promoCodes.notHaveCode": APPB2C_cj_review_payment_promoCodes_notHaveCode4,
  "APPB2C.cj.review.payment.promoCodes.promo": APPB2C_cj_review_payment_promoCodes_promo4,
  "APPB2C.cj.review.payment.promoCodes.promoCode": APPB2C_cj_review_payment_promoCodes_promoCode4,
  "APPB2C.cj.review.payment.promoCodes.remove": APPB2C_cj_review_payment_promoCodes_remove4,
  "APPB2C.cj.review.payment.promoCodes.removeCode": APPB2C_cj_review_payment_promoCodes_removeCode4,
  "APPB2C.cj.review.payment.promoCodes.total": APPB2C_cj_review_payment_promoCodes_total4,
  "APPB2C.cj.review.payment.tax": APPB2C_cj_review_payment_tax4,
  "APPB2C.cj.review.payment.title": APPB2C_cj_review_payment_title4,
  "APPB2C.cj.review.payment.total": APPB2C_cj_review_payment_total4,
  "APPB2C.cj.review.paymentError": APPB2C_cj_review_paymentError4,
  "APPB2C.cj.review.paymentNote": APPB2C_cj_review_paymentNote4,
  "APPB2C.cj.review.paymentNoteMultiple": APPB2C_cj_review_paymentNoteMultiple3,
  "APPB2C.cj.review.paymentNoteSingle": APPB2C_cj_review_paymentNoteSingle3,
  "APPB2C.cj.review.paymentNoteZeroFee": APPB2C_cj_review_paymentNoteZeroFee4,
  "APPB2C.cj.review.payOnline.buttonText": APPB2C_cj_review_payOnline_buttonText4,
  "APPB2C.cj.review.payOnline.sideNote": APPB2C_cj_review_payOnline_sideNote4,
  "APPB2C.cj.review.personalDetails.address": APPB2C_cj_review_personalDetails_address4,
  "APPB2C.cj.review.personalDetails.dob": APPB2C_cj_review_personalDetails_dob4,
  "APPB2C.cj.review.personalDetails.email": APPB2C_cj_review_personalDetails_email4,
  "APPB2C.cj.review.personalDetails.gender": APPB2C_cj_review_personalDetails_gender4,
  "APPB2C.cj.review.personalDetails.name": APPB2C_cj_review_personalDetails_name4,
  "APPB2C.cj.review.personalDetails.phone": APPB2C_cj_review_personalDetails_phone4,
  "APPB2C.cj.review.personalDetails.smsNotificationsConsent": APPB2C_cj_review_personalDetails_smsNotificationsConsent4,
  "APPB2C.cj.review.personalDetails.title": APPB2C_cj_review_personalDetails_title4,
  "APPB2C.cj.review.registrationError": APPB2C_cj_review_registrationError4,
  "APPB2C.cj.review.registrationFailedModal.changedFee.btn": APPB2C_cj_review_registrationFailedModal_changedFee_btn4,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line1": APPB2C_cj_review_registrationFailedModal_changedFee_line14,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line2": APPB2C_cj_review_registrationFailedModal_changedFee_line24,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line3": APPB2C_cj_review_registrationFailedModal_changedFee_line34,
  "APPB2C.cj.review.registrationFailedModal.changedFee.title": APPB2C_cj_review_registrationFailedModal_changedFee_title4,
  "APPB2C.cj.review.registrationFailedModal.cta": APPB2C_cj_review_registrationFailedModal_cta4,
  "APPB2C.cj.review.registrationFailedModal.message": APPB2C_cj_review_registrationFailedModal_message4,
  "APPB2C.cj.review.registrationFailedModal.title": APPB2C_cj_review_registrationFailedModal_title4,
  "APPB2C.cj.review.taxNumberError": APPB2C_cj_review_taxNumberError4,
  "APPB2C.cj.review.title": APPB2C_cj_review_title4,
  "APPB2C.cj.review.tncs.agreement": APPB2C_cj_review_tncs_agreement4,
  "APPB2C.cj.review.tncs.title": APPB2C_cj_review_tncs_title4,
  "APPB2C.cj.review.tooHighPromoCodeError": APPB2C_cj_review_tooHighPromoCodeError4,
  "APPB2C.cj.timer.modal.cta": APPB2C_cj_timer_modal_cta4,
  "APPB2C.cj.timer.modal.message": APPB2C_cj_timer_modal_message4,
  "APPB2C.cj.timer.modal.title": APPB2C_cj_timer_modal_title4,
  "APPB2C.cj.timer.nearEnd.minuteCount": APPB2C_cj_timer_nearEnd_minuteCount4,
  "APPB2C.cj.timer.nearEnd.minuteCount_other": APPB2C_cj_timer_nearEnd_minuteCount_other4,
  "APPB2C.cj.timer.nearEnd.text": APPB2C_cj_timer_nearEnd_text4,
  "APPB2C.cj.timer.nearEnd.title": APPB2C_cj_timer_nearEnd_title4,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied4,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied.child": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child4,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest4,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest.child": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child4,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle": APPB2C_cj_ttProfile_forms_interest_sectionTitle4,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle.child": APPB2C_cj_ttProfile_forms_interest_sectionTitle_child4,
  "APPB2C.cj.ttProfile.forms.occupation.occupationLevel": APPB2C_cj_ttProfile_forms_occupation_occupationLevel4,
  "APPB2C.cj.ttProfile.forms.occupation.occupationSector": APPB2C_cj_ttProfile_forms_occupation_occupationSector4,
  "APPB2C.cj.ttProfile.forms.occupation.sectionTitle": APPB2C_cj_ttProfile_forms_occupation_sectionTitle4,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality": APPB2C_cj_ttProfile_forms_personal_countryOfNationality4,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality.child": APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child4,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.384": APPB2C_cj_ttProfile_forms_personal_educationLevel_3844,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.385": APPB2C_cj_ttProfile_forms_personal_educationLevel_3854,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.386": APPB2C_cj_ttProfile_forms_personal_educationLevel_3864,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.387": APPB2C_cj_ttProfile_forms_personal_educationLevel_3874,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.title": APPB2C_cj_ttProfile_forms_personal_educationLevel_title4,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken4,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken.child": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child4,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle": APPB2C_cj_ttProfile_forms_personal_sectionTitle4,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle.child": APPB2C_cj_ttProfile_forms_personal_sectionTitle_child4,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish4,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child4,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel4,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child4,
  "APPB2C.cj.ttProfile.note": APPB2C_cj_ttProfile_note4,
  "APPB2C.cj.ttProfile.note.child": APPB2C_cj_ttProfile_note_child4,
  "APPB2C.cj.ttProfile.title": APPB2C_cj_ttProfile_title4,
  "APPB2C.cj.ttProfile.title.child": APPB2C_cj_ttProfile_title_child4,
  "APPB2C.cj.ukviStartPage.ac.content": APPB2C_cj_ukviStartPage_ac_content4,
  "APPB2C.cj.ukviStartPage.ac.title": APPB2C_cj_ukviStartPage_ac_title4,
  "APPB2C.cj.ukviStartPage.gt.content": APPB2C_cj_ukviStartPage_gt_content4,
  "APPB2C.cj.ukviStartPage.gt.title": APPB2C_cj_ukviStartPage_gt_title4,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.content": APPB2C_cj_ukviStartPage_lifeSkils_a1_content4,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.title": APPB2C_cj_ukviStartPage_lifeSkils_a1_title4,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.content": APPB2C_cj_ukviStartPage_lifeSkils_a2_content4,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.title": APPB2C_cj_ukviStartPage_lifeSkils_a2_title4,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.content": APPB2C_cj_ukviStartPage_lifeSkils_b1_content4,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.title": APPB2C_cj_ukviStartPage_lifeSkils_b1_title4,
  "APPB2C.cj.ukviStartPage.lifeSkils.citizenship": APPB2C_cj_ukviStartPage_lifeSkils_citizenship4,
  "APPB2C.cj.ukviStartPage.lifeSkils.extension": APPB2C_cj_ukviStartPage_lifeSkils_extension4,
  "APPB2C.cj.ukviStartPage.lifeSkils.family": APPB2C_cj_ukviStartPage_lifeSkils_family4,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.content": APPB2C_cj_ukviStartPage_lifeSkils_note_content4,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.title": APPB2C_cj_ukviStartPage_lifeSkils_note_title4,
  "APPB2C.cj.ukviStartPage.lifeSkils.title": APPB2C_cj_ukviStartPage_lifeSkils_title4,
  "APPB2C.cj.ukviStartPage.lifeSkils.visaTypes": APPB2C_cj_ukviStartPage_lifeSkils_visaTypes4,
  "APPB2C.cj.ukviStartPage.note1.content": APPB2C_cj_ukviStartPage_note1_content4,
  "APPB2C.cj.ukviStartPage.note1.title": APPB2C_cj_ukviStartPage_note1_title4,
  "APPB2C.cj.ukviStartPage.terms": APPB2C_cj_ukviStartPage_terms4,
  "APPB2C.cj.ukviStartPage.testsBelow": APPB2C_cj_ukviStartPage_testsBelow4,
  "APPB2C.cj.ukviStartPage.title": APPB2C_cj_ukviStartPage_title4,
  "APPB2C.cj.ukviStartPage.whichUkvi": APPB2C_cj_ukviStartPage_whichUkvi4,
  "APPB2C.common.account.details.email.cancel": APPB2C_common_account_details_email_cancel3,
  "APPB2C.common.account.details.email.confirm": APPB2C_common_account_details_email_confirm4,
  "APPB2C.common.account.details.email.label": APPB2C_common_account_details_email_label4,
  "APPB2C.common.account.details.email.maxLength": APPB2C_common_account_details_email_maxLength4,
  "APPB2C.common.account.details.email.mismatch": APPB2C_common_account_details_email_mismatch4,
  "APPB2C.common.account.details.email.note": APPB2C_common_account_details_email_note4,
  "APPB2C.common.account.details.email.save": APPB2C_common_account_details_email_save4,
  "APPB2C.common.account.details.email.set": APPB2C_common_account_details_email_set4,
  "APPB2C.common.account.details.email.success": APPB2C_common_account_details_email_success4,
  "APPB2C.common.account.details.email.username": APPB2C_common_account_details_email_username4,
  "APPB2C.common.account.details.email.validEmail": APPB2C_common_account_details_email_validEmail4,
  "APPB2C.common.account.details.name": APPB2C_common_account_details_name4,
  "APPB2C.common.account.details.psw.cancel": APPB2C_common_account_details_psw_cancel3,
  "APPB2C.common.account.details.psw.change": APPB2C_common_account_details_psw_change4,
  "APPB2C.common.account.details.psw.label": APPB2C_common_account_details_psw_label4,
  "APPB2C.common.account.details.psw.new": APPB2C_common_account_details_psw_new4,
  "APPB2C.common.account.details.psw.old": APPB2C_common_account_details_psw_old4,
  "APPB2C.common.account.details.psw.requirements.levels.0": APPB2C_common_account_details_psw_requirements_levels_04,
  "APPB2C.common.account.details.psw.requirements.levels.1": APPB2C_common_account_details_psw_requirements_levels_14,
  "APPB2C.common.account.details.psw.requirements.levels.2": APPB2C_common_account_details_psw_requirements_levels_24,
  "APPB2C.common.account.details.psw.requirements.levels.3": APPB2C_common_account_details_psw_requirements_levels_34,
  "APPB2C.common.account.details.psw.requirements.levels.4": APPB2C_common_account_details_psw_requirements_levels_44,
  "APPB2C.common.account.details.psw.requirements.maxLength": APPB2C_common_account_details_psw_requirements_maxLength4,
  "APPB2C.common.account.details.psw.requirements.mustHave": APPB2C_common_account_details_psw_requirements_mustHave4,
  "APPB2C.common.account.details.psw.requirements.ok": APPB2C_common_account_details_psw_requirements_ok4,
  "APPB2C.common.account.details.psw.requirements.reqs.length": APPB2C_common_account_details_psw_requirements_reqs_length4,
  "APPB2C.common.account.details.psw.requirements.reqs.lower": APPB2C_common_account_details_psw_requirements_reqs_lower4,
  "APPB2C.common.account.details.psw.requirements.reqs.number": APPB2C_common_account_details_psw_requirements_reqs_number4,
  "APPB2C.common.account.details.psw.requirements.reqs.special": APPB2C_common_account_details_psw_requirements_reqs_special4,
  "APPB2C.common.account.details.psw.requirements.reqs.upper": APPB2C_common_account_details_psw_requirements_reqs_upper4,
  "APPB2C.common.account.details.psw.requirements.shouldHave": APPB2C_common_account_details_psw_requirements_shouldHave4,
  "APPB2C.common.account.details.psw.requirements.strength": APPB2C_common_account_details_psw_requirements_strength4,
  "APPB2C.common.account.details.psw.requirements.tooWeak": APPB2C_common_account_details_psw_requirements_tooWeak4,
  "APPB2C.common.account.details.psw.show": APPB2C_common_account_details_psw_show4,
  "APPB2C.common.account.details.psw.success": APPB2C_common_account_details_psw_success4,
  "APPB2C.common.account.details.subnote.address": APPB2C_common_account_details_subnote_address4,
  "APPB2C.common.account.details.subnote.city": APPB2C_common_account_details_subnote_city4,
  "APPB2C.common.account.error": APPB2C_common_account_error4,
  "APPB2C.common.account.marketing.success": APPB2C_common_account_marketing_success4,
  "APPB2C.common.account.marketing.title": APPB2C_common_account_marketing_title4,
  "APPB2C.common.account.smsNotificationSettings.mobileLabel": APPB2C_common_account_smsNotificationSettings_mobileLabel4,
  "APPB2C.common.account.smsNotificationSettings.success": APPB2C_common_account_smsNotificationSettings_success4,
  "APPB2C.common.account.smsNotificationSettings.title": APPB2C_common_account_smsNotificationSettings_title4,
  "APPB2C.common.account.terms.cta": APPB2C_common_account_terms_cta4,
  "APPB2C.common.account.terms.disclaimer": APPB2C_common_account_terms_disclaimer4,
  "APPB2C.common.account.terms.download": APPB2C_common_account_terms_download4,
  "APPB2C.common.account.terms.extra.line1": APPB2C_common_account_terms_extra_line14,
  "APPB2C.common.account.terms.extra.line2": APPB2C_common_account_terms_extra_line24,
  "APPB2C.common.account.terms.extra.line3.1": APPB2C_common_account_terms_extra_line3_14,
  "APPB2C.common.account.terms.extra.line3.2": APPB2C_common_account_terms_extra_line3_24,
  "APPB2C.common.account.terms.extra.title": APPB2C_common_account_terms_extra_title4,
  "APPB2C.common.account.terms.previous": APPB2C_common_account_terms_previous4,
  "APPB2C.common.account.terms.title": APPB2C_common_account_terms_title4,
  "APPB2C.common.account.title": APPB2C_common_account_title4,
  "APPB2C.common.alertBar.afterTest": APPB2C_common_alertBar_afterTest4,
  "APPB2C.common.alertBar.beforeTest": APPB2C_common_alertBar_beforeTest4,
  "APPB2C.common.alertBar.cancelReupload": APPB2C_common_alertBar_cancelReupload4,
  "APPB2C.common.alertBar.check": APPB2C_common_alertBar_check4,
  "APPB2C.common.alertBar.childConsentMissing": APPB2C_common_alertBar_childConsentMissing4,
  "APPB2C.common.alertBar.creds": APPB2C_common_alertBar_creds4,
  "APPB2C.common.alertBar.eorPayment": APPB2C_common_alertBar_eorPayment4,
  "APPB2C.common.alertBar.eorPaymentProof": APPB2C_common_alertBar_eorPaymentProof4,
  "APPB2C.common.alertBar.idReupload": APPB2C_common_alertBar_idReupload4,
  "APPB2C.common.alertBar.idUpload": APPB2C_common_alertBar_idUpload4,
  "APPB2C.common.alertBar.medicalReupload": APPB2C_common_alertBar_medicalReupload4,
  "APPB2C.common.alertBar.medicalUpload": APPB2C_common_alertBar_medicalUpload4,
  "APPB2C.common.alertBar.regPayment": APPB2C_common_alertBar_regPayment4,
  "APPB2C.common.alertBar.regPaymentProof": APPB2C_common_alertBar_regPaymentProof4,
  "APPB2C.common.alertBar.withCreds": APPB2C_common_alertBar_withCreds4,
  "APPB2C.common.basic.add": APPB2C_common_basic_add4,
  "APPB2C.common.basic.backToTop": APPB2C_common_basic_backToTop4,
  "APPB2C.common.basic.bookingDetails.arrival": APPB2C_common_basic_bookingDetails_arrival4,
  "APPB2C.common.basic.bookingDetails.lifeSkills.results": APPB2C_common_basic_bookingDetails_lifeSkills_results4,
  "APPB2C.common.basic.bookingDetails.lifeSkills.title": APPB2C_common_basic_bookingDetails_lifeSkills_title4,
  "APPB2C.common.basic.bookingDetails.lrw.cd": APPB2C_common_basic_bookingDetails_lrw_cd4,
  "APPB2C.common.basic.bookingDetails.lrw.online": APPB2C_common_basic_bookingDetails_lrw_online4,
  "APPB2C.common.basic.bookingDetails.lrw.pb": APPB2C_common_basic_bookingDetails_lrw_pb4,
  "APPB2C.common.basic.bookingDetails.lrw.results": APPB2C_common_basic_bookingDetails_lrw_results4,
  "APPB2C.common.basic.bookingDetails.lrw.title": APPB2C_common_basic_bookingDetails_lrw_title4,
  "APPB2C.common.basic.bookingDetails.osrTitle.listening": APPB2C_common_basic_bookingDetails_osrTitle_listening4,
  "APPB2C.common.basic.bookingDetails.osrTitle.reading": APPB2C_common_basic_bookingDetails_osrTitle_reading4,
  "APPB2C.common.basic.bookingDetails.speaking.f2f": APPB2C_common_basic_bookingDetails_speaking_f2f4,
  "APPB2C.common.basic.bookingDetails.speaking.live": APPB2C_common_basic_bookingDetails_speaking_live4,
  "APPB2C.common.basic.bookingDetails.speaking.online": APPB2C_common_basic_bookingDetails_speaking_online4,
  "APPB2C.common.basic.bookingDetails.speaking.remote": APPB2C_common_basic_bookingDetails_speaking_remote4,
  "APPB2C.common.basic.bookingDetails.speaking.results": APPB2C_common_basic_bookingDetails_speaking_results4,
  "APPB2C.common.basic.bookingDetails.speaking.title": APPB2C_common_basic_bookingDetails_speaking_title4,
  "APPB2C.common.basic.bookPrePaid": APPB2C_common_basic_bookPrePaid4,
  "APPB2C.common.basic.cancel": APPB2C_common_basic_cancel4,
  "APPB2C.common.basic.change": APPB2C_common_basic_change4,
  "APPB2C.common.basic.choose": APPB2C_common_basic_choose4,
  "APPB2C.common.basic.close": APPB2C_common_basic_close4,
  "APPB2C.common.basic.copied": APPB2C_common_basic_copied4,
  "APPB2C.common.basic.copy": APPB2C_common_basic_copy4,
  "APPB2C.common.basic.dateField.day": APPB2C_common_basic_dateField_day4,
  "APPB2C.common.basic.dateField.month": APPB2C_common_basic_dateField_month4,
  "APPB2C.common.basic.dateField.months": APPB2C_common_basic_dateField_months4,
  "APPB2C.common.basic.dateField.year": APPB2C_common_basic_dateField_year4,
  "APPB2C.common.basic.edit": APPB2C_common_basic_edit4,
  "APPB2C.common.basic.errors.contactTestCentre": APPB2C_common_basic_errors_contactTestCentre4,
  "APPB2C.common.basic.errors.network": APPB2C_common_basic_errors_network4,
  "APPB2C.common.basic.errors.noTest": APPB2C_common_basic_errors_noTest4,
  "APPB2C.common.basic.errors.server": APPB2C_common_basic_errors_server4,
  "APPB2C.common.basic.errors.title": APPB2C_common_basic_errors_title4,
  "APPB2C.common.basic.errors.unableToDownloadFile": APPB2C_common_basic_errors_unableToDownloadFile4,
  "APPB2C.common.basic.errors.unknown": APPB2C_common_basic_errors_unknown4,
  "APPB2C.common.basic.expando.collapse": APPB2C_common_basic_expando_collapse4,
  "APPB2C.common.basic.expando.show": APPB2C_common_basic_expando_show4,
  "APPB2C.common.basic.externalLinkTitle": APPB2C_common_basic_externalLinkTitle4,
  "APPB2C.common.basic.failure": APPB2C_common_basic_failure4,
  "APPB2C.common.basic.forms.addressMaxLength": APPB2C_common_basic_forms_addressMaxLength4,
  "APPB2C.common.basic.forms.dobMin": APPB2C_common_basic_forms_dobMin4,
  "APPB2C.common.basic.forms.dobMinIol": APPB2C_common_basic_forms_dobMinIol4,
  "APPB2C.common.basic.forms.dobOutOfRange": APPB2C_common_basic_forms_dobOutOfRange4,
  "APPB2C.common.basic.forms.firstNameMaxLength": APPB2C_common_basic_forms_firstNameMaxLength4,
  "APPB2C.common.basic.forms.idExpiration": APPB2C_common_basic_forms_idExpiration4,
  "APPB2C.common.basic.forms.idMaxLength": APPB2C_common_basic_forms_idMaxLength4,
  "APPB2C.common.basic.forms.idNumber": APPB2C_common_basic_forms_idNumber4,
  "APPB2C.common.basic.forms.idProofRequired": APPB2C_common_basic_forms_idProofRequired4,
  "APPB2C.common.basic.forms.maxLengthMessage": APPB2C_common_basic_forms_maxLengthMessage4,
  "APPB2C.common.basic.forms.minorDobMax": APPB2C_common_basic_forms_minorDobMax4,
  "APPB2C.common.basic.forms.minorDobMin": APPB2C_common_basic_forms_minorDobMin4,
  "APPB2C.common.basic.forms.mobileBypass": APPB2C_common_basic_forms_mobileBypass4,
  "APPB2C.common.basic.forms.mobileLength": APPB2C_common_basic_forms_mobileLength4,
  "APPB2C.common.basic.forms.onlyLatin": APPB2C_common_basic_forms_onlyLatin4,
  "APPB2C.common.basic.forms.postCodeMaxLength": APPB2C_common_basic_forms_postCodeMaxLength4,
  "APPB2C.common.basic.forms.postCodeMessage": APPB2C_common_basic_forms_postCodeMessage4,
  "APPB2C.common.basic.forms.postCodeNotFound": APPB2C_common_basic_forms_postCodeNotFound4,
  "APPB2C.common.basic.forms.requiredMessage": APPB2C_common_basic_forms_requiredMessage4,
  "APPB2C.common.basic.forms.requiredNoSurmane": APPB2C_common_basic_forms_requiredNoSurmane4,
  "APPB2C.common.basic.forms.stateMaxLength": APPB2C_common_basic_forms_stateMaxLength4,
  "APPB2C.common.basic.forms.surnameMaxLength": APPB2C_common_basic_forms_surnameMaxLength4,
  "APPB2C.common.basic.forms.townMaxLength": APPB2C_common_basic_forms_townMaxLength4,
  "APPB2C.common.basic.forms.unsupportedChars": APPB2C_common_basic_forms_unsupportedChars4,
  "APPB2C.common.basic.forms.validMobile": APPB2C_common_basic_forms_validMobile4,
  "APPB2C.common.basic.goBack": APPB2C_common_basic_goBack4,
  "APPB2C.common.basic.loading": APPB2C_common_basic_loading4,
  "APPB2C.common.basic.menu": APPB2C_common_basic_menu4,
  "APPB2C.common.basic.no": APPB2C_common_basic_no4,
  "APPB2C.common.basic.notAllowed.msg": APPB2C_common_basic_notAllowed_msg4,
  "APPB2C.common.basic.notAllowed.title": APPB2C_common_basic_notAllowed_title4,
  "APPB2C.common.basic.notFound.goHome": APPB2C_common_basic_notFound_goHome4,
  "APPB2C.common.basic.notFound.msg": APPB2C_common_basic_notFound_msg4,
  "APPB2C.common.basic.notFound.title": APPB2C_common_basic_notFound_title4,
  "APPB2C.common.basic.notLoading.note": APPB2C_common_basic_notLoading_note4,
  "APPB2C.common.basic.notLoading.refresh": APPB2C_common_basic_notLoading_refresh4,
  "APPB2C.common.basic.notLoading.title": APPB2C_common_basic_notLoading_title4,
  "APPB2C.common.basic.ok": APPB2C_common_basic_ok4,
  "APPB2C.common.basic.on": APPB2C_common_basic_on4,
  "APPB2C.common.basic.oneSecond": APPB2C_common_basic_oneSecond4,
  "APPB2C.common.basic.pleaseNote": APPB2C_common_basic_pleaseNote4,
  "APPB2C.common.basic.retake": APPB2C_common_basic_retake4,
  "APPB2C.common.basic.upload": APPB2C_common_basic_upload4,
  "APPB2C.common.basic.validationError": APPB2C_common_basic_validationError4,
  "APPB2C.common.basic.waitReassurance": APPB2C_common_basic_waitReassurance4,
  "APPB2C.common.basic.wereSorry": APPB2C_common_basic_wereSorry4,
  "APPB2C.common.basic.yes": APPB2C_common_basic_yes4,
  "APPB2C.common.bookTest.changeExamType": APPB2C_common_bookTest_changeExamType4,
  "APPB2C.common.bookTest.filters.afternoon": APPB2C_common_bookTest_filters_afternoon4,
  "APPB2C.common.bookTest.filters.allDates": APPB2C_common_bookTest_filters_allDates4,
  "APPB2C.common.bookTest.filters.anyTime": APPB2C_common_bookTest_filters_anyTime4,
  "APPB2C.common.bookTest.filters.computer": APPB2C_common_bookTest_filters_computer4,
  "APPB2C.common.bookTest.filters.evening": APPB2C_common_bookTest_filters_evening4,
  "APPB2C.common.bookTest.filters.modal.cta": APPB2C_common_bookTest_filters_modal_cta4,
  "APPB2C.common.bookTest.filters.modal.hide": APPB2C_common_bookTest_filters_modal_hide4,
  "APPB2C.common.bookTest.filters.modal.iDontMind": APPB2C_common_bookTest_filters_modal_iDontMind4,
  "APPB2C.common.bookTest.filters.modal.resultsIn": APPB2C_common_bookTest_filters_modal_resultsIn4,
  "APPB2C.common.bookTest.filters.modal.subtitle": APPB2C_common_bookTest_filters_modal_subtitle4,
  "APPB2C.common.bookTest.filters.modal.title": APPB2C_common_bookTest_filters_modal_title4,
  "APPB2C.common.bookTest.filters.modal.whichFormatTest": APPB2C_common_bookTest_filters_modal_whichFormatTest4,
  "APPB2C.common.bookTest.filters.modal.whichTimeOfDay": APPB2C_common_bookTest_filters_modal_whichTimeOfDay4,
  "APPB2C.common.bookTest.filters.morning": APPB2C_common_bookTest_filters_morning4,
  "APPB2C.common.bookTest.filters.paper": APPB2C_common_bookTest_filters_paper4,
  "APPB2C.common.bookTest.filters.seeAllFilters": APPB2C_common_bookTest_filters_seeAllFilters4,
  "APPB2C.common.bookTest.filters.title": APPB2C_common_bookTest_filters_title4,
  "APPB2C.common.bookTest.loadMoreTests": APPB2C_common_bookTest_loadMoreTests4,
  "APPB2C.common.bookTest.noDiscount.msg.countryNotMatched": APPB2C_common_bookTest_noDiscount_msg_countryNotMatched4,
  "APPB2C.common.bookTest.noDiscount.msg.notFound": APPB2C_common_bookTest_noDiscount_msg_notFound4,
  "APPB2C.common.bookTest.noDiscount.msg.notSupported": APPB2C_common_bookTest_noDiscount_msg_notSupported4,
  "APPB2C.common.bookTest.noDiscount.msg.reserved": APPB2C_common_bookTest_noDiscount_msg_reserved4,
  "APPB2C.common.bookTest.noDiscount.msg.used": APPB2C_common_bookTest_noDiscount_msg_used4,
  "APPB2C.common.bookTest.noDiscount.title": APPB2C_common_bookTest_noDiscount_title4,
  "APPB2C.common.bookTest.results.book": APPB2C_common_bookTest_results_book4,
  "APPB2C.common.bookTest.results.bookFor": APPB2C_common_bookTest_results_bookFor4,
  "APPB2C.common.bookTest.results.cd": APPB2C_common_bookTest_results_cd4,
  "APPB2C.common.bookTest.results.changeSpeaking": APPB2C_common_bookTest_results_changeSpeaking4,
  "APPB2C.common.bookTest.results.clearFilters": APPB2C_common_bookTest_results_clearFilters4,
  "APPB2C.common.bookTest.results.fromCurrentLocation": APPB2C_common_bookTest_results_fromCurrentLocation4,
  "APPB2C.common.bookTest.results.lrw": APPB2C_common_bookTest_results_lrw4,
  "APPB2C.common.bookTest.results.noResultsGoBack": APPB2C_common_bookTest_results_noResultsGoBack4,
  "APPB2C.common.bookTest.results.noResultsText": APPB2C_common_bookTest_results_noResultsText4,
  "APPB2C.common.bookTest.results.noResultsTitle": APPB2C_common_bookTest_results_noResultsTitle4,
  "APPB2C.common.bookTest.results.pb": APPB2C_common_bookTest_results_pb4,
  "APPB2C.common.bookTest.results.review": APPB2C_common_bookTest_results_review4,
  "APPB2C.common.bookTest.results.searchText": APPB2C_common_bookTest_results_searchText4,
  "APPB2C.common.bookTest.results.speaking": APPB2C_common_bookTest_results_speaking4,
  "APPB2C.common.bookTest.results.viewOnMap": APPB2C_common_bookTest_results_viewOnMap4,
  "APPB2C.common.bookTest.sortBy.date": APPB2C_common_bookTest_sortBy_date4,
  "APPB2C.common.bookTest.sortBy.distance": APPB2C_common_bookTest_sortBy_distance4,
  "APPB2C.common.bookTest.sortBy.price": APPB2C_common_bookTest_sortBy_price4,
  "APPB2C.common.bookTest.sortBy.title": APPB2C_common_bookTest_sortBy_title4,
  "APPB2C.common.bookTest.title": APPB2C_common_bookTest_title4,
  "APPB2C.common.cancelBooking.alreadyRequested.cta": APPB2C_common_cancelBooking_alreadyRequested_cta4,
  "APPB2C.common.cancelBooking.alreadyRequested.title": APPB2C_common_cancelBooking_alreadyRequested_title4,
  "APPB2C.common.cancelBooking.back": APPB2C_common_cancelBooking_back4,
  "APPB2C.common.cancelBooking.bankDetails.form.accountHolder": APPB2C_common_cancelBooking_bankDetails_form_accountHolder4,
  "APPB2C.common.cancelBooking.bankDetails.form.bankAddress": APPB2C_common_cancelBooking_bankDetails_form_bankAddress4,
  "APPB2C.common.cancelBooking.bankDetails.form.bankName": APPB2C_common_cancelBooking_bankDetails_form_bankName4,
  "APPB2C.common.cancelBooking.bankDetails.form.bankNumber": APPB2C_common_cancelBooking_bankDetails_form_bankNumber4,
  "APPB2C.common.cancelBooking.bankDetails.form.changeCountry": APPB2C_common_cancelBooking_bankDetails_form_changeCountry4,
  "APPB2C.common.cancelBooking.bankDetails.form.city": APPB2C_common_cancelBooking_bankDetails_form_city4,
  "APPB2C.common.cancelBooking.bankDetails.form.country": APPB2C_common_cancelBooking_bankDetails_form_country4,
  "APPB2C.common.cancelBooking.bankDetails.form.holderEmail": APPB2C_common_cancelBooking_bankDetails_form_holderEmail4,
  "APPB2C.common.cancelBooking.bankDetails.form.holderName": APPB2C_common_cancelBooking_bankDetails_form_holderName4,
  "APPB2C.common.cancelBooking.bankDetails.form.iban": APPB2C_common_cancelBooking_bankDetails_form_iban4,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidIban": APPB2C_common_cancelBooking_bankDetails_form_invalidIban4,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidSwift": APPB2C_common_cancelBooking_bankDetails_form_invalidSwift4,
  "APPB2C.common.cancelBooking.bankDetails.form.postcode": APPB2C_common_cancelBooking_bankDetails_form_postcode4,
  "APPB2C.common.cancelBooking.bankDetails.form.sortCode": APPB2C_common_cancelBooking_bankDetails_form_sortCode4,
  "APPB2C.common.cancelBooking.bankDetails.form.swift": APPB2C_common_cancelBooking_bankDetails_form_swift4,
  "APPB2C.common.cancelBooking.bankDetails.note": APPB2C_common_cancelBooking_bankDetails_note4,
  "APPB2C.common.cancelBooking.bankDetails.privacy": APPB2C_common_cancelBooking_bankDetails_privacy4,
  "APPB2C.common.cancelBooking.bankDetails.terms.consent": APPB2C_common_cancelBooking_bankDetails_terms_consent4,
  "APPB2C.common.cancelBooking.bankDetails.terms.title": APPB2C_common_cancelBooking_bankDetails_terms_title4,
  "APPB2C.common.cancelBooking.bankDetails.title": APPB2C_common_cancelBooking_bankDetails_title4,
  "APPB2C.common.cancelBooking.bottomNotes.note1": APPB2C_common_cancelBooking_bottomNotes_note14,
  "APPB2C.common.cancelBooking.bottomNotes.note2": APPB2C_common_cancelBooking_bottomNotes_note24,
  "APPB2C.common.cancelBooking.bottomNotes.note3": APPB2C_common_cancelBooking_bottomNotes_note34,
  "APPB2C.common.cancelBooking.bottomNotes.terms": APPB2C_common_cancelBooking_bottomNotes_terms4,
  "APPB2C.common.cancelBooking.cancelNotes.1": APPB2C_common_cancelBooking_cancelNotes_14,
  "APPB2C.common.cancelBooking.cancelNotes.2": APPB2C_common_cancelBooking_cancelNotes_24,
  "APPB2C.common.cancelBooking.cancelNotes.3": APPB2C_common_cancelBooking_cancelNotes_34,
  "APPB2C.common.cancelBooking.checkTest": APPB2C_common_cancelBooking_checkTest4,
  "APPB2C.common.cancelBooking.cta.addBankDetails": APPB2C_common_cancelBooking_cta_addBankDetails4,
  "APPB2C.common.cancelBooking.cta.goBack": APPB2C_common_cancelBooking_cta_goBack4,
  "APPB2C.common.cancelBooking.cta.submit": APPB2C_common_cancelBooking_cta_submit4,
  "APPB2C.common.cancelBooking.error.cancelledAlready": APPB2C_common_cancelBooking_error_cancelledAlready4,
  "APPB2C.common.cancelBooking.error.missingFile": APPB2C_common_cancelBooking_error_missingFile4,
  "APPB2C.common.cancelBooking.error.requestedAlready": APPB2C_common_cancelBooking_error_requestedAlready4,
  "APPB2C.common.cancelBooking.reason.form.choose": APPB2C_common_cancelBooking_reason_form_choose4,
  "APPB2C.common.cancelBooking.reason.form.freeform": APPB2C_common_cancelBooking_reason_form_freeform4,
  "APPB2C.common.cancelBooking.reason.form.optional": APPB2C_common_cancelBooking_reason_form_optional4,
  "APPB2C.common.cancelBooking.reason.form.requiredEvidence": APPB2C_common_cancelBooking_reason_form_requiredEvidence4,
  "APPB2C.common.cancelBooking.reason.form.requiredReason": APPB2C_common_cancelBooking_reason_form_requiredReason4,
  "APPB2C.common.cancelBooking.reason.form.title": APPB2C_common_cancelBooking_reason_form_title4,
  "APPB2C.common.cancelBooking.reason.note.content": APPB2C_common_cancelBooking_reason_note_content4,
  "APPB2C.common.cancelBooking.reason.note.title": APPB2C_common_cancelBooking_reason_note_title4,
  "APPB2C.common.cancelBooking.subtitle": APPB2C_common_cancelBooking_subtitle4,
  "APPB2C.common.cancelBooking.title": APPB2C_common_cancelBooking_title4,
  "APPB2C.common.cancelBooking.transferNote.cta": APPB2C_common_cancelBooking_transferNote_cta4,
  "APPB2C.common.cancelBooking.transferNote.title": APPB2C_common_cancelBooking_transferNote_title4,
  "APPB2C.common.cancelBooking.transferNote.titleIol": APPB2C_common_cancelBooking_transferNote_titleIol4,
  "APPB2C.common.cancelBooking.upload.mobile": APPB2C_common_cancelBooking_upload_mobile4,
  "APPB2C.common.cancelBooking.upload.notes.1": APPB2C_common_cancelBooking_upload_notes_14,
  "APPB2C.common.cancelBooking.upload.notes.10": APPB2C_common_cancelBooking_upload_notes_104,
  "APPB2C.common.cancelBooking.upload.notes.2": APPB2C_common_cancelBooking_upload_notes_24,
  "APPB2C.common.cancelBooking.upload.notes.3": APPB2C_common_cancelBooking_upload_notes_34,
  "APPB2C.common.cancelBooking.upload.notes.4": APPB2C_common_cancelBooking_upload_notes_44,
  "APPB2C.common.cancelBooking.upload.notes.5": APPB2C_common_cancelBooking_upload_notes_54,
  "APPB2C.common.cancelBooking.upload.notes.6": APPB2C_common_cancelBooking_upload_notes_64,
  "APPB2C.common.cancelBooking.upload.notes.7": APPB2C_common_cancelBooking_upload_notes_74,
  "APPB2C.common.cancelBooking.upload.notes.8": APPB2C_common_cancelBooking_upload_notes_84,
  "APPB2C.common.cancelBooking.upload.requirements.note": APPB2C_common_cancelBooking_upload_requirements_note4,
  "APPB2C.common.cancelBooking.upload.requirements.title": APPB2C_common_cancelBooking_upload_requirements_title4,
  "APPB2C.common.cancelBooking.upload.title": APPB2C_common_cancelBooking_upload_title4,
  "APPB2C.common.changeBooking.cancel.cta": APPB2C_common_changeBooking_cancel_cta4,
  "APPB2C.common.changeBooking.cancel.text": APPB2C_common_changeBooking_cancel_text4,
  "APPB2C.common.changeBooking.goBack": APPB2C_common_changeBooking_goBack4,
  "APPB2C.common.changeBooking.subtitle": APPB2C_common_changeBooking_subtitle4,
  "APPB2C.common.changeBooking.title": APPB2C_common_changeBooking_title4,
  "APPB2C.common.changeBooking.transfer.cta": APPB2C_common_changeBooking_transfer_cta4,
  "APPB2C.common.changeBooking.transfer.text": APPB2C_common_changeBooking_transfer_text4,
  "APPB2C.common.changeBooking.transfer.textIol": APPB2C_common_changeBooking_transfer_textIol4,
  "APPB2C.common.changeBooking.transferNotes.1": APPB2C_common_changeBooking_transferNotes_14,
  "APPB2C.common.changeBooking.transferNotes.2": APPB2C_common_changeBooking_transferNotes_24,
  "APPB2C.common.changeBooking.transferNotesIol.1": APPB2C_common_changeBooking_transferNotesIol_14,
  "APPB2C.common.changeBooking.transferNotesIol.2": APPB2C_common_changeBooking_transferNotesIol_24,
  "APPB2C.common.changeBookingAck.back": APPB2C_common_changeBookingAck_back4,
  "APPB2C.common.changeBookingAck.cancel.banner": APPB2C_common_changeBookingAck_cancel_banner4,
  "APPB2C.common.changeBookingAck.cancel.contact.email": APPB2C_common_changeBookingAck_cancel_contact_email4,
  "APPB2C.common.changeBookingAck.cancel.contact.phone": APPB2C_common_changeBookingAck_cancel_contact_phone4,
  "APPB2C.common.changeBookingAck.cancel.contact.title": APPB2C_common_changeBookingAck_cancel_contact_title4,
  "APPB2C.common.changeBookingAck.cancel.contact.titleIol": APPB2C_common_changeBookingAck_cancel_contact_titleIol4,
  "APPB2C.common.changeBookingAck.cancel.refNr": APPB2C_common_changeBookingAck_cancel_refNr4,
  "APPB2C.common.changeBookingAck.cancel.refunds": APPB2C_common_changeBookingAck_cancel_refunds4,
  "APPB2C.common.changeBookingAck.cancel.respondTime": APPB2C_common_changeBookingAck_cancel_respondTime4,
  "APPB2C.common.changeBookingAck.cancel.terms": APPB2C_common_changeBookingAck_cancel_terms4,
  "APPB2C.common.changeBookingAck.cancel.title": APPB2C_common_changeBookingAck_cancel_title4,
  "APPB2C.common.changeBookingAck.fees": APPB2C_common_changeBookingAck_fees4,
  "APPB2C.common.changeBookingAck.feesIol": APPB2C_common_changeBookingAck_feesIol4,
  "APPB2C.common.changeBookingAck.speaking.banner": APPB2C_common_changeBookingAck_speaking_banner4,
  "APPB2C.common.changeBookingAck.speaking.note.line1": APPB2C_common_changeBookingAck_speaking_note_line14,
  "APPB2C.common.changeBookingAck.speaking.note.line2": APPB2C_common_changeBookingAck_speaking_note_line24,
  "APPB2C.common.changeBookingAck.speaking.note.title": APPB2C_common_changeBookingAck_speaking_note_title4,
  "APPB2C.common.changeBookingAck.speaking.title": APPB2C_common_changeBookingAck_speaking_title4,
  "APPB2C.common.changeBookingAck.terms": APPB2C_common_changeBookingAck_terms4,
  "APPB2C.common.changeBookingAck.transfer.banner": APPB2C_common_changeBookingAck_transfer_banner4,
  "APPB2C.common.changeBookingAck.transfer.note": APPB2C_common_changeBookingAck_transfer_note4,
  "APPB2C.common.changeBookingAck.transfer.respondTime": APPB2C_common_changeBookingAck_transfer_respondTime4,
  "APPB2C.common.changeBookingAck.transfer.title": APPB2C_common_changeBookingAck_transfer_title4,
  "APPB2C.common.changeBookingAck.transferIol.banner": APPB2C_common_changeBookingAck_transferIol_banner4,
  "APPB2C.common.changeBookingAck.transferIol.contact": APPB2C_common_changeBookingAck_transferIol_contact4,
  "APPB2C.common.changeBookingAck.transferIol.note": APPB2C_common_changeBookingAck_transferIol_note4,
  "APPB2C.common.changeBookingAck.transferIol.refNr": APPB2C_common_changeBookingAck_transferIol_refNr4,
  "APPB2C.common.changeBookingAck.transferIol.terms": APPB2C_common_changeBookingAck_transferIol_terms4,
  "APPB2C.common.changeSpeaking.cards.change": APPB2C_common_changeSpeaking_cards_change4,
  "APPB2C.common.changeSpeaking.cards.noSlots": APPB2C_common_changeSpeaking_cards_noSlots4,
  "APPB2C.common.changeSpeaking.cards.noSlots.error": APPB2C_common_changeSpeaking_cards_noSlots_error4,
  "APPB2C.common.changeSpeaking.cards.title": APPB2C_common_changeSpeaking_cards_title4,
  "APPB2C.common.changeSpeaking.cta": APPB2C_common_changeSpeaking_cta4,
  "APPB2C.common.changeSpeaking.modals.noSlots.keepTest": APPB2C_common_changeSpeaking_modals_noSlots_keepTest4,
  "APPB2C.common.changeSpeaking.modals.noSlots.line1": APPB2C_common_changeSpeaking_modals_noSlots_line14,
  "APPB2C.common.changeSpeaking.modals.noSlots.line2": APPB2C_common_changeSpeaking_modals_noSlots_line24,
  "APPB2C.common.changeSpeaking.modals.noSlots.title": APPB2C_common_changeSpeaking_modals_noSlots_title4,
  "APPB2C.common.changeSpeaking.modals.noSlots.transferTest": APPB2C_common_changeSpeaking_modals_noSlots_transferTest4,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.cta": APPB2C_common_changeSpeaking_modals_testNotAvailable_cta4,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line1": APPB2C_common_changeSpeaking_modals_testNotAvailable_line14,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line2": APPB2C_common_changeSpeaking_modals_testNotAvailable_line24,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.title": APPB2C_common_changeSpeaking_modals_testNotAvailable_title4,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.cta": APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta4,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.line1": APPB2C_common_changeSpeaking_modals_transferNotAvailable_line14,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.title": APPB2C_common_changeSpeaking_modals_transferNotAvailable_title4,
  "APPB2C.common.changeSpeaking.note.back": APPB2C_common_changeSpeaking_note_back4,
  "APPB2C.common.changeSpeaking.note.line1": APPB2C_common_changeSpeaking_note_line14,
  "APPB2C.common.changeSpeaking.note.line2": APPB2C_common_changeSpeaking_note_line24,
  "APPB2C.common.changeSpeaking.note.title": APPB2C_common_changeSpeaking_note_title4,
  "APPB2C.common.changeSpeaking.title": APPB2C_common_changeSpeaking_title4,
  "APPB2C.common.chooseTest.difference": APPB2C_common_chooseTest_difference4,
  "APPB2C.common.chooseTest.iol.consent": APPB2C_common_chooseTest_iol_consent4,
  "APPB2C.common.chooseTest.iol.cta": APPB2C_common_chooseTest_iol_cta4,
  "APPB2C.common.chooseTest.iol.desc": APPB2C_common_chooseTest_iol_desc4,
  "APPB2C.common.chooseTest.iol.link": APPB2C_common_chooseTest_iol_link4,
  "APPB2C.common.chooseTest.iol.note.content": APPB2C_common_chooseTest_iol_note_content4,
  "APPB2C.common.chooseTest.iol.note.title": APPB2C_common_chooseTest_iol_note_title4,
  "APPB2C.common.chooseTest.iol.title": APPB2C_common_chooseTest_iol_title4,
  "APPB2C.common.chooseTest.ors.cta": APPB2C_common_chooseTest_ors_cta4,
  "APPB2C.common.chooseTest.ors.descAc": APPB2C_common_chooseTest_ors_descAc4,
  "APPB2C.common.chooseTest.ors.descGt": APPB2C_common_chooseTest_ors_descGt4,
  "APPB2C.common.chooseTest.ors.title": APPB2C_common_chooseTest_ors_title4,
  "APPB2C.common.chooseTest.title": APPB2C_common_chooseTest_title4,
  "APPB2C.common.chooseTest.ukvi.difference": APPB2C_common_chooseTest_ukvi_difference4,
  "APPB2C.common.chooseTest.ukvi.inCentre.cta": APPB2C_common_chooseTest_ukvi_inCentre_cta4,
  "APPB2C.common.chooseTest.ukvi.inCentre.descAc": APPB2C_common_chooseTest_ukvi_inCentre_descAc4,
  "APPB2C.common.chooseTest.ukvi.inCentre.descGt": APPB2C_common_chooseTest_ukvi_inCentre_descGt4,
  "APPB2C.common.chooseTest.ukvi.inCentre.title": APPB2C_common_chooseTest_ukvi_inCentre_title4,
  "APPB2C.common.chooseTest.ukvi.online.consent": APPB2C_common_chooseTest_ukvi_online_consent4,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.symbol": APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol4,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.title": APPB2C_common_chooseTest_ukvi_online_consentBiometric_title4,
  "APPB2C.common.chooseTest.ukvi.online.cta": APPB2C_common_chooseTest_ukvi_online_cta4,
  "APPB2C.common.chooseTest.ukvi.online.link": APPB2C_common_chooseTest_ukvi_online_link4,
  "APPB2C.common.chooseTest.ukvi.online.note.content": APPB2C_common_chooseTest_ukvi_online_note_content4,
  "APPB2C.common.chooseTest.ukvi.online.note.title": APPB2C_common_chooseTest_ukvi_online_note_title4,
  "APPB2C.common.chooseTest.ukvi.online.title": APPB2C_common_chooseTest_ukvi_online_title4,
  "APPB2C.common.draft.cancel": APPB2C_common_draft_cancel3,
  "APPB2C.common.draft.cta.confirm": APPB2C_common_draft_cta_confirm4,
  "APPB2C.common.draft.cta.decline": APPB2C_common_draft_cta_decline4,
  "APPB2C.common.draft.cta.note": APPB2C_common_draft_cta_note4,
  "APPB2C.common.draft.difference": APPB2C_common_draft_difference4,
  "APPB2C.common.draft.edit": APPB2C_common_draft_edit4,
  "APPB2C.common.draft.error.pastDeadline": APPB2C_common_draft_error_pastDeadline4,
  "APPB2C.common.draft.id.cta": APPB2C_common_draft_id_cta4,
  "APPB2C.common.draft.id.documentWithNoExpiryDate": APPB2C_common_draft_id_documentWithNoExpiryDate4,
  "APPB2C.common.draft.id.header": APPB2C_common_draft_id_header4,
  "APPB2C.common.draft.id.issuingAuthority": APPB2C_common_draft_id_issuingAuthority4,
  "APPB2C.common.draft.id.note": APPB2C_common_draft_id_note4,
  "APPB2C.common.draft.id.title": APPB2C_common_draft_id_title4,
  "APPB2C.common.draft.note.line1": APPB2C_common_draft_note_line14,
  "APPB2C.common.draft.note.line2": APPB2C_common_draft_note_line24,
  "APPB2C.common.draft.note.line3": APPB2C_common_draft_note_line34,
  "APPB2C.common.draft.personal.cta": APPB2C_common_draft_personal_cta4,
  "APPB2C.common.draft.personal.header": APPB2C_common_draft_personal_header4,
  "APPB2C.common.draft.personal.note": APPB2C_common_draft_personal_note4,
  "APPB2C.common.draft.personal.title": APPB2C_common_draft_personal_title4,
  "APPB2C.common.draft.title": APPB2C_common_draft_title4,
  "APPB2C.common.examType.iol.ac": APPB2C_common_examType_iol_ac4,
  "APPB2C.common.examType.iol.gt": APPB2C_common_examType_iol_gt4,
  "APPB2C.common.examType.ors.ac": APPB2C_common_examType_ors_ac4,
  "APPB2C.common.examType.ors.gt": APPB2C_common_examType_ors_gt4,
  "APPB2C.common.examType.ors.osr.ac": APPB2C_common_examType_ors_osr_ac4,
  "APPB2C.common.examType.ors.osr.gt": APPB2C_common_examType_ors_osr_gt4,
  "APPB2C.common.examType.ukvi.ac": APPB2C_common_examType_ukvi_ac4,
  "APPB2C.common.examType.ukvi.gt": APPB2C_common_examType_ukvi_gt4,
  "APPB2C.common.examType.ukvi.lfska1": APPB2C_common_examType_ukvi_lfska14,
  "APPB2C.common.examType.ukvi.lfska2": APPB2C_common_examType_ukvi_lfska24,
  "APPB2C.common.examType.ukvi.lfskb1": APPB2C_common_examType_ukvi_lfskb14,
  "APPB2C.common.examType.ukvi.osr.ac": APPB2C_common_examType_ukvi_osr_ac4,
  "APPB2C.common.examType.ukvi.osr.gt": APPB2C_common_examType_ukvi_osr_gt4,
  "APPB2C.common.examType.uol.ac": APPB2C_common_examType_uol_ac4,
  "APPB2C.common.examType.uol.gt": APPB2C_common_examType_uol_gt4,
  "APPB2C.common.filePreview.label": APPB2C_common_filePreview_label4,
  "APPB2C.common.filePreview.pdfPreviewLink": APPB2C_common_filePreview_pdfPreviewLink4,
  "APPB2C.common.filePreview.status": APPB2C_common_filePreview_status4,
  "APPB2C.common.filePreview.statusName.approved": APPB2C_common_filePreview_statusName_approved4,
  "APPB2C.common.filePreview.statusName.deleted": APPB2C_common_filePreview_statusName_deleted4,
  "APPB2C.common.filePreview.statusName.pending": APPB2C_common_filePreview_statusName_pending4,
  "APPB2C.common.filePreview.statusName.rejected": APPB2C_common_filePreview_statusName_rejected4,
  "APPB2C.common.filePreview.statusName.reviewed": APPB2C_common_filePreview_statusName_reviewed4,
  "APPB2C.common.fileUpload.cta": APPB2C_common_fileUpload_cta4,
  "APPB2C.common.fileUpload.exceededSize": APPB2C_common_fileUpload_exceededSize4,
  "APPB2C.common.fileUpload.fileApproved": APPB2C_common_fileUpload_fileApproved4,
  "APPB2C.common.fileUpload.fileOptional": APPB2C_common_fileUpload_fileOptional4,
  "APPB2C.common.fileUpload.fileRequired": APPB2C_common_fileUpload_fileRequired4,
  "APPB2C.common.fileUpload.filesCount": APPB2C_common_fileUpload_filesCount4,
  "APPB2C.common.fileUpload.fileSize": APPB2C_common_fileUpload_fileSize4,
  "APPB2C.common.fileUpload.imgAlt": APPB2C_common_fileUpload_imgAlt4,
  "APPB2C.common.fileUpload.pdfIeMsg": APPB2C_common_fileUpload_pdfIeMsg4,
  "APPB2C.common.fileUpload.pdfPasswordMsg": APPB2C_common_fileUpload_pdfPasswordMsg4,
  "APPB2C.common.fileUpload.remove": APPB2C_common_fileUpload_remove4,
  "APPB2C.common.fileUpload.requirements": APPB2C_common_fileUpload_requirements4,
  "APPB2C.common.fileUpload.success": APPB2C_common_fileUpload_success4,
  "APPB2C.common.findTest.changeCity": APPB2C_common_findTest_changeCity4,
  "APPB2C.common.findTest.changeCountry": APPB2C_common_findTest_changeCountry4,
  "APPB2C.common.findTest.cta": APPB2C_common_findTest_cta4,
  "APPB2C.common.findTest.dates.clear": APPB2C_common_findTest_dates_clear4,
  "APPB2C.common.findTest.dates.from": APPB2C_common_findTest_dates_from4,
  "APPB2C.common.findTest.dates.noDatesSelected": APPB2C_common_findTest_dates_noDatesSelected4,
  "APPB2C.common.findTest.dates.noresults.details": APPB2C_common_findTest_dates_noresults_details4,
  "APPB2C.common.findTest.dates.noresults.title": APPB2C_common_findTest_dates_noresults_title4,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.cta": APPB2C_common_findTest_dates_noresultsMobileLocation_cta4,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.details": APPB2C_common_findTest_dates_noresultsMobileLocation_details4,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.title": APPB2C_common_findTest_dates_noresultsMobileLocation_title4,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.details": APPB2C_common_findTest_dates_noresultsSpecialReqs_details4,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.title": APPB2C_common_findTest_dates_noresultsSpecialReqs_title4,
  "APPB2C.common.findTest.dates.pleaseSelect": APPB2C_common_findTest_dates_pleaseSelect4,
  "APPB2C.common.findTest.dates.selected": APPB2C_common_findTest_dates_selected4,
  "APPB2C.common.findTest.dates.showAll": APPB2C_common_findTest_dates_showAll4,
  "APPB2C.common.findTest.dates.showDateRange": APPB2C_common_findTest_dates_showDateRange4,
  "APPB2C.common.findTest.dates.title": APPB2C_common_findTest_dates_title4,
  "APPB2C.common.findTest.dates.to": APPB2C_common_findTest_dates_to4,
  "APPB2C.common.findTest.examFormat.all": APPB2C_common_findTest_examFormat_all4,
  "APPB2C.common.findTest.examFormat.cd": APPB2C_common_findTest_examFormat_cd4,
  "APPB2C.common.findTest.examFormat.pb": APPB2C_common_findTest_examFormat_pb4,
  "APPB2C.common.findTest.examFormat.results": APPB2C_common_findTest_examFormat_results4,
  "APPB2C.common.findTest.examFormat.title": APPB2C_common_findTest_examFormat_title4,
  "APPB2C.common.findTest.iol.noLrwResults": APPB2C_common_findTest_iol_noLrwResults4,
  "APPB2C.common.findTest.iol.organisationCheck.check": APPB2C_common_findTest_iol_organisationCheck_check4,
  "APPB2C.common.findTest.iol.organisationCheck.desc": APPB2C_common_findTest_iol_organisationCheck_desc4,
  "APPB2C.common.findTest.iol.organisationCheck.title": APPB2C_common_findTest_iol_organisationCheck_title4,
  "APPB2C.common.findTest.iol.softwareCheck.check": APPB2C_common_findTest_iol_softwareCheck_check4,
  "APPB2C.common.findTest.iol.softwareCheck.desc1": APPB2C_common_findTest_iol_softwareCheck_desc14,
  "APPB2C.common.findTest.iol.softwareCheck.desc2": APPB2C_common_findTest_iol_softwareCheck_desc24,
  "APPB2C.common.findTest.iol.softwareCheck.link": APPB2C_common_findTest_iol_softwareCheck_link4,
  "APPB2C.common.findTest.iol.softwareCheck.title": APPB2C_common_findTest_iol_softwareCheck_title4,
  "APPB2C.common.findTest.langModal.choose": APPB2C_common_findTest_langModal_choose4,
  "APPB2C.common.findTest.langModal.chooseSingle": APPB2C_common_findTest_langModal_chooseSingle4,
  "APPB2C.common.findTest.langModal.continue": APPB2C_common_findTest_langModal_continue4,
  "APPB2C.common.findTest.langModal.title": APPB2C_common_findTest_langModal_title4,
  "APPB2C.common.findTest.multipleLocations": APPB2C_common_findTest_multipleLocations4,
  "APPB2C.common.findTest.osrInfo": APPB2C_common_findTest_osrInfo4,
  "APPB2C.common.findTest.osrInfoLink": APPB2C_common_findTest_osrInfoLink4,
  "APPB2C.common.findTest.residencyConfirmation.btn": APPB2C_common_findTest_residencyConfirmation_btn4,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.btn": APPB2C_common_findTest_residencyConfirmation_errorMsg_btn4,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line1": APPB2C_common_findTest_residencyConfirmation_errorMsg_line14,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line2": APPB2C_common_findTest_residencyConfirmation_errorMsg_line24,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.title": APPB2C_common_findTest_residencyConfirmation_errorMsg_title4,
  "APPB2C.common.findTest.residencyConfirmation.msg": APPB2C_common_findTest_residencyConfirmation_msg3,
  "APPB2C.common.findTest.residencyConfirmation.msg.line1": APPB2C_common_findTest_residencyConfirmation_msg_line14,
  "APPB2C.common.findTest.residencyConfirmation.msg.line2": APPB2C_common_findTest_residencyConfirmation_msg_line24,
  "APPB2C.common.findTest.residencyConfirmation.msg.line3": APPB2C_common_findTest_residencyConfirmation_msg_line34,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInCountryLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine24,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInLocationLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine24,
  "APPB2C.common.findTest.residencyConfirmation.selectDates": APPB2C_common_findTest_residencyConfirmation_selectDates4,
  "APPB2C.common.findTest.residencyConfirmation.title": APPB2C_common_findTest_residencyConfirmation_title4,
  "APPB2C.common.findTest.searchIn": APPB2C_common_findTest_searchIn4,
  "APPB2C.common.findTest.selectCountry.continue": APPB2C_common_findTest_selectCountry_continue4,
  "APPB2C.common.findTest.selectCountry.countryNotSupported": APPB2C_common_findTest_selectCountry_countryNotSupported4,
  "APPB2C.common.findTest.selectCountry.label": APPB2C_common_findTest_selectCountry_label4,
  "APPB2C.common.findTest.selectCountry.placeholder": APPB2C_common_findTest_selectCountry_placeholder4,
  "APPB2C.common.findTest.selectLocation.closestToMe": APPB2C_common_findTest_selectLocation_closestToMe4,
  "APPB2C.common.findTest.selectLocation.geolocationBlocked": APPB2C_common_findTest_selectLocation_geolocationBlocked4,
  "APPB2C.common.findTest.selectLocation.label": APPB2C_common_findTest_selectLocation_label4,
  "APPB2C.common.findTest.selectLocation.noExamForThisCountry": APPB2C_common_findTest_selectLocation_noExamForThisCountry4,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation4,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.cta": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta4,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option1": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option14,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option2": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option24,
  "APPB2C.common.findTest.specialReqs.findOutMore": APPB2C_common_findTest_specialReqs_findOutMore4,
  "APPB2C.common.findTest.specialReqs.list.subtitle": APPB2C_common_findTest_specialReqs_list_subtitle4,
  "APPB2C.common.findTest.specialReqs.list.title": APPB2C_common_findTest_specialReqs_list_title4,
  "APPB2C.common.findTest.specialReqs.needCheckbox": APPB2C_common_findTest_specialReqs_needCheckbox4,
  "APPB2C.common.findTest.specialReqs.other": APPB2C_common_findTest_specialReqs_other4,
  "APPB2C.common.findTest.specialReqs.pleaseNoteMessage": APPB2C_common_findTest_specialReqs_pleaseNoteMessage4,
  "APPB2C.common.findTest.subtitle": APPB2C_common_findTest_subtitle4,
  "APPB2C.common.findTest.title": APPB2C_common_findTest_title4,
  "APPB2C.common.findTest.uol.organisationCheck.check": APPB2C_common_findTest_uol_organisationCheck_check4,
  "APPB2C.common.findTest.uol.organisationCheck.desc": APPB2C_common_findTest_uol_organisationCheck_desc4,
  "APPB2C.common.findTest.uol.organisationCheck.title": APPB2C_common_findTest_uol_organisationCheck_title4,
  "APPB2C.common.findTest.uol.softwareCheck.check": APPB2C_common_findTest_uol_softwareCheck_check4,
  "APPB2C.common.findTest.uol.softwareCheck.desc1": APPB2C_common_findTest_uol_softwareCheck_desc14,
  "APPB2C.common.findTest.uol.softwareCheck.desc2": APPB2C_common_findTest_uol_softwareCheck_desc24,
  "APPB2C.common.findTest.uol.softwareCheck.link": APPB2C_common_findTest_uol_softwareCheck_link4,
  "APPB2C.common.findTest.uol.softwareCheck.title": APPB2C_common_findTest_uol_softwareCheck_title4,
  "APPB2C.common.footer.terms.accessibility": APPB2C_common_footer_terms_accessibility4,
  "APPB2C.common.footer.terms.copyrightLine1": APPB2C_common_footer_terms_copyrightLine14,
  "APPB2C.common.footer.terms.copyrightLine2": APPB2C_common_footer_terms_copyrightLine24,
  "APPB2C.common.footer.terms.dataProtection": APPB2C_common_footer_terms_dataProtection4,
  "APPB2C.common.footer.terms.privacyAndCookies": APPB2C_common_footer_terms_privacyAndCookies4,
  "APPB2C.common.footer.terms.termsOfUse": APPB2C_common_footer_terms_termsOfUse4,
  "APPB2C.common.gelRedirect.errorMessage": APPB2C_common_gelRedirect_errorMessage4,
  "APPB2C.common.gelRedirect.goBack": APPB2C_common_gelRedirect_goBack3,
  "APPB2C.common.gelRedirect.header": APPB2C_common_gelRedirect_header4,
  "APPB2C.common.gelRedirect.info": APPB2C_common_gelRedirect_info4,
  "APPB2C.common.general.next": APPB2C_common_general_next4,
  "APPB2C.common.general.previous": APPB2C_common_general_previous4,
  "APPB2C.common.general.regNotFound": APPB2C_common_general_regNotFound4,
  "APPB2C.common.general.remove": APPB2C_common_general_remove4,
  "APPB2C.common.general.steps": APPB2C_common_general_steps4,
  "APPB2C.common.general.stepsContinued": APPB2C_common_general_stepsContinued4,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabel": APPB2C_common_helpAndContact_actionSection_contactFormLabel4,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabelInCentre": APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre4,
  "APPB2C.common.helpAndContact.actionSection.contactFormLink": APPB2C_common_helpAndContact_actionSection_contactFormLink4,
  "APPB2C.common.helpAndContact.actionSection.faqLabel": APPB2C_common_helpAndContact_actionSection_faqLabel4,
  "APPB2C.common.helpAndContact.actionSection.faqLink": APPB2C_common_helpAndContact_actionSection_faqLink4,
  "APPB2C.common.helpAndContact.actionSection.icHeader": APPB2C_common_helpAndContact_actionSection_icHeader4,
  "APPB2C.common.helpAndContact.actionSection.iolHeader": APPB2C_common_helpAndContact_actionSection_iolHeader4,
  "APPB2C.common.helpAndContact.actionSection.launchChatBtn": APPB2C_common_helpAndContact_actionSection_launchChatBtn4,
  "APPB2C.common.helpAndContact.actionSection.launchChatLabel": APPB2C_common_helpAndContact_actionSection_launchChatLabel4,
  "APPB2C.common.helpAndContact.form.back": APPB2C_common_helpAndContact_form_back4,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLink": APPB2C_common_helpAndContact_form_complaint_moreInfoLink4,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLinkTitle": APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle4,
  "APPB2C.common.helpAndContact.form.complaint.radio.complaint": APPB2C_common_helpAndContact_form_complaint_radio_complaint4,
  "APPB2C.common.helpAndContact.form.complaint.radio.other": APPB2C_common_helpAndContact_form_complaint_radio_other4,
  "APPB2C.common.helpAndContact.form.complaint.title": APPB2C_common_helpAndContact_form_complaint_title4,
  "APPB2C.common.helpAndContact.form.label": APPB2C_common_helpAndContact_form_label4,
  "APPB2C.common.helpAndContact.form.legal": APPB2C_common_helpAndContact_form_legal4,
  "APPB2C.common.helpAndContact.form.response": APPB2C_common_helpAndContact_form_response4,
  "APPB2C.common.helpAndContact.form.submit": APPB2C_common_helpAndContact_form_submit4,
  "APPB2C.common.helpAndContact.form.thanks.back": APPB2C_common_helpAndContact_form_thanks_back4,
  "APPB2C.common.helpAndContact.form.thanks.banner": APPB2C_common_helpAndContact_form_thanks_banner4,
  "APPB2C.common.helpAndContact.form.thanks.info": APPB2C_common_helpAndContact_form_thanks_info4,
  "APPB2C.common.helpAndContact.form.thanks.title": APPB2C_common_helpAndContact_form_thanks_title4,
  "APPB2C.common.helpAndContact.form.title": APPB2C_common_helpAndContact_form_title4,
  "APPB2C.common.helpAndContact.form.validation": APPB2C_common_helpAndContact_form_validation4,
  "APPB2C.common.helpAndContact.icContent.commonQuestionsHeader": APPB2C_common_helpAndContact_icContent_commonQuestionsHeader4,
  "APPB2C.common.helpAndContact.icContent.emailLabel": APPB2C_common_helpAndContact_icContent_emailLabel4,
  "APPB2C.common.helpAndContact.icContent.header": APPB2C_common_helpAndContact_icContent_header4,
  "APPB2C.common.helpAndContact.icContent.phoneLabel": APPB2C_common_helpAndContact_icContent_phoneLabel4,
  "APPB2C.common.helpAndContact.icContent.referenceReminder": APPB2C_common_helpAndContact_icContent_referenceReminder4,
  "APPB2C.common.helpAndContact.ieltsReference": APPB2C_common_helpAndContact_ieltsReference4,
  "APPB2C.common.helpAndContact.noTests.bookNewBtn": APPB2C_common_helpAndContact_noTests_bookNewBtn4,
  "APPB2C.common.helpAndContact.noTests.contactLink": APPB2C_common_helpAndContact_noTests_contactLink4,
  "APPB2C.common.helpAndContact.noTests.header": APPB2C_common_helpAndContact_noTests_header4,
  "APPB2C.common.helpAndContact.selectExamLabel": APPB2C_common_helpAndContact_selectExamLabel4,
  "APPB2C.common.helpAndContact.title": APPB2C_common_helpAndContact_title4,
  "APPB2C.common.invitation.modal.header": APPB2C_common_invitation_modal_header4,
  "APPB2C.common.invitation.modal.invalid": APPB2C_common_invitation_modal_invalid4,
  "APPB2C.common.modals.newTermsAndConditions.checkboxNote": APPB2C_common_modals_newTermsAndConditions_checkboxNote4,
  "APPB2C.common.modals.newTermsAndConditions.checkboxTC": APPB2C_common_modals_newTermsAndConditions_checkboxTC4,
  "APPB2C.common.modals.newTermsAndConditions.cta": APPB2C_common_modals_newTermsAndConditions_cta4,
  "APPB2C.common.modals.newTermsAndConditions.error": APPB2C_common_modals_newTermsAndConditions_error4,
  "APPB2C.common.modals.newTermsAndConditions.note": APPB2C_common_modals_newTermsAndConditions_note4,
  "APPB2C.common.modals.newTermsAndConditions.title": APPB2C_common_modals_newTermsAndConditions_title4,
  "APPB2C.common.modals.newTermsAndConditions.trigger": APPB2C_common_modals_newTermsAndConditions_trigger4,
  "APPB2C.common.nav.bookNewTest": APPB2C_common_nav_bookNewTest4,
  "APPB2C.common.nav.chooseLanguage": APPB2C_common_nav_chooseLanguage4,
  "APPB2C.common.nav.hello": APPB2C_common_nav_hello4,
  "APPB2C.common.nav.helpAndContact": APPB2C_common_nav_helpAndContact4,
  "APPB2C.common.nav.login": APPB2C_common_nav_login4,
  "APPB2C.common.nav.logout": APPB2C_common_nav_logout4,
  "APPB2C.common.nav.logoutModal.cancel": APPB2C_common_nav_logoutModal_cancel3,
  "APPB2C.common.nav.logoutModal.confirm": APPB2C_common_nav_logoutModal_confirm4,
  "APPB2C.common.nav.logoutModal.message": APPB2C_common_nav_logoutModal_message4,
  "APPB2C.common.nav.logoutModal.question": APPB2C_common_nav_logoutModal_question4,
  "APPB2C.common.nav.logoutModal.title": APPB2C_common_nav_logoutModal_title4,
  "APPB2C.common.nav.myAccount": APPB2C_common_nav_myAccount4,
  "APPB2C.common.nav.preparation": APPB2C_common_nav_preparation4,
  "APPB2C.common.nav.resultsTests": APPB2C_common_nav_resultsTests4,
  "APPB2C.common.nav.ttp": APPB2C_common_nav_ttp4,
  "APPB2C.common.preparation.noTests.contactLink": APPB2C_common_preparation_noTests_contactLink4,
  "APPB2C.common.preparation.noTests.header": APPB2C_common_preparation_noTests_header4,
  "APPB2C.common.preparation.selectExamLabel": APPB2C_common_preparation_selectExamLabel4,
  "APPB2C.common.preparation.title": APPB2C_common_preparation_title4,
  "APPB2C.common.preparation.viewTestBtn": APPB2C_common_preparation_viewTestBtn4,
  "APPB2C.common.recreateReg.errMsg": APPB2C_common_recreateReg_errMsg4,
  "APPB2C.common.recreateReg.errTitle": APPB2C_common_recreateReg_errTitle4,
  "APPB2C.common.recreateReg.progress": APPB2C_common_recreateReg_progress4,
  "APPB2C.common.regHeader.reference": APPB2C_common_regHeader_reference4,
  "APPB2C.common.regHeader.trfLoading": APPB2C_common_regHeader_trfLoading4,
  "APPB2C.common.regHeader.trfNumber": APPB2C_common_regHeader_trfNumber4,
  "APPB2C.common.regInProgressModal.continue": APPB2C_common_regInProgressModal_continue4,
  "APPB2C.common.regInProgressModal.message1": APPB2C_common_regInProgressModal_message14,
  "APPB2C.common.regInProgressModal.message2": APPB2C_common_regInProgressModal_message24,
  "APPB2C.common.regInProgressModal.restart": APPB2C_common_regInProgressModal_restart4,
  "APPB2C.common.regInProgressModal.title": APPB2C_common_regInProgressModal_title4,
  "APPB2C.common.selfServices.requested.cancellation": APPB2C_common_selfServices_requested_cancellation4,
  "APPB2C.common.selfServices.requested.transfer": APPB2C_common_selfServices_requested_transfer4,
  "APPB2C.common.specialReqs.AMANU": APPB2C_common_specialReqs_AMANU4,
  "APPB2C.common.specialReqs.ANTHR": APPB2C_common_specialReqs_ANTHR4,
  "APPB2C.common.specialReqs.BTP": APPB2C_common_specialReqs_BTP4,
  "APPB2C.common.specialReqs.BWP": APPB2C_common_specialReqs_BWP4,
  "APPB2C.common.specialReqs.EPTP": APPB2C_common_specialReqs_EPTP4,
  "APPB2C.common.specialReqs.ET": APPB2C_common_specialReqs_ET4,
  "APPB2C.common.specialReqs.LRTV": APPB2C_common_specialReqs_LRTV4,
  "APPB2C.common.specialReqs.SAE": APPB2C_common_specialReqs_SAE4,
  "APPB2C.common.specialReqs.VAS": APPB2C_common_specialReqs_VAS4,
  "APPB2C.common.specialReqs.WP": APPB2C_common_specialReqs_WP4,
  "APPB2C.common.startPage.book": APPB2C_common_startPage_book4,
  "APPB2C.common.startPage.candidates.nowAdult": APPB2C_common_startPage_candidates_nowAdult4,
  "APPB2C.common.startPage.candidates.showingFor": APPB2C_common_startPage_candidates_showingFor4,
  "APPB2C.common.startPage.common.cancelled": APPB2C_common_startPage_common_cancelled4,
  "APPB2C.common.startPage.description.ac": APPB2C_common_startPage_description_ac4,
  "APPB2C.common.startPage.description.gt": APPB2C_common_startPage_description_gt4,
  "APPB2C.common.startPage.description.ukvi": APPB2C_common_startPage_description_ukvi4,
  "APPB2C.common.startPage.endNote": APPB2C_common_startPage_endNote4,
  "APPB2C.common.startPage.error.body": APPB2C_common_startPage_error_body4,
  "APPB2C.common.startPage.error.cta": APPB2C_common_startPage_error_cta4,
  "APPB2C.common.startPage.error.title": APPB2C_common_startPage_error_title4,
  "APPB2C.common.startPage.hero.cta": APPB2C_common_startPage_hero_cta4,
  "APPB2C.common.startPage.hero.subtitle": APPB2C_common_startPage_hero_subtitle4,
  "APPB2C.common.startPage.hero.title": APPB2C_common_startPage_hero_title4,
  "APPB2C.common.startPage.ielts": APPB2C_common_startPage_ielts4,
  "APPB2C.common.startPage.ieltsUkvi": APPB2C_common_startPage_ieltsUkvi4,
  "APPB2C.common.startPage.missing.cancel": APPB2C_common_startPage_missing_cancel4,
  "APPB2C.common.startPage.missing.marketing.cta": APPB2C_common_startPage_missing_marketing_cta4,
  "APPB2C.common.startPage.missing.marketing.notes.full1": APPB2C_common_startPage_missing_marketing_notes_full14,
  "APPB2C.common.startPage.missing.marketing.notes.full2": APPB2C_common_startPage_missing_marketing_notes_full24,
  "APPB2C.common.startPage.missing.marketing.notes.full3": APPB2C_common_startPage_missing_marketing_notes_full34,
  "APPB2C.common.startPage.missing.marketing.notes.link": APPB2C_common_startPage_missing_marketing_notes_link4,
  "APPB2C.common.startPage.missing.marketing.notes.main": APPB2C_common_startPage_missing_marketing_notes_main4,
  "APPB2C.common.startPage.missing.marketing.opts.BcAndThirdParties": APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties4,
  "APPB2C.common.startPage.missing.marketing.opts.BcOnly": APPB2C_common_startPage_missing_marketing_opts_BcOnly4,
  "APPB2C.common.startPage.missing.marketing.opts.DoNotSend": APPB2C_common_startPage_missing_marketing_opts_DoNotSend4,
  "APPB2C.common.startPage.missing.marketing.title": APPB2C_common_startPage_missing_marketing_title4,
  "APPB2C.common.startPage.missing.ttData.cta": APPB2C_common_startPage_missing_ttData_cta4,
  "APPB2C.common.startPage.missing.ttData.dob": APPB2C_common_startPage_missing_ttData_dob4,
  "APPB2C.common.startPage.missing.ttData.firstName": APPB2C_common_startPage_missing_ttData_firstName4,
  "APPB2C.common.startPage.missing.ttData.lastName": APPB2C_common_startPage_missing_ttData_lastName4,
  "APPB2C.common.startPage.missing.ttData.line1": APPB2C_common_startPage_missing_ttData_line14,
  "APPB2C.common.startPage.missing.ttData.line2": APPB2C_common_startPage_missing_ttData_line24,
  "APPB2C.common.startPage.missing.ttData.line3": APPB2C_common_startPage_missing_ttData_line34,
  "APPB2C.common.startPage.missing.ttData.noLastName": APPB2C_common_startPage_missing_ttData_noLastName4,
  "APPB2C.common.startPage.missing.ttData.title": APPB2C_common_startPage_missing_ttData_title4,
  "APPB2C.common.startPage.options.ac": APPB2C_common_startPage_options_ac4,
  "APPB2C.common.startPage.options.gt": APPB2C_common_startPage_options_gt4,
  "APPB2C.common.startPage.options.ukvi": APPB2C_common_startPage_options_ukvi4,
  "APPB2C.common.startPage.past.cd": APPB2C_common_startPage_past_cd4,
  "APPB2C.common.startPage.past.empty": APPB2C_common_startPage_past_empty4,
  "APPB2C.common.startPage.past.osr.newScore": APPB2C_common_startPage_past_osr_newScore4,
  "APPB2C.common.startPage.past.osr.note.awaiting": APPB2C_common_startPage_past_osr_note_awaiting4,
  "APPB2C.common.startPage.past.osr.note.confirmed": APPB2C_common_startPage_past_osr_note_confirmed4,
  "APPB2C.common.startPage.past.osr.note.hasResults": APPB2C_common_startPage_past_osr_note_hasResults4,
  "APPB2C.common.startPage.past.osr.originalScore": APPB2C_common_startPage_past_osr_originalScore4,
  "APPB2C.common.startPage.past.osr.viewRetakeLink": APPB2C_common_startPage_past_osr_viewRetakeLink4,
  "APPB2C.common.startPage.past.pb": APPB2C_common_startPage_past_pb4,
  "APPB2C.common.startPage.past.rebook": APPB2C_common_startPage_past_rebook4,
  "APPB2C.common.startPage.past.score": APPB2C_common_startPage_past_score4,
  "APPB2C.common.startPage.past.scoreLifeSkills": APPB2C_common_startPage_past_scoreLifeSkills4,
  "APPB2C.common.startPage.past.title": APPB2C_common_startPage_past_title4,
  "APPB2C.common.startPage.past.upcomingResults": APPB2C_common_startPage_past_upcomingResults4,
  "APPB2C.common.startPage.readTnCs": APPB2C_common_startPage_readTnCs4,
  "APPB2C.common.startPage.recent.cta": APPB2C_common_startPage_recent_cta4,
  "APPB2C.common.startPage.recent.title": APPB2C_common_startPage_recent_title4,
  "APPB2C.common.startPage.subtitle": APPB2C_common_startPage_subtitle4,
  "APPB2C.common.startPage.title": APPB2C_common_startPage_title4,
  "APPB2C.common.startPage.tncs": APPB2C_common_startPage_tncs4,
  "APPB2C.common.startPage.upcoming.cd": APPB2C_common_startPage_upcoming_cd4,
  "APPB2C.common.startPage.upcoming.empty": APPB2C_common_startPage_upcoming_empty4,
  "APPB2C.common.startPage.upcoming.lifeSkills": APPB2C_common_startPage_upcoming_lifeSkills4,
  "APPB2C.common.startPage.upcoming.live": APPB2C_common_startPage_upcoming_live4,
  "APPB2C.common.startPage.upcoming.manage": APPB2C_common_startPage_upcoming_manage4,
  "APPB2C.common.startPage.upcoming.missing.childConsent": APPB2C_common_startPage_upcoming_missing_childConsent4,
  "APPB2C.common.startPage.upcoming.missing.id": APPB2C_common_startPage_upcoming_missing_id4,
  "APPB2C.common.startPage.upcoming.missing.medical": APPB2C_common_startPage_upcoming_missing_medical4,
  "APPB2C.common.startPage.upcoming.missing.payment": APPB2C_common_startPage_upcoming_missing_payment4,
  "APPB2C.common.startPage.upcoming.noSpeaking": APPB2C_common_startPage_upcoming_noSpeaking4,
  "APPB2C.common.startPage.upcoming.noTime": APPB2C_common_startPage_upcoming_noTime4,
  "APPB2C.common.startPage.upcoming.osr.note": APPB2C_common_startPage_upcoming_osr_note4,
  "APPB2C.common.startPage.upcoming.pastPaymentDeadline": APPB2C_common_startPage_upcoming_pastPaymentDeadline4,
  "APPB2C.common.startPage.upcoming.pay": APPB2C_common_startPage_upcoming_pay4,
  "APPB2C.common.startPage.upcoming.pb": APPB2C_common_startPage_upcoming_pb4,
  "APPB2C.common.startPage.upcoming.prepare": APPB2C_common_startPage_upcoming_prepare4,
  "APPB2C.common.startPage.upcoming.remote": APPB2C_common_startPage_upcoming_remote4,
  "APPB2C.common.startPage.upcoming.title": APPB2C_common_startPage_upcoming_title4,
  "APPB2C.common.startPage.whichIELTS": APPB2C_common_startPage_whichIELTS4,
  "APPB2C.common.terms.downloadInfo": APPB2C_common_terms_downloadInfo4,
  "APPB2C.common.terms.downloadTitle": APPB2C_common_terms_downloadTitle4,
  "APPB2C.common.terms.error": APPB2C_common_terms_error4,
  "APPB2C.common.terms.title": APPB2C_common_terms_title4,
  "APPB2C.common.testDetails.changeTest": APPB2C_common_testDetails_changeTest4,
  "APPB2C.common.testDetails.changeWrittenTest": APPB2C_common_testDetails_changeWrittenTest4,
  "APPB2C.common.testDetails.completeYourBooking": APPB2C_common_testDetails_completeYourBooking4,
  "APPB2C.common.testDetails.mapModal.close": APPB2C_common_testDetails_mapModal_close4,
  "APPB2C.common.testDetails.mapModal.title": APPB2C_common_testDetails_mapModal_title4,
  "APPB2C.common.testDetails.modifyNote.confirm": APPB2C_common_testDetails_modifyNote_confirm4,
  "APPB2C.common.testDetails.modifyNote.note": APPB2C_common_testDetails_modifyNote_note4,
  "APPB2C.common.testDetails.modifyNote.pleaseNote": APPB2C_common_testDetails_modifyNote_pleaseNote4,
  "APPB2C.common.testDetails.title": APPB2C_common_testDetails_title4,
  "APPB2C.common.testDetails.yourSpeakingTest": APPB2C_common_testDetails_yourSpeakingTest4,
  "APPB2C.common.testDetails.yourWrittenTest": APPB2C_common_testDetails_yourWrittenTest4,
  "APPB2C.common.transfer.agreement": APPB2C_common_transfer_agreement4,
  "APPB2C.common.transfer.contactNote": APPB2C_common_transfer_contactNote4,
  "APPB2C.common.transfer.contactNoteIol": APPB2C_common_transfer_contactNoteIol4,
  "APPB2C.common.transfer.cta": APPB2C_common_transfer_cta4,
  "APPB2C.common.transfer.form.freeform": APPB2C_common_transfer_form_freeform4,
  "APPB2C.common.transfer.form.reasons.1": APPB2C_common_transfer_form_reasons_14,
  "APPB2C.common.transfer.form.reasons.2": APPB2C_common_transfer_form_reasons_24,
  "APPB2C.common.transfer.form.reasons.3": APPB2C_common_transfer_form_reasons_34,
  "APPB2C.common.transfer.form.reasons.4": APPB2C_common_transfer_form_reasons_44,
  "APPB2C.common.transfer.form.reasons.choose": APPB2C_common_transfer_form_reasons_choose4,
  "APPB2C.common.transfer.form.reasons.other": APPB2C_common_transfer_form_reasons_other4,
  "APPB2C.common.transfer.form.reasons.title": APPB2C_common_transfer_form_reasons_title4,
  "APPB2C.common.transfer.goBack": APPB2C_common_transfer_goBack4,
  "APPB2C.common.transfer.notes.1": APPB2C_common_transfer_notes_14,
  "APPB2C.common.transfer.notes.2": APPB2C_common_transfer_notes_24,
  "APPB2C.common.transfer.notes.3": APPB2C_common_transfer_notes_34,
  "APPB2C.common.transfer.notes.4": APPB2C_common_transfer_notes_44,
  "APPB2C.common.transfer.notesIol.1": APPB2C_common_transfer_notesIol_14,
  "APPB2C.common.transfer.notesIol.2": APPB2C_common_transfer_notesIol_24,
  "APPB2C.common.transfer.requestValidation": APPB2C_common_transfer_requestValidation4,
  "APPB2C.common.transfer.selfService.availableDates.title": APPB2C_common_transfer_selfService_availableDates_title4,
  "APPB2C.common.transfer.selfService.chooseDates.title": APPB2C_common_transfer_selfService_chooseDates_title4,
  "APPB2C.common.transfer.selfService.review.confirm.proceedBtn": APPB2C_common_transfer_selfService_review_confirm_proceedBtn4,
  "APPB2C.common.transfer.selfService.review.confirm.title": APPB2C_common_transfer_selfService_review_confirm_title4,
  "APPB2C.common.transfer.selfService.review.errors.noDistricts": APPB2C_common_transfer_selfService_review_errors_noDistricts4,
  "APPB2C.common.transfer.selfService.review.errors.noLrwSlots": APPB2C_common_transfer_selfService_review_errors_noLrwSlots4,
  "APPB2C.common.transfer.selfService.review.errors.transferDisabled": APPB2C_common_transfer_selfService_review_errors_transferDisabled4,
  "APPB2C.common.transfer.selfService.review.test.changeLrw": APPB2C_common_transfer_selfService_review_test_changeLrw4,
  "APPB2C.common.transfer.selfService.review.tncs.agreement": APPB2C_common_transfer_selfService_review_tncs_agreement4,
  "APPB2C.common.transfer.title": APPB2C_common_transfer_title4,
  "APPB2C.common.underConstruction.message": APPB2C_common_underConstruction_message4,
  "APPB2C.common.underConstruction.title": APPB2C_common_underConstruction_title4,
  "APPB2C.common.unknownCountry.cta": APPB2C_common_unknownCountry_cta4,
  "APPB2C.common.unknownCountry.header": APPB2C_common_unknownCountry_header4,
  "APPB2C.common.unknownCountry.msg1": APPB2C_common_unknownCountry_msg14,
  "APPB2C.common.unknownCountry.msg2": APPB2C_common_unknownCountry_msg24,
  "APPB2C.common.unknownCountry.title": APPB2C_common_unknownCountry_title4,
  "APPB2C.common.unpaidErrorBar.paymentExpired": APPB2C_common_unpaidErrorBar_paymentExpired4,
  "APPB2C.common.updateBar.cancelled": APPB2C_common_updateBar_cancelled4,
  "APPB2C.common.updateBar.cancelPending": APPB2C_common_updateBar_cancelPending4,
  "APPB2C.common.updateBar.cancelRejected": APPB2C_common_updateBar_cancelRejected4,
  "APPB2C.common.updateBar.close": APPB2C_common_updateBar_close4,
  "APPB2C.common.updateBar.details": APPB2C_common_updateBar_details4,
  "APPB2C.common.updateBar.eorCompleted": APPB2C_common_updateBar_eorCompleted4,
  "APPB2C.common.updateBar.eorCompleted.scoreDecreased": APPB2C_common_updateBar_eorCompleted_scoreDecreased4,
  "APPB2C.common.updateBar.eorCompleted.scoreIncreased": APPB2C_common_updateBar_eorCompleted_scoreIncreased4,
  "APPB2C.common.updateBar.eorCompleted.scoreUnchanged": APPB2C_common_updateBar_eorCompleted_scoreUnchanged4,
  "APPB2C.common.updateBar.eorPending": APPB2C_common_updateBar_eorPending4,
  "APPB2C.common.updateBar.eorPendingAgentPays": APPB2C_common_updateBar_eorPendingAgentPays4,
  "APPB2C.common.updateBar.eorRefunded": APPB2C_common_updateBar_eorRefunded4,
  "APPB2C.common.updateBar.eorRefundPending": APPB2C_common_updateBar_eorRefundPending4,
  "APPB2C.common.updateBar.eorRejected": APPB2C_common_updateBar_eorRejected4,
  "APPB2C.common.updateBar.eorRejected.details": APPB2C_common_updateBar_eorRejected_details3,
  "APPB2C.common.updateBar.eorRejected.overallBand": APPB2C_common_updateBar_eorRejected_overallBand4,
  "APPB2C.common.updateBar.hasResults": APPB2C_common_updateBar_hasResults4,
  "APPB2C.common.updateBar.termsUpdated": APPB2C_common_updateBar_termsUpdated4,
  "APPB2C.common.updateBar.transactionRefundRequestAccepted": APPB2C_common_updateBar_transactionRefundRequestAccepted4,
  "APPB2C.common.updateBar.transactionRefundRequestPending": APPB2C_common_updateBar_transactionRefundRequestPending4,
  "APPB2C.common.updateBar.transactionRefundRequestRejected": APPB2C_common_updateBar_transactionRefundRequestRejected4,
  "APPB2C.common.updateBar.transferCancelled": APPB2C_common_updateBar_transferCancelled4,
  "APPB2C.common.updateBar.transferPending": APPB2C_common_updateBar_transferPending4,
  "APPB2C.common.updateBar.transferPendingCmds": APPB2C_common_updateBar_transferPendingCmds4,
  "APPB2C.common.updateBar.transferred": APPB2C_common_updateBar_transferred4,
  "APPB2C.common.updateBar.transferredCmds": APPB2C_common_updateBar_transferredCmds4,
  "APPB2C.registration.bookingDetails.payment": APPB2C_registration_bookingDetails_payment4,
  "APPB2C.registration.bookingDetails.results": APPB2C_registration_bookingDetails_results4,
  "APPB2C.registration.bookingDetails.seePayment": APPB2C_registration_bookingDetails_seePayment4,
  "APPB2C.registration.bookingDetails.seeTestTaker": APPB2C_registration_bookingDetails_seeTestTaker4,
  "APPB2C.registration.bookingDetails.whatNext": APPB2C_registration_bookingDetails_whatNext4,
  "APPB2C.registration.date.check.back": APPB2C_registration_date_check_back4,
  "APPB2C.registration.date.check.beforeTestDate": APPB2C_registration_date_check_beforeTestDate4,
  "APPB2C.registration.date.check.intro": APPB2C_registration_date_check_intro4,
  "APPB2C.registration.date.check.onTestDate": APPB2C_registration_date_check_onTestDate4,
  "APPB2C.registration.date.check.step1.body": APPB2C_registration_date_check_step1_body4,
  "APPB2C.registration.date.check.step1.link": APPB2C_registration_date_check_step1_link4,
  "APPB2C.registration.date.check.step1.title": APPB2C_registration_date_check_step1_title4,
  "APPB2C.registration.date.check.step2.body": APPB2C_registration_date_check_step2_body4,
  "APPB2C.registration.date.check.step2.link": APPB2C_registration_date_check_step2_link4,
  "APPB2C.registration.date.check.step2.title": APPB2C_registration_date_check_step2_title4,
  "APPB2C.registration.date.check.step3.body1": APPB2C_registration_date_check_step3_body14,
  "APPB2C.registration.date.check.step3.body2": APPB2C_registration_date_check_step3_body24,
  "APPB2C.registration.date.check.step3.noDetailsBody": APPB2C_registration_date_check_step3_noDetailsBody4,
  "APPB2C.registration.date.check.step3.noDetailsTitle": APPB2C_registration_date_check_step3_noDetailsTitle4,
  "APPB2C.registration.date.check.step3.noSpeakingPinBody": APPB2C_registration_date_check_step3_noSpeakingPinBody4,
  "APPB2C.registration.date.check.step3.noSpeakingPinTitle": APPB2C_registration_date_check_step3_noSpeakingPinTitle4,
  "APPB2C.registration.date.check.step3.password": APPB2C_registration_date_check_step3_password4,
  "APPB2C.registration.date.check.step3.speakingPin": APPB2C_registration_date_check_step3_speakingPin4,
  "APPB2C.registration.date.check.step3.step1.body": APPB2C_registration_date_check_step3_step1_body4,
  "APPB2C.registration.date.check.step3.step1.title": APPB2C_registration_date_check_step3_step1_title4,
  "APPB2C.registration.date.check.step3.step2.body": APPB2C_registration_date_check_step3_step2_body4,
  "APPB2C.registration.date.check.step3.step2.title": APPB2C_registration_date_check_step3_step2_title4,
  "APPB2C.registration.date.check.step3.step3.body": APPB2C_registration_date_check_step3_step3_body4,
  "APPB2C.registration.date.check.step3.step3.title": APPB2C_registration_date_check_step3_step3_title4,
  "APPB2C.registration.date.check.step3.username": APPB2C_registration_date_check_step3_username4,
  "APPB2C.registration.date.check.step3.writtenPin": APPB2C_registration_date_check_step3_writtenPin4,
  "APPB2C.registration.date.zone.country": APPB2C_registration_date_zone_country4,
  "APPB2C.registration.date.zone.header": APPB2C_registration_date_zone_header4,
  "APPB2C.registration.date.zone.name": APPB2C_registration_date_zone_name4,
  "APPB2C.registration.date.zone.time": APPB2C_registration_date_zone_time4,
  "APPB2C.registration.dateLocation.changeLinkIol": APPB2C_registration_dateLocation_changeLinkIol4,
  "APPB2C.registration.dateLocation.changeLrwLink": APPB2C_registration_dateLocation_changeLrwLink4,
  "APPB2C.registration.dateLocation.changeSpeakingLink": APPB2C_registration_dateLocation_changeSpeakingLink4,
  "APPB2C.registration.dateLocation.location": APPB2C_registration_dateLocation_location4,
  "APPB2C.registration.dateLocation.manage.cancel": APPB2C_registration_dateLocation_manage_cancel4,
  "APPB2C.registration.dateLocation.manage.proceed": APPB2C_registration_dateLocation_manage_proceed3,
  "APPB2C.registration.dateLocation.manage.title": APPB2C_registration_dateLocation_manage_title4,
  "APPB2C.registration.dateLocation.manage.transferSpeaking": APPB2C_registration_dateLocation_manage_transferSpeaking4,
  "APPB2C.registration.dateLocation.manage.transferWhole": APPB2C_registration_dateLocation_manage_transferWhole4,
  "APPB2C.registration.dateLocation.speakingTransfer.keepSpeakingTest": APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest4,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody1": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody14,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody2": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody24,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsHeader": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader4,
  "APPB2C.registration.dateLocation.speakingTransfer.requestTransfer": APPB2C_registration_dateLocation_speakingTransfer_requestTransfer4,
  "APPB2C.registration.incorrectOrganisation.homeLink": APPB2C_registration_incorrectOrganisation_homeLink4,
  "APPB2C.registration.incorrectOrganisation.line1": APPB2C_registration_incorrectOrganisation_line14,
  "APPB2C.registration.incorrectOrganisation.line2": APPB2C_registration_incorrectOrganisation_line24,
  "APPB2C.registration.payments.actions.editProofCTA": APPB2C_registration_payments_actions_editProofCTA4,
  "APPB2C.registration.payments.actions.header": APPB2C_registration_payments_actions_header4,
  "APPB2C.registration.payments.actions.paymentInProgressInfo": APPB2C_registration_payments_actions_paymentInProgressInfo4,
  "APPB2C.registration.payments.actions.payOfflineCTA": APPB2C_registration_payments_actions_payOfflineCTA4,
  "APPB2C.registration.payments.actions.payOnlineCTA": APPB2C_registration_payments_actions_payOnlineCTA4,
  "APPB2C.registration.payments.actions.subHeader": APPB2C_registration_payments_actions_subHeader4,
  "APPB2C.registration.payments.actions.uploadProofCTA": APPB2C_registration_payments_actions_uploadProofCTA4,
  "APPB2C.registration.payments.awaitingPayment": APPB2C_registration_payments_awaitingPayment4,
  "APPB2C.registration.payments.back": APPB2C_registration_payments_back4,
  "APPB2C.registration.payments.completed": APPB2C_registration_payments_completed4,
  "APPB2C.registration.payments.expired": APPB2C_registration_payments_expired4,
  "APPB2C.registration.payments.finishPayment.error.cta": APPB2C_registration_payments_finishPayment_error_cta4,
  "APPB2C.registration.payments.finishPayment.error.messageLine1": APPB2C_registration_payments_finishPayment_error_messageLine14,
  "APPB2C.registration.payments.finishPayment.error.messageLine2": APPB2C_registration_payments_finishPayment_error_messageLine24,
  "APPB2C.registration.payments.finishPayment.error.title": APPB2C_registration_payments_finishPayment_error_title4,
  "APPB2C.registration.payments.finishPayment.failure.alert": APPB2C_registration_payments_finishPayment_failure_alert4,
  "APPB2C.registration.payments.finishPayment.failure.cta": APPB2C_registration_payments_finishPayment_failure_cta4,
  "APPB2C.registration.payments.finishPayment.failure.message": APPB2C_registration_payments_finishPayment_failure_message4,
  "APPB2C.registration.payments.finishPayment.failure.title": APPB2C_registration_payments_finishPayment_failure_title4,
  "APPB2C.registration.payments.finishPayment.pleaseWait": APPB2C_registration_payments_finishPayment_pleaseWait4,
  "APPB2C.registration.payments.finishPayment.processing": APPB2C_registration_payments_finishPayment_processing4,
  "APPB2C.registration.payments.finishPayment.success.alert": APPB2C_registration_payments_finishPayment_success_alert4,
  "APPB2C.registration.payments.finishPayment.success.cta": APPB2C_registration_payments_finishPayment_success_cta4,
  "APPB2C.registration.payments.finishPayment.success.message": APPB2C_registration_payments_finishPayment_success_message4,
  "APPB2C.registration.payments.finishPayment.success.title": APPB2C_registration_payments_finishPayment_success_title4,
  "APPB2C.registration.payments.offlineDetails.accountName": APPB2C_registration_payments_offlineDetails_accountName4,
  "APPB2C.registration.payments.offlineDetails.accountNumber": APPB2C_registration_payments_offlineDetails_accountNumber4,
  "APPB2C.registration.payments.offlineDetails.payBy": APPB2C_registration_payments_offlineDetails_payBy4,
  "APPB2C.registration.payments.offlineDetails.sortCode": APPB2C_registration_payments_offlineDetails_sortCode4,
  "APPB2C.registration.payments.paidByAgent": APPB2C_registration_payments_paidByAgent4,
  "APPB2C.registration.payments.proofPreview.fileAlt": APPB2C_registration_payments_proofPreview_fileAlt4,
  "APPB2C.registration.payments.proofPreview.proofLabel": APPB2C_registration_payments_proofPreview_proofLabel4,
  "APPB2C.registration.payments.proofUpload.cta": APPB2C_registration_payments_proofUpload_cta4,
  "APPB2C.registration.payments.proofUpload.info": APPB2C_registration_payments_proofUpload_info4,
  "APPB2C.registration.payments.proofUpload.intro": APPB2C_registration_payments_proofUpload_intro4,
  "APPB2C.registration.payments.proofUpload.rules.intro": APPB2C_registration_payments_proofUpload_rules_intro4,
  "APPB2C.registration.payments.proofUpload.rules.rule1": APPB2C_registration_payments_proofUpload_rules_rule14,
  "APPB2C.registration.payments.proofUpload.rules.rule2": APPB2C_registration_payments_proofUpload_rules_rule24,
  "APPB2C.registration.payments.proofUpload.rules.rule3": APPB2C_registration_payments_proofUpload_rules_rule34,
  "APPB2C.registration.payments.proofUpload.rules.rule4": APPB2C_registration_payments_proofUpload_rules_rule44,
  "APPB2C.registration.payments.proofUpload.rules.rule5": APPB2C_registration_payments_proofUpload_rules_rule54,
  "APPB2C.registration.payments.transaction.cancelledSuffix": APPB2C_registration_payments_transaction_cancelledSuffix4,
  "APPB2C.registration.payments.transaction.deadlineWarning.default": APPB2C_registration_payments_transaction_deadlineWarning_default4,
  "APPB2C.registration.payments.transaction.deadlineWarning.eor": APPB2C_registration_payments_transaction_deadlineWarning_eor4,
  "APPB2C.registration.payments.transaction.deadlineWarning.registration": APPB2C_registration_payments_transaction_deadlineWarning_registration4,
  "APPB2C.registration.payments.transaction.description.appeal": APPB2C_registration_payments_transaction_description_appeal4,
  "APPB2C.registration.payments.transaction.description.eor": APPB2C_registration_payments_transaction_description_eor4,
  "APPB2C.registration.payments.transaction.description.registration": APPB2C_registration_payments_transaction_description_registration4,
  "APPB2C.registration.payments.transaction.description.transfer": APPB2C_registration_payments_transaction_description_transfer4,
  "APPB2C.registration.payments.transaction.feeLabel": APPB2C_registration_payments_transaction_feeLabel4,
  "APPB2C.registration.payments.transaction.header": APPB2C_registration_payments_transaction_header4,
  "APPB2C.registration.payments.transaction.hideDetails": APPB2C_registration_payments_transaction_hideDetails4,
  "APPB2C.registration.payments.transaction.outdatedTermsLabel": APPB2C_registration_payments_transaction_outdatedTermsLabel4,
  "APPB2C.registration.payments.transaction.pastDeadline": APPB2C_registration_payments_transaction_pastDeadline4,
  "APPB2C.registration.payments.transaction.pastDeadlineContactUs": APPB2C_registration_payments_transaction_pastDeadlineContactUs4,
  "APPB2C.registration.payments.transaction.pastDeadlineEmail": APPB2C_registration_payments_transaction_pastDeadlineEmail4,
  "APPB2C.registration.payments.transaction.pastDeadlinePhone": APPB2C_registration_payments_transaction_pastDeadlinePhone4,
  "APPB2C.registration.payments.transaction.promoCode": APPB2C_registration_payments_transaction_promoCode4,
  "APPB2C.registration.payments.transaction.receipt": APPB2C_registration_payments_transaction_receipt4,
  "APPB2C.registration.payments.transaction.receiptCta": APPB2C_registration_payments_transaction_receiptCta4,
  "APPB2C.registration.payments.transaction.referenceLabel": APPB2C_registration_payments_transaction_referenceLabel4,
  "APPB2C.registration.payments.transaction.showDetails": APPB2C_registration_payments_transaction_showDetails4,
  "APPB2C.registration.payments.transaction.taxLabel": APPB2C_registration_payments_transaction_taxLabel4,
  "APPB2C.registration.payments.transaction.termsLabel": APPB2C_registration_payments_transaction_termsLabel4,
  "APPB2C.registration.payments.transaction.totalLabel": APPB2C_registration_payments_transaction_totalLabel4,
  "APPB2C.registration.payments.transaction.underReviewSuffix": APPB2C_registration_payments_transaction_underReviewSuffix4,
  "APPB2C.registration.payments.transaction.unpaidSuffix": APPB2C_registration_payments_transaction_unpaidSuffix4,
  "APPB2C.registration.payments.unpaid": APPB2C_registration_payments_unpaid4,
  "APPB2C.registration.prepare.a1.cta": APPB2C_registration_prepare_a1_cta4,
  "APPB2C.registration.prepare.a1.header": APPB2C_registration_prepare_a1_header4,
  "APPB2C.registration.prepare.a2.cta": APPB2C_registration_prepare_a2_cta4,
  "APPB2C.registration.prepare.a2.header": APPB2C_registration_prepare_a2_header4,
  "APPB2C.registration.prepare.b1.cta": APPB2C_registration_prepare_b1_cta4,
  "APPB2C.registration.prepare.b1.header": APPB2C_registration_prepare_b1_header4,
  "APPB2C.registration.prepare.blog.body.cta": APPB2C_registration_prepare_blog_body_cta4,
  "APPB2C.registration.prepare.blog.body.line1": APPB2C_registration_prepare_blog_body_line14,
  "APPB2C.registration.prepare.blog.header": APPB2C_registration_prepare_blog_header4,
  "APPB2C.registration.prepare.cdFamiliarTest.body.cta": APPB2C_registration_prepare_cdFamiliarTest_body_cta4,
  "APPB2C.registration.prepare.cdFamiliarTest.body.line1": APPB2C_registration_prepare_cdFamiliarTest_body_line14,
  "APPB2C.registration.prepare.cdFamiliarTest.header": APPB2C_registration_prepare_cdFamiliarTest_header4,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.cta": APPB2C_registration_prepare_cdFamiliarVideos_body_cta4,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.line1": APPB2C_registration_prepare_cdFamiliarVideos_body_line14,
  "APPB2C.registration.prepare.cdFamiliarVideos.header": APPB2C_registration_prepare_cdFamiliarVideos_header4,
  "APPB2C.registration.prepare.cdTests.body.cta": APPB2C_registration_prepare_cdTests_body_cta4,
  "APPB2C.registration.prepare.cdTests.body.line1": APPB2C_registration_prepare_cdTests_body_line14,
  "APPB2C.registration.prepare.cdTests.header": APPB2C_registration_prepare_cdTests_header4,
  "APPB2C.registration.prepare.cdTipsVideos.body.cta": APPB2C_registration_prepare_cdTipsVideos_body_cta4,
  "APPB2C.registration.prepare.cdTipsVideos.body.line1": APPB2C_registration_prepare_cdTipsVideos_body_line14,
  "APPB2C.registration.prepare.cdTipsVideos.header": APPB2C_registration_prepare_cdTipsVideos_header4,
  "APPB2C.registration.prepare.facebook.body.cta": APPB2C_registration_prepare_facebook_body_cta4,
  "APPB2C.registration.prepare.facebook.body.line1": APPB2C_registration_prepare_facebook_body_line14,
  "APPB2C.registration.prepare.facebook.header": APPB2C_registration_prepare_facebook_header4,
  "APPB2C.registration.prepare.gel.disabledMsg": APPB2C_registration_prepare_gel_disabledMsg4,
  "APPB2C.registration.prepare.gel.ielts.body.cta": APPB2C_registration_prepare_gel_ielts_body_cta4,
  "APPB2C.registration.prepare.gel.ielts.body.line1": APPB2C_registration_prepare_gel_ielts_body_line14,
  "APPB2C.registration.prepare.gel.ielts.header": APPB2C_registration_prepare_gel_ielts_header4,
  "APPB2C.registration.prepare.gel.ukvi.body.cta": APPB2C_registration_prepare_gel_ukvi_body_cta3,
  "APPB2C.registration.prepare.gel.ukvi.body.line1": APPB2C_registration_prepare_gel_ukvi_body_line14,
  "APPB2C.registration.prepare.gel.ukvi.header": APPB2C_registration_prepare_gel_ukvi_header4,
  "APPB2C.registration.prepare.header": APPB2C_registration_prepare_header4,
  "APPB2C.registration.prepare.ieltsReady.subTitle": APPB2C_registration_prepare_ieltsReady_subTitle4,
  "APPB2C.registration.prepare.ieltsReady.title": APPB2C_registration_prepare_ieltsReady_title4,
  "APPB2C.registration.prepare.ieltsReadyMember.body": APPB2C_registration_prepare_ieltsReadyMember_body4,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestBtn": APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn4,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestInfo": APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo4,
  "APPB2C.registration.prepare.ieltsReadyMember.freePrepLink": APPB2C_registration_prepare_ieltsReadyMember_freePrepLink4,
  "APPB2C.registration.prepare.ieltsReadyMember.header": APPB2C_registration_prepare_ieltsReadyMember_header4,
  "APPB2C.registration.prepare.ieltsReadyMember.label": APPB2C_registration_prepare_ieltsReadyMember_label4,
  "APPB2C.registration.prepare.ieltsReadyMember.link": APPB2C_registration_prepare_ieltsReadyMember_link4,
  "APPB2C.registration.prepare.ieltsReadyMember.payBtn": APPB2C_registration_prepare_ieltsReadyMember_payBtn4,
  "APPB2C.registration.prepare.ieltsReadyMember.unpaidTestInfo": APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo4,
  "APPB2C.registration.prepare.intro": APPB2C_registration_prepare_intro4,
  "APPB2C.registration.prepare.introLifeSkills": APPB2C_registration_prepare_introLifeSkills4,
  "APPB2C.registration.prepare.onlineCourse.body.cta": APPB2C_registration_prepare_onlineCourse_body_cta4,
  "APPB2C.registration.prepare.onlineCourse.body.line1": APPB2C_registration_prepare_onlineCourse_body_line14,
  "APPB2C.registration.prepare.onlineCourse.header": APPB2C_registration_prepare_onlineCourse_header4,
  "APPB2C.registration.prepare.pbPractice.body.cta": APPB2C_registration_prepare_pbPractice_body_cta4,
  "APPB2C.registration.prepare.pbPractice.body.line1": APPB2C_registration_prepare_pbPractice_body_line14,
  "APPB2C.registration.prepare.pbPractice.header": APPB2C_registration_prepare_pbPractice_header4,
  "APPB2C.registration.prepare.practice.body.cta": APPB2C_registration_prepare_practice_body_cta4,
  "APPB2C.registration.prepare.practice.body.line1": APPB2C_registration_prepare_practice_body_line14,
  "APPB2C.registration.prepare.practice.header": APPB2C_registration_prepare_practice_header4,
  "APPB2C.registration.prepare.prepApp.body.cta": APPB2C_registration_prepare_prepApp_body_cta4,
  "APPB2C.registration.prepare.prepApp.body.line1": APPB2C_registration_prepare_prepApp_body_line14,
  "APPB2C.registration.prepare.prepApp.header": APPB2C_registration_prepare_prepApp_header4,
  "APPB2C.registration.prepare.prepApp.ukvi.cta1": APPB2C_registration_prepare_prepApp_ukvi_cta14,
  "APPB2C.registration.prepare.prepApp.ukvi.cta2": APPB2C_registration_prepare_prepApp_ukvi_cta24,
  "APPB2C.registration.prepare.prepApp.ukvi.line1": APPB2C_registration_prepare_prepApp_ukvi_line14,
  "APPB2C.registration.prepare.prepApp.ukvi.line2": APPB2C_registration_prepare_prepApp_ukvi_line24,
  "APPB2C.registration.prepare.prepVideos.body.cta": APPB2C_registration_prepare_prepVideos_body_cta4,
  "APPB2C.registration.prepare.prepVideos.body.line1": APPB2C_registration_prepare_prepVideos_body_line14,
  "APPB2C.registration.prepare.prepVideos.header": APPB2C_registration_prepare_prepVideos_header4,
  "APPB2C.registration.prepare.reading.body.cta": APPB2C_registration_prepare_reading_body_cta4,
  "APPB2C.registration.prepare.reading.body.line1": APPB2C_registration_prepare_reading_body_line14,
  "APPB2C.registration.prepare.reading.header": APPB2C_registration_prepare_reading_header4,
  "APPB2C.registration.prepare.rteLastMinute.body.cta": APPB2C_registration_prepare_rteLastMinute_body_cta4,
  "APPB2C.registration.prepare.rteLastMinute.body.item1": APPB2C_registration_prepare_rteLastMinute_body_item14,
  "APPB2C.registration.prepare.rteLastMinute.body.item2": APPB2C_registration_prepare_rteLastMinute_body_item24,
  "APPB2C.registration.prepare.rteLastMinute.body.item3": APPB2C_registration_prepare_rteLastMinute_body_item34,
  "APPB2C.registration.prepare.rteLastMinute.body.line1": APPB2C_registration_prepare_rteLastMinute_body_line14,
  "APPB2C.registration.prepare.rteLastMinute.header": APPB2C_registration_prepare_rteLastMinute_header4,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.cta": APPB2C_registration_prepare_rteLastMinute_ukvi_cta4,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.line1": APPB2C_registration_prepare_rteLastMinute_ukvi_line14,
  "APPB2C.registration.prepare.rteTestDrive.body.cta": APPB2C_registration_prepare_rteTestDrive_body_cta4,
  "APPB2C.registration.prepare.rteTestDrive.body.item1": APPB2C_registration_prepare_rteTestDrive_body_item14,
  "APPB2C.registration.prepare.rteTestDrive.body.item2": APPB2C_registration_prepare_rteTestDrive_body_item24,
  "APPB2C.registration.prepare.rteTestDrive.body.item3": APPB2C_registration_prepare_rteTestDrive_body_item34,
  "APPB2C.registration.prepare.rteTestDrive.body.line1": APPB2C_registration_prepare_rteTestDrive_body_line14,
  "APPB2C.registration.prepare.rteTestDrive.body.line2": APPB2C_registration_prepare_rteTestDrive_body_line24,
  "APPB2C.registration.prepare.rteTestDrive.header": APPB2C_registration_prepare_rteTestDrive_header4,
  "APPB2C.registration.prepare.speaking.body.cta": APPB2C_registration_prepare_speaking_body_cta4,
  "APPB2C.registration.prepare.speaking.body.line1": APPB2C_registration_prepare_speaking_body_line14,
  "APPB2C.registration.prepare.speaking.header": APPB2C_registration_prepare_speaking_header4,
  "APPB2C.registration.prepare.tutor.body.cta": APPB2C_registration_prepare_tutor_body_cta4,
  "APPB2C.registration.prepare.tutor.body.line1": APPB2C_registration_prepare_tutor_body_line14,
  "APPB2C.registration.prepare.tutor.body.line2": APPB2C_registration_prepare_tutor_body_line24,
  "APPB2C.registration.prepare.tutor.header": APPB2C_registration_prepare_tutor_header4,
  "APPB2C.registration.prepare.webinars.body.cta": APPB2C_registration_prepare_webinars_body_cta4,
  "APPB2C.registration.prepare.webinars.body.item1": APPB2C_registration_prepare_webinars_body_item14,
  "APPB2C.registration.prepare.webinars.body.item2": APPB2C_registration_prepare_webinars_body_item24,
  "APPB2C.registration.prepare.webinars.body.item3": APPB2C_registration_prepare_webinars_body_item34,
  "APPB2C.registration.prepare.webinars.body.line1": APPB2C_registration_prepare_webinars_body_line14,
  "APPB2C.registration.prepare.webinars.header": APPB2C_registration_prepare_webinars_header4,
  "APPB2C.registration.results.bandScore.osrBooked": APPB2C_registration_results_bandScore_osrBooked4,
  "APPB2C.registration.results.bandScore.osrMarked": APPB2C_registration_results_bandScore_osrMarked4,
  "APPB2C.registration.results.eor.acknowledgement.backButton": APPB2C_registration_results_eor_acknowledgement_backButton4,
  "APPB2C.registration.results.eor.acknowledgement.components": APPB2C_registration_results_eor_acknowledgement_components4,
  "APPB2C.registration.results.eor.acknowledgement.confirmation": APPB2C_registration_results_eor_acknowledgement_confirmation4,
  "APPB2C.registration.results.eor.acknowledgement.fee": APPB2C_registration_results_eor_acknowledgement_fee4,
  "APPB2C.registration.results.eor.acknowledgement.info": APPB2C_registration_results_eor_acknowledgement_info4,
  "APPB2C.registration.results.eor.acknowledgement.noteTitle": APPB2C_registration_results_eor_acknowledgement_noteTitle4,
  "APPB2C.registration.results.eor.acknowledgement.payment": APPB2C_registration_results_eor_acknowledgement_payment4,
  "APPB2C.registration.results.eor.acknowledgement.paymentButton": APPB2C_registration_results_eor_acknowledgement_paymentButton4,
  "APPB2C.registration.results.eor.acknowledgement.paymentNote": APPB2C_registration_results_eor_acknowledgement_paymentNote4,
  "APPB2C.registration.results.eor.acknowledgement.reference": APPB2C_registration_results_eor_acknowledgement_reference4,
  "APPB2C.registration.results.eor.acknowledgement.success": APPB2C_registration_results_eor_acknowledgement_success4,
  "APPB2C.registration.results.eor.acknowledgement.tax": APPB2C_registration_results_eor_acknowledgement_tax4,
  "APPB2C.registration.results.eor.acknowledgement.terms": APPB2C_registration_results_eor_acknowledgement_terms4,
  "APPB2C.registration.results.eor.acknowledgement.title": APPB2C_registration_results_eor_acknowledgement_title4,
  "APPB2C.registration.results.eor.acknowledgement.total": APPB2C_registration_results_eor_acknowledgement_total4,
  "APPB2C.registration.results.eor.postDeadline.back": APPB2C_registration_results_eor_postDeadline_back4,
  "APPB2C.registration.results.eor.postDeadline.email": APPB2C_registration_results_eor_postDeadline_email4,
  "APPB2C.registration.results.eor.postDeadline.help": APPB2C_registration_results_eor_postDeadline_help4,
  "APPB2C.registration.results.eor.postDeadline.iol.launchChatLine": APPB2C_registration_results_eor_postDeadline_iol_launchChatLine4,
  "APPB2C.registration.results.eor.postDeadline.iol.line3": APPB2C_registration_results_eor_postDeadline_iol_line34,
  "APPB2C.registration.results.eor.postDeadline.line1": APPB2C_registration_results_eor_postDeadline_line14,
  "APPB2C.registration.results.eor.postDeadline.line2": APPB2C_registration_results_eor_postDeadline_line24,
  "APPB2C.registration.results.eor.postDeadline.line3": APPB2C_registration_results_eor_postDeadline_line34,
  "APPB2C.registration.results.eor.postDeadline.phone": APPB2C_registration_results_eor_postDeadline_phone4,
  "APPB2C.registration.results.eor.preDeadline.back": APPB2C_registration_results_eor_preDeadline_back4,
  "APPB2C.registration.results.eor.preDeadline.error.explanationMaxLength": APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength4,
  "APPB2C.registration.results.eor.preDeadline.error.missingTrfNumber": APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber4,
  "APPB2C.registration.results.eor.preDeadline.error.wrongTrfNumber": APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber4,
  "APPB2C.registration.results.eor.preDeadline.evidence": APPB2C_registration_results_eor_preDeadline_evidence4,
  "APPB2C.registration.results.eor.preDeadline.evidenceRequired": APPB2C_registration_results_eor_preDeadline_evidenceRequired4,
  "APPB2C.registration.results.eor.preDeadline.explanation": APPB2C_registration_results_eor_preDeadline_explanation4,
  "APPB2C.registration.results.eor.preDeadline.explanationRequired": APPB2C_registration_results_eor_preDeadline_explanationRequired4,
  "APPB2C.registration.results.eor.preDeadline.fee": APPB2C_registration_results_eor_preDeadline_fee4,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line1": APPB2C_registration_results_eor_preDeadline_iolNote_line13,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line2": APPB2C_registration_results_eor_preDeadline_iolNote_line24,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line3": APPB2C_registration_results_eor_preDeadline_iolNote_line34,
  "APPB2C.registration.results.eor.preDeadline.legal": APPB2C_registration_results_eor_preDeadline_legal4,
  "APPB2C.registration.results.eor.preDeadline.legalNote": APPB2C_registration_results_eor_preDeadline_legalNote4,
  "APPB2C.registration.results.eor.preDeadline.line1": APPB2C_registration_results_eor_preDeadline_line14,
  "APPB2C.registration.results.eor.preDeadline.line2": APPB2C_registration_results_eor_preDeadline_line24,
  "APPB2C.registration.results.eor.preDeadline.maxScore": APPB2C_registration_results_eor_preDeadline_maxScore4,
  "APPB2C.registration.results.eor.preDeadline.note": APPB2C_registration_results_eor_preDeadline_note4,
  "APPB2C.registration.results.eor.preDeadline.noteTitle": APPB2C_registration_results_eor_preDeadline_noteTitle4,
  "APPB2C.registration.results.eor.preDeadline.orderAcknowledgementLabel": APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel4,
  "APPB2C.registration.results.eor.preDeadline.paymentCta": APPB2C_registration_results_eor_preDeadline_paymentCta4,
  "APPB2C.registration.results.eor.preDeadline.paymentTitle": APPB2C_registration_results_eor_preDeadline_paymentTitle4,
  "APPB2C.registration.results.eor.preDeadline.payOfflineCTA": APPB2C_registration_results_eor_preDeadline_payOfflineCTA4,
  "APPB2C.registration.results.eor.preDeadline.payOnlineCTA": APPB2C_registration_results_eor_preDeadline_payOnlineCTA4,
  "APPB2C.registration.results.eor.preDeadline.scoresRequired": APPB2C_registration_results_eor_preDeadline_scoresRequired4,
  "APPB2C.registration.results.eor.preDeadline.scoresTitle": APPB2C_registration_results_eor_preDeadline_scoresTitle4,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr4,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Listening": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening4,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Reading": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading4,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Speaking": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking4,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Writing": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing4,
  "APPB2C.registration.results.eor.preDeadline.tax": APPB2C_registration_results_eor_preDeadline_tax4,
  "APPB2C.registration.results.eor.preDeadline.trfNumberLabel": APPB2C_registration_results_eor_preDeadline_trfNumberLabel4,
  "APPB2C.registration.results.eor.preDeadline.trfNumberSubnote": APPB2C_registration_results_eor_preDeadline_trfNumberSubnote4,
  "APPB2C.registration.results.eor.preDeadline.trfNumberValidation": APPB2C_registration_results_eor_preDeadline_trfNumberValidation4,
  "APPB2C.registration.results.eor.title": APPB2C_registration_results_eor_title4,
  "APPB2C.registration.results.eor.titlePayment": APPB2C_registration_results_eor_titlePayment4,
  "APPB2C.registration.results.hasResults.bandScore.decrease": APPB2C_registration_results_hasResults_bandScore_decrease4,
  "APPB2C.registration.results.hasResults.bandScore.increase": APPB2C_registration_results_hasResults_bandScore_increase4,
  "APPB2C.registration.results.hasResults.bandScore.unchanged": APPB2C_registration_results_hasResults_bandScore_unchanged4,
  "APPB2C.registration.results.hasResults.eorAgentInfo": APPB2C_registration_results_hasResults_eorAgentInfo3,
  "APPB2C.registration.results.hasResults.eorLetter": APPB2C_registration_results_hasResults_eorLetter4,
  "APPB2C.registration.results.hasResults.eorLine1": APPB2C_registration_results_hasResults_eorLine14,
  "APPB2C.registration.results.hasResults.eorLine2": APPB2C_registration_results_hasResults_eorLine24,
  "APPB2C.registration.results.hasResults.eorLink": APPB2C_registration_results_hasResults_eorLink4,
  "APPB2C.registration.results.hasResults.eTrfCta": APPB2C_registration_results_hasResults_eTrfCta3,
  "APPB2C.registration.results.hasResults.explanations.heading": APPB2C_registration_results_hasResults_explanations_heading4,
  "APPB2C.registration.results.hasResults.explanations.hideButton": APPB2C_registration_results_hasResults_explanations_hideButton3,
  "APPB2C.registration.results.hasResults.explanations.improve": APPB2C_registration_results_hasResults_explanations_improve4,
  "APPB2C.registration.results.hasResults.explanations.showButton": APPB2C_registration_results_hasResults_explanations_showButton3,
  "APPB2C.registration.results.hasResults.lifeSkills.failed": APPB2C_registration_results_hasResults_lifeSkills_failed4,
  "APPB2C.registration.results.hasResults.lifeSkills.level": APPB2C_registration_results_hasResults_lifeSkills_level4,
  "APPB2C.registration.results.hasResults.lifeSkills.note": APPB2C_registration_results_hasResults_lifeSkills_note4,
  "APPB2C.registration.results.hasResults.lifeSkills.passed": APPB2C_registration_results_hasResults_lifeSkills_passed4,
  "APPB2C.registration.results.hasResults.lifeSkills.result": APPB2C_registration_results_hasResults_lifeSkills_result4,
  "APPB2C.registration.results.hasResults.osr.bandScore.decrease": APPB2C_registration_results_hasResults_osr_bandScore_decrease3,
  "APPB2C.registration.results.hasResults.osr.bandScore.increase": APPB2C_registration_results_hasResults_osr_bandScore_increase3,
  "APPB2C.registration.results.hasResults.osr.bandScore.unchanged": APPB2C_registration_results_hasResults_osr_bandScore_unchanged3,
  "APPB2C.registration.results.hasResults.osr.previousResults": APPB2C_registration_results_hasResults_osr_previousResults4,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreDecreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased4,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreIncreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased4,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreUnchanged": APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged4,
  "APPB2C.registration.results.hasResults.osr.updateBar.title": APPB2C_registration_results_hasResults_osr_updateBar_title4,
  "APPB2C.registration.results.hasResults.osrLink": APPB2C_registration_results_hasResults_osrLink3,
  "APPB2C.registration.results.hasResults.posting.oneCopyWarning": APPB2C_registration_results_hasResults_posting_oneCopyWarning4,
  "APPB2C.registration.results.hasResults.posting.postedOn": APPB2C_registration_results_hasResults_posting_postedOn4,
  "APPB2C.registration.results.hasResults.posting.postedTo": APPB2C_registration_results_hasResults_posting_postedTo4,
  "APPB2C.registration.results.hasResults.posting.willPostOn": APPB2C_registration_results_hasResults_posting_willPostOn4,
  "APPB2C.registration.results.hasResults.posting.willPostTo": APPB2C_registration_results_hasResults_posting_willPostTo4,
  "APPB2C.registration.results.hasResults.rankNameConsonant": APPB2C_registration_results_hasResults_rankNameConsonant4,
  "APPB2C.registration.results.hasResults.rankNameVowel": APPB2C_registration_results_hasResults_rankNameVowel4,
  "APPB2C.registration.results.hasResults.scoreImprove.btn": APPB2C_registration_results_hasResults_scoreImprove_btn4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.backBtn": APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.description": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.title": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.description": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.title": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.description": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.title": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title4,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.subtitle": APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle4,
  "APPB2C.registration.results.hasResults.scoreImprove.subtitle": APPB2C_registration_results_hasResults_scoreImprove_subtitle4,
  "APPB2C.registration.results.hasResults.scoreImprove.title": APPB2C_registration_results_hasResults_scoreImprove_title4,
  "APPB2C.registration.results.hasResults.scores.label": APPB2C_registration_results_hasResults_scores_label4,
  "APPB2C.registration.results.hasResults.scores.Listening": APPB2C_registration_results_hasResults_scores_Listening4,
  "APPB2C.registration.results.hasResults.scores.Overall": APPB2C_registration_results_hasResults_scores_Overall4,
  "APPB2C.registration.results.hasResults.scores.Reading": APPB2C_registration_results_hasResults_scores_Reading4,
  "APPB2C.registration.results.hasResults.scores.Speaking": APPB2C_registration_results_hasResults_scores_Speaking4,
  "APPB2C.registration.results.hasResults.scores.Writing": APPB2C_registration_results_hasResults_scores_Writing4,
  "APPB2C.registration.results.hasResults.share.applyBoard": APPB2C_registration_results_hasResults_share_applyBoard4,
  "APPB2C.registration.results.hasResults.share.RecognisingOrgsLink": APPB2C_registration_results_hasResults_share_RecognisingOrgsLink4,
  "APPB2C.registration.results.hasResults.share.subtitle": APPB2C_registration_results_hasResults_share_subtitle4,
  "APPB2C.registration.results.hasResults.share.title": APPB2C_registration_results_hasResults_share_title4,
  "APPB2C.registration.results.hasResults.share.trfCta": APPB2C_registration_results_hasResults_share_trfCta4,
  "APPB2C.registration.results.hasResults.viewExplanation": APPB2C_registration_results_hasResults_viewExplanation4,
  "APPB2C.registration.results.noResults.cmds.title": APPB2C_registration_results_noResults_cmds_title4,
  "APPB2C.registration.results.noResults.note.title": APPB2C_registration_results_noResults_note_title4,
  "APPB2C.registration.results.noResults.osr.booked": APPB2C_registration_results_noResults_osr_booked4,
  "APPB2C.registration.results.noResults.osr.previousScores": APPB2C_registration_results_noResults_osr_previousScores4,
  "APPB2C.registration.results.noResults.title": APPB2C_registration_results_noResults_title4,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote1": APPB2C_registration_results_organisations_availableReceivingOrgsNote14,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote2": APPB2C_registration_results_organisations_availableReceivingOrgsNote24,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote3": APPB2C_registration_results_organisations_availableReceivingOrgsNote33,
  "APPB2C.registration.results.organisations.chosenOrganisationsHeader": APPB2C_registration_results_organisations_chosenOrganisationsHeader4,
  "APPB2C.registration.results.organisations.editRo.addAnother": APPB2C_registration_results_organisations_editRo_addAnother4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.addNew": APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.address": APPB2C_registration_results_organisations_editRo_chooseOrganisations_address4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.country": APPB2C_registration_results_organisations_editRo_chooseOrganisations_country4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryAll": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryOptional": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.file": APPB2C_registration_results_organisations_editRo_chooseOrganisations_file4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.org": APPB2C_registration_results_organisations_editRo_chooseOrganisations_org4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.organisation": APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.person": APPB2C_registration_results_organisations_editRo_chooseOrganisations_person4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.postCode": APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByCountry": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry4,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByOrg": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg4,
  "APPB2C.registration.results.organisations.editRo.error.caseNumberMaxLength": APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength4,
  "APPB2C.registration.results.organisations.editRo.error.contactPersonMaxLength": APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength4,
  "APPB2C.registration.results.organisations.editRo.error.minScoreMissing": APPB2C_registration_results_organisations_editRo_error_minScoreMissing4,
  "APPB2C.registration.results.organisations.editRo.error.missingAddressLine1": APPB2C_registration_results_organisations_editRo_error_missingAddressLine14,
  "APPB2C.registration.results.organisations.editRo.error.missingOrgName": APPB2C_registration_results_organisations_editRo_error_missingOrgName4,
  "APPB2C.registration.results.organisations.editRo.error.orgNameMaxLength": APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength4,
  "APPB2C.registration.results.organisations.editRo.iol.addCta": APPB2C_registration_results_organisations_editRo_iol_addCta4,
  "APPB2C.registration.results.organisations.editRo.iol.address": APPB2C_registration_results_organisations_editRo_iol_address4,
  "APPB2C.registration.results.organisations.editRo.iol.cancelAdd": APPB2C_registration_results_organisations_editRo_iol_cancelAdd3,
  "APPB2C.registration.results.organisations.editRo.iol.cancelEdit": APPB2C_registration_results_organisations_editRo_iol_cancelEdit4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.clear": APPB2C_registration_results_organisations_editRo_iol_conditions_clear4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.header": APPB2C_registration_results_organisations_editRo_iol_conditions_header4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.mandatoryPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.no": APPB2C_registration_results_organisations_editRo_iol_conditions_no4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note1": APPB2C_registration_results_organisations_editRo_iol_conditions_note14,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note2": APPB2C_registration_results_organisations_editRo_iol_conditions_note24,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note3": APPB2C_registration_results_organisations_editRo_iol_conditions_note34,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.optionalPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.subHeader": APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader4,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.yes": APPB2C_registration_results_organisations_editRo_iol_conditions_yes4,
  "APPB2C.registration.results.organisations.editRo.iol.copied": APPB2C_registration_results_organisations_editRo_iol_copied4,
  "APPB2C.registration.results.organisations.editRo.iol.copy": APPB2C_registration_results_organisations_editRo_iol_copy4,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.edelivery": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery4,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.eResults": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults4,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.note": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note4,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.postal": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal4,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.trf": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf4,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note1": APPB2C_registration_results_organisations_editRo_iol_delivery_note14,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2": APPB2C_registration_results_organisations_editRo_iol_delivery_note24,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2.results": APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results4,
  "APPB2C.registration.results.organisations.editRo.iol.newOrgTitle": APPB2C_registration_results_organisations_editRo_iol_newOrgTitle4,
  "APPB2C.registration.results.organisations.editRo.iol.noResults": APPB2C_registration_results_organisations_editRo_iol_noResults4,
  "APPB2C.registration.results.organisations.editRo.iol.removeLink": APPB2C_registration_results_organisations_editRo_iol_removeLink4,
  "APPB2C.registration.results.organisations.editRo.iol.saveCta": APPB2C_registration_results_organisations_editRo_iol_saveCta4,
  "APPB2C.registration.results.organisations.editRo.iol.validation.overallScore": APPB2C_registration_results_organisations_editRo_iol_validation_overallScore4,
  "APPB2C.registration.results.organisations.editRo.iol.validation.selectedOrganisation": APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation4,
  "APPB2C.registration.results.organisations.iol.chooseCta": APPB2C_registration_results_organisations_iol_chooseCta4,
  "APPB2C.registration.results.organisations.iol.delivery.auto.header": APPB2C_registration_results_organisations_iol_delivery_auto_header4,
  "APPB2C.registration.results.organisations.iol.delivery.auto.note": APPB2C_registration_results_organisations_iol_delivery_auto_note4,
  "APPB2C.registration.results.organisations.iol.delivery.auto.notSentNote": APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote4,
  "APPB2C.registration.results.organisations.iol.delivery.auto.sentNote": APPB2C_registration_results_organisations_iol_delivery_auto_sentNote4,
  "APPB2C.registration.results.organisations.iol.delivery.postal.header": APPB2C_registration_results_organisations_iol_delivery_postal_header4,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note1": APPB2C_registration_results_organisations_iol_delivery_postal_note14,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2": APPB2C_registration_results_organisations_iol_delivery_postal_note24,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2.results": APPB2C_registration_results_organisations_iol_delivery_postal_note2_results4,
  "APPB2C.registration.results.organisations.iol.edit": APPB2C_registration_results_organisations_iol_edit4,
  "APPB2C.registration.results.organisations.iol.error": APPB2C_registration_results_organisations_iol_error4,
  "APPB2C.registration.results.organisations.iol.generalCondition": APPB2C_registration_results_organisations_iol_generalCondition4,
  "APPB2C.registration.results.organisations.iol.noConditions": APPB2C_registration_results_organisations_iol_noConditions4,
  "APPB2C.registration.results.organisations.iol.notChosen": APPB2C_registration_results_organisations_iol_notChosen4,
  "APPB2C.registration.results.organisations.iol.preciseCondition": APPB2C_registration_results_organisations_iol_preciseCondition4,
  "APPB2C.registration.results.organisations.iol.roAccept.link": APPB2C_registration_results_organisations_iol_roAccept_link4,
  "APPB2C.registration.results.organisations.iol.roAccept.message": APPB2C_registration_results_organisations_iol_roAccept_message4,
  "APPB2C.registration.results.organisations.iol.roAccept.title": APPB2C_registration_results_organisations_iol_roAccept_title4,
  "APPB2C.registration.results.organisations.iol.sendAnyway": APPB2C_registration_results_organisations_iol_sendAnyway4,
  "APPB2C.registration.results.organisations.list.item1": APPB2C_registration_results_organisations_list_item14,
  "APPB2C.registration.results.organisations.list.item2": APPB2C_registration_results_organisations_list_item24,
  "APPB2C.registration.results.organisations.list.item3": APPB2C_registration_results_organisations_list_item34,
  "APPB2C.registration.results.organisations.list.item4": APPB2C_registration_results_organisations_list_item44,
  "APPB2C.registration.results.organisations.mod.delivery.auto.header": APPB2C_registration_results_organisations_mod_delivery_auto_header4,
  "APPB2C.registration.results.organisations.mod.delivery.auto.note": APPB2C_registration_results_organisations_mod_delivery_auto_note4,
  "APPB2C.registration.results.organisations.mod.delivery.auto.notSentNote": APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote4,
  "APPB2C.registration.results.organisations.mod.delivery.auto.sentNote": APPB2C_registration_results_organisations_mod_delivery_auto_sentNote4,
  "APPB2C.registration.results.organisations.mod.delivery.postal.header": APPB2C_registration_results_organisations_mod_delivery_postal_header4,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note1": APPB2C_registration_results_organisations_mod_delivery_postal_note14,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note2": APPB2C_registration_results_organisations_mod_delivery_postal_note24,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note3": APPB2C_registration_results_organisations_mod_delivery_postal_note34,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note4": APPB2C_registration_results_organisations_mod_delivery_postal_note44,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note5": APPB2C_registration_results_organisations_mod_delivery_postal_note54,
  "APPB2C.registration.results.organisations.mod.delivery.postal.notSentNote": APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote4,
  "APPB2C.registration.results.organisations.mod.delivery.postal.sentNote": APPB2C_registration_results_organisations_mod_delivery_postal_sentNote4,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount4,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount_other": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other4,
  "APPB2C.registration.results.organisations.mod.howTo.item1": APPB2C_registration_results_organisations_mod_howTo_item14,
  "APPB2C.registration.results.organisations.mod.howTo.item2": APPB2C_registration_results_organisations_mod_howTo_item24,
  "APPB2C.registration.results.organisations.mod.howTo.line1": APPB2C_registration_results_organisations_mod_howTo_line14,
  "APPB2C.registration.results.organisations.mod.howTo.title": APPB2C_registration_results_organisations_mod_howTo_title4,
  "APPB2C.registration.results.organisations.noReceivingOrgsSelectedNote": APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote4,
  "APPB2C.registration.results.organisations.note0": APPB2C_registration_results_organisations_note04,
  "APPB2C.registration.results.organisations.note1": APPB2C_registration_results_organisations_note14,
  "APPB2C.registration.results.organisations.note2": APPB2C_registration_results_organisations_note24,
  "APPB2C.registration.results.organisations.organisationsCount": APPB2C_registration_results_organisations_organisationsCount4,
  "APPB2C.registration.results.organisations.organisationsCount_other": APPB2C_registration_results_organisations_organisationsCount_other4,
  "APPB2C.registration.results.organisations.receivingOrgsLimitReachedNote": APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote4,
  "APPB2C.registration.results.organisations.title": APPB2C_registration_results_organisations_title4,
  "APPB2C.registration.results.osr.chooseDate.accessibility": APPB2C_registration_results_osr_chooseDate_accessibility4,
  "APPB2C.registration.results.osr.chooseDate.accessibilityLink": APPB2C_registration_results_osr_chooseDate_accessibilityLink4,
  "APPB2C.registration.results.osr.chooseDate.chooseDateTitle": APPB2C_registration_results_osr_chooseDate_chooseDateTitle4,
  "APPB2C.registration.results.osr.chooseDate.note.content": APPB2C_registration_results_osr_chooseDate_note_content4,
  "APPB2C.registration.results.osr.chooseDate.note.title": APPB2C_registration_results_osr_chooseDate_note_title4,
  "APPB2C.registration.results.osr.chooseDate.retakeListening": APPB2C_registration_results_osr_chooseDate_retakeListening4,
  "APPB2C.registration.results.osr.chooseDate.retakeReading": APPB2C_registration_results_osr_chooseDate_retakeReading4,
  "APPB2C.registration.results.osr.chooseDate.retakeSpeaking": APPB2C_registration_results_osr_chooseDate_retakeSpeaking4,
  "APPB2C.registration.results.osr.chooseDate.retakeWriting": APPB2C_registration_results_osr_chooseDate_retakeWriting4,
  "APPB2C.registration.results.osr.chooseSkill.backBtn": APPB2C_registration_results_osr_chooseSkill_backBtn4,
  "APPB2C.registration.results.osr.chooseSkill.chooseDateBtn": APPB2C_registration_results_osr_chooseSkill_chooseDateBtn4,
  "APPB2C.registration.results.osr.chooseSkill.confirmationRequired": APPB2C_registration_results_osr_chooseSkill_confirmationRequired4,
  "APPB2C.registration.results.osr.chooseSkill.formTitle": APPB2C_registration_results_osr_chooseSkill_formTitle4,
  "APPB2C.registration.results.osr.chooseSkill.modal.contact": APPB2C_registration_results_osr_chooseSkill_modal_contact4,
  "APPB2C.registration.results.osr.chooseSkill.modal.description": APPB2C_registration_results_osr_chooseSkill_modal_description4,
  "APPB2C.registration.results.osr.chooseSkill.modal.email": APPB2C_registration_results_osr_chooseSkill_modal_email4,
  "APPB2C.registration.results.osr.chooseSkill.modal.phone": APPB2C_registration_results_osr_chooseSkill_modal_phone4,
  "APPB2C.registration.results.osr.chooseSkill.modal.title": APPB2C_registration_results_osr_chooseSkill_modal_title4,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.confirmation": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation4,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.description": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description4,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.title": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title4,
  "APPB2C.registration.results.osr.chooseSkill.skillRequired": APPB2C_registration_results_osr_chooseSkill_skillRequired4,
  "APPB2C.registration.results.osr.chooseTest.availableDates": APPB2C_registration_results_osr_chooseTest_availableDates4,
  "APPB2C.registration.results.osr.chooseTest.speaking.time": APPB2C_registration_results_osr_chooseTest_speaking_time4,
  "APPB2C.registration.results.osr.error.missingFile": APPB2C_registration_results_osr_error_missingFile4,
  "APPB2C.registration.results.osr.error.noTestDates": APPB2C_registration_results_osr_error_noTestDates4,
  "APPB2C.registration.results.osr.error.noTests": APPB2C_registration_results_osr_error_noTests4,
  "APPB2C.registration.results.osr.line1": APPB2C_registration_results_osr_line14,
  "APPB2C.registration.results.osr.line2": APPB2C_registration_results_osr_line24,
  "APPB2C.registration.results.osr.line3": APPB2C_registration_results_osr_line34,
  "APPB2C.registration.results.osr.line4": APPB2C_registration_results_osr_line44,
  "APPB2C.registration.results.osr.line5": APPB2C_registration_results_osr_line54,
  "APPB2C.registration.results.osr.review.note": APPB2C_registration_results_osr_review_note4,
  "APPB2C.registration.results.osr.review.note.testDay": APPB2C_registration_results_osr_review_note_testDay4,
  "APPB2C.registration.results.osr.submitBtn": APPB2C_registration_results_osr_submitBtn4,
  "APPB2C.registration.results.osr.successBanner": APPB2C_registration_results_osr_successBanner4,
  "APPB2C.registration.results.osr.title": APPB2C_registration_results_osr_title4,
  "APPB2C.registration.results.ttAbsent.btn": APPB2C_registration_results_ttAbsent_btn4,
  "APPB2C.registration.results.ttAbsent.msg": APPB2C_registration_results_ttAbsent_msg4,
  "APPB2C.registration.results.updateBar.osr.booked": APPB2C_registration_results_updateBar_osr_booked4,
  "APPB2C.registration.results.updateBar.osr.marked": APPB2C_registration_results_updateBar_osr_marked4,
  "APPB2C.registration.tabs.bookingDetails": APPB2C_registration_tabs_bookingDetails4,
  "APPB2C.registration.tabs.candidate": APPB2C_registration_tabs_candidate4,
  "APPB2C.registration.tabs.date": APPB2C_registration_tabs_date4,
  "APPB2C.registration.tabs.dateLocation": APPB2C_registration_tabs_dateLocation4,
  "APPB2C.registration.tabs.payments": APPB2C_registration_tabs_payments4,
  "APPB2C.registration.tabs.recognisingOrgs": APPB2C_registration_tabs_recognisingOrgs4,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.1": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_14,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.2": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_24,
  "APPB2C.registration.tabs.results": APPB2C_registration_tabs_results4,
  "APPB2C.registration.testTaker.bookedFor.completed": APPB2C_registration_testTaker_bookedFor_completed4,
  "APPB2C.registration.testTaker.bookedFor.dob": APPB2C_registration_testTaker_bookedFor_dob4,
  "APPB2C.registration.testTaker.bookedFor.email": APPB2C_registration_testTaker_bookedFor_email4,
  "APPB2C.registration.testTaker.bookedFor.gender": APPB2C_registration_testTaker_bookedFor_gender4,
  "APPB2C.registration.testTaker.bookedFor.name": APPB2C_registration_testTaker_bookedFor_name4,
  "APPB2C.registration.testTaker.bookedFor.parent": APPB2C_registration_testTaker_bookedFor_parent4,
  "APPB2C.registration.testTaker.bookedFor.title": APPB2C_registration_testTaker_bookedFor_title4,
  "APPB2C.registration.testTaker.childConsent.back": APPB2C_registration_testTaker_childConsent_back4,
  "APPB2C.registration.testTaker.childConsent.context": APPB2C_registration_testTaker_childConsent_context4,
  "APPB2C.registration.testTaker.childConsent.cta": APPB2C_registration_testTaker_childConsent_cta4,
  "APPB2C.registration.testTaker.childConsent.options.1": APPB2C_registration_testTaker_childConsent_options_14,
  "APPB2C.registration.testTaker.childConsent.options.2": APPB2C_registration_testTaker_childConsent_options_24,
  "APPB2C.registration.testTaker.childConsent.options.3": APPB2C_registration_testTaker_childConsent_options_34,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.add": APPB2C_registration_testTaker_childConsent_somebodyElse_add4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmCheckbox": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.1": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_14,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.2": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_24,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.noLastName": APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.note": APPB2C_registration_testTaker_childConsent_somebodyElse_note4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phone": APPB2C_registration_testTaker_childConsent_somebodyElse_phone4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phoneNote": APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.policy": APPB2C_registration_testTaker_childConsent_somebodyElse_policy4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.relationship": APPB2C_registration_testTaker_childConsent_somebodyElse_relationship4,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.1": APPB2C_registration_testTaker_childConsent_somebodyElse_title_14,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.2": APPB2C_registration_testTaker_childConsent_somebodyElse_title_24,
  "APPB2C.registration.testTaker.childConsent.title": APPB2C_registration_testTaker_childConsent_title4,
  "APPB2C.registration.testTaker.childConsent.warning": APPB2C_registration_testTaker_childConsent_warning4,
  "APPB2C.registration.testTaker.consent.edit": APPB2C_registration_testTaker_consent_edit4,
  "APPB2C.registration.testTaker.consent.missing": APPB2C_registration_testTaker_consent_missing4,
  "APPB2C.registration.testTaker.consent.title": APPB2C_registration_testTaker_consent_title4,
  "APPB2C.registration.testTaker.contact.address": APPB2C_registration_testTaker_contact_address4,
  "APPB2C.registration.testTaker.contact.edit": APPB2C_registration_testTaker_contact_edit4,
  "APPB2C.registration.testTaker.contact.mobile": APPB2C_registration_testTaker_contact_mobile4,
  "APPB2C.registration.testTaker.contact.parent": APPB2C_registration_testTaker_contact_parent4,
  "APPB2C.registration.testTaker.contact.title": APPB2C_registration_testTaker_contact_title4,
  "APPB2C.registration.testTaker.editContact.address": APPB2C_registration_testTaker_editContact_address4,
  "APPB2C.registration.testTaker.editContact.back": APPB2C_registration_testTaker_editContact_back4,
  "APPB2C.registration.testTaker.editContact.city": APPB2C_registration_testTaker_editContact_city4,
  "APPB2C.registration.testTaker.editContact.country.change": APPB2C_registration_testTaker_editContact_country_change4,
  "APPB2C.registration.testTaker.editContact.country.residence": APPB2C_registration_testTaker_editContact_country_residence4,
  "APPB2C.registration.testTaker.editContact.cta": APPB2C_registration_testTaker_editContact_cta4,
  "APPB2C.registration.testTaker.editContact.error.missingAddress": APPB2C_registration_testTaker_editContact_error_missingAddress4,
  "APPB2C.registration.testTaker.editContact.error.missingCountry": APPB2C_registration_testTaker_editContact_error_missingCountry4,
  "APPB2C.registration.testTaker.editContact.error.pastCutoff": APPB2C_registration_testTaker_editContact_error_pastCutoff4,
  "APPB2C.registration.testTaker.editContact.mobile": APPB2C_registration_testTaker_editContact_mobile4,
  "APPB2C.registration.testTaker.editContact.note": APPB2C_registration_testTaker_editContact_note4,
  "APPB2C.registration.testTaker.editContact.postCode": APPB2C_registration_testTaker_editContact_postCode4,
  "APPB2C.registration.testTaker.editContact.state": APPB2C_registration_testTaker_editContact_state4,
  "APPB2C.registration.testTaker.editContact.warn": APPB2C_registration_testTaker_editContact_warn4,
  "APPB2C.registration.testTaker.error": APPB2C_registration_testTaker_error4,
  "APPB2C.registration.testTaker.files.editButton.add": APPB2C_registration_testTaker_files_editButton_add4,
  "APPB2C.registration.testTaker.files.editButton.edit": APPB2C_registration_testTaker_files_editButton_edit4,
  "APPB2C.registration.testTaker.files.upload.back.subtitle": APPB2C_registration_testTaker_files_upload_back_subtitle4,
  "APPB2C.registration.testTaker.files.upload.back.title": APPB2C_registration_testTaker_files_upload_back_title4,
  "APPB2C.registration.testTaker.files.upload.cta": APPB2C_registration_testTaker_files_upload_cta4,
  "APPB2C.registration.testTaker.files.upload.error.fileTooLarge": APPB2C_registration_testTaker_files_upload_error_fileTooLarge4,
  "APPB2C.registration.testTaker.files.upload.error.maxNumberOfFilesExceeded": APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded4,
  "APPB2C.registration.testTaker.files.upload.error.uploadUnavailable": APPB2C_registration_testTaker_files_upload_error_uploadUnavailable4,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileName": APPB2C_registration_testTaker_files_upload_error_wrongFileName4,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileType": APPB2C_registration_testTaker_files_upload_error_wrongFileType4,
  "APPB2C.registration.testTaker.files.upload.mobile.line1": APPB2C_registration_testTaker_files_upload_mobile_line14,
  "APPB2C.registration.testTaker.files.upload.mobile.line2": APPB2C_registration_testTaker_files_upload_mobile_line24,
  "APPB2C.registration.testTaker.files.upload.mobile.title": APPB2C_registration_testTaker_files_upload_mobile_title4,
  "APPB2C.registration.testTaker.files.upload.note": APPB2C_registration_testTaker_files_upload_note4,
  "APPB2C.registration.testTaker.id.child": APPB2C_registration_testTaker_id_child4,
  "APPB2C.registration.testTaker.id.edit.rules.example": APPB2C_registration_testTaker_id_edit_rules_example4,
  "APPB2C.registration.testTaker.id.edit.subtitle": APPB2C_registration_testTaker_id_edit_subtitle4,
  "APPB2C.registration.testTaker.id.edit.title": APPB2C_registration_testTaker_id_edit_title4,
  "APPB2C.registration.testTaker.id.edit.upload.exampleProof": APPB2C_registration_testTaker_id_edit_upload_exampleProof4,
  "APPB2C.registration.testTaker.id.edit.upload.imgAlt": APPB2C_registration_testTaker_id_edit_upload_imgAlt4,
  "APPB2C.registration.testTaker.id.expiryDate": APPB2C_registration_testTaker_id_expiryDate4,
  "APPB2C.registration.testTaker.id.fileAlt": APPB2C_registration_testTaker_id_fileAlt4,
  "APPB2C.registration.testTaker.id.idNumber": APPB2C_registration_testTaker_id_idNumber4,
  "APPB2C.registration.testTaker.id.issuingAuthority": APPB2C_registration_testTaker_id_issuingAuthority4,
  "APPB2C.registration.testTaker.id.noFilesBody": APPB2C_registration_testTaker_id_noFilesBody4,
  "APPB2C.registration.testTaker.id.noFilesTitle": APPB2C_registration_testTaker_id_noFilesTitle4,
  "APPB2C.registration.testTaker.id.title": APPB2C_registration_testTaker_id_title4,
  "APPB2C.registration.testTaker.id.typeNames.card": APPB2C_registration_testTaker_id_typeNames_card4,
  "APPB2C.registration.testTaker.id.typeNames.other": APPB2C_registration_testTaker_id_typeNames_other4,
  "APPB2C.registration.testTaker.id.typeNames.passport": APPB2C_registration_testTaker_id_typeNames_passport4,
  "APPB2C.registration.testTaker.specialArrangements.description": APPB2C_registration_testTaker_specialArrangements_description4,
  "APPB2C.registration.testTaker.specialArrangements.edit.line1": APPB2C_registration_testTaker_specialArrangements_edit_line14,
  "APPB2C.registration.testTaker.specialArrangements.edit.line2": APPB2C_registration_testTaker_specialArrangements_edit_line24,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule1": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule14,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule2": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule24,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule3": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule34,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule4": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule44,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule5": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule54,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.title": APPB2C_registration_testTaker_specialArrangements_edit_rules_title4,
  "APPB2C.registration.testTaker.specialArrangements.edit.subtitle": APPB2C_registration_testTaker_specialArrangements_edit_subtitle4,
  "APPB2C.registration.testTaker.specialArrangements.edit.title": APPB2C_registration_testTaker_specialArrangements_edit_title4,
  "APPB2C.registration.testTaker.specialArrangements.fileAlt": APPB2C_registration_testTaker_specialArrangements_fileAlt4,
  "APPB2C.registration.testTaker.specialArrangements.noFilesBody": APPB2C_registration_testTaker_specialArrangements_noFilesBody4,
  "APPB2C.registration.testTaker.specialArrangements.noFilesTitle": APPB2C_registration_testTaker_specialArrangements_noFilesTitle4,
  "APPB2C.registration.testTaker.specialArrangements.title": APPB2C_registration_testTaker_specialArrangements_title4,
  "APPB2C.testCard.arrival": APPB2C_testCard_arrival4,
  "APPB2C.testCard.book": APPB2C_testCard_book4,
  "APPB2C.testCard.bookExam": APPB2C_testCard_bookExam4,
  "APPB2C.testCard.bookFor": APPB2C_testCard_bookFor4,
  "APPB2C.testCard.bookForFree": APPB2C_testCard_bookForFree4,
  "APPB2C.testCard.bookingPrice": APPB2C_testCard_bookingPrice4,
  "APPB2C.testCard.cd": APPB2C_testCard_cd4,
  "APPB2C.testCard.changeSpeaking": APPB2C_testCard_changeSpeaking4,
  "APPB2C.testCard.f2f": APPB2C_testCard_f2f4,
  "APPB2C.testCard.hideSpeakingTests": APPB2C_testCard_hideSpeakingTests4,
  "APPB2C.testCard.lrw": APPB2C_testCard_lrw4,
  "APPB2C.testCard.lrwHeader": APPB2C_testCard_lrwHeader3,
  "APPB2C.testCard.ls": APPB2C_testCard_ls4,
  "APPB2C.testCard.mixedSlots": APPB2C_testCard_mixedSlots4,
  "APPB2C.testCard.notLinkedVenue": APPB2C_testCard_notLinkedVenue4,
  "APPB2C.testCard.pb": APPB2C_testCard_pb4,
  "APPB2C.testCard.review": APPB2C_testCard_review4,
  "APPB2C.testCard.showSpeakingTests": APPB2C_testCard_showSpeakingTests4,
  "APPB2C.testCard.speaking": APPB2C_testCard_speaking4,
  "APPB2C.testCard.speakingAddress": APPB2C_testCard_speakingAddress4,
  "APPB2C.testCard.timeUnknown": APPB2C_testCard_timeUnknown4,
  "APPB2C.testCard.vcs": APPB2C_testCard_vcs4
};

// src/locale/zh-CN/b2c.json
var APPB2C_auth_changePassword_cta5 = "\u8BBE\u7F6E\u5BC6\u7801";
var APPB2C_auth_changePassword_error5 = "\u770B\u8D77\u6765\u51FA\u4E86\u4EC0\u4E48\u95EE\u9898\u3002\u8BF7\u5728\u51E0\u5206\u949F\u540E\u91CD\u8BD5\u3002";
var APPB2C_auth_changePassword_error_differentPasswords5 = "\u8F93\u5165\u7684\u5BC6\u7801\u4E0D\u540C\u3002";
var APPB2C_auth_changePassword_error_wrongIdNumber5 = "\u8EAB\u4EFD\u8BC1\u53F7\u7801\u4E0D\u6B63\u786E\u3002";
var APPB2C_auth_changePassword_error_wrongOldPassword5 = "\u65E7\u5BC6\u7801\u4E0D\u6B63\u786E\u3002";
var APPB2C_auth_changePassword_error_wrongPassword5 = "\u5BC6\u7801\u4E0D\u7B26\u5408\u8981\u6C42\u3002";
var APPB2C_auth_changePassword_id_label5 = "\u62A4\u7167\u53F7\u7801/\u56FD\u6C11\u8EAB\u4EFD\u8BC1\u53F7\u7801";
var APPB2C_auth_changePassword_id_note5 = "\u8981\u7EE7\u7EED\u767B\u5F55, \u8BF7\u5728\u4E0B\u9762\u6DFB\u52A0\u60A8\u7684\u62A4\u7167\u53F7\u7801/\u56FD\u6C11\u8EAB\u4EFD\u8BC1\u53F7\u7801\u3002";
var APPB2C_auth_changePassword_id_warn5 = '\u5982\u679C\u60A8\u5728\u63D0\u4F9B\u6B63\u786E\u7684\u8EAB\u4EFD\u8BC1\u4EF6\u65F6\u4ECD\u7136\u770B\u5230"\u8EAB\u4EFD\u8BC1\u53F7\u7801\u4E0D\u6B63\u786E"\u9519\u8BEF, \u8BF7\u8054\u7CFB\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u4E2D\u5FC3\u3002';
var APPB2C_auth_changePassword_pass_label5 = "\u5BC6\u7801";
var APPB2C_auth_changePassword_pass_note5 = "\u8BF7\u8BBE\u7F6E\u60A8\u9009\u62E9\u7684\u5BC6\u7801\u4EE5\u7EE7\u7EED\u3002";
var APPB2C_auth_changePassword_title5 = "\u8BBE\u7F6E\u65B0\u5BC6\u7801";
var APPB2C_auth_forgetPassword_cta5 = "\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u5411\u6211\u53D1\u9001\u6062\u590D\u94FE\u63A5";
var APPB2C_auth_forgetPassword_email5 = "\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_auth_forgetPassword_success_msg15 = "\u8C22\u8C22\uFF01\u5982\u679C\u5E10\u6237\u5B58\u5728, \u5C06\u53D1\u9001\u4E00\u5C01\u5305\u542B\u8FDB\u4E00\u6B65\u8BF4\u660E\u7684\u7535\u5B50\u90AE\u4EF6\u3002";
var APPB2C_auth_forgetPassword_success_msg25 = "\u8BF7\u5728\u6536\u5230\u94FE\u63A5\u65F6\u5355\u51FB\u94FE\u63A5\u3002";
var APPB2C_auth_forgetPassword_success_title5 = "\u6210\u529F\uFF01";
var APPB2C_auth_forgetPassword_text5 = "\u5FD8\u8BB0\u5BC6\u7801\uFF1F\u6CA1\u95EE\u9898, \u6211\u4EEC\u53EF\u4EE5\u5411\u60A8\u53D1\u9001\u4E00\u4E2A\u94FE\u63A5\u6765\u6062\u590D\u5B83\u3002";
var APPB2C_auth_forgetPassword_title5 = "\u6062\u590D\u5BC6\u7801";
var APPB2C_auth_resetPassword_cta5 = "\u91CD\u7F6E\u5BC6\u7801";
var APPB2C_auth_resetPassword_error_expiredToken5 = '\u91CD\u7F6E\u5BC6\u7801\u7684\u94FE\u63A5\u5DF2\u8FC7\u671F\u3002\u8BF7\u4F7F\u7528\u767B\u5F55\u5C4F\u5E55\u4E0A\u7684"\u5FD8\u8BB0\u5BC6\u7801"\u6765\u63A5\u6536\u65B0\u94FE\u63A5\u3002';
var APPB2C_auth_resetPassword_error_wrongPassword5 = "\u60A8\u8F93\u5165\u7684\u5BC6\u7801\u4E0D\u7B26\u5408\u8981\u6C42\u3002";
var APPB2C_auth_resetPassword_error_wrongToken5 = "\u5BC6\u7801\u91CD\u7F6E\u7684\u4E34\u65F6\u94FE\u63A5\u65E0\u6548\u3002";
var APPB2C_auth_resetPassword_form_newPassword5 = "\u65B0\u5BC6\u7801";
var APPB2C_auth_resetPassword_noToken_cta5 = "\u627E\u56DE\u60A8\u7684\u5BC6\u7801";
var APPB2C_auth_resetPassword_noToken_msg5 = "\u65E0\u6CD5\u8BC6\u522B\u63D0\u4F9B\u7684\u4EE4\u724C";
var APPB2C_auth_resetPassword_success_msg5 = "\u60A8\u7684\u5BC6\u7801\u5DF2\u6210\u529F\u66F4\u6539\u3002\u60A8\u5C06\u5F88\u5FEB\u88AB\u91CD\u5B9A\u5411...";
var APPB2C_auth_resetPassword_success_title5 = "\u6210\u529F\uFF01";
var APPB2C_auth_resetPassword_title5 = "\u91CD\u7F6E\u5BC6\u7801";
var APPB2C_cj_basic_cta5 = "\u4FDD\u5B58\u5E76\u7EE7\u7EED";
var APPB2C_cj_basic_goToReview5 = "\u4FDD\u5B58\u5E76\u67E5\u770B";
var APPB2C_cj_basic_scrollLink5 = "\u67E5\u770B\u8981\u68C0\u67E5\u7684\u7B54\u6848\u3002";
var APPB2C_cj_basic_selectDate5 = "\u9009\u62E9\u65E5\u671F";
var APPB2C_cj_basic_selectTime5 = "\u9009\u62E9\u65F6\u95F4";
var APPB2C_cj_basic_validationError5 = "\u8BF7\u68C0\u67E5\u6240\u6709\u7A81\u51FA\u663E\u793A\u7684\u7B54\u6848\u4EE5\u7EE7\u7EED\u3002";
var APPB2C_cj_bookingComplete_feeDetails_discount5 = "\u664B\u5347";
var APPB2C_cj_bookingComplete_feeDetails_fee5 = "\u8D39";
var APPB2C_cj_bookingComplete_feeDetails_newFee5 = "\u65B0\u8D39\u7528";
var APPB2C_cj_bookingComplete_feeDetails_promo5 = "\u4FC3\u9500";
var APPB2C_cj_bookingComplete_feeDetails_reference5 = "\u53C2\u8003";
var APPB2C_cj_bookingComplete_feeDetails_tax5 = "\u7A0E";
var APPB2C_cj_bookingComplete_feeDetails_title5 = "\u8D39\u7528\u8BE6\u60C5";
var APPB2C_cj_bookingComplete_feeDetails_total5 = "\u603B";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine15 = "\u8003\u8BD5\u8D39\u7528\u5DF2\u6298\u6263\u81F3";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine25 = "\u901A\u8FC7\u4F7F\u7528\u4FC3\u9500\u4EE3\u7801\u3002";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine35 = "\u60A8\u65E0\u9700\u652F\u4ED8\u4EFB\u4F55\u8D39\u7528\u3002";
var APPB2C_cj_bookingComplete_loadingPaymentMethodsError5 = "\u6211\u4EEC\u76EE\u524D\u65E0\u6CD5\u52A0\u8F7D\u53EF\u7528\u7684\u4ED8\u6B3E\u65B9\u5F0F\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_bookingComplete_loadingRegistrationError5 = "\u6211\u4EEC\u76EE\u524D\u65E0\u6CD5\u52A0\u8F7D\u60A8\u7684\u6CE8\u518C\u8BE6\u7EC6\u4FE1\u606F\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_bookingComplete_note5 = "\u606D\u559C, \u60A8\u7684\u96C5\u601D\u8003\u8BD5\u5DF2\u9884\u8BA2\u3002\u6211\u4EEC\u5DF2\u5C06\u9884\u8BA2\u786E\u8BA4\u53D1\u9001\u5230\u60A8\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740(\u4E0D\u8981\u5FD8\u8BB0\u68C0\u67E5\u60A8\u7684\u5783\u573E\u90AE\u4EF6\u6587\u4EF6\u5939\uFF01";
var APPB2C_cj_bookingComplete_noteUolPassportInfo5 = "\u60A8\u7684\u9884\u8BA2\u9700\u8981\u9A8C\u8BC1\u60A8\u7684\u62A4\u7167\u3002\u6211\u4EEC\u4F1A\u5C3D\u5FEB\u4E0E\u60A8\u8054\u7CFB\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_code5 = "\u6392\u5E8F\u4EE3\u7801";
var APPB2C_cj_bookingComplete_offlinePayment_description5 = "\u4ED8\u6B3E\u8BF4\u660E: ";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line15 = "\u8003\u8BD5\u8D39\u7528\u5DF2\u6298\u6263\u81F3";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line25 = "\u4E3A\u4E86\u786E\u8BA4\u60A8\u7684\u9884\u8BA2, \u8BF7\u652F\u4ED8\u5269\u4F59\u7684\u8D39\u7528";
var APPB2C_cj_bookingComplete_offlinePayment_fee5 = "\u8D39";
var APPB2C_cj_bookingComplete_offlinePayment_hideDescription5 = "\u9690\u85CF\u4ED8\u6B3E\u8BF4\u660E";
var APPB2C_cj_bookingComplete_offlinePayment_incTax5 = "\u542B\u7A0E";
var APPB2C_cj_bookingComplete_offlinePayment_name5 = "\u5E10\u6237\u540D\u79F0";
var APPB2C_cj_bookingComplete_offlinePayment_note_line15 = "\u60A8\u73B0\u5728\u6709 {{\u5929}} \u4E2A\u5DE5\u4F5C\u65E5\u8981\u652F\u4ED8\u3002\u5982\u679C\u60A8\u672A\u5728\u6B64\u65F6\u95F4\u5185\u4ED8\u6B3E, \u60A8\u7684\u8003\u8BD5\u5730\u70B9\u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours5 = "\u60A8\u73B0\u5728\u6709 {{\u5C0F\u65F6}} \u5C0F\u65F6\u8981\u652F\u4ED8\u3002\u5982\u679C\u60A8\u672A\u5728\u6B64\u65F6\u95F4\u5185\u4ED8\u6B3E, \u60A8\u7684\u8003\u8BD5\u5730\u70B9\u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft5 = "\u60A8\u5FC5\u987B\u5728 {{\u65E5\u671F}} \u4E4B\u524D\u4ED8\u6B3E\u3002\u5982\u679C\u60A8\u672A\u5728\u6B64\u65F6\u95F4\u5185\u4ED8\u6B3E, \u60A8\u7684\u8003\u8BD5\u5730\u70B9\u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_line25 = "\u7136\u540E, \u60A8\u9700\u8981\u91CD\u65B0\u767B\u5F55\u6B64\u7AD9\u70B9\u4EE5\u4E0A\u4F20\u60A8\u7684\u4ED8\u6B3E\u8BC1\u660E\u3002";
var APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote5 = "\u8BF7\u6CE8\u610F";
var APPB2C_cj_bookingComplete_offlinePayment_number5 = "\u5E10\u53F7";
var APPB2C_cj_bookingComplete_offlinePayment_reference5 = "\u4ED8\u6B3E\u53C2\u8003";
var APPB2C_cj_bookingComplete_offlinePayment_showDescription5 = "\u663E\u793A\u4ED8\u6B3E\u8BF4\u660E";
var APPB2C_cj_bookingComplete_otherPaymentOptions5 = "\u67E5\u770B\u5176\u4ED6\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_cj_bookingComplete_paymentFailed5 = "\u62B1\u6B49, \u60A8\u7684\u5728\u7EBF\u4ED8\u6B3E\u5931\u8D25\uFF01\u6211\u4EEC\u4ECD\u7136\u4E3A\u60A8\u9884\u8BA2\u4E86\u6D4B\u8BD5, \u4F46\u60A8\u9700\u8981\u5C1D\u8BD5\u518D\u6B21\u652F\u4ED8\u6D4B\u8BD5\u8D39\u7528\u3002";
var APPB2C_cj_bookingComplete_paymentOptions5 = "\u67E5\u770B\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_cj_bookingComplete_prepareNote_accesNote5 = "\u514D\u8D39\u8BBF\u95EE\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u63D0\u4F9B\u7684\u96C5\u601D\u8003\u8BD5\u6750\u6599\u548C\u8D44\u6E90\u3002";
var APPB2C_cj_bookingComplete_prepareNote_counselling_note5 = "\u901A\u8FC7\u6211\u4EEC\u7684\u96C5\u601D\u5347\u5B66\u6307\u5BFC\u5957\u9910, \u60A8\u5C06\u6709\u673A\u4F1A\u4ECE\u6211\u4EEC\u7684\u96C5\u601D\u4E13\u5BB6\u90A3\u91CC\u83B7\u5F97\u6700\u4F73\u652F\u6301, \u4ED6\u4EEC\u5C06\u6307\u5BFC\u5E76\u5EFA\u8BAE\u60A8\u5982\u4F55\u6700\u597D\u5730\u51C6\u5907\u8003\u8BD5\u4EE5\u8FBE\u5230\u60A8\u60F3\u8981\u7684\u5206\u6570\u3002";
var APPB2C_cj_bookingComplete_prepareNote_counselling_view5 = "\u67E5\u770B\u6211\u4EEC\u7684\u54A8\u8BE2\u670D\u52A1";
var APPB2C_cj_bookingComplete_prepareNote_note5 = "\u786E\u4FDD\u60A8\u51C6\u5907\u597D\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u7684\u7B2C\u4E00\u6B65\u662F\u505A\u597D\u51C6\u5907, \u4EE5\u4FBF\u60A8\u77E5\u9053\u8003\u8BD5\u5F53\u5929\u4F1A\u53D1\u751F\u4EC0\u4E48\u3002";
var APPB2C_cj_bookingComplete_prepareNote_title5 = "\u51C6\u5907\u8003\u8BD5";
var APPB2C_cj_bookingComplete_prepareNote_view5 = "\u67E5\u770B\u51C6\u5907\u6750\u6599";
var APPB2C_cj_bookingComplete_title5 = "\u9884\u8BA2\u5B8C\u6210";
var APPB2C_cj_bookingComplete_tnc5 = "\u60A8\u540C\u610F\u8FD9\u4E9B";
var APPB2C_cj_bookingComplete_tncLink5 = "\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_cj_bookingComplete_tryAgain5 = "\u518D\u6B21\u5C1D\u8BD5\u4ED8\u6B3E";
var APPB2C_cj_bookingComplete_yourBooking5 = "\u60A8\u7684\u9884\u8BA2";
var APPB2C_cj_childDetails_about5 = "\u5173\u4E8E\u60A8\u7684\u5B69\u5B50";
var APPB2C_cj_childDetails_aboutNote5 = "\u8BF7\u6DFB\u52A0\u53C2\u52A0\u6D4B\u8BD5\u7684\u5B69\u5B50\u7684\u8BE6\u7EC6\u4FE1\u606F: ";
var APPB2C_cj_childDetails_error_missingFirstName5 = "\u8BF7\u8F93\u5165\u60A8\u5B69\u5B50\u7684\u540D\u5B57/\u540D\u5B57\u3002";
var APPB2C_cj_childDetails_error_missingSurname5 = '\u8BF7\u8F93\u5165\u60A8\u5B69\u5B50\u7684\u59D3\u6C0F/\u59D3\u6C0F\u3002\u5982\u679C\u4ED6\u4EEC\u6CA1\u6709\u59D3\u6C0F, \u8BF7\u5728\u6B64\u5B57\u6BB5\u4E2D\u8F93\u5165\u8FDE\u5B57\u7B26("-")\u3002';
var APPB2C_cj_childDetails_error_wrongDob5 = "\u8BF7\u63D0\u4F9B\u60A8\u5B69\u5B50\u7684\u5B9E\u9645\u51FA\u751F\u65E5\u671F\u3002";
var APPB2C_cj_childDetails_forms_personal_idMatch5 = "\u8FD9\u5FC5\u987B\u4E0E\u5176\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E0A\u7684\u59D3\u540D\u4E00\u81F4";
var APPB2C_cj_childDetails_noLastName5 = "\u4ED6\u4EEC\u6CA1\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_childDetails_note5 = "\u4ED6\u4EEC\u7684\u5168\u540D\u548C\u51FA\u751F\u65E5\u671F\u5FC5\u987B\u4E0E\u8003\u8BD5\u5730\u70B9\u51FA\u793A\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_childDetails_onTestDay5 = "\u8003\u8BD5\u5F53\u5929";
var APPB2C_cj_childDetails_title5 = "\u60A8\u5B69\u5B50\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_cj_finishPayment_error5 = "\u6211\u4EEC\u76EE\u524D\u65E0\u6CD5\u5904\u7406\u60A8\u7684\u4ED8\u6B3E\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_finishPayment_pleaseWait5 = "\u8BF7\u7A0D\u5019";
var APPB2C_cj_finishPayment_processing5 = "\u6211\u4EEC\u6B63\u5728\u5904\u7406\u60A8\u7684\u4ED8\u6B3E...";
var APPB2C_cj_idDetails_confirm_change5 = "\u6211\u9700\u8981\u66F4\u6539\u8FD9\u4E9B\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_cj_idDetails_confirm_confirm5 = "\u6211\u786E\u8BA4\u8FD9\u4E9B\u7EC6\u8282\u662F\u6B63\u786E\u7684";
var APPB2C_cj_idDetails_confirm_note15 = "\u786E\u4FDD\u60A8\u544A\u8BC9\u6211\u4EEC\u7684$t({{idName}})\u8BE6\u7EC6\u4FE1\u606F\u6B63\u786E\u65E0\u8BEF\u975E\u5E38\u91CD\u8981\u3002\u5728\u7EE7\u7EED\u4E4B\u524D, \u8BF7\u5728\u4E0B\u9762\u68C0\u67E5\u5B83\u4EEC\u3002";
var APPB2C_cj_idDetails_confirm_note25 = "\u5728\u8003\u8BD5\u5F53\u5929, \u60A8\u9700\u8981\u968F\u8EAB\u643A\u5E26\u6B64$t({{idName}})\u3002";
var APPB2C_cj_idDetails_confirm_title5 = "\u8BF7\u5728\u7EE7\u7EED\u4E4B\u524D\u68C0\u67E5\u60A8\u7684$t({{idName}})";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_content5 = "\u5982\u679C\u60A8\u7684\u8EAB\u4EFD\u8BC1\u4EF6\u8BE6\u7EC6\u4FE1\u606F\u6709\u8BEF, \u53EF\u80FD\u4F1A\u5EF6\u8FDF\u7ED3\u679C\u7684\u53D1\u5E03\u3002";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_cj_idDetails_error_missingIdExpiry5 = "\u8BF7\u63D0\u4F9B\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u7684\u6709\u6548\u671F\u3002";
var APPB2C_cj_idDetails_error_missingIdNumber5 = "\u8BF7\u63D0\u4F9B\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u53F7\u7801\u3002\u5B83\u9700\u8981\u5339\u914D\u7279\u5B9A\u7684\u683C\u5F0F(\u5141\u8BB8\u4F7F\u7528\u5B57\u6BCD\u3001\u6570\u5B57\u548C\u7279\u6B8A\u5B57\u7B26: _\\-/, )\u3002";
var APPB2C_cj_idDetails_error_missingIdType5 = "\u8BF7\u63D0\u4F9B\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u7C7B\u578B\u3002";
var APPB2C_cj_idDetails_error_missingIssuingAuthority5 = "\u8BF7\u63D0\u4F9B\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u7684\u7B7E\u53D1\u673A\u5173\u3002";
var APPB2C_cj_idDetails_error_wrongFilename5 = "\u6587\u4EF6\u540D\u5305\u542B\u65E0\u6548\u5B57\u7B26\u3002";
var APPB2C_cj_idDetails_error_wrongIdExpiry5 = "\u8BF7\u63D0\u4F9B\u5728\u6240\u9009\u8003\u8BD5\u65E5\u671F\u4E4B\u524D\u4E0D\u4F1A\u8FC7\u671F\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u3002";
var APPB2C_cj_idDetails_forms_changeNote5 = "\u60A8\u5DF2\u66F4\u6539\u6240\u9009\u6807\u8BC6\u7C7B\u578B\u3002\u8BF7\u786E\u4FDD\u4E0B\u9762\u4E0A\u4F20\u7684\u56FE\u50CF\u4E0E\u60A8\u5728\u8003\u8BD5\u5F53\u5929\u643A\u5E26\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_idDetails_forms_changeNote_child5 = "\u60A8\u5DF2\u66F4\u6539\u6240\u9009\u6807\u8BC6\u7C7B\u578B\u3002\u8BF7\u786E\u4FDD\u4E0B\u9762\u4E0A\u4F20\u7684\u56FE\u50CF\u4E0E\u8003\u8BD5\u5F53\u5929\u643A\u5E26\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_idDetails_forms_documentWithNoExpiryDate5 = "\u6587\u6863\u6CA1\u6709\u5230\u671F\u65E5\u671F";
var APPB2C_cj_idDetails_forms_expiryDate5 = "$t({{idName}}, capitalise) \u5230\u671F\u65E5\u671F";
var APPB2C_cj_idDetails_forms_idNumber5 = "$t({{idName}}, capitalise) number";
var APPB2C_cj_idDetails_forms_idType5 = "\u6807\u8BC6\u7C7B\u578B";
var APPB2C_cj_idDetails_forms_issuingAuthority5 = "\u53D1\u8BC1\u673A\u5173";
var APPB2C_cj_idDetails_idExpired5 = "\u60A8\u4E4B\u524D\u6CE8\u518C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u73B0\u5DF2\u8FC7\u671F\u3002\u8BF7\u6DFB\u52A0\u65B0\u7684\u8EAB\u4EFD\u8BC1\u660E\u3002";
var APPB2C_cj_idDetails_iolMessage5 = "\u5728\u8003\u8BD5\u5F53\u5929, \u60A8\u5C06\u9700\u8981\u51FA\u793A\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u76F8\u540C\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u3002";
var APPB2C_cj_idDetails_noteAboutIdentification5 = "\u5728\u8003\u8BD5\u5F53\u5929, \u60A8\u5C06\u88AB\u8981\u6C42\u643A\u5E26\u4E0E\u6CE8\u518C\u76F8\u540C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u3002";
var APPB2C_cj_idDetails_noteAboutIdentification_child5 = "\u5728\u8003\u8BD5\u5F53\u5929, \u60A8\u7684\u5B69\u5B50\u5C06\u88AB\u8981\u6C42\u643A\u5E26\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u76F8\u540C\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u3002";
var APPB2C_cj_idDetails_noteAboutIdentificationIol5 = "\u62A4\u7167\u662F\u76EE\u524D\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u552F\u4E00\u53EF\u63A5\u53D7\u7684\u8EAB\u4EFD\u8BC1\u4EF6\u3002\u5982\u679C\u60A8\u5E0C\u671B\u4F7F\u7528\u5176\u4ED6\u53EF\u63A5\u53D7\u7684\u8EAB\u4EFD\u8BC1\u4EF6, \u60A8\u5E94\u8BE5\u53C2\u52A0\u96C5\u601D\u7EB8\u7B14\u8003\u8BD5\u6216\u96C5\u601D\u673A\u8003\u3002";
var APPB2C_cj_idDetails_noteAboutIdentificationIolLink5 = "\u5982\u679C\u60A8\u6CA1\u6709\u6709\u6548\u62A4\u7167, \u8BF7\u4F7F\u7528\u6B64\u94FE\u63A5\u9884\u8BA2\u5176\u4ED6\u8003\u8BD5\u3002";
var APPB2C_cj_idDetails_noteAboutIdentificationUol5 = "\u62A4\u7167\u662F\u76EE\u524D\u552F\u4E00\u53EF\u63A5\u53D7\u7684\u7528\u4E8E UKVI \u5728\u7EBF\u96C5\u601D\u8003\u8BD5\u7684\u8EAB\u4EFD\u8BC1\u4EF6\u3002\u5982\u679C\u60A8\u5E0C\u671B\u4F7F\u7528\u5176\u4ED6\u53EF\u63A5\u53D7\u7684\u8EAB\u4EFD\u8BC1\u4EF6, \u60A8\u5E94\u8BE5\u53C2\u52A0\u96C5\u601D UKVI \u7EB8\u7B14\u8003\u8BD5\u6216\u96C5\u601D UKVI \u673A\u8003\u3002";
var APPB2C_cj_idDetails_title5 = "\u8EAB\u4EFD\u8BC1\u660E\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_cj_idDetails_title_child5 = "\u60A8\u5B69\u5B50\u7684\u8EAB\u4EFD\u8BC1\u660E";
var APPB2C_cj_idDetails_typeNames_card5 = "\u8EAB\u4EFD\u8BC1";
var APPB2C_cj_idDetails_typeNames_other5 = "\u8EAB\u4EFD\u8BC1";
var APPB2C_cj_idDetails_typeNames_passport5 = "\u62A4\u7167";
var APPB2C_cj_idDetails_upload_fetchError5 = "\u6211\u4EEC\u65E0\u6CD5\u5904\u7406\u60A8\u7684\u6587\u4EF6\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_idDetails_upload_file15 = "\u56FE\u7247 1";
var APPB2C_cj_idDetails_upload_file25 = "\u56FE 2(\u53EF\u9009)";
var APPB2C_cj_idDetails_upload_instructions_capture5 = "\u6355\u83B7\u6574\u4E2A\u6587\u6863";
var APPB2C_cj_idDetails_upload_instructions_covers5 = "\u786E\u4FDD\u6CA1\u6709\u4EFB\u4F55\u4E1C\u897F\u8986\u76D6\u60A8\u7684\u6587\u6863(\u624B\u6307\u3001\u8D34\u7EB8\u3001\u56DE\u5F62\u9488\u7B49)";
var APPB2C_cj_idDetails_upload_instructions_directLight5 = "\u8FDC\u79BB\u76F4\u5C04\u5149\u4EE5\u907F\u514D\u7729\u5149\u3002";
var APPB2C_cj_idDetails_upload_instructions_noBlur5 = "\u786E\u4FDD\u7167\u7247/\u626B\u63CF\u6E05\u6670\u5BF9\u7126, \u6CA1\u6709\u6A21\u7CCA\u3002";
var APPB2C_cj_idDetails_upload_instructions_title5 = "\u5982\u4F55\u62CD\u6444\u5408\u9002\u7684\u7167\u7247/\u626B\u63CF: ";
var APPB2C_cj_idDetails_upload_mobile5 = "\u7531\u4E8E\u60A8\u770B\u8D77\u6765\u50CF\u662F\u5728\u79FB\u52A8\u8BBE\u5907\u4E0A, \u6211\u4EEC\u5EFA\u8BAE\u60A8\u4ECE\u8BBE\u5907\u4E0A\u4F20\u7167\u7247\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_cover5 = "\u786E\u4FDD\u60A8\u6CA1\u6709\u906E\u76D6\u62A4\u7167\u6570\u636E(\u624B\u6307\u3001\u56DE\u5F62\u9488\u7B49)\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_crop5 = "\u5C06\u60A8\u7684\u62A4\u7167\u653E\u5728\u5E73\u5766\u7684\u8868\u9762\u4E0A, \u5E76\u786E\u4FDD\u6570\u636E\u53EF\u89C1\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions5 = "\u8BF7\u6309\u7167\u4EE5\u4E0B\u8BF4\u660E\u64CD\u4F5C, \u786E\u4FDD\u60A8\u7684\u7167\u7247\u7B26\u5408\u6211\u4EEC\u7684\u8981\u6C42\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_glare5 = "\u7167\u7247\u9700\u8981\u6E05\u6670, \u6CA1\u6709\u53CD\u5149, \u786E\u4FDD\u6240\u6709\u6570\u636E\u90FD\u662F\u53EF\u8BFB\u7684\u3002";
var APPB2C_cj_idDetails_upload_passport_guidelines_watermark5 = "\u786E\u4FDD\u5728\u76F8\u673A\u8BBE\u7F6E\u4E2D\u7981\u7528\u56FE\u50CF\u6C34\u5370\u3002";
var APPB2C_cj_idDetails_upload_title5 = "\u4E0A\u4F20$t\u8BC1\u660E({{idName}})";
var APPB2C_cj_ieltsReady_description_line15 = "\u8BBF\u95EE\u4E30\u5BCC\u7684\u96C5\u601D\u51C6\u5907\u5B66\u4E60\u6750\u6599, \u6DB5\u76D6\u8BED\u6CD5\u3001\u8BCD\u6C47\u3001\u9605\u8BFB\u3001\u542C\u529B\u7B49\u3002\u63D0\u9AD8\u60A8\u7684\u96C5\u601D\u8003\u8BD5\u6280\u80FD, \u65E0\u8BBA\u662F\u5355\u72EC\u5B66\u4E60\u8FD8\u662F\u5728\u4E13\u5BB6\u6307\u5BFC\u4E0B\u5B66\u4E60\u3002\u901A\u8FC7 IELTS Ready \u83B7\u5F97\u60A8\u60F3\u8981\u7684\u5206\u6570\u3002";
var APPB2C_cj_ieltsReady_description_line25 = "\u795D\u4F60\u597D\u8FD0\uFF01";
var APPB2C_cj_ieltsReady_dobMin5 = "\u60A8\u9700\u8981\u5E74\u6EE1 18 \u5C81\u624D\u80FD\u521B\u5EFA\u5E10\u6237\u3002\u60A8\u9700\u8981\u60A8\u7684\u7236\u6BCD\u6216\u76D1\u62A4\u4EBA\u624D\u80FD\u8FDB\u5165\u96C5\u601D\u5907\u8003\u4F1A\u5458\u4E13\u533A\u3002";
var APPB2C_cj_ieltsReady_nameWarning5 = "\u5F53\u60A8\u62A5\u540D\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u65F6, \u60A8\u7684\u59D3\u540D\u5FC5\u987B\u4E0E\u60A8\u7684\u5B98\u65B9\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6<bold>\u76F8\u7B26</bold>, \u8BF7\u786E\u4FDD\u73B0\u5728\u6B63\u786E\u65E0\u8BEF, \u4EE5\u8282\u7701\u4EE5\u540E\u7684\u65F6\u95F4\u3002";
var APPB2C_cj_ieltsReady_note5 = "\u60A8\u9700\u8981\u4E00\u4E2A\u5E10\u6237\u624D\u80FD\u8BBF\u95EE <0>IELTS Ready \u4F1A\u5458\u4E13\u533A.</0>";
var APPB2C_cj_ieltsReady_personalDetails_title4 = "\u5173\u4E8E\u4F60";
var APPB2C_cj_ieltsReady_submitBtnText5 = "\u521B\u5EFA\u5E10\u6237";
var APPB2C_cj_ieltsReady_targetScore_option5 = "\u6211\u8FD8\u4E0D\u77E5\u9053";
var APPB2C_cj_ieltsReady_targetScore_title5 = "\u96C5\u601D\u8003\u8BD5\u7684\u603B\u4F53\u76EE\u6807\u5206\u6570\u662F\u591A\u5C11\uFF1F";
var APPB2C_cj_ieltsReady_title5 = "\u521B\u5EFA\u4E00\u4E2A\u5E10\u6237";
var APPB2C_cj_incorrectOrganisation_bookNewLink5 = "\u5728\u6B64\u7F51\u7AD9\u4E0A\u9884\u8BA2\u8003\u8BD5(\u6211\u4EEC\u5C06\u5E2E\u52A9\u60A8\u627E\u5230\u8003\u8BD5\u4E2D\u5FC3)";
var APPB2C_cj_incorrectOrganisation_line15 = "\u770B\u8D77\u6765\u60A8\u6B63\u5728\u5C1D\u8BD5\u5411\u6211\u4EEC\u4E0D\u8BA4\u8BC6\u7684\u7EC4\u7EC7\u9884\u8BA2\u6D4B\u8BD5\u3002";
var APPB2C_cj_incorrectOrganisation_line25 = "\u4E0D\u7528\u62C5\u5FC3;\u60A8\u53EF\u4EE5\u5C1D\u8BD5\u4EE5\u4E0B\u51E0\u79CD\u65B9\u6CD5: ";
var APPB2C_cj_incorrectOrganisation_locationsLink5 = "\u5728\u96C5\u601D\u7F51\u7AD9\u4E0A\u67E5\u770B\u6211\u4EEC\u7684\u5168\u7403\u4F4D\u7F6E";
var APPB2C_cj_incorrectOrganisation_locationsLinkUsa5 = "\u5728\u96C5\u601D\u7F51\u7AD9\u4E0A\u67E5\u770B\u7F8E\u56FD\u7684\u6240\u6709\u8003\u8BD5\u4E2D\u5FC3";
var APPB2C_cj_incorrectOrganisation_title5 = "\u4E0D\u597D\u610F\u601D\uFF01";
var APPB2C_cj_marketingPrefs5 = "\u60A8\u7684\u8425\u9500\u504F\u597D";
var APPB2C_cj_onRequest_created_back5 = "< \u8FD4\u56DE\u6240\u6709 UKVI \u6D4B\u8BD5\u9009\u9879";
var APPB2C_cj_onRequest_created_in5 = "\u5728";
var APPB2C_cj_onRequest_created_info5 = "\u6211\u4EEC\u5C06\u5BA1\u6838\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F, \u6211\u4EEC\u5C06\u5728 48-72 \u5C0F\u65F6\u5185\u4E0E\u60A8\u8054\u7CFB\u4EE5\u8DDF\u8FDB\u6B64\u8BF7\u6C42\u3002";
var APPB2C_cj_onRequest_created_note5 = "\u6211\u4EEC\u5DF2\u7ECF\u6CE8\u518C\u4E86\u60A8\u53C2\u52A0\u7528\u4E8EUKVI\u7684\u96C5\u601D\u8003\u8BD5\u7684\u5174\u8DA3";
var APPB2C_cj_onRequest_created_title5 = "\u60A8\u7684\u8BF7\u6C42\u5DF2\u6536\u5230";
var APPB2C_cj_onRequest_form_labels_confirmEmail5 = "\u786E\u8BA4\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_cj_onRequest_form_labels_email5 = "\u60A8\u7684\u7535\u5B50\u90AE\u4EF6";
var APPB2C_cj_onRequest_form_labels_firstname5 = "\u540D\u5B57/\u540D\u5B57(\u5305\u62EC\u4E2D\u95F4\u540D)";
var APPB2C_cj_onRequest_form_labels_surname5 = "\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_onRequest_form_labels_telephone5 = "\u624B\u673A\u53F7\u7801";
var APPB2C_cj_onRequest_form_labels_testRangeQuestion5 = "\u60A8\u60F3\u591A\u4E45\u53C2\u52A0\u8003\u8BD5\uFF1F";
var APPB2C_cj_onRequest_form_submit5 = "\u6CE8\u518C\u6211\u7684\u5174\u8DA3";
var APPB2C_cj_onRequest_form_testRangeOpt_oneMonth5 = "\u5728\u63A5\u4E0B\u6765\u768428\u5929\u5185";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonths5 = "\u672A\u67653\u4E2A\u6708\u5185";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus5 = "\u57283\u4E2A\u591A\u6708\u5185";
var APPB2C_cj_onRequest_form_testRangeOpt_twoMonths5 = "\u672A\u67652\u4E2A\u6708\u5185";
var APPB2C_cj_onRequest_info15 = "\u586B\u5199\u8868\u683C\u4EE5\u6CE8\u518C\u60A8\u5BF9\u53C2\u52A0\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5\u7684\u5174\u8DA3\u3002\u6211\u4EEC\u5C06\u5BA1\u6838\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F, \u5E76\u4E0E\u60A8\u8054\u7CFB\u4EE5\u8DDF\u8FDB\u6B64\u8BF7\u6C42\u3002";
var APPB2C_cj_onRequest_note5 = "\u76EE\u524D\u6CA1\u6709\u9002\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5\u65E5\u671F\u3002";
var APPB2C_cj_onRequest_otherLocations_choose5 = "\u9009\u62E9\u5176\u4ED6\u4F4D\u7F6E";
var APPB2C_cj_onRequest_otherLocations_hide5 = "-\u9690\u85CF";
var APPB2C_cj_onRequest_otherLocations_info15 = "\u60A8\u53EF\u4EE5\u901A\u8FC7\u4E24\u79CD\u65B9\u5F0F\u5E2E\u52A9\u60A8\u9884\u8BA2\u8003\u8BD5\u65E5\u671F: ";
var APPB2C_cj_onRequest_otherLocations_info25 = "\u6211\u4EEC\u5728\u4EE5\u4E0B\u5730\u70B9\u6709\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_cj_onRequest_otherLocations_show5 = "+ \u663E\u793A\u66F4\u591A";
var APPB2C_cj_onRequest_please5 = "\u8BF7\u6CE8\u610F";
var APPB2C_cj_onRequest_prefNote5 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u5C06\u5C3D\u4E00\u5207\u52AA\u529B\u6EE1\u8DB3\u60A8\u7684\u9996\u9009\u3002\u5982\u679C\u60A8\u7684\u9996\u9009\u9009\u62E9\u4E0D\u53EF\u7528, \u5C06\u4E3A\u60A8\u63D0\u4F9B\u66FF\u4EE3\u9009\u9879\u3002";
var APPB2C_cj_onRequest_subtitles_aboutPreferences5 = "\u5173\u4E8E\u60A8\u7684\u6D4B\u8BD5\u9996\u9009\u9879";
var APPB2C_cj_onRequest_subtitles_aboutYou5 = "\u5173\u4E8E\u4F60";
var APPB2C_cj_onRequest_subtitles_markpref5 = "\u60A8\u7684\u8425\u9500\u504F\u597D";
var APPB2C_cj_onRequest_subtitles_register5 = "\u6CE8\u518C\u60A8\u5BF9\u53C2\u52A0\u8003\u8BD5\u7684\u5174\u8DA3";
var APPB2C_cj_personalDetails_error_doubleBooking5 = "\u8003\u751F\u5DF2\u4E8E\u540C\u65E5\u62A5\u540D\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_cj_personalDetails_error_missingAddress5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u90AE\u5BC4\u5730\u5740\u3002";
var APPB2C_cj_personalDetails_error_missingEmail5 = "\u8BF7\u8F93\u5165\u6709\u6548\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u3002";
var APPB2C_cj_personalDetails_error_missingEmailConfirm5 = "\u8BF7\u91CD\u65B0\u8F93\u5165\u60A8\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u4EE5\u786E\u8BA4\u8BE5\u5730\u5740\u3002";
var APPB2C_cj_personalDetails_error_missingFirstName5 = "\u8BF7\u8F93\u5165\u60A8\u7684\u540D\u5B57/\u540D\u5B57\u3002";
var APPB2C_cj_personalDetails_error_missingMobile5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u624B\u673A\u53F7\u7801\u3002";
var APPB2C_cj_personalDetails_error_missingSex5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u6027\u522B\u3002";
var APPB2C_cj_personalDetails_error_missingState5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u72B6\u6001\u3002";
var APPB2C_cj_personalDetails_error_missingSurname5 = '\u8BF7\u8F93\u5165\u60A8\u7684\u59D3\u6C0F/\u59D3\u6C0F\u3002\u5982\u679C\u60A8\u6CA1\u6709\u59D3\u6C0F, \u8BF7\u5728\u6B64\u5B57\u6BB5\u4E2D\u8F93\u5165\u8FDE\u5B57\u7B26("-")\u3002';
var APPB2C_cj_personalDetails_error_missingTown5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u57CE\u9547/\u57CE\u5E02\u3002";
var APPB2C_cj_personalDetails_error_missingZip5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u90AE\u653F\u7F16\u7801/\u90AE\u653F\u7F16\u7801\u3002";
var APPB2C_cj_personalDetails_error_wrongDob5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u5B9E\u9645\u51FA\u751F\u65E5\u671F\u3002";
var APPB2C_cj_personalDetails_error_wrongEmailConfirm5 = "\u91CD\u65B0\u8F93\u5165\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u4E0E\u60A8\u63D0\u4F9B\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u4E0D\u5339\u914D\u3002\u8BF7\u4ED4\u7EC6\u68C0\u67E5\u60A8\u7684\u62FC\u5199\u3002";
var APPB2C_cj_personalDetails_examTakenModal_cta5 = "\u67E5\u627E\u65B0\u6D4B\u8BD5";
var APPB2C_cj_personalDetails_examTakenModal_message5 = "\u4E0D\u8FC7\u522B\u62C5\u5FC3, \u6211\u4EEC\u8FD8\u6709\u5F88\u591A\u5176\u4ED6\u6D4B\u8BD5\u4F9B\u60A8\u9009\u62E9, \u6211\u4EEC\u4E0D\u4F1A\u5FD8\u8BB0\u60A8\u521A\u521A\u544A\u8BC9\u6211\u4EEC\u7684\u4EFB\u4F55\u7EC6\u8282\u3002";
var APPB2C_cj_personalDetails_examTakenModal_title5 = "\u5F88\u62B1\u6B49, \u60A8\u9009\u62E9\u7684\u6D4B\u8BD5\u4E0D\u518D\u53EF\u7528";
var APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse5 = "\u4E3A\u4ED6\u4EBA\u9884\u7EA6\u6D4B\u8BD5";
var APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate5 = "\u9009\u62E9\u5176\u4ED6\u8003\u8BD5\u65E5\u671F";
var APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle5 = "\u4E0D\u8981\u5FD8\u8BB0\u60A8\u5DF2\u7ECF\u9884\u8BA2\u4E86\u6D4B\u8BD5";
var APPB2C_cj_personalDetails_existingRegistrationsModal_title5 = "\u60A8\u5DF2\u7ECF\u5728\u6B64\u65E5\u671F\u9884\u8BA2\u4E86\u6D4B\u8BD5\uFF01";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking5 = "\u60A8\u53EF\u4EE5\u5728\u5E94\u8BD5\u8005\u95E8\u6237\u4E2D\u67E5\u770B\u60A8\u5F53\u524D\u7684\u9884\u8BA2\u3002";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking5 = "\u67E5\u770B\u6211\u5F53\u524D\u7684\u9884\u8BA2";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest5 = "\u60A8\u7684\u6D4B\u8BD5";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest5 = "\u60A8\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest5 = "\u60A8\u7684\u7B14\u8BD5";
var APPB2C_cj_personalDetails_forms_address_country5 = "\u5C45\u4F4F\u56FD\u5BB6/\u5730\u533A";
var APPB2C_cj_personalDetails_forms_address_enterManually5 = "\u624B\u52A8\u8F93\u5165\u5730\u5740";
var APPB2C_cj_personalDetails_forms_address_postal5 = "\u90AE\u653F\u5730\u5740";
var APPB2C_cj_personalDetails_forms_address_state5 = "\u5DDE";
var APPB2C_cj_personalDetails_forms_address_town5 = "\u57CE\u9547 / \u57CE\u5E02";
var APPB2C_cj_personalDetails_forms_address_zip5 = "\u90AE\u653F\u7F16\u7801 / \u90AE\u7F16";
var APPB2C_cj_personalDetails_forms_contact_confirmEmail5 = "\u786E\u8BA4\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_cj_personalDetails_forms_contact_email5 = "\u60A8\u7684\u7535\u5B50\u90AE\u4EF6";
var APPB2C_cj_personalDetails_forms_contact_emailExist5 = "\u6B64\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u5DF2\u88AB\u4F7F\u7528\u3002\u5982\u679C\u662F\u60A8, \u8BF7\u5C1D\u8BD5\u767B\u5F55\u3002";
var APPB2C_cj_personalDetails_forms_contact_emailInUse5 = "\u6B64\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u5DF2\u88AB\u4F7F\u7528\u3002\u5982\u679C\u662F\u4F60, \u4F60\u60F3<0>\u767B\u5F55</0>\u5417\uFF1F";
var APPB2C_cj_personalDetails_forms_contact_mobile5 = "\u624B\u673A\u53F7\u7801";
var APPB2C_cj_personalDetails_forms_contact_note5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u7535\u8BDD\u53F7\u7801\u548C\u90AE\u653F\u5730\u5740, \u4EE5\u9632\u6211\u4EEC\u9700\u8981\u4E0E\u60A8\u8054\u7CFB\u6216\u5411\u60A8\u53D1\u9001\u4EFB\u4F55\u6587\u4EF6(\u4F8B\u5982\u60A8\u7684\u6D4B\u8BD5\u62A5\u544A\u8868)\u3002";
var APPB2C_cj_personalDetails_forms_contact_password5 = "\u5BC6\u7801";
var APPB2C_cj_personalDetails_forms_contact_smsConsent5 = "\u6211\u540C\u610F\u901A\u8FC7\u77ED\u4FE1\u3001WhatsApp \u7B49\u63A5\u6536\u901A\u77E5\u6216\u8054\u7CFB\u5230\u6B64\u7535\u8BDD\u53F7\u7801\u7684\u8003\u8BD5\u6CE8\u518C\u3002";
var APPB2C_cj_personalDetails_forms_contact_smsNote5 = "<bold>\u8BF7\u6CE8\u610F: </bold>\u60A8\u6240\u5728\u7684\u4F4D\u7F6E\u53EF\u80FD\u4E0D\u63D0\u4F9B\u6B64\u670D\u52A1\u3002";
var APPB2C_cj_personalDetails_forms_contact_title5 = "\u60A8\u7684\u8054\u7CFB\u65B9\u5F0F";
var APPB2C_cj_personalDetails_forms_personal_change5 = "\u6539\u53D8";
var APPB2C_cj_personalDetails_forms_personal_dob5 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_cj_personalDetails_forms_personal_dobCta5 = "\u5728\u8003\u8BD5\u4E2D\u5FC3\u9884\u8BA2\u96C5\u601D\u8003\u8BD5";
var APPB2C_cj_personalDetails_forms_personal_firstMiddleName5 = "\u540D\u5B57/\u540D\u5B57(\u5305\u62EC\u4E2D\u95F4\u540D)";
var APPB2C_cj_personalDetails_forms_personal_firstName5 = "\u540D\u5B57/\u540D\u5B57";
var APPB2C_cj_personalDetails_forms_personal_idMatch5 = "\u8FD9\u5FC5\u987B\u4E0E\u60A8\u8EAB\u4EFD\u8BC1\u4EF6\u4E0A\u7684\u59D3\u540D\u4E00\u81F4";
var APPB2C_cj_personalDetails_forms_personal_lastName5 = "\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_personalDetails_forms_personal_name5 = "\u540D\u5B57";
var APPB2C_cj_personalDetails_forms_personal_noLastName5 = "\u6211\u6CA1\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_cj_personalDetails_forms_personal_sectionTitle5 = "\u5173\u4E8E\u4F60";
var APPB2C_cj_personalDetails_forWho_child5 = "\u6211\u7684\u5B69\u5B50";
var APPB2C_cj_personalDetails_forWho_childUnder185 = "(18\u5C81\u4EE5\u4E0B)";
var APPB2C_cj_personalDetails_forWho_dob5 = "\u51FA\u751F\u65E5\u671F: ";
var APPB2C_cj_personalDetails_forWho_myself5 = "\u672C\u4EBA";
var APPB2C_cj_personalDetails_forWho_note5 = "\u60A8\u4E0D\u80FD\u4E3A 11 \u5C81\u4EE5\u4E0B\u7684\u513F\u7AE5\u9884\u8BA2\u8003\u8BD5\u3002\u5982\u679C\u60A8\u7684\u5B69\u5B50\u5E74\u6EE1 18 \u5C81, \u8BF7\u8BA9\u4ED6\u4EEC\u81EA\u884C\u6CE8\u518C\u3002";
var APPB2C_cj_personalDetails_forWho_title5 = "\u60A8\u4E3A\u8C01\u9884\u8BA2\u8003\u8BD5\uFF1F";
var APPB2C_cj_personalDetails_gender5 = "\u6027: ";
var APPB2C_cj_personalDetails_idNote_idMatch5 = "\u6B64\u9875\u9762\u4E0A\u63D0\u4F9B\u7684\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u5FC5\u987B\u4E0E\u7528\u4E8E\u6CE8\u518C\u5E76\u5728\u8003\u8BD5\u5730\u70B9\u51FA\u793A\u7684\u5E94\u8BD5\u8005\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u5C06\u663E\u793A\u5728\u5E94\u8BD5\u8005\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u5982\u679C\u60A8\u5728\u6211\u4EEC\u7B7E\u53D1\u6210\u7EE9\u5355\u4E4B\u524D\u8981\u6C42\u6211\u4EEC\u4FEE\u6539\u4EFB\u4F55\u6B64\u7C7B\u4FE1\u606F, \u60A8\u53EF\u80FD\u4F1A\u88AB\u6536\u53D6\u8D39\u7528\u3002\u8BF7\u6CE8\u610F, \u6210\u7EE9\u5355\u53D1\u51FA\u540E\u4E0D\u5F97\u8FDB\u884C\u4EFB\u4F55\u4FEE\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_change5 = "\u5168\u540D\u548C\u51FA\u751F\u65E5\u671F<bold>\u5FC5\u987B\u4E0E\u60A8</bold>\u5728\u8003\u8BD5\u5F53\u5929\u51FA\u793A\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_france4 = "\u8BF7\u786E\u4FDD\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u4E0E\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u6587\u4EF6\u5C06\u663E\u793A\u5728\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u66F4\u6539\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u7684\u8D39\u7528\u3002\u8BF7\u6CE8\u610F, \u7ED3\u679C\u53D1\u5E03\u540E, \u65E0\u6CD5\u66F4\u6539\u4EFB\u4F55\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_france_adult4 = "\u8BF7\u786E\u4FDD\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u4E0E\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u6587\u4EF6\u5C06\u663E\u793A\u5728\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u66F4\u6539\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u7684\u8D39\u7528\u3002\u8BF7\u6CE8\u610F, \u7ED3\u679C\u53D1\u5E03\u540E, \u65E0\u6CD5\u66F4\u6539\u4EFB\u4F55\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_france_minor4 = "\u8BF7\u786E\u4FDD\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u4E0E\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u672A\u6210\u5E74\u4EBA\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u6587\u4EF6\u5C06\u663E\u793A\u5728\u5176\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u66F4\u6539\u5176\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u7684\u8D39\u7528\u3002\u8BF7\u6CE8\u610F, \u7ED3\u679C\u53D1\u5E03\u540E, \u65E0\u6CD5\u66F4\u6539\u4EFB\u4F55\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_spain4 = "\u8BF7\u786E\u4FDD\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u4E0E\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u6587\u4EF6\u5C06\u663E\u793A\u5728\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u5C06\u65E0\u6CD5\u5BF9\u60A8\u7684\u4E2A\u4EBA\u4FE1\u606F\u8FDB\u884C\u4EFB\u4F55\u66F4\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_adult4 = "\u8BF7\u786E\u4FDD\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u4E0E\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u6587\u4EF6\u5C06\u663E\u793A\u5728\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u5C06\u65E0\u6CD5\u5BF9\u60A8\u7684\u4E2A\u4EBA\u4FE1\u606F\u8FDB\u884C\u4EFB\u4F55\u66F4\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_minor4 = "\u8BF7\u786E\u4FDD\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u4E0E\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u672A\u6210\u5E74\u4EBA\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u6587\u4EF6\u5C06\u663E\u793A\u5728\u5176\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u5C06\u65E0\u6CD5\u5BF9\u5176\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u8FDB\u884C\u4EFB\u4F55\u66F4\u6539\u3002";
var APPB2C_cj_personalDetails_idNote_whenForChild5 = "\u6211\u4EEC\u5C06\u5728\u4E0B\u4E00\u9875\u8BE2\u95EE\u60A8\u6709\u5173\u60A8\u5B69\u5B50\u7684\u4FE1\u606F";
var APPB2C_cj_personalDetails_login_btn5 = "\u767B\u5F55";
var APPB2C_cj_personalDetails_login_emailNotEditable5 = "\u521B\u5EFA\u5E10\u6237\u540E, \u60A8\u5C06\u65E0\u6CD5\u7F16\u8F91\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u3002\u8981\u66F4\u6539\u6B64\u8BBE\u7F6E, \u8BF7<1>\u8054\u7CFB\u6211\u4EEC</1>";
var APPB2C_cj_personalDetails_login_link5 = "\u5DF2\u7ECF\u6709\u8D26\u6237\u4E86\uFF1F<1>\u767B\u5F55</1>";
var APPB2C_cj_personalDetails_login_loggedIn5 = "\u4F60\u597D\uFF01\u60A8\u73B0\u5728\u5DF2\u767B\u5F55\u3002";
var APPB2C_cj_personalDetails_login_logout5 = "\u4E0D\u662F\u4F60\u7684\u8BE6\u7EC6\u4FE1\u606F\uFF1F<1>\u6CE8\u9500</1>";
var APPB2C_cj_personalDetails_login_note5 = "\u60A8\u9700\u8981\u4E00\u4E2A\u5E10\u6237\u6765\u7BA1\u7406\u60A8\u7684\u9884\u8BA2\u3002\u9884\u8BA2\u6D4B\u8BD5\u540E, \u60A8\u5C06\u6536\u5230\u4E00\u5C01\u7535\u5B50\u90AE\u4EF6, \u8BF4\u660E\u5982\u4F55\u5728\u7EBF\u8BBF\u95EE\u60A8\u7684\u9884\u8BA2\u3002";
var APPB2C_cj_personalDetails_login_title5 = "\u521B\u5EFA\u4E00\u4E2A\u5E10\u6237";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate5 = "\u67E5\u627E\u65B0\u6D4B\u8BD5";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line15 = "\u60A8\u4E0D\u80FD\u5728 {{nearestRegistratonExamDate}} \u4E0A\u9884\u8BA2\u4E0E\u5176\u4ED6\u8003\u8BD5\u5982\u6B64\u63A5\u8FD1\u7684\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u3002";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line25 = "\u8BF7\u627E\u5230\u6BD4\u4E0A\u4E00\u6B21\u8003\u8BD5\u65E9\u6216\u665A 5 \u5929\u6216\u66F4\u665A\u7684\u8003\u8BD5\u65E5\u671F\u3002";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title5 = "\u8BF7\u9009\u62E9\u5176\u4ED6\u8003\u8BD5\u65E5\u671F";
var APPB2C_cj_personalDetails_title5 = "\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_cj_personalDetails_tncs_agreement5 = "\u6211\u540C\u610F\u5E10\u6237\u6CE8\u518C<1>\u6761\u6B3E\u548C\u6761\u4EF6</1>";
var APPB2C_cj_personalDetails_tncs_title5 = "\u6211\u4EEC\u7684\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_cj_personalDetails_visaType_label5 = "\u60A8\u9700\u8981\u4EC0\u4E48\u7C7B\u578B\u7684\u7B7E\u8BC1\uFF1F";
var APPB2C_cj_personalDetails_visaType_label_child5 = "\u4ED6\u4EEC\u9700\u8981\u4EC0\u4E48\u7B7E\u8BC1\u7C7B\u578B\uFF1F";
var APPB2C_cj_personalDetails_visaType_link5 = "\u4E86\u89E3\u6709\u5173\u7B7E\u8BC1\u7C7B\u578B\u7684\u66F4\u591A\u4FE1\u606F";
var APPB2C_cj_personalDetails_visaType_title5 = "\u60A8\u7684\u7B7E\u8BC1\u7C7B\u578B";
var APPB2C_cj_personalDetails_visaType_title_child5 = "\u4ED6\u4EEC\u7684\u7B7E\u8BC1\u7C7B\u578B";
var APPB2C_cj_rebook_errorModal_message5 = "\u770B\u8D77\u6765\u51FA\u4E86\u4EC0\u4E48\u95EE\u9898\u3002\u8BF7\u5728\u51E0\u5206\u949F\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_rebook_errorModal_title5 = "\u9884\u8BA2\u5931\u8D25";
var APPB2C_cj_review_acknowledgement_anotherPerson5 = "\u53E6\u4E00\u4E2A\u4EBA";
var APPB2C_cj_review_acknowledgement_company5 = "\u4E00\u5BB6\u516C\u53F8";
var APPB2C_cj_review_acknowledgement_fields_companyName5 = "\u516C\u53F8\u540D\u79F0";
var APPB2C_cj_review_acknowledgement_fields_companyName_subnote5 = "\u4F7F\u7528\u516C\u53F8\u540D\u79F0\u7684\u539F\u59CB\u62FC\u5199";
var APPB2C_cj_review_acknowledgement_fields_country5 = "\u56FD\u5BB6/\u5730\u533A";
var APPB2C_cj_review_acknowledgement_fields_email5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_cj_review_acknowledgement_fields_familyName5 = "\u59D3";
var APPB2C_cj_review_acknowledgement_fields_firstName5 = "\u5176\u4ED6/\u540D\u5B57";
var APPB2C_cj_review_acknowledgement_fields_mobile5 = "\u7535\u8BDD\u53F7\u7801";
var APPB2C_cj_review_acknowledgement_myself5 = "\u672C\u4EBA";
var APPB2C_cj_review_acknowledgement_send5 = "\u8C01\u5E94\u8BE5\u6536\u5230\u6D4B\u8BD5\u9884\u8BA2\u7684\u8BA2\u5355\u786E\u8BA4\uFF1F";
var APPB2C_cj_review_acknowledgement_title5 = "\u8BA2\u5355\u786E\u8BA4";
var APPB2C_cj_review_agent_label5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u4EE3\u7406\u540D\u79F0";
var APPB2C_cj_review_agent_note5 = "\u60A8\u662F\u5426\u6709\u4EE3\u8868\u60A8\u5904\u7406 UKVI \u7533\u8BF7\u7684\u4EE3\u7406\u4EBA\uFF1F";
var APPB2C_cj_review_agent_title5 = "\u4EE3\u7406\u540D\u79F0";
var APPB2C_cj_review_ai_note5 = "\u60A8\u662F\u5426\u613F\u610F\u4F7F\u7528\u533F\u540D\u8003\u8BD5\u7B54\u6848\u548C\u8003\u8BD5\u8BB0\u5F55\u6765\u57F9\u8BAD\u8FDC\u7A0B\u76D1\u8003\u548C\u8003\u8BD5\u8BC4\u5206\u8F6F\u4EF6\uFF1F";
var APPB2C_cj_review_ai_title5 = "\u57F9\u8BAD\u540C\u610F\u4E66";
var APPB2C_cj_review_bookingDetails_lrw_title5 = "\u60A8\u7684\u7B14\u8BD5";
var APPB2C_cj_review_bookingDetails_lrw_title_child5 = "\u60A8\u5B69\u5B50\u7684\u7B14\u8BD5";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills5 = "\u60A8\u7684\u6D4B\u8BD5";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child5 = "\u4F60\u7684\u5B69\u5B50\u7684\u6D4B\u8BD5";
var APPB2C_cj_review_bookingDetails_speaking_title5 = "\u60A8\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_cj_review_bookingDetails_speaking_title_child5 = "\u60A8\u5B69\u5B50\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_cj_review_bookingDetails_title5 = "\u60A8\u6B63\u5728\u9884\u8BA2";
var APPB2C_cj_review_bookNowNoFee_buttonText5 = "\u7ACB\u5373\u9884\u8BA2";
var APPB2C_cj_review_bookTest5 = "\u4E66\u672C\u6D4B\u8BD5";
var APPB2C_cj_review_change5 = "\u6539\u53D8";
var APPB2C_cj_review_changeLrwTest5 = "\u66F4\u6539\u7B14\u8BD5\u65E5\u671F";
var APPB2C_cj_review_changeSpeakingTest5 = "\u66F4\u6539\u6F14\u8BB2\u65F6\u95F4\u8868";
var APPB2C_cj_review_changeUkviLsTest5 = "\u66F4\u6539\u8003\u8BD5\u65E5\u671F";
var APPB2C_cj_review_childDetails_guardiansTitle5 = "\u5C06\u63A5\u60A8\u5B69\u5B50\u7684\u4EBA";
var APPB2C_cj_review_childDetails_onTestDay5 = "\u8003\u8BD5\u5F53\u5929";
var APPB2C_cj_review_childDetails_onTestDayOptions_15 = "\u513F\u7AE5\u53EF\u4EE5\u81EA\u884C\u79BB\u5F00\u8003\u573A";
var APPB2C_cj_review_childDetails_onTestDayOptions_25 = "\u6211\u4F1A\u4ECE\u8003\u8BD5\u573A\u6240\u63A5\u6211\u7684\u5B69\u5B50";
var APPB2C_cj_review_childDetails_onTestDayOptions_35 = "\u5176\u4ED6\u4EBA\u4F1A\u4ECE\u8003\u8BD5\u573A\u6240\u63A5\u6211\u7684\u5B69\u5B50";
var APPB2C_cj_review_childDetails_title5 = "\u60A8\u5B69\u5B50\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_cj_review_confirmPaymentModal_btnNote5 = "\u60A8\u53EF\u80FD\u9700\u8981\u652F\u4ED8\u6BD4\u5217\u51FA\u7684 <0></0> \u66F4\u591A\u7684\u8D39\u7528";
var APPB2C_cj_review_confirmPaymentModal_note5 = "\u8BF7\u6CE8\u610F: \u60A8\u7684\u94F6\u884C\u53EF\u80FD\u4F1A\u5411\u60A8\u6536\u53D6\u989D\u5916\u8D39\u7528, \u7528\u4E8E\u60A8\u7684\u96C5\u601D\u8003\u8BD5\u5728\u7EBF\u4ED8\u6B3E\u3002";
var APPB2C_cj_review_confirmPaymentModal_optionsTitle5 = "\u60A8\u6709\u4E24\u79CD\u9009\u62E9\u3002";
var APPB2C_cj_review_confirmPaymentModal_title5 = "\u5728\u7EBF\u652F\u4ED8\u53EF\u80FD\u4F1A\u82B1\u8D39\u66F4\u591A";
var APPB2C_cj_review_countryMsg_pakistan5 = "\u7531\u4E8E\u6C47\u7387\u6CE2\u52A8, \u4F7F\u7528\u4FE1\u7528\u5361\u652F\u4ED8\u7684\u96C5\u601D\u8D39\u7528\u53EF\u80FD\u4E0E\u4E0A\u8FF0\u4EF7\u683C\u4E0D\u540C\u3002\u5728\u7EBF\u6216\u56FD\u9645\u5546\u5BB6\u4EE5\u5DF4\u57FA\u65AF\u5766\u5362\u6BD4\u8FDB\u884C\u7684\u4FE1\u7528\u5361\u4EA4\u6613\u7531\u5546\u5BB6\u4EE5\u5916\u5E01\u7ED3\u7B97\u3002";
var APPB2C_cj_review_error_missingOrderAcknowledgement5 = "\u8BF7\u6307\u5B9A\u8C01\u5E94\u8BE5\u6536\u5230\u6D4B\u8BD5\u9884\u8BA2\u7684\u8BA2\u5355\u786E\u8BA4\u3002";
var APPB2C_cj_review_error_missingPickups5 = "\u8BF7\u6CE8\u660E\u8C01\u5C06\u4ECE\u8003\u8BD5\u573A\u6240\u63A5\u60A8\u7684\u5B69\u5B50\u3002";
var APPB2C_cj_review_error_missingTown5 = "\u8BF7\u66F4\u6539\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F, \u5E76\u5728\u60A8\u7684\u5730\u5740\u4E2D\u5305\u542B\u57CE\u9547/\u57CE\u5E02\u3002";
var APPB2C_cj_review_error_wrongCountrySetting5 = "\u60A8\u6240\u5728\u7684\u56FD\u5BB6/\u5730\u533A\u65E0\u6CD5\u9884\u8BA2\u6B64\u6D4B\u8BD5\u3002\u8BF7\u9009\u62E9\u5176\u4ED6\u6D4B\u8BD5\u3002";
var APPB2C_cj_review_error_wrongPaymentConfig5 = "\u4ED8\u6B3E\u914D\u7F6E\u7F3A\u5931\u6216\u4E0D\u6B63\u786E, \u5982\u679C\u95EE\u9898\u4ECD\u7136\u5B58\u5728, \u8BF7\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3\u3002";
var APPB2C_cj_review_error_wrongTaxNumber5 = "\u8BF7\u63D0\u4F9B\u6709\u6548\u7684\u589E\u503C\u7A0E/\u56FD\u5BB6\u5DE5\u4E1A\u8BB8\u53EF\u8BC1\u53F7\u3002\u5B83\u5FC5\u987B\u5339\u914D\u7279\u5B9A\u683C\u5F0F(\u4F8B\u5982 13243546-3-35\u30011324354635)";
var APPB2C_cj_review_expiredIdError5 = "\u5E94\u8BD5\u8005\u7684 ID \u4E0D\u5F97\u5728\u6240\u9009\u8003\u8BD5\u65E5\u671F\u4E4B\u524D\u8FC7\u671F\u3002";
var APPB2C_cj_review_idDetails_issuing5 = "\u53D1\u8BC1\u673A\u5173";
var APPB2C_cj_review_idDetails_nationality5 = "\u56FD\u7C4D\u56FD\u5BB6/\u5730\u533A";
var APPB2C_cj_review_idDetails_title5 = "\u60A8\u7684\u8EAB\u4EFD\u8BC1\u660E";
var APPB2C_cj_review_idDetails_title_child5 = "\u60A8\u5B69\u5B50\u7684\u8EAB\u4EFD\u8BC1\u660E";
var APPB2C_cj_review_invoice_companyName5 = "\u53D1\u7968\u62AC\u5934";
var APPB2C_cj_review_invoice_consent5 = "\u60A8\u662F\u5426\u540C\u610F\u82F1\u56FD\u6587\u5316\u534F\u4F1A(\u53F0\u6E7E)\u6709\u9650\u516C\u53F8\u4EE3\u8868\u60A8\u5904\u7406\u53D1\u7968\u548C\u4EA7\u54C1\u9000\u8D27\u8BC1\u660E, \u4EE5\u52A0\u5FEB\u9000\u6B3E\u6D41\u7A0B\uFF1F";
var APPB2C_cj_review_invoice_send5 = "\u60A8\u5E0C\u671B\u6211\u4EEC\u5F00\u5177\u4EC0\u4E48\u7C7B\u578B\u7684\u53D1\u7968\uFF1F";
var APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate5 = "\u516C\u6C11\u6570\u5B57\u8BC1\u4E66";
var APPB2C_cj_review_invoice_subtype_label5 = "\u60A8\u60F3\u4F7F\u7528\u54EA\u4E2A\u8FD0\u8425\u5546\uFF1F";
var APPB2C_cj_review_invoice_subtype_mobileBarcode5 = "\u624B\u673A\u6761\u7801";
var APPB2C_cj_review_invoice_taxCaption_donate5 = "\u6148\u5584\u673A\u6784\u7F16\u53F7";
var APPB2C_cj_review_invoice_taxCaption_duplicatePaper5 = "\u53D1\u7968\u7F16\u53F7";
var APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate5 = "\u516C\u6C11\u6570\u5B57\u8BC1\u4E66\u5FC5\u987B\u5339\u914D 16 \u4E2A\u5B57\u6BCD\u6570\u5B57\u5B57\u7B26\u7684\u6B63\u786E\u683C\u5F0F(\u4F8B\u5982 1122AABB3344CCDD)";
var APPB2C_cj_review_invoice_taxValidation_donate5 = "\u6148\u5584\u673A\u6784\u7F16\u53F7\u5FC5\u987B\u5339\u914D 3-7 \u4F4D\u6570\u5B57\u7684\u7279\u5B9A\u683C\u5F0F(\u4F8B\u5982 123)";
var APPB2C_cj_review_invoice_taxValidation_mobileBarcode5 = "\u79FB\u52A8\u6761\u7801\u5FC5\u987B\u5339\u914D\u6B63\u786E\u7684\u683C\u5F0F(\u4F8B\u5982/123-ABC)";
var APPB2C_cj_review_invoice_taxValidation_triplicate5 = "\u53D1\u7968\u53F7\u7801\u5FC5\u987B\u5339\u914D 8 \u4F4D\u7279\u5B9A\u683C\u5F0F(\u4F8B\u5982 11223344)";
var APPB2C_cj_review_invoice_title5 = "\u53D1\u7968\u4FE1\u606F";
var APPB2C_cj_review_invoice_type_donate5 = "\u6350\u8D60\u7535\u5B50\u53D1\u7968";
var APPB2C_cj_review_invoice_type_duplicateElectronic5 = "\u7EDF\u4E00\u7535\u5B50\u53D1\u7968\u590D\u5370\u4EF6";
var APPB2C_cj_review_invoice_type_duplicatePaper5 = "\u7EDF\u4E00\u7EB8\u8D28\u53D1\u7968\u526F\u672C";
var APPB2C_cj_review_invoice_type_triplicate5 = "\u4E00\u5F0F\u4E09\u4EFD\u7EDF\u4E00\u7EB8\u8D28\u53D1\u7968";
var APPB2C_cj_review_localization_localTime5 = "\u60A8\u7684\u5F53\u5730\u65F6\u95F4";
var APPB2C_cj_review_localization_location5 = "\u6211\u4EEC\u5C06\u60A8\u672C\u5730\u5316\u4E3A";
var APPB2C_cj_review_localization_timezone5 = "\u60A8\u7684\u65F6\u533A";
var APPB2C_cj_review_missingMarketingCta5 = "\u68C0\u67E5\u60A8\u7684\u7B54\u6848";
var APPB2C_cj_review_missingMarketingError5 = "\u770B\u8D77\u6765\u60A8\u4E4B\u524D\u5DF2\u7ECF\u5C1D\u8BD5\u8FC7\u4E0E\u6211\u4EEC\u9884\u8BA2\u6D4B\u8BD5, \u73B0\u5728\u6211\u4EEC\u53EA\u9700\u8981\u518D\u6B21\u4E0E\u60A8\u6838\u5BF9\u4E00\u4E9B\u7B54\u6848\u3002";
var APPB2C_cj_review_note_content5 = "\u8BF7\u5728\u9884\u8BA2\u524D\u4ED4\u7EC6\u68C0\u67E5\u6240\u6709\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_cj_review_note_title5 = "\u60A8\u8FD8\u6CA1\u6709\u9884\u8BA2\uFF01";
var APPB2C_cj_review_or5 = "\u6216";
var APPB2C_cj_review_payLater_buttonText5 = "\u7EBF\u4E0B\u652F\u4ED8";
var APPB2C_cj_review_payLater_sideNote5 = "\u4ED8\u6B3E\u65B9\u5F0F\u5C06\u663E\u793A\u5728\u4E0B\u4E00\u9875";
var APPB2C_cj_review_payment_fee5 = "\u8D39";
var APPB2C_cj_review_payment_promoCodes_applied5 = "\u5DF2\u5E94\u7528\u4FC3\u9500\u4EE3\u7801";
var APPB2C_cj_review_payment_promoCodes_applyCode5 = "\u5E94\u7528\u4EE3\u7801";
var APPB2C_cj_review_payment_promoCodes_discount5 = "\u664B\u5347";
var APPB2C_cj_review_payment_promoCodes_generalError5 = "\u53D1\u751F\u9519\u8BEF - \u8BF7\u91CD\u8BD5\u3002";
var APPB2C_cj_review_payment_promoCodes_haveCode5 = "\u6211\u6709\u4E00\u4E2A\u4FC3\u9500\u4EE3\u7801";
var APPB2C_cj_review_payment_promoCodes_invalidCode5 = "\u60A8\u8F93\u5165\u7684\u4FC3\u9500\u4EE3\u7801\u65E0\u6548";
var APPB2C_cj_review_payment_promoCodes_newFee5 = "\u65B0\u8D39\u7528";
var APPB2C_cj_review_payment_promoCodes_notHaveCode5 = "\u6211\u6CA1\u6709\u4FC3\u9500\u4EE3\u7801";
var APPB2C_cj_review_payment_promoCodes_promo5 = "\u4FC3\u9500";
var APPB2C_cj_review_payment_promoCodes_promoCode5 = "\u4FC3\u9500\u4EE3\u7801";
var APPB2C_cj_review_payment_promoCodes_remove5 = "\u5220\u9664";
var APPB2C_cj_review_payment_promoCodes_removeCode5 = "\u5220\u9664\u4FC3\u9500\u4EE3\u7801";
var APPB2C_cj_review_payment_promoCodes_total5 = "\u603B";
var APPB2C_cj_review_payment_tax5 = "\u7A0E";
var APPB2C_cj_review_payment_title5 = "\u4ED8\u6B3E";
var APPB2C_cj_review_payment_total5 = "\u603B";
var APPB2C_cj_review_paymentError5 = "\u6211\u4EEC\u65E0\u6CD5\u5904\u7406\u60A8\u7684\u4ED8\u6B3E\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_review_paymentNote5 = "\u9884\u8BA2\u548C\u4ED8\u6B3E: ";
var APPB2C_cj_review_paymentNoteMultiple4 = "\u60A8\u73B0\u5728\u53EF\u4EE5\u9884\u8BA2\u8003\u8BD5\u4E86: ";
var APPB2C_cj_review_paymentNoteSingle4 = "\u4E00\u65E6\u60A8\u9009\u62E9\u4E86\u4EE5\u4E0B\u9009\u9879, \u6211\u4EEC\u5C06\u4E3A\u60A8\u9884\u8BA2\u6D4B\u8BD5\u3002";
var APPB2C_cj_review_paymentNoteZeroFee5 = "\u83B7\u53D6\u514D\u8D39\u6D4B\u8BD5";
var APPB2C_cj_review_payOnline_buttonText5 = "\u5728\u7EBF\u652F\u4ED8";
var APPB2C_cj_review_payOnline_sideNote5 = "\u63A8\u8350\u7684\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_cj_review_personalDetails_address5 = "\u5730\u5740";
var APPB2C_cj_review_personalDetails_dob5 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_cj_review_personalDetails_email5 = "\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_cj_review_personalDetails_gender5 = "\u6027";
var APPB2C_cj_review_personalDetails_name5 = "\u540D\u5B57";
var APPB2C_cj_review_personalDetails_phone5 = "\u624B\u673A\u53F7\u7801";
var APPB2C_cj_review_personalDetails_smsNotificationsConsent5 = "\u6211\u60F3\u901A\u8FC7\u8FD9\u4E2A\u624B\u673A\u53F7\u7801\u63A5\u6536\u6709\u5173\u6211\u7684\u6D4B\u8BD5\u548C\u7ED3\u679C\u7684\u66F4\u65B0";
var APPB2C_cj_review_personalDetails_title5 = "\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_cj_review_registrationError5 = "\u6211\u4EEC\u73B0\u5728\u65E0\u6CD5\u521B\u5EFA\u6CE8\u518C\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_btn5 = "\u663E\u793A\u65B0\u4EF7\u683C";
var APPB2C_cj_review_registrationFailedModal_changedFee_line15 = "\u6211\u4EEC\u901A\u77E5\u60A8, \u67D0\u4E9B\u6D4B\u8BD5\u7684\u4EF7\u683C\u5DF2\u66F4\u6539\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_line25 = "\u8BF7\u67E5\u770B\u65B0\u4EF7\u683C\u5E76\u91CD\u65B0\u9009\u62E9\u6D4B\u8BD5\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_line35 = "\u5BF9\u4E8E\u7ED9\u60A8\u5E26\u6765\u7684\u4E0D\u4FBF, \u6211\u4EEC\u6DF1\u8868\u6B49\u610F\u3002";
var APPB2C_cj_review_registrationFailedModal_changedFee_title5 = "\u6D4B\u8BD5\u4EF7\u683C\u5DF2\u66F4\u6539";
var APPB2C_cj_review_registrationFailedModal_cta5 = "\u91CD\u65B0\u5F00\u59CB\u9884\u8BA2";
var APPB2C_cj_review_registrationFailedModal_message5 = "\u54CE\u5440, \u51FA\u4E8B\u4E86\u3002\u8BF7\u91CD\u8BD5\u3002";
var APPB2C_cj_review_registrationFailedModal_title5 = "\u91CD\u65B0\u5F00\u59CB\u9884\u8BA2";
var APPB2C_cj_review_taxNumberError5 = "\u8BF7\u63D0\u4F9B\u6709\u6548\u7684\u589E\u503C\u7A0E/\u56FD\u5BB6\u7A0E\u53F7, \u8BE5\u53F7\u7801\u5FC5\u987B\u4E0E\u7279\u5B9A\u683C\u5F0F\u76F8\u5339\u914D(\u4F8B\u598213243546-3-35,1324354635)\u3002";
var APPB2C_cj_review_title5 = "\u56DE\u987E";
var APPB2C_cj_review_tncs_agreement5 = "\u6211\u540C\u610F\u96C5\u601D\u8003\u8BD5<0>\u6761\u6B3E\u548C\u6761\u4EF6</0>\u548C<2>\u53D6\u6D88\u653F\u7B56</2>";
var APPB2C_cj_review_tncs_title5 = "\u6211\u4EEC\u7684\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_cj_review_tooHighPromoCodeError5 = "\u6211\u4EEC\u65E0\u6CD5\u5B8C\u6210\u60A8\u7684\u6CE8\u518C\u3002\u8BF7\u5728\u4E0D\u4F7F\u7528\u4FC3\u9500\u4EE3\u7801\u7684\u60C5\u51B5\u4E0B\u5C1D\u8BD5\u6CE8\u518C\u3002\u5982\u679C\u60A8\u8BA4\u4E3A\u4EE3\u7801\u5E94\u8BE5\u6709\u6548, \u8BF7\u8054\u7CFB\u60A8\u7684\u4E2D\u5FC3\u5BFB\u6C42\u5E2E\u52A9";
var APPB2C_cj_timer_modal_cta5 = "\u91CD\u65B0\u5F00\u59CB\u9884\u8BA2";
var APPB2C_cj_timer_modal_message5 = "\u4E0D\u597D\u610F\u601D;\u60A8\u6CA1\u6709\u65F6\u95F4\u5B8C\u6210\u6B64\u9884\u8BA2\u3002";
var APPB2C_cj_timer_modal_title5 = "\u91CD\u65B0\u5F00\u59CB\u9884\u8BA2";
var APPB2C_cj_timer_nearEnd_minuteCount5 = "{{\u8BA1\u6570}} \u5206\u949F";
var APPB2C_cj_timer_nearEnd_minuteCount_other5 = "{{\u8BA1\u6570}} \u5206\u949F";
var APPB2C_cj_timer_nearEnd_text5 = '\u60A8\u9009\u62E9\u7684\u6D4B\u8BD5\u4EC5\u4FDD\u7559\u53E6\u4E00\u4E2A$t(APPB2C.cj.timer.nearEnd.minuteCount, {"count": {{count}} })\u3002';
var APPB2C_cj_timer_nearEnd_title5 = "\u6CE8\u610F\u529B\uFF01";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied5 = "\u60A8\u60F3\u5728\u54EA\u4E2A\u56FD\u5BB6/\u5730\u533A\u5B66\u4E60/\u5DE5\u4F5C/\u751F\u6D3B\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child5 = "\u4ED6\u4EEC\u60F3\u5728\u54EA\u4E2A\u56FD\u5BB6/\u5730\u533A\u5B66\u4E60/\u5DE5\u4F5C/\u751F\u6D3B\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest5 = "\u4F60\u4E3A\u4EC0\u4E48\u8981\u53C2\u52A0\u8003\u8BD5\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child5 = "\u4ED6\u4EEC\u4E3A\u4EC0\u4E48\u8981\u53C2\u52A0\u8003\u8BD5\uFF1F";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle5 = "\u60A8\u5BF9\u96C5\u601D\u7684\u5174\u8DA3";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle_child5 = "\u4ED6\u4EEC\u5BF9\u96C5\u601D\u7684\u5174\u8DA3";
var APPB2C_cj_ttProfile_forms_occupation_occupationLevel5 = "\u4F60\u7684\u804C\u4E1A\u6C34\u5E73\u662F\u591A\u5C11\uFF1F";
var APPB2C_cj_ttProfile_forms_occupation_occupationSector5 = "\u60A8\u7684\u804C\u4E1A\u662F\u4EC0\u4E48\uFF1F";
var APPB2C_cj_ttProfile_forms_occupation_sectionTitle5 = "\u60A8\u7684\u804C\u4E1A";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality5 = "\u60A8\u7684\u56FD\u7C4D\u56FD\u5BB6/\u5730\u533A\u662F\u4EC0\u4E48\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child5 = "\u4ED6\u4EEC\u7684\u56FD\u7C4D\u56FD\u5BB6/\u5730\u533A\u662F\u4EC0\u4E48\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3845 = "\u4E2D\u5B66(\u6700\u591A16\u5C81)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3855 = "\u4E2D\u5B66 (16-19 \u5C81)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3865 = "\u5B66\u4F4D(\u6216\u540C\u7B49\u5B66\u5386)";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3875 = "\u7814\u7A76\u751F";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_title5 = "\u4F60\u5B8C\u6210\u4E86\u4EC0\u4E48\u7EA7\u522B\u7684\u6559\u80B2\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken5 = "\u4F60\u7684\u7B2C\u4E00\u8BED\u8A00\u662F\u4EC0\u4E48\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child5 = "\u4ED6\u4EEC\u7684\u7B2C\u4E00\u8BED\u8A00\u662F\u4EC0\u4E48\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle5 = "\u5173\u4E8E\u4F60";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle_child5 = "\u5173\u4E8E\u60A8\u7684\u5B69\u5B50";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish5 = "\u4F60\u5B66\u82F1\u8BED\u591A\u5C11\u5E74\u4E86\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child5 = "\u4ED6\u4EEC\u5B66\u4E60\u82F1\u8BED\u591A\u5C11\u5E74\u4E86\uFF1F";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel5 = "\u60A8\u5BF9\u6B64\u95EE\u9898\u7684\u56DE\u7B54\u5BF9\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u6CA1\u6709\u5F71\u54CD";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child5 = "\u60A8\u5BF9\u6B64\u95EE\u9898\u7684\u56DE\u7B54\u5BF9\u4ED6\u4EEC\u7684\u8003\u8BD5\u6210\u7EE9\u6CA1\u6709\u5F71\u54CD";
var APPB2C_cj_ttProfile_note5 = "\u56DE\u7B54\u8FD9\u4E9B\u95EE\u9898\u4E0D\u4F1A\u5F71\u54CD\u60A8\u7684\u96C5\u601D\u8003\u8BD5\u6210\u7EE9\u3002\u8FD9\u4E9B\u95EE\u9898\u5C06\u5E2E\u52A9\u6211\u4EEC\u6539\u8FDB\u5BF9\u50CF\u60A8\u8FD9\u6837\u7684\u5E94\u8BD5\u8005\u7684\u670D\u52A1\u3002";
var APPB2C_cj_ttProfile_note_child5 = "\u56DE\u7B54\u8FD9\u4E9B\u95EE\u9898\u5BF9\u60A8\u5B69\u5B50\u7684\u96C5\u601D\u8003\u8BD5\u6210\u7EE9\u6CA1\u6709\u5F71\u54CD\u3002\u8FD9\u4E9B\u95EE\u9898\u5C06\u5E2E\u52A9\u6211\u4EEC\u6539\u8FDB\u5BF9\u50CF\u4ED6\u4EEC\u8FD9\u6837\u7684\u5E94\u8BD5\u8005\u7684\u670D\u52A1\u3002";
var APPB2C_cj_ttProfile_title5 = "\u60A8\u7684\u4E2A\u4EBA\u8D44\u6599";
var APPB2C_cj_ttProfile_title_child5 = "\u60A8\u5B69\u5B50\u7684\u4E2A\u4EBA\u8D44\u6599";
var APPB2C_cj_ukviStartPage_ac_content5 = "\u8BE5\u8003\u8BD5\u9002\u7528\u4E8E\u5E0C\u671B\u653B\u8BFB\u672C\u79D1\u6216\u7814\u7A76\u751F\u6C34\u5E73\u7684\u5E94\u8BD5\u8005, \u4EE5\u53CA\u5728\u82F1\u56FD\u5BFB\u6C42\u4E13\u4E1A\u6CE8\u518C\u7684\u8003\u751F\u3002";
var APPB2C_cj_ukviStartPage_ac_title5 = "\u7528\u4E8E\u82F1\u56FD\u56FD\u9645\u6559\u80B2\u76EE\u6807\u7684\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5";
var APPB2C_cj_ukviStartPage_gt_content5 = "\u8BE5\u6D4B\u8BD5\u9002\u7528\u4E8E\u5E0C\u671B\u79FB\u6C11\u5230\u82F1\u56FD\u7684\u5E94\u8BD5\u8005\u4EE5\u53CA\u5E0C\u671B\u63A5\u53D7\u5B66\u4F4D\u4EE5\u4E0B\u57F9\u8BAD\u6216\u5B66\u4E60\u7684\u8003\u751F\u3002";
var APPB2C_cj_ukviStartPage_gt_title5 = "\u7528\u4E8E UKVI \u57F9\u8BAD\u7C7B\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_content5 = '\u8BE5\u6D4B\u8BD5\u9002\u7528\u4E8E\u90A3\u4E9B\u9700\u8981\u8BC1\u660E\u81EA\u5DF1\u7684\u82F1\u8BED\u53E3\u8BED\u548C\u542C\u529B\u6280\u80FD\u7684\u4EBA, \u4F5C\u4E3A\u4ED6\u4EEC\u7533\u8BF7\u82F1\u56FD\u7B7E\u8BC1\u548C\u79FB\u6C11\u5C40"\u5B9A\u5C45\u8005\u5BB6\u5EAD"\u7B7E\u8BC1\u7684\u4E00\u90E8\u5206\u3002';
var APPB2C_cj_ukviStartPage_lifeSkils_a1_title5 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5 A1";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_content5 = "\u8BE5\u6D4B\u8BD5\u9002\u7528\u4E8E\u90A3\u4E9B\u9700\u8981\u8BC1\u660E\u81EA\u5DF1\u7684\u82F1\u8BED\u53E3\u8BED\u548C\u542C\u529B\u6280\u80FD\u7684\u4EBA, \u4F5C\u4E3A\u4ED6\u4EEC\u7533\u8BF7\u82F1\u56FD\u7B7E\u8BC1\u548C\u79FB\u6C11\u5C40\u5EF6\u671F\u5230\u5BB6\u5EAD, \u914D\u5076\u6216\u4F34\u4FA3\u7B7E\u8BC1\u7684\u4E00\u90E8\u5206\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_title5 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5A2(\u4EC5\u9650\u82F1\u56FD)";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_content5 = "\u8BE5\u6D4B\u8BD5\u9002\u7528\u4E8E\u90A3\u4E9B\u9700\u8981\u8BC1\u660E\u81EA\u5DF1\u7684\u82F1\u8BED\u53E3\u8BED\u548C\u542C\u529B\u6280\u80FD\u7684\u4EBA, \u4F5C\u4E3A\u4ED6\u4EEC\u7533\u8BF7\u82F1\u56FD\u7B7E\u8BC1\u548C\u79FB\u6C11\u5C40\u65E0\u9650\u671F\u5C45\u7559\u6216\u516C\u6C11\u8EAB\u4EFD\u7684\u4E00\u90E8\u5206\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_title5 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5B1";
var APPB2C_cj_ukviStartPage_lifeSkils_citizenship5 = "\u65E0\u9650\u671F\u5C45\u7559\u6216\u516C\u6C11\u8EAB\u4EFD\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_extension5 = "\u5EF6\u957F\u5BB6\u5EAD\u3001\u914D\u5076\u6216\u4F34\u4FA3\u7B7E\u8BC1";
var APPB2C_cj_ukviStartPage_lifeSkils_family5 = "\u5BB6\u5EAD\u7B7E\u8BC1";
var APPB2C_cj_ukviStartPage_lifeSkils_note_content5 = "\u5982\u679C\u60A8\u5728\u8FC7\u53BB 2 \u5E74\u5185\u53C2\u52A0\u4E86\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5\u5E76\u901A\u8FC7\u8003\u8BD5, \u5219\u4E0D\u5E94\u53C2\u52A0\u5B8C\u5168\u76F8\u540C\u7684\u8003\u8BD5\u3002";
var APPB2C_cj_ukviStartPage_lifeSkils_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_cj_ukviStartPage_lifeSkils_title5 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5";
var APPB2C_cj_ukviStartPage_lifeSkils_visaTypes5 = "\u5982\u679C\u60A8\u7533\u8BF7\u4EE5\u4E0B\u7C7B\u578B\u7684\u82F1\u56FD\u7B7E\u8BC1, \u8FD9\u662F\u8981\u53C2\u52A0\u7684\u6D4B\u8BD5: ";
var APPB2C_cj_ukviStartPage_note1_content5 = "\u5982\u679C\u60A8\u6CA1\u6709\u9884\u8BA2\u96C5\u601D\u8003\u8BD5\u6765\u652F\u6301\u82F1\u56FD\u7B7E\u8BC1\u53CA\u79FB\u6C11\u7533\u8BF7, <0>\u8BF7\u53C2\u9605\u6211\u4EEC\u63D0\u4F9B\u7684\u5176\u4ED6\u96C5\u601D\u8003\u8BD5</0>\u3002";
var APPB2C_cj_ukviStartPage_note1_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_cj_ukviStartPage_terms5 = "\u5728\u7EE7\u7EED\u4E4B\u524D, \u8BF7\u9605\u8BFB<0>\u96C5\u601D\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u7684\u6761\u6B3E\u548C\u6761\u4EF6</0>\u3002";
var APPB2C_cj_ukviStartPage_testsBelow5 = "\u4EE5\u4E0B\u96C5\u601D\u8003\u8BD5\u5DF2\u83B7\u51C6\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u548C\u79FB\u6C11\u76EE\u7684: ";
var APPB2C_cj_ukviStartPage_title5 = "\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_cj_ukviStartPage_whichUkvi5 = "\u54EA\u79CD\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u7684\u96C5\u601D\u8003\u8BD5\u9002\u5408\u6211\uFF1F";
var APPB2C_common_account_details_email_cancel4 = "\u53D6\u6D88";
var APPB2C_common_account_details_email_confirm5 = "\u786E\u8BA4\u7535\u5B50\u90AE\u4EF6";
var APPB2C_common_account_details_email_label5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_common_account_details_email_maxLength5 = "\u60A8\u7684\u7535\u5B50\u90AE\u4EF6\u592A\u957F, \u6700\u5927\u957F\u5EA6\u4E3A 60\u3002";
var APPB2C_common_account_details_email_mismatch5 = "\u6B64\u5B57\u6BB5\u9700\u8981\u4E0E\u4E0A\u9762\u7684\u7535\u5B50\u90AE\u4EF6\u5B57\u6BB5\u5B8C\u5168\u5339\u914D\u3002";
var APPB2C_common_account_details_email_note5 = "\u60A8\u5C06\u63D0\u4F9B\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u5FC5\u987B\u6709\u6548, \u5E76\u4E14\u60A8\u9700\u8981\u6709\u6743\u8BBF\u95EE\u5B83\u3002\u6709\u5173\u60A8\u7684\u5E10\u6237\u548C\u6CE8\u518C\u7684\u6240\u6709\u901A\u4FE1\u5C06\u53D1\u9001\u5230\u6B64\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u3002\u4ECE\u73B0\u5728\u5F00\u59CB, \u60A8\u5C06\u80FD\u591F\u4F7F\u7528\u7528\u6237\u540D\u6216\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u767B\u5F55\u95E8\u6237\u3002";
var APPB2C_common_account_details_email_save5 = "\u4FDD\u5B58\u7535\u5B50\u90AE\u4EF6";
var APPB2C_common_account_details_email_set5 = "\u63D0\u4F9B\u7535\u5B50\u90AE\u4EF6";
var APPB2C_common_account_details_email_success5 = "\u60A8\u7684\u7535\u5B50\u90AE\u4EF6\u5DF2\u4FDD\u5B58\u3002";
var APPB2C_common_account_details_email_username5 = "\u7528\u6237\u540D";
var APPB2C_common_account_details_email_validEmail5 = "\u8BF7\u6307\u5B9A\u6709\u6548\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u3002";
var APPB2C_common_account_details_name5 = "\u60A8\u7684\u59D3\u540D";
var APPB2C_common_account_details_psw_cancel4 = "\u53D6\u6D88";
var APPB2C_common_account_details_psw_change5 = "\u66F4\u6539\u5BC6\u7801";
var APPB2C_common_account_details_psw_label5 = "\u5BC6\u7801";
var APPB2C_common_account_details_psw_new5 = "\u65B0\u5BC6\u7801";
var APPB2C_common_account_details_psw_old5 = "\u65E7\u5BC6\u7801";
var APPB2C_common_account_details_psw_requirements_levels_05 = "\u975E\u5E38\u5F31";
var APPB2C_common_account_details_psw_requirements_levels_15 = "\u5F31";
var APPB2C_common_account_details_psw_requirements_levels_25 = "\u4E2D\u7B49";
var APPB2C_common_account_details_psw_requirements_levels_35 = "\u5F3A";
var APPB2C_common_account_details_psw_requirements_levels_45 = "\u975E\u5E38\u5F3A\u5927";
var APPB2C_common_account_details_psw_requirements_maxLength5 = "\u60A8\u7684\u5BC6\u7801\u592A\u957F, \u6700\u5927\u957F\u5EA6\u4E3A 100\u3002";
var APPB2C_common_account_details_psw_requirements_mustHave5 = "\u60A8\u7684\u5BC6\u7801\u5FC5\u987B\u5177\u6709: ";
var APPB2C_common_account_details_psw_requirements_ok5 = "\u60A8\u7684\u5BC6\u7801\u7B26\u5408\u8981\u6C42";
var APPB2C_common_account_details_psw_requirements_reqs_length5 = "8 \u4E2A\u6216\u66F4\u591A\u5B57\u7B26";
var APPB2C_common_account_details_psw_requirements_reqs_lower5 = "\u5C0F\u5199\u5B57\u6BCD";
var APPB2C_common_account_details_psw_requirements_reqs_number5 = "\u81F3\u5C11\u4E00\u4E2A\u6570\u5B57";
var APPB2C_common_account_details_psw_requirements_reqs_special5 = "\u81F3\u5C11\u4E00\u4E2A\u7279\u6B8A\u5B57\u7B26";
var APPB2C_common_account_details_psw_requirements_reqs_upper5 = "\u5927\u5199\u5B57\u6BCD";
var APPB2C_common_account_details_psw_requirements_shouldHave5 = "\u5E76\u4E14\u9700\u8981\u6EE1\u8DB3\u4EE5\u4E0B\u8981\u6C42\u4E2D\u7684 3 \u4E2A: ";
var APPB2C_common_account_details_psw_requirements_strength5 = "\u5F3A\u5EA6";
var APPB2C_common_account_details_psw_requirements_tooWeak5 = "\u60A8\u7684\u5BC6\u7801\u592A\u5F31\u3002";
var APPB2C_common_account_details_psw_show5 = "\u663E\u793A\u5BC6\u7801";
var APPB2C_common_account_details_psw_success5 = "\u60A8\u7684\u5BC6\u7801\u5DF2\u66F4\u6539\u3002";
var APPB2C_common_account_details_subnote_address5 = "\u4F7F\u7528\u5730\u5740\u7684\u539F\u59CB\u62FC\u5199";
var APPB2C_common_account_details_subnote_city5 = "\u4F7F\u7528town / city\u7684\u539F\u59CB\u62FC\u5199";
var APPB2C_common_account_error5 = "\u54CE\u5440, \u51FA\u4E8B\u4E86\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_common_account_marketing_success5 = "\u60A8\u7684\u8425\u9500\u504F\u597D\u5DF2\u6210\u529F\u66F4\u65B0\u3002";
var APPB2C_common_account_marketing_title5 = "\u60A8\u7684\u8425\u9500\u504F\u597D";
var APPB2C_common_account_smsNotificationSettings_mobileLabel5 = "\u77ED\u4FE1\u5C06\u53D1\u9001\u5230: ";
var APPB2C_common_account_smsNotificationSettings_success5 = "\u60A8\u7684\u77ED\u4FE1\u901A\u77E5\u8BBE\u7F6E\u5DF2\u6210\u529F\u66F4\u65B0\u3002";
var APPB2C_common_account_smsNotificationSettings_title5 = "\u77ED\u4FE1\u901A\u77E5\u8BBE\u7F6E";
var APPB2C_common_account_terms_cta5 = "\u4E0B\u8F7D\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_account_terms_disclaimer5 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u5C06\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F\u6765\u5904\u7406\u60A8\u7684\u6CE8\u518C\u3002\u5904\u7406\u60A8\u7684\u4FE1\u606F\u7684\u6CD5\u5F8B\u4F9D\u636E\u662F\u540C\u610F\u6211\u4EEC\u7684\u6CE8\u518C\u6761\u6B3E\u548C\u6761\u4EF6(\u5408\u540C)\u3002";
var APPB2C_common_account_terms_download5 = "\u60A8\u53EF\u4EE5\u4E0B\u8F7D\u5546\u5B9A\u7684\u6761\u6B3E\u548C\u6761\u4EF6\u7248\u672C: ";
var APPB2C_common_account_terms_extra_line15 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u9075\u5B88\u82F1\u56FD\u7684\u6570\u636E\u4FDD\u62A4\u6CD5\u4EE5\u53CA\u7B26\u5408\u56FD\u9645\u516C\u8BA4\u6807\u51C6\u7684\u5176\u4ED6\u56FD\u5BB6/\u5730\u533A\u7684\u6CD5\u5F8B\u3002";
var APPB2C_common_account_terms_extra_line25 = "\u60A8\u6709\u6743\u7D22\u53D6\u6211\u4EEC\u6301\u6709\u7684\u5173\u4E8E\u60A8\u7684\u4FE1\u606F\u7684\u526F\u672C, \u5E76\u6709\u6743\u8981\u6C42\u6211\u4EEC\u66F4\u6B63\u8BE5\u4FE1\u606F\u4E2D\u7684\u4EFB\u4F55\u4E0D\u51C6\u786E\u4E4B\u5904\u3002\u5982\u679C\u60A8\u5BF9\u6211\u4EEC\u5982\u4F55\u4F7F\u7528\u60A8\u7684\u4E2A\u4EBA\u4FE1\u606F\u6709\u7591\u8651, \u60A8\u4E5F\u6709\u6743\u5411\u9690\u79C1\u76D1\u7BA1\u673A\u6784\u6295\u8BC9\u3002";
var APPB2C_common_account_terms_extra_line3_15 = "\u6709\u5173\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7F51\u7AD9\u7684\u9690\u79C1\u90E8\u5206, ";
var APPB2C_common_account_terms_extra_line3_25 = "\u6216\u8054\u7CFB\u60A8\u5F53\u5730\u7684\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u529E\u4E8B\u5904\u3002\u6211\u4EEC\u5C06\u4ECE\u6536\u96C6\u60A8\u7684\u6CE8\u518C\u4FE1\u606F\u4E4B\u65E5\u8D77\u4FDD\u7559\u60A8\u7684\u4FE1\u606F 3 \u5E74;\u51FA\u4E8E\u8425\u9500\u76EE\u7684, \u6211\u4EEC\u5C06\u4FDD\u7559\u60A8\u7684\u4FE1\u606F 7 \u5E74\u3002";
var APPB2C_common_account_terms_extra_title5 = "\u6570\u636E\u4FDD\u62A4";
var APPB2C_common_account_terms_previous5 = "\u60A8\u53EF\u4EE5\u5728\u6B64\u5904\u627E\u5230\u4EE5\u524D\u7684\u6761\u6B3E\u548C\u6761\u4EF6\u7248\u672C\u7684\u94FE\u63A5: <0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_common_account_terms_title5 = "\u60A8\u540C\u610F\u5E10\u6237\u6CE8\u518C<0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_common_account_title5 = "\u6211\u7684\u5E10\u6237";
var APPB2C_common_alertBar_afterTest5 = "\u60A8\u4ECD\u7136\u9700\u8981: ";
var APPB2C_common_alertBar_beforeTest5 = "\u5728\u8003\u8BD5\u65E5\u4E4B\u524D, \u60A8\u9700\u8981: ";
var APPB2C_common_alertBar_cancelReupload5 = "\u518D\u6B21\u4E0A\u4F20\u53D6\u6D88\u6587\u4EF6";
var APPB2C_common_alertBar_check5 = "\u8FDB\u884C\u6D4B\u8BD5\u524D\u68C0\u67E5";
var APPB2C_common_alertBar_childConsentMissing5 = "\u63D0\u4F9B\u513F\u7AE5\u540C\u610F\u4FE1\u606F";
var APPB2C_common_alertBar_creds5 = "\u67E5\u770B\u6211\u7684\u767B\u5F55\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_alertBar_eorPayment5 = "\u652F\u4ED8\u60A8\u7684\u590D\u8BAE\u8D39\u7528";
var APPB2C_common_alertBar_eorPaymentProof5 = "\u4E0A\u4F20\u60A8\u7684\u590D\u8BAE\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_common_alertBar_idReupload5 = "\u518D\u6B21\u4E0A\u4F20\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6";
var APPB2C_common_alertBar_idUpload5 = "\u4E0A\u4F20\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6";
var APPB2C_common_alertBar_medicalReupload5 = "\u518D\u6B21\u4E0A\u4F20\u533B\u7597\u8BC1\u636E";
var APPB2C_common_alertBar_medicalUpload5 = "\u4E0A\u4F20\u533B\u7597\u8BC1\u636E";
var APPB2C_common_alertBar_regPayment5 = "\u652F\u4ED8\u8003\u8BD5\u8D39\u7528";
var APPB2C_common_alertBar_regPaymentProof5 = "\u4E0A\u4F20\u8003\u8BD5\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_common_alertBar_withCreds5 = "\u60A8\u73B0\u5728\u53EF\u4EE5\u767B\u5F55\u6D4B\u8BD5: ";
var APPB2C_common_basic_add5 = "\u52A0";
var APPB2C_common_basic_backToTop5 = "\u8FD4\u56DE\u9875\u9996";
var APPB2C_common_basic_bookingDetails_arrival5 = "(\u8BF7\u5230\u8FBE{{\u65F6\u95F4}})";
var APPB2C_common_basic_bookingDetails_lifeSkills_results5 = "\u542C\u529B\u548C\u53E3\u8BED";
var APPB2C_common_basic_bookingDetails_lifeSkills_title5 = "\u60A8\u7684\u6D4B\u8BD5";
var APPB2C_common_basic_bookingDetails_lrw_cd5 = "\u96C5\u601D\u673A\u8003\u5728\u8003\u70B9";
var APPB2C_common_basic_bookingDetails_lrw_online5 = "\u5728\u7EBF";
var APPB2C_common_basic_bookingDetails_lrw_pb5 = "\u96C5\u601D\u7EB8\u7B14\u8003\u8BD5";
var APPB2C_common_basic_bookingDetails_lrw_results5 = "\u542C\u529B\u3001\u9605\u8BFB\u548C\u5199\u4F5C";
var APPB2C_common_basic_bookingDetails_lrw_title5 = "\u60A8\u7684\u7B14\u8BD5";
var APPB2C_common_basic_bookingDetails_osrTitle_listening5 = "\u60A8\u7684\u542C\u529B\u6D4B\u8BD5";
var APPB2C_common_basic_bookingDetails_osrTitle_reading5 = "\u60A8\u7684\u9605\u8BFB\u6D4B\u8BD5";
var APPB2C_common_basic_bookingDetails_speaking_f2f5 = "\u9762\u5BF9\u9762";
var APPB2C_common_basic_bookingDetails_speaking_live5 = "\u9762\u5BF9\u9762";
var APPB2C_common_basic_bookingDetails_speaking_online5 = "\u5728\u7EBF";
var APPB2C_common_basic_bookingDetails_speaking_remote5 = "\u8003\u8BD5\u4E2D\u5FC3\u89C6\u9891\u901A\u8BDD";
var APPB2C_common_basic_bookingDetails_speaking_results5 = "\u8BF4";
var APPB2C_common_basic_bookingDetails_speaking_title5 = "\u60A8\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_basic_bookPrePaid5 = "\u9884\u7EA6\u9884\u4ED8\u8D39\u8003\u8BD5";
var APPB2C_common_basic_cancel5 = "\u53D6\u6D88";
var APPB2C_common_basic_change5 = "\u6539\u53D8";
var APPB2C_common_basic_choose5 = "\u9009\u62E9";
var APPB2C_common_basic_close5 = "\u5173\u95ED";
var APPB2C_common_basic_copied5 = "\u590D\u5236";
var APPB2C_common_basic_copy5 = "\u590D\u5236";
var APPB2C_common_basic_dateField_day5 = "\u65E5";
var APPB2C_common_basic_dateField_month5 = "\u6708";
var APPB2C_common_basic_dateField_months5 = "\u9009\u62E9\u6708\u4EFD";
var APPB2C_common_basic_dateField_year5 = "\u5E74";
var APPB2C_common_basic_edit5 = "\u7F16\u8F91";
var APPB2C_common_basic_errors_contactTestCentre5 = "\u62B1\u6B49, \u51FA\u73B0\u4E86\u95EE\u9898\u3002\u8BF7\u8054\u7CFB\u60A8\u5F53\u5730\u7684\u4E2D\u5FC3\u7533\u8BF7\u63A5\u9001\u670D\u52A1\u3002";
var APPB2C_common_basic_errors_network5 = "\u53EF\u80FD\u60A8\u5DF2\u7ECF\u5931\u53BB\u4E86\u4E0E\u4E92\u8054\u7F51\u7684\u8FDE\u63A5\u3002\u8BF7\u5C1D\u8BD5\u91CD\u65B0\u8FDE\u63A5\u5E76\u5237\u65B0\u9875\u9762\u3002";
var APPB2C_common_basic_errors_noTest5 = "\u6B64\u6D4B\u8BD5\u4E0D\u518D\u53EF\u7528\u3002\u8BF7\u9009\u62E9\u5176\u4ED6\u6D4B\u8BD5\u3002";
var APPB2C_common_basic_errors_server5 = "\u770B\u8D77\u6765\u51FA\u4E86\u4EC0\u4E48\u95EE\u9898\u3002\u8BF7\u5728\u51E0\u5206\u949F\u540E\u91CD\u8BD5\u3002";
var APPB2C_common_basic_errors_title5 = "\u54CE\u5440\uFF01";
var APPB2C_common_basic_errors_unableToDownloadFile5 = "\u6211\u4EEC\u5F97\u5230\u4E86\u4F60\u7684\u6587\u4EF6, \u4F46\u5728\u663E\u793A\u5B83\u4EEC\u65F6\u9047\u5230\u4E86\u95EE\u9898\u3002";
var APPB2C_common_basic_errors_unknown5 = "\u770B\u8D77\u6765\u51FA\u4E86\u4EC0\u4E48\u95EE\u9898\u3002\u8BF7\u5728\u51E0\u5206\u949F\u540E\u91CD\u8BD5\u3002";
var APPB2C_common_basic_expando_collapse5 = "- \u9690\u85CF\u5B8C\u6574\u901A\u77E5";
var APPB2C_common_basic_expando_show5 = "+ \u9605\u8BFB\u5B8C\u6574\u901A\u77E5";
var APPB2C_common_basic_externalLinkTitle5 = "(\u6253\u5F00\u65B0\u7A97\u53E3)";
var APPB2C_common_basic_failure5 = "\u5931\u8D25";
var APPB2C_common_basic_forms_addressMaxLength5 = "\u5730\u5740\u957F\u5EA6\u6700\u591A\u4E3A {{count}} \u4E2A\u5B57\u7B26\u3002";
var APPB2C_common_basic_forms_dobMin5 = "\u60A8\u9700\u8981\u5E74\u6EE1 18 \u5C81\u624D\u80FD\u521B\u5EFA\u5E10\u6237\u3002\u60A8\u5C06\u9700\u8981\u60A8\u7684\u7236\u6BCD\u6216\u76D1\u62A4\u4EBA\u4E3A\u60A8\u9884\u8BA2\u8003\u8BD5\u3002";
var APPB2C_common_basic_forms_dobMinIol5 = "\u62B1\u6B49, \u60A8\u5FC5\u987B\u5E74\u6EE1 18 \u5C81\u624D\u80FD\u5728\u7EBF\u9884\u8BA2\u96C5\u601D\u8003\u8BD5\u3002\u8BF7\u5728\u8003\u8BD5\u4E2D\u5FC3\u9884\u8BA2\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_basic_forms_dobOutOfRange5 = "\u4F60\u786E\u5B9A\u4F60\u662F\u90A3\u4E00\u5E74\u51FA\u751F\u7684\u5417\uFF1F";
var APPB2C_common_basic_forms_firstNameMaxLength5 = "\u540D\u5B57/\u540D\u5B57\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u4E2A\u5B57\u7B26\u957F\u3002";
var APPB2C_common_basic_forms_idExpiration5 = "\u60A8\u7684\u6587\u4EF6\u5728\u8003\u8BD5\u5B66\u671F\u524D\u8FC7\u671F\u3002";
var APPB2C_common_basic_forms_idMaxLength5 = "\u6587\u6863\u7F16\u53F7\u6700\u591A\u53EF\u4EE5\u5305\u542B 20 \u4E2A\u5B57\u7B26\u3002";
var APPB2C_common_basic_forms_idNumber5 = '\u5B57\u6BB5\u5FC5\u987B\u4E0E\u7279\u5B9A\u683C\u5F0F\u5339\u914D(\u5141\u8BB8\u4F7F\u7528\u62C9\u4E01\u5B57\u6BCD\u3001\u6570\u5B57\u548C\u8FDE\u5B57\u7B26"-")';
var APPB2C_common_basic_forms_idProofRequired5 = "\u9700\u8981\u8EAB\u4EFD\u8BC1\u660E\u3002";
var APPB2C_common_basic_forms_maxLengthMessage5 = "\u6700\u5927\u957F\u5EA6\u4E3A {{\u8BA1\u6570}}\u3002";
var APPB2C_common_basic_forms_minorDobMax5 = "\u5982\u679C\u60A8\u7684\u5B69\u5B50\u5E74\u6EE1 18 \u5C81, \u8BF7\u8BA9\u4ED6\u4EEC\u81EA\u5DF1\u5728\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u6CE8\u518C\u4E00\u4E2A\u5E10\u6237";
var APPB2C_common_basic_forms_minorDobMin5 = "11\u5C81\u53CA\u4EE5\u4E0A\u7684\u513F\u7AE5\u53EF\u4EE5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_basic_forms_mobileBypass5 = "\u8FD9\u662F\u6211\u6B63\u786E\u7684\u624B\u673A\u53F7\u7801";
var APPB2C_common_basic_forms_mobileLength5 = "\u624B\u673A\u53F7\u7801\u6700\u957F\u53EF\u8FBE 20 \u4E2A\u5B57\u7B26\u3002";
var APPB2C_common_basic_forms_onlyLatin5 = "\u5B57\u6BB5\u5E94\u4EC5\u5305\u542B\u62C9\u4E01\u5B57\u7B26\u3002";
var APPB2C_common_basic_forms_postCodeMaxLength5 = "\u90AE\u653F\u7F16\u7801\u7684\u957F\u5EA6\u6700\u591A\u4E3A {{count}} \u4E2A\u5B57\u7B26\u3002";
var APPB2C_common_basic_forms_postCodeMessage5 = "\u90AE\u653F\u7F16\u7801/\u90AE\u653F\u7F16\u7801\u5FC5\u987B\u5339\u914D5\u4F4D\u7279\u5B9A\u683C\u5F0F(\u4F8B\u598207102)";
var APPB2C_common_basic_forms_postCodeNotFound5 = "\u62B1\u6B49, \u6211\u4EEC\u627E\u4E0D\u5230\u8BE5\u90AE\u653F\u7F16\u7801;\u8BF7\u68C0\u67E5\u60A8\u8F93\u5165\u662F\u5426\u6B63\u786E\u3002";
var APPB2C_common_basic_forms_requiredMessage5 = "\u6B64\u5B57\u6BB5\u4E3A\u5FC5\u586B\u5B57\u6BB5\u3002";
var APPB2C_common_basic_forms_requiredNoSurmane5 = '\u6B64\u5B57\u6BB5\u4E3A\u5FC5\u586B\u5B57\u6BB5\u3002\u5982\u679C\u60A8\u6CA1\u6709\u59D3\u6C0F, \u8BF7\u5728\u6B64\u5B57\u6BB5\u4E2D\u8F93\u5165\u8FDE\u5B57\u7B26("-")\u3002';
var APPB2C_common_basic_forms_stateMaxLength5 = "\u72B6\u6001\u6700\u591A\u53EF\u4EE5\u662F {{count}} \u4E2A\u5B57\u7B26\u957F\u3002";
var APPB2C_common_basic_forms_surnameMaxLength5 = "\u59D3\u6C0F/\u59D3\u6C0F\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u4E2A\u5B57\u7B26\u957F\u3002";
var APPB2C_common_basic_forms_townMaxLength5 = "\u57CE\u9547/\u57CE\u5E02\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u4E2A\u5B57\u7B26\u957F\u3002";
var APPB2C_common_basic_forms_unsupportedChars5 = "\u5B57\u6BB5\u5305\u542B\u4E0D\u652F\u6301\u7684\u5B57\u7B26\u96C6\u3002";
var APPB2C_common_basic_forms_validMobile5 = "\u8BF7\u6307\u5B9A\u6709\u6548\u7684\u624B\u673A\u53F7\u7801\u3002";
var APPB2C_common_basic_goBack5 = "\u56DE\u53BB";
var APPB2C_common_basic_loading5 = "\u88C5\u8F7D\u3002\u3002\u3002";
var APPB2C_common_basic_menu5 = "\u83DC\u5355";
var APPB2C_common_basic_no5 = "\u4E0D";
var APPB2C_common_basic_notAllowed_msg5 = "\u60A8\u4E0D\u5F97\u8BBF\u95EE\u6B64\u9875\u9762\u3002";
var APPB2C_common_basic_notAllowed_title5 = "\u54CE\u5440\uFF01";
var APPB2C_common_basic_notFound_goHome5 = "\u8F6C\u5230\u4E3B\u9875";
var APPB2C_common_basic_notFound_msg5 = "\u60A8\u8981\u67E5\u627E\u7684\u9875\u9762\u4E0D\u5B58\u5728";
var APPB2C_common_basic_notFound_title5 = "\u672A\u627E\u5230\u9875\u9762";
var APPB2C_common_basic_notLoading_note5 = "\u6211\u4EEC\u65E0\u6CD5\u52A0\u8F7D\u8BF7\u6C42\u7684\u9875\u9762\u3002\u5F88\u53EF\u80FD\u60A8\u5DF2\u7ECF\u5931\u53BB\u4E86\u7F51\u7EDC\u8FDE\u63A5\u3002\u5C1D\u8BD5\u5728\u51E0\u5206\u949F\u5185\u5237\u65B0\u9875\u9762\u3002";
var APPB2C_common_basic_notLoading_refresh5 = "\u5237\u65B0";
var APPB2C_common_basic_notLoading_title5 = "\u65E0\u6CD5\u52A0\u8F7D\u9875\u9762";
var APPB2C_common_basic_ok5 = "\u8FD8\u884C";
var APPB2C_common_basic_on5 = "\u4E0A";
var APPB2C_common_basic_oneSecond5 = "\u8BF7\u4E00\u79D2\u949F";
var APPB2C_common_basic_pleaseNote5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_basic_retake5 = "\u593A\u56DE";
var APPB2C_common_basic_upload5 = "\u4E0A\u4F20";
var APPB2C_common_basic_validationError5 = "\u8BF7\u68C0\u67E5\u6240\u6709\u6807\u8BB0\u7684\u95EE\u9898\u4EE5\u7EE7\u7EED\u3002";
var APPB2C_common_basic_waitReassurance5 = "\u6211\u4EEC\u5FEB\u5230\u4E86...";
var APPB2C_common_basic_wereSorry5 = "\u5BF9\u4E0D\u8D77";
var APPB2C_common_basic_yes5 = "\u662F\u7684";
var APPB2C_common_bookTest_changeExamType5 = "\u66F4\u6539\u6D4B\u8BD5\u7C7B\u578B";
var APPB2C_common_bookTest_filters_afternoon5 = "\u4E0B\u5348";
var APPB2C_common_bookTest_filters_allDates5 = "\u6240\u6709\u65E5\u671F";
var APPB2C_common_bookTest_filters_anyTime5 = "\u4E00\u5929\u4E2D\u7684\u4EFB\u4F55\u65F6\u95F4";
var APPB2C_common_bookTest_filters_computer5 = "\u8BA1\u7B97\u673A";
var APPB2C_common_bookTest_filters_evening5 = "\u508D\u665A";
var APPB2C_common_bookTest_filters_modal_cta5 = "\u641C\u7D22\u7ED3\u679C";
var APPB2C_common_bookTest_filters_modal_hide5 = "\u9690\u85CF";
var APPB2C_common_bookTest_filters_modal_iDontMind5 = "\u6211\u4E0D\u4ECB\u610F";
var APPB2C_common_bookTest_filters_modal_resultsIn5 = "\u7ED3\u679C\u5728 {{\u5929}} \u5929";
var APPB2C_common_bookTest_filters_modal_subtitle5 = "\u8FC7\u6EE4\u5668\u6D4B\u8BD5";
var APPB2C_common_bookTest_filters_modal_title5 = "\u8FC7\u6EE4 \u5668";
var APPB2C_common_bookTest_filters_modal_whichFormatTest5 = "\u60A8\u60F3\u53C2\u52A0\u54EA\u79CD\u5F62\u5F0F\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_bookTest_filters_modal_whichTimeOfDay5 = "\u4E00\u5929\u4E2D\u542C\u529B\u3001\u9605\u8BFB\u548C\u5199\u4F5C\u6D4B\u8BD5\u7684\u65F6\u95F4";
var APPB2C_common_bookTest_filters_morning5 = "\u4E0A\u5348";
var APPB2C_common_bookTest_filters_paper5 = "\u7EB8";
var APPB2C_common_bookTest_filters_seeAllFilters5 = "\u67E5\u770B\u6240\u6709\u8FC7\u6EE4\u5668";
var APPB2C_common_bookTest_filters_title5 = "\u7B5B\u9009\u4F9D\u636E";
var APPB2C_common_bookTest_loadMoreTests5 = "\u52A0\u8F7D\u66F4\u591A\u6D4B\u8BD5";
var APPB2C_common_bookTest_noDiscount_msg_countryNotMatched5 = "\u6B64\u4EE3\u91D1\u5238\u4E0D\u80FD\u5728\u60A8\u9009\u62E9\u7684\u56FD\u5BB6/\u5730\u533A\u4F7F\u7528\u3002\u8BF7\u8FD4\u56DE\u539F\u6765\u7684\u56FD\u5BB6/\u5730\u533A\u9009\u62E9\u3002";
var APPB2C_common_bookTest_noDiscount_msg_notFound5 = "\u6B64\u51ED\u8BC1\u65E0\u6548\u3002";
var APPB2C_common_bookTest_noDiscount_msg_notSupported5 = "\u6B64\u4EE3\u91D1\u5238\u4E13\u7528\u4E8E\u5176\u4ED6\u4EA7\u54C1\u3002\u8BF7\u8FD4\u56DE\u539F\u59CB\u4EA7\u54C1\u9009\u62E9\u3002";
var APPB2C_common_bookTest_noDiscount_msg_reserved5 = "\u6B64\u4EE3\u91D1\u5238\u5DF2\u88AB\u4F7F\u7528\u3002\u8BF7\u5728\u4F7F\u7528\u7684\u5730\u65B9\u5B8C\u6210\u6CE8\u518C, \u6216\u7A0D\u540E\u518D\u8BD5\u3002";
var APPB2C_common_bookTest_noDiscount_msg_used5 = "\u6B64\u4EE3\u91D1\u5238\u5DF2\u88AB\u4F7F\u7528\u3002\u60A8\u53EF\u4EE5\u7EE7\u7EED\u9884\u8BA2, \u4F46\u60A8\u5FC5\u987B\u652F\u4ED8\u6D4B\u8BD5\u7684\u5168\u4EF7\u3002";
var APPB2C_common_bookTest_noDiscount_title5 = "\u8BF7\u6CE8\u610F";
var APPB2C_common_bookTest_results_book5 = "\u4E66\u7C4D\u6D4B\u8BD5";
var APPB2C_common_bookTest_results_bookFor5 = "\u9884\u8BA2";
var APPB2C_common_bookTest_results_cd5 = "\u96C5\u601D\u673A\u8003";
var APPB2C_common_bookTest_results_changeSpeaking5 = "\u66F4\u6539\u6F14\u8BB2\u65F6\u95F4\u8868";
var APPB2C_common_bookTest_results_clearFilters5 = "\u6E05\u9664\u8FC7\u6EE4\u5668\u5E76\u663E\u793A\u6240\u6709\u6D4B\u8BD5";
var APPB2C_common_bookTest_results_fromCurrentLocation5 = "\u8DDD\u5F53\u524D\u4F4D\u7F6E\u516C\u91CC";
var APPB2C_common_bookTest_results_lrw5 = "\u542C\u529B\u3001\u9605\u8BFB\u548C\u5199\u4F5C";
var APPB2C_common_bookTest_results_noResultsGoBack5 = "\u6211\u4EEC\u627E\u4E0D\u5230\u4EFB\u4F55\u6D4B\u8BD5, \u8BF7<1>\u8FD4\u56DE</1>\u5E76\u5C1D\u8BD5\u66F4\u6539\u60A8\u7684\u4F4D\u7F6E\u6216\u65E5\u671F";
var APPB2C_common_bookTest_results_noResultsText5 = "\u6211\u4EEC\u65E0\u6CD5\u627E\u5230\u4EFB\u4F55\u6EE1\u8DB3\u60A8\u9700\u6C42\u7684\u6D4B\u8BD5";
var APPB2C_common_bookTest_results_noResultsTitle5 = "\u4E0D\u597D\u610F\u601D";
var APPB2C_common_bookTest_results_pb5 = "\u96C5\u601D\u7EB8\u7B14\u8003\u8BD5";
var APPB2C_common_bookTest_results_review5 = "\u548C\u5BA1\u67E5";
var APPB2C_common_bookTest_results_searchText5 = "\u6211\u4EEC\u6B63\u5728\u5BFB\u627E\u60A8\u7684\u8003\u8BD5...";
var APPB2C_common_bookTest_results_speaking5 = "\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_bookTest_results_viewOnMap5 = "\u5728\u5730\u56FE\u4E0A\u67E5\u770B";
var APPB2C_common_bookTest_sortBy_date5 = "\u65E5\u671F";
var APPB2C_common_bookTest_sortBy_distance5 = "\u8DDD\u79BB";
var APPB2C_common_bookTest_sortBy_price5 = "\u4EF7\u683C";
var APPB2C_common_bookTest_sortBy_title5 = "\u6392\u5E8F\u65B9\u5F0F";
var APPB2C_common_bookTest_title5 = "\u9884\u7EA6\u6D4B\u8BD5";
var APPB2C_common_cancelBooking_alreadyRequested_cta5 = "\u8FD4\u56DE\u6211\u7684\u6D4B\u8BD5\u9884\u8BA2";
var APPB2C_common_cancelBooking_alreadyRequested_title5 = "\u60A8\u5DF2\u7ECF\u8BF7\u6C42\u4E86\u6B64\u670D\u52A1\u3002";
var APPB2C_common_cancelBooking_back5 = "\u6211\u4E0D\u60F3\u53D6\u6D88\u9884\u8BA2";
var APPB2C_common_cancelBooking_bankDetails_form_accountHolder5 = "\u94F6\u884C\u8D26\u6237\u6301\u6709\u4EBA\u5730\u5740";
var APPB2C_common_cancelBooking_bankDetails_form_bankAddress5 = "\u94F6\u884C\u5206\u884C\u5730\u5740";
var APPB2C_common_cancelBooking_bankDetails_form_bankName5 = "\u94F6\u884C\u540D\u79F0";
var APPB2C_common_cancelBooking_bankDetails_form_bankNumber5 = "\u94F6\u884C\u5E10\u53F7";
var APPB2C_common_cancelBooking_bankDetails_form_changeCountry5 = "\u66F4\u6539\u56FD\u5BB6/\u5730\u533A";
var APPB2C_common_cancelBooking_bankDetails_form_city5 = "\u57CE\u9547 / \u57CE\u5E02";
var APPB2C_common_cancelBooking_bankDetails_form_country5 = "\u56FD\u5BB6/\u5730\u533A";
var APPB2C_common_cancelBooking_bankDetails_form_holderEmail5 = "\u8D26\u6237\u6301\u6709\u4EBA\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_common_cancelBooking_bankDetails_form_holderName5 = "\u8D26\u6237\u6301\u6709\u4EBA\u59D3\u540D";
var APPB2C_common_cancelBooking_bankDetails_form_iban5 = "IBAN \u4EE3\u7801";
var APPB2C_common_cancelBooking_bankDetails_form_invalidIban5 = "IBAN \u4EE3\u7801\u4E0D\u6B63\u786E\u3002";
var APPB2C_common_cancelBooking_bankDetails_form_invalidSwift5 = "\u4E0D\u6B63\u786E\u7684 SWIFT \u4EE3\u7801\u3002";
var APPB2C_common_cancelBooking_bankDetails_form_postcode5 = "\u90AE\u653F\u7F16\u7801 / \u90AE\u7F16";
var APPB2C_common_cancelBooking_bankDetails_form_sortCode5 = "\u6392\u5E8F\u4EE3\u7801";
var APPB2C_common_cancelBooking_bankDetails_form_swift5 = "\u8FC5\u6377\u4EE3\u7801";
var APPB2C_common_cancelBooking_bankDetails_note5 = "\u5982\u679C\u60A8\u7684\u53D6\u6D88\u8BF7\u6C42\u6210\u529F, \u8BF7\u63D0\u4F9B\u60A8\u5E0C\u671B\u9000\u6B3E\u7684\u94F6\u884C\u5E10\u6237\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_common_cancelBooking_bankDetails_privacy5 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u5C06\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F\u6765\u5904\u7406\u60A8\u7684\u8BF7\u6C42\u3002\u6709\u5173\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7F51\u7AD9\u7684<0>\u9690\u79C1\u90E8\u5206.</0>";
var APPB2C_common_cancelBooking_bankDetails_terms_consent5 = "\u6211\u5728\u6B64\u540C\u610F\u901A\u8FC7\u539F\u59CB\u4ED8\u6B3E\u65B9\u5F0F\u7684\u66FF\u4EE3\u65B9\u5F0F\u63D0\u4F9B\u9000\u6B3E, \u5E76\u5C06\u9000\u6B3E\u5904\u7406\u5230\u4E0A\u9762\u63D0\u4F9B\u7684\u94F6\u884C\u5E10\u6237\u8BE6\u7EC6\u4FE1\u606F\u3002\u6211\u4E86\u89E3, \u5982\u679C\u6211\u63D0\u4F9B\u7684\u8D26\u6237\u8BE6\u7EC6\u4FE1\u606F\u4E0D\u51C6\u786E, \u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u4E0D\u8D1F\u8D23\u8F6C\u8D26\u5230\u9519\u8BEF\u7684\u94F6\u884C\u8D26\u6237\u3002";
var APPB2C_common_cancelBooking_bankDetails_terms_title5 = "\u6211\u4EEC\u7684\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_cancelBooking_bankDetails_title5 = "\u94F6\u884C\u8D26\u6237\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_cancelBooking_bottomNotes_note15 = "\u4E00\u65E6\u6211\u4EEC\u6536\u5230\u60A8\u7684\u53D6\u6D88\u8BF7\u6C42, \u5982\u679C\u9700\u8981\u4EFB\u4F55\u5176\u4ED6\u4FE1\u606F, \u6211\u4EEC\u5C06\u76F4\u63A5\u4E0E\u60A8\u8054\u7CFB\u3002";
var APPB2C_common_cancelBooking_bottomNotes_note25 = "\u9000\u6B3E\u987B\u7ECF\u6279\u51C6, \u60A8\u5C06\u5728 1 \u5468\u5185\u6536\u5230\u63A5\u53D7/\u62D2\u7EDD\u60A8\u7684\u8BF7\u6C42\u7684\u786E\u8BA4\u3002\u60A8\u7684\u9000\u6B3E\u53EF\u80FD\u4F1A\u4ECE\u4E2D\u6263\u9664\u7BA1\u7406\u8D39;\u8BF7\u9605\u8BFB <0>\u96C5\u601D\u8003\u8BD5\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_cancelBooking_bottomNotes_note35 = "\u5982\u679C\u83B7\u5F97\u6279\u51C6, \u60A8\u7684\u9000\u6B3E\u7533\u8BF7\u5C06\u5728\u63D0\u4EA4\u540E\u7684 3 \u5468\u5185\u5904\u7406\u3002";
var APPB2C_common_cancelBooking_bottomNotes_terms5 = "\u5728\u63D0\u4EA4\u7533\u8BF7\u4E4B\u524D, \u8BF7\u4ED4\u7EC6\u9605\u8BFB<0>\u96C5\u601D\u8003\u8BD5\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>\u3002";
var APPB2C_common_cancelBooking_cancelNotes_15 = "\u53D6\u6D88\u7533\u8BF7\u987B\u7ECF\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u6279\u51C6\u3002";
var APPB2C_common_cancelBooking_cancelNotes_25 = "\u60A8\u7684\u9000\u6B3E\u53EF\u80FD\u4F1A\u4ECE\u4E2D\u6263\u9664\u7BA1\u7406\u8D39\u3002";
var APPB2C_common_cancelBooking_cancelNotes_35 = "\u5728\u63D0\u4EA4\u7533\u8BF7\u4E4B\u524D, \u8BF7\u4ED4\u7EC6\u9605\u8BFB<0>\u96C5\u601D\u8003\u8BD5\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_cancelBooking_checkTest5 = "\u8BF7\u68C0\u67E5\u8FD9\u662F\u60A8\u8981\u53D6\u6D88\u7684\u6D4B\u8BD5: ";
var APPB2C_common_cancelBooking_cta_addBankDetails5 = "\u6DFB\u52A0\u60A8\u7684\u94F6\u884C\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_cancelBooking_cta_goBack5 = "\u8FD4\u56DE\u7F16\u8F91\u53D6\u6D88\u539F\u56E0";
var APPB2C_common_cancelBooking_cta_submit5 = "\u63D0\u4EA4\u53D6\u6D88\u8BF7\u6C42";
var APPB2C_common_cancelBooking_error_cancelledAlready5 = "\u6B64\u9884\u8BA2\u5DF2\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_cancelBooking_error_missingFile5 = "\u8BF7\u4E0A\u4F20\u8BC1\u660E\u60A8\u53D6\u6D88\u539F\u56E0\u7684\u8BC1\u636E\u3002";
var APPB2C_common_cancelBooking_error_requestedAlready5 = "\u6B64\u9884\u8BA2\u5DF2\u63D0\u51FA\u53D6\u6D88\u8BF7\u6C42\u3002";
var APPB2C_common_cancelBooking_reason_form_choose5 = "\u8BF7\u9009\u62E9\u4E00\u4E2A\u9009\u9879";
var APPB2C_common_cancelBooking_reason_form_freeform5 = "\u8BF7\u5728\u4E0B\u9762\u6DFB\u52A0\u66F4\u591A\u8BE6\u7EC6\u4FE1\u606F: ";
var APPB2C_common_cancelBooking_reason_form_optional5 = "(\u53EF\u9009)";
var APPB2C_common_cancelBooking_reason_form_requiredEvidence5 = "\u9700\u8981\u8BC1\u636E\u3002";
var APPB2C_common_cancelBooking_reason_form_requiredReason5 = "\u8BF7\u9009\u62E9\u53D6\u6D88\u9884\u8BA2\u7684\u539F\u56E0\u3002";
var APPB2C_common_cancelBooking_reason_form_title5 = "\u4E3A\u4EC0\u4E48\u9700\u8981\u53D6\u6D88\u6B64\u6D4B\u8BD5\uFF1F";
var APPB2C_common_cancelBooking_reason_note_content5 = "\u7531\u4E8E\u60A8\u7684\u8003\u8BD5\u65E5\u671F\u4E0D\u5230 5 \u5468, \u8BF7\u63D0\u4F9B\u53D6\u6D88\u8003\u8BD5\u7684\u539F\u56E0\u5E76\u9644\u4E0A\u76F8\u5173\u6587\u4EF6/\u8BC1\u636E\u3002";
var APPB2C_common_cancelBooking_reason_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_cancelBooking_subtitle5 = "\u9700\u8981\u53D6\u6D88\u6D4B\u8BD5\uFF1F";
var APPB2C_common_cancelBooking_title5 = "\u53D6\u6D88\u60A8\u7684\u9884\u8BA2";
var APPB2C_common_cancelBooking_transferNote_cta5 = "\u8BF7\u6C42\u8F6C\u79FB";
var APPB2C_common_cancelBooking_transferNote_title5 = "\u60A8\u662F\u5426\u77E5\u9053\u60A8\u53EF\u4EE5\u5C06\u9884\u8BA2\u8F6C\u79FB\u5230\u5176\u4ED6\u65E5\u671F\u6216\u5730\u70B9\uFF1F";
var APPB2C_common_cancelBooking_transferNote_titleIol5 = "\u60A8\u77E5\u9053\u60A8\u53EF\u4EE5\u5C06\u9884\u8BA2\u8F6C\u79FB\u5230\u5176\u4ED6\u65E5\u671F\u5417\uFF1F";
var APPB2C_common_cancelBooking_upload_mobile5 = "\u7531\u4E8E\u60A8\u770B\u8D77\u6765\u50CF\u662F\u5728\u79FB\u52A8\u8BBE\u5907\u4E0A, \u6211\u4EEC\u5EFA\u8BAE\u60A8\u4ECE\u8BBE\u5907\u4E0A\u4F20\u7167\u7247\u3002";
var APPB2C_common_cancelBooking_upload_notes_15 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u7531\u4E13\u4E1A\u533B\u751F\u7B7E\u53D1\u7684\u533B\u7597\u8BC1\u660E\u526F\u672C\u3002\u533B\u7597\u8BC1\u660E\u5FC5\u987B\u5305\u62EC\u75BE\u75C5\u6027\u8D28\u548C\u5176\u4ED6\u76F8\u5173\u4FE1\u606F, \u8FD9\u5C06\u6709\u52A9\u4E8E\u5BF9\u6B64\u7533\u8BF7\u8FDB\u884C\u4EFB\u4F55\u8BC4\u4F30, \u4EE5\u4F9B\u7279\u522B\u8003\u8651\u3002";
var APPB2C_common_cancelBooking_upload_notes_105 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u7531\u4E8E\u60A8\u8BA4\u4E3A\u6709\u6548\u7684\u7279\u5B9A\u539F\u56E0\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_25 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u56E0\u670D\u5175\u5F79\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_35 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u56E0\u5BB6\u4EBA\u4E27\u4EB2\u4E4B\u75DB\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_45 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u7531\u4E8E\u91CD\u590D\u9884\u8BA2\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_55 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u56E0\u5B98\u65B9\u627F\u8BFA\u6216\u51FA\u4E8E\u5B98\u65B9\u76EE\u7684\u65C5\u884C\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_65 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u7531\u4E8E\u60A8\u65E0\u6CD5\u63A7\u5236\u7684\u4E8B\u4EF6\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_75 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u5728\u8003\u8BD5\u5F53\u5929\u7531\u4E8E\u6280\u672F\u95EE\u9898\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_notes_85 = "\u60A8\u5FC5\u987B\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u526F\u672C, \u8BC1\u660E\u60A8\u56E0\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u53D6\u6D88\u8003\u8BD5\u800C\u65E0\u6CD5\u53C2\u52A0\u8003\u8BD5\u3002";
var APPB2C_common_cancelBooking_upload_requirements_note5 = "\u60A8\u4E0A\u4F20\u7684\u8BC1\u636E\u5E94\u8BE5\u6613\u4E8E\u9605\u8BFB\u3002\u60A8\u6700\u591A\u53EF\u4EE5\u6DFB\u52A0 4 \u4E2A\u6587\u4EF6\u3002";
var APPB2C_common_cancelBooking_upload_requirements_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_cancelBooking_upload_title5 = "\u4E0A\u4F20\u8BC1\u636E";
var APPB2C_common_changeBooking_cancel_cta5 = "\u8BF7\u6C42\u53D6\u6D88";
var APPB2C_common_changeBooking_cancel_text5 = "\u6211\u9700\u8981\u53D6\u6D88\u6D4B\u8BD5: ";
var APPB2C_common_changeBooking_goBack5 = "\u6211\u4E0D\u60F3\u66F4\u6539\u6211\u7684\u9884\u8BA2";
var APPB2C_common_changeBooking_subtitle5 = "\u60A8\u60F3\u5BF9\u6B64\u9884\u8BA2\u8FDB\u884C\u54EA\u4E9B\u66F4\u6539\uFF1F";
var APPB2C_common_changeBooking_title5 = "\u66F4\u6539\u9884\u8BA2";
var APPB2C_common_changeBooking_transfer_cta5 = "\u8BF7\u6C42\u8F6C\u79FB";
var APPB2C_common_changeBooking_transfer_text5 = "\u6211\u9700\u8981\u66F4\u6539\u6D4B\u8BD5\u7684\u65E5\u671F\u3001\u65F6\u95F4\u6216\u5730\u70B9: ";
var APPB2C_common_changeBooking_transfer_textIol5 = "\u6211\u9700\u8981\u5C06\u6D4B\u8BD5\u8F6C\u79FB\u5230\u5176\u4ED6\u65E5\u671F: ";
var APPB2C_common_changeBooking_transferNotes_15 = "\u8F6C\u5B66\u548C\u53D6\u6D88\u7533\u8BF7\u987B\u7ECF\u8003\u8BD5\u4E2D\u5FC3\u6279\u51C6\u3002";
var APPB2C_common_changeBooking_transferNotes_25 = "\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u56DE\u590D\u6240\u6709\u8BF7\u6C42";
var APPB2C_common_changeBooking_transferNotesIol_15 = "\u63A5\u9001\u548C\u53D6\u6D88\u987B\u7ECF\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u6279\u51C6\u3002";
var APPB2C_common_changeBooking_transferNotesIol_25 = "\u5728\u63D0\u4EA4\u7533\u8BF7\u4E4B\u524D, \u8BF7\u4ED4\u7EC6\u9605\u8BFB<0>\u96C5\u601D\u8003\u8BD5\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_changeBookingAck_back5 = "\u8FD4\u56DE\u6211\u7684\u6D4B\u8BD5\u9884\u8BA2";
var APPB2C_common_changeBookingAck_cancel_banner5 = "\u6211\u4EEC\u5DF2\u6536\u5230\u60A8\u53D6\u6D88\u6D4B\u8BD5\u7684\u8BF7\u6C42\u3002";
var APPB2C_common_changeBookingAck_cancel_contact_email5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_common_changeBookingAck_cancel_contact_phone5 = "\u7535\u8BDD";
var APPB2C_common_changeBookingAck_cancel_contact_title5 = "\u5982\u6709\u4EFB\u4F55\u7591\u95EE, \u8BF7\u8054\u7CFB: ";
var APPB2C_common_changeBookingAck_cancel_contact_titleIol5 = "\u5982\u679C\u60A8\u6709\u4EFB\u4F55\u5176\u4ED6\u7591\u95EE, \u8BF7<0>\u67E5\u770B\u6211\u4EEC\u7684\u5E2E\u52A9\u9875\u9762</0>\u3002";
var APPB2C_common_changeBookingAck_cancel_refNr5 = "\u56E0\u6B64, \u6211\u4EEC\u53EF\u4EE5\u66F4\u5FEB\u5730\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9, \u8BF7\u5F15\u7528\u60A8\u7684\u96C5\u601D\u53C2\u8003\u7F16\u53F7: ";
var APPB2C_common_changeBookingAck_cancel_refunds5 = "\u9000\u6B3E\u987B\u7ECF\u6279\u51C6, \u60A8\u5C06\u5728 1 \u5468\u5185\u6536\u5230\u63A5\u53D7/\u62D2\u7EDD\u60A8\u7684\u8BF7\u6C42\u7684\u786E\u8BA4\u3002";
var APPB2C_common_changeBookingAck_cancel_respondTime5 = "\u60A8\u7684\u9000\u6B3E\u91D1\u989D\u6700\u591A\u9700\u8981 3 \u5468\u65F6\u95F4\u9000\u8FD8\u5230\u60A8\u7684\u5361\u4E2D\u6216\u8BB0\u5165\u60A8\u7684\u94F6\u884C\u8D26\u6237\u3002";
var APPB2C_common_changeBookingAck_cancel_terms5 = "\u6709\u5173\u6211\u4EEC\u7684\u8F6C\u5B66\u653F\u7B56\u7684\u5B8C\u6574\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7684<0>\u96C5\u601D\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_changeBookingAck_cancel_title5 = "\u53D6\u6D88\u8BF7\u6C42\u786E\u8BA4";
var APPB2C_common_changeBookingAck_fees5 = "\u53EF\u80FD\u4F1A\u6536\u53D6\u8D39\u7528\u3002\u6211\u4EEC\u5C06\u5728\u76F4\u63A5\u4E0E\u60A8\u8054\u7CFB\u65F6\u786E\u8BA4\u3002";
var APPB2C_common_changeBookingAck_feesIol5 = "\u53EF\u80FD\u4F1A\u6536\u53D6\u884C\u653F\u8D39\u7528\u3002\u5982\u679C\u8BF7\u6C42\u83B7\u5F97\u6279\u51C6, \u6211\u4EEC\u5C06\u5411\u60A8\u53D1\u9001\u4ED8\u6B3E\u8BF4\u660E\u3002";
var APPB2C_common_changeBookingAck_speaking_banner5 = "\u60A8\u7684\u53E3\u8BED\u8003\u8BD5\u5DF2\u6210\u529F\u8F6C\u79FB\u3002";
var APPB2C_common_changeBookingAck_speaking_note_line15 = "\u66F4\u6539\u53E3\u8BED\u6D4B\u8BD5\u4E0D\u4F1A\u5F71\u54CD\u60A8\u7684\u7B14\u8BD5\u3002";
var APPB2C_common_changeBookingAck_speaking_note_line25 = "\u66F4\u6539\u53E3\u8BED\u6D4B\u8BD5\u4E0D\u6536\u53D6\u4EFB\u4F55\u8D39\u7528\u3002";
var APPB2C_common_changeBookingAck_speaking_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_changeBookingAck_speaking_title5 = "\u53E3\u8BED\u8003\u8BD5\u8F6C\u5B66";
var APPB2C_common_changeBookingAck_terms5 = "\u6709\u5173\u6211\u4EEC\u7684\u8F6C\u8BA9\u653F\u7B56\u7684\u5B8C\u6574\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7684<0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_common_changeBookingAck_transfer_banner5 = "\u60A8\u5DF2\u63D0\u4EA4\u4E0E\u8003\u8BD5\u4E2D\u5FC3\u67D0\u4EBA\u8BA8\u8BBA\u8F6C\u79FB\u8003\u8BD5\u7684\u8BF7\u6C42\u3002";
var APPB2C_common_changeBookingAck_transfer_note5 = "\u6211\u4EEC\u5C06\u76F4\u63A5\u4E0E\u60A8\u8054\u7CFB\u4EE5\u5B89\u6392\u65B0\u7684\u8003\u8BD5\u65E5\u671F\u3002\u4E00\u65E6\u60A8\u786E\u8BA4\u60A8\u5BF9\u65B0\u65E5\u671F\u611F\u5230\u6EE1\u610F, \u6211\u4EEC\u5C06\u5411\u60A8\u53D1\u9001\u66F4\u65B0\u7684\u6D4B\u8BD5\u9884\u8BA2\u7684\u786E\u8BA4\u3002";
var APPB2C_common_changeBookingAck_transfer_respondTime5 = "\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u56DE\u590D\u6240\u6709\u8BF7\u6C42\u3002";
var APPB2C_common_changeBookingAck_transfer_title5 = "\u8F6C\u79FB\u8BF7\u6C42\u786E\u8BA4";
var APPB2C_common_changeBookingAck_transferIol_banner5 = "\u6211\u4EEC\u5DF2\u6536\u5230\u60A8\u8F6C\u79FB\u6D4B\u8BD5\u7684\u8BF7\u6C42\u3002";
var APPB2C_common_changeBookingAck_transferIol_contact5 = "\u5982\u679C\u60A8\u6709\u4EFB\u4F55\u5176\u4ED6\u7591\u95EE, \u8BF7<0>\u67E5\u770B\u6211\u4EEC\u7684\u5E2E\u52A9\u9875\u9762</0>\u3002";
var APPB2C_common_changeBookingAck_transferIol_note5 = "\u6211\u4EEC\u5C06\u76F4\u63A5\u4E0E\u60A8\u8054\u7CFB\u4EE5\u5B89\u6392\u65B0\u7684\u8003\u8BD5\u65E5\u671F\u3002\u4E00\u65E6\u60A8\u786E\u8BA4\u60A8\u5BF9\u65B0\u65E5\u671F\u611F\u5230\u6EE1\u610F, \u6211\u4EEC\u5C06\u5411\u60A8\u53D1\u9001\u66F4\u65B0\u7684\u6D4B\u8BD5\u9884\u8BA2\u7684\u786E\u8BA4\u3002";
var APPB2C_common_changeBookingAck_transferIol_refNr5 = "\u56E0\u6B64, \u6211\u4EEC\u53EF\u4EE5\u66F4\u5FEB\u5730\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9, \u8BF7\u5F15\u7528\u60A8\u7684\u96C5\u601D\u53C2\u8003\u7F16\u53F7: ";
var APPB2C_common_changeBookingAck_transferIol_terms5 = "\u6709\u5173\u6211\u4EEC\u7684\u8F6C\u5B66\u653F\u7B56\u7684\u5B8C\u6574\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7684<0>\u96C5\u601D\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_changeSpeaking_cards_change5 = "\u53D8\u66F4\u6D4B\u8BD5";
var APPB2C_common_changeSpeaking_cards_noSlots5 = "\u6CA1\u6709\u53EF\u7528\u7684\u7A7A\u4F4D";
var APPB2C_common_changeSpeaking_cards_noSlots_error5 = "\u6240\u9009\u65F6\u95F4\u7684\u53D1\u8A00\u80FD\u529B\u5DF2\u7528\u5C3D\u3002\u5C1D\u8BD5\u9009\u62E9\u5176\u4ED6\u63D2\u69FD\u3002";
var APPB2C_common_changeSpeaking_cards_title5 = "\u60A8\u7684\u65B0\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_changeSpeaking_cta5 = "\u8F6C\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_changeSpeaking_modals_noSlots_keepTest5 = "\u7EE7\u7EED\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_changeSpeaking_modals_noSlots_line15 = "\u6211\u4EEC\u521A\u521A\u68C0\u67E5\u8FC7, \u6CA1\u6709\u5176\u4ED6\u53E3\u8BED\u6D4B\u8BD5\u53EF\u7528, \u5BF9\u4E0D\u8D77\uFF01";
var APPB2C_common_changeSpeaking_modals_noSlots_line25 = "\u5982\u679C\u60A8\u786E\u5B9E\u9700\u8981\u66F4\u6539\u53E3\u8BED\u8003\u8BD5, \u8BF7\u8BF7\u6C42\u66F4\u6539\u8003\u8BD5\u65E5\u671F, \u7136\u540E\u60A8\u53EF\u4EE5\u9009\u62E9\u65B0\u7684\u53E3\u8BED\u8003\u8BD5\u3002";
var APPB2C_common_changeSpeaking_modals_noSlots_title5 = "\u5BF9\u4E0D\u8D77 - \u6CA1\u6709\u53EF\u7528\u7684\u53E3\u8BED\u6D4B\u8BD5\uFF01";
var APPB2C_common_changeSpeaking_modals_noSlots_transferTest5 = "\u6539\u4E3A\u8BF7\u6C42\u8F6C\u79FB";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_cta5 = "\u9009\u62E9\u65B0\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line15 = "\u6211\u4EEC\u521A\u521A\u68C0\u67E5\u8FC7, \u60A8\u521A\u521A\u9009\u62E9\u7684\u53E3\u8BED\u6D4B\u8BD5\u4E0D\u53EF\u7528, \u5BF9\u4E0D\u8D77\uFF01";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line25 = "\u8BF7\u9009\u62E9\u5176\u4ED6\u53E3\u8BED\u6D4B\u8BD5\u3002";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_title5 = "\u6B64\u53E3\u8BED\u6D4B\u8BD5\u4E0D\u53EF\u7528\uFF01";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta5 = "\u7EE7\u7EED\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_line15 = "\u6211\u4EEC\u521A\u521A\u68C0\u67E5\u8FC7, \u6CA1\u6709\u5176\u4ED6\u53E3\u8BED\u6D4B\u8BD5\u53EF\u7528, \u5BF9\u4E0D\u8D77\uFF01";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_title5 = "\u5BF9\u4E0D\u8D77 - \u6CA1\u6709\u53EF\u7528\u7684\u53E3\u8BED\u6D4B\u8BD5\uFF01";
var APPB2C_common_changeSpeaking_note_back5 = "\u6211\u4E0D\u60F3\u66F4\u6539\u6211\u7684\u9884\u8BA2";
var APPB2C_common_changeSpeaking_note_line15 = "\u66F4\u6539\u53E3\u8BED\u6D4B\u8BD5\u4E0D\u4F1A\u5F71\u54CD\u60A8\u7684\u7B14\u8BD5\u3002";
var APPB2C_common_changeSpeaking_note_line25 = "\u66F4\u6539\u53E3\u8BED\u6D4B\u8BD5\u4E0D\u6536\u53D6\u4EFB\u4F55\u8D39\u7528\u3002";
var APPB2C_common_changeSpeaking_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_changeSpeaking_title5 = "\u66F4\u6539\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_chooseTest_difference5 = "\u8003\u8BD5\u4E2D\u5FC3\u96C5\u601D\u8003\u8BD5\u548C\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u6709\u4EC0\u4E48\u533A\u522B";
var APPB2C_common_chooseTest_iol_consent5 = "\u6211\u786E\u8BA4\u6211\u5F88\u9AD8\u5174\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u80FD\u591F\u4F7F\u7528\u9762\u90E8\u8BC6\u522B\u548C\u5176\u4ED6\u76D1\u8003\u6280\u672F\u5728\u8003\u8BD5\u671F\u95F4\u5B89\u5168\u5730\u76D1\u63A7\u6211\u3002";
var APPB2C_common_chooseTest_iol_cta5 = "\u5728\u7EBF\u9884\u8BA2\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_chooseTest_iol_desc5 = "\u968F\u65F6\u968F\u5730\u4EE5\u65B0\u7684\u5B89\u5168\u65B9\u5F0F\u8FDB\u884C\u6B64\u6D4B\u8BD5\u3002 \u5B83\u5C06\u5728\u7EBF\u6D4B\u8BD5\u7684\u4F18\u52BF\u4E0E\u89C6\u9891\u76D1\u63A7\u76F8\u7ED3\u5408, \u4EE5\u786E\u4FDD\u5B89\u5168\u7684\u4F53\u9A8C\u548C\u53EF\u9760\u7684\u7ED3\u679C\u3002";
var APPB2C_common_chooseTest_iol_link5 = "\u4E86\u89E3\u6709\u5173\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u8F6F\u4EF6\u7684\u66F4\u591A\u4FE1\u606F";
var APPB2C_common_chooseTest_iol_note_content5 = "\u60A8\u76EE\u524D\u5FC5\u987B\u5E74\u6EE1 18 \u5C81\u624D\u80FD\u53C2\u52A0\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u3002\u5982\u679C\u60A8\u4E3A18\u5C81\u4EE5\u4E0B\u7684\u4EBA\u9884\u8BA2\u8003\u8BD5, \u8BF7\u5728\u8003\u8BD5\u4E2D\u5FC3\u9884\u8BA2\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_chooseTest_iol_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_chooseTest_iol_title5 = "\u96C5\u601D\u5728\u7EBF\u8003\u8BD5";
var APPB2C_common_chooseTest_ors_cta5 = "\u5728\u8003\u8BD5\u4E2D\u5FC3\u9884\u8BA2\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_chooseTest_ors_descAc5 = "\u5728\u6211\u4EEC\u7684\u5B98\u65B9\u8003\u8BD5\u4E2D\u5FC3\u53C2\u52A0\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5\u3002\u60A8\u53EF\u4EE5\u9009\u62E9\u5728\u7EB8\u7B14\u8003\u8BD5\u6216\u8BA1\u7B97\u673A\u4E0A\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_chooseTest_ors_descGt5 = "\u5728\u6211\u4EEC\u7684\u5B98\u65B9\u8003\u8BD5\u4E2D\u5FC3\u53C2\u52A0\u96C5\u601D\u57F9\u8BAD\u7C7B\u8003\u8BD5\u3002\u60A8\u53EF\u4EE5\u9009\u62E9\u5728\u7EB8\u7B14\u8003\u8BD5\u6216\u8BA1\u7B97\u673A\u4E0A\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_chooseTest_ors_title5 = "\u8003\u8BD5\u4E2D\u5FC3\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_chooseTest_title5 = "\u9009\u62E9\u60A8\u7684\u6D4B\u8BD5";
var APPB2C_common_chooseTest_ukvi_difference5 = "\u8003\u8BD5\u4E2D\u5FC3\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5\u548C\u7528\u4E8E UKVI \u7684\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u6709\u4EC0\u4E48\u533A\u522B";
var APPB2C_common_chooseTest_ukvi_inCentre_cta5 = "\u5728\u8003\u8BD5\u4E2D\u5FC3\u9884\u8BA2\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_chooseTest_ukvi_inCentre_descAc5 = "\u5728\u6211\u4EEC\u7684\u5B98\u65B9\u8003\u8BD5\u4E2D\u5FC3\u4E4B\u4E00\u53C2\u52A0\u7528\u4E8E UKVI \u5B66\u672F\u7C7B\u8003\u8BD5\u7684\u96C5\u601D\u8003\u8BD5\u3002\u60A8\u53EF\u4EE5\u9009\u62E9\u5728\u7EB8\u7B14\u8003\u8BD5\u6216\u8BA1\u7B97\u673A\u4E0A\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_chooseTest_ukvi_inCentre_descGt5 = "\u5728\u6211\u4EEC\u7684\u5B98\u65B9\u8003\u8BD5\u4E2D\u5FC3\u4E4B\u4E00\u53C2\u52A0\u7528\u4E8E UKVI \u57F9\u8BAD\u7C7B\u7684\u96C5\u601D\u8003\u8BD5\u3002\u60A8\u53EF\u4EE5\u9009\u62E9\u5728\u7EB8\u7B14\u8003\u8BD5\u6216\u8BA1\u7B97\u673A\u4E0A\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_chooseTest_ukvi_inCentre_title5 = "\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5\u5728\u8003\u8BD5\u4E2D\u5FC3";
var APPB2C_common_chooseTest_ukvi_online_consent5 = "\u6211\u786E\u8BA4\u6211\u5F88\u9AD8\u5174\u96C5\u601D\u7528\u4E8EUKVI\u5728\u7EBF\u8003\u8BD5, \u80FD\u591F\u5728\u8003\u8BD5\u671F\u95F4\u4F7F\u7528\u9762\u90E8\u8BC6\u522B\u548C\u5176\u4ED6\u76D1\u8003\u6280\u672F\u5B89\u5168\u5730\u76D1\u63A7\u6211\u3002";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol5 = "(\u5728\u62A4\u7167\u4E0A\u5BFB\u627E\u8FD9\u4E2A\u7B26\u53F7\uFF01";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_title5 = "\u6211\u786E\u8BA4\u6211\u6709\u4E00\u672C\u5E26\u6709RFID\u82AF\u7247\u7684\u6709\u6548\u751F\u7269\u8BC6\u522B\u62A4\u7167\u3002";
var APPB2C_common_chooseTest_ukvi_online_cta5 = "\u5728\u7EBF\u9884\u8BA2\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_chooseTest_ukvi_online_link5 = "\u4E86\u89E3\u6709\u5173\u7528\u4E8E UKVI \u7684\u96C5\u601D\u8003\u8BD5\u7684\u66F4\u591A\u4FE1\u606F \u5728\u7EBF\u8F6F\u4EF6";
var APPB2C_common_chooseTest_ukvi_online_note_content5 = "\u60A8\u76EE\u524D\u5FC5\u987B\u5E74\u6EE1 18 \u5C81\u624D\u80FD\u53C2\u52A0\u7528\u4E8E UKVI \u7684\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u3002\u5982\u679C\u60A8\u4E3A18\u5C81\u4EE5\u4E0B\u7684\u4EBA\u9884\u8BA2\u8003\u8BD5, \u8BF7\u5728\u8003\u8BD5\u4E2D\u5FC3\u9884\u8BA2\u7528\u4E8EUKVI\u7684\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_chooseTest_ukvi_online_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_common_chooseTest_ukvi_online_title5 = "\u7528\u4E8E UKVI \u5728\u7EBF\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_draft_cancel4 = "\u53D6\u6D88";
var APPB2C_common_draft_cta_confirm5 = "\u786E\u8BA4\u6211\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_draft_cta_decline5 = "\u4E0D\u8981\u4E0E\u6211\u7684\u4EE3\u7406\u786E\u8BA4\u6211\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_draft_cta_note5 = "\u5982\u679C\u60A8\u4E0D\u786E\u8BA4\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F, {{\u4EE3\u7406}} \u5C06\u65E0\u6CD5\u4E3A\u60A8\u6CE8\u518C\u6D4B\u8BD5\u3002\u60A8\u4ECD\u7136\u53EF\u4EE5\u4E3A\u81EA\u5DF1\u9884\u8BA2\u6D4B\u8BD5";
var APPB2C_common_draft_difference5 = "\u60A8\u7684\u4EE3\u7406\u6DFB\u52A0\u4E86\u8FD9\u4E2A\u4E0D\u540C\u7684\u7B54\u6848\u3002";
var APPB2C_common_draft_edit5 = "\u6539\u53D8";
var APPB2C_common_draft_error_pastDeadline5 = "\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F\u786E\u8BA4\u622A\u6B62\u65E5\u671F\u5DF2\u8FC7\u3002";
var APPB2C_common_draft_id_cta5 = "\u66F4\u65B0\u6807\u8BC6";
var APPB2C_common_draft_id_documentWithNoExpiryDate5 = "\u6587\u6863\u6CA1\u6709\u5230\u671F\u65E5\u671F";
var APPB2C_common_draft_id_header5 = "\u60A8\u7684\u8EAB\u4EFD\u8BC1\u660E";
var APPB2C_common_draft_id_issuingAuthority5 = "\u53D1\u8BC1\u673A\u5173";
var APPB2C_common_draft_id_note5 = "\u5728\u8003\u8BD5\u5F53\u5929, \u60A8\u5C06\u9700\u8981\u643A\u5E26\u60A8\u5728\u4E0B\u9762\u63D0\u4F9B\u7684\u76F8\u540C\u8EAB\u4EFD\u8BC1\u660E\u3002";
var APPB2C_common_draft_id_title5 = "\u68C0\u67E5\u60A8\u7684\u8EAB\u4EFD\u8BC1\u4EF6\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_draft_note_line15 = "\u81EA\u60A8\u4E0A\u6B21\u8BBF\u95EE\u672C\u7F51\u7AD9\u4EE5\u6765, \u60A8\u7684\u96C5\u601D\u8003\u8BD5\u4EE3\u7406({{agent}})\u5728\u4E3A\u60A8\u6CE8\u518C\u8003\u8BD5\u65F6\u4E3A\u60A8\u6DFB\u52A0\u4E86\u4E0D\u540C\u7684\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_common_draft_note_line25 = "\u8BF7\u5728\u4E0B\u9762\u67E5\u770B\u4ED6\u4EEC\u7684\u66F4\u6539, \u5982\u679C\u60A8\u53D1\u73B0\u4ED6\u4EEC\u72AF\u4E86\u9519\u8BEF, \u8BF7\u66F4\u65B0\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_common_draft_note_line35 = "\u5982\u679C\u60A8\u4E0D\u786E\u8BA4\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F, {{\u4EE3\u7406}} \u5C06\u65E0\u6CD5\u4E3A\u60A8\u6CE8\u518C\u6D4B\u8BD5\u3002";
var APPB2C_common_draft_personal_cta5 = "\u66F4\u65B0\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_draft_personal_header5 = "\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_draft_personal_note5 = "\u6B64\u9875\u9762\u4E0A\u63D0\u4F9B\u7684\u6240\u6709\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u5FC5\u987B\u4E0E\u7528\u4E8E\u6CE8\u518C\u5E76\u5728\u8003\u8BD5\u5730\u70B9\u51FA\u793A\u7684\u5E94\u8BD5\u8005\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u4E00\u81F4, \u56E0\u4E3A\u8FD9\u4E9B\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u5C06\u663E\u793A\u5728\u5E94\u8BD5\u8005\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u4E0A\u3002\u6CE8\u518C\u5B8C\u6210\u540E, \u5982\u679C\u60A8\u5728\u6211\u4EEC\u7B7E\u53D1\u6210\u7EE9\u5355\u4E4B\u524D\u8981\u6C42\u6211\u4EEC\u4FEE\u6539\u4EFB\u4F55\u6B64\u7C7B\u4FE1\u606F, \u60A8\u53EF\u80FD\u4F1A\u88AB\u6536\u53D6\u8D39\u7528\u3002\u8BF7\u6CE8\u610F, \u6210\u7EE9\u5355\u53D1\u51FA\u540E\u4E0D\u5F97\u8FDB\u884C\u4EFB\u4F55\u4FEE\u6539\u3002";
var APPB2C_common_draft_personal_title5 = "\u68C0\u67E5\u60A8\u7684\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_draft_title5 = "\u786E\u8BA4\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_examType_iol_ac5 = "\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5(\u5728\u7EBF)";
var APPB2C_common_examType_iol_gt5 = "\u96C5\u601D\u57F9\u8BAD\u7C7B\u8003\u8BD5(\u5728\u7EBF)";
var APPB2C_common_examType_ors_ac5 = "\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5";
var APPB2C_common_examType_ors_gt5 = "\u96C5\u601D\u57F9\u8BAD\u7C7B\u8003\u8BD5";
var APPB2C_common_examType_ors_osr_ac5 = "\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5";
var APPB2C_common_examType_ors_osr_gt5 = "\u96C5\u601D\u57F9\u8BAD\u7C7B\u8003\u8BD5";
var APPB2C_common_examType_ukvi_ac5 = "\u7528\u4E8E\u82F1\u56FD\u56FD\u9645\u6559\u80B2\u76EE\u6807\u7684\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5";
var APPB2C_common_examType_ukvi_gt5 = "\u7528\u4E8E UKVI \u57F9\u8BAD\u7C7B\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_examType_ukvi_lfska15 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5 A1";
var APPB2C_common_examType_ukvi_lfska25 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5 A2";
var APPB2C_common_examType_ukvi_lfskb15 = "\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5B1";
var APPB2C_common_examType_ukvi_osr_ac5 = "UKVI\u5B66\u672F\u7C7B\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_examType_ukvi_osr_gt5 = "UKVI \u57F9\u8BAD\u7C7B\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_examType_uol_ac5 = "\u7528\u4E8E UKVI(\u5B66\u672F\u7C7B)\u7684\u96C5\u601D\u8003\u8BD5\u5728\u7EBF\u8003\u8BD5";
var APPB2C_common_examType_uol_gt5 = "\u7528\u4E8E UKVI(\u57F9\u8BAD\u7C7B)\u7684\u96C5\u601D\u8003\u8BD5(\u666E\u901A\u57F9\u8BAD\u7C7B)\u5728\u7EBF\u8003\u8BD5";
var APPB2C_common_filePreview_label5 = "\u56FE\u50CF";
var APPB2C_common_filePreview_pdfPreviewLink5 = "\u9884\u89C8\u6B64\u6587\u4EF6";
var APPB2C_common_filePreview_status5 = "\u5BA1\u6838\u72B6\u6001";
var APPB2C_common_filePreview_statusName_approved5 = "\u6279\u51C6";
var APPB2C_common_filePreview_statusName_deleted5 = "\u5220\u9664";
var APPB2C_common_filePreview_statusName_pending5 = "\u5F85\u5B9A";
var APPB2C_common_filePreview_statusName_rejected5 = "\u62D2\u7EDD";
var APPB2C_common_filePreview_statusName_reviewed5 = "\u68C0\u8BA8";
var APPB2C_common_fileUpload_cta5 = "\u9009\u62E9\u4E00\u4E2A\u6587\u4EF6";
var APPB2C_common_fileUpload_exceededSize5 = "\u6B64\u6587\u4EF6\u8D85\u8FC7\u5141\u8BB8\u7684\u6700\u5927\u5927\u5C0F";
var APPB2C_common_fileUpload_fileApproved5 = "\u6B64\u6587\u4EF6\u5DF2\u6279\u51C6, \u65E0\u6CD5\u5220\u9664";
var APPB2C_common_fileUpload_fileOptional5 = "\u56FE\u50CF {{\u6570\u5B57}}(\u53EF\u9009)";
var APPB2C_common_fileUpload_fileRequired5 = "\u56FE\u7247 1";
var APPB2C_common_fileUpload_filesCount5 = "\u9875\u9762 {{\u5F53\u524D}} \u7684 {{\u603B\u8BA1}}";
var APPB2C_common_fileUpload_fileSize5 = "\u6587\u4EF6\u4E0D\u5927\u4E8E {{\u5927\u5C0F}} MB";
var APPB2C_common_fileUpload_imgAlt5 = "\u6587\u4EF6\u9884\u89C8";
var APPB2C_common_fileUpload_pdfIeMsg5 = "\u60A8\u7684 PDF \u6587\u4EF6\u5DF2\u6210\u529F\u4E0A\u4F20, \u4F46\u9884\u89C8\u5728 Internet Explorer \u6D4F\u89C8\u5668\u4E2D\u4E0D\u53EF\u7528\u3002\u60A8\u662F\u5426\u77E5\u9053\u81EA 2015 \u5E74\u4EE5\u6765\u4E0D\u518D\u79EF\u6781\u652F\u6301 IE\uFF1F";
var APPB2C_common_fileUpload_pdfPasswordMsg5 = "\u4E0D\u5141\u8BB8\u4E0A\u4F20\u5B89\u5168\u6587\u4EF6\u3002";
var APPB2C_common_fileUpload_remove5 = "\u5220\u9664\u6B64\u6587\u4EF6";
var APPB2C_common_fileUpload_requirements5 = "\u8BF7\u4EC5\u4E0A\u4F20: ";
var APPB2C_common_fileUpload_success5 = "\u6587\u4EF6 {<bold><italic>{\u6587\u4EF6\u540D}}</italic></bold> \u6DFB\u52A0\u6210\u529F\uFF01";
var APPB2C_common_findTest_changeCity5 = "\u66F4\u6539\u57CE\u5E02";
var APPB2C_common_findTest_changeCountry5 = "\u66F4\u6539\u56FD\u5BB6/\u5730\u533A";
var APPB2C_common_findTest_cta5 = "\u641C\u7D22\u6D4B\u8BD5";
var APPB2C_common_findTest_dates_clear5 = "\u6E05\u695A";
var APPB2C_common_findTest_dates_from5 = "\u4ECE";
var APPB2C_common_findTest_dates_noDatesSelected5 = "\u62B1\u6B49 \u2013 \u60A8\u9009\u62E9\u7684\u65E5\u671F\u6CA1\u6709\u53EF\u7528\u7684\u6D4B\u8BD5\u3002\u8BF7\u6269\u5927\u60A8\u7684\u65E5\u671F\u8303\u56F4";
var APPB2C_common_findTest_dates_noresults_details5 = "\u5BF9\u4E0D\u8D77;\u6CA1\u6709\u53EF\u7528\u7684\u8003\u8BD5\u65E5\u671F\u3002\u8BF7\u9009\u62E9\u5176\u4ED6\u4F4D\u7F6E\u6216\u66F4\u6539\u7B5B\u9009\u6761\u4EF6";
var APPB2C_common_findTest_dates_noresults_title5 = "\u672A\u627E\u5230\u8003\u8BD5\u65E5\u671F";
var APPB2C_common_findTest_dates_noresultsMobileLocation_cta5 = "\u6CE8\u518C\u60A8\u7684\u5174\u8DA3";
var APPB2C_common_findTest_dates_noresultsMobileLocation_details5 = "\u76EE\u524D\u6B64\u5730\u70B9\u6CA1\u6709\u8003\u8BD5\u65E5\u671F, \u4F46\u60A8\u53EF\u4EE5\u6CE8\u518C\u53C2\u52A0\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u7684\u96C5\u601D\u8003\u8BD5\u7684\u5174\u8DA3\u3002";
var APPB2C_common_findTest_dates_noresultsMobileLocation_title5 = "\u672A\u627E\u5230\u8003\u8BD5\u65E5\u671F";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_details5 = "\u7531\u4E8E\u60A8\u5DF2\u544A\u77E5\u6211\u4EEC\u9700\u8981\u5728\u8003\u8BD5\u65E5\u671F\u8FDB\u884C\u7279\u6B8A\u5B89\u6392, \u56E0\u6B64\u6211\u4EEC\u65E0\u6CD5\u5728\u60A8\u9009\u62E9\u7684\u5730\u70B9\u627E\u5230\u4EFB\u4F55\u8003\u8BD5\u65E5\u671F\u3002\u8BF7\u4F7F\u7528\u4EE5\u4E0B\u8BE6\u7EC6\u4FE1\u606F\u76F4\u63A5\u4E0E\u6211\u4EEC\u8054\u7CFB, \u4EE5\u4E86\u89E3\u6211\u4EEC\u5982\u4F55\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9\u3002";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_title5 = "\u5BF9\u4E0D\u8D77;\u6CA1\u6709\u53EF\u7528\u7684\u8003\u8BD5\u65E5\u671F";
var APPB2C_common_findTest_dates_pleaseSelect5 = "\u8BF7\u4F7F\u7528\u4E0B\u9762\u7684\u65E5\u5386";
var APPB2C_common_findTest_dates_selected5 = "\u9009\u62E9";
var APPB2C_common_findTest_dates_showAll5 = "\u663E\u793A\u6240\u6709\u65E5\u671F";
var APPB2C_common_findTest_dates_showDateRange5 = "\u8BA9\u6211\u9009\u62E9\u65E5\u671F";
var APPB2C_common_findTest_dates_title5 = "\u60A8\u60F3\u4EC0\u4E48\u65F6\u5019\u53C2\u52A0\u8003\u8BD5\uFF1F";
var APPB2C_common_findTest_dates_to5 = "\u81EA";
var APPB2C_common_findTest_examFormat_all5 = "\u6211\u4E0D\u4ECB\u610F";
var APPB2C_common_findTest_examFormat_cd5 = "\u96C5\u601D\u673A\u8003";
var APPB2C_common_findTest_examFormat_pb5 = "\u96C5\u601D\u7EB8\u7B14\u8003\u8BD5";
var APPB2C_common_findTest_examFormat_results5 = "(\u7ED3\u679C\u4EE5 {{\u5929}} \u5929\u4E3A\u5355\u4F4D)";
var APPB2C_common_findTest_examFormat_title5 = "\u60A8\u60F3\u53C2\u52A0\u54EA\u79CD\u5F62\u5F0F\u7684\u96C5\u601D\u8003\u8BD5\uFF1F";
var APPB2C_common_findTest_iol_noLrwResults5 = "\u5BF9\u4E0D\u8D77;\u60A8\u6240\u5728\u56FD\u5BB6/\u5730\u533A\u6CA1\u6709\u53EF\u7528\u7684\u8003\u8BD5\u65E5\u671F\u3002";
var APPB2C_common_findTest_iol_organisationCheck_check5 = "\u6211\u786E\u8BA4\u6211\u5DF2\u68C0\u67E5\u6211\u7684\u76EE\u6807\u7EC4\u7EC7\u662F\u5426\u63A5\u53D7\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u3002";
var APPB2C_common_findTest_iol_organisationCheck_desc5 = "\u5728\u60A8\u5411\u6211\u4EEC\u9884\u8BA2\u8003\u8BD5\u4E4B\u524D, \u60A8\u5FC5\u987B\u4E0E\u60A8\u7684\u7EC4\u7EC7\u6838\u5B9E\u4ED6\u4EEC\u662F\u5426\u63A5\u53D7\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u3002";
var APPB2C_common_findTest_iol_organisationCheck_title5 = "\u60A8\u7684\u76EE\u6807\u7EC4\u7EC7\u6216\u673A\u6784\u662F\u5426\u63A5\u53D7\u5728\u7EBF\u96C5\u601D\u8003\u8BD5\uFF1F";
var APPB2C_common_findTest_iol_softwareCheck_check5 = "\u6211\u786E\u8BA4\u6211\u5DF2\u68C0\u67E5\u8BBE\u5907\u548C\u73AF\u5883\u8981\u6C42\u4EE5\u53C2\u52A0\u6D4B\u8BD5";
var APPB2C_common_findTest_iol_softwareCheck_desc15 = "\u60A8\u7684\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u4F7F\u7528Inspera\u8F6F\u4EF6, \u60A8\u9700\u8981\u5728Windows PC\u6216MacOS\u4E0A\u4E0B\u8F7D\u8BE5\u8F6F\u4EF6(\u60A8\u4E0D\u80FD\u5728\u624B\u673A\u6216\u5E73\u677F\u7535\u8111\u4E0A\u53C2\u52A0\u8003\u8BD5\uFF01";
var APPB2C_common_findTest_iol_softwareCheck_desc25 = "\u5728\u60A8\u5411\u6211\u4EEC\u9884\u8BA2\u6D4B\u8BD5\u4E4B\u524D, \u60A8\u5FC5\u987B\u68C0\u67E5\u60A8\u7684\u8BA1\u7B97\u673A\u662F\u5426\u7B26\u5408\u5EFA\u8BAE\u7684\u7CFB\u7EDF\u8981\u6C42\u3002";
var APPB2C_common_findTest_iol_softwareCheck_link5 = "\u68C0\u67E5\u6D4B\u8BD5\u7684\u8BBE\u5907\u8981\u6C42";
var APPB2C_common_findTest_iol_softwareCheck_title5 = "\u60A8\u7684\u8BA1\u7B97\u673A\u53EF\u4EE5\u8FD0\u884C\u53C2\u52A0\u8003\u8BD5\u6240\u9700\u7684\u8F6F\u4EF6\u5417\uFF1F";
var APPB2C_common_findTest_langModal_choose5 = "\u60A8\u4E4B\u524D\u5728 {{\u8BED\u8A00\u540D\u79F0}} \u4E2D\u6D4F\u89C8\u6B64\u7F51\u7AD9\u3002{{\u56FD\u5BB6/\u5730\u533A\u540D\u79F0}} \u4E2D\u7684\u9884\u8BA2\u6D4B\u8BD5\u4E0D\u652F\u6301\u6B64\u8BED\u8A00\u3002\u8BF7\u9009\u62E9\u4EE5\u4E0B\u9009\u9879\u4E4B\u4E00: ";
var APPB2C_common_findTest_langModal_chooseSingle5 = "\u60A8\u4E4B\u524D\u5728 {{\u8BED\u8A00\u540D\u79F0}} \u4E2D\u6D4F\u89C8\u6B64\u7F51\u7AD9\u3002{{\u56FD\u5BB6/\u5730\u533A\u540D\u79F0}} \u4E2D\u7684\u9884\u8BA2\u6D4B\u8BD5\u4E0D\u652F\u6301\u6B64\u8BED\u8A00\u3002\u8BF7\u9009\u62E9\u4EE5\u4E0B\u8BED\u8A00: ";
var APPB2C_common_findTest_langModal_continue5 = "\u60A8\u73B0\u5728\u53EF\u4EE5\u4F7F\u7528\u4EE5\u4E0B\u8BED\u8A00\u4E4B\u4E00\u7EE7\u7EED\u9884\u8BA2: ";
var APPB2C_common_findTest_langModal_title5 = "\u60A8\u5DF2\u66F4\u6539\u6D4B\u8BD5\u7684\u4F4D\u7F6E";
var APPB2C_common_findTest_multipleLocations5 = "\u591A\u4E2A\u5730\u70B9";
var APPB2C_common_findTest_osrInfo5 = "\u96C5\u601D\u7EB8\u7B14\u7C7B\u8003\u8BD5\u4E0D\u63D0\u4F9B<bold>\u4E00\u6B21\u6280\u80FD\u91CD\u8003</bold>, \u8BF7\u8003\u8651\u9884\u8BA2\u96C5\u601D\u673A\u8003\u4EE5\u83B7\u5F97\u6B64\u9009\u9879\u3002";
var APPB2C_common_findTest_osrInfoLink5 = "\u67E5\u770B\u66F4\u591A about One Skill Retake";
var APPB2C_common_findTest_residencyConfirmation_btn5 = "\u77E5\u9053\u4E86, \u7EE7\u7EED\u641C\u7D22";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_btn5 = "\u66F4\u6539\u6D4B\u8BD5\u7C7B\u578B";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line15 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9009\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11, \u5219\u65E0\u6CD5\u9884\u8BA2\u7EB8\u7B14\u8003\u8BD5\u3002\u8BF7<0>\u9009\u62E9\u8BA1\u7B97\u673A\u8003\u8BD5\u6216\u9009\u62E9\u60A8\u5C45\u4F4F\u56FD\u7684\u7EB8\u7B14\u8003\u8BD5.</0>";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line25 = '\u5728"\u9884\u7EA6\u6D4B\u8BD5"\u9875\u9762\u4E0A, \u60A8\u53EF\u4EE5\u4F7F\u7528\u8FC7\u6EE4\u6761\u4EF6\u66F4\u6539\u6D4B\u8BD5\u7C7B\u578B\u3002\u60A8\u4E5F\u53EF\u4EE5\u8FD4\u56DE\u56FD\u5BB6/\u5730\u533A\u9009\u62E9\u9875\u9762\u3002';
var APPB2C_common_findTest_residencyConfirmation_errorMsg_title5 = "\u4F60\u4E0D\u80FD\u9884\u8BA2\u8FD9\u4E2A\u6D4B\u8BD5";
var APPB2C_common_findTest_residencyConfirmation_msg4 = "\u5982\u679C\u60A8\u6709\u5174\u8DA3\u53C2\u52A0 <0>\u96C5\u601D\u8003\u8BD5\u7EB8\u7B14</0> \u8003\u8BD5, \u6211\u4EEC\u5FC5\u987B\u786E\u8BA4\u60A8\u662F\u53C2\u52A0\u8003\u8BD5\u7684\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11\u3002\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9009\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11, \u8BF7<0>\u9009\u62E9\u8BA1\u7B97\u673A\u6D4B\u8BD5\u6216\u9009\u62E9\u60A8\u5C45\u4F4F\u56FD\u5BB6/\u5730\u533A\u7684\u7EB8\u7B14\u6D4B\u8BD5</0>\u5982\u679C\u60A8\u9009\u62E9\u4E0D\u6B63\u786E, \u60A8\u7684\u6D4B\u8BD5\u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_line15 = "\u5982\u679C\u60A8\u6709\u5174\u8DA3\u53C2\u52A0 <0>IELTS on Paper</0> \u8003\u8BD5, \u6211\u4EEC\u5FC5\u987B\u786E\u8BA4\u60A8\u662F\u53C2\u52A0\u8003\u8BD5\u6240\u5728\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_line25 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9009\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11, \u8BF7<0>\u9009\u62E9\u8BA1\u7B97\u673A\u6D4B\u8BD5\u6216\u9009\u62E9\u4F4D\u4E8E\u60A8\u5C45\u4F4F\u56FD\u5BB6/\u5730\u533A\u7684\u7EB8\u8D28\u6D4B\u8BD5</0>\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_line35 = "\u5982\u679C\u60A8\u9009\u62E9\u9519\u8BEF, \u60A8\u7684\u8003\u8BD5\u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine25 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9009\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11, \u8BF7<0>\u9009\u62E9\u4F4D\u4E8E\u60A8\u5C45\u4F4F\u56FD\u5BB6/\u5730\u533A\u7684\u6D4B\u8BD5</0>\u3002";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine25 = "\u5982\u679C\u60A8\u4E0D\u662F\u6240\u9009\u56FD\u5BB6/\u5730\u533A\u7684\u5C45\u6C11, \u8BF7<0>\u9009\u62E9\u8BA1\u7B97\u673A\u6D4B\u8BD5</0>(\u5728\u5176\u4ED6\u57CE\u5E02\u53EF\u7528)<0>\u6216\u9009\u62E9\u60A8\u5C45\u4F4F\u56FD\u5BB6/\u5730\u533A\u7684\u7EB8\u8D28\u6D4B\u8BD5</0>\u3002";
var APPB2C_common_findTest_residencyConfirmation_selectDates5 = "\u8BF7\u5148\u9009\u62E9\u65E5\u671F";
var APPB2C_common_findTest_residencyConfirmation_title5 = "\u91CD\u8981\uFF01";
var APPB2C_common_findTest_searchIn5 = "\u641C\u7D22\u4F4D\u7F6E: ";
var APPB2C_common_findTest_selectCountry_continue5 = "\u7EE7\u7EED\u9884\u8BA2";
var APPB2C_common_findTest_selectCountry_countryNotSupported5 = "\u8981\u5728\u6B64\u56FD\u5BB6/\u5730\u533A\u9884\u8BA2\u8003\u8BD5, \u60A8\u9700\u8981\u4F7F\u7528\u5176\u4ED6\u7F51\u7AD9\u3002";
var APPB2C_common_findTest_selectCountry_label5 = "\u9009\u62E9\u4E00\u4E2A\u56FD\u5BB6/\u5730\u533A";
var APPB2C_common_findTest_selectCountry_placeholder5 = "\u6309\u56FD\u5BB6/\u5730\u533A\u540D\u79F0\u641C\u7D22";
var APPB2C_common_findTest_selectLocation_closestToMe5 = "\u79BB\u6211\u6700\u8FD1";
var APPB2C_common_findTest_selectLocation_geolocationBlocked5 = "\u6211\u4EEC\u65E0\u6CD5\u83B7\u53D6\u60A8\u7684\u4F4D\u7F6E, \u8BF7\u786E\u4FDD\u6B64\u7F51\u7AD9\u7684\u4F4D\u7F6E\u6743\u9650\u672A\u88AB\u963B\u6B62\u3002";
var APPB2C_common_findTest_selectLocation_label5 = "\u641C\u7D22\u57CE\u5E02";
var APPB2C_common_findTest_selectLocation_noExamForThisCountry5 = "\u6211\u4EEC\u5728\u8FD9\u4E2A\u56FD\u5BB6/\u5730\u533A\u627E\u4E0D\u5230\u4EFB\u4F55\u6D4B\u8BD5\u3002\u8BF7<1>\u8054\u7CFB\u6211\u4EEC</1>\u770B\u770B\u6211\u4EEC\u662F\u5426\u53EF\u4EE5\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9\u3002";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation5 = "\u6211\u4EEC\u627E\u4E0D\u5230\u4EFB\u4F55\u5173\u4E8E\u8FD9\u4E2A\u7EC4\u7EC7\u7684\u6D4B\u8BD5\u3002\u9009\u62E9\u4EE5\u4E0B\u9009\u9879\u4E4B\u4E00: ";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta5 = "\u641C\u7D22\u53EF\u7528\u7684\u6D4B\u8BD5";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option15 = "\u9009\u62E9\u8981\u9884\u7EA6\u8003\u8BD5\u7684\u5730\u70B9";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option25 = "<1>\u8054\u7CFB\u6211\u4EEC</1>\u6211\u4EEC\u5C06\u5C3D\u529B\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9";
var APPB2C_common_findTest_specialReqs_findOutMore5 = "\u4E86\u89E3\u6211\u4EEC\u5982\u4F55\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9";
var APPB2C_common_findTest_specialReqs_list_subtitle5 = "\u8FD9\u5C06\u5F71\u54CD\u53EF\u7528\u7684\u8003\u8BD5\u65E5\u671F\u3002\u8BF7\u5728\u4E0B\u9762\u9009\u62E9\u4E00\u4E2A\u6216\u591A\u4E2A\u9009\u9879\u3002";
var APPB2C_common_findTest_specialReqs_list_title5 = "\u8003\u8BD5\u5F53\u5929\u9700\u8981\u54EA\u4E9B\u7279\u6B8A\u5B89\u6392\uFF1F";
var APPB2C_common_findTest_specialReqs_needCheckbox5 = "\u6211\u5BF9\u8003\u8BD5\u65E5\u671F\u6709 <1>\u8F85\u52A9\u529F\u80FD</1> \u7684\u9700\u6C42";
var APPB2C_common_findTest_specialReqs_other5 = "\u60A8\u9700\u8981\u54EA\u4E9B\u7279\u6B8A\u5B89\u6392";
var APPB2C_common_findTest_specialReqs_pleaseNoteMessage5 = "\u5982\u679C\u60A8\u9700\u8981\u5728\u6700\u65E9\u7684\u8003\u8BD5\u65E5\u671F\u4E4B\u524D\u6CE8\u518C\u8003\u8BD5\u65E5\u671F, \u8BF7\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3\u4EE5\u83B7\u53D6\u53EF\u7528\u9009\u9879\u3002\u4F8B\u5982, \u5982\u679C\u60A8\u9700\u8981\u7279\u6B8A\u7684\u6D4B\u8BD5\u6750\u6599, \u8003\u8BD5\u4E2D\u5FC3\u53EF\u80FD\u65E0\u6CD5\u63D0\u4F9B\u66F4\u65E9\u7684\u8003\u8BD5\u65E5\u671F\u3002\u653E\u5927\u6253\u5370\u8BD5\u5377\u3001\u76F2\u6587\u3001\u5507\u8BFB\u7248\u7B49\u3002";
var APPB2C_common_findTest_subtitle5 = "\u60A8\u60F3\u5728\u54EA\u91CC\u53C2\u52A0\u8003\u8BD5\uFF1F";
var APPB2C_common_findTest_title5 = "\u67E5\u627E\u6D4B\u8BD5";
var APPB2C_common_findTest_uol_organisationCheck_check5 = "\u6211\u786E\u8BA4\u6211\u4E86\u89E3\u7528\u4E8E UKVI \u5728\u7EBF\u7684\u96C5\u601D\u8003\u8BD5\u4EC5\u9002\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u548C\u79FB\u6C11\u5C40\u3002";
var APPB2C_common_findTest_uol_organisationCheck_desc5 = "\u8981\u53C2\u52A0\u5176\u4ED6\u7EC4\u7EC7\u8BA4\u53EF\u7684\u96C5\u601D\u8003\u8BD5, \u8BF7<0>\u67E5\u770B\u6211\u4EEC\u7684\u5176\u4ED6\u96C5\u601D\u8003\u8BD5.</0>";
var APPB2C_common_findTest_uol_organisationCheck_title5 = "\u7528\u4E8E UKVI \u5728\u7EBF\u7684\u96C5\u601D\u8003\u8BD5\u4EC5\u9002\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u548C\u79FB\u6C11\u5C40";
var APPB2C_common_findTest_uol_softwareCheck_check5 = "\u6211\u786E\u8BA4\u6211\u5DF2\u68C0\u67E5\u8BBE\u5907\u548C\u73AF\u5883\u8981\u6C42\u4EE5\u53C2\u52A0\u6D4B\u8BD5";
var APPB2C_common_findTest_uol_softwareCheck_desc15 = "\u60A8\u7684 UKVI \u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u4F7F\u7528 Inspera \u8F6F\u4EF6, \u60A8\u9700\u8981\u5728 Windows PC \u6216 MacOS \u4E0A\u4E0B\u8F7D\u8BE5\u8F6F\u4EF6(\u60A8\u4E0D\u80FD\u5728\u624B\u673A\u6216\u5E73\u677F\u7535\u8111\u4E0A\u53C2\u52A0\u8003\u8BD5\uFF01";
var APPB2C_common_findTest_uol_softwareCheck_desc25 = "\u5728\u60A8\u5411\u6211\u4EEC\u9884\u8BA2\u6D4B\u8BD5\u4E4B\u524D, \u60A8\u5FC5\u987B\u68C0\u67E5\u60A8\u7684\u8BA1\u7B97\u673A\u662F\u5426\u7B26\u5408\u5EFA\u8BAE\u7684\u7CFB\u7EDF\u8981\u6C42\u3002";
var APPB2C_common_findTest_uol_softwareCheck_link5 = "\u68C0\u67E5\u6D4B\u8BD5\u7684\u8BBE\u5907\u8981\u6C42";
var APPB2C_common_findTest_uol_softwareCheck_title5 = "\u60A8\u7684\u8BA1\u7B97\u673A\u53EF\u4EE5\u8FD0\u884C\u53C2\u52A0\u8003\u8BD5\u6240\u9700\u7684\u8F6F\u4EF6\u5417\uFF1F";
var APPB2C_common_footer_terms_accessibility5 = "\u53EF\u53CA\u6027";
var APPB2C_common_footer_terms_copyrightLine15 = "\u8054\u5408\u738B\u56FD\u8D1F\u8D23\u6587\u5316\u5173\u7CFB\u548C\u6559\u80B2\u673A\u4F1A\u7684\u56FD\u9645\u7EC4\u7EC7\u3002";
var APPB2C_common_footer_terms_copyrightLine25 = "\u6CE8\u518C\u6148\u5584\u673A\u6784: 209131(\u82F1\u683C\u5170\u548C\u5A01\u5C14\u58EB)SC037733(\u82CF\u683C\u5170)\u3002";
var APPB2C_common_footer_terms_dataProtection5 = "\u6570\u636E\u4FDD\u62A4";
var APPB2C_common_footer_terms_privacyAndCookies5 = "\u9690\u79C1\u548C\u997C\u5E72";
var APPB2C_common_footer_terms_termsOfUse5 = "\u4F7F\u7528\u6761\u6B3E";
var APPB2C_common_gelRedirect_errorMessage5 = "\u6B64\u8D44\u6E90\u5F53\u524D\u4E0D\u53EF\u7528\u3002";
var APPB2C_common_gelRedirect_goBack4 = "\u67E5\u770B\u51C6\u5907\u6750\u6599";
var APPB2C_common_gelRedirect_header5 = "\u51C6\u5907\u8003\u8BD5";
var APPB2C_common_gelRedirect_info5 = "\u6211\u4EEC\u53EF\u4EE5\u901A\u8FC7\u6211\u4EEC\u7684\u4E00\u7CFB\u5217\u514D\u8D39\u8D44\u6E90\u5E2E\u52A9\u60A8\u51C6\u5907\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_general_next5 = "\u4E0B\u4E00\u4E2A";
var APPB2C_common_general_previous5 = "\u4EE5\u524D";
var APPB2C_common_general_regNotFound5 = "\u672A\u627E\u5230\u6CE8\u518C";
var APPB2C_common_general_remove5 = "\u5220\u9664";
var APPB2C_common_general_steps5 = "\u6B65\u9AA4 {{\u6B65\u9AA4}} \u7684 {{\u6B65\u9AA4}}";
var APPB2C_common_general_stepsContinued5 = "\u6B65\u9AA4 {{\u6B65\u9AA4}} \u7684 {{\u6B65\u9AA4}} \u7EE7\u7EED...";
var APPB2C_common_helpAndContact_actionSection_contactFormLabel5 = "\u6211\u73B0\u5728\u4E0D\u80FD\u804A\u5929, \u4F46\u6211\u9700\u8981\u6709\u4EBA\u7A0D\u540E\u8054\u7CFB\u6211: ";
var APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre5 = "\u60A8\u9700\u8981\u66F4\u591A\u5E2E\u52A9\u5417\uFF1F\u70B9\u51FB\u4E0B\u9762\u7684\u6309\u94AE\u586B\u5199\u8054\u7CFB\u8868\u683C\u3002";
var APPB2C_common_helpAndContact_actionSection_contactFormLink5 = "\u586B\u5199\u6211\u4EEC\u7684\u8054\u7CFB\u8868\u683C";
var APPB2C_common_helpAndContact_actionSection_faqLabel5 = "\u6211\u6709\u4E00\u4E2A\u5FEB\u901F\u7684\u95EE\u9898\u9700\u8981\u56DE\u7B54: ";
var APPB2C_common_helpAndContact_actionSection_faqLink5 = "\u67E5\u770B\u6211\u4EEC\u7684\u5E38\u89C1\u95EE\u9898";
var APPB2C_common_helpAndContact_actionSection_icHeader5 = "\u6211\u4EEC\u53EF\u4EE5\u5E2E\u52A9\u60A8\u7684\u5176\u4ED6\u65B9\u5F0F...";
var APPB2C_common_helpAndContact_actionSection_iolHeader5 = "\u60A8\u9700\u8981\u4EC0\u4E48\u6837\u7684\u652F\u6301\uFF1F";
var APPB2C_common_helpAndContact_actionSection_launchChatBtn5 = "\u542F\u52A8\u5B9E\u65F6\u804A\u5929";
var APPB2C_common_helpAndContact_actionSection_launchChatLabel5 = "\u6211\u9700\u8981\u5728\u7F51\u4E0A\u4E0E\u67D0\u4EBA\u8BA8\u8BBA\u6211\u7684\u6D4B\u8BD5: ";
var APPB2C_common_helpAndContact_form_back5 = "\u8FD4\u56DE\u5E2E\u52A9\u548C\u8054\u7CFB";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLink5 = "\u6709\u5173\u5411\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u6295\u8BC9\u7684\u66F4\u591A\u4FE1\u606F, \u8BF7\u53C2\u9605<0>\u6295\u8BC9</0>";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle5 = "\u4E86\u89E3\u66F4\u591A";
var APPB2C_common_helpAndContact_form_complaint_radio_complaint5 = "\u62B1\u6028";
var APPB2C_common_helpAndContact_form_complaint_radio_other5 = "\u5E38\u89C4\u8BF7\u6C42, \u4F8B\u5982\u3002\u4E00\u4E2A\u95EE\u9898";
var APPB2C_common_helpAndContact_form_complaint_title5 = "\u8BF7\u9009\u62E9\u8BF7\u6C42\u7C7B\u578B: ";
var APPB2C_common_helpAndContact_form_label5 = "\u8BF7\u7B80\u8981\u6982\u8FF0\u60A8\u7684\u67E5\u8BE2";
var APPB2C_common_helpAndContact_form_legal5 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u5C06\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F\u6765\u5904\u7406\u60A8\u7684\u8BF7\u6C42\u3002\u6709\u5173\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7F51\u7AD9\u7684<0>\u9690\u79C1\u90E8\u5206</0>";
var APPB2C_common_helpAndContact_form_response5 = "\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u56DE\u590D\u6240\u6709\u8BF7\u6C42\u3002";
var APPB2C_common_helpAndContact_form_submit5 = "\u63D0\u4EA4\u8054\u7CFB\u8868\u683C";
var APPB2C_common_helpAndContact_form_thanks_back5 = "\u8FD4\u56DE\u6211\u7684\u6D4B\u8BD5\u9884\u8BA2";
var APPB2C_common_helpAndContact_form_thanks_banner5 = "\u6211\u4EEC\u5DF2\u6536\u5230\u60A8\u7684\u8054\u7CFB\u8BF7\u6C42\u3002";
var APPB2C_common_helpAndContact_form_thanks_info5 = "\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u56DE\u590D\u6240\u6709\u8BF7\u6C42\u3002";
var APPB2C_common_helpAndContact_form_thanks_title5 = "\u611F\u8C22\u60A8\u4E0E\u6211\u4EEC\u8054\u7CFB";
var APPB2C_common_helpAndContact_form_title5 = "\u6211\u4EEC\u5982\u4F55\u5E2E\u52A9\u60A8\u8FDB\u884C\u6D4B\u8BD5\uFF1F";
var APPB2C_common_helpAndContact_form_validation5 = "\u6B64\u5B57\u6BB5\u4E3A\u5FC5\u586B\u5B57\u6BB5\u3002";
var APPB2C_common_helpAndContact_icContent_commonQuestionsHeader5 = "\u5E38\u89C1\u95EE\u9898";
var APPB2C_common_helpAndContact_icContent_emailLabel5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_common_helpAndContact_icContent_header5 = "\u5982\u679C\u60A8\u5BF9\u96C5\u601D\u8003\u8BD5\u6709\u4EFB\u4F55\u7591\u95EE, \u8BF7\u8054\u7CFB\u6211\u4EEC\u3002";
var APPB2C_common_helpAndContact_icContent_phoneLabel5 = "\u7535\u8BDD";
var APPB2C_common_helpAndContact_icContent_referenceReminder5 = "\u56E0\u6B64, \u6211\u4EEC\u53EF\u4EE5\u66F4\u5FEB\u5730\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9, \u8BF7\u5F15\u7528\u60A8\u7684\u96C5\u601D\u53C2\u8003\u7F16\u53F7: ";
var APPB2C_common_helpAndContact_ieltsReference5 = "\u96C5\u601D\u53C2\u8003\u7F16\u53F7: ";
var APPB2C_common_helpAndContact_noTests_bookNewBtn5 = "\u9884\u7EA6\u65B0\u6D4B\u8BD5";
var APPB2C_common_helpAndContact_noTests_contactLink5 = "\u8054\u7CFB\u65B9\u5F0F\u53EF\u4EE5\u5728\u6211\u4EEC\u7684<0>\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u7F51\u7AD9</0>\u4E0A\u627E\u5230\u3002";
var APPB2C_common_helpAndContact_noTests_header5 = "\u60A8\u6CA1\u6709\u4EFB\u4F55\u6D4B\u8BD5\u53EF\u4EE5\u8BF7\u6C42\u5E2E\u52A9\u3002";
var APPB2C_common_helpAndContact_selectExamLabel5 = "\u663E\u793A\u5BF9\u6B64\u6D4B\u8BD5\u7684\u5E2E\u52A9: ";
var APPB2C_common_helpAndContact_title5 = "\u5E2E\u52A9\u548C\u8054\u7CFB";
var APPB2C_common_invitation_modal_header5 = "\u65E0\u6548\u94FE\u63A5";
var APPB2C_common_invitation_modal_invalid5 = "\u60A8\u5C1D\u8BD5\u4F7F\u7528\u7684 URL \u4E0D\u6B63\u786E\u6216\u4E0D\u518D\u6709\u6548\u3002\u60A8\u53EF\u4EE5\u5728\u6CA1\u6709\u4EE3\u7406\u4E0A\u4E0B\u6587\u7684\u60C5\u51B5\u4E0B\u7EE7\u7EED\u6CE8\u518C\u3002";
var APPB2C_common_modals_newTermsAndConditions_checkboxNote5 = "\u6211\u540C\u610F {{\u4E0A\u4E0B\u6587}}";
var APPB2C_common_modals_newTermsAndConditions_checkboxTC5 = "\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_modals_newTermsAndConditions_cta5 = "\u8FDB\u884C";
var APPB2C_common_modals_newTermsAndConditions_error5 = "\u54CE\u5440, \u51FA\u4E8B\u4E86\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_common_modals_newTermsAndConditions_note5 = "\u8BF7\u5728\u7EE7\u7EED\u4E4B\u524D\u63A5\u53D7\u65B0\u7684\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_modals_newTermsAndConditions_title5 = "\u65B0\u7684\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_modals_newTermsAndConditions_trigger5 = "\u63A5\u53D7\u65B0\u7684\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_nav_bookNewTest5 = "\u9884\u8BA2\u65B0\u6D4B\u8BD5";
var APPB2C_common_nav_chooseLanguage5 = "\u9009\u62E9\u8BED\u8A00";
var APPB2C_common_nav_hello5 = "\u4F60\u597D";
var APPB2C_common_nav_helpAndContact5 = "\u5E2E\u52A9\u548C\u8054\u7CFB";
var APPB2C_common_nav_login5 = "\u767B\u5F55";
var APPB2C_common_nav_logout5 = "\u6CE8\u9500";
var APPB2C_common_nav_logoutModal_cancel4 = "\u53D6\u6D88";
var APPB2C_common_nav_logoutModal_confirm5 = "\u8FD8\u884C";
var APPB2C_common_nav_logoutModal_message5 = "\u6CE8\u9500\u5C06\u9000\u51FA\u6CE8\u518C\u8FC7\u7A0B\u3002";
var APPB2C_common_nav_logoutModal_question5 = "\u60A8\u786E\u5B9A\u8981\u7EE7\u7EED\u5417\uFF1F";
var APPB2C_common_nav_logoutModal_title5 = "\u6CE8\u9500";
var APPB2C_common_nav_myAccount5 = "\u6211\u7684\u5E10\u6237";
var APPB2C_common_nav_preparation5 = "\u5236\u5907";
var APPB2C_common_nav_resultsTests5 = "\u6D4B\u8BD5\u548C\u7ED3\u679C";
var APPB2C_common_nav_ttp5 = "\u5E94\u8BD5\u8005\u95E8\u6237";
var APPB2C_common_preparation_noTests_contactLink5 = "\u514D\u8D39\u7684\u5907\u8003\u6750\u6599\u53EF\u5728\u6211\u4EEC\u7684<0>\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u7F51\u7AD9</0>\u4E0A\u627E\u5230\u3002";
var APPB2C_common_preparation_noTests_header5 = "\u60A8\u6CA1\u6709\u4EFB\u4F55\u9884\u5B9A\u7684\u8003\u8BD5\u8981\u51C6\u5907\u3002";
var APPB2C_common_preparation_selectExamLabel5 = "\u663E\u793A\u6B64\u6D4B\u8BD5\u7684\u6750\u6599: ";
var APPB2C_common_preparation_title5 = "\u5236\u5907";
var APPB2C_common_preparation_viewTestBtn5 = "\u67E5\u770B\u6B64\u6D4B\u8BD5";
var APPB2C_common_recreateReg_errMsg5 = "\u6211\u4EEC\u65E0\u6CD5\u91CD\u65B0\u521B\u5EFA\u6B64\u6CE8\u518C, \u4F46\u8BF7\u4E0D\u8981\u62C5\u5FC3\u3002\u6211\u4EEC\u4F1A\u5C06\u60A8\u91CD\u5B9A\u5411\u5230\u6807\u51C6\u6CE8\u518C\u3002";
var APPB2C_common_recreateReg_errTitle5 = "\u91CD\u65B0\u521B\u5EFA\u5931\u8D25";
var APPB2C_common_recreateReg_progress5 = "\u91CD\u65B0\u521B\u5EFA\u60A8\u7684\u9884\u8BA2";
var APPB2C_common_regHeader_reference5 = "\u53C2\u8003";
var APPB2C_common_regHeader_trfLoading5 = "\u7B49\u4E00\u4E0B\u3002\u3002\u3002";
var APPB2C_common_regHeader_trfNumber5 = "\u6210\u7EE9\u5355\u7F16\u53F7";
var APPB2C_common_regInProgressModal_continue5 = "\u6CA1\u5173\u7CFB";
var APPB2C_common_regInProgressModal_message15 = "\u60A8\u5DF2\u7ECF\u5728\u8FDB\u884C {<bold>{date}}</bold> \u7684\u6CE8\u518C\u3002\u60A8\u771F\u7684\u60F3\u4ECE\u6240\u9009\u6D4B\u8BD5\u91CD\u65B0\u5F00\u59CB\u5417\uFF1F";
var APPB2C_common_regInProgressModal_message25 = "\u5728\u8FD9\u79CD\u60C5\u51B5\u4E0B, \u60A8\u5F53\u524D\u7684\u63D2\u69FD\u5C06\u88AB\u91CA\u653E\u3002";
var APPB2C_common_regInProgressModal_restart5 = "\u91CD\u65B0\u5F00\u59CB";
var APPB2C_common_regInProgressModal_title5 = "\u6CE8\u610F\u529B\uFF01";
var APPB2C_common_selfServices_requested_cancellation5 = "\u5DF2\u8BF7\u6C42\u53D6\u6D88";
var APPB2C_common_selfServices_requested_transfer5 = "\u5DF2\u8BF7\u6C42\u8F6C\u79FB";
var APPB2C_common_specialReqs_AMANU5 = "\u6284\u5199\u5458";
var APPB2C_common_specialReqs_ANTHR5 = "\u53E6\u4E00\u4E2A";
var APPB2C_common_specialReqs_BTP5 = "\u76F2\u6587\u8BD5\u5377";
var APPB2C_common_specialReqs_BWP5 = "\u76F2\u6587\u6587\u5B57\u5904\u7406\u5668";
var APPB2C_common_specialReqs_EPTP5 = "\u653E\u5927\u6253\u5370\u8BD5\u5377";
var APPB2C_common_specialReqs_ET5 = "\u989D\u5916\u65F6\u95F4";
var APPB2C_common_specialReqs_LRTV5 = "\u5507\u8BFB\u6D4B\u8BD5\u7248";
var APPB2C_common_specialReqs_SAE5 = "\u4E13\u7528\u6269\u589E\u8BBE\u5907";
var APPB2C_common_specialReqs_VAS5 = "\u58F0\u63A7\u8F6F\u4EF6";
var APPB2C_common_specialReqs_WP5 = "\u6587\u5B57\u5904\u7406\u8F6F\u4EF6";
var APPB2C_common_startPage_book5 = "\u4E66";
var APPB2C_common_startPage_candidates_nowAdult5 = "\u6211\u4EEC\u6CE8\u610F\u5230{{name}}\u73B0\u5728\u5728\u6CD5\u5F8B\u4E0A\u662F\u6210\u5E74\u4EBA\u3002\u6839\u636E\u6211\u4EEC\u7684\u653F\u7B56, \u4ED6\u4EEC\u5FC5\u987B\u81EA\u5DF1\u7BA1\u7406\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_common_startPage_candidates_showingFor5 = "\u663E\u793A\u4EE5\u4E0B\u5404\u9879\u7684\u6D4B\u8BD5\u548C\u7ED3\u679C: ";
var APPB2C_common_startPage_common_cancelled5 = "\u53D6\u6D88";
var APPB2C_common_startPage_description_ac5 = "\u5982\u679C\u60A8\u60F3\u5728\u672C\u79D1\u6216\u7814\u7A76\u751F\u9636\u6BB5\u5B66\u4E60, \u6216\u52A0\u5165\u4E16\u754C\u4EFB\u4F55\u5730\u65B9\u7684\u4E13\u4E1A\u7EC4\u7EC7, \u8BF7\u53C2\u52A0\u6B64\u6D4B\u8BD5\u3002";
var APPB2C_common_startPage_description_gt5 = "\u5982\u679C\u60A8\u60F3\u5728\u5B66\u4F4D\u4EE5\u4E0B\u6C34\u5E73\u5B66\u4E60\u6216\u5728\u4E16\u754C\u4EFB\u4F55\u5730\u65B9\u5DE5\u4F5C, \u8BF7\u53C2\u52A0\u6B64\u6D4B\u8BD5\u3002";
var APPB2C_common_startPage_description_ukvi5 = "\u82F1\u56FD\u653F\u5E9C\u6279\u51C6\u4E86\u5B89\u5168\u82F1\u8BED\u8BED\u8A00\u6D4B\u8BD5, \u9002\u7528\u4E8E\u9700\u8981\u5DE5\u4F5C/\u914D\u5076\u7B7E\u8BC1\u7684\u4EBA, \u6216\u8BA1\u5212\u5B66\u4E60\u5B66\u4F4D\u4EE5\u4E0B\u8BFE\u7A0B\u7684\u4EBA, \u6216\u5728UKVI\u4E0D\u8BA4\u53EF\u7684\u82F1\u56FD\u673A\u6784\u5B66\u4E60\u7684\u4EBA\u3002";
var APPB2C_common_startPage_endNote5 = "\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u662F\u96C5\u601D\u8003\u8BD5\u7684\u9A84\u50B2\u5171\u540C\u62E5\u6709\u8005\u3002";
var APPB2C_common_startPage_error_body5 = "\u53EF\u80FD\u8FD8\u6709\u66F4\u591A\u6D4B\u8BD5, \u4F46\u6211\u4EEC\u5728\u52A0\u8F7D\u5B83\u4EEC\u65F6\u9047\u5230\u4E86\u9519\u8BEF\u3002\u8BF7\u7A0D\u540E\u5C1D\u8BD5\u5237\u65B0\u9875\u9762\u3002";
var APPB2C_common_startPage_error_cta5 = "\u5237\u65B0\u9875\u9762";
var APPB2C_common_startPage_error_title5 = "\u65E0\u6CD5\u52A0\u8F7D\u6240\u6709\u6D4B\u8BD5";
var APPB2C_common_startPage_hero_cta5 = "\u9884\u7EA6\u65B0\u6D4B\u8BD5";
var APPB2C_common_startPage_hero_subtitle5 = "\u60A8\u7684\u5E94\u8BD5\u8005\u95E8\u6237\u5C06\u5E2E\u52A9\u60A8\u51C6\u5907\u8003\u8BD5\u3001\u7BA1\u7406\u9884\u8BA2\u3001\u83B7\u53D6\u6210\u7EE9, \u5E76\u4F7F\u7528\u96C5\u601D\u6210\u7EE9\u7EE7\u7EED\u60A8\u7684\u82F1\u8BED\u4E4B\u65C5\u3002";
var APPB2C_common_startPage_hero_title5 = "\u6D4B\u8BD5\u4E0E\u7ED3\u679C";
var APPB2C_common_startPage_ielts5 = "\u96C5\u601D";
var APPB2C_common_startPage_ieltsUkvi5 = "\u7528\u4E8E\u82F1\u56FD\u7B7E\u8BC1\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_startPage_missing_cancel5 = "\u6211\u4E0D\u60F3\u767B\u5F55";
var APPB2C_common_startPage_missing_marketing_cta5 = "\u6551";
var APPB2C_common_startPage_missing_marketing_notes_full15 = '\u60A8\u53EF\u4EE5\u968F\u65F6\u5728\u5E94\u8BD5\u8005\u95E8\u6237\u7684"\u6211\u7684\u5E10\u6237"\u533A\u57DF\u4E2D\u53D6\u6D88\u8BA2\u9605\u6211\u4EEC\u7684\u7535\u5B50\u90AE\u4EF6\u3002';
var APPB2C_common_startPage_missing_marketing_notes_full25 = "\u6216\u8005\u4F60\u53EF\u4EE5";
var APPB2C_common_startPage_missing_marketing_notes_full35 = "\u6211\u4EEC\u5C06\u6839\u636E\u60A8\u7684\u540C\u610F\u5904\u7406\u60A8\u7684\u4E2A\u4EBA\u4FE1\u606F\u3002";
var APPB2C_common_startPage_missing_marketing_notes_link5 = "\u8054\u7CFB\u6211\u4EEC";
var APPB2C_common_startPage_missing_marketing_notes_main5 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u5E0C\u671B\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F\u53D1\u9001\u6211\u4EEC\u8BA4\u4E3A\u611F\u5174\u8DA3\u7684\u6D3B\u52A8\u3001\u670D\u52A1\u548C\u4E8B\u4EF6(\u5305\u62EC\u793E\u4EA4\u6D3B\u52A8)\u7684\u8BE6\u7EC6\u4FE1\u606F\u3002";
var APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties5 = "\u6211\u5F88\u9AD8\u5174\u6536\u5230\u6709\u5173\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u548C\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u9009\u62E9\u7684\u7B2C\u4E09\u65B9\u7EC4\u7EC7\u7684\u4EA7\u54C1\u3001\u670D\u52A1\u548C\u6D3B\u52A8\u7684\u4FE1\u606F\u3002";
var APPB2C_common_startPage_missing_marketing_opts_BcOnly5 = "\u6211\u5F88\u9AD8\u5174\u6536\u5230\u6709\u5173\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A(\u5305\u62EC\u66F4\u5E7F\u6CDB\u7684\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u6210\u5458)\u63D0\u4F9B\u6216\u7EC4\u7EC7\u7684\u4EA7\u54C1\u3001\u670D\u52A1\u548C\u6D3B\u52A8\u7684\u6700\u65B0\u4FE1\u606F\u3002";
var APPB2C_common_startPage_missing_marketing_opts_DoNotSend5 = "\u8BF7\u4E0D\u8981\u5411\u6211\u53D1\u9001\u4EFB\u4F55\u8425\u9500\u66F4\u65B0\u3002";
var APPB2C_common_startPage_missing_marketing_title5 = "\u60A8\u7684\u8425\u9500\u504F\u597D";
var APPB2C_common_startPage_missing_ttData_cta5 = "\u786E\u8BA4\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_startPage_missing_ttData_dob5 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_common_startPage_missing_ttData_firstName5 = "\u540D\u5B57/\u540D\u5B57(\u5305\u62EC\u4E2D\u95F4\u540D)";
var APPB2C_common_startPage_missing_ttData_lastName5 = "\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_common_startPage_missing_ttData_line15 = "\u6211\u4EEC\u53EF\u4EE5\u770B\u5230\u60A8\u4E4B\u524D\u5411\u6211\u4EEC\u6CE8\u518C\u8FC7\u5176\u4ED6\u4EA7\u54C1\u6216\u670D\u52A1\u3002";
var APPB2C_common_startPage_missing_ttData_line25 = "\u4EE5\u4FBF\u6211\u4EEC\u767B\u5F55, \u8BF7\u518D\u6B21\u786E\u8BA4\u60A8\u7684\u59D3\u540D\u548C\u51FA\u751F\u65E5\u671F\u3002";
var APPB2C_common_startPage_missing_ttData_line35 = "\u8C22\u8C22\uFF01";
var APPB2C_common_startPage_missing_ttData_noLastName5 = "\u6211\u6CA1\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_common_startPage_missing_ttData_title5 = "\u6211\u4EEC\u9700\u8981\u68C0\u67E5\u4E00\u4E9B\u4E1C\u897F\uFF01";
var APPB2C_common_startPage_options_ac5 = "\u96C5\u601D\u5B66\u672F\u7C7B\u8003\u8BD5";
var APPB2C_common_startPage_options_gt5 = "\u96C5\u601D\u57F9\u8BAD\u7C7B\u8003\u8BD5";
var APPB2C_common_startPage_options_ukvi5 = "\u7528\u4E8E UKVI \u6216\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_startPage_past_cd5 = "\u96C5\u601D\u673A\u8003";
var APPB2C_common_startPage_past_empty5 = "\u60A8\u6CA1\u6709\u4EFB\u4F55\u6D4B\u8BD5";
var APPB2C_common_startPage_past_osr_newScore5 = "\u60A8\u7684\u65B0\u5206\u6570";
var APPB2C_common_startPage_past_osr_note_awaiting5 = "\u60A8\u6B63\u5728\u7B49\u5F85 {{skill}} \u91CD\u8003\u7684\u7ED3\u679C, \u4EE5\u5C1D\u8BD5\u63D0\u9AD8\u60A8\u7684\u603B\u5206\u3002";
var APPB2C_common_startPage_past_osr_note_confirmed5 = "\u60A8\u5DF2\u7ECF\u9884\u8BA2\u4E86{{\u6280\u80FD}}\u91CD\u8003, \u4EE5\u5C1D\u8BD5\u63D0\u9AD8\u60A8\u7684\u6574\u4F53\u5206\u6570\u3002";
var APPB2C_common_startPage_past_osr_note_hasResults5 = "\u60A8\u9884\u8BA2\u4E86 {{skill}} \u91CD\u8003, \u4EE5\u5C1D\u8BD5\u63D0\u9AD8\u60A8\u7684\u6574\u4F53\u5206\u6570\u3002\u60A8\u7684\u65B0\u5206\u6570\u73B0\u5DF2\u63A8\u51FA\u3002";
var APPB2C_common_startPage_past_osr_originalScore5 = "\u60A8\u7684\u539F\u59CB\u5206\u6570";
var APPB2C_common_startPage_past_osr_viewRetakeLink5 = "\u67E5\u770B\u5DF2\u9884\u8BA2\u7684\u91CD\u8003";
var APPB2C_common_startPage_past_pb5 = "\u96C5\u601D\u7EB8\u7B14\u8003\u8BD5";
var APPB2C_common_startPage_past_rebook5 = "\u4F7F\u7528\u4E0D\u540C\u65E5\u671F\u91CD\u65B0\u9884\u8BA2\u6B64\u6D4B\u8BD5";
var APPB2C_common_startPage_past_score5 = "\u4F60\u5F97\u5206\u4E86";
var APPB2C_common_startPage_past_scoreLifeSkills5 = "\u60A8\u7684\u5206\u6570";
var APPB2C_common_startPage_past_title5 = "\u60A8\u4E4B\u524D\u7684\u6D4B\u8BD5\u548C\u5206\u6570";
var APPB2C_common_startPage_past_upcomingResults5 = "\u60A8\u7684\u7ED3\u679C\u5C06\u5728 {{date}} \u4E4B\u524D\u63D0\u4F9B";
var APPB2C_common_startPage_readTnCs5 = "\u5728\u7EE7\u7EED\u4E4B\u524D, \u8BF7\u9605\u8BFB\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_startPage_recent_cta5 = "\u67E5\u770B\u5B8C\u6574\u7ED3\u679C";
var APPB2C_common_startPage_recent_title5 = "\u60A8\u6700\u8FD1\u7684\u7ED3\u679C";
var APPB2C_common_startPage_subtitle5 = "\u60A8\u60F3\u53C2\u52A0\u54EA\u79CD\u96C5\u601D\u8003\u8BD5\uFF1F";
var APPB2C_common_startPage_title5 = "\u9009\u62E9\u60A8\u7684\u96C5\u601D\u8003\u8BD5";
var APPB2C_common_startPage_tncs5 = "\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_startPage_upcoming_cd5 = "\u8BA1\u7B97\u673A\u6D4B\u8BD5";
var APPB2C_common_startPage_upcoming_empty5 = "\u60A8\u6CA1\u6709\u4EFB\u4F55\u5373\u5C06\u8FDB\u884C\u7684\u6D4B\u8BD5";
var APPB2C_common_startPage_upcoming_lifeSkills5 = "\u542C\u529B\u548C\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_startPage_upcoming_live5 = "\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_startPage_upcoming_manage5 = "\u67E5\u770B\u6B64\u9884\u8BA2";
var APPB2C_common_startPage_upcoming_missing_childConsent5 = "\u66F4\u65B0\u5B50\u9879\u540C\u610F\u4FE1\u606F";
var APPB2C_common_startPage_upcoming_missing_id5 = "\u4E0A\u4F20\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6";
var APPB2C_common_startPage_upcoming_missing_medical5 = "\u4E0A\u4F20\u533B\u7597\u8BC1\u636E";
var APPB2C_common_startPage_upcoming_missing_payment5 = "\u4E0A\u4F20\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_common_startPage_upcoming_noSpeaking5 = "\u6682\u65E0\u5173\u4E8E\u53D1\u8A00\u7684\u4FE1\u606F";
var APPB2C_common_startPage_upcoming_noTime5 = "\u6211\u4EEC\u5C06\u5728\u8003\u8BD5\u65E5\u671F\u524D\u4E0D\u8D85\u8FC7 48 \u5C0F\u65F6\u5411\u60A8\u53D1\u9001\u8003\u8BD5\u7684\u786E\u5207\u65F6\u95F4\u3002";
var APPB2C_common_startPage_upcoming_osr_note5 = "\u60A8\u7684 {{\u6280\u80FD}} \u91CD\u8003\u6210\u7EE9\u5C06\u8BA1\u5165\u60A8\u7684\u65B0\u603B\u6210\u7EE9\u3002";
var APPB2C_common_startPage_upcoming_pastPaymentDeadline5 = "\u4ED8\u6B3E\u65E5\u671F\u5DF2\u8FC7\u671F";
var APPB2C_common_startPage_upcoming_pay5 = "\u652F\u4ED8\u8003\u8BD5\u8D39\u7528";
var APPB2C_common_startPage_upcoming_pb5 = "\u7B14\u8BD5";
var APPB2C_common_startPage_upcoming_prepare5 = "\u51C6\u5907\u6B64\u6D4B\u8BD5";
var APPB2C_common_startPage_upcoming_remote5 = "\u8FDC\u7A0B\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_startPage_upcoming_title5 = "\u60A8\u5373\u5C06\u8FDB\u884C\u7684\u6D4B\u8BD5";
var APPB2C_common_startPage_whichIELTS5 = "\u54EA\u79CD\u96C5\u601D\u8003\u8BD5\u9002\u5408\u6211\uFF1F";
var APPB2C_common_terms_downloadInfo5 = "\u60A8\u7684\u6761\u6B3E\u548C\u6761\u4EF6PDF\u4E0B\u8F7D\u5C06\u5F88\u5FEB\u5F00\u59CB\u3002";
var APPB2C_common_terms_downloadTitle5 = "\u6761\u6B3E\u4E0E\u6761\u4EF6 PDF \u4E0B\u8F7D";
var APPB2C_common_terms_error5 = "\u54CE\u5440, \u51FA\u4E8B\u4E86\u3002\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002";
var APPB2C_common_terms_title5 = "\u6761\u6B3E\u548C\u6761\u4EF6";
var APPB2C_common_testDetails_changeTest5 = "\u53D8\u66F4\u6D4B\u8BD5";
var APPB2C_common_testDetails_changeWrittenTest5 = "\u66F4\u6539\u7B14\u8BD5";
var APPB2C_common_testDetails_completeYourBooking5 = "\u5B8C\u6210\u60A8\u7684\u9884\u8BA2";
var APPB2C_common_testDetails_mapModal_close5 = "\u5173\u95ED\u7A97\u53E3";
var APPB2C_common_testDetails_mapModal_title5 = "\u4F4D\u7F6E\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_testDetails_modifyNote_confirm5 = "\u6211\u7406\u89E3\u5E76\u540C\u610F\u8FD9\u4E9B\u6761\u6B3E\u548C\u6761\u4EF6\u3002";
var APPB2C_common_testDetails_modifyNote_note5 = "\u5C3D\u7BA1\u6211\u4EEC\u5C3D\u4E00\u5207\u52AA\u529B\u786E\u4FDD\u6211\u4EEC\u5C0A\u91CD\u60A8\u7684\u8003\u8BD5\u5730\u70B9\u3001\u65E5\u671F\u548C\u65F6\u95F4\u6863\u9009\u62E9, \u4F46\u6211\u4EEC\u4FDD\u7559\u6839\u636E\u53EF\u7528\u6027\u5E76\u6839\u636E\u6761\u6B3E\u548C\u6761\u4EF6\u4FEE\u6539\u3001\u7EC8\u6B62\u548C\u66FF\u6362\u60A8\u9009\u62E9\u7684\u504F\u597D\u7684\u6743\u5229\u3002";
var APPB2C_common_testDetails_modifyNote_pleaseNote5 = "\u8BF7\u6CE8\u610F";
var APPB2C_common_testDetails_title5 = "\u6D4B\u8BD5\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_common_testDetails_yourSpeakingTest5 = "\u60A8\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_common_testDetails_yourWrittenTest5 = "\u60A8\u7684\u7B14\u8BD5";
var APPB2C_common_transfer_agreement5 = "\u6709\u5173\u6211\u4EEC\u7684\u8F6C\u8BA9\u653F\u7B56\u7684\u5B8C\u6574\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7684<1>\u6761\u6B3E\u548C\u6761\u4EF6</1>";
var APPB2C_common_transfer_contactNote5 = "\u6211\u4EEC\u5C06\u76F4\u63A5\u4E0E\u60A8\u8054\u7CFB\u4EE5\u5904\u7406\u60A8\u7684\u8003\u8BD5\u8F6C\u79FB\u3002\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u56DE\u590D\u6240\u6709\u8BF7\u6C42";
var APPB2C_common_transfer_contactNoteIol5 = "\u6211\u4EEC\u5C06\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u76F4\u63A5\u4E0E\u60A8\u8054\u7CFB, \u4EE5\u5904\u7406\u60A8\u7684\u8003\u8BD5\u8F6C\u79FB\u3002\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u56DE\u590D\u6240\u6709\u8BF7\u6C42";
var APPB2C_common_transfer_cta5 = "\u63D0\u4EA4\u8F6C\u79FB\u8BF7\u6C42";
var APPB2C_common_transfer_form_freeform5 = "\u8BF7\u544A\u8BC9\u6211\u4EEC\u60A8\u5BF9\u65B0\u6D4B\u8BD5\u7684\u504F\u597D";
var APPB2C_common_transfer_form_reasons_15 = "\u6211\u9884\u8BA2\u7684\u6D4B\u8BD5\u7C7B\u578B";
var APPB2C_common_transfer_form_reasons_25 = "\u6211\u7684\u9884\u8BA2\u5730\u70B9";
var APPB2C_common_transfer_form_reasons_35 = "\u8003\u8BD5\u65E5\u671F";
var APPB2C_common_transfer_form_reasons_45 = "\u53EA\u662F\u53E3\u8BED\u90E8\u5206";
var APPB2C_common_transfer_form_reasons_choose5 = "\u8BF7\u9009\u62E9\u4E00\u4E2A\u9009\u9879";
var APPB2C_common_transfer_form_reasons_other5 = "\u5176\u4ED6";
var APPB2C_common_transfer_form_reasons_title5 = "\u60A8\u9700\u8981\u66F4\u6539\u4EC0\u4E48\uFF1F";
var APPB2C_common_transfer_goBack5 = "\u8FD4\u56DE";
var APPB2C_common_transfer_notes_15 = "\u8F6C\u5B66\u7533\u8BF7\u987B\u7ECF\u8003\u8BD5\u4E2D\u5FC3\u6279\u51C6\u3002";
var APPB2C_common_transfer_notes_25 = "\u96C5\u601D\u8003\u8BD5\u53EA\u80FD\u8F6C\u4E00\u6B21\u3002";
var APPB2C_common_transfer_notes_35 = "\u60A8\u5C06\u9700\u8981\u5728 3 \u4E2A\u6708\u5185\u9009\u62E9\u4E00\u4E2A\u66FF\u4EE3\u8003\u8BD5\u65E5\u3002";
var APPB2C_common_transfer_notes_45 = "\u53EF\u80FD\u4F1A\u6536\u53D6\u884C\u653F\u8D39\u7528\u3002\u5982\u679C\u8BF7\u6C42\u83B7\u5F97\u6279\u51C6, \u6211\u4EEC\u5C06\u5411\u60A8\u53D1\u9001\u4ED8\u6B3E\u8BF4\u660E\u3002";
var APPB2C_common_transfer_notesIol_15 = "\u8F6C\u79FB\u7533\u8BF7\u987B\u7ECF\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u6279\u51C6\u3002";
var APPB2C_common_transfer_notesIol_25 = "\u5728\u63D0\u4EA4\u7533\u8BF7\u4E4B\u524D, \u8BF7\u4ED4\u7EC6\u9605\u8BFB<0>\u96C5\u601D\u8003\u8BD5\u5EF6\u671F\u3001\u53D6\u6D88\u548C\u9000\u6B3E\u653F\u7B56</0>";
var APPB2C_common_transfer_requestValidation5 = "\u6B64\u5B57\u6BB5\u4E3A\u5FC5\u586B\u5B57\u6BB5\u3002";
var APPB2C_common_transfer_selfService_availableDates_title5 = "\u53EF\u7528\u65E5\u671F";
var APPB2C_common_transfer_selfService_chooseDates_title5 = "\u8F6C\u79FB\u6D4B\u8BD5";
var APPB2C_common_transfer_selfService_review_confirm_proceedBtn5 = "\u8F6C\u79FB\u6D4B\u8BD5";
var APPB2C_common_transfer_selfService_review_confirm_title5 = "\u786E\u8BA4\u8F6C\u8D26";
var APPB2C_common_transfer_selfService_review_errors_noDistricts5 = "\u6B64\u4F4D\u7F6E\u4E0D\u518D\u63D0\u4F9B\u6D4B\u8BD5\u65E5\u671F\u3002\u8BF7\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3, \u770B\u770B\u6211\u4EEC\u662F\u5426\u53EF\u4EE5\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9\u3002";
var APPB2C_common_transfer_selfService_review_errors_noLrwSlots5 = "\u6240\u9009\u65E5\u671F\u7684\u6D4B\u8BD5\u5BB9\u91CF\u5DF2\u7528\u5C3D\u3002\u5C1D\u8BD5\u9009\u62E9\u5176\u4ED6\u65E5\u671F\u3002";
var APPB2C_common_transfer_selfService_review_errors_transferDisabled5 = "\u8F6C\u79FB\u60A8\u7684\u6D4B\u8BD5\u4E0D\u518D\u53EF\u7528\u3002\u8BF7\u8054\u7CFB\u60A8\u7684\u8003\u8BD5\u4E2D\u5FC3\u4E86\u89E3\u66F4\u591A\u4FE1\u606F\u3002";
var APPB2C_common_transfer_selfService_review_test_changeLrw5 = "\u66F4\u6539\u7B14\u8BD5\u65E5\u671F";
var APPB2C_common_transfer_selfService_review_tncs_agreement5 = "\u60A8\u540C\u610F\u96C5\u601D <0>\u6761\u6B3E\u548C\u6761\u4EF6</0> \u548C <2>\u53D6\u6D88\u653F\u7B56</2>";
var APPB2C_common_transfer_title5 = "\u8F6C\u79FB\u60A8\u7684\u9884\u8BA2";
var APPB2C_common_underConstruction_message5 = "\u4F60\u597D\u3002\u60A8\u6B63\u5728\u8BBF\u95EE\u6B64\u9875\u9762, \u56E0\u4E3A\u60A8\u662F\u6211\u4EEC\u6D4B\u8BD5\u6B64\u9884\u8BA2\u7F51\u7AD9\u7684\u8BD5\u70B9\u7684\u4E00\u90E8\u5206(\u611F\u8C22\u60A8\u7684\u5E2E\u52A9\uFF01\u522B\u62C5\u5FC3, \u5728\u6211\u4EEC\u4E0A\u7EBF\u4E4B\u524D, \u6211\u4EEC\u4F1A\u786E\u4FDD\u6211\u4EEC\u5728\u8FD9\u91CC\u5411\u771F\u6B63\u7684\u5BA2\u6237\u5C55\u793A\u4E00\u4E9B\u9002\u5F53\u7684\u5185\u5BB9:)";
var APPB2C_common_underConstruction_title5 = "\u6B63\u5728\u65BD\u5DE5\u4E2D\u3002\u3002\u3002";
var APPB2C_common_unknownCountry_cta5 = "\u6211\u5DF2\u5173\u95ED\u6211\u7684 VPN";
var APPB2C_common_unknownCountry_header5 = "\u6211\u4EEC\u65E0\u6CD5\u68C0\u6D4B\u5230\u60A8\u6B63\u5728\u6D4F\u89C8\u7684\u56FD\u5BB6/\u5730\u533A:(";
var APPB2C_common_unknownCountry_msg15 = "\u6709\u65F6\u53D1\u751F\u8FD9\u79CD\u60C5\u51B5\u662F\u56E0\u4E3A\u60A8\u7684\u8BBE\u5907\u6B63\u5728\u4F7F\u7528\u9690\u85CF\u60A8\u4F4D\u7F6E\u7684\u8F6F\u4EF6\u3002";
var APPB2C_common_unknownCountry_msg25 = "\u5982\u679C\u60A8\u4F7F\u7528\u7684\u662F\u9690\u85CF\u60A8\u4F4D\u7F6E\u7684 VPN \u6216\u7C7B\u4F3C\u5DE5\u5177, \u8BF7\u5C06\u5176\u5173\u95ED\u5E76\u91CD\u8BD5\u3002";
var APPB2C_common_unknownCountry_title5 = "\u6211\u4EEC\u627E\u4E0D\u5230\u4F60\uFF01";
var APPB2C_common_unpaidErrorBar_paymentExpired5 = "\u6CE8\u518C\u4ED8\u6B3E\u65E5\u671F\u5DF2\u8FC7\u671F";
var APPB2C_common_updateBar_cancelled5 = "\u6B64\u6D4B\u8BD5\u5DF2\u88AB\u53D6\u6D88\u3002";
var APPB2C_common_updateBar_cancelPending5 = "\u60A8\u53D6\u6D88\u6B64\u6D4B\u8BD5\u7684\u8BF7\u6C42\u6B63\u5728\u7B49\u5F85\u4E2D\u3002<1>\u67E5\u770B\u8BE6\u60C5</1>\u3002";
var APPB2C_common_updateBar_cancelRejected5 = "\u60A8\u7684\u53D6\u6D88\u8BF7\u6C42\u5DF2\u88AB\u62D2\u7EDD\u3002";
var APPB2C_common_updateBar_close5 = "\u5173\u95ED";
var APPB2C_common_updateBar_details5 = "\u8BF7\u53C2\u9605\u4E0B\u9762\u7684\u5B8C\u6574\u8BE6\u7EC6\u4FE1\u606F: ";
var APPB2C_common_updateBar_eorCompleted5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2(EOR)\u5DF2\u5B8C\u6210\u3002";
var APPB2C_common_updateBar_eorCompleted_scoreDecreased5 = "\u60A8\u7684\u603B\u5206\u4ECE {{prevOverallScore}} \u4E0B\u964D\u5230 {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreIncreased5 = "\u60A8\u7684\u603B\u5206\u4ECE {{prevOverallScore}} \u589E\u52A0\u5230 {{currentOverallScore}}";
var APPB2C_common_updateBar_eorCompleted_scoreUnchanged5 = "\u60A8\u7684\u603B\u5206\u4FDD\u6301\u4E0D\u53D8, \u4E3A {{currentOverallScore}}";
var APPB2C_common_updateBar_eorPending5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2 (EOR) \u6B63\u5728\u7B49\u5F85\u4E2D\u3002<1>\u67E5\u770B\u8BE6\u60C5</1>\u3002";
var APPB2C_common_updateBar_eorPendingAgentPays5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2 (EOR) \u6B63\u5728\u7B49\u5F85\u4E2D\u3002";
var APPB2C_common_updateBar_eorRefunded5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2(EOR)\u8D39\u7528\u5DF2\u9000\u8FD8\u3002";
var APPB2C_common_updateBar_eorRefundPending5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2 (EOR) \u8D39\u7528\u9000\u6B3E\u6B63\u5728\u5904\u7406\u4E2D\u3002";
var APPB2C_common_updateBar_eorRejected5 = "\u60A8\u7684\u67E5\u8BE2\u7ED3\u679C(EOR)\u88AB\u62D2\u7EDD\u3002";
var APPB2C_common_updateBar_eorRejected_details4 = "\u8BF7\u53C2\u9605\u4E0B\u9762\u7684\u5B8C\u6574\u8BE6\u7EC6\u4FE1\u606F: ";
var APPB2C_common_updateBar_eorRejected_overallBand5 = "\u60A8\u7684\u603B\u5206\u4E0E {{overallBand}} \u76F8\u6BD4\u6CA1\u6709\u53D8\u5316";
var APPB2C_common_updateBar_hasResults5 = "\u60A8\u7684\u7ED3\u679C\u73B0\u5DF2\u53EF\u7528\u3002";
var APPB2C_common_updateBar_termsUpdated5 = "\u60A8\u9700\u8981<1>\u63A5\u53D7\u65B0\u7684\u6761\u6B3E\u548C\u6761\u4EF6</1>\u8FD9\u4E9B\u6761\u6B3E\u548C\u6761\u4EF6\u5C06\u5728\u60A8\u7684\u8003\u8BD5\u5F53\u5929\u751F\u6548\u3002<br> \u6240\u6709\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u7684\u8003\u751F\u5747\u987B\u9075\u5B88\u672C\u6761\u6B3E\u548C\u6761\u4EF6\u3002";
var APPB2C_common_updateBar_transactionRefundRequestAccepted5 = "\u60A8\u7684\u9000\u6B3E\u7533\u8BF7\u5DF2\u88AB\u63A5\u53D7\u3002";
var APPB2C_common_updateBar_transactionRefundRequestPending5 = "\u60A8\u7684\u9000\u6B3E\u7533\u8BF7\u6B63\u5728\u7B49\u5F85\u4E2D\u3002";
var APPB2C_common_updateBar_transactionRefundRequestRejected5 = "\u60A8\u7684\u9000\u6B3E\u7533\u8BF7\u5DF2\u88AB\u62D2\u7EDD\u3002";
var APPB2C_common_updateBar_transferCancelled5 = "\u60A8\u7684\u8F6C\u8D26\u8BF7\u6C42\u65E0\u6CD5\u5F97\u5230\u5904\u7406\u3002\u8BF7\u91CD\u8BD5\u6216\u8054\u7CFB\u60A8\u7684\u8003\u8BD5\u4E2D\u5FC3\u4E86\u89E3\u66F4\u591A\u4FE1\u606F\u3002";
var APPB2C_common_updateBar_transferPending5 = "\u60A8\u8F6C\u79FB\u6B64\u6D4B\u8BD5\u7684\u8BF7\u6C42\u6B63\u5728\u7B49\u5F85\u4E2D\u3002<1>\u67E5\u770B\u8BE6\u60C5</1>\u3002";
var APPB2C_common_updateBar_transferPendingCmds5 = "\u60A8\u7684\u8F6C\u8D26\u6B63\u5728\u7B49\u5F85\u786E\u8BA4\u3002\u5F53\u60A8\u7684\u8F6C\u79FB\u8BF7\u6C42\u5F97\u5230\u5904\u7406\u540E, \u60A8\u5C06\u6536\u5230\u901A\u77E5\u3002";
var APPB2C_common_updateBar_transferred5 = "\u6B64\u6D4B\u8BD5\u5DF2\u8F6C\u79FB\u3002\u60A8\u7684\u65B0\u65E5\u671F\u5982\u4E0B\u3002";
var APPB2C_common_updateBar_transferredCmds5 = "\u6B64\u6D4B\u8BD5\u5DF2\u8F6C\u79FB\u3002\u6709\u5173\u65B0\u65E5\u671F, \u8BF7\u53C2\u9605\u9884\u8BA2\u8BE6\u60C5\u3002";
var APPB2C_registration_bookingDetails_payment5 = "\u4ED8\u6B3E";
var APPB2C_registration_bookingDetails_results5 = "\u4E86\u89E3\u7ED3\u679C";
var APPB2C_registration_bookingDetails_seePayment5 = "\u67E5\u770B\u4ED8\u6B3E\u8BE6\u60C5";
var APPB2C_registration_bookingDetails_seeTestTaker5 = "\u67E5\u770B\u5E94\u8BD5\u8005\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_registration_bookingDetails_whatNext5 = "\u63A5\u4E0B\u6765\u6211\u80FD\u505A\u4EC0\u4E48\uFF1F";
var APPB2C_registration_date_check_back5 = "\u8FD4\u56DE\u6D4B\u8BD5\u8BE6\u60C5";
var APPB2C_registration_date_check_beforeTestDate5 = "\u8003\u8BD5\u65E5\u524D";
var APPB2C_registration_date_check_intro5 = "\u5728\u8003\u8BD5\u65E5\u4E4B\u524D, \u8BF7\u786E\u4FDD\u60A8\u6267\u884C\u4EE5\u4E0B\u64CD\u4F5C: ";
var APPB2C_registration_date_check_onTestDate5 = "\u8003\u8BD5\u5F53\u5929";
var APPB2C_registration_date_check_step1_body5 = "\u68C0\u67E5\u60A8\u6253\u7B97\u53C2\u52A0\u8003\u8BD5\u7684\u7B14\u8BB0\u672C\u7535\u8111\u6216\u53F0\u5F0F\u7535\u8111\u662F\u5426\u7B26\u5408\u6700\u4F4E\u8981\u6C42(\u4EC5\u9650 PC \u6216 Mac \u2013 \u60A8\u4E0D\u80FD\u5728\u624B\u673A\u6216\u5E73\u677F\u7535\u8111\u4E0A\u53C2\u52A0\u96C5\u601D\u5728\u7EBF\u8003\u8BD5)\u3002";
var APPB2C_registration_date_check_step1_link5 = "\u68C0\u67E5\u60A8\u7684\u8BBE\u5907\u662F\u5426\u7B26\u5408\u8981\u6C42";
var APPB2C_registration_date_check_step1_title5 = "\u7B2C 1 \u6B65 \u2013 \u8BBE\u5907\u68C0\u67E5";
var APPB2C_registration_date_check_step2_body5 = "\u5728\u8003\u8BD5\u65E5\u671F\u4E4B\u524D, \u60A8\u9700\u8981\u5728\u6253\u7B97\u53C2\u52A0\u8003\u8BD5\u7684 PC \u6216 Mac \u4E0A\u5B89\u88C5 Inspera \u8F6F\u4EF6\u3002";
var APPB2C_registration_date_check_step2_link5 = "\u4E0B\u8F7D\u56E0\u65AF\u4F69\u62C9\u8F6F\u4EF6";
var APPB2C_registration_date_check_step2_title5 = "\u6B65\u9AA4 2 \u2013 \u5B89\u88C5\u8F6F\u4EF6";
var APPB2C_registration_date_check_step3_body15 = "\u5728\u60A8\u7684\u8BBE\u5907\u4E0A\u5B89\u88C5 Inspera \u540E, \u6253\u5F00\u8F6F\u4EF6\u5E76\u767B\u5F55\u3002\u8981\u767B\u5F55, \u60A8\u53EA\u9700\u5728\u4E0B\u9762\u8F93\u5165\u7528\u6237\u540D\u548C\u5BC6\u7801 - \u60A8\u53EA\u9700\u590D\u5236\u5E76\u7C98\u8D34\u5B83\u4EEC\u5373\u53EF\u3002";
var APPB2C_registration_date_check_step3_body25 = "\u767B\u5F55\u540E, \u60A8\u5C06\u5B8C\u6210\u4EE5\u4E0B\u6B65\u9AA4: ";
var APPB2C_registration_date_check_step3_noDetailsBody5 = "\u5B83\u4EEC\u5C06\u5728\u63A5\u8FD1\u8003\u8BD5\u65E5\u671F\u65F6\u53EF\u7528\u3002";
var APPB2C_registration_date_check_step3_noDetailsTitle5 = "\u60A8\u7684\u767B\u5F55\u8BE6\u7EC6\u4FE1\u606F\u5C1A\u4E0D\u53EF\u7528";
var APPB2C_registration_date_check_step3_noSpeakingPinBody5 = "\u8FD9\u5C06\u5728\u63A5\u8FD1\u8003\u8BD5\u65E5\u671F\u65F6\u53EF\u7528\u3002";
var APPB2C_registration_date_check_step3_noSpeakingPinTitle5 = "\u60A8\u7684\u53E3\u8BED\u8003\u8BD5 PIN \u7801\u5C1A\u4E0D\u53EF\u7528";
var APPB2C_registration_date_check_step3_password5 = "\u60A8\u7684\u5BC6\u7801: ";
var APPB2C_registration_date_check_step3_speakingPin5 = "\u60A8\u7684\u53E3\u8BED\u6D4B\u8BD5\u5BC6\u7801: ";
var APPB2C_registration_date_check_step3_step1_body5 = "\u7CFB\u7EDF\u4F1A\u8981\u6C42\u60A8\u68C0\u67E5\u9EA6\u514B\u98CE\u548C\u6444\u50CF\u5934\u662F\u5426\u6B63\u5E38\u5DE5\u4F5C\u3002";
var APPB2C_registration_date_check_step3_step1_title5 = "\u7B2C 1 \u6B65 \u2013 \u8BBE\u5907\u68C0\u67E5";
var APPB2C_registration_date_check_step3_step2_body5 = "\u60A8\u5C06\u88AB\u8981\u6C42\u62CD\u6444\u8138\u90E8\u548C\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u7684\u7167\u7247(\u8BF7\u786E\u4FDD\u60A8\u62E5\u6709\u4E0E\u6700\u521D\u6CE8\u518C\u76F8\u540C\u7684\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6)\u3002";
var APPB2C_registration_date_check_step3_step2_title5 = "\u7B2C 2 \u6B65 \u2013 \u8BC6\u522B";
var APPB2C_registration_date_check_step3_step3_body5 = "\u8981\u8BBF\u95EE\u60A8\u7684\u96C5\u601D\u5728\u7EBF\u8003\u8BD5, \u7CFB\u7EDF\u4F1A\u8981\u6C42\u60A8\u8F93\u5165 PIN \u7801\u3002";
var APPB2C_registration_date_check_step3_step3_title5 = "\u7B2C 3 \u6B65 \u2013 \u6D4B\u8BD5\u767B\u5F55";
var APPB2C_registration_date_check_step3_username5 = "\u60A8\u7684\u7528\u6237\u540D: ";
var APPB2C_registration_date_check_step3_writtenPin5 = "\u60A8\u7684\u7B14\u8BD5\u5BC6\u7801: ";
var APPB2C_registration_date_zone_country5 = "\u6211\u4EEC\u5C06\u60A8\u672C\u5730\u5316\u4E3A";
var APPB2C_registration_date_zone_header5 = "\u5F53\u60A8\u9884\u8BA2\u8003\u8BD5\u65F6";
var APPB2C_registration_date_zone_name5 = "\u60A8\u7684\u65F6\u533A";
var APPB2C_registration_date_zone_time5 = "\u60A8\u7684\u5F53\u5730\u65F6\u95F4";
var APPB2C_registration_dateLocation_changeLinkIol5 = "\u6211\u60F3\u66F4\u6539\u6211\u7684\u6D4B\u8BD5";
var APPB2C_registration_dateLocation_changeLrwLink5 = "\u6211\u60F3\u66F4\u6539\u7B14\u8BD5";
var APPB2C_registration_dateLocation_changeSpeakingLink5 = "\u6211\u60F3\u66F4\u6539\u6211\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_registration_dateLocation_location5 = "\u4F4D\u7F6E";
var APPB2C_registration_dateLocation_manage_cancel5 = "\u53D6\u6D88\u6211\u7684\u9884\u8BA2";
var APPB2C_registration_dateLocation_manage_proceed4 = "\u8FDB\u884C";
var APPB2C_registration_dateLocation_manage_title5 = "\u7BA1\u7406\u60A8\u7684\u9884\u8BA2";
var APPB2C_registration_dateLocation_manage_transferSpeaking5 = "\u6362\u4E2A\u8BF4\u8BDD";
var APPB2C_registration_dateLocation_manage_transferWhole5 = "\u8F6C\u5B66\u8003\u8BD5\u65E5\u671F";
var APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest5 = "\u7EE7\u7EED\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody15 = "\u6211\u4EEC\u521A\u521A\u68C0\u67E5\u8FC7, \u6CA1\u6709\u5176\u4ED6\u53E3\u8BED\u6D4B\u8BD5\u53EF\u7528, \u5BF9\u4E0D\u8D77\uFF01";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody25 = "\u5982\u679C\u60A8\u786E\u5B9E\u9700\u8981\u66F4\u6539\u53E3\u8BED\u8003\u8BD5, \u8BF7\u8BF7\u6C42\u66F4\u6539\u8003\u8BD5\u65E5\u671F, \u7136\u540E\u60A8\u53EF\u4EE5\u9009\u62E9\u65B0\u7684\u53E3\u8BED\u8003\u8BD5\u3002";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader5 = "\u5BF9\u4E0D\u8D77 - \u6CA1\u6709\u53EF\u7528\u7684\u53E3\u8BED\u6D4B\u8BD5\uFF01";
var APPB2C_registration_dateLocation_speakingTransfer_requestTransfer5 = "\u6539\u4E3A\u8BF7\u6C42\u8F6C\u79FB";
var APPB2C_registration_incorrectOrganisation_homeLink5 = "\u663E\u793A\u6CA1\u6709\u7EC4\u7EC7\u80CC\u666F\u7684\u5E94\u8BD5\u8005\u95E8\u6237";
var APPB2C_registration_incorrectOrganisation_line15 = "\u770B\u8D77\u6765\u60A8\u6B63\u5728\u5C1D\u8BD5\u5728\u6211\u4EEC\u4E0D\u8BA4\u8BC6\u7684\u7EC4\u7EC7\u73AF\u5883\u4E2D\u4F7F\u7528\u5E94\u8BD5\u8005\u95E8\u6237\u3002";
var APPB2C_registration_incorrectOrganisation_line25 = "\u8BF7\u5C1D\u8BD5\u70B9\u51FB\u5C06\u60A8\u518D\u6B21\u5F15\u5BFC\u5230\u8FD9\u91CC\u7684\u539F\u59CB\u94FE\u63A5, \u6216\u8005\u76F4\u63A5\u4F7F\u7528\u6CA1\u6709\u4EFB\u4F55\u4E0A\u4E0B\u6587\u7684 TTP: ";
var APPB2C_registration_payments_actions_editProofCTA5 = "\u7F16\u8F91\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_registration_payments_actions_header5 = "\u60A8\u60F3\u5982\u4F55\u4ED8\u6B3E\uFF1F";
var APPB2C_registration_payments_actions_paymentInProgressInfo5 = "\u4E4B\u524D\u7684\u5728\u7EBF\u652F\u4ED8\u5C1D\u8BD5\u4ECD\u5728\u5904\u7406\u4E2D\u3002\u8BF7\u7B49\u5F85\u7ED3\u679C\u3002";
var APPB2C_registration_payments_actions_payOfflineCTA5 = "\u4EE5\u5176\u4ED6\u65B9\u5F0F\u652F\u4ED8";
var APPB2C_registration_payments_actions_payOnlineCTA5 = "\u5728\u7EBF\u652F\u4ED8";
var APPB2C_registration_payments_actions_subHeader5 = "\u6211\u5DF2\u7ECF\u4ED8\u6B3E, \u9700\u8981\u4E0A\u4F20\u6211\u7684\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_registration_payments_actions_uploadProofCTA5 = "\u4E0A\u4F20\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_registration_payments_awaitingPayment5 = "\u7B49\u5F85\u4ED8\u6B3E";
var APPB2C_registration_payments_back5 = "\u8FD4\u56DE\u6D4B\u8BD5";
var APPB2C_registration_payments_completed5 = "\u5B8C\u6210";
var APPB2C_registration_payments_expired5 = "\u8FC7\u671F";
var APPB2C_registration_payments_finishPayment_error_cta5 = "\u5237\u65B0\u9875\u9762";
var APPB2C_registration_payments_finishPayment_error_messageLine15 = "\u6211\u4EEC\u5728\u68C0\u67E5\u60A8\u7684\u4ED8\u6B3E\u72B6\u6001\u65F6\u9047\u5230\u9519\u8BEF\u3002\u8BF7\u7A0D\u540E\u5C1D\u8BD5\u5237\u65B0\u9875\u9762\u3002";
var APPB2C_registration_payments_finishPayment_error_messageLine25 = "\u5982\u679C\u6B64\u95EE\u9898\u4ECD\u7136\u5B58\u5728, \u8BF7\u8FD4\u56DE\u60A8\u7684\u4ED8\u6B3E\u65B9\u5F0F\u3002";
var APPB2C_registration_payments_finishPayment_error_title5 = "\u672A\u77E5\u7684\u4ED8\u6B3E\u72B6\u6001";
var APPB2C_registration_payments_finishPayment_failure_alert5 = "\u62B1\u6B49, \u60A8\u7684\u5728\u7EBF\u4ED8\u6B3E\u5931\u8D25\uFF01";
var APPB2C_registration_payments_finishPayment_failure_cta5 = "\u67E5\u770B\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_registration_payments_finishPayment_failure_message5 = "\u8BF7\u8FD4\u56DE\u60A8\u7684\u4ED8\u6B3E\u65B9\u5F0F, \u7136\u540E\u91CD\u8BD5\u3002";
var APPB2C_registration_payments_finishPayment_failure_title5 = "\u4ED8\u6B3E\u5931\u8D25";
var APPB2C_registration_payments_finishPayment_pleaseWait5 = "\u8BF7\u7A0D\u5019";
var APPB2C_registration_payments_finishPayment_processing5 = "\u6211\u4EEC\u6B63\u5728\u5904\u7406\u60A8\u7684\u4ED8\u6B3E...";
var APPB2C_registration_payments_finishPayment_success_alert5 = "\u60A8\u7684\u4ED8\u6B3E\u5DF2\u6210\u529F\u5904\u7406\uFF01";
var APPB2C_registration_payments_finishPayment_success_cta5 = "\u67E5\u770B\u6B64\u6D4B\u8BD5";
var APPB2C_registration_payments_finishPayment_success_message5 = "\u6211\u4EEC\u5DF2\u5C06\u4ED8\u6B3E\u786E\u8BA4\u53D1\u9001\u5230\u60A8\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u3002";
var APPB2C_registration_payments_finishPayment_success_title5 = "\u4ED8\u6B3E\u786E\u8BA4";
var APPB2C_registration_payments_offlineDetails_accountName5 = "\u5E10\u6237\u540D\u79F0";
var APPB2C_registration_payments_offlineDetails_accountNumber5 = "\u5E10\u53F7";
var APPB2C_registration_payments_offlineDetails_payBy5 = "\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_registration_payments_offlineDetails_sortCode5 = "\u6392\u5E8F\u4EE3\u7801";
var APPB2C_registration_payments_paidByAgent5 = "\u6CE8\u518C\u8D39\u7528\u7531\u4EE3\u7406\u652F\u4ED8";
var APPB2C_registration_payments_proofPreview_fileAlt5 = "\u4E0A\u4F20\u7684\u4ED8\u6B3E\u8BC1\u660E";
var APPB2C_registration_payments_proofPreview_proofLabel5 = "\u4ED8\u6B3E\u8BC1\u660E: ";
var APPB2C_registration_payments_proofUpload_cta5 = "\u4FDD\u5B58\u60A8\u7684\u6587\u4EF6";
var APPB2C_registration_payments_proofUpload_info5 = "\u60A8\u53EF\u4EE5\u4E0A\u4F20 2 \u4E2A\u6587\u4EF6(\u60A8\u5FC5\u987B\u81F3\u5C11\u4E0A\u4F20 1 \u4E2A)";
var APPB2C_registration_payments_proofUpload_intro5 = "\u5982\u679C\u60A8\u5DF2\u7ECF\u652F\u4ED8\u4E86\u6B64\u8D39\u7528, \u60A8\u53EF\u4EE5\u5728\u4E0B\u9762\u4E0A\u4F20\u4ED8\u6B3E\u8BC1\u660E\u3002";
var APPB2C_registration_payments_proofUpload_rules_intro5 = "\u60A8\u7684\u4ED8\u6B3E\u8BC1\u660E\u5FC5\u987B\u6E05\u695A\u5730\u663E\u793A: ";
var APPB2C_registration_payments_proofUpload_rules_rule15 = "\u6536\u6B3E\u4EBA\u59D3\u540D";
var APPB2C_registration_payments_proofUpload_rules_rule25 = "\u6536\u6B3E\u4EBA\u8D26\u6237\u53F7\u7801";
var APPB2C_registration_payments_proofUpload_rules_rule35 = "\u652F\u4ED8\u7684\u91D1\u989D";
var APPB2C_registration_payments_proofUpload_rules_rule45 = "\u4ED8\u6B3E\u65E5\u671F";
var APPB2C_registration_payments_proofUpload_rules_rule55 = "\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u5E10\u53F7";
var APPB2C_registration_payments_transaction_cancelledSuffix5 = "(\u5DF2\u53D6\u6D88)";
var APPB2C_registration_payments_transaction_deadlineWarning_default5 = "\u60A8\u5FC5\u987B\u5728{{\u65E5\u671F}}\u4E4B\u524D\u652F\u4ED8\u6B64\u8D39\u7528\u3002";
var APPB2C_registration_payments_transaction_deadlineWarning_eor5 = "\u60A8\u5FC5\u987B\u5728{{\u65E5\u671F}}\u4E4B\u524D\u652F\u4ED8\u6B64\u8D39\u7528\u3002\u5728\u6B64\u65E5\u671F\u4E4B\u540E, \u60A8\u7684\u7ED3\u679C\u67E5\u8BE2 (EOR) \u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_registration_payments_transaction_deadlineWarning_registration5 = "\u60A8\u5FC5\u987B\u5728{{\u65E5\u671F}}\u4E4B\u524D\u652F\u4ED8\u6B64\u8D39\u7528\u3002\u5728\u6B64\u65E5\u671F\u4E4B\u540E, \u60A8\u7684\u6CE8\u518C\u5C06\u88AB\u53D6\u6D88\u3002";
var APPB2C_registration_payments_transaction_description_appeal5 = "\u4E0A\u8BC9";
var APPB2C_registration_payments_transaction_description_eor5 = "\u590D\u8BAE";
var APPB2C_registration_payments_transaction_description_registration5 = "\u8003\u8BD5\u62A5\u540D";
var APPB2C_registration_payments_transaction_description_transfer5 = "\u8F6C\u79FB";
var APPB2C_registration_payments_transaction_feeLabel5 = "\u8D39";
var APPB2C_registration_payments_transaction_header5 = "\u4ED8\u6B3E";
var APPB2C_registration_payments_transaction_hideDetails5 = "\u9690\u85CF\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_registration_payments_transaction_outdatedTermsLabel5 = "\u60A8\u6700\u521D\u540C\u610F\u8FD9\u4E9B<0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_registration_payments_transaction_pastDeadline5 = "\u6B64\u9879\u76EE\u7684\u4ED8\u6B3E\u5C1A\u672A\u5728\u6211\u4EEC\u7684\u7CFB\u7EDF\u4E2D\u6CE8\u518C\u3002";
var APPB2C_registration_payments_transaction_pastDeadlineContactUs5 = "\u5982\u679C\u60A8\u8BA4\u4E3A\u8FD9\u662F\u4E00\u4E2A\u9519\u8BEF, \u5E76\u4E14\u53EF\u4EE5\u63D0\u4F9B\u6309\u65F6\u4ED8\u6B3E\u7684\u8BC1\u660E, \u8BF7\u8054\u7CFB\u6211\u4EEC: ";
var APPB2C_registration_payments_transaction_pastDeadlineEmail5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_registration_payments_transaction_pastDeadlinePhone5 = "\u7535\u8BDD";
var APPB2C_registration_payments_transaction_promoCode5 = "\u4FC3\u9500\u4EE3\u7801";
var APPB2C_registration_payments_transaction_receipt5 = "\u6536\u636E";
var APPB2C_registration_payments_transaction_receiptCta5 = "\u60A8\u53EF\u4EE5\u5728\u6B64\u5904\u4E0B\u8F7D";
var APPB2C_registration_payments_transaction_referenceLabel5 = "\u4ED8\u6B3E\u53C2\u8003";
var APPB2C_registration_payments_transaction_showDetails5 = "\u663E\u793A\u8BE6\u60C5";
var APPB2C_registration_payments_transaction_taxLabel5 = "\u7A0E";
var APPB2C_registration_payments_transaction_termsLabel5 = "\u67E5\u770B<0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_registration_payments_transaction_totalLabel5 = "\u603B";
var APPB2C_registration_payments_transaction_underReviewSuffix5 = "(\u5BA1\u67E5\u4E2D)";
var APPB2C_registration_payments_transaction_unpaidSuffix5 = "(\u65E0\u507F)";
var APPB2C_registration_payments_unpaid5 = "\u672A\u4ED8\u6B3E\u7684";
var APPB2C_registration_prepare_a1_cta5 = "\u51C6\u5907\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5 \u2013 A1 \u7EA7";
var APPB2C_registration_prepare_a1_header5 = "A1 \u7EA7";
var APPB2C_registration_prepare_a2_cta5 = "\u51C6\u5907\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5 \u2013 A2 \u7EA7";
var APPB2C_registration_prepare_a2_header5 = "A2 \u7EA7";
var APPB2C_registration_prepare_b1_cta5 = "\u51C6\u5907\u96C5\u601D\u751F\u6D3B\u6280\u80FD\u7C7B\u8003\u8BD5 - B1 \u7EA7";
var APPB2C_registration_prepare_b1_header5 = "\u7EA7\u522B B1";
var APPB2C_registration_prepare_blog_body_cta5 = "\u8BBF\u95EE\u96C5\u601D\u6D77\u5916\u673A\u4F1A\u535A\u5BA2";
var APPB2C_registration_prepare_blog_body_line15 = "\u8BE5\u535A\u5BA2\u63D0\u4F9B\u6709\u5173\u96C5\u601D\u8003\u8BD5\u7684\u4E13\u5BB6\u5EFA\u8BAE\u548C\u6280\u5DE7, \u4EE5\u53CA\u7F8E\u56FD\u6821\u56ED\u7684\u6982\u8FF0\u3002";
var APPB2C_registration_prepare_blog_header5 = "\u96C5\u601D\u6D77\u5916\u673A\u4F1A\u535A\u5BA2";
var APPB2C_registration_prepare_cdFamiliarTest_body_cta5 = "\u8BBF\u95EE CD \u96C5\u601D\u719F\u6089\u5EA6\u8003\u8BD5";
var APPB2C_registration_prepare_cdFamiliarTest_body_line15 = "\u96C5\u601D\u673A\u8003\u7684\u514D\u8D39\u5B8C\u6574\u6837\u672C\u7248\u2014\u2014\u542C\u529B\u3001\u9605\u8BFB\u548C\u5199\u4F5C\u90E8\u5206\u63D0\u4F9B 2 \u5C0F\u65F6 30 \u5206\u949F\u7684\u95EE\u9898, \u4E0E\u771F\u6B63\u7684\u96C5\u601D\u8003\u8BD5\u5B8C\u5168\u76F8\u540C\u3002";
var APPB2C_registration_prepare_cdFamiliarTest_header5 = "CD\u96C5\u601D\u719F\u6089\u5EA6\u8003\u8BD5";
var APPB2C_registration_prepare_cdFamiliarVideos_body_cta5 = "\u8BBF\u95EE\u8BA1\u7B97\u673A\u6388\u8BFE\u7684\u96C5\u601D\u719F\u6089\u89C6\u9891";
var APPB2C_registration_prepare_cdFamiliarVideos_body_line15 = "\u8FD9\u4E9B\u89C6\u9891\u5C06\u5E2E\u52A9\u60A8\u4E86\u89E3\u542C\u529B, \u9605\u8BFB\u548C\u5199\u4F5C\u90E8\u5206\u5728\u8BA1\u7B97\u673A\u4E0A\u7684\u663E\u793A\u65B9\u5F0F\u3002";
var APPB2C_registration_prepare_cdFamiliarVideos_header5 = "\u96C5\u601D\u673A\u8003\u719F\u6089\u89C6\u9891";
var APPB2C_registration_prepare_cdTests_body_cta5 = "\u53C2\u52A0\u673A\u8003\u96C5\u601D\u6A21\u62DF\u8003\u8BD5";
var APPB2C_registration_prepare_cdTests_body_line15 = "\u8FD9\u4E9B\u793A\u4F8B\u6D4B\u8BD5\u95EE\u9898\u5C06\u4E3A\u60A8\u51C6\u5907\u6D4B\u8BD5, \u5E76\u5E2E\u52A9\u60A8\u786E\u5B9A\u60A8\u559C\u6B22\u7684\u9009\u9879\u3002";
var APPB2C_registration_prepare_cdTests_header5 = "\u96C5\u601D\u673A\u8003";
var APPB2C_registration_prepare_cdTipsVideos_body_cta5 = "\u8BBF\u95EE\u8BA1\u7B97\u673A\u4EA4\u4ED8\u96C5\u601D\u6280\u5DE7\u89C6\u9891";
var APPB2C_registration_prepare_cdTipsVideos_body_line15 = "\u96C5\u601D\u673A\u8003\u5C0F\u8D34\u58EB\u3002";
var APPB2C_registration_prepare_cdTipsVideos_header5 = "\u673A\u8003\u96C5\u601D\u8003\u8BD5\u6280\u5DE7\u89C6\u9891";
var APPB2C_registration_prepare_facebook_body_cta5 = "\u54A8\u8BE2\u4E13\u5BB6(\u8138\u4E66)";
var APPB2C_registration_prepare_facebook_body_line15 = "\u96C5\u601D\u4E13\u5BB6\u6BCF\u6708\u5728Facebook\u4E0A\u8FDB\u884C\u4E09\u6B21\u5B9E\u65F6\u804A\u5929, \u73B0\u573A\u56DE\u7B54\u89C2\u4F17\u7684\u95EE\u9898\u3002";
var APPB2C_registration_prepare_facebook_header5 = "\u54A8\u8BE2\u4E13\u5BB6(\u8138\u4E66)";
var APPB2C_registration_prepare_gel_disabledMsg5 = "\u60A8\u7684\u8003\u8BD5\u4E2D\u5FC3\u963B\u6B62\u4E86\u5BF9 <0>IELTS Ready Premium</0> \u7684\u8BBF\u95EE\u3002\u8BF7\u4E0E\u4ED6\u4EEC\u8054\u7CFB, \u4EE5\u83B7\u5F97\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u7684\u514D\u8D39\u51C6\u5907\u670D\u52A1\u3002";
var APPB2C_registration_prepare_gel_ielts_body_cta5 = "\u83B7\u53D6\u8BBF\u95EE\u6743\u9650";
var APPB2C_registration_prepare_gel_ielts_body_line15 = "\u4E2A\u6027\u5316\u3001\u667A\u80FD\u7684\u652F\u6301, \u9002\u5408\u60A8\u548C\u60A8\u7684\u5B66\u4E60\u9700\u6C42, \u5E2E\u52A9\u60A8\u8FBE\u5230\u6240\u9700\u7684\u96C5\u601D\u6210\u7EE9\u3002\u5305\u62EC\u6211\u4EEC\u6240\u6709\u6280\u80FD\u7684\u6A21\u62DF\u6D4B\u8BD5\u548C\u7EC3\u4E60\u8D44\u6E90\u3002<0>IELTS Ready Premium</0>\u7531 GEL IELTS Prep \u63D0\u4F9B\u652F\u6301, \u5C06\u5E2E\u52A9\u60A8\u53D6\u5F97\u6210\u529F\u3002";
var APPB2C_registration_prepare_gel_ielts_header5 = "<0>IELTS Ready Premium</0>";
var APPB2C_registration_prepare_gel_ukvi_body_cta4 = "\u7ACB\u5373\u8BBF\u95EE\u96C5\u601D\u5907\u8003\u9AD8\u7EA7\u8003\u8BD5";
var APPB2C_registration_prepare_gel_ukvi_body_line15 = "\u4E2A\u6027\u5316\u3001\u667A\u80FD\u7684\u652F\u6301, \u914D\u5408\u60A8\u7684\u5B66\u4E60\u9700\u6C42, \u5E2E\u52A9\u60A8\u8FBE\u5230\u6240\u9700\u7684 UKVI \u96C5\u601D\u6210\u7EE9\u3002\u5305\u62EC\u6211\u4EEC\u6240\u6709\u6280\u80FD\u7684\u6A21\u62DF\u6D4B\u8BD5\u548C\u7EC3\u4E60\u8D44\u6E90\u3002<0>IELTS Ready Premium</0>\u7531 GEL IELTS Prep \u63D0\u4F9B\u652F\u6301, \u5C06\u5E2E\u52A9\u60A8\u53D6\u5F97\u6210\u529F\u3002";
var APPB2C_registration_prepare_gel_ukvi_header5 = "<0>IELTS Ready Premium</0> UKVI";
var APPB2C_registration_prepare_header5 = "\u51C6\u5907\u8003\u8BD5";
var APPB2C_registration_prepare_ieltsReady_subTitle5 = "\u60A8\u53EF\u4EE5\u8BBF\u95EE\u4EE5\u4E0B\u51C6\u5907\u670D\u52A1: ";
var APPB2C_registration_prepare_ieltsReady_title5 = "\u9009\u62E9\u60A8\u7684\u51C6\u5907\u670D\u52A1";
var APPB2C_registration_prepare_ieltsReadyMember_body5 = "\u5E7F\u6CDB\u7684\u652F\u6301\u6750\u6599, \u5305\u62EC\u6A21\u62DF\u8003\u8BD5\u3001\u5907\u8003\u7F51\u7EDC\u7814\u8BA8\u4F1A\u3001\u6280\u5DE7\u3001\u89C6\u9891\u3001\u96C5\u601D\u4E4B\u8DEF\u5185\u5BB9\u7B49\u7B49\u3002\u6211\u4EEC\u7684 <0>IELTS Ready Member</0> \u4E13\u533A\u5C06\u5E2E\u52A9\u60A8\u81EA\u4FE1\u5730\u51C6\u5907\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn5 = "\u9884\u7EA6\u65B0\u6D4B\u8BD5";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo5 = "\u8981\u514D\u8D39\u83B7\u5F97 <0>IELTS Ready Premium</0>, \u60A8\u9700\u8981\u9884\u8BA2\u5373\u5C06\u5230\u6765\u7684\u8003\u8BD5\u3002";
var APPB2C_registration_prepare_ieltsReadyMember_freePrepLink5 = "\u514D\u8D39\u7684\u5907\u8003\u6750\u6599\u53EF\u5728\u6211\u4EEC\u7684<0>\u53C2\u52A0\u96C5\u601D\u8003\u8BD5\u7F51\u7AD9</0>";
var APPB2C_registration_prepare_ieltsReadyMember_header5 = "<0>IELTS Ready \u4F1A\u5458</0>";
var APPB2C_registration_prepare_ieltsReadyMember_label5 = "\u5BF9\u6240\u6709\u4EBA\u514D\u8D39";
var APPB2C_registration_prepare_ieltsReadyMember_link5 = "\u514D\u8D39\u8BBF\u95EE";
var APPB2C_registration_prepare_ieltsReadyMember_payBtn5 = "\u652F\u4ED8\u8003\u8BD5\u8D39\u7528";
var APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo5 = "\u8981\u514D\u8D39\u83B7\u5F97 <0>IELTS Ready Premium</0>, \u60A8\u9700\u8981\u4E3A\u5373\u5C06\u5230\u6765\u7684\u8003\u8BD5\u4ED8\u8D39\u3002";
var APPB2C_registration_prepare_intro5 = "\u6211\u4EEC\u53EF\u4EE5\u901A\u8FC7\u6211\u4EEC\u7684\u4E00\u7CFB\u5217\u514D\u8D39\u8D44\u6E90\u5E2E\u52A9\u60A8\u51C6\u5907\u96C5\u601D\u8003\u8BD5\u3002";
var APPB2C_registration_prepare_introLifeSkills5 = "\u6211\u4EEC\u53EF\u4EE5\u901A\u8FC7\u6211\u4EEC\u7684\u514D\u8D39\u8D44\u6E90\u5E2E\u52A9\u60A8\u4E3A\u751F\u6D3B\u6280\u80FD\u505A\u597D\u51C6\u5907\u3002";
var APPB2C_registration_prepare_onlineCourse_body_cta5 = "\u7ACB\u5373\u5F00\u59CB\u514D\u8D39\u8BD5\u7528";
var APPB2C_registration_prepare_onlineCourse_body_line15 = '\u6211\u4EEC\u7684\u56DB\u95E8"\u4E86\u89E3\u96C5\u601D\u4E13\u5BB6\u8BFE\u7A0B"\u5C06\u4E3A\u60A8\u63D0\u4F9B\u51C6\u5907\u96C5\u601D\u8003\u8BD5\u65F6\u9700\u8981\u4E86\u89E3\u7684\u6240\u6709\u5185\u5BB9\u7684\u5B8C\u6574\u6307\u5357\u3002\u60A8\u5C06\u6DF1\u5165\u4E86\u89E3\u96C5\u601D\u8003\u8BD5\u7684\u6BCF\u4E2A\u90E8\u5206\u2014\u2014\u9605\u8BFB\u3001\u5199\u4F5C\u3001\u542C\u529B\u548C\u53E3\u8BED\u3002\u60A8\u8FD8\u5C06\u83B7\u5F97\u6211\u4EEC\u7684\u89C6\u9891\u5BFC\u5E08\u548C\u7ECF\u9A8C\u4E30\u5BCC\u7684\u96C5\u601D\u6559\u80B2\u5DE5\u4F5C\u8005\u56E2\u961F\u7684\u5EFA\u8BAE\u3002\u52A0\u5165\u4E3A\u671F 7 \u5929\u7684\u514D\u8D39\u8BD5\u7528\u7248, \u8BA9\u60A8\u5728\u4E00\u5468\u5185\u8BBF\u95EE\u5168\u5957\u8BFE\u7A0B\u548C\u8BC4\u4F30\u3002';
var APPB2C_registration_prepare_onlineCourse_header5 = "\u5728\u7EBF\u8BFE\u7A0B: \u4E86\u89E3\u96C5\u601D";
var APPB2C_registration_prepare_pbPractice_body_cta5 = "\u8BBF\u95EE\u5B9E\u8DF5\u6D4B\u8BD5";
var APPB2C_registration_prepare_pbPractice_body_line15 = "\u5C06\u8FD9\u4E9B\u6D4B\u8BD5\u7528\u4E8E\u5B9A\u65F6\u7EC3\u4E60\u8BFE\u7A0B\u3002\u60A8\u8FD8\u53EF\u4EE5\u67E5\u770B\u548C\u6BD4\u8F83\u7B54\u6848\u3002";
var APPB2C_registration_prepare_pbPractice_header5 = "\u7EC3\u4E60\u5377";
var APPB2C_registration_prepare_practice_body_cta5 = "\u8BBF\u95EE\u6A21\u62DF\u6D4B\u8BD5";
var APPB2C_registration_prepare_practice_body_line15 = "\u5229\u7528\u6211\u4EEC\u7684\u514D\u8D39\u6A21\u62DF\u8003\u8BD5\u6765\u83B7\u5F97\u60A8\u9700\u8981\u7684\u96C5\u601D\u6210\u7EE9\u3002\u5982\u679C\u60A8\u5E0C\u671B\u79BB\u7EBF\u7EC3\u4E60, \u8BF7\u4ECE\u4ECB\u7ECD\u9875\u9762\u4E0B\u8F7D\u6D4B\u8BD5\u3001\u7A7A\u767D\u7B54\u5377\u3001\u6210\u7EE9\u5355\u548C\u7B54\u6848\u3002";
var APPB2C_registration_prepare_practice_header5 = "\u7EC3\u4E60\u6D4B\u8BD5";
var APPB2C_registration_prepare_prepApp_body_cta5 = "\u8BBF\u95EE\u96C5\u601D\u5907\u8003\u5E94\u7528\u7A0B\u5E8F";
var APPB2C_registration_prepare_prepApp_body_line15 = "\u96C5\u601D\u5E94\u7528\u7A0B\u5E8F\u5305\u542B\u6837\u9898\u3001\u7EC3\u4E60\u6D4B\u8BD5\u3001\u8BED\u6CD5\u63D0\u793A\u3001\u7EC3\u4E60\u3001\u6D4B\u9A8C\u7B49\u3002\u60A8\u8FD8\u53EF\u4EE5\u4F7F\u7528\u5B83\u6765\u8DDF\u8E2A\u8FDB\u5EA6\u3002";
var APPB2C_registration_prepare_prepApp_header5 = "\u96C5\u601D\u5907\u8003\u5E94\u7528\u7A0B\u5E8F";
var APPB2C_registration_prepare_prepApp_ukvi_cta15 = "\u82F9\u679C";
var APPB2C_registration_prepare_prepApp_ukvi_cta25 = "\u4EBA\u9020\u4EBA";
var APPB2C_registration_prepare_prepApp_ukvi_line15 = "\u5728\u4E13\u6CE8\u4E8E\u8003\u8BD5\u7684\u542C\u529B\u548C\u53E3\u8BED\u90E8\u5206\u7684\u540C\u65F6, \u6B64\u5E94\u7528\u7A0B\u5E8F\u8FD8\u5C06\u5E2E\u52A9\u60A8\u6269\u5C55\u8BCD\u6C47\u91CF, \u7EC3\u4E60\u8BED\u6CD5\u5E76\u4E86\u89E3\u8003\u8BD5\u5F53\u5929\u8BE5\u505A\u4EC0\u4E48\u3002\u60A8\u5C06\u80FD\u591F\u901A\u8FC7\u8FDB\u884C\u8BED\u6CD5\u7EC3\u4E60\u3001\u542C\u529B\u548C\u53E3\u8BED\u7EC3\u4E60\u6D4B\u8BD5\u4EE5\u53CA\u89C2\u770B\u96C5\u601D\u53E3\u8BED\u9762\u8BD5\u89C6\u9891\u6765\u6D4B\u8BD5\u60A8\u7684\u77E5\u8BC6\u3002";
var APPB2C_registration_prepare_prepApp_ukvi_line25 = "\u4E0B\u8F7D: ";
var APPB2C_registration_prepare_prepVideos_body_cta5 = "\u8BBF\u95EE\u96C5\u601D\u5907\u8003\u89C6\u9891";
var APPB2C_registration_prepare_prepVideos_body_line15 = "\u901A\u8FC7\u4E00\u7CFB\u5217\u89C6\u9891\u4E86\u89E3\u6D4B\u8BD5\u683C\u5F0F\u3002";
var APPB2C_registration_prepare_prepVideos_header5 = "\u96C5\u601D\u5907\u8003\u89C6\u9891";
var APPB2C_registration_prepare_reading_body_cta5 = "\u8BBF\u95EE\u7406\u89E3\u96C5\u601D: \u9605\u8BFB";
var APPB2C_registration_prepare_reading_body_line15 = "\u4E86\u89E3\u60A8\u9700\u8981\u4E86\u89E3\u7684\u6709\u5173\u96C5\u601D\u9605\u8BFB\u8003\u8BD5\u7684\u4FE1\u606F, \u4EE5\u63D0\u9AD8\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u3002";
var APPB2C_registration_prepare_reading_header5 = "\u4E86\u89E3\u96C5\u601D: \u9605\u8BFB";
var APPB2C_registration_prepare_rteLastMinute_body_cta5 = "\u73B0\u5728\u5F00\u59CB";
var APPB2C_registration_prepare_rteLastMinute_body_item15 = "9\u4E2A\u89C6\u9891\u63D0\u4F9B\u5EFA\u8BAE\u548C\u6559\u7A0B, ";
var APPB2C_registration_prepare_rteLastMinute_body_item25 = "100\u9879\u4E92\u52A8\u6D3B\u52A8, ";
var APPB2C_registration_prepare_rteLastMinute_body_item35 = "4 \u9879\u6280\u80FD\u4E2D\u6BCF\u4E00\u9879\u90FD\u6709 2 \u6B21\u7EC3\u4E60\u6D4B\u8BD5\u3002";
var APPB2C_registration_prepare_rteLastMinute_body_line15 = "\u901A\u8FC7\u4EE5\u4E0B\u65B9\u5F0F\u4E3A\u96C5\u601D\u8003\u8BD5\u505A\u597D\u51C6\u5907: ";
var APPB2C_registration_prepare_rteLastMinute_header5 = "\u96C5\u601D\u4E4B\u8DEF \u2013 \u6700\u540E\u4E00\u5206\u949F";
var APPB2C_registration_prepare_rteLastMinute_ukvi_cta5 = "\u8BBF\u95EE\u8BFE\u7A0B";
var APPB2C_registration_prepare_rteLastMinute_ukvi_line15 = "\u65E0\u9650\u5236\u5730\u53C2\u52A0\u96C5\u601D\u4E4B\u8DEF - \u6700\u540E\u4E00\u5206\u949F\u8BFE\u7A0B, \u8FD9\u662F\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u6700\u53D7\u6B22\u8FCE\u7684\u5728\u7EBF\u5907\u8003\u8BFE\u7A0B\u3002\u89C2\u770B\u63D0\u4F9B\u5EFA\u8BAE\u548C\u6559\u7A0B\u7684\u89C6\u9891, \u4F7F\u7528 100 \u4E2A\u4E92\u52A8\u6D3B\u52A8, \u5E76\u9488\u5BF9\u56DB\u4E2A\u6838\u5FC3\u6280\u80FD\u4E2D\u7684\u6BCF\u4E2A\u6280\u80FD\u8FDB\u884C\u4E24\u6B21\u7EC3\u4E60\u6D4B\u8BD5\u3002";
var APPB2C_registration_prepare_rteTestDrive_body_cta5 = "\u5C1D\u8BD5\u96C5\u601D\u8DEF\u8BD5\u9A7E\u7248";
var APPB2C_registration_prepare_rteTestDrive_body_item15 = "30\u4E2A\u4E92\u52A8\u7EC3\u4E60, ";
var APPB2C_registration_prepare_rteTestDrive_body_item25 = "4\u4E2A\u89C6\u9891\u63D0\u4F9B\u63D0\u793A\u548C\u5EFA\u8BAE, ";
var APPB2C_registration_prepare_rteTestDrive_body_item35 = "4 \u9879\u6280\u80FD\u4E2D\u6BCF\u4E00\u9879\u7684\u7EC3\u4E60\u6D4B\u8BD5\u3002";
var APPB2C_registration_prepare_rteTestDrive_body_line15 = "\u901A\u8FC7\u96C5\u601D\u4E4B\u8DEF\u8BD5\u9A7E\u5FEB\u901F\u5F00\u59CB\u60A8\u7684\u96C5\u601D\u4E4B\u65C5, \u5E76\u83B7\u5F97: ";
var APPB2C_registration_prepare_rteTestDrive_body_line25 = "\u6CE8\u518C\u5B8C\u6210\u540E, \u60A8\u8FD8\u5C06\u83B7\u5F97\u66F4\u591A\u6750\u6599\u3002";
var APPB2C_registration_prepare_rteTestDrive_header5 = "\u96C5\u601D\u8BD5\u9A7E\u4E4B\u8DEF \u2013 \u514D\u8D39\u7248";
var APPB2C_registration_prepare_speaking_body_cta5 = "\u8BBF\u95EE\u7406\u89E3\u96C5\u601D: \u53E3\u8BED";
var APPB2C_registration_prepare_speaking_body_line15 = "\u4E86\u89E3\u60A8\u9700\u8981\u4E86\u89E3\u7684\u6709\u5173\u96C5\u601D\u53E3\u8BED\u8003\u8BD5\u7684\u4FE1\u606F, \u4EE5\u63D0\u9AD8\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u3002";
var APPB2C_registration_prepare_speaking_header5 = "\u4E86\u89E3\u96C5\u601D: \u53E3\u8BED";
var APPB2C_registration_prepare_tutor_body_cta5 = "\u5355\u51FB\u6B64\u5904\u4EE5 1 \u7F8E\u5143\u7684\u4EF7\u683C\u5C1D\u8BD5\u60A8\u7684\u7B2C\u4E00\u6B21\u4F1A\u8BDD\u5E76\u4EAB\u53D7\u6B64\u4F18\u60E0\u3002";
var APPB2C_registration_prepare_tutor_body_line15 = "\u4E0E\u4E13\u4E1A\u7684\u96C5\u601D\u5BFC\u5E08\u4E00\u8D77\u51C6\u5907\u96C5\u601D\u8003\u8BD5\u3002\u6709\u8BB8\u591A\u8BFE\u7A0B\u53EF\u4F9B\u9009\u62E9, \u5982\u679C\u4E00\u5468\u6216\u51E0\u4E2A\u6708\u540E, \u60A8\u53EF\u4EE5\u4E3A\u96C5\u601D\u505A\u597D\u51C6\u5907\u3002\u7ACB\u5373\u5C1D\u8BD5\u60A8\u7684\u7B2C\u4E00\u4E2A\u4F1A\u8BDD, \u4EF7\u683C\u4E3A 1 \u7F8E\u5143\u3002";
var APPB2C_registration_prepare_tutor_body_line25 = "\u72EC\u5BB6\u4F18\u60E0: \u4F5C\u4E3A\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u7684\u96C5\u601D\u8003\u751F, \u5F53\u60A8\u8D2D\u4E70\u6211\u4EEC\u7684\u4E00\u5BF9\u4E00\u51C6\u5907\u8BFE\u7A0B\u4E4B\u4E00\u65F6, \u60A8\u53EF\u4EE5\u4EAB\u53D7 10% \u7684\u6298\u6263\u3002";
var APPB2C_registration_prepare_tutor_header5 = "1\u5BF91\u5BFC\u5E08 \u2013 \u96C5\u601D\u5907\u8003\u8BFE\u7A0B";
var APPB2C_registration_prepare_webinars_body_cta5 = "\u6CE8\u518C\u8BBF\u95EE";
var APPB2C_registration_prepare_webinars_body_item15 = "\u6307\u5BFC\u89C6\u9891";
var APPB2C_registration_prepare_webinars_body_item25 = "\u6240\u6709\u56DB\u79CD\u6280\u80FD\u7684\u8BE6\u7EC6\u5B66\u4E60\u8BA1\u5212";
var APPB2C_registration_prepare_webinars_body_item35 = "\u7EC3\u4E60\u6D4B\u8BD5";
var APPB2C_registration_prepare_webinars_body_line15 = "\u901A\u8FC7\u6211\u4EEC\u7684\u96C5\u601D\u4E13\u5BB6\u6BCF\u5468\u514D\u8D39\u4E3E\u529E\u7684\u96C5\u601D\u7F51\u7EDC\u7814\u8BA8\u4F1A, \u4E3A\u8003\u8BD5\u505A\u597D\u51C6\u5907\u3002\u60A8\u8FD8\u53EF\u4EE5\u901A\u8FC7\u4EE5\u4E0B\u65B9\u5F0F\u8BBF\u95EE\u6211\u4EEC\u7684\u96C5\u601D\u5B66\u4E60\u5305: ";
var APPB2C_registration_prepare_webinars_header5 = "\u96C5\u601D\u7F51\u7EDC\u7814\u8BA8\u4F1A";
var APPB2C_registration_results_bandScore_osrBooked5 = "\u91CD\u8003\u9884\u8BA2<1>\u67E5\u770B\u9884\u8BA2</1>";
var APPB2C_registration_results_bandScore_osrMarked5 = "\u91CD\u8003\u6807\u8BB0\u4E3A <1>\u67E5\u770B\u7ED3\u679C</1>";
var APPB2C_registration_results_eor_acknowledgement_backButton5 = "\u67E5\u770B\u6B64\u6D4B\u8BD5";
var APPB2C_registration_results_eor_acknowledgement_components5 = "\u60A8\u5DF2\u9009\u62E9\u8981\u91CD\u65B0\u6807\u8BB0\u7684\u6D4B\u8BD5\u7684\u4EE5\u4E0B\u90E8\u5206: ";
var APPB2C_registration_results_eor_acknowledgement_confirmation5 = "\u6211\u4EEC\u5DF2\u5C06\u672C EOR \u9884\u8BA2\u7684\u786E\u8BA4\u51FD\u53D1\u9001\u5230\u60A8\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u3002";
var APPB2C_registration_results_eor_acknowledgement_fee5 = "\u8D39";
var APPB2C_registration_results_eor_acknowledgement_info5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2 (EOR) \u5DF2\u88AB\u53D6\u6D88\u3002";
var APPB2C_registration_results_eor_acknowledgement_noteTitle5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_registration_results_eor_acknowledgement_payment5 = "\u5DF2\u5B8C\u6210\u4ED8\u6B3E";
var APPB2C_registration_results_eor_acknowledgement_paymentButton5 = "\u67E5\u770B\u60A8\u7684\u652F\u4ED8\u9009\u9879";
var APPB2C_registration_results_eor_acknowledgement_paymentNote5 = "\u60A8\u4ECD\u7136\u9700\u8981\u4ED8\u6B3E, \u5426\u5219\u60A8\u7684\u8BF7\u6C42\u5C06\u88AB\u53D6\u6D88";
var APPB2C_registration_results_eor_acknowledgement_reference5 = "\u63D0\u9AD8\u5B66\u4E60\u673A\u4F1A\u53C2\u8003";
var APPB2C_registration_results_eor_acknowledgement_success5 = "\u60A8\u7684\u7ED3\u679C\u67E5\u8BE2 (EOR) \u5DF2\u88AB\u9884\u8BA2, \u6B63\u5728\u7B49\u5F85\u51B3\u5B9A\u3002";
var APPB2C_registration_results_eor_acknowledgement_tax5 = "\u7A0E";
var APPB2C_registration_results_eor_acknowledgement_terms5 = "\u60A8\u540C\u610F\u8FD9\u4E9B<0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_registration_results_eor_acknowledgement_title5 = "\u590D\u8BAE\u786E\u8BA4";
var APPB2C_registration_results_eor_acknowledgement_total5 = "\u603B";
var APPB2C_registration_results_eor_postDeadline_back5 = "\u6211\u4E0D\u9700\u8981\u91CD\u65B0\u6807\u8BB0\u6211\u7684\u6D4B\u8BD5\u3002";
var APPB2C_registration_results_eor_postDeadline_email5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_registration_results_eor_postDeadline_help5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u6CE8\u518C\u53C2\u8003\u53F7, \u4EE5\u4FBF\u6211\u4EEC\u66F4\u597D\u5730\u5E2E\u52A9\u60A8: ";
var APPB2C_registration_results_eor_postDeadline_iol_launchChatLine5 = "\u6211\u9700\u8981\u5728\u7F51\u4E0A\u548C\u67D0\u4EBA\u804A\u5929: ";
var APPB2C_registration_results_eor_postDeadline_iol_line35 = "\u5982\u679C\u60A8\u5E0C\u671B\u6211\u4EEC\u8003\u8651\u60A8\u7684 EOR, \u8BF7\u8054\u7CFB\u6211\u4EEC\u4EE5\u63D0\u4F9B\u8BE6\u7EC6\u4FE1\u606F\u4EE5\u652F\u6301\u6B64\u8BF7\u6C42\u3002";
var APPB2C_registration_results_eor_postDeadline_line15 = '\u91CD\u65B0\u8BC4\u5206\u8BF7\u6C42(\u79F0\u4E3A\u7ED3\u679C\u67E5\u8BE2\u6216"EOR")\u5FC5\u987B\u5728\u8003\u8BD5\u4E4B\u65E5\u8D77 {{count}} \u5929\u5185\u63D0\u51FA\u3002';
var APPB2C_registration_results_eor_postDeadline_line25 = "\u7531\u4E8E EOR \u7533\u8BF7\u7684\u622A\u6B62\u65E5\u671F\u5DF2\u8FC7, \u60A8\u7684\u8BF7\u6C42\u65E0\u6CD5\u5728\u7EBF\u5904\u7406\u3002";
var APPB2C_registration_results_eor_postDeadline_line35 = "\u5982\u679C\u60A8\u5E0C\u671B\u4E2D\u5FC3\u8003\u8651\u60A8\u7684\u590D\u8BAE, \u8BF7\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3\u4EE5\u63D0\u4F9B\u8BE6\u7EC6\u4FE1\u606F\u4EE5\u652F\u6301\u6B64\u8BF7\u6C42\u3002";
var APPB2C_registration_results_eor_postDeadline_phone5 = "\u7535\u8BDD";
var APPB2C_registration_results_eor_preDeadline_back5 = "\u8FD4\u56DE\u7ED3\u679C";
var APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength5 = "\u60A8\u7684\u89E3\u91CA\u6700\u591A\u53EF\u4EE5\u662F {{count}} \u4E2A\u5B57\u7B26\u3002";
var APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber5 = "\u8BF7\u63D0\u4F9B\u6709\u6548\u7684\u6D4B\u8BD5\u62A5\u544A\u8868(TRF)\u7F16\u53F7\u3002";
var APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber5 = "\u6D4B\u8BD5\u62A5\u544A\u8868(TRF)\u7F16\u53F7\u5FC5\u987B\u4E0E\u7279\u5B9A\u683C\u5F0F\u4E00\u81F4(\u4F8B\u598211AA111110AAAA001A)\u3002";
var APPB2C_registration_results_eor_preDeadline_evidence5 = "\u8BF7\u4E0A\u4F20\u652F\u6301\u60A8\u91CD\u65B0\u6807\u8BB0\u8BF7\u6C42\u7684\u8BC1\u636E\u3002";
var APPB2C_registration_results_eor_preDeadline_evidenceRequired5 = "\u60A8\u5FC5\u987B\u63D0\u4F9B\u6B64\u8BC1\u636E\u6765\u652F\u6301\u60A8\u7684\u8BF7\u6C42\u3002";
var APPB2C_registration_results_eor_preDeadline_explanation5 = "\u8BF7\u5728\u4E0B\u9762\u7B80\u8981\u8BF4\u660E\u4E3A\u4EC0\u4E48\u60A8\u8BA4\u4E3A\u9700\u8981\u91CD\u65B0\u6807\u8BB0\u6240\u9009\u6D4B\u8BD5\u90E8\u4EF6";
var APPB2C_registration_results_eor_preDeadline_explanationRequired5 = "\u60A8\u5FC5\u987B\u63D0\u4F9B\u6B64\u8BF4\u660E\u4EE5\u652F\u6301\u60A8\u7684\u8BF7\u6C42\u3002";
var APPB2C_registration_results_eor_preDeadline_fee5 = "\u8D39";
var APPB2C_registration_results_eor_preDeadline_iolNote_line14 = "\u91CD\u65B0\u8BC4\u5206\u7531\u8BAD\u7EC3\u6709\u7D20\u7684\u9AD8\u7EA7\u5BA1\u67E5\u5458\u5B8C\u6210\u3002";
var APPB2C_registration_results_eor_preDeadline_iolNote_line25 = "\u7ED3\u679C\u53EF\u4EE5\u5728\u7533\u8BF7\u7684\u540C\u4E00\u5929\u548C\u7533\u8BF7\u540E\u6700\u591A 21 \u5929\u5185\u63D0\u4F9B, \u5177\u4F53\u53D6\u51B3\u4E8E\u51E0\u4E2A\u56E0\u7D20, \u5305\u62EC\u8981\u6C42\u91CD\u65B0\u6807\u8BB0\u7684\u90E8\u5206\u6570\u91CF\u3002";
var APPB2C_registration_results_eor_preDeadline_iolNote_line35 = "\u5982\u679C\u60A8\u5728 21 \u5929\u540E\u4ECD\u672A\u6536\u5230\u56DE\u590D, \u8BF7<0>\u8054\u7CFB\u6211\u4EEC</0>\u3002";
var APPB2C_registration_results_eor_preDeadline_legal5 = "\u6211\u540C\u610F EOR <0>\u6761\u6B3E\u548C\u6761\u4EF6</0>";
var APPB2C_registration_results_eor_preDeadline_legalNote5 = "\u82F1\u56FD\u6587\u5316\u534F\u4F1A\u5C06\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F\u6765\u5904\u7406\u60A8\u7684\u590D\u8BAE\u3002\u5904\u7406\u60A8\u7684\u4FE1\u606F\u7684\u6CD5\u5F8B\u4F9D\u636E\u662F\u540C\u610F\u6211\u4EEC\u7684 EOR \u6761\u6B3E\u548C\u6761\u4EF6\u3002";
var APPB2C_registration_results_eor_preDeadline_line15 = "\u5982\u679C\u60A8\u5BF9\u8003\u8BD5\u7684\u8BC4\u5206\u65B9\u5F0F\u4E0D\u6EE1\u610F, \u5E76\u4E14\u5E0C\u671B\u91CD\u65B0\u8BC4\u5206\u8003\u8BD5\u7684\u4EFB\u4F55\u90E8\u5206, \u60A8\u53EF\u4EE5\u5728\u8003\u8BD5\u65E5\u671F\u540E {{count}} \u5929\u5185\u7533\u8BF7\u7ED3\u679C\u67E5\u8BE2\u3002";
var APPB2C_registration_results_eor_preDeadline_line25 = "\u67E5\u8BE2\u96C5\u601D\u8003\u8BD5\u6210\u7EE9\u9700\u8981\u4ED8\u8D39, \u4F46\u662F\u5982\u679C\u60A8\u5728\u8003\u8BD5\u7684\u4EFB\u4F55\u9009\u5B9A\u90E8\u5206\u7684\u5206\u6570\u589E\u52A0, \u8FD9\u7B14\u8D39\u7528\u5C06\u9000\u8FD8\u3002";
var APPB2C_registration_results_eor_preDeadline_maxScore5 = "\u60A8\u83B7\u5F97\u4E86\u8FD9\u90E8\u5206\u7684\u6EE1\u5206\u3002\u60A8\u4E0D\u80FD\u8BF7\u6C42\u91CD\u65B0\u6807\u8BB0\u3002";
var APPB2C_registration_results_eor_preDeadline_note5 = "\u4ED8\u6B3E\u540E, \u60A8\u5C06\u65E0\u6CD5\u53D6\u6D88\u6216\u66F4\u6539\u60A8\u7684 EOR \u8BF7\u6C42\u3002";
var APPB2C_registration_results_eor_preDeadline_noteTitle5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel5 = "\u8C01\u5E94\u8BE5\u6536\u5230 EOR \u7684\u8BA2\u5355\u786E\u8BA4\uFF1F";
var APPB2C_registration_results_eor_preDeadline_paymentCta5 = "\u67E5\u770B\u4ED8\u6B3E\u65B9\u5F0F";
var APPB2C_registration_results_eor_preDeadline_paymentTitle5 = "\u652F\u4ED8\u91CD\u65B0\u6807\u8BB0\u8D39\u7528";
var APPB2C_registration_results_eor_preDeadline_payOfflineCTA5 = "\u4EE5\u5176\u4ED6\u65B9\u5F0F\u652F\u4ED8";
var APPB2C_registration_results_eor_preDeadline_payOnlineCTA5 = "\u5728\u7EBF\u652F\u4ED8";
var APPB2C_registration_results_eor_preDeadline_scoresRequired5 = "\u8BF7\u81F3\u5C11\u9009\u62E9\u4E00\u4E2A\u90E8\u5206\u3002";
var APPB2C_registration_results_eor_preDeadline_scoresTitle5 = "\u8BF7\u9009\u62E9\u6D4B\u8BD5\u7684\u4E00\u4E2A\u6216\u591A\u4E2A\u90E8\u5206\u8FDB\u884C\u91CD\u65B0\u8BC4\u5206: ";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr5 = "\u60A8\u5DF2\u9009\u62E9\u67E5\u8BE2\u60A8\u7684";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening5 = "\u542C\u529B\u91CD\u8003\u7ED3\u679C";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading5 = "\u9605\u8BFB\u91CD\u8003\u7ED3\u679C";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking5 = "\u53E3\u8BED\u91CD\u8003\u7ED3\u679C";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing5 = "\u5199\u4F5C\u91CD\u8003\u7ED3\u679C";
var APPB2C_registration_results_eor_preDeadline_tax5 = "\u542B\u7A0E";
var APPB2C_registration_results_eor_preDeadline_trfNumberLabel5 = "\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u7F16\u53F7";
var APPB2C_registration_results_eor_preDeadline_trfNumberSubnote5 = "\u8BF7\u63D0\u4F9B\u6BCF\u4E2A\u6D4B\u8BD5\u62A5\u544A\u8868\u53F3\u4E0B\u89D2\u7684 15-18 \u4F4D\u6570\u5B57\u3002";
var APPB2C_registration_results_eor_preDeadline_trfNumberValidation5 = "\u5B57\u6BB5\u5FC5\u987B\u4E0E\u7279\u5B9A\u683C\u5F0F\u5339\u914D(\u4F8B\u5982 11AA111110AAAA001A)\u3002";
var APPB2C_registration_results_eor_title5 = "\u91CD\u65B0\u8BC4\u5206(\u67E5\u8BE2\u6210\u7EE9)";
var APPB2C_registration_results_eor_titlePayment5 = "\u652F\u4ED8\u91CD\u65B0\u8BC4\u5206\u8D39\u7528 (EOR)";
var APPB2C_registration_results_hasResults_bandScore_decrease5 = "\u51CF\u5C11";
var APPB2C_registration_results_hasResults_bandScore_increase5 = "\u589E\u52A0";
var APPB2C_registration_results_hasResults_bandScore_unchanged5 = "\u53D8";
var APPB2C_registration_results_hasResults_eorAgentInfo4 = "\u5982\u679C\u60A8\u5E0C\u671B\u91CD\u65B0\u6807\u8BB0\u60A8\u7684\u6D4B\u8BD5, \u8BF7\u4E0E\u4EE3\u8868\u60A8\u53C2\u52A0\u6D4B\u8BD5\u7684\u4EE3\u7406\u8054\u7CFB\u3002";
var APPB2C_registration_results_hasResults_eorLetter5 = "\u8BF7\u67E5\u770B\u60A8\u7684 <0>EOR \u7ED3\u679C\u7ED3\u679C\u4FE1\u51FD<1></1></0>\u4E86\u89E3\u66F4\u591A\u8BE6\u60C5\u3002";
var APPB2C_registration_results_hasResults_eorLine15 = "\u60A8\u7684\u91CD\u65B0\u8BC4\u5206\u5DF2\u5B8C\u6210\u3002";
var APPB2C_registration_results_hasResults_eorLine25 = "\u60A8\u7684\u5206\u6570\u5728\u4E0B\u9762\u63D0\u4F9B\u3002\u91CD\u65B0\u6807\u8BB0\u7684\u90E8\u5206\u7A81\u51FA\u663E\u793A, \u4EE5\u4FBF\u4E8E\u53C2\u8003\u3002";
var APPB2C_registration_results_hasResults_eorLink5 = "\u6211\u5E0C\u671B\u6211\u7684\u6D4B\u8BD5\u88AB\u91CD\u65B0\u6807\u8BB0";
var APPB2C_registration_results_hasResults_eTrfCta4 = "\u4E0B\u8F7D\u60A8\u7684\u6210\u7EE9\u5355";
var APPB2C_registration_results_hasResults_explanations_heading5 = "\u60A8\u7684\u5206\u6570\u89E3\u91CA: ";
var APPB2C_registration_results_hasResults_explanations_hideButton4 = "\u9690\u85CF\u5206\u6570\u8BF4\u660E";
var APPB2C_registration_results_hasResults_explanations_improve5 = "\u5982\u4F55\u6539\u8FDB: ";
var APPB2C_registration_results_hasResults_explanations_showButton4 = "\u663E\u793A\u5206\u6570\u8BF4\u660E";
var APPB2C_registration_results_hasResults_lifeSkills_failed5 = "\u5931\u8D25";
var APPB2C_registration_results_hasResults_lifeSkills_level5 = "\u4E2D\u6B27\u6CD5\u7EA7\u522B";
var APPB2C_registration_results_hasResults_lifeSkills_note5 = "\u60A8\u5728\u7EBF\u63D0\u4F9B\u7684\u7ED3\u679C\u53EA\u662F\u4E34\u65F6\u7684, \u4E0D\u5E94\u7528\u4F5C\u5BF9\u60A8\u7684\u8868\u73B0\u7684\u5B98\u65B9\u786E\u8BA4\u3002\u96C5\u601D\u8003\u8BD5\u6210\u7EE9\u5355(TRF)\u662F\u786E\u8BA4\u60A8\u6210\u7EE9\u7684\u5B98\u65B9\u6587\u4EF6\u3002";
var APPB2C_registration_results_hasResults_lifeSkills_passed5 = "\u901A\u8FC7";
var APPB2C_registration_results_hasResults_lifeSkills_result5 = "\u7ED3\u679C";
var APPB2C_registration_results_hasResults_osr_bandScore_decrease4 = "\u51CF\u5C11";
var APPB2C_registration_results_hasResults_osr_bandScore_increase4 = "\u589E\u52A0";
var APPB2C_registration_results_hasResults_osr_bandScore_unchanged4 = "\u53D8";
var APPB2C_registration_results_hasResults_osr_previousResults5 = "\u5B8C\u6574\u67E5\u770B\u4EE5\u524D\u7684\u6D4B\u8BD5\u7ED3\u679C";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased5 = "\u60A8\u7684\u603B\u5206\u5DF2\u4ECE {{originalScore}} \u4E0B\u964D\u5230 {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased5 = "\u60A8\u7684\u603B\u5206\u5DF2\u4ECE {{originalScore}} \u589E\u52A0\u5230 {{osrScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged5 = "\u60A8\u7684\u603B\u5206\u4FDD\u6301\u4E0D\u53D8, \u4E3A {{originalScore}}";
var APPB2C_registration_results_hasResults_osr_updateBar_title5 = "\u60A8\u7684 {{osrSkillName}} \u91CD\u8003\u6210\u7EE9 ({{\u5206\u6570}}) \u5DF2\u8BA1\u5165\u60A8\u7684\u65B0\u603B\u5206\u3002";
var APPB2C_registration_results_hasResults_osrLink4 = "\u6211\u60F3\u91CD\u65B0\u53C2\u52A0\u4E00\u90E8\u5206\u8003\u8BD5";
var APPB2C_registration_results_hasResults_posting_oneCopyWarning5 = "\u53EA\u53D1\u9001\u4E00\u4EFD\u526F\u672C, \u56E0\u6B64\u8BF7\u59A5\u5584\u4FDD\u7BA1\uFF01";
var APPB2C_registration_results_hasResults_posting_postedOn5 = "\u60A8\u7684\u6D4B\u8BD5\u62A5\u544A\u8868\u5DF2\u53D1\u5E03\u5728: ";
var APPB2C_registration_results_hasResults_posting_postedTo5 = "\u5B83\u5DF2\u53D1\u5E03\u5230: ";
var APPB2C_registration_results_hasResults_posting_willPostOn5 = "\u60A8\u7684\u6D4B\u8BD5\u62A5\u544A\u8868\u5C06\u53D1\u5E03\u5728: ";
var APPB2C_registration_results_hasResults_posting_willPostTo5 = "\u5B83\u5C06\u88AB\u53D1\u5E03\u5230: ";
var APPB2C_registration_results_hasResults_rankNameConsonant5 = "\u8FD9\u610F\u5473\u7740\u60A8\u662F";
var APPB2C_registration_results_hasResults_rankNameVowel5 = "\u8FD9\u610F\u5473\u7740\u60A8\u662F";
var APPB2C_registration_results_hasResults_scoreImprove_btn5 = "\u67E5\u770B\u6211\u7684\u9009\u9879";
var APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn5 = "\u8FD4\u56DE\u6D4B\u8BD5\u7ED3\u679C\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description5 = "\u5982\u679C\u60A8\u5BF9\u8003\u8BD5\u591A\u4E2A\u90E8\u5206\u7684\u5206\u6570\u4E0D\u6EE1\u610F, \u53EF\u4EE5\u5FEB\u901F\u5411\u6211\u4EEC\u9884\u8BA2\u65B0\u7684\u8003\u8BD5\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title5 = "\u9884\u7EA6\u65B0\u6D4B\u8BD5";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description5 = "\u5982\u679C\u60A8\u5BF9\u8003\u8BD5\u7684\u8BC4\u5206\u65B9\u5F0F\u4E0D\u6EE1\u610F, \u5E76\u4E14\u5E0C\u671B\u91CD\u65B0\u8BC4\u5206\u8003\u8BD5\u7684\u4EFB\u4F55\u90E8\u5206, \u60A8\u53EF\u4EE5\u5728\u8003\u8BD5\u65E5\u671F\u540E {{count}} \u5929\u5185\u7533\u8BF7\u7ED3\u679C\u67E5\u8BE2\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title5 = "\u91CD\u65B0\u6807\u8BB0\u60A8\u7684\u6D4B\u8BD5";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description5 = "\u5982\u679C\u60A8\u5BF9\u8003\u8BD5\u4EFB\u4F55\u4E00\u4E2A\u90E8\u5206\u7684\u5206\u6570\u4E0D\u6EE1\u610F, \u5E76\u8BA4\u4E3A\u8FD9\u662F\u7531\u4E8E\u60A8\u5728\u8003\u8BD5\u5F53\u5929\u7684\u8868\u73B0, \u60A8\u53EF\u4EE5\u6CE8\u518C\u4E00\u9879\u6280\u80FD\u91CD\u8003\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title5 = "\u91CD\u8003\u90E8\u5206\u8003\u8BD5";
var APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle5 = "\u5982\u679C\u60A8\u60F3\u63D0\u9AD8\u603B\u5206, \u6709\u4EE5\u4E0B\u9009\u62E9: ";
var APPB2C_registration_results_hasResults_scoreImprove_subtitle5 = "\u5982\u679C\u60A8\u6CA1\u6709\u83B7\u5F97\u6240\u9700\u7684\u5206\u6570, \u60A8\u4E5F\u8BB8\u53EF\u4EE5\u63D0\u9AD8\u5B83\u3002";
var APPB2C_registration_results_hasResults_scoreImprove_title5 = "\u6CA1\u6709\u5F97\u5230\u60A8\u9700\u8981\u7684\u5206\u6570\uFF1F";
var APPB2C_registration_results_hasResults_scores_label5 = "\u60A8\u7684\u6EE1\u5206\u548C\u89E3\u91CA";
var APPB2C_registration_results_hasResults_scores_Listening5 = "\u6CE8\u610F\u7684";
var APPB2C_registration_results_hasResults_scores_Overall5 = "\u60A8\u7684\u603B\u5206";
var APPB2C_registration_results_hasResults_scores_Reading5 = "\u8BFB\u6570";
var APPB2C_registration_results_hasResults_scores_Speaking5 = "\u8BF4";
var APPB2C_registration_results_hasResults_scores_Writing5 = "\u5199\u4F5C";
var APPB2C_registration_results_hasResults_share_applyBoard5 = "\u76F4\u63A5\u5E94\u7528\u4E8E\u7A0B\u5E8F";
var APPB2C_registration_results_hasResults_share_RecognisingOrgsLink5 = "\u4E0E\u7EC4\u7EC7\u5171\u4EAB\u7ED3\u679C";
var APPB2C_registration_results_hasResults_share_subtitle5 = "\u4EE5\u4E0B\u662F\u60A8\u73B0\u5728\u53EF\u4EE5\u6267\u884C\u7684\u64CD\u4F5C: ";
var APPB2C_registration_results_hasResults_share_title5 = "\u62FF\u5230\u4E86\u4F60\u9700\u8981\u7684\u5206\u6570\u5417\uFF1F";
var APPB2C_registration_results_hasResults_share_trfCta5 = "\u4E0B\u8F7D\u60A8\u7684\u6D4B\u8BD5\u62A5\u544A\u8868";
var APPB2C_registration_results_hasResults_viewExplanation5 = "\u67E5\u770B\u5B8C\u6574\u5206\u6570\u548C\u8BF4\u660E";
var APPB2C_registration_results_noResults_cmds_title5 = "\u8981\u67E5\u770B\u6709\u5173\u7ED3\u679C\u7684\u4FE1\u606F(\u5305\u62EC\u7ED3\u679C\u7684\u53EF\u7528\u65F6\u95F4), \u8BF7\u5355\u51FB<0>here</0>\u3002\u6211\u4EEC\u5C06\u5411\u60A8\u53D1\u9001\u4E00\u4E2A\u94FE\u63A5, \u4EE5\u8BBF\u95EE\u60A8\u7684\u7ED3\u679C\u5E76\u4E0B\u8F7D\u60A8\u7684\u7535\u5B50\u8003\u8BD5\u6210\u7EE9\u5355 (TRF)\u3002";
var APPB2C_registration_results_noResults_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_registration_results_noResults_osr_booked5 = "\u91CD\u8003\u9884\u8BA2";
var APPB2C_registration_results_noResults_osr_previousScores5 = "\u60A8\u4E4B\u524D\u7684\u5206\u6570: ";
var APPB2C_registration_results_noResults_title5 = "\u8981\u67E5\u770B\u6709\u5173\u7ED3\u679C\u7684\u4FE1\u606F(\u5305\u62EC\u7ED3\u679C\u7684\u53EF\u7528\u65F6\u95F4), \u8BF7\u5355\u51FB<0>here</0>\u3002\u4E00\u65E6\u51C6\u5907\u597D, \u60A8\u5C06\u6536\u5230\u4E00\u4E2A\u94FE\u63A5, \u4EE5\u5728\u7EBF\u8BBF\u95EE\u60A8\u7684\u7ED3\u679C\u3002";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote15 = '\u60A8\u4ECD\u7136\u53EF\u4EE5\u9009\u62E9\u5176\u4ED6$t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })\u5C06\u7ED3\u679C\u53D1\u9001\u5230\u3002';
var APPB2C_registration_results_organisations_availableReceivingOrgsNote25 = "\u7533\u8BF7\u5F00\u653E\u81F3{{\u65E5\u671F}}\u3002";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote34 = "\u7EC4\u7EC7\u5728\u63A5\u53D7\u6210\u7EE9\u5355\u65B9\u9762\u53EF\u80FD\u6709\u4E0D\u540C\u7684\u89C4\u5219, \u6700\u77ED\u6709\u6548\u671F\u4E3A\u4E24\u5E74\u3002\u8BF7\u5728\u7533\u8BF7\u524D\u54A8\u8BE2\u60A8\u7684\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_chosenOrganisationsHeader5 = "\u9009\u62E9\u5C06\u7ED3\u679C\u53D1\u9001\u5230\u7684\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_addAnother5 = "+ \u6DFB\u52A0\u5176\u4ED6\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew5 = "+ \u6DFB\u52A0\u65B0\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_address5 = "\u5730\u5740: ";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_country5 = "\u9009\u62E9\u4E00\u4E2A\u56FD\u5BB6/\u5730\u533A";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll5 = "\u90FD";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional5 = "\u9009\u62E9\u56FD\u5BB6/\u5730\u533A: (\u53EF\u9009)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_file5 = "\u6587\u4EF6/\u6848\u4F8B\u7F16\u53F7: (\u53EF\u9009)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_org5 = "\u9009\u62E9\u4E00\u4E2A\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation5 = "\u7EC4\u7EC7\u540D\u79F0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_person5 = "\u4EBA\u5458/\u90E8\u95E8\u59D3\u540D: (\u53EF\u9009)";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode5 = "\u90AE\u653F\u7F16\u7801";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry5 = "\u6309\u56FD\u5BB6/\u5730\u533A\u540D\u79F0\u641C\u7D22";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg5 = "\u6309\u7EC4\u7EC7\u540D\u79F0\u641C\u7D22";
var APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength5 = "\u6587\u4EF6/\u6848\u4F8B\u7F16\u53F7\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u4E2A\u5B57\u7B26\u957F\u3002";
var APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength5 = "\u4EBA\u5458/\u90E8\u95E8\u540D\u79F0\u6700\u591A\u53EF\u4EE5\u6709 {{count}} \u4E2A\u5B57\u7B26\u957F\u3002";
var APPB2C_registration_results_organisations_editRo_error_minScoreMissing5 = "\u8BF7\u4E3A\u60A8\u7684\u6574\u4F53\u4E50\u961F\u9009\u62E9\u6700\u4F4E\u5206\u6570\u3002";
var APPB2C_registration_results_organisations_editRo_error_missingAddressLine15 = "\u8BF7\u63D0\u4F9B\u7EC4\u7EC7\u5730\u5740\u3002";
var APPB2C_registration_results_organisations_editRo_error_missingOrgName5 = "\u8BF7\u63D0\u4F9B\u7EC4\u7EC7\u540D\u79F0\u3002";
var APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength5 = "\u7EC4\u7EC7\u540D\u79F0\u7684\u957F\u5EA6\u6700\u591A\u4E3A {{count}} \u4E2A\u5B57\u7B26\u3002";
var APPB2C_registration_results_organisations_editRo_iol_addCta5 = "\u6DFB\u52A0\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_iol_address5 = "\u5730\u5740: ";
var APPB2C_registration_results_organisations_editRo_iol_cancelAdd4 = "\u53D6\u6D88";
var APPB2C_registration_results_organisations_editRo_iol_cancelEdit5 = "\u653E\u5F03\u66F4\u6539";
var APPB2C_registration_results_organisations_editRo_iol_conditions_clear5 = "\u6E05\u695A";
var APPB2C_registration_results_organisations_editRo_iol_conditions_header5 = "\u60A8\u60F3\u4E3A\u8FD9\u4E2A\u7EC4\u7EC7\u9009\u62E9\u6761\u4EF6\u5206\u6570\u5417\uFF1F";
var APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder5 = "\u8BF7\u9009\u62E9";
var APPB2C_registration_results_organisations_editRo_iol_conditions_no5 = "\u4E0D";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note15 = "\u8BF7\u5728\u8BE5\u7EC4\u7EC7\u6536\u5230\u60A8\u7684\u7ED3\u679C\u4E4B\u524D\u9009\u62E9\u60A8\u60F3\u8981\u5F97\u5206\u7684\u6700\u4F4E\u603B\u5206\u3002";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note25 = "\u60A8\u8FD8\u53EF\u4EE5\u4E3A\u6BCF\u4E2A\u6D4B\u8BD5\u7EC4\u4EF6\u9009\u62E9\u6700\u4F4E\u5206\u6570: ";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note35 = "\u53EA\u6709\u5F53\u60A8\u8FBE\u5230\u60A8\u8BBE\u5B9A\u7684\u6BCF\u4E2A\u5206\u6570\u65F6, \u6211\u4EEC\u624D\u4F1A\u5C06\u60A8\u7684\u7ED3\u679C\u53D1\u9001\u7ED9\u8BE5\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder5 = "\u81EA\u9009";
var APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader5 = "\u53EA\u6709\u5F53\u60A8\u83B7\u5F97\u6240\u9700\u7684\u5206\u6570\u65F6, \u6211\u4EEC\u624D\u4F1A\u5C06\u60A8\u7684\u7ED3\u679C\u53D1\u9001\u7ED9\u4ED6\u4EEC\u3002";
var APPB2C_registration_results_organisations_editRo_iol_conditions_yes5 = "\u662F\u7684";
var APPB2C_registration_results_organisations_editRo_iol_copied5 = "\u7535\u5B50\u90AE\u4EF6\u5730\u5740\u5DF2\u590D\u5236";
var APPB2C_registration_results_organisations_editRo_iol_copy5 = "\u590D\u5236\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery5 = "\u8BE5\u7EC4\u7EC7\u76F4\u63A5\u4ECE\u6211\u4EEC\u8FD9\u91CC\u63A5\u53D7\u60A8\u7684\u7535\u5B50\u6210\u7EE9\u5355\u3002\u5B83\u4E0D\u4F1A\u5F71\u54CD\u60A8\u53EF\u4EE5\u9009\u62E9\u591A\u5C11\u4E2A\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults5 = "\u7535\u5B50\u6210\u7EE9\u5355";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note5 = "\u60A8\u53EF\u4EE5\u5C06\u60A8\u7684 eTRF \u53D1\u9001\u7ED9\u4EFB\u610F\u6570\u91CF\u7684\u7EC4\u7EC7, \u4F46\u60A8\u6700\u591A\u53EA\u80FD\u9009\u62E9 {{number}} \u4E2A TRF \u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal5 = "\u6211\u4EEC\u5C06\u5411\u8BE5\u673A\u6784\u53D1\u9001\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u5355(TRF)\u3002<br>\u5B83\u8BA1\u5165\u60A8 {{number}} \u4E2A TRF \u7EC4\u7EC7\u7684\u9650\u5236\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf5 = "\u6210\u7EE9\u5355";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note15 = "\u8BE5\u7EC4\u7EC7\u63A5\u53D7\u96C5\u601D\u5728\u7EBF\u8003\u8BD5, \u4F46\u672A\u6CE8\u518C\u76F4\u63A5\u4ECE\u6211\u4EEC\u8FD9\u91CC\u63A5\u6536\u7535\u5B50\u6210\u7EE9\u5355\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note25 = "\u6536\u5230\u6210\u7EE9\u5355\u540E, \u60A8\u9700\u8981\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u5C06\u6210\u7EE9\u5355\u526F\u672C\u53D1\u9001\u7ED9\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results5 = "\u60A8\u9700\u8981\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u5C06\u60A8\u7684\u6210\u7EE9\u5355\u526F\u672C\u53D1\u9001\u7ED9\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_editRo_iol_newOrgTitle5 = "\u6DFB\u52A0\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_iol_noResults5 = "\u6682\u65E0\u7ED3\u679C";
var APPB2C_registration_results_organisations_editRo_iol_removeLink5 = "\u79FB\u9664\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_editRo_iol_saveCta5 = "\u4FDD\u5B58\u66F4\u6539";
var APPB2C_registration_results_organisations_editRo_iol_validation_overallScore5 = "\u60A8\u7684\u6574\u4F53\u4E50\u961F\u7684\u6700\u4F4E\u5206\u6570\u662F\u5FC5\u9700\u7684\u3002";
var APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation5 = "\u8BF7\u9009\u62E9\u4E00\u4E2A\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_iol_chooseCta5 = "\u9009\u62E9\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_iol_delivery_auto_header5 = "\u4EE5\u4E0B\u662F\u60A8\u9009\u62E9\u7684\u5C06\u81EA\u52A8\u6536\u5230\u60A8\u7684eTRF\u7684\u7EC4\u7EC7: ";
var APPB2C_registration_results_organisations_iol_delivery_auto_note5 = "\u6211\u4EEC\u5C06\u4E3A\u60A8\u5411\u8FD9\u4E9B\u7EC4\u7EC7\u53D1\u9001\u60A8\u7684\u7535\u5B50\u6210\u7EE9\u5355\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote5 = "\u6211\u4EEC\u5C1A\u672A\u5411\u8FD9\u4E9B\u7EC4\u7EC7\u53D1\u9001\u60A8\u7684 eTRF, \u56E0\u4E3A\u60A8\u6CA1\u6709\u8FBE\u5230\u60A8\u4E3A\u6BCF\u4E2A\u7EC4\u7EC7\u8BBE\u7F6E\u7684\u6700\u4F4E\u603B\u7B49\u7EA7\u3002";
var APPB2C_registration_results_organisations_iol_delivery_auto_sentNote5 = "\u6211\u4EEC\u5DF2\u5C06\u60A8\u7684eTRF\u53D1\u9001\u7ED9\u8FD9\u4E9B\u7EC4\u7EC7\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_iol_delivery_postal_header5 = "\u4EE5\u4E0B\u662F\u60A8\u9009\u62E9\u7684\u9700\u8981\u4EBA\u5DE5\u9012\u9001\u7684\u7EC4\u7EC7: ";
var APPB2C_registration_results_organisations_iol_delivery_postal_note15 = "\u8FD9\u4E9B\u7EC4\u7EC7\u63A5\u53D7\u96C5\u601D\u5728\u7EBF\u8003\u8BD5, \u4F46\u672A\u6CE8\u518C\u76F4\u63A5\u4ECE\u6211\u4EEC\u8FD9\u91CC\u63A5\u6536\u7535\u5B50\u6210\u7EE9\u5355\u3002";
var APPB2C_registration_results_organisations_iol_delivery_postal_note25 = "\u6536\u5230\u6210\u7EE9\u5355\u540E, \u60A8\u9700\u8981\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u5C06\u6210\u7EE9\u5355\u526F\u672C\u53D1\u9001\u7ED9\u6BCF\u4E2A\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2_results5 = "\u60A8\u9700\u8981\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u5C06\u6210\u7EE9\u5355\u526F\u672C\u53D1\u9001\u7ED9\u6BCF\u4E2A\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_iol_edit5 = "\u6539\u53D8";
var APPB2C_registration_results_organisations_iol_error5 = "\u52A0\u8F7D\u66F4\u6539\u65F6\u51FA\u9519\u3002<0></0>\u8BF7\u5C1D\u8BD5<1>\u5237\u65B0</1>\u9875\u9762\u3002";
var APPB2C_registration_results_organisations_iol_generalCondition5 = "\u53EA\u6709\u5F53\u60A8\u8FBE\u5230\u4EE5\u4E0B\u6700\u4F4E\u603B\u5206\u65F6, \u6211\u4EEC\u624D\u4F1A\u5411\u8BE5\u7EC4\u7EC7\u53D1\u9001\u60A8\u7684\u7ED3\u679C: ";
var APPB2C_registration_results_organisations_iol_noConditions5 = "\u60A8\u6CA1\u6709\u4E3A\u6B64\u7EC4\u7EC7\u8BBE\u7F6E\u4EFB\u4F55\u6761\u4EF6\u5206\u6570\u3002";
var APPB2C_registration_results_organisations_iol_notChosen5 = "\u672A\u9009\u62E9";
var APPB2C_registration_results_organisations_iol_preciseCondition5 = "\u53EA\u6709\u5F53\u60A8\u8FBE\u5230\u60A8\u8BBE\u5B9A\u7684\u6BCF\u4E2A\u5206\u6570\u65F6, \u6211\u4EEC\u624D\u4F1A\u5C06\u60A8\u7684\u7ED3\u679C\u53D1\u9001\u7ED9\u8BE5\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_iol_roAccept_link5 = "\u8BF7\u67E5\u770B\u54EA\u4E9B\u7EC4\u7EC7\u63A5\u53D7\u96C5\u601D\u5728\u7EBF\u8003\u8BD5\u6210\u7EE9";
var APPB2C_registration_results_organisations_iol_roAccept_message5 = "\u5982\u679C\u60A8\u53C2\u52A0\u96C5\u601D\u5728\u7EBF\u8003\u8BD5, \u90A3\u4E48\u60A8\u9700\u8981\u68C0\u67E5\u60A8\u7684\u7EC4\u7EC7\u662F\u5426\u63A5\u53D7\u8FD9\u4E00\u70B9\u3002";
var APPB2C_registration_results_organisations_iol_roAccept_title5 = "\u60A8\u7684\u76EE\u6807\u7EC4\u7EC7\u6216\u673A\u6784\u662F\u5426\u63A5\u53D7\u5728\u7EBF\u96C5\u601D\u8003\u8BD5\uFF1F";
var APPB2C_registration_results_organisations_iol_sendAnyway5 = "\u65E0\u8BBA\u5982\u4F55, \u5C06\u6211\u7684\u7ED3\u679C\u53D1\u9001\u7ED9\u8BE5\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_list_item15 = "\u5B66\u672F\u673A\u6784";
var APPB2C_registration_results_organisations_list_item25 = "\u653F\u5E9C\u673A\u6784";
var APPB2C_registration_results_organisations_list_item35 = "\u4E13\u4E1A\u56E2\u4F53";
var APPB2C_registration_results_organisations_list_item45 = "\u96C7\u4E3B";
var APPB2C_registration_results_organisations_mod_delivery_auto_header5 = "\u4EE5\u4E0B\u662F\u60A8\u9009\u62E9\u7684\u5C06\u81EA\u52A8\u6536\u5230\u60A8\u7684eTRF\u7684\u7EC4\u7EC7: ";
var APPB2C_registration_results_organisations_mod_delivery_auto_note5 = "\u6211\u4EEC\u5C06\u4E3A\u60A8\u5411\u8FD9\u4E9B\u7EC4\u7EC7\u53D1\u9001\u60A8\u7684\u7535\u5B50\u6210\u7EE9\u5355\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002\u60A8\u53EF\u4EE5\u6839\u636E\u9700\u8981\u9009\u62E9\u4EFB\u610F\u6570\u91CF\u7684\u8FD9\u4E9B\u3002";
var APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote5 = "\u6211\u4EEC\u5C1A\u672A\u5411\u8FD9\u4E9B\u7EC4\u7EC7\u53D1\u9001\u60A8\u7684 eTRF, \u56E0\u4E3A\u60A8\u6CA1\u6709\u8FBE\u5230\u60A8\u4E3A\u6BCF\u4E2A\u7EC4\u7EC7\u8BBE\u7F6E\u7684\u6700\u4F4E\u603B\u7B49\u7EA7\u3002";
var APPB2C_registration_results_organisations_mod_delivery_auto_sentNote5 = "\u6211\u4EEC\u5DF2\u5C06\u60A8\u7684eTRF\u53D1\u9001\u7ED9\u8FD9\u4E9B\u7EC4\u7EC7\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_header5 = "\u4EE5\u4E0B\u662F\u60A8\u9009\u62E9\u7684\u7EC4\u7EC7, \u6211\u4EEC\u4F1A\u5C06\u60A8\u7684\u6210\u7EE9\u5355\u90AE\u5BC4\u5230: ";
var APPB2C_registration_results_organisations_mod_delivery_postal_note15 = "\u6211\u4EEC\u4F1A\u5C06\u60A8\u7684\u8003\u8BD5\u6210\u7EE9\u5355 (TRF) \u90AE\u5BC4\u7ED9\u8FD9\u4E9B\u7EC4\u7EC7\u3002\u60A8\u6700\u591A\u53EA\u80FD\u9009\u62E9\u5176\u4E2D\u7684 {{count}}\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_note25 = "\u5982\u679C\u60A8\u5728\u6211\u4EEC\u7684\u5217\u8868\u4E2D\u627E\u4E0D\u5230\u7EC4\u7EC7, \u53EF\u4EE5\u6DFB\u52A0\u81EA\u5DF1\u7684\u7EC4\u7EC7\u3002\u5B83\u4EEC\u5C06\u88AB\u8BA1\u4E3A\u60A8\u7684\u6210\u7EE9\u5355\u9009\u62E9\u4E4B\u4E00\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_note35 = '\u60A8\u4ECD\u7136\u53EF\u4EE5\u9009\u62E9\u5176\u4ED6$t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} })\u5C06\u7ED3\u679C\u53D1\u9001\u5230\u3002';
var APPB2C_registration_results_organisations_mod_delivery_postal_note45 = '\u65E2\u7136\u60A8\u5DF2\u7ECF\u9009\u62E9\u4E86 $t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} }), \u60A8\u5C31\u4E0D\u80FD\u518D\u9009\u62E9\u4E86\u3002\u60A8\u4ECD\u7136\u53EF\u4EE5\u9009\u62E9\u63A5\u53D7 eTRF \u7684\u7EC4\u7EC7\u3002';
var APPB2C_registration_results_organisations_mod_delivery_postal_note55 = "\u60A8\u5DF2\u8FBE\u5230\u6211\u4EEC\u53EF\u4EE5\u5C06\u60A8\u7684\u7ED3\u679C\u53D1\u9001\u7ED9\u7684\u7EC4\u7EC7\u7684\u9650\u5236\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote5 = "\u6211\u4EEC\u6CA1\u6709\u5411\u8FD9\u4E9B\u7EC4\u7EC7\u53D1\u9001\u60A8\u7684\u6210\u7EE9\u5355, \u56E0\u4E3A\u60A8\u6CA1\u6709\u8FBE\u5230\u4E3A\u6BCF\u4E2A\u7EC4\u7EC7\u8BBE\u5B9A\u7684\u6700\u4F4E\u603B\u6210\u7EE9\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_sentNote5 = "\u6211\u4EEC\u5DF2\u5411\u8FD9\u4E9B\u7EC4\u7EC7\u53D1\u9001\u4E86\u60A8\u7684\u6210\u7EE9\u5355\u3002\u4F60\u4E0D\u9700\u8981\u505A\u4EFB\u4F55\u4E8B\u60C5\u3002";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount5 = "{{\u8BA1\u6570}}\u6210\u7EE9\u5355\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other5 = "{{\u8BA1\u6570}}\u6210\u7EE9\u5355\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_mod_howTo_item15 = "<bold>eTRF</bold>: \u76F4\u63A5\u4ECE\u6211\u4EEC\u8FD9\u91CC\u63A5\u53D7\u60A8\u7684\u7535\u5B50\u6D4B\u8BD5\u62A5\u544A\u8868(eTRF)\u7684\u7EC4\u7EC7\u3002\u60A8\u53EF\u4EE5\u6839\u636E\u9700\u8981\u9009\u62E9\u4EFB\u610F\u6570\u91CF\u7684\u8FD9\u4E9B\u3002";
var APPB2C_registration_results_organisations_mod_howTo_item25 = "TRF: \u6211\u4EEC\u4EE3\u8868\u60A8\u5411\u5176\u53D1\u9001\u8003\u8BD5\u6210\u7EE9\u5355 (<bold>TRF</bold>) \u7684\u7EC4\u7EC7\u3002\u60A8\u6700\u591A\u53EA\u80FD\u9009\u62E9\u5176\u4E2D\u7684 {<bold>{\u6570\u5B57}}</bold>\u3002";
var APPB2C_registration_results_organisations_mod_howTo_line15 = "\u60A8\u53EF\u4EE5\u4ECE\u4E24\u79CD\u7C7B\u578B\u7684\u7EC4\u7EC7\u4E2D\u8FDB\u884C\u9009\u62E9";
var APPB2C_registration_results_organisations_mod_howTo_title5 = "\u5982\u4F55\u9009\u62E9\u60A8\u7684\u7EC4\u7EC7";
var APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote5 = "\u6CA1\u6709\u9009\u62E9\u4EFB\u4F55\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_note05 = "\u6211\u4EEC\u53EF\u4EE5\u4EE3\u8868\u60A8\u5C06\u60A8\u7684\u7ED3\u679C\u53D1\u9001\u7ED9\u7EC4\u7EC7\u3002";
var APPB2C_registration_results_organisations_note15 = '\u6211\u4EEC\u6700\u591A\u53EF\u4EE5\u5C06\u60A8\u7684\u7ED3\u679C\u53D1\u9001\u5230$t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })\u3002';
var APPB2C_registration_results_organisations_note25 = "\u8FD9\u4E9B\u53EF\u80FD\u5305\u62EC: ";
var APPB2C_registration_results_organisations_organisationsCount5 = "{{\u8BA1\u6570}} \u7EC4\u7EC7";
var APPB2C_registration_results_organisations_organisationsCount_other5 = "{{\u8BA1\u6570}} \u7EC4\u7EC7";
var APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote5 = "\u60A8\u5DF2\u8FBE\u5230\u989D\u5916\u6210\u7EE9\u5355\u7533\u8BF7\u7684\u9650\u5236\u548C/\u6216\u7533\u8BF7\u622A\u6B62\u65E5\u671F\u5DF2\u8FC7\u3002";
var APPB2C_registration_results_organisations_title5 = "\u4E0E\u7EC4\u7EC7\u5171\u4EAB\u60A8\u7684\u7ED3\u679C";
var APPB2C_registration_results_osr_chooseDate_accessibility5 = "\u6211\u5BF9\u8003\u8BD5\u65E5\u671F\u6709<bold>\u65E0\u969C\u788D</bold>\u9700\u6C42";
var APPB2C_registration_results_osr_chooseDate_accessibilityLink5 = "\u4E86\u89E3\u6211\u4EEC\u5982\u4F55\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9";
var APPB2C_registration_results_osr_chooseDate_chooseDateTitle5 = "\u9009\u62E9\u60A8\u7684 {{\u6280\u80FD\u540D\u79F0}} \u91CD\u8003\u65E5\u671F";
var APPB2C_registration_results_osr_chooseDate_note_content5 = "\u6211\u4EEC\u53EA\u80FD\u5728\u60A8\u539F\u59CB\u8003\u8BD5\u65E5\u671F({{\u65E5\u671F}})\u540E\u7684 60 \u5929\u5185\u4E3A\u60A8\u63D0\u4F9B\u91CD\u8003\u65E5\u671F\u3002";
var APPB2C_registration_results_osr_chooseDate_note_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_registration_results_osr_chooseDate_retakeListening5 = "\u542C\u529B\u91CD\u8003";
var APPB2C_registration_results_osr_chooseDate_retakeReading5 = "\u9605\u8BFB\u91CD\u8003";
var APPB2C_registration_results_osr_chooseDate_retakeSpeaking5 = "\u53E3\u8BED\u91CD\u8003";
var APPB2C_registration_results_osr_chooseDate_retakeWriting5 = "\u5199\u4F5C\u91CD\u8003";
var APPB2C_registration_results_osr_chooseSkill_backBtn5 = "\u8FD4\u56DE\u7ED3\u679C";
var APPB2C_registration_results_osr_chooseSkill_chooseDateBtn5 = "\u9009\u62E9\u4E00\u6B21\u6280\u80FD\u91CD\u8003\u7684\u65E5\u671F";
var APPB2C_registration_results_osr_chooseSkill_confirmationRequired5 = "\u8BF7\u786E\u8BA4\u60A8\u5DF2\u68C0\u67E5\u76EE\u6807\u7EC4\u7EC7\u662F\u5426\u63A5\u53D7\u96C5\u601D\u91CD\u8003\u3002";
var APPB2C_registration_results_osr_chooseSkill_formTitle5 = "\u8BF7\u9009\u62E9\u8981\u91CD\u8003\u7684\u8003\u8BD5\u90E8\u5206";
var APPB2C_registration_results_osr_chooseSkill_modal_contact5 = "\u8BF7\u4F7F\u7528\u4EE5\u4E0B\u8BE6\u7EC6\u4FE1\u606F\u76F4\u63A5\u4E0E\u6211\u4EEC\u8054\u7CFB, \u4EE5\u4E86\u89E3\u6211\u4EEC\u5982\u4F55\u4E3A\u60A8\u63D0\u4F9B\u5E2E\u52A9\u3002";
var APPB2C_registration_results_osr_chooseSkill_modal_description5 = '\u6211\u4EEC\u6CA1\u6709\u4EFB\u4F55\u65E5\u671F\u53EF\u4F9B\u60A8\u9884\u8BA2\u6240\u9009\u7684"\u4E00\u9879\u6280\u80FD\u91CD\u8003"\u3002';
var APPB2C_registration_results_osr_chooseSkill_modal_email5 = "\u7535\u5B50\u90AE\u4EF6";
var APPB2C_registration_results_osr_chooseSkill_modal_phone5 = "\u7535\u8BDD";
var APPB2C_registration_results_osr_chooseSkill_modal_title5 = "\u5BF9\u4E0D\u8D77, \u6211\u4EEC\u6CA1\u6709\u4EFB\u4F55\u53EF\u7528\u65E5\u671F";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation5 = "\u6211\u786E\u8BA4\u6211\u9009\u62E9\u7684\u673A\u6784\u63A5\u53D7\u96C5\u601D\u6280\u80FD\u91CD\u8003\u3002";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description5 = "\u5728\u60A8\u5411\u6211\u4EEC\u9884\u8BA2\u8003\u8BD5\u4E4B\u524D, \u60A8\u5E94\u8BE5<0>\u68C0\u67E5\u60A8\u9009\u62E9\u7684\u673A\u6784\u662F\u5426\u63A5\u53D7\u76F8\u5173\u7684\u96C5\u601D\u8003\u8BD5\u7248\u672C.</0>";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title5 = "\u60A8\u6253\u7B97\u7533\u8BF7\u63A5\u53D7\u96C5\u601D\u6280\u80FD\u91CD\u8003\u7684\u673A\u6784\u5417\uFF1F";
var APPB2C_registration_results_osr_chooseSkill_skillRequired5 = "\u8BF7\u9009\u62E9\u60A8\u5E0C\u671B\u91CD\u4FEE\u7684\u6280\u80FD";
var APPB2C_registration_results_osr_chooseTest_availableDates5 = "\u53EF\u7528\u65E5\u671F";
var APPB2C_registration_results_osr_chooseTest_speaking_time5 = "\u66F4\u6539\u65F6\u95F4";
var APPB2C_registration_results_osr_error_missingFile5 = "\u4E0A\u4F20\u5230\u539F\u59CB\u6CE8\u518C\u7684\u6587\u4EF6\u5B58\u5728\u95EE\u9898\u3002\u8BF7\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3\u4EE5\u83B7\u5F97\u8FDB\u4E00\u6B65\u7684\u5E2E\u52A9\u3002";
var APPB2C_registration_results_osr_error_noTestDates5 = "\u6B64\u4F4D\u7F6E\u4E0D\u518D\u63D0\u4F9B\u6D4B\u8BD5\u65E5\u671F\u3002\u8BF7\u9009\u62E9\u5176\u4ED6\u8003\u8BD5\u6216\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3\u3002";
var APPB2C_registration_results_osr_error_noTests5 = "\u60A8\u9009\u62E9\u7684\u65F6\u95F4\u6BB5\u548C\u5730\u70B9\u4E2D\u4E0D\u518D\u6709\u53EF\u7528\u7684\u8003\u8BD5\u65E5\u671F\u3002\u8BF7<0>\u8FD4\u56DE</0>\u5E76\u66F4\u6539\u60A8\u7684\u641C\u7D22\u53C2\u6570\u3002";
var APPB2C_registration_results_osr_line15 = "\u5982\u679C\u60A8\u9700\u8981\u7B2C\u4E8C\u6B21\u673A\u4F1A\u6765\u63D0\u9AD8\u96C5\u601D\u6210\u7EE9, \u60A8\u53EF\u4EE5\u91CD\u65B0\u53C2\u52A0\u60A8\u4E0D\u6EE1\u610F\u7684\u8003\u8BD5\u4E4B\u4E00\u3002";
var APPB2C_registration_results_osr_line25 = "\u8FD9\u610F\u5473\u7740\u60A8\u5DF2\u7ECF\u5B8C\u6210\u7684\u6240\u6709\u51FA\u8272\u5DE5\u4F5C\u90FD\u4E0D\u4F1A\u6D6A\u8D39\u3002";
var APPB2C_registration_results_osr_line35 = "\u60A8\u60F3\u91CD\u8003\u8003\u8BD5\u6210\u7EE9\u7684\u54EA\u4E00\u90E8\u5206(\u5982\u4E0A\u6240\u793A), \u4EE5\u53CA\u4F55\u65F6\u91CD\u8003\uFF1F";
var APPB2C_registration_results_osr_line45 = "\u6211\u4EEC\u7684\u76EE\u6807\u662F\u572848-72\u5C0F\u65F6\u5185\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6\u56DE\u590D\u6240\u6709\u8BF7\u6C42\u3002";
var APPB2C_registration_results_osr_line55 = "\u82F1\u56FD\u6587\u5316\u6559\u80B2\u534F\u4F1A\u5C06\u4F7F\u7528\u60A8\u63D0\u4F9B\u7684\u4FE1\u606F\u6765\u5904\u7406\u60A8\u7684\u8BF7\u6C42\u3002\u6709\u5173\u8BE6\u7EC6\u4FE1\u606F, \u8BF7\u53C2\u9605\u6211\u4EEC\u7F51\u7AD9\u7684 <0>privacy \u90E8\u5206</0>";
var APPB2C_registration_results_osr_review_note5 = "\u8BF7\u6CE8\u610F, \u4EE5\u4E0B\u4E2A\u4EBA\u8BE6\u7EC6\u4FE1\u606F\u5DF2\u4ECE\u60A8\u7684\u539F\u59CB\u96C5\u601D\u8003\u8BD5\u9884\u8BA2\u4E2D\u68C0\u7D22\u5230\u3002\u8FD9\u4E9B\u6280\u80FD\u4E0D\u80FD\u4E3A\u60A8\u7684\u4E00\u9879\u6280\u80FD\u91CD\u8003\u800C\u66F4\u6539\u3002";
var APPB2C_registration_results_osr_review_note_testDay5 = "\u5728\u8003\u8BD5\u5F53\u5929, \u60A8\u5C06\u88AB\u8981\u6C42\u643A\u5E26\u60A8\u7528\u4E8E\u6CE8\u518C\u7684\u76F8\u540C\u8EAB\u4EFD\u8BC1\u660E\u6587\u4EF6\u3002\u5982\u679C\u60A8\u6CA1\u6709\u968F\u8EAB\u643A\u5E26\u539F\u59CB\u8003\u8BD5 ID, \u8BF7\u8054\u7CFB\u60A8\u7684\u8003\u8BD5\u4E2D\u5FC3\u3002";
var APPB2C_registration_results_osr_submitBtn5 = "\u63D0\u4EA4\u91CD\u8003\u7533\u8BF7";
var APPB2C_registration_results_osr_successBanner5 = "\u6211\u4EEC\u5DF2\u6536\u5230\u60A8\u7684\u96C5\u601D\u6280\u80FD\u7C7B\u8003\u8BD5\u91CD\u8003\u7533\u8BF7\u3002";
var APPB2C_registration_results_osr_title5 = "\u91CD\u8003\u8003\u8BD5\u7684\u4E00\u90E8\u5206";
var APPB2C_registration_results_ttAbsent_btn5 = "\u4F7F\u7528\u4E0D\u540C\u65E5\u671F\u91CD\u65B0\u9884\u8BA2\u6B64\u6D4B\u8BD5";
var APPB2C_registration_results_ttAbsent_msg5 = "\u7531\u4E8E\u8003\u751F\u5728\u8003\u8BD5\u65E5\u671F\u7F3A\u5E2D, \u56E0\u6B64\u65E0\u6CD5\u63D0\u4F9B\u6B64\u6D4B\u8BD5\u7684\u7ED3\u679C\u3002";
var APPB2C_registration_results_updateBar_osr_booked5 = "\u60A8\u5DF2\u9884\u8BA2\u91CD\u65B0\u53C2\u52A0 {{osrSkillName}} \u8003\u8BD5\u3002<1>\u67E5\u770B\u6B64\u9884\u8BA2</1>";
var APPB2C_registration_results_updateBar_osr_marked5 = "\u60A8\u7684 {{osrSkillName}} \u6D4B\u8BD5\u7684\u91CD\u8003\u5DF2\u88AB\u6807\u8BB0\u3002<1>\u67E5\u770B\u7ED3\u679C</1>";
var APPB2C_registration_tabs_bookingDetails5 = "\u9884\u8BA2\u8BE6\u60C5";
var APPB2C_registration_tabs_candidate5 = "\u5E94\u8BD5\u8005";
var APPB2C_registration_tabs_date5 = "\u65E5\u671F";
var APPB2C_registration_tabs_dateLocation5 = "\u65E5\u671F\u548C\u5730\u70B9";
var APPB2C_registration_tabs_payments5 = "\u4ED8\u6B3E";
var APPB2C_registration_tabs_recognisingOrgs5 = "\u8BA4\u53EF\u7EC4\u7EC7";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_15 = "\u8BF7\u76F4\u63A5\u8054\u7CFB\u8003\u8BD5\u4E2D\u5FC3, \u5C06\u60A8\u7684\u6210\u7EE9\u53D1\u9001\u7ED9\u8BA4\u53EF\u673A\u6784: ";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_25 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u6CE8\u518C\u53C2\u8003\u53F7, \u4EE5\u4FBF\u6211\u4EEC\u66F4\u597D\u5730\u5E2E\u52A9\u60A8: ";
var APPB2C_registration_tabs_results5 = "\u7ED3\u679C";
var APPB2C_registration_testTaker_bookedFor_completed5 = "\u8BE5\u6D4B\u8BD5\u7531\u4EE5\u4E0B\u4EBA\u5458\u8FDB\u884C: ";
var APPB2C_registration_testTaker_bookedFor_dob5 = "\u51FA\u751F\u65E5\u671F";
var APPB2C_registration_testTaker_bookedFor_email5 = "\u7535\u5B50\u90AE\u4EF6\u5730\u5740";
var APPB2C_registration_testTaker_bookedFor_gender5 = "\u6027";
var APPB2C_registration_testTaker_bookedFor_name5 = "\u540D\u5B57";
var APPB2C_registration_testTaker_bookedFor_parent5 = "\u5BB6\u957F/\u76D1\u62A4\u4EBA: ";
var APPB2C_registration_testTaker_bookedFor_title5 = "\u6B64\u6D4B\u8BD5\u9002\u7528\u4E8E: ";
var APPB2C_registration_testTaker_childConsent_back5 = "\u6211\u4E0D\u60F3\u7F16\u8F91\u513F\u7AE5\u540C\u610F\u4E66";
var APPB2C_registration_testTaker_childConsent_context5 = "\u60A8\u662F\u5426\u5141\u8BB8\u60A8\u7684\u5B69\u5B50\u5728\u6A21\u5757\u4E4B\u95F4\u7684\u8003\u8BD5\u4F11\u606F\u671F\u95F4\u548C\u8003\u8BD5\u7ED3\u675F\u65F6\u65E0\u4EBA\u966A\u4F34\u5730\u79BB\u5F00\u8003\u8BD5\u573A\u6240\uFF1F";
var APPB2C_registration_testTaker_childConsent_cta5 = "\u4FDD\u5B58\u540C\u610F\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_registration_testTaker_childConsent_options_15 = "\u662F\u7684;\u6211\u7ED9\u4E88\u6211\u7684\u8BB8\u53EF";
var APPB2C_registration_testTaker_childConsent_options_25 = "\u6D1B\u4F0A\u6211\u4F1A\u63A5\u4ED6\u4EEC";
var APPB2C_registration_testTaker_childConsent_options_35 = "\u6D1B\u4F0A\u6211\u60F3\u6307\u5B9A\u5176\u4ED6\u4EBA\u6765\u63A5\u4ED6\u4EEC";
var APPB2C_registration_testTaker_childConsent_somebodyElse_add5 = "+ \u6DFB\u52A0\u5176\u4ED6\u4EBA";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox5 = "\u662F\u7684, \u6211\u786E\u8BA4";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_15 = "\u60A8\u662F\u5426\u786E\u8BA4\u6240\u5217\u4EBA\u5458\u6709\u6743\u6536\u96C6";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_25 = "\u5728\u6D4B\u8BD5\u7ED3\u675F\u65F6, \u4ED6\u4EEC\u5DF2\u7ECF\u8D85\u8FC7 14 \u5C81\u4E86\uFF1F";
var APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName5 = "\u4ED6\u4EEC\u6CA1\u6709\u59D3\u6C0F/\u59D3\u6C0F";
var APPB2C_registration_testTaker_childConsent_somebodyElse_note5 = "\u8BF7\u63D0\u4F9B\u63A5\u5B69\u5B50\u4EBA\u5458\u7684\u8BE6\u7EC6\u4FE1\u606F\u3002\u5982\u679C\u7B14\u8BD5\u5F53\u5929\u548C\u53E3\u8BED\u8003\u8BD5\u5F53\u5929\u6709\u4E0D\u540C\u7684\u4EBA\u63A5\u5B69\u5B50, \u8BF7\u5728\u4E0B\u9762\u6DFB\u52A0\u4ED6\u4EEC\u7684\u540D\u5B57\u3002";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phone5 = "\u7535\u8BDD\u53F7\u7801";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote5 = "\u5728\u7D27\u6025\u60C5\u51B5\u4E0B";
var APPB2C_registration_testTaker_childConsent_somebodyElse_policy5 = "\u9605\u8BFB\u6211\u4EEC\u5B8C\u6574\u7684\u513F\u7AE5\u5B89\u5168\u6536\u96C6\u4FDD\u62A4\u653F\u7B56";
var APPB2C_registration_testTaker_childConsent_somebodyElse_relationship5 = "\u4E0E\u5B69\u5B50\u7684\u5173\u7CFB";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_15 = "\u5173\u4E8E\u63A5\u5B69\u5B50\u7684\u4EBA";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_25 = "\u5173\u4E8E\u7B2C\u4E8C\u4EBA\u79F0";
var APPB2C_registration_testTaker_childConsent_title5 = "\u513F\u7AE5\u540C\u610F\u4E66";
var APPB2C_registration_testTaker_childConsent_warning5 = "11\u5C81\u6216\u4EE5\u4E0A\u7684\u513F\u7AE5\u53EA\u6709\u5728\u7236\u6BCD/\u7167\u987E\u8005\u540C\u610F\u540E\u65B9\u53EF\u5355\u72EC\u79BB\u5F00\u8003\u8BD5\u573A\u6240";
var APPB2C_registration_testTaker_consent_edit5 = "\u7F16\u8F91\u540C\u610F";
var APPB2C_registration_testTaker_consent_missing5 = "\u8BF7\u63D0\u4F9B\u513F\u7AE5\u540C\u610F\u4FE1\u606F\u3002";
var APPB2C_registration_testTaker_consent_title5 = "\u540C\u610F: ";
var APPB2C_registration_testTaker_contact_address5 = "\u5730\u5740";
var APPB2C_registration_testTaker_contact_edit5 = "\u7F16\u8F91\u8054\u7CFB\u4EBA\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_registration_testTaker_contact_mobile5 = "\u624B\u673A\u53F7\u7801";
var APPB2C_registration_testTaker_contact_parent5 = "\u5BB6\u957F/\u76D1\u62A4\u4EBA\u7684\u8054\u7CFB\u65B9\u5F0F: ";
var APPB2C_registration_testTaker_contact_title5 = "\u8054\u7CFB\u65B9\u5F0F: ";
var APPB2C_registration_testTaker_editContact_address5 = "\u5730\u5740";
var APPB2C_registration_testTaker_editContact_back5 = "\u6211\u4E0D\u60F3\u66F4\u6539\u6211\u7684\u8054\u7CFB\u65B9\u5F0F";
var APPB2C_registration_testTaker_editContact_city5 = "\u57CE\u9547 / \u57CE\u5E02";
var APPB2C_registration_testTaker_editContact_country_change5 = "\u66F4\u6539\u56FD\u5BB6/\u5730\u533A";
var APPB2C_registration_testTaker_editContact_country_residence5 = "\u5C45\u4F4F\u56FD\u5BB6/\u5730\u533A";
var APPB2C_registration_testTaker_editContact_cta5 = "\u66F4\u65B0\u8BE6\u7EC6\u4FE1\u606F";
var APPB2C_registration_testTaker_editContact_error_missingAddress5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u5730\u5740\u3002";
var APPB2C_registration_testTaker_editContact_error_missingCountry5 = "\u8BF7\u63D0\u4F9B\u60A8\u7684\u56FD\u5BB6/\u5730\u533A\u3002";
var APPB2C_registration_testTaker_editContact_error_pastCutoff5 = "\u7F16\u8F91\u60A8\u7684\u8054\u7CFB\u65B9\u5F0F\u4E0D\u518D\u53EF\u7528\u3002";
var APPB2C_registration_testTaker_editContact_mobile5 = "\u624B\u673A\u53F7\u7801";
var APPB2C_registration_testTaker_editContact_note5 = "\u8BF7\u6CE8\u610F";
var APPB2C_registration_testTaker_editContact_postCode5 = "\u90AE\u653F\u7F16\u7801 / \u90AE\u7F16";
var APPB2C_registration_testTaker_editContact_state5 = "\u5DDE";
var APPB2C_registration_testTaker_editContact_warn5 = "\u8054\u7CFB\u4FE1\u606F\u7684\u66F4\u65B0\u5C06\u5728\u6D4B\u8BD5\u9884\u8BA2\u4E2D\u66F4\u6539: ";
var APPB2C_registration_testTaker_error5 = "\u6B64\u9884\u8BA2\u4E0D\u518D\u63D0\u4F9B\u7F16\u8F91\u60A8\u7684\u8BE6\u7EC6\u4FE1\u606F\u7684\u529F\u80FD\u3002";
var APPB2C_registration_testTaker_files_editButton_add5 = "\u4E0A\u4F20\u60A8\u7684\u6587\u4EF6";
var APPB2C_registration_testTaker_files_editButton_edit5 = "\u7F16\u8F91\u4E0A\u4F20\u7684\u6587\u4EF6";
var APPB2C_registration_testTaker_files_upload_back_subtitle5 = "(\u8FD9\u4E0D\u4F1A\u4E0A\u4F20\u6216\u5220\u9664\u4EFB\u4F55\u6587\u4EF6)";
var APPB2C_registration_testTaker_files_upload_back_title5 = "\u8FD4\u56DE\u5E94\u8BD5\u8005";
var APPB2C_registration_testTaker_files_upload_cta5 = "\u4FDD\u5B58\u60A8\u7684\u6587\u4EF6";
var APPB2C_registration_testTaker_files_upload_error_fileTooLarge5 = "\u60A8\u5C1D\u8BD5\u4E0A\u4F20\u7684\u6587\u4EF6\u592A\u5927\u3002\u8BF7\u5C06\u5176\u5220\u9664\u5E76\u9009\u62E9\u5176\u4ED6\u6587\u4EF6\u3002";
var APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded5 = "\u60A8\u5DF2\u8D85\u51FA\u53EF\u4EE5\u4E0A\u4F20\u7684\u6587\u4EF6\u6570\u3002\u8BF7\u5220\u9664\u8D85\u51FA\u9650\u5236\u7684\u6587\u4EF6, \u7136\u540E\u91CD\u8BD5\u3002";
var APPB2C_registration_testTaker_files_upload_error_uploadUnavailable5 = "\u6B64\u9884\u8BA2\u65E0\u6CD5\u4E0A\u4F20\u6587\u4EF6\u3002";
var APPB2C_registration_testTaker_files_upload_error_wrongFileName5 = "\u60A8\u5C1D\u8BD5\u4E0A\u4F20\u7684\u6587\u4EF6\u7684\u540D\u79F0\u5305\u542B\u4E0D\u53D7\u652F\u6301\u7684\u5B57\u7B26\u3002\u8BF7\u5C06\u5176\u5220\u9664, \u5728\u672C\u5730\u91CD\u547D\u540D, \u7136\u540E\u518D\u6B21\u5C1D\u8BD5\u4E0A\u4F20\u3002";
var APPB2C_registration_testTaker_files_upload_error_wrongFileType5 = "\u60A8\u6B63\u5728\u5C1D\u8BD5\u4EE5\u4E0D\u53D7\u652F\u6301\u7684\u683C\u5F0F\u4E0A\u4F20\u6587\u4EF6\u3002\u8BF7\u5C06\u5176\u5220\u9664\u5E76\u9009\u62E9\u5176\u4ED6\u6587\u4EF6\u3002";
var APPB2C_registration_testTaker_files_upload_mobile_line15 = "\u7531\u4E8E\u60A8\u770B\u8D77\u6765\u50CF\u662F\u5728\u79FB\u52A8\u8BBE\u5907\u4E0A, \u6211\u4EEC\u5EFA\u8BAE\u60A8\u4ECE\u8BBE\u5907\u4E0A\u4F20\u7167\u7247\u3002";
var APPB2C_registration_testTaker_files_upload_mobile_line25 = "\u5982\u679C\u60A8\u65E0\u6CD5\u5728\u79FB\u52A8\u8BBE\u5907\u4E0A\u6267\u884C\u6B64\u64CD\u4F5C, \u8BF7\u5728\u8BA1\u7B97\u673A\u4E0A\u91CD\u65B0\u767B\u5F55\u3002";
var APPB2C_registration_testTaker_files_upload_mobile_title5 = "\u8BF7\u6CE8\u610F: ";
var APPB2C_registration_testTaker_files_upload_note5 = "\u60A8\u53EF\u4EE5\u4E0A\u4F20 {{count}} \u6587\u4EF6(\u60A8\u5FC5\u987B\u81F3\u5C11\u4E0A\u4F20 1 \u4E2A)";
var APPB2C_registration_testTaker_id_child5 = "\u513F\u7AE5\u8EAB\u4EFD\u8BC1\u660E\u8BE6\u7EC6\u4FE1\u606F: ";
var APPB2C_registration_testTaker_id_edit_rules_example5 = "\u53C2\u89C1 $t({{idName}}) \u7684\u8BC1\u660E\u793A\u4F8B";
var APPB2C_registration_testTaker_id_edit_subtitle5 = "\u8BF7\u4E0A\u4F20\u5982\u4E0B\u6240\u793A\u7684$t({{idName}})\u8BC1\u660E: ";
var APPB2C_registration_testTaker_id_edit_title5 = "\u4E0A\u4F20$t\u8BC1\u660E({{idName}})";
var APPB2C_registration_testTaker_id_edit_upload_exampleProof5 = "\u793A\u4F8B\u8BC1\u660E";
var APPB2C_registration_testTaker_id_edit_upload_imgAlt5 = "\u8EAB\u4EFD\u8BC1\u660E";
var APPB2C_registration_testTaker_id_expiryDate5 = "$t({{idName}}, capitalise) \u5230\u671F\u65E5\u671F";
var APPB2C_registration_testTaker_id_fileAlt5 = "\u4E0A\u4F20\u7684\u8EAB\u4EFD\u8BC1\u660E";
var APPB2C_registration_testTaker_id_idNumber5 = "$t({{idName}}, capitalise) number";
var APPB2C_registration_testTaker_id_issuingAuthority5 = "\u53D1\u8BC1\u673A\u5173";
var APPB2C_registration_testTaker_id_noFilesBody5 = "\u60A8\u5C1A\u672A\u4E0A\u4F20\u4EFB\u4F55\u8EAB\u4EFD\u8BC1\u660E\u3002";
var APPB2C_registration_testTaker_id_noFilesTitle5 = "\u6CA1\u6709\u8BC1\u636E";
var APPB2C_registration_testTaker_id_title5 = "\u8EAB\u4EFD\u8BC1\u660E\u8BE6\u60C5: ";
var APPB2C_registration_testTaker_id_typeNames_card5 = "\u8EAB\u4EFD\u8BC1";
var APPB2C_registration_testTaker_id_typeNames_other5 = "\u8EAB\u4EFD\u8BC1";
var APPB2C_registration_testTaker_id_typeNames_passport5 = "\u62A4\u7167";
var APPB2C_registration_testTaker_specialArrangements_description5 = "\u60A8\u8981\u6C42\u4EE5\u4E0B\u7279\u6B8A\u5B89\u6392: ";
var APPB2C_registration_testTaker_specialArrangements_edit_line15 = "\u9700\u8981\u7279\u522B\u5B89\u6392: ";
var APPB2C_registration_testTaker_specialArrangements_edit_line25 = "\u6240\u6709\u7279\u6B8A\u8981\u6C42\u7684\u7533\u8BF7\u5FC5\u987B\u6709\u533B\u5B66\u8BC1\u636E\u652F\u6301\u3002";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule15 = "\u96C5\u601D\u7BA1\u7406\u5458\u5728\u8BED\u8A00\u548C\u6613\u8BFB\u6027\u65B9\u9762\u53EF\u4EE5\u8BBF\u95EE";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule25 = "\u987B\u4E3A\u62AC\u5934\u7EB8\u6B63\u672C, \u5E76\u9644\u6709\u8BA4\u53EF\u4ECE\u4E1A\u5458\u7684\u59D3\u540D\u3001\u76F8\u5173\u8D44\u683C\u53CA\u7B7E\u540D";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule35 = "\u5E94\u660E\u786E\u8BF4\u660E\u60A8\u7684\u7279\u6B8A\u9700\u6C42";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule45 = "\u5E94\u8BE5\u8BC1\u660E\u60A8\u9700\u8981\u7684\u7279\u6B8A\u5B89\u6392\u662F\u5408\u7406\u7684";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule55 = "\u5E94\u5728\u8003\u8BD5\u65E5\u671F\u524D\u4E0D\u8D85\u8FC7\u4E24\u5E74\u7B7E\u53D1";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_title5 = "\u533B\u7597\u8BC1\u660E\u5E94: ";
var APPB2C_registration_testTaker_specialArrangements_edit_subtitle5 = "\u5F53\u60A8\u9884\u8BA2\u8003\u8BD5\u65F6, \u60A8\u8868\u660E\u60A8\u9700\u8981\u7279\u6B8A\u5B89\u6392\u3002";
var APPB2C_registration_testTaker_specialArrangements_edit_title5 = "\u4E0A\u4F20\u533B\u7597\u8BC1\u636E";
var APPB2C_registration_testTaker_specialArrangements_fileAlt5 = "\u4E0A\u4F20\u7684\u533B\u7597\u8BC1\u636E";
var APPB2C_registration_testTaker_specialArrangements_noFilesBody5 = "\u60A8\u5C1A\u672A\u4E0A\u4F20\u4EFB\u4F55\u533B\u7597\u8BC1\u636E\u3002";
var APPB2C_registration_testTaker_specialArrangements_noFilesTitle5 = "\u6CA1\u6709\u8BC1\u636E";
var APPB2C_registration_testTaker_specialArrangements_title5 = "\u7279\u522B\u5B89\u6392: ";
var APPB2C_testCard_arrival5 = "(\u8BF7\u5230\u8FBE{{\u65F6\u95F4}})";
var APPB2C_testCard_book5 = "\u4E66";
var APPB2C_testCard_bookExam5 = "\u4E66\u7C4D\u6D4B\u8BD5";
var APPB2C_testCard_bookFor5 = "\u9884\u8BA2";
var APPB2C_testCard_bookForFree5 = "\u514D\u8D39\u9884\u8BA2\u6D4B\u8BD5";
var APPB2C_testCard_bookingPrice5 = "\u4EF7\u683C";
var APPB2C_testCard_cd5 = "\u96C5\u601D\u673A\u8003";
var APPB2C_testCard_changeSpeaking5 = "\u66F4\u6539\u6F14\u8BB2\u65F6\u95F4\u8868";
var APPB2C_testCard_f2f5 = "\u9762\u5BF9\u9762";
var APPB2C_testCard_hideSpeakingTests5 = "\u9690\u85CF\u6B64\u573A\u5730\u7684\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_testCard_lrw5 = "\u542C\u529B\u3001\u9605\u8BFB\u548C\u5199\u4F5C";
var APPB2C_testCard_lrwHeader4 = "\u7B14\u8BD5";
var APPB2C_testCard_ls5 = "\u542C\u529B\u548C\u53E3\u8BED";
var APPB2C_testCard_mixedSlots5 = "\u6D4B\u8BD5\u5C06\u88AB\u8BB0\u5F55\u4E0B\u6765\u3002\u9884\u8BA2\u5373\u8868\u793A\u540C\u610F\u3002\u5982\u679C\u60A8\u4E0D\u540C\u610F, \u8BF7\u9009\u62E9\u5176\u4ED6\u3002";
var APPB2C_testCard_notLinkedVenue5 = "\u53E3\u8BED\u548C\u7B14\u8BD5\u5730\u70B9\u4E0D\u4E00\u6837\u3002\u8BA1\u5212\u60A8\u7684\u65C5\u884C\u3002";
var APPB2C_testCard_pb5 = "\u96C5\u601D\u7EB8\u7B14\u8003\u8BD5";
var APPB2C_testCard_review5 = "\u548C\u5BA1\u67E5";
var APPB2C_testCard_showSpeakingTests5 = "\u5728\u6B64\u573A\u5730\u663E\u793A\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_testCard_speaking5 = "\u53E3\u8BED\u6D4B\u8BD5";
var APPB2C_testCard_speakingAddress5 = "\u53D1\u8A00\u5730\u5740";
var APPB2C_testCard_timeUnknown5 = "\u60A8\u5C06\u5728\u8003\u8BD5\u65E5\u671F\u524D 48 \u5C0F\u65F6\u6536\u5230\u8003\u8BD5\u65F6\u95F4\u3002";
var APPB2C_testCard_vcs5 = "\u8003\u8BD5\u4E2D\u5FC3\u89C6\u9891\u901A\u8BDD";
var b2c_default5 = {
  "APPB2C.auth.changePassword.cta": APPB2C_auth_changePassword_cta5,
  "APPB2C.auth.changePassword.error": APPB2C_auth_changePassword_error5,
  "APPB2C.auth.changePassword.error.differentPasswords": APPB2C_auth_changePassword_error_differentPasswords5,
  "APPB2C.auth.changePassword.error.wrongIdNumber": APPB2C_auth_changePassword_error_wrongIdNumber5,
  "APPB2C.auth.changePassword.error.wrongOldPassword": APPB2C_auth_changePassword_error_wrongOldPassword5,
  "APPB2C.auth.changePassword.error.wrongPassword": APPB2C_auth_changePassword_error_wrongPassword5,
  "APPB2C.auth.changePassword.id.label": APPB2C_auth_changePassword_id_label5,
  "APPB2C.auth.changePassword.id.note": APPB2C_auth_changePassword_id_note5,
  "APPB2C.auth.changePassword.id.warn": APPB2C_auth_changePassword_id_warn5,
  "APPB2C.auth.changePassword.pass.label": APPB2C_auth_changePassword_pass_label5,
  "APPB2C.auth.changePassword.pass.note": APPB2C_auth_changePassword_pass_note5,
  "APPB2C.auth.changePassword.title": APPB2C_auth_changePassword_title5,
  "APPB2C.auth.forgetPassword.cta": APPB2C_auth_forgetPassword_cta5,
  "APPB2C.auth.forgetPassword.email": APPB2C_auth_forgetPassword_email5,
  "APPB2C.auth.forgetPassword.success.msg1": APPB2C_auth_forgetPassword_success_msg15,
  "APPB2C.auth.forgetPassword.success.msg2": APPB2C_auth_forgetPassword_success_msg25,
  "APPB2C.auth.forgetPassword.success.title": APPB2C_auth_forgetPassword_success_title5,
  "APPB2C.auth.forgetPassword.text": APPB2C_auth_forgetPassword_text5,
  "APPB2C.auth.forgetPassword.title": APPB2C_auth_forgetPassword_title5,
  "APPB2C.auth.resetPassword.cta": APPB2C_auth_resetPassword_cta5,
  "APPB2C.auth.resetPassword.error.expiredToken": APPB2C_auth_resetPassword_error_expiredToken5,
  "APPB2C.auth.resetPassword.error.wrongPassword": APPB2C_auth_resetPassword_error_wrongPassword5,
  "APPB2C.auth.resetPassword.error.wrongToken": APPB2C_auth_resetPassword_error_wrongToken5,
  "APPB2C.auth.resetPassword.form.newPassword": APPB2C_auth_resetPassword_form_newPassword5,
  "APPB2C.auth.resetPassword.noToken.cta": APPB2C_auth_resetPassword_noToken_cta5,
  "APPB2C.auth.resetPassword.noToken.msg": APPB2C_auth_resetPassword_noToken_msg5,
  "APPB2C.auth.resetPassword.success.msg": APPB2C_auth_resetPassword_success_msg5,
  "APPB2C.auth.resetPassword.success.title": APPB2C_auth_resetPassword_success_title5,
  "APPB2C.auth.resetPassword.title": APPB2C_auth_resetPassword_title5,
  "APPB2C.cj.basic.cta": APPB2C_cj_basic_cta5,
  "APPB2C.cj.basic.goToReview": APPB2C_cj_basic_goToReview5,
  "APPB2C.cj.basic.scrollLink": APPB2C_cj_basic_scrollLink5,
  "APPB2C.cj.basic.selectDate": APPB2C_cj_basic_selectDate5,
  "APPB2C.cj.basic.selectTime": APPB2C_cj_basic_selectTime5,
  "APPB2C.cj.basic.validationError": APPB2C_cj_basic_validationError5,
  "APPB2C.cj.bookingComplete.feeDetails.discount": APPB2C_cj_bookingComplete_feeDetails_discount5,
  "APPB2C.cj.bookingComplete.feeDetails.fee": APPB2C_cj_bookingComplete_feeDetails_fee5,
  "APPB2C.cj.bookingComplete.feeDetails.newFee": APPB2C_cj_bookingComplete_feeDetails_newFee5,
  "APPB2C.cj.bookingComplete.feeDetails.promo": APPB2C_cj_bookingComplete_feeDetails_promo5,
  "APPB2C.cj.bookingComplete.feeDetails.reference": APPB2C_cj_bookingComplete_feeDetails_reference5,
  "APPB2C.cj.bookingComplete.feeDetails.tax": APPB2C_cj_bookingComplete_feeDetails_tax5,
  "APPB2C.cj.bookingComplete.feeDetails.title": APPB2C_cj_bookingComplete_feeDetails_title5,
  "APPB2C.cj.bookingComplete.feeDetails.total": APPB2C_cj_bookingComplete_feeDetails_total5,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine1": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine15,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine2": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine25,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine3": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine35,
  "APPB2C.cj.bookingComplete.loadingPaymentMethodsError": APPB2C_cj_bookingComplete_loadingPaymentMethodsError5,
  "APPB2C.cj.bookingComplete.loadingRegistrationError": APPB2C_cj_bookingComplete_loadingRegistrationError5,
  "APPB2C.cj.bookingComplete.note": APPB2C_cj_bookingComplete_note5,
  "APPB2C.cj.bookingComplete.noteUolPassportInfo": APPB2C_cj_bookingComplete_noteUolPassportInfo5,
  "APPB2C.cj.bookingComplete.offlinePayment.code": APPB2C_cj_bookingComplete_offlinePayment_code5,
  "APPB2C.cj.bookingComplete.offlinePayment.description": APPB2C_cj_bookingComplete_offlinePayment_description5,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line1": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line15,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line2": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line25,
  "APPB2C.cj.bookingComplete.offlinePayment.fee": APPB2C_cj_bookingComplete_offlinePayment_fee5,
  "APPB2C.cj.bookingComplete.offlinePayment.hideDescription": APPB2C_cj_bookingComplete_offlinePayment_hideDescription5,
  "APPB2C.cj.bookingComplete.offlinePayment.incTax": APPB2C_cj_bookingComplete_offlinePayment_incTax5,
  "APPB2C.cj.bookingComplete.offlinePayment.name": APPB2C_cj_bookingComplete_offlinePayment_name5,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1": APPB2C_cj_bookingComplete_offlinePayment_note_line15,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.hours": APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours5,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.timeLeft": APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft5,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line2": APPB2C_cj_bookingComplete_offlinePayment_note_line25,
  "APPB2C.cj.bookingComplete.offlinePayment.note.pleaseNote": APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote5,
  "APPB2C.cj.bookingComplete.offlinePayment.number": APPB2C_cj_bookingComplete_offlinePayment_number5,
  "APPB2C.cj.bookingComplete.offlinePayment.reference": APPB2C_cj_bookingComplete_offlinePayment_reference5,
  "APPB2C.cj.bookingComplete.offlinePayment.showDescription": APPB2C_cj_bookingComplete_offlinePayment_showDescription5,
  "APPB2C.cj.bookingComplete.otherPaymentOptions": APPB2C_cj_bookingComplete_otherPaymentOptions5,
  "APPB2C.cj.bookingComplete.paymentFailed": APPB2C_cj_bookingComplete_paymentFailed5,
  "APPB2C.cj.bookingComplete.paymentOptions": APPB2C_cj_bookingComplete_paymentOptions5,
  "APPB2C.cj.bookingComplete.prepareNote.accesNote": APPB2C_cj_bookingComplete_prepareNote_accesNote5,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.note": APPB2C_cj_bookingComplete_prepareNote_counselling_note5,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.view": APPB2C_cj_bookingComplete_prepareNote_counselling_view5,
  "APPB2C.cj.bookingComplete.prepareNote.note": APPB2C_cj_bookingComplete_prepareNote_note5,
  "APPB2C.cj.bookingComplete.prepareNote.title": APPB2C_cj_bookingComplete_prepareNote_title5,
  "APPB2C.cj.bookingComplete.prepareNote.view": APPB2C_cj_bookingComplete_prepareNote_view5,
  "APPB2C.cj.bookingComplete.title": APPB2C_cj_bookingComplete_title5,
  "APPB2C.cj.bookingComplete.tnc": APPB2C_cj_bookingComplete_tnc5,
  "APPB2C.cj.bookingComplete.tncLink": APPB2C_cj_bookingComplete_tncLink5,
  "APPB2C.cj.bookingComplete.tryAgain": APPB2C_cj_bookingComplete_tryAgain5,
  "APPB2C.cj.bookingComplete.yourBooking": APPB2C_cj_bookingComplete_yourBooking5,
  "APPB2C.cj.childDetails.about": APPB2C_cj_childDetails_about5,
  "APPB2C.cj.childDetails.aboutNote": APPB2C_cj_childDetails_aboutNote5,
  "APPB2C.cj.childDetails.error.missingFirstName": APPB2C_cj_childDetails_error_missingFirstName5,
  "APPB2C.cj.childDetails.error.missingSurname": APPB2C_cj_childDetails_error_missingSurname5,
  "APPB2C.cj.childDetails.error.wrongDob": APPB2C_cj_childDetails_error_wrongDob5,
  "APPB2C.cj.childDetails.forms.personal.idMatch": APPB2C_cj_childDetails_forms_personal_idMatch5,
  "APPB2C.cj.childDetails.noLastName": APPB2C_cj_childDetails_noLastName5,
  "APPB2C.cj.childDetails.note": APPB2C_cj_childDetails_note5,
  "APPB2C.cj.childDetails.onTestDay": APPB2C_cj_childDetails_onTestDay5,
  "APPB2C.cj.childDetails.title": APPB2C_cj_childDetails_title5,
  "APPB2C.cj.finishPayment.error": APPB2C_cj_finishPayment_error5,
  "APPB2C.cj.finishPayment.pleaseWait": APPB2C_cj_finishPayment_pleaseWait5,
  "APPB2C.cj.finishPayment.processing": APPB2C_cj_finishPayment_processing5,
  "APPB2C.cj.idDetails.confirm.change": APPB2C_cj_idDetails_confirm_change5,
  "APPB2C.cj.idDetails.confirm.confirm": APPB2C_cj_idDetails_confirm_confirm5,
  "APPB2C.cj.idDetails.confirm.note1": APPB2C_cj_idDetails_confirm_note15,
  "APPB2C.cj.idDetails.confirm.note2": APPB2C_cj_idDetails_confirm_note25,
  "APPB2C.cj.idDetails.confirm.title": APPB2C_cj_idDetails_confirm_title5,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.content": APPB2C_cj_idDetails_confirm_wrongDetailsNote_content5,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.title": APPB2C_cj_idDetails_confirm_wrongDetailsNote_title5,
  "APPB2C.cj.idDetails.error.missingIdExpiry": APPB2C_cj_idDetails_error_missingIdExpiry5,
  "APPB2C.cj.idDetails.error.missingIdNumber": APPB2C_cj_idDetails_error_missingIdNumber5,
  "APPB2C.cj.idDetails.error.missingIdType": APPB2C_cj_idDetails_error_missingIdType5,
  "APPB2C.cj.idDetails.error.missingIssuingAuthority": APPB2C_cj_idDetails_error_missingIssuingAuthority5,
  "APPB2C.cj.idDetails.error.wrongFilename": APPB2C_cj_idDetails_error_wrongFilename5,
  "APPB2C.cj.idDetails.error.wrongIdExpiry": APPB2C_cj_idDetails_error_wrongIdExpiry5,
  "APPB2C.cj.idDetails.forms.changeNote": APPB2C_cj_idDetails_forms_changeNote5,
  "APPB2C.cj.idDetails.forms.changeNote.child": APPB2C_cj_idDetails_forms_changeNote_child5,
  "APPB2C.cj.idDetails.forms.documentWithNoExpiryDate": APPB2C_cj_idDetails_forms_documentWithNoExpiryDate5,
  "APPB2C.cj.idDetails.forms.expiryDate": APPB2C_cj_idDetails_forms_expiryDate5,
  "APPB2C.cj.idDetails.forms.idNumber": APPB2C_cj_idDetails_forms_idNumber5,
  "APPB2C.cj.idDetails.forms.idType": APPB2C_cj_idDetails_forms_idType5,
  "APPB2C.cj.idDetails.forms.issuingAuthority": APPB2C_cj_idDetails_forms_issuingAuthority5,
  "APPB2C.cj.idDetails.idExpired": APPB2C_cj_idDetails_idExpired5,
  "APPB2C.cj.idDetails.iolMessage": APPB2C_cj_idDetails_iolMessage5,
  "APPB2C.cj.idDetails.noteAboutIdentification": APPB2C_cj_idDetails_noteAboutIdentification5,
  "APPB2C.cj.idDetails.noteAboutIdentification.child": APPB2C_cj_idDetails_noteAboutIdentification_child5,
  "APPB2C.cj.idDetails.noteAboutIdentificationIol": APPB2C_cj_idDetails_noteAboutIdentificationIol5,
  "APPB2C.cj.idDetails.noteAboutIdentificationIolLink": APPB2C_cj_idDetails_noteAboutIdentificationIolLink5,
  "APPB2C.cj.idDetails.noteAboutIdentificationUol": APPB2C_cj_idDetails_noteAboutIdentificationUol5,
  "APPB2C.cj.idDetails.title": APPB2C_cj_idDetails_title5,
  "APPB2C.cj.idDetails.title.child": APPB2C_cj_idDetails_title_child5,
  "APPB2C.cj.idDetails.typeNames.card": APPB2C_cj_idDetails_typeNames_card5,
  "APPB2C.cj.idDetails.typeNames.other": APPB2C_cj_idDetails_typeNames_other5,
  "APPB2C.cj.idDetails.typeNames.passport": APPB2C_cj_idDetails_typeNames_passport5,
  "APPB2C.cj.idDetails.upload.fetchError": APPB2C_cj_idDetails_upload_fetchError5,
  "APPB2C.cj.idDetails.upload.file1": APPB2C_cj_idDetails_upload_file15,
  "APPB2C.cj.idDetails.upload.file2": APPB2C_cj_idDetails_upload_file25,
  "APPB2C.cj.idDetails.upload.instructions.capture": APPB2C_cj_idDetails_upload_instructions_capture5,
  "APPB2C.cj.idDetails.upload.instructions.covers": APPB2C_cj_idDetails_upload_instructions_covers5,
  "APPB2C.cj.idDetails.upload.instructions.directLight": APPB2C_cj_idDetails_upload_instructions_directLight5,
  "APPB2C.cj.idDetails.upload.instructions.noBlur": APPB2C_cj_idDetails_upload_instructions_noBlur5,
  "APPB2C.cj.idDetails.upload.instructions.title": APPB2C_cj_idDetails_upload_instructions_title5,
  "APPB2C.cj.idDetails.upload.mobile": APPB2C_cj_idDetails_upload_mobile5,
  "APPB2C.cj.idDetails.upload.passport.guidelines.cover": APPB2C_cj_idDetails_upload_passport_guidelines_cover5,
  "APPB2C.cj.idDetails.upload.passport.guidelines.crop": APPB2C_cj_idDetails_upload_passport_guidelines_crop5,
  "APPB2C.cj.idDetails.upload.passport.guidelines.followInstructions": APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions5,
  "APPB2C.cj.idDetails.upload.passport.guidelines.glare": APPB2C_cj_idDetails_upload_passport_guidelines_glare5,
  "APPB2C.cj.idDetails.upload.passport.guidelines.watermark": APPB2C_cj_idDetails_upload_passport_guidelines_watermark5,
  "APPB2C.cj.idDetails.upload.title": APPB2C_cj_idDetails_upload_title5,
  "APPB2C.cj.ieltsReady.description.line1": APPB2C_cj_ieltsReady_description_line15,
  "APPB2C.cj.ieltsReady.description.line2": APPB2C_cj_ieltsReady_description_line25,
  "APPB2C.cj.ieltsReady.dobMin": APPB2C_cj_ieltsReady_dobMin5,
  "APPB2C.cj.ieltsReady.nameWarning": APPB2C_cj_ieltsReady_nameWarning5,
  "APPB2C.cj.ieltsReady.note": APPB2C_cj_ieltsReady_note5,
  "APPB2C.cj.ieltsReady.personalDetails.title": APPB2C_cj_ieltsReady_personalDetails_title4,
  "APPB2C.cj.ieltsReady.submitBtnText": APPB2C_cj_ieltsReady_submitBtnText5,
  "APPB2C.cj.ieltsReady.targetScore.option": APPB2C_cj_ieltsReady_targetScore_option5,
  "APPB2C.cj.ieltsReady.targetScore.title": APPB2C_cj_ieltsReady_targetScore_title5,
  "APPB2C.cj.ieltsReady.title": APPB2C_cj_ieltsReady_title5,
  "APPB2C.cj.incorrectOrganisation.bookNewLink": APPB2C_cj_incorrectOrganisation_bookNewLink5,
  "APPB2C.cj.incorrectOrganisation.line1": APPB2C_cj_incorrectOrganisation_line15,
  "APPB2C.cj.incorrectOrganisation.line2": APPB2C_cj_incorrectOrganisation_line25,
  "APPB2C.cj.incorrectOrganisation.locationsLink": APPB2C_cj_incorrectOrganisation_locationsLink5,
  "APPB2C.cj.incorrectOrganisation.locationsLinkUsa": APPB2C_cj_incorrectOrganisation_locationsLinkUsa5,
  "APPB2C.cj.incorrectOrganisation.title": APPB2C_cj_incorrectOrganisation_title5,
  "APPB2C.cj.marketingPrefs": APPB2C_cj_marketingPrefs5,
  "APPB2C.cj.onRequest.created.back": APPB2C_cj_onRequest_created_back5,
  "APPB2C.cj.onRequest.created.in": APPB2C_cj_onRequest_created_in5,
  "APPB2C.cj.onRequest.created.info": APPB2C_cj_onRequest_created_info5,
  "APPB2C.cj.onRequest.created.note": APPB2C_cj_onRequest_created_note5,
  "APPB2C.cj.onRequest.created.title": APPB2C_cj_onRequest_created_title5,
  "APPB2C.cj.onRequest.form.labels.confirmEmail": APPB2C_cj_onRequest_form_labels_confirmEmail5,
  "APPB2C.cj.onRequest.form.labels.email": APPB2C_cj_onRequest_form_labels_email5,
  "APPB2C.cj.onRequest.form.labels.firstname": APPB2C_cj_onRequest_form_labels_firstname5,
  "APPB2C.cj.onRequest.form.labels.surname": APPB2C_cj_onRequest_form_labels_surname5,
  "APPB2C.cj.onRequest.form.labels.telephone": APPB2C_cj_onRequest_form_labels_telephone5,
  "APPB2C.cj.onRequest.form.labels.testRangeQuestion": APPB2C_cj_onRequest_form_labels_testRangeQuestion5,
  "APPB2C.cj.onRequest.form.submit": APPB2C_cj_onRequest_form_submit5,
  "APPB2C.cj.onRequest.form.testRangeOpt.oneMonth": APPB2C_cj_onRequest_form_testRangeOpt_oneMonth5,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonths": APPB2C_cj_onRequest_form_testRangeOpt_threeMonths5,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonthsPlus": APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus5,
  "APPB2C.cj.onRequest.form.testRangeOpt.twoMonths": APPB2C_cj_onRequest_form_testRangeOpt_twoMonths5,
  "APPB2C.cj.onRequest.info1": APPB2C_cj_onRequest_info15,
  "APPB2C.cj.onRequest.note": APPB2C_cj_onRequest_note5,
  "APPB2C.cj.onRequest.otherLocations.choose": APPB2C_cj_onRequest_otherLocations_choose5,
  "APPB2C.cj.onRequest.otherLocations.hide": APPB2C_cj_onRequest_otherLocations_hide5,
  "APPB2C.cj.onRequest.otherLocations.info1": APPB2C_cj_onRequest_otherLocations_info15,
  "APPB2C.cj.onRequest.otherLocations.info2": APPB2C_cj_onRequest_otherLocations_info25,
  "APPB2C.cj.onRequest.otherLocations.show": APPB2C_cj_onRequest_otherLocations_show5,
  "APPB2C.cj.onRequest.please": APPB2C_cj_onRequest_please5,
  "APPB2C.cj.onRequest.prefNote": APPB2C_cj_onRequest_prefNote5,
  "APPB2C.cj.onRequest.subtitles.aboutPreferences": APPB2C_cj_onRequest_subtitles_aboutPreferences5,
  "APPB2C.cj.onRequest.subtitles.aboutYou": APPB2C_cj_onRequest_subtitles_aboutYou5,
  "APPB2C.cj.onRequest.subtitles.markpref": APPB2C_cj_onRequest_subtitles_markpref5,
  "APPB2C.cj.onRequest.subtitles.register": APPB2C_cj_onRequest_subtitles_register5,
  "APPB2C.cj.personalDetails.error.doubleBooking": APPB2C_cj_personalDetails_error_doubleBooking5,
  "APPB2C.cj.personalDetails.error.missingAddress": APPB2C_cj_personalDetails_error_missingAddress5,
  "APPB2C.cj.personalDetails.error.missingEmail": APPB2C_cj_personalDetails_error_missingEmail5,
  "APPB2C.cj.personalDetails.error.missingEmailConfirm": APPB2C_cj_personalDetails_error_missingEmailConfirm5,
  "APPB2C.cj.personalDetails.error.missingFirstName": APPB2C_cj_personalDetails_error_missingFirstName5,
  "APPB2C.cj.personalDetails.error.missingMobile": APPB2C_cj_personalDetails_error_missingMobile5,
  "APPB2C.cj.personalDetails.error.missingSex": APPB2C_cj_personalDetails_error_missingSex5,
  "APPB2C.cj.personalDetails.error.missingState": APPB2C_cj_personalDetails_error_missingState5,
  "APPB2C.cj.personalDetails.error.missingSurname": APPB2C_cj_personalDetails_error_missingSurname5,
  "APPB2C.cj.personalDetails.error.missingTown": APPB2C_cj_personalDetails_error_missingTown5,
  "APPB2C.cj.personalDetails.error.missingZip": APPB2C_cj_personalDetails_error_missingZip5,
  "APPB2C.cj.personalDetails.error.wrongDob": APPB2C_cj_personalDetails_error_wrongDob5,
  "APPB2C.cj.personalDetails.error.wrongEmailConfirm": APPB2C_cj_personalDetails_error_wrongEmailConfirm5,
  "APPB2C.cj.personalDetails.examTakenModal.cta": APPB2C_cj_personalDetails_examTakenModal_cta5,
  "APPB2C.cj.personalDetails.examTakenModal.message": APPB2C_cj_personalDetails_examTakenModal_message5,
  "APPB2C.cj.personalDetails.examTakenModal.title": APPB2C_cj_personalDetails_examTakenModal_title5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse": APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate": APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle": APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.title": APPB2C_cj_personalDetails_existingRegistrationsModal_title5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourLifeSkillsTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourSpeakingTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest5,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourWrittenTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest5,
  "APPB2C.cj.personalDetails.forms.address.country": APPB2C_cj_personalDetails_forms_address_country5,
  "APPB2C.cj.personalDetails.forms.address.enterManually": APPB2C_cj_personalDetails_forms_address_enterManually5,
  "APPB2C.cj.personalDetails.forms.address.postal": APPB2C_cj_personalDetails_forms_address_postal5,
  "APPB2C.cj.personalDetails.forms.address.state": APPB2C_cj_personalDetails_forms_address_state5,
  "APPB2C.cj.personalDetails.forms.address.town": APPB2C_cj_personalDetails_forms_address_town5,
  "APPB2C.cj.personalDetails.forms.address.zip": APPB2C_cj_personalDetails_forms_address_zip5,
  "APPB2C.cj.personalDetails.forms.contact.confirmEmail": APPB2C_cj_personalDetails_forms_contact_confirmEmail5,
  "APPB2C.cj.personalDetails.forms.contact.email": APPB2C_cj_personalDetails_forms_contact_email5,
  "APPB2C.cj.personalDetails.forms.contact.emailExist": APPB2C_cj_personalDetails_forms_contact_emailExist5,
  "APPB2C.cj.personalDetails.forms.contact.emailInUse": APPB2C_cj_personalDetails_forms_contact_emailInUse5,
  "APPB2C.cj.personalDetails.forms.contact.mobile": APPB2C_cj_personalDetails_forms_contact_mobile5,
  "APPB2C.cj.personalDetails.forms.contact.note": APPB2C_cj_personalDetails_forms_contact_note5,
  "APPB2C.cj.personalDetails.forms.contact.password": APPB2C_cj_personalDetails_forms_contact_password5,
  "APPB2C.cj.personalDetails.forms.contact.smsConsent": APPB2C_cj_personalDetails_forms_contact_smsConsent5,
  "APPB2C.cj.personalDetails.forms.contact.smsNote": APPB2C_cj_personalDetails_forms_contact_smsNote5,
  "APPB2C.cj.personalDetails.forms.contact.title": APPB2C_cj_personalDetails_forms_contact_title5,
  "APPB2C.cj.personalDetails.forms.personal.change": APPB2C_cj_personalDetails_forms_personal_change5,
  "APPB2C.cj.personalDetails.forms.personal.dob": APPB2C_cj_personalDetails_forms_personal_dob5,
  "APPB2C.cj.personalDetails.forms.personal.dobCta": APPB2C_cj_personalDetails_forms_personal_dobCta5,
  "APPB2C.cj.personalDetails.forms.personal.firstMiddleName": APPB2C_cj_personalDetails_forms_personal_firstMiddleName5,
  "APPB2C.cj.personalDetails.forms.personal.firstName": APPB2C_cj_personalDetails_forms_personal_firstName5,
  "APPB2C.cj.personalDetails.forms.personal.idMatch": APPB2C_cj_personalDetails_forms_personal_idMatch5,
  "APPB2C.cj.personalDetails.forms.personal.lastName": APPB2C_cj_personalDetails_forms_personal_lastName5,
  "APPB2C.cj.personalDetails.forms.personal.name": APPB2C_cj_personalDetails_forms_personal_name5,
  "APPB2C.cj.personalDetails.forms.personal.noLastName": APPB2C_cj_personalDetails_forms_personal_noLastName5,
  "APPB2C.cj.personalDetails.forms.personal.sectionTitle": APPB2C_cj_personalDetails_forms_personal_sectionTitle5,
  "APPB2C.cj.personalDetails.forWho.child": APPB2C_cj_personalDetails_forWho_child5,
  "APPB2C.cj.personalDetails.forWho.childUnder18": APPB2C_cj_personalDetails_forWho_childUnder185,
  "APPB2C.cj.personalDetails.forWho.dob": APPB2C_cj_personalDetails_forWho_dob5,
  "APPB2C.cj.personalDetails.forWho.myself": APPB2C_cj_personalDetails_forWho_myself5,
  "APPB2C.cj.personalDetails.forWho.note": APPB2C_cj_personalDetails_forWho_note5,
  "APPB2C.cj.personalDetails.forWho.title": APPB2C_cj_personalDetails_forWho_title5,
  "APPB2C.cj.personalDetails.gender": APPB2C_cj_personalDetails_gender5,
  "APPB2C.cj.personalDetails.idNote.idMatch": APPB2C_cj_personalDetails_idNote_idMatch5,
  "APPB2C.cj.personalDetails.idNote.idMatch.change": APPB2C_cj_personalDetails_idNote_idMatch_change5,
  "APPB2C.cj.personalDetails.idNote.idMatch.france": APPB2C_cj_personalDetails_idNote_idMatch_france4,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.adult": APPB2C_cj_personalDetails_idNote_idMatch_france_adult4,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.minor": APPB2C_cj_personalDetails_idNote_idMatch_france_minor4,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain": APPB2C_cj_personalDetails_idNote_idMatch_spain4,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.adult": APPB2C_cj_personalDetails_idNote_idMatch_spain_adult4,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.minor": APPB2C_cj_personalDetails_idNote_idMatch_spain_minor4,
  "APPB2C.cj.personalDetails.idNote.whenForChild": APPB2C_cj_personalDetails_idNote_whenForChild5,
  "APPB2C.cj.personalDetails.login.btn": APPB2C_cj_personalDetails_login_btn5,
  "APPB2C.cj.personalDetails.login.emailNotEditable": APPB2C_cj_personalDetails_login_emailNotEditable5,
  "APPB2C.cj.personalDetails.login.link": APPB2C_cj_personalDetails_login_link5,
  "APPB2C.cj.personalDetails.login.loggedIn": APPB2C_cj_personalDetails_login_loggedIn5,
  "APPB2C.cj.personalDetails.login.logout": APPB2C_cj_personalDetails_login_logout5,
  "APPB2C.cj.personalDetails.login.note": APPB2C_cj_personalDetails_login_note5,
  "APPB2C.cj.personalDetails.login.title": APPB2C_cj_personalDetails_login_title5,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.changeDate": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate5,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line1": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line15,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line2": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line25,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.title": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title5,
  "APPB2C.cj.personalDetails.title": APPB2C_cj_personalDetails_title5,
  "APPB2C.cj.personalDetails.tncs.agreement": APPB2C_cj_personalDetails_tncs_agreement5,
  "APPB2C.cj.personalDetails.tncs.title": APPB2C_cj_personalDetails_tncs_title5,
  "APPB2C.cj.personalDetails.visaType.label": APPB2C_cj_personalDetails_visaType_label5,
  "APPB2C.cj.personalDetails.visaType.label.child": APPB2C_cj_personalDetails_visaType_label_child5,
  "APPB2C.cj.personalDetails.visaType.link": APPB2C_cj_personalDetails_visaType_link5,
  "APPB2C.cj.personalDetails.visaType.title": APPB2C_cj_personalDetails_visaType_title5,
  "APPB2C.cj.personalDetails.visaType.title.child": APPB2C_cj_personalDetails_visaType_title_child5,
  "APPB2C.cj.rebook.errorModal.message": APPB2C_cj_rebook_errorModal_message5,
  "APPB2C.cj.rebook.errorModal.title": APPB2C_cj_rebook_errorModal_title5,
  "APPB2C.cj.review.acknowledgement.anotherPerson": APPB2C_cj_review_acknowledgement_anotherPerson5,
  "APPB2C.cj.review.acknowledgement.company": APPB2C_cj_review_acknowledgement_company5,
  "APPB2C.cj.review.acknowledgement.fields.companyName": APPB2C_cj_review_acknowledgement_fields_companyName5,
  "APPB2C.cj.review.acknowledgement.fields.companyName.subnote": APPB2C_cj_review_acknowledgement_fields_companyName_subnote5,
  "APPB2C.cj.review.acknowledgement.fields.country": APPB2C_cj_review_acknowledgement_fields_country5,
  "APPB2C.cj.review.acknowledgement.fields.email": APPB2C_cj_review_acknowledgement_fields_email5,
  "APPB2C.cj.review.acknowledgement.fields.familyName": APPB2C_cj_review_acknowledgement_fields_familyName5,
  "APPB2C.cj.review.acknowledgement.fields.firstName": APPB2C_cj_review_acknowledgement_fields_firstName5,
  "APPB2C.cj.review.acknowledgement.fields.mobile": APPB2C_cj_review_acknowledgement_fields_mobile5,
  "APPB2C.cj.review.acknowledgement.myself": APPB2C_cj_review_acknowledgement_myself5,
  "APPB2C.cj.review.acknowledgement.send": APPB2C_cj_review_acknowledgement_send5,
  "APPB2C.cj.review.acknowledgement.title": APPB2C_cj_review_acknowledgement_title5,
  "APPB2C.cj.review.agent.label": APPB2C_cj_review_agent_label5,
  "APPB2C.cj.review.agent.note": APPB2C_cj_review_agent_note5,
  "APPB2C.cj.review.agent.title": APPB2C_cj_review_agent_title5,
  "APPB2C.cj.review.ai.note": APPB2C_cj_review_ai_note5,
  "APPB2C.cj.review.ai.title": APPB2C_cj_review_ai_title5,
  "APPB2C.cj.review.bookingDetails.lrw.title": APPB2C_cj_review_bookingDetails_lrw_title5,
  "APPB2C.cj.review.bookingDetails.lrw.title.child": APPB2C_cj_review_bookingDetails_lrw_title_child5,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills5,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills.child": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child5,
  "APPB2C.cj.review.bookingDetails.speaking.title": APPB2C_cj_review_bookingDetails_speaking_title5,
  "APPB2C.cj.review.bookingDetails.speaking.title.child": APPB2C_cj_review_bookingDetails_speaking_title_child5,
  "APPB2C.cj.review.bookingDetails.title": APPB2C_cj_review_bookingDetails_title5,
  "APPB2C.cj.review.bookNowNoFee.buttonText": APPB2C_cj_review_bookNowNoFee_buttonText5,
  "APPB2C.cj.review.bookTest": APPB2C_cj_review_bookTest5,
  "APPB2C.cj.review.change": APPB2C_cj_review_change5,
  "APPB2C.cj.review.changeLrwTest": APPB2C_cj_review_changeLrwTest5,
  "APPB2C.cj.review.changeSpeakingTest": APPB2C_cj_review_changeSpeakingTest5,
  "APPB2C.cj.review.changeUkviLsTest": APPB2C_cj_review_changeUkviLsTest5,
  "APPB2C.cj.review.childDetails.guardiansTitle": APPB2C_cj_review_childDetails_guardiansTitle5,
  "APPB2C.cj.review.childDetails.onTestDay": APPB2C_cj_review_childDetails_onTestDay5,
  "APPB2C.cj.review.childDetails.onTestDayOptions.1": APPB2C_cj_review_childDetails_onTestDayOptions_15,
  "APPB2C.cj.review.childDetails.onTestDayOptions.2": APPB2C_cj_review_childDetails_onTestDayOptions_25,
  "APPB2C.cj.review.childDetails.onTestDayOptions.3": APPB2C_cj_review_childDetails_onTestDayOptions_35,
  "APPB2C.cj.review.childDetails.title": APPB2C_cj_review_childDetails_title5,
  "APPB2C.cj.review.confirmPaymentModal.btnNote": APPB2C_cj_review_confirmPaymentModal_btnNote5,
  "APPB2C.cj.review.confirmPaymentModal.note": APPB2C_cj_review_confirmPaymentModal_note5,
  "APPB2C.cj.review.confirmPaymentModal.optionsTitle": APPB2C_cj_review_confirmPaymentModal_optionsTitle5,
  "APPB2C.cj.review.confirmPaymentModal.title": APPB2C_cj_review_confirmPaymentModal_title5,
  "APPB2C.cj.review.countryMsg.pakistan": APPB2C_cj_review_countryMsg_pakistan5,
  "APPB2C.cj.review.error.missingOrderAcknowledgement": APPB2C_cj_review_error_missingOrderAcknowledgement5,
  "APPB2C.cj.review.error.missingPickups": APPB2C_cj_review_error_missingPickups5,
  "APPB2C.cj.review.error.missingTown": APPB2C_cj_review_error_missingTown5,
  "APPB2C.cj.review.error.wrongCountrySetting": APPB2C_cj_review_error_wrongCountrySetting5,
  "APPB2C.cj.review.error.wrongPaymentConfig": APPB2C_cj_review_error_wrongPaymentConfig5,
  "APPB2C.cj.review.error.wrongTaxNumber": APPB2C_cj_review_error_wrongTaxNumber5,
  "APPB2C.cj.review.expiredIdError": APPB2C_cj_review_expiredIdError5,
  "APPB2C.cj.review.idDetails.issuing": APPB2C_cj_review_idDetails_issuing5,
  "APPB2C.cj.review.idDetails.nationality": APPB2C_cj_review_idDetails_nationality5,
  "APPB2C.cj.review.idDetails.title": APPB2C_cj_review_idDetails_title5,
  "APPB2C.cj.review.idDetails.title.child": APPB2C_cj_review_idDetails_title_child5,
  "APPB2C.cj.review.invoice.companyName": APPB2C_cj_review_invoice_companyName5,
  "APPB2C.cj.review.invoice.consent": APPB2C_cj_review_invoice_consent5,
  "APPB2C.cj.review.invoice.send": APPB2C_cj_review_invoice_send5,
  "APPB2C.cj.review.invoice.subtype.citizenDigitalCertificate": APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate5,
  "APPB2C.cj.review.invoice.subtype.label": APPB2C_cj_review_invoice_subtype_label5,
  "APPB2C.cj.review.invoice.subtype.mobileBarcode": APPB2C_cj_review_invoice_subtype_mobileBarcode5,
  "APPB2C.cj.review.invoice.taxCaption.donate": APPB2C_cj_review_invoice_taxCaption_donate5,
  "APPB2C.cj.review.invoice.taxCaption.duplicatePaper": APPB2C_cj_review_invoice_taxCaption_duplicatePaper5,
  "APPB2C.cj.review.invoice.taxValidation.citizenDigitalCertificate": APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate5,
  "APPB2C.cj.review.invoice.taxValidation.donate": APPB2C_cj_review_invoice_taxValidation_donate5,
  "APPB2C.cj.review.invoice.taxValidation.mobileBarcode": APPB2C_cj_review_invoice_taxValidation_mobileBarcode5,
  "APPB2C.cj.review.invoice.taxValidation.triplicate": APPB2C_cj_review_invoice_taxValidation_triplicate5,
  "APPB2C.cj.review.invoice.title": APPB2C_cj_review_invoice_title5,
  "APPB2C.cj.review.invoice.type.donate": APPB2C_cj_review_invoice_type_donate5,
  "APPB2C.cj.review.invoice.type.duplicateElectronic": APPB2C_cj_review_invoice_type_duplicateElectronic5,
  "APPB2C.cj.review.invoice.type.duplicatePaper": APPB2C_cj_review_invoice_type_duplicatePaper5,
  "APPB2C.cj.review.invoice.type.triplicate": APPB2C_cj_review_invoice_type_triplicate5,
  "APPB2C.cj.review.localization.localTime": APPB2C_cj_review_localization_localTime5,
  "APPB2C.cj.review.localization.location": APPB2C_cj_review_localization_location5,
  "APPB2C.cj.review.localization.timezone": APPB2C_cj_review_localization_timezone5,
  "APPB2C.cj.review.missingMarketingCta": APPB2C_cj_review_missingMarketingCta5,
  "APPB2C.cj.review.missingMarketingError": APPB2C_cj_review_missingMarketingError5,
  "APPB2C.cj.review.note.content": APPB2C_cj_review_note_content5,
  "APPB2C.cj.review.note.title": APPB2C_cj_review_note_title5,
  "APPB2C.cj.review.or": APPB2C_cj_review_or5,
  "APPB2C.cj.review.payLater.buttonText": APPB2C_cj_review_payLater_buttonText5,
  "APPB2C.cj.review.payLater.sideNote": APPB2C_cj_review_payLater_sideNote5,
  "APPB2C.cj.review.payment.fee": APPB2C_cj_review_payment_fee5,
  "APPB2C.cj.review.payment.promoCodes.applied": APPB2C_cj_review_payment_promoCodes_applied5,
  "APPB2C.cj.review.payment.promoCodes.applyCode": APPB2C_cj_review_payment_promoCodes_applyCode5,
  "APPB2C.cj.review.payment.promoCodes.discount": APPB2C_cj_review_payment_promoCodes_discount5,
  "APPB2C.cj.review.payment.promoCodes.generalError": APPB2C_cj_review_payment_promoCodes_generalError5,
  "APPB2C.cj.review.payment.promoCodes.haveCode": APPB2C_cj_review_payment_promoCodes_haveCode5,
  "APPB2C.cj.review.payment.promoCodes.invalidCode": APPB2C_cj_review_payment_promoCodes_invalidCode5,
  "APPB2C.cj.review.payment.promoCodes.newFee": APPB2C_cj_review_payment_promoCodes_newFee5,
  "APPB2C.cj.review.payment.promoCodes.notHaveCode": APPB2C_cj_review_payment_promoCodes_notHaveCode5,
  "APPB2C.cj.review.payment.promoCodes.promo": APPB2C_cj_review_payment_promoCodes_promo5,
  "APPB2C.cj.review.payment.promoCodes.promoCode": APPB2C_cj_review_payment_promoCodes_promoCode5,
  "APPB2C.cj.review.payment.promoCodes.remove": APPB2C_cj_review_payment_promoCodes_remove5,
  "APPB2C.cj.review.payment.promoCodes.removeCode": APPB2C_cj_review_payment_promoCodes_removeCode5,
  "APPB2C.cj.review.payment.promoCodes.total": APPB2C_cj_review_payment_promoCodes_total5,
  "APPB2C.cj.review.payment.tax": APPB2C_cj_review_payment_tax5,
  "APPB2C.cj.review.payment.title": APPB2C_cj_review_payment_title5,
  "APPB2C.cj.review.payment.total": APPB2C_cj_review_payment_total5,
  "APPB2C.cj.review.paymentError": APPB2C_cj_review_paymentError5,
  "APPB2C.cj.review.paymentNote": APPB2C_cj_review_paymentNote5,
  "APPB2C.cj.review.paymentNoteMultiple": APPB2C_cj_review_paymentNoteMultiple4,
  "APPB2C.cj.review.paymentNoteSingle": APPB2C_cj_review_paymentNoteSingle4,
  "APPB2C.cj.review.paymentNoteZeroFee": APPB2C_cj_review_paymentNoteZeroFee5,
  "APPB2C.cj.review.payOnline.buttonText": APPB2C_cj_review_payOnline_buttonText5,
  "APPB2C.cj.review.payOnline.sideNote": APPB2C_cj_review_payOnline_sideNote5,
  "APPB2C.cj.review.personalDetails.address": APPB2C_cj_review_personalDetails_address5,
  "APPB2C.cj.review.personalDetails.dob": APPB2C_cj_review_personalDetails_dob5,
  "APPB2C.cj.review.personalDetails.email": APPB2C_cj_review_personalDetails_email5,
  "APPB2C.cj.review.personalDetails.gender": APPB2C_cj_review_personalDetails_gender5,
  "APPB2C.cj.review.personalDetails.name": APPB2C_cj_review_personalDetails_name5,
  "APPB2C.cj.review.personalDetails.phone": APPB2C_cj_review_personalDetails_phone5,
  "APPB2C.cj.review.personalDetails.smsNotificationsConsent": APPB2C_cj_review_personalDetails_smsNotificationsConsent5,
  "APPB2C.cj.review.personalDetails.title": APPB2C_cj_review_personalDetails_title5,
  "APPB2C.cj.review.registrationError": APPB2C_cj_review_registrationError5,
  "APPB2C.cj.review.registrationFailedModal.changedFee.btn": APPB2C_cj_review_registrationFailedModal_changedFee_btn5,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line1": APPB2C_cj_review_registrationFailedModal_changedFee_line15,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line2": APPB2C_cj_review_registrationFailedModal_changedFee_line25,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line3": APPB2C_cj_review_registrationFailedModal_changedFee_line35,
  "APPB2C.cj.review.registrationFailedModal.changedFee.title": APPB2C_cj_review_registrationFailedModal_changedFee_title5,
  "APPB2C.cj.review.registrationFailedModal.cta": APPB2C_cj_review_registrationFailedModal_cta5,
  "APPB2C.cj.review.registrationFailedModal.message": APPB2C_cj_review_registrationFailedModal_message5,
  "APPB2C.cj.review.registrationFailedModal.title": APPB2C_cj_review_registrationFailedModal_title5,
  "APPB2C.cj.review.taxNumberError": APPB2C_cj_review_taxNumberError5,
  "APPB2C.cj.review.title": APPB2C_cj_review_title5,
  "APPB2C.cj.review.tncs.agreement": APPB2C_cj_review_tncs_agreement5,
  "APPB2C.cj.review.tncs.title": APPB2C_cj_review_tncs_title5,
  "APPB2C.cj.review.tooHighPromoCodeError": APPB2C_cj_review_tooHighPromoCodeError5,
  "APPB2C.cj.timer.modal.cta": APPB2C_cj_timer_modal_cta5,
  "APPB2C.cj.timer.modal.message": APPB2C_cj_timer_modal_message5,
  "APPB2C.cj.timer.modal.title": APPB2C_cj_timer_modal_title5,
  "APPB2C.cj.timer.nearEnd.minuteCount": APPB2C_cj_timer_nearEnd_minuteCount5,
  "APPB2C.cj.timer.nearEnd.minuteCount_other": APPB2C_cj_timer_nearEnd_minuteCount_other5,
  "APPB2C.cj.timer.nearEnd.text": APPB2C_cj_timer_nearEnd_text5,
  "APPB2C.cj.timer.nearEnd.title": APPB2C_cj_timer_nearEnd_title5,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied5,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied.child": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child5,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest5,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest.child": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child5,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle": APPB2C_cj_ttProfile_forms_interest_sectionTitle5,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle.child": APPB2C_cj_ttProfile_forms_interest_sectionTitle_child5,
  "APPB2C.cj.ttProfile.forms.occupation.occupationLevel": APPB2C_cj_ttProfile_forms_occupation_occupationLevel5,
  "APPB2C.cj.ttProfile.forms.occupation.occupationSector": APPB2C_cj_ttProfile_forms_occupation_occupationSector5,
  "APPB2C.cj.ttProfile.forms.occupation.sectionTitle": APPB2C_cj_ttProfile_forms_occupation_sectionTitle5,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality": APPB2C_cj_ttProfile_forms_personal_countryOfNationality5,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality.child": APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child5,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.384": APPB2C_cj_ttProfile_forms_personal_educationLevel_3845,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.385": APPB2C_cj_ttProfile_forms_personal_educationLevel_3855,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.386": APPB2C_cj_ttProfile_forms_personal_educationLevel_3865,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.387": APPB2C_cj_ttProfile_forms_personal_educationLevel_3875,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.title": APPB2C_cj_ttProfile_forms_personal_educationLevel_title5,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken5,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken.child": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child5,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle": APPB2C_cj_ttProfile_forms_personal_sectionTitle5,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle.child": APPB2C_cj_ttProfile_forms_personal_sectionTitle_child5,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish5,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child5,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel5,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child5,
  "APPB2C.cj.ttProfile.note": APPB2C_cj_ttProfile_note5,
  "APPB2C.cj.ttProfile.note.child": APPB2C_cj_ttProfile_note_child5,
  "APPB2C.cj.ttProfile.title": APPB2C_cj_ttProfile_title5,
  "APPB2C.cj.ttProfile.title.child": APPB2C_cj_ttProfile_title_child5,
  "APPB2C.cj.ukviStartPage.ac.content": APPB2C_cj_ukviStartPage_ac_content5,
  "APPB2C.cj.ukviStartPage.ac.title": APPB2C_cj_ukviStartPage_ac_title5,
  "APPB2C.cj.ukviStartPage.gt.content": APPB2C_cj_ukviStartPage_gt_content5,
  "APPB2C.cj.ukviStartPage.gt.title": APPB2C_cj_ukviStartPage_gt_title5,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.content": APPB2C_cj_ukviStartPage_lifeSkils_a1_content5,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.title": APPB2C_cj_ukviStartPage_lifeSkils_a1_title5,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.content": APPB2C_cj_ukviStartPage_lifeSkils_a2_content5,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.title": APPB2C_cj_ukviStartPage_lifeSkils_a2_title5,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.content": APPB2C_cj_ukviStartPage_lifeSkils_b1_content5,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.title": APPB2C_cj_ukviStartPage_lifeSkils_b1_title5,
  "APPB2C.cj.ukviStartPage.lifeSkils.citizenship": APPB2C_cj_ukviStartPage_lifeSkils_citizenship5,
  "APPB2C.cj.ukviStartPage.lifeSkils.extension": APPB2C_cj_ukviStartPage_lifeSkils_extension5,
  "APPB2C.cj.ukviStartPage.lifeSkils.family": APPB2C_cj_ukviStartPage_lifeSkils_family5,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.content": APPB2C_cj_ukviStartPage_lifeSkils_note_content5,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.title": APPB2C_cj_ukviStartPage_lifeSkils_note_title5,
  "APPB2C.cj.ukviStartPage.lifeSkils.title": APPB2C_cj_ukviStartPage_lifeSkils_title5,
  "APPB2C.cj.ukviStartPage.lifeSkils.visaTypes": APPB2C_cj_ukviStartPage_lifeSkils_visaTypes5,
  "APPB2C.cj.ukviStartPage.note1.content": APPB2C_cj_ukviStartPage_note1_content5,
  "APPB2C.cj.ukviStartPage.note1.title": APPB2C_cj_ukviStartPage_note1_title5,
  "APPB2C.cj.ukviStartPage.terms": APPB2C_cj_ukviStartPage_terms5,
  "APPB2C.cj.ukviStartPage.testsBelow": APPB2C_cj_ukviStartPage_testsBelow5,
  "APPB2C.cj.ukviStartPage.title": APPB2C_cj_ukviStartPage_title5,
  "APPB2C.cj.ukviStartPage.whichUkvi": APPB2C_cj_ukviStartPage_whichUkvi5,
  "APPB2C.common.account.details.email.cancel": APPB2C_common_account_details_email_cancel4,
  "APPB2C.common.account.details.email.confirm": APPB2C_common_account_details_email_confirm5,
  "APPB2C.common.account.details.email.label": APPB2C_common_account_details_email_label5,
  "APPB2C.common.account.details.email.maxLength": APPB2C_common_account_details_email_maxLength5,
  "APPB2C.common.account.details.email.mismatch": APPB2C_common_account_details_email_mismatch5,
  "APPB2C.common.account.details.email.note": APPB2C_common_account_details_email_note5,
  "APPB2C.common.account.details.email.save": APPB2C_common_account_details_email_save5,
  "APPB2C.common.account.details.email.set": APPB2C_common_account_details_email_set5,
  "APPB2C.common.account.details.email.success": APPB2C_common_account_details_email_success5,
  "APPB2C.common.account.details.email.username": APPB2C_common_account_details_email_username5,
  "APPB2C.common.account.details.email.validEmail": APPB2C_common_account_details_email_validEmail5,
  "APPB2C.common.account.details.name": APPB2C_common_account_details_name5,
  "APPB2C.common.account.details.psw.cancel": APPB2C_common_account_details_psw_cancel4,
  "APPB2C.common.account.details.psw.change": APPB2C_common_account_details_psw_change5,
  "APPB2C.common.account.details.psw.label": APPB2C_common_account_details_psw_label5,
  "APPB2C.common.account.details.psw.new": APPB2C_common_account_details_psw_new5,
  "APPB2C.common.account.details.psw.old": APPB2C_common_account_details_psw_old5,
  "APPB2C.common.account.details.psw.requirements.levels.0": APPB2C_common_account_details_psw_requirements_levels_05,
  "APPB2C.common.account.details.psw.requirements.levels.1": APPB2C_common_account_details_psw_requirements_levels_15,
  "APPB2C.common.account.details.psw.requirements.levels.2": APPB2C_common_account_details_psw_requirements_levels_25,
  "APPB2C.common.account.details.psw.requirements.levels.3": APPB2C_common_account_details_psw_requirements_levels_35,
  "APPB2C.common.account.details.psw.requirements.levels.4": APPB2C_common_account_details_psw_requirements_levels_45,
  "APPB2C.common.account.details.psw.requirements.maxLength": APPB2C_common_account_details_psw_requirements_maxLength5,
  "APPB2C.common.account.details.psw.requirements.mustHave": APPB2C_common_account_details_psw_requirements_mustHave5,
  "APPB2C.common.account.details.psw.requirements.ok": APPB2C_common_account_details_psw_requirements_ok5,
  "APPB2C.common.account.details.psw.requirements.reqs.length": APPB2C_common_account_details_psw_requirements_reqs_length5,
  "APPB2C.common.account.details.psw.requirements.reqs.lower": APPB2C_common_account_details_psw_requirements_reqs_lower5,
  "APPB2C.common.account.details.psw.requirements.reqs.number": APPB2C_common_account_details_psw_requirements_reqs_number5,
  "APPB2C.common.account.details.psw.requirements.reqs.special": APPB2C_common_account_details_psw_requirements_reqs_special5,
  "APPB2C.common.account.details.psw.requirements.reqs.upper": APPB2C_common_account_details_psw_requirements_reqs_upper5,
  "APPB2C.common.account.details.psw.requirements.shouldHave": APPB2C_common_account_details_psw_requirements_shouldHave5,
  "APPB2C.common.account.details.psw.requirements.strength": APPB2C_common_account_details_psw_requirements_strength5,
  "APPB2C.common.account.details.psw.requirements.tooWeak": APPB2C_common_account_details_psw_requirements_tooWeak5,
  "APPB2C.common.account.details.psw.show": APPB2C_common_account_details_psw_show5,
  "APPB2C.common.account.details.psw.success": APPB2C_common_account_details_psw_success5,
  "APPB2C.common.account.details.subnote.address": APPB2C_common_account_details_subnote_address5,
  "APPB2C.common.account.details.subnote.city": APPB2C_common_account_details_subnote_city5,
  "APPB2C.common.account.error": APPB2C_common_account_error5,
  "APPB2C.common.account.marketing.success": APPB2C_common_account_marketing_success5,
  "APPB2C.common.account.marketing.title": APPB2C_common_account_marketing_title5,
  "APPB2C.common.account.smsNotificationSettings.mobileLabel": APPB2C_common_account_smsNotificationSettings_mobileLabel5,
  "APPB2C.common.account.smsNotificationSettings.success": APPB2C_common_account_smsNotificationSettings_success5,
  "APPB2C.common.account.smsNotificationSettings.title": APPB2C_common_account_smsNotificationSettings_title5,
  "APPB2C.common.account.terms.cta": APPB2C_common_account_terms_cta5,
  "APPB2C.common.account.terms.disclaimer": APPB2C_common_account_terms_disclaimer5,
  "APPB2C.common.account.terms.download": APPB2C_common_account_terms_download5,
  "APPB2C.common.account.terms.extra.line1": APPB2C_common_account_terms_extra_line15,
  "APPB2C.common.account.terms.extra.line2": APPB2C_common_account_terms_extra_line25,
  "APPB2C.common.account.terms.extra.line3.1": APPB2C_common_account_terms_extra_line3_15,
  "APPB2C.common.account.terms.extra.line3.2": APPB2C_common_account_terms_extra_line3_25,
  "APPB2C.common.account.terms.extra.title": APPB2C_common_account_terms_extra_title5,
  "APPB2C.common.account.terms.previous": APPB2C_common_account_terms_previous5,
  "APPB2C.common.account.terms.title": APPB2C_common_account_terms_title5,
  "APPB2C.common.account.title": APPB2C_common_account_title5,
  "APPB2C.common.alertBar.afterTest": APPB2C_common_alertBar_afterTest5,
  "APPB2C.common.alertBar.beforeTest": APPB2C_common_alertBar_beforeTest5,
  "APPB2C.common.alertBar.cancelReupload": APPB2C_common_alertBar_cancelReupload5,
  "APPB2C.common.alertBar.check": APPB2C_common_alertBar_check5,
  "APPB2C.common.alertBar.childConsentMissing": APPB2C_common_alertBar_childConsentMissing5,
  "APPB2C.common.alertBar.creds": APPB2C_common_alertBar_creds5,
  "APPB2C.common.alertBar.eorPayment": APPB2C_common_alertBar_eorPayment5,
  "APPB2C.common.alertBar.eorPaymentProof": APPB2C_common_alertBar_eorPaymentProof5,
  "APPB2C.common.alertBar.idReupload": APPB2C_common_alertBar_idReupload5,
  "APPB2C.common.alertBar.idUpload": APPB2C_common_alertBar_idUpload5,
  "APPB2C.common.alertBar.medicalReupload": APPB2C_common_alertBar_medicalReupload5,
  "APPB2C.common.alertBar.medicalUpload": APPB2C_common_alertBar_medicalUpload5,
  "APPB2C.common.alertBar.regPayment": APPB2C_common_alertBar_regPayment5,
  "APPB2C.common.alertBar.regPaymentProof": APPB2C_common_alertBar_regPaymentProof5,
  "APPB2C.common.alertBar.withCreds": APPB2C_common_alertBar_withCreds5,
  "APPB2C.common.basic.add": APPB2C_common_basic_add5,
  "APPB2C.common.basic.backToTop": APPB2C_common_basic_backToTop5,
  "APPB2C.common.basic.bookingDetails.arrival": APPB2C_common_basic_bookingDetails_arrival5,
  "APPB2C.common.basic.bookingDetails.lifeSkills.results": APPB2C_common_basic_bookingDetails_lifeSkills_results5,
  "APPB2C.common.basic.bookingDetails.lifeSkills.title": APPB2C_common_basic_bookingDetails_lifeSkills_title5,
  "APPB2C.common.basic.bookingDetails.lrw.cd": APPB2C_common_basic_bookingDetails_lrw_cd5,
  "APPB2C.common.basic.bookingDetails.lrw.online": APPB2C_common_basic_bookingDetails_lrw_online5,
  "APPB2C.common.basic.bookingDetails.lrw.pb": APPB2C_common_basic_bookingDetails_lrw_pb5,
  "APPB2C.common.basic.bookingDetails.lrw.results": APPB2C_common_basic_bookingDetails_lrw_results5,
  "APPB2C.common.basic.bookingDetails.lrw.title": APPB2C_common_basic_bookingDetails_lrw_title5,
  "APPB2C.common.basic.bookingDetails.osrTitle.listening": APPB2C_common_basic_bookingDetails_osrTitle_listening5,
  "APPB2C.common.basic.bookingDetails.osrTitle.reading": APPB2C_common_basic_bookingDetails_osrTitle_reading5,
  "APPB2C.common.basic.bookingDetails.speaking.f2f": APPB2C_common_basic_bookingDetails_speaking_f2f5,
  "APPB2C.common.basic.bookingDetails.speaking.live": APPB2C_common_basic_bookingDetails_speaking_live5,
  "APPB2C.common.basic.bookingDetails.speaking.online": APPB2C_common_basic_bookingDetails_speaking_online5,
  "APPB2C.common.basic.bookingDetails.speaking.remote": APPB2C_common_basic_bookingDetails_speaking_remote5,
  "APPB2C.common.basic.bookingDetails.speaking.results": APPB2C_common_basic_bookingDetails_speaking_results5,
  "APPB2C.common.basic.bookingDetails.speaking.title": APPB2C_common_basic_bookingDetails_speaking_title5,
  "APPB2C.common.basic.bookPrePaid": APPB2C_common_basic_bookPrePaid5,
  "APPB2C.common.basic.cancel": APPB2C_common_basic_cancel5,
  "APPB2C.common.basic.change": APPB2C_common_basic_change5,
  "APPB2C.common.basic.choose": APPB2C_common_basic_choose5,
  "APPB2C.common.basic.close": APPB2C_common_basic_close5,
  "APPB2C.common.basic.copied": APPB2C_common_basic_copied5,
  "APPB2C.common.basic.copy": APPB2C_common_basic_copy5,
  "APPB2C.common.basic.dateField.day": APPB2C_common_basic_dateField_day5,
  "APPB2C.common.basic.dateField.month": APPB2C_common_basic_dateField_month5,
  "APPB2C.common.basic.dateField.months": APPB2C_common_basic_dateField_months5,
  "APPB2C.common.basic.dateField.year": APPB2C_common_basic_dateField_year5,
  "APPB2C.common.basic.edit": APPB2C_common_basic_edit5,
  "APPB2C.common.basic.errors.contactTestCentre": APPB2C_common_basic_errors_contactTestCentre5,
  "APPB2C.common.basic.errors.network": APPB2C_common_basic_errors_network5,
  "APPB2C.common.basic.errors.noTest": APPB2C_common_basic_errors_noTest5,
  "APPB2C.common.basic.errors.server": APPB2C_common_basic_errors_server5,
  "APPB2C.common.basic.errors.title": APPB2C_common_basic_errors_title5,
  "APPB2C.common.basic.errors.unableToDownloadFile": APPB2C_common_basic_errors_unableToDownloadFile5,
  "APPB2C.common.basic.errors.unknown": APPB2C_common_basic_errors_unknown5,
  "APPB2C.common.basic.expando.collapse": APPB2C_common_basic_expando_collapse5,
  "APPB2C.common.basic.expando.show": APPB2C_common_basic_expando_show5,
  "APPB2C.common.basic.externalLinkTitle": APPB2C_common_basic_externalLinkTitle5,
  "APPB2C.common.basic.failure": APPB2C_common_basic_failure5,
  "APPB2C.common.basic.forms.addressMaxLength": APPB2C_common_basic_forms_addressMaxLength5,
  "APPB2C.common.basic.forms.dobMin": APPB2C_common_basic_forms_dobMin5,
  "APPB2C.common.basic.forms.dobMinIol": APPB2C_common_basic_forms_dobMinIol5,
  "APPB2C.common.basic.forms.dobOutOfRange": APPB2C_common_basic_forms_dobOutOfRange5,
  "APPB2C.common.basic.forms.firstNameMaxLength": APPB2C_common_basic_forms_firstNameMaxLength5,
  "APPB2C.common.basic.forms.idExpiration": APPB2C_common_basic_forms_idExpiration5,
  "APPB2C.common.basic.forms.idMaxLength": APPB2C_common_basic_forms_idMaxLength5,
  "APPB2C.common.basic.forms.idNumber": APPB2C_common_basic_forms_idNumber5,
  "APPB2C.common.basic.forms.idProofRequired": APPB2C_common_basic_forms_idProofRequired5,
  "APPB2C.common.basic.forms.maxLengthMessage": APPB2C_common_basic_forms_maxLengthMessage5,
  "APPB2C.common.basic.forms.minorDobMax": APPB2C_common_basic_forms_minorDobMax5,
  "APPB2C.common.basic.forms.minorDobMin": APPB2C_common_basic_forms_minorDobMin5,
  "APPB2C.common.basic.forms.mobileBypass": APPB2C_common_basic_forms_mobileBypass5,
  "APPB2C.common.basic.forms.mobileLength": APPB2C_common_basic_forms_mobileLength5,
  "APPB2C.common.basic.forms.onlyLatin": APPB2C_common_basic_forms_onlyLatin5,
  "APPB2C.common.basic.forms.postCodeMaxLength": APPB2C_common_basic_forms_postCodeMaxLength5,
  "APPB2C.common.basic.forms.postCodeMessage": APPB2C_common_basic_forms_postCodeMessage5,
  "APPB2C.common.basic.forms.postCodeNotFound": APPB2C_common_basic_forms_postCodeNotFound5,
  "APPB2C.common.basic.forms.requiredMessage": APPB2C_common_basic_forms_requiredMessage5,
  "APPB2C.common.basic.forms.requiredNoSurmane": APPB2C_common_basic_forms_requiredNoSurmane5,
  "APPB2C.common.basic.forms.stateMaxLength": APPB2C_common_basic_forms_stateMaxLength5,
  "APPB2C.common.basic.forms.surnameMaxLength": APPB2C_common_basic_forms_surnameMaxLength5,
  "APPB2C.common.basic.forms.townMaxLength": APPB2C_common_basic_forms_townMaxLength5,
  "APPB2C.common.basic.forms.unsupportedChars": APPB2C_common_basic_forms_unsupportedChars5,
  "APPB2C.common.basic.forms.validMobile": APPB2C_common_basic_forms_validMobile5,
  "APPB2C.common.basic.goBack": APPB2C_common_basic_goBack5,
  "APPB2C.common.basic.loading": APPB2C_common_basic_loading5,
  "APPB2C.common.basic.menu": APPB2C_common_basic_menu5,
  "APPB2C.common.basic.no": APPB2C_common_basic_no5,
  "APPB2C.common.basic.notAllowed.msg": APPB2C_common_basic_notAllowed_msg5,
  "APPB2C.common.basic.notAllowed.title": APPB2C_common_basic_notAllowed_title5,
  "APPB2C.common.basic.notFound.goHome": APPB2C_common_basic_notFound_goHome5,
  "APPB2C.common.basic.notFound.msg": APPB2C_common_basic_notFound_msg5,
  "APPB2C.common.basic.notFound.title": APPB2C_common_basic_notFound_title5,
  "APPB2C.common.basic.notLoading.note": APPB2C_common_basic_notLoading_note5,
  "APPB2C.common.basic.notLoading.refresh": APPB2C_common_basic_notLoading_refresh5,
  "APPB2C.common.basic.notLoading.title": APPB2C_common_basic_notLoading_title5,
  "APPB2C.common.basic.ok": APPB2C_common_basic_ok5,
  "APPB2C.common.basic.on": APPB2C_common_basic_on5,
  "APPB2C.common.basic.oneSecond": APPB2C_common_basic_oneSecond5,
  "APPB2C.common.basic.pleaseNote": APPB2C_common_basic_pleaseNote5,
  "APPB2C.common.basic.retake": APPB2C_common_basic_retake5,
  "APPB2C.common.basic.upload": APPB2C_common_basic_upload5,
  "APPB2C.common.basic.validationError": APPB2C_common_basic_validationError5,
  "APPB2C.common.basic.waitReassurance": APPB2C_common_basic_waitReassurance5,
  "APPB2C.common.basic.wereSorry": APPB2C_common_basic_wereSorry5,
  "APPB2C.common.basic.yes": APPB2C_common_basic_yes5,
  "APPB2C.common.bookTest.changeExamType": APPB2C_common_bookTest_changeExamType5,
  "APPB2C.common.bookTest.filters.afternoon": APPB2C_common_bookTest_filters_afternoon5,
  "APPB2C.common.bookTest.filters.allDates": APPB2C_common_bookTest_filters_allDates5,
  "APPB2C.common.bookTest.filters.anyTime": APPB2C_common_bookTest_filters_anyTime5,
  "APPB2C.common.bookTest.filters.computer": APPB2C_common_bookTest_filters_computer5,
  "APPB2C.common.bookTest.filters.evening": APPB2C_common_bookTest_filters_evening5,
  "APPB2C.common.bookTest.filters.modal.cta": APPB2C_common_bookTest_filters_modal_cta5,
  "APPB2C.common.bookTest.filters.modal.hide": APPB2C_common_bookTest_filters_modal_hide5,
  "APPB2C.common.bookTest.filters.modal.iDontMind": APPB2C_common_bookTest_filters_modal_iDontMind5,
  "APPB2C.common.bookTest.filters.modal.resultsIn": APPB2C_common_bookTest_filters_modal_resultsIn5,
  "APPB2C.common.bookTest.filters.modal.subtitle": APPB2C_common_bookTest_filters_modal_subtitle5,
  "APPB2C.common.bookTest.filters.modal.title": APPB2C_common_bookTest_filters_modal_title5,
  "APPB2C.common.bookTest.filters.modal.whichFormatTest": APPB2C_common_bookTest_filters_modal_whichFormatTest5,
  "APPB2C.common.bookTest.filters.modal.whichTimeOfDay": APPB2C_common_bookTest_filters_modal_whichTimeOfDay5,
  "APPB2C.common.bookTest.filters.morning": APPB2C_common_bookTest_filters_morning5,
  "APPB2C.common.bookTest.filters.paper": APPB2C_common_bookTest_filters_paper5,
  "APPB2C.common.bookTest.filters.seeAllFilters": APPB2C_common_bookTest_filters_seeAllFilters5,
  "APPB2C.common.bookTest.filters.title": APPB2C_common_bookTest_filters_title5,
  "APPB2C.common.bookTest.loadMoreTests": APPB2C_common_bookTest_loadMoreTests5,
  "APPB2C.common.bookTest.noDiscount.msg.countryNotMatched": APPB2C_common_bookTest_noDiscount_msg_countryNotMatched5,
  "APPB2C.common.bookTest.noDiscount.msg.notFound": APPB2C_common_bookTest_noDiscount_msg_notFound5,
  "APPB2C.common.bookTest.noDiscount.msg.notSupported": APPB2C_common_bookTest_noDiscount_msg_notSupported5,
  "APPB2C.common.bookTest.noDiscount.msg.reserved": APPB2C_common_bookTest_noDiscount_msg_reserved5,
  "APPB2C.common.bookTest.noDiscount.msg.used": APPB2C_common_bookTest_noDiscount_msg_used5,
  "APPB2C.common.bookTest.noDiscount.title": APPB2C_common_bookTest_noDiscount_title5,
  "APPB2C.common.bookTest.results.book": APPB2C_common_bookTest_results_book5,
  "APPB2C.common.bookTest.results.bookFor": APPB2C_common_bookTest_results_bookFor5,
  "APPB2C.common.bookTest.results.cd": APPB2C_common_bookTest_results_cd5,
  "APPB2C.common.bookTest.results.changeSpeaking": APPB2C_common_bookTest_results_changeSpeaking5,
  "APPB2C.common.bookTest.results.clearFilters": APPB2C_common_bookTest_results_clearFilters5,
  "APPB2C.common.bookTest.results.fromCurrentLocation": APPB2C_common_bookTest_results_fromCurrentLocation5,
  "APPB2C.common.bookTest.results.lrw": APPB2C_common_bookTest_results_lrw5,
  "APPB2C.common.bookTest.results.noResultsGoBack": APPB2C_common_bookTest_results_noResultsGoBack5,
  "APPB2C.common.bookTest.results.noResultsText": APPB2C_common_bookTest_results_noResultsText5,
  "APPB2C.common.bookTest.results.noResultsTitle": APPB2C_common_bookTest_results_noResultsTitle5,
  "APPB2C.common.bookTest.results.pb": APPB2C_common_bookTest_results_pb5,
  "APPB2C.common.bookTest.results.review": APPB2C_common_bookTest_results_review5,
  "APPB2C.common.bookTest.results.searchText": APPB2C_common_bookTest_results_searchText5,
  "APPB2C.common.bookTest.results.speaking": APPB2C_common_bookTest_results_speaking5,
  "APPB2C.common.bookTest.results.viewOnMap": APPB2C_common_bookTest_results_viewOnMap5,
  "APPB2C.common.bookTest.sortBy.date": APPB2C_common_bookTest_sortBy_date5,
  "APPB2C.common.bookTest.sortBy.distance": APPB2C_common_bookTest_sortBy_distance5,
  "APPB2C.common.bookTest.sortBy.price": APPB2C_common_bookTest_sortBy_price5,
  "APPB2C.common.bookTest.sortBy.title": APPB2C_common_bookTest_sortBy_title5,
  "APPB2C.common.bookTest.title": APPB2C_common_bookTest_title5,
  "APPB2C.common.cancelBooking.alreadyRequested.cta": APPB2C_common_cancelBooking_alreadyRequested_cta5,
  "APPB2C.common.cancelBooking.alreadyRequested.title": APPB2C_common_cancelBooking_alreadyRequested_title5,
  "APPB2C.common.cancelBooking.back": APPB2C_common_cancelBooking_back5,
  "APPB2C.common.cancelBooking.bankDetails.form.accountHolder": APPB2C_common_cancelBooking_bankDetails_form_accountHolder5,
  "APPB2C.common.cancelBooking.bankDetails.form.bankAddress": APPB2C_common_cancelBooking_bankDetails_form_bankAddress5,
  "APPB2C.common.cancelBooking.bankDetails.form.bankName": APPB2C_common_cancelBooking_bankDetails_form_bankName5,
  "APPB2C.common.cancelBooking.bankDetails.form.bankNumber": APPB2C_common_cancelBooking_bankDetails_form_bankNumber5,
  "APPB2C.common.cancelBooking.bankDetails.form.changeCountry": APPB2C_common_cancelBooking_bankDetails_form_changeCountry5,
  "APPB2C.common.cancelBooking.bankDetails.form.city": APPB2C_common_cancelBooking_bankDetails_form_city5,
  "APPB2C.common.cancelBooking.bankDetails.form.country": APPB2C_common_cancelBooking_bankDetails_form_country5,
  "APPB2C.common.cancelBooking.bankDetails.form.holderEmail": APPB2C_common_cancelBooking_bankDetails_form_holderEmail5,
  "APPB2C.common.cancelBooking.bankDetails.form.holderName": APPB2C_common_cancelBooking_bankDetails_form_holderName5,
  "APPB2C.common.cancelBooking.bankDetails.form.iban": APPB2C_common_cancelBooking_bankDetails_form_iban5,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidIban": APPB2C_common_cancelBooking_bankDetails_form_invalidIban5,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidSwift": APPB2C_common_cancelBooking_bankDetails_form_invalidSwift5,
  "APPB2C.common.cancelBooking.bankDetails.form.postcode": APPB2C_common_cancelBooking_bankDetails_form_postcode5,
  "APPB2C.common.cancelBooking.bankDetails.form.sortCode": APPB2C_common_cancelBooking_bankDetails_form_sortCode5,
  "APPB2C.common.cancelBooking.bankDetails.form.swift": APPB2C_common_cancelBooking_bankDetails_form_swift5,
  "APPB2C.common.cancelBooking.bankDetails.note": APPB2C_common_cancelBooking_bankDetails_note5,
  "APPB2C.common.cancelBooking.bankDetails.privacy": APPB2C_common_cancelBooking_bankDetails_privacy5,
  "APPB2C.common.cancelBooking.bankDetails.terms.consent": APPB2C_common_cancelBooking_bankDetails_terms_consent5,
  "APPB2C.common.cancelBooking.bankDetails.terms.title": APPB2C_common_cancelBooking_bankDetails_terms_title5,
  "APPB2C.common.cancelBooking.bankDetails.title": APPB2C_common_cancelBooking_bankDetails_title5,
  "APPB2C.common.cancelBooking.bottomNotes.note1": APPB2C_common_cancelBooking_bottomNotes_note15,
  "APPB2C.common.cancelBooking.bottomNotes.note2": APPB2C_common_cancelBooking_bottomNotes_note25,
  "APPB2C.common.cancelBooking.bottomNotes.note3": APPB2C_common_cancelBooking_bottomNotes_note35,
  "APPB2C.common.cancelBooking.bottomNotes.terms": APPB2C_common_cancelBooking_bottomNotes_terms5,
  "APPB2C.common.cancelBooking.cancelNotes.1": APPB2C_common_cancelBooking_cancelNotes_15,
  "APPB2C.common.cancelBooking.cancelNotes.2": APPB2C_common_cancelBooking_cancelNotes_25,
  "APPB2C.common.cancelBooking.cancelNotes.3": APPB2C_common_cancelBooking_cancelNotes_35,
  "APPB2C.common.cancelBooking.checkTest": APPB2C_common_cancelBooking_checkTest5,
  "APPB2C.common.cancelBooking.cta.addBankDetails": APPB2C_common_cancelBooking_cta_addBankDetails5,
  "APPB2C.common.cancelBooking.cta.goBack": APPB2C_common_cancelBooking_cta_goBack5,
  "APPB2C.common.cancelBooking.cta.submit": APPB2C_common_cancelBooking_cta_submit5,
  "APPB2C.common.cancelBooking.error.cancelledAlready": APPB2C_common_cancelBooking_error_cancelledAlready5,
  "APPB2C.common.cancelBooking.error.missingFile": APPB2C_common_cancelBooking_error_missingFile5,
  "APPB2C.common.cancelBooking.error.requestedAlready": APPB2C_common_cancelBooking_error_requestedAlready5,
  "APPB2C.common.cancelBooking.reason.form.choose": APPB2C_common_cancelBooking_reason_form_choose5,
  "APPB2C.common.cancelBooking.reason.form.freeform": APPB2C_common_cancelBooking_reason_form_freeform5,
  "APPB2C.common.cancelBooking.reason.form.optional": APPB2C_common_cancelBooking_reason_form_optional5,
  "APPB2C.common.cancelBooking.reason.form.requiredEvidence": APPB2C_common_cancelBooking_reason_form_requiredEvidence5,
  "APPB2C.common.cancelBooking.reason.form.requiredReason": APPB2C_common_cancelBooking_reason_form_requiredReason5,
  "APPB2C.common.cancelBooking.reason.form.title": APPB2C_common_cancelBooking_reason_form_title5,
  "APPB2C.common.cancelBooking.reason.note.content": APPB2C_common_cancelBooking_reason_note_content5,
  "APPB2C.common.cancelBooking.reason.note.title": APPB2C_common_cancelBooking_reason_note_title5,
  "APPB2C.common.cancelBooking.subtitle": APPB2C_common_cancelBooking_subtitle5,
  "APPB2C.common.cancelBooking.title": APPB2C_common_cancelBooking_title5,
  "APPB2C.common.cancelBooking.transferNote.cta": APPB2C_common_cancelBooking_transferNote_cta5,
  "APPB2C.common.cancelBooking.transferNote.title": APPB2C_common_cancelBooking_transferNote_title5,
  "APPB2C.common.cancelBooking.transferNote.titleIol": APPB2C_common_cancelBooking_transferNote_titleIol5,
  "APPB2C.common.cancelBooking.upload.mobile": APPB2C_common_cancelBooking_upload_mobile5,
  "APPB2C.common.cancelBooking.upload.notes.1": APPB2C_common_cancelBooking_upload_notes_15,
  "APPB2C.common.cancelBooking.upload.notes.10": APPB2C_common_cancelBooking_upload_notes_105,
  "APPB2C.common.cancelBooking.upload.notes.2": APPB2C_common_cancelBooking_upload_notes_25,
  "APPB2C.common.cancelBooking.upload.notes.3": APPB2C_common_cancelBooking_upload_notes_35,
  "APPB2C.common.cancelBooking.upload.notes.4": APPB2C_common_cancelBooking_upload_notes_45,
  "APPB2C.common.cancelBooking.upload.notes.5": APPB2C_common_cancelBooking_upload_notes_55,
  "APPB2C.common.cancelBooking.upload.notes.6": APPB2C_common_cancelBooking_upload_notes_65,
  "APPB2C.common.cancelBooking.upload.notes.7": APPB2C_common_cancelBooking_upload_notes_75,
  "APPB2C.common.cancelBooking.upload.notes.8": APPB2C_common_cancelBooking_upload_notes_85,
  "APPB2C.common.cancelBooking.upload.requirements.note": APPB2C_common_cancelBooking_upload_requirements_note5,
  "APPB2C.common.cancelBooking.upload.requirements.title": APPB2C_common_cancelBooking_upload_requirements_title5,
  "APPB2C.common.cancelBooking.upload.title": APPB2C_common_cancelBooking_upload_title5,
  "APPB2C.common.changeBooking.cancel.cta": APPB2C_common_changeBooking_cancel_cta5,
  "APPB2C.common.changeBooking.cancel.text": APPB2C_common_changeBooking_cancel_text5,
  "APPB2C.common.changeBooking.goBack": APPB2C_common_changeBooking_goBack5,
  "APPB2C.common.changeBooking.subtitle": APPB2C_common_changeBooking_subtitle5,
  "APPB2C.common.changeBooking.title": APPB2C_common_changeBooking_title5,
  "APPB2C.common.changeBooking.transfer.cta": APPB2C_common_changeBooking_transfer_cta5,
  "APPB2C.common.changeBooking.transfer.text": APPB2C_common_changeBooking_transfer_text5,
  "APPB2C.common.changeBooking.transfer.textIol": APPB2C_common_changeBooking_transfer_textIol5,
  "APPB2C.common.changeBooking.transferNotes.1": APPB2C_common_changeBooking_transferNotes_15,
  "APPB2C.common.changeBooking.transferNotes.2": APPB2C_common_changeBooking_transferNotes_25,
  "APPB2C.common.changeBooking.transferNotesIol.1": APPB2C_common_changeBooking_transferNotesIol_15,
  "APPB2C.common.changeBooking.transferNotesIol.2": APPB2C_common_changeBooking_transferNotesIol_25,
  "APPB2C.common.changeBookingAck.back": APPB2C_common_changeBookingAck_back5,
  "APPB2C.common.changeBookingAck.cancel.banner": APPB2C_common_changeBookingAck_cancel_banner5,
  "APPB2C.common.changeBookingAck.cancel.contact.email": APPB2C_common_changeBookingAck_cancel_contact_email5,
  "APPB2C.common.changeBookingAck.cancel.contact.phone": APPB2C_common_changeBookingAck_cancel_contact_phone5,
  "APPB2C.common.changeBookingAck.cancel.contact.title": APPB2C_common_changeBookingAck_cancel_contact_title5,
  "APPB2C.common.changeBookingAck.cancel.contact.titleIol": APPB2C_common_changeBookingAck_cancel_contact_titleIol5,
  "APPB2C.common.changeBookingAck.cancel.refNr": APPB2C_common_changeBookingAck_cancel_refNr5,
  "APPB2C.common.changeBookingAck.cancel.refunds": APPB2C_common_changeBookingAck_cancel_refunds5,
  "APPB2C.common.changeBookingAck.cancel.respondTime": APPB2C_common_changeBookingAck_cancel_respondTime5,
  "APPB2C.common.changeBookingAck.cancel.terms": APPB2C_common_changeBookingAck_cancel_terms5,
  "APPB2C.common.changeBookingAck.cancel.title": APPB2C_common_changeBookingAck_cancel_title5,
  "APPB2C.common.changeBookingAck.fees": APPB2C_common_changeBookingAck_fees5,
  "APPB2C.common.changeBookingAck.feesIol": APPB2C_common_changeBookingAck_feesIol5,
  "APPB2C.common.changeBookingAck.speaking.banner": APPB2C_common_changeBookingAck_speaking_banner5,
  "APPB2C.common.changeBookingAck.speaking.note.line1": APPB2C_common_changeBookingAck_speaking_note_line15,
  "APPB2C.common.changeBookingAck.speaking.note.line2": APPB2C_common_changeBookingAck_speaking_note_line25,
  "APPB2C.common.changeBookingAck.speaking.note.title": APPB2C_common_changeBookingAck_speaking_note_title5,
  "APPB2C.common.changeBookingAck.speaking.title": APPB2C_common_changeBookingAck_speaking_title5,
  "APPB2C.common.changeBookingAck.terms": APPB2C_common_changeBookingAck_terms5,
  "APPB2C.common.changeBookingAck.transfer.banner": APPB2C_common_changeBookingAck_transfer_banner5,
  "APPB2C.common.changeBookingAck.transfer.note": APPB2C_common_changeBookingAck_transfer_note5,
  "APPB2C.common.changeBookingAck.transfer.respondTime": APPB2C_common_changeBookingAck_transfer_respondTime5,
  "APPB2C.common.changeBookingAck.transfer.title": APPB2C_common_changeBookingAck_transfer_title5,
  "APPB2C.common.changeBookingAck.transferIol.banner": APPB2C_common_changeBookingAck_transferIol_banner5,
  "APPB2C.common.changeBookingAck.transferIol.contact": APPB2C_common_changeBookingAck_transferIol_contact5,
  "APPB2C.common.changeBookingAck.transferIol.note": APPB2C_common_changeBookingAck_transferIol_note5,
  "APPB2C.common.changeBookingAck.transferIol.refNr": APPB2C_common_changeBookingAck_transferIol_refNr5,
  "APPB2C.common.changeBookingAck.transferIol.terms": APPB2C_common_changeBookingAck_transferIol_terms5,
  "APPB2C.common.changeSpeaking.cards.change": APPB2C_common_changeSpeaking_cards_change5,
  "APPB2C.common.changeSpeaking.cards.noSlots": APPB2C_common_changeSpeaking_cards_noSlots5,
  "APPB2C.common.changeSpeaking.cards.noSlots.error": APPB2C_common_changeSpeaking_cards_noSlots_error5,
  "APPB2C.common.changeSpeaking.cards.title": APPB2C_common_changeSpeaking_cards_title5,
  "APPB2C.common.changeSpeaking.cta": APPB2C_common_changeSpeaking_cta5,
  "APPB2C.common.changeSpeaking.modals.noSlots.keepTest": APPB2C_common_changeSpeaking_modals_noSlots_keepTest5,
  "APPB2C.common.changeSpeaking.modals.noSlots.line1": APPB2C_common_changeSpeaking_modals_noSlots_line15,
  "APPB2C.common.changeSpeaking.modals.noSlots.line2": APPB2C_common_changeSpeaking_modals_noSlots_line25,
  "APPB2C.common.changeSpeaking.modals.noSlots.title": APPB2C_common_changeSpeaking_modals_noSlots_title5,
  "APPB2C.common.changeSpeaking.modals.noSlots.transferTest": APPB2C_common_changeSpeaking_modals_noSlots_transferTest5,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.cta": APPB2C_common_changeSpeaking_modals_testNotAvailable_cta5,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line1": APPB2C_common_changeSpeaking_modals_testNotAvailable_line15,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line2": APPB2C_common_changeSpeaking_modals_testNotAvailable_line25,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.title": APPB2C_common_changeSpeaking_modals_testNotAvailable_title5,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.cta": APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta5,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.line1": APPB2C_common_changeSpeaking_modals_transferNotAvailable_line15,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.title": APPB2C_common_changeSpeaking_modals_transferNotAvailable_title5,
  "APPB2C.common.changeSpeaking.note.back": APPB2C_common_changeSpeaking_note_back5,
  "APPB2C.common.changeSpeaking.note.line1": APPB2C_common_changeSpeaking_note_line15,
  "APPB2C.common.changeSpeaking.note.line2": APPB2C_common_changeSpeaking_note_line25,
  "APPB2C.common.changeSpeaking.note.title": APPB2C_common_changeSpeaking_note_title5,
  "APPB2C.common.changeSpeaking.title": APPB2C_common_changeSpeaking_title5,
  "APPB2C.common.chooseTest.difference": APPB2C_common_chooseTest_difference5,
  "APPB2C.common.chooseTest.iol.consent": APPB2C_common_chooseTest_iol_consent5,
  "APPB2C.common.chooseTest.iol.cta": APPB2C_common_chooseTest_iol_cta5,
  "APPB2C.common.chooseTest.iol.desc": APPB2C_common_chooseTest_iol_desc5,
  "APPB2C.common.chooseTest.iol.link": APPB2C_common_chooseTest_iol_link5,
  "APPB2C.common.chooseTest.iol.note.content": APPB2C_common_chooseTest_iol_note_content5,
  "APPB2C.common.chooseTest.iol.note.title": APPB2C_common_chooseTest_iol_note_title5,
  "APPB2C.common.chooseTest.iol.title": APPB2C_common_chooseTest_iol_title5,
  "APPB2C.common.chooseTest.ors.cta": APPB2C_common_chooseTest_ors_cta5,
  "APPB2C.common.chooseTest.ors.descAc": APPB2C_common_chooseTest_ors_descAc5,
  "APPB2C.common.chooseTest.ors.descGt": APPB2C_common_chooseTest_ors_descGt5,
  "APPB2C.common.chooseTest.ors.title": APPB2C_common_chooseTest_ors_title5,
  "APPB2C.common.chooseTest.title": APPB2C_common_chooseTest_title5,
  "APPB2C.common.chooseTest.ukvi.difference": APPB2C_common_chooseTest_ukvi_difference5,
  "APPB2C.common.chooseTest.ukvi.inCentre.cta": APPB2C_common_chooseTest_ukvi_inCentre_cta5,
  "APPB2C.common.chooseTest.ukvi.inCentre.descAc": APPB2C_common_chooseTest_ukvi_inCentre_descAc5,
  "APPB2C.common.chooseTest.ukvi.inCentre.descGt": APPB2C_common_chooseTest_ukvi_inCentre_descGt5,
  "APPB2C.common.chooseTest.ukvi.inCentre.title": APPB2C_common_chooseTest_ukvi_inCentre_title5,
  "APPB2C.common.chooseTest.ukvi.online.consent": APPB2C_common_chooseTest_ukvi_online_consent5,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.symbol": APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol5,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.title": APPB2C_common_chooseTest_ukvi_online_consentBiometric_title5,
  "APPB2C.common.chooseTest.ukvi.online.cta": APPB2C_common_chooseTest_ukvi_online_cta5,
  "APPB2C.common.chooseTest.ukvi.online.link": APPB2C_common_chooseTest_ukvi_online_link5,
  "APPB2C.common.chooseTest.ukvi.online.note.content": APPB2C_common_chooseTest_ukvi_online_note_content5,
  "APPB2C.common.chooseTest.ukvi.online.note.title": APPB2C_common_chooseTest_ukvi_online_note_title5,
  "APPB2C.common.chooseTest.ukvi.online.title": APPB2C_common_chooseTest_ukvi_online_title5,
  "APPB2C.common.draft.cancel": APPB2C_common_draft_cancel4,
  "APPB2C.common.draft.cta.confirm": APPB2C_common_draft_cta_confirm5,
  "APPB2C.common.draft.cta.decline": APPB2C_common_draft_cta_decline5,
  "APPB2C.common.draft.cta.note": APPB2C_common_draft_cta_note5,
  "APPB2C.common.draft.difference": APPB2C_common_draft_difference5,
  "APPB2C.common.draft.edit": APPB2C_common_draft_edit5,
  "APPB2C.common.draft.error.pastDeadline": APPB2C_common_draft_error_pastDeadline5,
  "APPB2C.common.draft.id.cta": APPB2C_common_draft_id_cta5,
  "APPB2C.common.draft.id.documentWithNoExpiryDate": APPB2C_common_draft_id_documentWithNoExpiryDate5,
  "APPB2C.common.draft.id.header": APPB2C_common_draft_id_header5,
  "APPB2C.common.draft.id.issuingAuthority": APPB2C_common_draft_id_issuingAuthority5,
  "APPB2C.common.draft.id.note": APPB2C_common_draft_id_note5,
  "APPB2C.common.draft.id.title": APPB2C_common_draft_id_title5,
  "APPB2C.common.draft.note.line1": APPB2C_common_draft_note_line15,
  "APPB2C.common.draft.note.line2": APPB2C_common_draft_note_line25,
  "APPB2C.common.draft.note.line3": APPB2C_common_draft_note_line35,
  "APPB2C.common.draft.personal.cta": APPB2C_common_draft_personal_cta5,
  "APPB2C.common.draft.personal.header": APPB2C_common_draft_personal_header5,
  "APPB2C.common.draft.personal.note": APPB2C_common_draft_personal_note5,
  "APPB2C.common.draft.personal.title": APPB2C_common_draft_personal_title5,
  "APPB2C.common.draft.title": APPB2C_common_draft_title5,
  "APPB2C.common.examType.iol.ac": APPB2C_common_examType_iol_ac5,
  "APPB2C.common.examType.iol.gt": APPB2C_common_examType_iol_gt5,
  "APPB2C.common.examType.ors.ac": APPB2C_common_examType_ors_ac5,
  "APPB2C.common.examType.ors.gt": APPB2C_common_examType_ors_gt5,
  "APPB2C.common.examType.ors.osr.ac": APPB2C_common_examType_ors_osr_ac5,
  "APPB2C.common.examType.ors.osr.gt": APPB2C_common_examType_ors_osr_gt5,
  "APPB2C.common.examType.ukvi.ac": APPB2C_common_examType_ukvi_ac5,
  "APPB2C.common.examType.ukvi.gt": APPB2C_common_examType_ukvi_gt5,
  "APPB2C.common.examType.ukvi.lfska1": APPB2C_common_examType_ukvi_lfska15,
  "APPB2C.common.examType.ukvi.lfska2": APPB2C_common_examType_ukvi_lfska25,
  "APPB2C.common.examType.ukvi.lfskb1": APPB2C_common_examType_ukvi_lfskb15,
  "APPB2C.common.examType.ukvi.osr.ac": APPB2C_common_examType_ukvi_osr_ac5,
  "APPB2C.common.examType.ukvi.osr.gt": APPB2C_common_examType_ukvi_osr_gt5,
  "APPB2C.common.examType.uol.ac": APPB2C_common_examType_uol_ac5,
  "APPB2C.common.examType.uol.gt": APPB2C_common_examType_uol_gt5,
  "APPB2C.common.filePreview.label": APPB2C_common_filePreview_label5,
  "APPB2C.common.filePreview.pdfPreviewLink": APPB2C_common_filePreview_pdfPreviewLink5,
  "APPB2C.common.filePreview.status": APPB2C_common_filePreview_status5,
  "APPB2C.common.filePreview.statusName.approved": APPB2C_common_filePreview_statusName_approved5,
  "APPB2C.common.filePreview.statusName.deleted": APPB2C_common_filePreview_statusName_deleted5,
  "APPB2C.common.filePreview.statusName.pending": APPB2C_common_filePreview_statusName_pending5,
  "APPB2C.common.filePreview.statusName.rejected": APPB2C_common_filePreview_statusName_rejected5,
  "APPB2C.common.filePreview.statusName.reviewed": APPB2C_common_filePreview_statusName_reviewed5,
  "APPB2C.common.fileUpload.cta": APPB2C_common_fileUpload_cta5,
  "APPB2C.common.fileUpload.exceededSize": APPB2C_common_fileUpload_exceededSize5,
  "APPB2C.common.fileUpload.fileApproved": APPB2C_common_fileUpload_fileApproved5,
  "APPB2C.common.fileUpload.fileOptional": APPB2C_common_fileUpload_fileOptional5,
  "APPB2C.common.fileUpload.fileRequired": APPB2C_common_fileUpload_fileRequired5,
  "APPB2C.common.fileUpload.filesCount": APPB2C_common_fileUpload_filesCount5,
  "APPB2C.common.fileUpload.fileSize": APPB2C_common_fileUpload_fileSize5,
  "APPB2C.common.fileUpload.imgAlt": APPB2C_common_fileUpload_imgAlt5,
  "APPB2C.common.fileUpload.pdfIeMsg": APPB2C_common_fileUpload_pdfIeMsg5,
  "APPB2C.common.fileUpload.pdfPasswordMsg": APPB2C_common_fileUpload_pdfPasswordMsg5,
  "APPB2C.common.fileUpload.remove": APPB2C_common_fileUpload_remove5,
  "APPB2C.common.fileUpload.requirements": APPB2C_common_fileUpload_requirements5,
  "APPB2C.common.fileUpload.success": APPB2C_common_fileUpload_success5,
  "APPB2C.common.findTest.changeCity": APPB2C_common_findTest_changeCity5,
  "APPB2C.common.findTest.changeCountry": APPB2C_common_findTest_changeCountry5,
  "APPB2C.common.findTest.cta": APPB2C_common_findTest_cta5,
  "APPB2C.common.findTest.dates.clear": APPB2C_common_findTest_dates_clear5,
  "APPB2C.common.findTest.dates.from": APPB2C_common_findTest_dates_from5,
  "APPB2C.common.findTest.dates.noDatesSelected": APPB2C_common_findTest_dates_noDatesSelected5,
  "APPB2C.common.findTest.dates.noresults.details": APPB2C_common_findTest_dates_noresults_details5,
  "APPB2C.common.findTest.dates.noresults.title": APPB2C_common_findTest_dates_noresults_title5,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.cta": APPB2C_common_findTest_dates_noresultsMobileLocation_cta5,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.details": APPB2C_common_findTest_dates_noresultsMobileLocation_details5,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.title": APPB2C_common_findTest_dates_noresultsMobileLocation_title5,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.details": APPB2C_common_findTest_dates_noresultsSpecialReqs_details5,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.title": APPB2C_common_findTest_dates_noresultsSpecialReqs_title5,
  "APPB2C.common.findTest.dates.pleaseSelect": APPB2C_common_findTest_dates_pleaseSelect5,
  "APPB2C.common.findTest.dates.selected": APPB2C_common_findTest_dates_selected5,
  "APPB2C.common.findTest.dates.showAll": APPB2C_common_findTest_dates_showAll5,
  "APPB2C.common.findTest.dates.showDateRange": APPB2C_common_findTest_dates_showDateRange5,
  "APPB2C.common.findTest.dates.title": APPB2C_common_findTest_dates_title5,
  "APPB2C.common.findTest.dates.to": APPB2C_common_findTest_dates_to5,
  "APPB2C.common.findTest.examFormat.all": APPB2C_common_findTest_examFormat_all5,
  "APPB2C.common.findTest.examFormat.cd": APPB2C_common_findTest_examFormat_cd5,
  "APPB2C.common.findTest.examFormat.pb": APPB2C_common_findTest_examFormat_pb5,
  "APPB2C.common.findTest.examFormat.results": APPB2C_common_findTest_examFormat_results5,
  "APPB2C.common.findTest.examFormat.title": APPB2C_common_findTest_examFormat_title5,
  "APPB2C.common.findTest.iol.noLrwResults": APPB2C_common_findTest_iol_noLrwResults5,
  "APPB2C.common.findTest.iol.organisationCheck.check": APPB2C_common_findTest_iol_organisationCheck_check5,
  "APPB2C.common.findTest.iol.organisationCheck.desc": APPB2C_common_findTest_iol_organisationCheck_desc5,
  "APPB2C.common.findTest.iol.organisationCheck.title": APPB2C_common_findTest_iol_organisationCheck_title5,
  "APPB2C.common.findTest.iol.softwareCheck.check": APPB2C_common_findTest_iol_softwareCheck_check5,
  "APPB2C.common.findTest.iol.softwareCheck.desc1": APPB2C_common_findTest_iol_softwareCheck_desc15,
  "APPB2C.common.findTest.iol.softwareCheck.desc2": APPB2C_common_findTest_iol_softwareCheck_desc25,
  "APPB2C.common.findTest.iol.softwareCheck.link": APPB2C_common_findTest_iol_softwareCheck_link5,
  "APPB2C.common.findTest.iol.softwareCheck.title": APPB2C_common_findTest_iol_softwareCheck_title5,
  "APPB2C.common.findTest.langModal.choose": APPB2C_common_findTest_langModal_choose5,
  "APPB2C.common.findTest.langModal.chooseSingle": APPB2C_common_findTest_langModal_chooseSingle5,
  "APPB2C.common.findTest.langModal.continue": APPB2C_common_findTest_langModal_continue5,
  "APPB2C.common.findTest.langModal.title": APPB2C_common_findTest_langModal_title5,
  "APPB2C.common.findTest.multipleLocations": APPB2C_common_findTest_multipleLocations5,
  "APPB2C.common.findTest.osrInfo": APPB2C_common_findTest_osrInfo5,
  "APPB2C.common.findTest.osrInfoLink": APPB2C_common_findTest_osrInfoLink5,
  "APPB2C.common.findTest.residencyConfirmation.btn": APPB2C_common_findTest_residencyConfirmation_btn5,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.btn": APPB2C_common_findTest_residencyConfirmation_errorMsg_btn5,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line1": APPB2C_common_findTest_residencyConfirmation_errorMsg_line15,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line2": APPB2C_common_findTest_residencyConfirmation_errorMsg_line25,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.title": APPB2C_common_findTest_residencyConfirmation_errorMsg_title5,
  "APPB2C.common.findTest.residencyConfirmation.msg": APPB2C_common_findTest_residencyConfirmation_msg4,
  "APPB2C.common.findTest.residencyConfirmation.msg.line1": APPB2C_common_findTest_residencyConfirmation_msg_line15,
  "APPB2C.common.findTest.residencyConfirmation.msg.line2": APPB2C_common_findTest_residencyConfirmation_msg_line25,
  "APPB2C.common.findTest.residencyConfirmation.msg.line3": APPB2C_common_findTest_residencyConfirmation_msg_line35,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInCountryLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine25,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInLocationLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine25,
  "APPB2C.common.findTest.residencyConfirmation.selectDates": APPB2C_common_findTest_residencyConfirmation_selectDates5,
  "APPB2C.common.findTest.residencyConfirmation.title": APPB2C_common_findTest_residencyConfirmation_title5,
  "APPB2C.common.findTest.searchIn": APPB2C_common_findTest_searchIn5,
  "APPB2C.common.findTest.selectCountry.continue": APPB2C_common_findTest_selectCountry_continue5,
  "APPB2C.common.findTest.selectCountry.countryNotSupported": APPB2C_common_findTest_selectCountry_countryNotSupported5,
  "APPB2C.common.findTest.selectCountry.label": APPB2C_common_findTest_selectCountry_label5,
  "APPB2C.common.findTest.selectCountry.placeholder": APPB2C_common_findTest_selectCountry_placeholder5,
  "APPB2C.common.findTest.selectLocation.closestToMe": APPB2C_common_findTest_selectLocation_closestToMe5,
  "APPB2C.common.findTest.selectLocation.geolocationBlocked": APPB2C_common_findTest_selectLocation_geolocationBlocked5,
  "APPB2C.common.findTest.selectLocation.label": APPB2C_common_findTest_selectLocation_label5,
  "APPB2C.common.findTest.selectLocation.noExamForThisCountry": APPB2C_common_findTest_selectLocation_noExamForThisCountry5,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation5,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.cta": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta5,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option1": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option15,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option2": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option25,
  "APPB2C.common.findTest.specialReqs.findOutMore": APPB2C_common_findTest_specialReqs_findOutMore5,
  "APPB2C.common.findTest.specialReqs.list.subtitle": APPB2C_common_findTest_specialReqs_list_subtitle5,
  "APPB2C.common.findTest.specialReqs.list.title": APPB2C_common_findTest_specialReqs_list_title5,
  "APPB2C.common.findTest.specialReqs.needCheckbox": APPB2C_common_findTest_specialReqs_needCheckbox5,
  "APPB2C.common.findTest.specialReqs.other": APPB2C_common_findTest_specialReqs_other5,
  "APPB2C.common.findTest.specialReqs.pleaseNoteMessage": APPB2C_common_findTest_specialReqs_pleaseNoteMessage5,
  "APPB2C.common.findTest.subtitle": APPB2C_common_findTest_subtitle5,
  "APPB2C.common.findTest.title": APPB2C_common_findTest_title5,
  "APPB2C.common.findTest.uol.organisationCheck.check": APPB2C_common_findTest_uol_organisationCheck_check5,
  "APPB2C.common.findTest.uol.organisationCheck.desc": APPB2C_common_findTest_uol_organisationCheck_desc5,
  "APPB2C.common.findTest.uol.organisationCheck.title": APPB2C_common_findTest_uol_organisationCheck_title5,
  "APPB2C.common.findTest.uol.softwareCheck.check": APPB2C_common_findTest_uol_softwareCheck_check5,
  "APPB2C.common.findTest.uol.softwareCheck.desc1": APPB2C_common_findTest_uol_softwareCheck_desc15,
  "APPB2C.common.findTest.uol.softwareCheck.desc2": APPB2C_common_findTest_uol_softwareCheck_desc25,
  "APPB2C.common.findTest.uol.softwareCheck.link": APPB2C_common_findTest_uol_softwareCheck_link5,
  "APPB2C.common.findTest.uol.softwareCheck.title": APPB2C_common_findTest_uol_softwareCheck_title5,
  "APPB2C.common.footer.terms.accessibility": APPB2C_common_footer_terms_accessibility5,
  "APPB2C.common.footer.terms.copyrightLine1": APPB2C_common_footer_terms_copyrightLine15,
  "APPB2C.common.footer.terms.copyrightLine2": APPB2C_common_footer_terms_copyrightLine25,
  "APPB2C.common.footer.terms.dataProtection": APPB2C_common_footer_terms_dataProtection5,
  "APPB2C.common.footer.terms.privacyAndCookies": APPB2C_common_footer_terms_privacyAndCookies5,
  "APPB2C.common.footer.terms.termsOfUse": APPB2C_common_footer_terms_termsOfUse5,
  "APPB2C.common.gelRedirect.errorMessage": APPB2C_common_gelRedirect_errorMessage5,
  "APPB2C.common.gelRedirect.goBack": APPB2C_common_gelRedirect_goBack4,
  "APPB2C.common.gelRedirect.header": APPB2C_common_gelRedirect_header5,
  "APPB2C.common.gelRedirect.info": APPB2C_common_gelRedirect_info5,
  "APPB2C.common.general.next": APPB2C_common_general_next5,
  "APPB2C.common.general.previous": APPB2C_common_general_previous5,
  "APPB2C.common.general.regNotFound": APPB2C_common_general_regNotFound5,
  "APPB2C.common.general.remove": APPB2C_common_general_remove5,
  "APPB2C.common.general.steps": APPB2C_common_general_steps5,
  "APPB2C.common.general.stepsContinued": APPB2C_common_general_stepsContinued5,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabel": APPB2C_common_helpAndContact_actionSection_contactFormLabel5,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabelInCentre": APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre5,
  "APPB2C.common.helpAndContact.actionSection.contactFormLink": APPB2C_common_helpAndContact_actionSection_contactFormLink5,
  "APPB2C.common.helpAndContact.actionSection.faqLabel": APPB2C_common_helpAndContact_actionSection_faqLabel5,
  "APPB2C.common.helpAndContact.actionSection.faqLink": APPB2C_common_helpAndContact_actionSection_faqLink5,
  "APPB2C.common.helpAndContact.actionSection.icHeader": APPB2C_common_helpAndContact_actionSection_icHeader5,
  "APPB2C.common.helpAndContact.actionSection.iolHeader": APPB2C_common_helpAndContact_actionSection_iolHeader5,
  "APPB2C.common.helpAndContact.actionSection.launchChatBtn": APPB2C_common_helpAndContact_actionSection_launchChatBtn5,
  "APPB2C.common.helpAndContact.actionSection.launchChatLabel": APPB2C_common_helpAndContact_actionSection_launchChatLabel5,
  "APPB2C.common.helpAndContact.form.back": APPB2C_common_helpAndContact_form_back5,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLink": APPB2C_common_helpAndContact_form_complaint_moreInfoLink5,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLinkTitle": APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle5,
  "APPB2C.common.helpAndContact.form.complaint.radio.complaint": APPB2C_common_helpAndContact_form_complaint_radio_complaint5,
  "APPB2C.common.helpAndContact.form.complaint.radio.other": APPB2C_common_helpAndContact_form_complaint_radio_other5,
  "APPB2C.common.helpAndContact.form.complaint.title": APPB2C_common_helpAndContact_form_complaint_title5,
  "APPB2C.common.helpAndContact.form.label": APPB2C_common_helpAndContact_form_label5,
  "APPB2C.common.helpAndContact.form.legal": APPB2C_common_helpAndContact_form_legal5,
  "APPB2C.common.helpAndContact.form.response": APPB2C_common_helpAndContact_form_response5,
  "APPB2C.common.helpAndContact.form.submit": APPB2C_common_helpAndContact_form_submit5,
  "APPB2C.common.helpAndContact.form.thanks.back": APPB2C_common_helpAndContact_form_thanks_back5,
  "APPB2C.common.helpAndContact.form.thanks.banner": APPB2C_common_helpAndContact_form_thanks_banner5,
  "APPB2C.common.helpAndContact.form.thanks.info": APPB2C_common_helpAndContact_form_thanks_info5,
  "APPB2C.common.helpAndContact.form.thanks.title": APPB2C_common_helpAndContact_form_thanks_title5,
  "APPB2C.common.helpAndContact.form.title": APPB2C_common_helpAndContact_form_title5,
  "APPB2C.common.helpAndContact.form.validation": APPB2C_common_helpAndContact_form_validation5,
  "APPB2C.common.helpAndContact.icContent.commonQuestionsHeader": APPB2C_common_helpAndContact_icContent_commonQuestionsHeader5,
  "APPB2C.common.helpAndContact.icContent.emailLabel": APPB2C_common_helpAndContact_icContent_emailLabel5,
  "APPB2C.common.helpAndContact.icContent.header": APPB2C_common_helpAndContact_icContent_header5,
  "APPB2C.common.helpAndContact.icContent.phoneLabel": APPB2C_common_helpAndContact_icContent_phoneLabel5,
  "APPB2C.common.helpAndContact.icContent.referenceReminder": APPB2C_common_helpAndContact_icContent_referenceReminder5,
  "APPB2C.common.helpAndContact.ieltsReference": APPB2C_common_helpAndContact_ieltsReference5,
  "APPB2C.common.helpAndContact.noTests.bookNewBtn": APPB2C_common_helpAndContact_noTests_bookNewBtn5,
  "APPB2C.common.helpAndContact.noTests.contactLink": APPB2C_common_helpAndContact_noTests_contactLink5,
  "APPB2C.common.helpAndContact.noTests.header": APPB2C_common_helpAndContact_noTests_header5,
  "APPB2C.common.helpAndContact.selectExamLabel": APPB2C_common_helpAndContact_selectExamLabel5,
  "APPB2C.common.helpAndContact.title": APPB2C_common_helpAndContact_title5,
  "APPB2C.common.invitation.modal.header": APPB2C_common_invitation_modal_header5,
  "APPB2C.common.invitation.modal.invalid": APPB2C_common_invitation_modal_invalid5,
  "APPB2C.common.modals.newTermsAndConditions.checkboxNote": APPB2C_common_modals_newTermsAndConditions_checkboxNote5,
  "APPB2C.common.modals.newTermsAndConditions.checkboxTC": APPB2C_common_modals_newTermsAndConditions_checkboxTC5,
  "APPB2C.common.modals.newTermsAndConditions.cta": APPB2C_common_modals_newTermsAndConditions_cta5,
  "APPB2C.common.modals.newTermsAndConditions.error": APPB2C_common_modals_newTermsAndConditions_error5,
  "APPB2C.common.modals.newTermsAndConditions.note": APPB2C_common_modals_newTermsAndConditions_note5,
  "APPB2C.common.modals.newTermsAndConditions.title": APPB2C_common_modals_newTermsAndConditions_title5,
  "APPB2C.common.modals.newTermsAndConditions.trigger": APPB2C_common_modals_newTermsAndConditions_trigger5,
  "APPB2C.common.nav.bookNewTest": APPB2C_common_nav_bookNewTest5,
  "APPB2C.common.nav.chooseLanguage": APPB2C_common_nav_chooseLanguage5,
  "APPB2C.common.nav.hello": APPB2C_common_nav_hello5,
  "APPB2C.common.nav.helpAndContact": APPB2C_common_nav_helpAndContact5,
  "APPB2C.common.nav.login": APPB2C_common_nav_login5,
  "APPB2C.common.nav.logout": APPB2C_common_nav_logout5,
  "APPB2C.common.nav.logoutModal.cancel": APPB2C_common_nav_logoutModal_cancel4,
  "APPB2C.common.nav.logoutModal.confirm": APPB2C_common_nav_logoutModal_confirm5,
  "APPB2C.common.nav.logoutModal.message": APPB2C_common_nav_logoutModal_message5,
  "APPB2C.common.nav.logoutModal.question": APPB2C_common_nav_logoutModal_question5,
  "APPB2C.common.nav.logoutModal.title": APPB2C_common_nav_logoutModal_title5,
  "APPB2C.common.nav.myAccount": APPB2C_common_nav_myAccount5,
  "APPB2C.common.nav.preparation": APPB2C_common_nav_preparation5,
  "APPB2C.common.nav.resultsTests": APPB2C_common_nav_resultsTests5,
  "APPB2C.common.nav.ttp": APPB2C_common_nav_ttp5,
  "APPB2C.common.preparation.noTests.contactLink": APPB2C_common_preparation_noTests_contactLink5,
  "APPB2C.common.preparation.noTests.header": APPB2C_common_preparation_noTests_header5,
  "APPB2C.common.preparation.selectExamLabel": APPB2C_common_preparation_selectExamLabel5,
  "APPB2C.common.preparation.title": APPB2C_common_preparation_title5,
  "APPB2C.common.preparation.viewTestBtn": APPB2C_common_preparation_viewTestBtn5,
  "APPB2C.common.recreateReg.errMsg": APPB2C_common_recreateReg_errMsg5,
  "APPB2C.common.recreateReg.errTitle": APPB2C_common_recreateReg_errTitle5,
  "APPB2C.common.recreateReg.progress": APPB2C_common_recreateReg_progress5,
  "APPB2C.common.regHeader.reference": APPB2C_common_regHeader_reference5,
  "APPB2C.common.regHeader.trfLoading": APPB2C_common_regHeader_trfLoading5,
  "APPB2C.common.regHeader.trfNumber": APPB2C_common_regHeader_trfNumber5,
  "APPB2C.common.regInProgressModal.continue": APPB2C_common_regInProgressModal_continue5,
  "APPB2C.common.regInProgressModal.message1": APPB2C_common_regInProgressModal_message15,
  "APPB2C.common.regInProgressModal.message2": APPB2C_common_regInProgressModal_message25,
  "APPB2C.common.regInProgressModal.restart": APPB2C_common_regInProgressModal_restart5,
  "APPB2C.common.regInProgressModal.title": APPB2C_common_regInProgressModal_title5,
  "APPB2C.common.selfServices.requested.cancellation": APPB2C_common_selfServices_requested_cancellation5,
  "APPB2C.common.selfServices.requested.transfer": APPB2C_common_selfServices_requested_transfer5,
  "APPB2C.common.specialReqs.AMANU": APPB2C_common_specialReqs_AMANU5,
  "APPB2C.common.specialReqs.ANTHR": APPB2C_common_specialReqs_ANTHR5,
  "APPB2C.common.specialReqs.BTP": APPB2C_common_specialReqs_BTP5,
  "APPB2C.common.specialReqs.BWP": APPB2C_common_specialReqs_BWP5,
  "APPB2C.common.specialReqs.EPTP": APPB2C_common_specialReqs_EPTP5,
  "APPB2C.common.specialReqs.ET": APPB2C_common_specialReqs_ET5,
  "APPB2C.common.specialReqs.LRTV": APPB2C_common_specialReqs_LRTV5,
  "APPB2C.common.specialReqs.SAE": APPB2C_common_specialReqs_SAE5,
  "APPB2C.common.specialReqs.VAS": APPB2C_common_specialReqs_VAS5,
  "APPB2C.common.specialReqs.WP": APPB2C_common_specialReqs_WP5,
  "APPB2C.common.startPage.book": APPB2C_common_startPage_book5,
  "APPB2C.common.startPage.candidates.nowAdult": APPB2C_common_startPage_candidates_nowAdult5,
  "APPB2C.common.startPage.candidates.showingFor": APPB2C_common_startPage_candidates_showingFor5,
  "APPB2C.common.startPage.common.cancelled": APPB2C_common_startPage_common_cancelled5,
  "APPB2C.common.startPage.description.ac": APPB2C_common_startPage_description_ac5,
  "APPB2C.common.startPage.description.gt": APPB2C_common_startPage_description_gt5,
  "APPB2C.common.startPage.description.ukvi": APPB2C_common_startPage_description_ukvi5,
  "APPB2C.common.startPage.endNote": APPB2C_common_startPage_endNote5,
  "APPB2C.common.startPage.error.body": APPB2C_common_startPage_error_body5,
  "APPB2C.common.startPage.error.cta": APPB2C_common_startPage_error_cta5,
  "APPB2C.common.startPage.error.title": APPB2C_common_startPage_error_title5,
  "APPB2C.common.startPage.hero.cta": APPB2C_common_startPage_hero_cta5,
  "APPB2C.common.startPage.hero.subtitle": APPB2C_common_startPage_hero_subtitle5,
  "APPB2C.common.startPage.hero.title": APPB2C_common_startPage_hero_title5,
  "APPB2C.common.startPage.ielts": APPB2C_common_startPage_ielts5,
  "APPB2C.common.startPage.ieltsUkvi": APPB2C_common_startPage_ieltsUkvi5,
  "APPB2C.common.startPage.missing.cancel": APPB2C_common_startPage_missing_cancel5,
  "APPB2C.common.startPage.missing.marketing.cta": APPB2C_common_startPage_missing_marketing_cta5,
  "APPB2C.common.startPage.missing.marketing.notes.full1": APPB2C_common_startPage_missing_marketing_notes_full15,
  "APPB2C.common.startPage.missing.marketing.notes.full2": APPB2C_common_startPage_missing_marketing_notes_full25,
  "APPB2C.common.startPage.missing.marketing.notes.full3": APPB2C_common_startPage_missing_marketing_notes_full35,
  "APPB2C.common.startPage.missing.marketing.notes.link": APPB2C_common_startPage_missing_marketing_notes_link5,
  "APPB2C.common.startPage.missing.marketing.notes.main": APPB2C_common_startPage_missing_marketing_notes_main5,
  "APPB2C.common.startPage.missing.marketing.opts.BcAndThirdParties": APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties5,
  "APPB2C.common.startPage.missing.marketing.opts.BcOnly": APPB2C_common_startPage_missing_marketing_opts_BcOnly5,
  "APPB2C.common.startPage.missing.marketing.opts.DoNotSend": APPB2C_common_startPage_missing_marketing_opts_DoNotSend5,
  "APPB2C.common.startPage.missing.marketing.title": APPB2C_common_startPage_missing_marketing_title5,
  "APPB2C.common.startPage.missing.ttData.cta": APPB2C_common_startPage_missing_ttData_cta5,
  "APPB2C.common.startPage.missing.ttData.dob": APPB2C_common_startPage_missing_ttData_dob5,
  "APPB2C.common.startPage.missing.ttData.firstName": APPB2C_common_startPage_missing_ttData_firstName5,
  "APPB2C.common.startPage.missing.ttData.lastName": APPB2C_common_startPage_missing_ttData_lastName5,
  "APPB2C.common.startPage.missing.ttData.line1": APPB2C_common_startPage_missing_ttData_line15,
  "APPB2C.common.startPage.missing.ttData.line2": APPB2C_common_startPage_missing_ttData_line25,
  "APPB2C.common.startPage.missing.ttData.line3": APPB2C_common_startPage_missing_ttData_line35,
  "APPB2C.common.startPage.missing.ttData.noLastName": APPB2C_common_startPage_missing_ttData_noLastName5,
  "APPB2C.common.startPage.missing.ttData.title": APPB2C_common_startPage_missing_ttData_title5,
  "APPB2C.common.startPage.options.ac": APPB2C_common_startPage_options_ac5,
  "APPB2C.common.startPage.options.gt": APPB2C_common_startPage_options_gt5,
  "APPB2C.common.startPage.options.ukvi": APPB2C_common_startPage_options_ukvi5,
  "APPB2C.common.startPage.past.cd": APPB2C_common_startPage_past_cd5,
  "APPB2C.common.startPage.past.empty": APPB2C_common_startPage_past_empty5,
  "APPB2C.common.startPage.past.osr.newScore": APPB2C_common_startPage_past_osr_newScore5,
  "APPB2C.common.startPage.past.osr.note.awaiting": APPB2C_common_startPage_past_osr_note_awaiting5,
  "APPB2C.common.startPage.past.osr.note.confirmed": APPB2C_common_startPage_past_osr_note_confirmed5,
  "APPB2C.common.startPage.past.osr.note.hasResults": APPB2C_common_startPage_past_osr_note_hasResults5,
  "APPB2C.common.startPage.past.osr.originalScore": APPB2C_common_startPage_past_osr_originalScore5,
  "APPB2C.common.startPage.past.osr.viewRetakeLink": APPB2C_common_startPage_past_osr_viewRetakeLink5,
  "APPB2C.common.startPage.past.pb": APPB2C_common_startPage_past_pb5,
  "APPB2C.common.startPage.past.rebook": APPB2C_common_startPage_past_rebook5,
  "APPB2C.common.startPage.past.score": APPB2C_common_startPage_past_score5,
  "APPB2C.common.startPage.past.scoreLifeSkills": APPB2C_common_startPage_past_scoreLifeSkills5,
  "APPB2C.common.startPage.past.title": APPB2C_common_startPage_past_title5,
  "APPB2C.common.startPage.past.upcomingResults": APPB2C_common_startPage_past_upcomingResults5,
  "APPB2C.common.startPage.readTnCs": APPB2C_common_startPage_readTnCs5,
  "APPB2C.common.startPage.recent.cta": APPB2C_common_startPage_recent_cta5,
  "APPB2C.common.startPage.recent.title": APPB2C_common_startPage_recent_title5,
  "APPB2C.common.startPage.subtitle": APPB2C_common_startPage_subtitle5,
  "APPB2C.common.startPage.title": APPB2C_common_startPage_title5,
  "APPB2C.common.startPage.tncs": APPB2C_common_startPage_tncs5,
  "APPB2C.common.startPage.upcoming.cd": APPB2C_common_startPage_upcoming_cd5,
  "APPB2C.common.startPage.upcoming.empty": APPB2C_common_startPage_upcoming_empty5,
  "APPB2C.common.startPage.upcoming.lifeSkills": APPB2C_common_startPage_upcoming_lifeSkills5,
  "APPB2C.common.startPage.upcoming.live": APPB2C_common_startPage_upcoming_live5,
  "APPB2C.common.startPage.upcoming.manage": APPB2C_common_startPage_upcoming_manage5,
  "APPB2C.common.startPage.upcoming.missing.childConsent": APPB2C_common_startPage_upcoming_missing_childConsent5,
  "APPB2C.common.startPage.upcoming.missing.id": APPB2C_common_startPage_upcoming_missing_id5,
  "APPB2C.common.startPage.upcoming.missing.medical": APPB2C_common_startPage_upcoming_missing_medical5,
  "APPB2C.common.startPage.upcoming.missing.payment": APPB2C_common_startPage_upcoming_missing_payment5,
  "APPB2C.common.startPage.upcoming.noSpeaking": APPB2C_common_startPage_upcoming_noSpeaking5,
  "APPB2C.common.startPage.upcoming.noTime": APPB2C_common_startPage_upcoming_noTime5,
  "APPB2C.common.startPage.upcoming.osr.note": APPB2C_common_startPage_upcoming_osr_note5,
  "APPB2C.common.startPage.upcoming.pastPaymentDeadline": APPB2C_common_startPage_upcoming_pastPaymentDeadline5,
  "APPB2C.common.startPage.upcoming.pay": APPB2C_common_startPage_upcoming_pay5,
  "APPB2C.common.startPage.upcoming.pb": APPB2C_common_startPage_upcoming_pb5,
  "APPB2C.common.startPage.upcoming.prepare": APPB2C_common_startPage_upcoming_prepare5,
  "APPB2C.common.startPage.upcoming.remote": APPB2C_common_startPage_upcoming_remote5,
  "APPB2C.common.startPage.upcoming.title": APPB2C_common_startPage_upcoming_title5,
  "APPB2C.common.startPage.whichIELTS": APPB2C_common_startPage_whichIELTS5,
  "APPB2C.common.terms.downloadInfo": APPB2C_common_terms_downloadInfo5,
  "APPB2C.common.terms.downloadTitle": APPB2C_common_terms_downloadTitle5,
  "APPB2C.common.terms.error": APPB2C_common_terms_error5,
  "APPB2C.common.terms.title": APPB2C_common_terms_title5,
  "APPB2C.common.testDetails.changeTest": APPB2C_common_testDetails_changeTest5,
  "APPB2C.common.testDetails.changeWrittenTest": APPB2C_common_testDetails_changeWrittenTest5,
  "APPB2C.common.testDetails.completeYourBooking": APPB2C_common_testDetails_completeYourBooking5,
  "APPB2C.common.testDetails.mapModal.close": APPB2C_common_testDetails_mapModal_close5,
  "APPB2C.common.testDetails.mapModal.title": APPB2C_common_testDetails_mapModal_title5,
  "APPB2C.common.testDetails.modifyNote.confirm": APPB2C_common_testDetails_modifyNote_confirm5,
  "APPB2C.common.testDetails.modifyNote.note": APPB2C_common_testDetails_modifyNote_note5,
  "APPB2C.common.testDetails.modifyNote.pleaseNote": APPB2C_common_testDetails_modifyNote_pleaseNote5,
  "APPB2C.common.testDetails.title": APPB2C_common_testDetails_title5,
  "APPB2C.common.testDetails.yourSpeakingTest": APPB2C_common_testDetails_yourSpeakingTest5,
  "APPB2C.common.testDetails.yourWrittenTest": APPB2C_common_testDetails_yourWrittenTest5,
  "APPB2C.common.transfer.agreement": APPB2C_common_transfer_agreement5,
  "APPB2C.common.transfer.contactNote": APPB2C_common_transfer_contactNote5,
  "APPB2C.common.transfer.contactNoteIol": APPB2C_common_transfer_contactNoteIol5,
  "APPB2C.common.transfer.cta": APPB2C_common_transfer_cta5,
  "APPB2C.common.transfer.form.freeform": APPB2C_common_transfer_form_freeform5,
  "APPB2C.common.transfer.form.reasons.1": APPB2C_common_transfer_form_reasons_15,
  "APPB2C.common.transfer.form.reasons.2": APPB2C_common_transfer_form_reasons_25,
  "APPB2C.common.transfer.form.reasons.3": APPB2C_common_transfer_form_reasons_35,
  "APPB2C.common.transfer.form.reasons.4": APPB2C_common_transfer_form_reasons_45,
  "APPB2C.common.transfer.form.reasons.choose": APPB2C_common_transfer_form_reasons_choose5,
  "APPB2C.common.transfer.form.reasons.other": APPB2C_common_transfer_form_reasons_other5,
  "APPB2C.common.transfer.form.reasons.title": APPB2C_common_transfer_form_reasons_title5,
  "APPB2C.common.transfer.goBack": APPB2C_common_transfer_goBack5,
  "APPB2C.common.transfer.notes.1": APPB2C_common_transfer_notes_15,
  "APPB2C.common.transfer.notes.2": APPB2C_common_transfer_notes_25,
  "APPB2C.common.transfer.notes.3": APPB2C_common_transfer_notes_35,
  "APPB2C.common.transfer.notes.4": APPB2C_common_transfer_notes_45,
  "APPB2C.common.transfer.notesIol.1": APPB2C_common_transfer_notesIol_15,
  "APPB2C.common.transfer.notesIol.2": APPB2C_common_transfer_notesIol_25,
  "APPB2C.common.transfer.requestValidation": APPB2C_common_transfer_requestValidation5,
  "APPB2C.common.transfer.selfService.availableDates.title": APPB2C_common_transfer_selfService_availableDates_title5,
  "APPB2C.common.transfer.selfService.chooseDates.title": APPB2C_common_transfer_selfService_chooseDates_title5,
  "APPB2C.common.transfer.selfService.review.confirm.proceedBtn": APPB2C_common_transfer_selfService_review_confirm_proceedBtn5,
  "APPB2C.common.transfer.selfService.review.confirm.title": APPB2C_common_transfer_selfService_review_confirm_title5,
  "APPB2C.common.transfer.selfService.review.errors.noDistricts": APPB2C_common_transfer_selfService_review_errors_noDistricts5,
  "APPB2C.common.transfer.selfService.review.errors.noLrwSlots": APPB2C_common_transfer_selfService_review_errors_noLrwSlots5,
  "APPB2C.common.transfer.selfService.review.errors.transferDisabled": APPB2C_common_transfer_selfService_review_errors_transferDisabled5,
  "APPB2C.common.transfer.selfService.review.test.changeLrw": APPB2C_common_transfer_selfService_review_test_changeLrw5,
  "APPB2C.common.transfer.selfService.review.tncs.agreement": APPB2C_common_transfer_selfService_review_tncs_agreement5,
  "APPB2C.common.transfer.title": APPB2C_common_transfer_title5,
  "APPB2C.common.underConstruction.message": APPB2C_common_underConstruction_message5,
  "APPB2C.common.underConstruction.title": APPB2C_common_underConstruction_title5,
  "APPB2C.common.unknownCountry.cta": APPB2C_common_unknownCountry_cta5,
  "APPB2C.common.unknownCountry.header": APPB2C_common_unknownCountry_header5,
  "APPB2C.common.unknownCountry.msg1": APPB2C_common_unknownCountry_msg15,
  "APPB2C.common.unknownCountry.msg2": APPB2C_common_unknownCountry_msg25,
  "APPB2C.common.unknownCountry.title": APPB2C_common_unknownCountry_title5,
  "APPB2C.common.unpaidErrorBar.paymentExpired": APPB2C_common_unpaidErrorBar_paymentExpired5,
  "APPB2C.common.updateBar.cancelled": APPB2C_common_updateBar_cancelled5,
  "APPB2C.common.updateBar.cancelPending": APPB2C_common_updateBar_cancelPending5,
  "APPB2C.common.updateBar.cancelRejected": APPB2C_common_updateBar_cancelRejected5,
  "APPB2C.common.updateBar.close": APPB2C_common_updateBar_close5,
  "APPB2C.common.updateBar.details": APPB2C_common_updateBar_details5,
  "APPB2C.common.updateBar.eorCompleted": APPB2C_common_updateBar_eorCompleted5,
  "APPB2C.common.updateBar.eorCompleted.scoreDecreased": APPB2C_common_updateBar_eorCompleted_scoreDecreased5,
  "APPB2C.common.updateBar.eorCompleted.scoreIncreased": APPB2C_common_updateBar_eorCompleted_scoreIncreased5,
  "APPB2C.common.updateBar.eorCompleted.scoreUnchanged": APPB2C_common_updateBar_eorCompleted_scoreUnchanged5,
  "APPB2C.common.updateBar.eorPending": APPB2C_common_updateBar_eorPending5,
  "APPB2C.common.updateBar.eorPendingAgentPays": APPB2C_common_updateBar_eorPendingAgentPays5,
  "APPB2C.common.updateBar.eorRefunded": APPB2C_common_updateBar_eorRefunded5,
  "APPB2C.common.updateBar.eorRefundPending": APPB2C_common_updateBar_eorRefundPending5,
  "APPB2C.common.updateBar.eorRejected": APPB2C_common_updateBar_eorRejected5,
  "APPB2C.common.updateBar.eorRejected.details": APPB2C_common_updateBar_eorRejected_details4,
  "APPB2C.common.updateBar.eorRejected.overallBand": APPB2C_common_updateBar_eorRejected_overallBand5,
  "APPB2C.common.updateBar.hasResults": APPB2C_common_updateBar_hasResults5,
  "APPB2C.common.updateBar.termsUpdated": APPB2C_common_updateBar_termsUpdated5,
  "APPB2C.common.updateBar.transactionRefundRequestAccepted": APPB2C_common_updateBar_transactionRefundRequestAccepted5,
  "APPB2C.common.updateBar.transactionRefundRequestPending": APPB2C_common_updateBar_transactionRefundRequestPending5,
  "APPB2C.common.updateBar.transactionRefundRequestRejected": APPB2C_common_updateBar_transactionRefundRequestRejected5,
  "APPB2C.common.updateBar.transferCancelled": APPB2C_common_updateBar_transferCancelled5,
  "APPB2C.common.updateBar.transferPending": APPB2C_common_updateBar_transferPending5,
  "APPB2C.common.updateBar.transferPendingCmds": APPB2C_common_updateBar_transferPendingCmds5,
  "APPB2C.common.updateBar.transferred": APPB2C_common_updateBar_transferred5,
  "APPB2C.common.updateBar.transferredCmds": APPB2C_common_updateBar_transferredCmds5,
  "APPB2C.registration.bookingDetails.payment": APPB2C_registration_bookingDetails_payment5,
  "APPB2C.registration.bookingDetails.results": APPB2C_registration_bookingDetails_results5,
  "APPB2C.registration.bookingDetails.seePayment": APPB2C_registration_bookingDetails_seePayment5,
  "APPB2C.registration.bookingDetails.seeTestTaker": APPB2C_registration_bookingDetails_seeTestTaker5,
  "APPB2C.registration.bookingDetails.whatNext": APPB2C_registration_bookingDetails_whatNext5,
  "APPB2C.registration.date.check.back": APPB2C_registration_date_check_back5,
  "APPB2C.registration.date.check.beforeTestDate": APPB2C_registration_date_check_beforeTestDate5,
  "APPB2C.registration.date.check.intro": APPB2C_registration_date_check_intro5,
  "APPB2C.registration.date.check.onTestDate": APPB2C_registration_date_check_onTestDate5,
  "APPB2C.registration.date.check.step1.body": APPB2C_registration_date_check_step1_body5,
  "APPB2C.registration.date.check.step1.link": APPB2C_registration_date_check_step1_link5,
  "APPB2C.registration.date.check.step1.title": APPB2C_registration_date_check_step1_title5,
  "APPB2C.registration.date.check.step2.body": APPB2C_registration_date_check_step2_body5,
  "APPB2C.registration.date.check.step2.link": APPB2C_registration_date_check_step2_link5,
  "APPB2C.registration.date.check.step2.title": APPB2C_registration_date_check_step2_title5,
  "APPB2C.registration.date.check.step3.body1": APPB2C_registration_date_check_step3_body15,
  "APPB2C.registration.date.check.step3.body2": APPB2C_registration_date_check_step3_body25,
  "APPB2C.registration.date.check.step3.noDetailsBody": APPB2C_registration_date_check_step3_noDetailsBody5,
  "APPB2C.registration.date.check.step3.noDetailsTitle": APPB2C_registration_date_check_step3_noDetailsTitle5,
  "APPB2C.registration.date.check.step3.noSpeakingPinBody": APPB2C_registration_date_check_step3_noSpeakingPinBody5,
  "APPB2C.registration.date.check.step3.noSpeakingPinTitle": APPB2C_registration_date_check_step3_noSpeakingPinTitle5,
  "APPB2C.registration.date.check.step3.password": APPB2C_registration_date_check_step3_password5,
  "APPB2C.registration.date.check.step3.speakingPin": APPB2C_registration_date_check_step3_speakingPin5,
  "APPB2C.registration.date.check.step3.step1.body": APPB2C_registration_date_check_step3_step1_body5,
  "APPB2C.registration.date.check.step3.step1.title": APPB2C_registration_date_check_step3_step1_title5,
  "APPB2C.registration.date.check.step3.step2.body": APPB2C_registration_date_check_step3_step2_body5,
  "APPB2C.registration.date.check.step3.step2.title": APPB2C_registration_date_check_step3_step2_title5,
  "APPB2C.registration.date.check.step3.step3.body": APPB2C_registration_date_check_step3_step3_body5,
  "APPB2C.registration.date.check.step3.step3.title": APPB2C_registration_date_check_step3_step3_title5,
  "APPB2C.registration.date.check.step3.username": APPB2C_registration_date_check_step3_username5,
  "APPB2C.registration.date.check.step3.writtenPin": APPB2C_registration_date_check_step3_writtenPin5,
  "APPB2C.registration.date.zone.country": APPB2C_registration_date_zone_country5,
  "APPB2C.registration.date.zone.header": APPB2C_registration_date_zone_header5,
  "APPB2C.registration.date.zone.name": APPB2C_registration_date_zone_name5,
  "APPB2C.registration.date.zone.time": APPB2C_registration_date_zone_time5,
  "APPB2C.registration.dateLocation.changeLinkIol": APPB2C_registration_dateLocation_changeLinkIol5,
  "APPB2C.registration.dateLocation.changeLrwLink": APPB2C_registration_dateLocation_changeLrwLink5,
  "APPB2C.registration.dateLocation.changeSpeakingLink": APPB2C_registration_dateLocation_changeSpeakingLink5,
  "APPB2C.registration.dateLocation.location": APPB2C_registration_dateLocation_location5,
  "APPB2C.registration.dateLocation.manage.cancel": APPB2C_registration_dateLocation_manage_cancel5,
  "APPB2C.registration.dateLocation.manage.proceed": APPB2C_registration_dateLocation_manage_proceed4,
  "APPB2C.registration.dateLocation.manage.title": APPB2C_registration_dateLocation_manage_title5,
  "APPB2C.registration.dateLocation.manage.transferSpeaking": APPB2C_registration_dateLocation_manage_transferSpeaking5,
  "APPB2C.registration.dateLocation.manage.transferWhole": APPB2C_registration_dateLocation_manage_transferWhole5,
  "APPB2C.registration.dateLocation.speakingTransfer.keepSpeakingTest": APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest5,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody1": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody15,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody2": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody25,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsHeader": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader5,
  "APPB2C.registration.dateLocation.speakingTransfer.requestTransfer": APPB2C_registration_dateLocation_speakingTransfer_requestTransfer5,
  "APPB2C.registration.incorrectOrganisation.homeLink": APPB2C_registration_incorrectOrganisation_homeLink5,
  "APPB2C.registration.incorrectOrganisation.line1": APPB2C_registration_incorrectOrganisation_line15,
  "APPB2C.registration.incorrectOrganisation.line2": APPB2C_registration_incorrectOrganisation_line25,
  "APPB2C.registration.payments.actions.editProofCTA": APPB2C_registration_payments_actions_editProofCTA5,
  "APPB2C.registration.payments.actions.header": APPB2C_registration_payments_actions_header5,
  "APPB2C.registration.payments.actions.paymentInProgressInfo": APPB2C_registration_payments_actions_paymentInProgressInfo5,
  "APPB2C.registration.payments.actions.payOfflineCTA": APPB2C_registration_payments_actions_payOfflineCTA5,
  "APPB2C.registration.payments.actions.payOnlineCTA": APPB2C_registration_payments_actions_payOnlineCTA5,
  "APPB2C.registration.payments.actions.subHeader": APPB2C_registration_payments_actions_subHeader5,
  "APPB2C.registration.payments.actions.uploadProofCTA": APPB2C_registration_payments_actions_uploadProofCTA5,
  "APPB2C.registration.payments.awaitingPayment": APPB2C_registration_payments_awaitingPayment5,
  "APPB2C.registration.payments.back": APPB2C_registration_payments_back5,
  "APPB2C.registration.payments.completed": APPB2C_registration_payments_completed5,
  "APPB2C.registration.payments.expired": APPB2C_registration_payments_expired5,
  "APPB2C.registration.payments.finishPayment.error.cta": APPB2C_registration_payments_finishPayment_error_cta5,
  "APPB2C.registration.payments.finishPayment.error.messageLine1": APPB2C_registration_payments_finishPayment_error_messageLine15,
  "APPB2C.registration.payments.finishPayment.error.messageLine2": APPB2C_registration_payments_finishPayment_error_messageLine25,
  "APPB2C.registration.payments.finishPayment.error.title": APPB2C_registration_payments_finishPayment_error_title5,
  "APPB2C.registration.payments.finishPayment.failure.alert": APPB2C_registration_payments_finishPayment_failure_alert5,
  "APPB2C.registration.payments.finishPayment.failure.cta": APPB2C_registration_payments_finishPayment_failure_cta5,
  "APPB2C.registration.payments.finishPayment.failure.message": APPB2C_registration_payments_finishPayment_failure_message5,
  "APPB2C.registration.payments.finishPayment.failure.title": APPB2C_registration_payments_finishPayment_failure_title5,
  "APPB2C.registration.payments.finishPayment.pleaseWait": APPB2C_registration_payments_finishPayment_pleaseWait5,
  "APPB2C.registration.payments.finishPayment.processing": APPB2C_registration_payments_finishPayment_processing5,
  "APPB2C.registration.payments.finishPayment.success.alert": APPB2C_registration_payments_finishPayment_success_alert5,
  "APPB2C.registration.payments.finishPayment.success.cta": APPB2C_registration_payments_finishPayment_success_cta5,
  "APPB2C.registration.payments.finishPayment.success.message": APPB2C_registration_payments_finishPayment_success_message5,
  "APPB2C.registration.payments.finishPayment.success.title": APPB2C_registration_payments_finishPayment_success_title5,
  "APPB2C.registration.payments.offlineDetails.accountName": APPB2C_registration_payments_offlineDetails_accountName5,
  "APPB2C.registration.payments.offlineDetails.accountNumber": APPB2C_registration_payments_offlineDetails_accountNumber5,
  "APPB2C.registration.payments.offlineDetails.payBy": APPB2C_registration_payments_offlineDetails_payBy5,
  "APPB2C.registration.payments.offlineDetails.sortCode": APPB2C_registration_payments_offlineDetails_sortCode5,
  "APPB2C.registration.payments.paidByAgent": APPB2C_registration_payments_paidByAgent5,
  "APPB2C.registration.payments.proofPreview.fileAlt": APPB2C_registration_payments_proofPreview_fileAlt5,
  "APPB2C.registration.payments.proofPreview.proofLabel": APPB2C_registration_payments_proofPreview_proofLabel5,
  "APPB2C.registration.payments.proofUpload.cta": APPB2C_registration_payments_proofUpload_cta5,
  "APPB2C.registration.payments.proofUpload.info": APPB2C_registration_payments_proofUpload_info5,
  "APPB2C.registration.payments.proofUpload.intro": APPB2C_registration_payments_proofUpload_intro5,
  "APPB2C.registration.payments.proofUpload.rules.intro": APPB2C_registration_payments_proofUpload_rules_intro5,
  "APPB2C.registration.payments.proofUpload.rules.rule1": APPB2C_registration_payments_proofUpload_rules_rule15,
  "APPB2C.registration.payments.proofUpload.rules.rule2": APPB2C_registration_payments_proofUpload_rules_rule25,
  "APPB2C.registration.payments.proofUpload.rules.rule3": APPB2C_registration_payments_proofUpload_rules_rule35,
  "APPB2C.registration.payments.proofUpload.rules.rule4": APPB2C_registration_payments_proofUpload_rules_rule45,
  "APPB2C.registration.payments.proofUpload.rules.rule5": APPB2C_registration_payments_proofUpload_rules_rule55,
  "APPB2C.registration.payments.transaction.cancelledSuffix": APPB2C_registration_payments_transaction_cancelledSuffix5,
  "APPB2C.registration.payments.transaction.deadlineWarning.default": APPB2C_registration_payments_transaction_deadlineWarning_default5,
  "APPB2C.registration.payments.transaction.deadlineWarning.eor": APPB2C_registration_payments_transaction_deadlineWarning_eor5,
  "APPB2C.registration.payments.transaction.deadlineWarning.registration": APPB2C_registration_payments_transaction_deadlineWarning_registration5,
  "APPB2C.registration.payments.transaction.description.appeal": APPB2C_registration_payments_transaction_description_appeal5,
  "APPB2C.registration.payments.transaction.description.eor": APPB2C_registration_payments_transaction_description_eor5,
  "APPB2C.registration.payments.transaction.description.registration": APPB2C_registration_payments_transaction_description_registration5,
  "APPB2C.registration.payments.transaction.description.transfer": APPB2C_registration_payments_transaction_description_transfer5,
  "APPB2C.registration.payments.transaction.feeLabel": APPB2C_registration_payments_transaction_feeLabel5,
  "APPB2C.registration.payments.transaction.header": APPB2C_registration_payments_transaction_header5,
  "APPB2C.registration.payments.transaction.hideDetails": APPB2C_registration_payments_transaction_hideDetails5,
  "APPB2C.registration.payments.transaction.outdatedTermsLabel": APPB2C_registration_payments_transaction_outdatedTermsLabel5,
  "APPB2C.registration.payments.transaction.pastDeadline": APPB2C_registration_payments_transaction_pastDeadline5,
  "APPB2C.registration.payments.transaction.pastDeadlineContactUs": APPB2C_registration_payments_transaction_pastDeadlineContactUs5,
  "APPB2C.registration.payments.transaction.pastDeadlineEmail": APPB2C_registration_payments_transaction_pastDeadlineEmail5,
  "APPB2C.registration.payments.transaction.pastDeadlinePhone": APPB2C_registration_payments_transaction_pastDeadlinePhone5,
  "APPB2C.registration.payments.transaction.promoCode": APPB2C_registration_payments_transaction_promoCode5,
  "APPB2C.registration.payments.transaction.receipt": APPB2C_registration_payments_transaction_receipt5,
  "APPB2C.registration.payments.transaction.receiptCta": APPB2C_registration_payments_transaction_receiptCta5,
  "APPB2C.registration.payments.transaction.referenceLabel": APPB2C_registration_payments_transaction_referenceLabel5,
  "APPB2C.registration.payments.transaction.showDetails": APPB2C_registration_payments_transaction_showDetails5,
  "APPB2C.registration.payments.transaction.taxLabel": APPB2C_registration_payments_transaction_taxLabel5,
  "APPB2C.registration.payments.transaction.termsLabel": APPB2C_registration_payments_transaction_termsLabel5,
  "APPB2C.registration.payments.transaction.totalLabel": APPB2C_registration_payments_transaction_totalLabel5,
  "APPB2C.registration.payments.transaction.underReviewSuffix": APPB2C_registration_payments_transaction_underReviewSuffix5,
  "APPB2C.registration.payments.transaction.unpaidSuffix": APPB2C_registration_payments_transaction_unpaidSuffix5,
  "APPB2C.registration.payments.unpaid": APPB2C_registration_payments_unpaid5,
  "APPB2C.registration.prepare.a1.cta": APPB2C_registration_prepare_a1_cta5,
  "APPB2C.registration.prepare.a1.header": APPB2C_registration_prepare_a1_header5,
  "APPB2C.registration.prepare.a2.cta": APPB2C_registration_prepare_a2_cta5,
  "APPB2C.registration.prepare.a2.header": APPB2C_registration_prepare_a2_header5,
  "APPB2C.registration.prepare.b1.cta": APPB2C_registration_prepare_b1_cta5,
  "APPB2C.registration.prepare.b1.header": APPB2C_registration_prepare_b1_header5,
  "APPB2C.registration.prepare.blog.body.cta": APPB2C_registration_prepare_blog_body_cta5,
  "APPB2C.registration.prepare.blog.body.line1": APPB2C_registration_prepare_blog_body_line15,
  "APPB2C.registration.prepare.blog.header": APPB2C_registration_prepare_blog_header5,
  "APPB2C.registration.prepare.cdFamiliarTest.body.cta": APPB2C_registration_prepare_cdFamiliarTest_body_cta5,
  "APPB2C.registration.prepare.cdFamiliarTest.body.line1": APPB2C_registration_prepare_cdFamiliarTest_body_line15,
  "APPB2C.registration.prepare.cdFamiliarTest.header": APPB2C_registration_prepare_cdFamiliarTest_header5,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.cta": APPB2C_registration_prepare_cdFamiliarVideos_body_cta5,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.line1": APPB2C_registration_prepare_cdFamiliarVideos_body_line15,
  "APPB2C.registration.prepare.cdFamiliarVideos.header": APPB2C_registration_prepare_cdFamiliarVideos_header5,
  "APPB2C.registration.prepare.cdTests.body.cta": APPB2C_registration_prepare_cdTests_body_cta5,
  "APPB2C.registration.prepare.cdTests.body.line1": APPB2C_registration_prepare_cdTests_body_line15,
  "APPB2C.registration.prepare.cdTests.header": APPB2C_registration_prepare_cdTests_header5,
  "APPB2C.registration.prepare.cdTipsVideos.body.cta": APPB2C_registration_prepare_cdTipsVideos_body_cta5,
  "APPB2C.registration.prepare.cdTipsVideos.body.line1": APPB2C_registration_prepare_cdTipsVideos_body_line15,
  "APPB2C.registration.prepare.cdTipsVideos.header": APPB2C_registration_prepare_cdTipsVideos_header5,
  "APPB2C.registration.prepare.facebook.body.cta": APPB2C_registration_prepare_facebook_body_cta5,
  "APPB2C.registration.prepare.facebook.body.line1": APPB2C_registration_prepare_facebook_body_line15,
  "APPB2C.registration.prepare.facebook.header": APPB2C_registration_prepare_facebook_header5,
  "APPB2C.registration.prepare.gel.disabledMsg": APPB2C_registration_prepare_gel_disabledMsg5,
  "APPB2C.registration.prepare.gel.ielts.body.cta": APPB2C_registration_prepare_gel_ielts_body_cta5,
  "APPB2C.registration.prepare.gel.ielts.body.line1": APPB2C_registration_prepare_gel_ielts_body_line15,
  "APPB2C.registration.prepare.gel.ielts.header": APPB2C_registration_prepare_gel_ielts_header5,
  "APPB2C.registration.prepare.gel.ukvi.body.cta": APPB2C_registration_prepare_gel_ukvi_body_cta4,
  "APPB2C.registration.prepare.gel.ukvi.body.line1": APPB2C_registration_prepare_gel_ukvi_body_line15,
  "APPB2C.registration.prepare.gel.ukvi.header": APPB2C_registration_prepare_gel_ukvi_header5,
  "APPB2C.registration.prepare.header": APPB2C_registration_prepare_header5,
  "APPB2C.registration.prepare.ieltsReady.subTitle": APPB2C_registration_prepare_ieltsReady_subTitle5,
  "APPB2C.registration.prepare.ieltsReady.title": APPB2C_registration_prepare_ieltsReady_title5,
  "APPB2C.registration.prepare.ieltsReadyMember.body": APPB2C_registration_prepare_ieltsReadyMember_body5,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestBtn": APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn5,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestInfo": APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo5,
  "APPB2C.registration.prepare.ieltsReadyMember.freePrepLink": APPB2C_registration_prepare_ieltsReadyMember_freePrepLink5,
  "APPB2C.registration.prepare.ieltsReadyMember.header": APPB2C_registration_prepare_ieltsReadyMember_header5,
  "APPB2C.registration.prepare.ieltsReadyMember.label": APPB2C_registration_prepare_ieltsReadyMember_label5,
  "APPB2C.registration.prepare.ieltsReadyMember.link": APPB2C_registration_prepare_ieltsReadyMember_link5,
  "APPB2C.registration.prepare.ieltsReadyMember.payBtn": APPB2C_registration_prepare_ieltsReadyMember_payBtn5,
  "APPB2C.registration.prepare.ieltsReadyMember.unpaidTestInfo": APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo5,
  "APPB2C.registration.prepare.intro": APPB2C_registration_prepare_intro5,
  "APPB2C.registration.prepare.introLifeSkills": APPB2C_registration_prepare_introLifeSkills5,
  "APPB2C.registration.prepare.onlineCourse.body.cta": APPB2C_registration_prepare_onlineCourse_body_cta5,
  "APPB2C.registration.prepare.onlineCourse.body.line1": APPB2C_registration_prepare_onlineCourse_body_line15,
  "APPB2C.registration.prepare.onlineCourse.header": APPB2C_registration_prepare_onlineCourse_header5,
  "APPB2C.registration.prepare.pbPractice.body.cta": APPB2C_registration_prepare_pbPractice_body_cta5,
  "APPB2C.registration.prepare.pbPractice.body.line1": APPB2C_registration_prepare_pbPractice_body_line15,
  "APPB2C.registration.prepare.pbPractice.header": APPB2C_registration_prepare_pbPractice_header5,
  "APPB2C.registration.prepare.practice.body.cta": APPB2C_registration_prepare_practice_body_cta5,
  "APPB2C.registration.prepare.practice.body.line1": APPB2C_registration_prepare_practice_body_line15,
  "APPB2C.registration.prepare.practice.header": APPB2C_registration_prepare_practice_header5,
  "APPB2C.registration.prepare.prepApp.body.cta": APPB2C_registration_prepare_prepApp_body_cta5,
  "APPB2C.registration.prepare.prepApp.body.line1": APPB2C_registration_prepare_prepApp_body_line15,
  "APPB2C.registration.prepare.prepApp.header": APPB2C_registration_prepare_prepApp_header5,
  "APPB2C.registration.prepare.prepApp.ukvi.cta1": APPB2C_registration_prepare_prepApp_ukvi_cta15,
  "APPB2C.registration.prepare.prepApp.ukvi.cta2": APPB2C_registration_prepare_prepApp_ukvi_cta25,
  "APPB2C.registration.prepare.prepApp.ukvi.line1": APPB2C_registration_prepare_prepApp_ukvi_line15,
  "APPB2C.registration.prepare.prepApp.ukvi.line2": APPB2C_registration_prepare_prepApp_ukvi_line25,
  "APPB2C.registration.prepare.prepVideos.body.cta": APPB2C_registration_prepare_prepVideos_body_cta5,
  "APPB2C.registration.prepare.prepVideos.body.line1": APPB2C_registration_prepare_prepVideos_body_line15,
  "APPB2C.registration.prepare.prepVideos.header": APPB2C_registration_prepare_prepVideos_header5,
  "APPB2C.registration.prepare.reading.body.cta": APPB2C_registration_prepare_reading_body_cta5,
  "APPB2C.registration.prepare.reading.body.line1": APPB2C_registration_prepare_reading_body_line15,
  "APPB2C.registration.prepare.reading.header": APPB2C_registration_prepare_reading_header5,
  "APPB2C.registration.prepare.rteLastMinute.body.cta": APPB2C_registration_prepare_rteLastMinute_body_cta5,
  "APPB2C.registration.prepare.rteLastMinute.body.item1": APPB2C_registration_prepare_rteLastMinute_body_item15,
  "APPB2C.registration.prepare.rteLastMinute.body.item2": APPB2C_registration_prepare_rteLastMinute_body_item25,
  "APPB2C.registration.prepare.rteLastMinute.body.item3": APPB2C_registration_prepare_rteLastMinute_body_item35,
  "APPB2C.registration.prepare.rteLastMinute.body.line1": APPB2C_registration_prepare_rteLastMinute_body_line15,
  "APPB2C.registration.prepare.rteLastMinute.header": APPB2C_registration_prepare_rteLastMinute_header5,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.cta": APPB2C_registration_prepare_rteLastMinute_ukvi_cta5,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.line1": APPB2C_registration_prepare_rteLastMinute_ukvi_line15,
  "APPB2C.registration.prepare.rteTestDrive.body.cta": APPB2C_registration_prepare_rteTestDrive_body_cta5,
  "APPB2C.registration.prepare.rteTestDrive.body.item1": APPB2C_registration_prepare_rteTestDrive_body_item15,
  "APPB2C.registration.prepare.rteTestDrive.body.item2": APPB2C_registration_prepare_rteTestDrive_body_item25,
  "APPB2C.registration.prepare.rteTestDrive.body.item3": APPB2C_registration_prepare_rteTestDrive_body_item35,
  "APPB2C.registration.prepare.rteTestDrive.body.line1": APPB2C_registration_prepare_rteTestDrive_body_line15,
  "APPB2C.registration.prepare.rteTestDrive.body.line2": APPB2C_registration_prepare_rteTestDrive_body_line25,
  "APPB2C.registration.prepare.rteTestDrive.header": APPB2C_registration_prepare_rteTestDrive_header5,
  "APPB2C.registration.prepare.speaking.body.cta": APPB2C_registration_prepare_speaking_body_cta5,
  "APPB2C.registration.prepare.speaking.body.line1": APPB2C_registration_prepare_speaking_body_line15,
  "APPB2C.registration.prepare.speaking.header": APPB2C_registration_prepare_speaking_header5,
  "APPB2C.registration.prepare.tutor.body.cta": APPB2C_registration_prepare_tutor_body_cta5,
  "APPB2C.registration.prepare.tutor.body.line1": APPB2C_registration_prepare_tutor_body_line15,
  "APPB2C.registration.prepare.tutor.body.line2": APPB2C_registration_prepare_tutor_body_line25,
  "APPB2C.registration.prepare.tutor.header": APPB2C_registration_prepare_tutor_header5,
  "APPB2C.registration.prepare.webinars.body.cta": APPB2C_registration_prepare_webinars_body_cta5,
  "APPB2C.registration.prepare.webinars.body.item1": APPB2C_registration_prepare_webinars_body_item15,
  "APPB2C.registration.prepare.webinars.body.item2": APPB2C_registration_prepare_webinars_body_item25,
  "APPB2C.registration.prepare.webinars.body.item3": APPB2C_registration_prepare_webinars_body_item35,
  "APPB2C.registration.prepare.webinars.body.line1": APPB2C_registration_prepare_webinars_body_line15,
  "APPB2C.registration.prepare.webinars.header": APPB2C_registration_prepare_webinars_header5,
  "APPB2C.registration.results.bandScore.osrBooked": APPB2C_registration_results_bandScore_osrBooked5,
  "APPB2C.registration.results.bandScore.osrMarked": APPB2C_registration_results_bandScore_osrMarked5,
  "APPB2C.registration.results.eor.acknowledgement.backButton": APPB2C_registration_results_eor_acknowledgement_backButton5,
  "APPB2C.registration.results.eor.acknowledgement.components": APPB2C_registration_results_eor_acknowledgement_components5,
  "APPB2C.registration.results.eor.acknowledgement.confirmation": APPB2C_registration_results_eor_acknowledgement_confirmation5,
  "APPB2C.registration.results.eor.acknowledgement.fee": APPB2C_registration_results_eor_acknowledgement_fee5,
  "APPB2C.registration.results.eor.acknowledgement.info": APPB2C_registration_results_eor_acknowledgement_info5,
  "APPB2C.registration.results.eor.acknowledgement.noteTitle": APPB2C_registration_results_eor_acknowledgement_noteTitle5,
  "APPB2C.registration.results.eor.acknowledgement.payment": APPB2C_registration_results_eor_acknowledgement_payment5,
  "APPB2C.registration.results.eor.acknowledgement.paymentButton": APPB2C_registration_results_eor_acknowledgement_paymentButton5,
  "APPB2C.registration.results.eor.acknowledgement.paymentNote": APPB2C_registration_results_eor_acknowledgement_paymentNote5,
  "APPB2C.registration.results.eor.acknowledgement.reference": APPB2C_registration_results_eor_acknowledgement_reference5,
  "APPB2C.registration.results.eor.acknowledgement.success": APPB2C_registration_results_eor_acknowledgement_success5,
  "APPB2C.registration.results.eor.acknowledgement.tax": APPB2C_registration_results_eor_acknowledgement_tax5,
  "APPB2C.registration.results.eor.acknowledgement.terms": APPB2C_registration_results_eor_acknowledgement_terms5,
  "APPB2C.registration.results.eor.acknowledgement.title": APPB2C_registration_results_eor_acknowledgement_title5,
  "APPB2C.registration.results.eor.acknowledgement.total": APPB2C_registration_results_eor_acknowledgement_total5,
  "APPB2C.registration.results.eor.postDeadline.back": APPB2C_registration_results_eor_postDeadline_back5,
  "APPB2C.registration.results.eor.postDeadline.email": APPB2C_registration_results_eor_postDeadline_email5,
  "APPB2C.registration.results.eor.postDeadline.help": APPB2C_registration_results_eor_postDeadline_help5,
  "APPB2C.registration.results.eor.postDeadline.iol.launchChatLine": APPB2C_registration_results_eor_postDeadline_iol_launchChatLine5,
  "APPB2C.registration.results.eor.postDeadline.iol.line3": APPB2C_registration_results_eor_postDeadline_iol_line35,
  "APPB2C.registration.results.eor.postDeadline.line1": APPB2C_registration_results_eor_postDeadline_line15,
  "APPB2C.registration.results.eor.postDeadline.line2": APPB2C_registration_results_eor_postDeadline_line25,
  "APPB2C.registration.results.eor.postDeadline.line3": APPB2C_registration_results_eor_postDeadline_line35,
  "APPB2C.registration.results.eor.postDeadline.phone": APPB2C_registration_results_eor_postDeadline_phone5,
  "APPB2C.registration.results.eor.preDeadline.back": APPB2C_registration_results_eor_preDeadline_back5,
  "APPB2C.registration.results.eor.preDeadline.error.explanationMaxLength": APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength5,
  "APPB2C.registration.results.eor.preDeadline.error.missingTrfNumber": APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber5,
  "APPB2C.registration.results.eor.preDeadline.error.wrongTrfNumber": APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber5,
  "APPB2C.registration.results.eor.preDeadline.evidence": APPB2C_registration_results_eor_preDeadline_evidence5,
  "APPB2C.registration.results.eor.preDeadline.evidenceRequired": APPB2C_registration_results_eor_preDeadline_evidenceRequired5,
  "APPB2C.registration.results.eor.preDeadline.explanation": APPB2C_registration_results_eor_preDeadline_explanation5,
  "APPB2C.registration.results.eor.preDeadline.explanationRequired": APPB2C_registration_results_eor_preDeadline_explanationRequired5,
  "APPB2C.registration.results.eor.preDeadline.fee": APPB2C_registration_results_eor_preDeadline_fee5,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line1": APPB2C_registration_results_eor_preDeadline_iolNote_line14,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line2": APPB2C_registration_results_eor_preDeadline_iolNote_line25,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line3": APPB2C_registration_results_eor_preDeadline_iolNote_line35,
  "APPB2C.registration.results.eor.preDeadline.legal": APPB2C_registration_results_eor_preDeadline_legal5,
  "APPB2C.registration.results.eor.preDeadline.legalNote": APPB2C_registration_results_eor_preDeadline_legalNote5,
  "APPB2C.registration.results.eor.preDeadline.line1": APPB2C_registration_results_eor_preDeadline_line15,
  "APPB2C.registration.results.eor.preDeadline.line2": APPB2C_registration_results_eor_preDeadline_line25,
  "APPB2C.registration.results.eor.preDeadline.maxScore": APPB2C_registration_results_eor_preDeadline_maxScore5,
  "APPB2C.registration.results.eor.preDeadline.note": APPB2C_registration_results_eor_preDeadline_note5,
  "APPB2C.registration.results.eor.preDeadline.noteTitle": APPB2C_registration_results_eor_preDeadline_noteTitle5,
  "APPB2C.registration.results.eor.preDeadline.orderAcknowledgementLabel": APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel5,
  "APPB2C.registration.results.eor.preDeadline.paymentCta": APPB2C_registration_results_eor_preDeadline_paymentCta5,
  "APPB2C.registration.results.eor.preDeadline.paymentTitle": APPB2C_registration_results_eor_preDeadline_paymentTitle5,
  "APPB2C.registration.results.eor.preDeadline.payOfflineCTA": APPB2C_registration_results_eor_preDeadline_payOfflineCTA5,
  "APPB2C.registration.results.eor.preDeadline.payOnlineCTA": APPB2C_registration_results_eor_preDeadline_payOnlineCTA5,
  "APPB2C.registration.results.eor.preDeadline.scoresRequired": APPB2C_registration_results_eor_preDeadline_scoresRequired5,
  "APPB2C.registration.results.eor.preDeadline.scoresTitle": APPB2C_registration_results_eor_preDeadline_scoresTitle5,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr5,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Listening": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening5,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Reading": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading5,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Speaking": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking5,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Writing": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing5,
  "APPB2C.registration.results.eor.preDeadline.tax": APPB2C_registration_results_eor_preDeadline_tax5,
  "APPB2C.registration.results.eor.preDeadline.trfNumberLabel": APPB2C_registration_results_eor_preDeadline_trfNumberLabel5,
  "APPB2C.registration.results.eor.preDeadline.trfNumberSubnote": APPB2C_registration_results_eor_preDeadline_trfNumberSubnote5,
  "APPB2C.registration.results.eor.preDeadline.trfNumberValidation": APPB2C_registration_results_eor_preDeadline_trfNumberValidation5,
  "APPB2C.registration.results.eor.title": APPB2C_registration_results_eor_title5,
  "APPB2C.registration.results.eor.titlePayment": APPB2C_registration_results_eor_titlePayment5,
  "APPB2C.registration.results.hasResults.bandScore.decrease": APPB2C_registration_results_hasResults_bandScore_decrease5,
  "APPB2C.registration.results.hasResults.bandScore.increase": APPB2C_registration_results_hasResults_bandScore_increase5,
  "APPB2C.registration.results.hasResults.bandScore.unchanged": APPB2C_registration_results_hasResults_bandScore_unchanged5,
  "APPB2C.registration.results.hasResults.eorAgentInfo": APPB2C_registration_results_hasResults_eorAgentInfo4,
  "APPB2C.registration.results.hasResults.eorLetter": APPB2C_registration_results_hasResults_eorLetter5,
  "APPB2C.registration.results.hasResults.eorLine1": APPB2C_registration_results_hasResults_eorLine15,
  "APPB2C.registration.results.hasResults.eorLine2": APPB2C_registration_results_hasResults_eorLine25,
  "APPB2C.registration.results.hasResults.eorLink": APPB2C_registration_results_hasResults_eorLink5,
  "APPB2C.registration.results.hasResults.eTrfCta": APPB2C_registration_results_hasResults_eTrfCta4,
  "APPB2C.registration.results.hasResults.explanations.heading": APPB2C_registration_results_hasResults_explanations_heading5,
  "APPB2C.registration.results.hasResults.explanations.hideButton": APPB2C_registration_results_hasResults_explanations_hideButton4,
  "APPB2C.registration.results.hasResults.explanations.improve": APPB2C_registration_results_hasResults_explanations_improve5,
  "APPB2C.registration.results.hasResults.explanations.showButton": APPB2C_registration_results_hasResults_explanations_showButton4,
  "APPB2C.registration.results.hasResults.lifeSkills.failed": APPB2C_registration_results_hasResults_lifeSkills_failed5,
  "APPB2C.registration.results.hasResults.lifeSkills.level": APPB2C_registration_results_hasResults_lifeSkills_level5,
  "APPB2C.registration.results.hasResults.lifeSkills.note": APPB2C_registration_results_hasResults_lifeSkills_note5,
  "APPB2C.registration.results.hasResults.lifeSkills.passed": APPB2C_registration_results_hasResults_lifeSkills_passed5,
  "APPB2C.registration.results.hasResults.lifeSkills.result": APPB2C_registration_results_hasResults_lifeSkills_result5,
  "APPB2C.registration.results.hasResults.osr.bandScore.decrease": APPB2C_registration_results_hasResults_osr_bandScore_decrease4,
  "APPB2C.registration.results.hasResults.osr.bandScore.increase": APPB2C_registration_results_hasResults_osr_bandScore_increase4,
  "APPB2C.registration.results.hasResults.osr.bandScore.unchanged": APPB2C_registration_results_hasResults_osr_bandScore_unchanged4,
  "APPB2C.registration.results.hasResults.osr.previousResults": APPB2C_registration_results_hasResults_osr_previousResults5,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreDecreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased5,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreIncreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased5,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreUnchanged": APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged5,
  "APPB2C.registration.results.hasResults.osr.updateBar.title": APPB2C_registration_results_hasResults_osr_updateBar_title5,
  "APPB2C.registration.results.hasResults.osrLink": APPB2C_registration_results_hasResults_osrLink4,
  "APPB2C.registration.results.hasResults.posting.oneCopyWarning": APPB2C_registration_results_hasResults_posting_oneCopyWarning5,
  "APPB2C.registration.results.hasResults.posting.postedOn": APPB2C_registration_results_hasResults_posting_postedOn5,
  "APPB2C.registration.results.hasResults.posting.postedTo": APPB2C_registration_results_hasResults_posting_postedTo5,
  "APPB2C.registration.results.hasResults.posting.willPostOn": APPB2C_registration_results_hasResults_posting_willPostOn5,
  "APPB2C.registration.results.hasResults.posting.willPostTo": APPB2C_registration_results_hasResults_posting_willPostTo5,
  "APPB2C.registration.results.hasResults.rankNameConsonant": APPB2C_registration_results_hasResults_rankNameConsonant5,
  "APPB2C.registration.results.hasResults.rankNameVowel": APPB2C_registration_results_hasResults_rankNameVowel5,
  "APPB2C.registration.results.hasResults.scoreImprove.btn": APPB2C_registration_results_hasResults_scoreImprove_btn5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.backBtn": APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.description": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.title": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.description": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.title": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.description": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.title": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title5,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.subtitle": APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle5,
  "APPB2C.registration.results.hasResults.scoreImprove.subtitle": APPB2C_registration_results_hasResults_scoreImprove_subtitle5,
  "APPB2C.registration.results.hasResults.scoreImprove.title": APPB2C_registration_results_hasResults_scoreImprove_title5,
  "APPB2C.registration.results.hasResults.scores.label": APPB2C_registration_results_hasResults_scores_label5,
  "APPB2C.registration.results.hasResults.scores.Listening": APPB2C_registration_results_hasResults_scores_Listening5,
  "APPB2C.registration.results.hasResults.scores.Overall": APPB2C_registration_results_hasResults_scores_Overall5,
  "APPB2C.registration.results.hasResults.scores.Reading": APPB2C_registration_results_hasResults_scores_Reading5,
  "APPB2C.registration.results.hasResults.scores.Speaking": APPB2C_registration_results_hasResults_scores_Speaking5,
  "APPB2C.registration.results.hasResults.scores.Writing": APPB2C_registration_results_hasResults_scores_Writing5,
  "APPB2C.registration.results.hasResults.share.applyBoard": APPB2C_registration_results_hasResults_share_applyBoard5,
  "APPB2C.registration.results.hasResults.share.RecognisingOrgsLink": APPB2C_registration_results_hasResults_share_RecognisingOrgsLink5,
  "APPB2C.registration.results.hasResults.share.subtitle": APPB2C_registration_results_hasResults_share_subtitle5,
  "APPB2C.registration.results.hasResults.share.title": APPB2C_registration_results_hasResults_share_title5,
  "APPB2C.registration.results.hasResults.share.trfCta": APPB2C_registration_results_hasResults_share_trfCta5,
  "APPB2C.registration.results.hasResults.viewExplanation": APPB2C_registration_results_hasResults_viewExplanation5,
  "APPB2C.registration.results.noResults.cmds.title": APPB2C_registration_results_noResults_cmds_title5,
  "APPB2C.registration.results.noResults.note.title": APPB2C_registration_results_noResults_note_title5,
  "APPB2C.registration.results.noResults.osr.booked": APPB2C_registration_results_noResults_osr_booked5,
  "APPB2C.registration.results.noResults.osr.previousScores": APPB2C_registration_results_noResults_osr_previousScores5,
  "APPB2C.registration.results.noResults.title": APPB2C_registration_results_noResults_title5,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote1": APPB2C_registration_results_organisations_availableReceivingOrgsNote15,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote2": APPB2C_registration_results_organisations_availableReceivingOrgsNote25,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote3": APPB2C_registration_results_organisations_availableReceivingOrgsNote34,
  "APPB2C.registration.results.organisations.chosenOrganisationsHeader": APPB2C_registration_results_organisations_chosenOrganisationsHeader5,
  "APPB2C.registration.results.organisations.editRo.addAnother": APPB2C_registration_results_organisations_editRo_addAnother5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.addNew": APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.address": APPB2C_registration_results_organisations_editRo_chooseOrganisations_address5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.country": APPB2C_registration_results_organisations_editRo_chooseOrganisations_country5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryAll": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryOptional": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.file": APPB2C_registration_results_organisations_editRo_chooseOrganisations_file5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.org": APPB2C_registration_results_organisations_editRo_chooseOrganisations_org5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.organisation": APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.person": APPB2C_registration_results_organisations_editRo_chooseOrganisations_person5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.postCode": APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByCountry": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry5,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByOrg": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg5,
  "APPB2C.registration.results.organisations.editRo.error.caseNumberMaxLength": APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength5,
  "APPB2C.registration.results.organisations.editRo.error.contactPersonMaxLength": APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength5,
  "APPB2C.registration.results.organisations.editRo.error.minScoreMissing": APPB2C_registration_results_organisations_editRo_error_minScoreMissing5,
  "APPB2C.registration.results.organisations.editRo.error.missingAddressLine1": APPB2C_registration_results_organisations_editRo_error_missingAddressLine15,
  "APPB2C.registration.results.organisations.editRo.error.missingOrgName": APPB2C_registration_results_organisations_editRo_error_missingOrgName5,
  "APPB2C.registration.results.organisations.editRo.error.orgNameMaxLength": APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength5,
  "APPB2C.registration.results.organisations.editRo.iol.addCta": APPB2C_registration_results_organisations_editRo_iol_addCta5,
  "APPB2C.registration.results.organisations.editRo.iol.address": APPB2C_registration_results_organisations_editRo_iol_address5,
  "APPB2C.registration.results.organisations.editRo.iol.cancelAdd": APPB2C_registration_results_organisations_editRo_iol_cancelAdd4,
  "APPB2C.registration.results.organisations.editRo.iol.cancelEdit": APPB2C_registration_results_organisations_editRo_iol_cancelEdit5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.clear": APPB2C_registration_results_organisations_editRo_iol_conditions_clear5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.header": APPB2C_registration_results_organisations_editRo_iol_conditions_header5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.mandatoryPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.no": APPB2C_registration_results_organisations_editRo_iol_conditions_no5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note1": APPB2C_registration_results_organisations_editRo_iol_conditions_note15,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note2": APPB2C_registration_results_organisations_editRo_iol_conditions_note25,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note3": APPB2C_registration_results_organisations_editRo_iol_conditions_note35,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.optionalPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.subHeader": APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader5,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.yes": APPB2C_registration_results_organisations_editRo_iol_conditions_yes5,
  "APPB2C.registration.results.organisations.editRo.iol.copied": APPB2C_registration_results_organisations_editRo_iol_copied5,
  "APPB2C.registration.results.organisations.editRo.iol.copy": APPB2C_registration_results_organisations_editRo_iol_copy5,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.edelivery": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery5,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.eResults": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults5,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.note": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note5,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.postal": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal5,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.trf": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf5,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note1": APPB2C_registration_results_organisations_editRo_iol_delivery_note15,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2": APPB2C_registration_results_organisations_editRo_iol_delivery_note25,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2.results": APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results5,
  "APPB2C.registration.results.organisations.editRo.iol.newOrgTitle": APPB2C_registration_results_organisations_editRo_iol_newOrgTitle5,
  "APPB2C.registration.results.organisations.editRo.iol.noResults": APPB2C_registration_results_organisations_editRo_iol_noResults5,
  "APPB2C.registration.results.organisations.editRo.iol.removeLink": APPB2C_registration_results_organisations_editRo_iol_removeLink5,
  "APPB2C.registration.results.organisations.editRo.iol.saveCta": APPB2C_registration_results_organisations_editRo_iol_saveCta5,
  "APPB2C.registration.results.organisations.editRo.iol.validation.overallScore": APPB2C_registration_results_organisations_editRo_iol_validation_overallScore5,
  "APPB2C.registration.results.organisations.editRo.iol.validation.selectedOrganisation": APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation5,
  "APPB2C.registration.results.organisations.iol.chooseCta": APPB2C_registration_results_organisations_iol_chooseCta5,
  "APPB2C.registration.results.organisations.iol.delivery.auto.header": APPB2C_registration_results_organisations_iol_delivery_auto_header5,
  "APPB2C.registration.results.organisations.iol.delivery.auto.note": APPB2C_registration_results_organisations_iol_delivery_auto_note5,
  "APPB2C.registration.results.organisations.iol.delivery.auto.notSentNote": APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote5,
  "APPB2C.registration.results.organisations.iol.delivery.auto.sentNote": APPB2C_registration_results_organisations_iol_delivery_auto_sentNote5,
  "APPB2C.registration.results.organisations.iol.delivery.postal.header": APPB2C_registration_results_organisations_iol_delivery_postal_header5,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note1": APPB2C_registration_results_organisations_iol_delivery_postal_note15,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2": APPB2C_registration_results_organisations_iol_delivery_postal_note25,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2.results": APPB2C_registration_results_organisations_iol_delivery_postal_note2_results5,
  "APPB2C.registration.results.organisations.iol.edit": APPB2C_registration_results_organisations_iol_edit5,
  "APPB2C.registration.results.organisations.iol.error": APPB2C_registration_results_organisations_iol_error5,
  "APPB2C.registration.results.organisations.iol.generalCondition": APPB2C_registration_results_organisations_iol_generalCondition5,
  "APPB2C.registration.results.organisations.iol.noConditions": APPB2C_registration_results_organisations_iol_noConditions5,
  "APPB2C.registration.results.organisations.iol.notChosen": APPB2C_registration_results_organisations_iol_notChosen5,
  "APPB2C.registration.results.organisations.iol.preciseCondition": APPB2C_registration_results_organisations_iol_preciseCondition5,
  "APPB2C.registration.results.organisations.iol.roAccept.link": APPB2C_registration_results_organisations_iol_roAccept_link5,
  "APPB2C.registration.results.organisations.iol.roAccept.message": APPB2C_registration_results_organisations_iol_roAccept_message5,
  "APPB2C.registration.results.organisations.iol.roAccept.title": APPB2C_registration_results_organisations_iol_roAccept_title5,
  "APPB2C.registration.results.organisations.iol.sendAnyway": APPB2C_registration_results_organisations_iol_sendAnyway5,
  "APPB2C.registration.results.organisations.list.item1": APPB2C_registration_results_organisations_list_item15,
  "APPB2C.registration.results.organisations.list.item2": APPB2C_registration_results_organisations_list_item25,
  "APPB2C.registration.results.organisations.list.item3": APPB2C_registration_results_organisations_list_item35,
  "APPB2C.registration.results.organisations.list.item4": APPB2C_registration_results_organisations_list_item45,
  "APPB2C.registration.results.organisations.mod.delivery.auto.header": APPB2C_registration_results_organisations_mod_delivery_auto_header5,
  "APPB2C.registration.results.organisations.mod.delivery.auto.note": APPB2C_registration_results_organisations_mod_delivery_auto_note5,
  "APPB2C.registration.results.organisations.mod.delivery.auto.notSentNote": APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote5,
  "APPB2C.registration.results.organisations.mod.delivery.auto.sentNote": APPB2C_registration_results_organisations_mod_delivery_auto_sentNote5,
  "APPB2C.registration.results.organisations.mod.delivery.postal.header": APPB2C_registration_results_organisations_mod_delivery_postal_header5,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note1": APPB2C_registration_results_organisations_mod_delivery_postal_note15,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note2": APPB2C_registration_results_organisations_mod_delivery_postal_note25,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note3": APPB2C_registration_results_organisations_mod_delivery_postal_note35,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note4": APPB2C_registration_results_organisations_mod_delivery_postal_note45,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note5": APPB2C_registration_results_organisations_mod_delivery_postal_note55,
  "APPB2C.registration.results.organisations.mod.delivery.postal.notSentNote": APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote5,
  "APPB2C.registration.results.organisations.mod.delivery.postal.sentNote": APPB2C_registration_results_organisations_mod_delivery_postal_sentNote5,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount5,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount_other": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other5,
  "APPB2C.registration.results.organisations.mod.howTo.item1": APPB2C_registration_results_organisations_mod_howTo_item15,
  "APPB2C.registration.results.organisations.mod.howTo.item2": APPB2C_registration_results_organisations_mod_howTo_item25,
  "APPB2C.registration.results.organisations.mod.howTo.line1": APPB2C_registration_results_organisations_mod_howTo_line15,
  "APPB2C.registration.results.organisations.mod.howTo.title": APPB2C_registration_results_organisations_mod_howTo_title5,
  "APPB2C.registration.results.organisations.noReceivingOrgsSelectedNote": APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote5,
  "APPB2C.registration.results.organisations.note0": APPB2C_registration_results_organisations_note05,
  "APPB2C.registration.results.organisations.note1": APPB2C_registration_results_organisations_note15,
  "APPB2C.registration.results.organisations.note2": APPB2C_registration_results_organisations_note25,
  "APPB2C.registration.results.organisations.organisationsCount": APPB2C_registration_results_organisations_organisationsCount5,
  "APPB2C.registration.results.organisations.organisationsCount_other": APPB2C_registration_results_organisations_organisationsCount_other5,
  "APPB2C.registration.results.organisations.receivingOrgsLimitReachedNote": APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote5,
  "APPB2C.registration.results.organisations.title": APPB2C_registration_results_organisations_title5,
  "APPB2C.registration.results.osr.chooseDate.accessibility": APPB2C_registration_results_osr_chooseDate_accessibility5,
  "APPB2C.registration.results.osr.chooseDate.accessibilityLink": APPB2C_registration_results_osr_chooseDate_accessibilityLink5,
  "APPB2C.registration.results.osr.chooseDate.chooseDateTitle": APPB2C_registration_results_osr_chooseDate_chooseDateTitle5,
  "APPB2C.registration.results.osr.chooseDate.note.content": APPB2C_registration_results_osr_chooseDate_note_content5,
  "APPB2C.registration.results.osr.chooseDate.note.title": APPB2C_registration_results_osr_chooseDate_note_title5,
  "APPB2C.registration.results.osr.chooseDate.retakeListening": APPB2C_registration_results_osr_chooseDate_retakeListening5,
  "APPB2C.registration.results.osr.chooseDate.retakeReading": APPB2C_registration_results_osr_chooseDate_retakeReading5,
  "APPB2C.registration.results.osr.chooseDate.retakeSpeaking": APPB2C_registration_results_osr_chooseDate_retakeSpeaking5,
  "APPB2C.registration.results.osr.chooseDate.retakeWriting": APPB2C_registration_results_osr_chooseDate_retakeWriting5,
  "APPB2C.registration.results.osr.chooseSkill.backBtn": APPB2C_registration_results_osr_chooseSkill_backBtn5,
  "APPB2C.registration.results.osr.chooseSkill.chooseDateBtn": APPB2C_registration_results_osr_chooseSkill_chooseDateBtn5,
  "APPB2C.registration.results.osr.chooseSkill.confirmationRequired": APPB2C_registration_results_osr_chooseSkill_confirmationRequired5,
  "APPB2C.registration.results.osr.chooseSkill.formTitle": APPB2C_registration_results_osr_chooseSkill_formTitle5,
  "APPB2C.registration.results.osr.chooseSkill.modal.contact": APPB2C_registration_results_osr_chooseSkill_modal_contact5,
  "APPB2C.registration.results.osr.chooseSkill.modal.description": APPB2C_registration_results_osr_chooseSkill_modal_description5,
  "APPB2C.registration.results.osr.chooseSkill.modal.email": APPB2C_registration_results_osr_chooseSkill_modal_email5,
  "APPB2C.registration.results.osr.chooseSkill.modal.phone": APPB2C_registration_results_osr_chooseSkill_modal_phone5,
  "APPB2C.registration.results.osr.chooseSkill.modal.title": APPB2C_registration_results_osr_chooseSkill_modal_title5,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.confirmation": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation5,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.description": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description5,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.title": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title5,
  "APPB2C.registration.results.osr.chooseSkill.skillRequired": APPB2C_registration_results_osr_chooseSkill_skillRequired5,
  "APPB2C.registration.results.osr.chooseTest.availableDates": APPB2C_registration_results_osr_chooseTest_availableDates5,
  "APPB2C.registration.results.osr.chooseTest.speaking.time": APPB2C_registration_results_osr_chooseTest_speaking_time5,
  "APPB2C.registration.results.osr.error.missingFile": APPB2C_registration_results_osr_error_missingFile5,
  "APPB2C.registration.results.osr.error.noTestDates": APPB2C_registration_results_osr_error_noTestDates5,
  "APPB2C.registration.results.osr.error.noTests": APPB2C_registration_results_osr_error_noTests5,
  "APPB2C.registration.results.osr.line1": APPB2C_registration_results_osr_line15,
  "APPB2C.registration.results.osr.line2": APPB2C_registration_results_osr_line25,
  "APPB2C.registration.results.osr.line3": APPB2C_registration_results_osr_line35,
  "APPB2C.registration.results.osr.line4": APPB2C_registration_results_osr_line45,
  "APPB2C.registration.results.osr.line5": APPB2C_registration_results_osr_line55,
  "APPB2C.registration.results.osr.review.note": APPB2C_registration_results_osr_review_note5,
  "APPB2C.registration.results.osr.review.note.testDay": APPB2C_registration_results_osr_review_note_testDay5,
  "APPB2C.registration.results.osr.submitBtn": APPB2C_registration_results_osr_submitBtn5,
  "APPB2C.registration.results.osr.successBanner": APPB2C_registration_results_osr_successBanner5,
  "APPB2C.registration.results.osr.title": APPB2C_registration_results_osr_title5,
  "APPB2C.registration.results.ttAbsent.btn": APPB2C_registration_results_ttAbsent_btn5,
  "APPB2C.registration.results.ttAbsent.msg": APPB2C_registration_results_ttAbsent_msg5,
  "APPB2C.registration.results.updateBar.osr.booked": APPB2C_registration_results_updateBar_osr_booked5,
  "APPB2C.registration.results.updateBar.osr.marked": APPB2C_registration_results_updateBar_osr_marked5,
  "APPB2C.registration.tabs.bookingDetails": APPB2C_registration_tabs_bookingDetails5,
  "APPB2C.registration.tabs.candidate": APPB2C_registration_tabs_candidate5,
  "APPB2C.registration.tabs.date": APPB2C_registration_tabs_date5,
  "APPB2C.registration.tabs.dateLocation": APPB2C_registration_tabs_dateLocation5,
  "APPB2C.registration.tabs.payments": APPB2C_registration_tabs_payments5,
  "APPB2C.registration.tabs.recognisingOrgs": APPB2C_registration_tabs_recognisingOrgs5,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.1": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_15,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.2": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_25,
  "APPB2C.registration.tabs.results": APPB2C_registration_tabs_results5,
  "APPB2C.registration.testTaker.bookedFor.completed": APPB2C_registration_testTaker_bookedFor_completed5,
  "APPB2C.registration.testTaker.bookedFor.dob": APPB2C_registration_testTaker_bookedFor_dob5,
  "APPB2C.registration.testTaker.bookedFor.email": APPB2C_registration_testTaker_bookedFor_email5,
  "APPB2C.registration.testTaker.bookedFor.gender": APPB2C_registration_testTaker_bookedFor_gender5,
  "APPB2C.registration.testTaker.bookedFor.name": APPB2C_registration_testTaker_bookedFor_name5,
  "APPB2C.registration.testTaker.bookedFor.parent": APPB2C_registration_testTaker_bookedFor_parent5,
  "APPB2C.registration.testTaker.bookedFor.title": APPB2C_registration_testTaker_bookedFor_title5,
  "APPB2C.registration.testTaker.childConsent.back": APPB2C_registration_testTaker_childConsent_back5,
  "APPB2C.registration.testTaker.childConsent.context": APPB2C_registration_testTaker_childConsent_context5,
  "APPB2C.registration.testTaker.childConsent.cta": APPB2C_registration_testTaker_childConsent_cta5,
  "APPB2C.registration.testTaker.childConsent.options.1": APPB2C_registration_testTaker_childConsent_options_15,
  "APPB2C.registration.testTaker.childConsent.options.2": APPB2C_registration_testTaker_childConsent_options_25,
  "APPB2C.registration.testTaker.childConsent.options.3": APPB2C_registration_testTaker_childConsent_options_35,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.add": APPB2C_registration_testTaker_childConsent_somebodyElse_add5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmCheckbox": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.1": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_15,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.2": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_25,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.noLastName": APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.note": APPB2C_registration_testTaker_childConsent_somebodyElse_note5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phone": APPB2C_registration_testTaker_childConsent_somebodyElse_phone5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phoneNote": APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.policy": APPB2C_registration_testTaker_childConsent_somebodyElse_policy5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.relationship": APPB2C_registration_testTaker_childConsent_somebodyElse_relationship5,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.1": APPB2C_registration_testTaker_childConsent_somebodyElse_title_15,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.2": APPB2C_registration_testTaker_childConsent_somebodyElse_title_25,
  "APPB2C.registration.testTaker.childConsent.title": APPB2C_registration_testTaker_childConsent_title5,
  "APPB2C.registration.testTaker.childConsent.warning": APPB2C_registration_testTaker_childConsent_warning5,
  "APPB2C.registration.testTaker.consent.edit": APPB2C_registration_testTaker_consent_edit5,
  "APPB2C.registration.testTaker.consent.missing": APPB2C_registration_testTaker_consent_missing5,
  "APPB2C.registration.testTaker.consent.title": APPB2C_registration_testTaker_consent_title5,
  "APPB2C.registration.testTaker.contact.address": APPB2C_registration_testTaker_contact_address5,
  "APPB2C.registration.testTaker.contact.edit": APPB2C_registration_testTaker_contact_edit5,
  "APPB2C.registration.testTaker.contact.mobile": APPB2C_registration_testTaker_contact_mobile5,
  "APPB2C.registration.testTaker.contact.parent": APPB2C_registration_testTaker_contact_parent5,
  "APPB2C.registration.testTaker.contact.title": APPB2C_registration_testTaker_contact_title5,
  "APPB2C.registration.testTaker.editContact.address": APPB2C_registration_testTaker_editContact_address5,
  "APPB2C.registration.testTaker.editContact.back": APPB2C_registration_testTaker_editContact_back5,
  "APPB2C.registration.testTaker.editContact.city": APPB2C_registration_testTaker_editContact_city5,
  "APPB2C.registration.testTaker.editContact.country.change": APPB2C_registration_testTaker_editContact_country_change5,
  "APPB2C.registration.testTaker.editContact.country.residence": APPB2C_registration_testTaker_editContact_country_residence5,
  "APPB2C.registration.testTaker.editContact.cta": APPB2C_registration_testTaker_editContact_cta5,
  "APPB2C.registration.testTaker.editContact.error.missingAddress": APPB2C_registration_testTaker_editContact_error_missingAddress5,
  "APPB2C.registration.testTaker.editContact.error.missingCountry": APPB2C_registration_testTaker_editContact_error_missingCountry5,
  "APPB2C.registration.testTaker.editContact.error.pastCutoff": APPB2C_registration_testTaker_editContact_error_pastCutoff5,
  "APPB2C.registration.testTaker.editContact.mobile": APPB2C_registration_testTaker_editContact_mobile5,
  "APPB2C.registration.testTaker.editContact.note": APPB2C_registration_testTaker_editContact_note5,
  "APPB2C.registration.testTaker.editContact.postCode": APPB2C_registration_testTaker_editContact_postCode5,
  "APPB2C.registration.testTaker.editContact.state": APPB2C_registration_testTaker_editContact_state5,
  "APPB2C.registration.testTaker.editContact.warn": APPB2C_registration_testTaker_editContact_warn5,
  "APPB2C.registration.testTaker.error": APPB2C_registration_testTaker_error5,
  "APPB2C.registration.testTaker.files.editButton.add": APPB2C_registration_testTaker_files_editButton_add5,
  "APPB2C.registration.testTaker.files.editButton.edit": APPB2C_registration_testTaker_files_editButton_edit5,
  "APPB2C.registration.testTaker.files.upload.back.subtitle": APPB2C_registration_testTaker_files_upload_back_subtitle5,
  "APPB2C.registration.testTaker.files.upload.back.title": APPB2C_registration_testTaker_files_upload_back_title5,
  "APPB2C.registration.testTaker.files.upload.cta": APPB2C_registration_testTaker_files_upload_cta5,
  "APPB2C.registration.testTaker.files.upload.error.fileTooLarge": APPB2C_registration_testTaker_files_upload_error_fileTooLarge5,
  "APPB2C.registration.testTaker.files.upload.error.maxNumberOfFilesExceeded": APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded5,
  "APPB2C.registration.testTaker.files.upload.error.uploadUnavailable": APPB2C_registration_testTaker_files_upload_error_uploadUnavailable5,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileName": APPB2C_registration_testTaker_files_upload_error_wrongFileName5,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileType": APPB2C_registration_testTaker_files_upload_error_wrongFileType5,
  "APPB2C.registration.testTaker.files.upload.mobile.line1": APPB2C_registration_testTaker_files_upload_mobile_line15,
  "APPB2C.registration.testTaker.files.upload.mobile.line2": APPB2C_registration_testTaker_files_upload_mobile_line25,
  "APPB2C.registration.testTaker.files.upload.mobile.title": APPB2C_registration_testTaker_files_upload_mobile_title5,
  "APPB2C.registration.testTaker.files.upload.note": APPB2C_registration_testTaker_files_upload_note5,
  "APPB2C.registration.testTaker.id.child": APPB2C_registration_testTaker_id_child5,
  "APPB2C.registration.testTaker.id.edit.rules.example": APPB2C_registration_testTaker_id_edit_rules_example5,
  "APPB2C.registration.testTaker.id.edit.subtitle": APPB2C_registration_testTaker_id_edit_subtitle5,
  "APPB2C.registration.testTaker.id.edit.title": APPB2C_registration_testTaker_id_edit_title5,
  "APPB2C.registration.testTaker.id.edit.upload.exampleProof": APPB2C_registration_testTaker_id_edit_upload_exampleProof5,
  "APPB2C.registration.testTaker.id.edit.upload.imgAlt": APPB2C_registration_testTaker_id_edit_upload_imgAlt5,
  "APPB2C.registration.testTaker.id.expiryDate": APPB2C_registration_testTaker_id_expiryDate5,
  "APPB2C.registration.testTaker.id.fileAlt": APPB2C_registration_testTaker_id_fileAlt5,
  "APPB2C.registration.testTaker.id.idNumber": APPB2C_registration_testTaker_id_idNumber5,
  "APPB2C.registration.testTaker.id.issuingAuthority": APPB2C_registration_testTaker_id_issuingAuthority5,
  "APPB2C.registration.testTaker.id.noFilesBody": APPB2C_registration_testTaker_id_noFilesBody5,
  "APPB2C.registration.testTaker.id.noFilesTitle": APPB2C_registration_testTaker_id_noFilesTitle5,
  "APPB2C.registration.testTaker.id.title": APPB2C_registration_testTaker_id_title5,
  "APPB2C.registration.testTaker.id.typeNames.card": APPB2C_registration_testTaker_id_typeNames_card5,
  "APPB2C.registration.testTaker.id.typeNames.other": APPB2C_registration_testTaker_id_typeNames_other5,
  "APPB2C.registration.testTaker.id.typeNames.passport": APPB2C_registration_testTaker_id_typeNames_passport5,
  "APPB2C.registration.testTaker.specialArrangements.description": APPB2C_registration_testTaker_specialArrangements_description5,
  "APPB2C.registration.testTaker.specialArrangements.edit.line1": APPB2C_registration_testTaker_specialArrangements_edit_line15,
  "APPB2C.registration.testTaker.specialArrangements.edit.line2": APPB2C_registration_testTaker_specialArrangements_edit_line25,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule1": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule15,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule2": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule25,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule3": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule35,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule4": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule45,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule5": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule55,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.title": APPB2C_registration_testTaker_specialArrangements_edit_rules_title5,
  "APPB2C.registration.testTaker.specialArrangements.edit.subtitle": APPB2C_registration_testTaker_specialArrangements_edit_subtitle5,
  "APPB2C.registration.testTaker.specialArrangements.edit.title": APPB2C_registration_testTaker_specialArrangements_edit_title5,
  "APPB2C.registration.testTaker.specialArrangements.fileAlt": APPB2C_registration_testTaker_specialArrangements_fileAlt5,
  "APPB2C.registration.testTaker.specialArrangements.noFilesBody": APPB2C_registration_testTaker_specialArrangements_noFilesBody5,
  "APPB2C.registration.testTaker.specialArrangements.noFilesTitle": APPB2C_registration_testTaker_specialArrangements_noFilesTitle5,
  "APPB2C.registration.testTaker.specialArrangements.title": APPB2C_registration_testTaker_specialArrangements_title5,
  "APPB2C.testCard.arrival": APPB2C_testCard_arrival5,
  "APPB2C.testCard.book": APPB2C_testCard_book5,
  "APPB2C.testCard.bookExam": APPB2C_testCard_bookExam5,
  "APPB2C.testCard.bookFor": APPB2C_testCard_bookFor5,
  "APPB2C.testCard.bookForFree": APPB2C_testCard_bookForFree5,
  "APPB2C.testCard.bookingPrice": APPB2C_testCard_bookingPrice5,
  "APPB2C.testCard.cd": APPB2C_testCard_cd5,
  "APPB2C.testCard.changeSpeaking": APPB2C_testCard_changeSpeaking5,
  "APPB2C.testCard.f2f": APPB2C_testCard_f2f5,
  "APPB2C.testCard.hideSpeakingTests": APPB2C_testCard_hideSpeakingTests5,
  "APPB2C.testCard.lrw": APPB2C_testCard_lrw5,
  "APPB2C.testCard.lrwHeader": APPB2C_testCard_lrwHeader4,
  "APPB2C.testCard.ls": APPB2C_testCard_ls5,
  "APPB2C.testCard.mixedSlots": APPB2C_testCard_mixedSlots5,
  "APPB2C.testCard.notLinkedVenue": APPB2C_testCard_notLinkedVenue5,
  "APPB2C.testCard.pb": APPB2C_testCard_pb5,
  "APPB2C.testCard.review": APPB2C_testCard_review5,
  "APPB2C.testCard.showSpeakingTests": APPB2C_testCard_showSpeakingTests5,
  "APPB2C.testCard.speaking": APPB2C_testCard_speaking5,
  "APPB2C.testCard.speakingAddress": APPB2C_testCard_speakingAddress5,
  "APPB2C.testCard.timeUnknown": APPB2C_testCard_timeUnknown5,
  "APPB2C.testCard.vcs": APPB2C_testCard_vcs5
};

// src/locale/ach-ug/b2c.json
var APPB2C_auth_changePassword_cta6 = "crwdns51413:0crwdne51413:0";
var APPB2C_auth_changePassword_error6 = "crwdns51425:0crwdne51425:0";
var APPB2C_auth_changePassword_error_differentPasswords6 = "crwdns152556:0crwdne152556:0";
var APPB2C_auth_changePassword_error_wrongIdNumber6 = "crwdns137326:0crwdne137326:0";
var APPB2C_auth_changePassword_error_wrongOldPassword6 = "crwdns152558:0crwdne152558:0";
var APPB2C_auth_changePassword_error_wrongPassword6 = "crwdns137328:0crwdne137328:0";
var APPB2C_auth_changePassword_id_label6 = "crwdns51417:0crwdne51417:0";
var APPB2C_auth_changePassword_id_note6 = "crwdns51415:0crwdne51415:0";
var APPB2C_auth_changePassword_id_warn6 = "crwdns51419:0crwdne51419:0";
var APPB2C_auth_changePassword_pass_label6 = "crwdns51423:0crwdne51423:0";
var APPB2C_auth_changePassword_pass_note6 = "crwdns51421:0crwdne51421:0";
var APPB2C_auth_changePassword_title6 = "crwdns51411:0crwdne51411:0";
var APPB2C_auth_forgetPassword_cta6 = "crwdns51389:0crwdne51389:0";
var APPB2C_auth_forgetPassword_email6 = "crwdns51385:0crwdne51385:0";
var APPB2C_auth_forgetPassword_success_msg16 = "crwdns51393:0crwdne51393:0";
var APPB2C_auth_forgetPassword_success_msg26 = "crwdns51395:0crwdne51395:0";
var APPB2C_auth_forgetPassword_success_title6 = "crwdns51391:0crwdne51391:0";
var APPB2C_auth_forgetPassword_text6 = "crwdns51387:0crwdne51387:0";
var APPB2C_auth_forgetPassword_title6 = "crwdns51383:0crwdne51383:0";
var APPB2C_auth_resetPassword_cta6 = "crwdns51399:0crwdne51399:0";
var APPB2C_auth_resetPassword_error_expiredToken6 = "crwdns137330:0crwdne137330:0";
var APPB2C_auth_resetPassword_error_wrongPassword6 = "crwdns137332:0crwdne137332:0";
var APPB2C_auth_resetPassword_error_wrongToken6 = "crwdns137334:0crwdne137334:0";
var APPB2C_auth_resetPassword_form_newPassword6 = "crwdns51401:0crwdne51401:0";
var APPB2C_auth_resetPassword_noToken_cta6 = "crwdns51409:0crwdne51409:0";
var APPB2C_auth_resetPassword_noToken_msg6 = "crwdns51407:0crwdne51407:0";
var APPB2C_auth_resetPassword_success_msg6 = "crwdns51405:0crwdne51405:0";
var APPB2C_auth_resetPassword_success_title6 = "crwdns51403:0crwdne51403:0";
var APPB2C_auth_resetPassword_title6 = "crwdns51397:0crwdne51397:0";
var APPB2C_cj_basic_cta6 = "crwdns51427:0crwdne51427:0";
var APPB2C_cj_basic_goToReview6 = "crwdns51429:0crwdne51429:0";
var APPB2C_cj_basic_scrollLink6 = "crwdns51433:0crwdne51433:0";
var APPB2C_cj_basic_selectDate6 = "crwdns51435:0crwdne51435:0";
var APPB2C_cj_basic_selectTime6 = "crwdns51437:0crwdne51437:0";
var APPB2C_cj_basic_validationError6 = "crwdns51431:0crwdne51431:0";
var APPB2C_cj_bookingComplete_feeDetails_discount6 = "crwdns51903:0crwdne51903:0";
var APPB2C_cj_bookingComplete_feeDetails_fee6 = "crwdns51897:0crwdne51897:0";
var APPB2C_cj_bookingComplete_feeDetails_newFee6 = "crwdns51899:0crwdne51899:0";
var APPB2C_cj_bookingComplete_feeDetails_promo6 = "crwdns51905:0crwdne51905:0";
var APPB2C_cj_bookingComplete_feeDetails_reference6 = "crwdns51909:0crwdne51909:0";
var APPB2C_cj_bookingComplete_feeDetails_tax6 = "crwdns51901:0crwdne51901:0";
var APPB2C_cj_bookingComplete_feeDetails_title6 = "crwdns51895:0crwdne51895:0";
var APPB2C_cj_bookingComplete_feeDetails_total6 = "crwdns51907:0crwdne51907:0";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine16 = "crwdns125778:0crwdne125778:0";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine26 = "crwdns125780:0crwdne125780:0";
var APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine36 = "crwdns125782:0crwdne125782:0";
var APPB2C_cj_bookingComplete_loadingPaymentMethodsError6 = "crwdns83781:0crwdne83781:0";
var APPB2C_cj_bookingComplete_loadingRegistrationError6 = "crwdns51893:0crwdne51893:0";
var APPB2C_cj_bookingComplete_note6 = "crwdns51883:0crwdne51883:0";
var APPB2C_cj_bookingComplete_noteUolPassportInfo6 = "crwdns51885:0crwdne51885:0";
var APPB2C_cj_bookingComplete_offlinePayment_code6 = "crwdns51939:0crwdne51939:0";
var APPB2C_cj_bookingComplete_offlinePayment_description6 = "crwdns51943:0crwdne51943:0";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line16 = "crwdns125784:0crwdne125784:0";
var APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line26 = "crwdns125786:0crwdne125786:0";
var APPB2C_cj_bookingComplete_offlinePayment_fee6 = "crwdns51931:0crwdne51931:0";
var APPB2C_cj_bookingComplete_offlinePayment_hideDescription6 = "crwdns51947:0crwdne51947:0";
var APPB2C_cj_bookingComplete_offlinePayment_incTax6 = "crwdns51933:0crwdne51933:0";
var APPB2C_cj_bookingComplete_offlinePayment_name6 = "crwdns51935:0crwdne51935:0";
var APPB2C_cj_bookingComplete_offlinePayment_note_line16 = "crwdns51925:0{{days}}crwdne51925:0";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours6 = "crwdns51927:0{{hours}}crwdne51927:0";
var APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft6 = "crwdns125573:0{{date}}crwdne125573:0";
var APPB2C_cj_bookingComplete_offlinePayment_note_line26 = "crwdns51929:0crwdne51929:0";
var APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote6 = "crwdns51923:0crwdne51923:0";
var APPB2C_cj_bookingComplete_offlinePayment_number6 = "crwdns51937:0crwdne51937:0";
var APPB2C_cj_bookingComplete_offlinePayment_reference6 = "crwdns51941:0crwdne51941:0";
var APPB2C_cj_bookingComplete_offlinePayment_showDescription6 = "crwdns51945:0crwdne51945:0";
var APPB2C_cj_bookingComplete_otherPaymentOptions6 = "crwdns51891:0crwdne51891:0";
var APPB2C_cj_bookingComplete_paymentFailed6 = "crwdns51887:0crwdne51887:0";
var APPB2C_cj_bookingComplete_paymentOptions6 = "crwdns152560:0crwdne152560:0";
var APPB2C_cj_bookingComplete_prepareNote_accesNote6 = "crwdns51915:0crwdne51915:0";
var APPB2C_cj_bookingComplete_prepareNote_counselling_note6 = "crwdns51919:0crwdne51919:0";
var APPB2C_cj_bookingComplete_prepareNote_counselling_view6 = "crwdns51921:0crwdne51921:0";
var APPB2C_cj_bookingComplete_prepareNote_note6 = "crwdns51913:0crwdne51913:0";
var APPB2C_cj_bookingComplete_prepareNote_title6 = "crwdns51911:0crwdne51911:0";
var APPB2C_cj_bookingComplete_prepareNote_view6 = "crwdns51917:0crwdne51917:0";
var APPB2C_cj_bookingComplete_title6 = "crwdns51879:0crwdne51879:0";
var APPB2C_cj_bookingComplete_tnc6 = "crwdns51949:0crwdne51949:0";
var APPB2C_cj_bookingComplete_tncLink6 = "crwdns51951:0crwdne51951:0";
var APPB2C_cj_bookingComplete_tryAgain6 = "crwdns51889:0crwdne51889:0";
var APPB2C_cj_bookingComplete_yourBooking6 = "crwdns51881:0crwdne51881:0";
var APPB2C_cj_childDetails_about6 = "crwdns51569:0crwdne51569:0";
var APPB2C_cj_childDetails_aboutNote6 = "crwdns51571:0crwdne51571:0";
var APPB2C_cj_childDetails_error_missingFirstName6 = "crwdns137336:0crwdne137336:0";
var APPB2C_cj_childDetails_error_missingSurname6 = "crwdns137338:0crwdne137338:0";
var APPB2C_cj_childDetails_error_wrongDob6 = "crwdns137340:0crwdne137340:0";
var APPB2C_cj_childDetails_forms_personal_idMatch6 = "crwdns51579:0crwdne51579:0";
var APPB2C_cj_childDetails_noLastName6 = "crwdns51573:0crwdne51573:0";
var APPB2C_cj_childDetails_note6 = "crwdns51575:0crwdne51575:0";
var APPB2C_cj_childDetails_onTestDay6 = "crwdns51577:0crwdne51577:0";
var APPB2C_cj_childDetails_title6 = "crwdns51567:0crwdne51567:0";
var APPB2C_cj_finishPayment_error6 = "crwdns51877:0crwdne51877:0";
var APPB2C_cj_finishPayment_pleaseWait6 = "crwdns51875:0crwdne51875:0";
var APPB2C_cj_finishPayment_processing6 = "crwdns51873:0crwdne51873:0";
var APPB2C_cj_idDetails_confirm_change6 = "crwdns51649:0crwdne51649:0";
var APPB2C_cj_idDetails_confirm_confirm6 = "crwdns51647:0crwdne51647:0";
var APPB2C_cj_idDetails_confirm_note16 = "crwdns51643:0$t({{idName}})crwdne51643:0";
var APPB2C_cj_idDetails_confirm_note26 = "crwdns51645:0$t({{idName}})crwdne51645:0";
var APPB2C_cj_idDetails_confirm_title6 = "crwdns51641:0$t({{idName}})crwdne51641:0";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_content6 = "crwdns51653:0crwdne51653:0";
var APPB2C_cj_idDetails_confirm_wrongDetailsNote_title6 = "crwdns51651:0crwdne51651:0";
var APPB2C_cj_idDetails_error_missingIdExpiry6 = "crwdns137342:0crwdne137342:0";
var APPB2C_cj_idDetails_error_missingIdNumber6 = "crwdns137344:0crwdne137344:0";
var APPB2C_cj_idDetails_error_missingIdType6 = "crwdns152562:0crwdne152562:0";
var APPB2C_cj_idDetails_error_missingIssuingAuthority6 = "crwdns137346:0crwdne137346:0";
var APPB2C_cj_idDetails_error_wrongFilename6 = "crwdns137348:0crwdne137348:0";
var APPB2C_cj_idDetails_error_wrongIdExpiry6 = "crwdns137350:0crwdne137350:0";
var APPB2C_cj_idDetails_forms_changeNote6 = "crwdns51589:0crwdne51589:0";
var APPB2C_cj_idDetails_forms_changeNote_child6 = "crwdns51591:0crwdne51591:0";
var APPB2C_cj_idDetails_forms_documentWithNoExpiryDate6 = "crwdns51599:0crwdne51599:0";
var APPB2C_cj_idDetails_forms_expiryDate6 = "crwdns51597:0$t({{idName}}, capitalise)crwdne51597:0";
var APPB2C_cj_idDetails_forms_idNumber6 = "crwdns51593:0$t({{idName}}, capitalise)crwdne51593:0";
var APPB2C_cj_idDetails_forms_idType6 = "crwdns51587:0crwdne51587:0";
var APPB2C_cj_idDetails_forms_issuingAuthority6 = "crwdns51595:0crwdne51595:0";
var APPB2C_cj_idDetails_idExpired6 = "crwdns51609:0crwdne51609:0";
var APPB2C_cj_idDetails_iolMessage6 = "crwdns51607:0crwdne51607:0";
var APPB2C_cj_idDetails_noteAboutIdentification6 = "crwdns51611:0crwdne51611:0";
var APPB2C_cj_idDetails_noteAboutIdentification_child6 = "crwdns51619:0crwdne51619:0";
var APPB2C_cj_idDetails_noteAboutIdentificationIol6 = "crwdns51613:0crwdne51613:0";
var APPB2C_cj_idDetails_noteAboutIdentificationIolLink6 = "crwdns51617:0crwdne51617:0";
var APPB2C_cj_idDetails_noteAboutIdentificationUol6 = "crwdns51615:0crwdne51615:0";
var APPB2C_cj_idDetails_title6 = "crwdns51583:0crwdne51583:0";
var APPB2C_cj_idDetails_title_child6 = "crwdns51585:0crwdne51585:0";
var APPB2C_cj_idDetails_typeNames_card6 = "crwdns51603:0crwdne51603:0";
var APPB2C_cj_idDetails_typeNames_other6 = "crwdns51605:0crwdne51605:0";
var APPB2C_cj_idDetails_typeNames_passport6 = "crwdns51601:0crwdne51601:0";
var APPB2C_cj_idDetails_upload_fetchError6 = "crwdns51639:0crwdne51639:0";
var APPB2C_cj_idDetails_upload_file16 = "crwdns51635:0crwdne51635:0";
var APPB2C_cj_idDetails_upload_file26 = "crwdns51637:0crwdne51637:0";
var APPB2C_cj_idDetails_upload_instructions_capture6 = "crwdns51627:0crwdne51627:0";
var APPB2C_cj_idDetails_upload_instructions_covers6 = "crwdns51633:0crwdne51633:0";
var APPB2C_cj_idDetails_upload_instructions_directLight6 = "crwdns51631:0crwdne51631:0";
var APPB2C_cj_idDetails_upload_instructions_noBlur6 = "crwdns51629:0crwdne51629:0";
var APPB2C_cj_idDetails_upload_instructions_title6 = "crwdns51625:0crwdne51625:0";
var APPB2C_cj_idDetails_upload_mobile6 = "crwdns51623:0crwdne51623:0";
var APPB2C_cj_idDetails_upload_passport_guidelines_cover6 = "crwdns148798:0crwdne148798:0";
var APPB2C_cj_idDetails_upload_passport_guidelines_crop6 = "crwdns148800:0crwdne148800:0";
var APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions6 = "crwdns148802:0crwdne148802:0";
var APPB2C_cj_idDetails_upload_passport_guidelines_glare6 = "crwdns148804:0crwdne148804:0";
var APPB2C_cj_idDetails_upload_passport_guidelines_watermark6 = "crwdns148806:0crwdne148806:0";
var APPB2C_cj_idDetails_upload_title6 = "crwdns51621:0$t({{idName}})crwdne51621:0";
var APPB2C_cj_ieltsReady_description_line16 = "crwdns138834:0crwdne138834:0";
var APPB2C_cj_ieltsReady_description_line26 = "crwdns138836:0crwdne138836:0";
var APPB2C_cj_ieltsReady_dobMin6 = "crwdns139856:0crwdne139856:0";
var APPB2C_cj_ieltsReady_nameWarning6 = "crwdns132002:0crwdne132002:0";
var APPB2C_cj_ieltsReady_note6 = "crwdns138840:0crwdne138840:0";
var APPB2C_cj_ieltsReady_personalDetails_title5 = "crwdns52003:0crwdne52003:0";
var APPB2C_cj_ieltsReady_submitBtnText6 = "crwdns52005:0crwdne52005:0";
var APPB2C_cj_ieltsReady_targetScore_option6 = "crwdns130970:0crwdne130970:0";
var APPB2C_cj_ieltsReady_targetScore_title6 = "crwdns130972:0crwdne130972:0";
var APPB2C_cj_ieltsReady_title6 = "crwdns51999:0crwdne51999:0";
var APPB2C_cj_incorrectOrganisation_bookNewLink6 = "crwdns83785:0crwdne83785:0";
var APPB2C_cj_incorrectOrganisation_line16 = "crwdns83787:0crwdne83787:0";
var APPB2C_cj_incorrectOrganisation_line26 = "crwdns83789:0crwdne83789:0";
var APPB2C_cj_incorrectOrganisation_locationsLink6 = "crwdns83791:0crwdne83791:0";
var APPB2C_cj_incorrectOrganisation_locationsLinkUsa6 = "crwdns83793:0crwdne83793:0";
var APPB2C_cj_incorrectOrganisation_title6 = "crwdns83795:0crwdne83795:0";
var APPB2C_cj_marketingPrefs6 = "crwdns51581:0crwdne51581:0";
var APPB2C_cj_onRequest_created_back6 = "crwdns83797:0crwdne83797:0";
var APPB2C_cj_onRequest_created_in6 = "crwdns83799:0crwdne83799:0";
var APPB2C_cj_onRequest_created_info6 = "crwdns83801:0crwdne83801:0";
var APPB2C_cj_onRequest_created_note6 = "crwdns83803:0crwdne83803:0";
var APPB2C_cj_onRequest_created_title6 = "crwdns83805:0crwdne83805:0";
var APPB2C_cj_onRequest_form_labels_confirmEmail6 = "crwdns83807:0crwdne83807:0";
var APPB2C_cj_onRequest_form_labels_email6 = "crwdns83809:0crwdne83809:0";
var APPB2C_cj_onRequest_form_labels_firstname6 = "crwdns83811:0crwdne83811:0";
var APPB2C_cj_onRequest_form_labels_surname6 = "crwdns83813:0crwdne83813:0";
var APPB2C_cj_onRequest_form_labels_telephone6 = "crwdns83815:0crwdne83815:0";
var APPB2C_cj_onRequest_form_labels_testRangeQuestion6 = "crwdns83817:0crwdne83817:0";
var APPB2C_cj_onRequest_form_submit6 = "crwdns83819:0crwdne83819:0";
var APPB2C_cj_onRequest_form_testRangeOpt_oneMonth6 = "crwdns83821:0crwdne83821:0";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonths6 = "crwdns83823:0crwdne83823:0";
var APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus6 = "crwdns83825:0crwdne83825:0";
var APPB2C_cj_onRequest_form_testRangeOpt_twoMonths6 = "crwdns83827:0crwdne83827:0";
var APPB2C_cj_onRequest_info16 = "crwdns83829:0crwdne83829:0";
var APPB2C_cj_onRequest_note6 = "crwdns83831:0crwdne83831:0";
var APPB2C_cj_onRequest_otherLocations_choose6 = "crwdns83833:0crwdne83833:0";
var APPB2C_cj_onRequest_otherLocations_hide6 = "crwdns83835:0crwdne83835:0";
var APPB2C_cj_onRequest_otherLocations_info16 = "crwdns83837:0crwdne83837:0";
var APPB2C_cj_onRequest_otherLocations_info26 = "crwdns83839:0crwdne83839:0";
var APPB2C_cj_onRequest_otherLocations_show6 = "crwdns83841:0crwdne83841:0";
var APPB2C_cj_onRequest_please6 = "crwdns83843:0crwdne83843:0";
var APPB2C_cj_onRequest_prefNote6 = "crwdns83845:0crwdne83845:0";
var APPB2C_cj_onRequest_subtitles_aboutPreferences6 = "crwdns83847:0crwdne83847:0";
var APPB2C_cj_onRequest_subtitles_aboutYou6 = "crwdns83849:0crwdne83849:0";
var APPB2C_cj_onRequest_subtitles_markpref6 = "crwdns83851:0crwdne83851:0";
var APPB2C_cj_onRequest_subtitles_register6 = "crwdns83853:0crwdne83853:0";
var APPB2C_cj_personalDetails_error_doubleBooking6 = "crwdns137352:0crwdne137352:0";
var APPB2C_cj_personalDetails_error_missingAddress6 = "crwdns137354:0crwdne137354:0";
var APPB2C_cj_personalDetails_error_missingEmail6 = "crwdns137356:0crwdne137356:0";
var APPB2C_cj_personalDetails_error_missingEmailConfirm6 = "crwdns137358:0crwdne137358:0";
var APPB2C_cj_personalDetails_error_missingFirstName6 = "crwdns137360:0crwdne137360:0";
var APPB2C_cj_personalDetails_error_missingMobile6 = "crwdns152564:0crwdne152564:0";
var APPB2C_cj_personalDetails_error_missingSex6 = "crwdns137362:0crwdne137362:0";
var APPB2C_cj_personalDetails_error_missingState6 = "crwdns137364:0crwdne137364:0";
var APPB2C_cj_personalDetails_error_missingSurname6 = "crwdns137366:0crwdne137366:0";
var APPB2C_cj_personalDetails_error_missingTown6 = "crwdns152566:0crwdne152566:0";
var APPB2C_cj_personalDetails_error_missingZip6 = "crwdns137368:0crwdne137368:0";
var APPB2C_cj_personalDetails_error_wrongDob6 = "crwdns137370:0crwdne137370:0";
var APPB2C_cj_personalDetails_error_wrongEmailConfirm6 = "crwdns137372:0crwdne137372:0";
var APPB2C_cj_personalDetails_examTakenModal_cta6 = "crwdns51547:0crwdne51547:0";
var APPB2C_cj_personalDetails_examTakenModal_message6 = "crwdns51545:0crwdne51545:0";
var APPB2C_cj_personalDetails_examTakenModal_title6 = "crwdns83855:0crwdne83855:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse6 = "crwdns51565:0crwdne51565:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate6 = "crwdns51563:0crwdne51563:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle6 = "crwdns51551:0crwdne51551:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_title6 = "crwdns51549:0crwdne51549:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking6 = "crwdns51559:0crwdne51559:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking6 = "crwdns51561:0crwdne51561:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest6 = "crwdns51557:0crwdne51557:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest6 = "crwdns51555:0crwdne51555:0";
var APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest6 = "crwdns51553:0crwdne51553:0";
var APPB2C_cj_personalDetails_forms_address_country6 = "crwdns51531:0crwdne51531:0";
var APPB2C_cj_personalDetails_forms_address_enterManually6 = "crwdns51541:0crwdne51541:0";
var APPB2C_cj_personalDetails_forms_address_postal6 = "crwdns51533:0crwdne51533:0";
var APPB2C_cj_personalDetails_forms_address_state6 = "crwdns51539:0crwdne51539:0";
var APPB2C_cj_personalDetails_forms_address_town6 = "crwdns51535:0crwdne51535:0";
var APPB2C_cj_personalDetails_forms_address_zip6 = "crwdns51537:0crwdne51537:0";
var APPB2C_cj_personalDetails_forms_contact_confirmEmail6 = "crwdns51521:0crwdne51521:0";
var APPB2C_cj_personalDetails_forms_contact_email6 = "crwdns51517:0crwdne51517:0";
var APPB2C_cj_personalDetails_forms_contact_emailExist6 = "crwdns51529:0crwdne51529:0";
var APPB2C_cj_personalDetails_forms_contact_emailInUse6 = "crwdns51519:0crwdne51519:0";
var APPB2C_cj_personalDetails_forms_contact_mobile6 = "crwdns51523:0crwdne51523:0";
var APPB2C_cj_personalDetails_forms_contact_note6 = "crwdns51515:0crwdne51515:0";
var APPB2C_cj_personalDetails_forms_contact_password6 = "crwdns51527:0crwdne51527:0";
var APPB2C_cj_personalDetails_forms_contact_smsConsent6 = "crwdns131494:0crwdne131494:0";
var APPB2C_cj_personalDetails_forms_contact_smsNote6 = "crwdns131496:0crwdne131496:0";
var APPB2C_cj_personalDetails_forms_contact_title6 = "crwdns51525:0crwdne51525:0";
var APPB2C_cj_personalDetails_forms_personal_change6 = "crwdns51509:0crwdne51509:0";
var APPB2C_cj_personalDetails_forms_personal_dob6 = "crwdns51505:0crwdne51505:0";
var APPB2C_cj_personalDetails_forms_personal_dobCta6 = "crwdns51513:0crwdne51513:0";
var APPB2C_cj_personalDetails_forms_personal_firstMiddleName6 = "crwdns51497:0crwdne51497:0";
var APPB2C_cj_personalDetails_forms_personal_firstName6 = "crwdns51495:0crwdne51495:0";
var APPB2C_cj_personalDetails_forms_personal_idMatch6 = "crwdns51503:0crwdne51503:0";
var APPB2C_cj_personalDetails_forms_personal_lastName6 = "crwdns51499:0crwdne51499:0";
var APPB2C_cj_personalDetails_forms_personal_name6 = "crwdns51511:0crwdne51511:0";
var APPB2C_cj_personalDetails_forms_personal_noLastName6 = "crwdns51501:0crwdne51501:0";
var APPB2C_cj_personalDetails_forms_personal_sectionTitle6 = "crwdns51507:0crwdne51507:0";
var APPB2C_cj_personalDetails_forWho_child6 = "crwdns51463:0crwdne51463:0";
var APPB2C_cj_personalDetails_forWho_childUnder186 = "crwdns51465:0crwdne51465:0";
var APPB2C_cj_personalDetails_forWho_dob6 = "crwdns51471:0crwdne51471:0";
var APPB2C_cj_personalDetails_forWho_myself6 = "crwdns51461:0crwdne51461:0";
var APPB2C_cj_personalDetails_forWho_note6 = "crwdns51469:0crwdne51469:0";
var APPB2C_cj_personalDetails_forWho_title6 = "crwdns51467:0crwdne51467:0";
var APPB2C_cj_personalDetails_gender6 = "crwdns51459:0crwdne51459:0";
var APPB2C_cj_personalDetails_idNote_idMatch6 = "crwdns153957:0crwdne153957:0";
var APPB2C_cj_personalDetails_idNote_idMatch_change6 = "crwdns132004:0crwdne132004:0";
var APPB2C_cj_personalDetails_idNote_idMatch_france5 = "crwdns126198:0crwdne126198:0";
var APPB2C_cj_personalDetails_idNote_idMatch_france_adult5 = "crwdns130608:0crwdne130608:0";
var APPB2C_cj_personalDetails_idNote_idMatch_france_minor5 = "crwdns130610:0crwdne130610:0";
var APPB2C_cj_personalDetails_idNote_idMatch_spain5 = "crwdns126200:0crwdne126200:0";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_adult5 = "crwdns130612:0crwdne130612:0";
var APPB2C_cj_personalDetails_idNote_idMatch_spain_minor5 = "crwdns130614:0crwdne130614:0";
var APPB2C_cj_personalDetails_idNote_whenForChild6 = "crwdns51491:0crwdne51491:0";
var APPB2C_cj_personalDetails_login_btn6 = "crwdns51489:0crwdne51489:0";
var APPB2C_cj_personalDetails_login_emailNotEditable6 = "crwdns137374:0crwdne137374:0";
var APPB2C_cj_personalDetails_login_link6 = "crwdns137376:0crwdne137376:0";
var APPB2C_cj_personalDetails_login_loggedIn6 = "crwdns51479:0crwdne51479:0";
var APPB2C_cj_personalDetails_login_logout6 = "crwdns137378:0crwdne137378:0";
var APPB2C_cj_personalDetails_login_note6 = "crwdns51481:0crwdne51481:0";
var APPB2C_cj_personalDetails_login_title6 = "crwdns51477:0crwdne51477:0";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate6 = "crwdns130974:0crwdne130974:0";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line16 = "crwdns130976:0{{nearestRegistratonExamDate}}crwdne130976:0";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line26 = "crwdns130978:0crwdne130978:0";
var APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title6 = "crwdns130980:0crwdne130980:0";
var APPB2C_cj_personalDetails_title6 = "crwdns51457:0crwdne51457:0";
var APPB2C_cj_personalDetails_tncs_agreement6 = "crwdns51475:0crwdne51475:0";
var APPB2C_cj_personalDetails_tncs_title6 = "crwdns51473:0crwdne51473:0";
var APPB2C_cj_personalDetails_visaType_label6 = "crwdns154213:0crwdne154213:0";
var APPB2C_cj_personalDetails_visaType_label_child6 = "crwdns154215:0crwdne154215:0";
var APPB2C_cj_personalDetails_visaType_link6 = "crwdns154217:0crwdne154217:0";
var APPB2C_cj_personalDetails_visaType_title6 = "crwdns154219:0crwdne154219:0";
var APPB2C_cj_personalDetails_visaType_title_child6 = "crwdns154221:0crwdne154221:0";
var APPB2C_cj_rebook_errorModal_message6 = "crwdns51455:0crwdne51455:0";
var APPB2C_cj_rebook_errorModal_title6 = "crwdns51453:0crwdne51453:0";
var APPB2C_cj_review_acknowledgement_anotherPerson6 = "crwdns51835:0crwdne51835:0";
var APPB2C_cj_review_acknowledgement_company6 = "crwdns51837:0crwdne51837:0";
var APPB2C_cj_review_acknowledgement_fields_companyName6 = "crwdns51839:0crwdne51839:0";
var APPB2C_cj_review_acknowledgement_fields_companyName_subnote6 = "crwdns132006:0crwdne132006:0";
var APPB2C_cj_review_acknowledgement_fields_country6 = "crwdns51849:0crwdne51849:0";
var APPB2C_cj_review_acknowledgement_fields_email6 = "crwdns51845:0crwdne51845:0";
var APPB2C_cj_review_acknowledgement_fields_familyName6 = "crwdns51843:0crwdne51843:0";
var APPB2C_cj_review_acknowledgement_fields_firstName6 = "crwdns51841:0crwdne51841:0";
var APPB2C_cj_review_acknowledgement_fields_mobile6 = "crwdns51847:0crwdne51847:0";
var APPB2C_cj_review_acknowledgement_myself6 = "crwdns51833:0crwdne51833:0";
var APPB2C_cj_review_acknowledgement_send6 = "crwdns51831:0crwdne51831:0";
var APPB2C_cj_review_acknowledgement_title6 = "crwdns51829:0crwdne51829:0";
var APPB2C_cj_review_agent_label6 = "crwdns51859:0crwdne51859:0";
var APPB2C_cj_review_agent_note6 = "crwdns51857:0crwdne51857:0";
var APPB2C_cj_review_agent_title6 = "crwdns51855:0crwdne51855:0";
var APPB2C_cj_review_ai_note6 = "crwdns51853:0crwdne51853:0";
var APPB2C_cj_review_ai_title6 = "crwdns51851:0crwdne51851:0";
var APPB2C_cj_review_bookingDetails_lrw_title6 = "crwdns51753:0crwdne51753:0";
var APPB2C_cj_review_bookingDetails_lrw_title_child6 = "crwdns51757:0crwdne51757:0";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills6 = "crwdns51755:0crwdne51755:0";
var APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child6 = "crwdns51759:0crwdne51759:0";
var APPB2C_cj_review_bookingDetails_speaking_title6 = "crwdns51761:0crwdne51761:0";
var APPB2C_cj_review_bookingDetails_speaking_title_child6 = "crwdns51763:0crwdne51763:0";
var APPB2C_cj_review_bookingDetails_title6 = "crwdns51751:0crwdne51751:0";
var APPB2C_cj_review_bookNowNoFee_buttonText6 = "crwdns125788:0crwdne125788:0";
var APPB2C_cj_review_bookTest6 = "crwdns51739:0crwdne51739:0";
var APPB2C_cj_review_change6 = "crwdns51727:0crwdne51727:0";
var APPB2C_cj_review_changeLrwTest6 = "crwdns139876:0crwdne139876:0";
var APPB2C_cj_review_changeSpeakingTest6 = "crwdns153920:0crwdne153920:0";
var APPB2C_cj_review_changeUkviLsTest6 = "crwdns148092:0crwdne148092:0";
var APPB2C_cj_review_childDetails_guardiansTitle6 = "crwdns51783:0crwdne51783:0";
var APPB2C_cj_review_childDetails_onTestDay6 = "crwdns51781:0crwdne51781:0";
var APPB2C_cj_review_childDetails_onTestDayOptions_16 = "crwdns51785:0crwdne51785:0";
var APPB2C_cj_review_childDetails_onTestDayOptions_26 = "crwdns51787:0crwdne51787:0";
var APPB2C_cj_review_childDetails_onTestDayOptions_36 = "crwdns51789:0crwdne51789:0";
var APPB2C_cj_review_childDetails_title6 = "crwdns51779:0crwdne51779:0";
var APPB2C_cj_review_confirmPaymentModal_btnNote6 = "crwdns137380:0crwdne137380:0";
var APPB2C_cj_review_confirmPaymentModal_note6 = "crwdns125577:0crwdne125577:0";
var APPB2C_cj_review_confirmPaymentModal_optionsTitle6 = "crwdns125579:0crwdne125579:0";
var APPB2C_cj_review_confirmPaymentModal_title6 = "crwdns125581:0crwdne125581:0";
var APPB2C_cj_review_countryMsg_pakistan6 = "crwdns51865:0crwdne51865:0";
var APPB2C_cj_review_error_missingOrderAcknowledgement6 = "crwdns137382:0crwdne137382:0";
var APPB2C_cj_review_error_missingPickups6 = "crwdns137384:0crwdne137384:0";
var APPB2C_cj_review_error_missingTown6 = "crwdns137386:0crwdne137386:0";
var APPB2C_cj_review_error_wrongCountrySetting6 = "crwdns152892:0crwdne152892:0";
var APPB2C_cj_review_error_wrongPaymentConfig6 = "crwdns152568:0crwdne152568:0";
var APPB2C_cj_review_error_wrongTaxNumber6 = "crwdns137390:0crwdne137390:0";
var APPB2C_cj_review_expiredIdError6 = "crwdns131272:0crwdne131272:0";
var APPB2C_cj_review_idDetails_issuing6 = "crwdns51795:0crwdne51795:0";
var APPB2C_cj_review_idDetails_nationality6 = "crwdns51797:0crwdne51797:0";
var APPB2C_cj_review_idDetails_title6 = "crwdns51791:0crwdne51791:0";
var APPB2C_cj_review_idDetails_title_child6 = "crwdns51793:0crwdne51793:0";
var APPB2C_cj_review_invoice_companyName6 = "crwdns131498:0crwdne131498:0";
var APPB2C_cj_review_invoice_consent6 = "crwdns131500:0crwdne131500:0";
var APPB2C_cj_review_invoice_send6 = "crwdns131502:0crwdne131502:0";
var APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate6 = "crwdns131504:0crwdne131504:0";
var APPB2C_cj_review_invoice_subtype_label6 = "crwdns131506:0crwdne131506:0";
var APPB2C_cj_review_invoice_subtype_mobileBarcode6 = "crwdns131508:0crwdne131508:0";
var APPB2C_cj_review_invoice_taxCaption_donate6 = "crwdns131510:0crwdne131510:0";
var APPB2C_cj_review_invoice_taxCaption_duplicatePaper6 = "crwdns131512:0crwdne131512:0";
var APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate6 = "crwdns131514:0crwdne131514:0";
var APPB2C_cj_review_invoice_taxValidation_donate6 = "crwdns131516:0crwdne131516:0";
var APPB2C_cj_review_invoice_taxValidation_mobileBarcode6 = "crwdns131518:0crwdne131518:0";
var APPB2C_cj_review_invoice_taxValidation_triplicate6 = "crwdns131520:0crwdne131520:0";
var APPB2C_cj_review_invoice_title6 = "crwdns131522:0crwdne131522:0";
var APPB2C_cj_review_invoice_type_donate6 = "crwdns131524:0crwdne131524:0";
var APPB2C_cj_review_invoice_type_duplicateElectronic6 = "crwdns131526:0crwdne131526:0";
var APPB2C_cj_review_invoice_type_duplicatePaper6 = "crwdns131528:0crwdne131528:0";
var APPB2C_cj_review_invoice_type_triplicate6 = "crwdns131530:0crwdne131530:0";
var APPB2C_cj_review_localization_localTime6 = "crwdns51747:0crwdne51747:0";
var APPB2C_cj_review_localization_location6 = "crwdns51745:0crwdne51745:0";
var APPB2C_cj_review_localization_timezone6 = "crwdns51749:0crwdne51749:0";
var APPB2C_cj_review_missingMarketingCta6 = "crwdns51721:0crwdne51721:0";
var APPB2C_cj_review_missingMarketingError6 = "crwdns51719:0crwdne51719:0";
var APPB2C_cj_review_note_content6 = "crwdns51715:0crwdne51715:0";
var APPB2C_cj_review_note_title6 = "crwdns51713:0crwdne51713:0";
var APPB2C_cj_review_or6 = "crwdns51733:0crwdne51733:0";
var APPB2C_cj_review_payLater_buttonText6 = "crwdns137392:0crwdne137392:0";
var APPB2C_cj_review_payLater_sideNote6 = "crwdns51737:0crwdne51737:0";
var APPB2C_cj_review_payment_fee6 = "crwdns51801:0crwdne51801:0";
var APPB2C_cj_review_payment_promoCodes_applied6 = "crwdns125790:0crwdne125790:0";
var APPB2C_cj_review_payment_promoCodes_applyCode6 = "crwdns51815:0crwdne51815:0";
var APPB2C_cj_review_payment_promoCodes_discount6 = "crwdns51817:0crwdne51817:0";
var APPB2C_cj_review_payment_promoCodes_generalError6 = "crwdns126202:0crwdne126202:0";
var APPB2C_cj_review_payment_promoCodes_haveCode6 = "crwdns51809:0crwdne51809:0";
var APPB2C_cj_review_payment_promoCodes_invalidCode6 = "crwdns126204:0crwdne126204:0";
var APPB2C_cj_review_payment_promoCodes_newFee6 = "crwdns51823:0crwdne51823:0";
var APPB2C_cj_review_payment_promoCodes_notHaveCode6 = "crwdns51811:0crwdne51811:0";
var APPB2C_cj_review_payment_promoCodes_promo6 = "crwdns51819:0crwdne51819:0";
var APPB2C_cj_review_payment_promoCodes_promoCode6 = "crwdns51807:0crwdne51807:0";
var APPB2C_cj_review_payment_promoCodes_remove6 = "crwdns125794:0crwdne125794:0";
var APPB2C_cj_review_payment_promoCodes_removeCode6 = "crwdns51813:0crwdne51813:0";
var APPB2C_cj_review_payment_promoCodes_total6 = "crwdns51821:0crwdne51821:0";
var APPB2C_cj_review_payment_tax6 = "crwdns51805:0crwdne51805:0";
var APPB2C_cj_review_payment_title6 = "crwdns51799:0crwdne51799:0";
var APPB2C_cj_review_payment_total6 = "crwdns51803:0crwdne51803:0";
var APPB2C_cj_review_paymentError6 = "crwdns51725:0crwdne51725:0";
var APPB2C_cj_review_paymentNote6 = "crwdns137394:0crwdne137394:0";
var APPB2C_cj_review_paymentNoteMultiple5 = "crwdns51743:0crwdne51743:0";
var APPB2C_cj_review_paymentNoteSingle5 = "crwdns51741:0crwdne51741:0";
var APPB2C_cj_review_paymentNoteZeroFee6 = "crwdns137396:0crwdne137396:0";
var APPB2C_cj_review_payOnline_buttonText6 = "crwdns137398:0crwdne137398:0";
var APPB2C_cj_review_payOnline_sideNote6 = "crwdns137400:0crwdne137400:0";
var APPB2C_cj_review_personalDetails_address6 = "crwdns51775:0crwdne51775:0";
var APPB2C_cj_review_personalDetails_dob6 = "crwdns51769:0crwdne51769:0";
var APPB2C_cj_review_personalDetails_email6 = "crwdns51771:0crwdne51771:0";
var APPB2C_cj_review_personalDetails_gender6 = "crwdns51777:0crwdne51777:0";
var APPB2C_cj_review_personalDetails_name6 = "crwdns51767:0crwdne51767:0";
var APPB2C_cj_review_personalDetails_phone6 = "crwdns51773:0crwdne51773:0";
var APPB2C_cj_review_personalDetails_smsNotificationsConsent6 = "crwdns131532:0crwdne131532:0";
var APPB2C_cj_review_personalDetails_title6 = "crwdns51765:0crwdne51765:0";
var APPB2C_cj_review_registrationError6 = "crwdns51717:0crwdne51717:0";
var APPB2C_cj_review_registrationFailedModal_changedFee_btn6 = "crwdns148788:0crwdne148788:0";
var APPB2C_cj_review_registrationFailedModal_changedFee_line16 = "crwdns148790:0crwdne148790:0";
var APPB2C_cj_review_registrationFailedModal_changedFee_line26 = "crwdns148792:0crwdne148792:0";
var APPB2C_cj_review_registrationFailedModal_changedFee_line36 = "crwdns148794:0crwdne148794:0";
var APPB2C_cj_review_registrationFailedModal_changedFee_title6 = "crwdns148796:0crwdne148796:0";
var APPB2C_cj_review_registrationFailedModal_cta6 = "crwdns51871:0crwdne51871:0";
var APPB2C_cj_review_registrationFailedModal_message6 = "crwdns51869:0crwdne51869:0";
var APPB2C_cj_review_registrationFailedModal_title6 = "crwdns51867:0crwdne51867:0";
var APPB2C_cj_review_taxNumberError6 = "crwdns137402:0crwdne137402:0";
var APPB2C_cj_review_title6 = "crwdns51711:0crwdne51711:0";
var APPB2C_cj_review_tncs_agreement6 = "crwdns51863:0crwdne51863:0";
var APPB2C_cj_review_tncs_title6 = "crwdns51861:0crwdne51861:0";
var APPB2C_cj_review_tooHighPromoCodeError6 = "crwdns130616:0crwdne130616:0";
var APPB2C_cj_timer_modal_cta6 = "crwdns51451:0crwdne51451:0";
var APPB2C_cj_timer_modal_message6 = "crwdns51449:0crwdne51449:0";
var APPB2C_cj_timer_modal_title6 = "crwdns51447:0crwdne51447:0";
var APPB2C_cj_timer_nearEnd_minuteCount6 = "crwdns51443:0{{count}}crwdne51443:0";
var APPB2C_cj_timer_nearEnd_minuteCount_other6 = "crwdns51445:0{{count}}crwdne51445:0";
var APPB2C_cj_timer_nearEnd_text6 = 'crwdns51441:0$t(APPB2C.cj.timer.nearEnd.minuteCount, {"count": {{count}} })crwdne51441:0';
var APPB2C_cj_timer_nearEnd_title6 = "crwdns51439:0crwdne51439:0";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied6 = "crwdns51707:0crwdne51707:0";
var APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child6 = "crwdns51709:0crwdne51709:0";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest6 = "crwdns51703:0crwdne51703:0";
var APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child6 = "crwdns51705:0crwdne51705:0";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle6 = "crwdns51699:0crwdne51699:0";
var APPB2C_cj_ttProfile_forms_interest_sectionTitle_child6 = "crwdns51701:0crwdne51701:0";
var APPB2C_cj_ttProfile_forms_occupation_occupationLevel6 = "crwdns51695:0crwdne51695:0";
var APPB2C_cj_ttProfile_forms_occupation_occupationSector6 = "crwdns51697:0crwdne51697:0";
var APPB2C_cj_ttProfile_forms_occupation_sectionTitle6 = "crwdns51693:0crwdne51693:0";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality6 = "crwdns51667:0crwdne51667:0";
var APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child6 = "crwdns51669:0crwdne51669:0";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3846 = "crwdns51683:0crwdne51683:0";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3856 = "crwdns51685:0crwdne51685:0";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3866 = "crwdns51687:0crwdne51687:0";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_3876 = "crwdns51689:0crwdne51689:0";
var APPB2C_cj_ttProfile_forms_personal_educationLevel_title6 = "crwdns51691:0crwdne51691:0";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken6 = "crwdns51671:0crwdne51671:0";
var APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child6 = "crwdns51673:0crwdne51673:0";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle6 = "crwdns51663:0crwdne51663:0";
var APPB2C_cj_ttProfile_forms_personal_sectionTitle_child6 = "crwdns51665:0crwdne51665:0";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish6 = "crwdns51675:0crwdne51675:0";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child6 = "crwdns51677:0crwdne51677:0";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel6 = "crwdns51679:0crwdne51679:0";
var APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child6 = "crwdns51681:0crwdne51681:0";
var APPB2C_cj_ttProfile_note6 = "crwdns51659:0crwdne51659:0";
var APPB2C_cj_ttProfile_note_child6 = "crwdns51661:0crwdne51661:0";
var APPB2C_cj_ttProfile_title6 = "crwdns51655:0crwdne51655:0";
var APPB2C_cj_ttProfile_title_child6 = "crwdns51657:0crwdne51657:0";
var APPB2C_cj_ukviStartPage_ac_content6 = "crwdns51963:0crwdne51963:0";
var APPB2C_cj_ukviStartPage_ac_title6 = "crwdns51961:0crwdne51961:0";
var APPB2C_cj_ukviStartPage_gt_content6 = "crwdns51967:0crwdne51967:0";
var APPB2C_cj_ukviStartPage_gt_title6 = "crwdns51965:0crwdne51965:0";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_content6 = "crwdns51985:0crwdne51985:0";
var APPB2C_cj_ukviStartPage_lifeSkils_a1_title6 = "crwdns51983:0crwdne51983:0";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_content6 = "crwdns51989:0crwdne51989:0";
var APPB2C_cj_ukviStartPage_lifeSkils_a2_title6 = "crwdns51987:0crwdne51987:0";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_content6 = "crwdns51993:0crwdne51993:0";
var APPB2C_cj_ukviStartPage_lifeSkils_b1_title6 = "crwdns51991:0crwdne51991:0";
var APPB2C_cj_ukviStartPage_lifeSkils_citizenship6 = "crwdns51977:0crwdne51977:0";
var APPB2C_cj_ukviStartPage_lifeSkils_extension6 = "crwdns51975:0crwdne51975:0";
var APPB2C_cj_ukviStartPage_lifeSkils_family6 = "crwdns51973:0crwdne51973:0";
var APPB2C_cj_ukviStartPage_lifeSkils_note_content6 = "crwdns51981:0crwdne51981:0";
var APPB2C_cj_ukviStartPage_lifeSkils_note_title6 = "crwdns51979:0crwdne51979:0";
var APPB2C_cj_ukviStartPage_lifeSkils_title6 = "crwdns51969:0crwdne51969:0";
var APPB2C_cj_ukviStartPage_lifeSkils_visaTypes6 = "crwdns51971:0crwdne51971:0";
var APPB2C_cj_ukviStartPage_note1_content6 = "crwdns51957:0crwdne51957:0";
var APPB2C_cj_ukviStartPage_note1_title6 = "crwdns51955:0crwdne51955:0";
var APPB2C_cj_ukviStartPage_terms6 = "crwdns51997:0crwdne51997:0";
var APPB2C_cj_ukviStartPage_testsBelow6 = "crwdns51959:0crwdne51959:0";
var APPB2C_cj_ukviStartPage_title6 = "crwdns51953:0crwdne51953:0";
var APPB2C_cj_ukviStartPage_whichUkvi6 = "crwdns51995:0crwdne51995:0";
var APPB2C_common_account_details_email_cancel5 = "crwdns52401:0crwdne52401:0";
var APPB2C_common_account_details_email_confirm6 = "crwdns52395:0crwdne52395:0";
var APPB2C_common_account_details_email_label6 = "crwdns52391:0crwdne52391:0";
var APPB2C_common_account_details_email_maxLength6 = "crwdns52403:0crwdne52403:0";
var APPB2C_common_account_details_email_mismatch6 = "crwdns52405:0crwdne52405:0";
var APPB2C_common_account_details_email_note6 = "crwdns52387:0crwdne52387:0";
var APPB2C_common_account_details_email_save6 = "crwdns52397:0crwdne52397:0";
var APPB2C_common_account_details_email_set6 = "crwdns52393:0crwdne52393:0";
var APPB2C_common_account_details_email_success6 = "crwdns52399:0crwdne52399:0";
var APPB2C_common_account_details_email_username6 = "crwdns52389:0crwdne52389:0";
var APPB2C_common_account_details_email_validEmail6 = "crwdns152570:0crwdne152570:0";
var APPB2C_common_account_details_name6 = "crwdns52385:0crwdne52385:0";
var APPB2C_common_account_details_psw_cancel5 = "crwdns52421:0crwdne52421:0";
var APPB2C_common_account_details_psw_change6 = "crwdns52411:0crwdne52411:0";
var APPB2C_common_account_details_psw_label6 = "crwdns52409:0crwdne52409:0";
var APPB2C_common_account_details_psw_new6 = "crwdns52417:0crwdne52417:0";
var APPB2C_common_account_details_psw_old6 = "crwdns52415:0crwdne52415:0";
var APPB2C_common_account_details_psw_requirements_levels_06 = "crwdns52423:0crwdne52423:0";
var APPB2C_common_account_details_psw_requirements_levels_16 = "crwdns52425:0crwdne52425:0";
var APPB2C_common_account_details_psw_requirements_levels_26 = "crwdns52427:0crwdne52427:0";
var APPB2C_common_account_details_psw_requirements_levels_36 = "crwdns52429:0crwdne52429:0";
var APPB2C_common_account_details_psw_requirements_levels_46 = "crwdns52431:0crwdne52431:0";
var APPB2C_common_account_details_psw_requirements_maxLength6 = "crwdns52451:0crwdne52451:0";
var APPB2C_common_account_details_psw_requirements_mustHave6 = "crwdns52435:0crwdne52435:0";
var APPB2C_common_account_details_psw_requirements_ok6 = "crwdns52433:0crwdne52433:0";
var APPB2C_common_account_details_psw_requirements_reqs_length6 = "crwdns52441:0crwdne52441:0";
var APPB2C_common_account_details_psw_requirements_reqs_lower6 = "crwdns52443:0crwdne52443:0";
var APPB2C_common_account_details_psw_requirements_reqs_number6 = "crwdns52447:0crwdne52447:0";
var APPB2C_common_account_details_psw_requirements_reqs_special6 = "crwdns52449:0crwdne52449:0";
var APPB2C_common_account_details_psw_requirements_reqs_upper6 = "crwdns52445:0crwdne52445:0";
var APPB2C_common_account_details_psw_requirements_shouldHave6 = "crwdns52437:0crwdne52437:0";
var APPB2C_common_account_details_psw_requirements_strength6 = "crwdns52439:0crwdne52439:0";
var APPB2C_common_account_details_psw_requirements_tooWeak6 = "crwdns52453:0crwdne52453:0";
var APPB2C_common_account_details_psw_show6 = "crwdns52413:0crwdne52413:0";
var APPB2C_common_account_details_psw_success6 = "crwdns52419:0crwdne52419:0";
var APPB2C_common_account_details_subnote_address6 = "crwdns132008:0crwdne132008:0";
var APPB2C_common_account_details_subnote_city6 = "crwdns132010:0crwdne132010:0";
var APPB2C_common_account_error6 = "crwdns52383:0crwdne52383:0";
var APPB2C_common_account_marketing_success6 = "crwdns52457:0crwdne52457:0";
var APPB2C_common_account_marketing_title6 = "crwdns52455:0crwdne52455:0";
var APPB2C_common_account_smsNotificationSettings_mobileLabel6 = "crwdns131534:0crwdne131534:0";
var APPB2C_common_account_smsNotificationSettings_success6 = "crwdns131536:0crwdne131536:0";
var APPB2C_common_account_smsNotificationSettings_title6 = "crwdns131538:0crwdne131538:0";
var APPB2C_common_account_terms_cta6 = "crwdns52477:0crwdne52477:0";
var APPB2C_common_account_terms_disclaimer6 = "crwdns52461:0crwdne52461:0";
var APPB2C_common_account_terms_download6 = "crwdns52473:0crwdne52473:0";
var APPB2C_common_account_terms_extra_line16 = "crwdns52465:0crwdne52465:0";
var APPB2C_common_account_terms_extra_line26 = "crwdns52467:0crwdne52467:0";
var APPB2C_common_account_terms_extra_line3_16 = "crwdns52469:0crwdne52469:0";
var APPB2C_common_account_terms_extra_line3_26 = "crwdns52471:0crwdne52471:0";
var APPB2C_common_account_terms_extra_title6 = "crwdns52463:0crwdne52463:0";
var APPB2C_common_account_terms_previous6 = "crwdns52475:0crwdne52475:0";
var APPB2C_common_account_terms_title6 = "crwdns52459:0crwdne52459:0";
var APPB2C_common_account_title6 = "crwdns52381:0crwdne52381:0";
var APPB2C_common_alertBar_afterTest6 = "crwdns53223:0crwdne53223:0";
var APPB2C_common_alertBar_beforeTest6 = "crwdns53219:0crwdne53219:0";
var APPB2C_common_alertBar_cancelReupload6 = "crwdns53241:0crwdne53241:0";
var APPB2C_common_alertBar_check6 = "crwdns53245:0crwdne53245:0";
var APPB2C_common_alertBar_childConsentMissing6 = "crwdns53243:0crwdne53243:0";
var APPB2C_common_alertBar_creds6 = "crwdns53247:0crwdne53247:0";
var APPB2C_common_alertBar_eorPayment6 = "crwdns53229:0crwdne53229:0";
var APPB2C_common_alertBar_eorPaymentProof6 = "crwdns53231:0crwdne53231:0";
var APPB2C_common_alertBar_idReupload6 = "crwdns53235:0crwdne53235:0";
var APPB2C_common_alertBar_idUpload6 = "crwdns53233:0crwdne53233:0";
var APPB2C_common_alertBar_medicalReupload6 = "crwdns53239:0crwdne53239:0";
var APPB2C_common_alertBar_medicalUpload6 = "crwdns53237:0crwdne53237:0";
var APPB2C_common_alertBar_regPayment6 = "crwdns53225:0crwdne53225:0";
var APPB2C_common_alertBar_regPaymentProof6 = "crwdns53227:0crwdne53227:0";
var APPB2C_common_alertBar_withCreds6 = "crwdns53221:0crwdne53221:0";
var APPB2C_common_basic_add6 = "crwdns52153:0crwdne52153:0";
var APPB2C_common_basic_backToTop6 = "crwdns52095:0crwdne52095:0";
var APPB2C_common_basic_bookingDetails_arrival6 = "crwdns52075:0{{time}}crwdne52075:0";
var APPB2C_common_basic_bookingDetails_lifeSkills_results6 = "crwdns52073:0crwdne52073:0";
var APPB2C_common_basic_bookingDetails_lifeSkills_title6 = "crwdns52071:0crwdne52071:0";
var APPB2C_common_basic_bookingDetails_lrw_cd6 = "crwdns52053:0crwdne52053:0";
var APPB2C_common_basic_bookingDetails_lrw_online6 = "crwdns52057:0crwdne52057:0";
var APPB2C_common_basic_bookingDetails_lrw_pb6 = "crwdns52055:0crwdne52055:0";
var APPB2C_common_basic_bookingDetails_lrw_results6 = "crwdns52051:0crwdne52051:0";
var APPB2C_common_basic_bookingDetails_lrw_title6 = "crwdns52049:0crwdne52049:0";
var APPB2C_common_basic_bookingDetails_osrTitle_listening6 = "crwdns83859:0crwdne83859:0";
var APPB2C_common_basic_bookingDetails_osrTitle_reading6 = "crwdns83861:0crwdne83861:0";
var APPB2C_common_basic_bookingDetails_speaking_f2f6 = "crwdns52063:0crwdne52063:0";
var APPB2C_common_basic_bookingDetails_speaking_live6 = "crwdns52065:0crwdne52065:0";
var APPB2C_common_basic_bookingDetails_speaking_online6 = "crwdns52069:0crwdne52069:0";
var APPB2C_common_basic_bookingDetails_speaking_remote6 = "crwdns52067:0crwdne52067:0";
var APPB2C_common_basic_bookingDetails_speaking_results6 = "crwdns52061:0crwdne52061:0";
var APPB2C_common_basic_bookingDetails_speaking_title6 = "crwdns52059:0crwdne52059:0";
var APPB2C_common_basic_bookPrePaid6 = "crwdns148094:0crwdne148094:0";
var APPB2C_common_basic_cancel6 = "crwdns152894:0crwdne152894:0";
var APPB2C_common_basic_change6 = "crwdns52149:0crwdne52149:0";
var APPB2C_common_basic_choose6 = "crwdns52085:0crwdne52085:0";
var APPB2C_common_basic_close6 = "crwdns52101:0crwdne52101:0";
var APPB2C_common_basic_copied6 = "crwdns52105:0crwdne52105:0";
var APPB2C_common_basic_copy6 = "crwdns52103:0crwdne52103:0";
var APPB2C_common_basic_dateField_day6 = "crwdns52041:0crwdne52041:0";
var APPB2C_common_basic_dateField_month6 = "crwdns52045:0crwdne52045:0";
var APPB2C_common_basic_dateField_months6 = "crwdns52043:0crwdne52043:0";
var APPB2C_common_basic_dateField_year6 = "crwdns52047:0crwdne52047:0";
var APPB2C_common_basic_edit6 = "crwdns52151:0crwdne52151:0";
var APPB2C_common_basic_errors_contactTestCentre6 = "crwdns83863:0crwdne83863:0";
var APPB2C_common_basic_errors_network6 = "crwdns52023:0crwdne52023:0";
var APPB2C_common_basic_errors_noTest6 = "crwdns52031:0crwdne52031:0";
var APPB2C_common_basic_errors_server6 = "crwdns52025:0crwdne52025:0";
var APPB2C_common_basic_errors_title6 = "crwdns52021:0crwdne52021:0";
var APPB2C_common_basic_errors_unableToDownloadFile6 = "crwdns52033:0crwdne52033:0";
var APPB2C_common_basic_errors_unknown6 = "crwdns52027:0crwdne52027:0";
var APPB2C_common_basic_expando_collapse6 = "crwdns52015:0crwdne52015:0";
var APPB2C_common_basic_expando_show6 = "crwdns52013:0crwdne52013:0";
var APPB2C_common_basic_externalLinkTitle6 = "crwdns52107:0crwdne52107:0";
var APPB2C_common_basic_failure6 = "crwdns52099:0crwdne52099:0";
var APPB2C_common_basic_forms_addressMaxLength6 = "crwdns152572:0{{count}}crwdne152572:0";
var APPB2C_common_basic_forms_dobMin6 = "crwdns52135:0crwdne52135:0";
var APPB2C_common_basic_forms_dobMinIol6 = "crwdns52137:0crwdne52137:0";
var APPB2C_common_basic_forms_dobOutOfRange6 = "crwdns52133:0crwdne52133:0";
var APPB2C_common_basic_forms_firstNameMaxLength6 = "crwdns152574:0{{count}}crwdne152574:0";
var APPB2C_common_basic_forms_idExpiration6 = "crwdns52121:0crwdne52121:0";
var APPB2C_common_basic_forms_idMaxLength6 = "crwdns52119:0crwdne52119:0";
var APPB2C_common_basic_forms_idNumber6 = "crwdns52117:0crwdne52117:0";
var APPB2C_common_basic_forms_idProofRequired6 = "crwdns52123:0crwdne52123:0";
var APPB2C_common_basic_forms_maxLengthMessage6 = "crwdns52111:0{{count}}crwdne52111:0";
var APPB2C_common_basic_forms_minorDobMax6 = "crwdns52139:0crwdne52139:0";
var APPB2C_common_basic_forms_minorDobMin6 = "crwdns52141:0crwdne52141:0";
var APPB2C_common_basic_forms_mobileBypass6 = "crwdns52127:0crwdne52127:0";
var APPB2C_common_basic_forms_mobileLength6 = "crwdns52125:0crwdne52125:0";
var APPB2C_common_basic_forms_onlyLatin6 = "crwdns52113:0crwdne52113:0";
var APPB2C_common_basic_forms_postCodeMaxLength6 = "crwdns152576:0{{count}}crwdne152576:0";
var APPB2C_common_basic_forms_postCodeMessage6 = "crwdns52143:0crwdne52143:0";
var APPB2C_common_basic_forms_postCodeNotFound6 = "crwdns52145:0crwdne52145:0";
var APPB2C_common_basic_forms_requiredMessage6 = "crwdns52109:0crwdne52109:0";
var APPB2C_common_basic_forms_requiredNoSurmane6 = "crwdns52131:0crwdne52131:0";
var APPB2C_common_basic_forms_stateMaxLength6 = "crwdns152578:0{{count}}crwdne152578:0";
var APPB2C_common_basic_forms_surnameMaxLength6 = "crwdns152580:0{{count}}crwdne152580:0";
var APPB2C_common_basic_forms_townMaxLength6 = "crwdns152582:0{{count}}crwdne152582:0";
var APPB2C_common_basic_forms_unsupportedChars6 = "crwdns52115:0crwdne52115:0";
var APPB2C_common_basic_forms_validMobile6 = "crwdns52129:0crwdne52129:0";
var APPB2C_common_basic_goBack6 = "crwdns52081:0crwdne52081:0";
var APPB2C_common_basic_loading6 = "crwdns52089:0crwdne52089:0";
var APPB2C_common_basic_menu6 = "crwdns52083:0crwdne52083:0";
var APPB2C_common_basic_no6 = "crwdns52079:0crwdne52079:0";
var APPB2C_common_basic_notAllowed_msg6 = "crwdns52019:0crwdne52019:0";
var APPB2C_common_basic_notAllowed_title6 = "crwdns52017:0crwdne52017:0";
var APPB2C_common_basic_notFound_goHome6 = "crwdns52011:0crwdne52011:0";
var APPB2C_common_basic_notFound_msg6 = "crwdns52009:0crwdne52009:0";
var APPB2C_common_basic_notFound_title6 = "crwdns52007:0crwdne52007:0";
var APPB2C_common_basic_notLoading_note6 = "crwdns52037:0crwdne52037:0";
var APPB2C_common_basic_notLoading_refresh6 = "crwdns52039:0crwdne52039:0";
var APPB2C_common_basic_notLoading_title6 = "crwdns52035:0crwdne52035:0";
var APPB2C_common_basic_ok6 = "crwdns52147:0crwdne52147:0";
var APPB2C_common_basic_on6 = "crwdns52159:0crwdne52159:0";
var APPB2C_common_basic_oneSecond6 = "crwdns52091:0crwdne52091:0";
var APPB2C_common_basic_pleaseNote6 = "crwdns52087:0crwdne52087:0";
var APPB2C_common_basic_retake6 = "crwdns83865:0crwdne83865:0";
var APPB2C_common_basic_upload6 = "crwdns52155:0crwdne52155:0";
var APPB2C_common_basic_validationError6 = "crwdns52157:0crwdne52157:0";
var APPB2C_common_basic_waitReassurance6 = "crwdns52093:0crwdne52093:0";
var APPB2C_common_basic_wereSorry6 = "crwdns52097:0crwdne52097:0";
var APPB2C_common_basic_yes6 = "crwdns52077:0crwdne52077:0";
var APPB2C_common_bookTest_changeExamType6 = "crwdns53067:0crwdne53067:0";
var APPB2C_common_bookTest_filters_afternoon6 = "crwdns53093:0crwdne53093:0";
var APPB2C_common_bookTest_filters_allDates6 = "crwdns53085:0crwdne53085:0";
var APPB2C_common_bookTest_filters_anyTime6 = "crwdns53089:0crwdne53089:0";
var APPB2C_common_bookTest_filters_computer6 = "crwdns53081:0crwdne53081:0";
var APPB2C_common_bookTest_filters_evening6 = "crwdns53095:0crwdne53095:0";
var APPB2C_common_bookTest_filters_modal_cta6 = "crwdns53111:0crwdne53111:0";
var APPB2C_common_bookTest_filters_modal_hide6 = "crwdns53109:0crwdne53109:0";
var APPB2C_common_bookTest_filters_modal_iDontMind6 = "crwdns53105:0crwdne53105:0";
var APPB2C_common_bookTest_filters_modal_resultsIn6 = "crwdns53103:0{{days}}crwdne53103:0";
var APPB2C_common_bookTest_filters_modal_subtitle6 = "crwdns53099:0crwdne53099:0";
var APPB2C_common_bookTest_filters_modal_title6 = "crwdns53097:0crwdne53097:0";
var APPB2C_common_bookTest_filters_modal_whichFormatTest6 = "crwdns53101:0crwdne53101:0";
var APPB2C_common_bookTest_filters_modal_whichTimeOfDay6 = "crwdns53107:0crwdne53107:0";
var APPB2C_common_bookTest_filters_morning6 = "crwdns53091:0crwdne53091:0";
var APPB2C_common_bookTest_filters_paper6 = "crwdns53083:0crwdne53083:0";
var APPB2C_common_bookTest_filters_seeAllFilters6 = "crwdns53087:0crwdne53087:0";
var APPB2C_common_bookTest_filters_title6 = "crwdns53079:0crwdne53079:0";
var APPB2C_common_bookTest_loadMoreTests6 = "crwdns53069:0crwdne53069:0";
var APPB2C_common_bookTest_noDiscount_msg_countryNotMatched6 = "crwdns152584:0crwdne152584:0";
var APPB2C_common_bookTest_noDiscount_msg_notFound6 = "crwdns152586:0crwdne152586:0";
var APPB2C_common_bookTest_noDiscount_msg_notSupported6 = "crwdns152588:0crwdne152588:0";
var APPB2C_common_bookTest_noDiscount_msg_reserved6 = "crwdns152590:0crwdne152590:0";
var APPB2C_common_bookTest_noDiscount_msg_used6 = "crwdns152592:0crwdne152592:0";
var APPB2C_common_bookTest_noDiscount_title6 = "crwdns152594:0crwdne152594:0";
var APPB2C_common_bookTest_results_book6 = "crwdns139862:0crwdne139862:0";
var APPB2C_common_bookTest_results_bookFor6 = "crwdns53127:0crwdne53127:0";
var APPB2C_common_bookTest_results_cd6 = "crwdns53131:0crwdne53131:0";
var APPB2C_common_bookTest_results_changeSpeaking6 = "crwdns153922:0crwdne153922:0";
var APPB2C_common_bookTest_results_clearFilters6 = "crwdns53121:0crwdne53121:0";
var APPB2C_common_bookTest_results_fromCurrentLocation6 = "crwdns53139:0crwdne53139:0";
var APPB2C_common_bookTest_results_lrw6 = "crwdns53123:0crwdne53123:0";
var APPB2C_common_bookTest_results_noResultsGoBack6 = "crwdns137404:0crwdne137404:0";
var APPB2C_common_bookTest_results_noResultsText6 = "crwdns137406:0crwdne137406:0";
var APPB2C_common_bookTest_results_noResultsTitle6 = "crwdns53115:0crwdne53115:0";
var APPB2C_common_bookTest_results_pb6 = "crwdns53133:0crwdne53133:0";
var APPB2C_common_bookTest_results_review6 = "crwdns53129:0crwdne53129:0";
var APPB2C_common_bookTest_results_searchText6 = "crwdns53113:0crwdne53113:0";
var APPB2C_common_bookTest_results_speaking6 = "crwdns139880:0crwdne139880:0";
var APPB2C_common_bookTest_results_viewOnMap6 = "crwdns53141:0crwdne53141:0";
var APPB2C_common_bookTest_sortBy_date6 = "crwdns53073:0crwdne53073:0";
var APPB2C_common_bookTest_sortBy_distance6 = "crwdns53071:0crwdne53071:0";
var APPB2C_common_bookTest_sortBy_price6 = "crwdns53075:0crwdne53075:0";
var APPB2C_common_bookTest_sortBy_title6 = "crwdns53077:0crwdne53077:0";
var APPB2C_common_bookTest_title6 = "crwdns53065:0crwdne53065:0";
var APPB2C_common_cancelBooking_alreadyRequested_cta6 = "crwdns52679:0crwdne52679:0";
var APPB2C_common_cancelBooking_alreadyRequested_title6 = "crwdns52677:0crwdne52677:0";
var APPB2C_common_cancelBooking_back6 = "crwdns52675:0crwdne52675:0";
var APPB2C_common_cancelBooking_bankDetails_form_accountHolder6 = "crwdns52749:0crwdne52749:0";
var APPB2C_common_cancelBooking_bankDetails_form_bankAddress6 = "crwdns52747:0crwdne52747:0";
var APPB2C_common_cancelBooking_bankDetails_form_bankName6 = "crwdns52741:0crwdne52741:0";
var APPB2C_common_cancelBooking_bankDetails_form_bankNumber6 = "crwdns52743:0crwdne52743:0";
var APPB2C_common_cancelBooking_bankDetails_form_changeCountry6 = "crwdns52757:0crwdne52757:0";
var APPB2C_common_cancelBooking_bankDetails_form_city6 = "crwdns52751:0crwdne52751:0";
var APPB2C_common_cancelBooking_bankDetails_form_country6 = "crwdns52755:0crwdne52755:0";
var APPB2C_common_cancelBooking_bankDetails_form_holderEmail6 = "crwdns52739:0crwdne52739:0";
var APPB2C_common_cancelBooking_bankDetails_form_holderName6 = "crwdns52737:0crwdne52737:0";
var APPB2C_common_cancelBooking_bankDetails_form_iban6 = "crwdns52763:0crwdne52763:0";
var APPB2C_common_cancelBooking_bankDetails_form_invalidIban6 = "crwdns52765:0crwdne52765:0";
var APPB2C_common_cancelBooking_bankDetails_form_invalidSwift6 = "crwdns52761:0crwdne52761:0";
var APPB2C_common_cancelBooking_bankDetails_form_postcode6 = "crwdns52753:0crwdne52753:0";
var APPB2C_common_cancelBooking_bankDetails_form_sortCode6 = "crwdns52745:0crwdne52745:0";
var APPB2C_common_cancelBooking_bankDetails_form_swift6 = "crwdns52759:0crwdne52759:0";
var APPB2C_common_cancelBooking_bankDetails_note6 = "crwdns52735:0crwdne52735:0";
var APPB2C_common_cancelBooking_bankDetails_privacy6 = "crwdns52771:0crwdne52771:0";
var APPB2C_common_cancelBooking_bankDetails_terms_consent6 = "crwdns52769:0crwdne52769:0";
var APPB2C_common_cancelBooking_bankDetails_terms_title6 = "crwdns52767:0crwdne52767:0";
var APPB2C_common_cancelBooking_bankDetails_title6 = "crwdns52733:0crwdne52733:0";
var APPB2C_common_cancelBooking_bottomNotes_note16 = "crwdns52725:0crwdne52725:0";
var APPB2C_common_cancelBooking_bottomNotes_note26 = "crwdns52727:0crwdne52727:0";
var APPB2C_common_cancelBooking_bottomNotes_note36 = "crwdns52729:0crwdne52729:0";
var APPB2C_common_cancelBooking_bottomNotes_terms6 = "crwdns52731:0crwdne52731:0";
var APPB2C_common_cancelBooking_cancelNotes_16 = "crwdns52669:0crwdne52669:0";
var APPB2C_common_cancelBooking_cancelNotes_26 = "crwdns52671:0crwdne52671:0";
var APPB2C_common_cancelBooking_cancelNotes_36 = "crwdns52673:0crwdne52673:0";
var APPB2C_common_cancelBooking_checkTest6 = "crwdns52681:0crwdne52681:0";
var APPB2C_common_cancelBooking_cta_addBankDetails6 = "crwdns52775:0crwdne52775:0";
var APPB2C_common_cancelBooking_cta_goBack6 = "crwdns52777:0crwdne52777:0";
var APPB2C_common_cancelBooking_cta_submit6 = "crwdns52773:0crwdne52773:0";
var APPB2C_common_cancelBooking_error_cancelledAlready6 = "crwdns152596:0crwdne152596:0";
var APPB2C_common_cancelBooking_error_missingFile6 = "crwdns152598:0crwdne152598:0";
var APPB2C_common_cancelBooking_error_requestedAlready6 = "crwdns152600:0crwdne152600:0";
var APPB2C_common_cancelBooking_reason_form_choose6 = "crwdns52689:0crwdne52689:0";
var APPB2C_common_cancelBooking_reason_form_freeform6 = "crwdns52693:0crwdne52693:0";
var APPB2C_common_cancelBooking_reason_form_optional6 = "crwdns52695:0crwdne52695:0";
var APPB2C_common_cancelBooking_reason_form_requiredEvidence6 = "crwdns52697:0crwdne52697:0";
var APPB2C_common_cancelBooking_reason_form_requiredReason6 = "crwdns52691:0crwdne52691:0";
var APPB2C_common_cancelBooking_reason_form_title6 = "crwdns52687:0crwdne52687:0";
var APPB2C_common_cancelBooking_reason_note_content6 = "crwdns52685:0crwdne52685:0";
var APPB2C_common_cancelBooking_reason_note_title6 = "crwdns52683:0crwdne52683:0";
var APPB2C_common_cancelBooking_subtitle6 = "crwdns52661:0crwdne52661:0";
var APPB2C_common_cancelBooking_title6 = "crwdns52659:0crwdne52659:0";
var APPB2C_common_cancelBooking_transferNote_cta6 = "crwdns52667:0crwdne52667:0";
var APPB2C_common_cancelBooking_transferNote_title6 = "crwdns52663:0crwdne52663:0";
var APPB2C_common_cancelBooking_transferNote_titleIol6 = "crwdns52665:0crwdne52665:0";
var APPB2C_common_cancelBooking_upload_mobile6 = "crwdns52719:0crwdne52719:0";
var APPB2C_common_cancelBooking_upload_notes_16 = "crwdns52701:0crwdne52701:0";
var APPB2C_common_cancelBooking_upload_notes_106 = "crwdns52717:0crwdne52717:0";
var APPB2C_common_cancelBooking_upload_notes_26 = "crwdns52703:0crwdne52703:0";
var APPB2C_common_cancelBooking_upload_notes_36 = "crwdns52705:0crwdne52705:0";
var APPB2C_common_cancelBooking_upload_notes_46 = "crwdns52707:0crwdne52707:0";
var APPB2C_common_cancelBooking_upload_notes_56 = "crwdns52709:0crwdne52709:0";
var APPB2C_common_cancelBooking_upload_notes_66 = "crwdns52711:0crwdne52711:0";
var APPB2C_common_cancelBooking_upload_notes_76 = "crwdns52713:0crwdne52713:0";
var APPB2C_common_cancelBooking_upload_notes_86 = "crwdns52715:0crwdne52715:0";
var APPB2C_common_cancelBooking_upload_requirements_note6 = "crwdns52723:0crwdne52723:0";
var APPB2C_common_cancelBooking_upload_requirements_title6 = "crwdns52721:0crwdne52721:0";
var APPB2C_common_cancelBooking_upload_title6 = "crwdns52699:0crwdne52699:0";
var APPB2C_common_changeBooking_cancel_cta6 = "crwdns52617:0crwdne52617:0";
var APPB2C_common_changeBooking_cancel_text6 = "crwdns52615:0crwdne52615:0";
var APPB2C_common_changeBooking_goBack6 = "crwdns52599:0crwdne52599:0";
var APPB2C_common_changeBooking_subtitle6 = "crwdns52597:0crwdne52597:0";
var APPB2C_common_changeBooking_title6 = "crwdns52595:0crwdne52595:0";
var APPB2C_common_changeBooking_transfer_cta6 = "crwdns52613:0crwdne52613:0";
var APPB2C_common_changeBooking_transfer_text6 = "crwdns52609:0crwdne52609:0";
var APPB2C_common_changeBooking_transfer_textIol6 = "crwdns52611:0crwdne52611:0";
var APPB2C_common_changeBooking_transferNotes_16 = "crwdns52601:0crwdne52601:0";
var APPB2C_common_changeBooking_transferNotes_26 = "crwdns52603:0crwdne52603:0";
var APPB2C_common_changeBooking_transferNotesIol_16 = "crwdns52605:0crwdne52605:0";
var APPB2C_common_changeBooking_transferNotesIol_26 = "crwdns52607:0crwdne52607:0";
var APPB2C_common_changeBookingAck_back6 = "crwdns52833:0crwdne52833:0";
var APPB2C_common_changeBookingAck_cancel_banner6 = "crwdns52783:0crwdne52783:0";
var APPB2C_common_changeBookingAck_cancel_contact_email6 = "crwdns52793:0crwdne52793:0";
var APPB2C_common_changeBookingAck_cancel_contact_phone6 = "crwdns52791:0crwdne52791:0";
var APPB2C_common_changeBookingAck_cancel_contact_title6 = "crwdns52787:0crwdne52787:0";
var APPB2C_common_changeBookingAck_cancel_contact_titleIol6 = "crwdns52789:0crwdne52789:0";
var APPB2C_common_changeBookingAck_cancel_refNr6 = "crwdns52795:0crwdne52795:0";
var APPB2C_common_changeBookingAck_cancel_refunds6 = "crwdns52785:0crwdne52785:0";
var APPB2C_common_changeBookingAck_cancel_respondTime6 = "crwdns52781:0crwdne52781:0";
var APPB2C_common_changeBookingAck_cancel_terms6 = "crwdns52797:0crwdne52797:0";
var APPB2C_common_changeBookingAck_cancel_title6 = "crwdns52779:0crwdne52779:0";
var APPB2C_common_changeBookingAck_fees6 = "crwdns52827:0crwdne52827:0";
var APPB2C_common_changeBookingAck_feesIol6 = "crwdns52829:0crwdne52829:0";
var APPB2C_common_changeBookingAck_speaking_banner6 = "crwdns52819:0crwdne52819:0";
var APPB2C_common_changeBookingAck_speaking_note_line16 = "crwdns52823:0crwdne52823:0";
var APPB2C_common_changeBookingAck_speaking_note_line26 = "crwdns52825:0crwdne52825:0";
var APPB2C_common_changeBookingAck_speaking_note_title6 = "crwdns52821:0crwdne52821:0";
var APPB2C_common_changeBookingAck_speaking_title6 = "crwdns52817:0crwdne52817:0";
var APPB2C_common_changeBookingAck_terms6 = "crwdns52831:0crwdne52831:0";
var APPB2C_common_changeBookingAck_transfer_banner6 = "crwdns52801:0crwdne52801:0";
var APPB2C_common_changeBookingAck_transfer_note6 = "crwdns52805:0crwdne52805:0";
var APPB2C_common_changeBookingAck_transfer_respondTime6 = "crwdns52803:0crwdne52803:0";
var APPB2C_common_changeBookingAck_transfer_title6 = "crwdns52799:0crwdne52799:0";
var APPB2C_common_changeBookingAck_transferIol_banner6 = "crwdns52807:0crwdne52807:0";
var APPB2C_common_changeBookingAck_transferIol_contact6 = "crwdns52811:0crwdne52811:0";
var APPB2C_common_changeBookingAck_transferIol_note6 = "crwdns52809:0crwdne52809:0";
var APPB2C_common_changeBookingAck_transferIol_refNr6 = "crwdns52813:0crwdne52813:0";
var APPB2C_common_changeBookingAck_transferIol_terms6 = "crwdns52815:0crwdne52815:0";
var APPB2C_common_changeSpeaking_cards_change6 = "crwdns52847:0crwdne52847:0";
var APPB2C_common_changeSpeaking_cards_noSlots6 = "crwdns52849:0crwdne52849:0";
var APPB2C_common_changeSpeaking_cards_noSlots_error6 = "crwdns131540:0crwdne131540:0";
var APPB2C_common_changeSpeaking_cards_title6 = "crwdns52845:0crwdne52845:0";
var APPB2C_common_changeSpeaking_cta6 = "crwdns52875:0crwdne52875:0";
var APPB2C_common_changeSpeaking_modals_noSlots_keepTest6 = "crwdns52865:0crwdne52865:0";
var APPB2C_common_changeSpeaking_modals_noSlots_line16 = "crwdns52861:0crwdne52861:0";
var APPB2C_common_changeSpeaking_modals_noSlots_line26 = "crwdns152896:0crwdne152896:0";
var APPB2C_common_changeSpeaking_modals_noSlots_title6 = "crwdns83867:0crwdne83867:0";
var APPB2C_common_changeSpeaking_modals_noSlots_transferTest6 = "crwdns52867:0crwdne52867:0";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_cta6 = "crwdns52857:0crwdne52857:0";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line16 = "crwdns52853:0crwdne52853:0";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_line26 = "crwdns52855:0crwdne52855:0";
var APPB2C_common_changeSpeaking_modals_testNotAvailable_title6 = "crwdns52851:0crwdne52851:0";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta6 = "crwdns52873:0crwdne52873:0";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_line16 = "crwdns52871:0crwdne52871:0";
var APPB2C_common_changeSpeaking_modals_transferNotAvailable_title6 = "crwdns83869:0crwdne83869:0";
var APPB2C_common_changeSpeaking_note_back6 = "crwdns52843:0crwdne52843:0";
var APPB2C_common_changeSpeaking_note_line16 = "crwdns52839:0crwdne52839:0";
var APPB2C_common_changeSpeaking_note_line26 = "crwdns52841:0crwdne52841:0";
var APPB2C_common_changeSpeaking_note_title6 = "crwdns52837:0crwdne52837:0";
var APPB2C_common_changeSpeaking_title6 = "crwdns52835:0crwdne52835:0";
var APPB2C_common_chooseTest_difference6 = "crwdns52897:0crwdne52897:0";
var APPB2C_common_chooseTest_iol_consent6 = "crwdns52903:0crwdne52903:0";
var APPB2C_common_chooseTest_iol_cta6 = "crwdns52907:0crwdne52907:0";
var APPB2C_common_chooseTest_iol_desc6 = "crwdns52901:0crwdne52901:0";
var APPB2C_common_chooseTest_iol_link6 = "crwdns52905:0crwdne52905:0";
var APPB2C_common_chooseTest_iol_note_content6 = "crwdns52911:0crwdne52911:0";
var APPB2C_common_chooseTest_iol_note_title6 = "crwdns52909:0crwdne52909:0";
var APPB2C_common_chooseTest_iol_title6 = "crwdns52899:0crwdne52899:0";
var APPB2C_common_chooseTest_ors_cta6 = "crwdns52945:0crwdne52945:0";
var APPB2C_common_chooseTest_ors_descAc6 = "crwdns52941:0crwdne52941:0";
var APPB2C_common_chooseTest_ors_descGt6 = "crwdns52943:0crwdne52943:0";
var APPB2C_common_chooseTest_ors_title6 = "crwdns52939:0crwdne52939:0";
var APPB2C_common_chooseTest_title6 = "crwdns52895:0crwdne52895:0";
var APPB2C_common_chooseTest_ukvi_difference6 = "crwdns52913:0crwdne52913:0";
var APPB2C_common_chooseTest_ukvi_inCentre_cta6 = "crwdns52937:0crwdne52937:0";
var APPB2C_common_chooseTest_ukvi_inCentre_descAc6 = "crwdns52933:0crwdne52933:0";
var APPB2C_common_chooseTest_ukvi_inCentre_descGt6 = "crwdns52935:0crwdne52935:0";
var APPB2C_common_chooseTest_ukvi_inCentre_title6 = "crwdns52931:0crwdne52931:0";
var APPB2C_common_chooseTest_ukvi_online_consent6 = "crwdns52921:0crwdne52921:0";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol6 = "crwdns52925:0crwdne52925:0";
var APPB2C_common_chooseTest_ukvi_online_consentBiometric_title6 = "crwdns52923:0crwdne52923:0";
var APPB2C_common_chooseTest_ukvi_online_cta6 = "crwdns52917:0crwdne52917:0";
var APPB2C_common_chooseTest_ukvi_online_link6 = "crwdns52919:0crwdne52919:0";
var APPB2C_common_chooseTest_ukvi_online_note_content6 = "crwdns52929:0crwdne52929:0";
var APPB2C_common_chooseTest_ukvi_online_note_title6 = "crwdns52927:0crwdne52927:0";
var APPB2C_common_chooseTest_ukvi_online_title6 = "crwdns52915:0crwdne52915:0";
var APPB2C_common_draft_cancel5 = "crwdns52561:0crwdne52561:0";
var APPB2C_common_draft_cta_confirm6 = "crwdns52583:0crwdne52583:0";
var APPB2C_common_draft_cta_decline6 = "crwdns52587:0crwdne52587:0";
var APPB2C_common_draft_cta_note6 = "crwdns52585:0{{agent}}crwdne52585:0";
var APPB2C_common_draft_difference6 = "crwdns52557:0crwdne52557:0";
var APPB2C_common_draft_edit6 = "crwdns52559:0crwdne52559:0";
var APPB2C_common_draft_error_pastDeadline6 = "crwdns152602:0crwdne152602:0";
var APPB2C_common_draft_id_cta6 = "crwdns52581:0crwdne52581:0";
var APPB2C_common_draft_id_documentWithNoExpiryDate6 = "crwdns52577:0crwdne52577:0";
var APPB2C_common_draft_id_header6 = "crwdns52571:0crwdne52571:0";
var APPB2C_common_draft_id_issuingAuthority6 = "crwdns52579:0crwdne52579:0";
var APPB2C_common_draft_id_note6 = "crwdns52575:0crwdne52575:0";
var APPB2C_common_draft_id_title6 = "crwdns52573:0crwdne52573:0";
var APPB2C_common_draft_note_line16 = "crwdns52551:0{{agent}}crwdne52551:0";
var APPB2C_common_draft_note_line26 = "crwdns52553:0crwdne52553:0";
var APPB2C_common_draft_note_line36 = "crwdns52555:0{{agent}}crwdne52555:0";
var APPB2C_common_draft_personal_cta6 = "crwdns52569:0crwdne52569:0";
var APPB2C_common_draft_personal_header6 = "crwdns52563:0crwdne52563:0";
var APPB2C_common_draft_personal_note6 = "crwdns153959:0crwdne153959:0";
var APPB2C_common_draft_personal_title6 = "crwdns52565:0crwdne52565:0";
var APPB2C_common_draft_title6 = "crwdns52549:0crwdne52549:0";
var APPB2C_common_examType_iol_ac6 = "crwdns52225:0crwdne52225:0";
var APPB2C_common_examType_iol_gt6 = "crwdns52227:0crwdne52227:0";
var APPB2C_common_examType_ors_ac6 = "crwdns52221:0crwdne52221:0";
var APPB2C_common_examType_ors_gt6 = "crwdns52223:0crwdne52223:0";
var APPB2C_common_examType_ors_osr_ac6 = "crwdns83871:0crwdne83871:0";
var APPB2C_common_examType_ors_osr_gt6 = "crwdns83873:0crwdne83873:0";
var APPB2C_common_examType_ukvi_ac6 = "crwdns52233:0crwdne52233:0";
var APPB2C_common_examType_ukvi_gt6 = "crwdns52235:0crwdne52235:0";
var APPB2C_common_examType_ukvi_lfska16 = "crwdns52237:0crwdne52237:0";
var APPB2C_common_examType_ukvi_lfska26 = "crwdns52239:0crwdne52239:0";
var APPB2C_common_examType_ukvi_lfskb16 = "crwdns52241:0crwdne52241:0";
var APPB2C_common_examType_ukvi_osr_ac6 = "crwdns152604:0crwdne152604:0";
var APPB2C_common_examType_ukvi_osr_gt6 = "crwdns152606:0crwdne152606:0";
var APPB2C_common_examType_uol_ac6 = "crwdns52229:0crwdne52229:0";
var APPB2C_common_examType_uol_gt6 = "crwdns52231:0crwdne52231:0";
var APPB2C_common_filePreview_label6 = "crwdns53203:0crwdne53203:0";
var APPB2C_common_filePreview_pdfPreviewLink6 = "crwdns53207:0crwdne53207:0";
var APPB2C_common_filePreview_status6 = "crwdns53205:0crwdne53205:0";
var APPB2C_common_filePreview_statusName_approved6 = "crwdns53211:0crwdne53211:0";
var APPB2C_common_filePreview_statusName_deleted6 = "crwdns53217:0crwdne53217:0";
var APPB2C_common_filePreview_statusName_pending6 = "crwdns53209:0crwdne53209:0";
var APPB2C_common_filePreview_statusName_rejected6 = "crwdns53213:0crwdne53213:0";
var APPB2C_common_filePreview_statusName_reviewed6 = "crwdns53215:0crwdne53215:0";
var APPB2C_common_fileUpload_cta6 = "crwdns53175:0crwdne53175:0";
var APPB2C_common_fileUpload_exceededSize6 = "crwdns53197:0crwdne53197:0";
var APPB2C_common_fileUpload_fileApproved6 = "crwdns53183:0crwdne53183:0";
var APPB2C_common_fileUpload_fileOptional6 = "crwdns53187:0{{number}}crwdne53187:0";
var APPB2C_common_fileUpload_fileRequired6 = "crwdns53185:0crwdne53185:0";
var APPB2C_common_fileUpload_filesCount6 = "crwdns53189:0{{current}}crwdnd53189:0{{total}}crwdne53189:0";
var APPB2C_common_fileUpload_fileSize6 = "crwdns53179:0{{size}}crwdne53179:0";
var APPB2C_common_fileUpload_imgAlt6 = "crwdns154223:0crwdne154223:0";
var APPB2C_common_fileUpload_pdfIeMsg6 = "crwdns53191:0crwdne53191:0";
var APPB2C_common_fileUpload_pdfPasswordMsg6 = "crwdns53195:0crwdne53195:0";
var APPB2C_common_fileUpload_remove6 = "crwdns53181:0crwdne53181:0";
var APPB2C_common_fileUpload_requirements6 = "crwdns53177:0crwdne53177:0";
var APPB2C_common_fileUpload_success6 = "crwdns53193:0{{fileName}}crwdne53193:0";
var APPB2C_common_findTest_changeCity6 = "crwdns52957:0crwdne52957:0";
var APPB2C_common_findTest_changeCountry6 = "crwdns52955:0crwdne52955:0";
var APPB2C_common_findTest_cta6 = "crwdns52951:0crwdne52951:0";
var APPB2C_common_findTest_dates_clear6 = "crwdns52991:0crwdne52991:0";
var APPB2C_common_findTest_dates_from6 = "crwdns52987:0crwdne52987:0";
var APPB2C_common_findTest_dates_noDatesSelected6 = "crwdns83875:0crwdne83875:0";
var APPB2C_common_findTest_dates_noresults_details6 = "crwdns52997:0crwdne52997:0";
var APPB2C_common_findTest_dates_noresults_title6 = "crwdns52995:0crwdne52995:0";
var APPB2C_common_findTest_dates_noresultsMobileLocation_cta6 = "crwdns53007:0crwdne53007:0";
var APPB2C_common_findTest_dates_noresultsMobileLocation_details6 = "crwdns53005:0crwdne53005:0";
var APPB2C_common_findTest_dates_noresultsMobileLocation_title6 = "crwdns53003:0crwdne53003:0";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_details6 = "crwdns53001:0crwdne53001:0";
var APPB2C_common_findTest_dates_noresultsSpecialReqs_title6 = "crwdns52999:0crwdne52999:0";
var APPB2C_common_findTest_dates_pleaseSelect6 = "crwdns52983:0crwdne52983:0";
var APPB2C_common_findTest_dates_selected6 = "crwdns52985:0crwdne52985:0";
var APPB2C_common_findTest_dates_showAll6 = "crwdns52979:0crwdne52979:0";
var APPB2C_common_findTest_dates_showDateRange6 = "crwdns52981:0crwdne52981:0";
var APPB2C_common_findTest_dates_title6 = "crwdns52977:0crwdne52977:0";
var APPB2C_common_findTest_dates_to6 = "crwdns52989:0crwdne52989:0";
var APPB2C_common_findTest_examFormat_all6 = "crwdns53029:0crwdne53029:0";
var APPB2C_common_findTest_examFormat_cd6 = "crwdns152608:0crwdne152608:0";
var APPB2C_common_findTest_examFormat_pb6 = "crwdns53025:0crwdne53025:0";
var APPB2C_common_findTest_examFormat_results6 = "crwdns53023:0{{days}}crwdne53023:0";
var APPB2C_common_findTest_examFormat_title6 = "crwdns53021:0crwdne53021:0";
var APPB2C_common_findTest_iol_noLrwResults6 = "crwdns53031:0crwdne53031:0";
var APPB2C_common_findTest_iol_organisationCheck_check6 = "crwdns53047:0crwdne53047:0";
var APPB2C_common_findTest_iol_organisationCheck_desc6 = "crwdns53045:0crwdne53045:0";
var APPB2C_common_findTest_iol_organisationCheck_title6 = "crwdns53043:0crwdne53043:0";
var APPB2C_common_findTest_iol_softwareCheck_check6 = "crwdns53041:0crwdne53041:0";
var APPB2C_common_findTest_iol_softwareCheck_desc16 = "crwdns53035:0crwdne53035:0";
var APPB2C_common_findTest_iol_softwareCheck_desc26 = "crwdns53037:0crwdne53037:0";
var APPB2C_common_findTest_iol_softwareCheck_link6 = "crwdns53039:0crwdne53039:0";
var APPB2C_common_findTest_iol_softwareCheck_title6 = "crwdns53033:0crwdne53033:0";
var APPB2C_common_findTest_langModal_choose6 = "crwdns130982:0{{languageName}}crwdnd130982:0{{countryName}}crwdne130982:0";
var APPB2C_common_findTest_langModal_chooseSingle6 = "crwdns130984:0{{languageName}}crwdnd130984:0{{countryName}}crwdne130984:0";
var APPB2C_common_findTest_langModal_continue6 = "crwdns130986:0crwdne130986:0";
var APPB2C_common_findTest_langModal_title6 = "crwdns130988:0crwdne130988:0";
var APPB2C_common_findTest_multipleLocations6 = "crwdns52959:0crwdne52959:0";
var APPB2C_common_findTest_osrInfo6 = "crwdns152610:0crwdne152610:0";
var APPB2C_common_findTest_osrInfoLink6 = "crwdns152898:0crwdne152898:0";
var APPB2C_common_findTest_residencyConfirmation_btn6 = "crwdns152612:0crwdne152612:0";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_btn6 = "crwdns152614:0crwdne152614:0";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line16 = "crwdns152616:0crwdne152616:0";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_line26 = "crwdns152618:0crwdne152618:0";
var APPB2C_common_findTest_residencyConfirmation_errorMsg_title6 = "crwdns152620:0crwdne152620:0";
var APPB2C_common_findTest_residencyConfirmation_msg5 = "crwdns152622:0crwdne152622:0";
var APPB2C_common_findTest_residencyConfirmation_msg_line16 = "crwdns152678:0crwdne152678:0";
var APPB2C_common_findTest_residencyConfirmation_msg_line26 = "crwdns152680:0crwdne152680:0";
var APPB2C_common_findTest_residencyConfirmation_msg_line36 = "crwdns152682:0crwdne152682:0";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine26 = "crwdns152684:0crwdne152684:0";
var APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine26 = "crwdns152686:0crwdne152686:0";
var APPB2C_common_findTest_residencyConfirmation_selectDates6 = "crwdns152624:0crwdne152624:0";
var APPB2C_common_findTest_residencyConfirmation_title6 = "crwdns152626:0crwdne152626:0";
var APPB2C_common_findTest_searchIn6 = "crwdns52953:0crwdne52953:0";
var APPB2C_common_findTest_selectCountry_continue6 = "crwdns52965:0crwdne52965:0";
var APPB2C_common_findTest_selectCountry_countryNotSupported6 = "crwdns52967:0crwdne52967:0";
var APPB2C_common_findTest_selectCountry_label6 = "crwdns52961:0crwdne52961:0";
var APPB2C_common_findTest_selectCountry_placeholder6 = "crwdns52963:0crwdne52963:0";
var APPB2C_common_findTest_selectLocation_closestToMe6 = "crwdns52973:0crwdne52973:0";
var APPB2C_common_findTest_selectLocation_geolocationBlocked6 = "crwdns52975:0crwdne52975:0";
var APPB2C_common_findTest_selectLocation_label6 = "crwdns52969:0crwdne52969:0";
var APPB2C_common_findTest_selectLocation_noExamForThisCountry6 = "crwdns52971:0crwdne52971:0";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation6 = "crwdns148368:0crwdne148368:0";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta6 = "crwdns148370:0crwdne148370:0";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option16 = "crwdns148372:0crwdne148372:0";
var APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option26 = "crwdns148374:0crwdne148374:0";
var APPB2C_common_findTest_specialReqs_findOutMore6 = "crwdns53009:0crwdne53009:0";
var APPB2C_common_findTest_specialReqs_list_subtitle6 = "crwdns53015:0crwdne53015:0";
var APPB2C_common_findTest_specialReqs_list_title6 = "crwdns53013:0crwdne53013:0";
var APPB2C_common_findTest_specialReqs_needCheckbox6 = "crwdns53011:0crwdne53011:0";
var APPB2C_common_findTest_specialReqs_other6 = "crwdns53017:0crwdne53017:0";
var APPB2C_common_findTest_specialReqs_pleaseNoteMessage6 = "crwdns53019:0crwdne53019:0";
var APPB2C_common_findTest_subtitle6 = "crwdns52949:0crwdne52949:0";
var APPB2C_common_findTest_title6 = "crwdns52947:0crwdne52947:0";
var APPB2C_common_findTest_uol_organisationCheck_check6 = "crwdns53063:0crwdne53063:0";
var APPB2C_common_findTest_uol_organisationCheck_desc6 = "crwdns53061:0crwdne53061:0";
var APPB2C_common_findTest_uol_organisationCheck_title6 = "crwdns53059:0crwdne53059:0";
var APPB2C_common_findTest_uol_softwareCheck_check6 = "crwdns53057:0crwdne53057:0";
var APPB2C_common_findTest_uol_softwareCheck_desc16 = "crwdns53051:0crwdne53051:0";
var APPB2C_common_findTest_uol_softwareCheck_desc26 = "crwdns53053:0crwdne53053:0";
var APPB2C_common_findTest_uol_softwareCheck_link6 = "crwdns53055:0crwdne53055:0";
var APPB2C_common_findTest_uol_softwareCheck_title6 = "crwdns53049:0crwdne53049:0";
var APPB2C_common_footer_terms_accessibility6 = "crwdns52879:0crwdne52879:0";
var APPB2C_common_footer_terms_copyrightLine16 = "crwdns52885:0crwdne52885:0";
var APPB2C_common_footer_terms_copyrightLine26 = "crwdns52887:0crwdne52887:0";
var APPB2C_common_footer_terms_dataProtection6 = "crwdns52883:0crwdne52883:0";
var APPB2C_common_footer_terms_privacyAndCookies6 = "crwdns52881:0crwdne52881:0";
var APPB2C_common_footer_terms_termsOfUse6 = "crwdns52877:0crwdne52877:0";
var APPB2C_common_gelRedirect_errorMessage6 = "crwdns53311:0crwdne53311:0";
var APPB2C_common_gelRedirect_goBack5 = "crwdns53313:0crwdne53313:0";
var APPB2C_common_gelRedirect_header6 = "crwdns53307:0crwdne53307:0";
var APPB2C_common_gelRedirect_info6 = "crwdns53309:0crwdne53309:0";
var APPB2C_common_general_next6 = "crwdns52181:0crwdne52181:0";
var APPB2C_common_general_previous6 = "crwdns52183:0crwdne52183:0";
var APPB2C_common_general_regNotFound6 = "crwdns52187:0crwdne52187:0";
var APPB2C_common_general_remove6 = "crwdns52185:0crwdne52185:0";
var APPB2C_common_general_steps6 = "crwdns52189:0{{step}}crwdnd52189:0{{steps}}crwdne52189:0";
var APPB2C_common_general_stepsContinued6 = "crwdns52191:0{{step}}crwdnd52191:0{{steps}}crwdne52191:0";
var APPB2C_common_helpAndContact_actionSection_contactFormLabel6 = "crwdns52513:0crwdne52513:0";
var APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre6 = "crwdns154225:0crwdne154225:0";
var APPB2C_common_helpAndContact_actionSection_contactFormLink6 = "crwdns52515:0crwdne52515:0";
var APPB2C_common_helpAndContact_actionSection_faqLabel6 = "crwdns52505:0crwdne52505:0";
var APPB2C_common_helpAndContact_actionSection_faqLink6 = "crwdns52507:0crwdne52507:0";
var APPB2C_common_helpAndContact_actionSection_icHeader6 = "crwdns52501:0crwdne52501:0";
var APPB2C_common_helpAndContact_actionSection_iolHeader6 = "crwdns52503:0crwdne52503:0";
var APPB2C_common_helpAndContact_actionSection_launchChatBtn6 = "crwdns52511:0crwdne52511:0";
var APPB2C_common_helpAndContact_actionSection_launchChatLabel6 = "crwdns52509:0crwdne52509:0";
var APPB2C_common_helpAndContact_form_back6 = "crwdns52517:0crwdne52517:0";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLink6 = "crwdns154227:0crwdne154227:0";
var APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle6 = "crwdns154229:0crwdne154229:0";
var APPB2C_common_helpAndContact_form_complaint_radio_complaint6 = "crwdns154231:0crwdne154231:0";
var APPB2C_common_helpAndContact_form_complaint_radio_other6 = "crwdns154233:0crwdne154233:0";
var APPB2C_common_helpAndContact_form_complaint_title6 = "crwdns154235:0crwdne154235:0";
var APPB2C_common_helpAndContact_form_label6 = "crwdns52521:0crwdne52521:0";
var APPB2C_common_helpAndContact_form_legal6 = "crwdns52527:0crwdne52527:0";
var APPB2C_common_helpAndContact_form_response6 = "crwdns52525:0crwdne52525:0";
var APPB2C_common_helpAndContact_form_submit6 = "crwdns52529:0crwdne52529:0";
var APPB2C_common_helpAndContact_form_thanks_back6 = "crwdns52537:0crwdne52537:0";
var APPB2C_common_helpAndContact_form_thanks_banner6 = "crwdns52533:0crwdne52533:0";
var APPB2C_common_helpAndContact_form_thanks_info6 = "crwdns52535:0crwdne52535:0";
var APPB2C_common_helpAndContact_form_thanks_title6 = "crwdns52531:0crwdne52531:0";
var APPB2C_common_helpAndContact_form_title6 = "crwdns52519:0crwdne52519:0";
var APPB2C_common_helpAndContact_form_validation6 = "crwdns52523:0crwdne52523:0";
var APPB2C_common_helpAndContact_icContent_commonQuestionsHeader6 = "crwdns52499:0crwdne52499:0";
var APPB2C_common_helpAndContact_icContent_emailLabel6 = "crwdns52493:0crwdne52493:0";
var APPB2C_common_helpAndContact_icContent_header6 = "crwdns52491:0crwdne52491:0";
var APPB2C_common_helpAndContact_icContent_phoneLabel6 = "crwdns52495:0crwdne52495:0";
var APPB2C_common_helpAndContact_icContent_referenceReminder6 = "crwdns52497:0crwdne52497:0";
var APPB2C_common_helpAndContact_ieltsReference6 = "crwdns52483:0crwdne52483:0";
var APPB2C_common_helpAndContact_noTests_bookNewBtn6 = "crwdns52487:0crwdne52487:0";
var APPB2C_common_helpAndContact_noTests_contactLink6 = "crwdns52489:0crwdne52489:0";
var APPB2C_common_helpAndContact_noTests_header6 = "crwdns52485:0crwdne52485:0";
var APPB2C_common_helpAndContact_selectExamLabel6 = "crwdns52481:0crwdne52481:0";
var APPB2C_common_helpAndContact_title6 = "crwdns52479:0crwdne52479:0";
var APPB2C_common_invitation_modal_header6 = "crwdns53171:0crwdne53171:0";
var APPB2C_common_invitation_modal_invalid6 = "crwdns53173:0crwdne53173:0";
var APPB2C_common_modals_newTermsAndConditions_checkboxNote6 = "crwdns53299:0{{context}}crwdne53299:0";
var APPB2C_common_modals_newTermsAndConditions_checkboxTC6 = "crwdns53301:0crwdne53301:0";
var APPB2C_common_modals_newTermsAndConditions_cta6 = "crwdns53303:0crwdne53303:0";
var APPB2C_common_modals_newTermsAndConditions_error6 = "crwdns53305:0crwdne53305:0";
var APPB2C_common_modals_newTermsAndConditions_note6 = "crwdns53297:0crwdne53297:0";
var APPB2C_common_modals_newTermsAndConditions_title6 = "crwdns53295:0crwdne53295:0";
var APPB2C_common_modals_newTermsAndConditions_trigger6 = "crwdns53293:0crwdne53293:0";
var APPB2C_common_nav_bookNewTest6 = "crwdns52211:0crwdne52211:0";
var APPB2C_common_nav_chooseLanguage6 = "crwdns130990:0crwdne130990:0";
var APPB2C_common_nav_hello6 = "crwdns52209:0crwdne52209:0";
var APPB2C_common_nav_helpAndContact6 = "crwdns52217:0crwdne52217:0";
var APPB2C_common_nav_login6 = "crwdns52193:0crwdne52193:0";
var APPB2C_common_nav_logout6 = "crwdns52197:0crwdne52197:0";
var APPB2C_common_nav_logoutModal_cancel5 = "crwdns52207:0crwdne52207:0";
var APPB2C_common_nav_logoutModal_confirm6 = "crwdns52205:0crwdne52205:0";
var APPB2C_common_nav_logoutModal_message6 = "crwdns52201:0crwdne52201:0";
var APPB2C_common_nav_logoutModal_question6 = "crwdns52203:0crwdne52203:0";
var APPB2C_common_nav_logoutModal_title6 = "crwdns52199:0crwdne52199:0";
var APPB2C_common_nav_myAccount6 = "crwdns52215:0crwdne52215:0";
var APPB2C_common_nav_preparation6 = "crwdns52219:0crwdne52219:0";
var APPB2C_common_nav_resultsTests6 = "crwdns52213:0crwdne52213:0";
var APPB2C_common_nav_ttp6 = "crwdns52195:0crwdne52195:0";
var APPB2C_common_preparation_noTests_contactLink6 = "crwdns52547:0crwdne52547:0";
var APPB2C_common_preparation_noTests_header6 = "crwdns52545:0crwdne52545:0";
var APPB2C_common_preparation_selectExamLabel6 = "crwdns52541:0crwdne52541:0";
var APPB2C_common_preparation_title6 = "crwdns52539:0crwdne52539:0";
var APPB2C_common_preparation_viewTestBtn6 = "crwdns52543:0crwdne52543:0";
var APPB2C_common_recreateReg_errMsg6 = "crwdns52893:0crwdne52893:0";
var APPB2C_common_recreateReg_errTitle6 = "crwdns52891:0crwdne52891:0";
var APPB2C_common_recreateReg_progress6 = "crwdns52889:0crwdne52889:0";
var APPB2C_common_regHeader_reference6 = "crwdns52589:0crwdne52589:0";
var APPB2C_common_regHeader_trfLoading6 = "crwdns52591:0crwdne52591:0";
var APPB2C_common_regHeader_trfNumber6 = "crwdns52593:0crwdne52593:0";
var APPB2C_common_regInProgressModal_continue6 = "crwdns53151:0crwdne53151:0";
var APPB2C_common_regInProgressModal_message16 = "crwdns53145:0{{date}}crwdne53145:0";
var APPB2C_common_regInProgressModal_message26 = "crwdns53147:0crwdne53147:0";
var APPB2C_common_regInProgressModal_restart6 = "crwdns53149:0crwdne53149:0";
var APPB2C_common_regInProgressModal_title6 = "crwdns53143:0crwdne53143:0";
var APPB2C_common_selfServices_requested_cancellation6 = "crwdns53201:0crwdne53201:0";
var APPB2C_common_selfServices_requested_transfer6 = "crwdns53199:0crwdne53199:0";
var APPB2C_common_specialReqs_AMANU6 = "crwdns52161:0crwdne52161:0";
var APPB2C_common_specialReqs_ANTHR6 = "crwdns52163:0crwdne52163:0";
var APPB2C_common_specialReqs_BTP6 = "crwdns52165:0crwdne52165:0";
var APPB2C_common_specialReqs_BWP6 = "crwdns52167:0crwdne52167:0";
var APPB2C_common_specialReqs_EPTP6 = "crwdns52169:0crwdne52169:0";
var APPB2C_common_specialReqs_ET6 = "crwdns52171:0crwdne52171:0";
var APPB2C_common_specialReqs_LRTV6 = "crwdns52173:0crwdne52173:0";
var APPB2C_common_specialReqs_SAE6 = "crwdns52175:0crwdne52175:0";
var APPB2C_common_specialReqs_VAS6 = "crwdns52177:0crwdne52177:0";
var APPB2C_common_specialReqs_WP6 = "crwdns52179:0crwdne52179:0";
var APPB2C_common_startPage_book6 = "crwdns52251:0crwdne52251:0";
var APPB2C_common_startPage_candidates_nowAdult6 = "crwdns52333:0{{name}}crwdne52333:0";
var APPB2C_common_startPage_candidates_showingFor6 = "crwdns52331:0crwdne52331:0";
var APPB2C_common_startPage_common_cancelled6 = "crwdns52335:0crwdne52335:0";
var APPB2C_common_startPage_description_ac6 = "crwdns52273:0crwdne52273:0";
var APPB2C_common_startPage_description_gt6 = "crwdns52275:0crwdne52275:0";
var APPB2C_common_startPage_description_ukvi6 = "crwdns52277:0crwdne52277:0";
var APPB2C_common_startPage_endNote6 = "crwdns52269:0crwdne52269:0";
var APPB2C_common_startPage_error_body6 = "crwdns52377:0crwdne52377:0";
var APPB2C_common_startPage_error_cta6 = "crwdns52379:0crwdne52379:0";
var APPB2C_common_startPage_error_title6 = "crwdns52375:0crwdne52375:0";
var APPB2C_common_startPage_hero_cta6 = "crwdns52263:0crwdne52263:0";
var APPB2C_common_startPage_hero_subtitle6 = "crwdns52261:0crwdne52261:0";
var APPB2C_common_startPage_hero_title6 = "crwdns52259:0crwdne52259:0";
var APPB2C_common_startPage_ielts6 = "crwdns52247:0crwdne52247:0";
var APPB2C_common_startPage_ieltsUkvi6 = "crwdns52249:0crwdne52249:0";
var APPB2C_common_startPage_missing_cancel6 = "crwdns52337:0crwdne52337:0";
var APPB2C_common_startPage_missing_marketing_cta6 = "crwdns52373:0crwdne52373:0";
var APPB2C_common_startPage_missing_marketing_notes_full16 = "crwdns52365:0crwdne52365:0";
var APPB2C_common_startPage_missing_marketing_notes_full26 = "crwdns52367:0crwdne52367:0";
var APPB2C_common_startPage_missing_marketing_notes_full36 = "crwdns52371:0crwdne52371:0";
var APPB2C_common_startPage_missing_marketing_notes_link6 = "crwdns52369:0crwdne52369:0";
var APPB2C_common_startPage_missing_marketing_notes_main6 = "crwdns52363:0crwdne52363:0";
var APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties6 = "crwdns153728:0crwdne153728:0";
var APPB2C_common_startPage_missing_marketing_opts_BcOnly6 = "crwdns52359:0crwdne52359:0";
var APPB2C_common_startPage_missing_marketing_opts_DoNotSend6 = "crwdns52361:0crwdne52361:0";
var APPB2C_common_startPage_missing_marketing_title6 = "crwdns52357:0crwdne52357:0";
var APPB2C_common_startPage_missing_ttData_cta6 = "crwdns52347:0crwdne52347:0";
var APPB2C_common_startPage_missing_ttData_dob6 = "crwdns52355:0crwdne52355:0";
var APPB2C_common_startPage_missing_ttData_firstName6 = "crwdns52349:0crwdne52349:0";
var APPB2C_common_startPage_missing_ttData_lastName6 = "crwdns52351:0crwdne52351:0";
var APPB2C_common_startPage_missing_ttData_line16 = "crwdns52341:0crwdne52341:0";
var APPB2C_common_startPage_missing_ttData_line26 = "crwdns52343:0crwdne52343:0";
var APPB2C_common_startPage_missing_ttData_line36 = "crwdns52345:0crwdne52345:0";
var APPB2C_common_startPage_missing_ttData_noLastName6 = "crwdns52353:0crwdne52353:0";
var APPB2C_common_startPage_missing_ttData_title6 = "crwdns52339:0crwdne52339:0";
var APPB2C_common_startPage_options_ac6 = "crwdns52253:0crwdne52253:0";
var APPB2C_common_startPage_options_gt6 = "crwdns52255:0crwdne52255:0";
var APPB2C_common_startPage_options_ukvi6 = "crwdns52257:0crwdne52257:0";
var APPB2C_common_startPage_past_cd6 = "crwdns52313:0crwdne52313:0";
var APPB2C_common_startPage_past_empty6 = "crwdns52323:0crwdne52323:0";
var APPB2C_common_startPage_past_osr_newScore6 = "crwdns83877:0crwdne83877:0";
var APPB2C_common_startPage_past_osr_note_awaiting6 = "crwdns88299:0{{skill}}crwdne88299:0";
var APPB2C_common_startPage_past_osr_note_confirmed6 = "crwdns83881:0{{skill}}crwdne83881:0";
var APPB2C_common_startPage_past_osr_note_hasResults6 = "crwdns83883:0{{skill}}crwdne83883:0";
var APPB2C_common_startPage_past_osr_originalScore6 = "crwdns83885:0crwdne83885:0";
var APPB2C_common_startPage_past_osr_viewRetakeLink6 = "crwdns83887:0crwdne83887:0";
var APPB2C_common_startPage_past_pb6 = "crwdns52315:0crwdne52315:0";
var APPB2C_common_startPage_past_rebook6 = "crwdns52321:0crwdne52321:0";
var APPB2C_common_startPage_past_score6 = "crwdns52317:0crwdne52317:0";
var APPB2C_common_startPage_past_scoreLifeSkills6 = "crwdns52319:0crwdne52319:0";
var APPB2C_common_startPage_past_title6 = "crwdns52311:0crwdne52311:0";
var APPB2C_common_startPage_past_upcomingResults6 = "crwdns52325:0{{date}}crwdne52325:0";
var APPB2C_common_startPage_readTnCs6 = "crwdns52267:0crwdne52267:0";
var APPB2C_common_startPage_recent_cta6 = "crwdns52329:0crwdne52329:0";
var APPB2C_common_startPage_recent_title6 = "crwdns52327:0crwdne52327:0";
var APPB2C_common_startPage_subtitle6 = "crwdns52245:0crwdne52245:0";
var APPB2C_common_startPage_title6 = "crwdns52243:0crwdne52243:0";
var APPB2C_common_startPage_tncs6 = "crwdns52271:0crwdne52271:0";
var APPB2C_common_startPage_upcoming_cd6 = "crwdns52285:0crwdne52285:0";
var APPB2C_common_startPage_upcoming_empty6 = "crwdns52295:0crwdne52295:0";
var APPB2C_common_startPage_upcoming_lifeSkills6 = "crwdns52291:0crwdne52291:0";
var APPB2C_common_startPage_upcoming_live6 = "crwdns52289:0crwdne52289:0";
var APPB2C_common_startPage_upcoming_manage6 = "crwdns52281:0crwdne52281:0";
var APPB2C_common_startPage_upcoming_missing_childConsent6 = "crwdns52309:0crwdne52309:0";
var APPB2C_common_startPage_upcoming_missing_id6 = "crwdns52303:0crwdne52303:0";
var APPB2C_common_startPage_upcoming_missing_medical6 = "crwdns52305:0crwdne52305:0";
var APPB2C_common_startPage_upcoming_missing_payment6 = "crwdns52307:0crwdne52307:0";
var APPB2C_common_startPage_upcoming_noSpeaking6 = "crwdns52297:0crwdne52297:0";
var APPB2C_common_startPage_upcoming_noTime6 = "crwdns52299:0crwdne52299:0";
var APPB2C_common_startPage_upcoming_osr_note6 = "crwdns83889:0{{skill}}crwdne83889:0";
var APPB2C_common_startPage_upcoming_pastPaymentDeadline6 = "crwdns125583:0crwdne125583:0";
var APPB2C_common_startPage_upcoming_pay6 = "crwdns52301:0crwdne52301:0";
var APPB2C_common_startPage_upcoming_pb6 = "crwdns52287:0crwdne52287:0";
var APPB2C_common_startPage_upcoming_prepare6 = "crwdns52283:0crwdne52283:0";
var APPB2C_common_startPage_upcoming_remote6 = "crwdns52293:0crwdne52293:0";
var APPB2C_common_startPage_upcoming_title6 = "crwdns52279:0crwdne52279:0";
var APPB2C_common_startPage_whichIELTS6 = "crwdns52265:0crwdne52265:0";
var APPB2C_common_terms_downloadInfo6 = "crwdns53289:0crwdne53289:0";
var APPB2C_common_terms_downloadTitle6 = "crwdns53287:0crwdne53287:0";
var APPB2C_common_terms_error6 = "crwdns53291:0crwdne53291:0";
var APPB2C_common_terms_title6 = "crwdns53285:0crwdne53285:0";
var APPB2C_common_testDetails_changeTest6 = "crwdns53159:0crwdne53159:0";
var APPB2C_common_testDetails_changeWrittenTest6 = "crwdns53155:0crwdne53155:0";
var APPB2C_common_testDetails_completeYourBooking6 = "crwdns53163:0crwdne53163:0";
var APPB2C_common_testDetails_mapModal_close6 = "crwdns154237:0crwdne154237:0";
var APPB2C_common_testDetails_mapModal_title6 = "crwdns154239:0crwdne154239:0";
var APPB2C_common_testDetails_modifyNote_confirm6 = "crwdns53169:0crwdne53169:0";
var APPB2C_common_testDetails_modifyNote_note6 = "crwdns53167:0crwdne53167:0";
var APPB2C_common_testDetails_modifyNote_pleaseNote6 = "crwdns53165:0crwdne53165:0";
var APPB2C_common_testDetails_title6 = "crwdns53153:0crwdne53153:0";
var APPB2C_common_testDetails_yourSpeakingTest6 = "crwdns53161:0crwdne53161:0";
var APPB2C_common_testDetails_yourWrittenTest6 = "crwdns53157:0crwdne53157:0";
var APPB2C_common_transfer_agreement6 = "crwdns52655:0crwdne52655:0";
var APPB2C_common_transfer_contactNote6 = "crwdns52649:0crwdne52649:0";
var APPB2C_common_transfer_contactNoteIol6 = "crwdns52651:0crwdne52651:0";
var APPB2C_common_transfer_cta6 = "crwdns52657:0crwdne52657:0";
var APPB2C_common_transfer_form_freeform6 = "crwdns52633:0crwdne52633:0";
var APPB2C_common_transfer_form_reasons_16 = "crwdns52635:0crwdne52635:0";
var APPB2C_common_transfer_form_reasons_26 = "crwdns52637:0crwdne52637:0";
var APPB2C_common_transfer_form_reasons_36 = "crwdns52639:0crwdne52639:0";
var APPB2C_common_transfer_form_reasons_46 = "crwdns52641:0crwdne52641:0";
var APPB2C_common_transfer_form_reasons_choose6 = "crwdns52645:0crwdne52645:0";
var APPB2C_common_transfer_form_reasons_other6 = "crwdns52647:0crwdne52647:0";
var APPB2C_common_transfer_form_reasons_title6 = "crwdns52643:0crwdne52643:0";
var APPB2C_common_transfer_goBack6 = "crwdns152688:0crwdne152688:0";
var APPB2C_common_transfer_notes_16 = "crwdns52621:0crwdne52621:0";
var APPB2C_common_transfer_notes_26 = "crwdns52623:0crwdne52623:0";
var APPB2C_common_transfer_notes_36 = "crwdns52625:0crwdne52625:0";
var APPB2C_common_transfer_notes_46 = "crwdns52627:0crwdne52627:0";
var APPB2C_common_transfer_notesIol_16 = "crwdns52629:0crwdne52629:0";
var APPB2C_common_transfer_notesIol_26 = "crwdns52631:0crwdne52631:0";
var APPB2C_common_transfer_requestValidation6 = "crwdns52653:0crwdne52653:0";
var APPB2C_common_transfer_selfService_availableDates_title6 = "crwdns152690:0crwdne152690:0";
var APPB2C_common_transfer_selfService_chooseDates_title6 = "crwdns152692:0crwdne152692:0";
var APPB2C_common_transfer_selfService_review_confirm_proceedBtn6 = "crwdns152900:0crwdne152900:0";
var APPB2C_common_transfer_selfService_review_confirm_title6 = "crwdns152902:0crwdne152902:0";
var APPB2C_common_transfer_selfService_review_errors_noDistricts6 = "crwdns153526:0crwdne153526:0";
var APPB2C_common_transfer_selfService_review_errors_noLrwSlots6 = "crwdns152904:0crwdne152904:0";
var APPB2C_common_transfer_selfService_review_errors_transferDisabled6 = "crwdns152906:0crwdne152906:0";
var APPB2C_common_transfer_selfService_review_test_changeLrw6 = "crwdns152908:0crwdne152908:0";
var APPB2C_common_transfer_selfService_review_tncs_agreement6 = "crwdns152910:0crwdne152910:0";
var APPB2C_common_transfer_title6 = "crwdns52619:0crwdne52619:0";
var APPB2C_common_underConstruction_message6 = "crwdns53283:0crwdne53283:0";
var APPB2C_common_underConstruction_title6 = "crwdns53281:0crwdne53281:0";
var APPB2C_common_unknownCountry_cta6 = "crwdns53279:0crwdne53279:0";
var APPB2C_common_unknownCountry_header6 = "crwdns53273:0crwdne53273:0";
var APPB2C_common_unknownCountry_msg16 = "crwdns53275:0crwdne53275:0";
var APPB2C_common_unknownCountry_msg26 = "crwdns53277:0crwdne53277:0";
var APPB2C_common_unknownCountry_title6 = "crwdns53271:0crwdne53271:0";
var APPB2C_common_unpaidErrorBar_paymentExpired6 = "crwdns125585:0crwdne125585:0";
var APPB2C_common_updateBar_cancelled6 = "crwdns53253:0crwdne53253:0";
var APPB2C_common_updateBar_cancelPending6 = "crwdns53251:0crwdne53251:0";
var APPB2C_common_updateBar_cancelRejected6 = "crwdns152694:0crwdne152694:0";
var APPB2C_common_updateBar_close6 = "crwdns53249:0crwdne53249:0";
var APPB2C_common_updateBar_details6 = "crwdns148096:0crwdne148096:0";
var APPB2C_common_updateBar_eorCompleted6 = "crwdns53265:0crwdne53265:0";
var APPB2C_common_updateBar_eorCompleted_scoreDecreased6 = "crwdns148098:0{{prevOverallScore}}crwdnd148098:0{{currentOverallScore}}crwdne148098:0";
var APPB2C_common_updateBar_eorCompleted_scoreIncreased6 = "crwdns148100:0{{prevOverallScore}}crwdnd148100:0{{currentOverallScore}}crwdne148100:0";
var APPB2C_common_updateBar_eorCompleted_scoreUnchanged6 = "crwdns148102:0{{currentOverallScore}}crwdne148102:0";
var APPB2C_common_updateBar_eorPending6 = "crwdns53263:0crwdne53263:0";
var APPB2C_common_updateBar_eorPendingAgentPays6 = "crwdns83891:0crwdne83891:0";
var APPB2C_common_updateBar_eorRefunded6 = "crwdns53269:0crwdne53269:0";
var APPB2C_common_updateBar_eorRefundPending6 = "crwdns53267:0crwdne53267:0";
var APPB2C_common_updateBar_eorRejected6 = "crwdns148048:0crwdne148048:0";
var APPB2C_common_updateBar_eorRejected_details5 = "crwdns148050:0crwdne148050:0";
var APPB2C_common_updateBar_eorRejected_overallBand6 = "crwdns148052:0{{overallBand}}crwdne148052:0";
var APPB2C_common_updateBar_hasResults6 = "crwdns53261:0crwdne53261:0";
var APPB2C_common_updateBar_termsUpdated6 = "crwdns137408:0crwdne137408:0";
var APPB2C_common_updateBar_transactionRefundRequestAccepted6 = "crwdns152696:0crwdne152696:0";
var APPB2C_common_updateBar_transactionRefundRequestPending6 = "crwdns152698:0crwdne152698:0";
var APPB2C_common_updateBar_transactionRefundRequestRejected6 = "crwdns152700:0crwdne152700:0";
var APPB2C_common_updateBar_transferCancelled6 = "crwdns152628:0crwdne152628:0";
var APPB2C_common_updateBar_transferPending6 = "crwdns53255:0crwdne53255:0";
var APPB2C_common_updateBar_transferPendingCmds6 = "crwdns152630:0crwdne152630:0";
var APPB2C_common_updateBar_transferred6 = "crwdns53257:0crwdne53257:0";
var APPB2C_common_updateBar_transferredCmds6 = "crwdns152632:0crwdne152632:0";
var APPB2C_registration_bookingDetails_payment6 = "crwdns83893:0crwdne83893:0";
var APPB2C_registration_bookingDetails_results6 = "crwdns83895:0crwdne83895:0";
var APPB2C_registration_bookingDetails_seePayment6 = "crwdns83897:0crwdne83897:0";
var APPB2C_registration_bookingDetails_seeTestTaker6 = "crwdns83899:0crwdne83899:0";
var APPB2C_registration_bookingDetails_whatNext6 = "crwdns83901:0crwdne83901:0";
var APPB2C_registration_date_check_back6 = "crwdns53339:0crwdne53339:0";
var APPB2C_registration_date_check_beforeTestDate6 = "crwdns53341:0crwdne53341:0";
var APPB2C_registration_date_check_intro6 = "crwdns53343:0crwdne53343:0";
var APPB2C_registration_date_check_onTestDate6 = "crwdns53357:0crwdne53357:0";
var APPB2C_registration_date_check_step1_body6 = "crwdns53347:0crwdne53347:0";
var APPB2C_registration_date_check_step1_link6 = "crwdns53349:0crwdne53349:0";
var APPB2C_registration_date_check_step1_title6 = "crwdns53345:0crwdne53345:0";
var APPB2C_registration_date_check_step2_body6 = "crwdns53353:0crwdne53353:0";
var APPB2C_registration_date_check_step2_link6 = "crwdns53355:0crwdne53355:0";
var APPB2C_registration_date_check_step2_title6 = "crwdns53351:0crwdne53351:0";
var APPB2C_registration_date_check_step3_body16 = "crwdns53359:0crwdne53359:0";
var APPB2C_registration_date_check_step3_body26 = "crwdns53369:0crwdne53369:0";
var APPB2C_registration_date_check_step3_noDetailsBody6 = "crwdns53363:0crwdne53363:0";
var APPB2C_registration_date_check_step3_noDetailsTitle6 = "crwdns53361:0crwdne53361:0";
var APPB2C_registration_date_check_step3_noSpeakingPinBody6 = "crwdns53389:0crwdne53389:0";
var APPB2C_registration_date_check_step3_noSpeakingPinTitle6 = "crwdns53387:0crwdne53387:0";
var APPB2C_registration_date_check_step3_password6 = "crwdns53367:0crwdne53367:0";
var APPB2C_registration_date_check_step3_speakingPin6 = "crwdns53383:0crwdne53383:0";
var APPB2C_registration_date_check_step3_step1_body6 = "crwdns53373:0crwdne53373:0";
var APPB2C_registration_date_check_step3_step1_title6 = "crwdns53371:0crwdne53371:0";
var APPB2C_registration_date_check_step3_step2_body6 = "crwdns53377:0crwdne53377:0";
var APPB2C_registration_date_check_step3_step2_title6 = "crwdns53375:0crwdne53375:0";
var APPB2C_registration_date_check_step3_step3_body6 = "crwdns53381:0crwdne53381:0";
var APPB2C_registration_date_check_step3_step3_title6 = "crwdns53379:0crwdne53379:0";
var APPB2C_registration_date_check_step3_username6 = "crwdns53365:0crwdne53365:0";
var APPB2C_registration_date_check_step3_writtenPin6 = "crwdns53385:0crwdne53385:0";
var APPB2C_registration_date_zone_country6 = "crwdns53333:0crwdne53333:0";
var APPB2C_registration_date_zone_header6 = "crwdns53331:0crwdne53331:0";
var APPB2C_registration_date_zone_name6 = "crwdns53337:0crwdne53337:0";
var APPB2C_registration_date_zone_time6 = "crwdns53335:0crwdne53335:0";
var APPB2C_registration_dateLocation_changeLinkIol6 = "crwdns53391:0crwdne53391:0";
var APPB2C_registration_dateLocation_changeLrwLink6 = "crwdns53393:0crwdne53393:0";
var APPB2C_registration_dateLocation_changeSpeakingLink6 = "crwdns53395:0crwdne53395:0";
var APPB2C_registration_dateLocation_location6 = "crwdns53407:0crwdne53407:0";
var APPB2C_registration_dateLocation_manage_cancel6 = "crwdns148376:0crwdne148376:0";
var APPB2C_registration_dateLocation_manage_proceed5 = "crwdns148378:0crwdne148378:0";
var APPB2C_registration_dateLocation_manage_title6 = "crwdns148380:0crwdne148380:0";
var APPB2C_registration_dateLocation_manage_transferSpeaking6 = "crwdns152912:0crwdne152912:0";
var APPB2C_registration_dateLocation_manage_transferWhole6 = "crwdns152704:0crwdne152704:0";
var APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest6 = "crwdns53403:0crwdne53403:0";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody16 = "crwdns53399:0crwdne53399:0";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody26 = "crwdns152914:0crwdne152914:0";
var APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader6 = "crwdns53397:0crwdne53397:0";
var APPB2C_registration_dateLocation_speakingTransfer_requestTransfer6 = "crwdns53405:0crwdne53405:0";
var APPB2C_registration_incorrectOrganisation_homeLink6 = "crwdns153442:0crwdne153442:0";
var APPB2C_registration_incorrectOrganisation_line16 = "crwdns153444:0crwdne153444:0";
var APPB2C_registration_incorrectOrganisation_line26 = "crwdns153446:0crwdne153446:0";
var APPB2C_registration_payments_actions_editProofCTA6 = "crwdns54037:0crwdne54037:0";
var APPB2C_registration_payments_actions_header6 = "crwdns54025:0crwdne54025:0";
var APPB2C_registration_payments_actions_paymentInProgressInfo6 = "crwdns54029:0crwdne54029:0";
var APPB2C_registration_payments_actions_payOfflineCTA6 = "crwdns54031:0crwdne54031:0";
var APPB2C_registration_payments_actions_payOnlineCTA6 = "crwdns54027:0crwdne54027:0";
var APPB2C_registration_payments_actions_subHeader6 = "crwdns54033:0crwdne54033:0";
var APPB2C_registration_payments_actions_uploadProofCTA6 = "crwdns54035:0crwdne54035:0";
var APPB2C_registration_payments_awaitingPayment6 = "crwdns53949:0crwdne53949:0";
var APPB2C_registration_payments_back6 = "crwdns53953:0crwdne53953:0";
var APPB2C_registration_payments_completed6 = "crwdns53951:0crwdne53951:0";
var APPB2C_registration_payments_expired6 = "crwdns125587:0crwdne125587:0";
var APPB2C_registration_payments_finishPayment_error_cta6 = "crwdns54023:0crwdne54023:0";
var APPB2C_registration_payments_finishPayment_error_messageLine16 = "crwdns54019:0crwdne54019:0";
var APPB2C_registration_payments_finishPayment_error_messageLine26 = "crwdns54021:0crwdne54021:0";
var APPB2C_registration_payments_finishPayment_error_title6 = "crwdns54017:0crwdne54017:0";
var APPB2C_registration_payments_finishPayment_failure_alert6 = "crwdns54011:0crwdne54011:0";
var APPB2C_registration_payments_finishPayment_failure_cta6 = "crwdns54015:0crwdne54015:0";
var APPB2C_registration_payments_finishPayment_failure_message6 = "crwdns54013:0crwdne54013:0";
var APPB2C_registration_payments_finishPayment_failure_title6 = "crwdns54009:0crwdne54009:0";
var APPB2C_registration_payments_finishPayment_pleaseWait6 = "crwdns53999:0crwdne53999:0";
var APPB2C_registration_payments_finishPayment_processing6 = "crwdns53997:0crwdne53997:0";
var APPB2C_registration_payments_finishPayment_success_alert6 = "crwdns54003:0crwdne54003:0";
var APPB2C_registration_payments_finishPayment_success_cta6 = "crwdns54007:0crwdne54007:0";
var APPB2C_registration_payments_finishPayment_success_message6 = "crwdns54005:0crwdne54005:0";
var APPB2C_registration_payments_finishPayment_success_title6 = "crwdns54001:0crwdne54001:0";
var APPB2C_registration_payments_offlineDetails_accountName6 = "crwdns54041:0crwdne54041:0";
var APPB2C_registration_payments_offlineDetails_accountNumber6 = "crwdns54043:0crwdne54043:0";
var APPB2C_registration_payments_offlineDetails_payBy6 = "crwdns54039:0crwdne54039:0";
var APPB2C_registration_payments_offlineDetails_sortCode6 = "crwdns54045:0crwdne54045:0";
var APPB2C_registration_payments_paidByAgent6 = "crwdns148082:0crwdne148082:0";
var APPB2C_registration_payments_proofPreview_fileAlt6 = "crwdns54067:0crwdne54067:0";
var APPB2C_registration_payments_proofPreview_proofLabel6 = "crwdns54065:0crwdne54065:0";
var APPB2C_registration_payments_proofUpload_cta6 = "crwdns54051:0crwdne54051:0";
var APPB2C_registration_payments_proofUpload_info6 = "crwdns54049:0crwdne54049:0";
var APPB2C_registration_payments_proofUpload_intro6 = "crwdns54047:0crwdne54047:0";
var APPB2C_registration_payments_proofUpload_rules_intro6 = "crwdns54053:0crwdne54053:0";
var APPB2C_registration_payments_proofUpload_rules_rule16 = "crwdns54055:0crwdne54055:0";
var APPB2C_registration_payments_proofUpload_rules_rule26 = "crwdns54057:0crwdne54057:0";
var APPB2C_registration_payments_proofUpload_rules_rule36 = "crwdns54059:0crwdne54059:0";
var APPB2C_registration_payments_proofUpload_rules_rule46 = "crwdns54061:0crwdne54061:0";
var APPB2C_registration_payments_proofUpload_rules_rule56 = "crwdns54063:0crwdne54063:0";
var APPB2C_registration_payments_transaction_cancelledSuffix6 = "crwdns53969:0crwdne53969:0";
var APPB2C_registration_payments_transaction_deadlineWarning_default6 = "crwdns53987:0{{date}}crwdne53987:0";
var APPB2C_registration_payments_transaction_deadlineWarning_eor6 = "crwdns53991:0{{date}}crwdne53991:0";
var APPB2C_registration_payments_transaction_deadlineWarning_registration6 = "crwdns53989:0{{date}}crwdne53989:0";
var APPB2C_registration_payments_transaction_description_appeal6 = "crwdns53963:0crwdne53963:0";
var APPB2C_registration_payments_transaction_description_eor6 = "crwdns53961:0crwdne53961:0";
var APPB2C_registration_payments_transaction_description_registration6 = "crwdns53957:0crwdne53957:0";
var APPB2C_registration_payments_transaction_description_transfer6 = "crwdns53959:0crwdne53959:0";
var APPB2C_registration_payments_transaction_feeLabel6 = "crwdns53975:0crwdne53975:0";
var APPB2C_registration_payments_transaction_header6 = "crwdns53955:0crwdne53955:0";
var APPB2C_registration_payments_transaction_hideDetails6 = "crwdns53971:0crwdne53971:0";
var APPB2C_registration_payments_transaction_outdatedTermsLabel6 = "crwdns53995:0crwdne53995:0";
var APPB2C_registration_payments_transaction_pastDeadline6 = "crwdns125589:0crwdne125589:0";
var APPB2C_registration_payments_transaction_pastDeadlineContactUs6 = "crwdns125591:0crwdne125591:0";
var APPB2C_registration_payments_transaction_pastDeadlineEmail6 = "crwdns125593:0crwdne125593:0";
var APPB2C_registration_payments_transaction_pastDeadlinePhone6 = "crwdns125595:0crwdne125595:0";
var APPB2C_registration_payments_transaction_promoCode6 = "crwdns130992:0crwdne130992:0";
var APPB2C_registration_payments_transaction_receipt6 = "crwdns53983:0crwdne53983:0";
var APPB2C_registration_payments_transaction_receiptCta6 = "crwdns53985:0crwdne53985:0";
var APPB2C_registration_payments_transaction_referenceLabel6 = "crwdns53981:0crwdne53981:0";
var APPB2C_registration_payments_transaction_showDetails6 = "crwdns53973:0crwdne53973:0";
var APPB2C_registration_payments_transaction_taxLabel6 = "crwdns53977:0crwdne53977:0";
var APPB2C_registration_payments_transaction_termsLabel6 = "crwdns53993:0crwdne53993:0";
var APPB2C_registration_payments_transaction_totalLabel6 = "crwdns53979:0crwdne53979:0";
var APPB2C_registration_payments_transaction_underReviewSuffix6 = "crwdns53965:0crwdne53965:0";
var APPB2C_registration_payments_transaction_unpaidSuffix6 = "crwdns53967:0crwdne53967:0";
var APPB2C_registration_payments_unpaid6 = "crwdns53947:0crwdne53947:0";
var APPB2C_registration_prepare_a1_cta6 = "crwdns83903:0crwdne83903:0";
var APPB2C_registration_prepare_a1_header6 = "crwdns54223:0crwdne54223:0";
var APPB2C_registration_prepare_a2_cta6 = "crwdns83905:0crwdne83905:0";
var APPB2C_registration_prepare_a2_header6 = "crwdns54227:0crwdne54227:0";
var APPB2C_registration_prepare_b1_cta6 = "crwdns54233:0crwdne54233:0";
var APPB2C_registration_prepare_b1_header6 = "crwdns54231:0crwdne54231:0";
var APPB2C_registration_prepare_blog_body_cta6 = "crwdns54137:0crwdne54137:0";
var APPB2C_registration_prepare_blog_body_line16 = "crwdns54135:0crwdne54135:0";
var APPB2C_registration_prepare_blog_header6 = "crwdns54133:0crwdne54133:0";
var APPB2C_registration_prepare_cdFamiliarTest_body_cta6 = "crwdns54185:0crwdne54185:0";
var APPB2C_registration_prepare_cdFamiliarTest_body_line16 = "crwdns54183:0crwdne54183:0";
var APPB2C_registration_prepare_cdFamiliarTest_header6 = "crwdns54181:0crwdne54181:0";
var APPB2C_registration_prepare_cdFamiliarVideos_body_cta6 = "crwdns54179:0crwdne54179:0";
var APPB2C_registration_prepare_cdFamiliarVideos_body_line16 = "crwdns54177:0crwdne54177:0";
var APPB2C_registration_prepare_cdFamiliarVideos_header6 = "crwdns54175:0crwdne54175:0";
var APPB2C_registration_prepare_cdTests_body_cta6 = "crwdns54167:0crwdne54167:0";
var APPB2C_registration_prepare_cdTests_body_line16 = "crwdns54165:0crwdne54165:0";
var APPB2C_registration_prepare_cdTests_header6 = "crwdns54163:0crwdne54163:0";
var APPB2C_registration_prepare_cdTipsVideos_body_cta6 = "crwdns54173:0crwdne54173:0";
var APPB2C_registration_prepare_cdTipsVideos_body_line16 = "crwdns54171:0crwdne54171:0";
var APPB2C_registration_prepare_cdTipsVideos_header6 = "crwdns54169:0crwdne54169:0";
var APPB2C_registration_prepare_facebook_body_cta6 = "crwdns54143:0crwdne54143:0";
var APPB2C_registration_prepare_facebook_body_line16 = "crwdns54141:0crwdne54141:0";
var APPB2C_registration_prepare_facebook_header6 = "crwdns54139:0crwdne54139:0";
var APPB2C_registration_prepare_gel_disabledMsg6 = "crwdns138842:0crwdne138842:0";
var APPB2C_registration_prepare_gel_ielts_body_cta6 = "crwdns138844:0crwdne138844:0";
var APPB2C_registration_prepare_gel_ielts_body_line16 = "crwdns138846:0crwdne138846:0";
var APPB2C_registration_prepare_gel_ielts_header6 = "crwdns138848:0crwdne138848:0";
var APPB2C_registration_prepare_gel_ukvi_body_cta5 = "crwdns54209:0crwdne54209:0";
var APPB2C_registration_prepare_gel_ukvi_body_line16 = "crwdns138850:0crwdne138850:0";
var APPB2C_registration_prepare_gel_ukvi_header6 = "crwdns138852:0crwdne138852:0";
var APPB2C_registration_prepare_header6 = "crwdns54069:0crwdne54069:0";
var APPB2C_registration_prepare_ieltsReady_subTitle6 = "crwdns83907:0crwdne83907:0";
var APPB2C_registration_prepare_ieltsReady_title6 = "crwdns83909:0crwdne83909:0";
var APPB2C_registration_prepare_ieltsReadyMember_body6 = "crwdns138854:0crwdne138854:0";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn6 = "crwdns83913:0crwdne83913:0";
var APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo6 = "crwdns138856:0crwdne138856:0";
var APPB2C_registration_prepare_ieltsReadyMember_freePrepLink6 = "crwdns83917:0crwdne83917:0";
var APPB2C_registration_prepare_ieltsReadyMember_header6 = "crwdns138858:0crwdne138858:0";
var APPB2C_registration_prepare_ieltsReadyMember_label6 = "crwdns138860:0crwdne138860:0";
var APPB2C_registration_prepare_ieltsReadyMember_link6 = "crwdns138862:0crwdne138862:0";
var APPB2C_registration_prepare_ieltsReadyMember_payBtn6 = "crwdns125828:0crwdne125828:0";
var APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo6 = "crwdns138864:0crwdne138864:0";
var APPB2C_registration_prepare_intro6 = "crwdns54071:0crwdne54071:0";
var APPB2C_registration_prepare_introLifeSkills6 = "crwdns54073:0crwdne54073:0";
var APPB2C_registration_prepare_onlineCourse_body_cta6 = "crwdns54109:0crwdne54109:0";
var APPB2C_registration_prepare_onlineCourse_body_line16 = "crwdns54107:0crwdne54107:0";
var APPB2C_registration_prepare_onlineCourse_header6 = "crwdns54105:0crwdne54105:0";
var APPB2C_registration_prepare_pbPractice_body_cta6 = "crwdns54191:0crwdne54191:0";
var APPB2C_registration_prepare_pbPractice_body_line16 = "crwdns54189:0crwdne54189:0";
var APPB2C_registration_prepare_pbPractice_header6 = "crwdns54187:0crwdne54187:0";
var APPB2C_registration_prepare_practice_body_cta6 = "crwdns54221:0crwdne54221:0";
var APPB2C_registration_prepare_practice_body_line16 = "crwdns54219:0crwdne54219:0";
var APPB2C_registration_prepare_practice_header6 = "crwdns54217:0crwdne54217:0";
var APPB2C_registration_prepare_prepApp_body_cta6 = "crwdns54123:0crwdne54123:0";
var APPB2C_registration_prepare_prepApp_body_line16 = "crwdns54121:0crwdne54121:0";
var APPB2C_registration_prepare_prepApp_header6 = "crwdns54119:0crwdne54119:0";
var APPB2C_registration_prepare_prepApp_ukvi_cta16 = "crwdns54129:0crwdne54129:0";
var APPB2C_registration_prepare_prepApp_ukvi_cta26 = "crwdns54131:0crwdne54131:0";
var APPB2C_registration_prepare_prepApp_ukvi_line16 = "crwdns54125:0crwdne54125:0";
var APPB2C_registration_prepare_prepApp_ukvi_line26 = "crwdns54127:0crwdne54127:0";
var APPB2C_registration_prepare_prepVideos_body_cta6 = "crwdns54149:0crwdne54149:0";
var APPB2C_registration_prepare_prepVideos_body_line16 = "crwdns54147:0crwdne54147:0";
var APPB2C_registration_prepare_prepVideos_header6 = "crwdns54145:0crwdne54145:0";
var APPB2C_registration_prepare_reading_body_cta6 = "crwdns54161:0crwdne54161:0";
var APPB2C_registration_prepare_reading_body_line16 = "crwdns54159:0crwdne54159:0";
var APPB2C_registration_prepare_reading_header6 = "crwdns54157:0crwdne54157:0";
var APPB2C_registration_prepare_rteLastMinute_body_cta6 = "crwdns54085:0crwdne54085:0";
var APPB2C_registration_prepare_rteLastMinute_body_item16 = "crwdns54079:0crwdne54079:0";
var APPB2C_registration_prepare_rteLastMinute_body_item26 = "crwdns54081:0crwdne54081:0";
var APPB2C_registration_prepare_rteLastMinute_body_item36 = "crwdns54083:0crwdne54083:0";
var APPB2C_registration_prepare_rteLastMinute_body_line16 = "crwdns54077:0crwdne54077:0";
var APPB2C_registration_prepare_rteLastMinute_header6 = "crwdns54075:0crwdne54075:0";
var APPB2C_registration_prepare_rteLastMinute_ukvi_cta6 = "crwdns54089:0crwdne54089:0";
var APPB2C_registration_prepare_rteLastMinute_ukvi_line16 = "crwdns54087:0crwdne54087:0";
var APPB2C_registration_prepare_rteTestDrive_body_cta6 = "crwdns54103:0crwdne54103:0";
var APPB2C_registration_prepare_rteTestDrive_body_item16 = "crwdns54095:0crwdne54095:0";
var APPB2C_registration_prepare_rteTestDrive_body_item26 = "crwdns54097:0crwdne54097:0";
var APPB2C_registration_prepare_rteTestDrive_body_item36 = "crwdns54099:0crwdne54099:0";
var APPB2C_registration_prepare_rteTestDrive_body_line16 = "crwdns54093:0crwdne54093:0";
var APPB2C_registration_prepare_rteTestDrive_body_line26 = "crwdns54101:0crwdne54101:0";
var APPB2C_registration_prepare_rteTestDrive_header6 = "crwdns54091:0crwdne54091:0";
var APPB2C_registration_prepare_speaking_body_cta6 = "crwdns54155:0crwdne54155:0";
var APPB2C_registration_prepare_speaking_body_line16 = "crwdns54153:0crwdne54153:0";
var APPB2C_registration_prepare_speaking_header6 = "crwdns54151:0crwdne54151:0";
var APPB2C_registration_prepare_tutor_body_cta6 = "crwdns54117:0crwdne54117:0";
var APPB2C_registration_prepare_tutor_body_line16 = "crwdns54113:0crwdne54113:0";
var APPB2C_registration_prepare_tutor_body_line26 = "crwdns54115:0crwdne54115:0";
var APPB2C_registration_prepare_tutor_header6 = "crwdns54111:0crwdne54111:0";
var APPB2C_registration_prepare_webinars_body_cta6 = "crwdns54203:0crwdne54203:0";
var APPB2C_registration_prepare_webinars_body_item16 = "crwdns54197:0crwdne54197:0";
var APPB2C_registration_prepare_webinars_body_item26 = "crwdns54199:0crwdne54199:0";
var APPB2C_registration_prepare_webinars_body_item36 = "crwdns54201:0crwdne54201:0";
var APPB2C_registration_prepare_webinars_body_line16 = "crwdns54195:0crwdne54195:0";
var APPB2C_registration_prepare_webinars_header6 = "crwdns54193:0crwdne54193:0";
var APPB2C_registration_results_bandScore_osrBooked6 = "crwdns83923:0crwdne83923:0";
var APPB2C_registration_results_bandScore_osrMarked6 = "crwdns83925:0crwdne83925:0";
var APPB2C_registration_results_eor_acknowledgement_backButton6 = "crwdns53729:0crwdne53729:0";
var APPB2C_registration_results_eor_acknowledgement_components6 = "crwdns53713:0crwdne53713:0";
var APPB2C_registration_results_eor_acknowledgement_confirmation6 = "crwdns53711:0crwdne53711:0";
var APPB2C_registration_results_eor_acknowledgement_fee6 = "crwdns53719:0crwdne53719:0";
var APPB2C_registration_results_eor_acknowledgement_info6 = "crwdns130996:0crwdne130996:0";
var APPB2C_registration_results_eor_acknowledgement_noteTitle6 = "crwdns53715:0crwdne53715:0";
var APPB2C_registration_results_eor_acknowledgement_payment6 = "crwdns53717:0crwdne53717:0";
var APPB2C_registration_results_eor_acknowledgement_paymentButton6 = "crwdns53733:0crwdne53733:0";
var APPB2C_registration_results_eor_acknowledgement_paymentNote6 = "crwdns53731:0crwdne53731:0";
var APPB2C_registration_results_eor_acknowledgement_reference6 = "crwdns53725:0crwdne53725:0";
var APPB2C_registration_results_eor_acknowledgement_success6 = "crwdns53709:0crwdne53709:0";
var APPB2C_registration_results_eor_acknowledgement_tax6 = "crwdns53721:0crwdne53721:0";
var APPB2C_registration_results_eor_acknowledgement_terms6 = "crwdns53727:0crwdne53727:0";
var APPB2C_registration_results_eor_acknowledgement_title6 = "crwdns53707:0crwdne53707:0";
var APPB2C_registration_results_eor_acknowledgement_total6 = "crwdns53723:0crwdne53723:0";
var APPB2C_registration_results_eor_postDeadline_back6 = "crwdns53699:0crwdne53699:0";
var APPB2C_registration_results_eor_postDeadline_email6 = "crwdns53703:0crwdne53703:0";
var APPB2C_registration_results_eor_postDeadline_help6 = "crwdns53705:0crwdne53705:0";
var APPB2C_registration_results_eor_postDeadline_iol_launchChatLine6 = "crwdns83927:0crwdne83927:0";
var APPB2C_registration_results_eor_postDeadline_iol_line36 = "crwdns83929:0crwdne83929:0";
var APPB2C_registration_results_eor_postDeadline_line16 = "crwdns53693:0{{count}}crwdne53693:0";
var APPB2C_registration_results_eor_postDeadline_line26 = "crwdns53695:0crwdne53695:0";
var APPB2C_registration_results_eor_postDeadline_line36 = "crwdns53697:0crwdne53697:0";
var APPB2C_registration_results_eor_postDeadline_phone6 = "crwdns53701:0crwdne53701:0";
var APPB2C_registration_results_eor_preDeadline_back6 = "crwdns53651:0crwdne53651:0";
var APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength6 = "crwdns152708:0{{count}}crwdne152708:0";
var APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber6 = "crwdns152710:0crwdne152710:0";
var APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber6 = "crwdns152712:0crwdne152712:0";
var APPB2C_registration_results_eor_preDeadline_evidence6 = "crwdns53669:0crwdne53669:0";
var APPB2C_registration_results_eor_preDeadline_evidenceRequired6 = "crwdns53671:0crwdne53671:0";
var APPB2C_registration_results_eor_preDeadline_explanation6 = "crwdns53665:0crwdne53665:0";
var APPB2C_registration_results_eor_preDeadline_explanationRequired6 = "crwdns53667:0crwdne53667:0";
var APPB2C_registration_results_eor_preDeadline_fee6 = "crwdns53647:0crwdne53647:0";
var APPB2C_registration_results_eor_preDeadline_iolNote_line15 = "crwdns53673:0crwdne53673:0";
var APPB2C_registration_results_eor_preDeadline_iolNote_line26 = "crwdns148104:0crwdne148104:0";
var APPB2C_registration_results_eor_preDeadline_iolNote_line36 = "crwdns53677:0crwdne53677:0";
var APPB2C_registration_results_eor_preDeadline_legal6 = "crwdns53681:0crwdne53681:0";
var APPB2C_registration_results_eor_preDeadline_legalNote6 = "crwdns53683:0crwdne53683:0";
var APPB2C_registration_results_eor_preDeadline_line16 = "crwdns83931:0{{count}}crwdne83931:0";
var APPB2C_registration_results_eor_preDeadline_line26 = "crwdns53643:0crwdne53643:0";
var APPB2C_registration_results_eor_preDeadline_maxScore6 = "crwdns53661:0crwdne53661:0";
var APPB2C_registration_results_eor_preDeadline_note6 = "crwdns53645:0crwdne53645:0";
var APPB2C_registration_results_eor_preDeadline_noteTitle6 = "crwdns53679:0crwdne53679:0";
var APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel6 = "crwdns53685:0crwdne53685:0";
var APPB2C_registration_results_eor_preDeadline_paymentCta6 = "crwdns53687:0crwdne53687:0";
var APPB2C_registration_results_eor_preDeadline_paymentTitle6 = "crwdns53641:0crwdne53641:0";
var APPB2C_registration_results_eor_preDeadline_payOfflineCTA6 = "crwdns53691:0crwdne53691:0";
var APPB2C_registration_results_eor_preDeadline_payOnlineCTA6 = "crwdns53689:0crwdne53689:0";
var APPB2C_registration_results_eor_preDeadline_scoresRequired6 = "crwdns53663:0crwdne53663:0";
var APPB2C_registration_results_eor_preDeadline_scoresTitle6 = "crwdns53653:0crwdne53653:0";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr6 = "crwdns83933:0crwdne83933:0";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening6 = "crwdns83935:0crwdne83935:0";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading6 = "crwdns83937:0crwdne83937:0";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking6 = "crwdns83939:0crwdne83939:0";
var APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing6 = "crwdns83941:0crwdne83941:0";
var APPB2C_registration_results_eor_preDeadline_tax6 = "crwdns53649:0crwdne53649:0";
var APPB2C_registration_results_eor_preDeadline_trfNumberLabel6 = "crwdns53655:0crwdne53655:0";
var APPB2C_registration_results_eor_preDeadline_trfNumberSubnote6 = "crwdns53657:0crwdne53657:0";
var APPB2C_registration_results_eor_preDeadline_trfNumberValidation6 = "crwdns53659:0crwdne53659:0";
var APPB2C_registration_results_eor_title6 = "crwdns83943:0crwdne83943:0";
var APPB2C_registration_results_eor_titlePayment6 = "crwdns53637:0crwdne53637:0";
var APPB2C_registration_results_hasResults_bandScore_decrease6 = "crwdns148106:0crwdne148106:0";
var APPB2C_registration_results_hasResults_bandScore_increase6 = "crwdns148108:0crwdne148108:0";
var APPB2C_registration_results_hasResults_bandScore_unchanged6 = "crwdns148110:0crwdne148110:0";
var APPB2C_registration_results_hasResults_eorAgentInfo5 = "crwdns53625:0crwdne53625:0";
var APPB2C_registration_results_hasResults_eorLetter6 = "crwdns152634:0crwdne152634:0";
var APPB2C_registration_results_hasResults_eorLine16 = "crwdns152636:0crwdne152636:0";
var APPB2C_registration_results_hasResults_eorLine26 = "crwdns152638:0crwdne152638:0";
var APPB2C_registration_results_hasResults_eorLink6 = "crwdns53623:0crwdne53623:0";
var APPB2C_registration_results_hasResults_eTrfCta5 = "crwdns53621:0crwdne53621:0";
var APPB2C_registration_results_hasResults_explanations_heading6 = "crwdns53607:0crwdne53607:0";
var APPB2C_registration_results_hasResults_explanations_hideButton5 = "crwdns53605:0crwdne53605:0";
var APPB2C_registration_results_hasResults_explanations_improve6 = "crwdns53609:0crwdne53609:0";
var APPB2C_registration_results_hasResults_explanations_showButton5 = "crwdns53603:0crwdne53603:0";
var APPB2C_registration_results_hasResults_lifeSkills_failed6 = "crwdns53595:0crwdne53595:0";
var APPB2C_registration_results_hasResults_lifeSkills_level6 = "crwdns53591:0crwdne53591:0";
var APPB2C_registration_results_hasResults_lifeSkills_note6 = "crwdns53597:0crwdne53597:0";
var APPB2C_registration_results_hasResults_lifeSkills_passed6 = "crwdns53593:0crwdne53593:0";
var APPB2C_registration_results_hasResults_lifeSkills_result6 = "crwdns53589:0crwdne53589:0";
var APPB2C_registration_results_hasResults_osr_bandScore_decrease5 = "crwdns83945:0crwdne83945:0";
var APPB2C_registration_results_hasResults_osr_bandScore_increase5 = "crwdns83947:0crwdne83947:0";
var APPB2C_registration_results_hasResults_osr_bandScore_unchanged5 = "crwdns83949:0crwdne83949:0";
var APPB2C_registration_results_hasResults_osr_previousResults6 = "crwdns83951:0crwdne83951:0";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased6 = "crwdns83953:0{{originalScore}}crwdnd83953:0{{osrScore}}crwdne83953:0";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased6 = "crwdns83955:0{{originalScore}}crwdnd83955:0{{osrScore}}crwdne83955:0";
var APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged6 = "crwdns83957:0{{originalScore}}crwdne83957:0";
var APPB2C_registration_results_hasResults_osr_updateBar_title6 = "crwdns83959:0{{osrSkillName}}crwdnd83959:0{{score}}crwdne83959:0";
var APPB2C_registration_results_hasResults_osrLink5 = "crwdns53633:0crwdne53633:0";
var APPB2C_registration_results_hasResults_posting_oneCopyWarning6 = "crwdns53619:0crwdne53619:0";
var APPB2C_registration_results_hasResults_posting_postedOn6 = "crwdns53611:0crwdne53611:0";
var APPB2C_registration_results_hasResults_posting_postedTo6 = "crwdns53615:0crwdne53615:0";
var APPB2C_registration_results_hasResults_posting_willPostOn6 = "crwdns53613:0crwdne53613:0";
var APPB2C_registration_results_hasResults_posting_willPostTo6 = "crwdns53617:0crwdne53617:0";
var APPB2C_registration_results_hasResults_rankNameConsonant6 = "crwdns53599:0crwdne53599:0";
var APPB2C_registration_results_hasResults_rankNameVowel6 = "crwdns53601:0crwdne53601:0";
var APPB2C_registration_results_hasResults_scoreImprove_btn6 = "crwdns125832:0crwdne125832:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn6 = "crwdns83963:0crwdne83963:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description6 = "crwdns83965:0crwdne83965:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title6 = "crwdns83967:0crwdne83967:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description6 = "crwdns83969:0{{count}}crwdne83969:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title6 = "crwdns83971:0crwdne83971:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description6 = "crwdns83973:0crwdne83973:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title6 = "crwdns130998:0crwdne130998:0";
var APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle6 = "crwdns83977:0crwdne83977:0";
var APPB2C_registration_results_hasResults_scoreImprove_subtitle6 = "crwdns125834:0crwdne125834:0";
var APPB2C_registration_results_hasResults_scoreImprove_title6 = "crwdns83981:0crwdne83981:0";
var APPB2C_registration_results_hasResults_scores_label6 = "crwdns83983:0crwdne83983:0";
var APPB2C_registration_results_hasResults_scores_Listening6 = "crwdns53579:0crwdne53579:0";
var APPB2C_registration_results_hasResults_scores_Overall6 = "crwdns83985:0crwdne83985:0";
var APPB2C_registration_results_hasResults_scores_Reading6 = "crwdns53581:0crwdne53581:0";
var APPB2C_registration_results_hasResults_scores_Speaking6 = "crwdns53585:0crwdne53585:0";
var APPB2C_registration_results_hasResults_scores_Writing6 = "crwdns53583:0crwdne53583:0";
var APPB2C_registration_results_hasResults_share_applyBoard6 = "crwdns126206:0crwdne126206:0";
var APPB2C_registration_results_hasResults_share_RecognisingOrgsLink6 = "crwdns83987:0crwdne83987:0";
var APPB2C_registration_results_hasResults_share_subtitle6 = "crwdns83989:0crwdne83989:0";
var APPB2C_registration_results_hasResults_share_title6 = "crwdns83991:0crwdne83991:0";
var APPB2C_registration_results_hasResults_share_trfCta6 = "crwdns83993:0crwdne83993:0";
var APPB2C_registration_results_hasResults_viewExplanation6 = "crwdns83995:0crwdne83995:0";
var APPB2C_registration_results_noResults_cmds_title6 = "crwdns131000:0crwdne131000:0";
var APPB2C_registration_results_noResults_note_title6 = "crwdns53753:0crwdne53753:0";
var APPB2C_registration_results_noResults_osr_booked6 = "crwdns83997:0crwdne83997:0";
var APPB2C_registration_results_noResults_osr_previousScores6 = "crwdns83999:0crwdne83999:0";
var APPB2C_registration_results_noResults_title6 = "crwdns131002:0crwdne131002:0";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote16 = 'crwdns53779:0$t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })crwdne53779:0';
var APPB2C_registration_results_organisations_availableReceivingOrgsNote26 = "crwdns53781:0{{date}}crwdne53781:0";
var APPB2C_registration_results_organisations_availableReceivingOrgsNote35 = "crwdns153602:0crwdne153602:0";
var APPB2C_registration_results_organisations_chosenOrganisationsHeader6 = "crwdns53757:0crwdne53757:0";
var APPB2C_registration_results_organisations_editRo_addAnother6 = "crwdns53945:0crwdne53945:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew6 = "crwdns53873:0crwdne53873:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_address6 = "crwdns53881:0crwdne53881:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_country6 = "crwdns53861:0crwdne53861:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll6 = "crwdns53865:0crwdne53865:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional6 = "crwdns53863:0crwdne53863:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_file6 = "crwdns53879:0crwdne53879:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_org6 = "crwdns53869:0crwdne53869:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation6 = "crwdns53875:0crwdne53875:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_person6 = "crwdns53877:0crwdne53877:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode6 = "crwdns53883:0crwdne53883:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry6 = "crwdns53867:0crwdne53867:0";
var APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg6 = "crwdns53871:0crwdne53871:0";
var APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength6 = "crwdns152640:0{{count}}crwdne152640:0";
var APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength6 = "crwdns152642:0{{count}}crwdne152642:0";
var APPB2C_registration_results_organisations_editRo_error_minScoreMissing6 = "crwdns152644:0crwdne152644:0";
var APPB2C_registration_results_organisations_editRo_error_missingAddressLine16 = "crwdns152646:0crwdne152646:0";
var APPB2C_registration_results_organisations_editRo_error_missingOrgName6 = "crwdns152648:0crwdne152648:0";
var APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength6 = "crwdns152650:0{{count}}crwdne152650:0";
var APPB2C_registration_results_organisations_editRo_iol_addCta6 = "crwdns53935:0crwdne53935:0";
var APPB2C_registration_results_organisations_editRo_iol_address6 = "crwdns53893:0crwdne53893:0";
var APPB2C_registration_results_organisations_editRo_iol_cancelAdd5 = "crwdns53889:0crwdne53889:0";
var APPB2C_registration_results_organisations_editRo_iol_cancelEdit6 = "crwdns53887:0crwdne53887:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_clear6 = "crwdns53927:0crwdne53927:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_header6 = "crwdns53915:0crwdne53915:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder6 = "crwdns53923:0crwdne53923:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_no6 = "crwdns53919:0crwdne53919:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note16 = "crwdns53929:0crwdne53929:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note26 = "crwdns53931:0crwdne53931:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_note36 = "crwdns53933:0crwdne53933:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder6 = "crwdns53925:0crwdne53925:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader6 = "crwdns53917:0crwdne53917:0";
var APPB2C_registration_results_organisations_editRo_iol_conditions_yes6 = "crwdns53921:0crwdne53921:0";
var APPB2C_registration_results_organisations_editRo_iol_copied6 = "crwdns53897:0crwdne53897:0";
var APPB2C_registration_results_organisations_editRo_iol_copy6 = "crwdns53895:0crwdne53895:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery6 = "crwdns153448:0crwdne153448:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults6 = "crwdns153450:0crwdne153450:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note6 = "crwdns153452:0{{number}}crwdne153452:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal6 = "crwdns137410:0{{number}}crwdne137410:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf6 = "crwdns53905:0crwdne53905:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note16 = "crwdns153454:0crwdne153454:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note26 = "crwdns53901:0crwdne53901:0";
var APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results6 = "crwdns53903:0crwdne53903:0";
var APPB2C_registration_results_organisations_editRo_iol_newOrgTitle6 = "crwdns53885:0crwdne53885:0";
var APPB2C_registration_results_organisations_editRo_iol_noResults6 = "crwdns53891:0crwdne53891:0";
var APPB2C_registration_results_organisations_editRo_iol_removeLink6 = "crwdns53939:0crwdne53939:0";
var APPB2C_registration_results_organisations_editRo_iol_saveCta6 = "crwdns53937:0crwdne53937:0";
var APPB2C_registration_results_organisations_editRo_iol_validation_overallScore6 = "crwdns53943:0crwdne53943:0";
var APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation6 = "crwdns53941:0crwdne53941:0";
var APPB2C_registration_results_organisations_iol_chooseCta6 = "crwdns53845:0crwdne53845:0";
var APPB2C_registration_results_organisations_iol_delivery_auto_header6 = "crwdns153456:0crwdne153456:0";
var APPB2C_registration_results_organisations_iol_delivery_auto_note6 = "crwdns153458:0crwdne153458:0";
var APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote6 = "crwdns153460:0crwdne153460:0";
var APPB2C_registration_results_organisations_iol_delivery_auto_sentNote6 = "crwdns153462:0crwdne153462:0";
var APPB2C_registration_results_organisations_iol_delivery_postal_header6 = "crwdns53829:0crwdne53829:0";
var APPB2C_registration_results_organisations_iol_delivery_postal_note16 = "crwdns153464:0crwdne153464:0";
var APPB2C_registration_results_organisations_iol_delivery_postal_note26 = "crwdns53833:0crwdne53833:0";
var APPB2C_registration_results_organisations_iol_delivery_postal_note2_results6 = "crwdns53835:0crwdne53835:0";
var APPB2C_registration_results_organisations_iol_edit6 = "crwdns53855:0crwdne53855:0";
var APPB2C_registration_results_organisations_iol_error6 = "crwdns53857:0crwdne53857:0";
var APPB2C_registration_results_organisations_iol_generalCondition6 = "crwdns53849:0crwdne53849:0";
var APPB2C_registration_results_organisations_iol_noConditions6 = "crwdns53847:0crwdne53847:0";
var APPB2C_registration_results_organisations_iol_notChosen6 = "crwdns53853:0crwdne53853:0";
var APPB2C_registration_results_organisations_iol_preciseCondition6 = "crwdns53851:0crwdne53851:0";
var APPB2C_registration_results_organisations_iol_roAccept_link6 = "crwdns53827:0crwdne53827:0";
var APPB2C_registration_results_organisations_iol_roAccept_message6 = "crwdns53825:0crwdne53825:0";
var APPB2C_registration_results_organisations_iol_roAccept_title6 = "crwdns53823:0crwdne53823:0";
var APPB2C_registration_results_organisations_iol_sendAnyway6 = "crwdns53859:0crwdne53859:0";
var APPB2C_registration_results_organisations_list_item16 = "crwdns53771:0crwdne53771:0";
var APPB2C_registration_results_organisations_list_item26 = "crwdns53773:0crwdne53773:0";
var APPB2C_registration_results_organisations_list_item36 = "crwdns53775:0crwdne53775:0";
var APPB2C_registration_results_organisations_list_item46 = "crwdns53777:0crwdne53777:0";
var APPB2C_registration_results_organisations_mod_delivery_auto_header6 = "crwdns153466:0crwdne153466:0";
var APPB2C_registration_results_organisations_mod_delivery_auto_note6 = "crwdns153468:0crwdne153468:0";
var APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote6 = "crwdns153470:0crwdne153470:0";
var APPB2C_registration_results_organisations_mod_delivery_auto_sentNote6 = "crwdns153472:0crwdne153472:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_header6 = "crwdns53803:0crwdne53803:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_note16 = "crwdns53805:0{{count}}crwdne53805:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_note26 = "crwdns53807:0crwdne53807:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_note36 = 'crwdns53809:0$t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} })crwdne53809:0';
var APPB2C_registration_results_organisations_mod_delivery_postal_note46 = 'crwdns153474:0$t(APPB2C.registration.results.organisations.mod.delivery.postal.trfCount, {"count": {{count}} })crwdne153474:0';
var APPB2C_registration_results_organisations_mod_delivery_postal_note56 = "crwdns53813:0crwdne53813:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote6 = "crwdns53819:0crwdne53819:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_sentNote6 = "crwdns53821:0crwdne53821:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount6 = "crwdns53815:0{{count}}crwdne53815:0";
var APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other6 = "crwdns53817:0{{count}}crwdne53817:0";
var APPB2C_registration_results_organisations_mod_howTo_item16 = "crwdns153476:0crwdne153476:0";
var APPB2C_registration_results_organisations_mod_howTo_item26 = "crwdns53793:0{{number}}crwdne53793:0";
var APPB2C_registration_results_organisations_mod_howTo_line16 = "crwdns53789:0crwdne53789:0";
var APPB2C_registration_results_organisations_mod_howTo_title6 = "crwdns53787:0crwdne53787:0";
var APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote6 = "crwdns53785:0crwdne53785:0";
var APPB2C_registration_results_organisations_note06 = "crwdns53765:0crwdne53765:0";
var APPB2C_registration_results_organisations_note16 = 'crwdns53767:0$t(APPB2C.registration.results.organisations.organisationsCount, {"count": {{count}} })crwdne53767:0';
var APPB2C_registration_results_organisations_note26 = "crwdns53769:0crwdne53769:0";
var APPB2C_registration_results_organisations_organisationsCount6 = "crwdns53759:0{{count}}crwdne53759:0";
var APPB2C_registration_results_organisations_organisationsCount_other6 = "crwdns53761:0{{count}}crwdne53761:0";
var APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote6 = "crwdns53783:0crwdne53783:0";
var APPB2C_registration_results_organisations_title6 = "crwdns53763:0crwdne53763:0";
var APPB2C_registration_results_osr_chooseDate_accessibility6 = "crwdns84001:0crwdne84001:0";
var APPB2C_registration_results_osr_chooseDate_accessibilityLink6 = "crwdns84003:0crwdne84003:0";
var APPB2C_registration_results_osr_chooseDate_chooseDateTitle6 = "crwdns84005:0{{skillName}}crwdne84005:0";
var APPB2C_registration_results_osr_chooseDate_note_content6 = "crwdns84007:0{{date}}crwdne84007:0";
var APPB2C_registration_results_osr_chooseDate_note_title6 = "crwdns84009:0crwdne84009:0";
var APPB2C_registration_results_osr_chooseDate_retakeListening6 = "crwdns84011:0crwdne84011:0";
var APPB2C_registration_results_osr_chooseDate_retakeReading6 = "crwdns84013:0crwdne84013:0";
var APPB2C_registration_results_osr_chooseDate_retakeSpeaking6 = "crwdns84015:0crwdne84015:0";
var APPB2C_registration_results_osr_chooseDate_retakeWriting6 = "crwdns84017:0crwdne84017:0";
var APPB2C_registration_results_osr_chooseSkill_backBtn6 = "crwdns84019:0crwdne84019:0";
var APPB2C_registration_results_osr_chooseSkill_chooseDateBtn6 = "crwdns125089:0crwdne125089:0";
var APPB2C_registration_results_osr_chooseSkill_confirmationRequired6 = "crwdns84065:0crwdne84065:0";
var APPB2C_registration_results_osr_chooseSkill_formTitle6 = "crwdns84025:0crwdne84025:0";
var APPB2C_registration_results_osr_chooseSkill_modal_contact6 = "crwdns84027:0crwdne84027:0";
var APPB2C_registration_results_osr_chooseSkill_modal_description6 = "crwdns84029:0crwdne84029:0";
var APPB2C_registration_results_osr_chooseSkill_modal_email6 = "crwdns84031:0crwdne84031:0";
var APPB2C_registration_results_osr_chooseSkill_modal_phone6 = "crwdns84033:0crwdne84033:0";
var APPB2C_registration_results_osr_chooseSkill_modal_title6 = "crwdns84035:0crwdne84035:0";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation6 = "crwdns84037:0crwdne84037:0";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description6 = "crwdns137412:0crwdne137412:0";
var APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title6 = "crwdns84041:0crwdne84041:0";
var APPB2C_registration_results_osr_chooseSkill_skillRequired6 = "crwdns84043:0crwdne84043:0";
var APPB2C_registration_results_osr_chooseTest_availableDates6 = "crwdns84045:0crwdne84045:0";
var APPB2C_registration_results_osr_chooseTest_speaking_time6 = "crwdns84047:0crwdne84047:0";
var APPB2C_registration_results_osr_error_missingFile6 = "crwdns152652:0crwdne152652:0";
var APPB2C_registration_results_osr_error_noTestDates6 = "crwdns152654:0crwdne152654:0";
var APPB2C_registration_results_osr_error_noTests6 = "crwdns152656:0crwdne152656:0";
var APPB2C_registration_results_osr_line16 = "crwdns53737:0crwdne53737:0";
var APPB2C_registration_results_osr_line26 = "crwdns53739:0crwdne53739:0";
var APPB2C_registration_results_osr_line36 = "crwdns53741:0crwdne53741:0";
var APPB2C_registration_results_osr_line46 = "crwdns53743:0crwdne53743:0";
var APPB2C_registration_results_osr_line56 = "crwdns137414:0crwdne137414:0";
var APPB2C_registration_results_osr_review_note6 = "crwdns84049:0crwdne84049:0";
var APPB2C_registration_results_osr_review_note_testDay6 = "crwdns137416:0crwdne137416:0";
var APPB2C_registration_results_osr_submitBtn6 = "crwdns53747:0crwdne53747:0";
var APPB2C_registration_results_osr_successBanner6 = "crwdns84053:0crwdne84053:0";
var APPB2C_registration_results_osr_title6 = "crwdns53735:0crwdne53735:0";
var APPB2C_registration_results_ttAbsent_btn6 = "crwdns126208:0crwdne126208:0";
var APPB2C_registration_results_ttAbsent_msg6 = "crwdns126210:0crwdne126210:0";
var APPB2C_registration_results_updateBar_osr_booked6 = "crwdns84055:0{{osrSkillName}}crwdne84055:0";
var APPB2C_registration_results_updateBar_osr_marked6 = "crwdns84057:0{{osrSkillName}}crwdne84057:0";
var APPB2C_registration_tabs_bookingDetails6 = "crwdns84059:0crwdne84059:0";
var APPB2C_registration_tabs_candidate6 = "crwdns84061:0crwdne84061:0";
var APPB2C_registration_tabs_date6 = "crwdns53315:0crwdne53315:0";
var APPB2C_registration_tabs_dateLocation6 = "crwdns53317:0crwdne53317:0";
var APPB2C_registration_tabs_payments6 = "crwdns53323:0crwdne53323:0";
var APPB2C_registration_tabs_recognisingOrgs6 = "crwdns53325:0crwdne53325:0";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_16 = "crwdns53327:0crwdne53327:0";
var APPB2C_registration_tabs_recognisingOrgs_defaultMsg_26 = "crwdns53329:0crwdne53329:0";
var APPB2C_registration_tabs_results6 = "crwdns53321:0crwdne53321:0";
var APPB2C_registration_testTaker_bookedFor_completed6 = "crwdns53411:0crwdne53411:0";
var APPB2C_registration_testTaker_bookedFor_dob6 = "crwdns53417:0crwdne53417:0";
var APPB2C_registration_testTaker_bookedFor_email6 = "crwdns53421:0crwdne53421:0";
var APPB2C_registration_testTaker_bookedFor_gender6 = "crwdns53419:0crwdne53419:0";
var APPB2C_registration_testTaker_bookedFor_name6 = "crwdns53415:0crwdne53415:0";
var APPB2C_registration_testTaker_bookedFor_parent6 = "crwdns53413:0crwdne53413:0";
var APPB2C_registration_testTaker_bookedFor_title6 = "crwdns53409:0crwdne53409:0";
var APPB2C_registration_testTaker_childConsent_back6 = "crwdns53441:0crwdne53441:0";
var APPB2C_registration_testTaker_childConsent_context6 = "crwdns53445:0crwdne53445:0";
var APPB2C_registration_testTaker_childConsent_cta6 = "crwdns53453:0crwdne53453:0";
var APPB2C_registration_testTaker_childConsent_options_16 = "crwdns53447:0crwdne53447:0";
var APPB2C_registration_testTaker_childConsent_options_26 = "crwdns53449:0crwdne53449:0";
var APPB2C_registration_testTaker_childConsent_options_36 = "crwdns53451:0crwdne53451:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_add6 = "crwdns53469:0crwdne53469:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox6 = "crwdns53475:0crwdne53475:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_16 = "crwdns53471:0crwdne53471:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_26 = "crwdns53473:0crwdne53473:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName6 = "crwdns53461:0crwdne53461:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_note6 = "crwdns53455:0crwdne53455:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phone6 = "crwdns53465:0crwdne53465:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote6 = "crwdns53467:0crwdne53467:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_policy6 = "crwdns53477:0crwdne53477:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_relationship6 = "crwdns53463:0crwdne53463:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_16 = "crwdns53457:0crwdne53457:0";
var APPB2C_registration_testTaker_childConsent_somebodyElse_title_26 = "crwdns53459:0crwdne53459:0";
var APPB2C_registration_testTaker_childConsent_title6 = "crwdns53439:0crwdne53439:0";
var APPB2C_registration_testTaker_childConsent_warning6 = "crwdns53443:0crwdne53443:0";
var APPB2C_registration_testTaker_consent_edit6 = "crwdns53435:0crwdne53435:0";
var APPB2C_registration_testTaker_consent_missing6 = "crwdns53437:0crwdne53437:0";
var APPB2C_registration_testTaker_consent_title6 = "crwdns53433:0crwdne53433:0";
var APPB2C_registration_testTaker_contact_address6 = "crwdns53429:0crwdne53429:0";
var APPB2C_registration_testTaker_contact_edit6 = "crwdns53427:0crwdne53427:0";
var APPB2C_registration_testTaker_contact_mobile6 = "crwdns53431:0crwdne53431:0";
var APPB2C_registration_testTaker_contact_parent6 = "crwdns53425:0crwdne53425:0";
var APPB2C_registration_testTaker_contact_title6 = "crwdns53423:0crwdne53423:0";
var APPB2C_registration_testTaker_editContact_address6 = "crwdns53489:0crwdne53489:0";
var APPB2C_registration_testTaker_editContact_back6 = "crwdns53483:0crwdne53483:0";
var APPB2C_registration_testTaker_editContact_city6 = "crwdns53491:0crwdne53491:0";
var APPB2C_registration_testTaker_editContact_country_change6 = "crwdns53487:0crwdne53487:0";
var APPB2C_registration_testTaker_editContact_country_residence6 = "crwdns53485:0crwdne53485:0";
var APPB2C_registration_testTaker_editContact_cta6 = "crwdns53499:0crwdne53499:0";
var APPB2C_registration_testTaker_editContact_error_missingAddress6 = "crwdns152658:0crwdne152658:0";
var APPB2C_registration_testTaker_editContact_error_missingCountry6 = "crwdns152660:0crwdne152660:0";
var APPB2C_registration_testTaker_editContact_error_pastCutoff6 = "crwdns152662:0crwdne152662:0";
var APPB2C_registration_testTaker_editContact_mobile6 = "crwdns53497:0crwdne53497:0";
var APPB2C_registration_testTaker_editContact_note6 = "crwdns53479:0crwdne53479:0";
var APPB2C_registration_testTaker_editContact_postCode6 = "crwdns53493:0crwdne53493:0";
var APPB2C_registration_testTaker_editContact_state6 = "crwdns53495:0crwdne53495:0";
var APPB2C_registration_testTaker_editContact_warn6 = "crwdns53481:0crwdne53481:0";
var APPB2C_registration_testTaker_error6 = "crwdns152664:0crwdne152664:0";
var APPB2C_registration_testTaker_files_editButton_add6 = "crwdns53531:0crwdne53531:0";
var APPB2C_registration_testTaker_files_editButton_edit6 = "crwdns53533:0crwdne53533:0";
var APPB2C_registration_testTaker_files_upload_back_subtitle6 = "crwdns53547:0crwdne53547:0";
var APPB2C_registration_testTaker_files_upload_back_title6 = "crwdns84063:0crwdne84063:0";
var APPB2C_registration_testTaker_files_upload_cta6 = "crwdns53543:0crwdne53543:0";
var APPB2C_registration_testTaker_files_upload_error_fileTooLarge6 = "crwdns152666:0crwdne152666:0";
var APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded6 = "crwdns152668:0crwdne152668:0";
var APPB2C_registration_testTaker_files_upload_error_uploadUnavailable6 = "crwdns152670:0crwdne152670:0";
var APPB2C_registration_testTaker_files_upload_error_wrongFileName6 = "crwdns152672:0crwdne152672:0";
var APPB2C_registration_testTaker_files_upload_error_wrongFileType6 = "crwdns152674:0crwdne152674:0";
var APPB2C_registration_testTaker_files_upload_mobile_line16 = "crwdns53539:0crwdne53539:0";
var APPB2C_registration_testTaker_files_upload_mobile_line26 = "crwdns53541:0crwdne53541:0";
var APPB2C_registration_testTaker_files_upload_mobile_title6 = "crwdns53537:0crwdne53537:0";
var APPB2C_registration_testTaker_files_upload_note6 = "crwdns53535:0{{count}}crwdne53535:0";
var APPB2C_registration_testTaker_id_child6 = "crwdns53503:0crwdne53503:0";
var APPB2C_registration_testTaker_id_edit_rules_example6 = "crwdns53527:0$t({{idName}})crwdne53527:0";
var APPB2C_registration_testTaker_id_edit_subtitle6 = "crwdns53525:0$t({{idName}})crwdne53525:0";
var APPB2C_registration_testTaker_id_edit_title6 = "crwdns53523:0$t({{idName}})crwdne53523:0";
var APPB2C_registration_testTaker_id_edit_upload_exampleProof6 = "crwdns53529:0crwdne53529:0";
var APPB2C_registration_testTaker_id_edit_upload_imgAlt6 = "crwdns154241:0crwdne154241:0";
var APPB2C_registration_testTaker_id_expiryDate6 = "crwdns53513:0$t({{idName}}, capitalise)crwdne53513:0";
var APPB2C_registration_testTaker_id_fileAlt6 = "crwdns53517:0crwdne53517:0";
var APPB2C_registration_testTaker_id_idNumber6 = "crwdns53511:0$t({{idName}}, capitalise)crwdne53511:0";
var APPB2C_registration_testTaker_id_issuingAuthority6 = "crwdns53515:0crwdne53515:0";
var APPB2C_registration_testTaker_id_noFilesBody6 = "crwdns53521:0crwdne53521:0";
var APPB2C_registration_testTaker_id_noFilesTitle6 = "crwdns53519:0crwdne53519:0";
var APPB2C_registration_testTaker_id_title6 = "crwdns53501:0crwdne53501:0";
var APPB2C_registration_testTaker_id_typeNames_card6 = "crwdns53507:0crwdne53507:0";
var APPB2C_registration_testTaker_id_typeNames_other6 = "crwdns53509:0crwdne53509:0";
var APPB2C_registration_testTaker_id_typeNames_passport6 = "crwdns53505:0crwdne53505:0";
var APPB2C_registration_testTaker_specialArrangements_description6 = "crwdns53551:0crwdne53551:0";
var APPB2C_registration_testTaker_specialArrangements_edit_line16 = "crwdns53563:0crwdne53563:0";
var APPB2C_registration_testTaker_specialArrangements_edit_line26 = "crwdns53565:0crwdne53565:0";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule16 = "crwdns53569:0crwdne53569:0";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule26 = "crwdns53571:0crwdne53571:0";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule36 = "crwdns53573:0crwdne53573:0";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule46 = "crwdns53575:0crwdne53575:0";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_rule56 = "crwdns53577:0crwdne53577:0";
var APPB2C_registration_testTaker_specialArrangements_edit_rules_title6 = "crwdns53567:0crwdne53567:0";
var APPB2C_registration_testTaker_specialArrangements_edit_subtitle6 = "crwdns53561:0crwdne53561:0";
var APPB2C_registration_testTaker_specialArrangements_edit_title6 = "crwdns53559:0crwdne53559:0";
var APPB2C_registration_testTaker_specialArrangements_fileAlt6 = "crwdns53557:0crwdne53557:0";
var APPB2C_registration_testTaker_specialArrangements_noFilesBody6 = "crwdns53555:0crwdne53555:0";
var APPB2C_registration_testTaker_specialArrangements_noFilesTitle6 = "crwdns53553:0crwdne53553:0";
var APPB2C_registration_testTaker_specialArrangements_title6 = "crwdns53549:0crwdne53549:0";
var APPB2C_testCard_arrival6 = "crwdns54255:0{{time}}crwdne54255:0";
var APPB2C_testCard_book6 = "crwdns54261:0crwdne54261:0";
var APPB2C_testCard_bookExam6 = "crwdns139864:0crwdne139864:0";
var APPB2C_testCard_bookFor6 = "crwdns54263:0crwdne54263:0";
var APPB2C_testCard_bookForFree6 = "crwdns139866:0crwdne139866:0";
var APPB2C_testCard_bookingPrice6 = "crwdns139868:0crwdne139868:0";
var APPB2C_testCard_cd6 = "crwdns54241:0crwdne54241:0";
var APPB2C_testCard_changeSpeaking6 = "crwdns153924:0crwdne153924:0";
var APPB2C_testCard_f2f6 = "crwdns54245:0crwdne54245:0";
var APPB2C_testCard_hideSpeakingTests6 = "crwdns54267:0crwdne54267:0";
var APPB2C_testCard_lrw6 = "crwdns54235:0crwdne54235:0";
var APPB2C_testCard_lrwHeader5 = "crwdns139882:0crwdne139882:0";
var APPB2C_testCard_ls6 = "crwdns54237:0crwdne54237:0";
var APPB2C_testCard_mixedSlots6 = "crwdns139884:0crwdne139884:0";
var APPB2C_testCard_notLinkedVenue6 = "crwdns139886:0crwdne139886:0";
var APPB2C_testCard_pb6 = "crwdns54243:0crwdne54243:0";
var APPB2C_testCard_review6 = "crwdns54265:0crwdne54265:0";
var APPB2C_testCard_showSpeakingTests6 = "crwdns54269:0crwdne54269:0";
var APPB2C_testCard_speaking6 = "crwdns139888:0crwdne139888:0";
var APPB2C_testCard_speakingAddress6 = "crwdns54253:0crwdne54253:0";
var APPB2C_testCard_timeUnknown6 = "crwdns139874:0crwdne139874:0";
var APPB2C_testCard_vcs6 = "crwdns54247:0crwdne54247:0";
var b2c_default6 = {
  "APPB2C.auth.changePassword.cta": APPB2C_auth_changePassword_cta6,
  "APPB2C.auth.changePassword.error": APPB2C_auth_changePassword_error6,
  "APPB2C.auth.changePassword.error.differentPasswords": APPB2C_auth_changePassword_error_differentPasswords6,
  "APPB2C.auth.changePassword.error.wrongIdNumber": APPB2C_auth_changePassword_error_wrongIdNumber6,
  "APPB2C.auth.changePassword.error.wrongOldPassword": APPB2C_auth_changePassword_error_wrongOldPassword6,
  "APPB2C.auth.changePassword.error.wrongPassword": APPB2C_auth_changePassword_error_wrongPassword6,
  "APPB2C.auth.changePassword.id.label": APPB2C_auth_changePassword_id_label6,
  "APPB2C.auth.changePassword.id.note": APPB2C_auth_changePassword_id_note6,
  "APPB2C.auth.changePassword.id.warn": APPB2C_auth_changePassword_id_warn6,
  "APPB2C.auth.changePassword.pass.label": APPB2C_auth_changePassword_pass_label6,
  "APPB2C.auth.changePassword.pass.note": APPB2C_auth_changePassword_pass_note6,
  "APPB2C.auth.changePassword.title": APPB2C_auth_changePassword_title6,
  "APPB2C.auth.forgetPassword.cta": APPB2C_auth_forgetPassword_cta6,
  "APPB2C.auth.forgetPassword.email": APPB2C_auth_forgetPassword_email6,
  "APPB2C.auth.forgetPassword.success.msg1": APPB2C_auth_forgetPassword_success_msg16,
  "APPB2C.auth.forgetPassword.success.msg2": APPB2C_auth_forgetPassword_success_msg26,
  "APPB2C.auth.forgetPassword.success.title": APPB2C_auth_forgetPassword_success_title6,
  "APPB2C.auth.forgetPassword.text": APPB2C_auth_forgetPassword_text6,
  "APPB2C.auth.forgetPassword.title": APPB2C_auth_forgetPassword_title6,
  "APPB2C.auth.resetPassword.cta": APPB2C_auth_resetPassword_cta6,
  "APPB2C.auth.resetPassword.error.expiredToken": APPB2C_auth_resetPassword_error_expiredToken6,
  "APPB2C.auth.resetPassword.error.wrongPassword": APPB2C_auth_resetPassword_error_wrongPassword6,
  "APPB2C.auth.resetPassword.error.wrongToken": APPB2C_auth_resetPassword_error_wrongToken6,
  "APPB2C.auth.resetPassword.form.newPassword": APPB2C_auth_resetPassword_form_newPassword6,
  "APPB2C.auth.resetPassword.noToken.cta": APPB2C_auth_resetPassword_noToken_cta6,
  "APPB2C.auth.resetPassword.noToken.msg": APPB2C_auth_resetPassword_noToken_msg6,
  "APPB2C.auth.resetPassword.success.msg": APPB2C_auth_resetPassword_success_msg6,
  "APPB2C.auth.resetPassword.success.title": APPB2C_auth_resetPassword_success_title6,
  "APPB2C.auth.resetPassword.title": APPB2C_auth_resetPassword_title6,
  "APPB2C.cj.basic.cta": APPB2C_cj_basic_cta6,
  "APPB2C.cj.basic.goToReview": APPB2C_cj_basic_goToReview6,
  "APPB2C.cj.basic.scrollLink": APPB2C_cj_basic_scrollLink6,
  "APPB2C.cj.basic.selectDate": APPB2C_cj_basic_selectDate6,
  "APPB2C.cj.basic.selectTime": APPB2C_cj_basic_selectTime6,
  "APPB2C.cj.basic.validationError": APPB2C_cj_basic_validationError6,
  "APPB2C.cj.bookingComplete.feeDetails.discount": APPB2C_cj_bookingComplete_feeDetails_discount6,
  "APPB2C.cj.bookingComplete.feeDetails.fee": APPB2C_cj_bookingComplete_feeDetails_fee6,
  "APPB2C.cj.bookingComplete.feeDetails.newFee": APPB2C_cj_bookingComplete_feeDetails_newFee6,
  "APPB2C.cj.bookingComplete.feeDetails.promo": APPB2C_cj_bookingComplete_feeDetails_promo6,
  "APPB2C.cj.bookingComplete.feeDetails.reference": APPB2C_cj_bookingComplete_feeDetails_reference6,
  "APPB2C.cj.bookingComplete.feeDetails.tax": APPB2C_cj_bookingComplete_feeDetails_tax6,
  "APPB2C.cj.bookingComplete.feeDetails.title": APPB2C_cj_bookingComplete_feeDetails_title6,
  "APPB2C.cj.bookingComplete.feeDetails.total": APPB2C_cj_bookingComplete_feeDetails_total6,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine1": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine16,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine2": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine26,
  "APPB2C.cj.bookingComplete.feeDetails.zeroFeeNoteLine3": APPB2C_cj_bookingComplete_feeDetails_zeroFeeNoteLine36,
  "APPB2C.cj.bookingComplete.loadingPaymentMethodsError": APPB2C_cj_bookingComplete_loadingPaymentMethodsError6,
  "APPB2C.cj.bookingComplete.loadingRegistrationError": APPB2C_cj_bookingComplete_loadingRegistrationError6,
  "APPB2C.cj.bookingComplete.note": APPB2C_cj_bookingComplete_note6,
  "APPB2C.cj.bookingComplete.noteUolPassportInfo": APPB2C_cj_bookingComplete_noteUolPassportInfo6,
  "APPB2C.cj.bookingComplete.offlinePayment.code": APPB2C_cj_bookingComplete_offlinePayment_code6,
  "APPB2C.cj.bookingComplete.offlinePayment.description": APPB2C_cj_bookingComplete_offlinePayment_description6,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line1": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line16,
  "APPB2C.cj.bookingComplete.offlinePayment.discounted.note.line2": APPB2C_cj_bookingComplete_offlinePayment_discounted_note_line26,
  "APPB2C.cj.bookingComplete.offlinePayment.fee": APPB2C_cj_bookingComplete_offlinePayment_fee6,
  "APPB2C.cj.bookingComplete.offlinePayment.hideDescription": APPB2C_cj_bookingComplete_offlinePayment_hideDescription6,
  "APPB2C.cj.bookingComplete.offlinePayment.incTax": APPB2C_cj_bookingComplete_offlinePayment_incTax6,
  "APPB2C.cj.bookingComplete.offlinePayment.name": APPB2C_cj_bookingComplete_offlinePayment_name6,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1": APPB2C_cj_bookingComplete_offlinePayment_note_line16,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.hours": APPB2C_cj_bookingComplete_offlinePayment_note_line1_hours6,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line1.timeLeft": APPB2C_cj_bookingComplete_offlinePayment_note_line1_timeLeft6,
  "APPB2C.cj.bookingComplete.offlinePayment.note.line2": APPB2C_cj_bookingComplete_offlinePayment_note_line26,
  "APPB2C.cj.bookingComplete.offlinePayment.note.pleaseNote": APPB2C_cj_bookingComplete_offlinePayment_note_pleaseNote6,
  "APPB2C.cj.bookingComplete.offlinePayment.number": APPB2C_cj_bookingComplete_offlinePayment_number6,
  "APPB2C.cj.bookingComplete.offlinePayment.reference": APPB2C_cj_bookingComplete_offlinePayment_reference6,
  "APPB2C.cj.bookingComplete.offlinePayment.showDescription": APPB2C_cj_bookingComplete_offlinePayment_showDescription6,
  "APPB2C.cj.bookingComplete.otherPaymentOptions": APPB2C_cj_bookingComplete_otherPaymentOptions6,
  "APPB2C.cj.bookingComplete.paymentFailed": APPB2C_cj_bookingComplete_paymentFailed6,
  "APPB2C.cj.bookingComplete.paymentOptions": APPB2C_cj_bookingComplete_paymentOptions6,
  "APPB2C.cj.bookingComplete.prepareNote.accesNote": APPB2C_cj_bookingComplete_prepareNote_accesNote6,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.note": APPB2C_cj_bookingComplete_prepareNote_counselling_note6,
  "APPB2C.cj.bookingComplete.prepareNote.counselling.view": APPB2C_cj_bookingComplete_prepareNote_counselling_view6,
  "APPB2C.cj.bookingComplete.prepareNote.note": APPB2C_cj_bookingComplete_prepareNote_note6,
  "APPB2C.cj.bookingComplete.prepareNote.title": APPB2C_cj_bookingComplete_prepareNote_title6,
  "APPB2C.cj.bookingComplete.prepareNote.view": APPB2C_cj_bookingComplete_prepareNote_view6,
  "APPB2C.cj.bookingComplete.title": APPB2C_cj_bookingComplete_title6,
  "APPB2C.cj.bookingComplete.tnc": APPB2C_cj_bookingComplete_tnc6,
  "APPB2C.cj.bookingComplete.tncLink": APPB2C_cj_bookingComplete_tncLink6,
  "APPB2C.cj.bookingComplete.tryAgain": APPB2C_cj_bookingComplete_tryAgain6,
  "APPB2C.cj.bookingComplete.yourBooking": APPB2C_cj_bookingComplete_yourBooking6,
  "APPB2C.cj.childDetails.about": APPB2C_cj_childDetails_about6,
  "APPB2C.cj.childDetails.aboutNote": APPB2C_cj_childDetails_aboutNote6,
  "APPB2C.cj.childDetails.error.missingFirstName": APPB2C_cj_childDetails_error_missingFirstName6,
  "APPB2C.cj.childDetails.error.missingSurname": APPB2C_cj_childDetails_error_missingSurname6,
  "APPB2C.cj.childDetails.error.wrongDob": APPB2C_cj_childDetails_error_wrongDob6,
  "APPB2C.cj.childDetails.forms.personal.idMatch": APPB2C_cj_childDetails_forms_personal_idMatch6,
  "APPB2C.cj.childDetails.noLastName": APPB2C_cj_childDetails_noLastName6,
  "APPB2C.cj.childDetails.note": APPB2C_cj_childDetails_note6,
  "APPB2C.cj.childDetails.onTestDay": APPB2C_cj_childDetails_onTestDay6,
  "APPB2C.cj.childDetails.title": APPB2C_cj_childDetails_title6,
  "APPB2C.cj.finishPayment.error": APPB2C_cj_finishPayment_error6,
  "APPB2C.cj.finishPayment.pleaseWait": APPB2C_cj_finishPayment_pleaseWait6,
  "APPB2C.cj.finishPayment.processing": APPB2C_cj_finishPayment_processing6,
  "APPB2C.cj.idDetails.confirm.change": APPB2C_cj_idDetails_confirm_change6,
  "APPB2C.cj.idDetails.confirm.confirm": APPB2C_cj_idDetails_confirm_confirm6,
  "APPB2C.cj.idDetails.confirm.note1": APPB2C_cj_idDetails_confirm_note16,
  "APPB2C.cj.idDetails.confirm.note2": APPB2C_cj_idDetails_confirm_note26,
  "APPB2C.cj.idDetails.confirm.title": APPB2C_cj_idDetails_confirm_title6,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.content": APPB2C_cj_idDetails_confirm_wrongDetailsNote_content6,
  "APPB2C.cj.idDetails.confirm.wrongDetailsNote.title": APPB2C_cj_idDetails_confirm_wrongDetailsNote_title6,
  "APPB2C.cj.idDetails.error.missingIdExpiry": APPB2C_cj_idDetails_error_missingIdExpiry6,
  "APPB2C.cj.idDetails.error.missingIdNumber": APPB2C_cj_idDetails_error_missingIdNumber6,
  "APPB2C.cj.idDetails.error.missingIdType": APPB2C_cj_idDetails_error_missingIdType6,
  "APPB2C.cj.idDetails.error.missingIssuingAuthority": APPB2C_cj_idDetails_error_missingIssuingAuthority6,
  "APPB2C.cj.idDetails.error.wrongFilename": APPB2C_cj_idDetails_error_wrongFilename6,
  "APPB2C.cj.idDetails.error.wrongIdExpiry": APPB2C_cj_idDetails_error_wrongIdExpiry6,
  "APPB2C.cj.idDetails.forms.changeNote": APPB2C_cj_idDetails_forms_changeNote6,
  "APPB2C.cj.idDetails.forms.changeNote.child": APPB2C_cj_idDetails_forms_changeNote_child6,
  "APPB2C.cj.idDetails.forms.documentWithNoExpiryDate": APPB2C_cj_idDetails_forms_documentWithNoExpiryDate6,
  "APPB2C.cj.idDetails.forms.expiryDate": APPB2C_cj_idDetails_forms_expiryDate6,
  "APPB2C.cj.idDetails.forms.idNumber": APPB2C_cj_idDetails_forms_idNumber6,
  "APPB2C.cj.idDetails.forms.idType": APPB2C_cj_idDetails_forms_idType6,
  "APPB2C.cj.idDetails.forms.issuingAuthority": APPB2C_cj_idDetails_forms_issuingAuthority6,
  "APPB2C.cj.idDetails.idExpired": APPB2C_cj_idDetails_idExpired6,
  "APPB2C.cj.idDetails.iolMessage": APPB2C_cj_idDetails_iolMessage6,
  "APPB2C.cj.idDetails.noteAboutIdentification": APPB2C_cj_idDetails_noteAboutIdentification6,
  "APPB2C.cj.idDetails.noteAboutIdentification.child": APPB2C_cj_idDetails_noteAboutIdentification_child6,
  "APPB2C.cj.idDetails.noteAboutIdentificationIol": APPB2C_cj_idDetails_noteAboutIdentificationIol6,
  "APPB2C.cj.idDetails.noteAboutIdentificationIolLink": APPB2C_cj_idDetails_noteAboutIdentificationIolLink6,
  "APPB2C.cj.idDetails.noteAboutIdentificationUol": APPB2C_cj_idDetails_noteAboutIdentificationUol6,
  "APPB2C.cj.idDetails.title": APPB2C_cj_idDetails_title6,
  "APPB2C.cj.idDetails.title.child": APPB2C_cj_idDetails_title_child6,
  "APPB2C.cj.idDetails.typeNames.card": APPB2C_cj_idDetails_typeNames_card6,
  "APPB2C.cj.idDetails.typeNames.other": APPB2C_cj_idDetails_typeNames_other6,
  "APPB2C.cj.idDetails.typeNames.passport": APPB2C_cj_idDetails_typeNames_passport6,
  "APPB2C.cj.idDetails.upload.fetchError": APPB2C_cj_idDetails_upload_fetchError6,
  "APPB2C.cj.idDetails.upload.file1": APPB2C_cj_idDetails_upload_file16,
  "APPB2C.cj.idDetails.upload.file2": APPB2C_cj_idDetails_upload_file26,
  "APPB2C.cj.idDetails.upload.instructions.capture": APPB2C_cj_idDetails_upload_instructions_capture6,
  "APPB2C.cj.idDetails.upload.instructions.covers": APPB2C_cj_idDetails_upload_instructions_covers6,
  "APPB2C.cj.idDetails.upload.instructions.directLight": APPB2C_cj_idDetails_upload_instructions_directLight6,
  "APPB2C.cj.idDetails.upload.instructions.noBlur": APPB2C_cj_idDetails_upload_instructions_noBlur6,
  "APPB2C.cj.idDetails.upload.instructions.title": APPB2C_cj_idDetails_upload_instructions_title6,
  "APPB2C.cj.idDetails.upload.mobile": APPB2C_cj_idDetails_upload_mobile6,
  "APPB2C.cj.idDetails.upload.passport.guidelines.cover": APPB2C_cj_idDetails_upload_passport_guidelines_cover6,
  "APPB2C.cj.idDetails.upload.passport.guidelines.crop": APPB2C_cj_idDetails_upload_passport_guidelines_crop6,
  "APPB2C.cj.idDetails.upload.passport.guidelines.followInstructions": APPB2C_cj_idDetails_upload_passport_guidelines_followInstructions6,
  "APPB2C.cj.idDetails.upload.passport.guidelines.glare": APPB2C_cj_idDetails_upload_passport_guidelines_glare6,
  "APPB2C.cj.idDetails.upload.passport.guidelines.watermark": APPB2C_cj_idDetails_upload_passport_guidelines_watermark6,
  "APPB2C.cj.idDetails.upload.title": APPB2C_cj_idDetails_upload_title6,
  "APPB2C.cj.ieltsReady.description.line1": APPB2C_cj_ieltsReady_description_line16,
  "APPB2C.cj.ieltsReady.description.line2": APPB2C_cj_ieltsReady_description_line26,
  "APPB2C.cj.ieltsReady.dobMin": APPB2C_cj_ieltsReady_dobMin6,
  "APPB2C.cj.ieltsReady.nameWarning": APPB2C_cj_ieltsReady_nameWarning6,
  "APPB2C.cj.ieltsReady.note": APPB2C_cj_ieltsReady_note6,
  "APPB2C.cj.ieltsReady.personalDetails.title": APPB2C_cj_ieltsReady_personalDetails_title5,
  "APPB2C.cj.ieltsReady.submitBtnText": APPB2C_cj_ieltsReady_submitBtnText6,
  "APPB2C.cj.ieltsReady.targetScore.option": APPB2C_cj_ieltsReady_targetScore_option6,
  "APPB2C.cj.ieltsReady.targetScore.title": APPB2C_cj_ieltsReady_targetScore_title6,
  "APPB2C.cj.ieltsReady.title": APPB2C_cj_ieltsReady_title6,
  "APPB2C.cj.incorrectOrganisation.bookNewLink": APPB2C_cj_incorrectOrganisation_bookNewLink6,
  "APPB2C.cj.incorrectOrganisation.line1": APPB2C_cj_incorrectOrganisation_line16,
  "APPB2C.cj.incorrectOrganisation.line2": APPB2C_cj_incorrectOrganisation_line26,
  "APPB2C.cj.incorrectOrganisation.locationsLink": APPB2C_cj_incorrectOrganisation_locationsLink6,
  "APPB2C.cj.incorrectOrganisation.locationsLinkUsa": APPB2C_cj_incorrectOrganisation_locationsLinkUsa6,
  "APPB2C.cj.incorrectOrganisation.title": APPB2C_cj_incorrectOrganisation_title6,
  "APPB2C.cj.marketingPrefs": APPB2C_cj_marketingPrefs6,
  "APPB2C.cj.onRequest.created.back": APPB2C_cj_onRequest_created_back6,
  "APPB2C.cj.onRequest.created.in": APPB2C_cj_onRequest_created_in6,
  "APPB2C.cj.onRequest.created.info": APPB2C_cj_onRequest_created_info6,
  "APPB2C.cj.onRequest.created.note": APPB2C_cj_onRequest_created_note6,
  "APPB2C.cj.onRequest.created.title": APPB2C_cj_onRequest_created_title6,
  "APPB2C.cj.onRequest.form.labels.confirmEmail": APPB2C_cj_onRequest_form_labels_confirmEmail6,
  "APPB2C.cj.onRequest.form.labels.email": APPB2C_cj_onRequest_form_labels_email6,
  "APPB2C.cj.onRequest.form.labels.firstname": APPB2C_cj_onRequest_form_labels_firstname6,
  "APPB2C.cj.onRequest.form.labels.surname": APPB2C_cj_onRequest_form_labels_surname6,
  "APPB2C.cj.onRequest.form.labels.telephone": APPB2C_cj_onRequest_form_labels_telephone6,
  "APPB2C.cj.onRequest.form.labels.testRangeQuestion": APPB2C_cj_onRequest_form_labels_testRangeQuestion6,
  "APPB2C.cj.onRequest.form.submit": APPB2C_cj_onRequest_form_submit6,
  "APPB2C.cj.onRequest.form.testRangeOpt.oneMonth": APPB2C_cj_onRequest_form_testRangeOpt_oneMonth6,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonths": APPB2C_cj_onRequest_form_testRangeOpt_threeMonths6,
  "APPB2C.cj.onRequest.form.testRangeOpt.threeMonthsPlus": APPB2C_cj_onRequest_form_testRangeOpt_threeMonthsPlus6,
  "APPB2C.cj.onRequest.form.testRangeOpt.twoMonths": APPB2C_cj_onRequest_form_testRangeOpt_twoMonths6,
  "APPB2C.cj.onRequest.info1": APPB2C_cj_onRequest_info16,
  "APPB2C.cj.onRequest.note": APPB2C_cj_onRequest_note6,
  "APPB2C.cj.onRequest.otherLocations.choose": APPB2C_cj_onRequest_otherLocations_choose6,
  "APPB2C.cj.onRequest.otherLocations.hide": APPB2C_cj_onRequest_otherLocations_hide6,
  "APPB2C.cj.onRequest.otherLocations.info1": APPB2C_cj_onRequest_otherLocations_info16,
  "APPB2C.cj.onRequest.otherLocations.info2": APPB2C_cj_onRequest_otherLocations_info26,
  "APPB2C.cj.onRequest.otherLocations.show": APPB2C_cj_onRequest_otherLocations_show6,
  "APPB2C.cj.onRequest.please": APPB2C_cj_onRequest_please6,
  "APPB2C.cj.onRequest.prefNote": APPB2C_cj_onRequest_prefNote6,
  "APPB2C.cj.onRequest.subtitles.aboutPreferences": APPB2C_cj_onRequest_subtitles_aboutPreferences6,
  "APPB2C.cj.onRequest.subtitles.aboutYou": APPB2C_cj_onRequest_subtitles_aboutYou6,
  "APPB2C.cj.onRequest.subtitles.markpref": APPB2C_cj_onRequest_subtitles_markpref6,
  "APPB2C.cj.onRequest.subtitles.register": APPB2C_cj_onRequest_subtitles_register6,
  "APPB2C.cj.personalDetails.error.doubleBooking": APPB2C_cj_personalDetails_error_doubleBooking6,
  "APPB2C.cj.personalDetails.error.missingAddress": APPB2C_cj_personalDetails_error_missingAddress6,
  "APPB2C.cj.personalDetails.error.missingEmail": APPB2C_cj_personalDetails_error_missingEmail6,
  "APPB2C.cj.personalDetails.error.missingEmailConfirm": APPB2C_cj_personalDetails_error_missingEmailConfirm6,
  "APPB2C.cj.personalDetails.error.missingFirstName": APPB2C_cj_personalDetails_error_missingFirstName6,
  "APPB2C.cj.personalDetails.error.missingMobile": APPB2C_cj_personalDetails_error_missingMobile6,
  "APPB2C.cj.personalDetails.error.missingSex": APPB2C_cj_personalDetails_error_missingSex6,
  "APPB2C.cj.personalDetails.error.missingState": APPB2C_cj_personalDetails_error_missingState6,
  "APPB2C.cj.personalDetails.error.missingSurname": APPB2C_cj_personalDetails_error_missingSurname6,
  "APPB2C.cj.personalDetails.error.missingTown": APPB2C_cj_personalDetails_error_missingTown6,
  "APPB2C.cj.personalDetails.error.missingZip": APPB2C_cj_personalDetails_error_missingZip6,
  "APPB2C.cj.personalDetails.error.wrongDob": APPB2C_cj_personalDetails_error_wrongDob6,
  "APPB2C.cj.personalDetails.error.wrongEmailConfirm": APPB2C_cj_personalDetails_error_wrongEmailConfirm6,
  "APPB2C.cj.personalDetails.examTakenModal.cta": APPB2C_cj_personalDetails_examTakenModal_cta6,
  "APPB2C.cj.personalDetails.examTakenModal.message": APPB2C_cj_personalDetails_examTakenModal_message6,
  "APPB2C.cj.personalDetails.examTakenModal.title": APPB2C_cj_personalDetails_examTakenModal_title6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.bookSomeoneElse": APPB2C_cj_personalDetails_existingRegistrationsModal_bookSomeoneElse6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.changeDate": APPB2C_cj_personalDetails_existingRegistrationsModal_changeDate6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.subtitle": APPB2C_cj_personalDetails_existingRegistrationsModal_subtitle6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.title": APPB2C_cj_personalDetails_existingRegistrationsModal_title6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewBooking6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.viewCurrentBooking": APPB2C_cj_personalDetails_existingRegistrationsModal_viewCurrentBooking6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourLifeSkillsTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourLifeSkillsTest6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourSpeakingTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourSpeakingTest6,
  "APPB2C.cj.personalDetails.existingRegistrationsModal.yourWrittenTest": APPB2C_cj_personalDetails_existingRegistrationsModal_yourWrittenTest6,
  "APPB2C.cj.personalDetails.forms.address.country": APPB2C_cj_personalDetails_forms_address_country6,
  "APPB2C.cj.personalDetails.forms.address.enterManually": APPB2C_cj_personalDetails_forms_address_enterManually6,
  "APPB2C.cj.personalDetails.forms.address.postal": APPB2C_cj_personalDetails_forms_address_postal6,
  "APPB2C.cj.personalDetails.forms.address.state": APPB2C_cj_personalDetails_forms_address_state6,
  "APPB2C.cj.personalDetails.forms.address.town": APPB2C_cj_personalDetails_forms_address_town6,
  "APPB2C.cj.personalDetails.forms.address.zip": APPB2C_cj_personalDetails_forms_address_zip6,
  "APPB2C.cj.personalDetails.forms.contact.confirmEmail": APPB2C_cj_personalDetails_forms_contact_confirmEmail6,
  "APPB2C.cj.personalDetails.forms.contact.email": APPB2C_cj_personalDetails_forms_contact_email6,
  "APPB2C.cj.personalDetails.forms.contact.emailExist": APPB2C_cj_personalDetails_forms_contact_emailExist6,
  "APPB2C.cj.personalDetails.forms.contact.emailInUse": APPB2C_cj_personalDetails_forms_contact_emailInUse6,
  "APPB2C.cj.personalDetails.forms.contact.mobile": APPB2C_cj_personalDetails_forms_contact_mobile6,
  "APPB2C.cj.personalDetails.forms.contact.note": APPB2C_cj_personalDetails_forms_contact_note6,
  "APPB2C.cj.personalDetails.forms.contact.password": APPB2C_cj_personalDetails_forms_contact_password6,
  "APPB2C.cj.personalDetails.forms.contact.smsConsent": APPB2C_cj_personalDetails_forms_contact_smsConsent6,
  "APPB2C.cj.personalDetails.forms.contact.smsNote": APPB2C_cj_personalDetails_forms_contact_smsNote6,
  "APPB2C.cj.personalDetails.forms.contact.title": APPB2C_cj_personalDetails_forms_contact_title6,
  "APPB2C.cj.personalDetails.forms.personal.change": APPB2C_cj_personalDetails_forms_personal_change6,
  "APPB2C.cj.personalDetails.forms.personal.dob": APPB2C_cj_personalDetails_forms_personal_dob6,
  "APPB2C.cj.personalDetails.forms.personal.dobCta": APPB2C_cj_personalDetails_forms_personal_dobCta6,
  "APPB2C.cj.personalDetails.forms.personal.firstMiddleName": APPB2C_cj_personalDetails_forms_personal_firstMiddleName6,
  "APPB2C.cj.personalDetails.forms.personal.firstName": APPB2C_cj_personalDetails_forms_personal_firstName6,
  "APPB2C.cj.personalDetails.forms.personal.idMatch": APPB2C_cj_personalDetails_forms_personal_idMatch6,
  "APPB2C.cj.personalDetails.forms.personal.lastName": APPB2C_cj_personalDetails_forms_personal_lastName6,
  "APPB2C.cj.personalDetails.forms.personal.name": APPB2C_cj_personalDetails_forms_personal_name6,
  "APPB2C.cj.personalDetails.forms.personal.noLastName": APPB2C_cj_personalDetails_forms_personal_noLastName6,
  "APPB2C.cj.personalDetails.forms.personal.sectionTitle": APPB2C_cj_personalDetails_forms_personal_sectionTitle6,
  "APPB2C.cj.personalDetails.forWho.child": APPB2C_cj_personalDetails_forWho_child6,
  "APPB2C.cj.personalDetails.forWho.childUnder18": APPB2C_cj_personalDetails_forWho_childUnder186,
  "APPB2C.cj.personalDetails.forWho.dob": APPB2C_cj_personalDetails_forWho_dob6,
  "APPB2C.cj.personalDetails.forWho.myself": APPB2C_cj_personalDetails_forWho_myself6,
  "APPB2C.cj.personalDetails.forWho.note": APPB2C_cj_personalDetails_forWho_note6,
  "APPB2C.cj.personalDetails.forWho.title": APPB2C_cj_personalDetails_forWho_title6,
  "APPB2C.cj.personalDetails.gender": APPB2C_cj_personalDetails_gender6,
  "APPB2C.cj.personalDetails.idNote.idMatch": APPB2C_cj_personalDetails_idNote_idMatch6,
  "APPB2C.cj.personalDetails.idNote.idMatch.change": APPB2C_cj_personalDetails_idNote_idMatch_change6,
  "APPB2C.cj.personalDetails.idNote.idMatch.france": APPB2C_cj_personalDetails_idNote_idMatch_france5,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.adult": APPB2C_cj_personalDetails_idNote_idMatch_france_adult5,
  "APPB2C.cj.personalDetails.idNote.idMatch.france.minor": APPB2C_cj_personalDetails_idNote_idMatch_france_minor5,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain": APPB2C_cj_personalDetails_idNote_idMatch_spain5,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.adult": APPB2C_cj_personalDetails_idNote_idMatch_spain_adult5,
  "APPB2C.cj.personalDetails.idNote.idMatch.spain.minor": APPB2C_cj_personalDetails_idNote_idMatch_spain_minor5,
  "APPB2C.cj.personalDetails.idNote.whenForChild": APPB2C_cj_personalDetails_idNote_whenForChild6,
  "APPB2C.cj.personalDetails.login.btn": APPB2C_cj_personalDetails_login_btn6,
  "APPB2C.cj.personalDetails.login.emailNotEditable": APPB2C_cj_personalDetails_login_emailNotEditable6,
  "APPB2C.cj.personalDetails.login.link": APPB2C_cj_personalDetails_login_link6,
  "APPB2C.cj.personalDetails.login.loggedIn": APPB2C_cj_personalDetails_login_loggedIn6,
  "APPB2C.cj.personalDetails.login.logout": APPB2C_cj_personalDetails_login_logout6,
  "APPB2C.cj.personalDetails.login.note": APPB2C_cj_personalDetails_login_note6,
  "APPB2C.cj.personalDetails.login.title": APPB2C_cj_personalDetails_login_title6,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.changeDate": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_changeDate6,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line1": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line16,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.line2": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_line26,
  "APPB2C.cj.personalDetails.registrationInLockoutPeriodModal.title": APPB2C_cj_personalDetails_registrationInLockoutPeriodModal_title6,
  "APPB2C.cj.personalDetails.title": APPB2C_cj_personalDetails_title6,
  "APPB2C.cj.personalDetails.tncs.agreement": APPB2C_cj_personalDetails_tncs_agreement6,
  "APPB2C.cj.personalDetails.tncs.title": APPB2C_cj_personalDetails_tncs_title6,
  "APPB2C.cj.personalDetails.visaType.label": APPB2C_cj_personalDetails_visaType_label6,
  "APPB2C.cj.personalDetails.visaType.label.child": APPB2C_cj_personalDetails_visaType_label_child6,
  "APPB2C.cj.personalDetails.visaType.link": APPB2C_cj_personalDetails_visaType_link6,
  "APPB2C.cj.personalDetails.visaType.title": APPB2C_cj_personalDetails_visaType_title6,
  "APPB2C.cj.personalDetails.visaType.title.child": APPB2C_cj_personalDetails_visaType_title_child6,
  "APPB2C.cj.rebook.errorModal.message": APPB2C_cj_rebook_errorModal_message6,
  "APPB2C.cj.rebook.errorModal.title": APPB2C_cj_rebook_errorModal_title6,
  "APPB2C.cj.review.acknowledgement.anotherPerson": APPB2C_cj_review_acknowledgement_anotherPerson6,
  "APPB2C.cj.review.acknowledgement.company": APPB2C_cj_review_acknowledgement_company6,
  "APPB2C.cj.review.acknowledgement.fields.companyName": APPB2C_cj_review_acknowledgement_fields_companyName6,
  "APPB2C.cj.review.acknowledgement.fields.companyName.subnote": APPB2C_cj_review_acknowledgement_fields_companyName_subnote6,
  "APPB2C.cj.review.acknowledgement.fields.country": APPB2C_cj_review_acknowledgement_fields_country6,
  "APPB2C.cj.review.acknowledgement.fields.email": APPB2C_cj_review_acknowledgement_fields_email6,
  "APPB2C.cj.review.acknowledgement.fields.familyName": APPB2C_cj_review_acknowledgement_fields_familyName6,
  "APPB2C.cj.review.acknowledgement.fields.firstName": APPB2C_cj_review_acknowledgement_fields_firstName6,
  "APPB2C.cj.review.acknowledgement.fields.mobile": APPB2C_cj_review_acknowledgement_fields_mobile6,
  "APPB2C.cj.review.acknowledgement.myself": APPB2C_cj_review_acknowledgement_myself6,
  "APPB2C.cj.review.acknowledgement.send": APPB2C_cj_review_acknowledgement_send6,
  "APPB2C.cj.review.acknowledgement.title": APPB2C_cj_review_acknowledgement_title6,
  "APPB2C.cj.review.agent.label": APPB2C_cj_review_agent_label6,
  "APPB2C.cj.review.agent.note": APPB2C_cj_review_agent_note6,
  "APPB2C.cj.review.agent.title": APPB2C_cj_review_agent_title6,
  "APPB2C.cj.review.ai.note": APPB2C_cj_review_ai_note6,
  "APPB2C.cj.review.ai.title": APPB2C_cj_review_ai_title6,
  "APPB2C.cj.review.bookingDetails.lrw.title": APPB2C_cj_review_bookingDetails_lrw_title6,
  "APPB2C.cj.review.bookingDetails.lrw.title.child": APPB2C_cj_review_bookingDetails_lrw_title_child6,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills6,
  "APPB2C.cj.review.bookingDetails.lrw.titleLifeSkills.child": APPB2C_cj_review_bookingDetails_lrw_titleLifeSkills_child6,
  "APPB2C.cj.review.bookingDetails.speaking.title": APPB2C_cj_review_bookingDetails_speaking_title6,
  "APPB2C.cj.review.bookingDetails.speaking.title.child": APPB2C_cj_review_bookingDetails_speaking_title_child6,
  "APPB2C.cj.review.bookingDetails.title": APPB2C_cj_review_bookingDetails_title6,
  "APPB2C.cj.review.bookNowNoFee.buttonText": APPB2C_cj_review_bookNowNoFee_buttonText6,
  "APPB2C.cj.review.bookTest": APPB2C_cj_review_bookTest6,
  "APPB2C.cj.review.change": APPB2C_cj_review_change6,
  "APPB2C.cj.review.changeLrwTest": APPB2C_cj_review_changeLrwTest6,
  "APPB2C.cj.review.changeSpeakingTest": APPB2C_cj_review_changeSpeakingTest6,
  "APPB2C.cj.review.changeUkviLsTest": APPB2C_cj_review_changeUkviLsTest6,
  "APPB2C.cj.review.childDetails.guardiansTitle": APPB2C_cj_review_childDetails_guardiansTitle6,
  "APPB2C.cj.review.childDetails.onTestDay": APPB2C_cj_review_childDetails_onTestDay6,
  "APPB2C.cj.review.childDetails.onTestDayOptions.1": APPB2C_cj_review_childDetails_onTestDayOptions_16,
  "APPB2C.cj.review.childDetails.onTestDayOptions.2": APPB2C_cj_review_childDetails_onTestDayOptions_26,
  "APPB2C.cj.review.childDetails.onTestDayOptions.3": APPB2C_cj_review_childDetails_onTestDayOptions_36,
  "APPB2C.cj.review.childDetails.title": APPB2C_cj_review_childDetails_title6,
  "APPB2C.cj.review.confirmPaymentModal.btnNote": APPB2C_cj_review_confirmPaymentModal_btnNote6,
  "APPB2C.cj.review.confirmPaymentModal.note": APPB2C_cj_review_confirmPaymentModal_note6,
  "APPB2C.cj.review.confirmPaymentModal.optionsTitle": APPB2C_cj_review_confirmPaymentModal_optionsTitle6,
  "APPB2C.cj.review.confirmPaymentModal.title": APPB2C_cj_review_confirmPaymentModal_title6,
  "APPB2C.cj.review.countryMsg.pakistan": APPB2C_cj_review_countryMsg_pakistan6,
  "APPB2C.cj.review.error.missingOrderAcknowledgement": APPB2C_cj_review_error_missingOrderAcknowledgement6,
  "APPB2C.cj.review.error.missingPickups": APPB2C_cj_review_error_missingPickups6,
  "APPB2C.cj.review.error.missingTown": APPB2C_cj_review_error_missingTown6,
  "APPB2C.cj.review.error.wrongCountrySetting": APPB2C_cj_review_error_wrongCountrySetting6,
  "APPB2C.cj.review.error.wrongPaymentConfig": APPB2C_cj_review_error_wrongPaymentConfig6,
  "APPB2C.cj.review.error.wrongTaxNumber": APPB2C_cj_review_error_wrongTaxNumber6,
  "APPB2C.cj.review.expiredIdError": APPB2C_cj_review_expiredIdError6,
  "APPB2C.cj.review.idDetails.issuing": APPB2C_cj_review_idDetails_issuing6,
  "APPB2C.cj.review.idDetails.nationality": APPB2C_cj_review_idDetails_nationality6,
  "APPB2C.cj.review.idDetails.title": APPB2C_cj_review_idDetails_title6,
  "APPB2C.cj.review.idDetails.title.child": APPB2C_cj_review_idDetails_title_child6,
  "APPB2C.cj.review.invoice.companyName": APPB2C_cj_review_invoice_companyName6,
  "APPB2C.cj.review.invoice.consent": APPB2C_cj_review_invoice_consent6,
  "APPB2C.cj.review.invoice.send": APPB2C_cj_review_invoice_send6,
  "APPB2C.cj.review.invoice.subtype.citizenDigitalCertificate": APPB2C_cj_review_invoice_subtype_citizenDigitalCertificate6,
  "APPB2C.cj.review.invoice.subtype.label": APPB2C_cj_review_invoice_subtype_label6,
  "APPB2C.cj.review.invoice.subtype.mobileBarcode": APPB2C_cj_review_invoice_subtype_mobileBarcode6,
  "APPB2C.cj.review.invoice.taxCaption.donate": APPB2C_cj_review_invoice_taxCaption_donate6,
  "APPB2C.cj.review.invoice.taxCaption.duplicatePaper": APPB2C_cj_review_invoice_taxCaption_duplicatePaper6,
  "APPB2C.cj.review.invoice.taxValidation.citizenDigitalCertificate": APPB2C_cj_review_invoice_taxValidation_citizenDigitalCertificate6,
  "APPB2C.cj.review.invoice.taxValidation.donate": APPB2C_cj_review_invoice_taxValidation_donate6,
  "APPB2C.cj.review.invoice.taxValidation.mobileBarcode": APPB2C_cj_review_invoice_taxValidation_mobileBarcode6,
  "APPB2C.cj.review.invoice.taxValidation.triplicate": APPB2C_cj_review_invoice_taxValidation_triplicate6,
  "APPB2C.cj.review.invoice.title": APPB2C_cj_review_invoice_title6,
  "APPB2C.cj.review.invoice.type.donate": APPB2C_cj_review_invoice_type_donate6,
  "APPB2C.cj.review.invoice.type.duplicateElectronic": APPB2C_cj_review_invoice_type_duplicateElectronic6,
  "APPB2C.cj.review.invoice.type.duplicatePaper": APPB2C_cj_review_invoice_type_duplicatePaper6,
  "APPB2C.cj.review.invoice.type.triplicate": APPB2C_cj_review_invoice_type_triplicate6,
  "APPB2C.cj.review.localization.localTime": APPB2C_cj_review_localization_localTime6,
  "APPB2C.cj.review.localization.location": APPB2C_cj_review_localization_location6,
  "APPB2C.cj.review.localization.timezone": APPB2C_cj_review_localization_timezone6,
  "APPB2C.cj.review.missingMarketingCta": APPB2C_cj_review_missingMarketingCta6,
  "APPB2C.cj.review.missingMarketingError": APPB2C_cj_review_missingMarketingError6,
  "APPB2C.cj.review.note.content": APPB2C_cj_review_note_content6,
  "APPB2C.cj.review.note.title": APPB2C_cj_review_note_title6,
  "APPB2C.cj.review.or": APPB2C_cj_review_or6,
  "APPB2C.cj.review.payLater.buttonText": APPB2C_cj_review_payLater_buttonText6,
  "APPB2C.cj.review.payLater.sideNote": APPB2C_cj_review_payLater_sideNote6,
  "APPB2C.cj.review.payment.fee": APPB2C_cj_review_payment_fee6,
  "APPB2C.cj.review.payment.promoCodes.applied": APPB2C_cj_review_payment_promoCodes_applied6,
  "APPB2C.cj.review.payment.promoCodes.applyCode": APPB2C_cj_review_payment_promoCodes_applyCode6,
  "APPB2C.cj.review.payment.promoCodes.discount": APPB2C_cj_review_payment_promoCodes_discount6,
  "APPB2C.cj.review.payment.promoCodes.generalError": APPB2C_cj_review_payment_promoCodes_generalError6,
  "APPB2C.cj.review.payment.promoCodes.haveCode": APPB2C_cj_review_payment_promoCodes_haveCode6,
  "APPB2C.cj.review.payment.promoCodes.invalidCode": APPB2C_cj_review_payment_promoCodes_invalidCode6,
  "APPB2C.cj.review.payment.promoCodes.newFee": APPB2C_cj_review_payment_promoCodes_newFee6,
  "APPB2C.cj.review.payment.promoCodes.notHaveCode": APPB2C_cj_review_payment_promoCodes_notHaveCode6,
  "APPB2C.cj.review.payment.promoCodes.promo": APPB2C_cj_review_payment_promoCodes_promo6,
  "APPB2C.cj.review.payment.promoCodes.promoCode": APPB2C_cj_review_payment_promoCodes_promoCode6,
  "APPB2C.cj.review.payment.promoCodes.remove": APPB2C_cj_review_payment_promoCodes_remove6,
  "APPB2C.cj.review.payment.promoCodes.removeCode": APPB2C_cj_review_payment_promoCodes_removeCode6,
  "APPB2C.cj.review.payment.promoCodes.total": APPB2C_cj_review_payment_promoCodes_total6,
  "APPB2C.cj.review.payment.tax": APPB2C_cj_review_payment_tax6,
  "APPB2C.cj.review.payment.title": APPB2C_cj_review_payment_title6,
  "APPB2C.cj.review.payment.total": APPB2C_cj_review_payment_total6,
  "APPB2C.cj.review.paymentError": APPB2C_cj_review_paymentError6,
  "APPB2C.cj.review.paymentNote": APPB2C_cj_review_paymentNote6,
  "APPB2C.cj.review.paymentNoteMultiple": APPB2C_cj_review_paymentNoteMultiple5,
  "APPB2C.cj.review.paymentNoteSingle": APPB2C_cj_review_paymentNoteSingle5,
  "APPB2C.cj.review.paymentNoteZeroFee": APPB2C_cj_review_paymentNoteZeroFee6,
  "APPB2C.cj.review.payOnline.buttonText": APPB2C_cj_review_payOnline_buttonText6,
  "APPB2C.cj.review.payOnline.sideNote": APPB2C_cj_review_payOnline_sideNote6,
  "APPB2C.cj.review.personalDetails.address": APPB2C_cj_review_personalDetails_address6,
  "APPB2C.cj.review.personalDetails.dob": APPB2C_cj_review_personalDetails_dob6,
  "APPB2C.cj.review.personalDetails.email": APPB2C_cj_review_personalDetails_email6,
  "APPB2C.cj.review.personalDetails.gender": APPB2C_cj_review_personalDetails_gender6,
  "APPB2C.cj.review.personalDetails.name": APPB2C_cj_review_personalDetails_name6,
  "APPB2C.cj.review.personalDetails.phone": APPB2C_cj_review_personalDetails_phone6,
  "APPB2C.cj.review.personalDetails.smsNotificationsConsent": APPB2C_cj_review_personalDetails_smsNotificationsConsent6,
  "APPB2C.cj.review.personalDetails.title": APPB2C_cj_review_personalDetails_title6,
  "APPB2C.cj.review.registrationError": APPB2C_cj_review_registrationError6,
  "APPB2C.cj.review.registrationFailedModal.changedFee.btn": APPB2C_cj_review_registrationFailedModal_changedFee_btn6,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line1": APPB2C_cj_review_registrationFailedModal_changedFee_line16,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line2": APPB2C_cj_review_registrationFailedModal_changedFee_line26,
  "APPB2C.cj.review.registrationFailedModal.changedFee.line3": APPB2C_cj_review_registrationFailedModal_changedFee_line36,
  "APPB2C.cj.review.registrationFailedModal.changedFee.title": APPB2C_cj_review_registrationFailedModal_changedFee_title6,
  "APPB2C.cj.review.registrationFailedModal.cta": APPB2C_cj_review_registrationFailedModal_cta6,
  "APPB2C.cj.review.registrationFailedModal.message": APPB2C_cj_review_registrationFailedModal_message6,
  "APPB2C.cj.review.registrationFailedModal.title": APPB2C_cj_review_registrationFailedModal_title6,
  "APPB2C.cj.review.taxNumberError": APPB2C_cj_review_taxNumberError6,
  "APPB2C.cj.review.title": APPB2C_cj_review_title6,
  "APPB2C.cj.review.tncs.agreement": APPB2C_cj_review_tncs_agreement6,
  "APPB2C.cj.review.tncs.title": APPB2C_cj_review_tncs_title6,
  "APPB2C.cj.review.tooHighPromoCodeError": APPB2C_cj_review_tooHighPromoCodeError6,
  "APPB2C.cj.timer.modal.cta": APPB2C_cj_timer_modal_cta6,
  "APPB2C.cj.timer.modal.message": APPB2C_cj_timer_modal_message6,
  "APPB2C.cj.timer.modal.title": APPB2C_cj_timer_modal_title6,
  "APPB2C.cj.timer.nearEnd.minuteCount": APPB2C_cj_timer_nearEnd_minuteCount6,
  "APPB2C.cj.timer.nearEnd.minuteCount_other": APPB2C_cj_timer_nearEnd_minuteCount_other6,
  "APPB2C.cj.timer.nearEnd.text": APPB2C_cj_timer_nearEnd_text6,
  "APPB2C.cj.timer.nearEnd.title": APPB2C_cj_timer_nearEnd_title6,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied6,
  "APPB2C.cj.ttProfile.forms.interest.countryBeingApplied.child": APPB2C_cj_ttProfile_forms_interest_countryBeingApplied_child6,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest6,
  "APPB2C.cj.ttProfile.forms.interest.reasonForTakingTest.child": APPB2C_cj_ttProfile_forms_interest_reasonForTakingTest_child6,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle": APPB2C_cj_ttProfile_forms_interest_sectionTitle6,
  "APPB2C.cj.ttProfile.forms.interest.sectionTitle.child": APPB2C_cj_ttProfile_forms_interest_sectionTitle_child6,
  "APPB2C.cj.ttProfile.forms.occupation.occupationLevel": APPB2C_cj_ttProfile_forms_occupation_occupationLevel6,
  "APPB2C.cj.ttProfile.forms.occupation.occupationSector": APPB2C_cj_ttProfile_forms_occupation_occupationSector6,
  "APPB2C.cj.ttProfile.forms.occupation.sectionTitle": APPB2C_cj_ttProfile_forms_occupation_sectionTitle6,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality": APPB2C_cj_ttProfile_forms_personal_countryOfNationality6,
  "APPB2C.cj.ttProfile.forms.personal.countryOfNationality.child": APPB2C_cj_ttProfile_forms_personal_countryOfNationality_child6,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.384": APPB2C_cj_ttProfile_forms_personal_educationLevel_3846,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.385": APPB2C_cj_ttProfile_forms_personal_educationLevel_3856,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.386": APPB2C_cj_ttProfile_forms_personal_educationLevel_3866,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.387": APPB2C_cj_ttProfile_forms_personal_educationLevel_3876,
  "APPB2C.cj.ttProfile.forms.personal.educationLevel.title": APPB2C_cj_ttProfile_forms_personal_educationLevel_title6,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken6,
  "APPB2C.cj.ttProfile.forms.personal.mainLanguageSpoken.child": APPB2C_cj_ttProfile_forms_personal_mainLanguageSpoken_child6,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle": APPB2C_cj_ttProfile_forms_personal_sectionTitle6,
  "APPB2C.cj.ttProfile.forms.personal.sectionTitle.child": APPB2C_cj_ttProfile_forms_personal_sectionTitle_child6,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish6,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglish.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglish_child6,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel6,
  "APPB2C.cj.ttProfile.forms.personal.yearsOfStudyingEnglishSublabel.child": APPB2C_cj_ttProfile_forms_personal_yearsOfStudyingEnglishSublabel_child6,
  "APPB2C.cj.ttProfile.note": APPB2C_cj_ttProfile_note6,
  "APPB2C.cj.ttProfile.note.child": APPB2C_cj_ttProfile_note_child6,
  "APPB2C.cj.ttProfile.title": APPB2C_cj_ttProfile_title6,
  "APPB2C.cj.ttProfile.title.child": APPB2C_cj_ttProfile_title_child6,
  "APPB2C.cj.ukviStartPage.ac.content": APPB2C_cj_ukviStartPage_ac_content6,
  "APPB2C.cj.ukviStartPage.ac.title": APPB2C_cj_ukviStartPage_ac_title6,
  "APPB2C.cj.ukviStartPage.gt.content": APPB2C_cj_ukviStartPage_gt_content6,
  "APPB2C.cj.ukviStartPage.gt.title": APPB2C_cj_ukviStartPage_gt_title6,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.content": APPB2C_cj_ukviStartPage_lifeSkils_a1_content6,
  "APPB2C.cj.ukviStartPage.lifeSkils.a1.title": APPB2C_cj_ukviStartPage_lifeSkils_a1_title6,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.content": APPB2C_cj_ukviStartPage_lifeSkils_a2_content6,
  "APPB2C.cj.ukviStartPage.lifeSkils.a2.title": APPB2C_cj_ukviStartPage_lifeSkils_a2_title6,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.content": APPB2C_cj_ukviStartPage_lifeSkils_b1_content6,
  "APPB2C.cj.ukviStartPage.lifeSkils.b1.title": APPB2C_cj_ukviStartPage_lifeSkils_b1_title6,
  "APPB2C.cj.ukviStartPage.lifeSkils.citizenship": APPB2C_cj_ukviStartPage_lifeSkils_citizenship6,
  "APPB2C.cj.ukviStartPage.lifeSkils.extension": APPB2C_cj_ukviStartPage_lifeSkils_extension6,
  "APPB2C.cj.ukviStartPage.lifeSkils.family": APPB2C_cj_ukviStartPage_lifeSkils_family6,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.content": APPB2C_cj_ukviStartPage_lifeSkils_note_content6,
  "APPB2C.cj.ukviStartPage.lifeSkils.note.title": APPB2C_cj_ukviStartPage_lifeSkils_note_title6,
  "APPB2C.cj.ukviStartPage.lifeSkils.title": APPB2C_cj_ukviStartPage_lifeSkils_title6,
  "APPB2C.cj.ukviStartPage.lifeSkils.visaTypes": APPB2C_cj_ukviStartPage_lifeSkils_visaTypes6,
  "APPB2C.cj.ukviStartPage.note1.content": APPB2C_cj_ukviStartPage_note1_content6,
  "APPB2C.cj.ukviStartPage.note1.title": APPB2C_cj_ukviStartPage_note1_title6,
  "APPB2C.cj.ukviStartPage.terms": APPB2C_cj_ukviStartPage_terms6,
  "APPB2C.cj.ukviStartPage.testsBelow": APPB2C_cj_ukviStartPage_testsBelow6,
  "APPB2C.cj.ukviStartPage.title": APPB2C_cj_ukviStartPage_title6,
  "APPB2C.cj.ukviStartPage.whichUkvi": APPB2C_cj_ukviStartPage_whichUkvi6,
  "APPB2C.common.account.details.email.cancel": APPB2C_common_account_details_email_cancel5,
  "APPB2C.common.account.details.email.confirm": APPB2C_common_account_details_email_confirm6,
  "APPB2C.common.account.details.email.label": APPB2C_common_account_details_email_label6,
  "APPB2C.common.account.details.email.maxLength": APPB2C_common_account_details_email_maxLength6,
  "APPB2C.common.account.details.email.mismatch": APPB2C_common_account_details_email_mismatch6,
  "APPB2C.common.account.details.email.note": APPB2C_common_account_details_email_note6,
  "APPB2C.common.account.details.email.save": APPB2C_common_account_details_email_save6,
  "APPB2C.common.account.details.email.set": APPB2C_common_account_details_email_set6,
  "APPB2C.common.account.details.email.success": APPB2C_common_account_details_email_success6,
  "APPB2C.common.account.details.email.username": APPB2C_common_account_details_email_username6,
  "APPB2C.common.account.details.email.validEmail": APPB2C_common_account_details_email_validEmail6,
  "APPB2C.common.account.details.name": APPB2C_common_account_details_name6,
  "APPB2C.common.account.details.psw.cancel": APPB2C_common_account_details_psw_cancel5,
  "APPB2C.common.account.details.psw.change": APPB2C_common_account_details_psw_change6,
  "APPB2C.common.account.details.psw.label": APPB2C_common_account_details_psw_label6,
  "APPB2C.common.account.details.psw.new": APPB2C_common_account_details_psw_new6,
  "APPB2C.common.account.details.psw.old": APPB2C_common_account_details_psw_old6,
  "APPB2C.common.account.details.psw.requirements.levels.0": APPB2C_common_account_details_psw_requirements_levels_06,
  "APPB2C.common.account.details.psw.requirements.levels.1": APPB2C_common_account_details_psw_requirements_levels_16,
  "APPB2C.common.account.details.psw.requirements.levels.2": APPB2C_common_account_details_psw_requirements_levels_26,
  "APPB2C.common.account.details.psw.requirements.levels.3": APPB2C_common_account_details_psw_requirements_levels_36,
  "APPB2C.common.account.details.psw.requirements.levels.4": APPB2C_common_account_details_psw_requirements_levels_46,
  "APPB2C.common.account.details.psw.requirements.maxLength": APPB2C_common_account_details_psw_requirements_maxLength6,
  "APPB2C.common.account.details.psw.requirements.mustHave": APPB2C_common_account_details_psw_requirements_mustHave6,
  "APPB2C.common.account.details.psw.requirements.ok": APPB2C_common_account_details_psw_requirements_ok6,
  "APPB2C.common.account.details.psw.requirements.reqs.length": APPB2C_common_account_details_psw_requirements_reqs_length6,
  "APPB2C.common.account.details.psw.requirements.reqs.lower": APPB2C_common_account_details_psw_requirements_reqs_lower6,
  "APPB2C.common.account.details.psw.requirements.reqs.number": APPB2C_common_account_details_psw_requirements_reqs_number6,
  "APPB2C.common.account.details.psw.requirements.reqs.special": APPB2C_common_account_details_psw_requirements_reqs_special6,
  "APPB2C.common.account.details.psw.requirements.reqs.upper": APPB2C_common_account_details_psw_requirements_reqs_upper6,
  "APPB2C.common.account.details.psw.requirements.shouldHave": APPB2C_common_account_details_psw_requirements_shouldHave6,
  "APPB2C.common.account.details.psw.requirements.strength": APPB2C_common_account_details_psw_requirements_strength6,
  "APPB2C.common.account.details.psw.requirements.tooWeak": APPB2C_common_account_details_psw_requirements_tooWeak6,
  "APPB2C.common.account.details.psw.show": APPB2C_common_account_details_psw_show6,
  "APPB2C.common.account.details.psw.success": APPB2C_common_account_details_psw_success6,
  "APPB2C.common.account.details.subnote.address": APPB2C_common_account_details_subnote_address6,
  "APPB2C.common.account.details.subnote.city": APPB2C_common_account_details_subnote_city6,
  "APPB2C.common.account.error": APPB2C_common_account_error6,
  "APPB2C.common.account.marketing.success": APPB2C_common_account_marketing_success6,
  "APPB2C.common.account.marketing.title": APPB2C_common_account_marketing_title6,
  "APPB2C.common.account.smsNotificationSettings.mobileLabel": APPB2C_common_account_smsNotificationSettings_mobileLabel6,
  "APPB2C.common.account.smsNotificationSettings.success": APPB2C_common_account_smsNotificationSettings_success6,
  "APPB2C.common.account.smsNotificationSettings.title": APPB2C_common_account_smsNotificationSettings_title6,
  "APPB2C.common.account.terms.cta": APPB2C_common_account_terms_cta6,
  "APPB2C.common.account.terms.disclaimer": APPB2C_common_account_terms_disclaimer6,
  "APPB2C.common.account.terms.download": APPB2C_common_account_terms_download6,
  "APPB2C.common.account.terms.extra.line1": APPB2C_common_account_terms_extra_line16,
  "APPB2C.common.account.terms.extra.line2": APPB2C_common_account_terms_extra_line26,
  "APPB2C.common.account.terms.extra.line3.1": APPB2C_common_account_terms_extra_line3_16,
  "APPB2C.common.account.terms.extra.line3.2": APPB2C_common_account_terms_extra_line3_26,
  "APPB2C.common.account.terms.extra.title": APPB2C_common_account_terms_extra_title6,
  "APPB2C.common.account.terms.previous": APPB2C_common_account_terms_previous6,
  "APPB2C.common.account.terms.title": APPB2C_common_account_terms_title6,
  "APPB2C.common.account.title": APPB2C_common_account_title6,
  "APPB2C.common.alertBar.afterTest": APPB2C_common_alertBar_afterTest6,
  "APPB2C.common.alertBar.beforeTest": APPB2C_common_alertBar_beforeTest6,
  "APPB2C.common.alertBar.cancelReupload": APPB2C_common_alertBar_cancelReupload6,
  "APPB2C.common.alertBar.check": APPB2C_common_alertBar_check6,
  "APPB2C.common.alertBar.childConsentMissing": APPB2C_common_alertBar_childConsentMissing6,
  "APPB2C.common.alertBar.creds": APPB2C_common_alertBar_creds6,
  "APPB2C.common.alertBar.eorPayment": APPB2C_common_alertBar_eorPayment6,
  "APPB2C.common.alertBar.eorPaymentProof": APPB2C_common_alertBar_eorPaymentProof6,
  "APPB2C.common.alertBar.idReupload": APPB2C_common_alertBar_idReupload6,
  "APPB2C.common.alertBar.idUpload": APPB2C_common_alertBar_idUpload6,
  "APPB2C.common.alertBar.medicalReupload": APPB2C_common_alertBar_medicalReupload6,
  "APPB2C.common.alertBar.medicalUpload": APPB2C_common_alertBar_medicalUpload6,
  "APPB2C.common.alertBar.regPayment": APPB2C_common_alertBar_regPayment6,
  "APPB2C.common.alertBar.regPaymentProof": APPB2C_common_alertBar_regPaymentProof6,
  "APPB2C.common.alertBar.withCreds": APPB2C_common_alertBar_withCreds6,
  "APPB2C.common.basic.add": APPB2C_common_basic_add6,
  "APPB2C.common.basic.backToTop": APPB2C_common_basic_backToTop6,
  "APPB2C.common.basic.bookingDetails.arrival": APPB2C_common_basic_bookingDetails_arrival6,
  "APPB2C.common.basic.bookingDetails.lifeSkills.results": APPB2C_common_basic_bookingDetails_lifeSkills_results6,
  "APPB2C.common.basic.bookingDetails.lifeSkills.title": APPB2C_common_basic_bookingDetails_lifeSkills_title6,
  "APPB2C.common.basic.bookingDetails.lrw.cd": APPB2C_common_basic_bookingDetails_lrw_cd6,
  "APPB2C.common.basic.bookingDetails.lrw.online": APPB2C_common_basic_bookingDetails_lrw_online6,
  "APPB2C.common.basic.bookingDetails.lrw.pb": APPB2C_common_basic_bookingDetails_lrw_pb6,
  "APPB2C.common.basic.bookingDetails.lrw.results": APPB2C_common_basic_bookingDetails_lrw_results6,
  "APPB2C.common.basic.bookingDetails.lrw.title": APPB2C_common_basic_bookingDetails_lrw_title6,
  "APPB2C.common.basic.bookingDetails.osrTitle.listening": APPB2C_common_basic_bookingDetails_osrTitle_listening6,
  "APPB2C.common.basic.bookingDetails.osrTitle.reading": APPB2C_common_basic_bookingDetails_osrTitle_reading6,
  "APPB2C.common.basic.bookingDetails.speaking.f2f": APPB2C_common_basic_bookingDetails_speaking_f2f6,
  "APPB2C.common.basic.bookingDetails.speaking.live": APPB2C_common_basic_bookingDetails_speaking_live6,
  "APPB2C.common.basic.bookingDetails.speaking.online": APPB2C_common_basic_bookingDetails_speaking_online6,
  "APPB2C.common.basic.bookingDetails.speaking.remote": APPB2C_common_basic_bookingDetails_speaking_remote6,
  "APPB2C.common.basic.bookingDetails.speaking.results": APPB2C_common_basic_bookingDetails_speaking_results6,
  "APPB2C.common.basic.bookingDetails.speaking.title": APPB2C_common_basic_bookingDetails_speaking_title6,
  "APPB2C.common.basic.bookPrePaid": APPB2C_common_basic_bookPrePaid6,
  "APPB2C.common.basic.cancel": APPB2C_common_basic_cancel6,
  "APPB2C.common.basic.change": APPB2C_common_basic_change6,
  "APPB2C.common.basic.choose": APPB2C_common_basic_choose6,
  "APPB2C.common.basic.close": APPB2C_common_basic_close6,
  "APPB2C.common.basic.copied": APPB2C_common_basic_copied6,
  "APPB2C.common.basic.copy": APPB2C_common_basic_copy6,
  "APPB2C.common.basic.dateField.day": APPB2C_common_basic_dateField_day6,
  "APPB2C.common.basic.dateField.month": APPB2C_common_basic_dateField_month6,
  "APPB2C.common.basic.dateField.months": APPB2C_common_basic_dateField_months6,
  "APPB2C.common.basic.dateField.year": APPB2C_common_basic_dateField_year6,
  "APPB2C.common.basic.edit": APPB2C_common_basic_edit6,
  "APPB2C.common.basic.errors.contactTestCentre": APPB2C_common_basic_errors_contactTestCentre6,
  "APPB2C.common.basic.errors.network": APPB2C_common_basic_errors_network6,
  "APPB2C.common.basic.errors.noTest": APPB2C_common_basic_errors_noTest6,
  "APPB2C.common.basic.errors.server": APPB2C_common_basic_errors_server6,
  "APPB2C.common.basic.errors.title": APPB2C_common_basic_errors_title6,
  "APPB2C.common.basic.errors.unableToDownloadFile": APPB2C_common_basic_errors_unableToDownloadFile6,
  "APPB2C.common.basic.errors.unknown": APPB2C_common_basic_errors_unknown6,
  "APPB2C.common.basic.expando.collapse": APPB2C_common_basic_expando_collapse6,
  "APPB2C.common.basic.expando.show": APPB2C_common_basic_expando_show6,
  "APPB2C.common.basic.externalLinkTitle": APPB2C_common_basic_externalLinkTitle6,
  "APPB2C.common.basic.failure": APPB2C_common_basic_failure6,
  "APPB2C.common.basic.forms.addressMaxLength": APPB2C_common_basic_forms_addressMaxLength6,
  "APPB2C.common.basic.forms.dobMin": APPB2C_common_basic_forms_dobMin6,
  "APPB2C.common.basic.forms.dobMinIol": APPB2C_common_basic_forms_dobMinIol6,
  "APPB2C.common.basic.forms.dobOutOfRange": APPB2C_common_basic_forms_dobOutOfRange6,
  "APPB2C.common.basic.forms.firstNameMaxLength": APPB2C_common_basic_forms_firstNameMaxLength6,
  "APPB2C.common.basic.forms.idExpiration": APPB2C_common_basic_forms_idExpiration6,
  "APPB2C.common.basic.forms.idMaxLength": APPB2C_common_basic_forms_idMaxLength6,
  "APPB2C.common.basic.forms.idNumber": APPB2C_common_basic_forms_idNumber6,
  "APPB2C.common.basic.forms.idProofRequired": APPB2C_common_basic_forms_idProofRequired6,
  "APPB2C.common.basic.forms.maxLengthMessage": APPB2C_common_basic_forms_maxLengthMessage6,
  "APPB2C.common.basic.forms.minorDobMax": APPB2C_common_basic_forms_minorDobMax6,
  "APPB2C.common.basic.forms.minorDobMin": APPB2C_common_basic_forms_minorDobMin6,
  "APPB2C.common.basic.forms.mobileBypass": APPB2C_common_basic_forms_mobileBypass6,
  "APPB2C.common.basic.forms.mobileLength": APPB2C_common_basic_forms_mobileLength6,
  "APPB2C.common.basic.forms.onlyLatin": APPB2C_common_basic_forms_onlyLatin6,
  "APPB2C.common.basic.forms.postCodeMaxLength": APPB2C_common_basic_forms_postCodeMaxLength6,
  "APPB2C.common.basic.forms.postCodeMessage": APPB2C_common_basic_forms_postCodeMessage6,
  "APPB2C.common.basic.forms.postCodeNotFound": APPB2C_common_basic_forms_postCodeNotFound6,
  "APPB2C.common.basic.forms.requiredMessage": APPB2C_common_basic_forms_requiredMessage6,
  "APPB2C.common.basic.forms.requiredNoSurmane": APPB2C_common_basic_forms_requiredNoSurmane6,
  "APPB2C.common.basic.forms.stateMaxLength": APPB2C_common_basic_forms_stateMaxLength6,
  "APPB2C.common.basic.forms.surnameMaxLength": APPB2C_common_basic_forms_surnameMaxLength6,
  "APPB2C.common.basic.forms.townMaxLength": APPB2C_common_basic_forms_townMaxLength6,
  "APPB2C.common.basic.forms.unsupportedChars": APPB2C_common_basic_forms_unsupportedChars6,
  "APPB2C.common.basic.forms.validMobile": APPB2C_common_basic_forms_validMobile6,
  "APPB2C.common.basic.goBack": APPB2C_common_basic_goBack6,
  "APPB2C.common.basic.loading": APPB2C_common_basic_loading6,
  "APPB2C.common.basic.menu": APPB2C_common_basic_menu6,
  "APPB2C.common.basic.no": APPB2C_common_basic_no6,
  "APPB2C.common.basic.notAllowed.msg": APPB2C_common_basic_notAllowed_msg6,
  "APPB2C.common.basic.notAllowed.title": APPB2C_common_basic_notAllowed_title6,
  "APPB2C.common.basic.notFound.goHome": APPB2C_common_basic_notFound_goHome6,
  "APPB2C.common.basic.notFound.msg": APPB2C_common_basic_notFound_msg6,
  "APPB2C.common.basic.notFound.title": APPB2C_common_basic_notFound_title6,
  "APPB2C.common.basic.notLoading.note": APPB2C_common_basic_notLoading_note6,
  "APPB2C.common.basic.notLoading.refresh": APPB2C_common_basic_notLoading_refresh6,
  "APPB2C.common.basic.notLoading.title": APPB2C_common_basic_notLoading_title6,
  "APPB2C.common.basic.ok": APPB2C_common_basic_ok6,
  "APPB2C.common.basic.on": APPB2C_common_basic_on6,
  "APPB2C.common.basic.oneSecond": APPB2C_common_basic_oneSecond6,
  "APPB2C.common.basic.pleaseNote": APPB2C_common_basic_pleaseNote6,
  "APPB2C.common.basic.retake": APPB2C_common_basic_retake6,
  "APPB2C.common.basic.upload": APPB2C_common_basic_upload6,
  "APPB2C.common.basic.validationError": APPB2C_common_basic_validationError6,
  "APPB2C.common.basic.waitReassurance": APPB2C_common_basic_waitReassurance6,
  "APPB2C.common.basic.wereSorry": APPB2C_common_basic_wereSorry6,
  "APPB2C.common.basic.yes": APPB2C_common_basic_yes6,
  "APPB2C.common.bookTest.changeExamType": APPB2C_common_bookTest_changeExamType6,
  "APPB2C.common.bookTest.filters.afternoon": APPB2C_common_bookTest_filters_afternoon6,
  "APPB2C.common.bookTest.filters.allDates": APPB2C_common_bookTest_filters_allDates6,
  "APPB2C.common.bookTest.filters.anyTime": APPB2C_common_bookTest_filters_anyTime6,
  "APPB2C.common.bookTest.filters.computer": APPB2C_common_bookTest_filters_computer6,
  "APPB2C.common.bookTest.filters.evening": APPB2C_common_bookTest_filters_evening6,
  "APPB2C.common.bookTest.filters.modal.cta": APPB2C_common_bookTest_filters_modal_cta6,
  "APPB2C.common.bookTest.filters.modal.hide": APPB2C_common_bookTest_filters_modal_hide6,
  "APPB2C.common.bookTest.filters.modal.iDontMind": APPB2C_common_bookTest_filters_modal_iDontMind6,
  "APPB2C.common.bookTest.filters.modal.resultsIn": APPB2C_common_bookTest_filters_modal_resultsIn6,
  "APPB2C.common.bookTest.filters.modal.subtitle": APPB2C_common_bookTest_filters_modal_subtitle6,
  "APPB2C.common.bookTest.filters.modal.title": APPB2C_common_bookTest_filters_modal_title6,
  "APPB2C.common.bookTest.filters.modal.whichFormatTest": APPB2C_common_bookTest_filters_modal_whichFormatTest6,
  "APPB2C.common.bookTest.filters.modal.whichTimeOfDay": APPB2C_common_bookTest_filters_modal_whichTimeOfDay6,
  "APPB2C.common.bookTest.filters.morning": APPB2C_common_bookTest_filters_morning6,
  "APPB2C.common.bookTest.filters.paper": APPB2C_common_bookTest_filters_paper6,
  "APPB2C.common.bookTest.filters.seeAllFilters": APPB2C_common_bookTest_filters_seeAllFilters6,
  "APPB2C.common.bookTest.filters.title": APPB2C_common_bookTest_filters_title6,
  "APPB2C.common.bookTest.loadMoreTests": APPB2C_common_bookTest_loadMoreTests6,
  "APPB2C.common.bookTest.noDiscount.msg.countryNotMatched": APPB2C_common_bookTest_noDiscount_msg_countryNotMatched6,
  "APPB2C.common.bookTest.noDiscount.msg.notFound": APPB2C_common_bookTest_noDiscount_msg_notFound6,
  "APPB2C.common.bookTest.noDiscount.msg.notSupported": APPB2C_common_bookTest_noDiscount_msg_notSupported6,
  "APPB2C.common.bookTest.noDiscount.msg.reserved": APPB2C_common_bookTest_noDiscount_msg_reserved6,
  "APPB2C.common.bookTest.noDiscount.msg.used": APPB2C_common_bookTest_noDiscount_msg_used6,
  "APPB2C.common.bookTest.noDiscount.title": APPB2C_common_bookTest_noDiscount_title6,
  "APPB2C.common.bookTest.results.book": APPB2C_common_bookTest_results_book6,
  "APPB2C.common.bookTest.results.bookFor": APPB2C_common_bookTest_results_bookFor6,
  "APPB2C.common.bookTest.results.cd": APPB2C_common_bookTest_results_cd6,
  "APPB2C.common.bookTest.results.changeSpeaking": APPB2C_common_bookTest_results_changeSpeaking6,
  "APPB2C.common.bookTest.results.clearFilters": APPB2C_common_bookTest_results_clearFilters6,
  "APPB2C.common.bookTest.results.fromCurrentLocation": APPB2C_common_bookTest_results_fromCurrentLocation6,
  "APPB2C.common.bookTest.results.lrw": APPB2C_common_bookTest_results_lrw6,
  "APPB2C.common.bookTest.results.noResultsGoBack": APPB2C_common_bookTest_results_noResultsGoBack6,
  "APPB2C.common.bookTest.results.noResultsText": APPB2C_common_bookTest_results_noResultsText6,
  "APPB2C.common.bookTest.results.noResultsTitle": APPB2C_common_bookTest_results_noResultsTitle6,
  "APPB2C.common.bookTest.results.pb": APPB2C_common_bookTest_results_pb6,
  "APPB2C.common.bookTest.results.review": APPB2C_common_bookTest_results_review6,
  "APPB2C.common.bookTest.results.searchText": APPB2C_common_bookTest_results_searchText6,
  "APPB2C.common.bookTest.results.speaking": APPB2C_common_bookTest_results_speaking6,
  "APPB2C.common.bookTest.results.viewOnMap": APPB2C_common_bookTest_results_viewOnMap6,
  "APPB2C.common.bookTest.sortBy.date": APPB2C_common_bookTest_sortBy_date6,
  "APPB2C.common.bookTest.sortBy.distance": APPB2C_common_bookTest_sortBy_distance6,
  "APPB2C.common.bookTest.sortBy.price": APPB2C_common_bookTest_sortBy_price6,
  "APPB2C.common.bookTest.sortBy.title": APPB2C_common_bookTest_sortBy_title6,
  "APPB2C.common.bookTest.title": APPB2C_common_bookTest_title6,
  "APPB2C.common.cancelBooking.alreadyRequested.cta": APPB2C_common_cancelBooking_alreadyRequested_cta6,
  "APPB2C.common.cancelBooking.alreadyRequested.title": APPB2C_common_cancelBooking_alreadyRequested_title6,
  "APPB2C.common.cancelBooking.back": APPB2C_common_cancelBooking_back6,
  "APPB2C.common.cancelBooking.bankDetails.form.accountHolder": APPB2C_common_cancelBooking_bankDetails_form_accountHolder6,
  "APPB2C.common.cancelBooking.bankDetails.form.bankAddress": APPB2C_common_cancelBooking_bankDetails_form_bankAddress6,
  "APPB2C.common.cancelBooking.bankDetails.form.bankName": APPB2C_common_cancelBooking_bankDetails_form_bankName6,
  "APPB2C.common.cancelBooking.bankDetails.form.bankNumber": APPB2C_common_cancelBooking_bankDetails_form_bankNumber6,
  "APPB2C.common.cancelBooking.bankDetails.form.changeCountry": APPB2C_common_cancelBooking_bankDetails_form_changeCountry6,
  "APPB2C.common.cancelBooking.bankDetails.form.city": APPB2C_common_cancelBooking_bankDetails_form_city6,
  "APPB2C.common.cancelBooking.bankDetails.form.country": APPB2C_common_cancelBooking_bankDetails_form_country6,
  "APPB2C.common.cancelBooking.bankDetails.form.holderEmail": APPB2C_common_cancelBooking_bankDetails_form_holderEmail6,
  "APPB2C.common.cancelBooking.bankDetails.form.holderName": APPB2C_common_cancelBooking_bankDetails_form_holderName6,
  "APPB2C.common.cancelBooking.bankDetails.form.iban": APPB2C_common_cancelBooking_bankDetails_form_iban6,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidIban": APPB2C_common_cancelBooking_bankDetails_form_invalidIban6,
  "APPB2C.common.cancelBooking.bankDetails.form.invalidSwift": APPB2C_common_cancelBooking_bankDetails_form_invalidSwift6,
  "APPB2C.common.cancelBooking.bankDetails.form.postcode": APPB2C_common_cancelBooking_bankDetails_form_postcode6,
  "APPB2C.common.cancelBooking.bankDetails.form.sortCode": APPB2C_common_cancelBooking_bankDetails_form_sortCode6,
  "APPB2C.common.cancelBooking.bankDetails.form.swift": APPB2C_common_cancelBooking_bankDetails_form_swift6,
  "APPB2C.common.cancelBooking.bankDetails.note": APPB2C_common_cancelBooking_bankDetails_note6,
  "APPB2C.common.cancelBooking.bankDetails.privacy": APPB2C_common_cancelBooking_bankDetails_privacy6,
  "APPB2C.common.cancelBooking.bankDetails.terms.consent": APPB2C_common_cancelBooking_bankDetails_terms_consent6,
  "APPB2C.common.cancelBooking.bankDetails.terms.title": APPB2C_common_cancelBooking_bankDetails_terms_title6,
  "APPB2C.common.cancelBooking.bankDetails.title": APPB2C_common_cancelBooking_bankDetails_title6,
  "APPB2C.common.cancelBooking.bottomNotes.note1": APPB2C_common_cancelBooking_bottomNotes_note16,
  "APPB2C.common.cancelBooking.bottomNotes.note2": APPB2C_common_cancelBooking_bottomNotes_note26,
  "APPB2C.common.cancelBooking.bottomNotes.note3": APPB2C_common_cancelBooking_bottomNotes_note36,
  "APPB2C.common.cancelBooking.bottomNotes.terms": APPB2C_common_cancelBooking_bottomNotes_terms6,
  "APPB2C.common.cancelBooking.cancelNotes.1": APPB2C_common_cancelBooking_cancelNotes_16,
  "APPB2C.common.cancelBooking.cancelNotes.2": APPB2C_common_cancelBooking_cancelNotes_26,
  "APPB2C.common.cancelBooking.cancelNotes.3": APPB2C_common_cancelBooking_cancelNotes_36,
  "APPB2C.common.cancelBooking.checkTest": APPB2C_common_cancelBooking_checkTest6,
  "APPB2C.common.cancelBooking.cta.addBankDetails": APPB2C_common_cancelBooking_cta_addBankDetails6,
  "APPB2C.common.cancelBooking.cta.goBack": APPB2C_common_cancelBooking_cta_goBack6,
  "APPB2C.common.cancelBooking.cta.submit": APPB2C_common_cancelBooking_cta_submit6,
  "APPB2C.common.cancelBooking.error.cancelledAlready": APPB2C_common_cancelBooking_error_cancelledAlready6,
  "APPB2C.common.cancelBooking.error.missingFile": APPB2C_common_cancelBooking_error_missingFile6,
  "APPB2C.common.cancelBooking.error.requestedAlready": APPB2C_common_cancelBooking_error_requestedAlready6,
  "APPB2C.common.cancelBooking.reason.form.choose": APPB2C_common_cancelBooking_reason_form_choose6,
  "APPB2C.common.cancelBooking.reason.form.freeform": APPB2C_common_cancelBooking_reason_form_freeform6,
  "APPB2C.common.cancelBooking.reason.form.optional": APPB2C_common_cancelBooking_reason_form_optional6,
  "APPB2C.common.cancelBooking.reason.form.requiredEvidence": APPB2C_common_cancelBooking_reason_form_requiredEvidence6,
  "APPB2C.common.cancelBooking.reason.form.requiredReason": APPB2C_common_cancelBooking_reason_form_requiredReason6,
  "APPB2C.common.cancelBooking.reason.form.title": APPB2C_common_cancelBooking_reason_form_title6,
  "APPB2C.common.cancelBooking.reason.note.content": APPB2C_common_cancelBooking_reason_note_content6,
  "APPB2C.common.cancelBooking.reason.note.title": APPB2C_common_cancelBooking_reason_note_title6,
  "APPB2C.common.cancelBooking.subtitle": APPB2C_common_cancelBooking_subtitle6,
  "APPB2C.common.cancelBooking.title": APPB2C_common_cancelBooking_title6,
  "APPB2C.common.cancelBooking.transferNote.cta": APPB2C_common_cancelBooking_transferNote_cta6,
  "APPB2C.common.cancelBooking.transferNote.title": APPB2C_common_cancelBooking_transferNote_title6,
  "APPB2C.common.cancelBooking.transferNote.titleIol": APPB2C_common_cancelBooking_transferNote_titleIol6,
  "APPB2C.common.cancelBooking.upload.mobile": APPB2C_common_cancelBooking_upload_mobile6,
  "APPB2C.common.cancelBooking.upload.notes.1": APPB2C_common_cancelBooking_upload_notes_16,
  "APPB2C.common.cancelBooking.upload.notes.10": APPB2C_common_cancelBooking_upload_notes_106,
  "APPB2C.common.cancelBooking.upload.notes.2": APPB2C_common_cancelBooking_upload_notes_26,
  "APPB2C.common.cancelBooking.upload.notes.3": APPB2C_common_cancelBooking_upload_notes_36,
  "APPB2C.common.cancelBooking.upload.notes.4": APPB2C_common_cancelBooking_upload_notes_46,
  "APPB2C.common.cancelBooking.upload.notes.5": APPB2C_common_cancelBooking_upload_notes_56,
  "APPB2C.common.cancelBooking.upload.notes.6": APPB2C_common_cancelBooking_upload_notes_66,
  "APPB2C.common.cancelBooking.upload.notes.7": APPB2C_common_cancelBooking_upload_notes_76,
  "APPB2C.common.cancelBooking.upload.notes.8": APPB2C_common_cancelBooking_upload_notes_86,
  "APPB2C.common.cancelBooking.upload.requirements.note": APPB2C_common_cancelBooking_upload_requirements_note6,
  "APPB2C.common.cancelBooking.upload.requirements.title": APPB2C_common_cancelBooking_upload_requirements_title6,
  "APPB2C.common.cancelBooking.upload.title": APPB2C_common_cancelBooking_upload_title6,
  "APPB2C.common.changeBooking.cancel.cta": APPB2C_common_changeBooking_cancel_cta6,
  "APPB2C.common.changeBooking.cancel.text": APPB2C_common_changeBooking_cancel_text6,
  "APPB2C.common.changeBooking.goBack": APPB2C_common_changeBooking_goBack6,
  "APPB2C.common.changeBooking.subtitle": APPB2C_common_changeBooking_subtitle6,
  "APPB2C.common.changeBooking.title": APPB2C_common_changeBooking_title6,
  "APPB2C.common.changeBooking.transfer.cta": APPB2C_common_changeBooking_transfer_cta6,
  "APPB2C.common.changeBooking.transfer.text": APPB2C_common_changeBooking_transfer_text6,
  "APPB2C.common.changeBooking.transfer.textIol": APPB2C_common_changeBooking_transfer_textIol6,
  "APPB2C.common.changeBooking.transferNotes.1": APPB2C_common_changeBooking_transferNotes_16,
  "APPB2C.common.changeBooking.transferNotes.2": APPB2C_common_changeBooking_transferNotes_26,
  "APPB2C.common.changeBooking.transferNotesIol.1": APPB2C_common_changeBooking_transferNotesIol_16,
  "APPB2C.common.changeBooking.transferNotesIol.2": APPB2C_common_changeBooking_transferNotesIol_26,
  "APPB2C.common.changeBookingAck.back": APPB2C_common_changeBookingAck_back6,
  "APPB2C.common.changeBookingAck.cancel.banner": APPB2C_common_changeBookingAck_cancel_banner6,
  "APPB2C.common.changeBookingAck.cancel.contact.email": APPB2C_common_changeBookingAck_cancel_contact_email6,
  "APPB2C.common.changeBookingAck.cancel.contact.phone": APPB2C_common_changeBookingAck_cancel_contact_phone6,
  "APPB2C.common.changeBookingAck.cancel.contact.title": APPB2C_common_changeBookingAck_cancel_contact_title6,
  "APPB2C.common.changeBookingAck.cancel.contact.titleIol": APPB2C_common_changeBookingAck_cancel_contact_titleIol6,
  "APPB2C.common.changeBookingAck.cancel.refNr": APPB2C_common_changeBookingAck_cancel_refNr6,
  "APPB2C.common.changeBookingAck.cancel.refunds": APPB2C_common_changeBookingAck_cancel_refunds6,
  "APPB2C.common.changeBookingAck.cancel.respondTime": APPB2C_common_changeBookingAck_cancel_respondTime6,
  "APPB2C.common.changeBookingAck.cancel.terms": APPB2C_common_changeBookingAck_cancel_terms6,
  "APPB2C.common.changeBookingAck.cancel.title": APPB2C_common_changeBookingAck_cancel_title6,
  "APPB2C.common.changeBookingAck.fees": APPB2C_common_changeBookingAck_fees6,
  "APPB2C.common.changeBookingAck.feesIol": APPB2C_common_changeBookingAck_feesIol6,
  "APPB2C.common.changeBookingAck.speaking.banner": APPB2C_common_changeBookingAck_speaking_banner6,
  "APPB2C.common.changeBookingAck.speaking.note.line1": APPB2C_common_changeBookingAck_speaking_note_line16,
  "APPB2C.common.changeBookingAck.speaking.note.line2": APPB2C_common_changeBookingAck_speaking_note_line26,
  "APPB2C.common.changeBookingAck.speaking.note.title": APPB2C_common_changeBookingAck_speaking_note_title6,
  "APPB2C.common.changeBookingAck.speaking.title": APPB2C_common_changeBookingAck_speaking_title6,
  "APPB2C.common.changeBookingAck.terms": APPB2C_common_changeBookingAck_terms6,
  "APPB2C.common.changeBookingAck.transfer.banner": APPB2C_common_changeBookingAck_transfer_banner6,
  "APPB2C.common.changeBookingAck.transfer.note": APPB2C_common_changeBookingAck_transfer_note6,
  "APPB2C.common.changeBookingAck.transfer.respondTime": APPB2C_common_changeBookingAck_transfer_respondTime6,
  "APPB2C.common.changeBookingAck.transfer.title": APPB2C_common_changeBookingAck_transfer_title6,
  "APPB2C.common.changeBookingAck.transferIol.banner": APPB2C_common_changeBookingAck_transferIol_banner6,
  "APPB2C.common.changeBookingAck.transferIol.contact": APPB2C_common_changeBookingAck_transferIol_contact6,
  "APPB2C.common.changeBookingAck.transferIol.note": APPB2C_common_changeBookingAck_transferIol_note6,
  "APPB2C.common.changeBookingAck.transferIol.refNr": APPB2C_common_changeBookingAck_transferIol_refNr6,
  "APPB2C.common.changeBookingAck.transferIol.terms": APPB2C_common_changeBookingAck_transferIol_terms6,
  "APPB2C.common.changeSpeaking.cards.change": APPB2C_common_changeSpeaking_cards_change6,
  "APPB2C.common.changeSpeaking.cards.noSlots": APPB2C_common_changeSpeaking_cards_noSlots6,
  "APPB2C.common.changeSpeaking.cards.noSlots.error": APPB2C_common_changeSpeaking_cards_noSlots_error6,
  "APPB2C.common.changeSpeaking.cards.title": APPB2C_common_changeSpeaking_cards_title6,
  "APPB2C.common.changeSpeaking.cta": APPB2C_common_changeSpeaking_cta6,
  "APPB2C.common.changeSpeaking.modals.noSlots.keepTest": APPB2C_common_changeSpeaking_modals_noSlots_keepTest6,
  "APPB2C.common.changeSpeaking.modals.noSlots.line1": APPB2C_common_changeSpeaking_modals_noSlots_line16,
  "APPB2C.common.changeSpeaking.modals.noSlots.line2": APPB2C_common_changeSpeaking_modals_noSlots_line26,
  "APPB2C.common.changeSpeaking.modals.noSlots.title": APPB2C_common_changeSpeaking_modals_noSlots_title6,
  "APPB2C.common.changeSpeaking.modals.noSlots.transferTest": APPB2C_common_changeSpeaking_modals_noSlots_transferTest6,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.cta": APPB2C_common_changeSpeaking_modals_testNotAvailable_cta6,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line1": APPB2C_common_changeSpeaking_modals_testNotAvailable_line16,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.line2": APPB2C_common_changeSpeaking_modals_testNotAvailable_line26,
  "APPB2C.common.changeSpeaking.modals.testNotAvailable.title": APPB2C_common_changeSpeaking_modals_testNotAvailable_title6,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.cta": APPB2C_common_changeSpeaking_modals_transferNotAvailable_cta6,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.line1": APPB2C_common_changeSpeaking_modals_transferNotAvailable_line16,
  "APPB2C.common.changeSpeaking.modals.transferNotAvailable.title": APPB2C_common_changeSpeaking_modals_transferNotAvailable_title6,
  "APPB2C.common.changeSpeaking.note.back": APPB2C_common_changeSpeaking_note_back6,
  "APPB2C.common.changeSpeaking.note.line1": APPB2C_common_changeSpeaking_note_line16,
  "APPB2C.common.changeSpeaking.note.line2": APPB2C_common_changeSpeaking_note_line26,
  "APPB2C.common.changeSpeaking.note.title": APPB2C_common_changeSpeaking_note_title6,
  "APPB2C.common.changeSpeaking.title": APPB2C_common_changeSpeaking_title6,
  "APPB2C.common.chooseTest.difference": APPB2C_common_chooseTest_difference6,
  "APPB2C.common.chooseTest.iol.consent": APPB2C_common_chooseTest_iol_consent6,
  "APPB2C.common.chooseTest.iol.cta": APPB2C_common_chooseTest_iol_cta6,
  "APPB2C.common.chooseTest.iol.desc": APPB2C_common_chooseTest_iol_desc6,
  "APPB2C.common.chooseTest.iol.link": APPB2C_common_chooseTest_iol_link6,
  "APPB2C.common.chooseTest.iol.note.content": APPB2C_common_chooseTest_iol_note_content6,
  "APPB2C.common.chooseTest.iol.note.title": APPB2C_common_chooseTest_iol_note_title6,
  "APPB2C.common.chooseTest.iol.title": APPB2C_common_chooseTest_iol_title6,
  "APPB2C.common.chooseTest.ors.cta": APPB2C_common_chooseTest_ors_cta6,
  "APPB2C.common.chooseTest.ors.descAc": APPB2C_common_chooseTest_ors_descAc6,
  "APPB2C.common.chooseTest.ors.descGt": APPB2C_common_chooseTest_ors_descGt6,
  "APPB2C.common.chooseTest.ors.title": APPB2C_common_chooseTest_ors_title6,
  "APPB2C.common.chooseTest.title": APPB2C_common_chooseTest_title6,
  "APPB2C.common.chooseTest.ukvi.difference": APPB2C_common_chooseTest_ukvi_difference6,
  "APPB2C.common.chooseTest.ukvi.inCentre.cta": APPB2C_common_chooseTest_ukvi_inCentre_cta6,
  "APPB2C.common.chooseTest.ukvi.inCentre.descAc": APPB2C_common_chooseTest_ukvi_inCentre_descAc6,
  "APPB2C.common.chooseTest.ukvi.inCentre.descGt": APPB2C_common_chooseTest_ukvi_inCentre_descGt6,
  "APPB2C.common.chooseTest.ukvi.inCentre.title": APPB2C_common_chooseTest_ukvi_inCentre_title6,
  "APPB2C.common.chooseTest.ukvi.online.consent": APPB2C_common_chooseTest_ukvi_online_consent6,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.symbol": APPB2C_common_chooseTest_ukvi_online_consentBiometric_symbol6,
  "APPB2C.common.chooseTest.ukvi.online.consentBiometric.title": APPB2C_common_chooseTest_ukvi_online_consentBiometric_title6,
  "APPB2C.common.chooseTest.ukvi.online.cta": APPB2C_common_chooseTest_ukvi_online_cta6,
  "APPB2C.common.chooseTest.ukvi.online.link": APPB2C_common_chooseTest_ukvi_online_link6,
  "APPB2C.common.chooseTest.ukvi.online.note.content": APPB2C_common_chooseTest_ukvi_online_note_content6,
  "APPB2C.common.chooseTest.ukvi.online.note.title": APPB2C_common_chooseTest_ukvi_online_note_title6,
  "APPB2C.common.chooseTest.ukvi.online.title": APPB2C_common_chooseTest_ukvi_online_title6,
  "APPB2C.common.draft.cancel": APPB2C_common_draft_cancel5,
  "APPB2C.common.draft.cta.confirm": APPB2C_common_draft_cta_confirm6,
  "APPB2C.common.draft.cta.decline": APPB2C_common_draft_cta_decline6,
  "APPB2C.common.draft.cta.note": APPB2C_common_draft_cta_note6,
  "APPB2C.common.draft.difference": APPB2C_common_draft_difference6,
  "APPB2C.common.draft.edit": APPB2C_common_draft_edit6,
  "APPB2C.common.draft.error.pastDeadline": APPB2C_common_draft_error_pastDeadline6,
  "APPB2C.common.draft.id.cta": APPB2C_common_draft_id_cta6,
  "APPB2C.common.draft.id.documentWithNoExpiryDate": APPB2C_common_draft_id_documentWithNoExpiryDate6,
  "APPB2C.common.draft.id.header": APPB2C_common_draft_id_header6,
  "APPB2C.common.draft.id.issuingAuthority": APPB2C_common_draft_id_issuingAuthority6,
  "APPB2C.common.draft.id.note": APPB2C_common_draft_id_note6,
  "APPB2C.common.draft.id.title": APPB2C_common_draft_id_title6,
  "APPB2C.common.draft.note.line1": APPB2C_common_draft_note_line16,
  "APPB2C.common.draft.note.line2": APPB2C_common_draft_note_line26,
  "APPB2C.common.draft.note.line3": APPB2C_common_draft_note_line36,
  "APPB2C.common.draft.personal.cta": APPB2C_common_draft_personal_cta6,
  "APPB2C.common.draft.personal.header": APPB2C_common_draft_personal_header6,
  "APPB2C.common.draft.personal.note": APPB2C_common_draft_personal_note6,
  "APPB2C.common.draft.personal.title": APPB2C_common_draft_personal_title6,
  "APPB2C.common.draft.title": APPB2C_common_draft_title6,
  "APPB2C.common.examType.iol.ac": APPB2C_common_examType_iol_ac6,
  "APPB2C.common.examType.iol.gt": APPB2C_common_examType_iol_gt6,
  "APPB2C.common.examType.ors.ac": APPB2C_common_examType_ors_ac6,
  "APPB2C.common.examType.ors.gt": APPB2C_common_examType_ors_gt6,
  "APPB2C.common.examType.ors.osr.ac": APPB2C_common_examType_ors_osr_ac6,
  "APPB2C.common.examType.ors.osr.gt": APPB2C_common_examType_ors_osr_gt6,
  "APPB2C.common.examType.ukvi.ac": APPB2C_common_examType_ukvi_ac6,
  "APPB2C.common.examType.ukvi.gt": APPB2C_common_examType_ukvi_gt6,
  "APPB2C.common.examType.ukvi.lfska1": APPB2C_common_examType_ukvi_lfska16,
  "APPB2C.common.examType.ukvi.lfska2": APPB2C_common_examType_ukvi_lfska26,
  "APPB2C.common.examType.ukvi.lfskb1": APPB2C_common_examType_ukvi_lfskb16,
  "APPB2C.common.examType.ukvi.osr.ac": APPB2C_common_examType_ukvi_osr_ac6,
  "APPB2C.common.examType.ukvi.osr.gt": APPB2C_common_examType_ukvi_osr_gt6,
  "APPB2C.common.examType.uol.ac": APPB2C_common_examType_uol_ac6,
  "APPB2C.common.examType.uol.gt": APPB2C_common_examType_uol_gt6,
  "APPB2C.common.filePreview.label": APPB2C_common_filePreview_label6,
  "APPB2C.common.filePreview.pdfPreviewLink": APPB2C_common_filePreview_pdfPreviewLink6,
  "APPB2C.common.filePreview.status": APPB2C_common_filePreview_status6,
  "APPB2C.common.filePreview.statusName.approved": APPB2C_common_filePreview_statusName_approved6,
  "APPB2C.common.filePreview.statusName.deleted": APPB2C_common_filePreview_statusName_deleted6,
  "APPB2C.common.filePreview.statusName.pending": APPB2C_common_filePreview_statusName_pending6,
  "APPB2C.common.filePreview.statusName.rejected": APPB2C_common_filePreview_statusName_rejected6,
  "APPB2C.common.filePreview.statusName.reviewed": APPB2C_common_filePreview_statusName_reviewed6,
  "APPB2C.common.fileUpload.cta": APPB2C_common_fileUpload_cta6,
  "APPB2C.common.fileUpload.exceededSize": APPB2C_common_fileUpload_exceededSize6,
  "APPB2C.common.fileUpload.fileApproved": APPB2C_common_fileUpload_fileApproved6,
  "APPB2C.common.fileUpload.fileOptional": APPB2C_common_fileUpload_fileOptional6,
  "APPB2C.common.fileUpload.fileRequired": APPB2C_common_fileUpload_fileRequired6,
  "APPB2C.common.fileUpload.filesCount": APPB2C_common_fileUpload_filesCount6,
  "APPB2C.common.fileUpload.fileSize": APPB2C_common_fileUpload_fileSize6,
  "APPB2C.common.fileUpload.imgAlt": APPB2C_common_fileUpload_imgAlt6,
  "APPB2C.common.fileUpload.pdfIeMsg": APPB2C_common_fileUpload_pdfIeMsg6,
  "APPB2C.common.fileUpload.pdfPasswordMsg": APPB2C_common_fileUpload_pdfPasswordMsg6,
  "APPB2C.common.fileUpload.remove": APPB2C_common_fileUpload_remove6,
  "APPB2C.common.fileUpload.requirements": APPB2C_common_fileUpload_requirements6,
  "APPB2C.common.fileUpload.success": APPB2C_common_fileUpload_success6,
  "APPB2C.common.findTest.changeCity": APPB2C_common_findTest_changeCity6,
  "APPB2C.common.findTest.changeCountry": APPB2C_common_findTest_changeCountry6,
  "APPB2C.common.findTest.cta": APPB2C_common_findTest_cta6,
  "APPB2C.common.findTest.dates.clear": APPB2C_common_findTest_dates_clear6,
  "APPB2C.common.findTest.dates.from": APPB2C_common_findTest_dates_from6,
  "APPB2C.common.findTest.dates.noDatesSelected": APPB2C_common_findTest_dates_noDatesSelected6,
  "APPB2C.common.findTest.dates.noresults.details": APPB2C_common_findTest_dates_noresults_details6,
  "APPB2C.common.findTest.dates.noresults.title": APPB2C_common_findTest_dates_noresults_title6,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.cta": APPB2C_common_findTest_dates_noresultsMobileLocation_cta6,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.details": APPB2C_common_findTest_dates_noresultsMobileLocation_details6,
  "APPB2C.common.findTest.dates.noresultsMobileLocation.title": APPB2C_common_findTest_dates_noresultsMobileLocation_title6,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.details": APPB2C_common_findTest_dates_noresultsSpecialReqs_details6,
  "APPB2C.common.findTest.dates.noresultsSpecialReqs.title": APPB2C_common_findTest_dates_noresultsSpecialReqs_title6,
  "APPB2C.common.findTest.dates.pleaseSelect": APPB2C_common_findTest_dates_pleaseSelect6,
  "APPB2C.common.findTest.dates.selected": APPB2C_common_findTest_dates_selected6,
  "APPB2C.common.findTest.dates.showAll": APPB2C_common_findTest_dates_showAll6,
  "APPB2C.common.findTest.dates.showDateRange": APPB2C_common_findTest_dates_showDateRange6,
  "APPB2C.common.findTest.dates.title": APPB2C_common_findTest_dates_title6,
  "APPB2C.common.findTest.dates.to": APPB2C_common_findTest_dates_to6,
  "APPB2C.common.findTest.examFormat.all": APPB2C_common_findTest_examFormat_all6,
  "APPB2C.common.findTest.examFormat.cd": APPB2C_common_findTest_examFormat_cd6,
  "APPB2C.common.findTest.examFormat.pb": APPB2C_common_findTest_examFormat_pb6,
  "APPB2C.common.findTest.examFormat.results": APPB2C_common_findTest_examFormat_results6,
  "APPB2C.common.findTest.examFormat.title": APPB2C_common_findTest_examFormat_title6,
  "APPB2C.common.findTest.iol.noLrwResults": APPB2C_common_findTest_iol_noLrwResults6,
  "APPB2C.common.findTest.iol.organisationCheck.check": APPB2C_common_findTest_iol_organisationCheck_check6,
  "APPB2C.common.findTest.iol.organisationCheck.desc": APPB2C_common_findTest_iol_organisationCheck_desc6,
  "APPB2C.common.findTest.iol.organisationCheck.title": APPB2C_common_findTest_iol_organisationCheck_title6,
  "APPB2C.common.findTest.iol.softwareCheck.check": APPB2C_common_findTest_iol_softwareCheck_check6,
  "APPB2C.common.findTest.iol.softwareCheck.desc1": APPB2C_common_findTest_iol_softwareCheck_desc16,
  "APPB2C.common.findTest.iol.softwareCheck.desc2": APPB2C_common_findTest_iol_softwareCheck_desc26,
  "APPB2C.common.findTest.iol.softwareCheck.link": APPB2C_common_findTest_iol_softwareCheck_link6,
  "APPB2C.common.findTest.iol.softwareCheck.title": APPB2C_common_findTest_iol_softwareCheck_title6,
  "APPB2C.common.findTest.langModal.choose": APPB2C_common_findTest_langModal_choose6,
  "APPB2C.common.findTest.langModal.chooseSingle": APPB2C_common_findTest_langModal_chooseSingle6,
  "APPB2C.common.findTest.langModal.continue": APPB2C_common_findTest_langModal_continue6,
  "APPB2C.common.findTest.langModal.title": APPB2C_common_findTest_langModal_title6,
  "APPB2C.common.findTest.multipleLocations": APPB2C_common_findTest_multipleLocations6,
  "APPB2C.common.findTest.osrInfo": APPB2C_common_findTest_osrInfo6,
  "APPB2C.common.findTest.osrInfoLink": APPB2C_common_findTest_osrInfoLink6,
  "APPB2C.common.findTest.residencyConfirmation.btn": APPB2C_common_findTest_residencyConfirmation_btn6,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.btn": APPB2C_common_findTest_residencyConfirmation_errorMsg_btn6,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line1": APPB2C_common_findTest_residencyConfirmation_errorMsg_line16,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.line2": APPB2C_common_findTest_residencyConfirmation_errorMsg_line26,
  "APPB2C.common.findTest.residencyConfirmation.errorMsg.title": APPB2C_common_findTest_residencyConfirmation_errorMsg_title6,
  "APPB2C.common.findTest.residencyConfirmation.msg": APPB2C_common_findTest_residencyConfirmation_msg5,
  "APPB2C.common.findTest.residencyConfirmation.msg.line1": APPB2C_common_findTest_residencyConfirmation_msg_line16,
  "APPB2C.common.findTest.residencyConfirmation.msg.line2": APPB2C_common_findTest_residencyConfirmation_msg_line26,
  "APPB2C.common.findTest.residencyConfirmation.msg.line3": APPB2C_common_findTest_residencyConfirmation_msg_line36,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInCountryLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInCountryLine26,
  "APPB2C.common.findTest.residencyConfirmation.msg.noComputerTestsInLocationLine2": APPB2C_common_findTest_residencyConfirmation_msg_noComputerTestsInLocationLine26,
  "APPB2C.common.findTest.residencyConfirmation.selectDates": APPB2C_common_findTest_residencyConfirmation_selectDates6,
  "APPB2C.common.findTest.residencyConfirmation.title": APPB2C_common_findTest_residencyConfirmation_title6,
  "APPB2C.common.findTest.searchIn": APPB2C_common_findTest_searchIn6,
  "APPB2C.common.findTest.selectCountry.continue": APPB2C_common_findTest_selectCountry_continue6,
  "APPB2C.common.findTest.selectCountry.countryNotSupported": APPB2C_common_findTest_selectCountry_countryNotSupported6,
  "APPB2C.common.findTest.selectCountry.label": APPB2C_common_findTest_selectCountry_label6,
  "APPB2C.common.findTest.selectCountry.placeholder": APPB2C_common_findTest_selectCountry_placeholder6,
  "APPB2C.common.findTest.selectLocation.closestToMe": APPB2C_common_findTest_selectLocation_closestToMe6,
  "APPB2C.common.findTest.selectLocation.geolocationBlocked": APPB2C_common_findTest_selectLocation_geolocationBlocked6,
  "APPB2C.common.findTest.selectLocation.label": APPB2C_common_findTest_selectLocation_label6,
  "APPB2C.common.findTest.selectLocation.noExamForThisCountry": APPB2C_common_findTest_selectLocation_noExamForThisCountry6,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation6,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.cta": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_cta6,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option1": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option16,
  "APPB2C.common.findTest.selectLocation.noExamForThisOrganisation.option2": APPB2C_common_findTest_selectLocation_noExamForThisOrganisation_option26,
  "APPB2C.common.findTest.specialReqs.findOutMore": APPB2C_common_findTest_specialReqs_findOutMore6,
  "APPB2C.common.findTest.specialReqs.list.subtitle": APPB2C_common_findTest_specialReqs_list_subtitle6,
  "APPB2C.common.findTest.specialReqs.list.title": APPB2C_common_findTest_specialReqs_list_title6,
  "APPB2C.common.findTest.specialReqs.needCheckbox": APPB2C_common_findTest_specialReqs_needCheckbox6,
  "APPB2C.common.findTest.specialReqs.other": APPB2C_common_findTest_specialReqs_other6,
  "APPB2C.common.findTest.specialReqs.pleaseNoteMessage": APPB2C_common_findTest_specialReqs_pleaseNoteMessage6,
  "APPB2C.common.findTest.subtitle": APPB2C_common_findTest_subtitle6,
  "APPB2C.common.findTest.title": APPB2C_common_findTest_title6,
  "APPB2C.common.findTest.uol.organisationCheck.check": APPB2C_common_findTest_uol_organisationCheck_check6,
  "APPB2C.common.findTest.uol.organisationCheck.desc": APPB2C_common_findTest_uol_organisationCheck_desc6,
  "APPB2C.common.findTest.uol.organisationCheck.title": APPB2C_common_findTest_uol_organisationCheck_title6,
  "APPB2C.common.findTest.uol.softwareCheck.check": APPB2C_common_findTest_uol_softwareCheck_check6,
  "APPB2C.common.findTest.uol.softwareCheck.desc1": APPB2C_common_findTest_uol_softwareCheck_desc16,
  "APPB2C.common.findTest.uol.softwareCheck.desc2": APPB2C_common_findTest_uol_softwareCheck_desc26,
  "APPB2C.common.findTest.uol.softwareCheck.link": APPB2C_common_findTest_uol_softwareCheck_link6,
  "APPB2C.common.findTest.uol.softwareCheck.title": APPB2C_common_findTest_uol_softwareCheck_title6,
  "APPB2C.common.footer.terms.accessibility": APPB2C_common_footer_terms_accessibility6,
  "APPB2C.common.footer.terms.copyrightLine1": APPB2C_common_footer_terms_copyrightLine16,
  "APPB2C.common.footer.terms.copyrightLine2": APPB2C_common_footer_terms_copyrightLine26,
  "APPB2C.common.footer.terms.dataProtection": APPB2C_common_footer_terms_dataProtection6,
  "APPB2C.common.footer.terms.privacyAndCookies": APPB2C_common_footer_terms_privacyAndCookies6,
  "APPB2C.common.footer.terms.termsOfUse": APPB2C_common_footer_terms_termsOfUse6,
  "APPB2C.common.gelRedirect.errorMessage": APPB2C_common_gelRedirect_errorMessage6,
  "APPB2C.common.gelRedirect.goBack": APPB2C_common_gelRedirect_goBack5,
  "APPB2C.common.gelRedirect.header": APPB2C_common_gelRedirect_header6,
  "APPB2C.common.gelRedirect.info": APPB2C_common_gelRedirect_info6,
  "APPB2C.common.general.next": APPB2C_common_general_next6,
  "APPB2C.common.general.previous": APPB2C_common_general_previous6,
  "APPB2C.common.general.regNotFound": APPB2C_common_general_regNotFound6,
  "APPB2C.common.general.remove": APPB2C_common_general_remove6,
  "APPB2C.common.general.steps": APPB2C_common_general_steps6,
  "APPB2C.common.general.stepsContinued": APPB2C_common_general_stepsContinued6,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabel": APPB2C_common_helpAndContact_actionSection_contactFormLabel6,
  "APPB2C.common.helpAndContact.actionSection.contactFormLabelInCentre": APPB2C_common_helpAndContact_actionSection_contactFormLabelInCentre6,
  "APPB2C.common.helpAndContact.actionSection.contactFormLink": APPB2C_common_helpAndContact_actionSection_contactFormLink6,
  "APPB2C.common.helpAndContact.actionSection.faqLabel": APPB2C_common_helpAndContact_actionSection_faqLabel6,
  "APPB2C.common.helpAndContact.actionSection.faqLink": APPB2C_common_helpAndContact_actionSection_faqLink6,
  "APPB2C.common.helpAndContact.actionSection.icHeader": APPB2C_common_helpAndContact_actionSection_icHeader6,
  "APPB2C.common.helpAndContact.actionSection.iolHeader": APPB2C_common_helpAndContact_actionSection_iolHeader6,
  "APPB2C.common.helpAndContact.actionSection.launchChatBtn": APPB2C_common_helpAndContact_actionSection_launchChatBtn6,
  "APPB2C.common.helpAndContact.actionSection.launchChatLabel": APPB2C_common_helpAndContact_actionSection_launchChatLabel6,
  "APPB2C.common.helpAndContact.form.back": APPB2C_common_helpAndContact_form_back6,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLink": APPB2C_common_helpAndContact_form_complaint_moreInfoLink6,
  "APPB2C.common.helpAndContact.form.complaint.moreInfoLinkTitle": APPB2C_common_helpAndContact_form_complaint_moreInfoLinkTitle6,
  "APPB2C.common.helpAndContact.form.complaint.radio.complaint": APPB2C_common_helpAndContact_form_complaint_radio_complaint6,
  "APPB2C.common.helpAndContact.form.complaint.radio.other": APPB2C_common_helpAndContact_form_complaint_radio_other6,
  "APPB2C.common.helpAndContact.form.complaint.title": APPB2C_common_helpAndContact_form_complaint_title6,
  "APPB2C.common.helpAndContact.form.label": APPB2C_common_helpAndContact_form_label6,
  "APPB2C.common.helpAndContact.form.legal": APPB2C_common_helpAndContact_form_legal6,
  "APPB2C.common.helpAndContact.form.response": APPB2C_common_helpAndContact_form_response6,
  "APPB2C.common.helpAndContact.form.submit": APPB2C_common_helpAndContact_form_submit6,
  "APPB2C.common.helpAndContact.form.thanks.back": APPB2C_common_helpAndContact_form_thanks_back6,
  "APPB2C.common.helpAndContact.form.thanks.banner": APPB2C_common_helpAndContact_form_thanks_banner6,
  "APPB2C.common.helpAndContact.form.thanks.info": APPB2C_common_helpAndContact_form_thanks_info6,
  "APPB2C.common.helpAndContact.form.thanks.title": APPB2C_common_helpAndContact_form_thanks_title6,
  "APPB2C.common.helpAndContact.form.title": APPB2C_common_helpAndContact_form_title6,
  "APPB2C.common.helpAndContact.form.validation": APPB2C_common_helpAndContact_form_validation6,
  "APPB2C.common.helpAndContact.icContent.commonQuestionsHeader": APPB2C_common_helpAndContact_icContent_commonQuestionsHeader6,
  "APPB2C.common.helpAndContact.icContent.emailLabel": APPB2C_common_helpAndContact_icContent_emailLabel6,
  "APPB2C.common.helpAndContact.icContent.header": APPB2C_common_helpAndContact_icContent_header6,
  "APPB2C.common.helpAndContact.icContent.phoneLabel": APPB2C_common_helpAndContact_icContent_phoneLabel6,
  "APPB2C.common.helpAndContact.icContent.referenceReminder": APPB2C_common_helpAndContact_icContent_referenceReminder6,
  "APPB2C.common.helpAndContact.ieltsReference": APPB2C_common_helpAndContact_ieltsReference6,
  "APPB2C.common.helpAndContact.noTests.bookNewBtn": APPB2C_common_helpAndContact_noTests_bookNewBtn6,
  "APPB2C.common.helpAndContact.noTests.contactLink": APPB2C_common_helpAndContact_noTests_contactLink6,
  "APPB2C.common.helpAndContact.noTests.header": APPB2C_common_helpAndContact_noTests_header6,
  "APPB2C.common.helpAndContact.selectExamLabel": APPB2C_common_helpAndContact_selectExamLabel6,
  "APPB2C.common.helpAndContact.title": APPB2C_common_helpAndContact_title6,
  "APPB2C.common.invitation.modal.header": APPB2C_common_invitation_modal_header6,
  "APPB2C.common.invitation.modal.invalid": APPB2C_common_invitation_modal_invalid6,
  "APPB2C.common.modals.newTermsAndConditions.checkboxNote": APPB2C_common_modals_newTermsAndConditions_checkboxNote6,
  "APPB2C.common.modals.newTermsAndConditions.checkboxTC": APPB2C_common_modals_newTermsAndConditions_checkboxTC6,
  "APPB2C.common.modals.newTermsAndConditions.cta": APPB2C_common_modals_newTermsAndConditions_cta6,
  "APPB2C.common.modals.newTermsAndConditions.error": APPB2C_common_modals_newTermsAndConditions_error6,
  "APPB2C.common.modals.newTermsAndConditions.note": APPB2C_common_modals_newTermsAndConditions_note6,
  "APPB2C.common.modals.newTermsAndConditions.title": APPB2C_common_modals_newTermsAndConditions_title6,
  "APPB2C.common.modals.newTermsAndConditions.trigger": APPB2C_common_modals_newTermsAndConditions_trigger6,
  "APPB2C.common.nav.bookNewTest": APPB2C_common_nav_bookNewTest6,
  "APPB2C.common.nav.chooseLanguage": APPB2C_common_nav_chooseLanguage6,
  "APPB2C.common.nav.hello": APPB2C_common_nav_hello6,
  "APPB2C.common.nav.helpAndContact": APPB2C_common_nav_helpAndContact6,
  "APPB2C.common.nav.login": APPB2C_common_nav_login6,
  "APPB2C.common.nav.logout": APPB2C_common_nav_logout6,
  "APPB2C.common.nav.logoutModal.cancel": APPB2C_common_nav_logoutModal_cancel5,
  "APPB2C.common.nav.logoutModal.confirm": APPB2C_common_nav_logoutModal_confirm6,
  "APPB2C.common.nav.logoutModal.message": APPB2C_common_nav_logoutModal_message6,
  "APPB2C.common.nav.logoutModal.question": APPB2C_common_nav_logoutModal_question6,
  "APPB2C.common.nav.logoutModal.title": APPB2C_common_nav_logoutModal_title6,
  "APPB2C.common.nav.myAccount": APPB2C_common_nav_myAccount6,
  "APPB2C.common.nav.preparation": APPB2C_common_nav_preparation6,
  "APPB2C.common.nav.resultsTests": APPB2C_common_nav_resultsTests6,
  "APPB2C.common.nav.ttp": APPB2C_common_nav_ttp6,
  "APPB2C.common.preparation.noTests.contactLink": APPB2C_common_preparation_noTests_contactLink6,
  "APPB2C.common.preparation.noTests.header": APPB2C_common_preparation_noTests_header6,
  "APPB2C.common.preparation.selectExamLabel": APPB2C_common_preparation_selectExamLabel6,
  "APPB2C.common.preparation.title": APPB2C_common_preparation_title6,
  "APPB2C.common.preparation.viewTestBtn": APPB2C_common_preparation_viewTestBtn6,
  "APPB2C.common.recreateReg.errMsg": APPB2C_common_recreateReg_errMsg6,
  "APPB2C.common.recreateReg.errTitle": APPB2C_common_recreateReg_errTitle6,
  "APPB2C.common.recreateReg.progress": APPB2C_common_recreateReg_progress6,
  "APPB2C.common.regHeader.reference": APPB2C_common_regHeader_reference6,
  "APPB2C.common.regHeader.trfLoading": APPB2C_common_regHeader_trfLoading6,
  "APPB2C.common.regHeader.trfNumber": APPB2C_common_regHeader_trfNumber6,
  "APPB2C.common.regInProgressModal.continue": APPB2C_common_regInProgressModal_continue6,
  "APPB2C.common.regInProgressModal.message1": APPB2C_common_regInProgressModal_message16,
  "APPB2C.common.regInProgressModal.message2": APPB2C_common_regInProgressModal_message26,
  "APPB2C.common.regInProgressModal.restart": APPB2C_common_regInProgressModal_restart6,
  "APPB2C.common.regInProgressModal.title": APPB2C_common_regInProgressModal_title6,
  "APPB2C.common.selfServices.requested.cancellation": APPB2C_common_selfServices_requested_cancellation6,
  "APPB2C.common.selfServices.requested.transfer": APPB2C_common_selfServices_requested_transfer6,
  "APPB2C.common.specialReqs.AMANU": APPB2C_common_specialReqs_AMANU6,
  "APPB2C.common.specialReqs.ANTHR": APPB2C_common_specialReqs_ANTHR6,
  "APPB2C.common.specialReqs.BTP": APPB2C_common_specialReqs_BTP6,
  "APPB2C.common.specialReqs.BWP": APPB2C_common_specialReqs_BWP6,
  "APPB2C.common.specialReqs.EPTP": APPB2C_common_specialReqs_EPTP6,
  "APPB2C.common.specialReqs.ET": APPB2C_common_specialReqs_ET6,
  "APPB2C.common.specialReqs.LRTV": APPB2C_common_specialReqs_LRTV6,
  "APPB2C.common.specialReqs.SAE": APPB2C_common_specialReqs_SAE6,
  "APPB2C.common.specialReqs.VAS": APPB2C_common_specialReqs_VAS6,
  "APPB2C.common.specialReqs.WP": APPB2C_common_specialReqs_WP6,
  "APPB2C.common.startPage.book": APPB2C_common_startPage_book6,
  "APPB2C.common.startPage.candidates.nowAdult": APPB2C_common_startPage_candidates_nowAdult6,
  "APPB2C.common.startPage.candidates.showingFor": APPB2C_common_startPage_candidates_showingFor6,
  "APPB2C.common.startPage.common.cancelled": APPB2C_common_startPage_common_cancelled6,
  "APPB2C.common.startPage.description.ac": APPB2C_common_startPage_description_ac6,
  "APPB2C.common.startPage.description.gt": APPB2C_common_startPage_description_gt6,
  "APPB2C.common.startPage.description.ukvi": APPB2C_common_startPage_description_ukvi6,
  "APPB2C.common.startPage.endNote": APPB2C_common_startPage_endNote6,
  "APPB2C.common.startPage.error.body": APPB2C_common_startPage_error_body6,
  "APPB2C.common.startPage.error.cta": APPB2C_common_startPage_error_cta6,
  "APPB2C.common.startPage.error.title": APPB2C_common_startPage_error_title6,
  "APPB2C.common.startPage.hero.cta": APPB2C_common_startPage_hero_cta6,
  "APPB2C.common.startPage.hero.subtitle": APPB2C_common_startPage_hero_subtitle6,
  "APPB2C.common.startPage.hero.title": APPB2C_common_startPage_hero_title6,
  "APPB2C.common.startPage.ielts": APPB2C_common_startPage_ielts6,
  "APPB2C.common.startPage.ieltsUkvi": APPB2C_common_startPage_ieltsUkvi6,
  "APPB2C.common.startPage.missing.cancel": APPB2C_common_startPage_missing_cancel6,
  "APPB2C.common.startPage.missing.marketing.cta": APPB2C_common_startPage_missing_marketing_cta6,
  "APPB2C.common.startPage.missing.marketing.notes.full1": APPB2C_common_startPage_missing_marketing_notes_full16,
  "APPB2C.common.startPage.missing.marketing.notes.full2": APPB2C_common_startPage_missing_marketing_notes_full26,
  "APPB2C.common.startPage.missing.marketing.notes.full3": APPB2C_common_startPage_missing_marketing_notes_full36,
  "APPB2C.common.startPage.missing.marketing.notes.link": APPB2C_common_startPage_missing_marketing_notes_link6,
  "APPB2C.common.startPage.missing.marketing.notes.main": APPB2C_common_startPage_missing_marketing_notes_main6,
  "APPB2C.common.startPage.missing.marketing.opts.BcAndThirdParties": APPB2C_common_startPage_missing_marketing_opts_BcAndThirdParties6,
  "APPB2C.common.startPage.missing.marketing.opts.BcOnly": APPB2C_common_startPage_missing_marketing_opts_BcOnly6,
  "APPB2C.common.startPage.missing.marketing.opts.DoNotSend": APPB2C_common_startPage_missing_marketing_opts_DoNotSend6,
  "APPB2C.common.startPage.missing.marketing.title": APPB2C_common_startPage_missing_marketing_title6,
  "APPB2C.common.startPage.missing.ttData.cta": APPB2C_common_startPage_missing_ttData_cta6,
  "APPB2C.common.startPage.missing.ttData.dob": APPB2C_common_startPage_missing_ttData_dob6,
  "APPB2C.common.startPage.missing.ttData.firstName": APPB2C_common_startPage_missing_ttData_firstName6,
  "APPB2C.common.startPage.missing.ttData.lastName": APPB2C_common_startPage_missing_ttData_lastName6,
  "APPB2C.common.startPage.missing.ttData.line1": APPB2C_common_startPage_missing_ttData_line16,
  "APPB2C.common.startPage.missing.ttData.line2": APPB2C_common_startPage_missing_ttData_line26,
  "APPB2C.common.startPage.missing.ttData.line3": APPB2C_common_startPage_missing_ttData_line36,
  "APPB2C.common.startPage.missing.ttData.noLastName": APPB2C_common_startPage_missing_ttData_noLastName6,
  "APPB2C.common.startPage.missing.ttData.title": APPB2C_common_startPage_missing_ttData_title6,
  "APPB2C.common.startPage.options.ac": APPB2C_common_startPage_options_ac6,
  "APPB2C.common.startPage.options.gt": APPB2C_common_startPage_options_gt6,
  "APPB2C.common.startPage.options.ukvi": APPB2C_common_startPage_options_ukvi6,
  "APPB2C.common.startPage.past.cd": APPB2C_common_startPage_past_cd6,
  "APPB2C.common.startPage.past.empty": APPB2C_common_startPage_past_empty6,
  "APPB2C.common.startPage.past.osr.newScore": APPB2C_common_startPage_past_osr_newScore6,
  "APPB2C.common.startPage.past.osr.note.awaiting": APPB2C_common_startPage_past_osr_note_awaiting6,
  "APPB2C.common.startPage.past.osr.note.confirmed": APPB2C_common_startPage_past_osr_note_confirmed6,
  "APPB2C.common.startPage.past.osr.note.hasResults": APPB2C_common_startPage_past_osr_note_hasResults6,
  "APPB2C.common.startPage.past.osr.originalScore": APPB2C_common_startPage_past_osr_originalScore6,
  "APPB2C.common.startPage.past.osr.viewRetakeLink": APPB2C_common_startPage_past_osr_viewRetakeLink6,
  "APPB2C.common.startPage.past.pb": APPB2C_common_startPage_past_pb6,
  "APPB2C.common.startPage.past.rebook": APPB2C_common_startPage_past_rebook6,
  "APPB2C.common.startPage.past.score": APPB2C_common_startPage_past_score6,
  "APPB2C.common.startPage.past.scoreLifeSkills": APPB2C_common_startPage_past_scoreLifeSkills6,
  "APPB2C.common.startPage.past.title": APPB2C_common_startPage_past_title6,
  "APPB2C.common.startPage.past.upcomingResults": APPB2C_common_startPage_past_upcomingResults6,
  "APPB2C.common.startPage.readTnCs": APPB2C_common_startPage_readTnCs6,
  "APPB2C.common.startPage.recent.cta": APPB2C_common_startPage_recent_cta6,
  "APPB2C.common.startPage.recent.title": APPB2C_common_startPage_recent_title6,
  "APPB2C.common.startPage.subtitle": APPB2C_common_startPage_subtitle6,
  "APPB2C.common.startPage.title": APPB2C_common_startPage_title6,
  "APPB2C.common.startPage.tncs": APPB2C_common_startPage_tncs6,
  "APPB2C.common.startPage.upcoming.cd": APPB2C_common_startPage_upcoming_cd6,
  "APPB2C.common.startPage.upcoming.empty": APPB2C_common_startPage_upcoming_empty6,
  "APPB2C.common.startPage.upcoming.lifeSkills": APPB2C_common_startPage_upcoming_lifeSkills6,
  "APPB2C.common.startPage.upcoming.live": APPB2C_common_startPage_upcoming_live6,
  "APPB2C.common.startPage.upcoming.manage": APPB2C_common_startPage_upcoming_manage6,
  "APPB2C.common.startPage.upcoming.missing.childConsent": APPB2C_common_startPage_upcoming_missing_childConsent6,
  "APPB2C.common.startPage.upcoming.missing.id": APPB2C_common_startPage_upcoming_missing_id6,
  "APPB2C.common.startPage.upcoming.missing.medical": APPB2C_common_startPage_upcoming_missing_medical6,
  "APPB2C.common.startPage.upcoming.missing.payment": APPB2C_common_startPage_upcoming_missing_payment6,
  "APPB2C.common.startPage.upcoming.noSpeaking": APPB2C_common_startPage_upcoming_noSpeaking6,
  "APPB2C.common.startPage.upcoming.noTime": APPB2C_common_startPage_upcoming_noTime6,
  "APPB2C.common.startPage.upcoming.osr.note": APPB2C_common_startPage_upcoming_osr_note6,
  "APPB2C.common.startPage.upcoming.pastPaymentDeadline": APPB2C_common_startPage_upcoming_pastPaymentDeadline6,
  "APPB2C.common.startPage.upcoming.pay": APPB2C_common_startPage_upcoming_pay6,
  "APPB2C.common.startPage.upcoming.pb": APPB2C_common_startPage_upcoming_pb6,
  "APPB2C.common.startPage.upcoming.prepare": APPB2C_common_startPage_upcoming_prepare6,
  "APPB2C.common.startPage.upcoming.remote": APPB2C_common_startPage_upcoming_remote6,
  "APPB2C.common.startPage.upcoming.title": APPB2C_common_startPage_upcoming_title6,
  "APPB2C.common.startPage.whichIELTS": APPB2C_common_startPage_whichIELTS6,
  "APPB2C.common.terms.downloadInfo": APPB2C_common_terms_downloadInfo6,
  "APPB2C.common.terms.downloadTitle": APPB2C_common_terms_downloadTitle6,
  "APPB2C.common.terms.error": APPB2C_common_terms_error6,
  "APPB2C.common.terms.title": APPB2C_common_terms_title6,
  "APPB2C.common.testDetails.changeTest": APPB2C_common_testDetails_changeTest6,
  "APPB2C.common.testDetails.changeWrittenTest": APPB2C_common_testDetails_changeWrittenTest6,
  "APPB2C.common.testDetails.completeYourBooking": APPB2C_common_testDetails_completeYourBooking6,
  "APPB2C.common.testDetails.mapModal.close": APPB2C_common_testDetails_mapModal_close6,
  "APPB2C.common.testDetails.mapModal.title": APPB2C_common_testDetails_mapModal_title6,
  "APPB2C.common.testDetails.modifyNote.confirm": APPB2C_common_testDetails_modifyNote_confirm6,
  "APPB2C.common.testDetails.modifyNote.note": APPB2C_common_testDetails_modifyNote_note6,
  "APPB2C.common.testDetails.modifyNote.pleaseNote": APPB2C_common_testDetails_modifyNote_pleaseNote6,
  "APPB2C.common.testDetails.title": APPB2C_common_testDetails_title6,
  "APPB2C.common.testDetails.yourSpeakingTest": APPB2C_common_testDetails_yourSpeakingTest6,
  "APPB2C.common.testDetails.yourWrittenTest": APPB2C_common_testDetails_yourWrittenTest6,
  "APPB2C.common.transfer.agreement": APPB2C_common_transfer_agreement6,
  "APPB2C.common.transfer.contactNote": APPB2C_common_transfer_contactNote6,
  "APPB2C.common.transfer.contactNoteIol": APPB2C_common_transfer_contactNoteIol6,
  "APPB2C.common.transfer.cta": APPB2C_common_transfer_cta6,
  "APPB2C.common.transfer.form.freeform": APPB2C_common_transfer_form_freeform6,
  "APPB2C.common.transfer.form.reasons.1": APPB2C_common_transfer_form_reasons_16,
  "APPB2C.common.transfer.form.reasons.2": APPB2C_common_transfer_form_reasons_26,
  "APPB2C.common.transfer.form.reasons.3": APPB2C_common_transfer_form_reasons_36,
  "APPB2C.common.transfer.form.reasons.4": APPB2C_common_transfer_form_reasons_46,
  "APPB2C.common.transfer.form.reasons.choose": APPB2C_common_transfer_form_reasons_choose6,
  "APPB2C.common.transfer.form.reasons.other": APPB2C_common_transfer_form_reasons_other6,
  "APPB2C.common.transfer.form.reasons.title": APPB2C_common_transfer_form_reasons_title6,
  "APPB2C.common.transfer.goBack": APPB2C_common_transfer_goBack6,
  "APPB2C.common.transfer.notes.1": APPB2C_common_transfer_notes_16,
  "APPB2C.common.transfer.notes.2": APPB2C_common_transfer_notes_26,
  "APPB2C.common.transfer.notes.3": APPB2C_common_transfer_notes_36,
  "APPB2C.common.transfer.notes.4": APPB2C_common_transfer_notes_46,
  "APPB2C.common.transfer.notesIol.1": APPB2C_common_transfer_notesIol_16,
  "APPB2C.common.transfer.notesIol.2": APPB2C_common_transfer_notesIol_26,
  "APPB2C.common.transfer.requestValidation": APPB2C_common_transfer_requestValidation6,
  "APPB2C.common.transfer.selfService.availableDates.title": APPB2C_common_transfer_selfService_availableDates_title6,
  "APPB2C.common.transfer.selfService.chooseDates.title": APPB2C_common_transfer_selfService_chooseDates_title6,
  "APPB2C.common.transfer.selfService.review.confirm.proceedBtn": APPB2C_common_transfer_selfService_review_confirm_proceedBtn6,
  "APPB2C.common.transfer.selfService.review.confirm.title": APPB2C_common_transfer_selfService_review_confirm_title6,
  "APPB2C.common.transfer.selfService.review.errors.noDistricts": APPB2C_common_transfer_selfService_review_errors_noDistricts6,
  "APPB2C.common.transfer.selfService.review.errors.noLrwSlots": APPB2C_common_transfer_selfService_review_errors_noLrwSlots6,
  "APPB2C.common.transfer.selfService.review.errors.transferDisabled": APPB2C_common_transfer_selfService_review_errors_transferDisabled6,
  "APPB2C.common.transfer.selfService.review.test.changeLrw": APPB2C_common_transfer_selfService_review_test_changeLrw6,
  "APPB2C.common.transfer.selfService.review.tncs.agreement": APPB2C_common_transfer_selfService_review_tncs_agreement6,
  "APPB2C.common.transfer.title": APPB2C_common_transfer_title6,
  "APPB2C.common.underConstruction.message": APPB2C_common_underConstruction_message6,
  "APPB2C.common.underConstruction.title": APPB2C_common_underConstruction_title6,
  "APPB2C.common.unknownCountry.cta": APPB2C_common_unknownCountry_cta6,
  "APPB2C.common.unknownCountry.header": APPB2C_common_unknownCountry_header6,
  "APPB2C.common.unknownCountry.msg1": APPB2C_common_unknownCountry_msg16,
  "APPB2C.common.unknownCountry.msg2": APPB2C_common_unknownCountry_msg26,
  "APPB2C.common.unknownCountry.title": APPB2C_common_unknownCountry_title6,
  "APPB2C.common.unpaidErrorBar.paymentExpired": APPB2C_common_unpaidErrorBar_paymentExpired6,
  "APPB2C.common.updateBar.cancelled": APPB2C_common_updateBar_cancelled6,
  "APPB2C.common.updateBar.cancelPending": APPB2C_common_updateBar_cancelPending6,
  "APPB2C.common.updateBar.cancelRejected": APPB2C_common_updateBar_cancelRejected6,
  "APPB2C.common.updateBar.close": APPB2C_common_updateBar_close6,
  "APPB2C.common.updateBar.details": APPB2C_common_updateBar_details6,
  "APPB2C.common.updateBar.eorCompleted": APPB2C_common_updateBar_eorCompleted6,
  "APPB2C.common.updateBar.eorCompleted.scoreDecreased": APPB2C_common_updateBar_eorCompleted_scoreDecreased6,
  "APPB2C.common.updateBar.eorCompleted.scoreIncreased": APPB2C_common_updateBar_eorCompleted_scoreIncreased6,
  "APPB2C.common.updateBar.eorCompleted.scoreUnchanged": APPB2C_common_updateBar_eorCompleted_scoreUnchanged6,
  "APPB2C.common.updateBar.eorPending": APPB2C_common_updateBar_eorPending6,
  "APPB2C.common.updateBar.eorPendingAgentPays": APPB2C_common_updateBar_eorPendingAgentPays6,
  "APPB2C.common.updateBar.eorRefunded": APPB2C_common_updateBar_eorRefunded6,
  "APPB2C.common.updateBar.eorRefundPending": APPB2C_common_updateBar_eorRefundPending6,
  "APPB2C.common.updateBar.eorRejected": APPB2C_common_updateBar_eorRejected6,
  "APPB2C.common.updateBar.eorRejected.details": APPB2C_common_updateBar_eorRejected_details5,
  "APPB2C.common.updateBar.eorRejected.overallBand": APPB2C_common_updateBar_eorRejected_overallBand6,
  "APPB2C.common.updateBar.hasResults": APPB2C_common_updateBar_hasResults6,
  "APPB2C.common.updateBar.termsUpdated": APPB2C_common_updateBar_termsUpdated6,
  "APPB2C.common.updateBar.transactionRefundRequestAccepted": APPB2C_common_updateBar_transactionRefundRequestAccepted6,
  "APPB2C.common.updateBar.transactionRefundRequestPending": APPB2C_common_updateBar_transactionRefundRequestPending6,
  "APPB2C.common.updateBar.transactionRefundRequestRejected": APPB2C_common_updateBar_transactionRefundRequestRejected6,
  "APPB2C.common.updateBar.transferCancelled": APPB2C_common_updateBar_transferCancelled6,
  "APPB2C.common.updateBar.transferPending": APPB2C_common_updateBar_transferPending6,
  "APPB2C.common.updateBar.transferPendingCmds": APPB2C_common_updateBar_transferPendingCmds6,
  "APPB2C.common.updateBar.transferred": APPB2C_common_updateBar_transferred6,
  "APPB2C.common.updateBar.transferredCmds": APPB2C_common_updateBar_transferredCmds6,
  "APPB2C.registration.bookingDetails.payment": APPB2C_registration_bookingDetails_payment6,
  "APPB2C.registration.bookingDetails.results": APPB2C_registration_bookingDetails_results6,
  "APPB2C.registration.bookingDetails.seePayment": APPB2C_registration_bookingDetails_seePayment6,
  "APPB2C.registration.bookingDetails.seeTestTaker": APPB2C_registration_bookingDetails_seeTestTaker6,
  "APPB2C.registration.bookingDetails.whatNext": APPB2C_registration_bookingDetails_whatNext6,
  "APPB2C.registration.date.check.back": APPB2C_registration_date_check_back6,
  "APPB2C.registration.date.check.beforeTestDate": APPB2C_registration_date_check_beforeTestDate6,
  "APPB2C.registration.date.check.intro": APPB2C_registration_date_check_intro6,
  "APPB2C.registration.date.check.onTestDate": APPB2C_registration_date_check_onTestDate6,
  "APPB2C.registration.date.check.step1.body": APPB2C_registration_date_check_step1_body6,
  "APPB2C.registration.date.check.step1.link": APPB2C_registration_date_check_step1_link6,
  "APPB2C.registration.date.check.step1.title": APPB2C_registration_date_check_step1_title6,
  "APPB2C.registration.date.check.step2.body": APPB2C_registration_date_check_step2_body6,
  "APPB2C.registration.date.check.step2.link": APPB2C_registration_date_check_step2_link6,
  "APPB2C.registration.date.check.step2.title": APPB2C_registration_date_check_step2_title6,
  "APPB2C.registration.date.check.step3.body1": APPB2C_registration_date_check_step3_body16,
  "APPB2C.registration.date.check.step3.body2": APPB2C_registration_date_check_step3_body26,
  "APPB2C.registration.date.check.step3.noDetailsBody": APPB2C_registration_date_check_step3_noDetailsBody6,
  "APPB2C.registration.date.check.step3.noDetailsTitle": APPB2C_registration_date_check_step3_noDetailsTitle6,
  "APPB2C.registration.date.check.step3.noSpeakingPinBody": APPB2C_registration_date_check_step3_noSpeakingPinBody6,
  "APPB2C.registration.date.check.step3.noSpeakingPinTitle": APPB2C_registration_date_check_step3_noSpeakingPinTitle6,
  "APPB2C.registration.date.check.step3.password": APPB2C_registration_date_check_step3_password6,
  "APPB2C.registration.date.check.step3.speakingPin": APPB2C_registration_date_check_step3_speakingPin6,
  "APPB2C.registration.date.check.step3.step1.body": APPB2C_registration_date_check_step3_step1_body6,
  "APPB2C.registration.date.check.step3.step1.title": APPB2C_registration_date_check_step3_step1_title6,
  "APPB2C.registration.date.check.step3.step2.body": APPB2C_registration_date_check_step3_step2_body6,
  "APPB2C.registration.date.check.step3.step2.title": APPB2C_registration_date_check_step3_step2_title6,
  "APPB2C.registration.date.check.step3.step3.body": APPB2C_registration_date_check_step3_step3_body6,
  "APPB2C.registration.date.check.step3.step3.title": APPB2C_registration_date_check_step3_step3_title6,
  "APPB2C.registration.date.check.step3.username": APPB2C_registration_date_check_step3_username6,
  "APPB2C.registration.date.check.step3.writtenPin": APPB2C_registration_date_check_step3_writtenPin6,
  "APPB2C.registration.date.zone.country": APPB2C_registration_date_zone_country6,
  "APPB2C.registration.date.zone.header": APPB2C_registration_date_zone_header6,
  "APPB2C.registration.date.zone.name": APPB2C_registration_date_zone_name6,
  "APPB2C.registration.date.zone.time": APPB2C_registration_date_zone_time6,
  "APPB2C.registration.dateLocation.changeLinkIol": APPB2C_registration_dateLocation_changeLinkIol6,
  "APPB2C.registration.dateLocation.changeLrwLink": APPB2C_registration_dateLocation_changeLrwLink6,
  "APPB2C.registration.dateLocation.changeSpeakingLink": APPB2C_registration_dateLocation_changeSpeakingLink6,
  "APPB2C.registration.dateLocation.location": APPB2C_registration_dateLocation_location6,
  "APPB2C.registration.dateLocation.manage.cancel": APPB2C_registration_dateLocation_manage_cancel6,
  "APPB2C.registration.dateLocation.manage.proceed": APPB2C_registration_dateLocation_manage_proceed5,
  "APPB2C.registration.dateLocation.manage.title": APPB2C_registration_dateLocation_manage_title6,
  "APPB2C.registration.dateLocation.manage.transferSpeaking": APPB2C_registration_dateLocation_manage_transferSpeaking6,
  "APPB2C.registration.dateLocation.manage.transferWhole": APPB2C_registration_dateLocation_manage_transferWhole6,
  "APPB2C.registration.dateLocation.speakingTransfer.keepSpeakingTest": APPB2C_registration_dateLocation_speakingTransfer_keepSpeakingTest6,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody1": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody16,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsBody2": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsBody26,
  "APPB2C.registration.dateLocation.speakingTransfer.noSpeakingSlotsHeader": APPB2C_registration_dateLocation_speakingTransfer_noSpeakingSlotsHeader6,
  "APPB2C.registration.dateLocation.speakingTransfer.requestTransfer": APPB2C_registration_dateLocation_speakingTransfer_requestTransfer6,
  "APPB2C.registration.incorrectOrganisation.homeLink": APPB2C_registration_incorrectOrganisation_homeLink6,
  "APPB2C.registration.incorrectOrganisation.line1": APPB2C_registration_incorrectOrganisation_line16,
  "APPB2C.registration.incorrectOrganisation.line2": APPB2C_registration_incorrectOrganisation_line26,
  "APPB2C.registration.payments.actions.editProofCTA": APPB2C_registration_payments_actions_editProofCTA6,
  "APPB2C.registration.payments.actions.header": APPB2C_registration_payments_actions_header6,
  "APPB2C.registration.payments.actions.paymentInProgressInfo": APPB2C_registration_payments_actions_paymentInProgressInfo6,
  "APPB2C.registration.payments.actions.payOfflineCTA": APPB2C_registration_payments_actions_payOfflineCTA6,
  "APPB2C.registration.payments.actions.payOnlineCTA": APPB2C_registration_payments_actions_payOnlineCTA6,
  "APPB2C.registration.payments.actions.subHeader": APPB2C_registration_payments_actions_subHeader6,
  "APPB2C.registration.payments.actions.uploadProofCTA": APPB2C_registration_payments_actions_uploadProofCTA6,
  "APPB2C.registration.payments.awaitingPayment": APPB2C_registration_payments_awaitingPayment6,
  "APPB2C.registration.payments.back": APPB2C_registration_payments_back6,
  "APPB2C.registration.payments.completed": APPB2C_registration_payments_completed6,
  "APPB2C.registration.payments.expired": APPB2C_registration_payments_expired6,
  "APPB2C.registration.payments.finishPayment.error.cta": APPB2C_registration_payments_finishPayment_error_cta6,
  "APPB2C.registration.payments.finishPayment.error.messageLine1": APPB2C_registration_payments_finishPayment_error_messageLine16,
  "APPB2C.registration.payments.finishPayment.error.messageLine2": APPB2C_registration_payments_finishPayment_error_messageLine26,
  "APPB2C.registration.payments.finishPayment.error.title": APPB2C_registration_payments_finishPayment_error_title6,
  "APPB2C.registration.payments.finishPayment.failure.alert": APPB2C_registration_payments_finishPayment_failure_alert6,
  "APPB2C.registration.payments.finishPayment.failure.cta": APPB2C_registration_payments_finishPayment_failure_cta6,
  "APPB2C.registration.payments.finishPayment.failure.message": APPB2C_registration_payments_finishPayment_failure_message6,
  "APPB2C.registration.payments.finishPayment.failure.title": APPB2C_registration_payments_finishPayment_failure_title6,
  "APPB2C.registration.payments.finishPayment.pleaseWait": APPB2C_registration_payments_finishPayment_pleaseWait6,
  "APPB2C.registration.payments.finishPayment.processing": APPB2C_registration_payments_finishPayment_processing6,
  "APPB2C.registration.payments.finishPayment.success.alert": APPB2C_registration_payments_finishPayment_success_alert6,
  "APPB2C.registration.payments.finishPayment.success.cta": APPB2C_registration_payments_finishPayment_success_cta6,
  "APPB2C.registration.payments.finishPayment.success.message": APPB2C_registration_payments_finishPayment_success_message6,
  "APPB2C.registration.payments.finishPayment.success.title": APPB2C_registration_payments_finishPayment_success_title6,
  "APPB2C.registration.payments.offlineDetails.accountName": APPB2C_registration_payments_offlineDetails_accountName6,
  "APPB2C.registration.payments.offlineDetails.accountNumber": APPB2C_registration_payments_offlineDetails_accountNumber6,
  "APPB2C.registration.payments.offlineDetails.payBy": APPB2C_registration_payments_offlineDetails_payBy6,
  "APPB2C.registration.payments.offlineDetails.sortCode": APPB2C_registration_payments_offlineDetails_sortCode6,
  "APPB2C.registration.payments.paidByAgent": APPB2C_registration_payments_paidByAgent6,
  "APPB2C.registration.payments.proofPreview.fileAlt": APPB2C_registration_payments_proofPreview_fileAlt6,
  "APPB2C.registration.payments.proofPreview.proofLabel": APPB2C_registration_payments_proofPreview_proofLabel6,
  "APPB2C.registration.payments.proofUpload.cta": APPB2C_registration_payments_proofUpload_cta6,
  "APPB2C.registration.payments.proofUpload.info": APPB2C_registration_payments_proofUpload_info6,
  "APPB2C.registration.payments.proofUpload.intro": APPB2C_registration_payments_proofUpload_intro6,
  "APPB2C.registration.payments.proofUpload.rules.intro": APPB2C_registration_payments_proofUpload_rules_intro6,
  "APPB2C.registration.payments.proofUpload.rules.rule1": APPB2C_registration_payments_proofUpload_rules_rule16,
  "APPB2C.registration.payments.proofUpload.rules.rule2": APPB2C_registration_payments_proofUpload_rules_rule26,
  "APPB2C.registration.payments.proofUpload.rules.rule3": APPB2C_registration_payments_proofUpload_rules_rule36,
  "APPB2C.registration.payments.proofUpload.rules.rule4": APPB2C_registration_payments_proofUpload_rules_rule46,
  "APPB2C.registration.payments.proofUpload.rules.rule5": APPB2C_registration_payments_proofUpload_rules_rule56,
  "APPB2C.registration.payments.transaction.cancelledSuffix": APPB2C_registration_payments_transaction_cancelledSuffix6,
  "APPB2C.registration.payments.transaction.deadlineWarning.default": APPB2C_registration_payments_transaction_deadlineWarning_default6,
  "APPB2C.registration.payments.transaction.deadlineWarning.eor": APPB2C_registration_payments_transaction_deadlineWarning_eor6,
  "APPB2C.registration.payments.transaction.deadlineWarning.registration": APPB2C_registration_payments_transaction_deadlineWarning_registration6,
  "APPB2C.registration.payments.transaction.description.appeal": APPB2C_registration_payments_transaction_description_appeal6,
  "APPB2C.registration.payments.transaction.description.eor": APPB2C_registration_payments_transaction_description_eor6,
  "APPB2C.registration.payments.transaction.description.registration": APPB2C_registration_payments_transaction_description_registration6,
  "APPB2C.registration.payments.transaction.description.transfer": APPB2C_registration_payments_transaction_description_transfer6,
  "APPB2C.registration.payments.transaction.feeLabel": APPB2C_registration_payments_transaction_feeLabel6,
  "APPB2C.registration.payments.transaction.header": APPB2C_registration_payments_transaction_header6,
  "APPB2C.registration.payments.transaction.hideDetails": APPB2C_registration_payments_transaction_hideDetails6,
  "APPB2C.registration.payments.transaction.outdatedTermsLabel": APPB2C_registration_payments_transaction_outdatedTermsLabel6,
  "APPB2C.registration.payments.transaction.pastDeadline": APPB2C_registration_payments_transaction_pastDeadline6,
  "APPB2C.registration.payments.transaction.pastDeadlineContactUs": APPB2C_registration_payments_transaction_pastDeadlineContactUs6,
  "APPB2C.registration.payments.transaction.pastDeadlineEmail": APPB2C_registration_payments_transaction_pastDeadlineEmail6,
  "APPB2C.registration.payments.transaction.pastDeadlinePhone": APPB2C_registration_payments_transaction_pastDeadlinePhone6,
  "APPB2C.registration.payments.transaction.promoCode": APPB2C_registration_payments_transaction_promoCode6,
  "APPB2C.registration.payments.transaction.receipt": APPB2C_registration_payments_transaction_receipt6,
  "APPB2C.registration.payments.transaction.receiptCta": APPB2C_registration_payments_transaction_receiptCta6,
  "APPB2C.registration.payments.transaction.referenceLabel": APPB2C_registration_payments_transaction_referenceLabel6,
  "APPB2C.registration.payments.transaction.showDetails": APPB2C_registration_payments_transaction_showDetails6,
  "APPB2C.registration.payments.transaction.taxLabel": APPB2C_registration_payments_transaction_taxLabel6,
  "APPB2C.registration.payments.transaction.termsLabel": APPB2C_registration_payments_transaction_termsLabel6,
  "APPB2C.registration.payments.transaction.totalLabel": APPB2C_registration_payments_transaction_totalLabel6,
  "APPB2C.registration.payments.transaction.underReviewSuffix": APPB2C_registration_payments_transaction_underReviewSuffix6,
  "APPB2C.registration.payments.transaction.unpaidSuffix": APPB2C_registration_payments_transaction_unpaidSuffix6,
  "APPB2C.registration.payments.unpaid": APPB2C_registration_payments_unpaid6,
  "APPB2C.registration.prepare.a1.cta": APPB2C_registration_prepare_a1_cta6,
  "APPB2C.registration.prepare.a1.header": APPB2C_registration_prepare_a1_header6,
  "APPB2C.registration.prepare.a2.cta": APPB2C_registration_prepare_a2_cta6,
  "APPB2C.registration.prepare.a2.header": APPB2C_registration_prepare_a2_header6,
  "APPB2C.registration.prepare.b1.cta": APPB2C_registration_prepare_b1_cta6,
  "APPB2C.registration.prepare.b1.header": APPB2C_registration_prepare_b1_header6,
  "APPB2C.registration.prepare.blog.body.cta": APPB2C_registration_prepare_blog_body_cta6,
  "APPB2C.registration.prepare.blog.body.line1": APPB2C_registration_prepare_blog_body_line16,
  "APPB2C.registration.prepare.blog.header": APPB2C_registration_prepare_blog_header6,
  "APPB2C.registration.prepare.cdFamiliarTest.body.cta": APPB2C_registration_prepare_cdFamiliarTest_body_cta6,
  "APPB2C.registration.prepare.cdFamiliarTest.body.line1": APPB2C_registration_prepare_cdFamiliarTest_body_line16,
  "APPB2C.registration.prepare.cdFamiliarTest.header": APPB2C_registration_prepare_cdFamiliarTest_header6,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.cta": APPB2C_registration_prepare_cdFamiliarVideos_body_cta6,
  "APPB2C.registration.prepare.cdFamiliarVideos.body.line1": APPB2C_registration_prepare_cdFamiliarVideos_body_line16,
  "APPB2C.registration.prepare.cdFamiliarVideos.header": APPB2C_registration_prepare_cdFamiliarVideos_header6,
  "APPB2C.registration.prepare.cdTests.body.cta": APPB2C_registration_prepare_cdTests_body_cta6,
  "APPB2C.registration.prepare.cdTests.body.line1": APPB2C_registration_prepare_cdTests_body_line16,
  "APPB2C.registration.prepare.cdTests.header": APPB2C_registration_prepare_cdTests_header6,
  "APPB2C.registration.prepare.cdTipsVideos.body.cta": APPB2C_registration_prepare_cdTipsVideos_body_cta6,
  "APPB2C.registration.prepare.cdTipsVideos.body.line1": APPB2C_registration_prepare_cdTipsVideos_body_line16,
  "APPB2C.registration.prepare.cdTipsVideos.header": APPB2C_registration_prepare_cdTipsVideos_header6,
  "APPB2C.registration.prepare.facebook.body.cta": APPB2C_registration_prepare_facebook_body_cta6,
  "APPB2C.registration.prepare.facebook.body.line1": APPB2C_registration_prepare_facebook_body_line16,
  "APPB2C.registration.prepare.facebook.header": APPB2C_registration_prepare_facebook_header6,
  "APPB2C.registration.prepare.gel.disabledMsg": APPB2C_registration_prepare_gel_disabledMsg6,
  "APPB2C.registration.prepare.gel.ielts.body.cta": APPB2C_registration_prepare_gel_ielts_body_cta6,
  "APPB2C.registration.prepare.gel.ielts.body.line1": APPB2C_registration_prepare_gel_ielts_body_line16,
  "APPB2C.registration.prepare.gel.ielts.header": APPB2C_registration_prepare_gel_ielts_header6,
  "APPB2C.registration.prepare.gel.ukvi.body.cta": APPB2C_registration_prepare_gel_ukvi_body_cta5,
  "APPB2C.registration.prepare.gel.ukvi.body.line1": APPB2C_registration_prepare_gel_ukvi_body_line16,
  "APPB2C.registration.prepare.gel.ukvi.header": APPB2C_registration_prepare_gel_ukvi_header6,
  "APPB2C.registration.prepare.header": APPB2C_registration_prepare_header6,
  "APPB2C.registration.prepare.ieltsReady.subTitle": APPB2C_registration_prepare_ieltsReady_subTitle6,
  "APPB2C.registration.prepare.ieltsReady.title": APPB2C_registration_prepare_ieltsReady_title6,
  "APPB2C.registration.prepare.ieltsReadyMember.body": APPB2C_registration_prepare_ieltsReadyMember_body6,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestBtn": APPB2C_registration_prepare_ieltsReadyMember_bookTestBtn6,
  "APPB2C.registration.prepare.ieltsReadyMember.bookTestInfo": APPB2C_registration_prepare_ieltsReadyMember_bookTestInfo6,
  "APPB2C.registration.prepare.ieltsReadyMember.freePrepLink": APPB2C_registration_prepare_ieltsReadyMember_freePrepLink6,
  "APPB2C.registration.prepare.ieltsReadyMember.header": APPB2C_registration_prepare_ieltsReadyMember_header6,
  "APPB2C.registration.prepare.ieltsReadyMember.label": APPB2C_registration_prepare_ieltsReadyMember_label6,
  "APPB2C.registration.prepare.ieltsReadyMember.link": APPB2C_registration_prepare_ieltsReadyMember_link6,
  "APPB2C.registration.prepare.ieltsReadyMember.payBtn": APPB2C_registration_prepare_ieltsReadyMember_payBtn6,
  "APPB2C.registration.prepare.ieltsReadyMember.unpaidTestInfo": APPB2C_registration_prepare_ieltsReadyMember_unpaidTestInfo6,
  "APPB2C.registration.prepare.intro": APPB2C_registration_prepare_intro6,
  "APPB2C.registration.prepare.introLifeSkills": APPB2C_registration_prepare_introLifeSkills6,
  "APPB2C.registration.prepare.onlineCourse.body.cta": APPB2C_registration_prepare_onlineCourse_body_cta6,
  "APPB2C.registration.prepare.onlineCourse.body.line1": APPB2C_registration_prepare_onlineCourse_body_line16,
  "APPB2C.registration.prepare.onlineCourse.header": APPB2C_registration_prepare_onlineCourse_header6,
  "APPB2C.registration.prepare.pbPractice.body.cta": APPB2C_registration_prepare_pbPractice_body_cta6,
  "APPB2C.registration.prepare.pbPractice.body.line1": APPB2C_registration_prepare_pbPractice_body_line16,
  "APPB2C.registration.prepare.pbPractice.header": APPB2C_registration_prepare_pbPractice_header6,
  "APPB2C.registration.prepare.practice.body.cta": APPB2C_registration_prepare_practice_body_cta6,
  "APPB2C.registration.prepare.practice.body.line1": APPB2C_registration_prepare_practice_body_line16,
  "APPB2C.registration.prepare.practice.header": APPB2C_registration_prepare_practice_header6,
  "APPB2C.registration.prepare.prepApp.body.cta": APPB2C_registration_prepare_prepApp_body_cta6,
  "APPB2C.registration.prepare.prepApp.body.line1": APPB2C_registration_prepare_prepApp_body_line16,
  "APPB2C.registration.prepare.prepApp.header": APPB2C_registration_prepare_prepApp_header6,
  "APPB2C.registration.prepare.prepApp.ukvi.cta1": APPB2C_registration_prepare_prepApp_ukvi_cta16,
  "APPB2C.registration.prepare.prepApp.ukvi.cta2": APPB2C_registration_prepare_prepApp_ukvi_cta26,
  "APPB2C.registration.prepare.prepApp.ukvi.line1": APPB2C_registration_prepare_prepApp_ukvi_line16,
  "APPB2C.registration.prepare.prepApp.ukvi.line2": APPB2C_registration_prepare_prepApp_ukvi_line26,
  "APPB2C.registration.prepare.prepVideos.body.cta": APPB2C_registration_prepare_prepVideos_body_cta6,
  "APPB2C.registration.prepare.prepVideos.body.line1": APPB2C_registration_prepare_prepVideos_body_line16,
  "APPB2C.registration.prepare.prepVideos.header": APPB2C_registration_prepare_prepVideos_header6,
  "APPB2C.registration.prepare.reading.body.cta": APPB2C_registration_prepare_reading_body_cta6,
  "APPB2C.registration.prepare.reading.body.line1": APPB2C_registration_prepare_reading_body_line16,
  "APPB2C.registration.prepare.reading.header": APPB2C_registration_prepare_reading_header6,
  "APPB2C.registration.prepare.rteLastMinute.body.cta": APPB2C_registration_prepare_rteLastMinute_body_cta6,
  "APPB2C.registration.prepare.rteLastMinute.body.item1": APPB2C_registration_prepare_rteLastMinute_body_item16,
  "APPB2C.registration.prepare.rteLastMinute.body.item2": APPB2C_registration_prepare_rteLastMinute_body_item26,
  "APPB2C.registration.prepare.rteLastMinute.body.item3": APPB2C_registration_prepare_rteLastMinute_body_item36,
  "APPB2C.registration.prepare.rteLastMinute.body.line1": APPB2C_registration_prepare_rteLastMinute_body_line16,
  "APPB2C.registration.prepare.rteLastMinute.header": APPB2C_registration_prepare_rteLastMinute_header6,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.cta": APPB2C_registration_prepare_rteLastMinute_ukvi_cta6,
  "APPB2C.registration.prepare.rteLastMinute.ukvi.line1": APPB2C_registration_prepare_rteLastMinute_ukvi_line16,
  "APPB2C.registration.prepare.rteTestDrive.body.cta": APPB2C_registration_prepare_rteTestDrive_body_cta6,
  "APPB2C.registration.prepare.rteTestDrive.body.item1": APPB2C_registration_prepare_rteTestDrive_body_item16,
  "APPB2C.registration.prepare.rteTestDrive.body.item2": APPB2C_registration_prepare_rteTestDrive_body_item26,
  "APPB2C.registration.prepare.rteTestDrive.body.item3": APPB2C_registration_prepare_rteTestDrive_body_item36,
  "APPB2C.registration.prepare.rteTestDrive.body.line1": APPB2C_registration_prepare_rteTestDrive_body_line16,
  "APPB2C.registration.prepare.rteTestDrive.body.line2": APPB2C_registration_prepare_rteTestDrive_body_line26,
  "APPB2C.registration.prepare.rteTestDrive.header": APPB2C_registration_prepare_rteTestDrive_header6,
  "APPB2C.registration.prepare.speaking.body.cta": APPB2C_registration_prepare_speaking_body_cta6,
  "APPB2C.registration.prepare.speaking.body.line1": APPB2C_registration_prepare_speaking_body_line16,
  "APPB2C.registration.prepare.speaking.header": APPB2C_registration_prepare_speaking_header6,
  "APPB2C.registration.prepare.tutor.body.cta": APPB2C_registration_prepare_tutor_body_cta6,
  "APPB2C.registration.prepare.tutor.body.line1": APPB2C_registration_prepare_tutor_body_line16,
  "APPB2C.registration.prepare.tutor.body.line2": APPB2C_registration_prepare_tutor_body_line26,
  "APPB2C.registration.prepare.tutor.header": APPB2C_registration_prepare_tutor_header6,
  "APPB2C.registration.prepare.webinars.body.cta": APPB2C_registration_prepare_webinars_body_cta6,
  "APPB2C.registration.prepare.webinars.body.item1": APPB2C_registration_prepare_webinars_body_item16,
  "APPB2C.registration.prepare.webinars.body.item2": APPB2C_registration_prepare_webinars_body_item26,
  "APPB2C.registration.prepare.webinars.body.item3": APPB2C_registration_prepare_webinars_body_item36,
  "APPB2C.registration.prepare.webinars.body.line1": APPB2C_registration_prepare_webinars_body_line16,
  "APPB2C.registration.prepare.webinars.header": APPB2C_registration_prepare_webinars_header6,
  "APPB2C.registration.results.bandScore.osrBooked": APPB2C_registration_results_bandScore_osrBooked6,
  "APPB2C.registration.results.bandScore.osrMarked": APPB2C_registration_results_bandScore_osrMarked6,
  "APPB2C.registration.results.eor.acknowledgement.backButton": APPB2C_registration_results_eor_acknowledgement_backButton6,
  "APPB2C.registration.results.eor.acknowledgement.components": APPB2C_registration_results_eor_acknowledgement_components6,
  "APPB2C.registration.results.eor.acknowledgement.confirmation": APPB2C_registration_results_eor_acknowledgement_confirmation6,
  "APPB2C.registration.results.eor.acknowledgement.fee": APPB2C_registration_results_eor_acknowledgement_fee6,
  "APPB2C.registration.results.eor.acknowledgement.info": APPB2C_registration_results_eor_acknowledgement_info6,
  "APPB2C.registration.results.eor.acknowledgement.noteTitle": APPB2C_registration_results_eor_acknowledgement_noteTitle6,
  "APPB2C.registration.results.eor.acknowledgement.payment": APPB2C_registration_results_eor_acknowledgement_payment6,
  "APPB2C.registration.results.eor.acknowledgement.paymentButton": APPB2C_registration_results_eor_acknowledgement_paymentButton6,
  "APPB2C.registration.results.eor.acknowledgement.paymentNote": APPB2C_registration_results_eor_acknowledgement_paymentNote6,
  "APPB2C.registration.results.eor.acknowledgement.reference": APPB2C_registration_results_eor_acknowledgement_reference6,
  "APPB2C.registration.results.eor.acknowledgement.success": APPB2C_registration_results_eor_acknowledgement_success6,
  "APPB2C.registration.results.eor.acknowledgement.tax": APPB2C_registration_results_eor_acknowledgement_tax6,
  "APPB2C.registration.results.eor.acknowledgement.terms": APPB2C_registration_results_eor_acknowledgement_terms6,
  "APPB2C.registration.results.eor.acknowledgement.title": APPB2C_registration_results_eor_acknowledgement_title6,
  "APPB2C.registration.results.eor.acknowledgement.total": APPB2C_registration_results_eor_acknowledgement_total6,
  "APPB2C.registration.results.eor.postDeadline.back": APPB2C_registration_results_eor_postDeadline_back6,
  "APPB2C.registration.results.eor.postDeadline.email": APPB2C_registration_results_eor_postDeadline_email6,
  "APPB2C.registration.results.eor.postDeadline.help": APPB2C_registration_results_eor_postDeadline_help6,
  "APPB2C.registration.results.eor.postDeadline.iol.launchChatLine": APPB2C_registration_results_eor_postDeadline_iol_launchChatLine6,
  "APPB2C.registration.results.eor.postDeadline.iol.line3": APPB2C_registration_results_eor_postDeadline_iol_line36,
  "APPB2C.registration.results.eor.postDeadline.line1": APPB2C_registration_results_eor_postDeadline_line16,
  "APPB2C.registration.results.eor.postDeadline.line2": APPB2C_registration_results_eor_postDeadline_line26,
  "APPB2C.registration.results.eor.postDeadline.line3": APPB2C_registration_results_eor_postDeadline_line36,
  "APPB2C.registration.results.eor.postDeadline.phone": APPB2C_registration_results_eor_postDeadline_phone6,
  "APPB2C.registration.results.eor.preDeadline.back": APPB2C_registration_results_eor_preDeadline_back6,
  "APPB2C.registration.results.eor.preDeadline.error.explanationMaxLength": APPB2C_registration_results_eor_preDeadline_error_explanationMaxLength6,
  "APPB2C.registration.results.eor.preDeadline.error.missingTrfNumber": APPB2C_registration_results_eor_preDeadline_error_missingTrfNumber6,
  "APPB2C.registration.results.eor.preDeadline.error.wrongTrfNumber": APPB2C_registration_results_eor_preDeadline_error_wrongTrfNumber6,
  "APPB2C.registration.results.eor.preDeadline.evidence": APPB2C_registration_results_eor_preDeadline_evidence6,
  "APPB2C.registration.results.eor.preDeadline.evidenceRequired": APPB2C_registration_results_eor_preDeadline_evidenceRequired6,
  "APPB2C.registration.results.eor.preDeadline.explanation": APPB2C_registration_results_eor_preDeadline_explanation6,
  "APPB2C.registration.results.eor.preDeadline.explanationRequired": APPB2C_registration_results_eor_preDeadline_explanationRequired6,
  "APPB2C.registration.results.eor.preDeadline.fee": APPB2C_registration_results_eor_preDeadline_fee6,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line1": APPB2C_registration_results_eor_preDeadline_iolNote_line15,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line2": APPB2C_registration_results_eor_preDeadline_iolNote_line26,
  "APPB2C.registration.results.eor.preDeadline.iolNote.line3": APPB2C_registration_results_eor_preDeadline_iolNote_line36,
  "APPB2C.registration.results.eor.preDeadline.legal": APPB2C_registration_results_eor_preDeadline_legal6,
  "APPB2C.registration.results.eor.preDeadline.legalNote": APPB2C_registration_results_eor_preDeadline_legalNote6,
  "APPB2C.registration.results.eor.preDeadline.line1": APPB2C_registration_results_eor_preDeadline_line16,
  "APPB2C.registration.results.eor.preDeadline.line2": APPB2C_registration_results_eor_preDeadline_line26,
  "APPB2C.registration.results.eor.preDeadline.maxScore": APPB2C_registration_results_eor_preDeadline_maxScore6,
  "APPB2C.registration.results.eor.preDeadline.note": APPB2C_registration_results_eor_preDeadline_note6,
  "APPB2C.registration.results.eor.preDeadline.noteTitle": APPB2C_registration_results_eor_preDeadline_noteTitle6,
  "APPB2C.registration.results.eor.preDeadline.orderAcknowledgementLabel": APPB2C_registration_results_eor_preDeadline_orderAcknowledgementLabel6,
  "APPB2C.registration.results.eor.preDeadline.paymentCta": APPB2C_registration_results_eor_preDeadline_paymentCta6,
  "APPB2C.registration.results.eor.preDeadline.paymentTitle": APPB2C_registration_results_eor_preDeadline_paymentTitle6,
  "APPB2C.registration.results.eor.preDeadline.payOfflineCTA": APPB2C_registration_results_eor_preDeadline_payOfflineCTA6,
  "APPB2C.registration.results.eor.preDeadline.payOnlineCTA": APPB2C_registration_results_eor_preDeadline_payOnlineCTA6,
  "APPB2C.registration.results.eor.preDeadline.scoresRequired": APPB2C_registration_results_eor_preDeadline_scoresRequired6,
  "APPB2C.registration.results.eor.preDeadline.scoresTitle": APPB2C_registration_results_eor_preDeadline_scoresTitle6,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr6,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Listening": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Listening6,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Reading": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Reading6,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Speaking": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Speaking6,
  "APPB2C.registration.results.eor.preDeadline.scoresTitleOsr.Writing": APPB2C_registration_results_eor_preDeadline_scoresTitleOsr_Writing6,
  "APPB2C.registration.results.eor.preDeadline.tax": APPB2C_registration_results_eor_preDeadline_tax6,
  "APPB2C.registration.results.eor.preDeadline.trfNumberLabel": APPB2C_registration_results_eor_preDeadline_trfNumberLabel6,
  "APPB2C.registration.results.eor.preDeadline.trfNumberSubnote": APPB2C_registration_results_eor_preDeadline_trfNumberSubnote6,
  "APPB2C.registration.results.eor.preDeadline.trfNumberValidation": APPB2C_registration_results_eor_preDeadline_trfNumberValidation6,
  "APPB2C.registration.results.eor.title": APPB2C_registration_results_eor_title6,
  "APPB2C.registration.results.eor.titlePayment": APPB2C_registration_results_eor_titlePayment6,
  "APPB2C.registration.results.hasResults.bandScore.decrease": APPB2C_registration_results_hasResults_bandScore_decrease6,
  "APPB2C.registration.results.hasResults.bandScore.increase": APPB2C_registration_results_hasResults_bandScore_increase6,
  "APPB2C.registration.results.hasResults.bandScore.unchanged": APPB2C_registration_results_hasResults_bandScore_unchanged6,
  "APPB2C.registration.results.hasResults.eorAgentInfo": APPB2C_registration_results_hasResults_eorAgentInfo5,
  "APPB2C.registration.results.hasResults.eorLetter": APPB2C_registration_results_hasResults_eorLetter6,
  "APPB2C.registration.results.hasResults.eorLine1": APPB2C_registration_results_hasResults_eorLine16,
  "APPB2C.registration.results.hasResults.eorLine2": APPB2C_registration_results_hasResults_eorLine26,
  "APPB2C.registration.results.hasResults.eorLink": APPB2C_registration_results_hasResults_eorLink6,
  "APPB2C.registration.results.hasResults.eTrfCta": APPB2C_registration_results_hasResults_eTrfCta5,
  "APPB2C.registration.results.hasResults.explanations.heading": APPB2C_registration_results_hasResults_explanations_heading6,
  "APPB2C.registration.results.hasResults.explanations.hideButton": APPB2C_registration_results_hasResults_explanations_hideButton5,
  "APPB2C.registration.results.hasResults.explanations.improve": APPB2C_registration_results_hasResults_explanations_improve6,
  "APPB2C.registration.results.hasResults.explanations.showButton": APPB2C_registration_results_hasResults_explanations_showButton5,
  "APPB2C.registration.results.hasResults.lifeSkills.failed": APPB2C_registration_results_hasResults_lifeSkills_failed6,
  "APPB2C.registration.results.hasResults.lifeSkills.level": APPB2C_registration_results_hasResults_lifeSkills_level6,
  "APPB2C.registration.results.hasResults.lifeSkills.note": APPB2C_registration_results_hasResults_lifeSkills_note6,
  "APPB2C.registration.results.hasResults.lifeSkills.passed": APPB2C_registration_results_hasResults_lifeSkills_passed6,
  "APPB2C.registration.results.hasResults.lifeSkills.result": APPB2C_registration_results_hasResults_lifeSkills_result6,
  "APPB2C.registration.results.hasResults.osr.bandScore.decrease": APPB2C_registration_results_hasResults_osr_bandScore_decrease5,
  "APPB2C.registration.results.hasResults.osr.bandScore.increase": APPB2C_registration_results_hasResults_osr_bandScore_increase5,
  "APPB2C.registration.results.hasResults.osr.bandScore.unchanged": APPB2C_registration_results_hasResults_osr_bandScore_unchanged5,
  "APPB2C.registration.results.hasResults.osr.previousResults": APPB2C_registration_results_hasResults_osr_previousResults6,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreDecreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreDecreased6,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreIncreased": APPB2C_registration_results_hasResults_osr_updateBar_scoreIncreased6,
  "APPB2C.registration.results.hasResults.osr.updateBar.scoreUnchanged": APPB2C_registration_results_hasResults_osr_updateBar_scoreUnchanged6,
  "APPB2C.registration.results.hasResults.osr.updateBar.title": APPB2C_registration_results_hasResults_osr_updateBar_title6,
  "APPB2C.registration.results.hasResults.osrLink": APPB2C_registration_results_hasResults_osrLink5,
  "APPB2C.registration.results.hasResults.posting.oneCopyWarning": APPB2C_registration_results_hasResults_posting_oneCopyWarning6,
  "APPB2C.registration.results.hasResults.posting.postedOn": APPB2C_registration_results_hasResults_posting_postedOn6,
  "APPB2C.registration.results.hasResults.posting.postedTo": APPB2C_registration_results_hasResults_posting_postedTo6,
  "APPB2C.registration.results.hasResults.posting.willPostOn": APPB2C_registration_results_hasResults_posting_willPostOn6,
  "APPB2C.registration.results.hasResults.posting.willPostTo": APPB2C_registration_results_hasResults_posting_willPostTo6,
  "APPB2C.registration.results.hasResults.rankNameConsonant": APPB2C_registration_results_hasResults_rankNameConsonant6,
  "APPB2C.registration.results.hasResults.rankNameVowel": APPB2C_registration_results_hasResults_rankNameVowel6,
  "APPB2C.registration.results.hasResults.scoreImprove.btn": APPB2C_registration_results_hasResults_scoreImprove_btn6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.backBtn": APPB2C_registration_results_hasResults_scoreImprove_modal_backBtn6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.description": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_description6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.bookNew.title": APPB2C_registration_results_hasResults_scoreImprove_modal_bookNew_title6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.description": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_description6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.eor.title": APPB2C_registration_results_hasResults_scoreImprove_modal_eor_title6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.description": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_description6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.osr.title": APPB2C_registration_results_hasResults_scoreImprove_modal_osr_title6,
  "APPB2C.registration.results.hasResults.scoreImprove.modal.subtitle": APPB2C_registration_results_hasResults_scoreImprove_modal_subtitle6,
  "APPB2C.registration.results.hasResults.scoreImprove.subtitle": APPB2C_registration_results_hasResults_scoreImprove_subtitle6,
  "APPB2C.registration.results.hasResults.scoreImprove.title": APPB2C_registration_results_hasResults_scoreImprove_title6,
  "APPB2C.registration.results.hasResults.scores.label": APPB2C_registration_results_hasResults_scores_label6,
  "APPB2C.registration.results.hasResults.scores.Listening": APPB2C_registration_results_hasResults_scores_Listening6,
  "APPB2C.registration.results.hasResults.scores.Overall": APPB2C_registration_results_hasResults_scores_Overall6,
  "APPB2C.registration.results.hasResults.scores.Reading": APPB2C_registration_results_hasResults_scores_Reading6,
  "APPB2C.registration.results.hasResults.scores.Speaking": APPB2C_registration_results_hasResults_scores_Speaking6,
  "APPB2C.registration.results.hasResults.scores.Writing": APPB2C_registration_results_hasResults_scores_Writing6,
  "APPB2C.registration.results.hasResults.share.applyBoard": APPB2C_registration_results_hasResults_share_applyBoard6,
  "APPB2C.registration.results.hasResults.share.RecognisingOrgsLink": APPB2C_registration_results_hasResults_share_RecognisingOrgsLink6,
  "APPB2C.registration.results.hasResults.share.subtitle": APPB2C_registration_results_hasResults_share_subtitle6,
  "APPB2C.registration.results.hasResults.share.title": APPB2C_registration_results_hasResults_share_title6,
  "APPB2C.registration.results.hasResults.share.trfCta": APPB2C_registration_results_hasResults_share_trfCta6,
  "APPB2C.registration.results.hasResults.viewExplanation": APPB2C_registration_results_hasResults_viewExplanation6,
  "APPB2C.registration.results.noResults.cmds.title": APPB2C_registration_results_noResults_cmds_title6,
  "APPB2C.registration.results.noResults.note.title": APPB2C_registration_results_noResults_note_title6,
  "APPB2C.registration.results.noResults.osr.booked": APPB2C_registration_results_noResults_osr_booked6,
  "APPB2C.registration.results.noResults.osr.previousScores": APPB2C_registration_results_noResults_osr_previousScores6,
  "APPB2C.registration.results.noResults.title": APPB2C_registration_results_noResults_title6,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote1": APPB2C_registration_results_organisations_availableReceivingOrgsNote16,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote2": APPB2C_registration_results_organisations_availableReceivingOrgsNote26,
  "APPB2C.registration.results.organisations.availableReceivingOrgsNote3": APPB2C_registration_results_organisations_availableReceivingOrgsNote35,
  "APPB2C.registration.results.organisations.chosenOrganisationsHeader": APPB2C_registration_results_organisations_chosenOrganisationsHeader6,
  "APPB2C.registration.results.organisations.editRo.addAnother": APPB2C_registration_results_organisations_editRo_addAnother6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.addNew": APPB2C_registration_results_organisations_editRo_chooseOrganisations_addNew6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.address": APPB2C_registration_results_organisations_editRo_chooseOrganisations_address6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.country": APPB2C_registration_results_organisations_editRo_chooseOrganisations_country6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryAll": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryAll6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.countryOptional": APPB2C_registration_results_organisations_editRo_chooseOrganisations_countryOptional6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.file": APPB2C_registration_results_organisations_editRo_chooseOrganisations_file6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.org": APPB2C_registration_results_organisations_editRo_chooseOrganisations_org6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.organisation": APPB2C_registration_results_organisations_editRo_chooseOrganisations_organisation6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.person": APPB2C_registration_results_organisations_editRo_chooseOrganisations_person6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.postCode": APPB2C_registration_results_organisations_editRo_chooseOrganisations_postCode6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByCountry": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByCountry6,
  "APPB2C.registration.results.organisations.editRo.chooseOrganisations.searchByOrg": APPB2C_registration_results_organisations_editRo_chooseOrganisations_searchByOrg6,
  "APPB2C.registration.results.organisations.editRo.error.caseNumberMaxLength": APPB2C_registration_results_organisations_editRo_error_caseNumberMaxLength6,
  "APPB2C.registration.results.organisations.editRo.error.contactPersonMaxLength": APPB2C_registration_results_organisations_editRo_error_contactPersonMaxLength6,
  "APPB2C.registration.results.organisations.editRo.error.minScoreMissing": APPB2C_registration_results_organisations_editRo_error_minScoreMissing6,
  "APPB2C.registration.results.organisations.editRo.error.missingAddressLine1": APPB2C_registration_results_organisations_editRo_error_missingAddressLine16,
  "APPB2C.registration.results.organisations.editRo.error.missingOrgName": APPB2C_registration_results_organisations_editRo_error_missingOrgName6,
  "APPB2C.registration.results.organisations.editRo.error.orgNameMaxLength": APPB2C_registration_results_organisations_editRo_error_orgNameMaxLength6,
  "APPB2C.registration.results.organisations.editRo.iol.addCta": APPB2C_registration_results_organisations_editRo_iol_addCta6,
  "APPB2C.registration.results.organisations.editRo.iol.address": APPB2C_registration_results_organisations_editRo_iol_address6,
  "APPB2C.registration.results.organisations.editRo.iol.cancelAdd": APPB2C_registration_results_organisations_editRo_iol_cancelAdd5,
  "APPB2C.registration.results.organisations.editRo.iol.cancelEdit": APPB2C_registration_results_organisations_editRo_iol_cancelEdit6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.clear": APPB2C_registration_results_organisations_editRo_iol_conditions_clear6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.header": APPB2C_registration_results_organisations_editRo_iol_conditions_header6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.mandatoryPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_mandatoryPlaceholder6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.no": APPB2C_registration_results_organisations_editRo_iol_conditions_no6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note1": APPB2C_registration_results_organisations_editRo_iol_conditions_note16,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note2": APPB2C_registration_results_organisations_editRo_iol_conditions_note26,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.note3": APPB2C_registration_results_organisations_editRo_iol_conditions_note36,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.optionalPlaceholder": APPB2C_registration_results_organisations_editRo_iol_conditions_optionalPlaceholder6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.subHeader": APPB2C_registration_results_organisations_editRo_iol_conditions_subHeader6,
  "APPB2C.registration.results.organisations.editRo.iol.conditions.yes": APPB2C_registration_results_organisations_editRo_iol_conditions_yes6,
  "APPB2C.registration.results.organisations.editRo.iol.copied": APPB2C_registration_results_organisations_editRo_iol_copied6,
  "APPB2C.registration.results.organisations.editRo.iol.copy": APPB2C_registration_results_organisations_editRo_iol_copy6,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.edelivery": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_edelivery6,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.eResults": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_eResults6,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.note": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_note6,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.postal": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_postal6,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.mod.trf": APPB2C_registration_results_organisations_editRo_iol_delivery_mod_trf6,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note1": APPB2C_registration_results_organisations_editRo_iol_delivery_note16,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2": APPB2C_registration_results_organisations_editRo_iol_delivery_note26,
  "APPB2C.registration.results.organisations.editRo.iol.delivery.note2.results": APPB2C_registration_results_organisations_editRo_iol_delivery_note2_results6,
  "APPB2C.registration.results.organisations.editRo.iol.newOrgTitle": APPB2C_registration_results_organisations_editRo_iol_newOrgTitle6,
  "APPB2C.registration.results.organisations.editRo.iol.noResults": APPB2C_registration_results_organisations_editRo_iol_noResults6,
  "APPB2C.registration.results.organisations.editRo.iol.removeLink": APPB2C_registration_results_organisations_editRo_iol_removeLink6,
  "APPB2C.registration.results.organisations.editRo.iol.saveCta": APPB2C_registration_results_organisations_editRo_iol_saveCta6,
  "APPB2C.registration.results.organisations.editRo.iol.validation.overallScore": APPB2C_registration_results_organisations_editRo_iol_validation_overallScore6,
  "APPB2C.registration.results.organisations.editRo.iol.validation.selectedOrganisation": APPB2C_registration_results_organisations_editRo_iol_validation_selectedOrganisation6,
  "APPB2C.registration.results.organisations.iol.chooseCta": APPB2C_registration_results_organisations_iol_chooseCta6,
  "APPB2C.registration.results.organisations.iol.delivery.auto.header": APPB2C_registration_results_organisations_iol_delivery_auto_header6,
  "APPB2C.registration.results.organisations.iol.delivery.auto.note": APPB2C_registration_results_organisations_iol_delivery_auto_note6,
  "APPB2C.registration.results.organisations.iol.delivery.auto.notSentNote": APPB2C_registration_results_organisations_iol_delivery_auto_notSentNote6,
  "APPB2C.registration.results.organisations.iol.delivery.auto.sentNote": APPB2C_registration_results_organisations_iol_delivery_auto_sentNote6,
  "APPB2C.registration.results.organisations.iol.delivery.postal.header": APPB2C_registration_results_organisations_iol_delivery_postal_header6,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note1": APPB2C_registration_results_organisations_iol_delivery_postal_note16,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2": APPB2C_registration_results_organisations_iol_delivery_postal_note26,
  "APPB2C.registration.results.organisations.iol.delivery.postal.note2.results": APPB2C_registration_results_organisations_iol_delivery_postal_note2_results6,
  "APPB2C.registration.results.organisations.iol.edit": APPB2C_registration_results_organisations_iol_edit6,
  "APPB2C.registration.results.organisations.iol.error": APPB2C_registration_results_organisations_iol_error6,
  "APPB2C.registration.results.organisations.iol.generalCondition": APPB2C_registration_results_organisations_iol_generalCondition6,
  "APPB2C.registration.results.organisations.iol.noConditions": APPB2C_registration_results_organisations_iol_noConditions6,
  "APPB2C.registration.results.organisations.iol.notChosen": APPB2C_registration_results_organisations_iol_notChosen6,
  "APPB2C.registration.results.organisations.iol.preciseCondition": APPB2C_registration_results_organisations_iol_preciseCondition6,
  "APPB2C.registration.results.organisations.iol.roAccept.link": APPB2C_registration_results_organisations_iol_roAccept_link6,
  "APPB2C.registration.results.organisations.iol.roAccept.message": APPB2C_registration_results_organisations_iol_roAccept_message6,
  "APPB2C.registration.results.organisations.iol.roAccept.title": APPB2C_registration_results_organisations_iol_roAccept_title6,
  "APPB2C.registration.results.organisations.iol.sendAnyway": APPB2C_registration_results_organisations_iol_sendAnyway6,
  "APPB2C.registration.results.organisations.list.item1": APPB2C_registration_results_organisations_list_item16,
  "APPB2C.registration.results.organisations.list.item2": APPB2C_registration_results_organisations_list_item26,
  "APPB2C.registration.results.organisations.list.item3": APPB2C_registration_results_organisations_list_item36,
  "APPB2C.registration.results.organisations.list.item4": APPB2C_registration_results_organisations_list_item46,
  "APPB2C.registration.results.organisations.mod.delivery.auto.header": APPB2C_registration_results_organisations_mod_delivery_auto_header6,
  "APPB2C.registration.results.organisations.mod.delivery.auto.note": APPB2C_registration_results_organisations_mod_delivery_auto_note6,
  "APPB2C.registration.results.organisations.mod.delivery.auto.notSentNote": APPB2C_registration_results_organisations_mod_delivery_auto_notSentNote6,
  "APPB2C.registration.results.organisations.mod.delivery.auto.sentNote": APPB2C_registration_results_organisations_mod_delivery_auto_sentNote6,
  "APPB2C.registration.results.organisations.mod.delivery.postal.header": APPB2C_registration_results_organisations_mod_delivery_postal_header6,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note1": APPB2C_registration_results_organisations_mod_delivery_postal_note16,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note2": APPB2C_registration_results_organisations_mod_delivery_postal_note26,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note3": APPB2C_registration_results_organisations_mod_delivery_postal_note36,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note4": APPB2C_registration_results_organisations_mod_delivery_postal_note46,
  "APPB2C.registration.results.organisations.mod.delivery.postal.note5": APPB2C_registration_results_organisations_mod_delivery_postal_note56,
  "APPB2C.registration.results.organisations.mod.delivery.postal.notSentNote": APPB2C_registration_results_organisations_mod_delivery_postal_notSentNote6,
  "APPB2C.registration.results.organisations.mod.delivery.postal.sentNote": APPB2C_registration_results_organisations_mod_delivery_postal_sentNote6,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount6,
  "APPB2C.registration.results.organisations.mod.delivery.postal.trfCount_other": APPB2C_registration_results_organisations_mod_delivery_postal_trfCount_other6,
  "APPB2C.registration.results.organisations.mod.howTo.item1": APPB2C_registration_results_organisations_mod_howTo_item16,
  "APPB2C.registration.results.organisations.mod.howTo.item2": APPB2C_registration_results_organisations_mod_howTo_item26,
  "APPB2C.registration.results.organisations.mod.howTo.line1": APPB2C_registration_results_organisations_mod_howTo_line16,
  "APPB2C.registration.results.organisations.mod.howTo.title": APPB2C_registration_results_organisations_mod_howTo_title6,
  "APPB2C.registration.results.organisations.noReceivingOrgsSelectedNote": APPB2C_registration_results_organisations_noReceivingOrgsSelectedNote6,
  "APPB2C.registration.results.organisations.note0": APPB2C_registration_results_organisations_note06,
  "APPB2C.registration.results.organisations.note1": APPB2C_registration_results_organisations_note16,
  "APPB2C.registration.results.organisations.note2": APPB2C_registration_results_organisations_note26,
  "APPB2C.registration.results.organisations.organisationsCount": APPB2C_registration_results_organisations_organisationsCount6,
  "APPB2C.registration.results.organisations.organisationsCount_other": APPB2C_registration_results_organisations_organisationsCount_other6,
  "APPB2C.registration.results.organisations.receivingOrgsLimitReachedNote": APPB2C_registration_results_organisations_receivingOrgsLimitReachedNote6,
  "APPB2C.registration.results.organisations.title": APPB2C_registration_results_organisations_title6,
  "APPB2C.registration.results.osr.chooseDate.accessibility": APPB2C_registration_results_osr_chooseDate_accessibility6,
  "APPB2C.registration.results.osr.chooseDate.accessibilityLink": APPB2C_registration_results_osr_chooseDate_accessibilityLink6,
  "APPB2C.registration.results.osr.chooseDate.chooseDateTitle": APPB2C_registration_results_osr_chooseDate_chooseDateTitle6,
  "APPB2C.registration.results.osr.chooseDate.note.content": APPB2C_registration_results_osr_chooseDate_note_content6,
  "APPB2C.registration.results.osr.chooseDate.note.title": APPB2C_registration_results_osr_chooseDate_note_title6,
  "APPB2C.registration.results.osr.chooseDate.retakeListening": APPB2C_registration_results_osr_chooseDate_retakeListening6,
  "APPB2C.registration.results.osr.chooseDate.retakeReading": APPB2C_registration_results_osr_chooseDate_retakeReading6,
  "APPB2C.registration.results.osr.chooseDate.retakeSpeaking": APPB2C_registration_results_osr_chooseDate_retakeSpeaking6,
  "APPB2C.registration.results.osr.chooseDate.retakeWriting": APPB2C_registration_results_osr_chooseDate_retakeWriting6,
  "APPB2C.registration.results.osr.chooseSkill.backBtn": APPB2C_registration_results_osr_chooseSkill_backBtn6,
  "APPB2C.registration.results.osr.chooseSkill.chooseDateBtn": APPB2C_registration_results_osr_chooseSkill_chooseDateBtn6,
  "APPB2C.registration.results.osr.chooseSkill.confirmationRequired": APPB2C_registration_results_osr_chooseSkill_confirmationRequired6,
  "APPB2C.registration.results.osr.chooseSkill.formTitle": APPB2C_registration_results_osr_chooseSkill_formTitle6,
  "APPB2C.registration.results.osr.chooseSkill.modal.contact": APPB2C_registration_results_osr_chooseSkill_modal_contact6,
  "APPB2C.registration.results.osr.chooseSkill.modal.description": APPB2C_registration_results_osr_chooseSkill_modal_description6,
  "APPB2C.registration.results.osr.chooseSkill.modal.email": APPB2C_registration_results_osr_chooseSkill_modal_email6,
  "APPB2C.registration.results.osr.chooseSkill.modal.phone": APPB2C_registration_results_osr_chooseSkill_modal_phone6,
  "APPB2C.registration.results.osr.chooseSkill.modal.title": APPB2C_registration_results_osr_chooseSkill_modal_title6,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.confirmation": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_confirmation6,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.description": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_description6,
  "APPB2C.registration.results.osr.chooseSkill.orgAcceptRetakes.title": APPB2C_registration_results_osr_chooseSkill_orgAcceptRetakes_title6,
  "APPB2C.registration.results.osr.chooseSkill.skillRequired": APPB2C_registration_results_osr_chooseSkill_skillRequired6,
  "APPB2C.registration.results.osr.chooseTest.availableDates": APPB2C_registration_results_osr_chooseTest_availableDates6,
  "APPB2C.registration.results.osr.chooseTest.speaking.time": APPB2C_registration_results_osr_chooseTest_speaking_time6,
  "APPB2C.registration.results.osr.error.missingFile": APPB2C_registration_results_osr_error_missingFile6,
  "APPB2C.registration.results.osr.error.noTestDates": APPB2C_registration_results_osr_error_noTestDates6,
  "APPB2C.registration.results.osr.error.noTests": APPB2C_registration_results_osr_error_noTests6,
  "APPB2C.registration.results.osr.line1": APPB2C_registration_results_osr_line16,
  "APPB2C.registration.results.osr.line2": APPB2C_registration_results_osr_line26,
  "APPB2C.registration.results.osr.line3": APPB2C_registration_results_osr_line36,
  "APPB2C.registration.results.osr.line4": APPB2C_registration_results_osr_line46,
  "APPB2C.registration.results.osr.line5": APPB2C_registration_results_osr_line56,
  "APPB2C.registration.results.osr.review.note": APPB2C_registration_results_osr_review_note6,
  "APPB2C.registration.results.osr.review.note.testDay": APPB2C_registration_results_osr_review_note_testDay6,
  "APPB2C.registration.results.osr.submitBtn": APPB2C_registration_results_osr_submitBtn6,
  "APPB2C.registration.results.osr.successBanner": APPB2C_registration_results_osr_successBanner6,
  "APPB2C.registration.results.osr.title": APPB2C_registration_results_osr_title6,
  "APPB2C.registration.results.ttAbsent.btn": APPB2C_registration_results_ttAbsent_btn6,
  "APPB2C.registration.results.ttAbsent.msg": APPB2C_registration_results_ttAbsent_msg6,
  "APPB2C.registration.results.updateBar.osr.booked": APPB2C_registration_results_updateBar_osr_booked6,
  "APPB2C.registration.results.updateBar.osr.marked": APPB2C_registration_results_updateBar_osr_marked6,
  "APPB2C.registration.tabs.bookingDetails": APPB2C_registration_tabs_bookingDetails6,
  "APPB2C.registration.tabs.candidate": APPB2C_registration_tabs_candidate6,
  "APPB2C.registration.tabs.date": APPB2C_registration_tabs_date6,
  "APPB2C.registration.tabs.dateLocation": APPB2C_registration_tabs_dateLocation6,
  "APPB2C.registration.tabs.payments": APPB2C_registration_tabs_payments6,
  "APPB2C.registration.tabs.recognisingOrgs": APPB2C_registration_tabs_recognisingOrgs6,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.1": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_16,
  "APPB2C.registration.tabs.recognisingOrgs.defaultMsg.2": APPB2C_registration_tabs_recognisingOrgs_defaultMsg_26,
  "APPB2C.registration.tabs.results": APPB2C_registration_tabs_results6,
  "APPB2C.registration.testTaker.bookedFor.completed": APPB2C_registration_testTaker_bookedFor_completed6,
  "APPB2C.registration.testTaker.bookedFor.dob": APPB2C_registration_testTaker_bookedFor_dob6,
  "APPB2C.registration.testTaker.bookedFor.email": APPB2C_registration_testTaker_bookedFor_email6,
  "APPB2C.registration.testTaker.bookedFor.gender": APPB2C_registration_testTaker_bookedFor_gender6,
  "APPB2C.registration.testTaker.bookedFor.name": APPB2C_registration_testTaker_bookedFor_name6,
  "APPB2C.registration.testTaker.bookedFor.parent": APPB2C_registration_testTaker_bookedFor_parent6,
  "APPB2C.registration.testTaker.bookedFor.title": APPB2C_registration_testTaker_bookedFor_title6,
  "APPB2C.registration.testTaker.childConsent.back": APPB2C_registration_testTaker_childConsent_back6,
  "APPB2C.registration.testTaker.childConsent.context": APPB2C_registration_testTaker_childConsent_context6,
  "APPB2C.registration.testTaker.childConsent.cta": APPB2C_registration_testTaker_childConsent_cta6,
  "APPB2C.registration.testTaker.childConsent.options.1": APPB2C_registration_testTaker_childConsent_options_16,
  "APPB2C.registration.testTaker.childConsent.options.2": APPB2C_registration_testTaker_childConsent_options_26,
  "APPB2C.registration.testTaker.childConsent.options.3": APPB2C_registration_testTaker_childConsent_options_36,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.add": APPB2C_registration_testTaker_childConsent_somebodyElse_add6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmCheckbox": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmCheckbox6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.1": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_16,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.confirmText.2": APPB2C_registration_testTaker_childConsent_somebodyElse_confirmText_26,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.noLastName": APPB2C_registration_testTaker_childConsent_somebodyElse_noLastName6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.note": APPB2C_registration_testTaker_childConsent_somebodyElse_note6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phone": APPB2C_registration_testTaker_childConsent_somebodyElse_phone6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.phoneNote": APPB2C_registration_testTaker_childConsent_somebodyElse_phoneNote6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.policy": APPB2C_registration_testTaker_childConsent_somebodyElse_policy6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.relationship": APPB2C_registration_testTaker_childConsent_somebodyElse_relationship6,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.1": APPB2C_registration_testTaker_childConsent_somebodyElse_title_16,
  "APPB2C.registration.testTaker.childConsent.somebodyElse.title.2": APPB2C_registration_testTaker_childConsent_somebodyElse_title_26,
  "APPB2C.registration.testTaker.childConsent.title": APPB2C_registration_testTaker_childConsent_title6,
  "APPB2C.registration.testTaker.childConsent.warning": APPB2C_registration_testTaker_childConsent_warning6,
  "APPB2C.registration.testTaker.consent.edit": APPB2C_registration_testTaker_consent_edit6,
  "APPB2C.registration.testTaker.consent.missing": APPB2C_registration_testTaker_consent_missing6,
  "APPB2C.registration.testTaker.consent.title": APPB2C_registration_testTaker_consent_title6,
  "APPB2C.registration.testTaker.contact.address": APPB2C_registration_testTaker_contact_address6,
  "APPB2C.registration.testTaker.contact.edit": APPB2C_registration_testTaker_contact_edit6,
  "APPB2C.registration.testTaker.contact.mobile": APPB2C_registration_testTaker_contact_mobile6,
  "APPB2C.registration.testTaker.contact.parent": APPB2C_registration_testTaker_contact_parent6,
  "APPB2C.registration.testTaker.contact.title": APPB2C_registration_testTaker_contact_title6,
  "APPB2C.registration.testTaker.editContact.address": APPB2C_registration_testTaker_editContact_address6,
  "APPB2C.registration.testTaker.editContact.back": APPB2C_registration_testTaker_editContact_back6,
  "APPB2C.registration.testTaker.editContact.city": APPB2C_registration_testTaker_editContact_city6,
  "APPB2C.registration.testTaker.editContact.country.change": APPB2C_registration_testTaker_editContact_country_change6,
  "APPB2C.registration.testTaker.editContact.country.residence": APPB2C_registration_testTaker_editContact_country_residence6,
  "APPB2C.registration.testTaker.editContact.cta": APPB2C_registration_testTaker_editContact_cta6,
  "APPB2C.registration.testTaker.editContact.error.missingAddress": APPB2C_registration_testTaker_editContact_error_missingAddress6,
  "APPB2C.registration.testTaker.editContact.error.missingCountry": APPB2C_registration_testTaker_editContact_error_missingCountry6,
  "APPB2C.registration.testTaker.editContact.error.pastCutoff": APPB2C_registration_testTaker_editContact_error_pastCutoff6,
  "APPB2C.registration.testTaker.editContact.mobile": APPB2C_registration_testTaker_editContact_mobile6,
  "APPB2C.registration.testTaker.editContact.note": APPB2C_registration_testTaker_editContact_note6,
  "APPB2C.registration.testTaker.editContact.postCode": APPB2C_registration_testTaker_editContact_postCode6,
  "APPB2C.registration.testTaker.editContact.state": APPB2C_registration_testTaker_editContact_state6,
  "APPB2C.registration.testTaker.editContact.warn": APPB2C_registration_testTaker_editContact_warn6,
  "APPB2C.registration.testTaker.error": APPB2C_registration_testTaker_error6,
  "APPB2C.registration.testTaker.files.editButton.add": APPB2C_registration_testTaker_files_editButton_add6,
  "APPB2C.registration.testTaker.files.editButton.edit": APPB2C_registration_testTaker_files_editButton_edit6,
  "APPB2C.registration.testTaker.files.upload.back.subtitle": APPB2C_registration_testTaker_files_upload_back_subtitle6,
  "APPB2C.registration.testTaker.files.upload.back.title": APPB2C_registration_testTaker_files_upload_back_title6,
  "APPB2C.registration.testTaker.files.upload.cta": APPB2C_registration_testTaker_files_upload_cta6,
  "APPB2C.registration.testTaker.files.upload.error.fileTooLarge": APPB2C_registration_testTaker_files_upload_error_fileTooLarge6,
  "APPB2C.registration.testTaker.files.upload.error.maxNumberOfFilesExceeded": APPB2C_registration_testTaker_files_upload_error_maxNumberOfFilesExceeded6,
  "APPB2C.registration.testTaker.files.upload.error.uploadUnavailable": APPB2C_registration_testTaker_files_upload_error_uploadUnavailable6,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileName": APPB2C_registration_testTaker_files_upload_error_wrongFileName6,
  "APPB2C.registration.testTaker.files.upload.error.wrongFileType": APPB2C_registration_testTaker_files_upload_error_wrongFileType6,
  "APPB2C.registration.testTaker.files.upload.mobile.line1": APPB2C_registration_testTaker_files_upload_mobile_line16,
  "APPB2C.registration.testTaker.files.upload.mobile.line2": APPB2C_registration_testTaker_files_upload_mobile_line26,
  "APPB2C.registration.testTaker.files.upload.mobile.title": APPB2C_registration_testTaker_files_upload_mobile_title6,
  "APPB2C.registration.testTaker.files.upload.note": APPB2C_registration_testTaker_files_upload_note6,
  "APPB2C.registration.testTaker.id.child": APPB2C_registration_testTaker_id_child6,
  "APPB2C.registration.testTaker.id.edit.rules.example": APPB2C_registration_testTaker_id_edit_rules_example6,
  "APPB2C.registration.testTaker.id.edit.subtitle": APPB2C_registration_testTaker_id_edit_subtitle6,
  "APPB2C.registration.testTaker.id.edit.title": APPB2C_registration_testTaker_id_edit_title6,
  "APPB2C.registration.testTaker.id.edit.upload.exampleProof": APPB2C_registration_testTaker_id_edit_upload_exampleProof6,
  "APPB2C.registration.testTaker.id.edit.upload.imgAlt": APPB2C_registration_testTaker_id_edit_upload_imgAlt6,
  "APPB2C.registration.testTaker.id.expiryDate": APPB2C_registration_testTaker_id_expiryDate6,
  "APPB2C.registration.testTaker.id.fileAlt": APPB2C_registration_testTaker_id_fileAlt6,
  "APPB2C.registration.testTaker.id.idNumber": APPB2C_registration_testTaker_id_idNumber6,
  "APPB2C.registration.testTaker.id.issuingAuthority": APPB2C_registration_testTaker_id_issuingAuthority6,
  "APPB2C.registration.testTaker.id.noFilesBody": APPB2C_registration_testTaker_id_noFilesBody6,
  "APPB2C.registration.testTaker.id.noFilesTitle": APPB2C_registration_testTaker_id_noFilesTitle6,
  "APPB2C.registration.testTaker.id.title": APPB2C_registration_testTaker_id_title6,
  "APPB2C.registration.testTaker.id.typeNames.card": APPB2C_registration_testTaker_id_typeNames_card6,
  "APPB2C.registration.testTaker.id.typeNames.other": APPB2C_registration_testTaker_id_typeNames_other6,
  "APPB2C.registration.testTaker.id.typeNames.passport": APPB2C_registration_testTaker_id_typeNames_passport6,
  "APPB2C.registration.testTaker.specialArrangements.description": APPB2C_registration_testTaker_specialArrangements_description6,
  "APPB2C.registration.testTaker.specialArrangements.edit.line1": APPB2C_registration_testTaker_specialArrangements_edit_line16,
  "APPB2C.registration.testTaker.specialArrangements.edit.line2": APPB2C_registration_testTaker_specialArrangements_edit_line26,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule1": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule16,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule2": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule26,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule3": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule36,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule4": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule46,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.rule5": APPB2C_registration_testTaker_specialArrangements_edit_rules_rule56,
  "APPB2C.registration.testTaker.specialArrangements.edit.rules.title": APPB2C_registration_testTaker_specialArrangements_edit_rules_title6,
  "APPB2C.registration.testTaker.specialArrangements.edit.subtitle": APPB2C_registration_testTaker_specialArrangements_edit_subtitle6,
  "APPB2C.registration.testTaker.specialArrangements.edit.title": APPB2C_registration_testTaker_specialArrangements_edit_title6,
  "APPB2C.registration.testTaker.specialArrangements.fileAlt": APPB2C_registration_testTaker_specialArrangements_fileAlt6,
  "APPB2C.registration.testTaker.specialArrangements.noFilesBody": APPB2C_registration_testTaker_specialArrangements_noFilesBody6,
  "APPB2C.registration.testTaker.specialArrangements.noFilesTitle": APPB2C_registration_testTaker_specialArrangements_noFilesTitle6,
  "APPB2C.registration.testTaker.specialArrangements.title": APPB2C_registration_testTaker_specialArrangements_title6,
  "APPB2C.testCard.arrival": APPB2C_testCard_arrival6,
  "APPB2C.testCard.book": APPB2C_testCard_book6,
  "APPB2C.testCard.bookExam": APPB2C_testCard_bookExam6,
  "APPB2C.testCard.bookFor": APPB2C_testCard_bookFor6,
  "APPB2C.testCard.bookForFree": APPB2C_testCard_bookForFree6,
  "APPB2C.testCard.bookingPrice": APPB2C_testCard_bookingPrice6,
  "APPB2C.testCard.cd": APPB2C_testCard_cd6,
  "APPB2C.testCard.changeSpeaking": APPB2C_testCard_changeSpeaking6,
  "APPB2C.testCard.f2f": APPB2C_testCard_f2f6,
  "APPB2C.testCard.hideSpeakingTests": APPB2C_testCard_hideSpeakingTests6,
  "APPB2C.testCard.lrw": APPB2C_testCard_lrw6,
  "APPB2C.testCard.lrwHeader": APPB2C_testCard_lrwHeader5,
  "APPB2C.testCard.ls": APPB2C_testCard_ls6,
  "APPB2C.testCard.mixedSlots": APPB2C_testCard_mixedSlots6,
  "APPB2C.testCard.notLinkedVenue": APPB2C_testCard_notLinkedVenue6,
  "APPB2C.testCard.pb": APPB2C_testCard_pb6,
  "APPB2C.testCard.review": APPB2C_testCard_review6,
  "APPB2C.testCard.showSpeakingTests": APPB2C_testCard_showSpeakingTests6,
  "APPB2C.testCard.speaking": APPB2C_testCard_speaking6,
  "APPB2C.testCard.speakingAddress": APPB2C_testCard_speakingAddress6,
  "APPB2C.testCard.timeUnknown": APPB2C_testCard_timeUnknown6,
  "APPB2C.testCard.vcs": APPB2C_testCard_vcs6
};

// src/config.ts
var i18nConfig = (loggerAction) => ({
  fallbackLng: "en",
  returnEmptyString: false,
  debug: false,
  ns: "b2c",
  defaultNS: "b2c",
  interpolation: {
    escapeValue: false,
    format: function(value, format) {
      if (format === "capitalise")
        return value[0].toUpperCase() + value.slice(1);
      return value;
    }
  },
  saveMissing: true,
  missingKeyHandler(lngs, ns, key) {
    if (loggerAction) {
      loggerAction(lngs, ns, key);
    }
  }
});
var initTranslations = (actions) => {
  import_i18next.default.use(import_react_i18next.initReactI18next).init(__spreadValues({}, i18nConfig(actions == null ? void 0 : actions.logMissingTranslationKeyAction))).then(() => {
    if (actions == null ? void 0 : actions.afterInitAction) {
      actions.afterInitAction();
    }
  });
  import_i18next.default.addResourceBundle("en", "b2c", b2c_default);
  import_i18next.default.addResourceBundle("en-US", "b2c", b2c_default2);
  import_i18next.default.addResourceBundle("ko", "b2c", b2c_default3);
  import_i18next.default.addResourceBundle("zh-TW", "b2c", b2c_default4);
  import_i18next.default.addResourceBundle("zh-CN", "b2c", b2c_default5);
  import_i18next.default.addResourceBundle("ach-UG", "b2c", b2c_default6);
  return import_i18next.default;
};

// src/const.ts
var defaultLanguage = "en-GB";
var ieltsUsaLanguage = "en-US";
var pseudoLanguage = "ach-UG";

// src/customisation/ieltsUsa.ts
var IELTS_USA_HOST_NAME = "registration-ieltsusa";
var orgRegex = /organi(z|s)ation/g;
var ieltsUsaSpecific = {
  countryId: 136,
  organisationAlias: "IELTS_USA"
};
var hasIeltsUsaUrl = () => {
  return window.location.host.includes(IELTS_USA_HOST_NAME);
};
var isIeltsUsaContextUrl = () => {
  const isIeltsUsaUrl = hasIeltsUsaUrl();
  const doesOrganisationAliasExistInUrl = window.location.search.match(orgRegex);
  return isIeltsUsaUrl && !doesOrganisationAliasExistInUrl;
};

// src/hooks.ts
var import_react_common2 = require("@britishcouncil/react-common");

// localeSettings.json
var languageNames = [
  { code: "en-GB", name: "English" },
  { code: "en-US", name: "English" },
  { code: "zh-TW", name: "\u7E41\u9AD4\u4E2D\u6587" },
  { code: "zh-CN", name: "\u7B80\u4F53\u4E2D\u6587" },
  { code: "ko", name: "\uD55C\uAD6D\uC5B4" }
];

// src/helpers/helpers.ts
var import_locale = require("date-fns/locale");
var import_react_common = require("@britishcouncil/react-common");
var getDateFnLocale = (locale) => {
  switch (locale) {
    case "en-US":
      return import_locale.enUS;
    case "zh-TW":
      return import_locale.zhTW;
    case "zh-CN":
      return import_locale.zhCN;
    case "ko":
      return import_locale.ko;
    default:
      return import_locale.enGB;
  }
};
var selectLanguage = (lang) => {
  var _a;
  const isUsa = hasIeltsUsaUrl();
  const fallbackLang = isUsa ? ieltsUsaLanguage : defaultLanguage;
  const currentLang = import_react_common.SimpleLS.read("SELECTED_LANGUAGE", fallbackLang);
  const supportedLanguages = readSupportedLanguages();
  if ([ieltsUsaLanguage, defaultLanguage].some((l) => l === currentLang) && currentLang !== fallbackLang) {
    import_react_common.SimpleLS.write("SELECTED_LANGUAGE", fallbackLang);
    return fallbackLang;
  }
  const supportedLang = (_a = supportedLanguages == null ? void 0 : supportedLanguages.find((l) => l.toLowerCase() === lang.toLowerCase())) != null ? _a : currentLang;
  import_react_common.SimpleLS.write("SELECTED_LANGUAGE", supportedLang);
  return supportedLang;
};
var setSupportedLanguages = (supportedLanguages) => {
  const isUsa = hasIeltsUsaUrl();
  const supportedLangs = [defaultLanguage, ...supportedLanguages.split(",")];
  const validatedSupportedLanguages = isUsa ? [ieltsUsaLanguage] : supportedLangs.filter((l) => l !== ieltsUsaLanguage);
  writeSupportedLanguages(validatedSupportedLanguages);
  return validatedSupportedLanguages;
};
var writeSupportedLanguages = (supportedLanguages) => {
  import_react_common.SimpleLS.write("SUPPORTED_LANGUAGES", supportedLanguages != null ? supportedLanguages : []);
};
var readSupportedLanguages = () => {
  return import_react_common.SimpleLS.read("SUPPORTED_LANGUAGES", []);
};

// src/helpers/setOneTrustLanguage.ts
var setOneTrustLanguage = (lang) => {
  var _a;
  const windowOneTrust = window;
  (_a = windowOneTrust == null ? void 0 : windowOneTrust.OneTrust) == null ? void 0 : _a.changeLanguage(getOneTrustLanguageCode(lang));
};
var getOneTrustLanguageCode = (lang) => {
  switch (lang) {
    case "zh-TW":
      return "zh-hant";
    case "zh-CN":
      return "zh-hans";
    case "en-GB":
    case "en-US":
      return "en";
    default:
      return lang;
  }
};

// src/hooks.ts
var useSelectedLang = () => {
  const urlLang = (0, import_react_common2.getUrlSearchParam)("lang");
  return selectLanguage(String(urlLang));
};
var useLanguageNames = () => {
  var _a;
  const supportedLanguages = readSupportedLanguages();
  return (_a = languageNames) == null ? void 0 : _a.filter((ln) => supportedLanguages == null ? void 0 : supportedLanguages.some((sl) => sl === ln.code));
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  defaultLanguage,
  getDateFnLocale,
  hasIeltsUsaUrl,
  ieltsUsaLanguage,
  ieltsUsaSpecific,
  initTranslations,
  isIeltsUsaContextUrl,
  pseudoLanguage,
  readSupportedLanguages,
  selectLanguage,
  setOneTrustLanguage,
  setSupportedLanguages,
  useLanguageNames,
  useSelectedLang,
  writeSupportedLanguages
});

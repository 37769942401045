export enum idDetailsErrorCodes {
  InvalidFileName = 'InvalidFileName',
}

export enum personalDetailsErrorCodes {
  /** code */
  EmailInUse = 'AccountWithSameEmailAlreadyExists',
  TestTakerFailure = 'TestTakerFailure',
  DateShouldBeInThePast = 'DateShouldBeInThePast',

  /** property */
  dob = 'dob',
  gender = 'gender',
  plannedExamId = 'plannedExamId',
  confirmEmail = 'confirmEmail',
}

export enum registrationErrorCodes {
  /** code */
  TaxIdentifier = 'TaxIdentifier',
  TotalFeeChanged = 'CalculatedTotalFeeGreaterThanExpected',
  VoucherReserved = 'VoucherReserved',
  VoucherReserveFailed = 'VoucherReserveFailed',
  MissingIdFile = 'MissingIdFile',
  CountrySettingNotFound = 'CountrySettingNotFound',
  GreaterOrEqualZero = 'GreaterOrEqualZero',
  NotFound = 'NotFound',

  /** property */
  AddressTown = 'address.town',
  companyName = 'companyName',
  taxNumber1 = 'taxNumber1',
  pickUps = 'pickUps',
}
